import React, { Dispatch, SetStateAction, useState } from 'react';
import { IProvider, IEvent, IFullAppointmentType } from 'model/v2';

import { ICalendarResource } from 'model/calendar/filters';

import AddObserversHeader from './AddObserversHeader';
import { AddObserversViewWrapper } from '../style';
import AttendeesHeader from './AttendeesHeader';
import Content from 'views/components/ui/content';
import AddAbaObserversButtons from './AddAbaObserversButtons';
import AbaObserversDropdown from './AbaObserversDropdown';
import AbaObserverRow from './AbaObserverRow';
import AbaObserverAlert from './AbaObserverAlert';
import { LocationType } from 'views/components/icons/eventIcons/locationIcon';

const MAX_OBSERVERS = 5;

interface Props {
  providersMap: Map<any, IProvider>;
  event: IEvent;
  abaObserversList: IEvent[];
  setAbaObserversList: Dispatch<SetStateAction<IEvent[]>>;
  lastAbaObserver: IEvent;
  setLastAbaObserver: Dispatch<SetStateAction<IEvent>>;
  observerId: number | undefined;
  setObserverId: Dispatch<SetStateAction<number | undefined>>;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  apptTypes: IFullAppointmentType[];
  setIsAddObservationMode: (val: boolean) => void;
  isAddObservationMode: boolean;
  setVisible: (val: boolean) => void;
  observersData: any;
  setAbaObserversListObj: Dispatch<SetStateAction<IEvent>>;
}
const AddAbaObserversView: React.FC<Props> = ({
  abaObserversList,
  setAbaObserversList,
  lastAbaObserver,
  observerId,
  setObserverId,
  setLastAbaObserver,
  event,
  setIsAddObservationMode,
  setVisible,
  observersData,
  setAbaObserversListObj
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isObserversDropdownShown, setIsObserversDropdownShown] = useState<
    boolean
  >(abaObserversList.length === 0);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [isLocationSelected, setIsLocationSelected] = React.useState<boolean>(
    true
  );
  const [abalocationType, setAbaLocationType] = React.useState<LocationType>();
  const [abaObserverId, setAbaObserverId] = React.useState<number>();

  return (
    <>
      <Content loading={isSaving} data={true} />
      <AddObserversViewWrapper>
        <AttendeesHeader
          observations={event.observations!}
          children={
            <AddAbaObserversButtons
              leadEventId={event.id!}
              abaObserversList={abaObserversList}
              setAbaObserversList={setAbaObserversList}
              lastAbaObserver={lastAbaObserver}
              setLastAbaObserver={setLastAbaObserver}
              setIsAddObservationMode={setIsAddObservationMode}
              setIsObserversDropdownShown={setIsObserversDropdownShown}
              isSaving={isSaving}
              isValidated={isValidated}
              setIsSaving={setIsSaving}
              setVisible={setVisible}
              event={event}
              setIsLocationSelected={setIsLocationSelected}
              abaObserverId={abaObserverId!}
              abalocationType={abalocationType}
            />
          }
        />
        <AddObserversHeader leadProvider={event.provider!} />

        {abaObserversList.map((Observations: IEvent, index: number) => (
          <AbaObserverRow
            key={index}
            Observations={Observations}
            observerIndex={index + 1}
            setLastAbaObserver={setLastAbaObserver}
            setObserverId={setObserverId}
            setIsObserversDropdownShown={setIsObserversDropdownShown}
            abaObserversList={abaObserversList}
            setAbaObserversList={setAbaObserversList}
            isSaving={isSaving}
            observersData={observersData}
            event={event}
            setIsAlertShown={setIsAlertShown}
            setIsValidated={setIsValidated}
            isValidated={isValidated}
            setAlertContent={setAlertContent}
          />
        ))}
        {(isObserversDropdownShown ||
          abaObserversList.length === 0 ||
          abaObserversList.length > 0) &&
          abaObserversList.length < MAX_OBSERVERS && (
            <AbaObserversDropdown
              abaObserversList={abaObserversList}
              setAbaObserversList={setAbaObserversList}
              lastAbaObserver={lastAbaObserver}
              setLastAbaObserver={setLastAbaObserver}
              observerId={observerId}
              setObserverId={setObserverId}
              setIsObserversDropdownShown={setIsObserversDropdownShown}
              event={event}
              observersData={observersData}
              isSaving={isSaving}
              MAX_OBSERVERS={MAX_OBSERVERS}
              setIsAlertShown={setIsAlertShown}
              setIsValidated={setIsValidated}
              isValidated={isValidated}
              setAlertContent={setAlertContent}
              setAbaObserversListObj={setAbaObserversListObj}
              isLocationSelected={isLocationSelected}
              setIsLocationSelected={setIsLocationSelected}
              setAbaObserverId={setAbaObserverId}
              abaObserverId={abaObserverId}
              setAbaLocationType={setAbaLocationType}
            />
          )}
        {isAlertShown && (
          <AbaObserverAlert
            alertContent={alertContent}
            setIsAlertShown={setIsAlertShown}
          />
        )}
      </AddObserversViewWrapper>
    </>
  );
};

export default AddAbaObserversView;
