import React, { useCallback } from 'react';
import {
  SCHEDULE_TYPE_LIST,
  SCHEDULE_REPEAT_LIST
} from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { Row } from 'antd';
import { FormItem } from 'views/containers/form';
import { APPOINTMENTS_END_DATE } from 'utils/constants/default';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import {
  RadioGroup,
  Select,
  Datepicker
} from 'api/sharedComponents/reactHookFormComponents';

const scheduleTypeOptions = mapToOption(SCHEDULE_TYPE_LIST);
const scheduleRepeatOptions = mapToOption(SCHEDULE_REPEAT_LIST);

function AppointmentRecurring({ disabledFormFields }) {
  const { control, setValue } = useFormContext();

  const watchedValues = useWatch({
    control,
    name: ['selectedDate', 'scheduleType']
  });

  const handleScheduleTypeChange = useCallback(
    event => {
      if (event.target.value === SCHEDULE_TYPE_LIST[0].id) {
        setValue('repeat', 0);
        setValue('recurringUntil', null);
      } else {
        setValue('repeat', 1);
        setValue('recurringUntil', APPOINTMENTS_END_DATE);
      }
    },
    [setValue]
  );

  const handleDatePickerChange = useCallback(
    event => {
      setValue('recurringUntil', event);
    },
    [setValue]
  );

  //TODO: use selected slot here and remove selected date
  const disabledDate = useCallback(
    current => {
      return (
        (current &&
          current <
            moment(watchedValues.selectedDate || new Date()).endOf('day')) ||
        (current && current > APPOINTMENTS_END_DATE)
      );
    },
    [watchedValues]
  );

  return (
    <Row type="flex" justify="start" align="bottom" gutter={16}>
      <FormItem className="schedule-type" label="Schedule Type" xs={16} sm={10}>
        <Controller
          name="scheduleType"
          control={control}
          render={fieldProps => (
            <RadioGroup
              options={scheduleTypeOptions}
              setValue={setValue}
              disabled={disabledFormFields.scheduleType}
              customOnChangeHandler={handleScheduleTypeChange}
              field={fieldProps}
            />
          )}
        />
      </FormItem>
      <FormItem className="repeat-wrap" xs={8} sm={3} pull={3}>
        <Controller
          name="repeat"
          control={control}
          render={fieldProps => (
            <>
              <Select
                options={scheduleRepeatOptions}
                disabled={
                  watchedValues.scheduleType === scheduleTypeOptions[0].value ||
                  disabledFormFields.repeat
                }
                field={fieldProps}
              />
            </>
          )}
        />
      </FormItem>

      <FormItem xs={16} sm={3} pull={3}>
        <div className="weeks-until">week(s) until</div>
      </FormItem>

      {true && (
        <FormItem label={'Recurring End Date'} xs={16} sm={5} pull={3}>
          <Controller
            name="recurringUntil"
            control={control}
            render={fieldProps => (
              <Datepicker
                placeholder="mm/dd/yyyy"
                format={'MM/DD/YYYY'}
                disabledDate={disabledDate}
                onChange={handleDatePickerChange}
                disabled={
                  disabledFormFields.repeat ||
                  watchedValues.scheduleType === scheduleTypeOptions[0].value
                }
                field={fieldProps}
              />
            )}
          />
        </FormItem>
      )}
    </Row>
  );
}

export default React.memo(AppointmentRecurring);
