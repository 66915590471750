import { GET_CLINIC_ROOMS } from 'api/graphql/queries/clinics';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useFormContext } from 'react-hook-form';
import RoomListItem from './RoomListItem';
import Content from 'views/components/ui/content';

const AppointmentRooms = () => {
  const { setValue, watch } = useFormContext();
  // Check use watch here
  const watchedValues = watch(['clinicId', 'selectedRoom']);

  const { data, loading: loadingRooms, error: errorRooms } = useQuery(
    GET_CLINIC_ROOMS,
    {
      variables: {
        id: watchedValues.clinicId
      },
      skip: !watchedValues.clinicId
    }
  );

  if (!watchedValues.clinicId) return null;

  return (
    <Content
      loading={loadingRooms}
      data={data?.clinicRooms}
      errors={errorRooms}
    >
      {roomsList => (
        <>
          <h2 className="appointment-body__title">
            Location <span>(select one)</span>
          </h2>
          <div>
            <div className="openings-rooms">
              <div className="openings-rooms__wrap">
                {roomsList.map(room => (
                  <RoomListItem
                    room={room}
                    key={room.id}
                    selectedRoomValue={watchedValues.selectedRoom}
                    setValue={setValue}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </Content>
  );
};

export default AppointmentRooms;
