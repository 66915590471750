import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';

export const userFragments = {
  user: gql`
    fragment User on User {
      id
      firstName
      lastName
      displayName
      speciality
      mobilePhone
      email
      name
      telehealth
      appointmentTypes {
        id
        isClinical
      }
      clinicId
      crId
      athenaId
    }
  `
};

export const GET_STAFF_PHOTO = gql`
  query($id: ID!) {
    userPhoto(id: $id)
  }
`;

export const GET_STAFF_MEMBER = gql`
  query($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      displayName
      speciality
      clinicId
      mobilePhone
      email
      name
      telehealth
      appointmentTypes {
        id
        isClinical
      }
      crId
      athenaId
    }
    specialities {
      id
      title
      abbreviation
    }
    appointmentTypes {
      id
      title
      headerColor
      isClinical
    }
    appointmentABATypes {
      id
      title
      headerColor
      isClinical
    }
  }
`;

export const GET_EVENT_STAFF_MEMBER = gql`
  query($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      displayName
      speciality
      clinicId
      mobilePhone
      email
      name
      appointmentTypes {
        id
        isClinical
      }
      crId
      athenaId
    }
    specialities {
      id
      title
    }
  }
`;

export const GET_STAFF_MEMBER_EVENTS = gql`
  query(
    $providersIDs: [String]!
    $startTime: String!
    $endTime: String!
  ) {
    events(
      providersIDs: $providersIDs
      startTime: $startTime
      endTime: $endTime
      timeZone: "${TIMEZONE}"
    ) {
      id
      startTime
      endTime
      subject
      client {
        id
        name
      }
      appointmentType {
        id
        isClinical
      }
      clinic {
        id
      }
      room {
        id
        roomName
      }
      locationType
      locationCategory
      locationStreet
      status
      recurringUntil
      type
    }
  }
`;

export const workingHoursFragments = {
  workingHours: gql`
    fragment UserWorkingHours on UserWorkingHours {
      userId
      scheduleId
      workingHours {
        sun {
          startTime
          endTime
        }
        mon {
          startTime
          endTime
        }
        tues {
          startTime
          endTime
        }
        wed {
          startTime
          endTime
        }
        thurs {
          startTime
          endTime
        }
        fri {
          startTime
          endTime
        }
        sat {
          startTime
          endTime
        }
      }
    }
  `
};

export const QUERY_USER_WORKING_HOURS = gql`
  query(
      $users: [UserObj], 
      $providersIDs: [String]!
      $startTime: String!
      $endTime: String!
      ) 
  {
    usersWorkingHours(users: $users) {
      ...UserWorkingHours
    }
    events(
        providersIDs: $providersIDs
        startTime: $startTime
        endTime: $endTime
        timeZone: "${TIMEZONE}"
    ) {
        id
        startTime
        endTime
        appointmentType{
          id
          isClinical
        }
        client{
          phenoType
        }
      }
  }
  ${workingHoursFragments.workingHours}
`;

export const GET_STAFF_WORKING_HOURS = gql`
  mutation($users: [UserObj]) {
    usersWorkingHours(users: $users) {
      ...UserWorkingHours
    }
  }
  ${workingHoursFragments.workingHours}
`;

export const GET_STAFF = gql`
  query($id: String!) {
    user(id: $id) {
      ...User
    }
  }
  ${userFragments.user}
`;
