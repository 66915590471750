import React from 'react';
import { FormItem } from 'views/containers/form';
import { Controller, useFormContext } from 'react-hook-form';
import { IOption, IProvider } from 'model/v2';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
// import { LOCATION_TYPE_LIST } from 'utils/constants/lists';

interface Props {
  filteredStaffList: IOption[];
  isProviderDisabled: boolean;
  providersMap: Map<any, IProvider>;
}

const AppointmentProvider: React.FC<Props> = ({
  filteredStaffList,
  isProviderDisabled,
  providersMap
}: Props) => {
  const { control, setValue, getValues, errors } = useFormContext();

  const setTelehealthLink = (id: string) => {
    const extractedProvider: IProvider[] | undefined = [
      ...providersMap.values()
    ].filter(providerSelected => providerSelected.id == Number(id));
    if (extractedProvider[0].telehealthLinks?.length === 1) {
      setValue('telehealthLink', extractedProvider[0].telehealthLinks[0]);
    } else {
      setValue('telehealthLink', undefined);
    }
  };

  const onProviderChange = React.useCallback(
    (providerId: string) => {
      setValue('selectedOpening', null);
      setValue('dirtyEdit', true);
      setValue('provider.id', providerId);
      setTelehealthLink(providerId);
    },
    [setValue, getValues, providersMap]
  );

  const fetchRef = React.useRef(0);

  React.useMemo(() => {
    const currentProvider = getValues('provider.id');
    const found = filteredStaffList.find(x => x.value === currentProvider);
    if (currentProvider && !found && fetchRef.current !== 0) {
      setValue('provider.id', null);
      setValue('selectedOpening', null);
      setValue('dirtyEdit', true);
    }
    fetchRef.current += 1;
  }, [filteredStaffList, getValues, setValue]);

  return (
    <FormItem optional={false} label="Provider" xs={100} sm={100}>
      <Controller
        name="provider.id"
        control={control}
        render={fieldProps => (
          <Select
            className="appt-category-type"
            showSearch
            filterOption={(input: string, option: any) => {
              const Children =
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0;
              const displayNameMatches = option.displayName
                ? option.displayName
                    .toLowerCase()
                    .trim()
                    .indexOf(input.toLowerCase().trim()) >= 0
                : false;
              return Children || displayNameMatches;
            }}
            field={fieldProps}
            placeholder="Select Provider"
            options={filteredStaffList}
            onSelect={onProviderChange}
            disabled={isProviderDisabled}
            errors={errors}
          />
        )}
      />
    </FormItem>
  );
};
export default React.memo(AppointmentProvider);
