import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const TitleWrapper = styled.div`
  min-height: 96px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8ecef;
  position: relative;
  h1 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.38px;
  }
  .inner-wrap {
    box-sizing: border-box;
    padding: 16px 16px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      display: none;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 15px;
    }
  }
`;

export const ContentWrapper = styled.div`
  .inner-wrap {
    min-height: calc(100vh - 160px);
  }
  .Modal-Final-Sub {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  .Modal-Content-Wrapper {
    padding: 0px 41px 24px 41px;
  }
  .Modal-Warning-Description {
    font-size: 14px;
    color: rgba(65, 87, 102, 1);
    line-height: 17.9px;
    font-weight: 400;
    font-style: Lato;
    font-family: 'Lato';
  }
  .Availability-details {
    display: flex;
    height: 64px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: rgba(65, 87, 102, 1);
    line-height: 17.9px;
    font-weight: 400;
    font-style: Lato;
    font-family: 'Lato';
    margin-bottom: 20px;
  }
  .card-item {
    border-radius: 4px;
    border: solid 1px #c9cfd3;
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: 0.3s border;
    &:hover {
      border-color: #6f4bf1;
    }
    &__head {
      display: block;
      align-items: flex-start;
      justify-content: space-between;
      h2 {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #21303a;
      }
      span {
        display: block;
        font-size: 15px;
        font-weight: normal;
        letter-spacing: normal;
        color: ${colors.DARK_GREY};
      }
      .anticon {
        font-size: 20px;
        color: #919699;
      }
    }
    &__subtitle {
      opacity: 0.8;
      font-size: 15px;
      font-weight: bold;
      color: #21303a;
    }
    &__hours {
      padding-left: 0;
      li {
        list-style: none;
        opacity: 0.8;
        font-size: 15px;
        color: #21303a;
        padding-bottom: 10px;
        span {
          min-width: 80px;
          display: inline-block;
          text-transform: capitalize;
        }
      }
    }
    &__stats {
      > * {
        opacity: 0.8;
        font-size: 15px;
        font-weight: bold;
        color: #21303a;
        text-align: center;
        span {
          display: block;
          font-size: 24px;
          font-weight: 500;
          color: #6f4bf1;
        }
      }
    }
  }
`;
