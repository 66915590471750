import { TableType } from 'model/tableType';
import {
  IAvailabilityTemplate,
  AvailabilityTemplate
} from 'model/availabilityTemplate';
import { IAvailabilityTemplateConfig } from 'model/tableConfigurations';
import { templateActions } from 'utils/constants/availabilityTemplate';
import MainButton from 'views/components/button';
import moment from 'antd/node_modules/moment';

export const mapAvailabilityTemplate = (
  data: TableType[],
  _addNew: (data?: TableType) => void,
  handler?: any
): IAvailabilityTemplateConfig[] =>
  (data as IAvailabilityTemplate[]).map(item => ({
    title: item[AvailabilityTemplate.title],
    department: item[AvailabilityTemplate.department],
    speciality: item[AvailabilityTemplate.speciality]?.abbreviation,
    name: item[AvailabilityTemplate.default]
      ? item[AvailabilityTemplate.name] + ' (Default)'
      : item[AvailabilityTemplate.name],
    createdAt: moment(item[AvailabilityTemplate.createdAt]).format(
      'MM/DD/YYYY'
    ),
    createdBy: item[AvailabilityTemplate.createdBy],
    isActive: (
      <div style={{ display: 'flex' }}>
        <MainButton
          title={'View'}
          onClick={() => handler.viewHandler(item[AvailabilityTemplate.id])}
          loading={false}
          disabled={false}
          className="availabilty-template-btn"
        />
        <MainButton
          title={
            item[AvailabilityTemplate.isActive] === true
              ? templateActions.DEACTIVATE
              : templateActions.REACTIVATE
          }
          onClick={() =>
            handler.deactivateHandler(
              item[AvailabilityTemplate.isActive],
              item.id
            )
          }
          loading={false}
          disabled={false}
          className="availabilty-template-btn"
        />
      </div>
    )
  }));
