import React, { ChangeEvent, LegacyRef, memo } from 'react';
import { CheckListWrapper } from '../../../../../components/calendar/filters/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActiveStatusFilter from '../../../../../components/calendar/filters/activeStatusFilter';
import { Input } from 'semantic-ui-react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ICalendarFilterOption } from 'model/calendar/filters';
import { providerStatusListType } from 'utils/constants/lists';
// import Loader from '../../ui/content/Loader';

export const searchValueContext = React.createContext('');
interface Props {
  filterDropdownExpand: Record<string, boolean>;
  filterTypeRefs: LegacyRef<HTMLDivElement> | undefined;
  handleGenericBlur: (e: any, filterType: string) => void;
  toggleFilterList: (type: string) => void;
  handleGenericSearch: (
    _e: ChangeEvent<HTMLInputElement>,
    data: { value: any },
    typeOfSearch: string
  ) => void;
  hasFilterOptions: boolean;
  searchValue: Record<string, string>;
  isLoading: boolean;
  filterValueMappings: (type: string) => providerStatusListType;
  handleSerachedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  checkedFilterType: ICalendarFilterOption[];
  setFilterType: React.Dispatch<React.SetStateAction<ICalendarFilterOption[]>>;
  setHasFilterOptions: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilter: (clearFilter: any) => void;
  Loader: React.FC;
  title: string;
  placeHolder: string;
  blurTitle: string;
  handleChangeType: string;
}

const WaitListCommonFilter = ({
  filterDropdownExpand,
  filterTypeRefs,
  handleGenericBlur,
  toggleFilterList,
  handleGenericSearch,
  hasFilterOptions,
  searchValue,
  isLoading,
  filterValueMappings,
  handleSerachedValues,
  checkedFilterType,
  setFilterType,
  setHasFilterOptions,
  clearFilter,
  Loader,
  title,
  placeHolder,
  blurTitle,
  handleChangeType
}: Props) => {
  return (
    <CheckListWrapper
      visible={filterDropdownExpand[blurTitle]}
      height="300px"
      style={{ position: 'relative' }}
    >
      <div
        ref={filterTypeRefs}
        onBlur={e => handleGenericBlur(e, blurTitle)}
        tabIndex={-1}
      >
        <div
          className="filter-input_wrapper waitList_input_wrapper"
          onClick={() => toggleFilterList(blurTitle)}
        >
          <Input
            className="filter_input_waitlist"
            placeholder={
              filterDropdownExpand[blurTitle] ? 'Search' : placeHolder
            }
            onChange={event =>
              handleGenericSearch(
                event,
                { value: event.currentTarget.value },
                blurTitle
              )
            }
          />
          {!filterDropdownExpand[blurTitle] && (
            <FontAwesomeIcon className="icon" icon={faCaretDown} />
          )}
          {filterDropdownExpand[blurTitle] && (
            <FontAwesomeIcon
              className="icon"
              icon={faCaretDown}
              style={{ opacity: 0 }}
            />
          )}
        </div>

        {filterDropdownExpand[blurTitle] && (
          <>
            <searchValueContext.Provider value={searchValue[blurTitle]}>
              <div
                className="list_wrapper"
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  zIndex: 1,
                  width: '100%'
                }}
              >
                <div className="scrollable_list">
                  {!hasFilterOptions && (
                    <p className="no-results_filter">No results found</p>
                  )}
                  {isLoading && <Loader />}
                  {!isLoading && (
                    <ActiveStatusFilter
                      statusList={filterValueMappings(handleChangeType) || []}
                      title={title}
                      handleSearchedValues={handleSerachedValues}
                      checkedStatus={checkedFilterType}
                      setCheckedStatus={setFilterType}
                      setHasData={setHasFilterOptions}
                    />
                  )}
                </div>
                <div className="calender-filters_actions">
                  <span onClick={() => clearFilter(setFilterType)}>
                    Clear all
                  </span>
                </div>
              </div>
            </searchValueContext.Provider>
          </>
        )}
      </div>
    </CheckListWrapper>
  );
};

export default memo(WaitListCommonFilter);
