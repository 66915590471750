import { Avatar } from '@material-ui/core';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { DEFAULT_DOCTOR_IMAGE } from 'utils/constants/default';
import { AttendeeRowWrapper } from './style';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  displayName: string;
  displayId: any;
  icon: any;
  iconTitle: string;
  color: string;
  eventType: string;
  isLoading?: boolean;
}

const AttendeeRow: React.FC<Props> = ({
  displayName,
  displayId,
  icon,
  iconTitle,
  color,
  eventType,
  isLoading
}) => {
  return (
    <AttendeeRowWrapper color={color}>
      <Grid>
        <Grid.Row className={`row-style ${eventType} `}>
          <Grid.Column className="avatar">
            {!isLoading ? (
              <Avatar
                src={iconTitle === 'Patient' ? ' ' : DEFAULT_DOCTOR_IMAGE}
              />
            ) : (
              <Skeleton variant="circle" width={'40px'} height={'40px'} />
            )}
          </Grid.Column>
          {!isLoading ? (
            <Grid.Column className="attende-name">
              <Link
                className="attende-name"
                target="_blank"
                to={
                  iconTitle === 'Patient'
                    ? `/clients/${displayId}`
                    : `/providers/${displayId}`
                }
              >
                {displayName}
              </Link>
            </Grid.Column>
          ) : (
            <Skeleton
              variant="rect"
              children={
                <Grid.Column className="attende-name">
                  <Link
                    className="attende-name"
                    target="_blank"
                    to={
                      iconTitle === 'Patient'
                        ? `/clients/1234`
                        : `/providers/1234`
                    }
                  >
                    {displayName}
                  </Link>
                </Grid.Column>
              }
            />
          )}{' '}
          {!isLoading ? (
            <Grid.Column className="button-column">
              <button className="provider-btn">
                {icon} {iconTitle}
              </button>
            </Grid.Column>
          ) : (
            <Skeleton
              variant="rect"
              children={
                <Grid.Column className="button-column">
                  <button className="provider-btn">
                    {icon} {`Lead Provider`}
                  </button>
                </Grid.Column>
              }
            />
          )}
        </Grid.Row>
      </Grid>
    </AttendeeRowWrapper>
  );
};

export default AttendeeRow;
