import { useReactiveVar } from '@apollo/client';
import {
  convertFilteredToSearched,
  isAttendeeInFiltered
} from 'helpers/calendarHelper';
import useEventsFetcherHook from 'hooks/eventsFetcherHook';
import { isEqual } from 'lodash';
import { ICalendarResource } from 'model/calendar/filters';
import { calendarGroup } from 'model/calendar/groups';
import {
  IClient,
  IEvent,
  IFormEvent,
  IFullAppointmentType,
  IProvider,
  ISmartResultItemForm
} from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar,
  removePhantomEvents
} from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  calendarFiltersPrefVar
} from 'utils/cache/filters';

import { formatAttendeeName, formatProviderDisplayName } from 'utils/format';

import { EVENTS_FETCHING_ACTIONS, plannerContext } from '..';
import {
  useAddClientToCalendar,
  useAddPhantomEvents,
  useAddProviderToCalendar,
  useApptTypesMap
} from './CustomPhantomHooks';
import { IPhantomEvent } from 'model/calendar/events';
import { mapSubstituteEventToPhantomEvent } from 'utils/mappers/response/phantomEvents';

interface Props {
  providersMap: Map<any, any>;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<SetStateAction<ICalendarResource[]>>;
  substituteProviderId?: number;
  substituteProvider?: IProvider;
  event: IFormEvent;
  apptTypes: IFullAppointmentType[];
  adjacentEvent?: IEvent;
  isDirectSubstitute?: boolean;
}

const SubstitutePhantomEvents: React.FC<Props> = ({
  providersMap,
  paginationArray,
  setPaginationArray,
  substituteProviderId,
  substituteProvider,
  event,
  apptTypes,
  adjacentEvent,
  isDirectSubstitute
}) => {
  const methods = useFormContext();
  const selectedGroupRef = useRef<ICalendarResource[] | null>();
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const appTypesMap = useApptTypesMap(apptTypes);
  const { setIsLoading, setIsCalendarLoading, isCalendarLoading } = useContext(
    plannerContext
  );

  const results: ISmartResultItemForm[] | undefined = useWatch({
    control: methods.control,
    name: 'results'
  });
  const smartEventRef = useRef(results);
  if (!isEqual(smartEventRef.current, results)) {
    smartEventRef.current = results;
  }
  const { getClientEvents, getStaffEvents } = useEventsFetcherHook(
    selectedGroupRef && selectedGroupRef.current
      ? selectedGroupRef.current
      : undefined,
    setIsLoading,
    setIsCalendarLoading,
    isCalendarLoading,
    EVENTS_FETCHING_ACTIONS.APPLY_SEARCH,
    setPaginationArray,
    paginationArray,
    [],
    selectedGroupRef.current as ICalendarResource[]
  );
  const addPhantomEvents = useAddPhantomEvents();
  //   const selectedCardDate: (moment.MomentInput | undefined)[] = [];
  const { addProviderToCalendar, searchedProviders } = useAddProviderToCalendar(
    getStaffEvents,
    filters,
    calendarView,
    calendarDate
  );
  const addClientToCalendar = useAddClientToCalendar(
    getClientEvents,
    filters,
    calendarView,
    calendarDate
  );
  useEffect(() => {
    if (substituteProviderId !== undefined) {
      const newProviders: ICalendarResource[] = [];
      let selectedProvider: ICalendarResource = providersMap?.get(
        substituteProviderId
      )!;
      selectedProvider = {
        ...selectedProvider,
        displayName: formatProviderDisplayName(selectedProvider),
        attendeeType: calendarGroup.provider
      };
      if (isAttendeeInFiltered(substituteProviderId) && selectedProvider) {
        const shouldFetchEvents = convertFilteredToSearched(selectedProvider);
        if (!shouldFetchEvents) return;
      } else if (
        substituteProviderId !== undefined &&
        searchedProviders?.has(substituteProviderId)
      ) {
        return;
      }
      if (selectedProvider) {
        newProviders.push(selectedProvider);
      }
      if (newProviders) {
        //in filter convert to search
        selectedGroupRef.current = newProviders;
        addProviderToCalendar(newProviders);
      }
    }
  }, [
    substituteProviderId,
    providersMap,
    searchedProviders,
    addProviderToCalendar
  ]);
  useEffect(() => {
    if (event.client?.id) {
      //const values = methods?.getValues();
      const selectedClient: ICalendarResource = {
        id: event?.client.id,
        displayName: formatAttendeeName(
          (event.client as IClient)?.firstName,
          (event.client as IClient)?.lastName,
          event.client?.clinic?.abbreviation
        ),
        name: (event.client as IClient).name,
        attendeeType: calendarGroup.client,
        clinic: event.clinic,
        profile: (event.client as IClient).profile
      };
      selectedGroupRef.current = [selectedClient];
      addClientToCalendar(selectedClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.client?.id]);
  useEffect(() => {
    removePhantomEvents(calendarEventsPrefVar());
    if (substituteProvider === undefined) {
      return;
    }
    const currentEvents = calendarEventsPrefVar();
    const phantomEvents: IPhantomEvent[][] = [];
    const mappedPhantomEvents = mapSubstituteEventToPhantomEvent(
      event,
      substituteProvider,
      appTypesMap,
      calendarView,
      calendarDate,
      adjacentEvent,
      isDirectSubstitute!
    );
    phantomEvents.push(mappedPhantomEvents);
    const allPhantomEvents = phantomEvents.flat(1);
    if (allPhantomEvents.length > 0) {
      addPhantomEvents(currentEvents, allPhantomEvents);
    } else {
      removePhantomEvents(calendarEventsPrefVar());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarDate,
    calendarView,
    smartEventRef,
    providersMap,
    smartEventRef.current,
    substituteProvider
  ]);
  return <></>;
};

export default React.memo(SubstitutePhantomEvents);
