import React, { useState } from 'react';
import * as linkify from 'linkifyjs';
import { DEFAULT_PHOTO_URL } from 'utils/constants/default';
import { GET_PROVIDER_PHOTO } from 'api/graphql/v2/queries/Providers';
import { useQuery } from '@apollo/react-hooks';
import ReactVisibilitySensor from 'react-visibility-sensor';

function ProfilePicture({ email, ...restProps }) {
  const { img, alt, className } = restProps;
  const [image, setImage] = useState('');
  const [visible, setVisible] = useState(false);
  const alternative = alt || '';
  const classN = className || 'avatar';

  useQuery(GET_PROVIDER_PHOTO, {
    variables: { email },
    skip: !email || !visible,
    onCompleted: data => {
      data?.providerPhoto && adjustDefaultImage(data.providerPhoto);
    }
  });

  const adjustDefaultImage = img => {
    setImage(!linkify.test(img) ? `data:image/jpeg;base64,${img}` : img);
  };
  if (!image && visible) {
    adjustDefaultImage(img || DEFAULT_PHOTO_URL);
  }

  return (
    <ReactVisibilitySensor>
      {({ isVisible }) => {
        if (isVisible) setVisible(isVisible);
        return <img src={image} alt={alternative} className={classN}></img>;
      }}
    </ReactVisibilitySensor>
  );
}
export default ProfilePicture;
