import styled from 'styled-components';

export const CalendarPageWrapper = styled.div`
  height: 100%;
  border: none;
  .page-content_wrapper {
    position: relative;
    .css-0 {
      z-index: 999;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .page-content_container {
      display: flex;
      flex-direction: row;
    }
  }
  .calendar-btn {
    position: absolute;
    right: 6px;
    top: 7px;
  }
`;
export default CalendarPageWrapper;
