export enum Room {
  id = 'id',
  clinicID = 'clinicID',
  email = 'email',
  hasInstruments = 'hasInstruments',
  hasTV = 'hasTV',
  roomName = 'roomName',
  roomType = 'roomType',
  seats = 'seats'
}
export enum RoomType {
  medical = 'Medical',
  allPurpose = 'All Purpose',
  gym = 'Gym'
}
export interface IRoom {
  id?: string;
  clinicID?: string;
  email?: string;
  hasInstruments?: boolean;
  hasTV?: boolean;
  roomName?: string;
  roomType?: string;
  seats?: number;
}
export const roomsTypeList = [
  RoomType.medical,
  RoomType.allPurpose,
  RoomType.gym
];
