import React, { useCallback } from 'react';
import ClinicTabs from './tabs';
import Breadcrumbs from 'views/components/ui/profile/Breadcrumbs';
import Content from 'views/components/ui/content';
import Header from './header';
import { ProfileWrap } from './profile.style';
import { useQuery } from '@apollo/react-hooks';
import { GET_CLINIC } from 'api/graphql/v2/queries/Clinics';
import MainButton from 'views/components/button';
import { useHistory } from 'react-router';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';

const ClinicProfile = ({ match }) => {
  const history = useHistory();
  const id = match.params.id;

  const { loading, error, data } = useQuery(GET_CLINIC, {
    variables: { id },
    fetchPolicy: 'network-only'
  });

  const editClinic = useCallback(
    clinic => {
      history.push({
        pathname: `/clinics/${clinic.id}/edit`,
        state: {
          title: 'Edit Clinic',
          clinic: clinic
        }
      });
    },
    [history]
  );

  return (
    <>
      <Content loading={loading} error={error} data={data}>
        {({ clinic }) => (
          <ProfileWrap>
            <div className="headerRow">
              <Breadcrumbs userType="clinics" clinic={clinic} />
              {getFeatureAvailability(FEATURES.CLINIC_CONFIGURATION) && (
                <MainButton
                  title={'Edit Clinic'}
                  onClick={() => editClinic(clinic)}
                />
              )}
            </div>
            <Header clinic={clinic} />
            <ClinicTabs clinic={clinic} />
          </ProfileWrap>
        )}
      </Content>
    </>
  );
};
export default ClinicProfile;
