import * as React from 'react';
import { Table } from 'semantic-ui-react';
import Tcell from './Tcell';
import { ITableColumn, ITableRow } from './type';

interface ITbodyProps {
  headers: ITableColumn[];
  rows: ITableRow[];
}
const Tbody: React.FC<ITbodyProps> = ({ headers, rows }: ITbodyProps) => (
  <Table.Body>
    {headers &&
      rows &&
      rows.map((row, i) => (
        <Table.Row key={i}>
          <Tcell headers={headers} row={row} />
        </Table.Row>
      ))}
  </Table.Body>
);

export default Tbody;
