import { ClientDayAvailabilityOutput, IClinic } from 'model/v2';
import React from 'react';
import ClientAvailabilityBlock from './ClientAvailabilityBlock';
import { DailyClientAvailabilityWrapper } from './style';

interface IProps {
  dayIndex: number;
  clientAvailabilityPerDay: Map<number, ClientDayAvailabilityOutput[]>;
  clientClinicNetwork: IClinic[];
}
const DailyClientAvailability: React.FC<IProps> = ({
  dayIndex,
  clientAvailabilityPerDay,
  clientClinicNetwork
}) => {
  return (
    <DailyClientAvailabilityWrapper>
      {clientAvailabilityPerDay
        .get(dayIndex)
        ?.map((availabilityBlock: ClientDayAvailabilityOutput) => (
          <ClientAvailabilityBlock
            availabilityBlock={availabilityBlock}
            clientClinicNetwork={clientClinicNetwork}
          />
        ))}
    </DailyClientAvailabilityWrapper>
  );
};

export default DailyClientAvailability;
