import { Input, Select } from 'api/sharedComponents/reactHookFormComponents';
import React, { useEffect, useMemo } from 'react';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import {
  ABAAppointmentSubType,
  IABAAppointmentSubType
} from 'model/abaAppointmentSubtype';
import MainButton from 'views/components/button';
import { ConfigurationFormWrapper } from '../AppointmetTypesForm.style';
import {
  mapAppointmentTitlesToOptions,
  mapToOption,
  mapValueToOptions
} from 'utils/mappers/form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { yupResolver } from '@hookform/resolvers/yup';
import { ABA_APPOINTMENT_SUBTYPE_SCHEMA } from 'utils/validators/configurations/abaAppointmentSubtype';
import { ILookup, Lookup } from 'model/lookup';
import { IAppointmentType } from 'model/appointment';
import { GET_ABA_APPOINTMENT_SUBTYPES } from 'api/graphql/v2/queries/AppointmentTypes';
import { PUT_ABA_APPOINTMENT_SUBTYPE } from 'api/graphql/v2/mutations/AppointmentTypes';
import { useMutation } from '@apollo/react-hooks';
import { formatABASubTypeToInput } from 'utils/format/appointmentType';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';

interface Props {
  initialValues: IABAAppointmentSubType;
  onFormClose: () => void;
  title: string;
  placesOfService: ILookup[];
  crAuthCodes: ILookup[];
  crTypes: ILookup[];
  superTypes: IAppointmentType[];
}

const ABAAppointmentSubTypeForm: React.FC<Props> = ({
  initialValues,
  title,
  onFormClose,
  superTypes,
  crAuthCodes,
  placesOfService,
  crTypes
}: Props) => {
  const methods = useForm<IABAAppointmentSubType>({
    defaultValues: initialValues,
    resolver: yupResolver(ABA_APPOINTMENT_SUBTYPE_SCHEMA())
  });

  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting, isDirty },
    reset
  } = methods;

  const superTypeOptions = useMemo(
    () => (superTypes ? mapAppointmentTitlesToOptions(superTypes) : []),
    [superTypes]
  );
  const crAuthCodesOptions = useMemo(
    () => (crAuthCodes ? mapToOption(crAuthCodes) : []),
    [crAuthCodes]
  );
  const placeOfServiceOptions = useMemo(
    () => (placesOfService ? mapToOption(placesOfService) : []),
    [placesOfService]
  );
  const crTypesOptions = useMemo(
    () => (crTypes ? mapValueToOptions(crTypes) : []),
    [crTypes]
  );

  const [submitABASubtype] = useMutation(PUT_ABA_APPOINTMENT_SUBTYPE);
  const onSubmit = handleSubmit(async (data: IABAAppointmentSubType) => {
    await submitABASubtype({
      variables: {
        settingsWithAppointment: formatABASubTypeToInput(data, initialValues),
        eventType: EVENT_TYPES.ABA
      },
      refetchQueries: [
        {
          query: GET_ABA_APPOINTMENT_SUBTYPES
        }
      ]
    })
      .then(onFormClose)
      .catch(error =>
        console.log('Error while saving ABA subtype', JSON.stringify(error))
      );
  });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  return (
    <>
      <ConfigurationFormWrapper>
        <div className="header">
          <h3>{title}</h3>
          <button className="ui icon button close-button" onClick={onFormClose}>
            <i className="close icon"></i>
          </button>
        </div>

        <form onSubmit={onSubmit}>
          <FormItem label="Title">
            <Controller
              name={ABAAppointmentSubType.title}
              control={control}
              render={(
                fieldProps: ControllerRenderProps<Record<string, any>>
              ) => (
                <Input
                  errors={errors}
                  placeholder="Input title"
                  field={fieldProps}
                />
              )}
            />
          </FormItem>

          {initialValues?.id !== 0 && (
            <FormItem label="ID">
              <Controller
                name={ABAAppointmentSubType.id}
                control={control}
                render={(
                  fieldProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <Input
                    errors={errors}
                    placeholder="Input ID"
                    field={fieldProps}
                    disabled
                  />
                )}
              />
            </FormItem>
          )}

          <FormItem label="Place of service">
            <Controller
              control={control}
              name={`${[ABAAppointmentSubType.placeOfService]}.${Lookup.id}`}
              render={(
                fieldProps: ControllerRenderProps<Record<string, any>>
              ) => (
                <Select
                  options={placeOfServiceOptions}
                  placeholder="Place of service"
                  field={fieldProps}
                  errors={errors}
                />
              )}
            />
          </FormItem>

          <FormItem label="Parent Type">
            <Controller
              control={control}
              name={`${[ABAAppointmentSubType.superType]}.${Lookup.id}`}
              render={(
                fieldProps: ControllerRenderProps<Record<string, any>>
              ) => (
                <Select
                  options={superTypeOptions}
                  placeholder="Parent Type"
                  field={fieldProps}
                  errors={errors}
                />
              )}
            />
          </FormItem>

          <FormItem label="CR Auth Code">
            <Controller
              control={control}
              name={`${[ABAAppointmentSubType.crAuthCode]}.${Lookup.id}`}
              render={(
                fieldProps: ControllerRenderProps<Record<string, any>>
              ) => (
                <Select
                  options={crAuthCodesOptions}
                  placeholder="CR Auth Code"
                  field={fieldProps}
                  errors={errors}
                />
              )}
            />
          </FormItem>

          <FormItem label="CR Appointment Type">
            <Controller
              control={control}
              name={ABAAppointmentSubType.crType}
              render={(
                fieldProps: ControllerRenderProps<Record<string, any>>
              ) => (
                <Select
                  options={crTypesOptions}
                  placeholder="CR Appointment Type"
                  field={fieldProps}
                  errors={errors}
                />
              )}
            />
          </FormItem>

          <MainButton
            className="submit-button"
            title="Save"
            onClick={() => {
              onSubmit();
            }}
            disabled={isSubmitting || !isDirty}
            loading={isSubmitting}
          ></MainButton>
        </form>
      </ConfigurationFormWrapper>
    </>
  );
};
export default ABAAppointmentSubTypeForm;
