import * as yup from 'yup';
import { Specialty } from 'model/v2';
import { COLOR_REGEX, ALPHANUMERIC_REGEX } from 'utils/constants/regex';

export const SPECIALTIES_SCHEMA = yup.object().shape({
  [Specialty.title]: yup.string().required('Title is required'),
  [Specialty.abbreviation]: yup
    .string()
    .required('Abbreviation is required')
    .test(
      'max length',
      'Abbreviation should be between 1 and 10 characters',
      function(value?: string | null) {
        return value!.length >= 1 && value!.length <= 10;
      }
    )
    .matches(
      ALPHANUMERIC_REGEX,
      'Only alphanumerics are allowed for this field '
    ),
  [Specialty.department]: yup.string().required('Departmentis required'),
  [Specialty.color]: yup
    .string()
    .required('Color is required')
    .matches(COLOR_REGEX, 'invalid color')
});
