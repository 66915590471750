import React, { useMemo } from 'react';
import {
  FormItem,
  Input,
  AutoFormattedInput,
  Select,
  Datepicker
} from 'views/containers/form';
import { Row } from 'antd';
import { Field, FastField } from 'formik';
import { mapToOption } from 'utils/mappers/form';

export const ClientInsuranceForm = React.memo(
  ({ patientRelationships, disabledFormFields }) => {
    const patientRelationshipToSubscriberOptions = useMemo(
      () => (patientRelationships ? mapToOption(patientRelationships) : []),
      [patientRelationships]
    );

    return (
      <>
        <div className="form-title">
          Insurance <span className="optionalSpan">(optional)</span>
        </div>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem optional label="Insurance Provider" xs={16} sm={6}>
            <Field
              name="profile.insuranceProvider"
              render={({ field }) => (
                <Input
                  name="profile.insuranceProvider"
                  value={field.value}
                  placeholder="Insurance Provider"
                  onChange={field.onChange}
                  disabled={disabledFormFields.insuranceProvider}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Member Number" xs={16} sm={6}>
            <Field
              name="profile.memberNumber"
              render={({ field }) => (
                <Input
                  name="profile.memberNumber"
                  value={field.value}
                  placeholder="Member Number"
                  onChange={field.onChange}
                  disabled={disabledFormFields.memberNumber}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Group Number" xs={16} sm={6}>
            <Field
              name="profile.groupNumber"
              render={({ field }) => (
                <Input
                  name="profile.groupNumber"
                  value={field.value}
                  placeholder="Group Number"
                  onChange={field.onChange}
                  disabled={disabledFormFields.groupNumber}
                />
              )}
            />
          </FormItem>
        </Row>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem optional label="Subscriber Last Name" xs={16} sm={6}>
            <Field
              name="profile.subscriberLastName"
              render={({ field }) => (
                <Input
                  name="profile.subscriberLastName"
                  value={field.value}
                  placeholder="Last Name"
                  onChange={field.onChange}
                  disabled={disabledFormFields.subscriberLastName}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Subscriber First Name" xs={16} sm={6}>
            <Field
              name="profile.subscriberFirstName"
              render={({ field }) => (
                <Input
                  name="profile.subscriberFirstName"
                  value={field.value}
                  placeholder="First Name"
                  onChange={field.onChange}
                  disabled={disabledFormFields.subscriberFirstName}
                />
              )}
            />
          </FormItem>
        </Row>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem optional label="Subscriber Date of Birth" xs={16} sm={6}>
            <Field
              name="profile.subscriberDOB"
              render={fieldProps => (
                <Datepicker
                  value={
                    fieldProps.field.value ? fieldProps.field.value : undefined
                  }
                  placeholder="mm/dd/yyyy"
                  format={'MM/DD/YYYY'}
                  disabled={disabledFormFields.subscriberDOB}
                  {...fieldProps}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Subscriber SSN" xs={16} sm={6}>
            <FastField
              name="profile.subscriberSSN"
              render={({ field }) => (
                <AutoFormattedInput
                  type="ssn"
                  name="profile.subscriberSSN"
                  value={field.value || ''}
                  placeholder="000-00-0000"
                  onChange={field.onChange}
                  disabled={disabledFormFields.subscriberSSN}
                />
              )}
            />
          </FormItem>
        </Row>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem
            optional
            label="Patient's Relationship to Subscriber"
            xs={16}
            sm={6}
          >
            <FastField
              name="profile.patientRelationshipToSubscriber"
              render={fieldProps => (
                <Select
                  options={patientRelationshipToSubscriberOptions}
                  placeholder="i.e. Child, Grandchild"
                  {...fieldProps}
                  disabled={disabledFormFields.patientRelationshipToSubscriber}
                />
              )}
            />
          </FormItem>
        </Row>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem
            optional
            label="Primary Care Physician's Phone Number"
            xs={16}
            sm={6}
          >
            <FastField
              name="profile.physicianPhoneNumber"
              render={({ field }) => (
                <AutoFormattedInput
                  type="phone"
                  name="profile.physicianPhoneNumber"
                  value={field.value || ''}
                  placeholder="000-000-0000"
                  onChange={field.onChange}
                  disabled={disabledFormFields.physicianPhoneNumber}
                />
              )}
            />
          </FormItem>
        </Row>
      </>
    );
  }
);
