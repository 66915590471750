import { ITableColumn } from 'views/components/table/type';
import { TableType } from 'model/tableType';
import { Specialty, IProviderSpecialty } from 'model/v2';
import { sortNumerical } from 'utils/sort';

export const SPECIALITIES_COLUMNS: ITableColumn[] = [
  {
    label: 'Title',
    key: Specialty.title,
    notWrap: true,
    comp: {
      sort: (a: TableType, b: TableType) => sort(a, b, Specialty.title),
      reverse: (a: TableType, b: TableType) => sort(b, a, Specialty.title)
    }
  },
  { label: 'Abbreviation', key: Specialty.abbreviation },
  { label: 'Color', key: Specialty.color },
  { label: 'Department Link', key: Specialty.department },
  {
    label: 'ID',
    key: Specialty.id,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sortNumerical(a[Specialty.id]!, b[Specialty.id]!),
      reverse: (a: TableType, b: TableType) =>
        sortNumerical(b[Specialty.id]!, a[Specialty.id]!)
    }
  }
];

const sort = (a: TableType, b: TableType, sortField: Specialty) =>
  (b as IProviderSpecialty)[sortField]!.toString().toLowerCase() >
  (a as IProviderSpecialty)[sortField]!.toString().toLowerCase()
    ? 1
    : -1;
