import React, { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Row } from 'antd';
import { FormItem } from 'views/containers/form';
import { CLINIC_PROVIDERS, PAYMENT_METHODS } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { RadioGroup } from 'api/sharedComponents/reactHookFormComponents/radio';

const clinicPreferences = mapToOption([
  CLINIC_PROVIDERS[0],
  CLINIC_PROVIDERS[2]
]);
const paymentMethods = mapToOption(PAYMENT_METHODS);
function AppointmentOther({ disabledFormFields }) {
  const { control, setValue } = useFormContext();
  const { isClinical } = useWatch({
    control,
    name: ['isClinical']
  });

  const onClinicPreferenceChange = useCallback(
    ({ target }) => {
      setValue('provider', null);
      setValue('clinicId', null);
      setValue('selectedSlot', null);
    },
    [setValue]
  );

  return (
    <Row type="flex" justify="start" align="bottom" gutter={16}>
      <Controller
        name="clinicPreference"
        control={control}
        render={fieldProps => (
          <FormItem
            className="schedule-type"
            label="Clinic Providers"
            xs={16}
            sm={10}
          >
            <RadioGroup
              defaultValue={clinicPreferences[0].value}
              options={clinicPreferences}
              customOnChangeHandler={onClinicPreferenceChange}
              field={fieldProps}
              disabled={disabledFormFields.clinicPreference}
            />
          </FormItem>
        )}
      />
      {isClinical === false && (
        <FormItem
          className="schedule-type"
          label="Payment Method"
          xs={16}
          sm={10}
        >
          <Controller
            name="paymentMethod"
            control={control}
            render={fieldProps => (
              <RadioGroup
                defaultValue={paymentMethods[0].value}
                name="paymentMethod"
                options={paymentMethods}
                field={fieldProps}
                disabled={disabledFormFields.paymentMethods}
              />
            )}
          />
        </FormItem>
      )}
    </Row>
  );
}

export default React.memo(AppointmentOther);
