import React, { useState, useCallback } from 'react';
import ModalSemantic from 'components/modalSemantic';
import EditRoomForm from 'pages/rooms/form';
import { Icon } from 'semantic-ui-react';
import { IRoom } from 'model/v2';
import { NEW_ROOM } from 'utils/constants/init';

interface IProps {
  clinicId?: number;
  onAddRoom: (room: IRoom) => void;
}
const AddRoomIcon: React.FC<IProps> = ({ clinicId, onAddRoom }: IProps) => {
  const [openForm, setOpenForm] = useState(false);

  const onAddForm = useCallback(() => {
    setOpenForm(true);
  }, [setOpenForm]);

  const onCloseForm = useCallback(() => {
    setOpenForm(false);
  }, [setOpenForm]);

  return (
    <>
      <label className="label">Rooms</label>
      <ModalSemantic
        title="Add Room"
        open={openForm}
        trigger={<Icon link name={'plus'} onClick={onAddForm} />}
        content={
          <EditRoomForm
            room={NEW_ROOM(clinicId)}
            onCloseForm={onCloseForm}
            onAddRoom={onAddRoom}
          />
        }
        onClose={onCloseForm}
      />
    </>
  );
};
export default React.memo(AddRoomIcon);
