import { IEditProviderForm, IProvider } from 'model/v2';

export const mapProviderSubmittedForm = (
  data: IEditProviderForm,
  provider?: IProvider,
  msId?: string
) => {
  let assistantsData: any = data.assistantsInput;
  if (assistantsData) {
    const uniqueNames = [
      ...new Set(
        assistantsData
          .filter((obj: { name: any }) => obj.name !== undefined)
          .map((obj: { name: any }) => obj.name)
      )
    ];

    assistantsData = uniqueNames as number[];
  }
  let PetAllergiesOrderValue = data?.petAllergies?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const updatedProvider = {
    ...provider,
    ...data,
    petAllergies: data.petAllergies ? PetAllergiesOrderValue : null,
    appointmentTypes: data.appointmentTypes?.map(item => ({ id: item })),
    msId: provider?.msId || msId,
    isObservable: data?.isObservable ? true : false,
    assistantsInput: assistantsData,
    allowSelfScheduling: data?.allowSelfScheduling ? true : false,
    corticaPrograms: data.corticaPrograms?.map(item => ({ id: item }))
  };
  return provider
    ? {
        ...updatedProvider,
        petAllergies: data.petAllergies ? PetAllergiesOrderValue : null,
        id: provider.id
      }
    : updatedProvider;
};

export const mapArrayOfIdsToObject = (clinicsIds: number[]) => {
  return clinicsIds?.map((clinicId: number) => ({
    id: clinicId
  }));
};

// get all clinic ids be selected
export const getSelectedClinicsIds = (
  selectedClinicsNames: string[],
  nameToIdMap: Map<string, number>
) => {
  const result: number[] = [];
  for (const clinic of selectedClinicsNames) {
    const id = nameToIdMap.get(clinic);
    if (id) result.push(id);
  }
  return result;
};
