import React from 'react';
import { IEvent, IFormEvent, IFullAppointmentType } from 'model/v2';
interface IProps {
  event: IFormEvent;
  appTypesMap: Map<number, IFullAppointmentType>;
  adjacentEvent?: IEvent;
  apptId: number;
}
const SubstitueAppointmentResults: React.FC<IProps> = ({
  event,
  appTypesMap,
  adjacentEvent,
  apptId
}) => {
  if (adjacentEvent) {
    apptId = adjacentEvent?.appointmentType?.id!;
  }
  const appointmentType = appTypesMap.get(apptId);
  return (
    <>
      <span>
        Appointment Type:{' '}
        {appointmentType?.title
          ? appointmentType?.title
          : event?.appointmentType?.title}
      </span>
    </>
  );
};
export default React.memo(SubstitueAppointmentResults);
