import React from 'react';

import {
  Checkbox as LibCheckbox,
  CheckboxGroup as LibCheckboxGroup
} from 'lib/ui';
import { ErrorMessage } from 'formik';

const Checkbox = props => {
  const { name, field, onChange, ...restProps } = props;
  const onChangeValue = value => {
    // because Formik expects normal Event with normal target, not single value
    const event = {
      target: {
        name: field.name,
        value: value.target.checked
      }
    };
    field.onChange(event);
  };
  return (
    <>
      <LibCheckbox onChange={onChangeValue} {...restProps} />
      <ErrorMessage name={name} className="errorMessage" />
    </>
  );
};

const CheckboxGroup = props => {
  const { name, field, onChange, children, ...restProps } = props;
  const onChangeValue = value => {
    // because Formik expects normal Event with normal target, not single value
    const event = {
      target: {
        name: field.name,
        value: value
      }
    };
    field.onChange(event);
  };
  return (
    <>
      <LibCheckboxGroup onChange={onChangeValue} {...restProps}>
        {children}
      </LibCheckboxGroup>
      <ErrorMessage name={name} className="errorMessage" />
    </>
  );
};

export default Checkbox;
export { Checkbox, CheckboxGroup };
