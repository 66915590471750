import { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { GET_CLIENT_ADDRESSES } from 'api/graphql/v2/queries/Clients';
import { mapToAddressOption } from 'utils/mappers/form';
import { alphabeticalSort } from 'utils/sort';
import { SAVE_CLIENT_ADDRESS } from 'api/graphql/v2/mutations/Client';

export const useClientAddressFetcher = clientID => {
  const [addresses, setAddresses] = useState([]);
  const [addressesOptions, setAddressesOptions] = useState([]);
  const { loading, error } = useQuery(GET_CLIENT_ADDRESSES, {
    variables: {
      clientId: Number(clientID)
    },
    skip: !clientID,
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        const result = data?.clientAddresses || [];
        setAddresses(result);
        setAddressesOptions(
          mapToAddressOption(result).sort((a, b) =>
            alphabeticalSort(a.label, b.label)
          )
        );
      }
    }
  });

  return {
    clientAddresses: addresses,
    clientAddressesOptions: addressesOptions,
    clientAddressesLoading: loading,
    clientAddressesError: error
  };
};

export const useSaveAddress = clientID => {
  const useSaved = useMutation(SAVE_CLIENT_ADDRESS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CLIENT_ADDRESSES,
        variables: {
          clientId: clientID
        }
      }
    ]
  });

  return useSaved;
};
