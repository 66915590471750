import React, { useState, useCallback } from 'react';
import Room from './Room';
import { Grid } from 'semantic-ui-react';
import { ContentWrapper, AddIconWrapper } from './style';
import AddRoomIcon from 'pages/clinics/form/AddRoomIcon';
import { IClinic, IRoom } from 'model/v2';
import { useHistory } from 'react-router';

interface IProps {
  clinic?: IClinic;
}

const RoomsList: React.FC<IProps> = ({ clinic }: IProps) => {
  const history = useHistory();

  const [rooms, setRooms] = useState<IRoom[]>(clinic?.rooms || []);
  const onAddRoom = useCallback(
    (room: IRoom) => {
      const old = rooms.find(x => x.id === room.id);
      if (!old || !room.id) {
        rooms.splice(0, 0, { ...room });
        setRooms([...rooms]);
      } else setRooms(rooms.map(obj => (obj.id === room.id ? room : obj)));
      history.replace({
        ...history.location,
        state: {
          clinic: {
            ...clinic,
            rooms
          }
        }
      });
    },
    [rooms, history, clinic]
  );

  return (
    <ContentWrapper>
      <Grid>
        <Grid.Row>
          <AddIconWrapper>
            <AddRoomIcon clinicId={clinic?.id} onAddRoom={onAddRoom} />
          </AddIconWrapper>
        </Grid.Row>
        <Grid>
          {rooms.map((room, index) => (
            <Room
              key={`${index}`}
              room={{
                ...room,
                clinic: {
                  id: clinic?.id
                }
              }}
              onAddRoom={onAddRoom}
            />
          ))}
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
export default React.memo(RoomsList);
