import React, { Dispatch, SetStateAction } from 'react';
import { HeaderWrapperDT } from './Style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCopy } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
import { IClinic, times } from 'model/v2';
import { useFormContext } from 'react-hook-form';
const momentTz = require('moment-timezone');
interface IProps {
  result: any;
  setCount?: Dispatch<SetStateAction<number>>;
  count?: number;
  providerTime: times;
  selectedIndex: number;
  index: number;
  isMakeupSession?: boolean;
  locationInfo?: string;
}
const SingleResultHeader: React.FC<IProps> = ({
  result,
  setCount,
  count,
  providerTime,
  selectedIndex,
  index,
  isMakeupSession,
  locationInfo
}: IProps) => {
  const { watch } = useFormContext();
  const [isCopiedResultCard, setIsCopiedResultCard] = React.useState<
    number | null
  >(null);
  const isInClinic = watch(`result.isInClinic`);
  const isOffsite = watch(`result.isOffsite`);
  const isTelehealth = watch(`result.isTelehealth`);
  const clinicId = watch('result.clinic.id');
  const telehealthLink = watch('result.telehealthLink');
  const address = watch('result.address')
    ? watch('result.address')
    : result?.address;
  const addressInfo = `${
    address?.addressLine1 ? address?.addressLine1 + ', ' : ''
  } ${address?.addressLine2 ? address?.addressLine2 + ', ' : ''} ${
    address?.city ? address?.city + ', ' : ''
  } ${address?.state ? address?.state + ', ' : ''} ${address?.zipCode || ''}`;
  const locationCategory = watch('location-category')
    ? watch('location-category')
    : result.locationCategory;
  let clinicName = 'N/A';
  result.inPersonClinics?.map((clinic: IClinic) => {
    if (clinicId === clinic.id) clinicName = clinic.name!;
  });
  let startTime = momentTz
    .tz(providerTime.startTime, result.provider.clinic.timezone)
    .format('hh:mm A');
  let endTime = momentTz
    .tz(providerTime.endTime, result.provider.clinic.timezone)
    .format('hh:mm A');
  if (!isMakeupSession) {
    startTime = providerTime.startTime;
    endTime = providerTime.endTime;
  }
  const appointmentDay =
    moment(result.times[0].startTime).format('dddd') +
    ' ' +
    moment(result.times[0].startTime).format('LL');
  let locationName = isInClinic
    ? `${clinicName}, In-clinic`
    : isTelehealth
    ? `${telehealthLink ? telehealthLink : `N/A`}, Telehealth`
    : isOffsite
    ? `${addressInfo && address ? addressInfo : `N/A`}, ${
        locationCategory ? locationCategory : `N/A`
      }, Off-Site`
    : ``;
  if (locationInfo) locationName = locationInfo;
  const copyTextVal = `<p>Make-up session ${count}:</p>
    <ul>
    <li>Day: ${appointmentDay}</li>
    <li>Time: ${startTime} - ${endTime}</li>
    <li>Provider Name: ${result.provider.name}, ${result.provider.speciality
    .abbreviation +
    '(' +
    result.provider.clinic.abbreviation +
    ')'}</li>
    <li>Location: ${locationName}</li>
    </ul>`;

  return (
    <>
      <HeaderWrapperDT>
        <div className="flex-r-container">
          <div className="icon-container">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div style={{ width: '80%' }}>
            <div className="max-w-container">
              <h4>{result.provider.name},</h4>
            </div>
            <div className="clinic-abbr">
              <h4>
                {result.provider.speciality.abbreviation +
                  '(' +
                  result.provider.clinic.abbreviation +
                  ')'}
              </h4>
            </div>
          </div>

          <div className="icon-container">
            <CopyToClipboard
              text={copyTextVal}
              options={{ format: 'text/html' }}
            >
              <FontAwesomeIcon
                icon={faCopy}
                style={{
                  cursor: selectedIndex === index ? 'pointer' : 'inherit',
                  color: isCopiedResultCard !== index ? 'grey' : '#6f4bf1'
                }}
                onClick={() => {
                  setIsCopiedResultCard(index);
                  setCount?.(count! + 1);
                  setTimeout(() => {
                    setIsCopiedResultCard(null);
                  }, 2000);
                }}
              />
            </CopyToClipboard>
          </div>
        </div>
      </HeaderWrapperDT>
    </>
  );
};
export default React.memo(SingleResultHeader);
