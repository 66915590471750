import * as Yup from 'yup';
import { PHONE_REGEX, ZIP_REGEX, SSN_REGEX } from 'utils/constants/regex';
import {
  TEST_FIFTEEN_MIN_INTERVAL,
  TEST_IF_SAME
} from 'utils/validators/availabilityTemplate/editAvailabilityBlock';
import moment from 'moment';

Yup.addMethod(Yup.object, 'unique', function(message, propertyName) {
  return this.test('unique property across array', message, function(value) {
    if (!value || !value[propertyName]) {
      return true;
    }
    if (
      this.parent.some(
        v =>
          v[propertyName]?.toLowerCase().trim() ===
            value[propertyName]?.toLowerCase().trim() && v !== value
      )
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`
      });
    }

    return true;
  });
});

export const CLIENT_FORM_SCHEMA = Yup.object().shape({
  //Provider registration date
  isActive: Yup.boolean().nullable(),
  clinic: Yup.object()
    .shape({
      id: Yup.string()
        .required('Clinic is required')
        .nullable()
    })
    .required('Clinic is required')
    .nullable(),
  usualProvider: Yup.object()
    .shape({
      id: Yup.string()
        .required('Usual provider is required')
        .nullable()
    })
    .required('Usual provider is required')
    .nullable(),

  registrationDate: Yup.date().nullable(),
  lastName: Yup.string().required('Last name is required'),
  firstName: Yup.string().required('First name is required'),
  firstNameUsed: Yup.string().nullable(),

  profile: Yup.object()
    .shape({
      dob: Yup.date()
        .required('Date of birth is required')
        .nullable()
        .test(
          'Date of birth limit',
          'Date of birth cannot be in the Future',
          function(value) {
            return (
              moment
                .utc()
                .endOf('day')
                .diff(value, 'days', true) >= 0
            );
          }
        ),
      legalSex: Yup.string()
        .required('Legal sex is required')
        .nullable(),
      gender: Yup.string()
        .required('Gender is required')
        .nullable(),
      cellPhone: Yup.string()
        .matches(PHONE_REGEX, 'Invalid phone number.')
        .required('Cell phone is required')
        .nullable(),
      homePhone: Yup.string()
        .matches(PHONE_REGEX || '', 'Invalid phone number.')
        .nullable()
        .transform((_, val) => (val === Number(val) ? val : null)),
      phonePreference: Yup.string().nullable(),
      consentToText: Yup.string()
        .required('Consent to text is required')
        .nullable(),
      personalEmail: Yup.string()
        .email('Invalid email address.')
        .required('Personal email is required')
        .nullable(),
      //Aditional information
      motherName: Yup.string().nullable(),
      motherPhone: Yup.string()
        .matches(PHONE_REGEX, 'Invalid phone number.')
        .nullable()
        .transform((_, val) => (val === Number(val) ? val : null)),
      motherEmail: Yup.string()
        .email('Invalid email address.')
        .nullable(),

      fatherName: Yup.string().nullable(),
      fatherPhone: Yup.string()
        .matches(PHONE_REGEX, 'Invalid phone number.')
        .nullable()
        .transform((_, val) => (val === Number(val) ? val : null)),
      fatherEmail: Yup.string()
        .email('Invalid email address.')
        .nullable(),

      otherAuthorizedGuardians: Yup.string().nullable(),
      consentToEmailPHI: Yup.string().nullable(),
      aboutCortica: Yup.string().nullable(),
      // privacy
      consentToCall: Yup.string()
        .required('Consent to call is required')
        .nullable(),
      medicalHistoryAuthority: Yup.string().nullable(),
      // insurance
      insuranceProvider: Yup.string().nullable(),
      memberNumber: Yup.string().nullable(),
      groupNumber: Yup.string().nullable(),
      subscriberLastName: Yup.string().nullable(),
      subscriberFirstName: Yup.string().nullable(),
      subscriberDOB: Yup.date().nullable(),
      subscriberSSN: Yup.string()
        .transform(value => (value === '' ? null : value))
        .matches(SSN_REGEX, 'Invalid SSN.')
        .nullable(),
      physicianPhoneNumber: Yup.string()
        .matches(PHONE_REGEX, 'Invalid phone number.')
        .nullable()
        .transform((_, val) => (val === Number(val) ? val : null)),
      patientRelationshipToSubscriber: Yup.string().nullable(),
      //legal guardian
      legalGuardianLastName: Yup.string()
        .required('Legal guardian last name is required')
        .nullable(),
      legalGuardianFirstName: Yup.string()
        .required('Legal guardian first name is required')
        .nullable(),
      // profile
      phenoType: Yup.string().nullable(),
      behavior: Yup.string().nullable(),
      cognition: Yup.string().nullable(),
      communication: Yup.string().nullable(),
      sensorimotor: Yup.string().nullable()
    })
    .nullable()
    .required('Profile is required'),

  addresses: Yup.array()
    .of(
      Yup.object()
        .shape({
          addressLine1: Yup.string()
            .trim()
            .required('Address line 1 is required')
            .nullable(),
          addressLine2: Yup.string().nullable(),
          city: Yup.string()
            .trim()
            .required('City is required')
            .nullable(),
          name: Yup.string()
            .required('Name is required')
            .trim()
            .nullable(),
          state: Yup.string()
            .required('State is required')
            .nullable(),
          zipCode: Yup.string()
            .matches(ZIP_REGEX, 'Invalid ZIP code.')
            .required('Zip code is required')
            .nullable()
        })
        .unique('Error, this name already exists', 'name')
        .required('Address')
    )
    .required('Addresses')
});

export const CLIENT_EDIT_AVAILABILITY_SCHEMA = Yup.object().shape({
  blockedEvents: Yup.array().of(
    Yup.object().shape({
      startDate: Yup.string().required('Start Date is required'),
      endDate: Yup.string().required('End Date is required')
    })
  ),
  statusOption: Yup.string().required('Availability Status is required')
});

export const CLIENT_EDIT_AVAILABILITY_BLOCK = Yup.object().shape({
  start: Yup.date().required('Start time is required'),
  end: Yup.date()
    .min(Yup.ref('start'), "End time can't be before start time")
    .required('End time is required')
    .test(
      'isValidEndDate',
      'difference between end and time should be equal or greater than 15 mins',
      function(end) {
        const { start } = this.parent;
        return TEST_FIFTEEN_MIN_INTERVAL(start, end);
      }
    ),
  location: Yup.number().required('You should provide location'),
  recurringDays: Yup.array()
    .of(Yup.number())
    .required('You should select a day')
});

export const WAIT_LIST = Yup.object().shape({
  start: Yup.date().required('Start time is required'),
  end: Yup.date()
    .min(Yup.ref('start'), "End time can't be before start time")
    .required('End time is required'),
  location: Yup.array().of(Yup.number())
  // recurringDays: Yup.array()
  //   .of(Yup.number())
  // .required('You should select a day')
});
