import styled from 'styled-components';

interface Props {
  sideBarWidth?: string;
  displayScroll?: boolean;
}
export const SideBarWrapper = styled.div<Props>`
  height: 100%;
  .ui.grid {
    height: 100%;
  }
  .ui.basic.segment {
    padding: 0;
  }
  .ui.segment {
    border: none;
  }
  .ui.grid {
    margin: 0;
  }
  .column {
    padding: 0 !important;
    height: 100%;
  }
  .side-bar {
    width: ${props =>
      props.sideBarWidth ? `${props.sideBarWidth} !important` : ''};
  }
  .ui.visible.push.sidebar {
    overflow-x: hidden;
  }
  .ui.visible.wide.left.sidebar ~ .fixed,
  .ui.visible.wide.left.sidebar ~ .pusher {
    transform: translate3d(
      ${props => (props.sideBarWidth ? `${props.sideBarWidth}` : '')},
      0,
      0
    ) !important;
  }
  .pusher {
    height: 100%;
    .push-content_wrapper {
      height: 100%;
      .isVisible-pusher {
        width: calc(
          100% -
            ${props => (props.sideBarWidth ? `${props.sideBarWidth}` : '350px')}
        );
        height: 100%;
      }
      .isInvisible-pusher {
        height: 100%;
      }
      .page-content_wrapper {
        height: 100%;
        .page-content_container {
          height: 100%;
        }
      }
    }
  }
`;

export const BottomSideBarWrapper = styled.div<Props>`
  height: 100% !important;
  .pushable > .pusher {
    overflow: unset;
  }
  .ui.visible.bottom.overlay.sidebar {
    height: 85% !important;
    width: 100% !important;
  }
  overflow-y: ${props => !props.displayScroll && 'hidden'};

  .ui.inverted.segment,
  .ui.primary.inverted.segment {
    background: #ffffff;
    color: #3e5766;
  }
  .ui.bottom.sidebar,
  .ui.top.sidebar {
    padding: 0px;
  }
  .scrollable {
    overflow-y: overlay;
    overflow-x: hidden;
    flex: 1;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
        inset 1px 1px 0px rgba(0, 0, 0, 0);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  }
`;
