import React from 'react';
import { FormItemWrap } from './style';
interface Props {
  label?: string;
  children: any;
  optional?: boolean;
  className?: string;
  WrapperClassName?: string;
}
const FormItem: React.FC<Props> = ({
  label,
  children,
  optional,
  className,
  WrapperClassName
}: Props) => {
  return (
    <FormItemWrap className={WrapperClassName}>
      <div className={`top-wrap ${className}`}>
        {label && <label style={{ paddingBottom: '5px' }}>{label}</label>}
        {optional && <span className="optional">optional</span>}{' '}
      </div>
      {children}
    </FormItemWrap>
  );
};
export default FormItem;
