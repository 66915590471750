import { Input } from 'api/sharedComponents/reactHookFormComponents';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import React, { useCallback } from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldName
} from 'react-hook-form';
import { Grid } from 'semantic-ui-react';
import { TableType } from 'model/tableType';
import { IAppointmentType } from 'model/appointment';
import ColorPicker from '../../../components/ui/colorPicker';
import { IProviderSpecialty } from 'model/v2';
interface Props {
  title: string;
  name: string;
  control: Control<TableType>;
  setValue: (
    name: FieldName<IAppointmentType | IProviderSpecialty>,
    value: unknown,
    config?:
      | Partial<{ shouldValidate: boolean; shouldDirty: boolean }>
      | undefined
  ) => void;
  trigger: (
    name?:
      | FieldName<IAppointmentType | IAppointmentType[] | IProviderSpecialty>
      | undefined
  ) => Promise<boolean>;
  errors: DeepMap<TableType, FieldError>;
}
const ColorPickerInput: React.FC<Props> = ({
  title,
  name,
  control,
  setValue,
  trigger,
  errors
}: Props) => {
  const handleColorChange = useCallback(
    (field, color) => {
      setValue(
        field as FieldName<IAppointmentType | IProviderSpecialty>,
        color.hex,
        {
          shouldDirty: true
        }
      );
      trigger(field);
    },
    [setValue, trigger]
  );
  return (
    <>
      <div className="color-picker">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={11}>
              <FormItem label={title}>
                <Controller
                  name={name}
                  control={control}
                  render={props => (
                    <Input
                      errors={errors}
                      placeholder="Input Number"
                      field={props}
                    ></Input>
                  )}
                />
              </FormItem>
            </Grid.Column>
            <Grid.Column width={2}>
              <ColorPicker
                handleColorChange={color => handleColorChange(name, color)}
                name={name}
                control={control}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};
export default ColorPickerInput;
