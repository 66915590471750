import React, { Dispatch } from 'react';
import TitleView from './TitleView';
import TimeView from 'pages/MainCalendarPage/AppointmentView/TimeView';
import AttendeesView from './AttendeesView';
import LocationView from 'pages/MainCalendarPage/AppointmentView/LocationView';
import AuditView from 'pages/MainCalendarPage/AppointmentView/AuditView';
import { AppointmentViewWrapper } from './style';
import { IEvent, SidebarState } from 'model/v2';

interface Props {
  event: IEvent;
  observersEvent: any;
  openSidebar: (sidebarState: SidebarState) => void;
  setIsAddObservationMode: Dispatch<React.SetStateAction<boolean>>;
}
const ObserverView: React.FC<Props> = ({
  event,
  observersEvent,
  openSidebar,
  setIsAddObservationMode
}: Props) => {
  return (
    <AppointmentViewWrapper>
      <>
        {event.leadEvent && observersEvent && (
          <div className="scrollable">
            <TitleView event={event} />
            <TimeView event={event} isObservation={true} />
            <AttendeesView
              LeadProvider={event.leadEvent.provider}
              leadEvent={observersEvent.event}
              provider={event.provider}
              openSidebar={openSidebar}
              setIsAddObservationMode={setIsAddObservationMode}
            />
            {event.appointmentType?.eventType?.name === 'ABA' ? (
              <>
                <LocationView event={event} isObservation={true} />
              </>
            ) : (
              <>
                <LocationView event={event.leadEvent} isObservation={true} />
              </>
            )}
            <AuditView event={event} isObservation={true} />
          </div>
        )}
      </>
    </AppointmentViewWrapper>
  );
};
export default React.memo(ObserverView);
