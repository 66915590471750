import React, { useCallback, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import ClinicInfo from './ClinicInfo';
import { Grid } from 'semantic-ui-react';
import { ContentWrapper } from './style';
import HoursInfo from './HoursInfo';
import Header from '../../../components/header';
import { CLINIC_FORM_SCHEMA } from 'utils/validators/configurations/clinicForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from 'react-apollo';
import Content from 'views/components/ui/content';
import { IClinicForm } from 'model/v2';
import { GET_CLINIC_LIST } from 'api/graphql/v2/queries/Clinics';
import {
  formatClinicFormToInput,
  formatToHoursForm
} from 'utils/format/clinic';
import RoomsList from 'pages/rooms/list';
import { ADD_NEW_CLINIC } from 'api/graphql/v2/mutations/Clinics';

export interface IClinicFormLocationStateProps {
  title: string;
  clinic?: IClinicForm;
}

const AddClinic: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    clinic,
    title
  }: IClinicFormLocationStateProps = location.state as IClinicFormLocationStateProps;
  const [hoursWarning, sethoursWarning] = useState(false);
  const [addClinic] = useMutation(ADD_NEW_CLINIC);

  const {
    data: clinicsData,
    error: errorLoadingClinics,
    loading: loadingClinics
  } = useQuery(GET_CLINIC_LIST);

  const methods = useForm<IClinicForm>({
    defaultValues: {
      ...clinic,
      businessHours: formatToHoursForm(
        clinic?.clinicBusinessHours || [],
        clinic?.id
      )
    },
    resolver: yupResolver(
      CLINIC_FORM_SCHEMA(clinicsData?.clinics || [], clinic != null, clinic?.id)
    )
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = methods;

  const onSubmit = handleSubmit(async (data: IClinicForm) => {
    const formattedInput = formatClinicFormToInput(data, clinic?.rooms);
    if (formattedInput.clinicBusinessHours.length === 0) {
      sethoursWarning(true);
    } else {
      await addClinic({
        variables: {
          clinic: formattedInput
        }
      }).then(res => {
        history.replace({
          ...history.location,
          state: {
            clinic: {
              ...res.data?.addClinic
            }
          }
        });
        history.goBack();
      });
    }
  });

  const onGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <Header
        title={title}
        onSubmit={onSubmit}
        onCancel={onGoBack}
        buttonText="Save Clinic"
        disabled={isSubmitting || !isDirty}
        loading={isSubmitting}
      />
      <Content
        data={clinicsData}
        error={errorLoadingClinics}
        loading={loadingClinics}
      >
        {() => (
          <>
            <ContentWrapper>
              <FormProvider {...methods}>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <label className={'label'}>{'Clinic Info'}</label>
                    </Grid.Column>
                    <Grid.Column>
                      <label className={'label'}>{'Clinic Hours'}</label>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <ClinicInfo
                        editMode={clinic != null}
                        clinic={clinic}
                        clinics={clinicsData.clinics}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <HoursInfo hours={clinic?.clinicBusinessHours} />
                      {hoursWarning && (
                        <label className={'warning'}>
                          {'You should select at least one clinic hours'}
                        </label>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </FormProvider>
            </ContentWrapper>
            <RoomsList clinic={clinic} />
          </>
        )}
      </Content>
    </>
  );
};

export default React.memo(AddClinic);
