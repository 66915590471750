import { isPhantomEvent } from 'helpers/calendarHelper';
import { ICalendarEvent, IPhantomEvent, Status } from 'model/calendar/events';
import {
  selectedItemsToCancelVar,
  selectedItemToViewVar
} from 'utils/cache/calendar';
import { EVENT_STATUS_MIN_SUCCESS_RANGE } from 'utils/constants/planner';

export const isItemSelectedForCancellation = (itemId: number) => {
  const selectedItemsForCancel = selectedItemsToCancelVar();
  return selectedItemsForCancel.has(itemId);
};

export const isItemSelectedToView = (item: ICalendarEvent) => {
  const selectedItemToView = selectedItemToViewVar();
  return selectedItemToView === item.calendarID;
};

export const isCancelledEvent = (event: ICalendarEvent) => {
  return event.status === Status.canceled;
};

export const isDeletedEvent = (event: ICalendarEvent) => {
  return event.isDelete;
};
export const isMakeupSessionFound = (event: ICalendarEvent) => {
  if (event.status === 'canceled') {
    return !!event?.room && event?.room?.id !== null;
  }
  return false;
};

export const isPendingToSyncEvent = (downStreamStatus?: number) => {
  return downStreamStatus
    ? downStreamStatus < EVENT_STATUS_MIN_SUCCESS_RANGE
    : false;
};

export const isItemContextMenuDisabled = (event: ICalendarEvent) => {
  return (
    isDeletedEvent(event) ||
    isMakeupSessionFound(event) ||
    isPhantomEvent(event as IPhantomEvent) ||
    isPendingToSyncEvent(event.crStatus) ||
    isPendingToSyncEvent(event.athenaStatus)
  );
};
