import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import TimeSlot from './TimeSlot';
import { TimeSlots } from 'utils/constants/lists';
import { ClientDayAvailabilityTimeSlot } from 'model/v2';
import { CloseOutlined } from '@ant-design/icons';
import CustomTimeSlot from './CustomTimeSlot';

interface IProps {
  day: {
    text: string;
    value: number;
  };
  handleClientAvailabilityDayFilter: Function;
  selectedTimes: ClientDayAvailabilityTimeSlot[];
  ClientAvailabilityDayFilter: {
    dayIndex: number;
    preferredTimes: ClientDayAvailabilityTimeSlot[];
  }[];
}

const DayFilterRow: React.FC<IProps> = ({
  day,
  handleClientAvailabilityDayFilter,
  selectedTimes
}: IProps) => {
  const toggleTimeSlot = (preferredTimes: ClientDayAvailabilityTimeSlot) => {
    handleClientAvailabilityDayFilter({ dayIndex: day.value, preferredTimes });
  };
  const [customEdit, setCustomEdit] = React.useState<boolean>(false);

  const closeCustomEdit = () => {
    setCustomEdit(false);
    const timeSlot = {
      id: 1,
      from: '',
      to: ''
    };
    toggleTimeSlot(timeSlot);
  };

  const openCustomEdit = () => {
    setCustomEdit(true);
    selectedTimes.forEach(time => {
      const timeSlot = {
        id: time.id,
        from: '',
        to: ''
      };
      toggleTimeSlot(timeSlot);
    });
  };

  return (
    <>
      <Grid.Row className="day-row">
        <Grid.Column style={{ width: '22%' }}>
          <div>{day.text}</div>
        </Grid.Column>

        {customEdit ? (
          <CustomTimeSlot
            key={day.value}
            toggleTimeSlot={(timeSlot: ClientDayAvailabilityTimeSlot) =>
              toggleTimeSlot(timeSlot)
            }
          />
        ) : (
          TimeSlots.map(timeSlot => {
            return (
              <Grid.Column width={4}>
                <TimeSlot
                  timeSlot={timeSlot}
                  toggleTimeSlot={(timeSlot: ClientDayAvailabilityTimeSlot) =>
                    toggleTimeSlot(timeSlot)
                  }
                  selectedTimeSlots={selectedTimes}
                />{' '}
              </Grid.Column>
            );
          })
        )}
        <Grid.Column style={{ width: '2%' }}>
          {customEdit ? (
            <CloseOutlined onClick={closeCustomEdit} />
          ) : (
            <Icon
              name="pencil alternate"
              inverted
              className="edit-icon"
              onClick={openCustomEdit}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
export default React.memo(DayFilterRow);
