import moment from 'antd/node_modules/moment';
import { MINUTES_INTERVAL_ERROR } from 'utils/constants/errors';
import { checkMinutesInterval } from 'utils/format/time';
import * as yup from 'yup';

export const ADD_AVAILABILITY_TEMPLATE = (isToShowDailyMax: boolean) =>
  yup.object().shape({
    department: yup.string().required('This field is required'),
    speciality: yup.number().required('This field is required'),
    templateType: yup.number(),
    employment: yup.number(),
    dailyMaxHours: isToShowDailyMax
      ? yup.array().of(
          yup.object().shape({
            value: yup
              .number()
              .transform(value => (isNaN(value) ? 0 : value))
              .test(
                'validRange',
                'value should be positive less than 24',
                value => {
                  if (value !== undefined && value >= 0 && value <= 24) {
                    return true;
                  }
                  return false;
                }
              )
              .test(
                'test is zero and have start time',
                'Enter Start Time or Remove Daily Max',
                function(value) {
                  const idx = this.path.split('[')[1].split(']')[0];
                  const startTime = (this.options as any).from[1].value.Day[idx]
                    .startTime;
                  if (
                    value !== undefined &&
                    value > 0 &&
                    (!moment(startTime).isValid() || startTime === undefined)
                  )
                    return false;
                  return true;
                }
              )
              .test(
                'test is not zero and not have start time',
                'Enter Daily Max or Remove Start Time',
                function(value) {
                  const idx = this.path.split('[')[1].split(']')[0];
                  const startTime = (this.options as any).from[1].value.Day[idx]
                    .startTime;
                  if (
                    value !== undefined &&
                    value == 0 &&
                    moment(startTime).isValid() &&
                    startTime !== undefined
                  )
                    return false;
                  return true;
                }
              )
          })
        )
      : yup.mixed().nullable(),
    Day: yup.array().of(
      yup
        .object()
        .shape({
          startTime: yup
            .string()
            .transform(value => (value === null ? undefined : value))
            .test('valid minutes', MINUTES_INTERVAL_ERROR, function(value) {
              if (value === undefined) return true;
              return checkMinutesInterval(value);
            }),
          length: yup
            .number()
            .min(0, 'Minimum value is 0')
            .max(14, 'Maximum value is 14')
            .transform(value => (isNaN(value) ? 0 : value))
        })
        .test('missingStart', 'Enter Start Time or Remove Length', value => {
          if (
            value.length !== undefined &&
            value.length > 0 &&
            value.length < 14
          ) {
            return value.startTime === undefined ||
              !moment(value.startTime).isValid()
              ? false
              : true;
          }
          return true;
        })
        .test('missingLength', 'Enter Length or remove Start Time', value => {
          if (
            value.startTime !== undefined &&
            moment(value.startTime).isValid() &&
            (value.length === undefined || value.length === 0)
          ) {
            return false;
          }
          return true;
        })
    )
  });
