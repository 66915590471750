import React, { useState } from 'react';
import * as linkify from 'linkifyjs';
import { DEFAULT_PHOTO_URL } from 'utils/constants/default';
import { GET_STAFF_PHOTO } from 'api/graphql/queries/staff';
import { useQuery } from '@apollo/react-hooks';

function ClientSearchProfilePicture({ img, alt, userId, className }) {
  const [image, setImage] = useState('');
  const alternative = alt || '';
  const classN = className || 'avatar';

  useQuery(GET_STAFF_PHOTO, {
    variables: { id: userId },
    skip: !userId,
    onCompleted: data => {
      data.userPhoto && adjustDefaultImage(data.userPhoto);
    }
  });

  const adjustDefaultImage = img => {
    setImage(!linkify.test(img) ? `data:image/jpeg;base64,${img}` : img);
  };

  if (!image) {
    adjustDefaultImage(img || DEFAULT_PHOTO_URL);
  }

  return <img src={image} alt={alternative} className={classN} />;
}
export default React.memo(ClientSearchProfilePicture);
