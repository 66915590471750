import moment from 'moment';

interface AvailabilitySlotProps {
  event: any;
  locations: { id: number; title: string };
}

export const AvailabilitySlot: React.FC<AvailabilitySlotProps> = ({
  event,
  locations
}: any) => {
  const eventStartTime = moment(event.start);
  const eventEndTime = moment(event.end);
  const differenceInMinutes = eventEndTime.diff(eventStartTime, 'minutes');
  let locationTypeArr: string[] = [];
  locations?.clientLocations.map((location: { id: number; title: string }) => {
    if ((location.id & event.location) > 0) {
      locationTypeArr.push(location.title);
    }
  });

  let transformedArray = locationTypeArr.map((element: string) => {
    switch (element) {
      case 'In Clinic':
        return 'In-clinic';
      case 'Offsite':
        return 'Off-Site';
      default:
        return element;
    }
  });
  if (differenceInMinutes < 45 && transformedArray.length > 0)
    transformedArray = ['...'];
  else if (differenceInMinutes < 45 && transformedArray.length <= 0)
    transformedArray = [''];
  else if (differenceInMinutes < 60 && transformedArray.length > 1)
    transformedArray = [`${transformedArray[0]}...`];
  else if (differenceInMinutes < 90 && transformedArray.length > 2)
    transformedArray = [`${transformedArray[0]}`, `${transformedArray[1]}...`];

  return (
    <div className="avail-content-container">
      <div className="avail-label" style={{ display: 'flex' }}>
        <div
          className="availability-icon"
          style={{
            height: '16px',
            width: '16px',
            borderRadius: '50%',
            backgroundColor: event.color,
            marginRight: '8px'
          }}
        ></div>
        {eventStartTime.format('hh:mm a')} - {eventEndTime.format('hh:mm a')}
      </div>
      {transformedArray.map((locationType: any) => {
        return (
          <div className="location-container">
            <span>{locationType}</span>
          </div>
        );
      })}
    </div>
  );
};

export const AvailabilityHeader = ({ label, client }: any) => {
  const labelday = label.label.replace(/[0-9]/g, '');
  const labeldate = label.label.replace(/[A-Za-z]/g, '');
  const displayDay = ['Sun'];
  return (
    <div>
      <div
        className={displayDay.includes(labelday.trim()) ? 'weekendDay' : ''}
        style={{
          textAlign: 'center',
          fontSize: '15px',
          fontWeight: 700,
          color: 'rgba(65, 87, 102, 1)'
        }}
      >
        <u>
          {labelday}
          {''}
          {labeldate}
        </u>
      </div>
      <div className="clinic-abbr">
        <span>
          {displayDay.includes(labelday.trim())
            ? ''
            : client?.clinic?.abbreviation}
        </span>
      </div>
    </div>
  );
};
