import React from 'react';
import { Input } from 'antd';
import { SearchWrapper } from './search.style';

interface Props {
  placeholder: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  otherProps?: any;
}

const SearchForm: React.FC<Props> = ({
  placeholder,
  defaultValue,
  onChange,
  onSearch,
  ...otherProps
}) => {
  const { Search } = Input;
  return (
    <SearchWrapper {...otherProps}>
      <Search
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        onSearch={onSearch}
        enterButton
        allowClear
      />
    </SearchWrapper>
  );
};
export default SearchForm;
