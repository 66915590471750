import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import { styles, rectangleStyle } from './style';

/**
 * @interface IColorCell To render color with its name cell
 * @param color the one need to be shown
 */

interface IColorCell {
  color: string;
}
const ColorCell: React.FC<IColorCell> = (props: IColorCell) => (
  <>
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <label style={styles.color}>{props.color}</label>
        </Grid.Column>
        <Grid.Column>
          <div style={rectangleStyle(props.color)}></div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
);

export default React.memo(ColorCell);
