import gql from 'graphql-tag';

export const addressFragments = {
  addressData: gql`
    fragment AddressData on Address {
      id
      addressLine1
      addressLine2
      state
      city
      name
      zipCode
      country
      isPrimary
      crId
    }
  `
};
