import React from 'react';

import { TableWrapper } from './style';
import { AlternateAvailabilityTemplate } from 'model/v2/availabilityTemplate';
import EditIcon from 'views/components/icons/edit';
import TrashIcon from 'views/components/icons/trash';
import Collapsible from 'components/ui/collapsible';

import moment from 'moment';
interface IProps {
  data?: AlternateAvailabilityTemplate[];
  onEdit: (template: object) => void;
  onDelete: (template: object) => void;
  disableCTA: boolean;
  onDuplicate: (template: object) => void;
  onDefaultEdit: (tempalte: object) => void;
}

const AlternateAvailabilityTable: React.FC<IProps> = ({
  data,
  onEdit,
  onDelete,
  disableCTA = false,
  onDuplicate,
  onDefaultEdit
}: IProps) => {
  return (
    <TableWrapper>
      <table className="availability-templates">
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Frequency</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th className="availability-actions">Edit/Delete</th>
        </tr>
        {data &&
          data.map((template, index) => (
            <tr key={index}>
              <td>{template?.name ? template?.name : 'N/A'}</td>
              <td className="availability-description">
                <Collapsible description={template.description} />
              </td>
              <td>{template?.frequency ? template?.frequency : 'N/A'}</td>
              <td>
                {template.isDefault
                  ? 'N/A'
                  : template?.activeFrom
                  ? moment.utc(template?.activeFrom).format('MM/DD/YYYY')
                  : 'N/A'}
              </td>
              <td>
                {template.isDefault
                  ? 'N/A'
                  : template?.activeTo
                  ? moment.utc(template?.activeTo).format('MM/DD/YYYY')
                  : 'N/A'}
              </td>
              <td className={`cta${disableCTA ? ' disabled' : ''}`}>
                <EditIcon
                  onClick={
                    template.isDefault
                      ? () => onDefaultEdit(data[index])
                      : () => onEdit(data[index])
                  }
                  className="edit icon"
                />
                {!template.isDefault && (
                  <span>
                    <TrashIcon
                      onClick={() => onDelete(data[index])}
                      className="delete icon"
                    />
                    <span
                      className="text-action"
                      onClick={() => onDuplicate(data[index])}
                    >
                      Duplicate and Create
                    </span>
                  </span>
                )}
              </td>
            </tr>
          ))}
      </table>
    </TableWrapper>
  );
};
export default React.memo(AlternateAvailabilityTable);
