import React from 'react';
import InfoOutlined from 'assets/img/InfoOutlined.png';

interface NotesProps {
  sidebarData: any;
  sidebarRef: React.RefObject<HTMLUListElement>;
}

const Notes: React.FC<NotesProps> = ({ sidebarData, sidebarRef }) => {
  const sortedNotes = sidebarData.notes.sort(
    (a: { actionAt: any }, b: { actionAt: string | number | Date }) => {
      return new Date(b.actionAt).getTime() - new Date(a.actionAt).getTime();
    }
  );
  return (
    <>
      <div className="sidebar-notes">
        <ul ref={sidebarRef}>
          {sortedNotes.map((note: any, index: number) => (
            <li key={index} className={note.error ? `notes-error` : ``}>
              <div className="notes-item">
                <span className="notes-item-header">
                  <span>{formatDate(note.actionAt)}</span> -{' '}
                  <span>{note.actionBy}</span>
                </span>
                <span
                  className="notes-item-content"
                  dangerouslySetInnerHTML={{
                    __html: note.note.replace(/======/g, '<br><br>')
                  }}
                ></span>
              </div>
              {note.error && (
                <>
                  <div className="error-msg">
                    <img src={InfoOutlined} />
                    Oops! Something went wrong.{' '}
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Notes;

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};
