import React from 'react';
import { Table } from 'lib/ui/';
import { ChairIcon, MusicalIcon, TvIcon, PhoneIcon } from 'lib/ui/icons';
import { alphabeticalSort } from 'utils/sort';
import { ROOMS_COLUMNS } from 'utils/constants/tables/clinics';

const columns = ROOMS_COLUMNS;

columns.find(column => column.dataIndex === 'amenities').render = (
  name,
  row
) => {
  const { seats, hasTV, hasInstruments, hasPhone } = row;
  return (
    <div className="amenities-info">
      <div className="amenities-info__item">
        <ChairIcon />
        <span>{seats} Seats</span>
      </div>
      {hasTV && (
        <div className="amenities-info__item">
          <TvIcon />
          <span>TV</span>
        </div>
      )}
      {hasInstruments && (
        <div className="amenities-info__item">
          <MusicalIcon />
          <span>Instruments</span>
        </div>
      )}
      {hasPhone && (
        <div className="amenities-info__item">
          <PhoneIcon />
          <span>Phone</span>
        </div>
      )}
    </div>
  );
};

const Rooms = ({ rooms }) => {
  const data = rooms?.sort((a, b) => alphabeticalSort(a.name, b.name)) || [];

  return <Table columns={columns} dataSource={data} rowKey="id" />;
};

export default Rooms;
export { Rooms };
