import React, { useCallback } from 'react';
import { LibRadio, LibRadioGroup } from 'lib/ui/radio/radio.style';
import { FormError } from './error';
import { styleNumbers } from 'utils/common/radioGroup';

const Radio = props => {
  const { field, ...restProps } = props;

  return (
    <>
      <LibRadio {...restProps} {...field} />
      {field?.name && <FormError name={field?.name} />}
    </>
  );
};

const RadioGroup = ({
  field,
  customOnChangeHandler,
  options,
  isColorNumbers = false,
  styleClassName = '',
  ...restProps
}) => {
  const coloredOptions = isColorNumbers
    ? styleNumbers(options, '#6F42F5')
    : options;

  const onCustomChange = useCallback(
    event => {
      field.onChange(event.target.value);
      if (customOnChangeHandler) customOnChangeHandler(event);
    },
    [field, customOnChangeHandler]
  );
  return (
    <>
      {field?.name && (
        <LibRadioGroup
          className={styleClassName}
          options={coloredOptions}
          {...restProps}
          {...field}
          onChange={onCustomChange}
        />
      )}
      {field?.name && <FormError name={field?.name} />}
    </>
  );
};

export default Radio;
export { Radio, RadioGroup };
