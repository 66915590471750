import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 100%;
  .ui.basic.buttons .button {
    font-weight: bold;
    border-radius: 0.5rem;
    text-shadow: none !important;
    box-shadow: none !important;
  }
`;
