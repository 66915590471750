import React from 'react';
import { Table } from 'lib/ui';

const dataSource = [
  {
    appointmentType: 'Grouped Limit',
    completed: '10 visits',
    scheduled: '9 visits',
    remaining: '1 visits',
    toFulfill: '+1/week',
    children: [
      {
        appointmentType: 'Speech Therapy',
        completed: '5 visits',
        scheduled: '4 visits'
      },
      {
        appointmentType: 'Speech Therapy',
        authsConverted: '5 visits',
        scheduled: '5 visits'
      }
    ]
  },
  {
    appointmentType: 'Occupational Therapy',
    completed: '10 visits',
    scheduled: '9 visits',
    authsRemaining: '1 visits',
    toFulfill: '+1/week'
  },
  {
    appointmentType: 'Music Therapy',
    completed: '10 visits',
    scheduled: '9 visits',
    remaining: '1 visits',
    toFulfill: '+1/week'
  }
];

const ExpandableTable = ({ columns, values }) => {
  return (
    <div className="expandable_table-container">
      <Table
        columns={columns}
        dataSource={values || dataSource}
        rowKey="key"
        pagination={false}
        defaultExpandAllRows
        size="small"
      />
    </div>
  );
};

export default ExpandableTable;
