import styled from 'styled-components';

export const FixedSideBarWrapper = styled.div`
  height: 100%;
  .side-nav.ui.vertical.menu {
    width: 290px;
    height: 100%;
    border-radius: 0;
    box-shadow: unset;
    border-width: 0 1px 0 0;
  }
`;
