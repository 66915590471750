import gql from 'graphql-tag';

export const appointmentTypesFragments = {
  appointmentTypesData: gql`
    fragment AppointmentTypeData on AppointmentType {
      id
      title
      headerColor
      backgroundColor
      eventType {
        id
        name
      }
      appointmentSubTypes {
        id
        title
      }
      isEvaluation
      isActive
    }
  `,
  appointmentTypeWithParent: gql`
    fragment AppointmentTypeWithParent on AppointmentType {
      id
      title
      headerColor
      backgroundColor
      paycodes
      eventType {
        id
        name
      }
      parent {
        id
        title
        headerColor
        backgroundColor
      }
    }
  `,
  appointmentTypesTableData: gql`
    fragment AppointmentTypesTableData on AppointmentType {
      id
      title
      headerColor
      backgroundColor
      isEvaluation
      isActive
      eventType {
        id
        name
      }
    }
  `,
  ABAAppointmentSubTypeConfig: gql`
    fragment ABAAppointmentSubTypeConfig on AppointmentSubTypeOutput {
      id
      title
      placeOfService {
        id
        value
      }
      superType {
        id
        value
      }
      crAuthCode {
        id
        value
      }
      crType
    }
  `,
  DTAppointmentSubTypeConfig: gql`
    fragment DTAppointmentSubTypeConfig on AppointmentSubTypeOutput {
      id
      title
      athenaId
      crName
      crType
      duration
      superType {
        id
        value
      }
      DTSettingId
      salesForceMapping {
        id
        salesforceTherapyTitle
      }
    }
  `,
  appointmentTypesConfig: gql`
    fragment AppointmentTypesConfig on AppointmentType {
      id
      title
      headerColor
      backgroundColor
      isEvaluation
      paycodes
      isActive
      dayAvailabilityTypes
      salesForceTherapyMappings {
        id
        salesforceTherapyTitle
      }
    }
  `,
  allAppointmentTypesConfig: gql`
    fragment AllAppointmentTypesConfig on AppointmentType {
      id
      title
      headerColor
      backgroundColor
      isEvaluation
      isActive
      dayAvailabilityTypes
    }
  `,
  MEDAppointmentSubTypeConfig: gql`
    fragment MEDAppointmentSubTypeConfig on AppointmentSubTypeOutput {
      id
      title
      athenaId
      crName
      crType
      duration
      superType {
        id
        value
      }
      MEDSettingId
    }
  `
};
