import styled from 'styled-components';
export const TableWrapper = styled.div`
  height: calc(100% - 83px);
  overflow: auto;
  border: none !important;
  .ui.sortable.table thead th.sorted {
    background: #f9fafb;
  }
  .ui.table {
    border: none !important;
  }
  .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2;
    border-top: 1px solid #c9cfd3;
  }
`;
