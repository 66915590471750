import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Popover } from 'antd';
import { EyeFilled, CalendarFilled } from '@ant-design/icons';
import { isRecurringEvent, openingId } from 'utils/common';
import { ProvidersCalendarWrap } from '../../NewAppointment.style';
import { useOpeningConflicts } from './openingsHooks';
import ProviderHoverCalendar from './ProviderHoverCalendar';
import { useWatch } from 'react-hook-form';

const CalendarEventItem = ({
  opening,
  reschedule,
  thisAndFollowingEvents,
  control,
  setValue,
  disabledFormFields
}) => {
  // load conflicts
  const { conflictOpening } = useOpeningConflicts(
    opening,
    thisAndFollowingEvents,
    disabledFormFields.reschedule
  );

  const watchedValues = useWatch({
    control,
    name: ['selectedSlot', 'scheduleType']
  });

  const handleSelect = useCallback(() => {
    if (!disabledFormFields.selectedSlot) {
      setValue('selectedSlot', conflictOpening);
    }
  }, [setValue, conflictOpening, disabledFormFields.selectedSlot]);

  const isSelected = useMemo(() => {
    return watchedValues.selectedSlot?.id === conflictOpening.id;
  }, [conflictOpening, watchedValues.selectedSlot]);

  const popoverComponent = useMemo(
    () => (
      <Popover
        placement="right"
        overlayClassName="viewSurrounding"
        content={
          opening && (
            <ProvidersCalendarWrap>
              <ProviderHoverCalendar opening={opening} />
            </ProvidersCalendarWrap>
          )
        }
        trigger="hover"
      >
        <EyeFilled />
      </Popover>
    ),
    [opening]
  );

  return (
    <div
      className={`openings-slot__item ${isSelected && 'selected'}`}
      key={openingId(opening)}
      onClick={handleSelect}
    >
      <span className="name">
        {opening.provider?.displayName || 'Loading names'}
      </span>
      <span>
        {moment(opening.start).format('hh:mm a')} -{' '}
        {moment(opening.end).format('hh:mm a')} {popoverComponent}
      </span>
      {isRecurringEvent(watchedValues.scheduleType) &&
        conflictOpening.openingsAvailableNum !== undefined &&
        conflictOpening.totalRecurringAppointments !== undefined && (
          <span className="conflicts-calculation">
            <CalendarFilled /> {conflictOpening.openingsAvailableNum}/
            {conflictOpening.totalRecurringAppointments}
          </span>
        )}
    </div>
  );
};

export default React.memo(CalendarEventItem);
