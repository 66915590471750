import styled from 'styled-components';
import { PURPLE } from 'utils/constants/theme';

export const AppointmentViewWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  .scrollable {
    overflow: scroll;
    padding: 0 24px;
    flex: 1;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
        inset 1px 1px 0px rgba(0, 0, 0, 0);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .appointment-status {
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
    text-transform: capitalize;
  }
  .edit-time-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 152px;
    height: 32px;
    padding: 0 !important;
    border-radius: 8px !important;
    border: solid 2px ${PURPLE} !important;
    background-color: #fff !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: ${PURPLE} !important;
    margin: 0 0 20px 0 !important;
  }
  .notes-title {
    margin: 0 0 14px 0;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0px;
    color: #21303a;
  }
  .notes-content {
    margin: 0 0 20px 0;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.15px;
    color: #21303a;
  }
`;

export const TitleViewWrapper = styled.div`
  margin-bottom: 20px;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 41px;
    color: #3e5766;
  }
`;

interface Props {
  specialityColor?: string;
}
export const AttendeesViewWrapper = styled.div<Props>`
  margin-bottom: 20px;
  .attendees-title {
    font-size: 21px;
    font-weight: bold;
    color: #21303a;
  }
  .provider-btn {
    background: #6f42f5;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 4%;
    font-size: 12px;
  }
  .patient-btn {
    background: #17a8c2;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 4%;
    font-size: 12px;
  }
  .observer-btn {
    background: #45bfff;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 4%;
    font-size: 12px;
  }
  .observer1-btn {
    background: #45bfff;
  }
  .observer2-btn {
    background: #4d7bbd;
  }
  .manage-btn {
    border: 1px solid #6f42f5;
    border-radius: 6px;
    color: #6f42f5;
    background: transparent;
    padding: 10%;
  }
  .manage-btn:hover {
    color: white;
    background-color: #724aef;
  }
  .manage-btn:disabled {
    opacity: 0.5;
  }
  .attende-name-row {
    width: 64% !important;
  }
  .attende-name-label {
    width: 28% !important;
  }
  .attendees {
    display: flex;
    align-items: center;
    margin: 5px;
    .avatar {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
      border-radius: 50%;
      border: solid 1px #979797;
      background-color: #d8d8d8;
    }
    .attende-name {
      font-size: 14px;
      color: black;
    }
    .client-bullet {
      width: 24px;
      height: 24px;
      border: solid 1px #979797;
      background-color: #d8d8d8;
      border-radius: 50%;
      margin-right: 5px;
    }
    .staff-bullet {
      width: 11px;
      height: 11px;
      background-color: ${props =>
        props.specialityColor ? props.specialityColor : '#45bfff'};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
`;

export const AppointmentAuthWrapper = styled.div`
  display: flex;
  margin: 0 0 32px 0;
  .auth-appointment-wrapper {
    width: 100%;
    padding: 0;
    display: unset;
    .no-authorizations {
      display: flex;
      align-items: center;
      margin: 0 0 9px 0;
      h4 {
        margin: 0 0 0 10px;
      }
    }
    h4 {
      font-family: Roboto;
      margin: 0 0 9px 0;
      font-size: 14px;
      font-weight: bold;
      color: #415766;
    }
    .wrapper_data {
      display: flex;
      margin: 0 0 14px 0;
      span {
        margin: 0 0 3px 0;
        opacity: 0.8;
        font-size: 15px;
        font-weight: bold;
        color: #21303a;
        text-align: unset;
      }
      .start_end {
        display: unset;
        padding: 0;
        display: flex;
        justify-content: unset;
        line-height: normal;
        span {
          font-size: 10px;
          color: #21303a;
          margin: 0;
          &.date-separator {
            display: inline-block;
            margin: 0 2px;
          }
        }
      }
    }
    .auth_therapies_data {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      & > div {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h5 {
          margin: 0 0 5px 0;
          font-family: Roboto;
          font-size: 10px;
          font-weight: bold;
          color: #000;
        }
        span {
          font-size: 15px;
          color: #21303a;
          width: unset;
        }
      }
    }
  }
`;

export const HeaderViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 12px 24px 12px 24px;
  background: #3e5766;
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin: 0;
    line-height: 54px;
  }
  .icons-wrapper {
    display: flex;
    justify-content: space-around;
    .anticon-close,
    .fa-ban,
    .fa-pen {
      cursor: pointer;
      margin-left: 26px;
      color: #ffffff;
      font-size: 18px;
    }
    .close-icon {
      color: #ffffff;
      font-size: '18px';
    }
  }
`;
