import { ISavedFilterOptions } from 'model/calendar/filters';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Select } from 'api/sharedComponents/reactHookFormComponents';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import {
  CALENDAR_FILTER_ENUM,
  DefaultTagFilterOption,
  IS_DEFAULT_FILTER_TAGS_OPTIONS,
  TAG_FILTER
} from 'utils/constants/calendarFilters';
import { SidebarFilterFormWrapper } from './style';
import { FilterTagListWrapper } from '../style';
import moment from 'moment';
import { checkIfFilterSelected } from 'utils/mappers/carePlans';
import Tag from '../Tag';

interface IProps {
  filterName?: string;
  pageName: string;
  isConfirmed: boolean;
  filtername: string;
  setFiltername: Dispatch<SetStateAction<string>>;
  filterData: ISavedFilterOptions;
  setRemovedFilterTags: Dispatch<SetStateAction<boolean>>;
  errorMsg: string;
}

const SidebarFilterForm: React.FC<IProps> = ({
  isConfirmed,
  setFiltername,
  filterData,
  setRemovedFilterTags,
  errorMsg
}: IProps) => {
  const methods = useFormContext();
  const [filter, setFilter] = useState<ISavedFilterOptions>(filterData);
  useEffect(() => {
    const isDefault = filter?.isDefault
      ? DefaultTagFilterOption.yes
      : DefaultTagFilterOption.no;
    if (!isConfirmed) {
      methods.setValue('name', filter?.name);
      methods.setValue('isDefault', isDefault);
      methods.setValue('filter', filter);
      setFiltername(filter?.name!);
    }
  }, [filter, isConfirmed]);
  const selectedLocation = filter?.locations!.map(item => parseInt(item.value));
  const selectedDay = filter?.days!.map(item => parseInt(item.value));
  const startTimeVal = filter?.startTime ? filter?.startTime : '00:00';
  const startTime = moment(startTimeVal, 'HH:mm');
  const endTimeVal = filter?.endTime ? filter?.endTime : '00:00';
  const endTime = moment(endTimeVal, 'HH:mm');
  if (
    checkIfFilterSelected(startTime, endTime, selectedDay, selectedLocation)
  ) {
    if (filter) {
      filter.additionalFilter = {
        isFilterApplied: true,
        locations: selectedLocation,
        startTime: startTime,
        endTime: endTime,
        days: selectedDay
      };
      filter.locations = [];
    }
  } else {
    if (filter)
      filter.additionalFilter = {
        isFilterApplied: false,
        locations: [],
        startTime: null,
        endTime: null,
        days: []
      };
  }
  const onRemoveFilter = useCallback(
    (type, value): void => {
      setRemovedFilterTags(true);
      switch (type) {
        case CALENDAR_FILTER_ENUM.clinics:
          const clinics = filter?.clinics?.filter(val => val.value !== value);
          setFilter(prevFilter => ({
            ...prevFilter,
            clinics: clinics
          }));
          break;
        case 'waitListReason':
          const waitListReason = filter?.waitListReason?.filter(
            val => val.value !== value
          );
          setFilter(prevFilter => ({
            ...prevFilter,
            waitListReason: waitListReason
          }));
          break;

        case 'therapyType':
          const therapyType = filter?.therapyType?.filter(
            val => val.value !== value
          );
          setFilter(prevFilter => ({
            ...prevFilter,
            therapyType: therapyType
          }));
          break;
        case 'serviceType':
          const serviceType = filter?.serviceType?.filter(
            val => val.value !== value
          );
          setFilter(prevFilter => ({
            ...prevFilter,
            serviceType: serviceType
          }));
          break;
        case 'clientAvailability':
          const clientAvailability = filter?.clientAvailability?.filter(
            val => val.value !== value
          );
          setFilter(prevFilter => ({
            ...prevFilter,
            clientAvailability: clientAvailability
          }));
          break;
        case CALENDAR_FILTER_ENUM.status:
          const status = filter?.status?.filter(val => val.value !== value);
          setFilter(prevFilter => ({
            ...prevFilter,
            status: status
          }));
          break;
        case CALENDAR_FILTER_ENUM.specialities:
          const specalities = filter?.specialities?.filter(
            val => val.value !== value
          );
          setFilter(prevFilter => ({
            ...prevFilter,
            specialities: specalities
          }));
          break;
        case CALENDAR_FILTER_ENUM.departments:
          const departments = filter?.departments?.filter(
            val => val.value !== value
          );
          setFilter(prevFilter => ({
            ...prevFilter,
            departments: departments
          }));
          break;
        case CALENDAR_FILTER_ENUM.programs:
          const programs = filter?.programs?.filter(val => val.value !== value);
          setFilter(prevFilter => ({
            ...prevFilter,
            programs: programs
          }));
          break;
        case CALENDAR_FILTER_ENUM.language:
          const language = filter?.language?.filter(val => val.value !== value);
          setFilter(prevFilter => ({
            ...prevFilter,
            language: language
          }));
          break;
        case CALENDAR_FILTER_ENUM.smartSchedulingStatus:
          const smartSchedulingStatus = filter?.smartSchedulingStatus?.filter(
            val => val.value !== value
          );
          setFilter(prevFilter => ({
            ...prevFilter,
            smartSchedulingStatus: smartSchedulingStatus
          }));
          break;
        default:
          console.warn(`Unhandled filter type: ${type}`);
      }
    },
    [setFilter, filter]
  );

  const getRemoveTagHandler = (type: CALENDAR_FILTER_ENUM, value: string) => {
    return () => onRemoveFilter(type, value);
  };
  const clearAdditionalFilter = useCallback(() => {
    setRemovedFilterTags(true);
    const additionalFilter = {
      isFilterApplied: false,
      locations: [],
      startTime: null,
      endTime: null,
      days: []
    };
    setFilter(prevFilter => ({
      ...prevFilter,
      additionalFilter: additionalFilter,
      days: [],
      locations: [],
      startTime: null,
      endTime: null
    }));
  }, [setFilter, filter]);
  const specalities = filter?.specialities?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.specialities}
        label={f.label}
        handleRemoveTag={
          getRemoveTagHandler(CALENDAR_FILTER_ENUM.specialities, f.value)!
        }
        value={f.value}
      />
    );
  });
  const languages = filter?.language?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.language}
        label={f.label}
        handleRemoveTag={
          getRemoveTagHandler(CALENDAR_FILTER_ENUM.language, f.value)!
        }
        value={f.value}
      />
    );
  });
  const clinicTags = filter?.clinics?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.clinics}
        label={f.label}
        handleRemoveTag={
          getRemoveTagHandler(CALENDAR_FILTER_ENUM.clinics, f.value)!
        }
        value={f.value}
      />
    );
  });

  const departments = filter?.departments?.map(f => (
    <Tag
      type={CALENDAR_FILTER_ENUM.departments}
      label={f.label}
      handleRemoveTag={
        getRemoveTagHandler(CALENDAR_FILTER_ENUM.departments, f.value)!
      }
      value={f.value}
    />
  ));

  const programs = filter?.programs?.map(f => (
    <Tag
      type={CALENDAR_FILTER_ENUM.programs}
      label={f.label}
      handleRemoveTag={
        getRemoveTagHandler(CALENDAR_FILTER_ENUM.programs, f.value)!
      }
      value={f.value}
    />
  ));
  const waitListReasonTags = filter?.waitListReason?.map(f => (
    <Tag
      type={CALENDAR_FILTER_ENUM.waitListReason}
      label={f.label}
      handleRemoveTag={
        getRemoveTagHandler(CALENDAR_FILTER_ENUM.waitListReason, f.value)!
      }
      value={f.value}
    />
  ));

  const therapyTypeTags = filter?.therapyType?.map(f => (
    <Tag
      type={CALENDAR_FILTER_ENUM.therapyType}
      label={f.label}
      handleRemoveTag={
        getRemoveTagHandler(CALENDAR_FILTER_ENUM.therapyType, f.value)!
      }
      value={f.value}
    />
  ));

  const serviceTypeTags = filter?.serviceType?.map(f => (
    <Tag
      type={CALENDAR_FILTER_ENUM.serviceType}
      label={f.label}
      handleRemoveTag={
        getRemoveTagHandler(CALENDAR_FILTER_ENUM.serviceType, f.value)!
      }
      value={f.value}
    />
  ));

  const smartSchedulingStatus = filter?.smartSchedulingStatus?.map(f => (
    <Tag
      type={CALENDAR_FILTER_ENUM.smartSchedulingStatus}
      label={f.label}
      handleRemoveTag={
        getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.smartSchedulingStatus,
          f.value
        )!
      }
      value={f.value}
    />
  ));

  const clientAvailabilityTags = filter?.clientAvailability?.map(f => (
    <Tag
      type={CALENDAR_FILTER_ENUM.clientAvailability}
      label={f.label}
      handleRemoveTag={
        getRemoveTagHandler(CALENDAR_FILTER_ENUM.clientAvailability, f.value)!
      }
      value={f.value}
    />
  ));

  const statusTags = filter?.status?.map(f => (
    <Tag
      type={CALENDAR_FILTER_ENUM.status}
      label={f.label}
      handleRemoveTag={
        getRemoveTagHandler(CALENDAR_FILTER_ENUM.status, f.value)!
      }
      value={f.value}
    />
  ));

  const AdditionalFilterTag = () => {
    if (filter?.additionalFilter?.isFilterApplied) {
      return (
        <Tag
          type={CALENDAR_FILTER_ENUM.therapyType}
          label={'Additional Filter'}
          handleRemoveTag={clearAdditionalFilter}
          value={'Additional Filter'}
        />
      );
    } else return <></>;
  };
  return (
    <SidebarFilterFormWrapper>
      <FilterTagListWrapper className="filter-tag-list-wrapper">
        <div className="tags-container">
          {clinicTags} {serviceTypeTags} {therapyTypeTags}
          {statusTags} {smartSchedulingStatus}
          {waitListReasonTags}
          {clientAvailabilityTags}
          {filter?.additionalFilter?.isFilterApplied && AdditionalFilterTag()}
          {departments}
          {languages}
          {specalities}
          {programs}
        </div>
      </FilterTagListWrapper>
      <div style={{ display: 'none' }}>
        <FormItem label={'Name'} WrapperClassName="name-field">
          <Controller
            name="filter"
            render={fieldProps => (
              <Input field={fieldProps} type="hidden" errors={undefined} />
            )}
          />
        </FormItem>
      </div>

      <FormItem label={'Name'} WrapperClassName="name-field">
        <Controller
          name={TAG_FILTER.name}
          render={fieldProps => (
            <Input
              field={fieldProps}
              placeholder={'Input Name'}
              errors={methods.errors}
            />
          )}
        />
        {errorMsg !== '' && <div className="error">{errorMsg}</div>}
      </FormItem>

      <FormItem label="Save as Default?" WrapperClassName="save-as-field">
        <Controller
          name={TAG_FILTER.isDefault}
          render={fieldProps => (
            <Select
              options={IS_DEFAULT_FILTER_TAGS_OPTIONS}
              field={fieldProps}
              errors={undefined}
            />
          )}
        />
      </FormItem>
    </SidebarFilterFormWrapper>
  );
};

export default SidebarFilterForm;
