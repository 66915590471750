import { flattenDeep } from 'lodash';
import { IClinic } from 'model/v2';
import { IProvider, IClient, SidebarState, IEvent } from 'model/v2';
import moment, { isMoment, Moment } from 'moment';
import { EVENTS_FETCHING_ACTIONS } from 'pages/MainCalendarPage';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';
import { View } from 'react-big-calendar';
import { ICalendarLoading } from 'model/calendar';
import { EventType, IPhantomEvent } from 'model/calendar/events';
import { ICalendarResource } from 'model/calendar/filters';
import { calendarGroup, IPlannerGroup } from 'model/calendar/groups';
import { customizeRange } from 'utils/builders/calendar';
import {
  calendarSidebarVar,
  CalendarView,
  mainCalendarPrefDayVar,
  PlannerView,
  views
} from 'utils/cache/calendar';
import {
  clientsResourcesPrevVar,
  filteredProvidersResourcesPrevVar,
  providerResourcesPrevVar,
  searchedClientsPrevVar,
  searchedProvidersPrefVar
} from 'utils/cache/filters';
import { CALENDAR_MAX_PAGE_SIZE } from 'utils/constants/calendarFilters';
import { DEFAULT_DURATION } from 'utils/constants/default';
import { CLINIC_PROVIDERS, EVENT_CATEGORY } from 'utils/constants/lists';
import { MAX_PLANNER_HOUR, MIN_PLANNER_HOUR } from 'utils/constants/planner';
import { formatAttendeeName, formatProviderDisplayName } from 'utils/format';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
const momentTz = require('moment-timezone');
export const momentDayToChar: Record<number, string> = {
  1: 'Mon',
  2: 'Tues',
  3: 'Wed',
  4: 'Thurs',
  5: 'Fri',
  6: 'Sat',
  0: 'Sun'
};

export const getView = (calendarView: CalendarView) => {
  switch (calendarView) {
    case views.PLANNER_DAY:
      return views.DAY as View;
    case views.PLANNER_WEEK:
      return views.WEEK as View;
    default:
      return calendarView as View;
  }
};

export const getEventDayIndex = (startTime: Date) => {
  return moment(startTime).day();
};

export const getEventDay = (startTime: Date) => {
  const startDayIndex = moment(startTime).day();
  return momentDayToChar[startDayIndex];
};

export const getPlannerWeekTime = (eventTime: Date, calendarDate: Date) => {
  let momentObject = moment(calendarDate, 'ddd MMM D YYYY HH:mm:ss ZZ');
  const hours = new Date(eventTime).getHours();
  const minutes = new Date(eventTime).getMinutes();
  momentObject.set({ h: hours, m: minutes });
  return momentObject;
};

export const getPlannerWeekDayLabel = (
  weekDayIndex: number,
  calendarDate: Date
) => {
  return `${momentDayToChar[weekDayIndex]} ${moment(
    customizeRange(calendarDate, 'week').start
  )
    .add(weekDayIndex, 'day')
    .format('DD')}`;
};

export const getPlannerWeekDayLabelByDrop = (
  weekDayIndex: number,
  calendarDate: Date
) => {
  return moment(customizeRange(calendarDate, 'week').start).add(
    weekDayIndex,
    'day'
  );
};

export const getProvidersGroups = (
  selectedProvider: ICalendarResource[],
  providers: ICalendarResource[],
  providersFetched: ICalendarResource[] | [],
  searchedProviders: Map<number, ICalendarResource>,
  action: string | undefined
) => {
  let newProviders: ICalendarResource[] = [];

  switch (action) {
    case EVENTS_FETCHING_ACTIONS.CALENDAR_NAVIGATION: {
      newProviders = providersFetched; // add page 0 providers
      break;
    }
    case EVENTS_FETCHING_ACTIONS.PAGE_NAVIGATION: {
      newProviders = providers.concat(providersFetched); //add current providers + new providers page
      break;
    }
    case EVENTS_FETCHING_ACTIONS.APPLY_FILTERS: {
      //keep serached values and add first page of filtered providers
      const orderedSearchedProviders = providers.filter(p =>
        searchedProviders.has(p.id)
      );

      newProviders = orderedSearchedProviders.concat(providersFetched || []);

      break;
    }
    case EVENTS_FETCHING_ACTIONS.APPLY_SEARCH: {
      // add searched provider on top
      newProviders = selectedProvider.concat(providers);
      break;
    }
    default: {
      break;
    }
  }

  return newProviders;
};

export const getClientsGroups = (
  action: string | undefined,
  clientsToFetch: ICalendarResource[],
  clients: ICalendarResource[],
  selectedClient: ICalendarResource[]
) => {
  let newClients: ICalendarResource[] = [];
  switch (action) {
    case EVENTS_FETCHING_ACTIONS.CALENDAR_NAVIGATION: {
      newClients = clientsToFetch; // add page 0 providers
      break;
    }
    case EVENTS_FETCHING_ACTIONS.PAGE_NAVIGATION: {
      newClients = clients.concat(
        filterFetchedClients(clients, clientsToFetch)
      );
      break;
    }
    case EVENTS_FETCHING_ACTIONS.APPLY_SEARCH: {
      // add searched provider on top
      newClients = selectedClient.concat(clients);
      break;
    }
    case EVENTS_FETCHING_ACTIONS.APPLY_FILTERS: {
      newClients = clients.concat(
        filterFetchedClients(clients, clientsToFetch)
      );
      break;
    }
    default: {
      break;
    }
  }
  return newClients;
};

const filterFetchedClients = (
  clients: ICalendarResource[],
  clientsToFetch: ICalendarResource[]
) => {
  return clientsToFetch.filter(
    fetchedClient => !clients.some(client => client.id === fetchedClient.id)
  );
};

export const getDefaultGroup = (): IPlannerGroup[] => {
  return [
    {
      id: 'DEFAULT',
      displayName: '',
      attendeeType: ''
    }
  ];
};

export const getFullDayEndTime = (date: Date, view: PlannerView) => {
  return moment(date)
    .endOf('day')
    .add(view === PlannerView.plannerWeek ? 1 : 0, 'hours')
    .toDate();
};

export const getFullDayStartTime = (date: Date) => {
  return moment(date)
    .startOf('day')
    .toDate();
};

export const getDefaultStartTime = (date: Date) => {
  return moment(date)
    .startOf('day')
    .add(MIN_PLANNER_HOUR, 'hours')
    .valueOf();
};

export const getDefaultEndTime = (date: Date, view: PlannerView) => {
  return moment(date)
    .startOf('day')
    .add(MAX_PLANNER_HOUR(view), 'hours')
    .valueOf();
};

export const getGroupsToFetch = (
  allGroups: ICalendarResource[],
  page: number,
  view: CalendarView
) => {
  const startIndex = page * CALENDAR_MAX_PAGE_SIZE(view);
  let endIndex = startIndex + CALENDAR_MAX_PAGE_SIZE(view);

  if (allGroups.length < startIndex) {
    return {
      providers: [],
      clients: []
    };
  } else if (allGroups.length < endIndex) {
    endIndex = allGroups.length;
  }
  const currentGroups = allGroups.slice(startIndex, endIndex);

  const clients = currentGroups.filter(
    group => group.attendeeType === calendarGroup.client
  );
  const providers = currentGroups.filter(
    group => group.attendeeType === calendarGroup.provider
  );
  return {
    clients,
    providers
  };
};

export const getCurrentPaginationArray = (
  clients: ICalendarResource[],
  providers: ICalendarResource[],
  paginationArray: ICalendarResource[],
  removedGroups: number[],
  action: string | undefined,
  filteredPoviders: ICalendarResource[],
  searchedprovider: Map<number, ICalendarResource>,
  searchedClients: Map<number, ICalendarResource>
) => {
  const displayedClientsIDs = clients.map(c => c.id);
  const unFetchedClients = paginationArray.filter(
    client =>
      searchedClients.has(client.id) && !displayedClientsIDs.includes(client.id)
  );

  const displayedProvidersIDs = providers.map(p => p.id);

  //new snapshot => current displayed clients (ordered and removed) +
  //unfetched clients added throusgh search but their page is not fetched
  //current displayed provider(ordered and removed) +
  //rest of clients and providers
  switch (action) {
    case EVENTS_FETCHING_ACTIONS.CALENDAR_NAVIGATION: {
      //remove clients , remove removed groups through search ,
      //and remove already displayed providers
      //This to handle getting back removed providers through location filter
      const otherProviders = paginationArray.filter(
        resource =>
          !removedGroups.includes(resource.id) &&
          resource.attendeeType !== calendarGroup.client &&
          !displayedProvidersIDs.includes(resource.id)
      );
      return clients
        .concat(unFetchedClients)
        .concat(providers)
        .concat(otherProviders);
    }
    case EVENTS_FETCHING_ACTIONS.APPLY_FILTERS: {
      const inSearchAndNotFetched = paginationArray.filter(
        provider =>
          searchedprovider.has(provider.id) &&
          !displayedProvidersIDs.includes(provider.id)
      );

      //in same view clients and providers are already fetched ,
      //will not be in paginated array
      return unFetchedClients
        .concat(inSearchAndNotFetched)
        .concat(filteredPoviders);
    }
    default: {
      return [];
    }
  }
};

export const mapValuesToArray = (map: Map<number, ICalendarResource>) => {
  let values: ICalendarResource[] = [];
  for (const value of map.values()) {
    values = values.concat(value);
  }
  return values;
};

export const mapKeysToArray = (map: Map<number, ICalendarResource>) => {
  let values: number[] = [];
  for (const id of map.keys()) {
    values = values.concat(id);
  }
  return values;
};

export const mapToPLannerWeekGroup = (
  providers: ICalendarResource[]
): IPlannerGroup[] => {
  return flattenDeep(
    providers.map(provider => {
      return Object.keys(momentDayToChar).map(day => {
        return {
          ...provider,
          baseId: provider.id?.toString(),
          id: `${provider.id}:${day}`
        };
      });
    })
  );
};

export const getClinicAbbreviation = (
  clinicId: string,
  clinicsMap: Map<any, IClinic>
) => clinicsMap?.get(clinicId)?.abbreviation;

//TODO: Handle iformEvent
export const newProviderAppointmentHandler = (provider: IProvider) => {
  formRedirectHandler(
    {
      event: {
        type: EVENT_CATEGORY[1].value as EventType,
        provider: {
          ...provider,
          displayName: formatAttendeeName(
            provider?.firstName,
            provider?.lastName,
            provider?.clinic?.abbreviation,
            provider?.speciality?.abbreviation
          )
        },
        clinic: provider?.clinic,
        duration: DEFAULT_DURATION.CLIENT,
        clinicPreference: CLINIC_PROVIDERS[2].id
      },
      action: SIDEBAR_ACTIONS.NEW
    },
    null,
    null,
    {
      //check if cant be string
      id: provider?.id!,
      displayName: formatAttendeeName(
        provider?.firstName,
        provider?.lastName,
        provider?.clinic?.abbreviation,
        provider?.speciality?.abbreviation
      ),
      attendeeType: calendarGroup.provider,
      email: provider?.email,
      baseId: provider?.id!.toString(),
      clinic: provider?.clinic,
      speciality: provider?.speciality
    }
  );
  mainCalendarPrefDayVar(new Date());
};

export const newClientAppointmentHandler = (
  client: IClient,
  isDTAppt?: Boolean | undefined,
  no_of_session?: any,
  subType_Id?: number | undefined,
  parent_Type_ID?: number | undefined,
  isABAAppt?: Boolean | undefined
) => {
  const clientName = formatAttendeeName(
    client?.firstName,
    client?.lastName,
    client?.clinic?.abbreviation
  );

  formRedirectHandler(
    {
      event: {
        client: client,
        clinic: client?.clinic,
        isDTAppt: isDTAppt,
        isABAAppt: isABAAppt,
        no_of_session: no_of_session
          ? no_of_session > 10
            ? 10
            : no_of_session > 0
            ? no_of_session
            : 1
          : 1,
        parent_Type_ID: parent_Type_ID,
        subType_Id: subType_Id
      },
      action: SIDEBAR_ACTIONS.NEW
    },
    null,
    {
      id: client.id!,
      displayName: clientName,
      name: client.name,
      attendeeType: calendarGroup.client,
      baseId: client?.id ? client.id!.toString() : undefined,
      clinic: client?.clinic,
      profile: client?.profile
    }
  );
  mainCalendarPrefDayVar(new Date());
};

export const viewAppointmentHandler = (eventId?: number) => {
  formRedirectHandler({
    event: { id: eventId },
    action: SIDEBAR_ACTIONS.VIEW
  });
};

export const newAppointmentHandler = (isAdmin?: boolean) => {
  const event = isAdmin ? { eventTypeName: EVENT_TYPES.Admin } : {};
  formRedirectHandler({
    event,
    action: SIDEBAR_ACTIONS.NEW
  });
};

export const editAppointmentHandler = (event: IEvent) => {
  const { client, provider } = event;
  formRedirectHandler(
    {
      event,
      action: SIDEBAR_ACTIONS.EDIT
    },
    null,
    {
      id: client?.id!,
      displayName: formatAttendeeName(
        client?.firstName,
        client?.lastName,
        client?.clinic?.abbreviation
      ),
      name: client?.name,
      attendeeType: calendarGroup.client,
      baseId: client?.id ? client.id.toString() : undefined,
      clinic: client?.clinic
    },
    {
      id: provider?.id!,
      displayName: formatProviderDisplayName(provider),
      attendeeType: calendarGroup.provider,
      email: provider?.email,
      baseId: provider?.id ? provider.id.toString() : undefined,
      clinic: provider?.clinic,
      speciality: provider?.speciality
    }
  );

  mainCalendarPrefDayVar(
    isMoment(event.startDate)
      ? (event.startDate as Moment).toDate()
      : event.startDate
  );
};

export const formRedirectHandler = (
  sidebarState: SidebarState,
  history?: any,
  client?: ICalendarResource | null,
  provider?: ICalendarResource | null
) => {
  if (client) {
    const clients = clientsResourcesPrevVar();
    const searchedClients = searchedClientsPrevVar();
    searchedClients.set(client.id, client);
    searchedClientsPrevVar(searchedClients);
    clientsResourcesPrevVar(
      [client].concat(clients.filter(x => x.id !== client.id))
    );
  }
  if (provider && provider.status === 'Inactive') {
    const providers = providerResourcesPrevVar();
    const filteredProviders = filteredProvidersResourcesPrevVar();
    const searchedProviders = searchedProvidersPrefVar();
    searchedProviders.set(provider.id, provider);
    searchedProvidersPrefVar(searchedProviders);
    filteredProvidersResourcesPrevVar(
      filteredProviders.filter(x => x.id !== provider.id)
    );
    providerResourcesPrevVar(
      [provider].concat(providers.filter(x => x.id !== provider.id))
    );
  }
  calendarSidebarVar(sidebarState);
  if (history) {
    history.push({
      pathname: '/planner'
    });
  }
};

export const isGroupFetched = (
  id: number,
  clients: ICalendarResource[],
  providers: ICalendarResource[]
) => {
  return clients.concat(providers).filter(x => x.id === id).length > 0;
};

export const notPhantomEvent = (event: IPhantomEvent) =>
  event.type !== EventType.phantomClientEvent &&
  event.type !== EventType.phantomAdminEvent &&
  event.type !== EventType.phantomObserverEvent;

export const isPhantomEvent = (event: IPhantomEvent) =>
  event.type === EventType.phantomClientEvent ||
  event.type === EventType.phantomAdminEvent ||
  event.type === EventType.phantomObserverEvent;

export const getCalendarLoadingState = (
  action: string | undefined = undefined,
  currentState: ICalendarLoading,
  groupType: calendarGroup | undefined = undefined,
  providersLength: number = 0,
  clientsLength: number = 0
) => {
  switch (action) {
    case EVENTS_FETCHING_ACTIONS.CALENDAR_NAVIGATION: {
      return {
        ...currentState,
        isLoadingClientsEvents: !!clientsLength,
        isLoadingStaffEvents: !!providersLength
      };
    }
    case EVENTS_FETCHING_ACTIONS.PAGE_NAVIGATION: {
      return {
        ...currentState,
        isLoadingClientsEvents: !!clientsLength,
        isLoadingStaffEvents: !!providersLength
      };
    }
    case EVENTS_FETCHING_ACTIONS.APPLY_SEARCH: {
      if (groupType === calendarGroup.client) {
        return {
          ...currentState,
          isLoadingClientsEvents: true
        };
      } else {
        return {
          ...currentState,
          isLoadingStaffEvents: true
        };
      }
    }
    case EVENTS_FETCHING_ACTIONS.APPLY_FILTERS: {
      return {
        ...currentState,
        isLoadingFilteredProviders: true,
        isLoadingStaffEvents: true,
        errorLoadingEvents: false
      };
    }
    default: {
      return {
        isLoadingStaffEvents: false,
        isLoadingClientsEvents: false,
        isLoadingFilteredProviders: false,
        errorLoadingEvents: false
      };
    }
  }
};

export const isAttendeeInFiltered = (providerId: number | undefined) => {
  const filteredProviders = filteredProvidersResourcesPrevVar();
  const isAdded =
    filteredProviders.filter(attendee => attendee?.id === providerId)?.length >
    0;
  return isAdded;
};

export const convertFilteredToSearched = (
  provider: ICalendarResource,
  isReorderFiltrSearchProvider?: boolean
) => {
  //remove from filtered values
  const filteredProviders = filteredProvidersResourcesPrevVar();
  const providers = providerResourcesPrevVar();
  const searchedProviders = searchedProvidersPrefVar();
  let shouldFetch = true;

  const filtered = filteredProviders.filter(x => x.id !== provider?.id);
  filteredProvidersResourcesPrevVar(filtered);

  if (isGroupFetched(provider.id, [], providers)) {
    //add to top
    const newProviders = [provider].concat(
      providers.filter(x => x.id !== provider.id)
    );
    const shouldReorderProvider =
      isReorderFiltrSearchProvider === false ?? false;

    if (!shouldReorderProvider) providerResourcesPrevVar(newProviders);

    shouldFetch = false;
  }
  if (provider.attendeeType === calendarGroup.provider) {
    searchedProvidersPrefVar(searchedProviders.set(provider.id, provider));
  }
  return shouldFetch;
};

export const getAvailableLocationType = (
  isInClinic: boolean,
  isOffsite: boolean,
  isTelehealth: boolean
) => {
  let num = 0;
  if (isTelehealth) num += 1;
  if (isInClinic) num += 2;
  if (isOffsite) num += 4;

  function switchResult(num: number) {
    switch (num) {
      case 1: {
        return 'Telehealth Only';
      }
      case 2: {
        return 'In Clinic Only';
      }
      case 3: {
        return 'In Clinic and Telehealth';
      }
      case 5: {
        return 'Offsite and Telehealth';
      }
      case 7: {
        return 'Any Availability';
      }
      default:
        return null;
    }
  }
  let getNum = switchResult(num);
  return getNum;
};
export const getLocalBrowserTime = (
  timezone: String,
  eventDate: String,
  eventTime: moment.MomentInput | undefined
) => {
  momentTz.tz.setDefault(timezone);
  let cutoffString = momentTz(eventDate + ' ' + eventTime)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss'); // in utc
  let utcCutoff = momentTz.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
  let displayCutoff = utcCutoff.clone().tz(momentTz.tz.guess());
  let timeVal = displayCutoff.format('HH:mm');
  momentTz.tz.setDefault();
  return timeVal;
};

export const getLocalBrowserTimeAndDate = (
  timezone: String,
  eventDate: String,
  eventTime: moment.MomentInput | undefined
) => {
  momentTz.tz.setDefault(timezone);
  let cutoffString = momentTz(eventDate + ' ' + eventTime)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss'); // in utc
  let utcCutoff = momentTz.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
  let displayCutoff = utcCutoff.clone().tz(momentTz.tz.guess());
  let dateAndTime = displayCutoff.format('YYYY-MM-DD hh:mm:ss');
  momentTz.tz.setDefault();
  return dateAndTime;
};

export const isAttendeeInSearched = (providerId: number | undefined) => {
  const filteredProviders = searchedProvidersPrefVar();
  const isAdded = filteredProviders.has(providerId!);
  return isAdded;
};
