import { useCallback } from 'react';
import { IEvent } from 'model/v2';
import { Checkbox } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { GET_FULL_EVENT } from 'api/graphql/v2/queries/Events';
import Loader from 'views/components/ui/content/Loader';
import moment from 'moment';
import { updateCalendarPref } from 'utils/cache/calendar';
import { DOWN_STREAM_STATUS_VALUES } from 'utils/constants/status';
import { CONFIRM_PENDING_EVENT } from 'api/graphql/v2/mutations/Event';
import { calendarEventsPrefVar } from 'utils/cache/filters';

interface Props {
  event: IEvent;
}

const isPendingConfirmationDisabled = (event: IEvent): boolean =>
  !event.isPendingConfirmation ||
  (!!event?.crStatus &&
    (event.crStatus < DOWN_STREAM_STATUS_VALUES.MIN_VALUE_CREATION ||
      event.crStatus >= DOWN_STREAM_STATUS_VALUES.MIN_VALUE_FAILURE));

const PendingAppointment = ({ event }: Props) => {
  const [
    confirmPendingEvent,
    { loading: loadingAppointmentConfirmation }
  ] = useMutation(CONFIRM_PENDING_EVENT, {
    onCompleted: () => {
      calendarEventsPrefVar([]);
      updateCalendarPref(moment(event.startDate));
    }
  });

  const confirmAppointment = useCallback(() => {
    confirmPendingEvent({
      variables: {
        id: event.id,
        masterId: event.master?.id,
        isRecurring: event.recurrencePattern?.recurringEvery ? true : false
      },
      refetchQueries: [
        {
          query: GET_FULL_EVENT,
          variables: {
            id: event.id
          }
        }
      ]
    });
  }, [confirmPendingEvent, event]);

  if (loadingAppointmentConfirmation) return <Loader />;

  return (
    <Checkbox
      toggle
      onChange={confirmAppointment}
      checked={!event.isPendingConfirmation}
      disabled={isPendingConfirmationDisabled(event)} // if event crStatus present and less than 100
    />
  );
};

export default PendingAppointment;
