import React, { useContext } from 'react';
import { TagWrapper } from './style';
import { CloseOutlined } from '@ant-design/icons';
import { CALENDAR_FILTER_ENUM } from 'utils/constants/calendarFilters';
import { plannerContext } from 'pages/MainCalendarPage';

interface IProps {
  type?: CALENDAR_FILTER_ENUM;
  label: string;
  handleRemoveTag?: (type: CALENDAR_FILTER_ENUM, value: string) => void;
  isReadOnly?: boolean;
  value?: string;
}

const Tag: React.FC<IProps> = ({
  type,
  label,
  isReadOnly,
  handleRemoveTag,
  value
}: IProps) => {
  const { isBulkCancelMode } = useContext(plannerContext);
  return (
    <TagWrapper>
      <div
        className={`filter-tag ${!isReadOnly && 'closeing-tag'}`}
        key={`${label}-${type}`}
      >
        <span>{label}</span>
        {!isReadOnly && (
          <CloseOutlined
            onClick={() => {
              if (isBulkCancelMode) return;
              if (handleRemoveTag) handleRemoveTag(type!, value || '');
            }}
          />
        )}
      </div>
    </TagWrapper>
  );
};

export default Tag;
