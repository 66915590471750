import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CHECK_DOWN_STREAMS_STATUS } from 'api/graphql/v2/queries/Tools';
import Banner from 'components/banner';

const DownStreamsCheckBanner = () => {
  const MINUTE_MS = 120000;
  const [message, setMessage] = useState('');
  const [showBanner, setShowBanner] = useState(true);
  useQuery(CHECK_DOWN_STREAMS_STATUS, {
    fetchPolicy: 'network-only',
    pollInterval: MINUTE_MS,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (
        !data.checkDownStreamsApiStatus.athena &&
        !data.checkDownStreamsApiStatus.cr
      )
        setMessage(
          'Axon is currently unable to connect to Central Reach and Athena. Check if these systems are down, or contact your administrator.'
        );
      else if (!data.checkDownStreamsApiStatus.athena)
        setMessage(
          'Axon is currently unable to connect to Athena. Check Athena for an outage, or contact your administrator.'
        );
      else if (!data.checkDownStreamsApiStatus.cr)
        setMessage(
          'Axon is currently unable to connect to Central Reach. Check Central Reach for an outage, or contact your administrator.'
        );
      else setMessage('');

      setShowBanner(true);
    }
  });

  const onCloseBanner = useCallback(() => setShowBanner(false), [
    setShowBanner
  ]);

  if (message && showBanner)
    return <Banner message={message} onClick={onCloseBanner} />;
  return <></>;
};
export default React.memo(DownStreamsCheckBanner);
