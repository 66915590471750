import moment from 'moment';
import { Culture, DateLocalizer, DateRange } from 'react-big-calendar';
export const MIN_CALENDAR_TIME = moment('7:00am', 'h:mma').toDate();
export const MIN_CLIENT_CALENDAR_TIME = moment('8:00am', 'h:mma').toDate();
export const MAX_CALENDAR_TIME = moment('8:00pm', 'h:mma').toDate();
export const MAX_CALENDAR_PREVIEW_TIME = moment('7:00pm', 'h:mma').toDate();

export const MIN_PLANNER_HOUR = 7;
export const MAX_PLANNER_HOUR = 21;

export const CALENDAR_SLOT_STEP = 15;

export const CALENDAR_TIMESLOTS = 4;

export const STAFF_CALENDAR_MIN_HEIGHT = '880px';
export const STAFF_CALENDAR_MAX_HEIGHT = '1080px';
export const CLIENT_CALENDAR_MIN_HEIGHT = '880px';
export const CLIENT_CALENDAR_MAX_HEIGHT = '980px';
export const MAIN_CALENDAR_MIN_HEIGHT = '700px';
export const MAIN_CALENDAR_MAX_HEIGHT = '1080px';
export const SCROLL_TIME_CALENDAR = moment()
  .set({ h: 7, m: 0 })
  .toDate();
export enum calendarIntervals {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export const CALENDAR_FORMATS = {
  dateFormat: 'D',
  timeGutterFormat: 'h a',
  dayFormat: 'ddd DD',
  dayHeaderFormat: 'dddd MMM DD, YYYY',
  dayRangeHeaderFormat: (
    { start, end }: DateRange,
    culture: Culture | undefined,
    localizer: DateLocalizer | undefined
  ) => {
    let date = localizer?.format(start, 'MMM DD', culture || '') + '-';
    const startMonth = localizer?.format(start, 'MMM', culture || '');
    const endMonth = localizer?.format(end, 'MMM', culture || '');
    date =
      startMonth === endMonth
        ? date + localizer?.format(end, 'DD', culture || '')
        : date + localizer?.format(end, 'MMM DD', culture || '');

    date = date + ',' + localizer?.format(end, 'YYYY', culture || '') || '';
    return date;
  },
  eventTimeRangeFormat: ({ start, end }: DateRange): string =>
    moment(start).format('hh:mm') + ' - ' + moment(end).format('hh:mm A')
};
