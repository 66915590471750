import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { IClinic } from 'model/v2';
import MainButton from 'views/components/button';
import { useHistory } from 'react-router';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { GET_CLINIC_LIST } from 'api/graphql/v2/queries/Clinics';
import {
  TitleWrapper,
  ContentWrapper
} from 'views/components/ui/card/card.style';
import Content from 'views/components/ui/content';
import ClinicsList from './ClinicList';

const ClinicsPage: React.FC<{}> = () => {
  const { loading, error, data } = useQuery(GET_CLINIC_LIST, {
    fetchPolicy: 'network-only'
  });
  const history = useHistory();
  const addClinic = useCallback(() => {
    history.push({
      pathname: '/clinics/add',
      state: {
        title: 'Add Clinic'
      }
    });
  }, [history]);

  return (
    <>
      <TitleWrapper>
        <div className="inner-wrap">
          <h1>Clinics</h1>
          {getFeatureAvailability(FEATURES.CLINIC_CONFIGURATION) && (
            <MainButton title={'Add Clinic'} onClick={addClinic} />
          )}
        </div>
      </TitleWrapper>
      <ContentWrapper>
        <div className="inner-wrap">
          <Content loading={loading} error={error} data={data}>
            {({ clinics }: { clinics: IClinic[] }) => (
              <ClinicsList clinics={clinics} />
            )}
          </Content>
        </div>
      </ContentWrapper>
    </>
  );
};

export default ClinicsPage;
