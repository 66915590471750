import React from 'react';

interface Props {
  onClick:
    | ((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void)
    | undefined;
  className?: string;
}
const EditIcon: React.FC<Props> = ({ onClick, className }: Props) => (
  <div className={className} onClick={onClick}>
    <svg
      className={className?.includes('disabled') ? 'disabled' : ''}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      stroke="#6f4bf1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.69117 20.2499H4.50183C4.30292 20.2499 4.11215 20.1709 3.9715 20.0302C3.83085 19.8896 3.75183 19.6988 3.75183 19.4999V15.3105C3.75183 15.2121 3.77123 15.1145 3.80892 15.0235C3.84661 14.9325 3.90186 14.8499 3.9715 14.7802L15.2215 3.53022C15.3622 3.38956 15.5529 3.31055 15.7518 3.31055C15.9507 3.31055 16.1415 3.38956 16.2822 3.53022L20.4715 7.71956C20.6122 7.86021 20.6912 8.05097 20.6912 8.24989C20.6912 8.4488 20.6122 8.63956 20.4715 8.78022L9.2215 20.0302C9.15186 20.0999 9.06918 20.1551 8.97818 20.1928C8.88719 20.2305 8.78966 20.2499 8.69117 20.2499Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7518 6L18.0018 11.25"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.95391 20.202L3.79953 15.0476"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);

export default React.memo(EditIcon);
