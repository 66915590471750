/* eslint-disable no-constant-condition */
import { ICalendarResource } from 'model/calendar/filters';
import {
  IClient,
  IFormEvent,
  IProvider,
  SidebarState,
  SingleSmartResultInterface
} from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import Content from 'views/components/ui/content';
import { useLazyQuery } from 'react-apollo';
import {
  SubstituteListWrapper,
  SubstituteWrapper
} from './SmartScheduleResult/Style';
import { FormProvider, useForm } from 'react-hook-form';
import { useClinicDataMap } from 'api/graphql/v2/hooks/clinics';
import SubstitutePhantomEvents from '../FormPhantomEvents/SubstitutePhantomEvents';
import { useApptTypesWithAdmin } from 'api/graphql/v2/hooks/appointmentTypes';
import SubstituteConfirmModal from '../AppointmentModals/SubstituteConfirmModal';
import { FIND_SUBSTITUTE_PROVIDERS_QUERY } from 'api/graphql/v2/queries/ProviderCallout';
import {
  ABA_EXTENSION_SUBSTITUTE_APPT_IDS,
  MAKEUP_SESSION_APPT_IDS,
  MAKEUP_SESSION_SUB_APPT_IDS,
  SUBSTITUTE_APPT_IDS
} from 'utils/constants/appointmentsTypes';
import {
  useApptTypesMap,
  useRemoveProviderFromCalendar
} from '../FormPhantomEvents/CustomPhantomHooks';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import { IEvent } from 'model//v2';
import SubstitueResultsCard from './SmartScheduleResult/SubstitueResultsCard';
import moment from 'moment';
import SingleSmartPhantomEvents from '../FormPhantomEvents/SingleSmartPhantomEvents';
import SubstituteMakeupSessionCard from './SmartScheduleResult/SubstituteMakeupSessionCard';
import { ALL_CANCELLATION_REASONS } from 'api/graphql/v2/queries/CancellationReasons';
interface Props {
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  appointmentCategory: string;
  setSmart: Dispatch<SetStateAction<boolean>>;
  setAppointmentCategory: Dispatch<SetStateAction<string>>;
  changeFormHandler: (val: IFormEvent) => void;
  isCustom: boolean;
  smart: boolean;
  currClient: IClient | undefined;
  setCurrClient: Dispatch<SetStateAction<IClient>>;
}
const FindSubstitute: React.FC<Props> = ({
  sidebarState,
  setPaginationArray,
  paginationArray,
  setVisible
}: Props) => {
  const event = sidebarState.event;

  const isDT = useMemo(
    () =>
      event?.appointmentType?.eventType?.name === APPOINTMENT_CATEGORY[1].value,
    [event?.appointmentType?.eventType?.name]
  );
  const isABA = useMemo(
    () =>
      event?.appointmentType?.eventType?.name === APPOINTMENT_CATEGORY[0].value,
    [event?.appointmentType?.eventType?.name]
  );
  const [substituteResults, setSubstituteResults] = useState<any>();
  const [substituteProviderId, setSubstituteProviderId] = useState<number>();
  const [substituteProvider, setSubstituteProvider] = useState<IProvider>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [substituteType, setSubstituteType] = useState<string>();
  const [isPendingConfirmation, setIsPendingConfirmation] = useState<boolean>(
    false
  );
  const [getMakeupSessions, setGetMakeupSessions] = useState<boolean>(false);
  const [adjacentEvent, setAdjacentEvent] = useState<IEvent>();
  const [providerTelehealthLink, setProviderTelehealthLink] = useState<
    string
  >();
  const getSingleEventResultsMapping = useCallback(smartData => {
    const resultsData = smartData?.findSubstituteProviders;
    let directSubstitutes: any[] = [];
    let adjacentAbaExtensions: any[] = [];
    let biAbaExtensions: any[] = [];
    resultsData.map((result: any) => {
      if (result.isDirectSubstitute) {
        result.substituteType = 'direct';
        directSubstitutes.push(result);
      } else if (result.adjacentEvent) {
        result.substituteType = 'adjacent';
        adjacentAbaExtensions.push(result);
      } else {
        result.substituteType = 'biSubstitute';
        biAbaExtensions.push(result);
      }
    });
    let smartResults = {
      substituteResultCount: resultsData?.length,
      directSubstitutes: directSubstitutes,
      adjacentAbaExtensions: adjacentAbaExtensions,
      biAbaExtensions: biAbaExtensions,
      makeupSessionResults: []
    };
    return smartResults;
  }, []);
  const getMakeupSessionResultsMapping = useCallback(smartData => {
    let resultsData = smartData?.findSubstituteProviders;
    const makeupResults: any[] = [];
    resultsData = resultsData.slice(0, 10);
    resultsData.map((item: any) => {
      item.inPersonClinics = item.inPersonClinics.filter(
        (clinic: any) => event?.client?.clinic?.id === clinic.id
      );
      let allowedLocationsList: string[] = [];
      if (item.locations.isInClinic) {
        allowedLocationsList.push('in-clinic');
      }
      if (item.locations.isOffsite) {
        allowedLocationsList.push('off-site');
      }
      if (item.locations.isTelehealth) {
        allowedLocationsList.push('telehealth');
      }
      item.allowedLocations = allowedLocationsList;
      if (item.locations.isInClinic) {
        item.isInClinic = true;
        item.isOffsite = false;
        item.isTelehealth = false;
      } else if (item.locations.isTelehealth) {
        item.isInClinic = false;
        item.isOffsite = false;
        item.isTelehealth = true;
        item.telehealthLink = event?.telehealthLink;
      } else if (item.locations.isOffsite) {
        item.isInClinic = false;
        item.isOffsite = true;
        item.isTelehealth = false;
        item.locationCategory = event?.locationCategory;
        item.address = event?.address;
      }

      if (item.inPersonClinics.length > 0 || !item.locations.isInClinic)
        makeupResults.push(item);
    });
    let smartResults = {
      substituteResultCount: makeupResults?.length,
      directSubstitutes: [],
      adjacentAbaExtensions: [],
      biAbaExtensions: [],
      makeupSessionResults: makeupResults
    };
    return smartResults;
  }, []);
  const [getSubstituteData, { loading }] = useLazyQuery(
    FIND_SUBSTITUTE_PROVIDERS_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: substituteResults => {
        const mappedSmartResults = getSingleEventResultsMapping(
          substituteResults
        );
        if (mappedSmartResults?.substituteResultCount === 0)
          setGetMakeupSessions(true);
        setSubstituteResults(mappedSmartResults);
      }
    }
  );
  const [
    getMakeupSessionsData,
    { loading: makeupSessionsLoading }
  ] = useLazyQuery(FIND_SUBSTITUTE_PROVIDERS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: substituteResults => {
      const mappedSmartResults = getMakeupSessionResultsMapping(
        substituteResults
      );
      setSubstituteResults(mappedSmartResults);
    }
  });
  useEffect(() => {
    const duration = moment(event?.endDate).diff(
      moment(event?.startDate),
      'minutes'
    );
    getSubstituteData({
      variables: {
        eventTypeName: event?.appointmentType?.eventType?.name,
        clientId: event?.client?.id,
        appointmentTypeId:
          event?.appointmentType?.parent?.id || event?.appointmentType?.id,
        apptSubTypeId: event?.appointmentType?.parent?.id
          ? event?.appointmentType?.id
          : 0,
        startTime: event?.startDate,
        endTime: event?.endDate,
        providerId: event?.provider?.id,
        locationType: event?.locationType,
        eventId: event?.id,
        duration: duration,
        makeupSessions: false,
        eventStatus: event?.status
      }
    });
    doCancellationsReasonsRequest();
  }, []);
  useEffect(() => {
    if (getMakeupSessions) {
      const eventStartDate = moment(
        moment(event?.startDate).format('YYYY-MM-DD')
      );
      const eventEndDate = moment(
        moment(event?.startDate).format('YYYY-MM-DD')
      );
      let startDate = moment
        .utc(eventStartDate.add(1, 'days').startOf('day'))
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      let endDate = moment
        .utc(eventEndDate.add(7, 'days').endOf('day'))
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const duration = moment(event?.endDate).diff(
        moment(event?.startDate),
        'minutes'
      );
      getMakeupSessionsData({
        variables: {
          eventTypeName: event?.appointmentType?.eventType?.name,
          clientId: event?.client?.id,
          appointmentTypeId:
            event?.appointmentType?.parent?.id || event?.appointmentType?.id,
          apptSubTypeId: event?.appointmentType?.parent?.id
            ? event?.appointmentType?.id
            : 0,
          startTime: startDate,
          endTime: endDate,
          providerId: event?.provider?.id,
          locationType: event?.locationType,
          eventId: event?.id,
          duration: duration,
          makeupSessions: true,
          eventStatus: event?.status
        }
      });
      setGetMakeupSessions(false);
    }
  }, [setGetMakeupSessions, getMakeupSessions]);
  //const { control, setValue } = useFormContext();
  const methods = useForm();
  const { providersMap, loadingClinics } = useClinicDataMap(
    sidebarState.action
  );

  const { apptTypes, loadingApptTypes } = useApptTypesWithAdmin();
  const [confirmModel, setConfirmModel] = React.useState(false);
  const handleCancel = React.useCallback(() => {
    setConfirmModel(!confirmModel);
  }, [confirmModel, setConfirmModel]);
  const [
    doCancellationsReasonsRequest,
    { data: cancellationReason, loading: loadingCancellationReason }
  ] = useLazyQuery(ALL_CANCELLATION_REASONS, {
    fetchPolicy: 'cache-and-network'
  });
  const [count, setCount] = React.useState<number>(1);
  const [selectedResult, setSelectedResult] = useState<
    SingleSmartResultInterface | undefined
  >();
  const appointmentTypeId =
    event?.appointmentType?.parent?.id || event?.appointmentType?.id;
  let apptId = SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!][
    appointmentTypeId!
  ]
    ? SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!][appointmentTypeId!]
    : appointmentTypeId;
  let appointmentSubTypeId = event?.appointmentType?.parent?.id
    ? event?.appointmentType?.id
    : undefined;

  const appTypesMap = useApptTypesMap(apptTypes);
  console.log('[getSubstituteMakeUpPayload] reqevent apptmap', appTypesMap);
  let isDirectSubstitute = false;
  if (substituteResults && substituteResults?.directSubstitutes?.length > 0)
    isDirectSubstitute = true;
  if (adjacentEvent) {
    apptId = adjacentEvent?.appointmentType?.id;
  } else if (
    !isDirectSubstitute &&
    substituteResults?.makeupSessionResults?.length === 0
  ) {
    apptId = ABA_EXTENSION_SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!];
  } else if (substituteResults?.makeupSessionResults?.length > 0) {
    apptId = MAKEUP_SESSION_APPT_IDS[process.env.REACT_APP_STAGE!][
      appointmentTypeId!
    ]
      ? MAKEUP_SESSION_APPT_IDS[process.env.REACT_APP_STAGE!][
          appointmentTypeId!
        ]
      : appointmentTypeId;
    appointmentSubTypeId =
      MAKEUP_SESSION_SUB_APPT_IDS[process.env.REACT_APP_STAGE!][
        event?.appointmentType?.id!
      ];
  }
  const appointmentType = appTypesMap.get(apptId)!;
  const { removeAllProvidersFromCalendar } = useRemoveProviderFromCalendar();
  const removeUnselectedProviders = () => {
    removeAllProvidersFromCalendar();
  };
  return (
    <SubstituteListWrapper>
      <Content
        loading={
          loading ||
          loadingClinics ||
          loadingApptTypes ||
          loadingCancellationReason ||
          makeupSessionsLoading
        }
        data={substituteResults}
      >
        {() => (
          <>
            {substituteResults &&
            substituteResults?.substituteResultCount > 0 ? (
              <>
                <FormProvider {...methods}>
                  {!selectedResult && (
                    <SubstitutePhantomEvents
                      providersMap={providersMap}
                      setPaginationArray={setPaginationArray}
                      paginationArray={paginationArray}
                      substituteProviderId={substituteProviderId!}
                      substituteProvider={substituteProvider!}
                      event={event!}
                      apptTypes={apptTypes}
                      adjacentEvent={adjacentEvent!}
                      isDirectSubstitute={isDirectSubstitute}
                    />
                  )}

                  {selectedResult &&
                    apptId &&
                    substituteResults.makeupSessionResults.length > 0 && (
                      <SingleSmartPhantomEvents
                        providersMap={providersMap}
                        apptTypes={apptTypes}
                        client={event?.client!}
                        setPaginationArray={setPaginationArray}
                        paginationArray={paginationArray}
                        visibleResults={true}
                        selectedResult={selectedResult}
                        appointmentTypeId={apptId}
                        key={selectedResult.toString()}
                        appointmentSubTypeId={appointmentSubTypeId!}
                        transitionResults={
                          substituteResults.makeupSessionResults
                        }
                        selectedIndex={selectedIndex!}
                      />
                    )}
                  <div className="p-flex">
                    <div className="div-flex"></div>
                    <p className="p-pd-l">
                      Direct Susbtitutes (
                      {substituteResults?.directSubstitutes?.length})
                    </p>
                    {substituteResults?.directSubstitutes.length > 0 ? (
                      <>
                        {substituteResults?.directSubstitutes.map(
                          (result: any, index: number) => {
                            return (
                              <>
                                <SubstitueResultsCard
                                  event={event!}
                                  appTypesMap={appTypesMap}
                                  apptId={apptId!}
                                  result={result}
                                  index={index}
                                  setSubstituteProviderId={
                                    setSubstituteProviderId
                                  }
                                  setSubstituteProvider={setSubstituteProvider}
                                  setAdjacentEvent={setAdjacentEvent}
                                  setIsPendingConfirmation={
                                    setIsPendingConfirmation
                                  }
                                  setConfirmModel={setConfirmModel}
                                  confirmModel={confirmModel}
                                  setSelectedIndex={setSelectedIndex}
                                  selectedIndex={selectedIndex!}
                                  setSubstituteType={setSubstituteType}
                                  substituteType={substituteType!}
                                  setCount={setCount}
                                  count={count}
                                  setProviderTelehealthLink={
                                    setProviderTelehealthLink
                                  }
                                />
                              </>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <>
                        <p className="red f-w-bold pd-l">
                          There are no direct subs at this time.
                        </p>
                        {(isABA || isDT) && (
                          <>
                            <p className="substitute-result-p">
                              Extend Adjacent ABA Appointment (
                              {substituteResults?.adjacentAbaExtensions?.length}
                              )
                            </p>
                            {substituteResults?.adjacentAbaExtensions.map(
                              (result: any, index: number) => {
                                return (
                                  <>
                                    <SubstitueResultsCard
                                      event={event!}
                                      appTypesMap={appTypesMap}
                                      apptId={apptId!}
                                      result={result}
                                      index={index}
                                      setSubstituteProviderId={
                                        setSubstituteProviderId
                                      }
                                      setSubstituteProvider={
                                        setSubstituteProvider
                                      }
                                      setAdjacentEvent={setAdjacentEvent}
                                      setIsPendingConfirmation={
                                        setIsPendingConfirmation
                                      }
                                      setConfirmModel={setConfirmModel}
                                      confirmModel={confirmModel}
                                      setSelectedIndex={setSelectedIndex}
                                      selectedIndex={selectedIndex!}
                                      setSubstituteType={setSubstituteType}
                                      substituteType={substituteType!}
                                      setCount={setCount}
                                      count={count}
                                      setProviderTelehealthLink={
                                        setProviderTelehealthLink
                                      }
                                    />
                                  </>
                                );
                              }
                            )}
                            <p className="substitute-result-p">
                              BI Substitutes (
                              {substituteResults?.biAbaExtensions?.length})
                            </p>

                            {substituteResults?.biAbaExtensions.map(
                              (result: any, index: number) => {
                                return (
                                  <>
                                    <SubstitueResultsCard
                                      event={event!}
                                      apptId={apptId!}
                                      result={result}
                                      index={index}
                                      setSubstituteProviderId={
                                        setSubstituteProviderId
                                      }
                                      setSubstituteProvider={
                                        setSubstituteProvider
                                      }
                                      setAdjacentEvent={setAdjacentEvent}
                                      setIsPendingConfirmation={
                                        setIsPendingConfirmation
                                      }
                                      setConfirmModel={setConfirmModel}
                                      confirmModel={confirmModel}
                                      setSelectedIndex={setSelectedIndex}
                                      selectedIndex={selectedIndex!}
                                      setSubstituteType={setSubstituteType}
                                      substituteType={substituteType!}
                                      appTypesMap={appTypesMap}
                                      setCount={setCount}
                                      count={count}
                                      setProviderTelehealthLink={
                                        setProviderTelehealthLink
                                      }
                                    />
                                  </>
                                );
                              }
                            )}
                          </>
                        )}

                        <p className="substitute-result-p">
                          Makeup Sessions (
                          {substituteResults?.makeupSessionResults?.length})
                        </p>

                        {substituteResults?.makeupSessionResults.map(
                          (result: any, index: number) => {
                            return (
                              <SubstituteMakeupSessionCard
                                appointmentTypeTitle={
                                  apptTypes.find(
                                    it => it.id === appointmentTypeId
                                  )?.title || ''
                                }
                                result={result}
                                index={index}
                                event={event}
                                offsiteCategory={event?.locationCategory}
                                minHours={event?.duration!}
                                clientId={event?.client?.id}
                                isAba={isABA}
                                isDT={isDT}
                                hasSingleDT={true}
                                client={event?.client!}
                                setConfirmModel={setConfirmModel}
                                confirmModel={confirmModel}
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                                selectedAppointment={
                                  apptTypes.find(it => it.id === apptId)
                                    ?.title || ''
                                }
                                removeUnselectedProviders={
                                  removeUnselectedProviders
                                }
                                setSelectedResult={setSelectedResult}
                                setCount={setCount}
                                count={count}
                                appTypesMap={appTypesMap}
                                setVisible={setVisible}
                              />
                            );
                          }
                        )}
                        <div className="pd-b-10"></div>
                      </>
                    )}
                  </div>
                  {confirmModel && (
                    <SubstituteConfirmModal
                      onClose={handleCancel}
                      event={event}
                      cancellationReasons={
                        cancellationReason.allCancellationReasons
                      }
                      substituteProvider={substituteProvider!}
                      isPendingConfirmation={isPendingConfirmation}
                      setVisible={setVisible}
                      appointmentType={appointmentType}
                      appTypesMap={appTypesMap}
                      adjacentEvent={adjacentEvent!}
                      isDirectSubstitute={isDirectSubstitute}
                      selectedResult={selectedResult}
                      providersMap={providersMap}
                      providerTelehealthLink={providerTelehealthLink!}
                    />
                  )}
                </FormProvider>
              </>
            ) : (
              <>
                <SubstituteWrapper>
                  <p className="red f-w-bold">
                    No substitutes available for exact date and time of this
                    appointment.
                  </p>
                  {isDT && (
                    <>
                      <p className="f-w-bold">
                        Before Cancelling this appointment, try:
                      </p>
                      <ul className="f-w-bold">
                        <li> Swapping for another type of DT appointment</li>
                      </ul>
                    </>
                  )}
                  <p className="f-w-bold">
                    Click the X to go back to the planner.{' '}
                  </p>
                </SubstituteWrapper>
              </>
            )}
          </>
        )}
      </Content>
    </SubstituteListWrapper>
  );
};
export default React.memo(FindSubstitute);
