import React from 'react';
import AppointmentLocation from './Location/AppointmentLocation';
import AppointmentMainFields from './MainFields/AppointmentMainFields';
import AppointmentOpenings from './Openings/AppointmentOpenings';
import { EventNotes } from './Other/EventNotes';

//TODO: add openings, notes, telehealth, rooms
const AppointmentBody = ({ client, disabledFormFields }) => {
  return (
    <div className="appointment-body">
      <AppointmentMainFields
        disabledFormFields={disabledFormFields}
        client={client}
      />
      <AppointmentOpenings
        clientId={client.id}
        disabledFormFields={disabledFormFields}
      />
      <p className="empty-text" style={{ paddingTop: 70 }}>
        Select a client and a type of appointment to see potential matches
      </p>

      <AppointmentLocation
        clientId={client.id}
        disabledFormFields={disabledFormFields}
      />
      <EventNotes disabledFormFields={disabledFormFields} />
    </div>
  );
};

export default React.memo(AppointmentBody);
