import { Tabs } from 'lib/ui';
import ScheduleCalendar from './ScheduleCalendar';
import { TabsWrap } from './tabs.style';
import React from 'react';

const StaffTabs = ({ provider }) => {
  const schedulePane = {
    title: '',
    content: <ScheduleCalendar provider={provider} />
  };

  const panes = [schedulePane];

  return (
    <TabsWrap>
      <Tabs type="card" panes={panes} />;
    </TabsWrap>
  );
};

export default React.memo(StaffTabs);
