import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';
import { FormItem } from 'views/containers/form';
import { Datepicker } from 'api/sharedComponents/reactHookFormComponents';
import moment, { Moment } from 'moment';

export const mapToValueOption = (map: any) => {
  const arr: any = [];
  if (map) {
    for (const [key, value] of map) {
      arr.push({ label: value.name, value: key });
    }
  }
  return arr;
};
interface IProps {
  startDate?: string;
  endDate?: string;
}

const AltTempDatePicker: React.FC<IProps> = ({ startDate, endDate }) => {
  const methods = useFormContext();
  const startDateHandler = React.useCallback(
    (val: Moment) => {
      methods.setValue('startDate', val);
      methods.setValue('dirtyEdit', true);
    },
    [methods]
  );
  const endDateHandler = React.useCallback(
    (val: Moment) => {
      methods.setValue('endDate', val);
      methods.setValue('dirtyEdit', true);
    },
    [methods]
  );
  useEffect(() => {
    startDateHandler(moment(startDate));
    endDateHandler(moment(endDate));
  }, []);
  const disabledStartDate = (current: Date) => {
    return current && moment(current).isBefore(moment().startOf('week'));
  };
  const disabledEndDate = (current: Date) => {
    return (
      current &&
      moment(current).isBefore(
        moment(methods.getValues('startDate')).add(6, 'days')
      )
    );
  };

  let defaultStartDate: Moment, defaultEndDate: Moment;

  if (startDate && endDate) {
    defaultStartDate = moment(startDate);
    defaultEndDate = moment(endDate);
  }

  return (
    <>
      <Grid.Column className="date-picker-container">
        <FormItem optional={false} label="Start Date *">
          <Controller
            name="startDate"
            control={methods.control}
            render={fieldProps => {
              return (
                <Datepicker
                  className="datapicker-style"
                  name="selectedStartDate"
                  placeholder={'MM/DD/YY'}
                  format="MM/DD/YYYY"
                  field={fieldProps}
                  defaultValue={defaultStartDate || ''}
                  onChange={startDateHandler}
                  errors={methods.errors}
                  disabledDate={disabledStartDate}
                  allowClear={false}
                  style={{
                    border: '1px solid #6f42f5',
                    borderRadius: '3px'
                  }}
                />
              );
            }}
          />
        </FormItem>
      </Grid.Column>
      <Grid.Column width={4} className="date-picker-container">
        <FormItem optional={false} label="End Date *">
          <Controller
            name="endDate"
            control={methods.control}
            render={fieldProps => {
              return (
                <Datepicker
                  className="datapicker-style"
                  name="selectedEndDate"
                  placeholder="MM/DD/YY"
                  format="MM/DD/YYYY"
                  field={fieldProps}
                  onChange={endDateHandler}
                  defaultValue={defaultEndDate || ''}
                  errors={methods.errors}
                  allowClear={false}
                  disabledDate={disabledEndDate}
                  minDate={moment(methods.getValues('startDate')).add(
                    1,
                    'weeks'
                  )}
                  style={{
                    border: '1px solid #6f42f5',
                    borderRadius: '3px'
                  }}
                />
              );
            }}
          />
        </FormItem>
      </Grid.Column>
    </>
  );
};
export default React.memo(AltTempDatePicker);
