import { formatEventPopupTime, formatHhMmTime } from 'utils/format';
import { ItemTooltipWrapper } from './style';

interface Props {
  slot: any;
}
const SlotTooltip: React.FC<Props> = ({ slot }: Props) => {
  let abbreviationArray: any = [];
  let abreviationString: any;
  let duration: any;
  duration = `${formatHhMmTime(slot.start)} - ${formatEventPopupTime(
    slot.end
  )} `;
  slot.clinics?.map((clinic: any) => {
    abbreviationArray.push(clinic?.abbreviation);
  });
  abreviationString = abbreviationArray.join(' | ');

  if (slot.type !== 'na') {
    return (
      <ItemTooltipWrapper>
        <div>{slot?.evalSlotLocation}</div>
        <p className="clinic-abbr">{abreviationString}</p>
        <div>
          {slot.appointmentType.title},{duration}
        </div>
      </ItemTooltipWrapper>
    );
  } else {
    return (
      <ItemTooltipWrapper>
        <div>Unavailable</div>
      </ItemTooltipWrapper>
    );
  }
};

export default SlotTooltip;
