import moment from 'moment';
//import momentTz from 'moment-timezone';
import {
  getAvailableLocationType,
  getEventDayIndex,
  getPlannerWeekTime,
  getLocalBrowserTime
} from 'helpers/calendarHelper';
import { EventType, ICalendarEvent } from 'model/calendar/events';
import { calendarGroup } from 'model/calendar/groups';
import { IEvent, IFullAppointmentType } from 'model/v2';
import { CalendarView, views } from 'utils/cache/calendar';
import { internalFilters } from 'utils/constants/calendarFilters';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { EVENT_STATUS } from 'utils/constants/status';
import {
  APPOINTMENT_BACKGROUND_COLORS,
  APPOINTMENT_HEADER_COLORS
} from 'utils/constants/theme';
import { isDisabledMovement } from 'utils/validators/dragEventValidator';
import { ICalendarResource } from 'model/calendar/filters';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { formatUsernameOriginal } from 'utils/format';
import { View } from 'react-big-calendar';
// let momentTz = require('moment-timezone');

export const mapToCalendarEventsV2 = (
  events: IEvent[],
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providers: ICalendarResource[] = [],
  isPendingFilter: boolean = false
) => {
  const providersLocStatus: Record<number, any> | null =
    providers.length > 0
      ? initProvidersFiltersLookUp(providers, isPendingFilter)
      : null;
  const finalEvents: ICalendarEvent[] = [];

  if (Array.isArray(events)) {
    events.forEach(e => {
      const event = {
        ...e,
        canMove: !isDisabledMovement(e as ICalendarEvent)
      };
      if (event.type === EventType.clientEvent) {
        if (type === calendarGroup.client) {
          const clientEvent = mapToClientEvent(event, view, date);
          setCalenderEventStyle(clientEvent);
          finalEvents.push(clientEvent);
        } else {
          if (type === calendarGroup.provider) {
            if (providersLocStatus) {
              const providerStatusObj = providersLocStatus[event.provider?.id!];
              if (providerStatusObj !== undefined) {
                if (event?.locationType === LOCATION_TYPE_LIST[0].id) {
                  providerStatusObj[LOCATION_TYPE_LIST[0].id] = true;
                } else if (event?.locationType === LOCATION_TYPE_LIST[1].id) {
                  providerStatusObj[LOCATION_TYPE_LIST[1].id] = true;
                } else if (event?.locationType === LOCATION_TYPE_LIST[2].id) {
                  providerStatusObj[LOCATION_TYPE_LIST[2].id] = true;
                }
              }
              if (isPendingFilter) {
                //if pending is applied , update providers lookup according to pending value
                providerStatusObj[internalFilters.pending] =
                  event.isPendingConfirmation ||
                  providerStatusObj[internalFilters.pending];
              }
            }
            const staffEvent = mapToStaffEvent(event, view, date);
            setCalenderEventStyle(staffEvent);
            finalEvents.push(staffEvent);
          }
        }
      } else if (event.type === EventType.adminEvent) {
        const staffEvent = mapToStaffEvent(event, view, date);
        console.log('event track', staffEvent);
        setCalenderEventStyle(staffEvent);
        finalEvents.push(staffEvent);
      } else if (event.type === EventType.observation) {
        const staffEvent = mapToStaffEvent(event, view, date);
        setCalenderEventStyle(staffEvent);
        finalEvents.push(staffEvent);
      }
    });
  }

  return { finalEvents, providersLocStatus };
};
export const mapEvalSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();

      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('end', new Date(formattedEndDateOfEvalSlot));
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(164, 219, 179, 0.4)',
            headerColor: 'rgb(164, 219, 179, 0.4)',
            title: 'DT Eval Slot'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(164, 219, 179, 0.4)',
            headerColor: 'rgb(164, 219, 179, 0.4)',
            title: 'DT Eval Slot'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapWelcomeVisitSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(18, 47, 78, 0.2)',
            headerColor: 'rgb(18, 47, 78, 0.2)',
            title: 'Welcome Visit'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(18, 47, 78, 0.2)',
            headerColor: 'rgb(18, 47, 78, 0.2)',
            title: 'Welcome Visit'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapADIRFollowUpSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(150, 77, 79, 0.5)',
            headerColor: 'rgb(150, 77, 79, 0.5)',
            title: 'ADIR Follow Up'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(150, 77, 79, 0.5)',
            headerColor: 'rgb(150, 77, 79, 0.5)',
            title: 'ADIR Follow Up'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapCIMSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(22, 24, 23, 0.3)',
            headerColor: 'rgb(22, 24, 23, 0.3)',
            title: 'CIM'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(22, 24, 23, 0.3)',
            headerColor: 'rgb(22, 24, 23, 0.3)',
            title: 'CIM'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapABAAssesmentParentInterviewSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(255, 190, 56, 0.6)',
            headerColor: 'rgb(255, 190, 56, 0.6)',
            title: 'ABA Assessment: Parent Interview'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(255, 190, 56, 0.6)',
            headerColor: 'rgb(255, 190, 56, 0.6)',
            title: 'ABA Assessment: Parent Interview'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapMPNeuroEvalSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');
      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(42, 91, 93, 0.5)',
            headerColor: 'rgb(42, 91, 93, 0.5)',
            title: 'MP Neuro Eval'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(42, 91, 93, 0.5)',
            headerColor: 'rgb(42, 91, 93, 0.5)',
            title: 'MP Neuro Eval'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapCarePlanMeetingSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(247, 235, 224, 0.7)',
            headerColor: 'rgb(247, 235, 224, 0.7)',
            title: 'Care Plan Meeting'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(247, 235, 224, 0.7)',
            headerColor: 'rgb(247, 235, 224, 0.7)',
            title: 'Care Plan Meeting'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapADIRSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(76, 0, 51, 0.5)',
            headerColor: 'rgb(76, 0, 51, 0.5)',
            title: 'ADIR'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(76, 0, 51, 0.5)',
            headerColor: 'rgb(76, 0, 51, 0.5)',
            title: 'ADIR'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapADORSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  console.log(type);
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(10, 0, 30, 0.5)',
            headerColor: 'rgb(10, 0, 30, 0.5)',
            title: 'ADOS'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(10, 0, 30, 0.5)',
            headerColor: 'rgb(10, 0, 30, 0.5)',
            title: 'ADOS'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapMDCareSessionSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(137, 241, 232, 0.4)',
            headerColor: 'rgb(137, 241, 232, 0.4)',
            title: 'MD Care Sessions'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(137, 241, 232, 0.4)',
            headerColor: 'rgb(137, 241, 232, 0.4)',
            title: 'MD Care Sessions'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapAdminWorkSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(185, 203, 221, 0.4)',
            headerColor: 'rgb(185, 203, 221, 0.4)',
            title: 'Admin Work'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(185, 203, 221, 0.4)',
            headerColor: 'rgb(185, 203, 221, 0.4)',
            title: 'Admin Work'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapParentTrainingSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  console.log(type, view, date, providersData);
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();
      console.log(formattedEndDateOfEvalSlot);
      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgba(190, 109, 183, 0.4)',
            headerColor: 'rgba(190, 109, 183, 0.4)',
            title: 'Parent Training'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgba(190, 109, 183, 0.4)',
            headerColor: 'rgba(190, 109, 183, 0.4)',
            title: 'Parent Training'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapEEGReadSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(22, 245, 137, 0.2)',
            headerColor: 'rgb(22, 245, 137, 0.2)',
            title: 'EEG Read'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(22, 245, 137, 0.2)',
            headerColor: 'rgb(22, 245, 137, 0.2)',
            title: 'EEG Read'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
export const mapABAAssesmentObservationSlotsToCalendar = (
  events: any,
  type: calendarGroup,
  view: CalendarView,
  date: Date,
  providersData: any
) => {
  let mappedEvents: any[] = [];
  events.map((ArrayOfMultipleProviders: any[]) => {
    ArrayOfMultipleProviders.map(singleProvidersEvalSlots => {
      mappedEvents.push(singleProvidersEvalSlots);
    });
  });
  console.log(date, 'inside if block of provide maptoCV2', type);
  const mapEvalEvents = mappedEvents.map(
    (e: {
      startTime: moment.MomentInput | undefined;
      endTime: moment.MomentInput | undefined;
      type: any;
      id: any;
      clinics: any;
      dayOfWeek: number;
      view: View;
      location: number;
      isInClinic: boolean;
      isOffsite: boolean;
      isTelehealth: boolean;
      providerId: any;
    }) => {
      let selDate = moment(date).format('YYYY-MM-DD');
      const filteredClinic = providersData.providerWithOpenAvailability.providertemplatewithavailability.filter(
        (providerInfo: { id: number; clinic: any }) => {
          return providerInfo.id === e.providerId;
        }
      );
      const clinicTimeZone = filteredClinic[0]
        ? filteredClinic[0].clinic
          ? filteredClinic[0].clinic.timezone
          : ''
        : '';
      e.startTime = getLocalBrowserTime(clinicTimeZone, selDate, e.startTime);
      e.endTime = getLocalBrowserTime(clinicTimeZone, selDate, e.endTime);
      const currentWeekStartDate = moment().startOf('week');
      const startDateOfEvalSlot = currentWeekStartDate.add(e.dayOfWeek, 'days');

      const formattedStartDateOFEvalSlot = startDateOfEvalSlot
        .utc()
        .toISOString();
      const startTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.startTime
      );
      const isoStartTimeOfEvalSlots = startTimeOfEvalSlot.toISOString();

      const endTimeOfEvalSlot = moment(
        startDateOfEvalSlot.format('YYYY MM DD') + ' ' + e.endTime
      );
      const isoEndTimeOfEvalSlots = endTimeOfEvalSlot.toISOString();
      const formattedStartTime = e.startTime;
      const formattedEndTime = e.endTime;
      const duration: any = moment.duration(
        moment(e.endTime, 'HH:mm').diff(moment(e.startTime, 'HH:mm'))
      );

      const formattedEndDateOfEvalSlot = moment(
        moment(formattedStartDateOFEvalSlot)
      )
        .add(parseInt(duration.asMinutes()) % 60)
        .toISOString();

      let resourceID = e.providerId ? e.providerId : undefined;
      let plannerWeekStartTime;
      let plannerWeekEndTime;

      if (view === views.PLANNER_WEEK) {
        plannerWeekStartTime = getPlannerWeekTime(
          (isoStartTimeOfEvalSlots as unknown) as Date,
          date
        );
        plannerWeekEndTime = getPlannerWeekTime(
          (isoEndTimeOfEvalSlots as unknown) as Date,
          date
        );
        const eventDay = getEventDayIndex(
          (startTimeOfEvalSlot.toISOString() as unknown) as Date
        );
        resourceID = `${resourceID}:${eventDay}`;
      }
      console.log('formatted endß date', formattedEndDateOfEvalSlot);
      const dateToBeUsed = date.toString();
      const substringtoRemove = date.toString().substring(16, 24);
      const dateForTimingOfEvalSlot = dateToBeUsed.replace(
        substringtoRemove,
        ''
      );
      const locationTypeVal = getAvailableLocationType(
        e.isInClinic,
        e.isOffsite,
        e.isTelehealth
      );
      if (
        moment()
          .day(e.dayOfWeek)
          .toString()
          .slice(0, 3) === dateForTimingOfEvalSlot.slice(0, 3)
      ) {
        const startTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedStartTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endTimeOfAnyEvalSlot =
          dateForTimingOfEvalSlot.slice(0, 16) +
          formattedEndTime +
          ':00' +
          dateForTimingOfEvalSlot.slice(16);
        const endDate = new Date(endTimeOfAnyEvalSlot);
        const starDate = new Date(startTimeOfAnyEvalSlot);
        return {
          appointmentType: {
            backgroundColor: 'rgb(250, 212, 172, 0.6)',
            headerColor: 'rgb(250, 212, 172, 0.6)',
            title: 'ABA Assessment: Observation'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          endTime: moment(endDate),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          startTime: moment(starDate),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      } else {
        return {
          appointmentType: {
            backgroundColor: 'rgb(250, 212, 172, 0.6)',
            headerColor: 'rgb(250, 212, 172, 0.6)',
            title: 'ABA Assessment: Observation'
          },
          athenaStatus: undefined,
          baseId: e.providerId,
          crStatus: undefined,
          end: endTimeOfEvalSlot.toISOString(),
          groupType: calendarGroup.provider,
          id: e.providerId,
          plannerWeekStartTime: plannerWeekStartTime,
          plannerWeekEndTime: plannerWeekEndTime,
          resourceId: resourceID,
          start: startTimeOfEvalSlot.toISOString(),
          title: locationTypeVal,
          type: EventType.adminEvent,
          calendarID: `staff:${e.id}`,
          canMove: false,
          clinics: e.clinics,
          color: '#1027a5',
          evalSlotLocation: locationTypeVal
        };
      }
    }
  );
  return mapEvalEvents;
};
const isParentAppointmentType = (appointmentType?: IFullAppointmentType) =>
  appointmentType?.eventType?.name !== EVENT_TYPES.DT;

const adjustAppointmentType = (
  event: IEvent,
  backgroundColor: string,
  headerColor: string
) => {
  if (
    event?.appointmentType &&
    isParentAppointmentType(event?.appointmentType)
  ) {
    event.appointmentType = {
      ...event.appointmentType,
      backgroundColor,
      headerColor
    };
  } else if (event?.appointmentType) {
    event.appointmentType = {
      ...event.appointmentType,
      parent: {
        ...event.appointmentType.parent,
        backgroundColor,
        headerColor
      }
    };
  }
};

const setCalenderEventStyle = (event: IEvent) => {
  let backgroundColor;
  let headerColor;
  if (event.status === EVENT_STATUS.canceled && event.isDelete === false) {
    backgroundColor = APPOINTMENT_BACKGROUND_COLORS.CANCELED;
    headerColor = APPOINTMENT_HEADER_COLORS.CANCELED;
    adjustAppointmentType(event, backgroundColor, headerColor);
  } else if (event.isDelete === true) {
    backgroundColor = APPOINTMENT_BACKGROUND_COLORS.DELETED;
    headerColor = APPOINTMENT_HEADER_COLORS.DELETED;
    adjustAppointmentType(event, backgroundColor, headerColor);
  } else if (event.type === EventType.adminEvent) {
    backgroundColor = APPOINTMENT_BACKGROUND_COLORS.ADMIN;
    headerColor = APPOINTMENT_HEADER_COLORS.ADMIN;
    adjustAppointmentType(event, backgroundColor, headerColor);
  }
};

//if pending not selected providers will not be filtered ,
//else if pending set default to false until a pending event is found
const initProvidersFiltersLookUp = (
  providers: ICalendarResource[],
  isPendingFilter: boolean
) => {
  const initProvidersFiltersObj: Record<string, any> = {};
  for (const provider of providers) {
    initProvidersFiltersObj[provider?.id!] = {
      [LOCATION_TYPE_LIST[0].id]: false,
      [LOCATION_TYPE_LIST[1].id]: false,
      [LOCATION_TYPE_LIST[2].id]: false,
      [internalFilters.pending]: !isPendingFilter ? true : false
    };
  }
  return initProvidersFiltersObj;
};

/**{
    ...event,
    calendarID: Unique id for the calendar (clieng:EventID) /(staff:EventID),
    start: Start of event used by react-big-calendar
    end: End of event used by react-big-calendar,
    startTime:  Start of event used by react-calendar-timeline,
    endTime: End of event used by react-calendar-timeline,
    plannerWeekStartTime: Selected Week First day Date + event's original start time
    plannerWeekEndTime: Selected Week First day Date + event's original end time
    resourceId: (client/provider) ID (planner day) , (client:DayIndex/provider:DayIndex) (planner week)
    baseId: (client/provider) ID
  }; **/

const mapToClientEvent = (
  event: IEvent,
  view: CalendarView,
  date: Date
): ICalendarEvent => {
  const eventsApptType = event.appointmentType;
  let resourceID = event.client?.id ? event.client?.id.toString() : '';
  let plannerWeekStartTime;
  let plannerWeekEndTime;

  if (view === views.PLANNER_WEEK) {
    plannerWeekStartTime = getPlannerWeekTime(event.startDate as Date, date);
    plannerWeekEndTime = getPlannerWeekTime(event.endDate as Date, date);
    const eventDay = getEventDayIndex(event.startDate as Date);
    resourceID = `${resourceID}:${eventDay}`;
  }

  return {
    ...event,
    calendarID: `client:${event.id}`,
    start: new Date(event.startDate as Date),
    end: new Date(event.endDate as Date),
    startTime: moment(event.startDate),
    endTime: moment(event.endDate),
    plannerWeekStartTime,
    plannerWeekEndTime,
    color: eventsApptType?.headerColor,
    groupType: calendarGroup.client,
    isDelete: event.isDelete,
    title:
      event.provider?.name ||
      formatUsernameOriginal(
        event.provider?.firstName,
        event.provider?.lastName
      ),
    resourceId: resourceID,
    baseId: event.client?.id ? event.client?.id.toString() : undefined,
    client: event.client,
    evalSlotLocation: null
  };
};

const mapToStaffEvent = (
  event: IEvent,
  view: CalendarView,
  date: Date
): ICalendarEvent => {
  const eventsApptType = event.appointmentType;
  let resourceID =
    event?.provider?.id && event?.provider?.status !== 'Inactive'
      ? event?.provider.id.toString()
      : undefined;

  let plannerWeekStartTime;
  let plannerWeekEndTime;

  if (view === views.PLANNER_WEEK) {
    plannerWeekEndTime = getPlannerWeekTime(event.endDate as Date, date);
    plannerWeekStartTime = getPlannerWeekTime(event.startDate as Date, date);
    const eventDay = getEventDayIndex(event.startDate as Date);
    resourceID = `${resourceID}:${eventDay}`;
  }
  return {
    ...event,
    calendarID: `staff:${event.id}`,
    start: new Date(event.startDate as Date),
    end: new Date(event.endDate as Date),
    startTime: moment(event.startDate),
    endTime: moment(event.endDate),
    color: eventsApptType?.headerColor,
    isDelete: event?.isDelete,
    plannerWeekStartTime,
    plannerWeekEndTime,

    groupType: calendarGroup.provider,
    title: event.isObservation
      ? event.leadEvent?.provider?.name ||
        formatUsernameOriginal(
          event.provider?.firstName,
          event.provider?.lastName
        )
      : event.client?.name ||
        formatUsernameOriginal(event.client?.firstName, event.client?.lastName),
    resourceId: resourceID,
    baseId: event.provider?.id ? event.provider?.id.toString() : undefined,
    client: event.client,
    evalSlotLocation: null
  };
};

const pushIntoCalendarEvents = (
  event: IEvent,
  events: ICalendarEvent[],
  providers: ICalendarResource[],
  clients: ICalendarResource[] | undefined,
  view: CalendarView,
  calendarDate: Date
) => {
  const providerId = event?.provider?.id;
  const clientId = event?.client?.id;
  if (
    providers.some(
      provider => provider.id === providerId && provider.status !== 'Inactive'
    )
  ) {
    events.push(
      mapToCalendarEventsV2([event], calendarGroup.provider, view, calendarDate)
        .finalEvents[0]
    );
  }
  if (clients?.some(client => client.id === clientId)) {
    events.push(
      mapToCalendarEventsV2([event], calendarGroup.client, view, calendarDate)
        .finalEvents[0]
    );
  }
};

export const prepareEventsAfterReschedule = (
  updatedEvent: IEvent,
  events: ICalendarEvent[],
  providers: ICalendarResource[],
  clients: ICalendarResource[] | undefined,
  view: CalendarView,
  calendarDate: Date
): ICalendarEvent[] => {
  const currentEvents = events.filter(event => event.id !== updatedEvent.id);
  pushIntoCalendarEvents(
    updatedEvent,
    currentEvents,
    providers,
    clients,
    view,
    calendarDate
  );
  return currentEvents;
};

export const mapToCalendarDropEventV2 = (
  eventId: number,
  events: ICalendarEvent[],
  providers: ICalendarResource[],
  newEvent: IEvent,
  view: CalendarView,
  calendarDate: Date,
  clients?: ICalendarResource[]
): ICalendarEvent[] => {
  const observationEvents = events.filter(
    e =>
      e.leadEvent?.id === eventId &&
      e.leadEvent?.provider?.status !== 'Inactive'
  );
  const eventsWithoutDragged = events.filter(
    e =>
      e.id !== eventId &&
      e.leadEvent?.id !== eventId &&
      e.leadEvent?.provider?.status !== 'Inactive'
  );
  const eventsWithDrageed = events.filter(
    e => e.id === eventId && e.leadEvent?.provider?.status !== 'Inactive'
  );
  let newObservationEvents: any[] = [];
  if (eventsWithDrageed[0].appointmentType?.eventType?.name === 'ABA') {
    const leadEventStartDate = moment(eventsWithDrageed[0]?.startDate);

    const newEventStartDate = moment(newEvent.startDate);

    const forwardOffset = moment
      .duration(newEventStartDate.diff(leadEventStartDate))
      .asMinutes();
    newObservationEvents = observationEvents?.map((e: ICalendarEvent) => {
      const observerStartDate = moment(e.startDate);
      const observerEndDate = moment(e.endDate);
      const observerDuration = moment
        .duration(observerEndDate.diff(observerStartDate))
        .asMinutes();
      const momentStartDate = moment(e.startDate).add(forwardOffset, 'minutes');
      const momentEndDate = moment(momentStartDate).add(
        observerDuration,
        'minutes'
      );
      return {
        ...e,
        startDate: momentStartDate,
        endDate: momentEndDate
      };
    });
  } else {
    newObservationEvents = observationEvents?.map((e: ICalendarEvent) => ({
      ...e,
      startDate: newEvent.startDate,
      endDate: newEvent.endDate
    }));
  }
  pushIntoCalendarEvents(
    newEvent,
    eventsWithoutDragged,
    providers,
    clients,
    view,
    calendarDate
  );

  if (newObservationEvents.length > 0) {
    eventsWithoutDragged.push(
      ...mapToCalendarEventsV2(
        newObservationEvents,
        calendarGroup.provider,
        view,
        calendarDate
      ).finalEvents
    );
  }

  return eventsWithoutDragged;
};
