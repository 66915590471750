import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
  ICalendarFilterOption,
  IClinicFilterData
} from 'model/calendar/filters';
import { mapClinicsToOptionsv1 } from 'utils/mappers/form';
import CalendarFilter from '../../../ui/calendarFilter';

interface Props {
  clinicsList: IClinicFilterData[];
  handleSearchedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  checkedClinics: ICalendarFilterOption[];
  setCheckedClinics: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  setHasData: Dispatch<SetStateAction<boolean>>;
  searchParams?: URLSearchParams;
  updateHistory?: () => void;
  title?: string;
}

const ClinicFilter = ({
  clinicsList,
  handleSearchedValues,
  checkedClinics,
  setCheckedClinics,
  setHasData,
  searchParams,
  updateHistory,
  title
}: Props) => {
  const clinics = useMemo(
    () => (clinicsList ? mapClinicsToOptionsv1(clinicsList) : []),

    [clinicsList]
  );

  const searchedClinics = useMemo(() => {
    const searchedValues = handleSearchedValues(clinics);

    return searchedValues;
  }, [handleSearchedValues, clinics]);

  useEffect(() => {
    searchedClinics?.length === 0 ? setHasData(false) : setHasData(true);
  }, [searchedClinics, setHasData]);

  return (
    <>
      <CalendarFilter
        setCheckedList={setCheckedClinics}
        checkedList={checkedClinics}
        listOptions={searchedClinics}
        length={clinics.length}
        title={title || 'Clinic'}
        searchParams={searchParams}
        updateHistory={updateHistory}
      />
    </>
  );
};

export default React.memo(ClinicFilter);
