import { BigCalendar } from 'lib/ui';
import { NavigateAction, View } from 'react-big-calendar';
import { customizeRange, getNavigationDate } from 'utils/builders/calendar';

import {
  STAFF_CALENDAR_MAX_HEIGHT,
  calendarIntervals
} from 'utils/constants/calendar';
import { localizer } from 'utils/builders/calendar';
import React, { useCallback, useMemo, useState } from 'react';
import { CalendarWrapper } from '../style';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import {
  createProviderSubBlocks,
  handleDroppedEvent,
  handleLowerTouchingEvents,
  handleUpperTouchingEvents,
  removeAllOverlappingEventsWithEditedOneAndAddNewEvent,
  removeOverlappingEvents,
  // durationInMinutes,
  // addMinutes,
  checkIfLessThanFiveMinsAndUpdate
} from './utils';
import { PlannerView, views } from 'utils/cache/calendar';
import { calendarDayMoment, calendarWeekMoment } from 'utils/format';
import { Event } from 'react-big-calendar';
import ModalSemantic from 'components/modalSemantic';
import EditForm from 'components/AvailabilityTemplateModalForm/EditForm';
import { ContentWrapper } from 'views/components/ui/card/card.style';
import { Appointment_Types } from 'components/AvailabilityTemplateModalForm/EditForm/dropdownOptions';
import MainCalendarToolbar from 'components/calendar/calendarToolbar';

import { mapArrayOfIdsToObject } from 'utils/mappers/request/Provider';
import { IClinic } from 'model/v2/clinic';
import { getLocationColor, getSlotsStyleColor } from '../utils';
import { ILocation } from 'model/v2/location';
import { CalenderEvent } from 'model/v2/availabilityTemplate';
import ClinicBarCalendar from './ClinicBarCalendar';
import { getClinicsAbbreviation } from 'utils/mappers/availabilityTemplate';
import { SCROLL_TIME_CALENDAR } from 'utils/constants/calendar';

interface IProps {
  events: CalenderEvent[];
  updateEvents: (
    value: CalenderEvent[] | ((prevVar: CalenderEvent[]) => CalenderEvent[])
  ) => void;
  bannerText?: string;
  department?: string;
  providerId?: number;
  alertText?: string;
  clinics?: IClinic[];
  providerDefaultClinicsIds?: number[];
  locations: ILocation[];
  selectedDate: Date;
  handleNavigation: (date: Date) => void;
  handleViewChange: () => void;
  disableNavigation?: boolean;
  handleAddAlternateModal: () => void;
  children?: React.ReactNode;
  isProviderAvailabilityMode?: boolean;
  editModeState: boolean;
  handleEditClick: () => void;
}

const TemplateCalender: React.FC<IProps> = ({
  events,
  bannerText,
  updateEvents,
  providerId,
  alertText,
  clinics,
  providerDefaultClinicsIds,
  locations,
  selectedDate,
  handleNavigation,
  handleViewChange,
  disableNavigation = false,
  department,
  children,
  isProviderAvailabilityMode,
  editModeState,
  handleEditClick
}: IProps) => {
  const view = PlannerView.plannerWeek;
  const DragAndDropCalendar = withDragAndDrop(BigCalendar);
  const [editModal, setEditModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [addedEvent, setAddedEvent] = useState<CalenderEvent | undefined>(
    undefined
  );
  const formats = {
    dayFormat: 'ddd'
  };

  const clinicsAbbreviations = useMemo(() => {
    return getClinicsAbbreviation(events, clinics);
  }, [clinics, events]);

  const customEventPropGetter = useCallback(
    (event: any) => {
      const appt_Type = Appointment_Types.find((appt_type: any) => {
        return appt_type.value === event.type;
      });
      if (appt_Type && appt_Type.colorRank <= 1) {
        return { className: `${event.type?.toLowerCase()}` };
      } else if (appt_Type && appt_Type.colorRank === 2) {
        const style = getSlotsStyleColor(appt_Type);
        return { style };
      } else {
        const style = getLocationColor(locations, event.location);

        if (event.type === 'evalslot' && event.location === locations[0]?.id) {
          return { className: 'evalslot' };
        } else {
          return { style };
        }
      }
    },
    [locations]
  );

  const titleAccessor = (event: Event) => {
    return event.title !== undefined ? event.title.toUpperCase() : '';
  };

  const moveEvent = useCallback(
    ({ event, start, end }: any) => {
      const idx = events.indexOf(event);
      if (end.getHours() < start.getHours()) {
        return;
      }
      if (event.start.getDay() === start.getDay()) {
        const updatedEvent: CalenderEvent = {
          ...event,
          start,
          end
        };
        events.splice(idx, 1, updatedEvent);
        removeOverlappingEvents(updatedEvent, events);
        handleDroppedEvent(updatedEvent, events);
        handleUpperTouchingEvents(updatedEvent, events);
        handleLowerTouchingEvents(updatedEvent, events);
        updateEvents([...events]);
      }
    },
    [events, updateEvents]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }: any) => {
      const indexOfNewEvent = events.indexOf(event);
      const updatedEvent = {
        ...event,
        start,
        end
      };

      checkIfLessThanFiveMinsAndUpdate(updatedEvent);
      events.splice(indexOfNewEvent, 1, updatedEvent);
      removeOverlappingEvents(updatedEvent, events);
      handleUpperTouchingEvents(updatedEvent, events);
      handleLowerTouchingEvents(updatedEvent, events);
      updateEvents([...events]);
    },
    [updateEvents, events]
  );

  const onEventClick = useCallback((event: Event) => {
    setSelectedEvent(event);
    setEditModal(true);
  }, []);

  const onEditModalClose = useCallback(() => {
    if (addedEvent) {
      updateEvents((prev: CalenderEvent[]) => {
        let newEvents = prev;
        const idx = newEvents.indexOf(addedEvent);
        newEvents.splice(idx, 1);
        setAddedEvent(undefined);
        return newEvents;
      });
    }
    setEditModal(false);
  }, [addedEvent, updateEvents]);

  const onEditModalSubmit = useCallback(
    (data: any, submittedEvent: CalenderEvent) => {
      if (addedEvent) setAddedEvent(undefined);
      const idx = events.indexOf(submittedEvent);
      const returnedClinics = mapArrayOfIdsToObject(data.clinics);
      const selectedOption = Appointment_Types.find(
        type => type.value === data.appt_type
      );
      const label = selectedOption?.label ?? data.appt_type;
      const submittedType = selectedOption?.value ?? data.appt_type;
      let newEvents: CalenderEvent[];
      if (idx > -1) {
        const updatedEvent: CalenderEvent = {
          id: submittedEvent?.id,
          title: label,
          type: submittedType,
          location: data.location,
          isAdmin: data.appt_type !== 'working',
          start: data.start,
          end: data.end,
          clinics: returnedClinics?.length === 0 ? undefined : returnedClinics
        };
        events.splice(idx, 1, updatedEvent);
      } else {
        submittedEvent.title = label;
        events.push(submittedEvent);
      }
      newEvents = [...events];

      const hasSublocks = data.sub_block_length > 0;
      if (hasSublocks) {
        data.recurringDays.forEach((day: number) => {
          let tempEvent = removeAllOverlappingEventsWithEditedOneAndAddNewEvent(
            newEvents,
            data,
            day,
            label,
            submittedType
          );

          newEvents = createProviderSubBlocks(
            newEvents,
            tempEvent,
            data,
            locations[0]
          );
        });

        updateEvents([...newEvents]);
        setEditModal(false);
      } else {
        data.recurringDays.forEach((day: number) => {
          removeAllOverlappingEventsWithEditedOneAndAddNewEvent(
            events,
            data,
            day,
            label,
            submittedType
          );
        });

        updateEvents([...events]);
        setEditModal(false);
      }
    },
    [addedEvent, events, updateEvents, locations]
  );

  const onDelete = useCallback(
    (selectedEvent: CalenderEvent) => {
      const idx = events.indexOf(selectedEvent);

      updateEvents((prev: CalenderEvent[]) => {
        let newEvents = prev;
        newEvents.splice(idx, 1);
        return [...newEvents];
      });
      setEditModal(false);
    },
    [updateEvents, events]
  );

  const createNewEvent = useCallback(
    (start: Date, end: Date) => {
      // With a 15 minute step size (<DragAndDropCalendar/>.props.step), we no longer need to nudge this minimum time.
      // I am keeping this around as an eloquent solution should the need arise again
      // if (durationInMinutes(end, start) < 10) {
      //   end = addMinutes(end, 10);
      // }
      const event: CalenderEvent = {
        end: end,
        start: start,
        title: Appointment_Types[0].value,
        type: Appointment_Types[0].value,
        location: locations[0].id!,
        isAdmin: false
      };

      onEventClick(event);
      updateEvents((prev: CalenderEvent[]) => {
        let newEvents = prev;
        setAddedEvent(event);
        newEvents.push(event);
        return newEvents;
      });
    },
    [locations, onEventClick, updateEvents]
  );

  const onCalenderClick = useCallback(
    ({ start, end }) => {
      createNewEvent(start, end);
    },
    [createNewEvent]
  );

  const onNavigate = useCallback(
    (navigationAction: NavigateAction) => {
      const navigationDate = getNavigationDate(
        navigationAction,
        selectedDate,
        view
      );
      handleNavigation(navigationDate);
    },
    [handleNavigation, selectedDate, view]
  );

  const toolbarData = useMemo(() => {
    const data = {
      label:
        view === PlannerView.plannerWeek
          ? calendarWeekMoment(selectedDate)
          : calendarDayMoment(selectedDate),
      onNavigate: onNavigate,
      view: view,
      date: selectedDate,
      onView: handleViewChange
    };
    return data;
  }, [handleViewChange, onNavigate, selectedDate, view]);

  const customToolbar = useCallback(
    (): JSX.Element => (
      <>
        <MainCalendarToolbar
          bannerText={bannerText}
          customViewEnabled={true}
          data={toolbarData}
          range={customizeRange(
            selectedDate,
            view === views.PLANNER_WEEK
              ? (calendarIntervals.WEEK as View)
              : (calendarIntervals.DAY as View)
          )}
          handleNavigation={handleNavigation}
          disabled={disableNavigation}
          inEditMode={editModeState}
          handleEditClick={handleEditClick}
          alertText={alertText}
        />
        <ClinicBarCalendar clinics={clinicsAbbreviations} />
      </>
    ),

    [
      bannerText,
      toolbarData,
      selectedDate,
      view,
      handleNavigation,
      disableNavigation,
      clinicsAbbreviations,
      editModeState,
      handleEditClick
    ]
  );
  const calendarComponents = {
    toolbar: customToolbar
  };

  return (
    <>
      <CalendarWrapper>
        <DragAndDropCalendar
          selectable={editModeState ? true : false}
          draggableAccessor={() => (editModeState ? true : false)}
          resizableAccessor={() => (editModeState ? true : false)}
          views={[views.DAY, views.WEEK] as View[]}
          defaultView={'week'}
          defaultDate={selectedDate}
          toolbar={true}
          scrollToTime={SCROLL_TIME_CALENDAR}
          events={events}
          step={15}
          timeslots={4}
          localizer={localizer}
          formats={formats}
          style={{
            height: STAFF_CALENDAR_MAX_HEIGHT
          }}
          components={calendarComponents}
          titleAccessor={titleAccessor}
          onSelectEvent={editModeState ? onEventClick : () => null}
          onEventDrop={moveEvent}
          resizable
          onEventResize={resizeEvent}
          eventPropGetter={customEventPropGetter}
          onSelectSlot={editModeState ? onCalenderClick : () => null}
        />
        {children}
      </CalendarWrapper>

      {editModal && (
        <ContentWrapper>
          <ModalSemantic
            open={true}
            onClose={onEditModalClose}
            modalWidth={800}
            title={'Edit Availability Block'}
            trigger={<div></div>}
            content={
              <EditForm
                onClose={onEditModalClose}
                onSubmit={onEditModalSubmit}
                onDelete={onDelete}
                locations={locations}
                data={selectedEvent}
                providerId={providerId}
                clinics={clinics}
                providerDefaultClinicsIds={providerDefaultClinicsIds}
                department={department}
                isProviderAvailabilityMode={isProviderAvailabilityMode}
              />
            }
          />
        </ContentWrapper>
      )}
    </>
  );
};

export default TemplateCalender;
