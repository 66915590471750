import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { IProvider } from 'model/v2';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { FormItem } from 'views/containers/form';
import OffsiteLocation from '../../../../views/components/forms/appointment/components/Location/OffsiteLocation';
import { LocationFormWrapper } from '../style';

const locationTypes = mapToOption(LOCATION_TYPE_LIST);

interface IProps {
  providersMap: Map<any, IProvider>;
}
const LocationForm: React.FC<IProps> = ({ providersMap }) => {
  const methods = useFormContext();
  const watched = useWatch({
    name: ['client', 'locationType'],
    control: methods.control
  });

  const onLocationTypeSelect = React.useCallback(
    (locationTypeId: string) => {
      if (locationTypeId === LOCATION_TYPE_LIST[2].id) {
        const providerId = methods.getValues('provider.id');
        const provider = providersMap.get(providerId);
        if (provider) {
          methods.setValue('clinic', { ...provider.clinic });
        } else {
          methods.setValue('clinic', { id: undefined });
        }
      } else if (locationTypeId === LOCATION_TYPE_LIST[0].id) {
        methods.setValue('clinic', { ...watched?.client?.clinic });
      }
    },
    [methods, providersMap, watched]
  );

  return (
    <LocationFormWrapper>
      <h2 className="section-title">Location</h2>
      <div className="location-first-row">
        <FormItem optional={false} label="Location Type">
          <Controller
            name="locationType"
            control={methods.control}
            render={fieldProps => (
              <Select
                className="appt-category-type"
                placeholder="Location Type"
                onSelect={onLocationTypeSelect}
                options={locationTypes}
                field={fieldProps}
                errors={methods.errors}
              />
            )}
          />
        </FormItem>
      </div>

      {watched.locationType === LOCATION_TYPE_LIST[1].id && (
        <OffsiteLocation clientId={watched.client.id} />
      )}
    </LocationFormWrapper>
  );
};
export default React.memo(LocationForm);
