import React, { useState } from 'react';
import { Table } from 'lib/ui';
import { useQuery } from '@apollo/react-hooks';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { GET_CLIENT_WAITLIST } from 'api/graphql/queries/waitlist';
import { alphabeticalSort } from 'utils/sort';
import { adjustWaitlistPending } from 'helpers/clientWaitlistHelper';
import Content from 'views/components/ui/content';
import { useFormContext, useWatch } from 'react-hook-form';

const Waitlist = ({ columns, client }) => {
  const { control } = useFormContext();

  const {
    repeat,
    appType,
    appSubType,
    selectedSlot,
    recurringUntil,
    duration
  } = useWatch({
    control,
    name: [
      'repeat',
      'appType',
      'appSubType',
      'selectedSlot',
      'recurringUntil',
      'duration'
    ]
  });
  const [waitlist, setWaitlist] = useState([]);
  const [apptTypes, setApptTypes] = useState([]);

  const { data: waitlistData, loading, error } = useQuery(GET_CLIENT_WAITLIST, {
    variables: { clientID: client?.id },
    onCompleted: data => {
      setWaitlist(data.clientWaitlist);
      setApptTypes(
        data.appointmentTypes
          .concat(data.appointmentABATypes.map(a => ({ ...a, isABA: true })))
          .sort((appt1, appt2) => alphabeticalSort(appt1.title, appt2.title))
      );
    },
    skip: !client?.id
  });

  const start = selectedSlot?.start || undefined;

  const data = adjustWaitlistPending(
    waitlist,
    apptTypes,
    start,
    repeat,
    recurringUntil,
    duration,
    appType,
    appSubType
  );

  const customExpandIcon = props => {
    if (props.expanded) {
      return (
        <CaretDownOutlined onClick={e => props.onExpand(props.record, e)} />
      );
    } else {
      return (
        <CaretRightOutlined
          onClick={e =>
            props.record.description && props.onExpand(props.record, e)
          }
        />
      );
    }
  };

  return (
    <div className="profile_table waitlist_wrap">
      <div className="profile_table__container">
        <Content loading={loading} data={waitlistData} error={error}>
          {() => (
            <Table
              columns={columns}
              dataSource={data}
              rowKey={record => record.key}
              pagination={false}
              expandedRowRender={record => record.description}
              expandIcon={props => customExpandIcon(props)}
              bordered
            />
          )}
        </Content>
      </div>
    </div>
  );
};

export default Waitlist;
