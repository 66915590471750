import React from 'react';
import * as PropTypes from 'prop-types';

import { LibTag } from './tag.style';

const Tag = ({ children, ...restProps }) => {
  return <LibTag {...restProps}>{children}</LibTag>;
};

Tag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Tag.defaultProps = {
  children: null
};

export default Tag;
export { Tag };
