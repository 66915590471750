import React, { useCallback, useState, useEffect, useRef } from 'react';
import { MODAL_AUTOMATA } from './ModalWrapper';
import { EditTimeModalWrapper } from './MainCalendar.style';
import { Select, TextArea } from 'views/containers/form';
import { Field, Formik } from 'formik';
import { APPOINTMENT_BY_MINUTE_VALIDATOR } from 'utils/validators/appointmentByMinute';
import moment from 'moment';
import { Button } from 'lib/ui';
import { useMutation } from '@apollo/react-hooks';
import { EDIT_EVENT_TIME } from 'api/graphql/v2/mutations/Event';
import { DEFAULT_EDIT_TIME_NOTE } from 'utils/constants/default';

// time padding for edit form
export const TIME_PADDING = 60;

// both are out of the functional component to avoid unnecessary rerenders

// init the options fields
export const setOptions = (
  startTime,
  endTime,
  originalStartDate,
  originalEndDate,
  validOptions,
  setStartTimeOptions,
  setEndTimeOptions,
  timePadInMinutes
) => {
  // comparing to the originals always
  // the only integrity condition needed is originalStart and end
  const refStartTime = originalStartDate || startTime;
  const refEndTime = originalEndDate || endTime;
  // original duration of event
  const diffMinutes = moment(refEndTime).diff(moment(refStartTime), 'minutes');
  // from the original we shall start the start time to has offset = 0
  const startTimeMoment = moment(refStartTime);
  const arrayOfOffsets = [
    ...Array(diffMinutes + 1 + 2 * timePadInMinutes)
      .fill()
      .map((_, index) => index - timePadInMinutes)
  ];

  // continue 1 hour before start time and 1 hour after end time of original
  startTimeMoment.subtract(timePadInMinutes + 1, 'minute');
  // create ooptions
  const options = arrayOfOffsets.map(el => {
    startTimeMoment.add(1, 'minutes');
    return {
      value: el, // add array of offset
      label: startTimeMoment.format('hh:mm a')
    };
  });
  // set ref for further use not re use computations
  validOptions.current = options;
  // filter start time less than the original end time
  setStartTimeOptions(options.filter(el => el.value < diffMinutes));
  // filter end time greater than original offset 0
  setEndTimeOptions(options.filter(el => el.value > 0)); // initital init
};

export const getStartOffset = (originalStartDate, startTime) => {
  return originalStartDate
    ? moment(startTime).diff(originalStartDate, 'minutes')
    : 0;
};

export const getEndOffset = (originalStartDate, startTime, endTime) => {
  return (
    moment(endTime).diff(moment(startTime), 'minutes') +
    (originalStartDate
      ? moment(startTime).diff(originalStartDate, 'minutes')
      : 0)
  );
};

export const ModalEditTime = ({ setStep, event, closeModal }) => {
  const handleCancel = useCallback(() => {
    setStep(MODAL_AUTOMATA.MODAL_CONTENT);
  }, [setStep]);

  const [startTimeOptions, setStartTimeOptions] = useState([]);
  const [endTimeOptions, setEndTimeOptions] = useState([]);
  const validOptions = useRef([]);

  const [editEventTime] = useMutation(EDIT_EVENT_TIME);

  const onSubmit = useCallback(
    (values, actions) => {
      actions.setSubmitting(true);
      // taking the absolute truth of the start
      const eventStart = moment(event.originalStartDate || event.startDate);
      editEventTime({
        variables: {
          data: {
            event: {
              startDate: eventStart
                .clone()
                .add(values.startTimeOffset, 'minutes')
                .toISOString(),
              endDate: eventStart
                .clone()
                .add(values.endTimeOffset, 'minutes')
                .toISOString(),
              id: values.id
            },
            notes: values.notes
          }
        }
      })
        .then(() => {
          actions.setSubmitting(false);
          closeModal();
        })
        .catch(err => {
          console.log(err);
          closeModal();
        });
    },
    [event.originalStartDate, event.startDate, editEventTime, closeModal]
  );

  const handleStartTimeSelection = useCallback(
    (val, endTimeOffset, setFieldValue) => {
      if (val >= endTimeOffset) setFieldValue('endTimeOffset', undefined);
      // end time options should be greater than event start time original
      setEndTimeOptions(
        validOptions.current.filter(el => el.value > Math.max(val, 0))
      );
    },
    []
  );

  useEffect(() => {
    setOptions(
      event.startDate,
      event.endDate,
      event.originalStartDate,
      event.originalEndDate,
      validOptions,
      setStartTimeOptions,
      setEndTimeOptions,
      TIME_PADDING /// 1 hour padding before and after
    );
  }, [event]);

  return (
    <EditTimeModalWrapper>
      <Formik
        initialValues={{
          startTimeOffset: getStartOffset(
            event.originalStartDate,
            event.startDate
          ),
          endTimeOffset: getEndOffset(
            event.originalStartDate,
            event.startDate,
            event.endDate
          ),
          notes: DEFAULT_EDIT_TIME_NOTE,
          id: event.id
        }}
        enableReinitialize
        isInitialValid={false}
        validationSchema={APPOINTMENT_BY_MINUTE_VALIDATOR}
        onSubmit={(values, actions) => onSubmit(values, actions)}
        render={({
          values,
          isValid,
          handleSubmit,
          isSubmitting,
          dirty,
          setFieldValue
        }) => (
          <>
            <h1 className="title">Edit Appointment Time</h1>
            <div className="form">
              <div className="item">
                <label className="label">Start Time</label>
                <Field
                  name="startTimeOffset"
                  value={values.startTimeOffset}
                  render={fieldProps => (
                    <Select
                      name="startTimeOffset"
                      placeholder="Start Time"
                      options={startTimeOptions}
                      value={values.startTimeOffset}
                      onSelect={val =>
                        handleStartTimeSelection(
                          val,
                          values.endTimeOffset,
                          setFieldValue
                        )
                      }
                      {...fieldProps}
                    />
                  )}
                />
              </div>

              <div className="item">
                <label className="label">End Time</label>
                <Field
                  name="endTimeOffset"
                  value={values.endTimeOffset}
                  key="endTimeOffset"
                  render={fieldProps => (
                    <Select
                      name="endTimeOffset"
                      placeholder="End Time"
                      value={values.endTimeOffset}
                      options={endTimeOptions}
                      {...fieldProps}
                    />
                  )}
                />
              </div>
            </div>

            <div className="notes-wrapper">
              <label className="label">Notes</label>
              <Field
                name="notes"
                value={values.notes}
                render={fieldProps => (
                  <TextArea
                    name="notes"
                    placeholder="N/A"
                    value={values.notes}
                    onChange={e => setFieldValue('notes', e.target.value)}
                  />
                )}
              />
            </div>

            <div className="buttons">
              <Button
                className="button secondary"
                onClick={handleCancel}
                loading={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                className={`button ${
                  !isValid || !dirty ? 'disabled' : 'primary'
                }`}
                disabled={!isValid || !dirty}
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                <span className="underlineSpan">OK</span>
              </Button>
            </div>
          </>
        )}
      />
    </EditTimeModalWrapper>
  );
};
