import React, { useCallback, useMemo } from 'react';
import { Field, FastField } from 'formik';
import { FormItem, Select, Datepicker, TextArea } from 'views/containers/form';
import { Row } from 'antd';
import { mapToOptionClinic } from 'utils/mappers/form';

export const ClientProviderForm = React.memo(
  ({
    clinicsWithoutRooms,
    updateClinicProviderOptions,
    providerOptions,
    clientTypes,
    disabledFormFields
  }) => {
    const clinicOptions = useMemo(
      () =>
        clinicsWithoutRooms
          ? mapToOptionClinic(
              clinicsWithoutRooms?.map(clinic => ({
                id: clinic.id,
                value: {
                  name: clinic.name,
                  abbreviation: clinic.abbreviation
                }
              }))
            )
          : [],
      [clinicsWithoutRooms]
    );

    const handleClinicChange = useCallback(
      clinicId => {
        updateClinicProviderOptions(clinicId, clinicsWithoutRooms);
      },
      [updateClinicProviderOptions, clinicsWithoutRooms]
    );

    return (
      <>
        <div className="form-title">Provider/Registration</div>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem label="Home Clinic" xs={16} sm={6}>
            <Field
              name="clinic.id"
              render={fieldProps => (
                <>
                  <Select
                    options={clinicOptions}
                    notFoundContent="Loading..."
                    placeholder="Select"
                    onOptionSelected={clinic => {
                      handleClinicChange(clinic);
                      fieldProps.form.setFieldValue('usualProvider.id', null);
                    }}
                    disabled={disabledFormFields.clinic}
                    {...fieldProps}
                  />
                </>
              )}
            />
          </FormItem>
          <FormItem label="Usual Provider" xs={16} sm={6}>
            <Field
              name="usualProvider.id"
              render={fieldProps => (
                <Select
                  options={providerOptions}
                  notFoundContent="Loading..."
                  placeholder="Select"
                  disabled={disabledFormFields.usualProvider}
                  {...fieldProps}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Registration Date" xs={16} sm={6}>
            <FastField
              name="registrationDate"
              render={fieldProps => (
                <Datepicker
                  value={
                    fieldProps.field.value ? fieldProps.field.value : undefined
                  }
                  disabled={disabledFormFields.registrationDate}
                  placeholder="mm/dd/yyyy"
                  format={'MM/DD/YYYY'}
                  {...fieldProps}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Client Type" xs={16} sm={6}>
            <Field
              name="clientType"
              render={fieldProps => (
                <Select
                  options={clientTypes}
                  notFoundContent="Loading..."
                  placeholder="Select"
                  disabled={disabledFormFields.clientType}
                  {...fieldProps}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Client Type Notes" xs={16} sm={12}>
            <FastField
              name="clientTypeNote.note"
              render={({ field }) => (
                <TextArea
                  name="clientTypeNote.note"
                  value={field.value}
                  placeholder="Client Type Notes..."
                  onChange={field.onChange}
                  disabled={disabledFormFields.clientTypeNotes}
                />
              )}
            />
          </FormItem>
        </Row>
      </>
    );
  }
);
