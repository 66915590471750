import React from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext
} from 'react-hook-form';
import Label from 'components/label';
import { Input } from 'api/sharedComponents/reactHookFormComponents';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { LabelWrapper } from './style';
import { IProvider } from 'model/v2';

interface IProps {
  title: string;
  name: string;
  edit: boolean;
  disabled: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  optional?: boolean;
}
const EditableLabel: React.FC<IProps> = ({
  title,
  label,
  name,
  edit,
  disabled,
  placeholder,
  className,
  optional
}: IProps) => {
  const { errors, control } = useFormContext();
  return (
    <LabelWrapper>
      {!edit && (
        <Label
          className={className ? className : ''}
          title={title}
          value={label}
        />
      )}
      {edit && (
        <FormItem optional={optional} label={title}>
          <Controller
            control={control}
            name={name}
            render={(fieldProps: ControllerRenderProps<IProvider>) => (
              <Input
                placeholder={placeholder}
                field={fieldProps}
                errors={errors}
                disabled={disabled}
                className={`${disabled ? 'disabled ' + className : className}`}
              />
            )}
          />
        </FormItem>
      )}
    </LabelWrapper>
  );
};

export default React.memo(EditableLabel);
