import React from 'react';
import FormSection from './FormSection';
import { FormItem } from 'views/containers/form';
import { Controller, useFormContext } from 'react-hook-form';
import { TextArea } from 'api/sharedComponents/reactHookFormComponents';

export const EventNotes = React.memo(({ children, values, setFieldValue }) => {
  const { control } = useFormContext();
  return (
    <>
      <FormSection title="Other" optional>
        <FormItem label="Notes" xs={16} sm={24}>
          <Controller
            control={control}
            name="notes"
            render={fieldProps => (
              <TextArea name="notes" placeholder="Notes" field={fieldProps} />
            )}
          />
        </FormItem>
      </FormSection>
    </>
  );
});
