import styled from 'styled-components';

export const FilterFormWrapper = styled.div`
  .min-height: 50px;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0;
    padding: 32px 32px 16px 32px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 1;
    .filter-form-title {
      margin: 0;
      opacity: 0.8;
      font-size: 18px;
      font-weight: bold;
      line-height: normal;
      color: #21303a;
    }
    .tag-delete-btn {
      border: none;
      padding: 0;
      margin: 0;
      font-size: 15px;
      letter-spacing: 0.17px;
      cursor: pointer;
      outline: none;
      text-transform: uppercase !important;
      font-family: Lato;
      &.ui.basic.button {
        background-color: #fff !important;
        color: #ff6c67 !important;
        box-shadow: none;
        font-weight: bold;
      }
    }
  }
  .filter-tag-list-wrapper {
    margin: 0 0 16px 0;
    padding: 0 32px;
    .tags-container {
      width: 100%;
      overflow: unset;
      flex-wrap: wrap;
      & > div {
        margin-bottom: 5px;
      }
    }
  }
  .error {
    padding-top: 5px;
  }
  .name-field,
  .save-as-field {
    margin: 0 0 16px 0;
    padding: 0 32px;
    max-width: 364px;
    .top-wrap {
      label {
        font-size: 14px;
        color: #415766;
      }
    }
    .ant-input {
      border-color: #c9cfd3;
      box-shadow: none;
      &:focus {
        border-color: #6f4bf1;
      }
    }
    &.save-as-field {
      margin: 0 0 50px 0;
    }
  }
  .action-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #c9cfd3;
    padding: 16px 32px 16px 0;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 1;
    button {
      padding: 0;
      border: none;
      font-size: 15px;
      &.ui.button {
        box-shadow: none !important;
        &:hover {
          box-shadow: none !important;
        }
      }
      &:nth-child(1) {
        color: #919699 !important;
        margin-right: 24px !important;
      }
      &:nth-child(2) {
        letter-spacing: 0.17px;
        color: #0d77b5 !important;
        text-decoration: underline;
        opacity: 0.5;
      }
    }
  }
`;

export const SidebarFilterFormWrapper = styled.div`
  .min-height: 50px;
    .tag-delete-btn {
      border: none;
      padding: 0;
      margin: 0;
      font-size: 15px;
      letter-spacing: 0.17px;
      cursor: pointer;
      outline: none;
      text-transform: uppercase !important;
      font-family: Lato;
      &.ui.basic.button {
        background-color: #fff !important;
        color: #ff6c67 !important;
        box-shadow: none;
        font-weight: bold;
      }
    }
  }
  .filter-tag-list-wrapper {
    margin: 20px 0 16px 0;
    .tags-container {
      width: 100%;
      overflow: unset;
      flex-wrap: wrap;
      & > div {
        margin-bottom: 5px;
      }
    }
  }
  .name-field,
  .save-as-field {
    margin: 0 0 16px 0;
    max-width: 364px;
    .top-wrap {
      label {
        font-size: 14px;
        color: #415766;
      }
    }
    .ant-input {
      border-color: #c9cfd3;
      box-shadow: none;
      &:focus {
        border-color: #6f4bf1;
      }
    }
    &.save-as-field {
      margin: 0 0 50px 0;
    }
  }
  .action-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #c9cfd3;
    padding: 16px 32px 16px 0;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 1;
    button {
      padding: 0;
      border: none;
      font-size: 15px;
      &.ui.button {
        box-shadow: none !important;
        &:hover {
          box-shadow: none !important;
        }
      }
      &:nth-child(1) {
        color: #919699 !important;
        margin-right: 24px !important;
      }
      &:nth-child(2) {
        letter-spacing: 0.17px;
        color: #0d77b5 !important;
        text-decoration: underline;
        opacity: 0.5;
      }
    }
  }
`;
