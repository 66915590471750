import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const SelectWrapper = styled.div`
  z-index: 90;
  .ui.fluid.selection.dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
    width: 135px;
    height: 32px !important;
    margin: 0 16px 0 0;
    padding: 5px 10px 6px 11px;
    border-radius: 8px;
    min-height: 0;
    .divider.text {
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    .menu > .item {
      border: none;
    }
    > .dropdown.icon {
      color: ${colors.PURPLE};
      font-size: 20px;
      cursor: pointer;
      position: fixed;
      line-height: 0;
      margin: 0;
      padding: 0;
      right: 10px;
    }
  }
`;
export default SelectWrapper;
