import {
  PlainTimepicker,
  RadioGroup,
  Select
} from 'api/sharedComponents/reactHookFormComponents';
import { IFullAppointmentType, IOption } from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';
import { minutesDurationToDuration } from 'utils/format/time';
import { FormItem } from 'views/containers/form';
import IndexedLabelSelector from './IndexedLabelSelector';
import MaximumProviders from './MaximumProviders';
import { MEDWrapper } from '../style';
import QuestionIcon from 'assets/img/icon-question.svg';
import FormItemNolabel from 'views/containers/form/formItem/formItemNolabel';
import { mapToValuedOption } from 'utils/mappers/form';
import { CLIENT_FREQUENCY_PREFERENCE } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { STYLE_CLASS } from 'utils/constants/appointmentsTypes';
import IndexedLabelSelectorNum from './IndexedLabelSelectorNum';
import { Popover } from 'antd';
import { getSingleAppointmentTypeIds } from 'utils/mappers/smartSchedule';

const MED_MAX_PROVIDERS = 3;
interface Props {
  apptTypes: IFullAppointmentType[];
  setSelectedAppointment: Dispatch<SetStateAction<string>>;
  setIsSingleAppt: Dispatch<SetStateAction<boolean | undefined>>;
}
const MEDCarePlan: React.FC<Props> = ({
  apptTypes,
  setSelectedAppointment,
  setIsSingleAppt
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [isSelectedSubType, setIsSelectedSubType] = useState(false);
  const methods = useFormContext();
  const apptOptionsList: IOption[] = useMemo(
    () => mapToValuedOption(apptTypes),
    [apptTypes]
  );
  const clientFrequencyPreferences = useMemo(
    () => mapToOption(CLIENT_FREQUENCY_PREFERENCE),
    []
  );

  const duration = useWatch({
    control: methods.control,
    name: 'med.sessionDuration'
  });

  const [selectedAppointmentType, setSelectedAppointmentType] = useState<
    IFullAppointmentType | undefined
  >(undefined);
  const subTypesOptionList: IOption[] = useMemo(
    () => mapToValuedOption(selectedAppointmentType?.appointmentSubTypes || []),
    [selectedAppointmentType]
  );
  const appointmentTypeSelect = useCallback(
    val => {
      const selectedApptType = apptTypes.find(apptType => {
        return apptType.id === val;
      });
      const singleApptIds = getSingleAppointmentTypeIds(
        process.env.REACT_APP_STAGE
      );
      const isSingleAppt = selectedApptType?.id
        ? singleApptIds.includes(selectedApptType?.id)
        : undefined;
      setIsSelected(true);
      setIsSingleAppt(isSingleAppt);
      methods.setValue('appType', val);
      setSelectedAppointmentType(selectedApptType);
      if (selectedApptType?.title)
        setSelectedAppointment(selectedApptType.title);
      methods.setValue(
        'appTypeLabel',
        `Appointment Type: ${selectedApptType?.title}`
      );
      methods.setValue('appSubType', undefined);
      setIsSelectedSubType(false);
      if (selectedApptType?.title === 'SLP') {
        methods.setValue('med.sessionDuration', 30);
      } else {
        methods.setValue('med.sessionDuration', 45);
      }
    },
    [apptTypes, methods]
  );
  const appointmentSubTypeHandler = useCallback(
    value => {
      const appointmentSubType = selectedAppointmentType?.appointmentSubTypes?.find(
        x => x.id === value
      );
      setIsSelectedSubType(true);
      const duration = appointmentSubType?.MEDSetting?.duration;
      methods.setValue('med.sessionDuration', duration);
    },
    [selectedAppointmentType, methods]
  );
  const content = useMemo(() => {
    return (
      <div className="popup">
        <label>i.Client Frequency Preference:</label> Selecting Fewest Days will
        aim to fill slots for the client on the days they are already booked, or
        minimize booked days. Finding Most Days will spread appointments across
        as many days possible.
        <br></br>
        <label>ii.Maximum Providers:</label> This will look for a total amount
        of providers, including booked appointments for this client.
        <br></br>
        <label>iii.Max Daily Hours:</label> Will limit the amount of hours a
        Client is booked on any day to this amount OR their Available Hours.
        Whichever is less “
      </div>
    );
  }, []);
  const stateMentTrue = 1;
  return (
    <MEDWrapper>
      <Grid.Row className="type-row">
        <Grid.Column>
          <FormItemNolabel optional={false}>
            <Controller
              name="appTypeLabel"
              control={methods.control}
              render={fieldProps => (
                <Select
                  className={
                    isSelected
                      ? STYLE_CLASS.SELECTED_STYLE
                      : STYLE_CLASS.UNSELECTED_STYLE
                  }
                  onChange="this.className=this.options[this.selectedIndex].className"
                  placeholder={'Appointment Type'}
                  options={apptOptionsList}
                  field={fieldProps}
                  errors={methods.errors}
                  onSelect={appointmentTypeSelect}
                />
              )}
            />
          </FormItemNolabel>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="type-row">
        <Grid.Column>
          <FormItemNolabel optional={false}>
            <Controller
              name="appSubType"
              control={methods.control}
              render={fieldProps => (
                <Select
                  placeholder="Appointment Sub-Type"
                  options={subTypesOptionList}
                  className={
                    isSelectedSubType
                      ? STYLE_CLASS.SELECTED_STYLE
                      : STYLE_CLASS.UNSELECTED_STYLE
                  }
                  field={fieldProps}
                  onSelect={appointmentSubTypeHandler}
                  errors={methods.errors}
                />
              )}
            />
          </FormItemNolabel>
        </Grid.Column>
      </Grid.Row>
      {stateMentTrue ? null : (
        <Grid>
          <Grid.Row className="session" floated="left" width={60}>
            Session Information
            <Popover
              placement="bottomLeft"
              content={content}
              title="Session Information"
              trigger="hover"
            >
              <img
                alt="ques"
                src={QuestionIcon}
                width={22}
                style={{ paddingLeft: 4 }}
              />
            </Popover>
          </Grid.Row>
          <Grid.Row columns={2} className="lab">
            <Grid.Column className="duration">
              <FormItem optional={false} label="Session Duration">
                <Controller
                  name="med.sessionDuration"
                  control={methods.control}
                  render={fieldProps => (
                    <PlainTimepicker
                      className="timepicker-style"
                      name="sessionDuration"
                      format="HH:mm"
                      pattern="[0-9]{2}:[0-9]{2}"
                      minuteStep={15}
                      disabled={true}
                      errors={methods.errors}
                      field={fieldProps}
                      value={minutesDurationToDuration(duration)}
                    />
                  )}
                />
              </FormItem>
            </Grid.Column>
            <Grid.Column className="duration">
              <FormItem optional={false} label="Weekly Sessions">
                <Controller
                  name="med.weeklySessions"
                  control={methods.control}
                  render={fieldProps => (
                    <IndexedLabelSelectorNum
                      max={5}
                      title={'Sessions'}
                      name="weeklySessions"
                      field={fieldProps}
                      errors={methods.errors}
                      className="weekly"
                    />
                  )}
                />
              </FormItem>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2} className="lab">
            <Grid.Column className="freq">
              <FormItem optional={false} label="Client Frequency Preference">
                <Controller
                  name="med.clientFrequencyPreference"
                  control={methods.control}
                  render={fieldProps => (
                    <RadioGroup
                      className="radio-style"
                      options={clientFrequencyPreferences}
                      field={fieldProps}
                      customOnChangeHandler={(e: any) =>
                        methods.setValue(
                          'med.clientFrequencyPreference',
                          e.target.value
                        )
                      }
                    />
                  )}
                />
              </FormItem>
            </Grid.Column>
            <Grid.Column className="prov">
              <Grid.Row>
                <FormItem optional={false} label="Maximum Providers">
                  <Controller
                    name="med.maximumProviders"
                    control={methods.control}
                    render={fieldProps => (
                      <MaximumProviders
                        fields={fieldProps}
                        errors={methods.errors}
                        max={MED_MAX_PROVIDERS}
                      />
                    )}
                  />
                </FormItem>
              </Grid.Row>
              <Grid.Row>
                <FormItem optional={false} label="Client Daily Hours">
                  <Controller
                    name="med.clientDailyHours"
                    control={methods.control}
                    render={fieldProps => (
                      <IndexedLabelSelector
                        min={60}
                        max={480}
                        step={60}
                        title={'Hours'}
                        name="clientDailyHours"
                        field={fieldProps}
                        errors={methods.errors}
                        className="daily-hours"
                      />
                    )}
                  />
                </FormItem>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </MEDWrapper>
  );
};
export default React.memo(MEDCarePlan);
