import {
  AuthenticationResult,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

// Set active account after every interactive acquireTokenRedirect request
msalInstance.handleRedirectPromise().then(authResult => {
  if (authResult && authResult.account) {
    msalInstance.setActiveAccount(authResult.account);
  }
});

// Set active account after every login
msalInstance.addEventCallback(message => {
  if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
    const payload = message.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// We need to set active account since msal can store multiple accounts in local/session storage.
// To avoid logging in an account and acquiring token for another we set the active account after every user interaction.

export default msalInstance;
