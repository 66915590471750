import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Checkbox, Divider } from 'antd';
import { isEmpty } from 'lodash';
import {
  checkedSavedFilterContext,
  searchValueContext
} from '../../calendar/filters';
import { waitlistSavedFilterContext } from 'views/components/waitlistPage/WaitlistPage';
// import { providerRosterCntx } from 'pages/provider/list';
import { ICalendarFilterOption } from 'model/calendar/filters';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';

interface Props {
  listOptions: ICalendarFilterOption[];
  setCheckedList: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  checkedList: ICalendarFilterOption[];
  length: number;
  title: string;
  handleChange?: (values: CheckboxValueType[], allChecked?: boolean) => void;
  searchParams?: URLSearchParams;
  updateHistory?: () => void;
}

const CalendarFilter: React.FC<Props> = ({
  listOptions,
  setCheckedList,
  checkedList,
  length,
  title,
  handleChange,
  searchParams,
  updateHistory
}: Props) => {
  const searchValue = useContext(searchValueContext);
  let setCheckedSavedFilter = useContext(checkedSavedFilterContext);
  const setWaitlistCheckedSavedFilter = useContext(waitlistSavedFilterContext);

  if (setCheckedSavedFilter === undefined)
    setCheckedSavedFilter = setWaitlistCheckedSavedFilter;
  const [checkAll, setCheckAll] = useState(
    checkedList?.length ===
      listOptions?.filter(list =>
        list.disabled !== undefined ? !list.disabled : true
      ).length
  );

  const onChange = (checkedValues: CheckboxValueType[]) => {
    const currentList = listOptions.filter(option =>
      checkedValues.includes(option.value)
    );
    setCheckedList(currentList);
    if (setCheckedSavedFilter) setCheckedSavedFilter('');
    if (searchParams && updateHistory) {
      searchParams.set('page', '1');
      updateHistory();
    }
    setCheckAll(checkedValues.length === length);
    if (handleChange) handleChange(checkedValues);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (setCheckedSavedFilter) setCheckedSavedFilter('');
    if (searchParams) searchParams.set('page', '1');
    setCheckedList(
      e.target.checked
        ? listOptions.filter(list =>
            list.disabled !== undefined ? !list.disabled : true
          )
        : []
    );
    setCheckAll(e.target.checked);
    if (handleChange) {
      handleChange(
        e.target.checked
          ? listOptions
              .filter(list =>
                list.disabled !== undefined ? !list.disabled : true
              )
              .map(option => option.value)
          : [],
        e.target.checked
      );
    }
  };

  const values = useMemo(() => {
    return checkedList?.map(option => option.value) || [];
  }, [checkedList]);

  useEffect(() => {
    if (
      checkedList?.length ===
      listOptions?.filter(list =>
        list.disabled !== undefined ? !list.disabled : true
      ).length
    )
      setCheckAll(true);
    else setCheckAll(false);
  }, [checkedList, listOptions]);

  return (
    <>
      {listOptions?.length > 0 && (
        <>
          <div className="title">{title}</div>
          <Checkbox.Group
            options={listOptions}
            value={values}
            onChange={onChange}
          />

          {isEmpty(searchValue) && (
            <Checkbox onChange={onCheckAllChange} checked={checkAll}>
              Select all
            </Checkbox>
          )}
          {title !== 'Internal' && <Divider />}
        </>
      )}
    </>
  );
};
export default React.memo(CalendarFilter);
