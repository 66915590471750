import { PlannerExtendDirection } from 'model';
import moment from 'moment';
import { EventType, ICalendarEvent } from 'model/calendar/events';
import { ICalendarResource } from 'model/calendar/filters';
import { calendarGroup } from 'model/calendar/groups';
import {
  EVENT_STATUS_MAX_SUCCESS_RANGE,
  EVENT_STATUS_MIN_SUCCESS_RANGE,
  DOWNSTREAM_SYNC_STATUS,
  MIN_EVENT_DURATION,
  NOT_VALID_TO_DROP_EVENT,
  isPendingAxonCancel
} from 'utils/constants/planner';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { IFullAppointmentType } from 'model/v2';
import { isCancelledEvent, isItemSelectedForCancellation } from '../BulkCancel';

export interface ISelectedEvent {
  eventId?: number;
  newStartDate?: string;
  newEndDate?: string;
  oldStartDate?: string;
  oldEndDate?: string;
  provider?: ICalendarResource;
  oldProvider?: number;
  error?: string;
  type?: EventType;
}

export const extendEventValidator = (
  time: number,
  edge: PlannerExtendDirection,
  event: ICalendarEvent
): ISelectedEvent | undefined => {
  let error = NOT_VALID_TO_DROP_EVENT;
  const difference =
    edge === PlannerExtendDirection.left
      ? new Date(event.end!).getTime() - time
      : time - new Date(event.start!).getTime();

  const newAppointment = {
    eventId: event.id,
    newStartDate:
      edge === PlannerExtendDirection.left
        ? new Date(time).toString()
        : new Date(event.start!).toString(),
    newEndDate:
      edge === PlannerExtendDirection.left
        ? new Date(event.end!)?.toString()
        : new Date(time!)?.toString(),
    oldStartDate: new Date(event.start!)?.toString(),
    oldEndDate: new Date(event.end!)?.toString(),
    type: event.type
  };
  if (
    moment(newAppointment.newStartDate).isBefore(
      moment(newAppointment.newEndDate)
    )
  ) {
    if (
      event.type === EventType.adminEvent ||
      validExtendTransition(event, difference)
    )
      return newAppointment;
  }
  return { error };
};

export const dropEventValidator = (
  time: number,
  group: string,
  event: ICalendarEvent,
  providers: ICalendarResource[]
): ISelectedEvent => {
  let error = NOT_VALID_TO_DROP_EVENT;
  const isFromStaff = event.groupType === calendarGroup.provider;
  const newProvider = providers.find(item => item.id?.toString() === group);
  const isToStaff = newProvider !== undefined;
  const newTime = getNewDragTime(time, event);
  const newAppointment = {
    eventId: event.id,
    newStartDate: newTime.newStartDate,
    newEndDate: newTime.newEndDate,
    oldStartDate: new Date(event.start!).toString(),
    oldEndDate: new Date(event.end!).toString(),
    type: event.type
  };

  if (event.type === EventType.adminEvent) {
    error = validateAdmin(isToStaff, group, event);
    return error ? { error } : newAppointment;
  }
  if (event.type !== EventType.unAvailable) {
    // same row
    if (
      event?.resourceId?.split(':')[0] === group ||
      (isFromStaff && !isToStaff && group === event?.client?.id?.toString())
    ) {
      return newAppointment;
    }

    // not drag from client to another client
    // not drag from provider to another provider
    // not drag from provider to different client

    error = validateTransitionToClient(isFromStaff, isToStaff);
    if (error) return { error };
    // new provider has same appointment

    error = validateTransitionToProvider(newProvider, event.appointmentType);
    if (error) return { error };

    return {
      ...newAppointment,
      provider: newProvider,
      oldProvider: event.provider?.id
    };
  }
  return { error };
};

const validExtendTransition = (event: ICalendarEvent, difference: number) =>
  event.type !== EventType.unAvailable &&
  event?.appointmentType?.eventType?.name !== EVENT_TYPES.DT &&
  event?.appointmentType?.eventType?.name !== EVENT_TYPES.MED &&
  difference >= MIN_EVENT_DURATION;

const validateTransitionToClient = (
  isFromStaff: boolean,
  isToStaff: boolean
) => {
  if (!isFromStaff && !isToStaff)
    return 'Moving appointments between clients is not allowed.';
  else if (isFromStaff && !isToStaff)
    return 'Provider appointments cannot be moved to clients.';
  return '';
};

const validateTransitionToProvider = (
  newProvider: ICalendarResource | undefined,
  eventAppointmentType?: IFullAppointmentType
) => {
  if (
    newProvider?.appointmentTypes?.find(
      i =>
        (i.id === eventAppointmentType?.id ||
          i.id === eventAppointmentType?.parent?.id) &&
        i.eventType?.id === eventAppointmentType?.eventType?.id
    ) === undefined
  )
    return 'This appointment type is not a valid type for the new provider.';
  return '';
};

const validateAdmin = (
  isToStaff: boolean,
  group: string,
  event: ICalendarEvent
) => {
  if (!isToStaff) return 'Admin appointments cannot be moved to clients.';
  else if (group !== event?.resourceId?.split(':')[0])
    return 'Moving admin appointments between providers is not allowed.';
  return '';
};

const isDownStreamSyncSuccess = (downStreamStatus: number) => {
  if (downStreamStatus === DOWNSTREAM_SYNC_STATUS.NO_SYNC) return true;
  return (
    downStreamStatus > EVENT_STATUS_MIN_SUCCESS_RANGE &&
    downStreamStatus < EVENT_STATUS_MAX_SUCCESS_RANGE
  );
};

// events without crStatus and athenaStatus
// events with status > 100 && < 200 or has no sync status
export const isFullySyncedEvent = (
  athenaStatus?: number,
  crStatus?: number
) => {
  if (!crStatus && !athenaStatus) return true;
  if (isPendingAxonCancel(crStatus!, athenaStatus!)) return false;
  return (
    isDownStreamSyncSuccess(crStatus!) && isDownStreamSyncSuccess(athenaStatus!)
  );
};

export const getNewDragTime = (time: number, event: ICalendarEvent) => {
  const duration =
    event.end && event.start
      ? event?.end?.getTime() - event?.start?.getTime()
      : 0;
  return {
    newStartDate: new Date(time).toString(),
    newEndDate: new Date(time + duration).toString()
  };
};

export const isObservationEvent = (event: ICalendarEvent) => {
  return event.leadEvent?.id != null ? true : false;
};

export const isDisabledMovement = (event: ICalendarEvent) =>
  isObservationEvent(event!) ||
  !isFullySyncedEvent(event?.athenaStatus, event?.crStatus) ||
  isCancelledEvent(event) ||
  isItemSelectedForCancellation(event.id!);
