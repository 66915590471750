import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const AuthGroupFormWrapper = styled.div`
  .button-wrap {
    button {
      width: 238px;
    }
  }
  .button-outline {
    margin-top: 12px;
    margin-bottom: 20px;
  }
`;

export const AuthIdWrapper = styled.div`
  width: auto;
  height: 284px;
  margin: 16px 22px 0 26px;
  padding: 32px 25px 0 47px;
  background: ${colors.WHITE};
  border: solid 1px #c9cfd3;
  border-radius: 4px;
  h3 {
    margin-bottom: 15px;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: ${colors.AVATAR_INITIAL};
    margin-bottom: 8px;
    &:first-child {
      margin-bottom: 15px;
    }
  }
  .ant-radio-group {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  .error {
    width: 189px;
    height: 11px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.RED};
  }
  .error-input {
    border-color: #cc3f3f;
  }
`;

export const AuthGroupFormContainer = styled.div`
  width: auto;
  margin: 0 22px 0 26px;
  padding-top: 16px;

  h2 {
    margin-bottom: 12px;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
  }
`;

export const AuthTherapiesFrom = styled.div`
  position: relative;
  width: 937px;
  min-height: 160px;
  padding: 31px 0 34px 37px;
  margin-top: 11px;
  border-radius: 4px;
  border: solid 1px #c9cfd3;
  background-color: ${colors.WHITE};
  span {
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #21303a;
    margin-bottom: 6px;
  }
  .vertical-line {
    position: absolute;
    top: 17px;
    right: 139px;
    width: 1px;
    height: calc(100% - (17px + 26px));
    border: solid 1px #979797;
  }
  .delete {
    margin-left: 40px;
    margin-bottom: 10px;
    border: none;
    text-transform: uppercase;
    color: ${colors.RED};
    font-family: Lato;
    font-size: 15px;
    font-weight: normal;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }
  .add_another {
    position: absolute;
    bottom: 40px;
    left: 44px;
    padding: 0;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.17px;
    border: none;
    span {
      color: ${colors.ACTIVE_URL};
      text-decoration: underline;
      font-weight: normal;
    }
  }
  .fa-times {
    position: absolute;
    top: 28px;
    right: -11%;
    font-size: 21px;
    cursor: pointer;
  }
  .ant-time-picker {
    margin-bottom: 0;
  }
  .error {
    position: absolute;
    bottom: 0;
    width: 160px;
    height: 30px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: bold;
    color: #cc3f3f;
    &-appt_type {
      width: 360px;
      bottom: 27px;
      left: 16px;
    }
  }
  .error-input {
    border-color: #cc3f3f;
    .ant-select-selection {
      border-color: #cc3f3f;
    }
  }
`;
