import { WebSocketLink } from 'apollo-link-ws/lib/webSocketLink';
import { SubscriptionClient } from 'subscriptions-transport-ws';

let isConnecting = false;
let wsClient: SubscriptionClient | WebSocketLink.Configuration | any = null;
const wsClientService = async (token: string) => {
  // Prevent creating another connection if one is already in progress
  if (isConnecting || wsClient) {
    return wsClient;
  }

  isConnecting = true;

  const bearerToken = 'Bearer ' + token;

  const wsUrl = `${process.env
    .REACT_APP_AWS_WEBSOCKET_URL!}?Authorization=${encodeURIComponent(
    bearerToken
  )}`;

  wsClient = new SubscriptionClient(wsUrl, {
    reconnect: true,
    minTimeout: 10000,
    lazy: true
  });

  wsClient.on('connected', () => {
    isConnecting = false;
  });

  wsClient.on('error', (error: any) => {
    console.error('WebSocket error:', error); // Debugging
    isConnecting = false;
  });

  wsClient.on('close', (event: any) => {
    console.error('WebSocket closed:', event); // Debugging
    isConnecting = false;
  });

  return wsClient;
};

export default wsClientService;
