import { IClinic } from 'model/clinic';
import { IStaff } from 'model/user';
import { IEventType } from 'model/eventType';
export interface IAppointmentSubType {
  id?: string;
  title?: string;
  duration?: string;
}

export interface Opening {
  startTime: Date;
  endTime: Date;
}
export interface UserOpenings {
  userId: string;
  openings: Opening[];
}
export interface OpeningCardType {
  id: string;
  start: Date;
  end: Date;
  provider?: IStaff;
  clinic?: IClinic;
  appType: string;
  recurringEvery?: number;
  recurringUntil?: Date;
}

export interface SalesForceTherapyMappingsType {
  id?: number;
  salesforceTherapyTitle?: string;
}

export enum AppointmentType {
  id = 'id',
  title = 'title',
  headerColor = 'headerColor',
  backgroundColor = 'backgroundColor',
  eventType = 'eventType',
  isEvaluation = 'isEvaluation',
  ActiveStatus = 'isActive',
  dayAvailabilityTypes = 'dayAvailabilityTypes',
  salesForceMapping = 'salesForceTherapyMappings',
  paycodes = 'paycodes'
}
export interface IAppointmentType {
  [AppointmentType.id]?: number;
  [AppointmentType.title]?: string;
  [AppointmentType.headerColor]?: string;
  [AppointmentType.backgroundColor]?: string;
  [AppointmentType.eventType]?: IEventType;
  [AppointmentType.isEvaluation]?: boolean;
  [AppointmentType.ActiveStatus]?: boolean;
  [AppointmentType.dayAvailabilityTypes]?: string[] | null;
  [AppointmentType.salesForceMapping]?: SalesForceTherapyMappingsType;
  [AppointmentType.paycodes]?: string;
  subType?: string;
}
