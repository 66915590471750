import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const ContentWrapper = styled.div`
  padding: 10px 32px;
  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: ${colors.WHITE};
    background-color: ${colors.PURPLE};
  }
  .ui.radio.checkbox input:checked ~ label:after {
    opacity: 1;
    color: ${colors.WHITE};
    background-color: ${colors.PURPLE};
  }
  .ui.radio.checkbox label,
  .ui.radio.checkbox + label {
    color: #415766;
    font-size: 13px;
    margin-top: 10px;
  }
  .label {
    margin-bottom: 10px !important;
  }
  .check-box-grooup {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .save-btn {
    display: flex;
    justify-content: flex-end;
    button {
      margin: 0;
    }
  }
`;
