import Timeline, {
  TimelineHeaders,
  DateHeader,
  SidebarHeader,
  ReactCalendarItemRendererProps,
  TimelineItemBase,
  TimelineGroupBase
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import MainCalendarToolbar from '../../calendarToolbar';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import moment, { Moment } from 'moment';
import { customizeRange, getNavigationDate } from 'utils/builders/calendar';
import { NavigateAction, View } from 'react-big-calendar';
import {
  mainCalendarPrefDayVar,
  PlannerView,
  providerAppointmentHours,
  removePhantomEvents,
  removeSelectedItemsToCancel,
  removeSelectedItemToView,
  selectedItemsToCancelVar,
  selectedItemToViewVar,
  views
} from 'utils/cache/calendar';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import PlannerWeekGroup from '../plannerGroup/plannerWeekGroup';
import PlannerDayGroup from '../plannerGroup/plannerDayGroup';

import {
  CalandarWrapper,
  PlannerSidebarWrapper,
  PlannerWrapper,
  CalendarUpperSidebar,
  AvailableSlotWrapper
} from './style';
import {
  PLANNER_DAY_KEYS,
  PLANNER_WEEK_KEYS,
  PLANNER_WEEK_SIDEBAR_WIDTH,
  PLANNER_DAY_SIDEBAR_WIDTH,
  ITEM_HEIGHT_RATIO,
  PLANNER_TIMESTEPS,
  RESIZE_DIRECTION,
  MIN_ZOOM,
  MAX_ZOOM,
  SCROLLABLE_TIMELINE_ID,
  NOT_VALID_TO_DROP_EVENT
} from 'utils/constants/planner';
import { calendarIntervals } from 'utils/constants/calendar';
import { calendarDayMoment, calendarWeekMoment } from 'utils/format';
import {
  EventType,
  ICalendarEvent,
  IPhantomEvent,
  IProviderAppointmentHours
} from 'model/calendar/events';
import { calendarGroup, IPlannerGroup } from 'model/calendar/groups';
import { useReactiveVar } from '@apollo/client';
import {
  calendarEventsPrefVar,
  clientsResourcesPrevVar,
  providerResourcesPrevVar,
  useGetAllResources,
  resetAllCalendarVariables
} from 'utils/cache/filters';

import CalendarFiltersAndSearch from 'components/calendar/calendarFiltersAndSearch';
import PlannerItem from '../plannerItem';
import PlannerMarkers from '../plannerMarkers';
import ResetFiltersIcon from '../../../../assets/img/noun-remove.svg';
import DroppableTimeLine from '../DroppableTimeline';
import ShowPopup from 'components/appointment/showPopup';
import {
  getDefaultEndTime,
  getDefaultGroup,
  getDefaultStartTime,
  getFullDayEndTime,
  getFullDayStartTime,
  getPlannerWeekDayLabelByDrop,
  mapToPLannerWeekGroup,
  notPhantomEvent
} from 'helpers/calendarHelper';
import {
  dropEventValidator,
  extendEventValidator,
  isDisabledMovement,
  ISelectedEvent
} from 'utils/validators/dragEventValidator';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';
import InfiniteScroll from 'react-infinite-scroll-component';
import { roundToNearestIncrement, scrollElementToTop } from 'utils/math';
import { CLINIC_PROVIDERS, EVENT_CATEGORY } from 'utils/constants/lists';
import { ICalendarResource } from 'model/calendar/filters';
import { mapToCalendarDropEventV2 } from 'utils/mappers/response/eventsV2';
import { toast } from 'react-toastify';
import {
  EVENTS_FETCHING_ACTIONS,
  plannerContext
} from 'pages/MainCalendarPage';
import { MIN_PER_PAGE } from 'utils/constants/calendarFilters';
import { DEFAULT_DURATION } from 'utils/constants/default';
import { SidebarState, IEvent } from 'model/v2';
import BulkCancelPopup from '../PlannerModals/BulkCancelPopup';
import BulkCancelModal from '../PlannerModals/BulkCancelModal';
import { EVENT_STATUS } from 'utils/constants/status';
import { Popup } from 'semantic-ui-react';
import SlotTooltip from '../plannerItem/SlotToolTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faLink, faHome } from '@fortawesome/free-solid-svg-icons';
import CancelAddAdminModal from '../PlannerModals/CancelAddAdminModal';

interface Props {
  events?: ICalendarEvent[];
  selectedDate: Date;
  handleViewChange: () => void;
  handleNavigation: (date: Date) => void;
  view: PlannerView;
  incrementPage: () => void;
  scroll?: boolean;
  setScroll: Dispatch<SetStateAction<boolean>>;
  openSidebar: (sidebarState: SidebarState) => void;
  isSidebarClosed: boolean;
  setRemovedGroups: Dispatch<SetStateAction<number[]>>;
  removedGroups: number[];
  setPaginationArray: React.Dispatch<React.SetStateAction<ICalendarResource[]>>;
  incrementPageDebouncer: () => void;
  paginationArray: ICalendarResource[];
}
const groupHeight = 60;
function usePrevious(data: any) {
  const ref = useRef<PlannerView>();
  React.useEffect(() => {
    ref.current = data;
  }, [data]);
  return ref.current;
}
const PlannerCalendar: React.FC<Props> = ({
  selectedDate,
  handleNavigation,
  handleViewChange,
  view,
  incrementPage,
  scroll,
  setScroll,
  openSidebar,
  isSidebarClosed,
  setRemovedGroups,
  removedGroups,
  setPaginationArray,
  incrementPageDebouncer,
  paginationArray
}: Props) => {
  const [openEventPopup, setOpenEventPopup] = useState(false);
  const selectedEventProps = useRef<ISelectedEvent>();
  const oldEventsBeforeDrop = useRef<ICalendarEvent[]>();
  let events: ICalendarEvent[] = useReactiveVar(calendarEventsPrefVar);

  const availableSlots: ICalendarEvent[] = events.filter(event => {
    return event.evalSlotLocation != null;
  });

  const phantomEvents: ICalendarEvent[] = events.filter(event => {
    return event.type === 'phantomClientEvent';
  });

  const phantomObserverEvents: ICalendarEvent[] = events.filter(event => {
    return event.type === 'phantomObserverEvent';
  });

  const unavailableSlots: ICalendarEvent[] = events.filter(event => {
    return event.type === 'na';
  });

  const realEventsData: ICalendarEvent[] = events.filter(event => {
    return event.evalSlotLocation === null;
  });

  const phantomAdminEvents: ICalendarEvent[] = events.filter(event => {
    return event.type === 'phantomAdminEvent';
  });

  const actualEvents: ICalendarEvent[] = [
    ...realEventsData,
    ...phantomEvents,
    ...phantomObserverEvents,
    ...phantomAdminEvents
  ];

  const overlaySlots: ICalendarEvent[] = [
    ...availableSlots,
    ...unavailableSlots
  ];
  const hoursData: IProviderAppointmentHours = {};
  events.forEach(event => {
    if (
      (event.type === EventType.clientEvent ||
        event.type === EventType.adminEvent) &&
      ['scheduled', 'rescheduled'].includes(event.status!) &&
      event.groupType === 'provider' &&
      !event.isPendingConfirmation &&
      [101, 102].includes(event?.crStatus!)
    ) {
      if (event.provider?.speciality?.department === 'ABA') {
        let directHours = 0;
        let indirectHours = 0;
        if (event.appointmentType?.paycodes === 'Direct')
          directHours = parseFloat((event.duration! / 60).toFixed(2));
        if (event.appointmentType?.paycodes === 'Indirect')
          indirectHours = parseFloat((event.duration! / 60).toFixed(2));
        if (hoursData[event.provider.id!]) {
          hoursData[event.provider.id!].directHours =
            hoursData[event.provider.id!].directHours + directHours;
          hoursData[event.provider.id!].indirecthours =
            hoursData[event.provider.id!].indirecthours + indirectHours;
          hoursData[event.provider.id!].totalHours =
            hoursData[event.provider.id!].directHours +
            hoursData[event.provider.id!].indirecthours;
        } else {
          hoursData[event.provider.id!] = {
            directHours: directHours,
            indirecthours: indirectHours,
            totalHours: directHours + indirectHours
          };
        }
      }
    }
  });
  providerAppointmentHours(hoursData);
  const providers = useReactiveVar(providerResourcesPrevVar);
  const clients = useReactiveVar(clientsResourcesPrevVar);
  const ref = useRef<any>(null);
  const [plannerView, setPlannerView] = useState<PlannerView>();
  //used to handle timeline width on view change
  const prevView = usePrevious(plannerView);

  const allCalendarResources = useGetAllResources();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const { setAction, isLoading: loading, isBulkCancelMode } = useContext(
    plannerContext
  );
  const selectedItemToView = useReactiveVar(selectedItemToViewVar);
  const selectedItemToCancel = useReactiveVar(selectedItemsToCancelVar);
  const [isBulkCancelModalOpen, setIsBulkCancelModalOpen] = useState(false);
  const [isCancelAddAdminModalOpen, setIsCancelAddAdminModalOpen] = useState(
    false
  );
  const groups = useMemo(() => {
    if (clients.concat(providers)?.length === 0) {
      return getDefaultGroup();
    }
    if (view === PlannerView.plannerWeek) {
      return mapToPLannerWeekGroup(clients.concat(providers));
    }
    return ((clients as unknown) as IPlannerGroup[]).concat(
      (providers as unknown) as IPlannerGroup[]
    );
  }, [clients, providers, view]);
  //Default from 7AM-8PM
  const defaultStart = useMemo(() => getDefaultStartTime(selectedDate), [
    selectedDate
  ]);

  const [defaultEnd, setDefaultEnd] = useState<number>(
    getDefaultEndTime(selectedDate, view)
  );

  //Full day
  const startTime = useMemo(() => getFullDayStartTime(selectedDate), [
    selectedDate
  ]);

  const [endTime, setEndTime] = useState<Date>(
    getFullDayEndTime(selectedDate, view)
  );

  const handleTimelineZoomReset = useCallback(() => {
    if (ref && ref.current) {
      ref?.current?.updateScrollCanvas(defaultStart, defaultEnd);
    }
    setShouldRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEnd, defaultStart, ref, shouldRefresh]);

  useEffect(() => {
    if (scroll) {
      scrollElementToTop(SCROLLABLE_TIMELINE_ID);
    }
    setScroll(false);
  }, [scroll, setScroll]);

  //  set default timeline range
  useEffect(() => {
    handleTimelineZoomReset();
  }, [handleTimelineZoomReset]);

  useEffect(() => {
    //Handle switch between plannerday and planner week
    //Adjust timeline to fit default hours from 7-8

    setPlannerView(view);
    if (prevView !== undefined && prevView !== view) {
      if (prevView === PlannerView.plannerDay) {
        setEndTime(getFullDayEndTime(selectedDate, view));
        setDefaultEnd(getDefaultEndTime(selectedDate, view));
      } else if (prevView === PlannerView.plannerWeek) {
        setEndTime(getFullDayEndTime(selectedDate, view));
        setDefaultEnd(getDefaultEndTime(selectedDate, view));
      }
    }
    removeSelectedItemsToCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useEffect(() => {
    //from navigation only , reset to default for every view
    if (prevView === view && prevView !== undefined) {
      setDefaultEnd(getDefaultEndTime(selectedDate, view));
      setEndTime(getFullDayEndTime(selectedDate, view));
    }
    removeSelectedItemsToCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const updateEventsMovement = useCallback(() => {
    let currentEvents = events.map(e => ({
      ...e,
      canMove: isSidebarClosed && !isDisabledMovement(e)
    }));
    if (isSidebarClosed) {
      currentEvents = currentEvents.filter(e =>
        notPhantomEvent(e as IPhantomEvent)
      );
    }
    calendarEventsPrefVar(currentEvents);
  }, [isSidebarClosed, events]);

  useEffect(() => {
    updateEventsMovement();
    // eslint-disable-next-line
  }, [isSidebarClosed, selectedItemToCancel]);

  const handleAutoFetching = useCallback(() => {
    const minPerPage =
      view === PlannerView.plannerDay
        ? MIN_PER_PAGE(view)
        : MIN_PER_PAGE(view) * 7;

    if (
      groups.length < minPerPage &&
      groups.length < allCalendarResources.length &&
      !loading
    ) {
      incrementPageDebouncer();
    }
  }, [
    view,
    groups,
    allCalendarResources.length,
    loading,
    incrementPageDebouncer
  ]);

  const onNavigate = useCallback(
    (navigationAction: NavigateAction) => {
      const navigationDate = getNavigationDate(
        navigationAction,
        selectedDate,
        view
      );
      updateEventsMovement();
      handleNavigation(navigationDate);
    },
    [handleNavigation, updateEventsMovement, selectedDate, view]
  );

  const toolbarData = useMemo(() => {
    const data = {
      label:
        view === PlannerView.plannerDay
          ? calendarDayMoment(selectedDate)
          : calendarWeekMoment(selectedDate),
      onNavigate: onNavigate,
      view: view,
      date: selectedDate,
      onView: handleViewChange
    };
    return data;
  }, [handleViewChange, onNavigate, selectedDate, view]);

  const groupRenderer = useCallback(
    ({ group }) => {
      return view === PlannerView.plannerDay ? (
        <PlannerDayGroup
          group={group}
          setRemovedGroups={setRemovedGroups}
          removedGroups={removedGroups}
          handleAutoFetching={handleAutoFetching}
        />
      ) : (
        <PlannerWeekGroup
          group={group}
          setRemovedGroups={setRemovedGroups}
          removedGroups={removedGroups}
          handleAutoFetching={handleAutoFetching}
          events={events}
        />
      );
    },
    [handleAutoFetching, removedGroups, setRemovedGroups, view]
  );

  const itemRenderer = (
    itemRenderer: ReactCalendarItemRendererProps<TimelineItemBase<number>>
  ) => {
    return (
      <PlannerItem
        itemRenderer={itemRenderer}
        openSidebar={openSidebar}
        setIsCancelAddAdminModalOpen={setIsCancelAddAdminModalOpen}
      />
    );
  };

  const sidebarHeaderRenderer = useCallback(() => {
    return (
      <>
        <PlannerSidebarWrapper view={view}>
          <CalendarFiltersAndSearch
            paginationArray={paginationArray}
            setPaginationArray={setPaginationArray}
          />
        </PlannerSidebarWrapper>
      </>
    );
  }, [view, paginationArray, setPaginationArray]);

  const closePopup = useCallback(() => {
    setOpenEventPopup(false);
  }, [setOpenEventPopup]);

  const onCancelReschedule = useCallback(() => {
    calendarEventsPrefVar(oldEventsBeforeDrop.current);

    setOpenEventPopup(false);
  }, [setOpenEventPopup]);

  const getWeekDropTime = useCallback(
    (week, time) => {
      const newDay = getPlannerWeekDayLabelByDrop(week, selectedDate);
      const extendTime = new Date(time);
      const newExtendTime = new Date(newDay.toDate());
      newExtendTime.setHours(extendTime.getHours());
      newExtendTime.setMinutes(extendTime.getMinutes());
      return newExtendTime.getTime();
    },
    [selectedDate]
  );

  const mapNewDropEvent = useCallback(
    (event, startDate: Moment, endDate: Moment, provider) => {
      oldEventsBeforeDrop.current = events;
      const newProvider = provider || event.provider;

      const newEvent: IEvent = {
        ...event,
        startDate,
        endDate,
        provider: newProvider,
        clinic: {
          id: newProvider?.clinic
        }
      };

      const currentEvents = mapToCalendarDropEventV2(
        event.id,
        events,
        providers,
        newEvent,
        view,
        calendarDate,
        clients
      );
      calendarEventsPrefVar(currentEvents);
    },
    [events, view, calendarDate, providers, clients]
  );

  const showDropPopup = useCallback(
    (
      droppedEventProps: ISelectedEvent | undefined,
      event: ICalendarEvent | undefined,
      provider?: ICalendarResource | null
    ) => {
      if (!droppedEventProps?.error) {
        selectedEventProps.current = droppedEventProps;
        if (
          (droppedEventProps?.provider &&
            droppedEventProps?.provider?.id !== event?.provider?.id) ||
          !moment(droppedEventProps?.newStartDate).isSame(event?.startTime) ||
          !moment(droppedEventProps?.newEndDate).isSame(event?.endTime)
        ) {
          mapNewDropEvent(
            event,
            moment(droppedEventProps?.newStartDate),
            moment(droppedEventProps?.newEndDate),
            provider
          );
          setOpenEventPopup(true);
        }
      }
      if (
        droppedEventProps?.error &&
        droppedEventProps?.error !== NOT_VALID_TO_DROP_EVENT
      ) {
        toast.error(droppedEventProps?.error);
      }
    },
    [mapNewDropEvent]
  );

  const moveResizeValidator = useCallback(
    (action, item, time, resizeEdge) => {
      let startTime = item.startTime;
      let endTime = item.endTime;
      if (view === PlannerView.plannerWeek) {
        startTime = item.plannerWeekStartTime;
        endTime = item.plannerWeekEndTime;
      }
      if (action === 'resize') {
        const timeMoment = moment(time);

        if (
          resizeEdge === 'right' &&
          timeMoment.diff(startTime, 'minutes') < 15
        ) {
          return moment(startTime)
            .add(15, 'minutes')
            .valueOf();
        } else if (
          resizeEdge === 'left' &&
          moment(endTime).diff(timeMoment, 'minutes') < 15
        ) {
          return moment(endTime)
            .add(-15, 'minutes')
            .valueOf();
        } else {
          return timeMoment
            .set(
              'minutes',
              roundToNearestIncrement(timeMoment.get('minutes'), 15)
            )
            .valueOf();
        }
      }
      return time;
    },
    [view]
  );

  const onItemResize = useCallback(
    (itemId, time, edge) => {
      let newTime = time;
      const event = events.find(item => item.calendarID === itemId);
      if (event?.evalSlotLocation !== null) {
        return;
      }
      if (view === PlannerView.plannerWeek) {
        newTime = getWeekDropTime(event?.resourceId?.split(':')[1], time);
      }
      const resizedItem = extendEventValidator(newTime, edge, event!);
      showDropPopup(resizedItem, event, null);
    },
    [view, events, getWeekDropTime, showDropPopup]
  );

  const handleScrolling = useCallback(
    (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
      const minTime = moment(startTime).valueOf();
      const maxTime = moment(endTime).valueOf();

      if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
        updateScrollCanvas(minTime, maxTime);
      } else if (visibleTimeStart < minTime) {
        updateScrollCanvas(
          minTime,
          minTime + (visibleTimeEnd - visibleTimeStart)
        );
      } else if (visibleTimeEnd > maxTime) {
        updateScrollCanvas(
          maxTime - (visibleTimeEnd - visibleTimeStart),
          maxTime
        );
      } else {
        updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
      }
    },
    [startTime, endTime]
  );

  const onItemMove = useCallback(
    async (itemId: string, time: number, group) => {
      let newTime = time;
      let newGroup = group;
      const event = events.find(item => item.calendarID === itemId);
      if (view === PlannerView.plannerWeek) {
        newGroup = group?.split(':')[0];
        newTime = getWeekDropTime(group?.split(':')[1], time);
      }
      if (event?.evalSlotLocation !== null) {
        return;
      }
      const movedItem = dropEventValidator(
        newTime,
        newGroup,
        event!,
        providers
      );
      showDropPopup(
        movedItem,
        event,
        providers.find(p => p?.id === Number(newGroup))
      );
    },
    [view, events, providers, getWeekDropTime, showDropPopup]
  );
  const onItemClick = useCallback(
    (itemId, mouseEvent) => {
      if (mouseEvent.button === 2) return;
      const event = events.find(item => item.calendarID === itemId.toString());

      if (event?.evalSlotLocation !== null) {
        let defaultEvent;
        let attendee: any;
        if (view === PlannerView.plannerWeek) {
          let startTime: any = event?.plannerWeekStartTime;
          let endTime: any = event?.plannerWeekEndTime;
          let groupID = event?.resourceId;
          startTime = moment(event?.plannerWeekStartTime || undefined)
            .set('day', Number(groupID?.toString().split(':')[1] || 0))
            .set('hours', moment(startTime).hours())
            .set('minutes', moment(startTime).minutes())
            .set('seconds', 0)
            .set('milliseconds', 0)
            .valueOf();

          endTime = moment(event?.plannerWeekEndTime || undefined)
            .set('day', Number(groupID?.toString().split(':')[1] || 0))
            .set('hours', moment(endTime).hours())
            .set('minutes', moment(endTime).minutes())
            .set('seconds', 0)
            .set('milliseconds', 0)
            .valueOf();

          // startTime = event?.plannerWeekStartTime;

          attendee = groups.find(
            (x: { id: any }) => x.id === event?.resourceId
          );

          defaultEvent = {
            startDate: startTime,
            endDate: endTime,
            duration: moment(endTime).diff(moment(startTime), 'minutes'),
            eventTypeName: EVENT_CATEGORY[0].value
          };
        }
        if (view === PlannerView.plannerDay) {
          let startTime: any = event?.startTime;
          const endTime = event?.endTime;
          attendee = groups.find((x: { id: any }) => x.id === event?.id);
          defaultEvent = {
            startDate: startTime,
            endDate: endTime,
            duration: endTime?.diff(startTime, 'minutes'),
            eventTypeName: EVENT_CATEGORY[1].value
          };
        }
        openSidebar({
          event: {
            ...defaultEvent,
            provider: {
              ...attendee,
              id: parseInt(attendee.baseId!) || parseInt(attendee.id)
            },
            clinicPreference: CLINIC_PROVIDERS[2].id
          },
          action: SIDEBAR_ACTIONS.NEW
        });
      } else {
        openSidebar({
          event: { id: event?.id, status: event?.status },
          action:
            event?.isObservation && event.status !== EVENT_STATUS.canceled
              ? SIDEBAR_ACTIONS.OBSERVATION_VIEW
              : SIDEBAR_ACTIONS.VIEW
        });
      }
      removePhantomEvents(events);
    },
    [events, openSidebar]
  );

  const onItemSelect = useCallback(
    (itemId, event) => {
      event?.preventDefault();
      if (event?.button === 2) return;
      const item = events.find(item => item.calendarID === itemId);
      const isItemInProgress = () => {
        const crStatus = item?.crStatus ?? 0;
        const athenaStatus = item?.athenaStatus ?? 0;
        if (
          [1, 2, 3, 4].includes(crStatus) ||
          [1, 2, 3, 4].includes(athenaStatus)
        ) {
          return true;
        }
        return false;
      };

      if ((event.ctrlKey || event.metaKey) && event.shiftKey) {
        if (!item || !getFeatureAvailability(FEATURES.BULK_CANCEL)) return;
        if (isItemInProgress()) return;
        const selectedItemsToCancel = selectedItemsToCancelVar();

        const isAlreadySelected = selectedItemsToCancel.has(item.id!);
        if (isAlreadySelected) {
          selectedItemsToCancel.delete(item.id!);
        } else {
          selectedItemsToCancel.set(item.id!, item);
        }

        const newSelectedItems = new Map<number, ICalendarEvent>(
          selectedItemsToCancel
        );
        selectedItemsToCancelVar(newSelectedItems);
      } else {
        selectedItemToViewVar(item?.calendarID!);
      }
    },
    [events]
  );

  const onItemContextMenu = useCallback(() => {
    removeSelectedItemToView();
  }, []);

  const updatePage = useCallback(() => {
    if (loading) {
      return;
    }
    incrementPage();
  }, [incrementPage, loading]);

  const onSlotClick = (event: any) => {
    let defaultEvent;
    const attendee: IPlannerGroup | undefined = groups.find(
      (x: { id: any }) => x.id === event.resourceId
    );
    if (view === PlannerView.plannerWeek) {
      let startTime: any = event?.plannerWeekStartTime;
      let endTime: any = event?.plannerWeekEndTime;
      let groupID = event?.resourceId;
      startTime = moment(event?.plannerWeekStartTime || undefined)
        .set('day', Number(groupID?.toString().split(':')[1] || 0))
        .set('hours', moment(startTime).hours())
        .set('minutes', moment(startTime).minutes())
        .set('seconds', 0)
        .set('milliseconds', 0)
        .valueOf();

      endTime = moment(event?.plannerWeekEndTime || undefined)
        .set('day', Number(groupID?.toString().split(':')[1] || 0))
        .set('hours', moment(endTime).hours())
        .set('minutes', moment(endTime).minutes())
        .set('seconds', 0)
        .set('milliseconds', 0)
        .valueOf();

      defaultEvent = {
        startDate: startTime,
        endDate: endTime,
        duration: moment(endTime).diff(moment(startTime), 'minutes'),
        eventTypeName: EVENT_CATEGORY[0].value
      };
    }
    if (view === PlannerView.plannerDay) {
      let startTime: any = event?.startTime;
      const endTime = event?.endTime;
      defaultEvent = {
        startDate: startTime,
        endDate: endTime,
        duration: endTime?.diff(startTime, 'minutes'),
        eventTypeName: EVENT_CATEGORY[1].value
      };
    }

    const eventData = {
      event: {
        ...defaultEvent,
        provider: {
          ...attendee,
          id: parseInt(attendee?.baseId!) || parseInt(attendee?.id!)
        },
        clinicPreference: CLINIC_PROVIDERS[2].id
      },
      action: SIDEBAR_ACTIONS.NEW
    };
    removePhantomEvents(events);
    setTimeout(() => {
      openSidebar(eventData);
    }, 1000);

    return;
  };

  const onCanvasClick = useCallback(
    (groupId, time) => {
      let newTime = time;
      if (view === PlannerView.plannerWeek) {
        newTime = moment(selectedDate || undefined)
          .set('day', groupId.split(':')[1] || 0)
          .set('hours', moment(time).hours())
          .set('minutes', moment(time).minutes())
          .set('seconds', 0)
          .set('milliseconds', 0)
          .valueOf();
      }

      const newEndTime = newTime + DEFAULT_DURATION.CLIENT * 60 * 1000;

      const attendee: IPlannerGroup | undefined = groups.find(
        (x: { id: any }) => x.id === groupId
      );

      const defaultEvent = {
        startDate: newTime,
        endDate: newEndTime,
        duration: DEFAULT_DURATION.CLIENT,
        eventTypeName: EVENT_CATEGORY[0].value,
        clinic: attendee?.clinic
      };

      if (attendee?.attendeeType === calendarGroup.client) {
        openSidebar({
          event: {
            ...defaultEvent,
            client: {
              ...attendee,
              name: attendee.name,
              id: parseInt(attendee.baseId!) || parseInt(attendee.id)
            },

            clinicPreference: CLINIC_PROVIDERS[0].id
          },
          action: SIDEBAR_ACTIONS.NEW
        });
      } else if (attendee?.attendeeType === calendarGroup.provider) {
        const sidebarData = {
          event: {
            ...defaultEvent,
            provider: {
              ...attendee,
              id: parseInt(attendee.baseId!) || parseInt(attendee.id)
            },
            clinicPreference: CLINIC_PROVIDERS[2].id
          },
          action: SIDEBAR_ACTIONS.NEW
        };

        openSidebar(sidebarData);
      }
    },
    [groups, view, selectedDate, openSidebar]
  );

  const resetFilters = useCallback(() => {
    if (isBulkCancelMode) return;
    resetAllCalendarVariables();
    setPaginationArray([]);
    setAction(EVENTS_FETCHING_ACTIONS.APPLY_FILTERS);
  }, [isBulkCancelMode, setPaginationArray, setAction]);

  const hasMore = useMemo(() => {
    return view === PlannerView.plannerDay
      ? groups.length < allCalendarResources.length
      : groups.length < allCalendarResources.length * 7;
  }, [allCalendarResources.length, groups.length, view]);

  const handleDrop = (e: any) => {
    if (e.eventType === 'move' || e.eventType === 'resize') {
      return;
    }
  };

  interface AvailableSlotLayerProps {
    getLayerRootProps: any;
    groupAvailableSlots: ICalendarEvent[];
    getLeftOffsetFromDate?: any;
  }
  const AvailableSlotLayer: React.FC<AvailableSlotLayerProps> = ({
    getLayerRootProps,
    groupAvailableSlots,
    getLeftOffsetFromDate = () => {}
  }) => {
    const calendarDate = useReactiveVar(mainCalendarPrefDayVar);

    if (groupAvailableSlots === undefined) {
      return <div></div>;
    }

    return (
      <div {...getLayerRootProps}>
        {groupAvailableSlots.map((slot: ICalendarEvent) => {
          // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          // getLeftOffsetFromDate only finds the correct offset
          // if the date in a slot matches the calendar date
          // thus, to determine offsets in the week view where we
          // have slots on different days, we create a dummy Date
          // that contains the HH:MM:SS of the slot we care about
          // but on the calendarDate. this allows offset calcs to
          // resolve correctly

          if (slot.start && slot.end) {
            let left: number;
            let right: number;
            const tempStart = new Date(calendarDate);
            const slotStart = new Date(slot.start);
            const slotEnd = new Date(slot.end);
            tempStart.setHours(slotStart.getHours());
            tempStart.setMinutes(slotStart.getMinutes());
            tempStart.setSeconds(slotStart.getSeconds());
            const tempEnd = new Date(calendarDate);
            tempEnd.setHours(slotEnd.getHours());
            tempEnd.setMinutes(slotEnd.getMinutes());
            tempEnd.setSeconds(slotEnd.getSeconds());
            left = getLeftOffsetFromDate(moment(tempStart));
            right = getLeftOffsetFromDate(moment(tempEnd));
            if (view === PlannerView.plannerDay) {
              if (slot.startTime) {
                const convertedSlotTime = slot.startTime!.toDate();

                const calendarDayDate = new Date(calendarDate).setHours(
                  0,
                  0,
                  0,
                  0
                );
                const slotDayDate = new Date(convertedSlotTime).setHours(
                  0,
                  0,
                  0,
                  0
                );

                if (slotDayDate === calendarDayDate) {
                  if (slot && slot.type !== 'na') {
                    return (
                      <Popup
                        trigger={
                          <div
                            key={slot.calendarID}
                            style={{
                              position: 'absolute',
                              left: left,
                              width: right - left,
                              backgroundColor: 'rgba(255, 255, 255, 1)',
                              height: '100%',
                              paddingLeft: '3px',
                              zIndex: 10,
                              border: '1px solid #C9CFD3'
                            }}
                            onClick={() => onSlotClick(slot)}
                          >
                            <AvailableSlotWrapper>
                              <div className="slot-wrapper">
                                <div className="slot-name h-40">
                                  <span className="title-text">
                                    {slot.appointmentType?.title}
                                  </span>
                                </div>
                                <div className="location-icons h-60">
                                  {(slot.evalSlotLocation.includes('Clinic') ||
                                    slot.evalSlotLocation.includes('Any')) && (
                                    <div className="icon-wrapper">
                                      <FontAwesomeIcon
                                        style={{
                                          color: '#8F8F8F',
                                          fontWeight: 400
                                        }}
                                        icon={faBriefcase}
                                      />
                                    </div>
                                  )}
                                  {(slot.evalSlotLocation.includes('Offsite') ||
                                    slot.evalSlotLocation.includes('Any')) && (
                                    <div className="icon-wrapper">
                                      <FontAwesomeIcon
                                        style={{
                                          color: '#8F8F8F',
                                          fontWeight: 400
                                        }}
                                        icon={faHome}
                                      />
                                    </div>
                                  )}
                                  {(slot.evalSlotLocation.includes(
                                    'Telehealth'
                                  ) ||
                                    slot.evalSlotLocation.includes('Any')) && (
                                    <div className="icon-wrapper">
                                      <FontAwesomeIcon
                                        style={{
                                          color: '#8F8F8F',
                                          fontWeight: 400
                                        }}
                                        icon={faLink}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </AvailableSlotWrapper>
                          </div>
                        }
                        content={<SlotTooltip slot={slot} />}
                        on={['hover', 'click']}
                      />
                    );
                  } else {
                    return (
                      <Popup
                        trigger={
                          <div
                            key={slot.calendarID}
                            style={{
                              position: 'absolute',
                              left: left,
                              width: right - left,
                              backgroundColor: 'rgba(217, 217, 217, 1)',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              pointerEvents: 'none',
                              zIndex: 10
                            }}
                          ></div>
                        }
                        content={<SlotTooltip slot={slot} />}
                        on={['hover']}
                      />
                    );
                  }
                }
              }
            } else {
              if (slot && slot.type !== 'na') {
                return (
                  <Popup
                    trigger={
                      <div
                        key={slot.calendarID}
                        style={{
                          position: 'absolute',
                          left: left,
                          width: right - left,
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                          height: '100%',
                          paddingLeft: '3px',
                          zIndex: 10,
                          border: '1px solid #C9CFD3'
                        }}
                        onClick={() => onSlotClick(slot)}
                      >
                        <AvailableSlotWrapper>
                          <div className="slot-wrapper">
                            <div className="slot-name h-40">
                              <span className="title-text">
                                {slot.appointmentType?.title}
                              </span>
                            </div>
                            <div className="location-icons h-60">
                              {(slot.evalSlotLocation.includes('Clinic') ||
                                slot.evalSlotLocation.includes('Any')) && (
                                <div className="icon-wrapper">
                                  <FontAwesomeIcon
                                    style={{
                                      color: '#8F8F8F',
                                      fontWeight: 400
                                    }}
                                    icon={faBriefcase}
                                  />
                                </div>
                              )}
                              {(slot.evalSlotLocation.includes('Offsite') ||
                                slot.evalSlotLocation.includes('Any')) && (
                                <div className="icon-wrapper">
                                  <FontAwesomeIcon
                                    style={{
                                      color: '#8F8F8F',
                                      fontWeight: 400
                                    }}
                                    icon={faHome}
                                  />
                                </div>
                              )}
                              {(slot.evalSlotLocation.includes('Telehealth') ||
                                slot.evalSlotLocation.includes('Any')) && (
                                <div className="icon-wrapper">
                                  <FontAwesomeIcon
                                    style={{
                                      color: '#8F8F8F',
                                      fontWeight: 400
                                    }}
                                    icon={faLink}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </AvailableSlotWrapper>
                      </div>
                    }
                    content={<SlotTooltip slot={slot} />}
                    on={['hover', 'click']}
                  />
                );
              } else {
                return (
                  <Popup
                    trigger={
                      <div
                        key={slot.calendarID}
                        style={{
                          position: 'absolute',
                          left: left,
                          width: right - left,
                          backgroundColor: 'rgba(217, 217, 217, 1)',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pointerEvents: 'none',
                          zIndex: 10
                        }}
                      ></div>
                    }
                    content={<SlotTooltip slot={slot} />}
                    on={['hover']}
                  />
                );
              }
            }
          }
          return;
        })}
      </div>
    );
  };

  return (
    <>
      <CalandarWrapper>
        <BulkCancelPopup setIsBulkCancelModalOpen={setIsBulkCancelModalOpen} />
        <BulkCancelModal
          setIsBulkCancelModalOpen={setIsBulkCancelModalOpen}
          isBulkCancelModalOpen={isBulkCancelModalOpen}
        />
        {isCancelAddAdminModalOpen && (
          <CancelAddAdminModal
            setIsCancelAddAdminModalOpen={setIsCancelAddAdminModalOpen}
            isCancelAddAdminModalOpen={isCancelAddAdminModalOpen}
          />
        )}
        <CalendarUpperSidebar>
          <div className="calendar-sidebar">
            <p className="sidebar-title">Calendars</p>
            <img
              className="reset-filters"
              src={ResetFiltersIcon}
              onClick={resetFilters}
              alt="Reset Filters"
            />
          </div>
          <div className="calendar-toolbar_tags">
            <MainCalendarToolbar
              data={toolbarData}
              setShouldRefresh={setShouldRefresh}
              range={customizeRange(
                selectedDate,
                view === views.PLANNER_DAY
                  ? (calendarIntervals.DAY as View)
                  : (calendarIntervals.WEEK as View)
              )}
              updateView={handleViewChange}
              handleNavigation={handleNavigation}
            />
          </div>
        </CalendarUpperSidebar>

        <PlannerWrapper view={view} id={SCROLLABLE_TIMELINE_ID}>
          <DroppableTimeLine>
            <InfiniteScroll
              dataLength={groups.length}
              next={updatePage}
              hasMore={hasMore}
              loader={null}
              scrollableTarget={SCROLLABLE_TIMELINE_ID}
            >
              <Timeline
                ref={ref}
                selected={[selectedItemToView] as any}
                groups={(groups as unknown) as TimelineGroupBase[]}
                groupRenderer={groupRenderer}
                items={(actualEvents as unknown) as TimelineItemBase<number>[]}
                keys={
                  view === PlannerView.plannerDay
                    ? PLANNER_DAY_KEYS
                    : PLANNER_WEEK_KEYS
                }
                timeSteps={PLANNER_TIMESTEPS}
                itemTouchSendsClick={false}
                sidebarWidth={
                  view === PlannerView.plannerDay
                    ? PLANNER_DAY_SIDEBAR_WIDTH
                    : PLANNER_WEEK_SIDEBAR_WIDTH
                }
                canResize={isSidebarClosed ? RESIZE_DIRECTION : false}
                useResizeHandle={true}
                stackItems
                lineHeight={groupHeight}
                itemHeightRatio={ITEM_HEIGHT_RATIO}
                moveResizeValidator={moveResizeValidator}
                onItemResize={onItemResize}
                onItemMove={onItemMove}
                onItemClick={onItemClick}
                onItemSelect={onItemSelect}
                onItemContextMenu={onItemContextMenu}
                onCanvasClick={onCanvasClick}
                itemRenderer={itemRenderer}
                horizontalLineClassNamesForGroup={(group: any) =>
                  group.attendeeType === calendarGroup.client
                    ? ['client-row']
                    : ['staff-row']
                }
                defaultTimeStart={new Date(defaultStart.valueOf())}
                defaultTimeEnd={new Date(defaultEnd)}
                minZoom={MIN_ZOOM}
                maxZoom={MAX_ZOOM}
                onTimeChange={handleScrolling}
                onItemDrag={handleDrop}
                // @ts-ignore
                rowData={overlaySlots}
                rowRenderer={({
                  rowData,
                  getLayerRootProps,
                  helpers,
                  group
                }: any) => {
                  let groupAvailableSlots;
                  if (view === PlannerView.plannerDay) {
                    groupAvailableSlots = rowData.filter(
                      (slot: { baseId: any }) => slot.baseId == group.id
                    );
                  } else if (view === PlannerView.plannerWeek) {
                    groupAvailableSlots = rowData.filter(
                      (slot: { resourceId: any }) => {
                        return slot.resourceId == group.id;
                      }
                    );
                  }

                  return (
                    <React.Fragment>
                      <AvailableSlotLayer
                        getLayerRootProps={getLayerRootProps}
                        getLeftOffsetFromDate={helpers.getLeftOffsetFromDate}
                        groupAvailableSlots={groupAvailableSlots}
                      />
                    </React.Fragment>
                  );
                }}
              >
                <TimelineHeaders>
                  <SidebarHeader>{sidebarHeaderRenderer}</SidebarHeader>
                  <DateHeader unit="hour" labelFormat="h a" className="hours" />
                  <DateHeader unit="minute" className="minutes" />
                </TimelineHeaders>
                <PlannerMarkers
                  startTime={startTime.valueOf()}
                  endTime={endTime.valueOf()}
                  view={view}
                />
              </Timeline>
            </InfiniteScroll>
          </DroppableTimeLine>
          {openEventPopup && (
            <ShowPopup
              selectedEventProps={selectedEventProps.current!}
              view={view}
              toast={toast}
              onClose={closePopup}
              onCancel={onCancelReschedule}
            />
          )}
        </PlannerWrapper>
      </CalandarWrapper>
    </>
  );
};

export default React.memo(PlannerCalendar);
