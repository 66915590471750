import React from 'react';
import { HeaderWrap } from './profile.style';
import { Row, Col } from 'antd';
import { FormItem, Input } from 'views/containers/form';
import { CLINIC_TYPES } from 'utils/constants/lists';

const Header = ({ clinic }) => {
  return (
    <HeaderWrap>
      <div className="inner-wrap">
        <Row type="flex" justify="space-between" align="top" gutter={0}>
          <Col className="clinic-info">
            <Row type="flex" justify="start" align="top" gutter={16}>
              <FormItem label="Clinic Name" xs={16} sm={7}>
                <Input disabled={true} name="clinicName" value={clinic.name} />
              </FormItem>
              <FormItem label="Clinic Abbreviation" xs={16} sm={3}>
                <Input
                  disabled={true}
                  name="clinicAbbreviation"
                  value={clinic.abbreviation}
                />
              </FormItem>
              <FormItem label="Clinic Type" xs={16} sm={3}>
                <Input
                  disabled={true}
                  name="clinicType"
                  value={
                    CLINIC_TYPES.find(x => x.id === clinic.clinicType)?.label
                  }
                />
              </FormItem>
              {clinic.clinicType === CLINIC_TYPES[1].id && clinic.clinicHub && (
                <FormItem label="Clinic Hub" xs={16} sm={3}>
                  <Input
                    disabled={true}
                    name="clinicHub"
                    value={clinic.clinicHub.name}
                  />
                </FormItem>
              )}
            </Row>
            <Row type="flex" justify="start" align="top" gutter={16}>
              <FormItem label="Address" xs={16} sm={7}>
                <Input
                  disabled={true}
                  name="clinicAddress"
                  value={clinic.address?.addressLine1}
                />
              </FormItem>
              <FormItem label="City" xs={16} sm={3}>
                <Input
                  disabled={true}
                  name="clinicCity"
                  value={clinic.address?.city}
                />
              </FormItem>
              <FormItem label="State" xs={16} sm={2}>
                <Input
                  disabled={true}
                  name="clinicState"
                  value={clinic.address?.state}
                />
              </FormItem>
              <FormItem label="Zip" xs={16} sm={2}>
                <Input
                  disabled={true}
                  name="clinicZip"
                  value={clinic.address?.zipCode}
                />
              </FormItem>
            </Row>
            <Row type="flex" justify="start" align="top" gutter={16}>
              <FormItem label="Phone" xs={16} sm={7}>
                <Input
                  disabled={true}
                  name="clinicPhone"
                  value={clinic.phone}
                />
              </FormItem>
              <FormItem label="Fax" xs={16} sm={7}>
                <Input disabled={true} name="clinicFax" value={clinic.fax} />
              </FormItem>
            </Row>
          </Col>
        </Row>
      </div>
    </HeaderWrap>
  );
};

export default Header;
export { Header };
