import { SalesForceTherapyMappingsType } from './appointment';
import { ILookup } from './lookup';

// make sure to be same as db data
export enum DTAppointmentSubType {
  id = 'id',
  title = 'title',
  athenaId = 'athenaId',
  crName = 'crName',
  crType = 'crType',
  duration = 'duration',
  superType = 'superType',
  DTSettingId = 'DTSettingId',
  salesForceMapping = 'salesForceMapping'
}
export interface IDTAppointmentSubType {
  [DTAppointmentSubType.id]?: number;
  [DTAppointmentSubType.title]: string;
  [DTAppointmentSubType.athenaId]: string;
  [DTAppointmentSubType.crName]: string;
  [DTAppointmentSubType.crType]: string;
  [DTAppointmentSubType.duration]?: number;
  [DTAppointmentSubType.superType]: ILookup;
  [DTAppointmentSubType.DTSettingId]?: number;
  [DTAppointmentSubType.salesForceMapping]?: SalesForceTherapyMappingsType;
}
