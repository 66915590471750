import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckListWrapper } from 'components/calendar/filters/style';
import useClickOutsideHook from 'hooks/clickOutsideHook';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Input, InputOnChangeData } from 'semantic-ui-react';
import LocationItems from './LocationItems';
import { ProviderCheckListWrapper } from './style';

interface IProps {
  allLocations: {
    name: string;
    id: number;
  }[];
  selectedLocationsList: Set<number>;
  setSelectedLocationsList: Dispatch<SetStateAction<Set<number>>>;
}
const LocationSelector: React.FC<IProps> = ({
  allLocations,
  selectedLocationsList,
  setSelectedLocationsList
}: IProps) => {
  const [isVisibleList, setIsVisibleList] = useState(false);
  const [checkedItems, setCheckedItems] = useState(new Set<number>());
  const [searchResults, setSearchResults] = useState<typeof allLocations>(
    allLocations
  );

  const filtersRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setSearchResults(allLocations);
  }, [allLocations]);

  const closeFiltersList = useCallback(() => {
    setIsVisibleList(false);
  }, [setIsVisibleList]);

  useClickOutsideHook(filtersRef, closeFiltersList);

  const toggleList = useCallback(() => {
    setIsVisibleList(prev => !prev);
  }, [setIsVisibleList]);

  const handleSearch = (
    _e: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setIsVisibleList(true);
    setSearchResults(
      allLocations.filter(it =>
        it.name?.toLocaleLowerCase()?.startsWith(data.value.toLocaleLowerCase())
      )
    );
  };

  const onHandleCheckChange = (id: number, isChecked?: boolean) => {
    if (isChecked) {
      setCheckedItems(prev => new Set(prev).add(id));
    } else {
      setCheckedItems(prev => {
        const next = new Set(prev);
        next.delete(id);
        return next;
      });
    }
  };

  const onClearAll = useCallback(() => {
    setCheckedItems(new Set());
  }, [setCheckedItems]);

  const onApply = useCallback(() => {
    setSelectedLocationsList(new Set(checkedItems));
    setIsVisibleList(false);
  }, [setSelectedLocationsList, setIsVisibleList, checkedItems]);

  useEffect(() => {
    setCheckedItems(new Set(selectedLocationsList));
  }, [selectedLocationsList]);

  return (
    <ProviderCheckListWrapper>
      <CheckListWrapper width={368} visible={isVisibleList} height={'162px'}>
        <div ref={filtersRef}>
          <div className="filter-input_wrapper" onClick={toggleList}>
            <Input
              className="filter_input"
              placeholder="Select Locations"
              onChange={handleSearch}
            />
            {!isVisibleList && (
              <FontAwesomeIcon className="icon" icon={faCaretDown} />
            )}
          </div>
          {isVisibleList && (
            <div className="list_wrapper">
              <div className="scrollable_list">
                {searchResults.length === 0 && (
                  <p className="no-results_filter">No results found</p>
                )}
                <LocationItems
                  locations={searchResults}
                  checkedItems={checkedItems}
                  onHandleChange={onHandleCheckChange}
                />
              </div>
              <div className="calender-filters_actions">
                <span onClick={onClearAll}>Clear all</span>
                <button
                  onClick={onApply}
                  disabled={false}
                  className={checkedItems.size > 0 ? 'highlightButton' : ''}
                >
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </CheckListWrapper>
    </ProviderCheckListWrapper>
  );
};
export default React.memo(LocationSelector);
