import React from 'react';
import moment from 'antd/node_modules/moment';

interface IProps {
  startTime: string;
  endTime: string;
  format?: boolean;
}
const DTResultTime: React.FC<IProps> = ({ startTime, endTime, format }) => {
  return (
    <>
      {format ? (
        <label
          style={{
            paddingLeft: '3px',
            fontSize: '11px',
            fontWeight: 'bold'
          }}
        >
          {moment(startTime).format('dddd')} {moment(startTime).format('LL')}
        </label>
      ) : (
        <label
          style={{
            paddingLeft: '3px',
            fontSize: '11px',
            fontWeight: 'bold'
          }}
        >
          {moment(startTime).format('hh:mm A')} -{' '}
          {moment(endTime).format('hh:mm A')}
        </label>
      )}
    </>
  );
};
export default React.memo(DTResultTime);
