import { ImpactedObserversWrapper } from '../style';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { IEvent } from 'model/v2';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import ImpactedObserverForm from 'components/appointment/popupForm/ImpactedObserverForm';

interface Props {
  observationEvent: IEvent[] | undefined;
  eventIndex: number;
  setSeriesEventObservations: Dispatch<SetStateAction<IEvent[]>>;
  seriesEventObservations: IEvent[];
  leadEvent: IEvent;
  setIsValidated: (val: boolean) => void;
  isValidated: boolean;
  newStartDate: string;
  newEndDate: string;
  initialValidation?: boolean;
  setAbaObserversList: Dispatch<SetStateAction<IEvent[]>>;
  eventType?: string;
}
const AbaImpactedObservers = ({
  observationEvent,
  eventIndex,
  leadEvent,
  setSeriesEventObservations,
  setIsValidated,
  isValidated,
  newStartDate,
  newEndDate,
  seriesEventObservations,
  setAbaObserversList,
  eventType
}: Props) => {
  const [openCollapse, setOpenCollapse] = useState(
    observationEvent !== undefined && observationEvent?.length > 0
      ? [true]
      : [false]
  );
  const handleOpenCollapse = (index: number) => {
    const newOpenCollapseArray = [...openCollapse];
    newOpenCollapseArray[index] = !newOpenCollapseArray[index];
    setOpenCollapse(newOpenCollapseArray);
  };
  const eventTimes = formatDates(
    observationEvent![0].startDate,
    newStartDate,
    newEndDate
  );
  return (
    <div style={{ width: '92%' }}>
      <ImpactedObserversWrapper>
        <div className="impacted-observers-list">
          <Grid style={{ width: '100%' }}>
            <Grid.Row className="impacted-observers-expanded">
              <Grid.Column width={15}>
                <div
                  className="observer-event-timing event-date"
                  style={{ float: 'left' }}
                >
                  <div className="event-date">
                    <CalendarOutlined />
                    <span style={{ paddingLeft: '10px' }}>
                      {eventTimes?.startDate}
                    </span>
                  </div>
                  <div className="event-date" style={{ paddingLeft: '50px' }}>
                    <ClockCircleOutlined className="event-details-icon" />
                  </div>
                  <div className="event-date" style={{ paddingLeft: '10px' }}>
                    {eventTimes?.startTime} {'-'} {eventTimes?.endTIme}
                  </div>
                </div>
              </Grid.Column>

              <Grid.Column width={1}>
                {' '}
                {observationEvent!.length >= 1 && (
                  <button
                    className="collapsible"
                    onClick={() => handleOpenCollapse(eventIndex)}
                  >
                    <Icon
                      name={
                        openCollapse[eventIndex]
                          ? 'triangle down'
                          : 'triangle right'
                      }
                      size="large"
                    ></Icon>
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div>
            {observationEvent?.map((observation, index) => {
              return (
                <>
                  {openCollapse[eventIndex] ? (
                    <ImpactedObserverForm
                      key={index}
                      observerIndex={index + 1}
                      eventIndex={eventIndex}
                      leadEvent={leadEvent}
                      observations={observation}
                      setSeriesEventObservations={setSeriesEventObservations}
                      seriesEventObservations={seriesEventObservations}
                      setIsValidated={setIsValidated}
                      newStartDate={newStartDate}
                      newEndDate={newEndDate}
                      isValidated={isValidated}
                      initialValidation={true}
                      setAbaObserversList={setAbaObserversList}
                      eventType={eventType}
                    />
                  ) : null}
                </>
              );
            })}
          </div>
        </div>
      </ImpactedObserversWrapper>
    </div>
  );
};

export default AbaImpactedObservers;

export const formatDates = (
  start: Date | Moment | undefined,
  newStartDate: string,
  newEndDate: string
) => {
  if (!start) return null;
  return {
    startDate: moment(start).format('MMMM DD, YYYY'),
    endTIme: moment(newEndDate).format('hh:mm a'),
    startTime: `${moment(newStartDate).format('hh:mm a')}`
  };
};
