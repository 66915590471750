import React, { useCallback } from 'react';
import { ChairIcon, TvIcon, MusicalIcon, PhoneIcon } from 'lib/ui/icons';

const RoomListItem = ({ room, selectedRoomValue, setValue }) => {
  const { id, roomName, seats, hasTV, hasInstruments, hasPhone } = room;

  const toggleRoomHandler = useCallback(
    id => {
      selectedRoomValue?.id === id
        ? setValue('selectedRoom', null) // deselect select
        : setValue('selectedRoom', room);
      setValue('locationCategory', null);
      setValue('locationDetails', {});
    },
    [setValue, selectedRoomValue, room]
  );

  return (
    <div
      className={`openings-rooms__item ${selectedRoomValue?.id === id &&
        'selected'}`}
      key={id}
      onClick={() => toggleRoomHandler(id)}
    >
      <div>{roomName}</div>
      <div>
        <ChairIcon />
        <span>{seats} Seats</span>
      </div>
      {hasTV && (
        <div>
          <TvIcon />
          <span>TV</span>
        </div>
      )}
      {hasInstruments && (
        <div>
          <MusicalIcon />
          <span>Instruments</span>
        </div>
      )}
      {hasPhone && (
        <div>
          <PhoneIcon />
          <span>Phone</span>
        </div>
      )}
    </div>
  );
};

export default RoomListItem;
