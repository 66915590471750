import { useState } from 'react';
import { ClientClinicNetworkOutput, IClinic } from 'model/v2';
import { useQuery } from '@apollo/react-hooks';
import { GET_CLIENT_CLINIC_NETWORK } from 'api/graphql/v2/queries/Clients';
export const useClientClinicNetwork = (clientId: number) => {
  const [clientClinicNetwork, setClientClinicNetwork] = useState<IClinic[]>([]);

  const { loading: isLoadingClinicNetwork } = useQuery<
    ClientClinicNetworkOutput
  >(GET_CLIENT_CLINIC_NETWORK, {
    fetchPolicy: 'no-cache',
    variables: { id: clientId },
    onCompleted: data => {
      setClientClinicNetwork(data.clientClinicNetwork);
    }
  });

  return { clientClinicNetwork, isLoadingClinicNetwork };
};
