import styled from 'styled-components';

export const FilterTagListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 16px 8px 16px;
  .bookmark {
    font-size: 23px;
    color: #415766;
    cursor: pointer;
    margin: 3px 8px 0 0;
  }
  .tags-container {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
        inset 1px 1px 0px rgba(0, 0, 0, 0);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .pt-10 {
    padding-top: 10px;
  }
  .saved_bookmark {
    color: #6f42f5;
    cursor: auto;
  }
  .clear-filters {
    cursor: pointer;
    color: rgba(111, 66, 245, 1);
    font-size: 14px;
    font-weight: bold;
  }
`;

export const TagWrapper = styled.div`
  height: 25px;
  margin-right: 10px;
  .filter-tag {
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 16px;
    border-radius: 12.5px;
    background-color: #6f42f5;
    font-family: Lato;
    font-size: 14px;
    line-height: normal;
    color: #fff;
    cursor: default;
    white-space: nowrap;
    &.closeing-tag {
      padding: 0 10px 0 16px;
    }
    .anticon-close {
      margin-left: 10px;
      cursor: pointer;
    }
  }
`;
