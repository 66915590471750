import React from 'react';

export const ROOMS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '280px',
    render: (text, record) => <strong>{text}</strong>
  },
  {
    title: 'Amenities',
    dataIndex: 'amenities'
  }
];
