import React from 'react';

import { Radio as LibRadio, RadioGroup as LibRadioGroup } from 'lib/ui';
import { ErrorMessage } from 'formik';

const Radio = props => {
  const { name, field, onChange, ...restProps } = props;
  const onChangeValue = value => {
    // because Formik expects normal Event with normal target, not single value
    const event = {
      target: {
        name: field.name,
        value: value.target.checked
      }
    };
    field.onChange(event);
  };
  return (
    <>
      <LibRadio onChange={onChangeValue} {...restProps} />
      <ErrorMessage name={name}>
        {msg => <div className="error">{msg}</div>}
      </ErrorMessage>
    </>
  );
};

const RadioGroup = props => {
  const {
    name,
    field,
    onChange,
    children,
    customOnChangeHandler,
    ...restProps
  } = props;
  const onChangeValue = value => {
    // because Formik expects normal Event with normal target, not single value
    const event = {
      target: {
        name: field.name,
        value: value.target.value
      }
    };
    field.onChange(event);
    if (customOnChangeHandler) customOnChangeHandler(value);
  };
  return (
    <>
      <LibRadioGroup onChange={onChangeValue} {...restProps}>
        {children}
      </LibRadioGroup>
      <ErrorMessage name={name}>
        {msg => <div className="error">{msg}</div>}
      </ErrorMessage>
    </>
  );
};

export default Radio;
export { Radio, RadioGroup };
