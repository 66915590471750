import React from 'react';
import { Tabs } from 'lib/ui';
import ScheduleCalendar from './ScheduleCalendar';
import { TabsWrap } from 'views/components/clients/profile/profile.style';
import CarePlan from './CarePlan';
import { useLocation } from 'react-router';
import {
  MAIN_CLIENT_AUTHORIZED_THERAPIES_COLUMNS,
  MAIN_CLIENT_EXPANDABLE_TABLE_COLUMNS
} from 'utils/constants/tables/clients';
import { getFeatureAvailability, FEATURES } from 'utils/featureToggle';
import AuthorizedTherapies from './AuthorizedTherapies';

const ClientTabs = ({ client, calendarOptions, accountID }) => {
  const location = useLocation();
  const activeKey = location.state?.activeKey;

  const schedulePane = {
    title: 'SCHEDULE',
    content: (
      <ScheduleCalendar client={client} calendarOptions={calendarOptions} />
    )
  };

  const carePlanPane = {
    title: 'CARE PLAN',
    content: <CarePlan client={client} />
  };

  const authorizedTherapiesPane = {
    title: 'AUTHORIZED THERAPIES',
    content: (
      <AuthorizedTherapies
        client={client}
        columns={MAIN_CLIENT_AUTHORIZED_THERAPIES_COLUMNS}
        expandableColumns={MAIN_CLIENT_EXPANDABLE_TABLE_COLUMNS}
        editable
      />
    )
  };

  const panes = [schedulePane];

  if (getFeatureAvailability(FEATURES.AUTHORIZATION))
    panes.push(authorizedTherapiesPane);

  if (getFeatureAvailability(FEATURES.CARE_PLAN)) panes.push(carePlanPane);

  return (
    <TabsWrap>
      <Tabs type="card" panes={panes} activeKey={activeKey ? activeKey : '0'} />
    </TabsWrap>
  );
};

export default ClientTabs;
export { ClientTabs };
