import { ICalendarResource } from 'model/calendar/filters';
import {
  IFullAppointmentType,
  // IProvider,
  IClient,
  ISmartSingleOpenings,
  SingleSmartResultInterface,
  ISmartSingleResult
} from 'model/v2';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ResultListWrapper } from './Style';
import SingleResultCard from './SingleResultCard';
import DTSingleResultStatistics from './DTSingleResultStatistics';
import { getAllowedLocationSingleDT } from 'helpers/smartScheduleCardHelper';
import SingleSmartPhantomEvents from 'pages/MainCalendarPage/FormPhantomEvents/SingleSmartPhantomEvents';
import { useRemoveProviderFromCalendar } from 'pages/MainCalendarPage/FormPhantomEvents/CustomPhantomHooks';

interface IProps {
  appointmentTypeId: number;
  smartResults: ISmartSingleOpenings;
  offsiteCategory?: string;
  minHours: number;
  action: string;
  providersMap: Map<any, ICalendarResource>;
  apptTypes: IFullAppointmentType[];
  client: IClient;
  paymentMethod: string;
  setVisible: (val: boolean) => void;
  visibleResults: boolean;
  toggleResultsView: () => void;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  isAba: boolean;
  appointmentSubTypeId?: number;
  setDisplayScroll?: React.Dispatch<React.SetStateAction<boolean>>;
  isDT: boolean;
  hasSingleDT: any;
  setCurrClient?: Dispatch<SetStateAction<IClient | undefined>>;
  currClient: any;
  selectedAppointment: string;
  setSmartResults: Dispatch<SetStateAction<ISmartSingleOpenings | undefined>>;
}
const SingleApptResultList: any = ({
  appointmentTypeId,
  smartResults,
  client,
  visibleResults,
  isAba,
  offsiteCategory,
  minHours,
  apptTypes,
  isDT,
  hasSingleDT,
  setDisplayScroll,
  toggleResultsView,
  providersMap,
  setVisible,
  appointmentSubTypeId,
  paginationArray,
  setPaginationArray,
  setCurrClient,
  selectedAppointment,
  setSmartResults
}: IProps) => {
  const [count, setCount] = React.useState<number>(1);
  smartResults?.openingCards.map(item => {
    item.allowedLocations = getAllowedLocationSingleDT(
      item.locations.isInClinic,
      item.locations.isTelehealth,
      item.locations.isOffsite
    );
  });
  let transitionResults: ISmartSingleResult[] | undefined =
    smartResults?.openingCards;

  //update allowed locations for transition results
  const [transitionResultsState, setTransitionResults] = useState<
    ISmartSingleResult[]
  >();
  useEffect(() => {
    setTransitionResults(transitionResults);
  }, [transitionResults]);
  if (transitionResultsState !== undefined) {
    transitionResultsState.map(item => {
      if (item.locations.isInClinic) {
        item.isInClinic = true;
        item.isOffsite = false;
        item.isTelehealth = false;
      } else if (item.locations.isOffsite) {
        item.isInClinic = false;
        item.isOffsite = true;
        item.isTelehealth = false;
      } else if (!item.locations.isInClinic && item.locations.isTelehealth) {
        item.isInClinic = false;
        item.isOffsite = false;
        item.isTelehealth = true;
      }
    });
  }

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [selectedResult, setSelectedResult] = useState<
    SingleSmartResultInterface | undefined
  >();

  const { removeAllProvidersFromCalendar } = useRemoveProviderFromCalendar();

  const removeUnselectedProviders = () => {
    removeAllProvidersFromCalendar();
    const deletedProviders = new Set();
    setPaginationArray(prevState => {
      return prevState.filter(
        resource =>
          resource.attendeeType === 'client' ||
          (resource.attendeeType === 'provider' &&
            !deletedProviders.has(resource.id))
      );
    });
  };

  const resetState = () => {
    // reset all of our index/etc. state;
    // remove providers?
    setSelectedIndex(undefined);
    setSelectedResult(undefined);
    setTransitionResults(undefined);
  };

  return (
    <ResultListWrapper>
      {selectedResult &&
        // appointmentSubTypeId &&
        selectedIndex !== undefined &&
        transitionResults && (
          <SingleSmartPhantomEvents
            providersMap={providersMap}
            apptTypes={apptTypes}
            client={client}
            setPaginationArray={setPaginationArray}
            paginationArray={paginationArray}
            visibleResults={visibleResults}
            selectedResult={selectedResult}
            appointmentTypeId={appointmentTypeId}
            key={selectedResult.toString()}
            appointmentSubTypeId={appointmentSubTypeId}
            transitionResults={transitionResults}
            selectedIndex={selectedIndex}
          />
        )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flexGrow: 1 }}>
          <DTSingleResultStatistics
            setDisplayScroll={setDisplayScroll}
            smartResults={smartResults}
            toggleResultsView={toggleResultsView}
            visibleResults={visibleResults}
            isAba={isAba}
            onClose={resetState}
            setCount={setCount}
            setSmartResults={setSmartResults}
          />
          {transitionResultsState !== undefined &&
            transitionResultsState?.map((result, index) => {
              return (
                <SingleResultCard
                  appointmentTypeTitle={
                    apptTypes.find(it => it.id === appointmentTypeId)?.title ||
                    ''
                  }
                  appointmentTypeId={appointmentTypeId}
                  result={result}
                  index={index}
                  offsiteCategory={offsiteCategory}
                  minHours={minHours}
                  clientId={client?.id}
                  isAba={isAba}
                  isDT={isDT}
                  hasSingleDT={hasSingleDT}
                  client={client}
                  setVisible={setVisible}
                  appointmentSubTypeId={appointmentSubTypeId}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  setCurrClient={setCurrClient}
                  selectedAppointment={selectedAppointment}
                  setSelectedResult={setSelectedResult}
                  removeUnselectedProviders={removeUnselectedProviders}
                  setCount={setCount}
                  count={count}
                />
              );
            })}
        </div>
        <div
          style={{
            position: 'sticky',
            backgroundColor: 'white',
            zIndex: 103,
            width: '100%',
            bottom: '0%'
          }}
        ></div>
      </div>
    </ResultListWrapper>
  );
};
export default React.memo(SingleApptResultList);
