import React, { useCallback, useState } from 'react';
import Card from 'views/components/ui/card';
import {
  TitleWrapper,
  ContentWrapper
} from 'views/components/ui/card/card.style';
import { useHistory } from 'react-router';
import SearchForm from 'views/components/ui/form/search';
import { configurations } from 'utils/constants/configurations';
import { Grid } from 'semantic-ui-react';
import { IConfigurations } from 'model/configurations';

const ConfigurationsPage: React.FC<{}> = React.memo(() => {
  const history = useHistory();
  const [filteredConfigurations, setFilteredConfigurations] = useState<
    IConfigurations[]
  >(configurations);

  type CallbackType = (...args: string[]) => void;

  const selectConfiguration = useCallback<CallbackType>(
    id => {
      history.push('/config/' + id);
    },
    [history]
  );

  //TODO: Determine event type
  const onSearch = (e: React.ChangeEvent<any>): void => {
    setFilteredConfigurations(
      configurations.filter(config =>
        config.title?.toLowerCase().includes(e.target.value?.toLowerCase())
      )
    );
  };

  return (
    <>
      <TitleWrapper>
        <div className="inner-wrap">
          <h1>Configuration</h1>
          <div className="actions">
            <SearchForm placeholder="Filter Data type" onChange={onSearch} />
          </div>
        </div>
      </TitleWrapper>
      <ContentWrapper>
        <div className="inner-wrap">
          <Grid stretched columns={3}>
            <Grid.Row>
              {filteredConfigurations.map((conf, index) => (
                <Card
                  key={index}
                  id={conf.id}
                  title={conf.title}
                  description={conf.description}
                  selectItem={selectConfiguration}
                />
              ))}
            </Grid.Row>
          </Grid>
        </div>
      </ContentWrapper>
    </>
  );
});

export default ConfigurationsPage;
