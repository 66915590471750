import { useReactiveVar } from '@apollo/client';
import { CALENDAR_EVENTS_SUBSCRIPTION } from 'api/graphql/v2/subscriptions/Events';
import { getView } from 'helpers/calendarHelper';
import { handleSubscriptionDataUpdate } from 'helpers/subscriptionsHelper';
import { ICalendarLoading } from 'model/calendar';
import { EventType } from 'model/calendar/events';
import { calendarGroup } from 'model/calendar/groups';
import { IEvent } from 'model/v2';
import { useCallback } from 'react';
import { useSubscription } from 'react-apollo';
import { customizeRange } from 'utils/builders/calendar';
import {
  CalendarView,
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar
} from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  calendarFiltersPrefVar,
  clientsResourcesPrevVar,
  providerResourcesPrevVar
} from 'utils/cache/filters';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { internalFilters } from 'utils/constants/calendarFilters';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { mapToCalendarEventsV2 } from 'utils/mappers/response/eventsV2';

const CALENDAR_ID = 'calendarID';

export const useEventSubscriptionHook = (
  isCalendarLoading: ICalendarLoading
) => {
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const providers = useReactiveVar(providerResourcesPrevVar);
  const clients = useReactiveVar(clientsResourcesPrevVar);

  const isLoading = useCallback(
    result => {
      return (
        !result ||
        isCalendarLoading.isLoadingClientsEvents ||
        isCalendarLoading.isLoadingStaffEvents
      );
    },
    [
      isCalendarLoading.isLoadingClientsEvents,
      isCalendarLoading.isLoadingStaffEvents
    ]
  );

  const handleSubscriptionResult = useCallback(
    result => {
      const calendarEvents = result.subscriptionData?.data?.calendarEvents;

      // Empty events or loading events should do nothing and leave it to queries running
      if (isLoading(calendarEvents)) return;

      const isPendingFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.pending
      );

      const isCanceledFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.canceled
      );

      const isDeletedFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.deleted
      );

      const { clientEvents, providerEvents } = formatEventResult(
        calendarEvents,
        calendarView,
        calendarDate,
        isPendingFilter
      );

      const shouldShowCanceled =
        !providerEvents[0].isOriginal && isCanceledFilter;

      const shouldShowDelete = !providerEvents[0].isOriginal && isDeletedFilter;

      // filter out unselected providers or clients in the calendar
      const providerIds = providers.map(provider => provider.id);
      const clientIds = clients.map(client => client.id);

      const finalClientEvents = clientEvents.filter(event => {
        const clientId = event.client?.id;
        return clientId && clientIds.includes(clientId);
      });

      console.log(
        'calendarEventsPrefVar in eventSubscritionHook.ts',
        providerEvents
      );
      const finalProviderEvents = providerEvents.filter(event => {
        const providerId = event.provider?.id;
        return providerId && providerIds.includes(providerId);
      });

      const events = calendarEventsPrefVar();

      let updatedEvents = handleSubscriptionDataUpdate(
        finalProviderEvents,
        events,
        CALENDAR_ID,
        shouldShowCanceled,
        shouldShowDelete
      );

      updatedEvents = handleSubscriptionDataUpdate(
        finalClientEvents,
        updatedEvents,
        CALENDAR_ID,
        shouldShowCanceled,
        shouldShowDelete
      );

      calendarEventsPrefVar(updatedEvents);
    },
    [
      isLoading,
      filters.internals,
      calendarView,
      calendarDate,
      providers,
      clients
    ]
  );

  const { data, error, loading } = useSubscription(
    CALENDAR_EVENTS_SUBSCRIPTION,
    {
      onSubscriptionComplete: (...args) => {
        console.log('onSubscriptionComplete', args);
      },
      variables: {
        startDate: customizeRange(calendarDate, getView(calendarView)).start,
        endDate: customizeRange(calendarDate, getView(calendarView)).end
      },
      skip: !getFeatureAvailability(FEATURES.CALENDARS_SUBSCRIPTIONS),
      onSubscriptionData: handleSubscriptionResult
    }
  );

  console.log('Calendar subscription status:', data, error, loading);
};

const formatEventResult = (
  calendarEvents: IEvent[],
  calendarView: CalendarView,
  calendarDate: Date,
  isPendingFilter: boolean
) => {
  calendarEvents.forEach(event => {
    event.type =
      event.appointmentType?.eventType?.name === EVENT_TYPES.Admin
        ? EventType.adminEvent
        : EventType.clientEvent;
  });

  const { finalEvents: clientEvents } = mapToCalendarEventsV2(
    calendarEvents,
    calendarGroup.client,
    calendarView,
    calendarDate
  );

  const { finalEvents: providerEvents } = mapToCalendarEventsV2(
    calendarEvents,
    calendarGroup.provider,
    calendarView,
    calendarDate,
    [],
    isPendingFilter
  );

  return { clientEvents, providerEvents };
};
