import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import InputRange, { Range } from 'react-input-range';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ClientDayAvailabilityOutput } from 'model/v2/clients';
import { InputDayFilterRowWrapper } from './ClientAvailabilityView/style';
import AvailabilityTimeForm from './AvailabilityTimeForm';
import plus from 'assets/img/Plus.png';
import pencil from 'assets/img/Pencil.png';
import check from 'assets/img/Check.png';
interface IProps {
  availability: ClientDayAvailabilityOutput;
  availabilityKey: number;
  index: number;
  count: number;
  setClientDayAvailability: Dispatch<
    SetStateAction<Map<number, ClientDayAvailabilityOutput[]> | undefined>
  >;
  clientDayAvailability: Map<number, ClientDayAvailabilityOutput[]> | undefined;
}

const InputDayFilterRow: React.FC<IProps> = ({
  availability,
  availabilityKey,
  index,
  count,
  setClientDayAvailability,
  clientDayAvailability
}: IProps) => {
  const formatLabel = useCallback((value: number) => {
    const totalMinutes = value % 1440; // Ensure it's within a 24-hour period (1440 minutes).

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours === 0 && minutes === 0) {
      return ' ';
    }
    const amPm = hours >= 12 ? 'pm' : 'am';

    // Convert 0 hours to 12 for AM and 12-hour format.
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${amPm}`;
  }, []);
  const getTimeValue = useCallback((time: string) => {
    const timeArr = time.split(':');
    return parseInt(timeArr[0]) * 60 + parseInt(timeArr[1]);
  }, []);
  const dateConvert = useCallback((timeString: string) => {
    const parts = timeString.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    // Create a Date object with the time
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }, []);
  const range: Range = {
    max: getTimeValue(availability.endTime),
    min: getTimeValue(availability.startTime)
  };
  let isTimeDisabled = false;
  if (range.max === 0 && range.min === 0) isTimeDisabled = true;
  const [isDragTimeDisabled, setIsDragTimeDisabled] = useState<boolean>(false);
  const [isvalidTime, setIsvalidTime] = useState<boolean>(true);
  const getHours = (value: number) => Math.floor(value / 60);

  const getMinutes = (value: number) => {
    const hours = getHours(value);
    const minutes = value - hours * 60;
    return minutes;
  };
  const getConvertedTime = useCallback((value: number) => {
    const hours = getHours(value);
    const minutes = getMinutes(value);
    const formattedHours = hours > 9 ? '' + hours : '0' + hours;
    const formattedMinutes = minutes > 9 ? '' + minutes : '0' + minutes;
    return formattedHours + ':' + formattedMinutes;
  }, []);
  const onRangeChange = useCallback(
    (range: any) => {
      setIsDragTimeDisabled(false);
      if (range.max - range.min <= 5 && range.min === 420) {
        setIsDragTimeDisabled(true);
        range.min = 0;
        range.max = 0;
      }
      if (clientDayAvailability) {
        // Clone the current state to avoid mutating it
        const updatedAvailability = new Map(clientDayAvailability);
        if (updatedAvailability && updatedAvailability.has(availabilityKey)) {
          const existingAvailability = updatedAvailability.get(availabilityKey);
          const availabilityValue = existingAvailability![index];
          availabilityValue.startTime = getConvertedTime(range.min);
          availabilityValue.endTime = getConvertedTime(range.max);
          existingAvailability!.splice(index, 1, availabilityValue);
          updatedAvailability.set(availabilityKey, existingAvailability!);
        }
        setClientDayAvailability(updatedAvailability);
      }
    },
    [range, clientDayAvailability, setClientDayAvailability]
  );
  const handleAddAvailability = useCallback(() => {
    if (clientDayAvailability) {
      // Clone the current state to avoid mutating it
      const updatedAvailability = new Map(clientDayAvailability);
      const timeSlot = {
        dayOfWeek: availabilityKey,
        startTime: '00:00',
        endTime: '00:00',
        location: 7
      };
      // Check if the dayOfWeek key exists in the map
      if (updatedAvailability && updatedAvailability.has(availabilityKey)) {
        const existingAvailability = updatedAvailability.get(availabilityKey);
        existingAvailability!.push(timeSlot);
        updatedAvailability.set(availabilityKey, existingAvailability!);
      }
      setClientDayAvailability(updatedAvailability);
    }
  }, [clientDayAvailability, setClientDayAvailability]);

  const handleDeleteAvailability = useCallback(() => {
    if (clientDayAvailability) {
      // Clone the current state to avoid mutating it
      const updatedAvailability = new Map(clientDayAvailability);

      if (updatedAvailability && updatedAvailability.has(availabilityKey)) {
        const existingAvailability = updatedAvailability.get(availabilityKey);
        existingAvailability!.splice(index, 1);
        updatedAvailability.set(availabilityKey, existingAvailability!);
      }
      setClientDayAvailability(updatedAvailability);
    }
  }, [clientDayAvailability, setClientDayAvailability]);
  const closeCustomEdit = useCallback(() => {
    if (clientDayAvailability && isvalidTime) {
      // Clone the current state to avoid mutating it
      const updatedAvailability = new Map(clientDayAvailability);
      if (updatedAvailability && updatedAvailability.has(availabilityKey)) {
        const existingAvailability = updatedAvailability.get(availabilityKey);
        const availabilityValue = existingAvailability![index];
        availabilityValue.customEdit = false;
        existingAvailability!.splice(index, 1, availabilityValue);
        updatedAvailability.set(availabilityKey, existingAvailability!);
      }
      setClientDayAvailability(updatedAvailability);
    }
  }, [clientDayAvailability, setClientDayAvailability, isvalidTime]);
  const openCustomEdit = useCallback(() => {
    if (clientDayAvailability) {
      // Clone the current state to avoid mutating it
      const updatedAvailability = new Map(clientDayAvailability);

      if (updatedAvailability && updatedAvailability.has(availabilityKey)) {
        const existingAvailability = updatedAvailability.get(availabilityKey);
        const availabilityValue = existingAvailability![index];
        if (availabilityValue.startTime === '00:00')
          availability.startTime = '07:00';
        if (availabilityValue.endTime === '00:00')
          availability.endTime = '07:15';
        availabilityValue.customEdit = true;
        existingAvailability!.splice(index, 1, availabilityValue);
        updatedAvailability.set(availabilityKey, existingAvailability!);
      }
      setIsDragTimeDisabled(false);
      setClientDayAvailability(updatedAvailability);
    }
  }, [clientDayAvailability, setClientDayAvailability]);
  return (
    <>
      <InputDayFilterRowWrapper
        isTimeDisabled={isTimeDisabled}
        isDragTimeDisabled={isDragTimeDisabled}
      >
        <div key={availability.id}>
          <Grid style={{ width: '360px' }}>
            {availability.customEdit! && (
              <Grid.Row style={{ paddingBottom: '0px' }}>
                <Grid.Column width={6}>
                  <AvailabilityTimeForm
                    time={dateConvert(availability.startTime)}
                    controlName={'start'}
                    displayName={'Start Time'}
                    dayIndex={availabilityKey}
                    index={index}
                    clientDayAvailability={clientDayAvailability}
                    setClientDayAvailability={setClientDayAvailability}
                    setIsvalidTime={setIsvalidTime}
                  ></AvailabilityTimeForm>
                </Grid.Column>
                <Grid.Column width={6}>
                  <AvailabilityTimeForm
                    time={dateConvert(availability.endTime)}
                    controlName={'end'}
                    displayName={'End Time'}
                    dayIndex={availabilityKey}
                    index={index}
                    clientDayAvailability={clientDayAvailability}
                    setClientDayAvailability={setClientDayAvailability}
                    setIsvalidTime={setIsvalidTime}
                  ></AvailabilityTimeForm>
                </Grid.Column>
                <Grid.Column width={4}>
                  <img
                    src={check}
                    className="faIcon faEditCls"
                    alt="check"
                    onClick={closeCustomEdit}
                    style={{
                      cursor: !isvalidTime ? 'not-allowed' : 'pointer'
                    }}
                  />
                  {index === count - 1 && (
                    <>
                      <img
                        src={plus}
                        alt="plus"
                        onClick={() => {
                          handleAddAvailability();
                        }}
                        className="faIcon faEditCls"
                      />
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
            {!availability.customEdit! && (
              <Grid.Row style={{ paddingBottom: '2px' }}>
                <Grid.Column width={12}>
                  <div className="time-cls">
                    {range.min > 0 && range.max > 0 && (
                      <p>
                        {formatLabel(range.min)} - {formatLabel(range.max)}
                      </p>
                    )}
                  </div>
                  <InputRange
                    maxValue={1140}
                    minValue={420}
                    value={range}
                    formatLabel={formatLabel}
                    onChange={onRangeChange}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <img
                    src={pencil}
                    alt="pencil"
                    onClick={openCustomEdit}
                    className="faIcon"
                  />
                  {count > 1 && (
                    <>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ color: `#E82424` }}
                        className="faIcon"
                        onClick={() => {
                          handleDeleteAvailability();
                        }}
                      />
                    </>
                  )}
                  {index === count - 1 && (
                    <img
                      src={plus}
                      alt="plus"
                      onClick={() => {
                        handleAddAvailability();
                      }}
                      className="faIcon"
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>
      </InputDayFilterRowWrapper>
    </>
  );
};
export default React.memo(InputDayFilterRow);
