import {
  ICalendarFilterOption,
  IFilterSelectionType,
  ISavedFilterOptions,
  ITagFilterForm
} from 'model/calendar/filters';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Input, Select } from 'api/sharedComponents/reactHookFormComponents';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { mapToDefaultTagFiltersValue } from 'utils/mappers/filters';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DefaultTagFilterOption,
  internalFilters,
  IS_DEFAULT_FILTER_TAGS_OPTIONS,
  TAG_FILTER
} from 'utils/constants/calendarFilters';
import { TAG_FILTER_SCHEMA } from 'utils/validators/configurations/tagFilter';
import PopupActions from 'components/popupActions';
import { FilterFormWrapper } from './style';
import { FilterTagListWrapper } from '../style';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useCalendarFilterFetcherHook from 'hooks/calendarFilterFetcherHook';
import { accountContext } from 'App';
import { Button } from 'semantic-ui-react';
import TagOption from './TagOption';
import {
  GET_FILTER_SELECTION_TYPE,
  GET_SCHEDULER_FILTERS
} from 'api/graphql/v2/queries/Filters';
import { getFilterSelections } from './utils';
import {
  ADD_FILTER,
  DELETE_FILTER,
  UPDATE_FILTER
} from 'api/graphql/v2/mutations/Filters';
import Content from 'views/components/ui/content';

interface IProps {
  filterName?: string;
  pinnedFilter?: ISavedFilterOptions;
  onClose: () => void;
  pageName: string;
  additionalFilter?: Record<string, any>;
  setCheckedSavedFilter?: Dispatch<SetStateAction<string>>;
  refetch?: () => void;
}

const FilterForm: React.FC<IProps> = ({
  filterName,
  pinnedFilter,
  onClose,
  pageName,
  additionalFilter,
  setCheckedSavedFilter,
  refetch
}: IProps) => {
  const account = useContext(accountContext);
  const { allFilters } = useCalendarFilterFetcherHook(pageName);
  if (pinnedFilter) pinnedFilter.additionalFilter = additionalFilter;
  const filter = useMemo(() => {
    return allFilters?.find(f => f.name === filterName) || pinnedFilter;
  }, [allFilters, filterName, pinnedFilter]);
  const { data: selectionTypeData, loading: isSelectionTypeLoading } = useQuery(
    GET_FILTER_SELECTION_TYPE,
    {
      fetchPolicy: 'network-only'
    }
  );

  const filterSelectionType: IFilterSelectionType = {};
  selectionTypeData?.getFilterSelectionType.map((selectionType: any) => {
    filterSelectionType[selectionType.typeName] = selectionType.id;
  });
  const smartSchedulingTags = filter?.smartSchedulingStatus?.map(f => {
    return <TagOption value={f.label} />;
  });
  const languageTags = filter?.language?.map(f => {
    return <TagOption value={f.label} />;
  });
  const clinicTags = filter?.clinics?.map(f => {
    return <TagOption value={f.label} />;
  });
  const programTags = filter?.programs?.map(f => {
    return <TagOption value={f.label} />;
  });

  const locationTags = filter?.locations?.map(f => (
    <TagOption value={f.label} />
  ));

  const departmentTags = filter?.departments?.map(f => (
    <TagOption value={f.label} />
  ));

  const specialitiesTags = filter?.specialities?.map(f => (
    <TagOption value={f.label} />
  ));

  const waitListReasonTags = filter?.waitListReason?.map(f => (
    <TagOption value={f.label} />
  ));

  const therapyTypeTags = filter?.therapyType?.map(f => (
    <TagOption value={f.label} />
  ));

  const serviceTypeTags = filter?.serviceType?.map(f => (
    <TagOption value={f.label} />
  ));

  const clientAvailabilityTags = filter?.clientAvailability?.map(f => (
    <TagOption value={f.label} />
  ));

  const statusTags = filter?.status?.map(f => <TagOption value={f.label} />);

  const internalTag = ((filter?.internals ||
    []) as internalFilters[])?.map(
    (f: internalFilters | ICalendarFilterOption) => (
      <TagOption
        value={
          filter?.name
            ? (f as ICalendarFilterOption)?.label
            : (f as internalFilters)[0]?.toUpperCase() +
              (f as internalFilters)?.slice(1)
        }
      />
    )
  );

  const AdditionalFilterTag = () => {
    if (filter?.additionalFilter?.isFilterApplied) {
      return <TagOption value="Additional Filter" />;
    } else return <></>;
  };
  const {
    formState: { isSubmitting: isSubmittingDelete },
    handleSubmit: handleSubmitDelete
  } = useForm<ITagFilterForm>();

  const defaultValues = useMemo(() => {
    return mapToDefaultTagFiltersValue(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.isDefault]);

  const methods = useForm<ITagFilterForm>({
    resolver: yupResolver(TAG_FILTER_SCHEMA(allFilters, filterName)),
    defaultValues: mapToDefaultTagFiltersValue(filter)
  });

  const {
    errors,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [updateFilter] = useMutation(UPDATE_FILTER);
  const [deleteRecord] = useMutation(DELETE_FILTER);
  const [addFilter] = useMutation(ADD_FILTER);
  const [username, setUsername] = useState<string>();
  useEffect(() => {
    if (account.username !== undefined) setUsername(account.username);
  }, [account.username]);
  const onConfirm = handleSubmit(async (data: ITagFilterForm) => {
    if (filter?.name) {
      //Edit mode
      const targetFilter = allFilters.find(f => f.id === filter.id);
      await updateFilter({
        variables: {
          id: targetFilter?.id?.toString(),
          pageName: pageName,
          updatedFilter: {
            name: data.name,
            isDefault: data.isDefault === DefaultTagFilterOption.yes,
            userId: account.username
          }
        },
        refetchQueries: [
          {
            query: GET_SCHEDULER_FILTERS,
            variables: { id: account.username, pageName: pageName }
          }
        ]
      })
        .then(() => {
          onClose();
          refetch && refetch();
        })
        .catch(error => {
          console.log('Error while saving filter', JSON.stringify(error));
        });
    } else {
      const filterSelections = getFilterSelections(
        filter!,
        filterSelectionType
      );

      const newFilter = {
        name: data.name,
        userId: account.username,
        isDefault: data.isDefault === DefaultTagFilterOption.yes,
        filterSelections: filterSelections,
        pageName: pageName
      };

      await addFilter({
        variables: { newFilter: newFilter },
        refetchQueries: [
          {
            query: GET_SCHEDULER_FILTERS,
            variables: { id: account.username, pageName: pageName }
          }
        ]
      })
        .then(() => {
          onClose();
          refetch && refetch();
        })
        .catch(error => {
          console.log('Error while saving filter', JSON.stringify(error));
        });
      //if (selectSavedFilter) selectSavedFilter(data.name);
      if (setCheckedSavedFilter) setCheckedSavedFilter(data.name);
    }
  });

  const deleteFilter = handleSubmitDelete(async () => {
    await deleteRecord({
      variables: { id: filter?.id?.toString() },

      refetchQueries: [
        {
          query: GET_SCHEDULER_FILTERS,
          variables: { id: username, pageName: pageName }
        }
      ]
    })
      .then(() => {
        onClose();
        refetch && refetch();
      })
      .catch(error => {
        console.log('Error while saving filter', JSON.stringify(error));
      });
  });

  return (
    <Content loading={isSelectionTypeLoading} data={true} fullHeight={true}>
      {() => (
        <>
          <FilterFormWrapper>
            <FormProvider {...methods}>
              <div className="header">
                <h3 className="filter-form-title">Save Filter</h3>
                {filter?.name && (
                  <Button
                    basic
                    loading={isSubmittingDelete}
                    className="tag-delete-btn"
                    onClick={deleteFilter}
                  >
                    delete
                  </Button>
                )}
              </div>

              <FilterTagListWrapper className="filter-tag-list-wrapper">
                <div className="tags-container">
                  {locationTags} {clinicTags} {serviceTypeTags}
                  {therapyTypeTags} {statusTags}
                  {smartSchedulingTags} {waitListReasonTags}
                  {departmentTags} {specialitiesTags}
                  {internalTag} {programTags} {clientAvailabilityTags}
                  {languageTags}
                  {AdditionalFilterTag()}
                </div>
              </FilterTagListWrapper>

              <FormItem label={'Name'} WrapperClassName="name-field">
                <Controller
                  name={TAG_FILTER.name}
                  render={fieldProps => (
                    <Input
                      field={fieldProps}
                      placeholder={'Input Name'}
                      errors={errors}
                    />
                  )}
                />
              </FormItem>

              <FormItem
                label="Save as Default"
                WrapperClassName="save-as-field"
              >
                <Controller
                  name={TAG_FILTER.isDefault}
                  render={fieldProps => (
                    <Select
                      options={IS_DEFAULT_FILTER_TAGS_OPTIONS}
                      field={fieldProps}
                      errors={errors}
                    />
                  )}
                />
              </FormItem>
              <PopupActions
                disabled={isSubmitting || !isDirty}
                loading={isSubmitting}
                onConfirm={onConfirm}
                onCancel={onClose}
              />
            </FormProvider>
          </FilterFormWrapper>
        </>
      )}
    </Content>
  );
};

export default FilterForm;
