import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { ISpecialitiesOptionsList } from 'model/v2';

interface Props {
  specialties: ISpecialitiesOptionsList[];
  checkedItems: Set<Number>;
  onHandleChange: (id: number, isChecked?: boolean) => void;
}

const SpecialtyItems = ({
  specialties,
  checkedItems,
  onHandleChange
}: Props) => {
  return (
    <>
      {specialties?.length > 0 && (
        <>
          {specialties.map(specialty => {
            return (
              <div className="saved_filters" key={specialty.id}>
                <Checkbox
                  className="saved-filters_checkbox"
                  label={specialty.title}
                  name={specialty.title}
                  value={specialty.id}
                  checked={checkedItems.has(parseInt(specialty.id!) || 0)}
                  onChange={(_e, data) =>
                    onHandleChange(parseInt(specialty.id!), data.checked)
                  }
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export default React.memo(SpecialtyItems);
