import styled from 'styled-components';
import { Switch } from 'antd';

export const LibSwitch = styled(Switch)`
  background-color: #8be07d;
  min-width: 48px;
  height: 20px;
  &.ant-switch-checked {
    background-color: #59c2ff;
  }
  .ant-switch-loading-icon,
  &::after {
    top: 3px;
    width: 12px;
    height: 12px;
  }
`;
