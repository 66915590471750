import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DriveTimeMileageWrapper } from '../style';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useLazyQuery } from 'react-apollo';
import Loader from 'views/components/ui/content/Loader';
import { FormItem, Input } from 'views/containers/form';
import searchIcon from 'assets/img/bars-search-search-bar.svg';
import {
  GET_DRIVE_TIME_MILEAGE,
  GET_SEARCH_ADDRESS
} from 'api/graphql/v2/queries/Events';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { IDriveTimeMileage } from 'model/v2';
import DriveTimeLocation from './DriveTimeLocation';
import { Grid } from 'semantic-ui-react';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
interface Props {
  pageName?: string;
  driveTimeAddress?: any;
  adminAddressisFetch?: any;
  isAddressFetched?: any;
  isSideBarEdit?: Boolean;
}
const DriveTimeMileage: React.FC<Props> = ({
  pageName,
  driveTimeAddress,
  adminAddressisFetch,
  isAddressFetched,
  isSideBarEdit
}) => {
  const methods = useFormContext();
  const selectStartingAddrrRef = useRef<any>(null);
  const [startingAddrList, setStartingAddrList] = useState<any[]>([]);
  const [destAddrList, setDestAddrList] = useState<any[]>([]);
  const [startingAddr, setStartingAddr] = useState<
    IDriveTimeMileage | undefined
  >(methods.getValues('driveTimeSourceAddr'));
  let [isAutoPopulateAddress, setIsAutopopulateAddress] = useState<
    Record<string, Boolean>
  >({ isPopulateStartAddress: false, isPopulateDestAddress: false });
  const adajacentEventAddress = {
    startingAdjacantAddress:
      driveTimeAddress?.getDriveEventAddress.startingAddress,
    destinationAdajacentAddress:
      driveTimeAddress?.getDriveEventAddress.destinationAddress
  };

  const [destAddr, setDestAddr] = useState<IDriveTimeMileage | undefined>(
    methods.getValues('driveTimeDesteAddr')
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mileageLoading, setMileageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (adajacentEventAddress.startingAdjacantAddress)
      handleStartingAddressSearch(
        adajacentEventAddress.startingAdjacantAddress,
        true
      );
    else if (adajacentEventAddress.startingAdjacantAddress === null)
      setStartingAddr(undefined);
    if (adajacentEventAddress.destinationAdajacentAddress)
      handleDestAddressSearch(
        adajacentEventAddress.destinationAdajacentAddress,
        true
      );
    else if (adajacentEventAddress.destinationAdajacentAddress === null)
      setDestAddr(undefined);
  }, [isAddressFetched]);

  const [doStartingAddrRequest, { data: startAddressList }] = useLazyQuery(
    GET_SEARCH_ADDRESS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setStartingAddrList(startAddressList?.searchAddress);
        setIsLoading(false);
        let [firstFetchedAddress] = startAddressList?.searchAddress;
        if (isAutoPopulateAddress.isPopulateStartAddress && firstFetchedAddress)
          handleStartingAddressChange([firstFetchedAddress]);
        else if (!firstFetchedAddress) setStartingAddr(undefined);
      }
    }
  );
  const [doDestAddrRequest, { data: destAddressList }] = useLazyQuery(
    GET_SEARCH_ADDRESS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setDestAddrList(destAddressList?.searchAddress);
        let [firstFetchedAddress] = destAddressList?.searchAddress;
        console.log(
          firstFetchedAddress,
          isAutoPopulateAddress,
          'handleDestinationAddressChange'
        );
        if (isAutoPopulateAddress.isPopulateDestAddress && firstFetchedAddress)
          handleDestinationAddressChange([firstFetchedAddress]);
        else if (!firstFetchedAddress) setDestAddr(undefined);
        setIsLoading(false);
      }
    }
  );
  const [doMileageRequest, { data: driveTimeMileage }] = useLazyQuery(
    GET_DRIVE_TIME_MILEAGE,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setMileageLoading(false);
        methods.setValue(
          'driveTimeMileage',
          driveTimeMileage.getDriveTimeMileage[0]
        );
        methods.setValue('mileage-request-in-progress', false);
        if (!firstOrLastDriveToNonClinic)
          methods.setValue(
            'reimbursableMileage',
            driveTimeMileage.getDriveTimeMileage[0]
          );
      }
    }
  );
  const handleStartingAddressSearch = useCallback(
    (query: any, isAutoPopulate?: Boolean) => {
      setIsLoading(true);
      isAutoPopulate &&
        setIsAutopopulateAddress(prevValue => {
          return { ...prevValue, isPopulateStartAddress: true };
        });
      doStartingAddrRequest({
        variables: {
          querySearch: query?.trim()
        }
      });
    },
    [doStartingAddrRequest]
  );
  const handleDestAddressSearch = useCallback(
    (query: any, isAutoPopulate?: Boolean) => {
      if (query) {
        setIsLoading(true);
        isAutoPopulate &&
          setIsAutopopulateAddress(prevValue => {
            return { ...prevValue, isPopulateDestAddress: true };
          });
        doDestAddrRequest({
          variables: {
            querySearch: query?.trim()
          }
        });
      }
    },
    [doDestAddrRequest]
  );
  const handleStartingAddressChange = useCallback(
    data => {
      let startAddrlatLon;
      let destAddrLatLon = destAddr
        ? `${destAddr.lat},${destAddr.lon}`
        : undefined;
      if (data.length && data[0].lat && data[0].lon) {
        startAddrlatLon = `${data[0].lat},${data[0].lon}`;
        //setStartingAddrLatLon(latLon);
        if (data[0].__typename) delete data[0].__typename;
        setStartingAddr(data[0]);
        methods.setValue('driveTimeSourceAddr', data[0]);
      }
      if (startAddrlatLon && destAddrLatLon) {
        const query = `${startAddrlatLon}:${destAddrLatLon}`;
        setMileageLoading(true);
        methods.setValue('mileage-request-in-progress', true);
        doMileageRequest({
          variables: {
            querySearch: query?.trim()
          }
        });
      } else if (isAutoPopulateAddress.isPopulateStartAddress)
        methods.setValue('driveTimeMileage', 0);
      setIsAutopopulateAddress(prevValue => {
        return { ...prevValue, isPopulateStartAddress: false };
      });

      methods.setValue('drivetime-source-address-search-in-progress', false);
    },
    [destAddr, methods, doMileageRequest, setIsAutopopulateAddress]
  );
  const handleDestinationAddressChange = useCallback(
    data => {
      let destAddrLatLon;
      let startingAddrLatLon = startingAddr
        ? `${startingAddr.lat},${startingAddr.lon}`
        : undefined;
      if (data.length && data[0].lat && data[0].lon) {
        destAddrLatLon = `${data[0].lat},${data[0].lon}`;
        //setDestAddrLatLon(latLon);
        if (data[0].__typename) delete data[0].__typename;
        setDestAddr(data[0]);
        methods.setValue('driveTimeDesteAddr', data[0]);
      }
      if (startingAddrLatLon && destAddrLatLon) {
        const query = `${startingAddrLatLon}:${destAddrLatLon}`;
        setMileageLoading(true);
        methods.setValue('mileage-request-in-progress', true);
        doMileageRequest({
          variables: {
            querySearch: query?.trim()
          }
        });
      } else if (isAutoPopulateAddress.isPopulateDestAddress)
        methods.setValue('driveTimeMileage', 0);
      setIsAutopopulateAddress(prevValue => {
        return { ...prevValue, isPopulateDestAddress: false };
      });

      methods.setValue(
        'drivetime-destination-address-search-in-progress',
        false
      );
    },
    [startingAddr, methods, doMileageRequest]
  );
  const handleStartAddrInputChange = useCallback(() => {
    setIsLoading(true);
    setStartingAddrList([]);
  }, []);
  const handleDestAddrInputChange = useCallback(() => {
    setIsLoading(true);
    setDestAddrList([]);
  }, []);
  const mileage: number | string | undefined = useWatch({
    name: 'driveTimeMileage',
    control: methods.control
  });

  const reimbursableMileageVal: number | undefined = useWatch({
    name: 'reimbursableMileage',
    control: methods.control
  });
  const firstOrLastDriveToNonClinic: boolean | undefined = useWatch({
    name: 'isFirstOrLastDriveToNonClinic',
    control: methods.control
  });

  useEffect(() => {
    if (mileage === undefined) return;
    let numMileage = Number(mileage);
    let reimbursableMileage = numMileage;
    if (firstOrLastDriveToNonClinic) {
      reimbursableMileage = numMileage <= 15 ? 0 : numMileage - 15;
    }
    methods.setValue(
      'reimbursableMileage',
      parseFloat(reimbursableMileage.toFixed(2))
    );
  }, [firstOrLastDriveToNonClinic, mileage, methods]);
  return (
    <DriveTimeMileageWrapper isSideBarEdit={isSideBarEdit} pageName={pageName}>
      <FormItem optional={false} label="Starting Address">
        {!adminAddressisFetch ? (
          <>
            <img src={searchIcon} className="search-icon admin-icon" alt="" />
            <Controller
              name="driveTimeSourceAddr"
              control={methods.control}
              render={() => (
                <AsyncTypeahead
                  disabled={false}
                  defaultSelected={
                    adajacentEventAddress?.startingAdjacantAddress
                      ? [adajacentEventAddress?.startingAdjacantAddress]
                      : []
                  }
                  className="client-Provider_wrapper Provider_Wrapper Admin_Wrapper"
                  filterBy={() => true}
                  ref={selectStartingAddrrRef}
                  id="search-starting-address"
                  labelKey="freeformAddress"
                  isLoading={isLoading}
                  minLength={3}
                  onSearch={handleStartingAddressSearch}
                  onBlur={(data: any) => {
                    if (data.target.value.length <= 1) {
                      setStartingAddr(undefined);
                      methods.setValue('driveTimeSourceAddr', undefined);
                      methods.setValue('driveTimeMileage', null);
                      methods.setValue('isFirstOrLastDriveToNonClinic', false);
                      methods.setValue('reimbursableMileage', null);
                    }
                  }}
                  options={startingAddrList}
                  placeholder={'Starting Address'}
                  onChange={(data: any[]) => {
                    handleStartingAddressChange(data);
                  }}
                  delay={500}
                  renderMenuItemChildren={option => (
                    <p className="client-Provider_option">
                      {option.freeformAddress}
                    </p>
                  )}
                  emptyLabel={
                    <p className="client-Provider_results">No results</p>
                  }
                  promptText={
                    !isLoading ? (
                      <p className="client-Provider_search">
                        Type to search...
                      </p>
                    ) : (
                      <p className="client-Provider_loader">
                        <Loader />
                      </p>
                    )
                  }
                  searchText={
                    <p className="client-Provider_loader">
                      <Loader />
                    </p>
                  }
                  onInputChange={handleStartAddrInputChange}
                  useCache={false} //to remove any cached values that may be added to calendar already
                />
              )}
            />
          </>
        ) : (
          <div className="Address-loader">
            <span>Fetching address please wait </span>
            <div className="loader_inner_cont">
              <Loader />
            </div>
          </div>
        )}
        {methods.errors.driveTimeSourceAddr?.freeformAddress && (
          <p className="error-message">
            {methods.errors.driveTimeSourceAddr?.freeformAddress.message}
          </p>
        )}
      </FormItem>
      <DriveTimeLocation address={startingAddr!} />
      <FormItem optional={false} label="Destination Address">
        {!adminAddressisFetch ? (
          <>
            <img src={searchIcon} className="search-icon admin-icon" alt="" />
            <Controller
              name="driveTimeDesteAddr"
              control={methods.control}
              render={() => (
                <AsyncTypeahead
                  disabled={false}
                  defaultSelected={
                    adajacentEventAddress?.destinationAdajacentAddress
                      ? [adajacentEventAddress?.destinationAdajacentAddress]
                      : []
                  }
                  className="client-Provider_wrapper Provider_Wrapper"
                  filterBy={() => true}
                  ref={selectStartingAddrrRef}
                  id="search-destination-address"
                  labelKey="freeformAddress"
                  isLoading={isLoading}
                  minLength={3}
                  onSearch={handleDestAddressSearch}
                  onBlur={(data: any) => {
                    if (data.target.value.length <= 1) {
                      setDestAddr(undefined);
                      methods.setValue('driveTimeDesteAddr', undefined);
                      methods.setValue('driveTimeMileage', null);
                      methods.setValue('isFirstOrLastDriveToNonClinic', false);
                      methods.setValue('reimbursableMileage', null);
                    }
                  }}
                  options={destAddrList}
                  placeholder={'Destination Address'}
                  onChange={(data: any[]) => {
                    handleDestinationAddressChange(data);
                  }}
                  delay={500}
                  renderMenuItemChildren={option => (
                    <p className="client-Provider_option">
                      {option.freeformAddress}
                    </p>
                  )}
                  emptyLabel={
                    <p className="client-Provider_results">No results</p>
                  }
                  promptText={
                    !isLoading ? (
                      <p className="client-Provider_search">
                        Type to search...
                      </p>
                    ) : (
                      <p className="client-Provider_loader">
                        <Loader />
                      </p>
                    )
                  }
                  searchText={
                    <p className="client-Provider_loader">
                      <Loader />
                    </p>
                  }
                  onInputChange={handleDestAddrInputChange}
                  useCache={false} //to remove any cached values that may be added to calendar already
                />
              )}
            />
          </>
        ) : (
          <div className="Address-loader">
            <span>Fetching address please wait </span>
            <div className="loader_inner_cont">
              <Loader />
            </div>
          </div>
        )}
        {methods.errors.driveTimeDesteAddr?.freeformAddress && (
          <p className="error-message">
            {methods.errors.driveTimeDesteAddr?.freeformAddress.message}
          </p>
        )}
      </FormItem>
      <DriveTimeLocation address={destAddr!} />
      <FormItem optional={false} label="Mileage (Miles)">
        <Controller
          name="driveTimeMileage"
          control={methods.control}
          render={() => (
            <Input
              className="mileage-style"
              name="driveTimeMileage"
              placeholder="Mileage"
              value={mileage}
              onChange={(e: any) => {
                let value = e.target.value.replace(/[^0-9.]/g, '');
                if (value === '') {
                  value = null;
                  methods.setValue('isFirstOrLastDriveToNonClinic', false);
                }
                methods.setValue('driveTimeMileage', value);
                if (!firstOrLastDriveToNonClinic)
                  methods.setValue('reimbursableMileage', value);
              }}
              errors={methods.errors}
            />
          )}
        />
        {mileageLoading && (
          <p className="mileage_loader">
            <Loader />
          </p>
        )}
        {methods.errors.driveTimeMileage && (
          <p className="error-message">
            {methods.errors.driveTimeMileage?.message}
          </p>
        )}
      </FormItem>
      {mileage !== undefined && mileage !== null && (
        <>
          <div className="driveTime_cls">
            <Grid className="grid">
              <div className="rectangle"></div>
              <Grid.Row>
                <Grid.Column width={10} className="reimburse-mileage-text">
                  Is this drive the first/last drive to/from a non clinic
                  location
                </Grid.Column>
                <Grid.Column width={6}>
                  <Controller
                    name="isFirstOrLastDriveToNonClinic"
                    control={methods.control}
                    render={fieldProps => (
                      <Select
                        className="appt-category-type"
                        options={[
                          { value: false, label: 'No' },
                          { value: true, label: 'Yes' }
                        ]}
                        defaultValue="No"
                        onSelect={(option: any) => {
                          methods.setValue(
                            'isFirstOrLastDriveToNonClinic',
                            option
                          );
                        }}
                        field={fieldProps}
                        errors={methods.errors}
                      />
                    )}
                    emptyLabel={
                      <p className="client-Provider_results">No results</p>
                    }
                    promptText={
                      !isLoading ? (
                        <p className="client-Provider_search">
                          Type to search...
                        </p>
                      ) : (
                        <p className="client-Provider_loader">
                          <Loader />
                        </p>
                      )
                    }
                    searchText={
                      <p className="client-Provider_loader">
                        <Loader />
                      </p>
                    }
                    onInputChange={handleStartAddrInputChange}
                    useCache={false} //to remove any cached values that may be added to calendar already
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          {firstOrLastDriveToNonClinic && (
            <FormItem optional={false} label="Reimbursable Mileage (Miles)">
              <Controller
                name="reimbursableMileage"
                control={methods.control}
                render={() => (
                  <Input
                    className="mileage-style"
                    name="reimbursableMileage"
                    placeholder="Reimbursable Mileage"
                    value={reimbursableMileageVal}
                    type="number"
                    disabled="true"
                    onChange={(e: any) => {
                      methods.setValue('reimbursableMileage', e.target.value);
                    }}
                    errors={methods.errors}
                  />
                )}
              />
              {methods.errors.reimbursableMileage && (
                <p className="error-message">
                  {methods.errors.reimbursableMileage?.message}
                </p>
              )}
            </FormItem>
          )}
        </>
      )}
    </DriveTimeMileageWrapper>
  );
};
export default React.memo(DriveTimeMileage);
