import styled from 'styled-components';

export const AddAlternateTemplateModalWrapper = styled.div`
  font-family: Lato !important;
  font-size: 15px !important;
  border: 1px solid #6f42f5;
  border-top: 10px solid #6f42f5;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;

  .startTemptInputField {
    border: 1px solid #6f42f5;
    border-radius: 3px;
    font-size: 15px;
  }

  .altModalFormUpperContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .altModalFormUpperContent {
    min-width: 50%;
  }

  .startTemplateInput {
    width: 100%;
    padding-right: 20px;
  }

  .date-picker-container {
    max-width: 50%;
    margin-right: 15px;
  }

  .altModalFormMidContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }

  .altModalFormMidContent {
    padding: 0px 20px 0px 0px;
    width: 100%;
  }

  .templateTitleInput {
    border: 1px solid #6f42f5;
    border-radius: 3px;
    height: 32px;
    padding: 0 11px;
  }

  .altModalFormBottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .description-style {
    display: flex;
    width: auto;
  }

  .description-text-area-style {
    border: 1px solid lightgray;
    border-radius: 5px;
    resize: none;
    &.ant-input {
      min-height: 100px;
      width: 290px;
    }
  }

  .alt-modal-form-buttons-container {
    margin-bottom: 28px;
    margin-right: 28px;
    button {
      font-family: Lato;
      border: none;
      font-size: 15px;
      border-radius: 0;
      padding: 0;
      &:first-of-type {
        color: #919699;
        height: 18px;
      }
      &:last-of-type {
        color: #6f42f5;
        border-bottom: 1px solid #6f42f5;
        margin-left: 24px;
        padding-bottom: 20px;
        height: 18px;
      }
    }
  }

  .error {
    position: absolute;
    padding: 5px 0;
  }
`;
