import React from 'react';
import { Field } from 'formik';
import { Row, Empty, Col } from 'antd';
import { FormItem, Select } from 'views/containers/form';
import { AddTherapyForm } from '../EditCarePlan.style';
import { getApptType } from 'utils/common';
import {
  mapToAppointmentOption,
  mapStringArrayToOptions
} from 'utils/mappers/form';

const RecommendedEvaluation = ({
  keyForm,
  recommendation,
  apptTypes,
  clientResponses,
  onDelete,
  setFieldValue
}) => {
  const apptType = getApptType({ title: recommendation.apptType }, apptTypes);

  const handleApptTypeSelect = () => {
    setFieldValue(`recommendedEvaluations.${keyForm}.apptSubType`, undefined);
  };

  return (
    <AddTherapyForm>
      <Row type="flex" justify="start" align="bottom" gutter={16}>
        <Col sx={16} sm={20}>
          <FormItem label="Appointment Type" xs={16} sm={12}>
            <Field
              name={`recommendedEvaluations.${keyForm}.apptType`}
              render={fieldProps => (
                <>
                  <Select
                    value={recommendation.apptType}
                    placeholder="Appointment Type"
                    options={mapToAppointmentOption(apptTypes)}
                    onSelect={handleApptTypeSelect}
                    notFoundContent={
                      apptTypes ? (
                        <div>Loading...</div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    {...fieldProps}
                  />
                </>
              )}
            />
          </FormItem>
          {apptType?.isClinical && (
            <FormItem label="Appointment Sub-Type" xs={16} sm={12}>
              <Field
                name={`recommendedEvaluations.${keyForm}.apptSubType`}
                render={fieldProps => (
                  <>
                    <Select
                      value={recommendation.apptSubType}
                      placeholder="Appointment Sub-Type"
                      options={mapToAppointmentOption(apptType?.subTypes || [])}
                      {...fieldProps}
                    />
                  </>
                )}
              />
            </FormItem>
          )}
          <FormItem label="Client Request" xs={16} sm={6}>
            <Field
              name={`recommendedEvaluations.${keyForm}.clientResponse`}
              render={fieldProps => (
                <>
                  <Select
                    value={recommendation.clientResponse}
                    placeholder="Select Client"
                    options={mapStringArrayToOptions(
                      Object.values(clientResponses)
                    )}
                    notFoundContent={
                      apptTypes ? (
                        <div>Loading...</div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    {...fieldProps}
                  />
                </>
              )}
            />
          </FormItem>
        </Col>
        <Row type="flex" justify="center" align="middle" gutter={16}>
          <div className="vertical-line" />
          <span className="delete" onClick={onDelete}>
            Delete
          </span>
        </Row>
      </Row>
    </AddTherapyForm>
  );
};

export default RecommendedEvaluation;
