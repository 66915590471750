import { TableType } from 'model/tableType';
import UnderlineTitle from 'views/components/underlineTitle';
import {
  ABAAppointmentSubType,
  IABAAppointmentSubType
} from 'model/abaAppointmentSubtype';
import { IABAAppointmentSubTypeConfig } from 'model/tableConfigurations';

export const mapABAAppoitmentSubTypes = (
  data: TableType[],
  addNew: (data?: TableType) => void
): IABAAppointmentSubTypeConfig[] =>
  (data as IABAAppointmentSubType[]).map(item => {
    return {
      title: (
        <UnderlineTitle
          title={item[ABAAppointmentSubType.title]}
          onClick={() => {
            addNew(item);
          }}
        />
      ),
      id: Number(item[ABAAppointmentSubType.id]),
      crType: item[ABAAppointmentSubType.crType],
      crAuthCode: item[ABAAppointmentSubType.crAuthCode]?.id || '',
      crAuthCodeName: item[ABAAppointmentSubType.crAuthCode]?.value || '',
      superType: item[ABAAppointmentSubType.superType]?.value || '',
      placeOfService: item[ABAAppointmentSubType.placeOfService]?.value || ''
    };
  });
