import gql from 'graphql-tag';

export const recurrencePatternFragments = {
  recurrencePattern: gql`
    fragment RecurrencePatternData on RecurrencePattern {
      id
      recurringType
      recurringEvery
      recurringUntil
    }
  `
};
