import gql from 'graphql-tag';
import { specialtiesFragments } from '../fragments/ProviderSpecialty';

export const GET_SPECIALTIES = gql`
  query {
    specialties {
      ...Specialty
    }
  }
  ${specialtiesFragments.specialty}
`;

export const GET_DEPARTMENTS = gql`
  query {
    specialtiesDepartment
  }
`;
