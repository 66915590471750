import styled, { css } from 'styled-components';
import { Radio } from 'antd';
import { PURPLE } from 'utils/constants/theme';

const sharedStyle = css`
  .ant-radio-inner {
    border-color: ${PURPLE};
    &:after {
      background-color: ${PURPLE};
    }
  }
  .ant-radio-checked:after {
    border-color: ${PURPLE};
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${PURPLE};
  }
  .ant-radio-wrapper {
    span {
      padding-right: 0;
    }
  }
`;

export const LibRadio = styled(Radio)`
  ${sharedStyle}
`;

export const LibRadioGroup = styled(Radio.Group)`
  ${sharedStyle};
  &.column {
    display: flex;
    flex-direction: column;
    label {
      min-height: 37px;
      display: flex;
      align-items: center;
    }
  }
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #415766;
  }
`;
