import {
  Days,
  IHoursEditFormList,
  IClinicBusinessHours,
  IClinicForm,
  IRoom
} from 'model/v2';
import { DAY_INDEX, DAY_TO_INDEX, WEEK } from 'utils/constants/default';
import { formatTime } from '.';

export const defaultHours = () => {
  return {
    [Days.mon]: { open: '12:00', close: '12:00', checked: true },
    [Days.tues]: { open: '12:00', close: '12:00', checked: true },
    [Days.weds]: { open: '12:00', close: '12:00', checked: true },
    [Days.thurs]: { open: '12:00', close: '12:00', checked: true },
    [Days.fri]: { open: '12:00', close: '12:00', checked: true },
    [Days.sat]: { open: '12:00', close: '12:00', checked: true },
    [Days.sun]: { open: '12:00', close: '12:00', checked: true }
  };
};

export const formatBusinessHours = (
  bHours: IClinicBusinessHours[],
  clinicId?: number
) =>
  WEEK.map(day => {
    const bHour = bHours.find(x => DAY_INDEX.get(`${x.dayOfWeek}`) === day);
    return {
      day,
      id: bHour?.id,
      startTime: bHour?.startTime ? formatTime(bHour?.startTime) : null,
      endTime: bHour?.endTime ? formatTime(bHour?.endTime) : null,
      clinic: { id: clinicId }
    };
  });

export const formatToHoursForm = (
  hours?: IClinicBusinessHours[],
  clinicId?: number
) => {
  let hoursList = defaultHours();
  hours?.forEach(hour => {
    const open = hour.startTime;
    const close = hour.endTime;
    const day = DAY_INDEX.get(`${hour.dayOfWeek}`)?.toLowerCase() || '';
    hoursList = {
      ...hoursList,
      [day]: {
        id: hour.id,
        close,
        open,
        checked: false,
        clinic: { id: clinicId }
      }
    };
  });
  return hoursList;
};

export const formatHoursFormToClinicHours = (
  businessHours?: IHoursEditFormList,
  clinicId?: number
) => {
  let hours: IClinicBusinessHours[] = [];
  for (const value in Days) {
    const day = value as Days;
    if (businessHours && !businessHours[day].checked) {
      hours.push({
        id: businessHours[day].id ? Number(businessHours[day].id) : undefined,
        dayOfWeek: DAY_TO_INDEX.get(day),
        startTime: businessHours[day].open,
        endTime: businessHours[day].close,
        clinic: clinicId ? { id: clinicId } : undefined
      });
    }
  }

  return hours;
};

export const formatClinicFormToInput = (
  clinic: IClinicForm,
  rooms?: IRoom[]
) => {
  const result = {
    ...clinic,
    id: Number(clinic.id),
    clinicHub: clinic.clinicHub?.id
      ? { id: Number(clinic.clinicHub?.id) }
      : undefined,
    clinicBusinessHours: formatHoursFormToClinicHours(
      clinic.businessHours,
      Number(clinic.id)
    ),
    address: {
      ...clinic.address,
      id: Number(clinic.address?.id)
    },
    rooms: rooms?.map(room => ({
      ...room,
      clinic: clinic.id ? { id: Number(clinic.id) } : undefined
    }))
  };
  delete result.businessHours;
  return result;
};
