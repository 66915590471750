import gql from 'graphql-tag';
import { specialtiesFragments } from '../fragments/ProviderSpecialty';

export const PUT_SPECIALTY = gql`
  mutation($specialty: SpecialtyInput!) {
    putSpecialty(specialty: $specialty) {
      ...Specialty
    }
  }
  ${specialtiesFragments.specialty}
`;
