import ModalSemantic from 'components/modalSemantic';
import { ISavedFilterOptions } from 'model/calendar/filters';
import FilterForm from './FilterForm';
import { Dispatch, SetStateAction } from 'react';

interface IProps {
  filterName?: string;
  pinnedFilter?: ISavedFilterOptions;
  onClose: () => void;
  pageName: string;
  additionalFilter?: Record<string, any>;
  setCheckedSavedFilter?: Dispatch<SetStateAction<string>>;
  refetch?: () => void;
}

const FilterTagForm: React.FC<IProps> = ({
  filterName,
  pinnedFilter,
  onClose,
  pageName,
  additionalFilter,
  setCheckedSavedFilter,
  refetch
}: IProps) => (
  <ModalSemantic
    open={true}
    onClose={onClose}
    trigger={<div></div>}
    content={
      <FilterForm
        filterName={filterName}
        pinnedFilter={pinnedFilter}
        onClose={onClose}
        pageName={pageName}
        additionalFilter={additionalFilter}
        setCheckedSavedFilter={setCheckedSavedFilter}
        refetch={refetch}
      />
    }
  />
);

export default FilterTagForm;
