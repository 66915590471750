import React, { useState, useCallback } from 'react';
import { ModalContent } from './ModalContent';
import { ModalEdit } from './ModalEdit';
import { ModalReasons } from './ModalReasons';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'react-apollo';
import { CANCEL_EVENT } from 'api/graphql/v2/mutations/Event';
import { GET_EVENT } from 'api/graphql/v2/queries/Events';
import { modalForList } from 'utils/constants/breadcrumbs';
import { GET_CLIENT_EVENTS } from 'api/graphql/v2/queries/Clients';
import { ModalEditTime } from './ModalEditTime';
import { EventModal } from './MainCalendar.style';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from 'views/components/ui/content';
import { getRefetchQueries } from 'views/components/forms/appointment/newAppointmentHelpers';
import { ALL_CANCELLATION_REASONS } from 'api/graphql/v2/queries/CancellationReasons';

export const MODAL_ACTIONS = Object.freeze({
  RESCHEDULE: 'reschedule',
  CANCEL: 'cancel',
  EDIT_TIME: 'editTime',
  EDIT_BEHAVIOR: 'editBehavior'
});

export const MODAL_AUTOMATA = Object.freeze({
  MODAL_CONTENT: 0,
  MODAL_EDIT: 1,
  MODAL_REASONS: 2,
  MODAL_EDIT_TIME: 3,
  MODAL_EDIT_BEHAVIOR: 4
});

const getCancelEventRefetchQueries = (modalContentFor, range, client) => {
  const calendarQueries = getRefetchQueries(range);
  switch (modalContentFor) {
    case modalForList.clientCalendar:
      return [
        {
          query: GET_CLIENT_EVENTS,
          variables: {
            clientId: client?.id,
            startDate: range.start,
            endDate: range.end
          },
          skip: !client || !client?.id
        }
      ];

    default:
      return calendarQueries;
  }
};

export const ModalWrapper = ({
  eventId,
  range,
  modalContentFor,
  setClickedEvent
}) => {
  const history = useHistory();
  const [step, setStep] = useState(MODAL_AUTOMATA.MODAL_CONTENT);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  const [editOption, setEditOption] = useState({
    cancelOptions: 'single',
    cancelReason: null
  });

  //TODO: load auth therapy as well
  const { data, loading: loadingData, error } = useQuery(GET_EVENT, {
    variables: {
      id: parseInt(eventId)
    },
    //TODO: handle when event is not in Dynamo throw error
    fetchPolicy: 'no-cache',
    skip: !eventId
  });

  const {
    loading: cancellationReasonsLoading,
    data: cancellationReasons
  } = useQuery(ALL_CANCELLATION_REASONS, {
    fetchPolicy: 'cache-and-network'
  });

  // define cancel event
  const [cancelEvent] = useMutation(CANCEL_EVENT, {
    refetchQueries: getCancelEventRefetchQueries(
      modalContentFor,
      range,
      data?.event?.client
    )
  });

  // function to force close modal
  const closeModal = useCallback(() => {
    setClickedEvent(undefined);
  }, [setClickedEvent]);

  // where step should be handled
  const editModalHandler = useCallback((step, recurringAppt = false) => {
    if (step === MODAL_ACTIONS.EDIT_BEHAVIOR && recurringAppt) {
      setStep(MODAL_AUTOMATA.MODAL_EDIT_BEHAVIOR);
    } else if (step === MODAL_ACTIONS.EDIT_TIME) {
      setStep(MODAL_AUTOMATA.MODAL_EDIT_TIME);
    } else if (recurringAppt) {
      setStep(MODAL_AUTOMATA.MODAL_EDIT);
    } else {
      setStep(MODAL_AUTOMATA.MODAL_REASONS);
    }
    setType(step);
  }, []);

  const setStateValueHandler = useCallback((name, value) => {
    setEditOption(prevState => ({ ...prevState, [name]: value }));
  }, []);

  // in case of reschedule
  const rescheduleEvent = useCallback(
    cancelNote => {
      history.push({
        pathname: `/appointments/${data.event.id}/reschedule`,
        state: {
          event: data.event,
          thisAndFollowingEvents: editOption.cancelOptions === 'all',
          reason: editOption.cancelReason && editOption.cancelReason.label,
          cancelReasonId: editOption.cancelReason.value,
          cancelNote,
          modalContentFor
        }
      });
    },
    [editOption, history, data, modalContentFor]
  );

  // In case of edit event behavior
  const editEventBehavior = useCallback(() => {
    history.push({
      pathname: `/appointments/${data.event.id}/editBehavior`,
      state: {
        event: data.event,
        thisAndFollowingEvents: editOption.cancelOptions === 'all',
        //Leaving all these fields for compatibility
        reason: editOption.cancelReason && editOption.cancelReason.label,
        cancelReasonId: undefined,
        cancelNote: undefined,
        modalContentFor
      }
    });
  }, [editOption, history, data, modalContentFor]);

  // in case of cancelling cancel
  const cancel = useCallback(
    cancelNote => {
      setLoading(true);
      cancelEvent({
        variables: {
          data: {
            event: {
              id: data.event.id
            },
            recurrent: editOption.cancelOptions === 'all',
            cancelReasonId: editOption.cancelReason.value,
            cancelNote
          }
        }
      }).then(() => {
        setLoading(false);
        closeModal();
      });
      setStep(MODAL_AUTOMATA.MODAL_CONTENT);
    },
    [data, editOption, closeModal, cancelEvent]
  );

  return (
    <EventModal
      className={
        step === MODAL_AUTOMATA.MODAL_CONTENT ? 'event-modal' : 'action-modal'
      }
      destroyOnClose={true}
      title=""
      visible
      onCancel={closeModal}
      footer=""
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      width={data?.event?.event?.authorizedTherapyID ? 584 : 520}
      isAuthAvailable={!!data?.event?.event?.authorizedTherapyID}
      isModalContent={step === MODAL_AUTOMATA.MODAL_CONTENT}
    >
      <Content
        loading={loadingData || cancellationReasonsLoading}
        error={
          error || data?.event === null ? "Event Couldn't be found" : false
        }
        data={data?.event}
      >
        {event => (
          <>
            {step === MODAL_AUTOMATA.MODAL_CONTENT && event && (
              <ModalContent
                data={{
                  isConverted: data?.isConverted,
                  isLastEventInSeries: data?.isLastEventInSeries,
                  ...event
                }}
                loading={loading}
                editModalHandler={editModalHandler}
                modalContentFor={modalContentFor}
                cancellationReasons={cancellationReasons.allCancellationReasons}
              />
            )}
            {(step === MODAL_AUTOMATA.MODAL_EDIT ||
              step === MODAL_AUTOMATA.MODAL_EDIT_BEHAVIOR) && (
              <ModalEdit
                editEventBehavior={editEventBehavior}
                step={step}
                setStateValueHandler={setStateValueHandler}
                editOption={editOption}
                visible
                setStep={setStep}
                eventID={event?.id}
                masterID={event?.master?.id}
              />
            )}
            {step === MODAL_AUTOMATA.MODAL_REASONS && (
              <ModalReasons
                cancelEvent={cancel}
                type={type}
                rescheduleEvent={rescheduleEvent}
                setStateValueHandler={setStateValueHandler}
                setEditOption={setEditOption}
                editOption={editOption}
                setStep={setStep}
              />
            )}
            {step === MODAL_AUTOMATA.MODAL_EDIT_TIME && (
              <ModalEditTime
                setStep={setStep}
                event={event}
                closeModal={closeModal}
              />
            )}
          </>
        )}
      </Content>
    </EventModal>
  );
};
