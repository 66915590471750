import { IEvent } from 'model/v2';
import React from 'react';
import { TitleViewWrapper } from './style';
interface Props {
  event: IEvent;
}
const StatusView: React.FC<Props> = ({ event }: Props) => {
  const { appointmentType } = event;
  const appointmentTypeName = appointmentType?.parent
    ? appointmentType.parent.title
    : appointmentType?.title;

  return (
    <TitleViewWrapper>
      <h2>Observation: {appointmentTypeName}</h2>
    </TitleViewWrapper>
  );
};
export default React.memo(StatusView);
