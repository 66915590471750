import styled from 'styled-components';
import { PURPLE } from 'utils/constants/theme';
import { Input } from 'antd';
import Cleave from 'cleave.js/react';

export const LibInput = styled(Input)`
  width: 100%;
  box-sizing: border-box;
  border-color: ${PURPLE};
  &:hover {
    border-color: ${PURPLE};
  }
  &:focus {
    border-color: ${PURPLE};
    box-shadow: 0 0 0 2px rgba(111, 75, 241, 0.22);
  }
  input {
    width: 100%;
    box-sizing: border-box;
    border-color: ${PURPLE};
    &:hover {
      border-color: ${PURPLE};
    }
    &:focus {
      border-color: ${PURPLE};
      box-shadow: 0 0 0 2px rgba(111, 75, 241, 0.22);
    }
  }
`;

export const LibDecimalInput = styled(Input)`
  width: 80px;
  border-radius: 9px;
`;

export const LibAutoFormattedInput = styled(Cleave)`
  width: 100%;
  padding: 7px 12px;
  line-height: 16px;
  box-sizing: border-box;
  border: 1px solid ${PURPLE};
  border-radius: 4px;
  &:hover {
    border-color: ${PURPLE};
    transition: 0.3s ease box-shadow;
  }
  &:focus {
    outline: none;
    border-color: ${PURPLE};
    box-shadow: 0 0 0 2px rgba(111, 75, 241, 0.22);
  }
`;

export const LibTextArea = styled(Input.TextArea)`
  &.ant-input {
    min-height: 144px;
  }
  padding: 8px 12px;
  box-sizing: border-box;
  border-color: ${PURPLE};
  &:hover {
    border-color: ${PURPLE};
  }
  &:focus {
    border-color: ${PURPLE};
    box-shadow: 0 0 0 2px rgba(111, 75, 241, 0.22);
  }
`;
