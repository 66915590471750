import { IClient, IEvent, IProvider } from 'model/v2';
import React from 'react';
import { PURPLE } from 'utils/constants/theme';
import { formatAttendeeName } from 'utils/format';
import AttendeeRow from './AttendeeRow';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { teal } from '@material-ui/core/colors';
import AttendeesHeader from './AttendeesHeader';
import ObservationRow from 'pages/MainCalendarPage/AppointmentObserver/ObservationRow';

interface Props {
  provider?: IProvider;
  client?: IClient;
  observations?: IEvent[];
  providersMap: Map<number, IProvider>;
  event: IEvent;
  setIsAddObservationMode: (val: boolean) => void;
  isLoading?: boolean;
}

const AttendeesSection: React.FC<Props> = ({
  provider,
  client,
  observations,
  event,
  isLoading
}) => {
  const providerName = formatAttendeeName(
    event.provider?.firstName,
    event.provider?.lastName,
    event.provider?.clinic?.abbreviation,
    event.provider?.speciality?.abbreviation
  );

  const clientName = formatAttendeeName(
    event.client?.firstName,
    event.client?.lastName,
    event.client?.clinic?.abbreviation
  );
  return (
    <>
      {event.type !== 'adminEvent' && (
        <AttendeesHeader
          observations={event.observations!}
          isLoading={isLoading}
        />
      )}

      {provider && (
        <AttendeeRow
          eventType={event.type!}
          displayName={providerName}
          displayId={provider?.id}
          icon={<EyeOutlined />}
          iconTitle={event.isObservation ? 'Observer' : 'Lead Provider'}
          color={PURPLE}
          isLoading={isLoading}
        />
      )}
      <ObservationRow observations={observations} />
      {client && (
        <AttendeeRow
          eventType={event.type!}
          displayName={clientName}
          displayId={client?.id}
          icon={<UserOutlined />}
          iconTitle="Patient"
          color={teal[100]}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default AttendeesSection;
