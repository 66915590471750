import React from 'react';
import moment from 'moment';

export const AUTH_THERAPIES_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'clientName',
    sorter: (a, b) =>
      a.clientName.props.title.localeCompare(b.clientName.props.title),
    sortDirections: ['descend', 'ascend'],
    render: text => text,
    width: '8%'
  },
  {
    title: 'Clinic',
    dataIndex: 'clinicAbr',
    sorter: (a, b) => a.clinicAbr.localeCompare(b.clinicAbr),
    sortDirections: ['descend', 'ascend'],
    width: '4%'
  },
  {
    title: 'Auth ID',
    dataIndex: 'authId',
    sorter: (a, b) => a.authId.localeCompare(b.authId),
    sortDirections: ['descend', 'ascend'],
    width: '6%'
  },
  {
    title: 'Start',
    dataIndex: 'startDate',
    sorter: (a, b) => a.startDate.localeCompare(b.startDate),
    sortDirections: ['descend', 'ascend'],
    render: text => moment(text).format('MM/DD/YYYY'),
    width: '6%'
  },
  {
    title: 'End',
    dataIndex: 'endDate',
    sorter: (a, b) => a.endDate.localeCompare(b.endDate),
    sortDirections: ['descend', 'ascend'],
    render: text => moment(text).format('MM/DD/YYYY'),
    width: '6%'
  },
  {
    title: 'Due',
    dataIndex: 'dueDate',
    sorter: (a, b) => a.dueDate.localeCompare(b.dueDate),
    sortDirections: ['descend', 'ascend'],
    render: text => moment(text).format('MM/DD/YYYY'),
    width: '6%'
  },
  {
    title: 'Appt Type',
    dataIndex: 'apptType',
    render: text => text,
    width: '8%'
  },
  {
    title: 'Completed',
    dataIndex: 'completed',
    sorter: (a, b) => a.completed.localeCompare(b.completed),
    sortDirections: ['descend', 'ascend'],
    width: '7%'
  },
  {
    title: 'Scheduled',
    dataIndex: 'scheduled',
    sorter: (a, b) => a.scheduled.localeCompare(b.scheduled),
    sortDirections: ['descend', 'ascend'],
    width: '7%'
  },
  {
    title: 'Auths Remaining',
    dataIndex: 'authsRemaining',
    sorter: (a, b) =>
      a.authsRemaining.count.localeCompare(b.authsRemaining.count),
    sortDirections: ['descend', 'ascend'],
    render: Obj => (
      <span className={Obj.lessThanTwentyPercent ? 'red' : ''}>
        {Obj.count}
      </span>
    ),
    width: '8%'
  },
  {
    title: 'Time Remaining',
    dataIndex: 'timeRemaining',
    sorter: (a, b) =>
      a.timeRemaining.string.localeCompare(b.timeRemaining.string),
    sortDirections: ['descend', 'ascend'],
    render: Obj => (
      <span className={Obj.negative ? 'red' : 'bold'}>
        {Obj.negative ? 'Expired' : Obj.string}
      </span>
    ),
    width: '8%'
  },
  {
    title: 'To Fulfill',
    dataIndex: 'toFulfill',
    sorter: (a, b) => a.toFulfill.localeCompare(b.toFulfill),
    sortDirections: ['descend', 'ascend'],
    render: text => <span className="bold">{text}</span>,
    width: '8%'
  }
];

export const COMPLETED_COLUNMS = [
  {
    title: 'Appt Type',
    dataIndex: 'apptType',
    render: text => <span>{text}</span>
  },
  {
    title: 'Comp.',
    dataIndex: 'completed',
    render: text => <span>{text}</span>
  },
  {
    title: 'Sched.',
    dataIndex: 'scheduled',
    render: text => <span>{text}</span>
  }
];
