export const EVENT_TYPES = {
  ABA: 'ABA',
  DT: 'DT',
  Admin: 'Admin',
  MED: 'MED'
};
export const STYLE_CLASS = {
  SELECTED_STYLE: 'appt-category-type-selected',
  UNSELECTED_STYLE: 'appt-category-type',
  INDEXED_SELECTED_STYLE: 'is-selected',
  INDEXED_UNSELECTED_STYLE: 'offsite',
  INDEXED_SELECTED_ERROR_STYLE: 'offsite-error'
};
export const ADMIN_APPT_ID_FOR_DURATION: Record<string, number[]> = {
  DEV: [3, 4, 276, 279, 280],
  STAGING: [152, 153, 381, 384, 385],
  UAT: [159, 160, 367, 368, 364],
  PROD: [3, 4, 458, 459, 455]
};
interface SubstituteApptObject {
  [key: string]: {
    [key: number]: number;
  };
}
export const SUBSTITUTE_APPT_IDS: SubstituteApptObject = {
  DEV: { 117: 121, 162: 192, 10: 240, 28: 238, 9: 239, 21: 241 },
  STAGING: { 117: 121, 192: 271, 10: 315, 28: 313, 9: 314, 21: 316 },
  UAT: { 152: 156, 177: 253, 40: 292, 12: 289, 38: 291, 10: 293 },
  PROD: { 162: 166, 195: 227, 43: 320, 13: 317, 41: 318, 11: 319 }
};

export const DT_SUBSTITUTE_SUB_APPT_IDS: SubstituteApptObject = {
  DEV: { 35: 244, 63: 242, 50: 243, 83: 245 },
  STAGING: { 35: 319, 63: 317, 50: 318, 309: 320 },
  UAT: { 46: 295, 132: 290, 93: 294, 54: 301 },
  PROD: { 48: 324, 139: 322, 98: 323, 57: 321 }
};

export const NURSE_PRACTITIONER_SPECIALTY_ID_MAPPING: Record<string, number> = {
  DEV: 12,
  STAGING: 12,
  UAT: 12,
  PROD: 12
};

export const ABA_EXTENSION_SUBSTITUTE_APPT_IDS: Record<string, number> = {
  DEV: 121,
  STAGING: 121,
  UAT: 156,
  PROD: 166
};

export const MAKEUP_SESSION_APPT_IDS: SubstituteApptObject = {
  DEV: { 117: 121, 162: 192, 10: 216, 28: 214, 9: 215, 21: 217 },
  STAGING: { 117: 121, 192: 271, 10: 294, 28: 290, 9: 292, 21: 293 },
  UAT: { 152: 156, 177: 253, 40: 271, 12: 269, 38: 270, 10: 272 },
  PROD: { 162: 166, 195: 227, 43: 301, 13: 299, 41: 300, 11: 302 }
};

export const MAKEUP_SESSION_SUB_APPT_IDS: SubstituteApptObject = {
  DEV: { 35: 218, 63: 220, 50: 219, 83: 221 },
  STAGING: { 35: 296, 63: 291, 50: 295, 32: 297 },
  UAT: { 46: 273, 132: 275, 93: 274, 138: 276 },
  PROD: { 48: 303, 139: 305, 98: 304, 145: 306 }
};

export const DRIVE_TIME_APPT_IDS: Record<string, number[]> = {
  DEV: [3, 276],
  STAGING: [152, 381],
  UAT: [159, 364],
  PROD: [3, 455]
};

export const DRIVE_TIME_APPT_ID: Record<string, number> = {
  DEV: 3,
  STAGING: 152,
  UAT: 159,
  PROD: 3
};

export const TELEHEALTH_SS_APPT_IDS: Record<string, number[]> = {
  DEV: [25],
  STAGING: [25],
  UAT: [31],
  PROD: [33]
};

export const ADMIN_DIRECT_APPT_IDS: Record<string, number> = {
  DEV: 1,
  STAGING: 150,
  UAT: 157,
  PROD: 1
};

export const ADMIN_IN_DIRECT_APPT_IDS: Record<string, number> = {
  DEV: 2,
  STAGING: 151,
  UAT: 158,
  PROD: 2
};
