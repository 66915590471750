import React from 'react';
import Row from 'views/components/ui/list';
import { formatAge, formatInitial } from 'utils/format';
import { getClientData } from 'utils/mappers/response/clients';

const Description = ({ client }) => {
  client = getClientData(client);
  return (
    <ul className="profile-info">
      <li className="name" key="name">
        {client.name}
      </li>
      <li className="date" key="date">
        <span>{formatAge(client.age)}</span>
        <span>{formatInitial(client?.profile?.gender)}</span>
        <span>{client?.profile?.dob}</span>
      </li>
      <li className="clinic" key="clinic">
        Clinic: <span>{client?.clinic?.name}</span>
      </li>
      <li className="client-type" key="client-type">
        Client Type: <span>{client.clientType}</span>
      </li>
      <li className="client-email" key="client-email">
        {client?.profile?.personalEmail}
      </li>
      <li key="more">
        <Row className="info" data={healthCondition(client?.profile)} />
      </li>
    </ul>
  );
};

const healthCondition = ({
  phenoType,
  behavior,
  cognition,
  communication,
  sensorimotor
}) => [
  ['Phenotype ', <span key="1">{phenoType}</span>],
  ['Behavior ', <span key="2">{behavior}</span>],
  ['Cognition ', <span key="3">{cognition}</span>],
  ['Communication ', <span key="4">{communication}</span>],
  ['Sensorimotor ', <span key="5">{sensorimotor}</span>]
];
export default Description;
