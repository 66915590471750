import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ALTERNATE_AVAILABILITY_TEMPLATE_VALIDATION } from 'utils/validators/availabilityTemplate/altAvailability';
import { Select, Input } from 'api/sharedComponents/reactHookFormComponents';
import { FormItem, TextArea } from 'views/containers/form';
import AltTempDatePicker from './AltTempDatePicker';
import { Button } from 'lib/ui';
import { AddAlternateTemplateModalWrapper } from './style';
import {
  ProviderTemplate,
  AltTemplateSubmittedFormData
} from 'model/v2/providerTemplate';
import { GET_DAY_AVAILABILITIES_BY_PROVIDER_TEMPALTE_ID } from 'api/graphql/v2/queries/dayAvailability';
import { useLazyQuery } from 'react-apollo';
import { mapDayAvailabilityToCalendarEvent } from 'utils/mappers/availabilityTemplate';
import { CalenderEvent } from 'model/v2/availabilityTemplate';
import { Appointment_Types } from 'components/AvailabilityTemplateModalForm/EditForm/dropdownOptions';
import moment from 'moment';

interface IProps {
  submittedData: any;
  onClose(): void;
  onSubmit(data: AltTemplateSubmittedFormData, calendarEvents: any): void;
  disableBtn: boolean;
  disableTemplateSelect: boolean;
  dropdownOptions: ProviderTemplate[];
  submitCallbackInFlight: boolean;
  selectedDate: Date;
  providerDefaultClinicsIds: number[];
}

const AddAlternateAvailabilityModalForm: React.FC<IProps> = ({
  submittedData,
  onClose,
  onSubmit,
  disableBtn,
  disableTemplateSelect,
  dropdownOptions,
  submitCallbackInFlight = false,
  selectedDate,
  providerDefaultClinicsIds
}: IProps) => {
  const methods = useForm({
    resolver: yupResolver(ALTERNATE_AVAILABILITY_TEMPLATE_VALIDATION),
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });
  const {
    id,
    activeFrom: startDate,
    activeTo: endDate,
    frequency,
    name: title,
    description: templateDescription
  } = submittedData;
  const handleChange = (e: any) => {
    methods.setValue('description', e.target.value);
  };
  const description = useWatch({
    name: 'description',
    control: methods.control,
    defaultValue: templateDescription || ''
  });
  const prepareTemplatesList = useCallback(() => {
    const templateOptions = dropdownOptions?.map(
      (providerTemplate: ProviderTemplate) => ({
        value: providerTemplate.id,
        label: providerTemplate.name
      })
    );
    templateOptions.unshift({ value: -1, label: 'Blank' });
    return templateOptions;
  }, [dropdownOptions]);

  const [
    startingTemplateDayAvailabilities,
    setStartingTemplateDayAvailabilities
  ] = useState<CalenderEvent[]>();

  const localFormSubmission = (data: AltTemplateSubmittedFormData) => {
    onSubmit(data, startingTemplateDayAvailabilities);
  };

  const [getStartingDayAvailabilities, { loading }] = useLazyQuery(
    GET_DAY_AVAILABILITIES_BY_PROVIDER_TEMPALTE_ID,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        let events = mapDayAvailabilityToCalendarEvent(
          data.getDayAvailabilitiesByProviderTemplateId,
          providerDefaultClinicsIds,
          Appointment_Types,
          selectedDate
        );
        setStartingTemplateDayAvailabilities(events);
      }
    }
  );

  const handleTemplateSelect = (providerTemplateId: number) => {
    if (providerTemplateId < 0) {
      setStartingTemplateDayAvailabilities([]);
    } else {
      getStartingDayAvailabilities({
        variables: { providerTemplateId }
      });
    }
  };

  //UseEffect for getStartingDayAVails for providerTemplateIds that aren't manually selected
  //IE: when we automatically populate the starting template when clicking on the edit button on the Alt Table.
  useEffect(() => {
    if (id) {
      getStartingDayAvailabilities({
        variables: { providerTemplateId: id }
      });
    }
  }, [id, getStartingDayAvailabilities]);

  return (
    <AddAlternateTemplateModalWrapper className="addAlternateModalContainer">
      <FormProvider {...methods}>
        {/* Template & Date Selection */}
        <div className="altModalFormUpperContainer">
          <div className="altModalFormUpperContent">
            <FormItem
              optional={false}
              label="Start Template *"
              className="startTemplateInput"
            >
              <Controller
                name={'startTemplate'}
                control={methods.control}
                error={methods.errors}
                defaultValue={title || undefined}
                placeholder={'Select Template'}
                render={fieldProps => (
                  <Select
                    options={prepareTemplatesList()}
                    field={fieldProps}
                    errors={methods.errors}
                    onSelect={handleTemplateSelect}
                    defaultValue={'Select Template'}
                    dropdownStyle={{ border: '1px solid #6f42f5' }}
                    disabled={disableTemplateSelect}
                    className={'startTemptInputField'}
                  />
                )}
              />
            </FormItem>
          </div>
          <AltTempDatePicker
            startDate={
              startDate ? moment.utc(startDate).format('MM/DD/YYYY') : undefined
            }
            endDate={
              endDate ? moment.utc(endDate).format('MM/DD/YYYY') : undefined
            }
          />
        </div>

        {/* Frequency and Title */}
        <div className="altModalFormMidContainer">
          <FormItem
            optional={false}
            label="Frequency *"
            className="altModalFormMidContent"
          >
            <Controller
              name={'frequency'}
              control={methods.control}
              error={methods.errors}
              placeholder={'Select Frequency'}
              defaultValue={frequency || undefined}
              render={fieldProps => (
                <Select
                  options={[
                    { value: 'Weekly', label: 'Weekly' },
                    { value: 'Bi-Weekly', label: 'Bi-weekly' }
                  ]}
                  defaultValue={'Select Frequency'}
                  field={fieldProps}
                  errors={methods.errors}
                  style={{
                    border: '1px solid #6f42f5',
                    borderRadius: '3px'
                  }}
                />
              )}
            />
          </FormItem>
          <FormItem
            optional={false}
            label="Title *"
            className="altModalFormMidContent"
          >
            <Controller
              name={'templateTitle'}
              control={methods.control}
              error={methods.errors}
              defaultValue={title || ''}
              render={fieldProps => (
                <Input
                  name={'templateTitle'}
                  field={fieldProps}
                  errors={methods.errors}
                />
              )}
            />
          </FormItem>
        </div>

        {/* Description and Buttons */}
        <div className="altModalFormBottomContainer">
          <FormItem
            optional={true}
            label="Description"
            className="description-style"
          >
            <Controller
              name={'description'}
              control={methods.control}
              error={methods.errors}
              defaultValue={templateDescription || ''}
              render={() => (
                <TextArea
                  name="description"
                  onChange={handleChange}
                  value={description}
                  errors={methods.errors}
                  className={'description-text-area-style'}
                />
              )}
            />
          </FormItem>
          <div className="alt-modal-form-buttons-container">
            <Button className="cancel-btn" onClick={onClose} type="">
              Cancel
            </Button>
            <Button
              className="confirm-btn"
              onClick={methods.handleSubmit(localFormSubmission)}
              disabled={disableBtn || loading}
              type="submit"
            >
              {submitCallbackInFlight ? 'Processing...' : 'Confirm'}
            </Button>
          </div>
        </div>
      </FormProvider>
    </AddAlternateTemplateModalWrapper>
  );
};

export default AddAlternateAvailabilityModalForm;
