import React, { useState, useContext } from 'react';
import { CancelModalWrapper } from '../style';
import { Button } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { CANCEL_OBSERVATION } from 'api/graphql/v2/mutations/Event';
import ModalSemantic from 'components/modalSemantic';
import { plannerContext } from 'pages/MainCalendarPage';
import { useReactiveVar } from '@apollo/client';
import { mainCalendarPrefDayVar } from 'utils/cache/calendar';
import moment from 'moment';
import CancelReasonDropdown from './CancelReasonDropdown';
import { FormProvider, useForm } from 'react-hook-form';
import CancelNoteTextArea from 'pages/MainCalendarPage/AppointmentModals/CancelModal/CancelNoteTextArea';
import {
  ADMIN_DEFAULT_CANCEL_REASON,
  ATHENA_DEFAULT_DELETE_REASON
} from 'utils/constants/lists';
import { APPOINTMENT_OBSERVER_CANCEL_SCHEMA } from 'utils/validators/calendar';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  leadEventId: number;
  observationEventId: number;
  setCancel: (val: boolean) => void;
  setVisible: (val: boolean) => void;
  cancelReasons: any;
}
const CancelObservationModal: React.FC<Props> = ({
  leadEventId,
  observationEventId,
  setCancel,
  setVisible,
  cancelReasons
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { handleDateSelect } = useContext(plannerContext);
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const isAdmin = false;
  const deleteObserver = true;
  const methods = useForm({
    defaultValues: {
      cancelNote: ''
    },
    resolver: yupResolver(APPOINTMENT_OBSERVER_CANCEL_SCHEMA(deleteObserver))
  });

  const [cancelObservation] = useMutation(CANCEL_OBSERVATION);

  const submitForm = React.useCallback(
    (data: any) => {
      setIsSaving(true);
      cancelObservation({
        variables: {
          observationEventId,
          leadEventId,
          data: {
            cancelReasonId:
              data.cancelReason !== null
                ? data.cancelReason
                : isAdmin
                ? ADMIN_DEFAULT_CANCEL_REASON
                : ATHENA_DEFAULT_DELETE_REASON,
            cancelNote: data.cancelNote || ''
          }
        }
      })
        .then(() => {
          setVisible(false);
          setIsSaving(false);
          handleDateSelect(moment(calendarDate).toDate());
        })
        .catch(() => {
          setIsSaving(false);
        });
    },
    [
      cancelObservation,
      observationEventId,
      leadEventId,
      isAdmin,
      setVisible,
      handleDateSelect,
      calendarDate
    ]
  );
  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);

  return (
    <ModalSemantic
      open={true}
      onClose={() => setCancel(false)}
      modalWidth={430}
      trigger={<div></div>}
      content={
        <CancelModalWrapper>
          {' '}
          <h2 className="cancel-modal-title">Confirm Cancellation</h2>
          <p className="cancel-modal-text">
            {' '}
            Are you sure you want to cancel the observation event?
          </p>
          <FormProvider {...methods}>
            <CancelReasonDropdown
              cancelReasons={cancelReasons}
              isDeleteOrAdminEvent={false}
            />
            <CancelNoteTextArea isDeleteOrAdminEvent={false} />
          </FormProvider>
          <div className="modal-actions">
            <Button onClick={() => setCancel(false)} basic disabled={isSaving}>
              No, GO Back
            </Button>
            <Button
              negative
              onClick={methods.handleSubmit(submitForm, submitError)}
              type="submit"
              loading={isSaving}
              disabled={isSaving}
            >
              Yes, Cancel
            </Button>
          </div>
        </CancelModalWrapper>
      }
    />
  );
};

export default CancelObservationModal;
