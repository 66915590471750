import { useReactiveVar } from '@apollo/client';
import useEventsFetcherHook from 'hooks/eventsFetcherHook';
import { ICalendarResource } from 'model/calendar/filters';
import {
  IClient,
  IFullAppointmentType,
  ISmartSingleResult,
  // IProvider,
  SingleSmartResultInterface
} from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef
} from 'react';

import {
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar,
  removePhantomEvents,
  updateCalendarPref
} from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  calendarFiltersPrefVar
} from 'utils/cache/filters';

import { EVENTS_FETCHING_ACTIONS, plannerContext } from '..';
import {
  useAddPhantomEvents,
  useAddProviderToCalendar,
  useApptTypesMap
} from './CustomPhantomHooks';
import moment from 'moment';
import { calendarGroup } from 'model/calendar/groups';
import { formatProviderDisplayName } from 'utils/format';
import { IPhantomEvent } from 'model/calendar/events';
import { mapSingleSmartPhantomEvent } from 'utils/mappers/response/phantomEvents';
import {
  convertFilteredToSearched,
  isAttendeeInFiltered
} from 'helpers/calendarHelper';
import { isEqual } from 'lodash';

interface Props {
  visibleResults: boolean;
  providersMap: Map<number, ICalendarResource>;
  client: IClient;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<SetStateAction<ICalendarResource[]>>;
  apptTypes: IFullAppointmentType[];
  selectedResult: SingleSmartResultInterface;
  appointmentTypeId: number;
  appointmentSubTypeId?: number;
  transitionResults: ISmartSingleResult[];
  selectedIndex: number;
}

const SingleSmartPhantomEvents: React.FC<Props> = ({
  providersMap,
  paginationArray,
  setPaginationArray,
  visibleResults,
  apptTypes,
  client,
  selectedResult,
  appointmentTypeId,
  appointmentSubTypeId
}) => {
  const selectedGroupRef = useRef<ICalendarResource[] | null>();
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const appTypesMap = useApptTypesMap(apptTypes);
  const { setIsLoading, setIsCalendarLoading, isCalendarLoading } = useContext(
    plannerContext
  );

  const smartEventRef = useRef(selectedResult);

  if (!isEqual(smartEventRef.current, selectedResult)) {
    smartEventRef.current = selectedResult;
  }

  const { getStaffEvents } = useEventsFetcherHook(
    selectedGroupRef && selectedGroupRef.current
      ? selectedGroupRef.current
      : undefined,
    setIsLoading,
    setIsCalendarLoading,
    isCalendarLoading,
    EVENTS_FETCHING_ACTIONS.APPLY_SEARCH,
    setPaginationArray,
    paginationArray,
    [],
    selectedGroupRef.current as ICalendarResource[]
  );

  const addPhantomEvents = useAddPhantomEvents();

  const { addProviderToCalendar, searchedProviders } = useAddProviderToCalendar(
    getStaffEvents,
    filters,
    calendarView,
    calendarDate
  );

  // addProviderToCalendar
  useEffect(() => {
    if (smartEventRef.current.provider.id !== undefined) {
      const newProviders: ICalendarResource[] = [];
      let selectedProvider: ICalendarResource = providersMap?.get(
        smartEventRef.current.provider.id
      )!;
      selectedProvider = {
        ...selectedProvider,
        displayName: formatProviderDisplayName(selectedProvider),
        attendeeType: calendarGroup.provider
      };
      if (
        isAttendeeInFiltered(smartEventRef.current.provider.id) &&
        selectedProvider
      ) {
        const shouldFetchEvents = convertFilteredToSearched(selectedProvider);
        if (!shouldFetchEvents) {
          return;
        }
      } else if (
        smartEventRef.current.provider.id !== undefined &&
        searchedProviders?.has(smartEventRef.current.provider.id)
      ) {
        return;
      }
      if (selectedProvider) {
        newProviders.push(selectedProvider);
      }
      if (newProviders) {
        selectedGroupRef.current = newProviders;
        addProviderToCalendar(newProviders);
      }
    }
  }, [
    addProviderToCalendar,
    providersMap,
    searchedProviders,
    smartEventRef.current.provider.id
  ]);

  // navigate planner date cursor
  useEffect(() => {
    updateCalendarPref(moment(smartEventRef.current.startTime));
  }, [smartEventRef.current.startTime]);

  // addPhantomEventToCalendar
  useEffect(() => {
    //if (!isProviderAdded) return;
    setTimeout(() => {
      const currentEvents = calendarEventsPrefVar();
      const phantomEvents: IPhantomEvent[][] = [];

      const mappedPhantomEvents = mapSingleSmartPhantomEvent(
        smartEventRef.current,
        calendarView,
        calendarDate,
        client,
        appTypesMap,
        providersMap,
        appointmentTypeId,
        appointmentSubTypeId
      );

      phantomEvents.push(mappedPhantomEvents);

      const allPhantomEvents = phantomEvents.flat(1);

      if (allPhantomEvents.length > 0 && visibleResults) {
        addPhantomEvents(currentEvents, allPhantomEvents);
      } else {
        removePhantomEvents(currentEvents);
      }
    }, 500);
  }, [
    calendarDate,
    calendarView,
    visibleResults,
    appTypesMap,
    providersMap,
    client,
    appointmentTypeId,
    appointmentSubTypeId,
    smartEventRef.current.isInClinic,
    smartEventRef.current.isTelehealth,
    smartEventRef.current.startTime
  ]);
  return <></>;
};

export default React.memo(SingleSmartPhantomEvents);
