import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';
import { PURPLE } from 'utils/constants/theme';

const sharedStyle = css`
  .ant-checkbox-checked::after,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-inner {
    border-color: ${PURPLE};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${PURPLE};
  }
`;
export const LibCheckbox = styled(Checkbox)`
  ${sharedStyle}
`;

export const LibCheckboxGroup = styled(Checkbox.Group)`
  ${sharedStyle};
  &.column {
    display: flex;
    flex-direction: column;
    label {
      min-height: 37px;
    }
  }
`;

export const LibOvalCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  align-items: center;
  .ant-checkbox-group-item {
    position: relative;
    margin-right: 4px;
    padding: 0;
    & > span:nth-child(2) {
      position: absolute;
      padding: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      font-weight: bold;
      color: #21303a;
    }
    .ant-checkbox {
      border-color: ${PURPLE};
      border-radius: 50%;
      width: 26px;
      height: 26px;
      .ant-checkbox-inner {
        border-color: ${PURPLE};
        width: 100%;
        height: 100%;
        border-radius: 50%;
        &::after {
          display: none;
        }
      }
      &::after {
        border: none;
      }
    }
    &.ant-checkbox-wrapper-checked {
      & > span:nth-child(2) {
        color: #fff;
      }
      .ant-checkbox {
        background-color: ${PURPLE};
        .ant-checkbox-inner {
          background-color: ${PURPLE};
        }
      }
    }
  }
`;
