import styled from 'styled-components';
import * as colors from 'utils/constants/theme';
import { Modal } from 'antd';

export const NewAppointmentWrap = styled.div`
  .inner-wrap {
    display: flex;
    align-items: stretch;
    padding: 0;
    min-height: calc(100vh - 81px);
    .profile_table {
      .ant-table-expand-icon-th,
      .ant-table-row-expand-icon-cell {
        display: none;
      }
      .ant-table-expanded-row-level-1 {
        td {
          &:first-child {
            display: none;
          }
        }
        .expandable_table-container {
          padding: 0;
          .ant-table-thead {
            span {
              font-size: 14px;
            }
          }
        }
        .ant-table-row-level-0,
        .ant-table-row-level-1 {
          td {
            display: table-cell;
            strong {
              font-size: 12px;
            }
          }
        }
      }
      table {
        td {
          &:nth-child(2) {
            strong {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
  .appointment-sidebar {
    position: relative;
    width: 100%;
    max-width: 500px;
    background-color: #f6f6f6;
  }
  .appointment-sidebar__search {
    max-width: 100%;
    padding: 30px 25px;
    background-color: #e4e7e9;
  }
  .appointment-sidebar__list {
    padding: 16px;
    .create-new {
      display: block;
      font-size: 15px;
      letter-spacing: 0.17px;
      color: ${colors.ACTIVE_URL};
      padding-bottom: 30px;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .appointment-sidebar__list-item {
    background-color: #fff;
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    padding: 16px;
    cursor: pointer;
    .avatar {
      min-width: 48px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #e4e7e9;
    }
    .info-wrap {
      p {
        font-size: 18px;
        letter-spacing: 0.2px;
        color: #000000;
      }
      span {
        font-size: 15px;
        color: #919699;
      }
    }
  }
  .empty-text {
    padding-top: 170px;
    text-align: center;
    font-size: 16px;
  }
  .appointment-sidebar__client-item {
    padding: 40px 16px;
  }
  .appointment-sidebar__client-card {
    border-radius: 8px;
    border: solid 1px #c9cfd3;
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
    padding: 24px 16px;
    .avatar {
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #c9cfd3;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .green {
      width: 105px;
      text-transform: uppercase;
      margin-bottom: 9px;
    }
    .name {
      margin-bottom: 3px;
      font-size: 20px;
      letter-spacing: 0.22px;
      color: #000000;
    }
    .dob {
      font-size: 14px;
      letter-spacing: 0.15px;
      color: ${colors.INACTIVE_URL};
      padding-bottom: 5px;
    }
    .info {
      font-size: 15px;
      color: #919699;
      padding-bottom: 5px;
      span {
        font-weight: bold;
        letter-spacing: 0.15px;
        color: #21303a;
        padding-left: 3px;
      }
    }
    .intake {
      span {
        font-weight: 500;
        padding-left: 0;
      }
      padding-top: 15px;
    }
    .status {
      padding-top: 15px;
      padding-bottom: 0;
    }
    .intake {
      width: auto;
      padding: 0;
      margin-left: -10px;
      > div {
        border: transparent;
        padding: 0;
        width: auto;
        box-shadow: none;
      }
    }
  }
  .appointment-sidebar__legend {
    padding: 32px 0 20px 0;
    p {
      padding-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
    .item {
      display: inline-block;
      padding-right: 20px;
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
      }
      span {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.14px;
        color: #000000;
        padding-left: 5px;
        vertical-align: super;
      }
      &.inclinic {
        &:before {
          background-color: #8be07d;
        }
      }
      &.unavailable {
        &:before {
          border: solid 1px #c9cfd3;
          background-image: linear-gradient(
            45deg,
            #f5f5f5 45.45%,
            #d9d9d9 45.45%,
            #d9d9d9 50%,
            #f5f5f5 50%,
            #f5f5f5 95.45%,
            #d9d9d9 95.45%,
            #d9d9d9 100%
          );
          background-size: 55.56px 49.56px;
        }
      }
      &.offsite {
        &:before {
          background-color: #007aff;
        }
      }
    }
  }
  .container-client-tab {
    width: calc(100% + 16px);
    margin-top: 7px;
    .ant-table-wrapper {
      margin-top: 0 !important;
    }
  }
  .appointment-sidebar__calendar {
    width: calc(100% - 16px);
    .event-close {
      position: absolute;
      top: 4px;
      right: 8px;
      font-size: 14px;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        /* transform: rotate(180deg); */
      }
    }
    .calendar-switch {
      width: 50%;
      padding-bottom: 24px;
    }
    .calendar-offsite {
      width: 50%;
      padding-bottom: 24px;
      span {
        user-select: none;
      }
    }
    .calendar-datepicker {
      height: 32px;
      margin-left: auto;
      margin-right: 20px;
      i {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eae8ff;
        border-radius: 50%;
        color: ${colors.PURPLE};
      }
      > span {
        width: 0;
        height: 0;
        font-size: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
      }
    }
    .rbc-time-header {
      padding-top: 8px;
      .rbc-header {
        min-height: 34px;
        line-height: 34px;
        background-color: #e7eaed;
      }
    }
    .inClinic,
    .inClinic.rbc-selected {
      background-color: #8be07d;
    }
    .offSite,
    .offSite.rbc-selected {
      background-color: #007aff;
    }
    .na,
    .na.rbc-selected {
      background-image: linear-gradient(
        45deg,
        #f5f5f5 45.45%,
        #d9d9d9 45.45%,
        #d9d9d9 50%,
        #f5f5f5 50%,
        #f5f5f5 95.45%,
        #d9d9d9 95.45%,
        #d9d9d9 100%
      );
      background-size: 30px 30px;
    }
    .appointment,
    .appointment.rbc-selected {
      width: 100% !important;
      left: 0 !important;
      background-color: #6162ff;
      z-index: 2;
    }
    .rbc-events-container {
      .rbc-event {
        .rbc-event-content {
          background-color: transparent;
          font-size: 0;
        }
        .rbc-event-label {
          opacity: 0;
        }
      }
    }
    .month .rbc-event:before {
      display: none;
    }
    .calendar-nav__title {
      background-color: #ffffff;
    }
  }
  .appointment-body {
    position: relative;
    width: calc(100% - 500px);
    padding: 40px 30px;
    background-color: #fff;
    .schedule-type {
      > div {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        > div {
          display: flex;
          flex-wrap: wrap;
        }
        > label {
          width: 100%;
        }
      }
      .ant-calendar-picker {
        max-width: 120px;
      }
    }
    .repeat-wrap {
      input {
        max-width: 39px;
        padding: 0 0 0 6px;
        text-align: center;
      }
      span {
        padding-left: 5px;
        color: ${colors.DARK_GREY};
      }
      .ant-select-selection-selected-value {
        color: inherit;
      }
    }
    .ant-col {
      .weeks-until {
        width: 80px;
        margin-bottom: 6px;
      }
    }
  }
  .appointment-body__title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #21303a;
    span {
      color: #919699;
      margin-left: 12px;
    }
  }
  .appointment-body__sub-title {
    font-size: 14px;
    font-weight: bold;
    color: #21303a;
    padding-bottom: 20px;
    display: block;
  }
  .client-calendar {
    .rbc-day-slot,
    .rbc-time-header-content {
      min-width: auto;
    }
    .rbc-day-slot .rbc-event {
      min-height: 10px !important;
    }
    .rbc-time-content {
      border-right: 1px solid #ddd;
    }
    .rbc-events-container .rbc-event {
      width: 100% !important;
      left: 0 !important;
    }
    .interseption.error {
      background-image: linear-gradient(
        120deg,
        #8be07d 25%,
        #007aff 25%,
        #007aff 50%,
        #8be07d 50%,
        #8be07d 75%,
        #007aff 75%,
        #007aff 100%
      );
      background-size: 21px 36.4px;
    }
  }
  .openings-calendar {
    height: auto;
    .calendar-nav {
      width: 100%;
      justify-content: center;
      display: flex;
      padding: 10px 0;
    }
    .calendar-switch {
      .ant-radio-button-wrapper {
        min-width: 65px;
        text-align: center;
        outline: none;
        &:first-child {
          border-radius: 15px 0 0 15px;
        }
        &:last-child {
          border-radius: 0 15px 15px 0;
        }
      }
    }
    .rbc-month-view {
      min-height: 282px;
      background-color: #f6f6f6;
      padding: 24px;
      border: none;
      .rbc-header {
        font-size: 16px;
        font-weight: bold;
        color: #21303a;
        padding-bottom: 10px;
        border: none;
      }
      .rbc-row-bg {
        display: none;
      }
      .rbc-month-row + .rbc-month-row {
        border: none;
      }
      .rbc-date-cell {
        position: relative;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 4px;
        &.rbc-current {
          .has-event {
            color: #fff;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            background-color: ${colors.PURPLE};
          }
        }
        &.rbc-off-range {
          content-visibility: hidden;
        }
        span {
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;
          bottom: 0;
          text-align: center;
          padding-top: 3px;
          font-size: 15px;
          color: #919699;
          border: solid 1px #c9cfd3;
          background-color: #e7eaed;
          border-radius: 4px;
          &.has-event {
            background-color: #fff;
            color: ${colors.PURPLE};
            cursor: pointer;
          }
        }
      }
      .rbc-row-segment {
        display: none;
      }
    }
  }
  .list-view {
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    padding: 24px;
    .show-more {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.17px;
      color: #0d77b5;
      text-decoration: underline;
      text-align: center;
      padding-top: 30px;
      cursor: pointer;
    }
  }
  .list-view__row {
    position: relative;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 4px;
    padding: 18px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.selected {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: ${colors.PURPLE};
      span {
        color: #fff;
      }
    }
    > span {
      padding: 0 10px;
      font-size: 16px;
      color: #21303a;
      &:last-child {
        position: absolute;
      }
    }

    .wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .dayOfWeek {
      width: 7%;
    }
    .month {
      width: 13%;
    }
    .range {
      width: 26%;
    }
    .name {
      width: 25%;
    }
    .conflicts-calculation {
      white-space: nowrap;
      color: #888a8c;
      font-size: 12px;
    }
    .viewSurrounding {
      width: 29%;
      position: relative;
      white-space: nowrap;
      z-index: 1;
      &:hover {
        color: #793efa;
      }
      i {
        margin-right: 5px;
      }
    }
  }
  .list-view__empty {
    font-size: 16px;
    color: #21303a;
  }
  .openings-slot {
    background-color: #f6f6f6;
    padding: 0 24px 10px;
  }
  .openings-slot__help {
    font-size: 16px;
    text-align: center;
    color: ${colors.DARK_GREY};
    margin-bottom: 30px;
    display: block;
  }
  .openings-slot__container {
    position: relative;
    top: -14px;
    width: 500px;
    display: flex;
    margin: 0 auto;
  }
  .openings-slot__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 500;
  }
  .opening_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .openings-slot__item {
    position: relative;
    width: calc(50% - 18px);
    border-radius: 4px;
    border: solid 1px #c9cfd3;
    background-color: #ffffff;
    margin: 2px 8px;
    padding: 20px 24px;
    cursor: pointer;
    &.selected {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: #6f4bf1;
      span {
        color: #fff;
      }
    }
    span {
      font-size: 15px;
      font-weight: bold;
      color: #6f4bf1;
      display: block;
    }
    .name {
      padding-right: 65px;
    }
    .conflicts-calculation {
      position: absolute;
      top: 20px;
      right: 20px;
      white-space: nowrap;
      color: #888a8c;
    }
  }
  .openings-rooms__wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
  }
  .openings-rooms__item {
    width: calc(50% - 16px);
    border-radius: 4px;
    border: solid 1px #c9cfd3;
    background-color: #ffffff;
    margin-bottom: 16px;
    padding: 20px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    div {
      text-align: center;
      &:not(:last-child) {
        padding-right: 8px;
      }
      &:first-child {
        font-size: 14px;
        font-weight: bold;
        color: #21303a;
        padding-right: 20px;
      }
    }
    span {
      font-size: 14px;
      text-align: center;
      color: #21303a;
      display: block;
    }
    &.selected {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: #6f4bf1;
      span,
      div {
        color: #fff;
      }
      svg {
        fill: #fff;
      }
    }
  }
  .location {
    background-color: #f6f6f6;
    padding: 0 24px 20px;
  }
  .location__inOffice,
  .section {
    border-radius: 4px;
    border: solid 1px #c9cfd3;
    background-color: #ffffff;
    padding: 20px 18px 0;
    margin-top: 24px;
    margin-bottom: 32px;
    &.selected {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: #6f4bf1;
      span,
      label {
        color: #fff;
      }
    }
    textarea {
      min-height: 73px;
    }
  }
`;

export const CalendarToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  flex-wrap: wrap;
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${colors.PURPLE} !important;
  }
  .calendar-switch {
    label {
      color: ${colors.PURPLE};
      border-color: ${colors.PURPLE};
      box-shadow: inherit;
      &:first-child {
        border-color: ${colors.PURPLE};
      }
      &.active,
      &:hover {
        border-color: ${colors.PURPLE};
        background-color: ${colors.PURPLE};
        color: #fff;
        box-shadow: inherit;
      }
    }
  }
  .calendar-nav {
    display: flex;
    align-items: center;
    > * {
      display: flex;
      align-items: center;
    }
    label {
      color: ${colors.PURPLE};
      border: 1px solid ${colors.PURPLE};
      box-shadow: inherit;
      &:first-child {
        border-color: ${colors.PURPLE};
      }
      &.ant-radio-button-wrapper-checked,
      &.active,
      &:hover {
        border-color: ${colors.PURPLE};
        background-color: ${colors.PURPLE};
        color: #fff;
        box-shadow: inherit;
      }
    }
    &__title {
      min-height: 32px;
      color: ${colors.PURPLE};
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${colors.PURPLE};
      border-left: none;
    }
    .calendar-nav__today {
      margin-right: 24px;
      label {
        border-radius: 8px;
        border-width: 2px;
        font-weight: bold;
      }
    }
    .ant-radio-group {
      label {
        padding: 0 9px;
      }
    }
  }
`;

export const WarningModal = styled(Modal)`
  width: 770px !important;
  &.conflict-modal {
    .slide-item {
      background-color: #e9f4d2;
    }
  }
  .ant-modal-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .ant-carousel {
    width: 300px;
    margin: 0 auto;
  }
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #21303a;
    padding-bottom: 28px;
  }
  .modal-info {
    opacity: 0.8;
    font-size: 15px;
    font-weight: bold;
    color: #21303a;
    padding-bottom: 18px;
  }
  .slide-item {
    width: calc(100% - 40px) !important;
    border-radius: 4px;
    background-color: #ffe2e2;
    padding: 12px 20px;
    text-align: left;
    margin: 0 20px;
    p {
      font-size: 15px;
      font-weight: bold;
      color: #21303a;
    }
  }
  .large {
    margin-bottom: 16px;
  }
  .ant-modal-close-x {
    font-size: 15px;
    letter-spacing: 0.17px;
    color: #0d77b5;
    text-decoration: underline;
    margin-right: 18px;
  }
  .button-wrap {
    padding-bottom: 20px;
    button {
      margin: 0 5px;
    }
    .secondary {
      height: 48px;
    }
  }
`;

export const ProvidersCalendarWrap = styled.div`
  background: #fff;
  width: 429px;
  padding: 20px 30px;
  .providers-calendar {
    position: relative;
    .rbc-time-view-resources .rbc-time-gutter,
    .rbc-time-view-resources .rbc-time-header-gutter {
      background-color: #fff;
    }
    .rbc-event {
      background-color: ${colors.LIGHT_SKY_BLUE} !important;
      border-radius: 0;
      border: 1px solid #fff;
      padding: 0;
      &.rbc-selected {
        border: 1px dashed #919699;
      }
    }
    .rbc-header {
      border-bottom: none;
    }
    .rbc-event-label {
      display: none;
    }
    .rbc-event-content {
      background-color: unset !important;
      display: flex;
      align-items: center;
    }
    .rbc-time-header {
      padding-top: 0;
      padding-bottom: 34px;
    }
    .current-date {
      position: absolute;
      left: 59px;
      right: 0;
      top: 58px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e7eaed;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #49555f;
    }
    .provider-item {
      width: 100%;
      display: flex;
      align-items: center;
      &:before {
        background-color: transparent;
      }
      span {
        font-size: 10px;
        letter-spacing: -0.33px;
        color: #21303a;
        padding: 0 10px;
        z-index: 3;
      }
      .date {
        min-width: 70px;
      }
      .appointment {
        margin: 0 auto;
        font-weight: 600;
      }
      .phenotype {
        min-width: 20px;
      }
    }
  }
`;

export const ModalWarningWrapper = styled(Modal)`
  margin-top: 15%;
  .ant-modal-close-x {
    .edit-button-2 {
      position: absolute;
      font-size: 15px;
      letter-spacing: 0.17px;
      top: 8px;
      right: 18px;
      color: rgb(13, 119, 181);
      text-decoration: underline;
    }
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2,
    p {
      opacity: 0.8;
      font-family: Lato;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      color: ${colors.AVATAR_INITIAL};
    }
    p {
      margin: 28px auto;
      font-size: 15px;
    }
    .button-wrapper {
      .large,
      .medium {
        padding: 0;
        min-width: 250px;
        padding-left: 14px;
        padding-right: 14px;
        height: 48px;
      }
      .medium {
        margin-right: 10px;
      }
    }
  }
`;
export const LocationWrapper = styled.div`
  width: 100%;
  .error {
    width: 189px;
    height: 11px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.RED};
  }
  .error-input {
    border-color: #cc3f3f;
  }
`;
