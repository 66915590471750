import React, { useMemo } from 'react';
import { EventType, ICalendarEvent } from 'model/calendar/events';
import { getSyncMessages } from 'utils/constants/planner';
import { formatEventPopupTime, formatHhMmTime } from 'utils/format';
import { isFullySyncedEvent } from 'utils/validators/dragEventValidator';
import { ItemTooltipWrapper } from './style';
import { isCancelledEvent } from 'utils/validators/BulkCancel';
import { Appointment_Types } from 'components/AvailabilityTemplateModalForm/EditForm/dropdownOptions';

interface Props {
  itemRenderer: any;
}
const ItemTooltip: React.FC<Props> = ({ itemRenderer }: Props) => {
  const { item }: { item: ICalendarEvent } = itemRenderer;

  const fullData = useMemo(
    () => ({
      subtype: item.appointmentType?.title,
      parentType: item.appointmentType?.parent?.title,
      room: item.room?.name || '',
      duration: `${formatHhMmTime(item.start)} - ${formatEventPopupTime(
        item.end
      )} `
    }),
    [item]
  );

  const filteredAvailabilities = Appointment_Types.filter(appointmentType => {
    if (fullData.subtype === appointmentType.value) {
      return true;
    }
    return false;
  });

  let abbreviationArray: any = [];
  let abreviationString: any;

  if (filteredAvailabilities.length != 0) {
    item.clinics?.map((clinic: any) => {
      abbreviationArray.push(clinic?.abbreviation);
    });
  }

  abreviationString = abbreviationArray.join(' | ');

  const getAppointmnetTitle = useMemo(() => {
    const appointmentTitle = fullData.parentType
      ? `${fullData.parentType}${'(' + fullData.subtype + ')'}`
      : `${fullData.subtype}`;
    if (appointmentTitle == undefined) {
      return '';
    } else {
      return appointmentTitle;
    }
  }, [fullData]);
  return (
    <ItemTooltipWrapper>
      {item.type === EventType.clientEvent ||
      item.type === EventType.phantomClientEvent ||
      item.type === EventType.phantomObserverEvent ? (
        <>
          {item.title?.trim() && (
            <p>{`${item.title || ''}${item.clinic?.id &&
              `(${item.clinic?.abbreviation || ''})`}`}</p>
          )}
          <p>
            {getAppointmnetTitle},{fullData.duration || ''}
            {/* {fullData.room || ''} */}
          </p>
        </>
      ) : (
        <>
          <p>{item.title}</p>
          <p className="clinic-abbr">{abreviationString}</p>
          <p>
            {getAppointmnetTitle},{fullData.duration || ' '}
          </p>
        </>
      )}
      {!isFullySyncedEvent(item.athenaStatus, item.crStatus) &&
        !isCancelledEvent(item) && (
          <text className="error">
            {getSyncMessages(item.crStatus!, item.athenaStatus!).map(str => (
              <p>{str}</p>
            ))}
          </text>
        )}
    </ItemTooltipWrapper>
  );
};
export default ItemTooltip;
