import React, { useMemo } from 'react';
import { Days } from 'model/clinic';
import HourInfoItem from './HourInfoItem';
import { IClinicBusinessHours } from 'model/v2';
import { DAY_INDEX } from 'utils/constants/default';

interface IProps {
  hours?: IClinicBusinessHours[];
}

const HoursInfo: React.FC<IProps> = ({ hours }: IProps) => {
  const days = useMemo(() => Object.values(Days), []);
  return (
    <>
      <HourInfoItem label={true} name={''} />
      {days.map(day => (
        <HourInfoItem
          name={day}
          key={day}
          checked={
            hours?.filter(
              h =>
                DAY_INDEX.get(`${h.dayOfWeek}`)?.toLowerCase() ===
                day.toLowerCase()
            ).length === 0 || !hours
          }
        />
      ))}
    </>
  );
};
export default React.memo(HoursInfo);
