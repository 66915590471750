import logo from 'assets/img/logo.svg';
import msLogo from 'assets/img/ms-logo.svg';

interface Props {
  handleLogin: () => Promise<void>;
}

export default function SignIn({ handleLogin }: Props) {
  return (
    <div className="signin-box vh-centered">
      <div className="cortica-logo">
        <img src={logo} className="logo" alt="logo" />
      </div>
      <>
        <h2>Sign In</h2>
        <p>Please sign in with your Microsoft account to continue.</p>
      </>

      <div className="microsoft-sign-in" onClick={handleLogin}>
        <img src={msLogo} className="logo v-centered " alt="logo" />
        Sign in with Microsoft
      </div>
    </div>
  );
}
