import { TimelineKeys, TimelineTimeSteps } from 'react-calendar-timeline';
import { PlannerView } from 'utils/cache/calendar';

export const PLANNER_DAY_KEYS: TimelineKeys = {
  groupIdKey: 'id',
  groupTitleKey: 'displayName',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'calendarID',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'resourceId',
  itemTimeStartKey: 'startTime',
  itemTimeEndKey: 'endTime'
};

export const PLANNER_WEEK_KEYS: TimelineKeys = {
  groupIdKey: 'id',
  groupTitleKey: 'displayName',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'calendarID',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'resourceId',
  itemTimeStartKey: 'plannerWeekStartTime',
  itemTimeEndKey: 'plannerWeekEndTime'
};

export const getPlannerKeys = () => {};

export const PLANNER_TIMESTEPS: TimelineTimeSteps = {
  second: 1,
  minute: 15,
  hour: 1,
  day: 1,
  month: 1,
  year: 1
};

export const MIN_PLANNER_HOUR = 7;
export const MAX_PLANNER_HOUR = (
  view: PlannerView | undefined = PlannerView.plannerDay
) => {
  return view === PlannerView.plannerWeek ? 21 : 20;
};
export const PLANNER_TIMESTEP = 15;
export const PLANNER_DAY_SIDEBAR_WIDTH = 291;
export const PLANNER_WEEK_SIDEBAR_WIDTH = 380;
export const GROUP_HEIGHT = 60;
export const ITEM_HEIGHT_RATIO = 0.97;
export const MIN_EVENT_DURATION = 900000;
export const EVENT_STATUS_MIN_SUCCESS_RANGE = 100;
export const EVENT_STATUS_MAX_SUCCESS_RANGE = 200;
export const FIRST_WEEK_DAY = '0';
export const LAST_WEEK_DAY = '6';
export const RESIZE_DIRECTION = 'both';
export const MIN_ZOOM = 60 * 60 * 1000 * 2; // 2 hrs
export const MAX_ZOOM = 60 * 60 * 1000 * 24; // 24 hrs

export const SCROLLABLE_TIMELINE_ID = 'srollableTimeline';

export const INVISIBLE_NOT_SYNC_ERR =
  'Invisible appointment has not synced (failed or pending) to CR';
export const NOT_VALID_TO_DROP_EVENT = 'notValid';

export const COULD_NOT_FIND_EVENT =
  'This event was cancelled by another user. Refresh Page.';

export const DOWNSTREAM_SYNC_STATUS = {
  CANCEL_SUCCESS: 103,
  NO_SYNC: 0
};

const TOOLTIP_MESSAGES = {
  PENDING_SYNC: 'In-progress Sync',
  FAILED_TO_SYNC: 'Failed to Sync',
  PENDING_SYNC_ATHENA: 'In-progress Sync to Athena',
  PENDING_SYNC_CENTRAL_REACH: 'In-progress Sync to Central Reach',
  FAILED_TO_SYNC_ATHENA: 'Failed to Sync to Athena',
  FAILED_TO_SYNC_CENTRAL_REACH: 'Failed to Sync to Central Reach',
  PENDING_CANCEL_AXON: 'In-progress Cancel in Axon'
};

export const isPendingAxonCancel = (
  centralReachStatus: number,
  athenaStatus: number
) => {
  return (
    (athenaStatus === DOWNSTREAM_SYNC_STATUS.NO_SYNC ||
      athenaStatus === DOWNSTREAM_SYNC_STATUS.CANCEL_SUCCESS) &&
    centralReachStatus === DOWNSTREAM_SYNC_STATUS.CANCEL_SUCCESS
  );
};

export const getSyncMessages = (crStatus: number, athenaStatus: number) => {
  console.log('athenaCRStatusSyncMessages=', athenaStatus, crStatus);
  if (athenaStatus && crStatus) {
    // IF BOTH DOWNSTREAMS PENDING SYNC
    if (
      crStatus < EVENT_STATUS_MIN_SUCCESS_RANGE &&
      athenaStatus < EVENT_STATUS_MIN_SUCCESS_RANGE
    )
      return [TOOLTIP_MESSAGES.PENDING_SYNC];

    // IF BOTH DOWNSTREAMS FAILED TO SYNC
    if (
      crStatus >= EVENT_STATUS_MAX_SUCCESS_RANGE &&
      athenaStatus >= EVENT_STATUS_MAX_SUCCESS_RANGE
    )
      return [TOOLTIP_MESSAGES.FAILED_TO_SYNC];
  }

  // IF BOTH DOWNSTREAMS SYNCED BUT PENDING CANCEL AXON
  if (isPendingAxonCancel(crStatus, athenaStatus))
    return [TOOLTIP_MESSAGES.PENDING_CANCEL_AXON];

  const syncMessages: string[] = [];

  // IF ONLY ATHENA IS PENDING OR FAILED TO SYNC
  if (athenaStatus) {
    if (athenaStatus < EVENT_STATUS_MIN_SUCCESS_RANGE)
      syncMessages.push(TOOLTIP_MESSAGES.PENDING_SYNC_ATHENA);
    if (athenaStatus >= EVENT_STATUS_MAX_SUCCESS_RANGE)
      syncMessages.push(TOOLTIP_MESSAGES.FAILED_TO_SYNC_ATHENA);
  }

  // IF ONLY CR IS PENDING OR FAILED TO SYNC
  if (crStatus) {
    if (crStatus < EVENT_STATUS_MIN_SUCCESS_RANGE)
      syncMessages.push(TOOLTIP_MESSAGES.PENDING_SYNC_CENTRAL_REACH);
    if (crStatus >= EVENT_STATUS_MAX_SUCCESS_RANGE)
      syncMessages.push(TOOLTIP_MESSAGES.FAILED_TO_SYNC_CENTRAL_REACH);
  }

  return syncMessages;
};
