import React from 'react';

type Props = {
  className: string;
};

const WaitlistIcon: React.FC<Props> = (p: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 426.667 426.667"
    fill="currentColor"
    version="1.1"
    viewBox="0 0 426.667 426.667"
    xmlSpace="preserve"
    width="20"
    className={p.className}
  >
    <path d="M303.893 122.773c-25.067-24.96-57.813-37.44-90.56-37.44v128l-90.56 90.56c50.027 50.027 130.987 50.027 181.013 0 50.027-50.026 50.027-131.093.107-181.12z"></path>
    <path d="M213.333 0C95.467 0 0 95.467 0 213.333s95.467 213.333 213.333 213.333 213.333-95.573 213.333-213.333C426.667 95.467 331.2 0 213.333 0zm0 384c-94.293 0-170.667-76.373-170.667-170.667S119.04 42.667 213.333 42.667 384 119.04 384 213.333 307.627 384 213.333 384z"></path>
  </svg>
);

export default WaitlistIcon;
