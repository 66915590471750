import React, { useState, useCallback } from 'react';
import { CancelConfirm } from './MainCalendar.style';
import { Select, TextArea } from 'lib/ui';
import { MODAL_AUTOMATA } from './ModalWrapper';
import Content from 'views/components/ui/content';
import { useQuery } from 'react-apollo';
import { mapToOption } from 'utils/mappers/form';
import { ALL_CANCELLATION_REASONS } from 'api/graphql/v2/queries/CancellationReasons';

export const ModalReasons = ({
  setStateValueHandler,
  type,
  cancelEvent,
  rescheduleEvent,
  setStep,
  setEditOption,
  editOption
}) => {
  const [options, setOptions] = useState([]);
  const [cancelNote, setCancelNote] = useState(undefined);

  const { loading, error } = useQuery(ALL_CANCELLATION_REASONS, {
    onCompleted: data => {
      const cancellationReasons = data.allCancellationReasons.filter(x =>
        type === 'reschedule' ? x.type !== 0 : x.type !== 1
      );
      setOptions(
        mapToOption(
          cancellationReasons?.map(reason => {
            return {
              id: reason.reasonId,
              value: reason.name
            };
          })
        )
      );
    }
  });

  const onSelect = useCallback(
    val => {
      const reasonOption = options.find(o => o.value === val);
      setStateValueHandler('cancelReason', reasonOption);
    },
    [options, setStateValueHandler]
  );

  const handleCancel = useCallback(() => {
    setEditOption({
      ...editOption,
      cancelReason: null
    });
    setStep(MODAL_AUTOMATA.MODAL_CONTENT);
  }, [setStep, setEditOption, editOption]);

  const handleNoteChange = useCallback(event => {
    setCancelNote(event.target.value);
  }, []);

  return (
    <CancelConfirm>
      <Content data={options} loading={loading} error={error}>
        {() => (
          <>
            <p>
              Select a reason for
              {type === 'reschedule' ? ' reschedule' : ' canceling'}
            </p>
            <Select
              showSearch
              onChange={onSelect}
              options={options}
              filterOption={(input, option) =>
                option.children
                  ?.toLowerCase()
                  .includes(input?.toLowerCase() ?? '')
              }
              placeholder="Select a reason"
            />
            <br />
            <TextArea
              placeholder="Type Note/Reason"
              className="textArea"
              value={cancelNote || ''}
              size="small"
              onChange={handleNoteChange}
              autoSize={true}
              // autoSize={{ minRows: 2, maxRows: 6 }}
            />
            <div className="ant-popover-buttons">
              <button
                type="button"
                className="ant-btn ant-btn-sm"
                onClick={handleCancel}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                disabled={!editOption.cancelReason}
                className="ant-btn ant-btn-primary ant-btn-sm"
                onClick={() =>
                  type === 'reschedule'
                    ? rescheduleEvent(cancelNote)
                    : cancelEvent(cancelNote)
                }
              >
                <span>OK</span>
              </button>
            </div>
          </>
        )}
      </Content>
    </CancelConfirm>
  );
};
