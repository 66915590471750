import { OvalCheckboxGroup } from 'lib/ui';
import React, { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import {
  Select,
  SelectWithColor
} from 'api/sharedComponents/reactHookFormComponents';
import { ModalWrapper } from '../style';
import EditTimeForm from './EditTimeForm';
import { WEEK_DAYS } from 'utils/constants/lists';
import { FrequencyFormWrapper } from 'pages/MainCalendarPage/AppointmentForm/style';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EDIT_AVAILABILITY_BLOCK,
  EDIT_AVAILABILITY_BLOCK_PROVIDER
} from 'utils/validators/availabilityTemplate/editAvailabilityBlock';
import { Event } from 'react-big-calendar';
import {
  Appointment_Types,
  NotesAmount,
  Notes_Length,
  SubBlock_Length
} from './dropdownOptions';
import ModalBottomWithDelete from '../ReusableComponents/ModalBottomWithDelete';
import ClinicSelect from './ClinicSelect';
import { ContentWrapper } from 'views/components/ui/card/card.style';
import { IClinic } from 'model/v2/clinic';
import moment, { Moment } from 'moment';
import { mapToLocationsDownOption } from 'utils/mappers/form';
import { ILocation } from 'model/v2/location';
import { CalenderEvent } from 'model/v2/availabilityTemplate';
interface IProps {
  onClose(): void;
  onSubmit(data: any, oldEvent: Event): void;
  onDelete(selectedEvent: CalenderEvent): void;
  data: any;
  providerId?: number;
  clinics?: IClinic[];
  providerDefaultClinicsIds?: number[];
  locations?: ILocation[];
  department?: string;
  isProviderAvailabilityMode?: boolean;
}
interface ITemplateForm {
  appt_type: string;
  start: Date;
  end: Date;
  sub_block_length: number;
  location: number;
  clinics: string[];
  recurringDays: number[];
}

const getSubBlockOptions = (start: Date | Moment, end: Date | Moment) => {
  const duration = Math.abs(moment(start).diff(end, 'm'));
  return SubBlock_Length.filter(
    x => x.value <= duration || x.value === SubBlock_Length[0].value
  );
};

const EditForm: React.FC<IProps> = ({
  onClose,
  onSubmit,
  onDelete,
  data,
  providerId,
  clinics,
  providerDefaultClinicsIds,
  locations,
  department,
  isProviderAvailabilityMode
}) => {
  const locationsOptions = useMemo(() => {
    return mapToLocationsDownOption(locations || []);
  }, [locations]);

  const methods = useForm<ITemplateForm>({
    resolver: isProviderAvailabilityMode
      ? yupResolver(EDIT_AVAILABILITY_BLOCK_PROVIDER)
      : yupResolver(EDIT_AVAILABILITY_BLOCK)
  });

  const { start, end } = methods.watch(['start', 'end'], data);
  const SubBlockOptions = getSubBlockOptions(start, end);
  const BlockLength = methods.watch('sub_block_length', 0);

  const dayIndex = data.start.getDay();
  const defaultClinics = useMemo(() => {
    return data.clinics && data.clinics?.length !== 0
      ? data.clinics?.map((clinicId: { id: number }) => {
          return clinicId.id;
        })
      : providerDefaultClinicsIds;
  }, [data.clinics, providerDefaultClinicsIds]);

  const submitForm = React.useCallback(
    (submittedData: any) => {
      onSubmit(submittedData, data);
    },
    [onSubmit, data]
  );

  const recurringDaysHandler = React.useCallback(
    (val: number[]) => {
      methods.setValue('recurringDays', val);
    },
    [methods]
  );

  let filteredOptions: typeof Appointment_Types = [];
  if (department === 'ABA' || department === 'MED') {
    filteredOptions = Appointment_Types.filter(
      option =>
        option.value !== 'Lunch' &&
        option.value !== 'Meeting' &&
        option.value !== 'Notes'
    );
  } else {
    filteredOptions = Appointment_Types;
  }

  return (
    <FormProvider {...methods}>
      <ModalWrapper>
        <div className="upper-formItem-wrapper">
          <div className="single-item">
            <FormItem label="Slot Type">
              <Controller
                control={methods.control}
                name={'appt_type'}
                defaultValue={
                  data.title ? data.type : Appointment_Types[0].value
                }
                render={fieldProps => (
                  <SelectWithColor
                    options={filteredOptions}
                    field={fieldProps}
                    defaultValue={Appointment_Types[0].value}
                    errors={methods.errors}
                  />
                )}
              />
            </FormItem>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper>
        <div className="upper-formItem-wrapper">
          <div className="upper-formItem">
            <div className="time-formItem">
              <EditTimeForm
                time={data.start}
                controlName={'start'}
                displayName={'Start Time'}
                dayIndex={dayIndex}
              ></EditTimeForm>

              <EditTimeForm
                time={data.end}
                controlName={'end'}
                displayName={'End Time'}
                dayIndex={dayIndex}
              >
                Clinics
              </EditTimeForm>

              <FormItem label="Sub Block Length">
                <Controller
                  control={methods.control}
                  name={'sub_block_length'}
                  defaultValue={SubBlockOptions[0].value}
                  render={fieldProps => (
                    <Select
                      options={SubBlockOptions}
                      defaultValue={SubBlockOptions[0].value}
                      field={fieldProps}
                      errors={methods.errors}
                    />
                  )}
                />
              </FormItem>
            </div>
          </div>

          <div className="upper-formItem">
            <FormItem label="Location">
              <Controller
                control={methods.control}
                name={'location'}
                defaultValue={data.location || (locations && locations[0].id)}
                render={fieldProps => (
                  <SelectWithColor
                    options={locationsOptions}
                    defaultValue={
                      data.location || (locations && locations[0].id)
                    }
                    field={fieldProps}
                    errors={methods.errors}
                  />
                )}
              />
            </FormItem>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper>
        {!isProviderAvailabilityMode && (
          <div className="upper-formItem-wrapper formItem-with-title">
            <div className="upper-formItem upper-formItem-title">
              <h3>Add Notes After </h3>
              <FormItem label="Amount">
                <Controller
                  control={methods.control}
                  rules={{ required: true }}
                  defaultValue={NotesAmount[0].value}
                  name={'notes_amount'}
                  render={fieldProps => (
                    <Select
                      options={NotesAmount}
                      defaultValue={NotesAmount[0].value}
                      field={fieldProps}
                      errors={methods.errors}
                      disabled={BlockLength === 0}
                    />
                  )}
                />
              </FormItem>
            </div>
            <div className="upper-formItem upper-formItem-title">
              <h3>Block Length(s) for</h3>
              <FormItem label="Duration">
                <Controller
                  control={methods.control}
                  name={'notes_duration'}
                  defaultValue={Notes_Length[0].value}
                  render={fieldProps => (
                    <Select
                      options={Notes_Length}
                      defaultValue={Notes_Length[0].value}
                      field={fieldProps}
                      errors={methods.errors}
                      disabled={BlockLength === 0}
                    />
                  )}
                />
              </FormItem>
            </div>
          </div>
        )}
      </ModalWrapper>

      <ModalWrapper>
        <ContentWrapper>
          {isProviderAvailabilityMode && (
            <div className="upper-formItem-wrapper">
              <div className="single-item">
                <FormItem label="Clinics">
                  <Controller
                    control={methods.control}
                    name={'clinics'}
                    defaultValue={defaultClinics}
                    render={() => (
                      <ClinicSelect
                        providerId={providerId}
                        clinics={clinics!}
                        defaultData={defaultClinics}
                      ></ClinicSelect>
                    )}
                  />
                </FormItem>
              </div>
            </div>
          )}
        </ContentWrapper>
      </ModalWrapper>

      <FrequencyFormWrapper>
        <h3>Apply To</h3>
        <FormItem optional={false} label="Days" className="daysWrapper">
          <Controller
            name="recurringDays"
            control={methods.control}
            defaultValue={[dayIndex]}
            render={fieldProps => (
              <OvalCheckboxGroup
                options={WEEK_DAYS}
                field={fieldProps}
                defaultValue={[dayIndex]}
                errors={methods.errors}
                children={null}
                onChange={recurringDaysHandler}
              />
            )}
          />
        </FormItem>
      </FrequencyFormWrapper>

      <ModalBottomWithDelete
        onClose={onClose}
        submitForm={submitForm}
        onDelete={onDelete}
        selectedEvent={data}
      />
    </FormProvider>
  );
};

export default EditForm;
