import React from 'react';
import Calendar, { Detail } from 'react-calendar';
import { CalendarWrapper } from './style';
import CaretLeftIcon from 'views/components/icons/caret/caretLeftIcon';
import CaretRightIcon from 'views/components/icons/caret/caretRightIcon';
import moment from 'moment';
import { getCalendarTitle } from 'utils/builders/calendar';
interface Props {
  onChangeHandler: (date: Date) => void;
  date: Date;
  view: Detail;
}
const DatePicker: React.FC<Props> = ({
  onChangeHandler,
  date,
  view
}: Props) => {
  return (
    <CalendarWrapper>
      <Calendar
        value={date}
        prevLabel={<CaretLeftIcon size="2x" />}
        nextLabel={<CaretRightIcon size="2x" />}
        formatMonthYear={(_locale, date) => getCalendarTitle(date) as string}
        formatShortWeekday={(_locale, date) => moment(date).format('dd')}
        calendarType={'US'}
        minDetail={view}
        maxDetail={view}
        view={view}
        onClickMonth={onChangeHandler}
        onClickDay={onChangeHandler}
      />
    </CalendarWrapper>
  );
};
export default React.memo(DatePicker);
