import { useState, useMemo, useEffect, useRef } from 'react';
import { GET_OPENINGS } from 'api/graphql/queries/openings';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useFormContext, useWatch } from 'react-hook-form';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { GET_OPENING_CONFLICTS } from 'api/graphql/queries/openings';
import { getApptType, isRecurringEvent } from 'utils/common';
import { useStaffMap } from '../../newAppointmentHooks';
import { clientCalendarVar } from '../SideBar/ClientCalendar';
import { NO_PREFERENCE_OPTION, TIMEZONE } from 'utils/constants/default';

const compareByDates = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDay() === date2.getDay()
  );
};

export const useOpenings = (clientId, apptTypes, disabled) => {
  // provider
  const location = useLocation();
  const { event, thisAndFollowingEvents } = location.state || {};
  const { control, setValue } = useFormContext();
  const formValues = useWatch({
    control,
    name: [
      'selectedDate',
      'duration',
      'clinicId',
      'locationType',
      'provider',
      'selectedSlot',
      'appType'
    ]
  });

  const availableDays = useRef(new Map());
  const [openings, setOpenings] = useState(undefined);

  const staffMap = useStaffMap();

  const apt = useMemo(() => {
    return getApptType({ title: formValues.appType }, apptTypes);
  }, [formValues.appType, apptTypes]);

  const range = useMemo(
    () => ({
      start: moment(formValues.selectedDate || undefined)
        .startOf('month')
        .startOf('day')
        .toISOString(),
      end: moment(formValues.selectedDate || undefined)
        .endOf('month')
        .startOf('day')
        .add(1, 'day')
        .toISOString()
    }),
    [formValues.selectedDate]
  );

  //TODO: check performance and cleaner option
  useEffect(() => {
    if (
      disabled &&
      formValues.selectedSlot?.start &&
      staffMap.get(formValues.selectedSlot.providerId) &&
      !Array.isArray(openings)
    ) {
      availableDays.current.set(
        moment(formValues.selectedSlot.start).format('DD-MM-YYYY'),
        true
      );
      setOpenings([
        {
          ...formValues.selectedSlot,
          provider: staffMap.get(formValues.selectedSlot.providerId)
        }
      ]);
    }
  }, [staffMap, disabled, formValues.selectedSlot, openings]);

  const { loading: loadingOpenings } = useQuery(GET_OPENINGS, {
    variables: {
      providerId:
        formValues.provider === NO_PREFERENCE_OPTION.id
          ? null
          : formValues.provider,
      clinicId:
        formValues.provider === NO_PREFERENCE_OPTION.id
          ? formValues.clinicId
          : null, //formValues.clinicId,
      startTime: range.start,
      endTime: range.end,
      scope: parseInt(formValues.duration),
      clientId: clientId,
      reschedule: !!event?.id,
      thisAndFollowing: !!thisAndFollowingEvents,
      timezone: TIMEZONE,
      rescheduledEvent: {
        recurringEvery: event?.recurringEvery,
        startTime: event?.startTime,
        endTime: event?.endTime,
        id: event?.id,
        masterID: event?.masterID
      },
      appointmentTypeId: apt?.id,
      isClinical: apt?.isClinical,
      locationType:
        formValues.locationType === LOCATION_TYPE_LIST[1].id
          ? 'offSite'
          : formValues.locationType === LOCATION_TYPE_LIST[0].id
          ? 'inClinic'
          : 'telehealth'
    },
    skip: !(
      (formValues.clinicId || formValues.provider) &&
      formValues.locationType &&
      clientId &&
      range.start &&
      range.end &&
      parseInt(formValues.duration) &&
      apt?.id &&
      !disabled
    ),
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      let newOpenings = [];
      availableDays.current = new Map();
      // loop add providers and check selected date
      let openingsPerDate = {};

      data.openings.forEach(userOpenings => {
        userOpenings.openings.forEach(o => {
          const openingDate = moment(o.startTime).format('DD-MM-YYYY');
          if (!availableDays.current.has(openingDate)) {
            availableDays.current.set(openingDate, true);
          }

          // TODO: provider memory and make it better
          if (Array.isArray(openingsPerDate[openingDate]))
            openingsPerDate[openingDate].push({
              provider: staffMap.get(userOpenings.userId),
              providerId: userOpenings.userId,
              start: o.startTime,
              end: o.endTime
            });
          else {
            openingsPerDate[openingDate] = [
              {
                provider: staffMap.get(userOpenings.userId),
                providerId: userOpenings.userId,
                start: o.startTime,
                end: o.endTime
              }
            ];
          }

          newOpenings.push({
            provider: staffMap.get(userOpenings.userId),
            providerId: userOpenings.userId,
            start: o.startTime,
            end: o.endTime,
            id: `${userOpenings.userId}-${o.startTime}`
          });
        });
      });

      setOpenings(newOpenings);

      if (newOpenings.length > 0) {
        if (
          formValues.selectedSlot?.start &&
          compareByDates(
            moment(formValues.selectedSlot?.start) //TODO: make this into date library
              .startOf('month')
              .toDate(),
            new Date(range.start)
          )
        ) {
          const newSelectedDate = moment(formValues.selectedSlot.start)
            .startOf('day')
            .toDate();
          setValue('selectedDate', newSelectedDate.toISOString());
          clientCalendarVar(newSelectedDate);
        } else {
          if (
            !availableDays.current.has(
              moment(formValues.selectedDate).format('DD-MM-YYYY')
            )
          ) {
            const newSelectedDate = moment(newOpenings[0].start)
              .startOf('day')
              .toDate();
            setValue('selectedDate', newSelectedDate.toISOString());
            clientCalendarVar(newSelectedDate);
          }
        }
      } else {
        setValue('selectedDate', new Date(range.start).toISOString());
        clientCalendarVar(new Date(range.start));
      }
    }
  });

  return {
    loadingOpenings,
    openings,
    staffMap,
    availableDays
  };
};

// for each opening get the conflicts and show it in pop up
export const useOpeningConflicts = (
  opening,
  thisAndFollowingEvents,
  reschedule
) => {
  const [conflictOpening, setConflictOpening] = useState(opening);
  const { watch } = useFormContext();

  //TODO: report this and update the values later with useWatch
  const watcher = watch([
    'repeat',
    'recurringUntil',
    'masterID',
    'scheduleType',
    'recurringEvery'
  ]);
  const providerId = opening.providerId || opening.provider?.id;
  const recurringEvery =
    watcher.repeat || parseInt(watcher.recurringEvery) || 0;
  const recurringUntil = moment.isMoment(watcher.recurringUntil)
    ? watcher.recurringUntil.toISOString()
    : watcher.recurringUntil;
  const { loading: loadingConflicts } = useQuery(GET_OPENING_CONFLICTS, {
    variables: {
      userId: providerId,
      startTime: opening.start,
      endTime: opening.end,
      recurringEvery: recurringEvery,
      recurringUntil: recurringUntil,
      masterID: watcher.masterID,
      recurring: isRecurringEvent(watcher.scheduleType),
      reschedule
    },
    fetchPolicy: 'cache-first',
    skip:
      !opening ||
      !providerId ||
      recurringEvery === 0 ||
      (reschedule && !thisAndFollowingEvents),
    onCompleted: data => {
      const totalRecurringAppointments = data.getEventConflicts.eventsCount;
      const openingsAvailableNum =
        totalRecurringAppointments - data.getEventConflicts.conflicts.length;

      setConflictOpening({
        ...opening,
        totalRecurringAppointments,
        openingsAvailableNum,
        conflicts: data.getEventConflicts.conflicts
      });
    }
  });

  return {
    conflictOpening: conflictOpening,
    loadingConflicts
  };
};
