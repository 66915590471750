import React from 'react';
import Breadcrumbs from 'views/components/ui/profile/Breadcrumbs';
import Header from './header';
import Content from 'views/components/ui/content';
import ClientTabs from './tabs/index.js';
import { useQuery } from '@apollo/react-hooks';
import { GET_CLIENT_PROFILE } from 'api/graphql/v2/queries/Clients';

const ClientProfile = ({ match, accountID }) => {
  const id = match.params.id;

  const { loading: loadingClientData, error, data: clientData } = useQuery(
    GET_CLIENT_PROFILE,
    {
      variables: {
        id: Number(id),
        startDate: '',
        endDate: '',
        clientIds: [parseInt(id)]
      },
      fetchPolicy: 'cache-and-network'
    }
  );
  return (
    <Content loading={loadingClientData} error={error} data={clientData}>
      {() => (
        <>
          <Breadcrumbs userType="client" user={clientData.clientProfile} />
          <Header
            client={clientData?.clientProfile}
            clientWaitlist={[]} //data?.clientWaitlist //to be replaced with clientData.waitList
            appointmentTypes={clientData?.allAppointmentTypes}
          />
          <ClientTabs
            client={clientData?.clientProfile}
            //determine if used in calendar
            calendarOptions={{
              blockedDates:
                clientData?.clientAvailability?.length > 0
                  ? clientData.clientAvailability[0].blockedDates
                  : [],
              availabilityLastUpdated:
                clientData?.clientAvailability?.length > 0
                  ? clientData.clientAvailability[0]
                      .availabilityLastModificationDate
                  : ''
            }}
            accountID={accountID}
          />
        </>
      )}
    </Content>
  );
};
export default ClientProfile;
