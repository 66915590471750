import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TimerTextField } from 'api/sharedComponents/reactHookFormComponents';
import moment from 'antd/node_modules/moment';
import { CustomTimeSlotWrapper } from './style';

interface IProps {
  toggleTimeSlot: Function;
}

const CustomTimeSlot: React.FC<IProps> = ({ toggleTimeSlot }: IProps) => {
  const [startTimeSelected, setStartTimeSelected] = React.useState<boolean>(
    false
  );
  const [endTimeSelected, setEndTimeSelected] = React.useState<boolean>(false);
  const [customStartTime, setCustomStartTime] = React.useState<moment.Moment>();
  const [customStartTimeError, setCustomStartTimeError] = React.useState<
    boolean
  >(false);
  const [customEndTime, setCustomEndTime] = React.useState<moment.Moment>();
  const [customEndTimeError, setCustomEndTimeError] = React.useState<boolean>(
    false
  );

  const methods = useFormContext();
  const { startTime, endTime } = useWatch({
    control: methods.control,
    name: ['startTime', 'endTime']
  });

  useEffect(() => {
    if (
      customStartTime &&
      customEndTime &&
      !customStartTimeError &&
      !customEndTimeError &&
      customEndTime > customStartTime
    ) {
      const preferredTimes = {
        id: 1,
        from: customStartTime.format('HH:mm'),
        to: customEndTime.format('HH:mm')
      };
      toggleTimeSlot(preferredTimes);
    }
  }, [
    customStartTime,
    customEndTime,
    customStartTimeError,
    customEndTimeError
  ]);

  useEffect(() => {
    setCustomStartTimeError(false);
    if (customStartTime && customStartTime?.minutes() % 15 !== 0) {
      setCustomStartTimeError(true);
    }
  }, [customStartTime]);
  useEffect(() => {
    setCustomEndTimeError(false);
    if (customEndTime && customEndTime?.minutes() % 15 !== 0) {
      setCustomEndTimeError(true);
    }
  }, [customEndTime]);
  const startTimeHandler = (event: any) => {
    const time = event.target.value;
    setCustomStartTimeError(false);
    setCustomStartTime(moment(time, 'HH:mm'));
    //methods.setValue('startTime', customStartTime);
    setStartTimeSelected(true);
  };

  const endTimeHandler = (event: any) => {
    const time = moment(event.target.value, 'HH:mm');
    setCustomEndTime(moment(time, 'HH:mm'));
    //methods.setValue('endTime', customEndTime);
    setEndTimeSelected(true);
  };

  return (
    <CustomTimeSlotWrapper
      startTimeSelected={startTimeSelected}
      endTimeSelected={endTimeSelected}
    >
      <Grid.Column width={7}>
        <div
          className={
            startTimeSelected
              ? 'custom-time-slot selected-time-slot'
              : 'custom-time-slot'
          }
        >
          StartTime:
          <Controller
            name="startTime"
            control={methods.control}
            render={fieldProps => (
              <TimerTextField
                className={startTimeSelected ? 'selected-start-time' : ''}
                field={fieldProps}
                errors={methods.errors}
                value={startTime?.format('HH:mm')}
                onChange={startTimeHandler}
                InputLabelProps={{
                  shrink: true
                }}
                onClick={(event: any) => event.preventDefault()}
              />
            )}
          />
        </div>
        {customStartTimeError && (
          <div className="time-error">please enter 15 min increments</div>
        )}
        {customStartTime! > customEndTime! && (
          <div className="time-error">
            End time can not be before start time
          </div>
        )}
      </Grid.Column>
      <Grid.Column width={7}>
        <div
          className={
            endTimeSelected
              ? 'custom-time-slot selected-time-slot'
              : 'custom-time-slot'
          }
        >
          EndTime:
          <Controller
            name="endTime"
            control={methods.control}
            render={fieldProps => (
              <TimerTextField
                className={endTimeSelected ? 'selected-end-time' : ''}
                field={fieldProps}
                errors={methods.errors}
                value={endTime?.format('HH:mm')}
                onChange={endTimeHandler}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 900
                }}
              />
            )}
          />
        </div>
        {customEndTimeError && (
          <div className="time-error">please enter 15 min increments</div>
        )}
      </Grid.Column>
    </CustomTimeSlotWrapper>
  );
};
export default React.memo(CustomTimeSlot);
