import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import moment from 'antd/node_modules/moment';
import { MINUTES_INTERVAL_ERROR } from 'utils/constants/errors';
import { TimerTextField } from 'api/sharedComponents/reactHookFormComponents';
import { ClientDayAvailabilityOutput } from 'model/v2/clients';

interface IProps {
  time: Date;
  controlName: string;
  displayName: string;
  dayIndex: number;
  index: number;
  setClientDayAvailability: Dispatch<
    SetStateAction<Map<number, ClientDayAvailabilityOutput[]> | undefined>
  >;
  clientDayAvailability: Map<number, ClientDayAvailabilityOutput[]> | undefined;
  setIsvalidTime: Dispatch<SetStateAction<boolean>>;
}

const AvailabilityTimeForm: React.FC<IProps> = ({
  time,
  controlName,
  displayName,
  dayIndex,
  index,
  setClientDayAvailability,
  clientDayAvailability,
  setIsvalidTime
}) => {
  const { control, errors, setValue, setError, trigger } = useFormContext();
  const constrollerIndex = `${controlName}${index}`;
  const timeHandler = React.useCallback(
    async event => {
      const submittedTime = event.target.value;
      if (clientDayAvailability) {
        // Clone the current state to avoid mutating it
        const updatedAvailability = new Map(clientDayAvailability);
        if (updatedAvailability && updatedAvailability.has(dayIndex)) {
          const existingAvailability = updatedAvailability.get(dayIndex);
          const availabilityValue = existingAvailability![index];
          if (controlName === 'start')
            availabilityValue.startTime = submittedTime;
          if (controlName === 'end') availabilityValue.endTime = submittedTime;
          const startMoment = moment(availabilityValue.startTime, 'HH:mm');
          const endMoment = moment(availabilityValue.endTime, 'HH:mm');
          const isValid = await trigger(constrollerIndex);
          setIsvalidTime(true);
          if (!moment(submittedTime, 'HH:mm').isValid() || !isValid) {
            setError(constrollerIndex, {
              message: MINUTES_INTERVAL_ERROR,
              shouldFocus: true
            });
            setIsvalidTime(false);
            return;
          } else if (startMoment.isAfter(endMoment)) {
            setError(constrollerIndex, {
              message: 'End time must be after start time',
              shouldFocus: true
            });
            setIsvalidTime(false);
            return;
          }
          existingAvailability!.splice(index, 1, availabilityValue);
          updatedAvailability.set(dayIndex, existingAvailability!);
        }
        setClientDayAvailability(updatedAvailability);
      }
    },
    [
      setValue,
      setError,
      trigger,
      controlName,
      constrollerIndex,
      dayIndex,
      setClientDayAvailability,
      clientDayAvailability
    ]
  );

  return (
    <>
      <FormItem label={displayName}>
        <Controller
          name={constrollerIndex}
          control={control}
          error={errors}
          defaultValue={time}
          render={fieldProps => (
            <TimerTextField
              field={fieldProps}
              errors={errors}
              defaultValue={moment(time)
                .day(dayIndex)
                .format('HH:mm')}
              onChange={timeHandler}
              inputProps={{
                step: 900
              }}
            />
          )}
        />
      </FormItem>
    </>
  );
};

export default AvailabilityTimeForm;
