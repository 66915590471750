import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button } from 'lib/ui';
import { Radio } from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  PlusCircleFilled
} from '@ant-design/icons';
import { CalendarToolbar } from './MainCalendar.style';
import { dayMoment } from 'utils/format';
import { isTodayInRange } from 'utils/builders/calendar';

const Toolbar = ({ data, range, updateView, ...restProps }) => {
  const { hasAdd = false, options = {}, source } = restProps;
  const history = useHistory();
  const {
    localizer: { messages },
    label,
    onNavigate,
    view,
    date
  } = data;

  const day = dayMoment(date);

  const addAppointmentHandler = () => {
    history.push({
      pathname: '/appointments/new',
      state: { modalContentFor: source }
    });
  };
  const viewNamesGroup = toolbar => {
    const setUi = (func, val) => {
      func(val);
      // TODO: call updateView() unconditionally and remove dispatcher when main calendar migrates from redux to apollo cache
      if (updateView) updateView(val);
    };
    const {
      localizer: { messages },
      views,
      view,
      onView
    } = toolbar;
    if (views.length > 1) {
      return views.map(name => (
        <Radio.Button
          key={name}
          className={clsx({ active: view === name })}
          onClick={() => setUi(onView, name)}
        >
          {messages[name]}
        </Radio.Button>
      ));
    }
  };
  return (
    <CalendarToolbar>
      <div className="calendar-switch">
        <Radio.Group>{viewNamesGroup(data)}</Radio.Group>
      </div>
      <div className="calendar-nav">
        <div className="calendar-nav__today">
          <Radio.Button
            className={isTodayInRange(range) && 'active'}
            value="current"
            onClick={() => onNavigate('TODAY')}
          >
            {messages.today}
          </Radio.Button>
        </div>
        <Radio.Group>
          <Radio.Button value="prev" onClick={() => onNavigate('PREV')}>
            <CaretLeftOutlined />
          </Radio.Button>
          <label className="calendar-nav__title">
            {view === 'day' ? day : label}
          </label>
          <Radio.Button value="next" onClick={() => onNavigate('NEXT')}>
            <CaretRightOutlined />
          </Radio.Button>
        </Radio.Group>
      </div>

      {hasAdd && (
        <div className="calendar-add-new">
          <Button
            className="large"
            type="primary"
            size="large"
            onClick={addAppointmentHandler}
          >
            <PlusCircleFilled />
            Add new
          </Button>
        </div>
      )}
      {options.clientAvailabilityLastUpdated && (
        <span className="calendar-availability-update">
          Availability Last Updated:{' '}
          {options.isClientProfile && options?.auditHistory?.auditActionAt ? (
            <>
              <span style={{ marginLeft: '10px', display: 'block' }}>
                <div>
                  {' '}
                  <b>{options?.auditHistory?.auditActionAt}</b>
                </div>
                <div
                  style={{
                    maxWidth: '180px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  title={`${options?.auditHistory?.auditActionBy}`}
                >
                  <b>{options?.auditHistory?.auditActionBy}</b>
                </div>
              </span>
              <br></br>
            </>
          ) : (
            <strong>
              {moment().isSame(options.clientAvailabilityLastUpdated, 'day')
                ? 'Today'
                : moment(options.clientAvailabilityLastUpdated).format('LL')}
            </strong>
          )}
        </span>
      )}
    </CalendarToolbar>
  );
};

export default Toolbar;
