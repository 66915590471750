import React from 'react';
import moment from 'moment';
import ReactVisibilitySensor from 'react-visibility-sensor';
import ClientSearchProfilePicture from './ClientSearchProfilePicture';

const ClientListItem = ({ client, getClient }) => {
  return (
    <div
      className="appointment-sidebar__list-item"
      key={client.id}
      onClick={() =>
        getClient({
          variables: {
            id: client.id
          }
        })
      }
    >
      <ReactVisibilitySensor>
        <ClientSearchProfilePicture img={client.photo} alt={client.name} />
      </ReactVisibilitySensor>
      <div className="info-wrap">
        <p>{client.name}</p>
        <span>{moment.utc(client.dob).format('MM/DD/YYYY')}</span>
      </div>
    </div>
  );
};

export default ClientListItem;
