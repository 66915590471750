import React from 'react';
import { AuthTherapiesFrom } from '../AuthGroupForm.style';
import { Row } from 'antd';
import { FormItem, Input, Select } from 'views/containers/form';
import { Field, FieldArray } from 'formik';
import { Button } from 'lib/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getApptType } from 'utils/common';

const AuthTherapy = ({
  values,
  handleChange,
  apptTypes,
  apptTypesOptions,
  quantityPatternsOptions,
  keyForm,
  authPeriodOptions,
  authTherapiesArrayHelpers,
  setChanges,
  setFieldValue,
  errors,
  setFieldTouched,
  touched
}) => {
  const isLimitInvalid =
    !!errors?.authTherapies?.[keyForm]?.authLimit &&
    touched?.authTherapies?.[keyForm]?.authLimit;

  const isApptTypeInvalid =
    typeof errors?.authTherapies?.[keyForm]?.apptTypes === 'string';

  const handlePatternSelection = val => {
    if (
      values.authTherapies[keyForm].authPattern &&
      val !== values.authTherapies[keyForm].authPattern
    )
      setFieldValue(`authTherapies.${keyForm}.authLimit`, undefined);
  };

  const handleApptTypeSelection = title => {
    const apptType = getApptType({ title }, apptTypes);
    if (apptType) setAuthPattern(apptType.isClinical ? 'Visits' : 'Hours');
  };

  const setAuthPattern = (val = undefined) => {
    setFieldValue(`authTherapies.${keyForm}.authPattern`, val);
  };

  const refreshPatternSelection = index => {
    const selectedApptTypes = values.authTherapies[keyForm].apptTypes; /// remove the deleted one

    const therapyType = getApptType({ title: selectedApptTypes[0] }, apptTypes)
      ?.isClinical;

    if (therapyType === undefined || therapyType === null) {
      setAuthPattern();
      return;
    }

    let mixed = false;
    selectedApptTypes.forEach((title, currentIndex) => {
      if (!title || index === currentIndex) return;
      const currentType = getApptType({ title }, apptTypes).isClinical;
      mixed = currentType !== therapyType ? true : false;
    });

    mixed === true
      ? setAuthPattern()
      : setAuthPattern(therapyType ? 'Visits' : 'Hours');
  };

  const deleteAuthTherapy = index => {
    authTherapiesArrayHelpers.remove(index);
    setChanges(true);
  };

  const handleChangeField = (e, nameFiled) => {
    handleChange(e);
    setFieldTouched(nameFiled);
  };

  return (
    <AuthTherapiesFrom>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FieldArray
          name={`authTherapies.${keyForm}.apptTypes`}
          render={arrayHelpers => (
            <>
              <FormItem xs={16} sm={8}>
                {values.authTherapies[keyForm].apptTypes.map(
                  (apptType, index) => {
                    return (
                      <FormItem
                        key={index}
                        label="Appointment Type"
                        xs={16}
                        sm={24}
                      >
                        <Field
                          name={`authTherapies.${keyForm}.apptTypes.${index}`}
                          value={apptType}
                          key={index}
                          render={fieldProps => (
                            <Select
                              value={apptType}
                              placeholder="Appointment Type"
                              onSelect={handleApptTypeSelection}
                              className={
                                isApptTypeInvalid ? 'error-input' : undefined
                              }
                              options={apptTypesOptions.filter(
                                option =>
                                  option.value === apptType ||
                                  !values.authTherapies[
                                    keyForm
                                  ].apptTypes.includes(option.value)
                              )}
                              {...fieldProps}
                            />
                          )}
                        />
                        {index > 0 && (
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => {
                              arrayHelpers.remove(index);
                              setChanges(true);
                              refreshPatternSelection(index);
                            }}
                          />
                        )}
                      </FormItem>
                    );
                  }
                )}
                <div className={'error error-appt_type'}>
                  {isApptTypeInvalid
                    ? errors?.authTherapies?.[keyForm]?.apptTypes
                    : null}
                </div>
              </FormItem>

              <Button
                className="add_another"
                onClick={() => arrayHelpers.push(undefined)}
              >
                +add another appointment type
              </Button>
            </>
          )}
        />
        <FormItem xs={16} sm={16}>
          <Row type="flex" justify="start" align="bottom" gutter={16}>
            <FormItem label="Limit" xs={16} sm={4}>
              <Field
                name={`authTherapies.${keyForm}.authLimit`}
                render={fieldProps => (
                  <>
                    <Input
                      name={`authTherapies.${keyForm}.authLimit`}
                      className={isLimitInvalid ? 'error-input' : undefined}
                      value={values?.authTherapies?.[keyForm]?.authLimit}
                      placeholder="Limit"
                      onChange={e =>
                        handleChangeField(
                          e,
                          `authTherapies.${keyForm}.authLimit`
                        )
                      }
                    />
                  </>
                )}
              />
            </FormItem>
            <FormItem xs={16} sm={4}>
              <Field
                name={`authTherapies.${keyForm}.authPattern`}
                render={fieldProps => (
                  <Select
                    value={values?.authTherapies?.[keyForm]?.authPattern}
                    placeholder="Visits"
                    options={quantityPatternsOptions}
                    onSelect={handlePatternSelection}
                    disabled={true}
                    {...fieldProps}
                  />
                )}
              />
            </FormItem>
            <FormItem xs={16} sm={2}>
              <span>per</span>
            </FormItem>
            <FormItem xs={16} sm={7}>
              <Field
                name={`authTherapies.${keyForm}.authPeriod`}
                render={fieldProps => (
                  <Select
                    value={values?.authTherapies?.[keyForm]?.authPeriod}
                    placeholder="Auth Period"
                    options={authPeriodOptions}
                    {...fieldProps}
                  />
                )}
              />
            </FormItem>
            <FormItem xs={16} sm={6}>
              <span
                className="delete"
                onClick={() => deleteAuthTherapy(keyForm)}
              >
                Delete
              </span>
            </FormItem>
          </Row>
        </FormItem>
        <div className="vertical-line" />
      </Row>
    </AuthTherapiesFrom>
  );
};

export default AuthTherapy;
