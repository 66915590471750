import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useClientAvailability } from 'hooks/useClientAvailability';
import Content from 'views/components/ui/content';
import { ClientAvailabilityViewWrapper } from '../ClientAvailabilityView/style';
import { ClientDayAvailabilityOutput } from 'model/v2/clients';
import InputDayFilterRow from '../InputDayFilterRow';
import { useFormContext } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';
import { CLIENT_AVAILABLE_DAYS } from 'utils/constants/lists';
import { useLocation } from 'react-router';

interface IProps {
  clientId: number;
  setClientAvailabilityErrMsg: Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setAutoGeneratedResults: Dispatch<React.SetStateAction<boolean>>;
}
const InputClientAvailabilityView: React.FC<IProps> = ({
  clientId,
  setClientAvailabilityErrMsg,
  setAutoGeneratedResults
}) => {
  const methods = useFormContext();
  const Days: string[] = Object.values(CLIENT_AVAILABLE_DAYS);
  const {
    isLoadingClientAvailability,
    clientAvailabilityPerDay
  } = useClientAvailability(clientId);

  const [clientDayAvailability, setClientDayAvailability] = useState<
    Map<number, ClientDayAvailabilityOutput[]>
  >();
  // Function to convert time strings to Date objects
  const parseTime = (timeString: string): Date => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0); // Optional: Set seconds to 0 if needed
    return date;
  };

  useEffect(() => {
    // Sort function for start times
    const sortByStartTime = (a: string, b: string): number => {
      const startTimeA = parseTime(a);
      const startTimeB = parseTime(b);
      return startTimeA.getTime() - startTimeB.getTime();
    };

    if (clientAvailabilityPerDay) {
      const daysMap = new Map<number, ClientDayAvailabilityOutput[]>();
      for (let i = 1; i < 7; i++) {
        if (clientAvailabilityPerDay.has(i)) {
          const availabilities = clientAvailabilityPerDay
            ?.get(i)!
            .sort((a, b) => sortByStartTime(a.startTime, b.startTime));
          daysMap.set(i, availabilities);
        } else {
          daysMap.set(i, [
            { startTime: '00:00', endTime: '00:00', dayOfWeek: i, location: 7 }
          ]);
        }
      }
      setClientDayAvailability(daysMap);
    }
  }, [clientAvailabilityPerDay]);

  useEffect(() => {
    setClientAvailabilityErrMsg(
      methods.errors.clientAvailabilityDayFilter?.message
    );
  }, [methods.errors.clientAvailabilityDayFilter, setClientAvailabilityErrMsg]);

  useEffect(() => {
    methods.trigger('clientAvailabilityDayFilter');
  }, [isLoadingClientAvailability, methods]);
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location?.search), [
    location
  ]);
  useEffect(() => {
    if (clientDayAvailability && clientDayAvailability.size > 0) {
      const clientAvailabilityDayFilter = Array.from(clientDayAvailability)
        .map(([dayIndex, clientAvailability]) => ({
          dayIndex,
          preferredTimes: clientAvailability
            .filter(
              dayAvailability =>
                dayAvailability.startTime !== '00:00' &&
                dayAvailability.endTime !== '00:00'
            )
            .map(dayAvailability => ({
              from: dayAvailability.startTime,
              to: dayAvailability.endTime,
              location: dayAvailability.location
            }))
        }))
        .filter(day => day.preferredTimes.length > 0);
      if (!isLoadingClientAvailability) {
        methods.setValue(
          'clientAvailabilityDayFilter',
          clientAvailabilityDayFilter
        );
        const clientParam = params.get('client');
        if (clientParam !== null && Number(clientParam) === clientId) {
          setTimeout(() => {
            setAutoGeneratedResults(true);
          }, 1000);
        }
      }
    }
  }, [
    clientDayAvailability,
    setClientDayAvailability,
    methods,
    isLoadingClientAvailability
  ]);

  const selectAllTimeSlots = useCallback(() => {
    if (clientDayAvailability) {
      const daysMap = new Map<number, ClientDayAvailabilityOutput[]>();
      for (let i = 1; i < 7; i++) {
        daysMap.set(i, [
          { startTime: '07:00', endTime: '19:00', dayOfWeek: i, location: 7 }
        ]);
      }
      setClientDayAvailability(daysMap);
    }
  }, [clientDayAvailability, setClientDayAvailability]);
  return (
    <Content
      loading={isLoadingClientAvailability}
      data={clientAvailabilityPerDay}
    >
      {() => (
        <ClientAvailabilityViewWrapper>
          <Grid columns={2}>
            <Grid.Row style={{ padding: '0px' }}>
              <Grid.Column width={8}></Grid.Column>
              <Grid.Column width={3}>
                <div className="select-all-btn" onClick={selectAllTimeSlots}>
                  Select All
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {clientDayAvailability &&
            Array.from(clientDayAvailability!).map(([key, value]) => (
              <div key={key}>
                <div className="day-title">{Days[key]}</div>
                {value!.map((availability, index) => (
                  <InputDayFilterRow
                    availability={availability}
                    availabilityKey={key}
                    index={index}
                    count={value?.length!}
                    setClientDayAvailability={setClientDayAvailability}
                    clientDayAvailability={clientDayAvailability}
                  />
                ))}
              </div>
            ))}
        </ClientAvailabilityViewWrapper>
      )}
    </Content>
  );
};

export default InputClientAvailabilityView;
