import React, { useState } from 'react';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import Snackbar from '../../assets/img/SnackBar.png';
import { Menu } from 'antd';
import { WaitList_Side_Menu } from 'views/components/waitlistPage/waitlist.styled';
import { ReactComponent as Logo } from '../../assets/img/scissors.svg';
import { ReactComponent as PatientLogo } from '../../assets/img/patient.svg';
import { ReactComponent as Subsession } from '../../assets/img/subsession.svg';
import ArrowBackFilled from '../../assets/img/ArrowBackFilled.svg';

interface props {
  waitListCount: number;
}

const SideMenuBar: React.FC<props> = ({ waitListCount }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <WaitList_Side_Menu>
      <div className="Main_Menu">
        <div className="main_head_cont">
          <div
            className="SideMenu_Expandable"
            onClick={() => toggleCollapsed()}
          >
            {collapsed ? (
              <img src={Snackbar}></img>
            ) : (
              <div className="expandable_Content">
                <div>
                  <span className="Client_List">Client Lists</span>
                  <p>DT Team</p>
                </div>
                <div>
                  <img src={ArrowBackFilled}></img>
                </div>
              </div>
            )}
          </div>
          <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            inlineCollapsed={collapsed}
          >
            <Menu.Item className="Waitlist" key="1" icon={<CalendarOutlined />}>
              Waitlist
            </Menu.Item>
            <Menu.Item key="2" icon={<Logo />}>
              Lost Services
            </Menu.Item>
            <Menu.Item key="3" icon={<PatientLogo />}>
              New Patients
            </Menu.Item>
            <Menu.Item key="sub1" icon={<Subsession />}>
              Sub Sessions
            </Menu.Item>
            <Menu.Item key="sub2" icon={<ClockCircleOutlined />}>
              Preference Slot change
            </Menu.Item>
          </Menu>
        </div>
        {!collapsed && (
          <>
            <div className="waitList_schedule">
              <div className="WaitList_Count">Total Waitlist</div>
              <div className="WaitList_Count">{waitListCount}</div>
            </div>
            <div className="waitList_schedule">
              <div>Can be Scheduled</div>
              <div>N/A</div>
            </div>
            <div className="waitList_schedule">
              <div>Partial Match</div>
              <div>N/A</div>
            </div>
            <div className="waitList_schedule">
              <div>Cannot be Scheduled</div>
              <div>N/A</div>
            </div>
          </>
        )}
      </div>
    </WaitList_Side_Menu>
  );
};

export default SideMenuBar;
