import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import {
  AdditionalProviderFieldWrapper,
  AppointmentTypeFormWrapper
} from './style';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { SelectWithDelete } from './../../../lib/ui/select/selectWithDelete';
import { alphabeticalSort } from 'utils/sort';
import { formatAttendeeNameV2 } from 'utils/format';
import { IProvider, IOption } from 'model/v2';
import { useRemoveProviderFromCalendar } from '../FormPhantomEvents/CustomPhantomHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { plannerContext } from '..';
import { SIDEBAR_ACTIONS } from '../AppointmentSidebar';
interface Props {
  providersMap: Map<string | number, IProvider>;
  action: string;
  currentProviderId: number | undefined;
  additionalProvidersList: IProvider[];
  setAdditionalProvidersList: React.Dispatch<React.SetStateAction<IProvider[]>>;
  maxAdditionalProviders?: number;
}
const ProviderSelect: React.FC<Props> = ({
  providersMap,
  action,
  currentProviderId,
  additionalProvidersList,
  setAdditionalProvidersList,
  maxAdditionalProviders = 10
}: //
Props) => {
  const mapStaffList = (providersMap: Map<string | number, IProvider>) => {
    let array: IOption[] = [];
    providersMap.forEach(val => {
      if (val.id === currentProviderId) return;
      array.push({
        label: formatAttendeeNameV2(
          val.name,
          val.clinic?.abbreviation,
          val.speciality?.title
        ),
        value: val.id,
        displayName: val.displayName
      });
    });
    if (additionalProvidersList.length > 0) {
      //filtered filters out providers already in the 'additional providers list'.
      const filtered = array.filter(
        provider =>
          !additionalProvidersList.some(addedProvider => {
            return (
              provider.value === addedProvider.id ||
              provider.value === currentProviderId
            );
          })
      );
      return filtered.sort((a, b) => alphabeticalSort(a.label, b.label));
    } else {
      return array.sort((a, b) => alphabeticalSort(a.label, b.label));
    }
  };
  const { removeProviderFromCalendar } = useRemoveProviderFromCalendar();
  const methods = useFormContext();
  const staffOptionsList: IOption[] = React.useMemo(
    () => mapStaffList(providersMap),
    [providersMap, currentProviderId, additionalProvidersList]
  );
  const [showSelect, setShowSelect] = React.useState<boolean>(false);
  const { isLoading } = useContext(plannerContext);
  const isEditMode = action === SIDEBAR_ACTIONS.EDIT;

  const handleSelect = (providerId: number) => {
    const newProvider = providersMap.get(providerId);
    if (newProvider) {
      setAdditionalProvidersList((prev: IProvider[]) => {
        return [...prev, newProvider];
      });
    }
    setShowSelect(!showSelect);
  };
  const handleSelectChange = (id: number) => {
    methods.setValue('Admin_ProviderId', id);
  };

  const handleRemoveProvider = (provider: IProvider, idx: number) => {
    setAdditionalProvidersList((prev: IProvider[]) => {
      const result = prev.filter((_curr, i) => {
        return i !== idx;
      });
      return result;
    });
    removeProviderFromCalendar(provider);
  };

  const renderAdditionalProvidersString = () => {
    const additionalProvidersString = (
      <div>
        Provider &nbsp;
        <span style={{ color: '#6f42f5' }}>
          (Add up to {maxAdditionalProviders} providers)
        </span>
      </div>
    );
    return isEditMode ? 'Provider' : additionalProvidersString;
  };

  return (
    <AppointmentTypeFormWrapper>
      <FormItem optional={false} label={renderAdditionalProvidersString()}>
        <Controller
          name={'provider.id'}
          control={methods.control}
          render={fieldProps => (
            // <></>
            <Select
              showSearch
              onSelect={handleSelectChange}
              filterOption={(input: string, option: any) => {
                const Children =
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0;
                const displayNameMatches = option.displayName
                  ? option.displayName
                      .toLowerCase()
                      .trim()
                      .indexOf(input.toLowerCase().trim()) >= 0
                  : false;
                return Children || displayNameMatches;
              }}
              className="appt-category-type"
              placeholder="Select Provider"
              options={staffOptionsList}
              field={{
                ...fieldProps,
                value: currentProviderId
                  ? providersMap.get(currentProviderId)?.name
                  : fieldProps.value
              }}
              errors={methods.errors}
              disabled={isEditMode || isLoading}
              listHeight={162}
            />
          )}
        />
      </FormItem>
      <ul className="additionalProvidersList">
        {additionalProvidersList ? (
          additionalProvidersList.map((provider, idx) => {
            return (
              <div className="selectWithDeleteStyle" key={idx}>
                <AdditionalProviderFieldWrapper>
                  <li className="providerListItem">{provider.name}</li>
                </AdditionalProviderFieldWrapper>
                <div className={isLoading ? 'disabled trashIcon' : 'trashIcon'}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={() => handleRemoveProvider(provider, idx)}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </ul>
      {showSelect ? (
        <div className="selectWithDeleteStyle">
          <SelectWithDelete
            showSearch
            filterOption={(input: string, option: any) => {
              const Children =
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0;
              const displayNameMatches = option.displayName
                ? option.displayName
                    .toLowerCase()
                    .trim()
                    .indexOf(input.toLowerCase().trim()) >= 0
                : false;
              return Children || displayNameMatches;
            }}
            className="appt-category-type"
            placeholder="Select Provider"
            options={staffOptionsList}
            onChange={handleSelect}
            errors={methods.errors}
            listHeight={162}
          />
          <div className="trashIcon">
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ marginBottom: '10px' }}
              onClick={() => {
                setShowSelect(!showSelect);
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {!isEditMode &&
        additionalProvidersList.length < maxAdditionalProviders - 1 &&
        !Boolean(showSelect) && (
          <div>
            <span
              onClick={() => setShowSelect(!showSelect)}
              className={
                isLoading
                  ? 'disabledAddAdditionalProviderText'
                  : 'addAdditionalProvidersText'
              }
            >
              + Add Provider
            </span>
          </div>
        )}
    </AppointmentTypeFormWrapper>
  );
};
export default React.memo(ProviderSelect);
