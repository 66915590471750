import * as colors from 'utils/constants/theme';
import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  .react-calendar {
    z-index: 90;
    font-size: 10px;
    width: 213px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d8d8d8;
    height: 235px;
    background-color: #fff;
    .react-calendar__navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none !important;
      }
      .react-calendar__navigation__arrow {
        color: ${colors.PURPLE};
        min-width: unset;
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0 9px;
        &:hover {
          background-color: transparent;
          opacity: 0.6;
        }
        &:focus {
          background-color: unset;
        }
      }
      .react-calendar__navigation__label {
        text-align: center;
        background-color: transparent;
        &:disabled {
          background-color: #fff;
        }
        span {
          font-family: 'lato';
          font-size: 12px;
          font-weight: bold;
          color: #415766;
          strong {
            display: block;
            font-size: 18px;
            font-weight: bold;
            color: #21303a;
          }
        }
      }
    }
    .react-calendar__viewContainer {
      padding: 0 10px;
      height: calc(100% - 44px);
      .react-calendar__month-view {
        height: 100%;
        > div {
          height: 100%;
          display: unset !important;
          align-items: unset !important;
          > div {
            height: 100%;
            .react-calendar__month-view__weekdays {
              border-bottom: 1px solid #d8d8d8;
              padding: 4px 0 6.5px 0;
              margin-bottom: 2px;
              .react-calendar__month-view__weekdays__weekday {
                padding: 0;
                abbr {
                  text-decoration: none;
                  font-family: 'Lato';
                  font-size: 7.1px;
                  font-weight: bold;
                  letter-spacing: 0.11px;
                  color: #415766;
                  cursor: default;
                }
              }
            }
            .react-calendar__month-view__days {
              height: calc(100% - 24.5px);
              .react-calendar__month-view__days__day {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                font-family: Lato;
                font-weight: bold;
                font-size: 9.8px;
                color: #111111;
                border-radius: 50%;
                &.react-calendar__month-view__days__day--neighboringMonth {
                  color: #e1e1e1;
                }
                &.react-calendar__tile--active {
                  color: #fff;
                  background-color: #6f4bf1;
                  &:hover,
                  &:focus {
                    color: #fff;
                    background-color: #6f4bf1;
                  }
                }
                &:hover,
                &:focus {
                  color: #111111;
                  background-color: #f2f4f5;
                }
              }
            }
          }
        }
      }
    }
  }
`;
