import { FormError } from 'api/sharedComponents/reactHookFormComponents/error';
import { useGetStateClinicsHook } from 'hooks/GetStateClinicsHook';
import { Checkbox, CheckboxGroup } from 'lib/ui/checkbox';
import { LibSelect } from 'lib/ui/select/select.style';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DEFAULT_TELEHEALTH } from 'utils/constants/default';
import { TelehealthWrapper } from './style';

interface Props {
  telehealthLink?: string[];
}

const TelehealthSelect: React.FC<Props> = ({ telehealthLink }: Props) => {
  const { errors, control } = useFormContext();
  const stateToClinicsMap = useGetStateClinicsHook();

  const states = useMemo(() => Array.from(stateToClinicsMap.keys()), [
    stateToClinicsMap
  ]);

  const getSelectedClinics = () => {
    const result: string[] = [];
    telehealthLink?.forEach((item: string) => {
      if (!item.includes(DEFAULT_TELEHEALTH.PREFIX)) {
        result.push(item);
      } else {
        const state = item.split(' ')[1];
        const stateClincs = stateToClinicsMap.get(state);
        if (stateClincs) result.push(...stateClincs);
      }
    });
    return result;
  };

  const [selectedClinics, setSelectedClinics] = useState<string[]>(
    getSelectedClinics()
  );

  const onDeselect = (item: any) => {
    if (!selectedClinics.includes(item)) {
      const state = item.split(' ')[1];
      const stateClincs = stateToClinicsMap.get(state);
      setSelectedClinics(
        selectedClinics.filter(clinic => !stateClincs?.includes(clinic))
      );
    } else {
      setSelectedClinics(selectedClinics.filter(clinic => clinic !== item));
    }
    const newTelehealth = telehealthLink?.filter(
      (clinic: string) => clinic !== item
    );
    control.setValue('telehealth', newTelehealth, { shouldDirty: true });
  };

  const checkStateClinics = (event: any, state: string) => {
    const stateClinics = stateToClinicsMap.get(state);
    if (!stateClinics) return;
    const filteredSelectedClinics = selectedClinics.filter(
      clinic => !stateClinics.includes(clinic)
    );
    if (event.target.checked) {
      setValues([...filteredSelectedClinics, ...stateClinics]);
    } else {
      setValues(filteredSelectedClinics);
    }
  };

  const checkClinic = (event: any, state: string) => {
    const stateClinics = stateToClinicsMap.get(state);
    const filteredSelectecClinics = selectedClinics.filter(
      clinic => !stateClinics?.includes(clinic)
    );
    setValues([...filteredSelectecClinics, ...event]);
  };

  const setValues = (clinics: string[]) => {
    const telehealth: string[] = [];
    for (const [state, stateClinics] of stateToClinicsMap) {
      const selectedStateClinics = clinics.filter(clinic =>
        stateClinics.includes(clinic)
      );
      if (selectedStateClinics.length === stateClinics.length) {
        telehealth.push(
          DEFAULT_TELEHEALTH.PREFIX + state + DEFAULT_TELEHEALTH.SUFFIX
        );
      } else {
        telehealth.push(...selectedStateClinics);
      }
    }

    control.setValue('telehealth', telehealth, { shouldDirty: true });
    setSelectedClinics(clinics);
  };

  const isStateChecked = (state: string) =>
    telehealthLink?.includes(
      DEFAULT_TELEHEALTH.PREFIX + state + DEFAULT_TELEHEALTH.SUFFIX
    );

  return (
    <>
      <LibSelect
        mode="multiple"
        placeholder="Licensed For"
        value={telehealthLink}
        onDeselect={onDeselect}
        dropdownRender={() => (
          <TelehealthWrapper>
            {states.map(state => (
              <div key={state}>
                <Checkbox
                  checked={isStateChecked(state)}
                  onChange={(event: any) => checkStateClinics(event, state)}
                >
                  {state}
                </Checkbox>
                <CheckboxGroup
                  options={stateToClinicsMap.get(state)}
                  name="telehealth"
                  value={selectedClinics}
                  onChange={(event: any) => checkClinic(event, state)}
                ></CheckboxGroup>
              </div>
            ))}
          </TelehealthWrapper>
        )}
      ></LibSelect>
      <FormError name="telehealth" errors={errors} />
    </>
  );
};

export default React.memo(TelehealthSelect);
