import moment from 'moment';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';

export function nextIntervalStart(date, interval = 'week') {
  return moment(date)
    .add(1, interval)
    .startOf(interval);
}

export function nextIntervalEnd(date, interval = 'week') {
  return moment(date)
    .add(1, interval)
    .endOf(interval);
}

export function prevIntervalStart(date, interval = 'week') {
  return moment(date)
    .subtract(1, interval)
    .startOf(interval);
}

export function prevIntervalEnd(date, interval = 'week') {
  return moment(date)
    .subtract(1, interval)
    .endOf(interval);
}

export function durationToMinutesDuration(duration) {
  return duration && duration.diff(moment(duration).startOf('day'), 'minutes');
}

export function calculateEndDate(startDate, duration) {
  return startDate.clone().add(duration, 'minutes');
}

export function minutesDurationToDuration(duration) {
  return (
    duration &&
    moment()
      .startOf('day')
      .add(duration, 'minutes')
  );
}

export function checkMinutesInterval(time, eventTypeName) {
  const momentValue = moment(time);
  const minutesIntervals = [45, 30, 15, 0];

  if (
    getFeatureAvailability(FEATURES.FIVE_MINUTE_SCHEDULING) &&
    momentValue.isValid() &&
    eventTypeName === EVENT_TYPES.ABA
  )
    return (
      typeof time !== 'undefined' &&
      momentValue.isValid() &&
      momentValue.minutes() % 5 === 0
    );

  return (
    minutesIntervals.includes(momentValue.minutes()) ||
    time === undefined ||
    !moment(time).isValid()
  );
}

export function isSelectedEvent(selected, event) {
  return (
    selected &&
    event &&
    selected.providerId &&
    event.providerId &&
    selected.providerId === event.providerId &&
    moment(selected.start).isSame(event.start) &&
    moment(selected.end).isSame(event.end)
  );
}
