import React from 'react';
import { Upload as LibUpload } from 'lib/ui/upload';
import * as PropTypes from 'prop-types';

const Upload = props => {
  const { field, children, onChange, ...restProps } = props;
  const onChangeValue = value => {
    // because Formik expects normal Event with normal target, not single value
    const event = {
      target: {
        name: field.name,
        value
      }
    };
    field.onChange(event);
  };

  return (
    <LibUpload onChange={onChangeValue} {...restProps}>
      {children}
    </LibUpload>
  );
};

Upload.propTypes = {
  field: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  onChange: PropTypes.func
};

Upload.defaultProps = {
  children: null
};

export default Upload;
export { Upload };
