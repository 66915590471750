import React from 'react';
import { Row } from 'antd';
import { FormItem } from 'views/containers/form';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import {
  RadioGroup,
  Timepicker
} from 'api/sharedComponents/reactHookFormComponents';

const locationTypeOptions = mapToOption(LOCATION_TYPE_LIST);
function AppointmentLocDurProv({ disabledFormFields, client }) {
  const { control } = useFormContext();
  const isClinical = useWatch({
    control,
    name: 'isClinical'
  });

  return (
    <Row type="flex" justify="start" align="top" gutter={16}>
      <FormItem label="Location Type" xs={16} sm={9}>
        <Controller
          name="locationType"
          control={control}
          render={fieldProps => (
            <RadioGroup
              options={locationTypeOptions}
              field={fieldProps}
              disabled={disabledFormFields?.locationType}
            />
          )}
        />
      </FormItem>
      <FormItem label="Duration" xs={16} sm={3}>
        <Controller
          name="duration"
          control={control}
          render={fieldProps => (
            <Timepicker
              placeholder="Min"
              minuteStep={5}
              popupStyle={{
                minWidth: 115
              }}
              format="HH:mm"
              disabled={
                disabledFormFields?.duration ||
                isClinical ||
                isClinical === null
              }
              field={fieldProps}
            />
          )}
        />
      </FormItem>
    </Row>
  );
}

export default React.memo(AppointmentLocDurProv);
