import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
const MetaFieldsForm: React.FC = () => {
  const methods = useFormContext();
  return (
    <>
      <Controller name="id" control={methods.control} render={() => <></>} />
      <Controller
        name="master.id"
        control={methods.control}
        render={() => <></>}
      />
      {/* <Controller
        name="authorizedTherapyID"
        control={methods.control}
        render={() => <></>}
      /> */}
    </>
  );
};
export default React.memo(MetaFieldsForm);
