import {
  IClinic,
  IProviderSpecialty,
  IAppointmentType,
  IWorkingHours
} from 'model/v2';
import { IProviderCapacity } from './providerCapacity';
import { CorticaProgram } from './corticaPrograms';

export enum Provider {
  id = 'id',
  name = 'name',
  firstName = 'firstName',
  lastName = 'lastName',
  displayName = 'displayName',
  speciality = 'speciality',
  mobilePhone = 'mobilePhone',
  email = 'email',
  appointmentTypes = 'appointmentTypes',
  clinic = 'clinic',
  crId = 'crId',
  athenaId = 'athenaId',
  telehealth = 'telehealth',
  status = 'status',
  employment = 'employment',
  capacity = 'capacity',
  isObservable = 'isObservable',
  isTestProvider = 'isTestProvider',
  msId = 'msId',
  rampingPercentage = 'rampingPercentage',
  assistantsInput = 'assistantsInput',
  allowSelfScheduling = 'allowSelfScheduling',
  telehealthLinks = 'telehealthLinks',
  gender = 'gender',
  petAllergies = 'petAllergies',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  corticaPrograms = 'corticaPrograms',
  salesForceId = 'salesForceId',
  transportation = 'transportation'
}
export interface IProvider {
  id?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  email?: string;
  name?: string;
  mobilePhone?: string;
  crId?: number;
  athenaId?: number;
  telehealth?: string[];
  isActive?: boolean;
  clinic?: IClinic;
  msId?: string;
  speciality?: IProviderSpecialty;
  appointmentTypes?: IAppointmentType[];
  workingHours?: IWorkingHours[];
  status?: string;
  employment?: string;
  isObservable?: boolean;
  isTestProvider?: boolean;
  transportation?: boolean;
  providerCapacity?: IProviderCapacity;
  assistantsInput?: number[];
  telehealthLinks?: any;
  allowSelfScheduling?: boolean;
  zipCode?: number;
  city?: string;
  petAllergies?: [number] | undefined;
  gender?: string;
  address?: providerAddress;
  corticaPrograms?: CorticaProgram[];
}

interface providerAddress {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  id?: number;
}

export interface IProviderSingle {
  id: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  email?: string;
  name?: string;
  mobilePhone?: string;
  crId?: number;
  athenaId?: number;
  telehealth?: string[];
  isActive?: boolean;
  clinic?: IClinic;
  msId?: string;
  speciality?: IProviderSpecialty;
  appointmentTypes?: IAppointmentType[];
  workingHours?: IWorkingHours[];
  status?: string;
  employment?: string;
  isObservable?: boolean;
  isTestProvider?: boolean;
  providerCapacity?: IProviderCapacity;
  assistantsInput?: number[];
  allowSelfScheduling?: boolean;
}

export interface IEditProviderForm
  extends Omit<
    IProvider,
    | 'appointmentTypes'
    | 'clinic'
    | 'speciality'
    | 'isObservable'
    | 'isTestProvider'
    | 'assistantsInput'
    | 'telehealthLinks'
    | 'allowSelfScheduling'
    | 'addresses'
    | 'gender'
    | 'corticaPrograms'
    | 'transportation'
  > {
  appointmentTypes: string[];
  clinic: string;
  speciality: string;
  capacity?: number;
  isObservable?: boolean;
  isTestProvider?: boolean;
  transportation?: boolean;
  assistantsInput?: number[];
  telehealthLinks?: any;
  allowSelfScheduling?: number;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: any;
  addressId?: number;
  gender?: string;
  corticaPrograms?: string[];
}

export enum PROVIDER_STATUS_LIST {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ACCOMMODATION = 'Accommodation'
}

export enum PROVIDER_EMPLOYMENT_LIST {
  FULL_TIME_SALARY = 'Full Time Salary',
  FULL_TIME_HOURLY = 'Full Time Hourly',
  PART_TIME_HOURLY = 'Part Time Hourly'
}

export enum PROVIDER_SPECIALITY_LIST {
  BCBA = 'BCBA',
  ABA_SUPERVISOR = 'ABA Supervisor',
  BEHAVIOR_INTERVENTIONIST_ASSISTANT_SUPERVISOR = 'Behavior Interventionist Assistant Supervisor'
}

export const SUPERVISOR_SPECIALITY_LIST_ID: Record<string, number[]> = {
  DEV: [3, 13, 10],
  STAGING: [3, 13, 10],
  UAT: [11, 6, 15],
  PROD: [11, 6, 15]
};
