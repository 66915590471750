import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { ICalendarFilterOption } from 'model/calendar/filters';
import { mapProgramsToOptions } from 'utils/mappers/form';
import CalendarFilter from '../../../ui/calendarFilter';

interface Props {
  programsList: any;
  handleSearchedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  checkedPrograms: ICalendarFilterOption[];
  setCheckedPrograms: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  setHasData: Dispatch<SetStateAction<boolean>>;
}

const ProgramFilter = ({
  programsList,
  handleSearchedValues,
  checkedPrograms,
  setCheckedPrograms,
  setHasData
}: Props) => {
  const programs = useMemo(
    () => (programsList ? mapProgramsToOptions(programsList) : []),

    [programsList]
  );

  const searchedPrograms = useMemo(() => {
    const searchedValues = handleSearchedValues(programs);

    return searchedValues;
  }, [handleSearchedValues, programs]);

  useEffect(() => {
    searchedPrograms?.length === 0 ? setHasData(false) : setHasData(true);
  }, [searchedPrograms, setHasData]);

  return (
    <>
      <CalendarFilter
        setCheckedList={setCheckedPrograms}
        checkedList={checkedPrograms}
        listOptions={searchedPrograms}
        length={programs.length}
        title="Program"
      />
    </>
  );
};

export default React.memo(ProgramFilter);
