import styled from 'styled-components';
import { ACTIVE_URL } from 'utils/constants/theme';
import * as colors from 'utils/constants/theme';

export const HeaderNav = styled.div`
  .navbar {
    background-color: #fff;
    border-bottom: 1px solid #c7c7cc;
  }
  .close-button {
    height: 15px;
    width: 15px;
    background-color: ${colors.WHITE} !important;
  }
  .inner-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .closeBtn {
    margin-bottom: 2px;
  }
  .inner-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
      display: none;
    }
  }
  .fixed-bar {
    background-color: #fff;
    border-bottom: 1px solid #c7c7cc;
    position: fixed;
    z-index: 9999;
    width: -webkit-fill-available;
  }
  h2 {
    font-size: 26px;
    font-weight: 600;
  }
  .inner-title {
    display: flex;
    align-items: center;
  }
  .new-appointment {
    padding-left: 48px;
    button {
      font-size: 16px;
      &:not(:last-child) {
        margin-right: 11px;
      }
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    padding-left: 40px;
    p {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.17px;
      color: ${ACTIVE_URL};
      text-transform: capitalize;
      cursor: pointer;
    }
  }
  .avatar {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: center;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #c7c7cc;
  }
  .button-wrap {
    margin-right: -10px;
    button {
      min-width: 118px;
    }
    > * {
      margin: 0 10px;
    }
    span {
      &:hover {
        color: #40a9ff;
        transition: color 0.3s;
      }
    }
  }
  &.edit-availability {
    h2 {
      font-size: 28px;
    }
    .user-info {
      p {
        text-decoration: underline;
      }
    }
  }
`;
