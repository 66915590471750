import styled from 'styled-components';
import * as colors from 'utils/constants/theme';
export const ConfigurationFormWrapper = styled.div`
  display: flex;
  margin: 0 10px;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  .header {
    display: flex;
    align-items: baseline;
    padding-top: 10px;
    justify-content: space-between;
  }
  .close-button {
    height: 15px;
    width: 15px;
    background-color: ${colors.WHITE} !important;
  }
  .submit-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
  }
  .error {
    display: block;
    color: ${colors.RED};
  }
  .color-picker {
    margin-bottom: -1rem;
  }
  .sf-therapy-mapping-field {
    margin-top: 18px;
  }
`;
