/* eslint-disable no-constant-condition */
import { ICalendarResource } from 'model/calendar/filters';
import {
  IClient,
  IFormEvent,
  SidebarState,
  SingleSmartResultInterface
} from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import Content from 'views/components/ui/content';
import { useLazyQuery } from 'react-apollo';
import {
  SubstituteListWrapper,
  SubstituteWrapper
} from './SmartScheduleResult/Style';
import { FormProvider, useForm } from 'react-hook-form';
import { useClinicDataMap } from 'api/graphql/v2/hooks/clinics';
import { useApptTypesWithAdmin } from 'api/graphql/v2/hooks/appointmentTypes';
import SubstituteConfirmModal from '../AppointmentModals/SubstituteConfirmModal';
import { FIND_SUBSTITUTE_PROVIDERS_QUERY } from 'api/graphql/v2/queries/ProviderCallout';
import {
  MAKEUP_SESSION_APPT_IDS,
  MAKEUP_SESSION_SUB_APPT_IDS
} from 'utils/constants/appointmentsTypes';
import {
  useApptTypesMap,
  useRemoveProviderFromCalendar
} from '../FormPhantomEvents/CustomPhantomHooks';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import moment from 'moment';
import SingleSmartPhantomEvents from '../FormPhantomEvents/SingleSmartPhantomEvents';
import SubstituteMakeupSessionCard from './SmartScheduleResult/SubstituteMakeupSessionCard';
import { ALL_CANCELLATION_REASONS } from 'api/graphql/v2/queries/CancellationReasons';
import SubstitutePhantomEvents from '../FormPhantomEvents/SubstitutePhantomEvents';
interface Props {
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  appointmentCategory: string;
  setSmart: Dispatch<SetStateAction<boolean>>;
  setAppointmentCategory: Dispatch<SetStateAction<string>>;
  changeFormHandler: (val: IFormEvent) => void;
  isCustom: boolean;
  smart: boolean;
  currClient: IClient | undefined;
  setCurrClient: Dispatch<SetStateAction<IClient>>;
}
const FindSubstitute: React.FC<Props> = ({
  sidebarState,
  setPaginationArray,
  paginationArray,
  setVisible
}: Props) => {
  const event = sidebarState.event;
  const isDT = useMemo(
    () =>
      event?.appointmentType?.eventType?.name === APPOINTMENT_CATEGORY[1].value,
    [event?.appointmentType?.eventType?.name]
  );
  const isABA = useMemo(
    () =>
      event?.appointmentType?.eventType?.name === APPOINTMENT_CATEGORY[0].value,
    [event?.appointmentType?.eventType?.name]
  );
  const [substituteResults, setSubstituteResults] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const getMakeupSessionResultsMapping = useCallback(smartData => {
    let resultsData = smartData?.findSubstituteProviders;
    const makeupResults: any[] = [];
    resultsData.map((item: any) => {
      item.inPersonClinics = item.inPersonClinics.filter(
        (clinic: any) => event?.client?.clinic?.id === clinic.id
      );
      let allowedLocationsList: string[] = [];
      if (item.locations.isInClinic) {
        allowedLocationsList.push('in-clinic');
      }
      if (item.locations.isOffsite) {
        allowedLocationsList.push('off-site');
      }
      if (item.locations.isTelehealth) {
        allowedLocationsList.push('telehealth');
      }
      item.allowedLocations = allowedLocationsList;
      if (item.locations.isInClinic) {
        item.isInClinic = true;
        item.isOffsite = false;
        item.isTelehealth = false;
      } else if (item.locations.isTelehealth) {
        item.isInClinic = false;
        item.isOffsite = false;
        item.isTelehealth = true;
        item.telehealthLink = event?.telehealthLink;
      } else if (item.locations.isOffsite) {
        item.isInClinic = false;
        item.isOffsite = true;
        item.isTelehealth = false;
        item.locationCategory = event?.locationCategory;
        item.address = event?.address;
      }
      if (item.inPersonClinics.length > 0 || !item.locations.isInClinic)
        makeupResults.push(item);
    });
    let smartResults = {
      substituteResultCount: makeupResults?.length,
      sameProviderMakeupSessions: makeupResults
        .filter(result => result.provider.id === event?.provider?.id)
        .slice(0, 10),
      otherProviderMakeupSessions: makeupResults
        .filter(result => result.provider.id !== event?.provider?.id)
        .slice(0, 10)
    };
    return smartResults;
  }, []);

  const [
    getMakeupSessionsData,
    { loading: makeupSessionsLoading }
  ] = useLazyQuery(FIND_SUBSTITUTE_PROVIDERS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: substituteResultsData => {
      const mappedSmartResults = getMakeupSessionResultsMapping(
        substituteResultsData
      );
      setSubstituteResults(mappedSmartResults);
    }
  });
  useEffect(() => {
    const eventStartDate = moment(
      moment(event?.startDate).format('YYYY-MM-DD')
    );
    const eventEndDate = moment(moment(event?.startDate).format('YYYY-MM-DD'));
    let startDate = moment
      .utc(eventStartDate.add(1, 'days').startOf('day'))
      .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    let endDate = moment
      .utc(eventEndDate.add(14, 'days').endOf('day'))
      .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    const duration = moment(event?.endDate).diff(
      moment(event?.startDate),
      'minutes'
    );
    getMakeupSessionsData({
      variables: {
        eventTypeName: event?.appointmentType?.eventType?.name,
        clientId: event?.client?.id,
        appointmentTypeId:
          event?.appointmentType?.parent?.id || event?.appointmentType?.id,
        apptSubTypeId: event?.appointmentType?.parent?.id
          ? event?.appointmentType?.id
          : 0,
        startTime: startDate,
        endTime: endDate,
        providerId: event?.provider?.id,
        locationType: event?.locationType,
        eventId: event?.id,
        duration: duration,
        makeupSessions: true,
        eventStatus: event?.status
      }
    });
    doCancellationsReasonsRequest();
  }, []);
  //const { control, setValue } = useFormContext();
  const methods = useForm();
  const { providersMap, loadingClinics } = useClinicDataMap(
    sidebarState.action
  );

  const { apptTypes, loadingApptTypes } = useApptTypesWithAdmin();
  const [confirmModel, setConfirmModel] = React.useState(false);
  const handleCancel = React.useCallback(() => {
    setConfirmModel(!confirmModel);
  }, [confirmModel, setConfirmModel]);
  const [
    doCancellationsReasonsRequest,
    { data: cancellationReason, loading: loadingCancellationReason }
  ] = useLazyQuery(ALL_CANCELLATION_REASONS, {
    fetchPolicy: 'cache-and-network'
  });
  const [count, setCount] = React.useState<number>(1);
  const [selectedResult, setSelectedResult] = useState<
    SingleSmartResultInterface | undefined
  >();
  const appointmentTypeId =
    event?.appointmentType?.parent?.id || event?.appointmentType?.id;
  let appointmentSubTypeId = event?.appointmentType?.parent?.id
    ? event?.appointmentType?.id
    : undefined;

  console.log('[getSubstituteMakeUpPayload] apptTypes here new one', apptTypes);
  const appTypesMap = useApptTypesMap(apptTypes);
  console.log(
    '[getSubstituteMakeUpPayload] apptTypes here new one useApptTypesMap',
    appTypesMap
  );
  let isDirectSubstitute = false;
  let apptId = MAKEUP_SESSION_APPT_IDS[process.env.REACT_APP_STAGE!][
    appointmentTypeId!
  ]
    ? MAKEUP_SESSION_APPT_IDS[process.env.REACT_APP_STAGE!][appointmentTypeId!]
    : appointmentTypeId;
  appointmentSubTypeId =
    MAKEUP_SESSION_SUB_APPT_IDS[process.env.REACT_APP_STAGE!][
      event?.appointmentType?.id!
    ] || appointmentSubTypeId;
  const appointmentType = appTypesMap.get(apptId)!;
  const { removeAllProvidersFromCalendar } = useRemoveProviderFromCalendar();
  const removeUnselectedProviders = () => {
    removeAllProvidersFromCalendar();
  };
  let indexCount = -1;
  return (
    <SubstituteListWrapper>
      <Content
        loading={
          loadingClinics ||
          loadingApptTypes ||
          loadingCancellationReason ||
          makeupSessionsLoading
        }
        data={substituteResults}
      >
        {() => (
          <>
            {substituteResults &&
            substituteResults?.substituteResultCount > 0 ? (
              <>
                <FormProvider {...methods}>
                  <SubstitutePhantomEvents
                    providersMap={providersMap}
                    setPaginationArray={setPaginationArray}
                    paginationArray={paginationArray}
                    event={event!}
                    apptTypes={apptTypes}
                    isDirectSubstitute={isDirectSubstitute}
                  />
                  {selectedResult &&
                    apptId &&
                    selectedIndex !== undefined &&
                    substituteResults?.substituteResultCount > 0 && (
                      <SingleSmartPhantomEvents
                        providersMap={providersMap}
                        apptTypes={apptTypes}
                        client={event?.client!}
                        setPaginationArray={setPaginationArray}
                        paginationArray={paginationArray}
                        visibleResults={true}
                        selectedResult={selectedResult}
                        appointmentTypeId={apptId}
                        key={selectedResult.toString()}
                        appointmentSubTypeId={appointmentSubTypeId!}
                        transitionResults={
                          substituteResults.makeupSessionResults
                        }
                        selectedIndex={selectedIndex}
                      />
                    )}
                  <div className="p-flex">
                    <div className="div-flex"></div>{' '}
                    <p className="substitute-result-p">
                      Same Provider Makeup Sessions (
                      {substituteResults?.sameProviderMakeupSessions?.length})
                    </p>
                    {substituteResults?.sameProviderMakeupSessions.map(
                      (result: any) => {
                        indexCount++;
                        return (
                          <SubstituteMakeupSessionCard
                            appointmentTypeTitle={
                              apptTypes.find(it => it.id === appointmentTypeId)
                                ?.title || ''
                            }
                            result={result}
                            index={indexCount}
                            offsiteCategory={event?.locationCategory}
                            minHours={event?.duration!}
                            clientId={event?.client?.id}
                            isAba={isABA}
                            isDT={isDT}
                            hasSingleDT={true}
                            client={event?.client!}
                            setConfirmModel={setConfirmModel}
                            confirmModel={confirmModel}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            selectedAppointment={
                              apptTypes.find(it => it.id === apptId)?.title ||
                              ''
                            }
                            removeUnselectedProviders={
                              removeUnselectedProviders
                            }
                            setSelectedResult={setSelectedResult}
                            appTypesMap={appTypesMap}
                            setCount={setCount}
                            count={count}
                            event={event}
                            setVisible={setVisible}
                          />
                        );
                      }
                    )}
                    <p className="substitute-result-p">
                      Other Providers Makeup Sessions (
                      {substituteResults?.otherProviderMakeupSessions?.length})
                    </p>
                    {substituteResults?.otherProviderMakeupSessions.map(
                      (result: any) => {
                        indexCount++;
                        return (
                          <SubstituteMakeupSessionCard
                            appointmentTypeTitle={
                              apptTypes.find(it => it.id === appointmentTypeId)
                                ?.title || ''
                            }
                            result={result}
                            index={indexCount}
                            offsiteCategory={event?.locationCategory}
                            minHours={event?.duration!}
                            clientId={event?.client?.id}
                            isAba={isABA}
                            isDT={isDT}
                            hasSingleDT={true}
                            client={event?.client!}
                            setConfirmModel={setConfirmModel}
                            confirmModel={confirmModel}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            selectedAppointment={
                              apptTypes.find(it => it.id === apptId)?.title ||
                              ''
                            }
                            removeUnselectedProviders={
                              removeUnselectedProviders
                            }
                            setSelectedResult={setSelectedResult}
                            appTypesMap={appTypesMap}
                            setCount={setCount}
                            count={count}
                            event={event}
                            setVisible={setVisible}
                          />
                        );
                      }
                    )}
                    <div className="pd-b-10"></div>
                  </div>
                  {confirmModel && (
                    <SubstituteConfirmModal
                      onClose={handleCancel}
                      event={event}
                      cancellationReasons={
                        cancellationReason.allCancellationReasons
                      }
                      setVisible={setVisible}
                      appointmentType={appointmentType}
                      appTypesMap={appTypesMap}
                      isDirectSubstitute={isDirectSubstitute}
                      selectedResult={selectedResult}
                      providersMap={providersMap}
                    />
                  )}
                </FormProvider>
              </>
            ) : (
              <>
                <SubstituteWrapper>
                  <p className="red f-w-bold">
                    No substitutes available for exact date and time of this
                    appointment.
                  </p>
                  {isDT && (
                    <>
                      <p className="f-w-bold">
                        Before Cancelling this appointment, try:
                      </p>
                      <ul className="f-w-bold">
                        <li> Swapping for another type of DT appointment</li>
                      </ul>
                    </>
                  )}
                  <p className="f-w-bold">
                    Click the X to go back to the planner.{' '}
                  </p>
                </SubstituteWrapper>
              </>
            )}
          </>
        )}
      </Content>
    </SubstituteListWrapper>
  );
};
export default React.memo(FindSubstitute);
