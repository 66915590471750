import gql from 'graphql-tag';

export const EDIT_CARE_PLAN = gql`
  mutation($clientID: ID!, $carePlan: CarePlanInput!) {
    updateCarePlan(clientID: $clientID, carePlan: $carePlan) {
      statusCode
    }
  }
`;

export const ADD_AXON_CARE_PLAN_NOTES = gql`
  mutation($note: AxonCarePlanNotesInput!) {
    addAxonCarePlanNotes(data: $note) {
      id
      note
      actionBy
      actionAt
      createdAt
    }
  }
`;

export const UPDATE_CARE_PLAN = gql`
  mutation($data: CarePlanSalesForceUpdateInput!) {
    updateCarePlan(data: $data)
  }
`;
