import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PROVIDER_MEMBER } from 'api/graphql/v2/queries/Providers';
import React, { useEffect } from 'react';
import Content from 'views/components/ui/content';
import Breadcrumbs from 'views/components/ui/profile/Breadcrumbs';
import Header from '../../../../pages/provider/profile/header';
import StaffTabs from './tabs';

const ProviderProfile = ({ match }) => {
  const id = match.params.id;
  const [ProviderData, setProviderData] = React.useState();
  const [GetSelectedProviderData, { loading, error }] = useLazyQuery(
    GET_PROVIDER_MEMBER,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: Number(id)
      },
      onCompleted: data => {
        setProviderData(data);
      }
    }
  );

  useEffect(() => {
    console.log('Appt types use effect');
    GetSelectedProviderData();
  }, [GetSelectedProviderData]);

  return (
    <Content loading={loading} error={error} data={ProviderData}>
      {() => (
        <>
          <Breadcrumbs userType="providers" user={ProviderData.provider} />
          <Header provider={ProviderData.provider} />
          <StaffTabs provider={ProviderData.provider} />
        </>
      )}
    </Content>
  );
};
export default ProviderProfile;
