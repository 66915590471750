import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState
} from 'react';
import { Views } from 'react-big-calendar';
import { BigCalendar } from 'lib/ui';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import { compareEvents } from 'utils/mappers/calendar';
import { localizer } from 'utils/builders/calendar';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import { MAX_CALENDAR_TIME, MIN_CALENDAR_TIME } from 'utils/constants/calendar';

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const formats = {
  timeGutterFormat: 'h a',
  dayFormat: 'ddd'
};

const customEventPropGetter = ({ type, isError }) => {
  return {
    className: `${type} ${isError && 'error'}`
  };
};

const checkAvailabilityType = availabilityType =>
  availabilityType ? 'offSite' : 'inClinic';

const AvailabilityCalendar = ({
  setFieldValue,
  availabilityType,
  setChanges,
  events
}) => {
  const availabilityRef = useRef(availabilityType);
  const eventsRef = useRef(events);
  const [, setChanged] = useState(false);

  useEffect(() => {
    availabilityRef.current = availabilityType;
    eventsRef.current = events;
    setChanged(prev => !prev);
  }, [availabilityType, events]);

  const deleteEvent = useCallback(
    id => {
      const arr = [...eventsRef.current];
      const index = arr.findIndex(i => i.id === id);
      arr.splice(index, 1);
      setFieldValue('events', arr);
      setChanges(true);
    },
    [setChanges, setFieldValue]
  );

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
      const idx = events.indexOf(event);
      let allDay = event.allDay;
      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true;
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false;
      }
      if (!moment(start).isSame(moment(end), 'day')) {
        end = moment(start)
          .endOf('day')
          .toDate();
      }
      const updatedEvent = {
        ...event,
        start,
        end,
        allDay
      };
      const nextEvents = [...events];
      nextEvents.splice(idx, 1, updatedEvent);
      setFieldValue('events', nextEvents);
      setChanges(true);
    },
    [setFieldValue, setChanges, events]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      const nextEvents = events.map(existingEvent => {
        return existingEvent.id === event.id
          ? {
              ...existingEvent,
              start,
              end,
              type: event.type
            }
          : existingEvent;
      });
      setFieldValue('events', nextEvents);
      setChanges(true);
    },
    [events, setFieldValue, setChanges]
  );

  const addEvent = useCallback(
    event => {
      const hour = {
        title: '',
        start: event.start,
        end: event.end,
        type: availabilityType ? 'offSite' : 'inClinic'
      };
      const nextEvents = [...events, hour];
      setFieldValue('events', nextEvents);
      setChanges(true);
    },
    [events, setFieldValue, setChanges, availabilityType]
  );

  const handleDraggableAccessor = useCallback(
    event => {
      const currentType = checkAvailabilityType(availabilityType);
      if (event.type === currentType) {
        return event;
      }
    },
    [availabilityType]
  );

  const filteredEvents = useMemo(() => compareEvents(events), [events]);

  return (
    <DragAndDropCalendar
      events={filteredEvents}
      views={[Views.WEEK]}
      min={MIN_CALENDAR_TIME}
      defaultView={Views.WEEK}
      defaultDate={new Date()}
      max={MAX_CALENDAR_TIME}
      step={15}
      timeslots={4}
      components={{
        // event: ,
        toolbar: () => '',
        event: CustomEvent({ deleteEvent, availabilityRef })
      }}
      style={{ height: '1100px' }}
      formats={formats}
      selectable
      localizer={localizer}
      onEventDrop={moveEvent}
      resizable
      onEventResize={resizeEvent}
      onSelectSlot={addEvent}
      eventPropGetter={customEventPropGetter}
      slotEventOverlap={false}
      draggableAccessor={handleDraggableAccessor}
    />
  );
};

const CustomEvent = ({ deleteEvent, availabilityRef }) => ({
  event: { id, isError, type }
}) => {
  const currentType = checkAvailabilityType(availabilityRef.current);
  if (type !== currentType) return null;
  return (
    !isError && (
      <FontAwesomeIcon
        className="event-close"
        icon={faTimes}
        onClick={() => deleteEvent(id)}
      />
    )
  );
};

export default AvailabilityCalendar;
export { AvailabilityCalendar };
