import { useHistory, useLocation } from 'react-router';
import { BigCalendar } from 'lib/ui';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { STAFF_CALENDAR_MAX_HEIGHT } from 'utils/constants/calendar';
import { localizer } from 'utils/builders/calendar';
import { EditAvailabilityWrap } from './EditAvailability.style';
import Header from 'views/containers/header';
import { useCallback, useState } from 'react';
import {
  addMinutes,
  checkIfLessThanFifteenMinsAndUpdate,
  durationInMinutes,
  handleDroppedEvent,
  handleLowerTouchingEvents,
  handleUpperTouchingEvents,
  removeAllOverlappingEventsWithEditedOneAndAddNewEvent,
  removeOverlappingEvents
} from 'pages/ConfigurationsPage/addAvailabilityTemplate/availabilityTemplateCalender/utils';
import { ClientAvailabilityEvent } from 'model/v2/availabilityTemplate';
import { ContentWrapper } from 'views/components/ui/card/card.style';
import EditAvailabilityBlockForm from './forms/EditAvailabilityBlockForm';
import ModalSemantic from 'components/modalSemantic';
import Content from 'views/components/ui/content';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CLIENT_LOCATIONS } from 'api/graphql/v2/queries/Enums';
import { CalendarWrapper } from 'pages/ConfigurationsPage/addAvailabilityTemplate/style';
import { getLocationColor } from 'pages/ConfigurationsPage/addAvailabilityTemplate/utils';
import {
  GET_AVAILABILITY_AUDIT,
  SAVE_CLIENT_AVAILABILITY
} from 'api/graphql/v2/mutations/ClientAvailability';
import {
  compareInitalandUpdatedSlots,
  mapClientAvailabilityOutputToEvents,
  mapClientEventsToClientAvailabilityInput
} from 'helpers/clientAvailabilityHelperV2';
import { GET_CLIENT_AVAILABILITYV2 } from 'api/graphql/v2/queries/Clients';
import { ClientAvailabilityOutput } from 'model/v2';
import { SCROLL_TIME_CALENDAR } from 'utils/constants/calendar';
import { CLIENT_LOCATIONS_DATA } from 'utils/constants/lists';
import { IAvailabilityAudit, IAvailabilityEvent } from 'model/event';
import moment from 'moment';
import Expand from '../../../../../assets/img/Expand.png';
import { AvailabilityModalWarning } from 'views/components/calendar/mainCalendar/modalWarning';
import Warning from '../../../../../assets/img/Warning.png';

const EditClientAvailability: React.FC = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const { client } = location.state;
  const view = 'week';
  const DragAndDropCalendar = withDragAndDrop(BigCalendar);
  const [events, setEvents] = useState<ClientAvailabilityEvent[]>([]);
  const [editModal, setEditModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ClientAvailabilityEvent>();
  const [initialSlots, setInitialSlots] = useState<ClientAvailabilityEvent[]>(
    []
  );
  const [addedEvent, setAddedEvent] = useState<
    ClientAvailabilityEvent | undefined
  >(undefined);
  const [deletPopupWarning, setDeletPopupWarning] = useState<boolean>(false);
  const [availabilityAudit, setAvailabilityAudit] = useState<
    { baseLimit: number; auditHistory: IAvailabilityAudit[] | [] } | undefined
  >();
  const [isProcessing, setIsProcessing] = useState<Boolean>(false);
  const [locationChecked, setLocationChecked] = useState<number>(0);
  const { data: locations, loading: loadingLocations, error } = useQuery(
    GET_CLIENT_LOCATIONS
  );

  const { loading: loadingEvents } = useQuery<ClientAvailabilityOutput>(
    GET_CLIENT_AVAILABILITYV2,
    {
      fetchPolicy: 'no-cache',
      variables: { id: client.id },
      onCompleted: data => {
        setEvents(
          mapClientAvailabilityOutputToEvents(data?.clientDayAvailabilities)
        );
        setInitialSlots(
          mapClientAvailabilityOutputToEvents(data?.clientDayAvailabilities)
        );
      }
    }
  );

  useQuery(GET_AVAILABILITY_AUDIT, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id },
    onCompleted: data => {
      setAvailabilityAudit({
        baseLimit: 3,
        auditHistory: data.getAvailabilityAudit.map(
          (auditData: IAvailabilityAudit) => {
            return {
              actionBy: auditData.actionBy,
              actionAt: moment(auditData.actionAt).format('MM/DD/YYYY'),
              actionTime: moment(auditData.actionAt).format('hh:mm a')
            };
          }
        )
      });
    }
  });

  const [saveTemplate] = useMutation(SAVE_CLIENT_AVAILABILITY);

  const formats = {
    dayFormat: 'ddd'
  };

  const customEventPropGetter = useCallback(
    (event: any) => {
      const locationData = [
        ...CLIENT_LOCATIONS_DATA,
        ...locations?.clientLocations
      ];
      const style = getLocationColor(locationData, event.location);
      style.backgroundColor = 'rgba(61, 137, 182, 0.2)';
      style.border = 'rgba(61, 137, 182, 0.2)';
      return {
        style
      };
    },
    [locations]
  );

  const titleAccessor = useCallback(
    (event: IAvailabilityEvent) => {
      let locationTypeArr: string[] = [];
      locations?.clientLocations.map(
        (location: { id: number; title: string }) => {
          if ((location.id & event.location!) > 0)
            locationTypeArr.push(location.title);
        }
      );

      return event.title?.toLocaleLowerCase() || locationTypeArr.join(', ');
    },
    [locations]
  );
  const EventComponent = (event: any) => {
    const eventStartTime = moment(event.event.start);
    const eventEndTime = moment(event.event.end);
    const differenceInMinutes = eventEndTime.diff(eventStartTime, 'minutes');
    let locationTypeArr: string[] = [];
    locations?.clientLocations.map(
      (location: { id: number; title: string }) => {
        if ((location.id & event.event.location!) > 0) {
          locationTypeArr.push(location.title);
        }
      }
    );

    let transformedArray = locationTypeArr.map((element: string) => {
      switch (element) {
        case 'In Clinic':
          return 'In-clinic';
        case 'Offsite':
          return 'Off-Site';
        default:
          return element;
      }
    });
    if (differenceInMinutes < 45 && transformedArray.length > 0)
      transformedArray = ['...'];
    else if (differenceInMinutes < 45 && transformedArray.length <= 0)
      transformedArray = [''];
    else if (differenceInMinutes < 60 && transformedArray.length > 1)
      transformedArray = [`${transformedArray[0]}...`];
    else if (differenceInMinutes < 90 && transformedArray.length > 2)
      transformedArray = [
        `${transformedArray[0]}`,
        `${transformedArray[1]}...`
      ];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {transformedArray.map((locationType: any) => {
          return (
            <div className="location-container">
              <span>{locationType}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const onDelete = useCallback(() => {
    history.goBack();
  }, [setEvents, events]);

  const resizeEvent = useCallback(
    ({ event, start, end }: any) => {
      const indexOfNewEvent = events.indexOf(event);
      const updatedEvent = {
        ...event,
        start,
        end
      };
      checkIfLessThanFifteenMinsAndUpdate(updatedEvent);
      events.splice(indexOfNewEvent, 1, updatedEvent);
      removeOverlappingEvents(updatedEvent, events);
      handleUpperTouchingEvents(updatedEvent, events);
      handleLowerTouchingEvents(updatedEvent, events);
      setEvents([...events]);
    },
    [setEvents, events]
  );

  const moveEvent = useCallback(
    ({ event, start, end }: any) => {
      const idx = events.indexOf(event);

      const updatedEvent = {
        ...event,
        start,
        end
      };
      events.splice(idx, 1, updatedEvent);
      removeOverlappingEvents(updatedEvent, events);
      handleDroppedEvent(updatedEvent, events);
      handleUpperTouchingEvents(updatedEvent, events);
      handleLowerTouchingEvents(updatedEvent, events);
      setEvents([...events]);
    },
    [events, setEvents]
  );

  const onEventClick = useCallback(
    (event: any) => {
      setSelectedEvent(event);
      setLocationChecked(event.location);
      setEditModal(true);
    },
    [locationChecked]
  );

  const createNewEvent = useCallback(
    (start: Date, end: Date) => {
      if (durationInMinutes(end, start) < 30) {
        end = addMinutes(end, 15);
      }
      const event: ClientAvailabilityEvent = {
        end: end,
        start: start,
        title: '',
        location: locations?.clientLocations[0].id
      };

      setEvents((prev: ClientAvailabilityEvent[]) => {
        let newEvents = prev;
        setAddedEvent(event);
        newEvents.push(event);
        return [...newEvents];
      });
      onEventClick(event);
    },
    [locations, onEventClick, setEvents]
  );

  const onCalenderClick = useCallback(
    ({ start, end }) => {
      createNewEvent(start, end);
    },
    [createNewEvent]
  );

  const onEditModalClose = useCallback(() => {
    if (addedEvent) {
      setEvents((prev: ClientAvailabilityEvent[]) => {
        let newEvents = prev;
        const idx = newEvents.indexOf(addedEvent);
        if (idx > -1) {
          newEvents.splice(idx, 1);
          setAddedEvent(undefined);
        }
        return [...newEvents];
      });
    }
    setDeletPopupWarning(false);
    setEditModal(false);
  }, [addedEvent]);

  const onEditModalSubmit = useCallback(
    (data: any, submittedEvent: ClientAvailabilityEvent) => {
      if (addedEvent) setAddedEvent(undefined);
      const idx = events.indexOf(submittedEvent);

      if (idx > -1) {
        const updatedEvent: ClientAvailabilityEvent = {
          id: submittedEvent.id,
          title: '',
          location: data.location,
          start: data.start,
          end: data.end
        };
        events.splice(idx, 1, updatedEvent);
      } else {
        events.push(submittedEvent);
      }

      data.recurringDays.forEach((day: number) => {
        removeAllOverlappingEventsWithEditedOneAndAddNewEvent(
          events,
          data,
          day
        );
      });
      setEvents([...events]);
      setEditModal(false);
    },
    [addedEvent, events]
  );

  const onEditDelete = useCallback(
    (selectedEvent: ClientAvailabilityEvent) => {
      const idx = events.indexOf(selectedEvent);

      setEvents((prev: ClientAvailabilityEvent[]) => {
        let newEvents = prev;
        setAddedEvent(undefined);
        newEvents.splice(idx, 1);
        return [...newEvents];
      });
      setEditModal(false);
    },
    [setEvents, events]
  );

  const goBack = useCallback(
    (isSaved?) => {
      const isSlotsUpdated = compareInitalandUpdatedSlots(initialSlots, events);
      if (!isSlotsUpdated || isSaved === true) {
        history.goBack();
      } else {
        setDeletPopupWarning(true);
      }
    },
    [history, events, initialSlots]
  );

  const onSaveButton = useCallback(() => {
    setIsProcessing(true);
    saveTemplate({
      variables: {
        clientAvailability: mapClientEventsToClientAvailabilityInput(
          client.id,
          events
        )
      }
    })
      .then(() => {
        goBack(true);
      })
      .catch((e: any) => {
        setIsProcessing(false);
        console.log(e);
      });
  }, [client, events, goBack, saveTemplate]);
  const checkIfAuditExist = () => {
    if (availabilityAudit) {
      if (
        availabilityAudit['auditHistory'].length > 0 &&
        availabilityAudit['auditHistory'].length > 3
      )
        return true;
      else return false;
    } else return false;
  };
  const CustomHeader = (label: any) => {
    const labelday = label.label.replace(/[0-9]/g, '');
    const labeldate = label.label.replace(/[A-Za-z]/g, '');
    const displayDay = ['Sun'];
    return (
      <div>
        <div
          className={displayDay.includes(labelday) ? 'weekendDay' : ''}
          style={{ textAlign: 'center', fontSize: '15px', fontWeight: 700 }}
        >
          <u>{labelday}</u>
          <span>{labeldate}</span>
        </div>
        <div className="clinic-abbr">
          <span>
            {displayDay.includes(labelday) ? '' : client?.clinic?.abbreviation}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Content
      loading={loadingEvents || loadingLocations}
      error={error}
      data={locations}
      fullHeight={true}
    >
      {() => (
        <>
          <EditAvailabilityWrap>
            <Header
              title="Edit Availability"
              source={client}
              buttonText="Save Changes"
              handleSubmit={onSaveButton}
              onCancel={goBack}
              loading={isProcessing}
              disabled={isProcessing}
              newAppointment={undefined}
            />
            <div className="inner-wrap">
              <div className="calendar-actions">
                <h2 className="sideBarTitle">RECURRING AVAILABILITY </h2>
                {availabilityAudit && (
                  <div className="audit-inner-label">
                    <p>Last updated by:</p>
                    {availabilityAudit['auditHistory']
                      .filter(
                        (
                          filtredAuditData: IAvailabilityAudit,
                          index: number
                        ) => {
                          return (
                            filtredAuditData &&
                            index < availabilityAudit.baseLimit
                          );
                        }
                      )
                      .map((auditData: IAvailabilityAudit, index: number) => {
                        return (
                          <div key={index}>
                            <p
                              title={`${auditData.actionBy},${auditData.actionAt} ${auditData.actionTime}`}
                              className={'Audit-content'}
                            >
                              {auditData.actionBy}, {'\u00A0'}
                              {auditData.actionAt} {'\u00A0'}
                              {auditData.actionTime}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                )}
                {checkIfAuditExist() &&
                  availabilityAudit?.baseLimit! <
                    availabilityAudit?.auditHistory.length! && (
                    <div className="audit-show-label">
                      <div
                        onClick={() => {
                          setAvailabilityAudit((prevState: any) => ({
                            ...prevState,
                            baseLimit: prevState['baseLimit'] + 3
                          }));
                        }}
                      >
                        <p>Show more</p>
                      </div>
                      <img
                        className="expand-icon"
                        src={Expand}
                        alt="expand-button"
                        onClick={() => {
                          setAvailabilityAudit((prevState: any) => ({
                            ...prevState,
                            baseLimit: prevState['baseLimit'] + 3
                          }));
                        }}
                      />
                    </div>
                  )}
                {checkIfAuditExist() &&
                  availabilityAudit?.baseLimit! >=
                    availabilityAudit?.auditHistory.length! && (
                    <div className="audit-show-label">
                      <div
                        onClick={() => {
                          setAvailabilityAudit((prevState: any) => ({
                            ...prevState,
                            baseLimit: 3
                          }));
                        }}
                      >
                        <p>Show less</p>
                      </div>
                      <img
                        className="expand-icon"
                        src={Expand}
                        style={{ transform: 'rotate(-180deg)' }}
                        alt="expand-button"
                        onClick={() => {
                          setAvailabilityAudit((prevState: any) => ({
                            ...prevState,
                            baseLimit: 3
                          }));
                        }}
                      />
                    </div>
                  )}
              </div>

              <CalendarWrapper>
                <DragAndDropCalendar
                  selectable
                  views={[view]}
                  defaultView={view}
                  defaultDate={new Date()}
                  toolbar={false}
                  scrollToTime={SCROLL_TIME_CALENDAR}
                  events={events}
                  step={15}
                  timeslots={4}
                  localizer={localizer}
                  formats={formats}
                  style={{
                    height: STAFF_CALENDAR_MAX_HEIGHT
                  }}
                  titleAccessor={titleAccessor}
                  components={{
                    event: EventComponent,
                    header: CustomHeader
                  }}
                  eventPropGetter={customEventPropGetter}
                  onEventResize={resizeEvent}
                  onEventDrop={moveEvent}
                  onSelectEvent={onEventClick}
                  onSelectSlot={onCalenderClick}
                  resizable
                  className="Client-edit-avail"
                />
              </CalendarWrapper>
            </div>
          </EditAvailabilityWrap>
          {editModal && (
            <ContentWrapper>
              <ModalSemantic
                open={true}
                onClose={onEditModalClose}
                modalWidth={550}
                title={'Edit Availability Block'}
                trigger={<div></div>}
                content={
                  <EditAvailabilityBlockForm
                    onClose={onEditModalClose}
                    onSubmit={onEditModalSubmit}
                    onDelete={onEditDelete}
                    locations={locations.clientLocations}
                    data={selectedEvent}
                    setLocationChecked={setLocationChecked}
                    locationChecked={locationChecked}
                  />
                }
              />
            </ContentWrapper>
          )}
          {deletPopupWarning && (
            <ContentWrapper>
              <ModalSemantic
                open={true}
                onClose={onEditModalClose}
                modalWidth={550}
                title={'Warning'}
                img={Warning}
                trigger={<div></div>}
                content={
                  <AvailabilityModalWarning
                    data={selectedEvent}
                    onClose={onEditModalClose}
                    onDelete={onDelete}
                    onSubmit={onEditModalSubmit}
                  />
                }
              />
            </ContentWrapper>
          )}
        </>
      )}
    </Content>
  );
};

export default EditClientAvailability;
