import React from 'react';

import { LibTimepicker } from './timepicker.style';

const Timepicker = props => {
  return <LibTimepicker inputReadOnly={true} {...props} />;
};

export default Timepicker;
export { Timepicker };
