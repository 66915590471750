import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import FormSection from '../Other/FormSection';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { Row } from 'antd';
import OffsiteLocation from './OffsiteLocation';
import AppointmentRooms from './AppointmentRooms';
import { Input } from 'api/sharedComponents/reactHookFormComponents';

function AppointmentLocation({ clientId }) {
  const { control } = useFormContext();
  const locationType = useWatch({ control, name: 'locationType' });

  switch (locationType) {
    case LOCATION_TYPE_LIST[0].id:
      return <AppointmentRooms />; // in clinic

    case LOCATION_TYPE_LIST[1].id: // offsite
      return (
        <>
          <h2 className="appointment-body__title">
            Location <span>(select one)</span>
          </h2>
          <div className="location__inOffice">
            <Row type="flex" justify="start" align="top" gutter={16}>
              <OffsiteLocation clientId={clientId} />
            </Row>
          </div>
        </>
      );
    case LOCATION_TYPE_LIST[2].id: // telehealth
      return (
        <FormSection title="Link" optional>
          <FormItem label="Telehealth Link" xs={16} sm={24}>
            <Controller
              name="telehealthLink"
              control={control}
              render={fieldProps => (
                <Input placeholder="Paste link here" field={fieldProps} />
              )}
            />
          </FormItem>
        </FormSection>
      );

    default:
      return null;
  }
}

export default AppointmentLocation;
