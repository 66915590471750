import * as React from 'react';
import { TableSortDirection } from './type';
import { Icon } from 'semantic-ui-react';

interface IProps {
  sorted: boolean;
  label: string;
  direction: TableSortDirection;
}
const TheadLabel: React.FC<IProps> = ({ sorted, label, direction }: IProps) => {
  return (
    <>
      <label style={{ marginRight: '5px' }}>{label}</label>
      {sorted && (
        <Icon
          name={
            direction === TableSortDirection.ascending ? 'sort up' : 'sort down'
          }
          style={{ color: '#0d77b5' }}
        />
      )}
      {!sorted && <Icon name={'sort down'} style={{ color: '#000000' }} />}
    </>
  );
};

export default TheadLabel;
