import React, { useCallback, MouseEvent } from 'react';
import { SearchWrapper, styles, LineWrapper } from './style';
import SearchForm from '../ui/form/search';
import MainButton from '../button';
import { TableType } from 'model/tableType';
import BreadcrumbComponent from './breadcrumb';
import { IBreadcrumbSection } from 'model/ui/breadcrumb';
import { BREADCRUMB_SECTION_SIZES } from 'utils/constants/breadcrumbs';

interface IConfigurationHeaderProps {
  addNew: (item?: TableType) => void;
  onSearch: (value: string) => void;
  onClick: () => void;
  title: string;
  visible?: boolean;
  searchPlaceholder?: string;
}

const ConfigurationHeader: React.FC<IConfigurationHeaderProps> = ({
  addNew,
  onSearch,
  onClick,
  title,
  visible,
  searchPlaceholder
}: IConfigurationHeaderProps) => {
  const sections: IBreadcrumbSection[] = [
    {
      key: 'Configuration',
      content: 'Configuration',
      link: false,
      onClick: onClick,
      size: BREADCRUMB_SECTION_SIZES.massive
    },
    {
      key: title,
      content: title,
      link: false,
      size: BREADCRUMB_SECTION_SIZES.large
    }
  ];

  const onAddNew = useCallback(
    (_e: MouseEvent<HTMLButtonElement>) => {
      addNew();
    },
    [addNew]
  );
  return (
    <>
      <div style={styles.container}>
        <BreadcrumbComponent sections={sections} />
        <SearchWrapper>
          <div className="actions">
            <SearchForm
              placeholder={searchPlaceholder || 'Search by title'}
              defaultValue=""
              onSearch={onSearch}
            />
            <MainButton
              title="Add New"
              onClick={onAddNew}
              disabled={visible}
            ></MainButton>
          </div>
        </SearchWrapper>
      </div>
      <LineWrapper />
    </>
  );
};
export default React.memo(ConfigurationHeader);
