import React, { useCallback } from 'react';
import moment from 'moment';
import { Popover } from 'antd';
import { EyeFilled, CalendarFilled } from '@ant-design/icons';
import { isSelectedEvent } from 'utils/format/time';
import { isRecurringEvent } from 'utils/common';
import { ProvidersCalendarWrap } from '../../NewAppointment.style';
import ProviderHoverCalendar from './ProviderHoverCalendar';
import { useOpeningConflicts } from './openingsHooks';
import { openingId } from 'utils/common';
import { useFormContext, useWatch } from 'react-hook-form';

const ListViewComponentItem = ({
  opening,
  reschedule,
  thisAndFollowingEvents,
  masterID,
  disabledFormFields
}) => {
  const { control, setValue } = useFormContext();

  const watchedValues = useWatch({
    control,
    name: ['selectedSlot', 'scheduleType']
  });

  const { conflictOpening } = useOpeningConflicts(
    opening,
    thisAndFollowingEvents,
    reschedule
  );

  const onSelect = useCallback(() => {
    if (!disabledFormFields.selectedSlot)
      setValue('selectedSlot', conflictOpening);
  }, [disabledFormFields, setValue, conflictOpening]);

  return (
    <div
      className={`list-view__row ${isSelectedEvent(
        watchedValues.selectedSlot,
        opening
      ) && 'selected'}`}
      key={openingId(opening)}
      onClick={onSelect}
    >
      {timeRangeLabel(conflictOpening, watchedValues.scheduleType)}
      <span>
        <>
          <div className="wrap"></div>
        </>
      </span>
    </div>
  );
};

const timeRangeLabel = (opening, scheduleType) => {
  const momentObject = moment(opening.start);

  return (
    <>
      <span className="dayOfWeek">{momentObject.format('ddd')}</span>
      <span className="month">{momentObject.format('MMM D')}</span>
      <span className="range">{`${momentObject.format('hh:mm a')} - ${moment(
        opening.end
      ).format('hh:mm a')}`}</span>
      <span className="name">{opening.provider?.displayName}</span>
      {isRecurringEvent(scheduleType) &&
        opening.openingsAvailableNum !== undefined &&
        opening.totalRecurringAppointments !== undefined && (
          <span className="conflicts-calculation">
            <CalendarFilled /> {opening.openingsAvailableNum}/
            {opening.totalRecurringAppointments}
          </span>
        )}
      <Popover
        placement="left"
        className="viewSurrounding"
        content={
          <ProvidersCalendarWrap>
            <ProviderHoverCalendar opening={opening} />
          </ProvidersCalendarWrap>
        }
        trigger="hover"
      >
        <EyeFilled />
        View Surrounding
      </Popover>
    </>
  );
};

export default React.memo(ListViewComponentItem);
