import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import Content from 'views/components/ui/content';
import Header from 'views/containers/header';
import { AddFormWrapper } from 'views/components/clients/clients.style';
import { CLIENT_FORM_SCHEMA } from 'utils/validators/clients';
import { mapToOption } from 'utils/mappers/form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_CLIENT_FORM_DATA } from 'api/graphql/v2/queries/Clients';
import { UPDATE_CLIENT, ADD_CLIENT } from 'api/graphql/v2/mutations/Client';
import { getClientFormData } from 'utils/mappers/request/clients';
import { ClientProfileForm } from './ClientProfileForm';
import { ClientLegalGuardianForm } from './ClientLegalGuardianForm';
import { ClientInsuranceForm } from './ClientInsuranceForm';
import { ClientPrivacyForm } from './ClientPrivacyForm';
import { ClientAdditionalInfoForm } from './ClientAdditionalInfoForm';
import { ClientProviderForm } from './ClientProviderForm';
import { ClientIdentificationForm } from './ClientIdentificationForm';
import { ClientContactForm } from './ClientContactForm';
import { formatClientAddress, formatUsernameOriginal } from 'utils/format';

export const ClientForm = ({ client, isCreate, disabledFormFields }) => {
  const [providerOptions, setProviderOptions] = useState([]);
  const history = useHistory();

  const updateClinicProviderOptions = useCallback((clinicId, clinics) => {
    const selectedClinicObject = clinics?.find(c => c.id === clinicId);
    if (selectedClinicObject)
      setProviderOptions(
        selectedClinicObject.providers
          ? mapToOption(
              selectedClinicObject.providers.map(provider => ({
                id: provider.id,
                value: formatUsernameOriginal(
                  //TODO : check computed value
                  provider?.firstName,
                  provider?.lastName
                )
              }))
            )
          : []
      );
  }, []);

  const { data, loading, error } = useQuery(GET_CLIENT_FORM_DATA, {
    onCompleted: data => {
      if (client.clinic?.id)
        updateClinicProviderOptions(
          client.clinic?.id,
          data?.clinicsListWithProviders
        );
    }
  });

  const [updateClient] = useMutation(UPDATE_CLIENT, {
    onCompleted: () => {
      history.goBack();
    }
  });

  const [createClient] = useMutation(ADD_CLIENT, {
    onCompleted: data => {
      history.push('/clients');
    }
  });

  const onSubmit = useCallback(
    (values, actions) => {
      actions.setSubmitting(true);
      formatClientAddress(values.addresses);
      if (!isCreate) {
        return updateClient({
          variables: { client: getClientFormData(values) }
        }).then(_ => actions.setSubmitting(false));
      } else {
        createClient({
          variables: { client: getClientFormData(values) }
        }).then(() => actions.setSubmitting(false));
      }
    },
    [isCreate, updateClient, createClient]
  );

  const onCancel = useCallback(() => history.goBack(), [history]);

  return (
    <Content data={data} loading={loading} error={error}>
      {() => (
        <AddFormWrapper className="popup-wrap">
          <Formik
            initialValues={client}
            validationSchema={CLIENT_FORM_SCHEMA}
            onSubmit={(values, actions) => onSubmit(values, actions)}
            render={({
              handleSubmit,
              isSubmitting,
              dirty,
              values,
              setFieldValue,
              getFieldValue
            }) => (
              <>
                <Header
                  title="Edit Client"
                  handleSubmit={handleSubmit}
                  onCancel={onCancel}
                  buttonText="Save Client"
                  loading={isSubmitting}
                  disabled={!dirty}
                />
                <div className="inner-wrap">
                  <ClientProviderForm
                    clinicsWithoutRooms={data?.clinicsListWithProviders || []}
                    updateClinicProviderOptions={updateClinicProviderOptions}
                    providerOptions={providerOptions}
                    clientTypes={data?.clientTypes || []}
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientIdentificationForm
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientContactForm
                    data={values}
                    setFieldValue={setFieldValue}
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientAdditionalInfoForm
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientPrivacyForm disabledFormFields={disabledFormFields} />
                  <ClientInsuranceForm
                    patientRelationships={data?.patientRelationships || []}
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientLegalGuardianForm
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientProfileForm disabledFormFields={disabledFormFields} />
                </div>
              </>
            )}
          />
        </AddFormWrapper>
      )}
    </Content>
  );
};
