import { useSubscription } from 'react-apollo';
import { SYNC_SUCCESS_SUBSCRIPTION } from 'api/graphql/v2/subscriptions/Events';
import React from 'react';
import { toast } from 'react-toastify';
import {
  ROUTE_PARAMS,
  ROUTE_PATHS,
  TARGET_BLANK
} from 'utils/constants/routes';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { limitProviderName } from 'utils/constants/toastMessage';
import { SyncNotice } from './ToastUtility';
import ToastMessage from './ToastMessage';

const SUCCESS_STATUS_MAPPINGS: Record<string, string> = {
  CREATE_SUCCESS: 'Created',
  UPDATE_SUCCESS: 'Updated',
  CANCEL_SUCCESS: 'Cancelled'
};

const notify = (result: any) => {
  console.log('notify event syncsuccesstoast', result);
  const successNotice: SyncNotice =
    result.subscriptionData?.data?.eventSyncSuccessNotice;
  if (!successNotice) return;

  const { eventId, downStream, isSupressLink } = successNotice;
  const noticeId = `${eventId}-${downStream}`;
  const link = ROUTE_PATHS.VIEW_APPOINTMENT + ROUTE_PARAMS.EVENT + eventId;

  const onToastClick = () => {
    if (!isSupressLink) window.open(link, TARGET_BLANK);
  };

  toast.success(syncNoticeContent(successNotice), {
    autoClose: 10000,
    toastId: noticeId,
    containerId: noticeId,
    closeOnClick: !isSupressLink,
    onClick: onToastClick
  });
};

const syncNoticeContent = (successNotice: SyncNotice) => {
  console.log('syncNoticeContent', successNotice);
  const { providerName, downStream, eventId } = successNotice;
  const time = new Date(successNotice.startDate!).toLocaleTimeString(
    navigator.language,
    {
      hour: '2-digit',
      minute: '2-digit'
    }
  );
  const action = SUCCESS_STATUS_MAPPINGS[successNotice.status!];

  const message = `${limitProviderName(
    providerName!
  )}'s ${time} event was successfully ${action} in ${downStream}`;

  const onClose = (e: any) => {
    const toastId = `${eventId}-${downStream}`;
    e.stopPropagation();
    toast.dismiss(toastId);
  };
  console.log('syncNoticeContent message', message);
  return <ToastMessage message={message} onClose={onClose} />;
};

interface Props {
  account: any;
}

const SyncSuccessToast: React.FC<Props> = ({ account }: Props) => {
  useSubscription(SYNC_SUCCESS_SUBSCRIPTION, {
    variables: { CreatedBy: account.name },
    skip: !getFeatureAvailability(FEATURES.SUCCESS_NOTICE_SUBSCRIPTIONS),
    onSubscriptionData: notify
  });
  return <></>;
};

export default SyncSuccessToast;
