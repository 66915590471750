import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { ICalendarFilterOption } from 'model/calendar/filters';
import { ILookup } from 'model/lookup';
import { DEFAULT_FILTER } from 'utils/constants/calendarFilters';
import { mapToCheckListOptions } from 'utils/mappers/form';
import CalendarFilter from '../../../ui/calendarFilter';

interface Props {
  internalsList: ILookup[];
  handleSearchedValues: (
    list: ICalendarFilterOption[],
    checkedList: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  setCheckedInternals: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  checkedInternals: ICalendarFilterOption[];
  setHasData: Dispatch<SetStateAction<boolean>>;
}

const InternaFilter = ({
  internalsList,
  handleSearchedValues,
  setCheckedInternals,
  checkedInternals,
  setHasData
}: Props) => {
  const internals = useMemo(
    () =>
      internalsList
        ? mapToCheckListOptions(
            internalsList,
            DEFAULT_FILTER.id,
            DEFAULT_FILTER.value
          )
        : [],

    [internalsList]
  );

  const searchedStatus = useMemo(() => {
    const searchedValues = handleSearchedValues(internals, checkedInternals);

    return searchedValues;
  }, [handleSearchedValues, internals, checkedInternals]);

  useEffect(() => {
    searchedStatus?.length === 0 ? setHasData(false) : setHasData(true);
  }, [searchedStatus, setHasData]);

  return (
    <>
      <CalendarFilter
        setCheckedList={setCheckedInternals}
        checkedList={checkedInternals}
        listOptions={searchedStatus}
        length={internals.length}
        title="Internal"
      />
    </>
  );
};

export default React.memo(InternaFilter);
