import gql from 'graphql-tag';

export const GET_CANCELLATIONS_REASONS = gql`
  query {
    cancellationReasons {
      id
      value
      type
    }
  }
`;

export const GET_PROVIDER_LOCATIONS = gql`
  query {
    providerLocations {
      id
      title
      color
      borderColor
    }
  }
`;

export const GET_CLIENT_LOCATIONS = gql`
  query {
    clientLocations {
      id
      title
      color
      borderColor
    }
  }
`;
export const GET_ABA_SUBTYPE_DATA = gql`
  query {
    placesOfService {
      id
      value
    }
    crAuthCodes {
      id
      value
    }
    crAppointmentTypes {
      id
      value
    }
  }
`;
