import { formatPatternString } from './authorizations';
import { getApptTypeAndSubType } from 'utils/common';
import moment from 'moment';
import ExpandedRowForWaitlist from 'views/components/waitlistPage/components/ExpandedRowForWaitlist';
import { WAITLIST_POPOVER_COLUMNS } from 'utils/constants/tables/waitlist';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_DURATION } from 'utils/constants/default';

export function formatWaitlist(data, goToClientProfile, clinicsMap) {
  const formatedList = [];
  if (data?.appointmentTypes && data?.appointmentABATypes)
    data.allAppointments = [
      ...data.appointmentTypes,
      ...data.appointmentABATypes
    ];
  const waitlist = data?.waitlist?.data || [];
  waitlist.forEach(item => {
    formatedList.push(
      formatWaitlistItem(item, data, goToClientProfile, clinicsMap)
    );
  });
  return formatedList;
}

export function formatWaitlistItem(
  item,
  options,
  goToClientProfile,
  clinicsMap
) {
  const { apptType, apptSubType } = getApptTypeAndSubType(
    {
      appointmentType: {
        id: item.appointmentTypeID,
        isClinical: item.isClinical
      },
      appointmentSubType: item.appointmentSubType
    },
    options.allAppointments
  );

  const clientAvailability = options?.clientAvailabilityStatusOptions?.find(
    x => x.id === item.clientAvailability
  );

  const waitlistPattern = item.quantityPattern
    ? item.quantityPattern.toLowerCase()
    : item.isClinical
    ? 'visits'
    : 'hours';

  return {
    key: item.id,
    clinicID: item.clinicID,
    clientID: item.clientID,
    clinicName: clinicsMap.get(item.clinicID)?.name,
    clientName: item.clientName,
    appointmentType: apptType?.title,
    apptType,
    apptSubTypeId: apptSubType?.id,
    appointmentSubType: apptSubType?.title || 'None',
    isClinical: item.isClinical,
    toFulfill: formatPatternString(
      item.toFulfill,
      waitlistPattern,
      item.isRecommendedTherapy
    ),
    clientAvailability:
      clientAvailability?.id === 'available'
        ? item.clientAvailabilityFrequency
        : clientAvailability?.value,
    canSchedule: item.canSchedule && item.toFulfill > 0,
    waiting: getWaitingValue(item.waiting),
    status: item.status,
    apptDuration: apptSubType?.duration
      ? apptSubType.duration
      : DEFAULT_DURATION.ABA,
    description: (
      <ExpandedRowForWaitlist
        recommended={
          item.isRecommendedTherapy ? item.maxRecommendedQuantity : 1
        }
        authorized={item.authorized}
        requested={item.requestedQuantity}
        toFulfill={item.toFulfill}
        ideal={item.ideal}
        receiving={item.receiving}
        pattern={waitlistPattern}
        isRecommendedTherapy={item.isRecommendedTherapy}
      />
    ),
    patient: renderPopover(
      item.clientName,
      item.clientID,
      WAITLIST_POPOVER_COLUMNS,
      goToClientProfile
    )
  };
}

export const getWaitingValue = waiting => {
  if (!waiting) return 'N/A';

  return moment.utc(waiting).fromNow();
};

const renderPopover = (
  clientName,
  clientID,
  completedColumns,
  goToClientProfile
) => {
  // const dataSource = [
  //   {
  //     key: clientID,
  //     date: '03/04/2020',
  //     author: clientName,
  //     note: 'Referred by Jaclyn Smith. Requested Dr. Kelly Goh for ABA.'
  //   }
  // ];
  // const content = () => {
  //   return (
  //     <>
  //       <div className="headerPopover">
  //         <div className="patientPhoto">
  //           <ProfilePicture alt={clientName} />
  //         </div>
  //         <div className="patientName">{clientName}</div>
  //       </div>
  //       <Table
  //         columns={completedColumns}
  //         dataSource={dataSource}
  //         rowKey={record => record.key}
  //         className="contentWrapper"
  //         pagination={false}
  //         bordered
  //       />
  //     </>
  //   );
  // };
  return (
    <>
      <p className="go-to-profile" onClick={() => goToClientProfile(clientID)}>
        {clientName}
      </p>
      {/* <Popover
        placement="rightTop"
        overlayClassName="popoverInfo"
        content={content()}
      > */}
      <FontAwesomeIcon icon={faFile} />
      {/* </Popover> */}
    </>
  );
};
