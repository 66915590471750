import gql from 'graphql-tag';
import { providerFragments } from '../fragments/Providers';
import { addressFragments } from '../fragments/Address';

export const FIND_SUBSTITUTE_PROVIDERS_QUERY = gql`
  query(
    $eventTypeName: String!
    $clientId: Int!
    $appointmentTypeId: Int!
    $apptSubTypeId: Int!
    $startTime: String!
    $endTime: String!
    $providerId: Int!
    $locationType: String!
    $eventId: Int!
    $duration: Int!
    $makeupSessions: Boolean!
    $eventStatus: String!
  ) {
    findSubstituteProviders(
      eventTypeName: $eventTypeName
      clientId: $clientId
      appointmentTypeId: $appointmentTypeId
      apptSubTypeId: $apptSubTypeId
      startTime: $startTime
      endTime: $endTime
      providerId: $providerId
      locationType: $locationType
      eventId: $eventId
      duration: $duration
      makeupSessions: $makeupSessions
      eventStatus: $eventStatus
    ) {
      date
      times {
        startTime
        endTime
        adjacentType
      }
      provider {
        ...Provider
      }
      clientTimezone
      apptTypeId
      inPersonClinics {
        id
        name
        abbreviation
      }
      dayOfWeek
      telehealthClinic {
        id
        name
      }
      locations {
        isTelehealth
        isInClinic
        isOffsite
      }
      isDirectSubstitute
      adjacentEvent {
        id
        startDate
        endDate
        duration
        locationType
        clinicPreference
        paymentMethod
        telehealthLink
        locationCategory
        provider {
          id
        }
        appointmentType {
          id
        }
        clinic {
          id
          name
          abbreviation
        }
        note {
          id
          note
        }
        address {
          ...AddressData
        }
        recurrencePattern {
          id
          recurringEvery
        }
        observationsCount
        observations {
          id
          provider {
            id
            name
          }
          startDate
          endDate
          duration
          locationType
          telehealthLink
        }
      }
    }
  }
  ${providerFragments.provider}
  ${addressFragments.addressData}
`;
