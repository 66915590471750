import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { AddObserversFormWrapper } from '../style';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { Icon, Input } from 'semantic-ui-react';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { IEvent, IProvider } from 'model/v2';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { FormProvider, useForm } from 'react-hook-form';
import TimeDurationForm from 'pages/MainCalendarPage/AppointmentForm/Date/TimeDurationForm';
import { initValues } from 'pages/MainCalendarPage/AppointmentSidebar';
import moment from 'moment';
import { LocationType } from 'views/components/icons/eventIcons/locationIcon';
import { UserAddOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ABA_ALERT_MESSAGES } from 'utils/constants/default';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
interface Props {
  abaObserversList: IEvent[];
  setAbaObserversList: Dispatch<SetStateAction<IEvent[]>>;
  lastAbaObserver: IEvent;
  setLastAbaObserver: Dispatch<SetStateAction<IEvent>>;
  observerId: number | undefined;
  setObserverId: Dispatch<SetStateAction<number | undefined>>;
  setIsObserversDropdownShown: (val: boolean) => void;
  event: IEvent;
  observersData: any;
  isSaving: boolean;
  MAX_OBSERVERS: number;
  setIsAlertShown: (val: boolean) => void;
  isValidated: boolean;
  setIsValidated: (val: boolean) => void;
  setAlertContent: (val: string) => void;
  setAbaObserversListObj: Dispatch<SetStateAction<IEvent>>;
  isLocationSelected: boolean;
  setIsLocationSelected: (val: boolean) => void;
  setAbaObserverId: Dispatch<SetStateAction<number | undefined>>;
  abaObserverId: number | undefined;
  setAbaLocationType: Dispatch<SetStateAction<LocationType | undefined>>;
}

const AbaObserversDropdown: React.FC<Props> = ({
  abaObserversList,
  setAbaObserversList,
  setLastAbaObserver,
  setIsObserversDropdownShown,
  event,
  observerId,
  setObserverId,
  observersData,
  isSaving,
  MAX_OBSERVERS,
  setIsAlertShown,
  isValidated,
  setAlertContent,
  setIsValidated,
  setAbaObserversListObj,
  isLocationSelected,
  setIsLocationSelected,
  setAbaObserverId,
  abaObserverId,
  setAbaLocationType
}) => {
  const locationTypes = mapToOption(LOCATION_TYPE_LIST);
  let filteredLocations = locationTypes.filter(
    (location: { value: any }) =>
      location.value === event.locationType || location.value === 'Telehealth'
  );
  const { provider: leadProvider } = event;
  const [observingProviders, setObservingProviders] = React.useState<
    IProvider[]
  >([]);

  const newObserverIndex = abaObserversList.length + 1;
  const [locationType, setLocationType] = React.useState<LocationType>();
  const [teleHealthLink, setTeleHealthLink] = React.useState<string>();
  const [isProviderSelected, setIsProviderSelected] = React.useState<boolean>(
    true
  );
  const [isTelehealthEntered, setIsTelehealthEntered] = React.useState<boolean>(
    true
  );
  let observersEvent: IEvent = {};

  const onObserverSelect = (observersId: number) => {
    setObserverId(observersId);
    setAbaObserverId(observersId);
    setIsProviderSelected(true);
    observersEvent.id = observersId;
    observersEvent.provider = {};
    observersEvent.provider.id = observersId;
    observersEvent.startDate = methods.getValues('startDate');
    observersEvent.endDate = methods.getValues('endDate');
    observersEvent.duration = methods.getValues('duration');
    setAbaObserversListObj(observersEvent);
    if (event.appointmentType?.eventType?.name === EVENT_TYPES.DT) {
      setAbaLocationType(event.locationType);
      setLocationType(event.locationType);
      observersEvent.locationType = event.locationType;
      setLastAbaObserver(observersEvent);
    }
    observersEvent = {};
    if (locationType !== undefined) {
      observersEvent.id = observersId;
      observersEvent.provider = {};
      if (observersEvent.provider !== undefined)
        observersEvent.provider.id = observersId;
      observersEvent.locationType = locationType;
      observersEvent.telehealthLink = teleHealthLink;
      observersEvent.startDate = methods.getValues('startDate');
      observersEvent.endDate = methods.getValues('endDate');
      observersEvent.duration = methods.getValues('duration');
      setLastAbaObserver(observersEvent);
    }
  };
  const onLocationSelect = (location: LocationType) => {
    setLocationType(location);
    setAbaLocationType(location);
    setIsLocationSelected(true);
    if (observerId !== undefined) {
      observersEvent.id = observerId;
      observersEvent.provider = {};
      if (observersEvent.provider !== undefined)
        observersEvent.provider.id = observerId;
      observersEvent.locationType = location;
      observersEvent.telehealthLink = teleHealthLink;
      observersEvent.startDate = methods.getValues('startDate');
      observersEvent.endDate = methods.getValues('endDate');
      observersEvent.duration = methods.getValues('duration');
      setLastAbaObserver(observersEvent);
    }
  };
  const onLinkChange = (e: any) => {
    setTeleHealthLink(e.target.value);
    if (e.target.value != '') {
      setIsTelehealthEntered(true);
    } else {
      setIsTelehealthEntered(false);
    }
    if (observerId !== undefined && locationType !== undefined) {
      observersEvent.id = observerId;
      observersEvent.provider = {};
      if (observersEvent.provider !== undefined)
        observersEvent.provider.id = observerId;
      observersEvent.locationType = locationType;
      observersEvent.telehealthLink = e.target.value;
      observersEvent.startDate = methods.getValues('startDate');
      observersEvent.endDate = methods.getValues('endDate');
      observersEvent.duration = methods.getValues('duration');
      setLastAbaObserver(observersEvent);
    }
  };
  let eventObj = JSON.parse(JSON.stringify(event));
  const ABA_MAX_DURATION = 60;
  if (eventObj.duration !== undefined && eventObj.duration > ABA_MAX_DURATION) {
    eventObj.duration = ABA_MAX_DURATION;
    eventObj.endDate = moment(eventObj.startDate)
      .clone()
      .add(eventObj.duration, 'minutes');
  }
  const addObserver = () => {
    if (abaObserverId === undefined) {
      setIsProviderSelected(false);
    }
    if (locationType === undefined) {
      setIsLocationSelected(false);
      return;
    }
    const eventStartTime = moment(
      moment(event.startDate).format('HH:mm'),
      'h:mma'
    );
    const eventEndTime = moment(moment(event.endDate).format('HH:mm'), 'h:mma');
    const observerStartTime = moment(
      moment(methods.getValues('startDate')).format('HH:mm'),
      'h:mma'
    );
    const observerEndTime = moment(
      moment(methods.getValues('endDate')).format('HH:mm'),
      'h:mma'
    );
    const durationVal = methods.getValues('duration');
    setIsAlertShown(false);
    setIsValidated(false);
    setAlertContent('');
    if (
      observerStartTime.isBefore(eventStartTime) ||
      observerStartTime.isAfter(eventEndTime) ||
      observerEndTime.isBefore(eventStartTime) ||
      observerEndTime.isAfter(eventEndTime) ||
      (durationVal !== undefined && durationVal < 0)
    ) {
      setIsAlertShown(true);
      setIsValidated(true);
      setAlertContent(ABA_ALERT_MESSAGES.timeAlert);
      return;
    } else if (durationVal !== undefined && durationVal < 15) {
      setIsAlertShown(true);
      setIsValidated(true);
      setAlertContent(ABA_ALERT_MESSAGES.durationAlert);
      return;
    }
    if (abaObserverId !== undefined && locationType !== undefined) {
      observersEvent.id = abaObserverId;
      observersEvent.provider = {};
      if (observersEvent.provider !== undefined)
        observersEvent.provider.id = abaObserverId;
      observersEvent.locationType = locationType;
      observersEvent.telehealthLink = teleHealthLink;
      setTeleHealthLink('');
      const momentStartDate = moment(
        moment(event.startDate).format('YYYY-MM-DD') +
          ' ' +
          moment(methods.getValues('startDate')).format('HH:mm')
      );
      const momentEndDate = moment(
        moment(event.endDate).format('YYYY-MM-DD') +
          ' ' +
          moment(methods.getValues('endDate')).format('HH:mm')
      );
      observersEvent.startDate = momentStartDate;
      observersEvent.endDate = momentEndDate;
      observersEvent.duration = methods.getValues('duration');
      setAbaObserversList((oldObserversList: IEvent[]) => [
        ...oldObserversList,
        observersEvent
      ]);
      setLastAbaObserver({});
      setAbaObserversListObj({});
      setObserverId(undefined);
      setAbaObserverId(undefined);
      setLocationType(undefined);
      setAbaLocationType(undefined);
      methods.setValue('startDate', moment(event.startDate));
      methods.setValue('endDate', moment(eventObj.endDate));
      methods.setValue('duration', eventObj.duration);
      setIsObserversDropdownShown(true);
    }
  };
  useEffect(() => {
    if (!observersData) return;
    const fetchedObservers: IProvider[] = observersData.getObservers;

    const filteredObservers = fetchedObservers;
    setObservingProviders(filteredObservers);
  }, [observersData, leadProvider, abaObserversList]);
  const selectedObservers = abaObserversList.map(
    observer => observer?.provider?.id
  );
  selectedObservers.push(event.provider?.id);
  const observingProvidersFiltered = observingProviders.filter(
    provider => !selectedObservers.includes(provider.id)
  );
  const dropdownOptions = observingProvidersFiltered.map(provider => ({
    value: provider.id,
    label: provider.name
  }));
  const methods = useForm({
    defaultValues: React.useMemo(() => {
      return initValues(eventObj);
    }, [event, observersData]),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });
  return (
    <AddObserversFormWrapper>
      <div className="aba_observer_div">
        <FormItem label={`Observer ${newObserverIndex}`}>
          <Select
            className={!isProviderSelected && `Observer_alert`}
            options={dropdownOptions}
            filterOption={(input: string, option: any) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
            showSearch
            onSelect={onObserverSelect}
            value={abaObserverId}
            listHeight={162}
            placeholder={
              <p style={{ paddingTop: '5px' }}>
                <Icon name="search"></Icon> Search by provider name
              </p>
            }
            field={undefined}
            errors={undefined}
            disabled={isSaving}
          />
          {!isProviderSelected && (
            <span className="Observer_required">Provider is required</span>
          )}
        </FormItem>
        {event.appointmentType?.eventType?.name === EVENT_TYPES.ABA && (
          <>
            <FormItem>
              <Select
                className={!isLocationSelected && `Observer_alert`}
                options={filteredLocations}
                filterOption={(input: string, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
                showSearch
                onSelect={onLocationSelect}
                value={locationType}
                listHeight={162}
                placeholder={
                  <p style={{ paddingTop: '5px' }}>
                    <Icon name="search"></Icon> Please select meeting location
                  </p>
                }
                field={undefined}
                errors={undefined}
                disabled={isSaving}
              />
              {!isLocationSelected && (
                <span className="Observer_required">
                  Location Type is required
                </span>
              )}
            </FormItem>

            {locationType === LOCATION_TYPE_LIST[2].id && (
              <FormItem>
                <Input
                  className={
                    !isTelehealthEntered
                      ? `Observer_Input Observer_alert`
                      : `Observer_Input`
                  }
                  placeholder="Example: https://addlink.com"
                  onChange={onLinkChange}
                />
              </FormItem>
            )}
            <FormProvider {...methods}>
              <TimeDurationForm
                setLastAbaObserver={setLastAbaObserver}
                setAbaObserversList={setAbaObserversList}
                abaObserversList={abaObserversList}
                observerIndex={0}
                leadEvent={event}
                observations={eventObj}
                setIsAlertShown={setIsAlertShown}
                setIsValidated={setIsValidated}
                setAlertContent={setAlertContent}
                setAbaObserversListObj={setAbaObserversListObj}
                observerId={observerId}
              />
            </FormProvider>
          </>
        )}
        {abaObserversList.length < MAX_OBSERVERS - 1 && (
          <button
            disabled={isSaving || isValidated}
            className="add-new-observer"
            onClick={addObserver}
          >
            <UserAddOutlined /> Add Observer
          </button>
        )}
      </div>
      <div className="aba_observer_trash">
        {false && (
          <FontAwesomeIcon icon={faTrashAlt} style={{ color: `#E82424` }} />
        )}
      </div>
    </AddObserversFormWrapper>
  );
};

export default AbaObserversDropdown;
