import styled from 'styled-components';
import { PURPLE, INACTIVE_URL } from 'utils/constants/theme';

export const SearchWrapper = styled.div`
  max-width: 300px;
  min-width: 255px;
  .ant-input-affix-wrapper {
    background: #f4f4f4;
    input {
      background: #f4f4f4;
      padding-right: 10px !important;
      &:hover {
        border-color: ${PURPLE};
      }
      &:focus {
        box-shadow: 0 0 0 2px rgba(111, 75, 241, 0.2);
      }
    }
  }
  .ant-btn-primary {
    background-color: #c9cfd3;
    border-color: #c9cfd3;
    padding: 0 10px;
    .anticon {
      font-weight: 600;
      font-size: 20px;
      color: ${INACTIVE_URL};
    }
  }
`;
