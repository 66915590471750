import { GET_APPOINTMENT_CONFLICTS_AND_AUTHORIZATIONS } from 'api/graphql/queries/events';
import React from 'react';
import { useQuery } from 'react-apollo';
import ModalWarning from './components/ModalWarning';
import ConflictPopup from './components/Other/ConflictPopup';

import { SUBMISSION_STATES } from './submissionReducer';

export default function AppointmentModals({
  state,
  dispatch,
  disabledFormFields
}) {
  useQuery(GET_APPOINTMENT_CONFLICTS_AND_AUTHORIZATIONS, {
    variables: state.warningsVariables,
    onCompleted: data => {
      if (
        Array.isArray(data?.getEventConflictsAndWarnings?.warnings) &&
        data?.getEventConflictsAndWarnings?.warnings.length > 0
      )
        dispatch({
          type: SUBMISSION_STATES.AUTHORIZATIONS,
          payload: {
            warnings: data.getEventConflictsAndWarnings.warnings,
            conflicts: data.getEventConflictsAndWarnings.conflicts,
            eventsLength: data.getEventConflictsAndWarnings.eventsCount
          }
        });
      else if (
        Array.isArray(data?.getEventConflictsAndWarnings?.conflicts) &&
        data?.getEventConflictsAndWarnings?.conflicts.length > 0
      )
        dispatch({
          type: SUBMISSION_STATES.CONFLICTS,
          payload: {
            warnings: data.getEventConflictsAndWarnings.warnings,
            conflicts: data.getEventConflictsAndWarnings.conflicts,
            eventsLength: data.getEventConflictsAndWarnings.eventsCount
          }
        });
      else {
        dispatch({ type: SUBMISSION_STATES.SAVE });
      }
    },
    onError: error => {
      console.error('error while fetching conflicts', error);
      dispatch({ type: SUBMISSION_STATES.START });
    },
    fetchPolicy: 'network-only',
    skip:
      state.currentState !== SUBMISSION_STATES.SUBMIT ||
      !state.warningsVariables
  });

  if (state.currentState === SUBMISSION_STATES.AUTHORIZATIONS)
    return (
      <ModalWarning
        state={state}
        dispatch={dispatch}
        reschedule={disabledFormFields.reschedule}
      />
    );
  else if (state.currentState === SUBMISSION_STATES.CONFLICTS)
    return <ConflictPopup dispatch={dispatch} state={state} />;
  else return null;
}
