import * as yup from 'yup';
import { ABAAppointmentSubType } from 'model/abaAppointmentSubtype';

export const ABA_APPOINTMENT_SUBTYPE_SCHEMA = () =>
  yup.object().shape({
    [ABAAppointmentSubType.title]: yup.string().required('Title is required'),
    [ABAAppointmentSubType.crType]: yup
      .string()
      .required('CR Appointment Type is required'),
    [ABAAppointmentSubType.placeOfService]: yup.object().shape({
      id: yup.string().required('Place Of service is required')
    }),
    [ABAAppointmentSubType.crAuthCode]: yup.object().shape({
      id: yup.string().required('CR Auth code is required')
    }),
    [ABAAppointmentSubType.superType]: yup.object().shape({
      id: yup.string().required('Supertype is required')
    })
  });
