import gql from 'graphql-tag';

export const ADD_AUTH_GROUP = gql`
  mutation($authGroup: AuthGroupInput!) {
    addAuthGroup(authGroup: $authGroup) {
      statusCode
    }
  }
`;

export const EDIT_AUTH_GROUP = gql`
  mutation($authGroupID: String!, $authGroup: AuthGroupInput!) {
    updateAuthGroup(authGroupID: $authGroupID, authGroup: $authGroup) {
      statusCode
    }
  }
`;
