import React from 'react';
import * as PropTypes from 'prop-types';

import { LibButton } from './button.style';

const Button = ({ type, children, ...restProps }) => {
  return (
    <LibButton type={type} {...restProps}>
      {children}
    </LibButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Button.defaultProps = {
  type: 'submit',
  children: null
};

export default Button;
export { Button };
