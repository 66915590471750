import gql from 'graphql-tag';

export const GET_SALESFORCE_THERAPY_MAPPINGS = gql`
  query {
    getSFTherapyMappings {
      id
      salesforceTherapyTitle
    }
  }
`;
