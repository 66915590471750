import React from 'react';
import BusinessHours from './BusinessHours';
import Rooms from './Rooms';
import Staff from './Staff';
import Clients from './Clients';
import { Tabs } from 'lib/ui';

const ClinicTabs = ({ clinic }) => {
  const businessPane = {
    title: 'BUSINESS HOURS',
    content: <BusinessHours businessHours={clinic.clinicBusinessHours} />
  };

  const roomsPane = {
    title: 'ROOMS',
    content: <Rooms rooms={clinic.rooms} />
  };

  const staffPane = {
    title: 'STAFF',
    content: <Staff clinicId={clinic.id} />
  };

  const clientsPane = {
    title: 'CLIENTS',
    content: <Clients clinicId={clinic.id} />
  };

  const panes = [businessPane, roomsPane, staffPane, clientsPane];

  return <Tabs type="card" panes={panes} />;
};

export default ClinicTabs;
export { ClinicTabs };
