import React, { useEffect, useState } from 'react';
import { CollapsibleWrapper } from './style';

interface Props {
  description: string | null;
}
const Collapsible: React.FC<Props> = ({ description }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const [stringLength, setStringLength] = useState(false);
  const expandText = () => {
    setShowMore(!showMore);
  };
  const checkLength = () => {
    if (description) {
      if (description.length > 50) {
        setStringLength(true);
      }
    }
  };

  useEffect(() => {
    checkLength();
  });

  return (
    <CollapsibleWrapper>
      <p className={`collapsible ${showMore ? 'show' : 'hidden'}`}>
        {stringLength && !showMore
          ? description?.substring(0, 50) + '...'
          : description}
      </p>
      {description && stringLength && (
        <span className="cta" onClick={() => expandText()}>
          {!showMore ? 'See more' : ' Show less'}
        </span>
      )}
    </CollapsibleWrapper>
  );
};

export default React.memo(Collapsible);
