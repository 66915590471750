import React from 'react';
import { FormItemWrap } from './formItem.styled';
import { Col } from 'antd';
import * as PropTypes from 'prop-types';

const FormItemNolabel = ({ children, optional, ...otherProps }) => {
  return (
    <Col {...otherProps}>
      <FormItemWrap>
        <div className="top-wrapv2">
          {optional && <span className="optional">optional</span>}
        </div>
        {children}
      </FormItemWrap>
    </Col>
  );
};

FormItemNolabel.propTypes = {
  label: PropTypes.string,
  optional: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

FormItemNolabel.defaultProps = {
  label: null,
  optional: null,
  children: null
};

export default FormItemNolabel;
