import React, { ReactNode } from 'react';
import { Menu } from 'semantic-ui-react';
import { FixedSideBarWrapper } from './style';
interface Props {
  children?: ReactNode;
}
const FixedSideBar: React.FC<Props> = ({ children }: Props) => {
  return (
    <FixedSideBarWrapper>
      <Menu borderless vertical stackable className="side-nav">
        {children}
      </Menu>
    </FixedSideBarWrapper>
  );
};

export default React.memo(FixedSideBar);
