//import { icon } from '@fortawesome/fontawesome-svg-core';
import {
  ICarePlanFieldsForSmartScheduling,
  IFullAppointmentType,
  SidebarState
} from 'model/v2';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import { CarePlanWrapper } from '../style';
import ABACarePlan from './ABACarePlan';
import DTCarePlan from './DTCarePlan';
import MEDCarePlan from './MEDCarePlan';
import QuestionIcon from 'assets/img/icon-question.svg';
import { Popover } from 'antd';
interface Props {
  appointmentCategory: string;
  apptTypes: IFullAppointmentType[];
  smart: boolean;
  setIsSingleAppt: Dispatch<SetStateAction<boolean | undefined>>;
  setSelectedAppointment: Dispatch<SetStateAction<string>>;
  parent_Type_ID: number | undefined;
  subType_Id: number | undefined;
  sidebarState?: SidebarState;
  isWaitlistDataLoading: boolean;
  showCarePlanWaitlistInfo: boolean;
  carePlanWaitlistData: ICarePlanFieldsForSmartScheduling;
  setSelectedAppointmentType: Dispatch<
    SetStateAction<IFullAppointmentType | undefined>
  >;
  selectedAppointmentType: IFullAppointmentType;
}
const CarePlanDetails: React.FC<Props> = ({
  appointmentCategory,
  apptTypes,
  smart,
  setIsSingleAppt,
  setSelectedAppointment,
  parent_Type_ID,
  subType_Id,
  sidebarState,
  isWaitlistDataLoading,
  showCarePlanWaitlistInfo,
  carePlanWaitlistData,
  setSelectedAppointmentType,
  selectedAppointmentType
}: Props) => {
  const [isABA] = useState(
    appointmentCategory === APPOINTMENT_CATEGORY[0].value ? true : false
  );
  const [open, setOpen] = useState<boolean>(true);
  const { ABAApptTypes, DTApptTypes, MEDApptTypes } = useMemo(() => {
    const ABAApptTypes = apptTypes.filter(
      appt => appt.eventType?.name === EVENT_TYPES.ABA
    );
    const DTApptTypes = apptTypes.filter(
      appt => appt.eventType?.name === EVENT_TYPES.DT
    );
    const MEDApptTypes = apptTypes.filter(
      appt => appt.eventType?.name === EVENT_TYPES.MED
    );
    return { ABAApptTypes, DTApptTypes, MEDApptTypes };
  }, [apptTypes]);

  const handleClick = () => {
    setOpen(!open);
  };

  const getContent = () => {
    if (appointmentCategory === APPOINTMENT_CATEGORY[0].value)
      return (
        <ABACarePlan
          apptTypes={ABAApptTypes}
          smart={smart}
          setIsSingleAppt={setIsSingleAppt}
          setSelectedAppointment={setSelectedAppointment}
          isWaitlistDataLoading={isWaitlistDataLoading}
          showCarePlanWaitlistInfo={showCarePlanWaitlistInfo}
          carePlanWaitlistData={carePlanWaitlistData}
        />
      );
    else if (appointmentCategory === APPOINTMENT_CATEGORY[1].value)
      return (
        <DTCarePlan
          apptTypes={DTApptTypes}
          setIsSingleAppt={setIsSingleAppt}
          setSelectedAppointment={setSelectedAppointment}
          parent_Type_ID={parent_Type_ID}
          subType_Id={subType_Id}
          sidebarState={sidebarState}
          isWaitlistDataLoading={isWaitlistDataLoading}
          showCarePlanWaitlistInfo={showCarePlanWaitlistInfo}
          carePlanWaitlistData={carePlanWaitlistData}
          setSelectedAppointmentType={setSelectedAppointmentType}
          selectedAppointmentType={selectedAppointmentType!}
        />
      );
    else if (appointmentCategory === APPOINTMENT_CATEGORY[4].value)
      return (
        <MEDCarePlan
          apptTypes={MEDApptTypes}
          setIsSingleAppt={setIsSingleAppt}
          setSelectedAppointment={setSelectedAppointment}
        />
      );
    return undefined;
  };
  const popoverContent = useMemo(() => {
    return (
      <p>
        The information below should reflect the remaining care plan requested
        sessions for this client. This will find results closest to the details
        entered
      </p>
    );
  }, []);

  return (
    <CarePlanWrapper>
      <Accordion>
        <Accordion.Title active={true} onClick={handleClick}>
          <Grid className="grid">
            <div className="rectangle"></div>
            <Grid.Row className="care-plan" floated="left" width={60}>
              Care Plan Details
              {isABA ? (
                <Popover
                  placement="bottomLeft"
                  content={popoverContent}
                  title="Care Plan Details"
                  trigger="hover"
                >
                  <img
                    alt="ques"
                    src={QuestionIcon}
                    width={22}
                    style={{ paddingLeft: 4 }}
                  />
                </Popover>
              ) : (
                <img
                  alt="ques"
                  src={QuestionIcon}
                  width={22}
                  style={{ paddingLeft: 4 }}
                />
              )}
              <Grid.Column floated="right" width="1" className="arrow">
                {open ? <Icon name="angle up" /> : <Icon name="angle down" />}
              </Grid.Column>
            </Grid.Row>
            <div className="rectangle"></div>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={open} content={getContent()} />
      </Accordion>
    </CarePlanWrapper>
  );
};
export default React.memo(CarePlanDetails);
