import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Table } from 'lib/ui/';
import { DEFAULT_PAGE_SIZE } from 'utils/constants/default';

const TableComponent = ({
  columns,
  data,
  total,
  page,
  onPageChangeCallback,
  onRowSelect,
  ...restProps
}) => {
  const numberOfPages = Math.ceil(total / DEFAULT_PAGE_SIZE);
  const { isClient } = restProps;
  const handlePageChange = page => {
    if (page <= numberOfPages) {
      onPageChangeCallback(page);
    }
  };
  const pagination = {
    total,
    current: Number(page),
    defaultPageSize: DEFAULT_PAGE_SIZE,
    showSizeChanger: false,
    onChange: page => handlePageChange(page)
  };
  const handleClick = () => {
    if (restProps.clearAllSelectedFilters) restProps.clearAllSelectedFilters();
  };

  const CustomEmptyState = () => {
    if (!isClient)
      return (
        <div>
          <p className="no-data">
            <strong>No Results Found</strong>
          </p>

          <p className="no-data">
            It looks like there are no results matching your filter criteria.
            Please modify your filters or clear them to view all results.
          </p>
        </div>
      );
    else {
      return (
        <div>
          <p className="no-data">
            <strong>No Clients Found</strong>
          </p>
        </div>
      );
    }
  };
  const [isEmptyData, setIsEmptyData] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (restProps?.dataSource?.length === 0) {
        setIsEmptyData(true);
      } else if (isClient) {
        if (data?.length === 0) {
          setIsEmptyData(true);
        }
      } else {
        setIsEmptyData(false);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [restProps.dataSource, data]);
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = e => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };
  return (
    <div
      className="table-scroll-container"
      ref={scrollContainerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        rowKey="id"
        onRow={row => ({
          onClick: () => {
            onRowSelect(row);
          }
        })}
        locale={{ emptyText: isEmptyData ? <CustomEmptyState /> : ' ' }}
        {...restProps}
      />
    </div>
  );
};
export default TableComponent;
