import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import CalendarFilter from '../../../ui/calendarFilter';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { mapToCheckListOptions } from 'utils/mappers/form';
import { ICalendarFilterOption } from 'model/calendar/filters';
import { DEFAULT_FILTER } from 'utils/constants/calendarFilters';

interface Props {
  handleSearchedValues: (
    list: ICalendarFilterOption[],
    checkedList: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  setHasData: Dispatch<SetStateAction<boolean>>;
  checkedLocations: ICalendarFilterOption[];
  setCheckedLocations: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
}

const LocationFilter = ({
  handleSearchedValues,
  checkedLocations,
  setCheckedLocations,
  setHasData
}: Props) => {
  const locations: ICalendarFilterOption[] = useMemo(() => {
    return mapToCheckListOptions(
      LOCATION_TYPE_LIST,
      DEFAULT_FILTER.id,
      DEFAULT_FILTER.value
    );
  }, []);

  const searchedLocations = useMemo(() => {
    const searchedValues = handleSearchedValues(locations, checkedLocations);

    return searchedValues;
  }, [handleSearchedValues, locations, checkedLocations]);

  useEffect(() => {
    searchedLocations?.length === 0 ? setHasData(false) : setHasData(true);
  }, [searchedLocations, setHasData]);

  return (
    <>
      <CalendarFilter
        setCheckedList={setCheckedLocations}
        checkedList={checkedLocations}
        listOptions={searchedLocations}
        length={locations.length}
        title="Location"
      />
    </>
  );
};
export default React.memo(LocationFilter);
