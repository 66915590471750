import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckListWrapper } from 'components/calendar/filters/style';
import useClickOutsideHook from 'hooks/clickOutsideHook';
import { ISpecialitiesOptionsList } from 'model/v2/providerSpecialty';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Input } from 'semantic-ui-react';
import SpecialtyItems from './SpecialtyItems';
import { ProviderCheckListWrapper } from './style';

interface IProps {
  allSpecialties: ISpecialitiesOptionsList[];
  selectedSpecialtiesList: Set<number>;
  setSelectedSpecialtiesList: Dispatch<SetStateAction<Set<number>>>;
}
const SpecialtySelector: React.FC<IProps> = ({
  allSpecialties,
  selectedSpecialtiesList,
  setSelectedSpecialtiesList
}: IProps) => {
  const [isVisibleList, setIsVisibleList] = useState(false);
  const [selectedSpecialties, setSelectedSpecialties] = useState<String[]>([]);
  const [checkedItems, setCheckedItems] = useState(new Set<number>());
  const [searchResults, setSearchResults] = useState<
    ISpecialitiesOptionsList[]
  >(allSpecialties);

  const filtersRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setSearchResults(allSpecialties);
  }, [allSpecialties]);

  const closeFiltersList = useCallback(() => {
    setIsVisibleList(false);
  }, [setIsVisibleList]);

  useClickOutsideHook(filtersRef, closeFiltersList);

  const toggleList = useCallback(() => {
    setIsVisibleList(prev => !prev);
  }, [setIsVisibleList]);

  const onHandleCheckChange = (id: number, isChecked?: boolean) => {
    if (isChecked) {
      setCheckedItems(prev => new Set(prev).add(id));
    } else {
      setCheckedItems(prev => {
        const next = new Set(prev);
        next.delete(id);
        return next;
      });
    }
  };

  const onClearAll = useCallback(() => {
    setCheckedItems(new Set());
  }, [setCheckedItems]);

  const onApply = useCallback(() => {
    setSelectedSpecialtiesList(new Set(checkedItems));
    setSelectedSpecialties([]);
    checkedItems.forEach(result => {
      const specialty = searchResults.find(
        specialty => parseInt(specialty.id!) === result
      );
      setSelectedSpecialties((oldSpecialtyList: String[]) => [
        ...oldSpecialtyList,
        specialty!.title!
      ]);
    });
    setIsVisibleList(false);
  }, [setSelectedSpecialtiesList, setIsVisibleList, checkedItems]);

  useEffect(() => {
    setCheckedItems(new Set(selectedSpecialtiesList));
  }, [selectedSpecialtiesList]);

  return (
    <ProviderCheckListWrapper>
      <CheckListWrapper width={368} visible={isVisibleList} height={'162px'}>
        <div ref={filtersRef}>
          <div
            className={
              selectedSpecialties.length > 0
                ? 'filter-input_wrapper specialty-dropdown'
                : 'filter-input_wrapper'
            }
            onClick={toggleList}
          >
            <Input
              className={
                selectedSpecialties.length > 0
                  ? 'filter_input specialties-selected'
                  : 'filter_input'
              }
              style={{ paddingLeft: 0 }}
              placeholder={
                selectedSpecialties.join(', ') || 'Filter by Specialty'
              }
              disabled
            />
            {!isVisibleList && (
              <FontAwesomeIcon
                className={
                  selectedSpecialties.length > 0
                    ? 'icon specialty-icon'
                    : 'icon'
                }
                icon={faCaretDown}
              />
            )}
          </div>
          {isVisibleList && (
            <div className="list_wrapper">
              <div className="scrollable_list">
                {searchResults.length === 0 && (
                  <p className="no-results_filter">No results found</p>
                )}
                <SpecialtyItems
                  specialties={searchResults}
                  checkedItems={checkedItems}
                  onHandleChange={onHandleCheckChange}
                />
              </div>
              <div className="calender-filters_actions">
                <span onClick={onClearAll}>Clear all</span>
                <button
                  onClick={onApply}
                  disabled={false}
                  className={checkedItems.size > 0 ? 'highlightButton' : ''}
                >
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </CheckListWrapper>
    </ProviderCheckListWrapper>
  );
};
export default React.memo(SpecialtySelector);
