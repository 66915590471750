import React from 'react';
import {
  IModalDownStreamsConfigurations,
  MESSAGES_TYPES,
  getDownStreamStatusConfigurations
} from 'utils/downstreams/DownStreamModalConfigurations';
import { StatusViewWrapper } from './style';
import { Grid } from 'semantic-ui-react';
import StatusAlert from './StatusAlert';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { useMutation } from 'react-apollo';
import {
  RETRY_LAST_SYNC_ACTION,
  FORCE_FAIL_ACTION
} from 'api/graphql/v2/mutations/Event';
import { IEvent } from 'model/v2';

interface Props {
  configurations?: IModalDownStreamsConfigurations;
  event: IEvent;
  reloadEvent: () => void;
  isLoading?: boolean;
}
const StatusView: React.FC<Props> = ({ event, reloadEvent }: Props) => {
  const [syncTrials, setSyncTrials] = React.useState(
    event?.syncAction?.retryCount || 0
  );
  const [retrySync, { loading: retryIsLoading }] = useMutation(
    RETRY_LAST_SYNC_ACTION,
    {
      onCompleted: data => {
        setSyncTrials(data.retryLastSyncAction.retryCount);
        reloadEvent();
      }
    }
  );
  const [forceFail, { loading: forceFailIsLoading }] = useMutation(
    FORCE_FAIL_ACTION,
    {
      onCompleted: () => {
        reloadEvent();
      }
    }
  );

  const eventConfigurations = getDownStreamStatusConfigurations(
    event?.crStatus,
    event?.athenaStatus,
    false
  );
  const [onRetryLoading, setOnRetryLoading] = React.useState(false);
  const maxResyncTrials = 3;

  React.useEffect(() => {
    setOnRetryLoading(false);
  }, [eventConfigurations]);

  const resync = () => {
    if (!event || !event.id) {
      return;
    }
    if (!retryIsLoading) {
      setOnRetryLoading(true);
      retrySync({ variables: { eventId: event.id } });
    }
  };

  const handleForceFail = () => {
    if (!event || !event.id) {
      return;
    }
    if (!forceFailIsLoading) {
      forceFail({ variables: { eventId: event.id } });
    }
  };

  return (
    <StatusViewWrapper>
      <Grid columns={2}>
        {getFeatureAvailability(FEATURES.RETRY_SYNC) &&
          (eventConfigurations.athenaStatusMessageType ===
            MESSAGES_TYPES.fail ||
            eventConfigurations.crStatusMessageType === MESSAGES_TYPES.fail) &&
          syncTrials < maxResyncTrials && (
            <Grid.Row>
              <p>**These appointments failed to sync. Please try again…</p>
            </Grid.Row>
          )}
        {(eventConfigurations.athenaStatusMessageType === MESSAGES_TYPES.fail ||
          eventConfigurations.crStatusMessageType === MESSAGES_TYPES.fail) &&
          syncTrials >= maxResyncTrials && (
            <Grid.Row>
              <p>
                This event has failed over 3 times. Try waiting a while before
                trying again.
              </p>
            </Grid.Row>
          )}
        <Grid.Row>
          <Grid.Column width={11}>
            <StatusAlert
              statusMessage={eventConfigurations.athenaStatusMessage}
              statusType={eventConfigurations.athenaStatusMessageType}
            />
            <StatusAlert
              statusMessage={eventConfigurations.crStatusMessage}
              statusType={eventConfigurations.crStatusMessageType}
            />
          </Grid.Column>
          {(eventConfigurations.athenaStatusMessageType ===
            MESSAGES_TYPES.fail ||
            eventConfigurations.crStatusMessageType ===
              MESSAGES_TYPES.fail) && (
            <Grid.Column floated="right" width={5}>
              {getFeatureAvailability(FEATURES.RETRY_SYNC) && (
                <button
                  className={
                    onRetryLoading ? 'resync-button onLoading' : 'resync-button'
                  }
                  onClick={resync}
                  disabled={onRetryLoading}
                >
                  Start Re-sync
                </button>
              )}
            </Grid.Column>
          )}
          {eventConfigurations.cancelDisabled && (
            <Grid.Column floated="right" width={5}>
              {getFeatureAvailability(FEATURES.FORCE_FAIL) && (
                <button
                  className={
                    forceFailIsLoading
                      ? 'force-fail-button onLoading'
                      : 'force-fail-button'
                  }
                  onClick={handleForceFail}
                  disabled={forceFailIsLoading}
                >
                  Force Fail
                </button>
              )}
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </StatusViewWrapper>
  );
};
export default React.memo(StatusView);
