import { UsaStates } from 'usa-states';
import { tz } from 'moment-timezone';

export const TIMEZONES_LIST = tz.names();
export const US_STATES_LIST = new UsaStates().states.map(state => ({
  id: state.name,
  value: state.name
}));

export const STATES = new UsaStates().states.map(state => state.abbreviation);
