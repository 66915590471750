import * as React from 'react';
import Thead from './Thead';
import Tbody from './Tbody';
import { Table, SemanticWIDTHSNUMBER } from 'semantic-ui-react';
import {
  ITableColumn,
  ITableRow,
  TableSortDirection,
  ITableComp
} from './type';
import { TableWrapper } from './style';

interface ITableProps {
  headers: ITableColumn[];
  data: ITableRow[];
  primarySortedKey: string;
  sort?: (comp: ITableComp, reverse: boolean) => void;
}

const TableComponent: React.FC<ITableProps> = (props: ITableProps) => {
  const { headers, data, primarySortedKey, sort } = props;
  const [direction, setDirection] = React.useState(
    TableSortDirection.ascending
  );
  const [clickedCol, setClickedCol] = React.useState(primarySortedKey);

  const changeSort = React.useCallback(
    (comp, col) => {
      if (sort) {
        sort(comp, direction === TableSortDirection.ascending ? false : true);
        setDirection(
          direction === TableSortDirection.ascending
            ? TableSortDirection.descending
            : TableSortDirection.ascending
        );
      }
      setClickedCol(col);
    },
    [direction, sort]
  );

  return (
    <TableWrapper>
      <Table
        sortable
        celled
        striped
        columns={headers.length as SemanticWIDTHSNUMBER} //Note: table has maximum of 16 coloumns
      >
        <Thead
          headers={headers}
          direction={direction}
          clickedCol={clickedCol}
          changeSort={changeSort}
        />
        <Tbody headers={headers} rows={data} />
      </Table>
    </TableWrapper>
  );
};

export default React.memo(TableComponent);
