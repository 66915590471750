import * as yup from 'yup';
import { PHONE_REGEX, ZIP_REGEX } from 'utils/constants/regex';
import { CLINIC_TYPES } from 'utils/constants/lists';
import { IClinic } from 'model/v2';

export const CLINIC_FORM_SCHEMA = (
  clinics: IClinic[],
  isEdit: boolean,
  currentClinicID?: number
) =>
  yup.object().shape({
    phone: yup
      .string()
      .required('Phone is required')
      .matches(PHONE_REGEX, 'Phone number is not valid'),
    clinicType: yup
      .string()
      .nullable()
      .test(
        'is-dependent-hub',
        "Can't convert a hub which already has spokes or couldn't fetch clinic data",
        function(value, _context) {
          if (
            Array.isArray(clinics) &&
            value !== CLINIC_TYPES[0].id &&
            isEdit
          ) {
            return (
              clinics?.filter(cl => cl.clinicHub?.id === currentClinicID)
                .length === 0
            );
          }
          return true;
        }
      ),
    clinicHub: yup.object().when('clinicType', {
      is: CLINIC_TYPES[1].id,
      then: yup
        .object()
        .shape({
          id: yup.string().nullable()
        })
        .required('Hub must be chosen')
    }),
    centralReachId: yup.string().required('CR ID is required'),
    centralReachPk: yup.string().required('CR PK is required'),
    fax: yup
      .string()
      .required('Fax is required')
      .matches(PHONE_REGEX, 'Fax is not valid'),
    address: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        addressLine1: yup
          .string()
          .trim()
          .nullable()
          .required('Address Line 1 is required'),
        addressLine2: yup.string().nullable(),
        state: yup
          .string()
          .nullable()
          .required('State is required'),
        city: yup
          .string()
          .trim()
          .nullable()
          .required('City is required'),
        zipCode: yup
          .string()
          .nullable()
          .required('Zip is required')
          .matches(ZIP_REGEX, 'Zip is not valid')
      })
      .required('Location Details are required'),
    name: yup.string().required('Clinic Name Id is required'),
    abbreviation: yup.string().required('Clinic Abbreviation is required'),
    departmentId: yup.string().required('Athena ID beside email section'),
    timezone: yup.string().required('Timezone is required'),
    centralReachLocationId: yup.string().required('CR Location ID is required'),
    unavailableClientId: yup
      .string()
      .nullable()
      .required('Admin Appointment ID is required')
  });
