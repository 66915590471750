import React, { useCallback } from 'react';
import { CancelConfirm } from './MainCalendar.style';
import { RadioGroup } from 'lib/ui';
import { useState } from 'react';
import { IS_LAST_EVENT } from 'api/graphql/v2/queries/Events';
import { useQuery } from '@apollo/react-hooks';
import { EVENT_RESCHEDULE_OPTIONS } from 'utils/constants/lists';
import Content from 'views/components/ui/content';
import { MODAL_AUTOMATA } from './ModalWrapper';
export const ModalEdit = ({
  editOption,
  setStateValueHandler,
  setStep,
  eventID,
  masterID,
  editEventBehavior,
  step
}) => {
  const [cancelOptions, setCancelOptions] = useState(
    EVENT_RESCHEDULE_OPTIONS(false)
  );
  const [loading, setLoading] = useState(!!masterID);

  useQuery(IS_LAST_EVENT, {
    variables: {
      id: eventID,
      masterId: masterID
    },
    fetchPolicy: 'no-cache',
    skip: !masterID,
    onCompleted: data => {
      const options = data.isLastEventInSeries
        ? EVENT_RESCHEDULE_OPTIONS(false)
        : EVENT_RESCHEDULE_OPTIONS(true);
      setCancelOptions(options);
      setLoading(false);
    }
  });

  const handleNextState = useCallback(() => {
    if (step === MODAL_AUTOMATA.MODAL_EDIT_BEHAVIOR) {
      editEventBehavior();
    } else {
      // this step is step === MODAL_AUTOMATA.MODAL_EDIT
      setStep(MODAL_AUTOMATA.MODAL_REASONS);
    }
  }, [step, editEventBehavior, setStep]);

  return (
    <Content loading={loading} data={cancelOptions}>
      {() => (
        <CancelConfirm>
          <p>Edit recurring event</p>
          <RadioGroup
            defaultValue={editOption.cancelOptions}
            onChange={e => {
              setStateValueHandler('cancelOptions', e.target.value);
            }}
            options={cancelOptions}
          />
          <div className="ant-popover-buttons">
            <button
              type="button"
              className="ant-btn ant-btn-sm"
              onClick={() => setStep(MODAL_AUTOMATA.MODAL_CONTENT)}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className="ant-btn ant-btn-primary ant-btn-sm"
              onClick={handleNextState}
            >
              <span>OK</span>
            </button>
          </div>
        </CancelConfirm>
      )}
    </Content>
  );
};
