import { eventsFragments } from 'api/graphql/v2/fragments/Events';
import { EventSyncNoticeData } from 'api/graphql/v2/fragments/Subscriptions';
import gql from 'graphql-tag';

export const SYNC_STATUS_SUBSCRIPTION = gql`
  subscription eventSyncStatus($eventId: Int!) {
    eventSyncStatus(eventId: $eventId) {
      eventId
      crStatus
      athenaStatus
    }
  }
`;

export const CALENDAR_EVENTS_SUBSCRIPTION = gql`
  subscription calendarEvents(
    $startDate: DateTime!
    $endDate: DateTime!
    $providerId: Int
    $clientId: Int
  ) {
    calendarEvents(
      startDate: $startDate
      endDate: $endDate
      providerId: $providerId
      clientId: $clientId
    ) {
      ...FullEventData
      ...CalendarCancelledEventData
    }
  }
  ${eventsFragments.fullEventData}
  ${eventsFragments.calendarCancelledEventData}
`;

export const SYNC_FAILURE_SUBSCRIPTION = gql`
  subscription eventSyncFailureNotice($CreatedBy: String!) {
    eventSyncFailureNotice(CreatedBy: $CreatedBy) {
      ...EventSyncNoticeData
    }
  }
  ${EventSyncNoticeData}
`;

export const SYNC_SUCCESS_SUBSCRIPTION = gql`
  subscription eventSyncSuccessNotice($CreatedBy: String!) {
    eventSyncSuccessNotice(CreatedBy: $CreatedBy) {
      ...EventSyncNoticeData
    }
  }
  ${EventSyncNoticeData}
`;
