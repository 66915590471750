import React, { useMemo, useState } from 'react';
import { times, TimeSlot } from 'model/v2';
import { TimeSlotWrapper } from './Style';
import { Grid } from 'semantic-ui-react';
// import { DropDownProps } from 'antd';
import moment from 'antd/node_modules/moment';
import { Controller, useFormContext } from 'react-hook-form';
import SelectList from 'api/sharedComponents/reactHookFormComponents/semantic/SelectList';
// import momentTimezone from 'moment-timezone';

interface IProps {
  times: TimeSlot[];
  timeZone: string;
  setProviderTime: (val: times) => void;
  setClientTimeZone: (val: string) => void;
  selectedIndex: number | undefined;
  index: number;
}

const TimeSlotSelector: React.FC<IProps> = ({
  times,
  timeZone,
  setProviderTime,
  setClientTimeZone,
  selectedIndex,
  index
}: IProps) => {
  const { control, setValue, errors } = useFormContext();
  const option1 = { timeZone: timeZone };
  const zone = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short', timeZone: timeZone })
    .split(' ')[2];
  setClientTimeZone(zone);
  const Slots = useMemo(() => {
    return times.map((time: TimeSlot) => {
      const dateTimeStart = new Date(time.startTime);
      const dateTimeEnd = new Date(time.endTime);
      return {
        value: time,
        text:
          moment(dateTimeStart.toLocaleString('en-US', option1)).format(
            'hh:mm A'
          ) +
          ' - ' +
          moment(dateTimeEnd.toLocaleString('en-US', option1)).format('hh:mm A')
      };
    });
  }, [times]);
  const [slotTiming, setSlotTiming] = useState(Slots[0].value);
  return (
    <>
      <TimeSlotWrapper>
        <Grid>
          <Grid.Row>
            <Controller
              name={`time-dropdown`}
              control={control}
              defaultValue={Slots[0].value}
              render={props => (
                <SelectList
                  field={props}
                  errors={errors}
                  options={Slots}
                  value={slotTiming}
                  disabled={selectedIndex !== index}
                  onChange={(_view: any, data: any) => {
                    setValue(`time-dropdown`, data.value);
                    setValue(`result.startTime`, data.value.startTime);
                    setValue(`result.endTime`, data.value.endTime);
                    setProviderTime(data.value);
                    setSlotTiming(data.value);
                    // TODO: somehow this needs to trigger update in phantom Event
                  }}
                />
              )}
            />
          </Grid.Row>
        </Grid>
      </TimeSlotWrapper>
    </>
  );
};

export default TimeSlotSelector;
