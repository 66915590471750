import React from 'react';
import { Datepicker as LibDatepicker } from 'lib/ui/datepicker';
import { ErrorMessage } from 'formik';

const Datepicker = props => {
  const { field, ...restProps } = props;
  const onChangeValue = value => {
    // because Formik expects normal Event with normal target, not single value
    const event = {
      target: {
        name: field.name,
        value
      }
    };
    field.onChange(event);
  };
  return (
    <>
      <LibDatepicker
        onChange={onChangeValue}
        getCalendarContainer={trigger => trigger.parentNode}
        {...restProps}
      />
      <ErrorMessage name={field.name}>
        {msg => <div className="error">{msg}</div>}
      </ErrorMessage>
    </>
  );
};

export default Datepicker;
export { Datepicker };
