import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { APPOINTMENT_ADD_SCHEMA } from 'utils/validators/calendar';
import { yupResolver } from '@hookform/resolvers/yup';
import Header from 'views/containers/header';
import NewAppointmentComponents from './NewAppointmentComponents';
import { useClinicsAndStaffList, useEventTypes } from './newAppointmentHooks';
import { NewAppointmentWrap } from './NewAppointment.style';
import Content from 'views/components/ui/content';
import { SUBMISSION_STATES, useSubmissionReducer } from './submissionReducer';
import AppointmentModals from './AppointmentModals';
import { getConflictsVariables } from './newAppointmentHelpers';

// This is the Main HOC for appointment form creation
// This component responsible for all data fetching and gathering to generate the form

// SELECTING THE CLIENT & INITIALIZE THE FORM
export default function NewAppointment({
  formEvent,
  disabledFormFields, // contains reschedule and isEdit
  eventMeta,
  client,
  apptTypes
}) {
  const history = useHistory();
  const { eventTypes, loadingEventTypes } = useEventTypes();
  const { clinics, loading: loadingClinics } = useClinicsAndStaffList();

  // requesting client
  // define form
  const methods = useForm({
    mode: 'all', // validation on fields run mode
    reValidateMode: 'onChange', // fields with error recheck validation
    defaultValues: formEvent,
    resolver: yupResolver(APPOINTMENT_ADD_SCHEMA),
    shouldFocusError: false,
    shouldUnregister: false
  });

  const submitError = useCallback(
    async (errors, e) => {
      methods.trigger();
    },
    [methods]
  );

  const pageTitle = formEvent.id ? 'Edit Appointment' : 'New Appointment';

  const [submissionState, submissionStateDispatch] = useSubmissionReducer(
    apptTypes,
    eventTypes,
    client,
    clinics,
    formEvent.id, //TODO: remove this as it is in formEvent which is in react hook form state
    eventMeta
  );

  const submitForm = useCallback(
    (data, e) => {
      if (disabledFormFields.isEditBehaviorOnly) {
        submissionStateDispatch({
          type: SUBMISSION_STATES.SAVE,
          payload: {
            formData: data
          }
        });
      } else {
        //TODO: submit if create and not recurring
        const computedConflictsVariables = getConflictsVariables(
          data,
          eventMeta,
          client,
          apptTypes
        );
        submissionStateDispatch({
          type: SUBMISSION_STATES.SUBMIT,
          payload: {
            formData: data,
            warningsVariables: computedConflictsVariables
          }
        });
      }
    },
    [disabledFormFields, eventMeta, apptTypes, client, submissionStateDispatch]
  );

  return (
    <FormProvider {...methods}>
      <Header
        title={pageTitle}
        buttonText="Save Appointment"
        loading={submissionState.loading || loadingClinics || loadingEventTypes}
        handleSubmit={methods.handleSubmit(submitForm, submitError)}
        onCancel={() => history.goBack()}
        newAppointment={{
          appointmentTitle: methods.watch('appType'),
          clientSelected: true
        }}
        // disabled={!methods.formState.isDirty || !methods.formState.isValid}
      />
      <NewAppointmentWrap>
        <AppointmentModals
          state={submissionState}
          dispatch={submissionStateDispatch}
          disabledFormFields={disabledFormFields}
        />
        <Content
          loading={loadingEventTypes || loadingClinics}
          data={true}
          error={false}
        >
          {() => (
            <NewAppointmentComponents
              disabledFormFields={disabledFormFields}
              client={client}
            />
          )}
        </Content>
      </NewAppointmentWrap>
    </FormProvider>
  );
}
