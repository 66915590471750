import React from 'react';
import { Button } from 'lib/ui/';
import { EVENT_STATUS } from 'utils/constants/status';

export const ModalButtons = ({
  loading,
  isAdminEvent,
  isPastEvent,
  isClinical,
  status,
  isReschedulable,
  rescheduleAction,
  editTimeAction,
  editBehaviorAction,
  cancelAction,
  configurations
}) => {
  return (
    <>
      {!isAdminEvent && isReschedulable(status) && (
        <Button
          className="secondary"
          disabled={loading || configurations.rescheduleDisabled}
          loading={loading}
          onClick={rescheduleAction}
        >
          Reschedule
        </Button>
      )}
      {isPastEvent && isClinical === false && !isAdminEvent && (
        <Button
          className="secondary"
          disabled={loading || configurations.rescheduleDisabled}
          loading={loading}
          onClick={editTimeAction}
        >
          Edit
        </Button>
      )}
      {!isAdminEvent && !isPastEvent && (
        <Button
          className="secondary"
          disabled={loading || configurations.editDisabled}
          loading={loading}
          onClick={editBehaviorAction}
        >
          Edit
        </Button>
      )}
      {status !== EVENT_STATUS.canceled && (
        <Button
          className="secondary red"
          disabled={loading || configurations.cancelDisabled}
          loading={loading}
          onClick={cancelAction}
        >
          Cancel
        </Button>
      )}
    </>
  );
};
