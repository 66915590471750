import gql from 'graphql-tag';

export const dayAvailability = gql`
  fragment DayAvailabilityOutput on DayAvailabilityOutput {
    id
    startTime
    endTime
    type
  }
`;

export const clientsFragment = {
  ClientData: gql`
    fragment ClientData on Client {
      id
      isActive
      isTestClient
      firstName
      lastName
      athenaId
      crId
      usualProvider {
        id
      }
      clientType
      availabilityStatus
      frequency
      firstNameUsed
      registrationDate
      clientType
      clientTypeNote {
        id
        note
        __typename @skip(if: true)
      }
    }
  `,
  ClientRosterData: gql`
    fragment ClientRosterData on Client {
      id
      isActive
      petAllergies
      languages {
        id
        name
      }
      restrictedProviders {
        id
      }
    }
  `,
  EventClient: gql`
    fragment EventClient on Client {
      id
      name
      clinic {
        id
        name
        abbreviation
      }
    }
  `,
  ClientProfile: gql`
    fragment ClientProfile on ClientProfile {
      id
      aboutCortica
      consentToEmailPHI
      consentToCall
      consentToText
      cellPhone
      legalGuardianFirstName
      legalGuardianLastName
      fatherEmail
      fatherName
      motherEmail
      motherName
      motherPhone
      fatherPhone
      legalSex
      medicalHistoryAuthority
      memberNumber
      noticesOnFile
      patientRelationshipToSubscriber
      phenoType
      motherNote {
        id
        note
        __typename @skip(if: true)
      }
      fatherNote {
        id
        note
        __typename @skip(if: true)
      }
      note {
        id
        note
        __typename @skip(if: true)
      }
      communication
      cognition
      behavior
      sensorimotor
      phonePreference
      physicianPhoneNumber
      subscriberFirstName
      subscriberLastName
      subscriberDOB
      subscriberSSN
      groupNumber
      homePhone
      insuranceProvider
      gender
      dob
      personalEmail
      otherAuthorizedGuardians
      __typename @skip(if: true)
    }
  `,
  AddressData: gql`
    fragment ClientAddressData on Address {
      id
      addressLine1
      addressLine2
      state
      city
      name
      zipCode
      country
      isPrimary
      __typename @skip(if: true)
    }
  `,
  clientAvailabilities: gql`
    fragment ClientAvailabilities on AvailabilitiesOutput {
      sun {
        ...DayAvailabilityOutput
      }
      mon {
        ...DayAvailabilityOutput
      }
      tues {
        ...DayAvailabilityOutput
      }
      wed {
        ...DayAvailabilityOutput
      }
      thurs {
        ...DayAvailabilityOutput
      }
      fri {
        ...DayAvailabilityOutput
      }
      sat {
        ...DayAvailabilityOutput
      }
    }
    ${dayAvailability}
  `,

  ClientBasicData: gql`
    fragment ClientBasicData on ClientBasicData {
      id
      athenaId
      crId
      legalGuardianFirstName
      legalGuardianLastName
      fatherEmail
      dob
    }
  `
};
