import { useReactiveVar } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { GET_CLIENT } from 'api/graphql/v2/queries/Clients';
import { GET_FULL_EVENT } from 'api/graphql/v2/queries/Events';
import { GET_PROVIDER_MEMBER } from 'api/graphql/v2/queries/Providers';
import Calendar from 'components/calendar/calendar';
import CalendarSidebar from 'components/calendar/calendarSidebar/index';
import PlannerCalendar from 'components/calendar/planner/plannerCalendar';
import DownStreamsCheckBanner from 'components/downStreamsCheckBanner';
import EventsErrorBanner from 'components/eventsErrorCheckBanner';
import {
  editAppointmentHandler,
  formRedirectHandler,
  getCalendarLoadingState,
  getCurrentPaginationArray,
  getGroupsToFetch,
  isPhantomEvent,
  newAppointmentHandler,
  newClientAppointmentHandler,
  newProviderAppointmentHandler,
  viewAppointmentHandler
} from 'helpers/calendarHelper';
import {
  getCancelFilterStatus,
  getDeletedFilterStatus
} from 'helpers/filterHekper';
import { useEventsFetcherHook } from 'hooks/eventsFetcherHook';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-big-calendar';
import { useLocation } from 'react-router';
import { ICalendarLoading } from 'model/calendar';
import { ICalendarEvent, IPhantomEvent } from 'model/calendar/events';
import { ICalendarResource } from 'model/calendar/filters';
import {
  calendarSidebarInit,
  calendarSidebarVar,
  CalendarView,
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar,
  PlannerView,
  removePhantomEvents,
  selectedItemsToCancelVar
} from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  calendarFiltersPrefVar,
  clientsResourcesPrevVar,
  providerResourcesPrevVar,
  searchedClientsPrevVar
} from 'utils/cache/filters';
import { internalFilters } from 'utils/constants/calendarFilters';
import { CALENDAR_VIEWS, PLANNER_VIEWS } from 'utils/constants/lists';
import { ROUTE_PATHS } from 'utils/constants/routes';
import {
  getClientEventsVariables,
  getStaffEventsVariables
} from 'utils/mappers/request/events';
import { adjustPhantomEventsMapping } from 'utils/mappers/response/phantomEvents';
import MainButton from 'views/components/button';
import Loader from 'views/components/ui/content/Loader';
import PushableSideBar from 'views/components/ui/sidebar';
import AppointmentSidebar, { SIDEBAR_ACTIONS } from './AppointmentSidebar';
import CalendarPageWrapper from './style';
import { SidebarState } from 'model/v2';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { useEventSubscriptionHook } from 'hooks/eventsSubscriptionHook';
import { useApptTypesWithAdmin } from 'api/graphql/v2/hooks/appointmentTypes';
// import { useForm } from 'react-hook-form';

export const EVENTS_FETCHING_ACTIONS: Record<string, string> = {
  CALENDAR_NAVIGATION: 'CALENDAR_NAVIGATION',
  PAGE_NAVIGATION: 'PAGE_NAVIGATION',
  APPLY_SEARCH: 'APPLY_SEARCH',
  APPLY_FILTERS: 'APPLY_FILTERS'
};

export const plannerContext = React.createContext<any>('');

interface IProps {
  toOpenNewAppointment?: boolean;
  isNewAdminAppointment?: boolean;
}
let isSidebarVisible: boolean;
const MainCalendarPage: React.FC<IProps> = React.memo(
  ({ toOpenNewAppointment, isNewAdminAppointment }: IProps) => {
    const location = useLocation();
    const params = useMemo(() => new URLSearchParams(location?.search), [
      location
    ]);

    const sidebarState = useReactiveVar(calendarSidebarVar);
    const { apptTypes, loadingApptTypes, adminTypes } = useApptTypesWithAdmin(
      sidebarState.action !== 'Edit'
    );
    // cons [sidebarState, setsideB]
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const ShadowFeatureAvailability = getFeatureAvailability(
      FEATURES.SHADOW_SCHEDULING
    );

    useEffect(() => {
      if (location.pathname === ROUTE_PATHS.VIEW_APPOINTMENT) {
        const eventId = params.get('event')
          ? parseInt(params.get('event')!)
          : undefined;
        if (eventId) viewAppointmentHandler(eventId);
      } else if (toOpenNewAppointment) {
        newAppointmentHandler(isNewAdminAppointment);
      }
    }, [isNewAdminAppointment, location, params, toOpenNewAppointment]);

    const providerId = params.get('provider');
    let formattedProviderId = providerId && parseInt(providerId);
    useQuery(GET_PROVIDER_MEMBER, {
      variables: { id: formattedProviderId },
      skip: !providerId || isLoading || !formattedProviderId,
      onCompleted: data => newProviderAppointmentHandler(data.provider)
    });

    const clientIdWithParams = params.get('client');
    const clientId = clientIdWithParams
      ? clientIdWithParams!.split('?')[0]
      : undefined;
    const isDTQueryParams = params.get('isDT');
    const isABAQueryParams = params.get('isABA');
    let subType: string | null = params.get('subType_Id');
    let parentType: string | null = params.get('parentType_Id');

    const isDTAppt: Boolean = isDTQueryParams === 'true' ? true : false;
    const isABAAppt: Boolean = isABAQueryParams === 'true' ? true : false;
    const Sessions: string | null = params.get('Sessions');
    if (isABAAppt) {
      parentType = subType;
      subType = null;
    }
    const subType_Id: number = parseInt(subType || '');
    const parent_Type_ID: number = parseInt(parentType || '');
    const no_of_session: number = parseInt(Sessions || '');

    useQuery(GET_CLIENT, {
      variables: { id: Number(clientId) },
      skip: !clientId || isLoading,
      onCompleted: data => {
        newClientAppointmentHandler(
          data.clientProfile,
          isDTAppt,
          no_of_session,
          subType_Id,
          parent_Type_ID,
          isABAAppt
        );
      }
    });

    const eventId = params.get('event')
      ? parseInt(params.get('event')!)
      : undefined;

    useQuery(GET_FULL_EVENT, {
      variables: { id: eventId },
      fetchPolicy: 'no-cache',
      skip:
        !eventId ||
        isLoading ||
        location.pathname !== ROUTE_PATHS.EDIT_APPOINTMENT,
      onCompleted: data => {
        editAppointmentHandler(data?.event);
      }
    });

    const [visible, setVisible] = useState<boolean>(false);
    const calendarView = useReactiveVar(plannerCalendarPrefViewVar);
    const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
    const providers = useReactiveVar(providerResourcesPrevVar);
    const clients = useReactiveVar(clientsResourcesPrevVar);
    const [removedGroups, setRemovedGroups] = useState<number[]>([]);
    const [paginationArray, setPaginationArray] = useState<ICalendarResource[]>(
      []
    );
    const [isCalendarLoading, setIsCalendarLoading] = useState<
      ICalendarLoading
    >({
      isLoadingClientsEvents: true,
      isLoadingStaffEvents: true,
      isLoadingFilteredProviders: true,
      errorLoadingEvents: false
    });
    const events = useReactiveVar(calendarEventsPrefVar);
    const [EditActualTimeState, SetEditActualTimeState] = useState(true);
    const searchedClients = useReactiveVar(searchedClientsPrevVar);
    const [scroll, setScroll] = useState<boolean>(false);
    const [page, setPage] = useState(0);
    const [action, setAction] = useState<string | undefined>(undefined);
    const selectedItemsToCancel = useReactiveVar(selectedItemsToCancelVar);
    const [isBulkCancelMode, setIsBulkCancelMode] = useState<boolean>(false);

    useEffect(() => {
      setIsBulkCancelMode(selectedItemsToCancel.size > 0);
    }, [selectedItemsToCancel.size]);

    const incrementPage = useCallback(() => {
      setPage(prev => prev + 1);
    }, []);

    const incrementPageDebouncer = React.useMemo(
      () =>
        debounce(() => {
          incrementPage();
        }, 1500),
      [incrementPage]
    );

    const clientsToFetch = useMemo(() => {
      return getGroupsToFetch(paginationArray, page, calendarView).clients;
    }, [paginationArray, page, calendarView]);

    const providersToFetch = useMemo(() => {
      return getGroupsToFetch(paginationArray, page, calendarView).providers;
    }, [page, paginationArray, calendarView]);

    const {
      getFilteredProviders,
      getStaffEvents,
      getClientEvents
    } = useEventsFetcherHook(
      undefined,
      setIsLoading,
      setIsCalendarLoading,
      isCalendarLoading,
      action,
      setPaginationArray,
      paginationArray,
      clientsToFetch,
      providersToFetch,
      page,
      incrementPage,
      removedGroups,
      setPage
    );

    useEventSubscriptionHook(isCalendarLoading);

    const filters = useReactiveVar(calendarFiltersPrefVar);
    const handleViewChange = useCallback<(...args: CalendarView[]) => void>(
      view => {
        plannerCalendarPrefViewVar(view);
      },
      []
    );
    const handleDateSelect = useCallback<(...args: Date[]) => void>(
      (date: Date) => {
        mainCalendarPrefDayVar(date);
      },
      []
    );
    const openSidebar = useCallback<(val: SidebarState) => void>(
      (sidebarS: SidebarState) => {
        formRedirectHandler(sidebarS);
        setVisible(true);
      },
      [setVisible]
    );

    const handleCloseSidebar = useCallback(
      (val: boolean, shouldRemovePhantom: boolean = false) => {
        formRedirectHandler(calendarSidebarInit);
        setVisible(val);
        if (!val && shouldRemovePhantom) {
          removePhantomEvents(events);
        }
      },
      [events]
    );

    useEffect(() => {
      if (sidebarState.event && !visible) {
        setVisible(true);
      }
      isSidebarVisible = visible;
    }, [sidebarState, visible]);

    const calendar = useMemo((): JSX.Element => {
      return (
        <>
          {CALENDAR_VIEWS.includes(calendarView) && (
            <div className="page-content_wrapper">
              {isLoading && <Loader />}
              <MainButton
                className="calendar-btn"
                title="Add New"
                onClick={() =>
                  openSidebar({
                    event: undefined,
                    action: SIDEBAR_ACTIONS.NEW
                  })
                }
                disabled={visible}
              />
              <div className="page-content_container">
                <CalendarSidebar />
                <Calendar
                  selectedDate={calendarDate}
                  view={calendarView as View}
                  handleViewChange={handleViewChange}
                  handleNavigation={handleDateSelect}
                  openSidebar={openSidebar}
                />
              </div>
            </div>
          )}
          {PLANNER_VIEWS.includes(calendarView) && (
            <div className="page-content_wrapper">
              {isLoading && <Loader />}
              <MainButton
                className="calendar-btn"
                title="Add New"
                onClick={() =>
                  openSidebar({
                    event: undefined,
                    action: SIDEBAR_ACTIONS.NEW
                  })
                }
                disabled={visible || isBulkCancelMode}
              />
              <PlannerCalendar
                setPaginationArray={setPaginationArray}
                selectedDate={calendarDate}
                handleViewChange={handleViewChange}
                handleNavigation={handleDateSelect}
                view={calendarView as PlannerView}
                incrementPage={incrementPage}
                scroll={scroll}
                setScroll={setScroll}
                openSidebar={openSidebar}
                isSidebarClosed={!visible}
                setRemovedGroups={setRemovedGroups}
                removedGroups={removedGroups}
                incrementPageDebouncer={incrementPageDebouncer}
                paginationArray={paginationArray}
              />
            </div>
          )}
        </>
      );
    }, [
      calendarView,
      isLoading,
      visible,
      calendarDate,
      handleViewChange,
      handleDateSelect,
      openSidebar,
      isBulkCancelMode,
      incrementPage,
      scroll,
      removedGroups,
      incrementPageDebouncer,
      paginationArray
    ]);

    useEffect(() => {
      if (action === EVENTS_FETCHING_ACTIONS.APPLY_FILTERS) {
        setIsCalendarLoading(
          getCalendarLoadingState(
            EVENTS_FETCHING_ACTIONS.APPLY_FILTERS,
            isCalendarLoading
          )
        );
        setScroll(true);
        getFilteredProviders();
      } else {
        setIsCalendarLoading({
          ...isCalendarLoading,
          isLoadingFilteredProviders: false,
          errorLoadingEvents: false
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, filters]);

    useEffect(() => {
      if (page === 0) return;
      setAction(EVENTS_FETCHING_ACTIONS.PAGE_NAVIGATION);

      setIsCalendarLoading(
        getCalendarLoadingState(
          EVENTS_FETCHING_ACTIONS.PAGE_NAVIGATION,
          isCalendarLoading,
          undefined,
          providersToFetch.length,
          clientsToFetch.length
        )
      );

      if (providersToFetch?.length > 0)
        getStaffEvents(
          getStaffEventsVariables(
            providersToFetch,
            calendarDate,
            calendarView,
            getCancelFilterStatus(filters.internals as internalFilters[]),
            getDeletedFilterStatus(filters.internals as internalFilters[])
          )
        );

      if (clientsToFetch?.length > 0)
        getClientEvents(
          getClientEventsVariables(
            clientsToFetch,
            calendarDate,
            calendarView,
            getCancelFilterStatus(filters.internals as internalFilters[]),
            getDeletedFilterStatus(filters.internals as internalFilters[])
          )
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
      let currentEvents: ICalendarEvent[] | IPhantomEvent[] = [];
      if (visible) {
        currentEvents = events.filter(
          e =>
            isPhantomEvent(e as IPhantomEvent) ||
            e.id === sidebarState?.event?.id
        );
        currentEvents = adjustPhantomEventsMapping(
          currentEvents as IPhantomEvent[],
          calendarView,
          calendarDate
        );
        calendarEventsPrefVar(currentEvents as ICalendarEvent[]);
      } else {
        calendarEventsPrefVar([]);
      }

      setPage(0);
      setAction(EVENTS_FETCHING_ACTIONS.CALENDAR_NAVIGATION);

      //reset page

      setScroll(true);
      const allResources = getCurrentPaginationArray(
        clients,
        providers,
        paginationArray,
        removedGroups,
        EVENTS_FETCHING_ACTIONS.CALENDAR_NAVIGATION,
        [],
        new Map(),
        searchedClients
      );

      setPaginationArray(allResources); //current resources with order and removed
      const clientsToFetch = getGroupsToFetch(allResources, 0, calendarView)
        .clients;
      const providersToFetch = getGroupsToFetch(allResources, 0, calendarView)
        .providers;

      setIsCalendarLoading(
        getCalendarLoadingState(
          EVENTS_FETCHING_ACTIONS.CALENDAR_NAVIGATION,
          isCalendarLoading,
          undefined,
          providersToFetch.length,
          clientsToFetch.length
        )
      );

      if (providersToFetch?.length > 0) {
        getStaffEvents(
          getStaffEventsVariables(
            providersToFetch,
            calendarDate,
            calendarView,
            getCancelFilterStatus(filters.internals as internalFilters[]),
            getDeletedFilterStatus(filters.internals as internalFilters[])
          )
        );
      }
      if (clientsToFetch?.length > 0)
        getClientEvents(
          getClientEventsVariables(
            clientsToFetch,
            calendarDate,
            calendarView,
            getCancelFilterStatus(filters.internals as internalFilters[]),
            getDeletedFilterStatus(filters.internals as internalFilters[])
          )
        );
      // setIsSideBarOpen()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarDate, calendarView]);

    useEffect(() => {
      if (!EditActualTimeState) {
        calendarEventsPrefVar([]);
        SetEditActualTimeState(true);
      }
    }, [EditActualTimeState]);
    const sideBarContent = useMemo((): JSX.Element => {
      return visible ? (
        <AppointmentSidebar
          setVisible={handleCloseSidebar}
          sidebarState={sidebarState}
          view={calendarView}
          date={calendarDate}
          setPaginationArray={setPaginationArray}
          paginationArray={paginationArray}
          openSidebar={openSidebar}
          visible={isSidebarVisible}
          apptTypes={apptTypes}
          loadingApptTypes={loadingApptTypes}
          adminTypes={adminTypes}
          isDTAppt={isDTAppt}
          subType_Id={subType_Id}
          parent_Type_ID={parent_Type_ID}
        />
      ) : (
        <></>
      );
    }, [
      visible,
      handleCloseSidebar,
      sidebarState,
      calendarView,
      calendarDate,
      paginationArray,
      openSidebar,
      apptTypes,
      loadingApptTypes,
      adminTypes
    ]);

    //ErrorMessage is intended for future use, where certain error messages will be displayed based off certain conditions.
    const errorMessage = isCalendarLoading.errorLoadingEvents
      ? 'The events could not be loaded as the response exceeds the database limit. Please adjust the filters to reduce the amount of data being returned.'
      : 'An error has occurred. Please refresh the page and try again.';

    return (
      <plannerContext.Provider
        value={{
          isLoading,
          setIsLoading,
          isCalendarLoading,
          setIsCalendarLoading,
          setAction,
          isBulkCancelMode,
          handleDateSelect,
          ShadowFeatureAvailability,
          EditActualTimeState,
          SetEditActualTimeState
        }}
      >
        <CalendarPageWrapper>
          <>
            <DownStreamsCheckBanner />
            {isCalendarLoading.errorLoadingEvents && (
              <EventsErrorBanner message={errorMessage} />
            )}
            <PushableSideBar
              content={sideBarContent}
              sideBarWidth="407px"
              visible={visible}
              pageComponent={calendar}
            />
          </>
        </CalendarPageWrapper>
      </plannerContext.Provider>
    );
  }
);

export default MainCalendarPage;
