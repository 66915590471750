import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { AppointmentTypeFormWrapper } from '../style';
import { DEFAULT_DURATION } from 'utils/constants/default';
import { IFullAppointmentType, SidebarState } from 'model/v2';
import { mapToValuedOption } from 'utils/mappers/form';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import FormItem from 'views/containers/form/formItem/formItem';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';

interface Props {
  apptTypes: IFullAppointmentType[];
  sidebarState: SidebarState;
}
const AppointmentTypeFormV2: React.FC<Props> = ({
  apptTypes,
  sidebarState
}: Props) => {
  const methods = useFormContext();
  const formApptType = useWatch({ name: 'appType', control: methods.control });
  const apptOptionsList = mapToValuedOption(apptTypes);

  console.log(sidebarState, 'sidebarstateofformv2');

  const [appointmentType, setAppointmentType] = React.useState<
    IFullAppointmentType | undefined
  >(undefined);

  React.useEffect(() => {
    setAppointmentType(apptTypes?.find(x => x.id === formApptType));
  }, [formApptType, apptTypes]);

  const subTypesOptionList = mapToValuedOption(
    appointmentType?.appointmentSubTypes || []
  );

  const appointmentTypeHandler = React.useCallback(
    val => {
      methods.setValue('appSubType', null);
      methods.setValue('selectedOpening', null);
      methods.setValue('dirtyEdit', true);

      const apptType = apptTypes?.find(x => x.id === val);
      setAppointmentType(apptType);

      methods.setValue('eventTypeName', apptType?.eventType?.name);
      const { startDate } = methods.getValues(['startDate']);
      if (
        apptType?.eventType?.name === EVENT_TYPES.ABA &&
        sidebarState.action !== SIDEBAR_ACTIONS.EDIT
      ) {
        methods.setValue('duration', DEFAULT_DURATION.ABA);
        !!startDate &&
          methods.setValue(
            'endDate',
            startDate.clone().add(DEFAULT_DURATION.ABA, 'minutes')
          );
      }
    },
    [methods, setAppointmentType, apptTypes]
  );

  const appointmentSubTypeHandler = React.useCallback(
    val => {
      const appointmentSubType = appointmentType?.appointmentSubTypes?.find(
        x => x.id === val
      );
      const apptType = appointmentType?.eventType?.name;
      let duration = appointmentSubType?.DTSetting?.duration;
      if (apptType === EVENT_TYPES.MED) {
        duration = appointmentSubType?.MEDSetting?.duration;
      }

      methods.setValue('duration', duration);
      methods.setValue('selectedOpening', null);
      methods.setValue('dirtyEdit', true);
      const { startDate } = methods.getValues(['startDate']);

      !!startDate &&
        methods.setValue('endDate', startDate.clone().add(duration, 'minutes'));
    },
    [appointmentType, methods]
  );

  return (
    <AppointmentTypeFormWrapper>
      <FormItem optional={false} label="Appointment Type">
        <Controller
          name="appType"
          control={methods.control}
          render={fieldProps => (
            <>
              <Select
                className="appt-category-type"
                showSearch
                filterOption={(input: string, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
                placeholder="Select Appointment Type"
                options={apptOptionsList}
                field={fieldProps}
                onSelect={appointmentTypeHandler}
                errors={methods.errors}
              />
            </>
          )}
        />
      </FormItem>
      <Controller
        name="eventTypeName"
        control={methods.control}
        render={() => <></>}
      />
      <FormItem optional={false} label="Appointment Sub-Type">
        <Controller
          name="appSubType"
          control={methods.control}
          render={fieldProps => (
            <Select
              className="appt-category-type"
              disabled={appointmentType?.eventType?.name === EVENT_TYPES.ABA}
              placeholder="Select Appointment Sub-Type"
              options={subTypesOptionList}
              field={fieldProps}
              onSelect={appointmentSubTypeHandler}
              errors={methods.errors}
            />
          )}
        />
      </FormItem>
    </AppointmentTypeFormWrapper>
  );
};

export default React.memo(AppointmentTypeFormV2);
