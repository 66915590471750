import { useState } from 'react';
import { Icon } from 'semantic-ui-react';

const AddObserversWarning = () => {
  const [isShown, setIsShown] = useState<boolean>(true);
  return isShown ? (
    <div className="observers-note">
      <div>
        <p className="add-observers-paragraph">
          Adding an onboarding provider as an observer to a pre-booked
          appointment will only impact their calendars for this single event.
        </p>
      </div>
      <div>
        <Icon name="close" onClick={() => setIsShown(false)}></Icon>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AddObserversWarning;
