import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TextArea } from 'views/containers/form';
interface Props {
  isDeleteOrAdminEvent?: boolean;
}
const CancelNoteTextArea = ({ isDeleteOrAdminEvent = false }: Props) => {
  const methods = useFormContext();
  const cancelNote = useWatch({ name: 'cancelNote', control: methods.control });
  const [isSelected, setIsSelected] = useState(false);
  const handleChange = (e: any) => {
    methods.setValue('cancelNote', e.target.value);
    if (e.target.value === '') {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  };

  return (
    <div className={isSelected ? 'add-note-selected' : 'add-note'}>
      <Controller
        name="cancelNote"
        control={methods.control}
        render={() =>
          isDeleteOrAdminEvent ? (
            <></>
          ) : (
            <TextArea
              name="cancelNote"
              placeholder="Add Note (optional)"
              value={cancelNote}
              onChange={handleChange}
              errors={methods.errors}
            />
          )
        }
      />
    </div>
  );
};

export default CancelNoteTextArea;
