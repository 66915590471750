/* eslint-disable no-unused-vars */
import {
  getEventsOfSpecificDay,
  getStartTimeAndLengthForEachDay
} from 'pages/ConfigurationsPage/addAvailabilityTemplate/utils';
import moment, { Moment } from 'moment';
import { IClinic } from 'model/v2/clinic';
import { ILocation } from 'model/v2/location';
import {
  AvailabilityTemplateEvents,
  CalenderEvent,
  DayAvailability,
  ModalData
} from 'model/v2/availabilityTemplate';

import { clinicNetworkTypes } from 'utils/constants/clinicTypes';
import {
  PROVIDER_MAX_DAILY_HOURS_TEMPLATE,
  WeekEnd
} from 'utils/constants/availabilityTemplate';

export enum Departments {
  ABA = 'ABA',
  DT = 'DT',
  MED = 'MED',
  FWC = 'FWC'
}

export enum Locations {
  ClinicAndTelehealth = 'clinicandtelehealth',
  Telehealth = 'telehealth',
  Clinic = 'clinic',
  Offsite = 'offsite',
  Any = 'anyavailability'
}

export const MapEventsToDayAvailability = (events: CalenderEvent[]) => {
  let Days: DayAvailability[] = [];
  for (let index = 0; index < 7; index++) {
    let eventsOfDayIndex = getEventsOfSpecificDay(events, index);
    if (eventsOfDayIndex.length > 0) {
      let { startTime, length } = getStartTimeAndLengthForEachDay(
        eventsOfDayIndex
      );

      Days.push({ startTime: startTime, length: length });
      continue;
    }
    Days.push({ startTime: moment.invalid(), length: 0 });
  }

  return Days;
};

export const mapEvent = (
  data: ModalData,
  Day: DayAvailability,
  index: number,
  defaultLocation: ILocation
) => {
  const startTimeMoment = moment(Day.startTime).day(index);
  const startDate = startTimeMoment.toDate();
  const startHour = startTimeMoment.toDate().getHours();
  const endTimeMoment = startTimeMoment.add(Day.length, 'hour');
  getCheckEndTimeMomentCalendar(endTimeMoment, startDate);
  const PostBreakOffSet = endTimeMoment.diff(
    moment()
      .day(index)
      .set({ hour: 12, minute: 45, second: 0, millisecond: 0 }),
    'minutes'
  );

  const breakStart = moment()
    .day(index)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .toDate();

  const breakEnd = moment()
    .day(index)
    .set({ hour: 12, minute: 45, second: 0, millisecond: 0 })
    .toDate();
  if (startHour < 12 && endTimeMoment.isSameOrAfter(breakStart)) {
    let preBreakEvent = defaultWorkingEvent(
      startDate,
      breakStart,
      defaultLocation,
      data
    );

    const postBreakEvent = defaultWorkingEvent(
      breakEnd,
      moment()
        .day(index)
        .set({ hour: 12, minute: 45, second: 0, millisecond: 0 })
        .add(PostBreakOffSet, 'minutes')
        .toDate(),
      defaultLocation,
      data
    );

    return PostBreakOffSet === 0
      ? [preBreakEvent, breakEvent(index, defaultLocation)]
      : [preBreakEvent, breakEvent(index, defaultLocation), postBreakEvent];
  }

  const WorkingEvent = defaultWorkingEvent(
    startDate,
    endTimeMoment.toDate(),
    defaultLocation,
    data
  );

  return [WorkingEvent];
};

export const mapABAEvent = (
  data: ModalData,
  Day: DayAvailability,
  index: number,
  defaultLocation: ILocation
) => {
  const startTimeMoment = moment(Day.startTime).day(index);
  const startDate = startTimeMoment.toDate();
  const endTimeMoment = startTimeMoment.add(Day.length, 'hour');
  getCheckEndTimeMomentCalendar(endTimeMoment, startDate);
  const WorkingEvent = defaultWorkingEvent(
    startDate,
    endTimeMoment.toDate(),
    defaultLocation,
    data
  );

  return [WorkingEvent];
};

export const defaultWorkingEvent = (
  start: Date,
  end: Date,
  defaultLocation: ILocation,
  data: ModalData
): CalenderEvent => {
  return {
    title: 'Care Session',
    type: 'working',
    isAdmin: false,
    location: defaultLocation?.id!,
    start: start,
    end: end,
    clinics: data.providerDefaultClinicsIds?.map((clinicId: number) => {
      return { id: clinicId };
    })
  };
};

export const breakEvent = (index: number, defaultLocation: ILocation) => {
  return {
    title: 'Lunch',
    type: 'Lunch',
    location: defaultLocation?.id!,
    isAdmin: true,
    start: moment()
      .day(index)
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      .toDate(),
    end: moment()
      .day(index)
      .set({ hour: 12, minute: 45, second: 0, millisecond: 0 })
      .toDate()
  };
};
export const mapEditedDayAvailabilityToEvent = (
  data: ModalData,
  events: CalenderEvent[],
  defaultLocation: ILocation,
  eventTitleMapping?: any[]
) => {
  let CalenderEvents: CalenderEvent[] = [];
  data.Day.forEach((day, index) => {
    if (String(day.length) !== '0') {
      const eventsOfDayIndex = getEventsOfSpecificDay(events, index);
      if (eventsOfDayIndex.length > 0) {
        CalenderEvents.push(...eventsOfDayIndex);
      } else {
        let MappedEvents;
        if (data.department === Departments.ABA)
          MappedEvents = mapABAEvent(data, day, index, defaultLocation);
        else MappedEvents = mapEvent(data, day, index, defaultLocation);
        CalenderEvents.push(...MappedEvents);
      }
    }
  });
  if (eventTitleMapping) {
    CalenderEvents = CalenderEvents.map(event => {
      const mappedOption = eventTitleMapping?.find(
        option => option.value === event.type
      );
      const title = mappedOption?.label ?? (event.type || '');
      event.title = title;
      return event;
    });
  }
  return CalenderEvents;
};

export const mapDayAvailabilityToCalendarEvent = (
  dayAvailabilities: AvailabilityTemplateEvents[],
  providerDefaultClinicsIds?: number[],
  eventTitleMapping?: any[],
  dateOffset?: Date
): CalenderEvent[] => {
  return dayAvailabilities?.map(
    (availabilityEvent: AvailabilityTemplateEvents): CalenderEvent => {
      const mappedOption = eventTitleMapping?.find(
        option => option.value === availabilityEvent.type
      );
      const title = mappedOption?.label ?? (availabilityEvent.type || '');
      return {
        id: availabilityEvent.id,
        title: title,
        type: availabilityEvent.type,
        start: moment(dateOffset)
          .day(availabilityEvent.dayOfWeek)
          .set({
            hour: Number(availabilityEvent.startTime.split(':')[0]),
            minute: Number(availabilityEvent.startTime.split(':')[1]),
            second: 0,
            millisecond: 0
          })
          .toDate(),
        end: moment(dateOffset)
          .day(availabilityEvent.dayOfWeek)
          .set({
            hour: Number(availabilityEvent.endTime.split(':')[0]),
            minute: Number(availabilityEvent.endTime.split(':')[1]),
            second: 0,
            millisecond: 0
          })
          .toDate(),
        isAdmin: availabilityEvent.isAdmin,
        location: availabilityEvent.location || 3,
        isInClinic: availabilityEvent.isInClinic,
        isOffsite: availabilityEvent.isOffsite,
        isTelehealth: availabilityEvent.isTelehealth,
        clinics:
          availabilityEvent.clinics && availabilityEvent.clinics?.length > 0
            ? availabilityEvent.clinics
            : providerDefaultClinicsIds?.map((clinicId: number) => {
                return { id: clinicId };
              })
      };
    }
  );
};

export const generateDefaultTemplate = (submittedData: ModalData) => {
  submittedData.Day.forEach((day: DayAvailability, index: number) => {
    if (index > 0 && index < 6) {
      day.startTime = moment()
        .set({
          hour: 8,
          minute: 45,
          second: 0,
          millisecond: 0
        })
        .day(index);
      day.length = 9;
    }
  });
};

export const generateModalDefaultTemplate = async () => {
  let dayAvailabilities: DayAvailability[] = [];
  for (let i = 0; i < 7; i++) {
    if (i > 0 && i < 6) {
      dayAvailabilities.push({
        startTime: moment()
          .set({
            hour: 8,
            minute: 45,
            second: 0,
            millisecond: 0
          })
          .day(i),
        length: 9
      });
    } else {
      dayAvailabilities.push({ startTime: moment.invalid(), length: 0 });
    }
  }
  return dayAvailabilities;
};

export const createClinicToSpokeMapNames = (clinics: IClinic[]) => {
  const clinicHubToSpokeMap = new Map<
    number | undefined,
    { label: string; value: number }[] | undefined
  >();

  clinics?.forEach(clinic => {
    const clinicSpokesArray =
      clinicHubToSpokeMap.get(clinic.clinicHub?.id) || [];
    if (
      clinic?.name &&
      clinic?.abbreviation &&
      clinic?.id &&
      clinic.clinicType === clinicNetworkTypes.SPOKE
    )
      clinicSpokesArray?.push({
        label: `${clinic.name} (${clinic.abbreviation})`,
        value: clinic.id
      });
    clinicHubToSpokeMap.set(clinic.clinicHub?.id, clinicSpokesArray);
  });
  return clinicHubToSpokeMap;
};

export const createClinicToSpokeMap = (clinics: IClinic[]) => {
  const clinicHubToSpokeMap = new Map<
    number | undefined,
    number[] | undefined
  >();

  clinics?.forEach(clinic => {
    const clinicSpokesArray =
      clinicHubToSpokeMap.get(clinic.clinicHub?.id) || [];
    if (clinic?.id && clinic.clinicType === clinicNetworkTypes.SPOKE)
      clinicSpokesArray?.push(clinic.id);
    clinicHubToSpokeMap.set(clinic.clinicHub?.id, clinicSpokesArray);
  });

  return clinicHubToSpokeMap;
};

export const createClinicNameToIdMap = (clinics?: IClinic[]) => {
  const clinicNameToIdMap = new Map<string, number>();
  if (!clinics) return clinicNameToIdMap;

  clinics?.forEach(clinic => {
    if (clinic.name && clinic.id) clinicNameToIdMap.set(clinic.name, clinic.id);
  });
  return clinicNameToIdMap;
};

export const createClinicIdToClinicMap = (clinics: IClinic[]) => {
  const clinicIdToNameMap = new Map<number, IClinic>();
  clinics?.forEach(clinic => {
    if (clinic.name && clinic.id) clinicIdToNameMap.set(clinic.id, clinic);
  });

  return clinicIdToNameMap;
};

export const getProviderDefaultClinics = (
  clinics: IClinic[] | undefined,
  providerClinic: IClinic | undefined
) => {
  let clinicHubToSpokeMap = new Map<number | undefined, number[] | undefined>();
  if (clinics) clinicHubToSpokeMap = createClinicToSpokeMap(clinics);
  const providerClinicNetwork = clinicHubToSpokeMap.get(providerClinic?.id);
  const defaultClinicIdArray: number[] = [];
  if (providerClinicNetwork && providerClinic?.id) {
    defaultClinicIdArray.push(providerClinic?.id, ...providerClinicNetwork);
  } else {
    if (providerClinic?.id) defaultClinicIdArray.push(providerClinic?.id);
  }
  return defaultClinicIdArray;
};

export const getClinicsAbbreviation = (
  events: CalenderEvent[],
  clinics: IClinic[] | undefined
) => {
  let idToClinicMap = new Map<number, IClinic>();
  if (clinics) idToClinicMap = createClinicIdToClinicMap(clinics);

  const arrayOfClinicsAbbr = new Array<Array<string>>(7);
  events?.forEach((event: CalenderEvent) => {
    const day: number = event?.start.getDay();
    event?.clinics?.forEach((clinicId: IClinic) => {
      let clinic: IClinic | undefined;
      if (clinicId?.id) clinic = idToClinicMap?.get(clinicId.id);
      if (!arrayOfClinicsAbbr[day])
        arrayOfClinicsAbbr[day] = new Array<string>();
      if (clinic?.abbreviation) {
        if (!arrayOfClinicsAbbr[day].includes(clinic.abbreviation))
          arrayOfClinicsAbbr[day].push(clinic.abbreviation);
      }
    });
    for (let i = 0; i < 7; ++i) {
      if (!arrayOfClinicsAbbr[i]) arrayOfClinicsAbbr[i] = [];
    }
  });
  return arrayOfClinicsAbbr;
};

export const getDefaultTemplateData = (submittedData?: ModalData) => {
  let dailyMaxHours = [];
  if (
    !submittedData ||
    !submittedData.dailyMaxHours ||
    submittedData.dailyMaxHours.length === 0
  ) {
    dailyMaxHours = getDefaultProviderTemplateDailyMax(submittedData?.Day);
  } else {
    dailyMaxHours = submittedData?.dailyMaxHours
      ?.sort(it => it.dayOfWeek)
      ?.map(it => ({
        dayOfWeek: it.dayOfWeek,
        value: getDefaultProviderDailyMaxValue(
          submittedData.Day[it.dayOfWeek],
          it.value
        )
      }));
  }
  return {
    dailyMaxHours,
    templateType: submittedData?.templateType,
    templateName: submittedData?.templateName
  };
};

export const getDefaultProviderDailyMaxValue = (
  dayAvailability?: DayAvailability,
  value?: number | undefined
) => {
  if (!dayAvailability?.length || dayAvailability?.length === 0) {
    return 0;
  }
  return Number(value) || PROVIDER_MAX_DAILY_HOURS_TEMPLATE;
};

const getDefaultProviderTemplateDailyMax = (days?: DayAvailability[]) => {
  const dailyMaxHours = [];
  for (let i = 0; i < 7; i++) {
    dailyMaxHours.push({
      dayOfWeek: i,
      value: days
        ? getDefaultDailyMaxByLength(days[i].length)
        : getDefaultDailyMaxForNoneTemplate(i)
    });
  }
  return dailyMaxHours;
};

const getDefaultDailyMaxForNoneTemplate = (dayOfWeek: number) =>
  dayOfWeek === WeekEnd.Saturday || dayOfWeek === WeekEnd.Sunday
    ? 0
    : PROVIDER_MAX_DAILY_HOURS_TEMPLATE;
const getDefaultDailyMaxByLength = (length: number) =>
  length === 0 ? 0 : PROVIDER_MAX_DAILY_HOURS_TEMPLATE;
export const getCheckEndTimeMomentCalendar = (
  endTimeMoment: Moment,
  startDate: Date
) => {
  if (endTimeMoment.get('hours') < startDate.getHours()) {
    endTimeMoment.set({ h: 23, m: 45, D: startDate.getUTCDate() });
  }
  return endTimeMoment.toDate();
};
