import gql from 'graphql-tag';

export const GET_ALL_CORTICA_PROGRAMS = gql`
  query {
    corticaPrograms {
      id
      programName
    }
  }
`;

export const GET_CORTICA_PROGRAM = gql`
  query corticaProgramById($id: Int!) {
    corticaProgramById(id: $id) {
      id
      programName
    }
  }
`;
