import {
  ABAAppointmentSubType,
  IABAAppointmentSubType
} from 'model/abaAppointmentSubtype';
import { ILookup } from 'model/lookup';
import { ITableColumn } from 'views/components/table/type';
import { sortNumerical } from 'utils/sort';
import { TableType } from 'model/tableType';
export const ABA_APPOINTMENT_SUBTYPES_COLUMNS: ITableColumn[] = [
  {
    label: 'Title',
    key: ABAAppointmentSubType.title,
    notWrap: true,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, ABAAppointmentSubType.title),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, ABAAppointmentSubType.title)
    }
  },
  {
    label: 'Super type',
    key: ABAAppointmentSubType.superType,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, ABAAppointmentSubType.superType),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, ABAAppointmentSubType.superType)
    }
  },
  { label: 'CR Auth code name', key: 'crAuthCodeName' },
  { label: 'CR Auth code', key: ABAAppointmentSubType.crAuthCode },
  {
    label: 'CR Type',
    key: ABAAppointmentSubType.crType,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, ABAAppointmentSubType.crType),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, ABAAppointmentSubType.crType)
    }
  },
  {
    label: 'Place of service',
    key: ABAAppointmentSubType.placeOfService,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, ABAAppointmentSubType.placeOfService),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, ABAAppointmentSubType.placeOfService)
    }
  },
  {
    label: 'ID',
    key: ABAAppointmentSubType.id,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sortNumerical(
          a[ABAAppointmentSubType.id]!,
          b[ABAAppointmentSubType.id]!
        ),
      reverse: (a: TableType, b: TableType) =>
        sortNumerical(
          b[ABAAppointmentSubType.id]!,
          a[ABAAppointmentSubType.id]!
        )
    }
  }
];
const sort = (a: TableType, b: TableType, sortField: ABAAppointmentSubType) => {
  let valueA = (a as IABAAppointmentSubType)[sortField];
  let valueB = (b as IABAAppointmentSubType)[sortField];
  if (
    sortField === ABAAppointmentSubType.superType ||
    sortField === ABAAppointmentSubType.placeOfService
  ) {
    valueA = (valueA as ILookup)?.value;
    valueB = (valueB as ILookup)?.value;
  }
  return valueA!.toString().toLowerCase() > valueB!.toString().toLowerCase()
    ? -1
    : 1;
};
