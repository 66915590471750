import React from 'react';
import { WHITE } from 'utils/constants/theme';
import { RescheduleWrapper } from '../style';

const RescheduleMileageWarning = () => {
  return (
    <RescheduleWrapper>
      <div
        style={{
          background: WHITE,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          borderTop: '1px solid #d8d8d8',
          paddingTop: '30px'
        }}
      >
        <h1 className="reschedule-message">Warning</h1>
        <p>
          The mileage entered is more than 50 miles. Please review before
          submitting.
        </p>
      </div>
    </RescheduleWrapper>
  );
};
export default React.memo(RescheduleMileageWarning);
