import React from 'react';

const List = ({ className, data }) => {
  return (
    <ul className={className} key="more-list">
      {data.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};
export default List;
