import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  PUT_ABA_OBSERVATION,
  PUT_OBSERVATION
} from 'api/graphql/v2/mutations/Event';
import { plannerContext } from 'pages/MainCalendarPage';
import moment from 'moment';
import { mainCalendarPrefDayVar } from 'utils/cache/calendar';
import { useReactiveVar } from '@apollo/client';
import { IEvent } from 'model/v2';
import { LocationType } from 'views/components/icons/eventIcons/locationIcon';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';

interface Props {
  setIsAddObservationMode: (val: boolean) => void;
  setIsObserversDropdownShown: (val: boolean) => void;
  abaObserversList: IEvent[];
  setAbaObserversList: Dispatch<SetStateAction<IEvent[]>>;
  lastAbaObserver: IEvent;
  setLastAbaObserver: Dispatch<SetStateAction<IEvent>>;
  leadEventId: number;
  setIsSaving: (val: boolean) => void;
  isSaving: boolean;
  isValidated: boolean;
  setVisible: (val: boolean) => void;
  event: IEvent;
  setIsLocationSelected: (val: boolean) => void;
  abaObserverId: number;
  abalocationType: LocationType;
}
const AddAbaObserversButtons: React.FC<Props> = ({
  abaObserversList,
  setIsAddObservationMode,
  setAbaObserversList,
  lastAbaObserver,
  setLastAbaObserver,
  leadEventId,
  setIsSaving,
  isSaving,
  isValidated,
  setVisible,
  event,
  setIsLocationSelected,
  abaObserverId,
  abalocationType
}) => {
  const { handleDateSelect } = useContext(plannerContext);
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const [putAbaObservations] = useMutation(PUT_ABA_OBSERVATION);
  const [putObservations] = useMutation(PUT_OBSERVATION);
  const onSave = async () => {
    if (
      abaObserverId !== undefined &&
      lastAbaObserver.locationType === undefined
    ) {
      setIsLocationSelected(false);
      return;
    }
    setIsSaving(true);
    const currentObserversPayLoad = [...abaObserversList];
    if (
      lastAbaObserver.id !== undefined &&
      lastAbaObserver.locationType !== undefined
    ) {
      currentObserversPayLoad.push(lastAbaObserver);
    }
    if (event.appointmentType?.eventType?.name === EVENT_TYPES.ABA) {
      await putAbaObservations({
        variables: {
          leadEventId,
          currentObservers: currentObserversPayLoad
        }
      });
    } else {
      await putObservations({
        variables: {
          leadEventId,
          currentObservers: currentObserversPayLoad
        }
      });
    }
    setVisible(false);
    handleDateSelect(moment(calendarDate).toDate());
    setAbaObserversList([]);
    setLastAbaObserver({});
    setIsAddObservationMode(false);
    setIsSaving(false);
  };
  const onCancel = () => {
    setAbaObserversList([]);
    const Observations = event.observations
      ? event.observations.map(observation => ({
          id: observation.id,
          provider: { id: observation.provider!.id },
          locationType: observation.locationType,
          telehealthLink: observation.telehealthLink,
          startDate: observation.startDate,
          endDate: observation.endDate,
          duration: observation.duration
        }))
      : [];
    setAbaObserversList(Observations);
    setLastAbaObserver({});
    setIsAddObservationMode(false);
  };
  return (
    <div style={{ width: '40%', float: 'right' }}>
      <button className="cancel-btn" onClick={onCancel} disabled={isSaving}>
        Cancel
      </button>
      <button
        className="save-btn"
        onClick={onSave}
        disabled={
          isSaving ||
          isValidated ||
          (abaObserverId !== undefined && abalocationType === undefined) ||
          (abaObserverId === undefined && abalocationType !== undefined)
        }
      >
        Save
      </button>
    </div>
  );
};
export default AddAbaObserversButtons;
