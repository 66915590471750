export const TARGET_BLANK = '_blank';
export const TARGET_SELF = '_self';

export const ROUTE_PATHS = {
  PLANNER: '/planner',
  NEW_APPOINTMENT: '/planner/new-appointment',
  EDIT_APPOINTMENT: '/planner/edit-appointment',
  VIEW_APPOINTMENT: '/planner/view-appointment',
  WAIT_LIST_NEW_APPOINTMENT: '/planner/wait-list-new-appointment',
  CLIENT_AVAILABILITY: '/clients/',
  PROVIDER: '/providers/',
  PROVIDER_EDIT: '/staff/'
};

export const ROUTE_PARAMS = {
  CLIENT: '?client=',
  PROVIDER: '?provider=',
  EVENT: '?event=',
  WAITLIST_EVAL_TYPE: '&isDT=',
  WAITLIST_SUBTYPE: '&subType_Id=',
  WAITLIST_PARENT_TYPE: '&parentType_Id=',
  WAITLIST_NO_SESSIONS: '&Sessions=',
  WAITLIST_IS_ABA: '&isABA='
};

export const SMART_SCHEDULE_URL = '/smart-schedule';
