import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { CHECK_DB_STATUS, UNPAUSE_DB } from 'api/graphql/v2/queries/Tools';
import { useQuery, useLazyQuery } from 'react-apollo';
import { PulseLoader } from 'react-spinners';
import { PURPLE } from 'utils/constants/theme';

const divStyle: any = {
  justifyContent: 'center',
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  background: '#e6e6e6de',
  zIndex: 1000
};
const textStyle: any = {
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: '40vh',
  width: '95%'
};
const loaderStyle: any = {
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: '50vh',
  width: '95%'
};

const TIME_LEFT = 30;
const MINUTE_MS = 120000;
const GlobalLoadingIndicator: React.FC = () => {
  const apolloClient = useApolloClient();
  const [timeLeft, setTimeLeft] = React.useState(TIME_LEFT);
  const [communicationError, setCommunicationError] = React.useState(false);
  const [unpauseDB] = useLazyQuery(UNPAUSE_DB, {
    fetchPolicy: 'network-only'
  });
  useQuery(CHECK_DB_STATUS, {
    fetchPolicy: 'network-only',
    pollInterval: MINUTE_MS,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setCommunicationError(data?.checkDownDBStatus);
      if (data?.checkDownDBStatus) unpauseDB();
    }
  });

  React.useEffect(() => {
    if (communicationError) {
      setTimeout(() => {
        if (timeLeft <= 0) {
          setCommunicationError(false);
          setTimeLeft(TIME_LEFT);
          apolloClient.resetStore();
        } else {
          setTimeLeft(timeLeft - 1);
        }
      }, 1000);
    }
  });

  return communicationError ? (
    <div style={divStyle}>
      <p
        style={textStyle}
      >{`Cortica Dbase is loading, please wait… This takes about ( ${timeLeft} ) seconds`}</p>
      <PulseLoader css={loaderStyle} size={7} color={PURPLE} />
    </div>
  ) : null;
};
export default React.memo(GlobalLoadingIndicator);
