import gql from 'graphql-tag';
import { providerFragments } from '../fragments/Providers';

export const ADD_NEW_PROVIDER = gql`
  mutation($data: InsertProviderInput!) {
    insertProvider(data: $data) {
      ...Provider
    }
  }
  ${providerFragments.simpleProvider}
`;

export const UPDATE_PROVIDER = gql`
  mutation(
    $data: InsertProviderInput!
    $isProviderCapacityUpdateRequired: Boolean!
  ) {
    updateProvider(
      data: $data
      isProviderCapacityUpdateRequired: $isProviderCapacityUpdateRequired
    ) {
      ...Provider
    }
  }
  ${providerFragments.simpleProvider}
`;
