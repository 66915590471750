import React, { useState, useCallback } from 'react';
import { IRoom, RoomType } from 'model/v2';
import { Grid, Checkbox, Input, Icon } from 'semantic-ui-react';
import ModalSemantic from 'components/modalSemantic';
import EditRoomForm from 'pages/rooms/form';

interface IProps {
  key: string;
  room: IRoom;
  onAddRoom: (room: IRoom) => void;
}
interface IPropsFields {
  room: IRoom;
}
const RoomFields = React.memo(({ room }: IPropsFields) => (
  <>
    <Grid.Column>
      <label className={'title'}>{'Room Name'}</label>
      <Grid.Row>
        <label className={'room-type'}>{room.name}</label>
      </Grid.Row>
    </Grid.Column>
    <Grid.Column>
      <label className={'title'}>{'Amenities'}</label>
      <Grid.Row>
        <label className={'title'}>{'Seats'}</label>
        <Input className={'input'} focus readOnly value={room.seats} />
      </Grid.Row>
      <Grid.Row>
        <Checkbox label="TV" checked={room.hasTV} />
      </Grid.Row>
      <Grid.Row>
        <Checkbox label="Instruments" checked={room.hasInstruments} />
      </Grid.Row>
    </Grid.Column>
    <Grid.Column>
      <label className={'title'}>{'Room Type'}</label>
      <Grid.Row>
        <Checkbox
          radio
          label="Medical"
          checked={room.type === RoomType.medical}
        />
      </Grid.Row>
      <Grid.Row>
        <Checkbox
          radio
          label="All Puropse"
          checked={room.type === RoomType.allPurpose}
        />
      </Grid.Row>
      <Grid.Row>
        <Checkbox radio label="Gym" checked={room.type === RoomType.gym} />
      </Grid.Row>
    </Grid.Column>
  </>
));
const Room: React.FC<IProps> = ({ room, onAddRoom }: IProps) => {
  const [openForm, setOpenForm] = useState(false);

  const onCloseForm = useCallback(() => {
    setOpenForm(false);
  }, [setOpenForm]);

  const onEditForm = useCallback(() => {
    setOpenForm(true);
  }, [setOpenForm]);

  return (
    <Grid.Row className={'block'} columns={4}>
      <RoomFields room={room} />
      <Grid.Column floated="right" width={1}>
        <ModalSemantic
          title={'Edit Room'}
          open={openForm}
          trigger={<Icon link name={'edit'} onClick={onEditForm} />}
          content={
            <EditRoomForm
              room={room}
              onCloseForm={onCloseForm}
              onAddRoom={onAddRoom}
            />
          }
          onClose={onCloseForm}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
export default React.memo(Room);
