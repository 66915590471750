import { useQuery } from '@apollo/react-hooks';
import { AUTHORIZED_THERAPIES_FLATS } from 'api/graphql/queries/authorizations/index.js';
import { DEFAULT_AUTH_TAB_PAGE_SIZE } from 'utils/constants/default';
import { formatAuthFlatData } from 'utils/mappers/response/authorizations';

function useAuths({ sort, filter }) {
  const { data, loading, fetchMore, refetch } = useQuery(
    AUTHORIZED_THERAPIES_FLATS,
    {
      variables: {
        from: 0,
        size: DEFAULT_AUTH_TAB_PAGE_SIZE,
        search: null,
        sort,
        filter
      },
      notifyOnNetworkStatusChange: true
    }
  );

  const loadMore = async () => {
    return fetchMore({
      query: AUTHORIZED_THERAPIES_FLATS,
      notifyOnNetworkStatusChange: true,
      variables: {
        from: data?.authorizedTherapiesFlats?.auths?.length,
        size: DEFAULT_AUTH_TAB_PAGE_SIZE,
        search: null,
        sort,
        filter
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newAuths = fetchMoreResult?.authorizedTherapiesFlats?.auths || [];
        const count = fetchMoreResult?.authorizedTherapiesFlats?.count || null;
        const typename = fetchMoreResult?.authorizedTherapiesFlats?.__typename;

        return Object.assign({}, previousResult, {
          authorizedTherapiesFlats: {
            auths: [
              ...previousResult.authorizedTherapiesFlats?.auths,
              ...newAuths
            ],
            count,
            __typename: typename
          }
        });
      }
    });
  };

  const auths = formatAuthFlatData(data);
  return {
    auths,
    count: data?.authorizedTherapiesFlats?.count || 0,
    loading,
    loadMore,
    refetch
  };
}
export default useAuths;
