import React from 'react';
import telehealthIcon from 'assets/img/icon-telehealth.png';
import inClinicIcon from 'assets/img/icon-inClinic.png';
import offSiteIcon from 'assets/img/icon-offSite.png';
export type LocationType = 'Telehealth' | 'In Clinic' | 'Off-Site' | undefined;

export const locationIcons = {
  Telehealth: telehealthIcon,
  'In Clinic': inClinicIcon,
  'Off-Site': offSiteIcon
};

interface Props {
  locationType?: LocationType;
  className?: string;
}
const LocationIcon: React.FC<Props> = ({ locationType, className }: Props) => {
  const iconSource: string | undefined = locationType
    ? locationIcons[locationType]
    : undefined;
  return iconSource ? (
    <img src={iconSource} className={className} alt="logo" />
  ) : (
    <div style={{ width: '13px', height: '13px', display: 'inline-block' }} />
  );
};

export default LocationIcon;
