import styled from 'styled-components';
import { TimePicker } from 'antd';
import { PURPLE } from 'utils/constants/theme';

export const LibTimepicker = styled(TimePicker)`
  width: 100%;
  input {
    box-sizing: border-box;
    border-color: ${PURPLE};
    &:hover {
      border-color: ${PURPLE};
    }
    &:focus {
      border-color: ${PURPLE};
      box-shadow: 0 0 0 2px rgba(111, 75, 241, 0.22);
    }
  }
  &:hover .ant-time-picker-input:not(.ant-input-disabled) {
    border-color: ${PURPLE};
  }
`;
