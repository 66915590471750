import {
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle
} from 'react-beautiful-dnd';

export const getDraggingStyle = (
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot
) => {
  if (snapshot.isDragging) {
    return {
      ...style,
      color: '#6f42f5',
      border: '2px solid #c9cfd3',
      height: 'auto !important'
    };
  }
  return {
    ...style
  };
};
