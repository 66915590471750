import * as React from 'react';
import { Icon, SemanticICONS, Grid } from 'semantic-ui-react';

interface IProps {
  title: string;
  icon: SemanticICONS;
  onClick?: () => void;
}

const IconWithTitle: React.FC<IProps> = ({ title, icon, onClick }: IProps) => (
  <Grid>
    <Grid.Row>
      <Grid.Column columns={2}>
        <label style={{ marginRight: '10px' }}>{title}</label>
        <Icon.Group size="small">
          <Icon link name={icon} onClick={onClick} />
        </Icon.Group>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default React.memo(IconWithTitle);
