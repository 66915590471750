import styled from 'styled-components';

export const RangeTimePickerWrapper = styled.div`
  .range-time {
    padding: 4.8px;
    margin-top: 9px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-evenly;
    border-radius: 4px;
    width: 98px;
    .ant-input:nth-child(odd) {
      width: 10px;
    }
    .ant-input:nth-child(even) {
      width: 18px;
    }
    .ant-input {
      padding: 0;
    }
  }
  .range-time-selected {
    padding: 4.8px;
    margin-top: 9px;
    border: 1px solid #d9d9d9;
    display: flex;
    border-radius: 4px;
    justify-content: space-evenly;
    width: 98px;
    background: #6f42f5;
    color: #ffffff;
    .ant-input:nth-child(odd) {
      color: #ffffff;
      width: 10px;
    }
    .ant-input:nth-child(even) {
      color: #ffffff;
      width: 18px;
    }
    .ant-input {
      padding: 0;
      border: none;
    }
  }
  .dash {
    margin-top: 2px;
  }
`;
