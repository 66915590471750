import { IEvent } from 'model/v2';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  observations: IEvent[];
  isLoading?: boolean;
}
const NUM_OF_MANDATORY_ATTENDEES = 2;
const AttendeesHeader: React.FC<Props> = ({
  observations,
  children,
  isLoading
}) => {
  const numOfAttendees =
    (observations?.length ? observations?.length : 0) +
    NUM_OF_MANDATORY_ATTENDEES;

  return (
    <Grid.Row>
      <Grid.Column width={8}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <div style={{ display: 'flex', alignItems: 'right' }}>
                <h2 className="attendees-title">Attendees&nbsp;</h2>
                {!isLoading ? (
                  <p className="attendees-title">({numOfAttendees})</p>
                ) : (
                  <div className="attendees-title">
                    <Skeleton
                      variant="text"
                      children={
                        <p className="attendees-title">
                          ({NUM_OF_MANDATORY_ATTENDEES})
                        </p>
                      }
                    />
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column columns={10}>{children}</Grid.Column>
    </Grid.Row>
  );
};

export default AttendeesHeader;
