import * as Yup from 'yup';
import {
  INT_QUANTITY_REGEX,
  FLOAT_QUANTITY_REGEX,
  MORE_THAT_TWO_DECIMALS_REGEX,
  ZERO_OR_NONE_DECIMALS_REGEX
} from 'utils/constants/regex';
import { getApptType } from 'utils/common';

export const EMPTY_SCHEMA = Yup.object();

export const CARE_PLAN_SCHEMA = (apptTypes, quantityPatterns) => {
  return Yup.object().shape({
    recommendedEvaluations: Yup.array().of(
      Yup.object().shape({
        apptType: Yup.string().required('Appointment Type is required'),
        apptSubType: Yup.string().when('apptType', {
          is: apptType =>
            getApptType({ title: apptType }, apptTypes)?.isClinical,
          then: Yup.string().required('Appointment Sub-Type is required')
        }),
        clientResponse: Yup.string().required('Client Response is required')
      })
    ),
    recommendedTherapies: Yup.array().of(
      Yup.object().shape({
        apptType: Yup.string().required('Appointment Type is required'),
        apptSubType: Yup.string().when('apptType', {
          is: apptType =>
            getApptType({ title: apptType }, apptTypes)?.isClinical,
          then: Yup.string().required('Appointment Sub-Type is required')
        }),
        recommendedQuantity: Yup.string()
          .required('Recommended Quantity is required')
          .when('quantityPattern', {
            is: quantityPatterns.VISITS, // TODO: use enum here
            then: Yup.string()
              .test(
                'is-valid-int-quantity',
                'Error. Only integers.',
                quantity => !isInvalidIntQuantity(quantity)
              )
              .test(
                'is-valid-int-range',
                'Error. Range must be # - #',
                quantity => !isInvalidRange(quantity, INT_QUANTITY_REGEX)
              ),
            otherwise: Yup.string()
              .test(
                'is-valid-float-range',
                'Error. Range must be # - #',
                quantity => !isInvalidRange(quantity, FLOAT_QUANTITY_REGEX)
              )
              .test(
                'has-valid-decimals',
                'Error.',
                quantity => !hasInvalidDecimals(quantity)
              )
              .test(
                'max-two-decimals',
                'Error. Only two digits after decimal.',
                quantity => !hasMoreThanTwoDecimals(quantity)
              )
              .test(
                'has-one-decimal-points',
                'Error. Only one decimal point.',
                quantity => !hasMoreThanOneDecimalPoint(quantity)
              )
          }),
        requestedQuantity: Yup.mixed()
          .notRequired()
          .default(undefined)
          .test(
            'is-valid-int-quantity',
            'Error. Only integers.',
            quantity => !isInvalidInt(quantity)
          ),
        quantityPattern: Yup.string().required('Quantity Pattern is required')
      })
    )
  });
};

const isInvalidRange = (quantity, validValue) =>
  quantity &&
  quantity.includes('-') &&
  (quantity.split('-').length > 2 ||
    quantity
      .split('-')
      .map(n => n.trim())
      .some(n => !validValue.test(n)));

const isInvalidIntQuantity = quantity =>
  quantity &&
  !quantity.includes('-') && // not a range
  !INT_QUANTITY_REGEX.test(quantity); // not an int

const hasInvalidDecimals = quantity =>
  quantity && ZERO_OR_NONE_DECIMALS_REGEX.test(quantity);

const hasMoreThanTwoDecimals = quantity =>
  quantity && MORE_THAT_TWO_DECIMALS_REGEX.test(quantity);

const hasMoreThanOneDecimalPoint = quantity =>
  quantity && !quantity.includes('-') && quantity.split('.').length > 2;

const isInvalidInt = quantity => quantity && !INT_QUANTITY_REGEX.test(quantity);

export const CLIENT_EDIT_AVAILABILITY_BLOCK = Yup.object().shape({
  start: Yup.date().required('Start time is required'),
  end: Yup.date()
    .min(Yup.ref('start'), "End time can't be before start time")
    .required('End time is required'),
  location: Yup.number().required('You should provide location'),
  recurringDays: Yup.array()
    .of(Yup.number())
    .required('You should select a day')
});
