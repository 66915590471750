import React from 'react';
import { LibInput, LibTextArea } from 'lib/ui/input/input.style';
import { FormError } from './error';
import { AutoFormattedInputLip } from 'lib/ui/input/input';
import TextField from '@material-ui/core/TextField';

const Input = ({ field, errors, ...restProps }) => {
  return (
    <>
      {field?.name && <LibInput {...restProps} {...field} type="text" />}
      {field?.name && <FormError name={field.name} errors={errors} />}
    </>
  );
};

const TimerTextField = ({ field, errors, ...restProps }) => {
  return (
    <>
      {field?.name && <TextField type="time" {...restProps} />}
      {field?.name && <FormError name={field.name} errors={errors} />}
    </>
  );
};

const DateTextField = ({ filed, errors, ...restProps }) => {
  return (
    <>
      {filed?.name && <TextField type="date" {...restProps} />}
      {filed?.name && <FormError name={filed.name} errors={errors} />}
    </>
  );
};

const AutoFormattedInput = ({ field, errors, ...restProps }) => {
  return (
    <>
      <AutoFormattedInputLip {...restProps} {...field} />
      {field?.name && (
        <FormError
          name={field?.name}
          errors={errors}
          render={({ message }) => <div className="error">{message}</div>}
        />
      )}
    </>
  );
};

const TextArea = ({ field, errors, className, ...restProps }) => {
  return (
    <div className={className}>
      <LibTextArea {...restProps} {...field} />
      {field?.name && (
        <FormError
          name={field?.name}
          errors={errors}
          render={({ message }) => <div className="error">{message}</div>}
        />
      )}
    </div>
  );
};

const InputNumber = ({ field, errors, ...restProps }) => {
  return (
    <>
      {field?.name && (
        <input
          className="input-num"
          type="number"
          placeholder="0"
          {...restProps}
        />
      )}
      {field?.name && <FormError name={field.name} errors={errors} />}
    </>
  );
};

export {
  TextArea,
  AutoFormattedInput,
  Input,
  TimerTextField,
  DateTextField,
  InputNumber
};
