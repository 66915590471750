import React from 'react';
import { Modal } from 'semantic-ui-react';
import Header from './Header';
import { ModalInner } from './style';

interface IProps {
  content: JSX.Element;
  trigger: JSX.Element;
  actions?: JSX.Element;
  header?: JSX.Element;
  title?: string;
  description?: string;
  open: boolean;
  modalWidth?: number;
  onClose: () => void;
  onOpen?: () => void;
  img?: string;
}

const ModalSemantic: React.FC<IProps> = ({
  content,
  trigger,
  actions,
  header,
  title,
  description,
  open,
  modalWidth,
  onClose,
  onOpen,
  img
}: IProps) => {
  return (
    <Modal
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      trigger={trigger}
      style={{ width: modalWidth || 492, marginTop: '16vh' }}
      centered={false}
    >
      <ModalInner>
        {(title || header) && (
          <Modal.Header>
            {title && <Header title={title} img={img} onClose={onClose} />}
            {header && header}
          </Modal.Header>
        )}
        <Modal.Content scrolling>
          <div>
            {description && <p>{description}</p>}
            {content}
          </div>
        </Modal.Content>
        <Modal.Actions>{actions}</Modal.Actions>
      </ModalInner>
    </Modal>
  );
};
export default React.memo(ModalSemantic);
