import {
  getDraggedGroupBackGroundColor,
  getGroupBackgroundColor
} from 'helpers/calendarUIHelper';
import styled from 'styled-components';
import { calendarGroup } from 'model/calendar/groups';
import * as colors from 'utils/constants/theme';

interface Props {
  type: string;
  specialityColor?: string;
  isGroupDragged: boolean;
}
export const GroupWrapper = styled.div<Props>`
  background-color: ${props => getGroupBackgroundColor(props.type)};

  height: 100%;
  .clone {
    color: ${colors.LIGHT_GREY} !important;
    background-color: ${props => getGroupBackgroundColor(props.type)};
  }
  .inline-flex-wrap {
    display: flex;
    flex-direction: row !important;
  }
  .custom-group {
    background-color: ${props =>
      getDraggedGroupBackGroundColor(props.isGroupDragged, props.type)};

    //css written to display client info

    justify-content: space-between;
    display: flex;
    align-items: start;
    padding: 0 3px;
    height: auto;

    .client-case-render {
      width: 100% !important;
      padding-left: 5px !important;
    }
    .basic-data-shell {
      display: flex;
      flex-direction: column;
    }
    .basic-info {
      display: flex;
      width: 100%;
      height: auto;
    }
    .basic-info-block {
      width: 50%;
      height: auto;
      display: flex;
      span {
        font-size: 10px;
        display: inline-block;
        line-height: 15px !important;
      }
    }
    .basic-label {
      font-size: 10px;
      color: #8c9aa3;
    }
    .label-value {
      font-size: 10px;
      color: #8c9aa3;
    }
    .dob-row {
      padding-top: 3px !important;
    }
    .advance-info-shell {
      display: flex;
      flex-direction: column !important;
      z-index: 25;
      .advance-info-shell-child {
        width: 100%;
        height: 15px;
        text-overflow: ellipsis;
      }
      small {
        line-height: 10px;
      }
    }

    //css written to display client info
    .close-button {
      font-size: smaller;
      background-color: ${props =>
        getDraggedGroupBackGroundColor(props.isGroupDragged, props.type)};

      &:hover {
        background-color: ${props => getGroupBackgroundColor(props.type)};
      }
      .anticon-close {
        color: #415766;
        font-size: 18px;
      }
    }
    .clone-close-button {
      font-size: smaller;
      background-color: ${props => getGroupBackgroundColor(props.type)};
      .anticon-close {
        color: #415766;
        font-size: 18px;
      }
    }
    .group-identifier {
      justify-content: center;
      display: flex;
      align-items: start;
      margin-top: 6px;
      overflow: hidden;
      .client-bullet {
        width: 24px;
        height: 24px;
        border: solid 1px #979797;
        background-color: #d8d8d8;
        border-radius: 50%;
        margin-top: 2px;
      }
      .staff-bullet {
        width: 11px;
        height: 11px;
        background-color: ${props =>
          props.specialityColor ? props.specialityColor : '#45bfff'};
        border-radius: 50%;
        margin: auto;
      }
      .title {
        margin-left: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
      }

      .drag-icon {
        color: ${colors.LIGHT_PINK};
        margin-right: ${props =>
          props.type === calendarGroup.provider ? '8px' : '3px'};
        margin-top: 3px;
        margin-left: 3px;
      }
    }
  }
`;

export default GroupWrapper;
