export const PHONE_REGEX = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export const STATE_REGEX = /^[A-Z]{2}$/;

export const ZIP_REGEX = /^\d{1,5}$/;

export const SSN_REGEX = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

export const DATE_REGEX = /^([0-2]\d|3[01])\/([0]\d|1[0-2])\/\d{4}$/;

export const TIME_REGEX = /^(0[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/;

export const INT_QUANTITY_REGEX = /^[1-9]\d*$/;

export const FLOAT_QUANTITY_REGEX = /^([1-9]\d*|\d*\.[1-9]\d?)$/; // int or float with 2 decimal points max

export const MORE_THAT_TWO_DECIMALS_REGEX = /^\d*\.[1-9]\d\d+$/;

export const ZERO_OR_NONE_DECIMALS_REGEX = /^\d*\.0*$/;

export const ABSOLUTE_URL_PROTOCOL_REGEX = /^https?:/;

export const COLOR_REGEX = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;

export const ALPHANUMERIC_REGEX = /^[A-Za-z0-9]+$/;

export const FAX_REGEX = /^\+?[0-9]{6,}$/;
