import React from 'react';
import AppointmentProvider from './AppointmentProvider';
import { Row } from 'antd';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { useClinicsAndStaffFilters } from './appointmentClinicHook';

export default function AppointmentClinic({ disabledFormFields, client }) {
  const { control, setValue } = useFormContext();

  const providerDependencies = useWatch({
    control,
    name: [
      'clinicPreference',
      'appType',
      'isClinical',
      'appSubType',
      'duration',
      'locationType'
    ]
  });

  const {
    filteredStaffList,
    staffList,
    clinicOptionsList,
    clinicsDropdownAvalability,
    clinicsDropdownDisabled
  } = useClinicsAndStaffFilters(
    providerDependencies.appType,
    providerDependencies.isClinical,
    providerDependencies.clinicPreference,
    client.clinicId,
    providerDependencies.locationType
  );

  const isProviderDisabled =
    !(
      providerDependencies.appType &&
      providerDependencies.locationType &&
      providerDependencies.duration &&
      ((providerDependencies.isClinical && providerDependencies.appSubType) ||
        !providerDependencies.isClinical)
    ) || disabledFormFields.provider;

  return (
    <Row type="flex" justify="start" align="top" gutter={16}>
      <Controller
        name="clinicId"
        control={control}
        render={fieldProps => (
          <>
            {clinicsDropdownAvalability && (
              <FormItem label="Clinic Type" xs={16} sm={12}>
                <Select
                  placeholder="Select Clinic"
                  options={clinicOptionsList}
                  disabled={
                    disabledFormFields.clinicId || clinicsDropdownDisabled
                  }
                  field={fieldProps}
                  onSelect={val => setValue('selectedRoom', null)}
                />
              </FormItem>
            )}
          </>
        )}
      />
      <AppointmentProvider
        client={client}
        staffList={staffList}
        filteredStaffList={filteredStaffList}
        disabledFormFields={disabledFormFields}
        isProviderDisabled={isProviderDisabled}
      />
    </Row>
  );
}
