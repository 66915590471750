import { ILookup } from './lookup';

// make sure to be same as db data
export enum MEDAppointmentSubType {
  id = 'id',
  title = 'title',
  athenaId = 'athenaId',
  crName = 'crName',
  crType = 'crType',
  duration = 'duration',
  superType = 'superType',
  MEDSettingId = 'MEDSettingId'
}
export interface IMEDAppointmentSubType {
  [MEDAppointmentSubType.id]?: number;
  [MEDAppointmentSubType.title]: string;
  [MEDAppointmentSubType.athenaId]: string;
  [MEDAppointmentSubType.crName]: string;
  [MEDAppointmentSubType.crType]: string;
  [MEDAppointmentSubType.duration]?: number;
  [MEDAppointmentSubType.superType]: ILookup;
  [MEDAppointmentSubType.MEDSettingId]?: number;
}
