import RemoveIcon from 'assets/img/remove-btn-white.svg';

interface Props {
  showWarnPopup: boolean;
  setShowWarnPopup: React.Dispatch<React.SetStateAction<boolean>>;
}
const BulkCancelWarn: React.FC<Props> = ({
  showWarnPopup,
  setShowWarnPopup
}: Props) => {
  const handleClose = () => {
    setShowWarnPopup(false);
  };

  return (
    <>
      {showWarnPopup && (
        <div className="two-column">
          <div className="reason">
            Some events are single events and will be cancelled. Enter the
            reason for the cancellation.
          </div>
          <div className="remove-btn" onClick={handleClose}>
            <img src={RemoveIcon} alt="remove-btn" />
          </div>
        </div>
      )}
    </>
  );
};
export default BulkCancelWarn;
