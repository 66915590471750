import React from 'react';
import AppointmentTypeForm from './AppointmentTypeForm';
import AppointmentLocDurProv from './AppointmentLocDurProv';
import AppointmentRecurring from './AppointmentRecurring';
import AppointmentOther from './AppointmentOther';
import AppointmentClinic from './AppointmentClinic';

const AppointmentMainFields = ({ disabledFormFields, client }) => {
  return (
    <>
      <AppointmentTypeForm disabledFormFields={disabledFormFields} />
      <AppointmentLocDurProv
        disabledFormFields={disabledFormFields}
        client={client}
      />
      <AppointmentClinic
        disabledFormFields={disabledFormFields}
        client={client}
      />
      <AppointmentRecurring disabledFormFields={disabledFormFields} />
      <AppointmentOther disabledFormFields={disabledFormFields} />
    </>
  );
};

export default React.memo(AppointmentMainFields);
