import * as yup from 'yup';
import { AppointmentType } from 'model/appointment';
import { COLOR_REGEX } from 'utils/constants/regex';
import { isEmpty } from 'lodash';

export const APPOINTMENT_TYPE_SCHEMA = (
  appointmentTitles: string[],
  initialTitle: string
) =>
  yup.object().shape({
    [AppointmentType.title]: yup
      .string()
      .required('Title is required')
      .test('unique title', 'This title already exists', function(
        value?: string | null
      ) {
        return (
          !appointmentTitles.includes(value?.toLowerCase().trim()!) ||
          (!isEmpty(initialTitle) &&
            value?.toLowerCase().trim() === initialTitle.toLowerCase())
        );
      }),
    [AppointmentType.headerColor]: yup
      .string()
      .required('Header Color is required')
      .matches(COLOR_REGEX, 'invalid color'),
    [AppointmentType.backgroundColor]: yup
      .string()
      .required('Background Color is required')
      .matches(COLOR_REGEX, 'invalid color'),
    [AppointmentType.dayAvailabilityTypes]: yup
      .array()
      .of(yup.string())
      .min(1, 'At least one availability slot type must be mapped'),
    [AppointmentType.salesForceMapping]: yup.object().shape({
      id: yup.number().nullable(),
      salesforceTherapyTitle: yup.string().nullable()
    }),
    [AppointmentType.paycodes]: yup.string().required('Paycode is required')
  });
