import React from 'react';
import * as PropTypes from 'prop-types';

import { LibRadio, LibRadioGroup } from './radio.style';

const Radio = ({ children, ...restProps }) => {
  return <LibRadio {...restProps}>{children}</LibRadio>;
};

const RadioGroup = ({ children, ...restProps }) => {
  return <LibRadioGroup {...restProps}>{children}</LibRadioGroup>;
};

Radio.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};
RadioGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Radio.defaultProps = {
  children: null
};
RadioGroup.defaultProps = {
  children: null
};

export default Radio;
export { Radio, RadioGroup };
