import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import { mapRoomIdToFullData } from 'utils/mappers/form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { IClinic, IOption, IRoom } from 'model/v2';

interface Props {
  clinicId: string;
  smart: boolean;
  clinicsMap: Map<any, IClinic>;
}
const RoomsForm: React.FC<Props> = ({ clinicId, smart, clinicsMap }: Props) => {
  const methods = useFormContext();
  const [options, setOptions] = React.useState<IOption[]>([]);
  React.useMemo(() => {
    const clinic = clinicsMap.get(clinicId);
    const roomsList: IOption[] = (clinic?.rooms || []).map((room: IRoom) => {
      return {
        label: mapRoomIdToFullData(room),
        value: room.id
      };
    });
    setOptions(roomsList);
  }, [clinicId, clinicsMap, setOptions]);
  return smart ? (
    <Controller name="room.id" control={methods.control} render={() => <></>} />
  ) : (
    <FormItem optional={false} label="Rooms">
      <Controller
        name="room.id"
        control={methods.control}
        render={fieldProps => (
          <Select
            placeholder="Select Room"
            options={options}
            field={fieldProps}
            errors={methods.errors}
          />
        )}
      />
    </FormItem>
  );
};
export default React.memo(RoomsForm);
