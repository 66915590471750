import React from 'react';
import { LibCarousel } from './carousel.style';
import * as PropTypes from 'prop-types';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const Carousel = ({ children, ...restProps }) => {
  const nextArrow = (
    <span>
      <RightOutlined />
    </span>
  );
  const prevArrow = (
    <span>
      <LeftOutlined />
    </span>
  );
  return (
    <LibCarousel nextArrow={nextArrow} prevArrow={prevArrow} {...restProps}>
      {children}
    </LibCarousel>
  );
};

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Carousel.defaultProps = {
  children: null
};

export default Carousel;
export { Carousel };
