import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { IOption } from 'model';
import { useCallback, useState } from 'react';
interface Props {
  cancelReasons: IOption[];
  isDeleteOrAdminEvent: boolean;
}
const CancelReasonDropdown = ({
  cancelReasons,
  isDeleteOrAdminEvent
}: Props) => {
  const methods = useFormContext();
  const getPopupContainer = useCallback(() => {
    return document.body;
  }, []);
  const [isSelected, setIsSelected] = useState(false);
  const cancelReasonSelect = (e: any) => {
    console.log(e, 'myselected');
    setIsSelected(true);
  };
  return (
    <Controller
      name="cancelReason"
      control={methods.control}
      render={fieldProps =>
        isDeleteOrAdminEvent ? (
          <></>
        ) : (
          <div
            className={isSelected ? 'select-reason-selected' : 'select-reason'}
          >
            <Select
              showSearch
              onSelect={cancelReasonSelect}
              placeholder="Select Reason"
              options={cancelReasons}
              dropdownStyle={{ border: '1px solid red' }}
              field={fieldProps}
              filterOption={(
                input: string | undefined,
                option: { children: string }
              ) =>
                option.children
                  ?.toLowerCase()
                  .includes(input?.toLowerCase() ?? '')
              }
              errors={methods.errors}
              getPopupContainer={getPopupContainer}
            />
          </div>
        )
      }
    />
  );
};

export default CancelReasonDropdown;
