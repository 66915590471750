import styled from 'styled-components';
import { Calendar } from 'react-big-calendar';
import * as colors from 'utils/constants/theme';

export const LibBigCalendar = styled(Calendar)`
  .rbc-allday-cell {
    display: none;
  }
  .rbc-month-row {
    overflow: inherit;
  }
  .rbc-today {
    background-color: transparent;
  }
  .rbc-time-content {
    border: none;
  }
  .rbc-time-view {
    border: none;
    width: 1245px;
    margin: auto;
  }
  .rbc-time-view {
    position: relative;
  }
  .rbc-time-header {
    padding-top: 20px;
  }
  .rbc-time-header-gutter {
    display: flex;
    justify-content: center;
    position: static !important;
  }
  .rbc-time-gutter,
  .rbc-time-header-gutter {
    border: none;
    .rbc-timeslot-group {
      border-bottom: none;
    }
  }
  .rbc-time-header {
    .rbc-time-header-content {
      border-left: none;
      .rbc-row.rbc-row-resource {
        border-bottom: none;
      }
    }
  }
  .rbc-time-content {
    .rbc-day-slot {
      .rbc-time-slot {
        border-color: rgba(65, 87, 102, 0.1);
      }
    }
  }
  .rbc-time-slot {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: uppercase;
    text-align: right;
    padding-right: 10px;
    color: ${colors.INACTIVE_URL};
  }
  .rbc-current-time-indicator {
    border: 1px solid #d36b61;
  }
  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
  }
  .rbc-events-container {
    .na {
      background-image: linear-gradient(
        45deg,
        #dbdbdb 6.52%,
        #f8f8f8 6.52%,
        #f8f8f8 50%,
        #dbdbdb 50%,
        #dbdbdb 56.52%,
        #f8f8f8 56.52%,
        #f8f8f8 100%
      );
      background-size: 18px 18px;
      pointer-events: none;
      > * {
        opacity: 0;
      }
    }
    .interseption {
      background-image: linear-gradient(
        120deg,
        #007aff 10%,
        #8be07d 10%,
        #8be07d 20%,
        #007aff 20%,
        #007aff 30%,
        #8be07d 30%,
        #8be07d 40%,
        #007aff 40%,
        #007aff 50%,
        #8be07d 50%,
        #8be07d 60%,
        #007aff 60%,
        #007aff 70%,
        #8be07d 70%,
        #8be07d 80%,
        #007aff 80%,
        #007aff 100%
      );
      background-size: auto 140px;
    }
    .rbc-event {
      border: none;
      padding: 0;
      .rbc-event-label {
        z-index: 10;
        background: inherit;
        position: relative;
        width: 100%;
        max-width: 130px;
        padding: 5px 7px;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75em;
        letter-spacing: normal;
        text-transform: lowercase;
        color: ${colors.AVATAR_INITIAL};
      }
      .rbc-event-content {
        padding: 2px 0px 0px 10px;
        background-color: rgba(255, 255, 255, 0.9);
        p {
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: ${colors.AVATAR_INITIAL};
        }
        img {
          width: 13px;
          height: 13px;
          margin-right: 4px;
        }
      }
    }
  }
  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    min-width: 140px;
  }
`;
