import React from 'react';
import { Button } from 'lib/ui';
import { STEPS } from './utils';
import { IConflictMapped } from 'model/v2';
import { WHITE } from 'utils/constants/theme';
import { MileageFooterWrapper } from '../style';

interface Props {
  conflicts: IConflictMapped[];
  setStep: (val: number) => void;
  backToForm: () => void;
}

const MileageWarningFooter: React.FC<Props> = ({
  setStep,
  conflicts,
  backToForm
}: Props) => {
  return (
    <MileageFooterWrapper>
      <div
        style={{
          background: WHITE,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          borderTop: '1px solid #d8d8d8',
          padding: '30px'
        }}
      >
        <h2 className="mileage-header">Warning</h2>
        <p className="mileage-error">
          The mileage entered is more than 50 miles. Please review before
          submitting.
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            className="secondary"
            type=""
            onClick={() => {
              if (conflicts.length > 0) {
                setStep(STEPS.CONFLICTS);
              } else {
                setStep(STEPS.SUBMIT);
              }
            }}
          >
            Keep on Schedule
          </Button>
          <Button className="secondary" type="" onClick={backToForm}>
            Back To Form
          </Button>
        </div>
      </div>
    </MileageFooterWrapper>
  );
};
export default React.memo(MileageWarningFooter);
