import React, { useState, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Checkbox } from 'semantic-ui-react';
import HoursInfoTimer from './HourInfoTimer';
import _ from 'lodash';

interface IProps {
  label?: boolean;
  name: string;
  checked?: boolean;
}
const HourInfoItem: React.FC<IProps> = ({ name, label, checked }: IProps) => {
  const { control, setValue } = useFormContext();
  const [disabled, setDisabled] = useState(checked);

  const toggleCheckBox = useCallback(
    (_e, data) => {
      setDisabled(data.checked);
      setValue(`businessHours.${name}.checked`, data.checked, {
        shouldDirty: true
      });
    },
    [setDisabled, setValue, name]
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={1}>
          <label style={{ marginRight: '20px' }}> {_.capitalize(name)} </label>
        </Grid.Column>
        <Grid.Column width={4}>
          {label && <label> {'Open'} </label>}
          {!label && (
            <HoursInfoTimer
              name={`businessHours.${name}.open`}
              disabled={disabled}
            />
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          {label && <label> {'Close'} </label>}
          {!label && (
            <HoursInfoTimer
              name={`businessHours.${name}.close`}
              disabled={disabled}
            />
          )}
        </Grid.Column>
        <Grid.Column width={1}>
          {!label && (
            <>
              <Controller
                control={control}
                name={`businessHours.${name}.checked`}
                render={() => (
                  <Checkbox
                    label={'Closed all day'}
                    style={{ width: '150px' }}
                    defaultChecked={checked}
                    onChange={toggleCheckBox}
                  />
                )}
              />
              <Controller
                control={control}
                name={`businessHours.${name}.id`}
                render={() => <></>}
              />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default React.memo(HourInfoItem);
