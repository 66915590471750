import React from 'react';
import { HeaderNav } from './header.style';
import * as PropTypes from 'prop-types';
import { Button } from 'lib/ui';
import { CloseOutlined } from '@ant-design/icons';
import ProfilePicture from 'views/components/ui/profile/ProfilePicture';

const Header = ({
  title,
  source,
  onCancel,
  handleSubmit,
  disabled,
  buttonText,
  loading,
  newAppointment,
  ...restProps
}) => (
  <HeaderNav {...restProps}>
    <div className={newAppointment ? 'fixed-bar' : 'navbar'}>
      <div className="inner-wrap">
        <div className="inner-title">
          <h2>{title}</h2>
          {newAppointment && newAppointment.clientSelected && (
            <div className="new-appointment">
              <Button className="large">
                {newAppointment.appointmentTitle
                  ? newAppointment.appointmentTitle
                  : 'New Appointment'}
              </Button>
            </div>
          )}
          {source && (
            <div className="user-info">
              <ProfilePicture img={source.photo} alt={source.name} />
              <p onClick={onCancel}>{source.name}</p>
            </div>
          )}
        </div>
        <div className="button-wrap">
          <Button
            className="large"
            onClick={handleSubmit}
            disabled={disabled}
            loading={loading}
          >
            {buttonText}
          </Button>
          <span>
            <CloseOutlined onClick={onCancel} />
          </span>
        </div>
      </div>
    </div>
  </HeaderNav>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  source: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  newAppointment: PropTypes.object
};

Header.defaultProps = {
  title: '',
  source: null,
  buttonText: 'Save',
  disabled: false,
  newAppointment: null
};

export default Header;
