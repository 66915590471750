import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAppointmentTypes } from '../../newAppointmentHooks';

import OpeningsForm from './OpeningsForm';

function AppointmentOpenings({ clientId, disabledFormFields }) {
  const { apptTypes, loadingApptTypes } = useAppointmentTypes();

  const { control } = useFormContext();
  const provider = useWatch({ control, name: 'provider' });

  if (loadingApptTypes || !Array.isArray(apptTypes) || !provider) return null;

  return (
    <>
      <OpeningsForm
        clientId={clientId}
        disabledFormFields={disabledFormFields}
        apptTypes={apptTypes}
      />
    </>
  );
}

export default React.memo(AppointmentOpenings);
