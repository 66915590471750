import React from 'react';
import { formatPatternString } from 'utils/mappers/response/authorizations';

export const ExpandedRowForWaitlistNewAppointment = ({
  ideal,
  receiving,
  pending,
  toFulfill,
  quantity,
  isRecommendedTherapy
}) => (
  <div className="expandedRowWrapper">
    <div className="left">
      <span>Ideal: </span>
      <span>Receiving: </span>
      <span>Pending: </span>
      <span>To Fulfill: </span>
    </div>
    <div className="right">
      <span>{formatPatternString(ideal, quantity, isRecommendedTherapy)}</span>
      <span className="minus">
        {formatPatternString(receiving, quantity, isRecommendedTherapy)}
      </span>
      <span className="with_underline minus">
        {formatPatternString(pending, quantity, isRecommendedTherapy)}
      </span>
      <span>
        {' '}
        = {formatPatternString(toFulfill, quantity, isRecommendedTherapy)}
      </span>
    </div>
  </div>
);
