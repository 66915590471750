import styled from 'styled-components';
import { DatePicker } from 'antd';
import { PURPLE } from 'utils/constants/theme';

export const LibDatepicker = styled(DatePicker)`
  input {
    box-sizing: border-box;
    border-color: ${({ disabled }) => (disabled ? 'inherit' : PURPLE)};
    &:hover {
      border-color: ${PURPLE};
    }
    &:focus {
      border-color: ${PURPLE};
      box-shadow: 0 0 0 2px rgba(111, 75, 241, 0.22);
    }
  }
  &:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: ${PURPLE};
  }
`;
