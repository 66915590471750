import React from 'react';
import { Button } from 'lib/ui';
import { IFormEvent, IProvider } from 'model/v2';
import { IOption } from 'model';
import { ConflictsFooterWrapper } from '../style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {
  prepareAdminForSubmissionV2,
  prepareEventForSubmissionV2
} from 'utils/mappers/request/events';
import { getConflictsVariables } from './utils';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import { clean } from 'utils/common';

interface Props {
  backToForm: () => void;
  recurringOptions: IOption[];
  formData: IFormEvent | null;
  providersMap: Map<any, IProvider>;
  doConflictsRequest: (val: any) => void;
}
const ObserverWarningFooter: React.FC<Props> = ({
  backToForm,
  recurringOptions,
  formData,
  providersMap,
  doConflictsRequest
}: Props) => {
  const rescheduleEvent = () => {
    const isAdmin =
      formData?.apptCategory?.split(': ')[1] === APPOINTMENT_CATEGORY[3].value;
    const mapped = clean(
      isAdmin
        ? prepareAdminForSubmissionV2(formData, providersMap)
        : prepareEventForSubmissionV2(formData)
    );
    const variables = isAdmin
      ? {}
      : getConflictsVariables(formData, [mapped], recurringOptions)[0];
    doConflictsRequest({ variables });
  };
  return (
    <ConflictsFooterWrapper>
      <h2>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          style={{ color: `#E82424` }}
        />{' '}
        Attention
      </h2>
      <p>
        Rescheduling this appointment will change it for any Observers
        attending.
      </p>
      <div className="buttons">
        <Button className="secondary" type="" onClick={backToForm}>
          No, Back To Form
        </Button>
        <Button
          className="secondary"
          type=""
          onClick={() => {
            rescheduleEvent();
          }}
        >
          Yes, Reschedule
        </Button>
      </div>
    </ConflictsFooterWrapper>
  );
};
export default React.memo(ObserverWarningFooter);
