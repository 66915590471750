import React from 'react';
import { toWords } from 'number-to-words';
import { Button } from 'lib/ui';
import SliderItems from 'views/components/forms/appointment/components/Other/SliderItems';
import { IConflictMapped } from 'model/v2';
import { STEPS } from './utils';
import { ConflictsFooterWrapper } from '../style';

interface Props {
  conflicts: IConflictMapped[];
  setStep: (val: number) => void;
  backToForm: () => void;
}
const ConflictsFooter: React.FC<Props> = ({
  conflicts,
  backToForm,
  setStep
}: Props) => {
  return (
    <ConflictsFooterWrapper>
      <h2>Warning</h2>
      <p>
        The series you’ve created has {toWords(conflicts.length)} (
        {conflicts.length}) conflict(s):
      </p>
      <SliderItems events={conflicts} isSidebar={true} />
      <div className="buttons">
        <Button
          className="secondary"
          type=""
          onClick={() => {
            setStep(STEPS.SUBMIT);
          }}
        >
          Keep on Schedule
        </Button>
        <Button className="secondary" type="" onClick={backToForm}>
          Back To Form
        </Button>
      </div>
    </ConflictsFooterWrapper>
  );
};
export default React.memo(ConflictsFooter);
