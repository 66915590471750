import React from 'react';
import { ContentWrapper } from './style';
import { Button } from 'semantic-ui-react';

interface IProps {
  loading: boolean;
  disabled: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const PopupActions: React.FC<IProps> = ({
  loading,
  disabled,
  onCancel,
  onConfirm
}: IProps) => {
  return (
    <ContentWrapper className="action-btn">
      <Button basic onClick={onCancel} color="grey">
        Cancel
      </Button>
      <Button
        basic
        onClick={onConfirm}
        color="teal"
        loading={loading}
        disabled={disabled}
      >
        Confirm
      </Button>
    </ContentWrapper>
  );
};
export default React.memo(PopupActions);
