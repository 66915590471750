import styled from 'styled-components';

export const RangeTimePickerModalWrapper = styled.div`
  .input-range {
    left: 20px;
    right: 125px;
    height: 50px;
    background: white;
  }
  .input-range__track--active {
    background: #6f42f5;
  }
  .input-range__label {
    font-family: Lato;
    font-size: 14px;
    margin-top: -5px;
  }
  .input-range__label--min,
  .input-range__label--max {
    margin-bottom: 15px;
  }
  .input-range__slider {
    margin-bottom: 30px;
    background: white;
    height: 1.4rem;
    margin-top: -0.8rem;
    width: 1.4rem;
  }
`;
