import React from 'react';
import CalendarIcon from 'assets/img/calenderIcon.png';

interface Props {
  onClick:
    | ((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void)
    | undefined;
  className?: string;
  children: React.ReactNode;
}
const MonthCalendarIcon: React.FC<Props> = ({
  onClick,
  className,
  children
}: Props) => (
  <>
    <img
      className={className}
      src={CalendarIcon}
      alt="calendarIcon"
      onClick={onClick}
    />
    {children}
  </>
);

export default React.memo(MonthCalendarIcon);
