import { ISmartSingleOpenings } from 'model/v2';
import React, { useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import { HeaderStatisticsWrapper } from './Style';
import { removePhantomEvents } from 'utils/cache/calendar';
import { calendarEventsPrefVar } from 'utils/cache/filters';

interface IProps {
  smartResults: ISmartSingleOpenings;
  toggleResultsView: any;
  visibleResults: boolean;
  isAba: boolean;
  onClose: () => void;
  setDisplayScroll?: React.Dispatch<React.SetStateAction<boolean>>;
  setCount?: React.Dispatch<React.SetStateAction<number>>;
  setSmartResults: React.Dispatch<
    React.SetStateAction<ISmartSingleOpenings | undefined>
  >;
}
const DTSingleResultStatistics: React.FC<IProps> = ({
  smartResults,
  toggleResultsView,
  visibleResults,
  setDisplayScroll,
  onClose,
  setCount,
  setSmartResults
}: IProps) => {
  const handleClick = useCallback(() => {
    if (onClose) onClose();
    if (visibleResults) {
      removePhantomEvents(calendarEventsPrefVar());
    }
    setCount?.(1);
    setDisplayScroll?.(true);
    toggleResultsView();
    setSmartResults(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleResultsView, visibleResults]);

  return (
    <HeaderStatisticsWrapper>
      <div className="bars">
        <Icon name="sidebar" color="grey" onClick={handleClick} link />
      </div>
      <div className="title">
        <label className="potentialResults">
          {smartResults?.openingCards?.length}
        </label>
        <label> Potential Results</label>
      </div>
    </HeaderStatisticsWrapper>
  );
};
export default React.memo(DTSingleResultStatistics);
