import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { IProvider } from 'model/v2';

interface Props {
  providers: IProvider[];
  checkedItems: Set<Number>;
  onHandleChange: (id: number, isChecked?: boolean) => void;
}

const ProviderItems = ({ providers, checkedItems, onHandleChange }: Props) => {
  return (
    <>
      {providers?.length > 0 && (
        <>
          {providers.map(provider => {
            return (
              <div className="saved_filters" key={provider.id}>
                <Checkbox
                  className="saved-filters_checkbox"
                  label={provider.name}
                  name={provider.name}
                  value={provider.id}
                  checked={checkedItems.has(provider.id || 0)}
                  onChange={(_e, data) =>
                    onHandleChange(provider.id!, data.checked)
                  }
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export default React.memo(ProviderItems);
