import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { TimerTextField } from 'api/sharedComponents/reactHookFormComponents';
import { useState } from 'react';
import { checkMinutesInterval } from 'utils/format/time';
import { MINUTES_INTERVAL_ERROR } from 'utils/constants/errors';
import { useEffect } from 'react';
import { DayAvailability } from 'model/v2/availabilityTemplate';
import { WeekEnd } from 'utils/constants/availabilityTemplate';
import { InputNumber } from 'api/sharedComponents/reactHookFormComponents/input';
interface IProps {
  dayName: string;
  index: number;
  data: DayAvailability | undefined;
  isToShowDailyMax: boolean;
}

const RecurringEvent: React.FC<IProps> = ({
  dayName,
  index,
  data,
  isToShowDailyMax
}) => {
  const {
    control,
    errors,
    watch,
    setValue,
    setError,
    trigger
  } = useFormContext();
  const indexName = `Day[${index}].startTime`;
  const [time, setTime] = useState<string | undefined>(
    data?.startTime?.isValid() ? data.startTime.format('HH:mm') : undefined
  );

  useEffect(() => {
    setTime(
      data?.startTime?.isValid() ? data.startTime.format('HH:mm') : undefined
    );
  }, [data, setTime]);

  const getLength = () => {
    if (data !== undefined) {
      return data?.length;
    }
    return index === WeekEnd.Sunday || index === WeekEnd.Saturday ? 0 : 9;
  };

  let hasError = false;
  if (errors.Day !== undefined && errors.Day[index] !== undefined) {
    hasError = true;
  }

  const startTime = watch(indexName);
  const length = watch(`Day[${index}].length`);

  const dimmedClass = useMemo(() => {
    if (
      (time === undefined || startTime?.isValid() === false) &&
      (length === undefined || length === 0 || length === '')
    ) {
      return 'dimmed';
    }
    return '';
  }, [startTime, length, time]);

  const timeHandler = React.useCallback(
    event => {
      const time = event.target.value;
      const momentValue = moment(time, 'hh:mm')
        .set('millisecond', 0)
        .day(index);
      setValue(indexName, momentValue, {
        shouldDirty: true
      });

      setTime(momentValue?.format('HH:mm'));
      if (!checkMinutesInterval(time)) {
        setError(indexName, {
          message: MINUTES_INTERVAL_ERROR,
          shouldFocus: true
        });
      }
      trigger(indexName);
    },
    [indexName, setValue, setError, trigger, index]
  );
  return (
    <div className="recurring-item">
      <FormItem label={dayName}>
        <div className={`${dimmedClass}`}>
          <Controller
            name={indexName}
            control={control}
            error={errors}
            defaultValue={
              time
                ? moment(time, 'hh:mm')
                    .set('millisecond', 0)
                    .day(index)
                : moment.invalid()
            }
            render={fieldProps => (
              <TimerTextField
                field={fieldProps}
                errors={errors}
                value={time}
                onChange={timeHandler}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 900
                }}
              />
            )}
          />
        </div>
      </FormItem>
      <FormItem label="Length">
        <div className={`${dimmedClass}`}>
          <Controller
            name={`Day[${index}].length`}
            control={control}
            error={errors}
            defaultValue={getLength()}
            render={fieldProps => (
              <input
                className="input-num"
                type="number"
                placeholder="0"
                {...fieldProps}
              ></input>
            )}
          />
        </div>
      </FormItem>
      {isToShowDailyMax && (
        <FormItem label="Daily Max">
          <div className={`${dimmedClass}`}>
            <Controller
              name={`dailyMaxHours[${index}].value`}
              control={control}
              error={errors}
              render={fieldProps => (
                <InputNumber
                  field={fieldProps}
                  errors={errors}
                  {...fieldProps}
                />
              )}
            />
          </div>
        </FormItem>
      )}
      {hasError && <p className="error-msg">{errors.Day[index].message}</p>}
      {hasError && (
        <p className="error-msg">{errors.Day[index]?.length?.message}</p>
      )}
    </div>
  );
};

export default RecurringEvent;
