export interface ILocation {
  id?: number;
  title?: string;
  color?: string;
  borderColor?: string;
}

export enum SmartScheduleLocation {
  InClinic = 'in-clinic',
  Offsite = 'off-site',
  Telehealth = 'telehealth'
}
