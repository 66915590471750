import { RefObject, useEffect } from 'react';

type Ref = RefObject<HTMLDivElement>;
const useClickOutsideHook = (ref: Ref, handleOutsideClick: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        handleOutsideClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handleOutsideClick]);
};

export default useClickOutsideHook;
