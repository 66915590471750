import { IEvent, IFullAppointmentType, IProvider } from 'model/v2';
import React, { Dispatch, useCallback, useEffect } from 'react';
import { AttendeesViewWrapper } from './style';
import { Grid } from 'semantic-ui-react';
import { ICalendarResource } from 'model/calendar/filters';
import AttendeesSection from './AttendeesView/AttendeesSection';
import ShadowPhantomEvents from '../FormPhantomEvents/ShadowPhantomEvents';

import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import AddAbaObserversView from './AttendeesView/AddAbaObserversView';
interface Props {
  event: IEvent;
  paginationArray?: ICalendarResource[];
  setPaginationArray?: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  providersMap?: Map<number, IProvider>;
  apptTypes?: IFullAppointmentType[];
  setVisible?: (val: boolean) => void;
  isAddObservationMode?: boolean;
  setIsAddObservationMode?: Dispatch<React.SetStateAction<boolean>>;
  observersData?: any;
  isLoading?: boolean;
}
const AttendeesView: React.FC<Props> = ({
  event,
  paginationArray,
  setPaginationArray,
  providersMap,
  apptTypes,
  setVisible,
  isAddObservationMode,
  setIsAddObservationMode,
  observersData,
  isLoading
}: Props) => {
  const { provider, client } = event;
  const getInitalObserversList = () => {
    return event.observations
      ? event.observations.map(observation => observation.provider?.id!)
      : [];
  };

  const getInitalAbaObserversList = useCallback(() => {
    return event.observations
      ? event.observations.map(observation => ({
          id: observation.id,
          provider: { id: observation.provider!.id },
          locationType: observation.locationType,
          telehealthLink: observation.telehealthLink,
          startDate: observation.startDate,
          endDate: observation.endDate,
          duration: observation.duration
        }))
      : [];
  }, [event.observations]);

  const [observersList, setObserversList] = React.useState<number[]>(
    getInitalObserversList()
  );

  const [abaObserversList, setAbaObserversList] = React.useState<IEvent[]>(
    getInitalAbaObserversList()
  );
  const [lastAbaObserver, setLastAbaObserver] = React.useState<IEvent>({});
  const [observerId, setObserverId] = React.useState<number>();
  const [abaObserversListObj, setAbaObserversListObj] = React.useState<IEvent>(
    {}
  );
  useEffect(() => {
    if (isAddObservationMode) {
      setObserversList(getInitalObserversList());
    } else {
      setObserversList(getInitalObserversList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddObservationMode]);

  useEffect(() => {
    setAbaObserversList(getInitalAbaObserversList());
  }, [getInitalAbaObserversList]);

  const observations: IEvent[] = event.observations!;
  return (
    <AttendeesViewWrapper specialityColor={provider?.speciality?.color}>
      <ShadowPhantomEvents
        observersList={observersList}
        abaObserversList={abaObserversList}
        lastAbaObserver={lastAbaObserver}
        observerId={observerId}
        event={event}
        providersMap={providersMap!}
        paginationArray={paginationArray!}
        setPaginationArray={setPaginationArray!}
        apptTypes={apptTypes!}
        isAddObservationMode={isAddObservationMode!}
        abaObserversListObj={abaObserversListObj}
      />
      {isAddObservationMode ? (
        event.appointmentType?.eventType?.name === EVENT_TYPES.DT ? (
          <AddAbaObserversView
            event={event}
            abaObserversList={abaObserversList}
            setAbaObserversList={setAbaObserversList}
            lastAbaObserver={lastAbaObserver}
            setLastAbaObserver={setLastAbaObserver}
            observerId={observerId}
            setObserverId={setObserverId}
            providersMap={providersMap!}
            apptTypes={apptTypes!}
            setPaginationArray={setPaginationArray!}
            paginationArray={paginationArray!}
            setIsAddObservationMode={setIsAddObservationMode!}
            isAddObservationMode={isAddObservationMode}
            setVisible={setVisible!}
            observersData={observersData}
            setAbaObserversListObj={setAbaObserversListObj}
          />
        ) : (
          <AddAbaObserversView
            event={event}
            abaObserversList={abaObserversList}
            setAbaObserversList={setAbaObserversList}
            lastAbaObserver={lastAbaObserver}
            setLastAbaObserver={setLastAbaObserver}
            observerId={observerId}
            setObserverId={setObserverId}
            providersMap={providersMap!}
            apptTypes={apptTypes!}
            setPaginationArray={setPaginationArray!}
            paginationArray={paginationArray!}
            setIsAddObservationMode={setIsAddObservationMode!}
            isAddObservationMode={isAddObservationMode}
            setVisible={setVisible!}
            observersData={observersData}
            setAbaObserversListObj={setAbaObserversListObj}
          />
        )
      ) : (
        <Grid columns={2} style={{ width: '100%' }}>
          <AttendeesSection
            client={client}
            provider={provider}
            observations={observations}
            providersMap={providersMap!}
            event={event}
            setIsAddObservationMode={setIsAddObservationMode!}
            isLoading={isLoading}
          />
        </Grid>
      )}
    </AttendeesViewWrapper>
  );
};
export default React.memo(AttendeesView);
