import * as React from 'react';
import { styles } from './style';

/**
 * @interface IUnderLineTileProps To render any column with underline and has click action
 * @param title the title shown with column
 * @param key the key to be used with click action
 * @param onClick the action done
 */

interface IUnderLineTileProps {
  title: string;
  onClick?: () => void;
}
const UnderlineTitle: React.FC<IUnderLineTileProps> = (
  props: IUnderLineTileProps
) => {
  const { title, onClick } = props;
  return (
    <p style={styles.underLineTitle} onClick={onClick}>
      {title}
    </p>
  );
};

export default React.memo(UnderlineTitle);
