import React from 'react';
import { LoaderWrap } from './loader.style';

const Loader = () => {
  return (
    <LoaderWrap className="loader-wrap">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <p>Searching for openings…</p>
    </LoaderWrap>
  );
};

export default Loader;
export { Loader };
