import React, { createContext, memo } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import 'antd/dist/antd.css';
import 'assets/sass/main.scss';
import UserNavigation from 'components/UserNavigation';
import AppointmentForm from 'views/components/forms/appointment';
import Clients from 'views/components/clients';
import ClientProfile from 'views/components/clients/profile';
import { ClientFormInit } from 'views/components/clients/forms/ClientFormInit';
import EditAvailability from 'views/components/clients/profile/availability/EditAvailability';
import EditClientAvailability from 'views/components/clients/profile/availability/EditAvailabilityV2';
import Staff from 'pages/provider/list';
import ProviderProfile from 'views/components/staff/profile';
import ClinicProfile from 'pages/clinics/profile';
import ErrorBoundary from 'utils/error/ErrorBoundary';
import EditCarePlan from 'views/components/forms/editCarePlan/EditCarePlan';
import AuthGroupForm from 'views/components/forms/auths/AuthGroupForm';
import AuthsTherapiesPage from 'views/components/authsTherapiesPage/AuthsTherapiesPage';
import WaitlistPage from 'views/components/waitlistPage/WaitlistPage';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import AppointmentTypeConfig from 'pages/ConfigurationsPage/appointmentTypeConfig/index';
import ConfigurationsPage from 'pages/ConfigurationsPage';
import AvailabilityTemplate from 'pages/ConfigurationsPage/availabilityTemplate/index';
import ClinicsPage from 'pages/clinics';
import MainCalendarPage from 'pages/MainCalendarPage';
import 'semantic-ui-css/semantic.min.css';
import {
  ABA_APPT_TYPE_TITLE,
  DT_APPT_TYPE_TITLE,
  MED_APPT_TYPE_TITLE
} from 'utils/constants/configurations';
import ABAAppointmentSubTypes from 'pages/ConfigurationsPage/abaAppointmentSubType';
import DTAppointmentSubTypeConfig from 'pages/ConfigurationsPage/DTAppointmentSubType/index';
import MEDAppointmentSubTypeConfig from 'pages/ConfigurationsPage/MEDAppointmentSubType/index';
import SpecialitiesConfig from 'pages/ConfigurationsPage/specialitiesConfig';
import AddClinic from 'pages/clinics/form/index';
import AddProvider from 'pages/provider/form';
import AddAvailabilityTemplate from 'pages/ConfigurationsPage/addAvailabilityTemplate';
import { EventTypes } from 'model/eventType';
import EditWorkingHoursV2 from 'views/components/staff/profile/availabilityTemplate';
import ViewTemplateModal from 'pages/ConfigurationsPage/availabilityTemplate/ViewTemplateCalendar';
import { SMART_SCHEDULE_URL } from 'utils/constants/routes';
import { ToastContainer } from 'react-toastify';
import SyncSuccessToast from 'components/toasts/SyncSuccessToast';
import SyncFailureToast from 'components/toasts/SyncFailureToast';

import { AwsRum } from 'aws-rum-web';

try {
  const config = {
    sessionSampleRate: 1,
    identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
    endpoint: process.env.REACT_APP_RUM_ENDPOINT,
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: true
  };

  const APPLICATION_ID = process.env.REACT_APP_RUM_APPLICATION_ID;
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'us-east-1';

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

export const accountContext = createContext();
function App({ account, logout }) {
  const [inIframe, setInIframe] = React.useState(false);
  React.useEffect(() => {
    // Check if window is not the top window
    if (window.self !== window.top) {
      setInIframe(true);
    }
  }, []);

  return (
    <accountContext.Provider value={account}>
      <div className="App">
        <Router>
          {!inIframe ? (
            <UserNavigation account={account} logout={logout} />
          ) : (
            <></>
          )}
          <div className="content">
            <ToastContainer closeButton={false} />
            <ErrorBoundary>
              <SyncFailureToast account={account} />
              <SyncSuccessToast account={account} />
              <Switch>
                {/* Calendar */}
                <Route exact path="/appointments/new">
                  <MainCalendarPage toOpenNewAppointment={true} />
                </Route>
                <Route exact path="/admin-appointments/new">
                  <MainCalendarPage
                    toOpenNewAppointment={true}
                    isNewAdminAppointment={true}
                  />
                </Route>
                <Route exact path="/appointments/:id/:operation">
                  <AppointmentForm
                    reschedule
                    accountID={account?.accountIdentifier}
                  />
                </Route>
                {/* Clients */}
                <Route
                  exact
                  path="/client/:operation"
                  render={props => <ClientFormInit {...props} />}
                ></Route>
                <Route
                  path="/client/:operation/:id"
                  render={props => <ClientFormInit {...props} />}
                ></Route>

                <Route
                  path="/clients/:id/oldavailability"
                  render={props => <EditAvailability {...props} />}
                ></Route>
                <Route
                  path="/clients/:id/availability"
                  render={props => <EditClientAvailability {...props} />}
                ></Route>
                {getFeatureAvailability(FEATURES.CARE_PLAN) && (
                  <Route
                    path="/clients/:id/edit-care-plan"
                    render={props => <EditCarePlan {...props} />}
                  ></Route>
                )}
                {getFeatureAvailability(FEATURES.AUTHORIZATION) && (
                  <Route
                    path="/clients/:clientID/auth-groups/new"
                    render={props => <AuthGroupForm {...props} />}
                  ></Route>
                )}
                {getFeatureAvailability(FEATURES.AUTHORIZATION) && (
                  <Route
                    path="/clients/:clientID/auth-groups/:authGroupID/edit"
                    render={props => <AuthGroupForm {...props} />}
                  ></Route>
                )}
                <Route
                  path="/clients/:id"
                  render={props => (
                    <ClientProfile
                      {...props}
                      accountID={account?.accountIdentifier}
                    />
                  )}
                />
                <Route path="/clients">
                  <Clients />
                </Route>
                {/* Staff / Providers */}
                {getFeatureAvailability(FEATURES.AVAILABILITY_TEMPLATE) && (
                  <Route
                    path="/providers/:id/availability"
                    render={props => <EditWorkingHoursV2 {...props} />}
                  ></Route>
                )}
                <Route
                  exact
                  path="/providers/new"
                  FeatureFlag
                  render={props => <AddProvider {...props} />}
                ></Route>
                <Route
                  exact
                  path="/providers/:id/edit"
                  render={props => <AddProvider {...props} />}
                ></Route>
                <Route
                  path="/providers/:id"
                  render={props => (
                    <ProviderProfile
                      {...props}
                      accountID={account?.accountIdentifier}
                    />
                  )}
                ></Route>
                <Route path="/providers">
                  <Staff />
                </Route>
                {/* Authorized Therapies */}
                {getFeatureAvailability(FEATURES.AUTHORIZATION) && (
                  <Route path="/auth-therapies">
                    <AuthsTherapiesPage />
                  </Route>
                )}
                {/* Clinics */}
                <Route exact path="/clinics">
                  <ClinicsPage />
                </Route>
                <Route
                  exact
                  path="/clinics/add"
                  render={props => <AddClinic {...props} />}
                ></Route>
                <Route
                  exact
                  path="/clinics/:id/edit"
                  render={props => <AddClinic {...props} />}
                ></Route>
                <Route
                  path="/clinics/:id"
                  render={props => <ClinicProfile {...props} />}
                ></Route>
                {getFeatureAvailability(FEATURES.WAITLIST) && (
                  /* Waitlist */
                  <Route exact path="/waitlist">
                    <WaitlistPage />
                  </Route>
                )}
                {/* Default */}
                <Route exact path="/">
                  <Redirect to="/planner" />
                </Route>
                <Route exact path="/config">
                  <ConfigurationsPage></ConfigurationsPage>
                </Route>
                <Route path="/config/ABAAppointmentType">
                  <AppointmentTypeConfig
                    eventType={EventTypes.ABA}
                    title={ABA_APPT_TYPE_TITLE}
                  ></AppointmentTypeConfig>
                </Route>
                <Route path="/config/DTAppointmentType">
                  <AppointmentTypeConfig
                    eventType={EventTypes.DT}
                    title={DT_APPT_TYPE_TITLE}
                  ></AppointmentTypeConfig>
                </Route>
                <Route path="/config/MEDAppointmentType">
                  <AppointmentTypeConfig
                    eventType={EventTypes.MED}
                    title={MED_APPT_TYPE_TITLE}
                  ></AppointmentTypeConfig>
                </Route>
                <Route path="/config/ABAAppointmentSubType">
                  <ABAAppointmentSubTypes />
                </Route>
                <Route path="/config/DTAppointmentSubType">
                  <DTAppointmentSubTypeConfig />
                </Route>
                <Route path="/config/MEDAppointmentSubType">
                  <MEDAppointmentSubTypeConfig />
                </Route>
                <Route path="/planner">
                  <MainCalendarPage />
                </Route>
                <Route path={SMART_SCHEDULE_URL}>
                  <Redirect to="/planner" />
                </Route>
                <Route path="/config/Specialities">
                  <SpecialitiesConfig />
                </Route>
                {getFeatureAvailability(FEATURES.AVAILABILITY_TEMPLATE) && (
                  <Route
                    path="/config/availabilityTemplates/:id"
                    render={props => <ViewTemplateModal {...props} />}
                  ></Route>
                )}
                {getFeatureAvailability(FEATURES.AVAILABILITY_TEMPLATE) && (
                  <Route path="/config/availabilityTemplates">
                    <AvailabilityTemplate />
                  </Route>
                )}
                {getFeatureAvailability(FEATURES.AVAILABILITY_TEMPLATE) && (
                  <Route path="/config/addTemplate">
                    <AddAvailabilityTemplate />
                  </Route>
                )}
              </Switch>
            </ErrorBoundary>
          </div>
        </Router>
      </div>
    </accountContext.Provider>
  );
}

export default memo(App);
// export const  accountContext;
