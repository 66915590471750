import React from 'react';
import { TimeViewWrapper } from './style';
import { IEvent } from 'model/v2';

interface Props {
  event: IEvent;
}
const DriveTimeMileageView: React.FC<Props> = ({ event }: Props) => {
  return (
    <TimeViewWrapper>
      <p className="appointment-type">Starting Address:</p>
      <p className="event-time">
        {event?.driveTimeSourceAddr?.freeformAddress
          ? event?.driveTimeSourceAddr?.freeformAddress
          : 'N/A'}
      </p>
      <p></p>
      <p className="appointment-type">Destination Address:</p>
      <p className="event-time">
        {event?.driveTimeDesteAddr?.freeformAddress
          ? event?.driveTimeDesteAddr?.freeformAddress
          : 'N/A'}
      </p>
      <p></p>
      <p className="appointment-type">
        Mileage:{' '}
        {event?.driveTimeMileage ? `${event?.driveTimeMileage} Miles` : 'N/A'}
      </p>
      <p className="appointment-type">
        Reimbursable Mileage:{' '}
        {event?.reimbursableMileage !== undefined &&
        event?.reimbursableMileage !== null
          ? `${event?.reimbursableMileage} Miles`
          : 'N/A'}
      </p>
    </TimeViewWrapper>
  );
};
export default React.memo(DriveTimeMileageView);
