import React, { useState } from 'react';
import { ColorResult, RGBColor, SketchPicker } from 'react-color';
import { Control } from 'react-hook-form';
import { styles } from './ColorPicker.style';
import { useColorHook } from './ColorPickerHooks';
import { TableType } from 'model/tableType';

interface Props {
  handleColorChange: (color: ColorResult) => void;
  name: string;
  control: Control<TableType>;
}
const ColorPicker: React.FC<Props> = ({
  handleColorChange,
  name,
  control
}: Props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleClick = () => {
    setDisplayColorPicker(
      (prevDisplayColorPicker: boolean) => !prevDisplayColorPicker
    );
  };

  const color: RGBColor = useColorHook(name, control);

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div
          style={{
            ...styles.color,
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
          }}
        />
      </div>
      {displayColorPicker && (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={color}
            onChangeComplete={(color: ColorResult) => {
              handleColorChange(color);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(ColorPicker);
