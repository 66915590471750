import styled from 'styled-components';

export const ProfileProfileHeaderWrapper = styled.div`
  margin-bottom: 6px;
  margin-left: 10px;
  margin-right: 20px;
  .personal-information {
    width: 23% !important;
    .url {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.275px;
      color: #3e5766;
    }
    .name {
      font-weight: bold;
      font-size: 24px;
      line-height: 41px;
      letter-spacing: 0.275px;
      color: #3e5766;
    }
    .phone {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.275px;
      color: #3e5766;
    }
    .prov-speciality {
      background: rgba(111, 66, 245, 0.2);
      color: #6f42f5;
      border: none !important;
    }
    .prov-employment {
      background: rgba(135, 196, 64, 0.2);
      color: #87c440;
      border: none !important;
    }
    .prov-status {
      background: rgba(232, 36, 36, 0.2);
      color: #e82424;
      border: none !important;
    }
    .prov-observable {
      background: rgb(160, 174, 255, 0.2);
      color: #a0aeff;
      border: none !important;
    }
    .row {
      margin-bottom: 8px;
    }
  }
  .provider-information {
    width: 29% !important;
    .body {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      .row {
        padding: 13px 0px 0 0px;
      }
      span {
        line-height: 16px;
        font-weight: 400;
        display: inline-block;
        margin-left: 4px;
      }
    }
  }
  .appointment-types {
    .appt-types {
      display: inline-block;
      border-radius: 24px;
      border: 1px solid;
      padding: 4px 8px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      line-height: 16px;
      margin-right: 4px;
      margin-bottom: 6px;
      letter-spacing: 0.275px;
    }
  }
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.275px;
    color: #3e5766;
    margin-bottom: 10px;
  }
  .new-appt-button {
    display: inline-block;
    width: 148px;
    padding: 10px 17px;
    margin-right: 30px;
    border-radius: 5px;
    background: #6f4bf1;
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
  }
  .edit-availability-button {
    position: absolute;
    top: 45px;
    right: 32px;
    width: 145px;
    color: #6f42f5;
    border: 1px solid #6f42f5;
    border-radius: 3px;
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;
  }
  .tele-link-icon {
    width: 18px !important;
    height: auto;
    padding-bottom: 3px !important;
    color: #1890ff;
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(215deg)
      brightness(75%) contrast(80%);
  }
`;
