import styled from 'styled-components';
import { Tag } from 'antd';
import { RED, GREEN, AVATAR_INITIAL } from 'utils/constants/theme';

export const LibTag = styled(Tag)`
  width: 75px;
  height: 23px;
  border-radius: 12px;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${AVATAR_INITIAL};
  display: flex;
  justify-content: center;
  align-items: center;
  &.green {
    border-color: ${GREEN};
  }
  &.red {
    border-color: ${RED};
  }
`;
