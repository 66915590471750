import { IBreadcrumbSection } from 'model/ui/breadcrumb';

export const USER_TAB = {
  client: 'Clients',
  staff: 'Staff',
  clinics: 'Clinics',
  providers: 'Providers'
};

export const modalForList = {
  mainCalendar: 'mainCalendar',
  clientCalendar: 'clientCalendar',
  providerCalendar: 'providerCalendar'
};

export const BREADCRUMB_SECTION_SIZES: Record<
  string,
  IBreadcrumbSection['size']
> = {
  big: 'big',
  small: 'small',
  mini: 'mini',
  tiny: 'tiny',
  large: 'large',
  huge: 'huge',
  massive: 'massive'
};
