import * as Yup from 'yup';
export const uniqueNameValidator = clientAddresses => {
  return Yup.object().shape({
    name: Yup.string().test(
      'unique address name',
      'Error, This name already exists',
      function(value) {
        const duplicateAddress = clientAddresses.filter(
          address => address.name?.toLowerCase() === value?.trim().toLowerCase()
        );
        return duplicateAddress?.length > 0 ? false : true;
      }
    )
  });
};
