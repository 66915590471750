import React, { useState, useCallback } from 'react';
import SearchForm from 'views/components/ui/form/search';
import Content from 'views/components/ui/content';
import ClientListItem from './ClientListItem';
import { NewAppointmentWrap } from '../../NewAppointment.style';
import { useClientSearchList } from '../../newAppointmentHooks';

const SearchClients = ({ onSelectClient }) => {
  const [searchName, setSearchName] = useState();

  const { clients, loadingClients, errorClients } = useClientSearchList(
    searchName
  );

  const onSearchChange = useCallback(event => {
    setSearchName(event.target.value);
  }, []);

  return (
    <NewAppointmentWrap>
      <div className="inner-wrap">
        <div className="appointment-sidebar" style={{ marginTop: '60px' }}>
          <SearchForm
            className="appointment-sidebar__search"
            search={searchName}
            onChange={onSearchChange}
            placeholder="Search Client"
          />
          {searchName === '' ? (
            <p className="empty-text">Use the search to add a client</p>
          ) : (
            <Content
              loading={loadingClients}
              data={clients}
              error={errorClients}
            >
              {() => (
                <div className="appointment-sidebar__list">
                  {clients.map(client => (
                    <ClientListItem
                      client={client}
                      getClient={onSelectClient}
                      key={client.id}
                    />
                  ))}
                </div>
              )}
            </Content>
          )}
        </div>
      </div>
    </NewAppointmentWrap>
  );
};

export default SearchClients;
