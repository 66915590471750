import * as yup from 'yup';
import { TAG_FILTER } from 'utils/constants/calendarFilters';
import { ISavedFilterOptions } from 'model/calendar/filters';

export const TAG_FILTER_SCHEMA = (
  allFilters: ISavedFilterOptions[],
  initialTitle?: string
) =>
  yup.object().shape({
    [TAG_FILTER.name]: yup
      .string()
      .required('Title is required')
      .test('unique title', 'This title already exists', function(
        value?: string | null
      ) {
        return (
          !allFilters.some(
            v => v.name?.toLowerCase().trim() === value?.toLowerCase().trim()
          ) ||
          value?.toLowerCase().trim() === initialTitle?.toLowerCase().trim()
        );
      })
  });
