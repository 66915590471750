import styled from 'styled-components';

export const CollapsibleWrapper = styled.div`
  .collapsible {
    text-overflow: ellipsis;

    &.hidden {
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 2px;
    }
    &.show {
      white-space: wrap;
      overflow: show;
    }
  }
  .cta {
    cursor: pointer;
    color: #45bfff;
    text-decoration: underline;
    :hover,
    :active,
    :focus {
      color: grey;
    }
  }
`;
