import { SYNC_FAILURE_SUBSCRIPTION } from 'api/graphql/v2/subscriptions/Events';
import React from 'react';
import { useSubscription } from 'react-apollo';
import { toast } from 'react-toastify';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { limitProviderName } from 'utils/constants/toastMessage';
import {
  ROUTE_PARAMS,
  ROUTE_PATHS,
  TARGET_BLANK
} from 'utils/constants/routes';
import { SyncNotice } from './ToastUtility';
import ToastMessage from './ToastMessage';

const FAILURE_STATUS_MAPPINGS: Record<string, string> = {
  CREATE_FAILURE: 'Creation',
  UPDATE_FAILURE: 'Update',
  CANCEL_FAILURE: 'Cancellation'
};

const notify = (result: any) => {
  const failureNotice: SyncNotice =
    result.subscriptionData?.data?.eventSyncFailureNotice;
  if (!failureNotice) return;

  const { eventId, downStream, isSupressLink } = failureNotice;
  const noticeId = `${eventId}-${downStream}`;
  const link = ROUTE_PATHS.VIEW_APPOINTMENT + ROUTE_PARAMS.EVENT + eventId;

  const onToastClick = () => {
    if (!isSupressLink) window.open(link, TARGET_BLANK);
  };

  toast.error(syncNoticeContent(failureNotice), {
    toastId: noticeId,
    containerId: noticeId,
    autoClose: false,
    closeOnClick: !isSupressLink,
    onClick: onToastClick
  });
};

const syncNoticeContent = (failureNotice: SyncNotice) => {
  const { providerName, downStream, eventId } = failureNotice;
  const time = new Date(failureNotice.startDate!).toLocaleTimeString(
    navigator.language,
    {
      hour: '2-digit',
      minute: '2-digit'
    }
  );
  const action = FAILURE_STATUS_MAPPINGS[failureNotice.status!];
  const message = `${limitProviderName(
    providerName!
  )}'s ${time} ${action} failed to sync to ${downStream}`;

  const onClose = (e: any) => {
    const toastId = `${eventId}-${downStream}`;
    e.stopPropagation();
    toast.dismiss(toastId);
  };

  return <ToastMessage message={message} onClose={onClose} />;
};

interface Props {
  account: any;
}

const SyncFailureToast: React.FC<Props> = ({ account }: Props) => {
  useSubscription(SYNC_FAILURE_SUBSCRIPTION, {
    variables: { CreatedBy: account.name },
    skip: !getFeatureAvailability(FEATURES.FAILURE_NOTICE_SUBSCRIPTIONS),
    onSubscriptionData: notify
  });
  return <></>;
};

export default SyncFailureToast;
