import { Input } from 'antd';
import React, { useState } from 'react';
import { Range } from 'react-input-range';
import { calculateRange, Duration } from './ABASessionDuration';
import { RangeTimePickerWrapper } from './rangetimepicker.style';

const HOUR_REGEX = new RegExp('(^[2-5]$|^$)');
const MINUTE_REGEX = new RegExp('(^[0-5][0-9]$|^[0-9]$|^$)');
const EMPTY_STRING = '';

const round = (duration: Duration) => {
  let { hour, minute } = duration;
  if (minute <= 7) {
    minute = 0;
  } else if (minute >= 8 && minute <= 22) {
    minute = 15;
  } else if (minute >= 23 && minute <= 38) {
    minute = 30;
  } else if (minute >= 39 && minute <= 53) {
    minute = 45;
  } else {
    hour++;
    minute = 0;
  }
  return { hour, minute };
};

const compareDurations = (min: Duration, max: Duration) => {
  return !(min.hour * 60 + min.minute >= max.hour * 60 + max.minute);
};

interface Props {
  min: Duration;
  max: Duration;
  setMin: (min: React.SetStateAction<Duration>) => void;
  setMax: (max: React.SetStateAction<Duration>) => void;
  setRange: (min: React.SetStateAction<Range>) => void;
}

const RangeTimePicker: React.FC<Props> = ({
  min,
  max,
  setMin,
  setMax,
  setRange
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const onMinHoursChange = (event: any) => {
    setIsSelected(true);
    const value = event.target.value;
    if (!HOUR_REGEX.test(value)) return;
    const newMin = { minute: value == 5 ? 2 : min.minute, hour: value };
    let newMax = max;
    if (!compareDurations(newMin, max)) newMax = newMin;
    setMin(newMin);
    setMax(newMax);
    setRange(calculateRange(newMin, newMax));
  };

  const onMinMinutesChange = (event: any) => {
    setIsSelected(true);
    const value = event.target.value;
    if (!MINUTE_REGEX.test(value)) return;
    setMin({ ...min, minute: value });
  };

  const onMaxHoursChange = (event: any) => {
    setIsSelected(true);
    const value = event.target.value;
    if (!HOUR_REGEX.test(value)) return;
    const newMax = { minute: value == 5 ? 2 : max.minute, hour: value };
    let newMin = min;
    if (!compareDurations(min, newMax)) newMin = newMax;
    setMin(newMin);
    setMax(newMax);
    setRange(calculateRange(newMin, newMax));
  };

  const onMaxMinutesChange = (event: any) => {
    setIsSelected(true);
    const value = event.target.value;
    if (!MINUTE_REGEX.test(value)) return;
    setMax({ ...max, minute: value });
  };

  const onMinMinutesBlur = () => {
    setIsSelected(true);
    const roundedMin = min.hour == 5 ? { hour: 5, minute: 0 } : round(min);
    let roundedMax;
    if (!compareDurations(roundedMin, max)) roundedMax = roundedMin;
    else roundedMax = round(max);
    setMin(roundedMin);
    setMax(roundedMax);
    setRange(calculateRange(roundedMin, roundedMax));
  };

  const onMaxMinutesBlur = () => {
    setIsSelected(true);
    const roundedMax = max.hour == 5 ? { hour: 5, minute: 0 } : round(max);
    let roundedMin;
    if (!compareDurations(min, roundedMax)) roundedMin = roundedMax;
    else roundedMin = round(min);
    setMin(roundedMin);
    setMax(roundedMax);
    setRange(calculateRange(roundedMin, roundedMax));
  };

  const onMinHoursBlur = (event: any) => {
    setIsSelected(true);
    if (event.target.value == EMPTY_STRING) event.target.value = 2;
    onMinHoursChange(event);
  };

  const onMaxHoursBlur = (event: any) => {
    setIsSelected(true);
    if (event.target.value == EMPTY_STRING) event.target.value = 5;
    onMaxHoursChange(event);
  };

  const formatMinutes = (minutes: number) => {
    if (minutes === 0) return '00';
    return minutes;
  };

  return (
    <RangeTimePickerWrapper>
      <div className={isSelected ? 'range-time-selected' : 'range-time'}>
        <div>
          <Input
            placeholder="H"
            bordered={false}
            onChange={onMinHoursChange}
            onBlur={onMinHoursBlur}
            value={min.hour}
          />
          :
          <Input
            placeholder="mm"
            bordered={false}
            value={formatMinutes(min.minute)}
            onBlur={onMinMinutesBlur}
            onChange={onMinMinutesChange}
          />
        </div>
        <div className="dash">-</div>
        <div>
          <Input
            placeholder="H"
            bordered={false}
            value={max.hour}
            onBlur={onMaxHoursBlur}
            onChange={onMaxHoursChange}
          />
          :
          <Input
            placeholder="mm"
            bordered={false}
            value={formatMinutes(max.minute)}
            onBlur={onMaxMinutesBlur}
            onChange={onMaxMinutesChange}
          />
        </div>
      </div>
    </RangeTimePickerWrapper>
  );
};

export default React.memo(RangeTimePicker);
