import { ContentWrapper } from 'views/components/ui/card/card.style';
import { DayValue } from 'utils/constants/availabilityTemplate';
import moment from 'moment';
import { useMemo } from 'react';
import ModalBottomWithDelete from 'components/AvailabilityTemplateModalForm/ReusableComponents/ModalBottomWithDelete';
export const AvailabilityModalWarning = ({
  data,
  onDelete,
  onSubmit,
  onClose
}) => {
  return (
    <>
      <ContentWrapper>
        <div className="Modal-Content-Wrapper">
          <span className="Modal-Warning-Description">
            Exiting now will not save the changes you are editing. Make sure to
            save any changes before leaving to avoid losing any changes. Are you
            sure you want to exit without saving?
          </span>
        </div>
        <div>
          <ModalBottomWithDelete
            submitForm={onDelete}
            onClose={onClose}
            selectedEvent={data}
            deleteAvail={true}
          />
        </div>
      </ContentWrapper>
    </>
  );
};
