import React from 'react';
import { Grid, Segment, Sidebar, Container } from 'semantic-ui-react';
import { SideBarWrapper } from './sidebar.style';
interface Props {
  content?: JSX.Element;
  pageComponent: JSX.Element;
  visible: boolean;
  //setVisible: Dispatch<SetStateAction<boolean>>;
  sideBarWidth?: string;
}

const PushableSideBar: React.FC<Props> = ({
  content,
  pageComponent,
  visible,
  sideBarWidth
}: Props) => (
  <SideBarWrapper sideBarWidth={sideBarWidth}>
    <Grid columns={1}>
      <Grid.Column>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            className="side-bar"
            as={Container}
            animation="push"
            icon="labeled"
            inverted="true"
            vertical="true"
            visible={visible}
            width="wide"
          >
            {content}
          </Sidebar>
          <Sidebar.Pusher>
            <Segment basic className="push-content_wrapper">
              <div
                className={visible ? 'isVisible-pusher' : 'isInvisible-pusher'}
              >
                {pageComponent}
              </div>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Grid.Column>
    </Grid>
  </SideBarWrapper>
);

export default React.memo(PushableSideBar);
