import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import moment from 'moment';

import {
  mapClinicsHookToOptions,
  mapCorticaProgramOptionsSavedFilters,
  mapToCheckListOptions,
  mapToFilterOptions,
  mapToSpecialityOptions
} from 'utils/mappers/form';
import { SAVED_FILTER } from 'utils/constants/calendarFilters';
import {
  ICalendarFilterOption,
  ISavedFilter,
  ISavedFilterOptions,
  ISchedularFilterData,
  ISchedularFilter
} from 'model/calendar/filters';
import { accountContext } from 'App';
import { GET_SCHEDULER_FILTERS } from 'api/graphql/v2/queries/Filters';
import { checkIfFilterSelected } from 'utils/mappers/carePlans';
import {
  FilterPageNameEnum,
  savedFilterNameEnum
} from 'components/calendar/calendarToolbar/FilterTagList/form/utils';
import { ReactiveVar, WatchQueryFetchPolicy } from '@apollo/client';

enum FilterSelectionTypeMapper {
  locations = 'locations',
  departments = 'departments',
  internals = 'internal',
  specialities = 'specialities',
  clinics = 'clinics',
  programs = 'programs',
  waitListReason = 'waitListReason',
  therapyType = 'therapyType',
  serviceType = 'serviceType',
  clientAvailability = 'clientAvailability',
  endTime = 'endTime',
  startTime = 'startTime',
  days = 'days',
  status = 'status',
  smartSchedulingStatus = 'smartSchedulingStatus',
  language = 'language'
}

export const useCalendarFilterFetcherHook = (
  planner: string,
  fetchPolicy?: WatchQueryFetchPolicy | undefined
) => {
  const account = useContext(accountContext);
  const [defaultFilter, setDefaultFilter] = useState<
    ISavedFilterOptions | undefined
  >(undefined);
  const [savedFilters, setSavedFilters] = useState<ICalendarFilterOption[]>([]);
  let allFilters: ISavedFilter[] = [];

  const {
    data: userFilters,
    loading: isSavedFilterLoading,
    refetch
  } = useQuery<ISchedularFilterData>(GET_SCHEDULER_FILTERS, {
    variables: {
      id: account?.username,
      pageName: planner
    },
    fetchPolicy: fetchPolicy || 'cache-first'
  });
  const useFilterRemovalHook = (
    setCheckedSavedFilter: React.Dispatch<React.SetStateAction<string>>,
    FILTER_ENUM: Record<string, string>,
    States: Record<
      string,
      React.Dispatch<React.SetStateAction<ICalendarFilterOption[]>>
    >
  ) => {
    let {
      setCheckedClinics,
      setWaitListReasons,
      setTherapyType,
      setServiceType,
      setClientAvailability,
      setCheckedStatus,
      setSmartSchedulingStatus,
      setCheckedPrograms,
      setProviderAvailability,
      setCheckedSpecialities,
      setCheckedDepartments,
      setCheckedLanguage
    } = States;
    return useCallback((type, value) => {
      if (setCheckedSavedFilter) setCheckedSavedFilter('');
      switch (type) {
        case FILTER_ENUM.clinics:
          setCheckedClinics((current: ICalendarFilterOption[]) =>
            current.filter(clinic => clinic.value !== value)
          );
          break;
        case FILTER_ENUM.waitListReason:
          setWaitListReasons((current: ICalendarFilterOption[]) =>
            current.filter(waitListReason => waitListReason.value !== value)
          );
          break;
        case FILTER_ENUM.programs:
          setCheckedPrograms((current: ICalendarFilterOption[]) =>
            current.filter(program => program.value !== value)
          );
          break;
        case 'providerAvailability':
          setProviderAvailability((current: ICalendarFilterOption[]) =>
            current.filter(avail => avail.value !== value)
          );
          break;
        case FILTER_ENUM.specialities:
          setCheckedSpecialities((current: ICalendarFilterOption[]) =>
            current.filter(special => special.value !== value)
          );
          break;

        case FILTER_ENUM.therapyType:
          setTherapyType((current: ICalendarFilterOption[]) =>
            current.filter(therapy => therapy.value !== value)
          );
          break;
        case FILTER_ENUM.serviceType:
          setServiceType((current: ICalendarFilterOption[]) =>
            current.filter(service => service.value !== value)
          );
          break;
        case FILTER_ENUM.clientAvailability:
          setClientAvailability((current: ICalendarFilterOption[]) =>
            current.filter(avail => avail.value !== value)
          );
          break;
        case FILTER_ENUM.status:
          setCheckedStatus((current: ICalendarFilterOption[]) =>
            current.filter(status => status.value !== value)
          );
          break;
        case FILTER_ENUM.smartSchedulingStatus:
          setSmartSchedulingStatus((current: ICalendarFilterOption[]) =>
            current.filter(status => status.value !== value)
          );
          break;
        case FILTER_ENUM.departments:
          setCheckedDepartments((current: ICalendarFilterOption[]) =>
            current?.filter(status => status.value !== value)
          );
          break;
        case FILTER_ENUM.language:
          setCheckedLanguage((current: ICalendarFilterOption[]) =>
            current?.filter(status => status.value !== value)
          );
          break;
        default:
          console.warn(`Unhandled filter type: ${type}`);
      }
    }, []);
  };

  const SavedFilter = (
    filterPageName: string,
    States: Record<
      string,
      React.Dispatch<React.SetStateAction<ICalendarFilterOption[]>>
    >,
    setAdditionalFilter: React.Dispatch<
      React.SetStateAction<Record<string, any>>
    >,
    setCheckedSavedFilter: React.Dispatch<React.SetStateAction<string>>,
    filterType: string,
    filterResult: any,
    name: string | boolean,
    defaultFilterPrefVar?: ReactiveVar<Boolean>
  ) => {
    let {
      setCheckedStatus,
      setWaitListReasons,
      setCheckedClinics,
      setTherapyType,
      setServiceType,
      setClientAvailability,
      setSmartSchedulingStatus,
      setCheckedPrograms,
      setCheckedSpecialities,
      setProviderAvailability,
      setCheckedDepartments,
      setCheckedLanguage
    } = States;
    if (filterPageName === FilterPageNameEnum.waitList) {
      setCheckedClinics((prevState: ICalendarFilterOption[]) => {
        if (filterType === savedFilterNameEnum.saved) {
          const savedFilterStatusString = JSON.stringify(filterResult.clinics);
          const prevStateString = JSON.stringify(prevState);
          return prevStateString === savedFilterStatusString
            ? prevState
            : filterResult.clinics;
        } else {
          return filterResult.clinics;
        }
      });

      setCheckedStatus((prevState: ICalendarFilterOption[]) => {
        if (filterType === savedFilterNameEnum.saved) {
          const savedFilterStatusString = JSON.stringify(filterResult.status);
          const prevStateString = JSON.stringify(prevState);
          return prevStateString === savedFilterStatusString
            ? prevState
            : filterResult.status;
        } else {
          return filterResult.status;
        }
      });
      setWaitListReasons((prevState: ICalendarFilterOption[]) => {
        if (filterType === savedFilterNameEnum.saved) {
          const savedFilterStatusString = JSON.stringify(
            filterResult.waitListReason
          );
          const prevStateString = JSON.stringify(prevState);
          return prevStateString === savedFilterStatusString
            ? prevState
            : filterResult.waitListReason;
        } else {
          return filterResult?.waitListReason;
        }
      });

      setTherapyType((prevState: ICalendarFilterOption[]) => {
        if (filterType === savedFilterNameEnum.saved) {
          const savedFilterStatusString = JSON.stringify(
            filterResult.therapyType
          );
          const prevStateString = JSON.stringify(prevState);
          return prevStateString === savedFilterStatusString
            ? prevState
            : filterResult.therapyType;
        } else {
          return filterResult.therapyType;
        }
      });

      setServiceType((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(
          filterResult.serviceType
        );
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.serviceType;
      });

      setClientAvailability((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(
          filterResult.clientAvailability
        );
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.clientAvailability;
      });

      setSmartSchedulingStatus((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(
          filterResult.smartSchedulingStatus
        );
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.smartSchedulingStatus;
      });
    } else if (filterPageName === FilterPageNameEnum.provider) {
      setCheckedStatus((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(filterResult.status);
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.status;
      });
      setCheckedPrograms((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(filterResult.programs);
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.programs;
      });
      setCheckedClinics((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(filterResult.clinics);
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.clinics;
      });
      setCheckedSpecialities((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(
          filterResult.specialities
        );
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.specialities;
      });
      setProviderAvailability((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(
          filterResult.clientAvailability
        );
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.clientAvailability;
      });
      setCheckedDepartments((prevState: ICalendarFilterOption[]) => {
        const savedFilterStatusString = JSON.stringify(
          filterResult.departments
        );
        const prevStateString = JSON.stringify(prevState);
        return prevStateString === savedFilterStatusString
          ? prevState
          : filterResult.departments;
      });
      setCheckedSavedFilter(filterResult?.name || '');
    } else {
      if (filterResult?.clinics?.length > 0)
        setCheckedClinics((prevState: ICalendarFilterOption[]) => {
          const savedFilterStatusString = JSON.stringify(filterResult.clinics);
          const prevStateString = JSON.stringify(prevState);
          return prevStateString === savedFilterStatusString
            ? prevState
            : filterResult.clinics;
        });
      if (filterResult?.status?.length > 0)
        setCheckedStatus((prevState: ICalendarFilterOption[]) => {
          if (filterType === savedFilterNameEnum.saved) {
            const savedFilterStatusString = JSON.stringify(filterResult.status);
            const prevStateString = JSON.stringify(prevState);
            return prevStateString === savedFilterStatusString
              ? prevState
              : filterResult.status;
          } else {
            return filterResult.status;
          }
        });
      if (filterResult?.programs?.length > 0) {
        setCheckedPrograms((prevState: ICalendarFilterOption[]) => {
          let prevPrograms = filterResult?.programs?.map(
            (data: ICalendarFilterOption) => {
              return {
                label: data.label,
                value: data.value
              };
            }
          );
          const savedFilterStatusString = JSON.stringify(prevPrograms);
          const prevStateString = JSON.stringify(prevState);
          return prevStateString === savedFilterStatusString
            ? prevState
            : filterResult.programs;
        });
      }
      if (filterResult?.clientAvailability?.length > 0)
        setClientAvailability((prevState: ICalendarFilterOption[]) => {
          const savedFilterStatusString = JSON.stringify(
            filterResult.clientAvailability
          );
          const prevStateString = JSON.stringify(prevState);
          return prevStateString === savedFilterStatusString
            ? prevState
            : filterResult.clientAvailability;
        });
      if (filterResult?.language?.length > 0)
        setCheckedLanguage((prevState: ICalendarFilterOption[]) => {
          const savedFilterLanguageString = JSON.stringify(
            filterResult.language
          );
          const prevStateString = JSON.stringify(prevState);
          return prevStateString === savedFilterLanguageString
            ? prevState
            : filterResult.language;
        });
    }

    const selectedLocation = filterResult.locations.map(
      (item: Record<string, string>) => parseInt(item.value)
    );
    const selectedDay = filterResult.days.map((item: Record<string, string>) =>
      parseInt(item.value)
    );
    const startTimeVal = filterResult.startTime
      ? filterResult.startTime
      : '00:00';
    const startTime = moment(startTimeVal, 'HH:mm');
    const endTimeVal = filterResult.endTime ? filterResult.endTime : '00:00';
    const endTime = moment(endTimeVal, 'HH:mm');
    if (
      checkIfFilterSelected(startTime, endTime, selectedDay, selectedLocation)
    ) {
      setAdditionalFilter(prevState => {
        const newState = {
          isFilterApplied: true,
          locations: selectedLocation,
          startTime: startTime,
          endTime: endTime,
          days: selectedDay
        };

        if (JSON.stringify(prevState) === JSON.stringify(newState)) {
          return prevState;
        } else {
          return newState;
        }
      });
    } else {
      setAdditionalFilter(prevState => {
        const newState = {
          isFilterApplied: false,
          locations: [],
          startTime: null,
          endTime: null,
          days: []
        };
        if (JSON.stringify(prevState) === JSON.stringify(newState)) {
          return prevState;
        } else {
          return newState;
        }
      });
    }
    setCheckedSavedFilter(
      (filterType === savedFilterNameEnum.saved ? name : filterResult.name) ||
        ''
    );
    defaultFilterPrefVar && defaultFilterPrefVar(false);
  };

  const userDefaultFilter = useMemo(() => {
    return userFilters?.getAllFiltersWithUserId.find(
      (filter: ISchedularFilter) => {
        return filter.isDefault === true;
      }
    );
  }, [userFilters]);

  const userNonDefaultFilter = useMemo(() => {
    return userFilters?.getAllFiltersWithUserId.filter(
      (filter: ISchedularFilter) => {
        return filter.isDefault === false;
      }
    );
  }, [userFilters]);

  useEffect(() => {
    const savedFilterArray = userNonDefaultFilter;
    if (userFilters?.getAllFiltersWithUserId) {
      if (userDefaultFilter) {
        const defaultFilterValues = getFilterValues(userDefaultFilter);
        setDefaultFilter({
          id: userDefaultFilter.id,
          name: userDefaultFilter.name,
          ...defaultFilterValues
        });
        savedFilterArray?.push(userDefaultFilter);
      } else {
        setDefaultFilter(undefined);
      }

      setSavedFilters(
        mapToCheckListOptions(
          savedFilterArray || [],
          SAVED_FILTER.id,
          SAVED_FILTER.value
        )
      );
    }
  }, [userNonDefaultFilter, userDefaultFilter, userFilters]);

  const savedFiltersMap = useMemo(() => {
    const filtersMap = new Map();
    userNonDefaultFilter?.forEach(filter => {
      const filterValues = getFilterValues(filter);
      allFilters.push({ ...filter, name: filter.name, ...filterValues });
      filtersMap.set(filter.name, filterValues);
    });

    if (userDefaultFilter) {
      const defaultFilterValues = getFilterValues(userDefaultFilter);
      allFilters.push({ name: userDefaultFilter.name, ...defaultFilterValues });
      filtersMap.set(userDefaultFilter.name, defaultFilterValues);
    }

    return filtersMap;
  }, [userNonDefaultFilter, allFilters, userDefaultFilter]);

  return {
    defaultFilter,
    savedFilters,
    savedFiltersMap,
    isSavedFilterLoading,
    defaultFilterValue: (defaultFilter as unknown) as ISavedFilterOptions,
    allFilters: ((allFilters || []) as unknown) as ISavedFilterOptions[],
    useFilterRemovalHook,
    SavedFilter,
    refetch
  };
};

const getFilterValues = (data: ISchedularFilter | undefined) => {
  const clinics = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.clinics
  );

  const programs = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.programs
  );

  const specialities = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.specialities
  );
  const smartSchedulingStatus = data?.filterSelections.filter(
    filter =>
      filter.type.typeName === FilterSelectionTypeMapper.smartSchedulingStatus
  );

  const departments = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.departments
  );

  const language = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.language
  );

  const locations = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.locations
  );
  const internals = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.internals
  );

  const waitListReason = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.waitListReason
  );

  const therapyType = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.therapyType
  );

  const serviceType = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.serviceType
  );

  const clientAvailability = data?.filterSelections.filter(
    filter =>
      filter.type.typeName === FilterSelectionTypeMapper.clientAvailability
  );

  const startTime = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.startTime
  );

  const endTime = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.endTime
  );

  const days = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.days
  );

  const status = data?.filterSelections.filter(
    filter => filter.type.typeName === FilterSelectionTypeMapper.status
  );

  return {
    id: data?.id,
    clinics: mapClinicsHookToOptions(clinics),
    programs: mapCorticaProgramOptionsSavedFilters(programs || []),
    specialities: mapToSpecialityOptions(specialities),
    departments: mapToFilterOptions(departments || [], false),
    locations: mapToFilterOptions(locations || [], false),
    internals: mapToFilterOptions(internals || [], true),
    status: mapToFilterOptions(status || [], false),
    waitListReason: mapToFilterOptions(waitListReason || [], false),
    therapyType: mapToFilterOptions(therapyType || [], false),
    serviceType: mapToFilterOptions(serviceType || [], false),
    clientAvailability: mapToFilterOptions(clientAvailability || [], false),
    days: mapToFilterOptions(days || [], false),
    smartSchedulingStatus: mapToFilterOptions(
      smartSchedulingStatus || [],
      false
    ),
    language: mapToFilterOptions(language || [], false),
    startTime: startTime![0]?.selectedId,
    endTime: endTime![0]?.selectedId,
    isDefault: data?.isDefault
  };
};

export default useCalendarFilterFetcherHook;
