import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const TabsWrap = styled.div`
  .profile-schedule-calendar {
    padding-top: 30px;
    max-width: 1280px;
    margin: 0 auto;
    .last-updated {
      font-size: 15px;
      color: #21303a;
      span {
        font-weight: bold;
      }
    }
  }
  .ant-tabs-tab-btn {
    margin-right: 100px;
  }
  .profile_table {
    font-family: Lato;
    font-weight: bold;
    color: #21303a;
    width: 100%;
    min-height: 61vh;
    height: 100%;
    background-color: #f6f6f6;
    &__title {
      display: flex;
      align-items: center;
      height: 76px;
      justify-content: space-between;
      padding-right: 52px;
      padding-left: 24px;
      box-shadow: inset 0 -1px 0 0 #c9cfd3;
      background: ${colors.WHITE};
      h1 {
        font-size: 24px;
        line-height: 1.71;
        letter-spacing: 0.26px;
      }
      .edit-button {
        height: 39px;
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: #f6f6f6;
      .table-title {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 16px;
        padding: 24px 0 18px 24px;
        box-shadow: inset 0 -1px 0 0 #c9cfd3;
        background-color: #ffffff;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        color: ${colors.AVATAR_INITIAL};
      }
      .ant-table-wrapper {
        width: 100%;
        max-width: 100%;
        align-self: baseline;
        .ant-table-content::before {
          display: none;
        }
        table {
          border-collapse: collapse;
          th {
            padding-left: 9px;
            padding-right: 0;
            font-family: Lato;
            font-size: 18px;
            font-weight: bold;
            color: #21303a;
            opacity: 0.8;
            border-right: 1px solid #c9cfd3 !important;
          }
          .ant-table-row-expand-icon-cell {
            border: none !important;
          }
          .ant-table-row-level-0 {
            background: #ffffff;
            &:nth-of-type(odd) {
              background: #ececec;
            }
            td {
              background: inherit;
              padding-left: 8px;
            }
          }
          .ant-table-expanded-row-level-1 {
            .ant-table-row-level-0,
            .ant-table-row-level-1 {
              cursor: default;
              strong {
                margin-left: 0;
              }
            }
          }
          td {
            height: 51px;
            padding: 11px 0 11px 16px;
            box-sizing: border-box;
            border-top: none;
            border-bottom: none !important;
            strong {
              font-family: Lato;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0.15px;
              color: #21303a;
            }
          }
        }
      }
    }
    .delete {
      font-family: Lato;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.17px;
      color: #cc3f3f;
      text-transform: uppercase;
      border: none;
      background: inherit;
      padding: 0;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .ant-table-content {
    &:after {
      content: none;
    }
  }
  .expandable_table-container {
    margin: -11px 0 -8px -16px;
    padding: 1px 26px 32px 54px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: 2px solid #d2d7db;
    border-top: none;
    background: #f9f9ff;
    .ant-table-small {
      border: none;
      table {
        border-bottom: 1px solid #c9cfd3;
        .ant-table-thead {
          tr {
            border: none;
            th {
              padding: 5px;
            }
          }
        }
        .ant-table-tbody {
          .ant-table-row-level-0 {
            border-top: 1px solid #c9cfd3;
            td {
              strong {
                font-weight: 600 !important;
              }
            }
            :first-child {
              border: none !important;
            }
          }
          .ant-table-row-level-1 {
            td {
              padding-top: 0;
              height: 30px;
              &:first-child {
                padding-left: 28px;
              }
            }
          }
        }
        tr {
          background: #f9f9ff !important;
          th,
          td {
            border: none !important;
            &:first-child {
              position: unset !important;
              .ant-table-row-expand-icon,
              .indent-level-1 {
                display: none;
              }
            }
          }
          th {
            background: #f9f9ff;
          }
        }
      }
    }
  }
  .red {
    color: ${colors.RED} !important;
    font-weight: 600 !important;
  }
  .waitlist_wrap {
    .profile_table__container {
      .ant-table-wrapper {
        table {
          .ant-table-expand-icon-th {
            display: table-cell !important;
            position: absolute;
            border: none;
            background: inherit;
          }
          .ant-table-row-level-0 {
            td {
              padding-left: 8px;
              &:nth-child(2) {
                padding-left: 30px;
              }
              strong {
                font-size: 12px;
              }
            }
            .ant-table-row-expand-icon-cell {
              display: table-cell;
              position: absolute;
              z-index: 5;
              border: none;
              background: none !important;
              padding-left: 4px;
              text-align: left;
              font-size: 21px;
              color: ${colors.PURPLE};
            }
            &:hover {
              .ant-table-row-expand-icon-cell {
                background: none;
              }
            }
          }
          .ant-table-expanded-row-level-1 {
            border: 1px solid #c4c6c8;
            td {
              background: #ececec;
              &:first-child {
                display: table-cell;
                width: 0;
                padding: 0;
                border: none;
              }
            }
          }
        }
      }
    }
    .expandedRowWrapper {
      display: flex;
      width: 180px;
      justify-content: space-between;
      .left,
      .right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        span {
          padding-bottom: 6px;
          font-family: Lato;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.13px;
          color: ${colors.AVATAR_INITIAL};
          &.with_underline {
            text-decoration: underline;
          }
          &.minus {
            text-align: left;
          }
        }
      }
      .left {
        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const AuthorizedTherapiesStyles = styled.div`
  .ant-table-wrapper {
    table {
      .ant-table-row-level-0 {
        cursor: ${({ isExpandedRowByClick }) =>
          isExpandedRowByClick ? 'pointer' : 'default'};
      }
      th {
        &:first-child {
          position: absolute;
          left: 100px;
          border: none;
        }
      }
      td {
        &:first-child {
          border: none;
          z-index: 10;
          &::after {
            content: '';
          }
        }
        &:nth-child(2) {
          strong {
            margin-left: 56px;
          }
        }
        .anticon-right,
        .anticon-down {
          font-size: 21px;
          vertical-align: inherit;
          cursor: pointer;
        }
        .fa-chevron-right,
        .fa-chevron-down {
          margin-left: 25px;
          margin-right: 34px;
          cursor: pointer;
        }
        .fa-edit {
          margin-left: 30px;
          cursor: pointer;
        }
      }
    }
  }
`;
