import { gql } from 'graphql-tag';

export const EventSyncNoticeData = gql`
  fragment EventSyncNoticeData on EventSyncNotice {
    eventId
    providerName
    clientName
    startDate
    endDate
    downStream
    CreatedBy
    totalCount
    failureCount
    status
    isSupressLink
  }
`;
