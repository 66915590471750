import React from 'react';
import {
  FormItem,
  Input,
  AutoFormattedInput,
  Select,
  RadioGroup
} from 'views/containers/form';
import { Row } from 'antd';
import { YES_NO_LIST, PHONE_PREFERENCE_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { Field, FastField, FieldArray } from 'formik';
import { US_STATES_LIST } from 'utils/constants/commonLists';
import { ReactComponent as MsLogo } from 'assets/img/icons-pencil.svg';

const yesNoOptions = mapToOption(YES_NO_LIST);
const stateOptions = mapToOption(US_STATES_LIST);
const phonePreferenceOptions = mapToOption(PHONE_PREFERENCE_LIST);
const addressBody = (
  indexFormate,
  index,
  setFieldValue,
  addresses,
  clientID,
  disabledFormFields
) => (
  <>
    {index > 0 && (
      <>
        <hr />
        <div className="address_cta_wrapper">
          <MsLogo
            alt="Delete Address"
            onClick={() =>
              deleteAddress(setFieldValue, addresses, index, clientID)
            }
          />
        </div>
      </>
    )}

    <Row type="flex" justify="start" align="top" gutter={16}>
      <FormItem label="Name" xs={16} sm={6}>
        <Field
          name={`${indexFormate}.name`}
          render={({ field }) => (
            <Input
              name={`${indexFormate}.name`}
              value={field.value}
              placeholder="Name"
              onChange={field.onChange}
              disabled={disabledFormFields.addresses.name}
            />
          )}
        />
      </FormItem>
    </Row>

    <Row type="flex" justify="start" align="top" gutter={16}>
      <FormItem label="Address (Line 1)" xs={16} sm={6}>
        <Field
          name={`${indexFormate}.addressLine1`}
          render={({ field }) => (
            <Input
              name={`${indexFormate}.addressLine1`}
              value={field.value}
              placeholder="Address"
              onChange={field.onChange}
              disabled={disabledFormFields.addresses.addressLine1}
            />
          )}
        />
      </FormItem>
      <FormItem optional label="Address (Line 2)" xs={16} sm={6}>
        <Field
          name={`${indexFormate}.addressLine2`}
          render={({ field }) => (
            <Input
              name={`${indexFormate}.addressLine2`}
              value={field.value}
              placeholder="Address"
              onChange={field.onChange}
              disabled={disabledFormFields.addresses.addressLine2}
            />
          )}
        />
      </FormItem>
    </Row>
    <Row type="flex" justify="start" align="top" gutter={16}>
      <FormItem label="City" xs={16} sm={6}>
        <Field
          name={`${indexFormate}.city`}
          render={({ field }) => (
            <Input
              name={`${indexFormate}.city`}
              value={field.value}
              placeholder="City"
              onChange={field.onChange}
              disabled={disabledFormFields.addresses.city}
            />
          )}
        />
      </FormItem>
      <FormItem label="State" xs={16} sm={6}>
        <Field
          name={`${indexFormate}.state`}
          render={fieldProps => (
            <Select
              options={stateOptions}
              placeholder="Select"
              {...fieldProps}
              disabled={disabledFormFields.addresses.state}
            />
          )}
        />
      </FormItem>

      <FormItem label="ZIP Code" xs={16} sm={6}>
        <FastField
          name={`${indexFormate}.zipCode`}
          render={({ field }) => (
            <AutoFormattedInput
              type="zip"
              name={`${indexFormate}.zipCode`}
              value={field.value || ''}
              placeholder="ZIP"
              onChange={field.onChange}
              disabled={disabledFormFields.addresses.zipCode}
            />
          )}
        />
      </FormItem>
    </Row>
  </>
);
const addAddress = (setFieldValue, data) => {
  setFieldValue('addresses', [
    ...data.addresses,
    {
      addressLine1: undefined,
      addressLine2: undefined,
      city: undefined,
      state: undefined,
      zipCode: undefined,
      cellPhone: undefined,
      name: undefined
    }
  ]);
};
const deleteAddress = (
  setFieldValue,
  addresses,
  index,
  removeAddress,
  clientID
) => {
  setFieldValue('addresses', [
    ...addresses.slice(0, index),
    ...addresses.slice(index + 1)
  ]);
};
export const ClientContactForm = React.memo(
  ({ data, setFieldValue, disabledFormFields }) => {
    return (
      <>
        <div className="form-title">Contact</div>

        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem label="Mobile Phone Number" xs={16} sm={6}>
            <FastField
              name="profile.cellPhone"
              render={({ field }) => (
                <AutoFormattedInput
                  type="phone"
                  name="profile.cellPhone"
                  value={field.value || ''}
                  placeholder="000-000-0000"
                  onChange={field.onChange}
                  disabled={disabledFormFields.cellPhone}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Home Phone Number" xs={16} sm={6}>
            <FastField
              name="profile.homePhone"
              render={({ field }) => (
                <AutoFormattedInput
                  type="phone"
                  name="profile.homePhone"
                  value={field.value || ''}
                  placeholder="000-000-0000"
                  onChange={field.onChange}
                  disabled={disabledFormFields.homePhone}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Phone Preference" xs={16} sm={4}>
            <Field
              name="profile.phonePreference"
              render={fieldProps => (
                <RadioGroup
                  defaultValue={fieldProps.field.value}
                  options={phonePreferenceOptions}
                  name="profile.phonePreference"
                  {...fieldProps}
                  disabled={disabledFormFields.phonePreference}
                />
              )}
            />
          </FormItem>
          <FormItem label="Consent to Text" xs={16} sm={4}>
            <Field
              name="profile.consentToText"
              render={fieldProps => (
                <RadioGroup
                  defaultValue={fieldProps.field.value}
                  options={yesNoOptions}
                  name="profile.consentToText"
                  {...fieldProps}
                  disabled={disabledFormFields.consentToText}
                />
              )}
            />
          </FormItem>
        </Row>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem label="Contact Email Address" xs={16} sm={6}>
            <FastField
              name="profile.personalEmail"
              render={({ field }) => (
                <Input
                  name="profile.personalEmail"
                  value={field.value}
                  placeholder="Email Address"
                  onChange={field.onChange}
                  disabled={disabledFormFields.personalEmail}
                />
              )}
            />
          </FormItem>
        </Row>
        <div className="form-title">Address(s)</div>

        <FieldArray
          name="addresses"
          render={arrayHelpers => (
            <div>
              {data.addresses?.map((address, index) => (
                <div key={index}>
                  {addressBody(
                    `addresses[${index}]`,
                    index,
                    setFieldValue,
                    data.addresses,
                    data.id,
                    disabledFormFields
                  )}
                </div>
              ))}
            </div>
          )}
        />
        <hr />

        <button
          className="address_cta"
          onClick={() => addAddress(setFieldValue, data)}
        >
          + Add Another Address
        </button>
      </>
    );
  }
);
