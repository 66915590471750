import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

// import Loader from '../../../ui/content/Loader';
import Loader from '../../../../src/views/components/ui/content/Loader';
import { CheckListWrapper } from 'components/calendar/filters/style';
import { default as ClientFilter } from 'views/components/waitlistPage/components/filters/mainFilter';
import { waitListFilterHelper } from 'helpers/filterHekper';
import WaitListCommonFilter from 'views/components/waitlistPage/components/filters/waitListCommonFilter';
import FilterListFilled from 'assets/img/FilterListFilled.png';
import {
  ClientFilterType,
  FilterPlaceHolder,
  ICalendarFilterData,
  ICalendarFilterOption
} from 'model/calendar/filters';
import { programTypeFilter } from 'utils/common';
import { CLIENT_STATUS_LIST } from 'model/v2/clients';

interface Props {
  isLoading: boolean;
  FilterData: ICalendarFilterData;
  checkedClinics: ICalendarFilterOption[];
  setCheckedClinics: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedPrograms: ICalendarFilterOption[];
  setCheckedPrograms: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedStatus: ICalendarFilterOption[];
  setCheckedStatus: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  setIsShowDrawer: React.Dispatch<React.SetStateAction<Boolean>>;
  setCheckedLanguage: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedLanguage: ICalendarFilterOption[];
  checkedClientAvailability: ICalendarFilterOption[];
  setClientAvailability: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  children: () => {
    ClientSearchWrapper: JSX.Element;
  };
}
const ClientFilterLayout = ({
  isLoading,
  FilterData,
  checkedClinics,
  setCheckedClinics,
  checkedPrograms,
  setCheckedPrograms,
  checkedStatus,
  setCheckedStatus,
  setIsShowDrawer,
  setCheckedLanguage,
  checkedLanguage,
  checkedClientAvailability,
  setClientAvailability,
  children
}: //   children
Props) => {
  const clinicFiltersRef = useRef<HTMLDivElement>(null);
  const restrictionRef = useRef<HTMLDivElement>(null);
  const languageRef = useRef<HTMLDivElement>(null);
  const specialtyFiltersRef = useRef<HTMLDivElement>(null);
  const statusFiltersRef = useRef<HTMLDivElement>(null);
  const programsRef = useRef<HTMLDivElement>(null);
  const availabilityRef = useRef<HTMLDivElement>(null);
  const clientAvailabilityFilterRef = useRef(null);
  const [hasClinics, setHasClinics] = useState(true);
  const [hasLanguage, setHasLanguage] = useState(true);
  const [hasPrograms, setHasPrograms] = useState(true);
  const [hasStatus, setHasStatus] = useState(true);
  const [hasClientAvailability, setHasClientAvailability] = useState(true);
  const filterRefs: any = {
    specialty: specialtyFiltersRef,
    clinic: clinicFiltersRef,
    status: statusFiltersRef,
    programs: programsRef,
    restriction: restrictionRef,
    language: languageRef,
    availability: availabilityRef,
    clientAvailability: clientAvailabilityFilterRef
  };
  let { ClientSearchWrapper } = children();

  const [searchValue, setSearchValue] = useState<Record<string, string>>({
    clinic: '',
    specialty: '',
    status: '',
    programs: '',
    restriction: '',
    language: '',
    availability: '',
    clientAvailability: ''
  });

  const [filterDropdownExpand, setFilterDropdownExpand] = useState<
    Record<string, boolean>
  >({
    clinic: false,
    specialty: false,
    status: false,
    programs: false,
    restriction: false,
    language: false,
    clientAvailability: false
  });

  const toggleFilterList = (type: string) => {
    setFilterDropdownExpand({
      ...filterDropdownExpand,
      [type]: !filterDropdownExpand[type]
    });
  };

  const handleGenericBlur = (
    e: React.FocusEvent<HTMLDivElement>,
    filterType: string
  ) => {
    const currentRef = filterRefs[filterType];
    if (
      currentRef?.current &&
      (e.relatedTarget === currentRef?.current ||
        currentRef?.current.contains(e.relatedTarget))
    ) {
      return; // Do nothing if the click was inside the dropdown
    }

    if (filterDropdownExpand[filterType]) {
      setFilterDropdownExpand((prevState: Record<string, boolean>) => ({
        ...prevState,
        [filterType]: false
      }));
    }
  };

  const handleGenericSearch = (
    _e: ChangeEvent<HTMLInputElement>,
    data: { value: any },
    typeOfSearch: string
  ) => {
    const isSupportedSearchType = ['clinic', 'language'].includes(typeOfSearch);

    if (isSupportedSearchType) {
      setFilterDropdownExpand((prevState: Record<string, boolean>) => ({
        ...prevState,
        [typeOfSearch]: true
      }));

      setSearchValue((prevState: Record<string, string | number>) => ({
        ...prevState,
        [typeOfSearch]: data?.value
      }));
    }
  };

  //Base handleSearchedValues method
  const createHandleSearchedValues = useCallback(
    (type: string) => {
      //if(setCheckedSavedFilter) setCheckedSavedFilter('');
      return (list: ICalendarFilterOption[]) => {
        const searchTerm = searchValue[type];
        if (type !== 'programs')
          return searchTerm === ''
            ? list
            : list?.filter((option: ICalendarFilterOption) => {
                return option?.label
                  ?.toLowerCase()
                  .startsWith(searchTerm?.toLowerCase());
              });
        else {
          let datas =
            searchTerm === ''
              ? list.map((data: ICalendarFilterOption) =>
                  programTypeFilter(FilterData, data)
                )
              : list
                  ?.filter((option: ICalendarFilterOption) => {
                    return option?.label
                      ?.toLowerCase()
                      .startsWith(searchTerm?.toLowerCase());
                  })
                  .map((data: ICalendarFilterOption) =>
                    programTypeFilter(FilterData, data)
                  );
          return datas;
        }
      };
    },
    [searchValue, FilterData]
  );

  //Creates a handleSearchedValues for each type of search, which is passed through props to the child components of each type (ex. activeStatusFilter)
  const handleClinicSearchedValues = createHandleSearchedValues('clinic');
  const handleLanguageSearchedValues = createHandleSearchedValues('language');
  const handleStatusSearchedValues = createHandleSearchedValues('status');
  const handleProgramsSearchedValue = createHandleSearchedValues('programs');
  const handleClientAvailabilityValues = createHandleSearchedValues(
    'clientAvailability'
  );
  const clearFilter = useCallback((clearFilter, departmentFilter?) => {
    if (clearFilter)
      clearFilter((prev: ICalendarFilterOption[]) => (prev.length ? [] : prev));
    if (departmentFilter)
      departmentFilter((prev: ICalendarFilterOption[]) =>
        prev.length ? [] : prev
      );
  }, []);
  const clientMappedFilters = useCallback(
    type => {
      if (type === 'status' && FilterData) {
        const filterStatusData = Object.entries(CLIENT_STATUS_LIST).map(
          ([_, value]) => ({
            id: value,
            title: value
          })
        );
        FilterData!.status = filterStatusData;
      }
      if (FilterData) {
        const data = waitListFilterHelper(type, FilterData);
        return data;
      }
    },
    [FilterData]
  );
  return (
    <div className="dropdown-wrapper provider-dropdown-wrapper">
      <CheckListWrapper
        visible={filterDropdownExpand.clinic}
        height="300px"
        style={{ position: 'relative' }}
      >
        <ClientFilter
          clinicFiltersRef={clinicFiltersRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          filterDropdownExpand={filterDropdownExpand}
          handleGenericSearch={handleGenericSearch}
          searchValue={searchValue}
          hasClinics={hasClinics}
          isLoading={isLoading}
          FilterData={FilterData?.clinicOptionsList!}
          handleClinicSearchedValues={handleClinicSearchedValues}
          checkedClinics={checkedClinics}
          setCheckedClinics={setCheckedClinics}
          setHasClinics={setHasClinics}
          clearFilter={clearFilter}
          Loader={Loader}
          type={ClientFilterType.clinic}
        />
      </CheckListWrapper>
      <CheckListWrapper
        visible={filterDropdownExpand.language}
        height="300px"
        style={{ position: 'relative' }}
      >
        <ClientFilter
          clinicFiltersRef={languageRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          filterDropdownExpand={filterDropdownExpand}
          handleGenericSearch={handleGenericSearch}
          searchValue={searchValue}
          hasClinics={hasLanguage}
          isLoading={isLoading}
          FilterData={FilterData?.languages!}
          handleClinicSearchedValues={handleLanguageSearchedValues}
          checkedClinics={checkedLanguage}
          setCheckedClinics={setCheckedLanguage!}
          setHasClinics={setHasLanguage}
          clearFilter={clearFilter}
          Loader={Loader}
          type={ClientFilterType.language}
          placeHolder={FilterPlaceHolder.language}
          title={'Languages'}
        />
      </CheckListWrapper>

      <WaitListCommonFilter
        filterDropdownExpand={filterDropdownExpand}
        filterTypeRefs={statusFiltersRef}
        handleGenericBlur={handleGenericBlur}
        toggleFilterList={toggleFilterList}
        handleGenericSearch={handleGenericSearch}
        hasFilterOptions={hasStatus}
        searchValue={searchValue}
        isLoading={isLoading}
        filterValueMappings={clientMappedFilters}
        handleSerachedValues={handleStatusSearchedValues}
        checkedFilterType={checkedStatus}
        setFilterType={setCheckedStatus}
        setHasFilterOptions={setHasStatus}
        clearFilter={clearFilter}
        Loader={Loader}
        title={'Status'}
        placeHolder={'Status'}
        blurTitle={'status'}
        handleChangeType={'status'}
      />

      <WaitListCommonFilter
        filterDropdownExpand={filterDropdownExpand}
        filterTypeRefs={programsRef}
        handleGenericBlur={handleGenericBlur}
        toggleFilterList={toggleFilterList}
        handleGenericSearch={handleGenericSearch}
        hasFilterOptions={hasPrograms}
        searchValue={searchValue}
        isLoading={isLoading}
        filterValueMappings={clientMappedFilters}
        handleSerachedValues={handleProgramsSearchedValue}
        checkedFilterType={checkedPrograms}
        setFilterType={setCheckedPrograms}
        setHasFilterOptions={setHasPrograms}
        clearFilter={clearFilter}
        Loader={Loader}
        title={'Program'}
        placeHolder={'Programs'}
        blurTitle={'programs'}
        handleChangeType={'corticaProgram'}
      />

      <WaitListCommonFilter
        filterDropdownExpand={filterDropdownExpand}
        filterTypeRefs={clientAvailabilityFilterRef}
        handleGenericBlur={handleGenericBlur}
        toggleFilterList={toggleFilterList}
        handleGenericSearch={handleGenericSearch}
        hasFilterOptions={hasClientAvailability}
        searchValue={searchValue}
        isLoading={isLoading}
        filterValueMappings={clientMappedFilters}
        handleSerachedValues={handleClientAvailabilityValues}
        checkedFilterType={checkedClientAvailability}
        setFilterType={setClientAvailability}
        setHasFilterOptions={setHasClientAvailability}
        clearFilter={clearFilter}
        Loader={Loader}
        title={'Availability'}
        placeHolder={'Availability'}
        blurTitle={'clientAvailability'}
        handleChangeType={'availabilityTime'}
      />
      {ClientSearchWrapper}

      <div>
        <div
          className="Additional_Filter_Layout"
          onClick={() => setIsShowDrawer(true)}
        >
          <img src={FilterListFilled}></img>
          <div className="Additional_Filter_Title">
            <span>
              <u>More Filters</u>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientFilterLayout;
