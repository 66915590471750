import React from 'react';
import { IEvent } from 'model/v2';
import { Grid } from 'semantic-ui-react';
import { formatAttendeeName } from 'utils/format';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { DEFAULT_DOCTOR_IMAGE } from 'utils/constants/default';

interface Props {
  observations: IEvent[] | undefined;
}
const ObservationRow: React.FC<Props> = ({ observations }: Props) => {
  return (
    <>
      {observations &&
        observations.map((observer: any) => {
          const observerName = formatAttendeeName(
            observer?.provider?.firstName,
            observer?.provider?.lastName,
            observer?.provider?.clinic?.abbreviation
          );
          return (
            <Grid.Row className={'observerRow'}>
              <Grid.Column className={'attende-name-row'}>
                <div className="attendees">
                  <Link
                    className="attende-name"
                    target="_blank"
                    to={`/providers/${observer.provider?.id}`}
                  >
                    <img
                      src={DEFAULT_DOCTOR_IMAGE}
                      alt={'pic'}
                      className="client-pic"
                    />
                    {observerName}
                  </Link>
                </div>
              </Grid.Column>
              <Grid.Column className={'attende-name-label'}>
                <button
                  className={`observerBtn observer${observations.indexOf(
                    observer
                  ) + 1}-btn`}
                >
                  <EyeOutlined /> Observer {observations.indexOf(observer) + 1}
                </button>
              </Grid.Column>
            </Grid.Row>
          );
        })}
    </>
  );
};

export default React.memo(ObservationRow);
