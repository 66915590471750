import React from 'react';
import NotesIcon from 'assets/img/icon-notes-chat.svg';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'lib/ui';
import moment from 'moment';
import { providerStatusForSelect } from 'utils/common';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { Avatar, Badge, Space } from 'antd';
import { MapProviderPetAllergies } from 'utils/common';
import searchIcon from 'assets/img/bars-search-search-bar.svg';
import { CLIENT_PROVIDER_HEADER, CLIENT_LANGUAGES_HEADER } from '../clientList';
import { CLIENT_EDIT_TYPE } from 'model/v2';
import { WaitListPopOver } from 'views/components/waitlistPage/waitlist.styled';
import { ROUTE_PATHS, TARGET_BLANK } from '../routes';
import { Link } from 'react-router-dom';

export const MAIN_CLIENTS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'DOB',
    dataIndex: 'dob'
  },
  {
    title: 'Gender',
    dataIndex: 'gender'
  },
  {
    title: 'Home Clinic',
    dataIndex: 'homeClinic'
  },
  {
    title: 'Status',
    dataIndex: 'status'
  }
];

export const CLINIC_CLIENTS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'DOB',
    dataIndex: 'dob'
  },
  {
    title: 'Gender',
    dataIndex: 'gender'
  },
  {
    title: 'Phenotype',
    dataIndex: 'phenoType'
  }
];

export const MAIN_CLIENT_AUTHORIZED_THERAPIES_COLUMNS = handleEdit => [
  {
    title: 'Auth ID',
    dataIndex: 'authGroupID',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Start',
    dataIndex: 'startDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'End',
    dataIndex: 'endDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Report Due',
    dataIndex: 'dueDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Auths Remaining',
    dataIndex: 'authsRemaining',
    render: remaining => (
      <strong className={remaining?.lessThanTwentyPercent ? 'red' : ''}>
        {remaining?.count}
      </strong>
    )
  },
  {
    title: 'Time Remaining',
    dataIndex: 'timeRemaining',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'View/Edit',
    dataIndex: 'view_edit',
    render: (_, record) => (
      <FontAwesomeIcon icon={faEdit} onClick={e => handleEdit(e, record)} />
    )
  }
];

export const MAIN_CLIENT_EXPANDABLE_TABLE_COLUMNS = [
  {
    title: 'Appointment Type',
    dataIndex: 'appointmentType',
    render: text => <strong>{text}</strong>,
    width: '20%'
  },
  {
    title: 'Auths Completed',
    dataIndex: 'completed',
    render: text => <strong>{text}</strong>,
    width: '13%'
  },
  {
    title: 'Auths Scheduled',
    dataIndex: 'scheduled',
    render: text => <strong>{text}</strong>,
    width: '16%'
  },
  {
    title: 'Auths Remaining',
    dataIndex: 'remaining',
    render: remaining => (
      <strong className={remaining?.lessThanTwentyPercent ? 'red' : ''}>
        {remaining?.count}
      </strong>
    ),
    width: '16%'
  },
  {
    title: 'Auths to Fulfill',
    dataIndex: 'toFulfill',
    render: text => <strong>{text}</strong>
  }
];

export const APPOINTMENT_CLIENT_AUTH_THERAPIES_COLUMNS = [
  {
    title: 'Auth ID',
    dataIndex: 'authGroupID',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Start',
    dataIndex: 'startDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'End',
    dataIndex: 'endDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Time Remaining',
    dataIndex: 'timeRemaining',
    render: text => <strong>{text}</strong>
  }
];
export const APPOINTMENT_CLIENT_EXPANDABLE_TABLE_COLUMNS = [
  {
    title: 'Appointment Type',
    dataIndex: 'appointmentType',
    render: text => <strong>{text}</strong>,
    width: '29%'
  },
  {
    title: 'Completed',
    dataIndex: 'completed',
    render: text => <strong>{text}</strong>,
    width: '19%'
  },
  {
    title: 'Scheduled',
    dataIndex: 'scheduled',
    render: text => <strong>{text}</strong>,
    width: '18%'
  },
  {
    title: 'Remaining',
    dataIndex: 'remaining',
    render: remaining => (
      <strong className={remaining?.lessThanTwentyPercent ? 'red' : ''}>
        {remaining?.count}
      </strong>
    ),
    width: '17%'
  },
  {
    title: 'To Fulfill',
    dataIndex: 'toFulfill',
    render: text => <strong>{text}</strong>,
    width: '17%'
  }
];

export const MAIN_CLIENT_SCHEDULING_STATUS_COLUMNS = onClick => [
  {
    title: 'Appt. Type',
    dataIndex: 'apptType',
    width: '20%'
  },
  {
    title: 'Appt. Sub-Type',
    dataIndex: 'apptSubType',
    width: '20%'
  },
  {
    title: 'Ideal Plan',
    dataIndex: 'idealPlan',
    width: '15%'
  },
  {
    title: 'Receiving',
    dataIndex: 'receiving',
    width: '15%'
  },
  {
    title: 'To Fulfill',
    dataIndex: 'toFulfill',
    width: '15%'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (_text, record) => {
      return (
        record.canSchedule && (
          <Button className="secondary" onClick={() => onClick(record)}>
            Schedule
          </Button>
        )
      );
    },
    width: '15%'
  }
];

export const MAIN_CLIENT_RECOMMENDED_EVALUATIONS_COLUMNS = [
  {
    title: 'Appointment Type',
    dataIndex: 'apptType',
    render: text => <strong>{text}</strong>,
    width: '25%'
  },
  {
    title: 'Appointment Sub-Type',
    dataIndex: 'apptSubType',
    render: text => <strong>{text}</strong>,
    width: '25%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: text => <strong>{text}</strong>,
    width: '25%'
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    render: text => <strong>{text}</strong>,
    width: '25%'
  }
];

export const MAIN_CLIENT_RECOMMENDED_THERAPIES_COLUMNS = [
  {
    title: 'Appointment Type',
    dataIndex: 'apptType',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Appointment Sub-Type',
    dataIndex: 'apptSubType',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Recommended',
    dataIndex: 'recommendedQuantity',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Requested',
    dataIndex: 'requestedQuantity',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Date',
    dataIndex: 'updatedAt',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  }
];

export const APPOINTMENT_CLIENT_WAITLIST_COLUMNS = [
  {
    title: 'Appt. Type',
    dataIndex: 'appt',
    render: text => <strong>{text}</strong>,
    width: '30%'
  },
  {
    title: 'Appt. Sub Type',
    dataIndex: 'subType',
    render: text => <strong>{text}</strong>,
    width: '30%'
  },
  {
    title: 'To FulFill',
    dataIndex: 'toFulFill',
    render: text => <strong>{text}</strong>,
    width: '22%'
  },
  {
    title: 'Waiting',
    dataIndex: 'waiting',
    render: text => <strong>{text}</strong>,
    width: '18%'
  }
];

export const CLIENT_COLUMNS = (
  clientDataLoading,
  handleChange,
  setShowSidebar,
  setSidebarData,
  clientAllergies,
  calendarFilterData,
  SubRestrictedProvider,
  PopOverComp
) => {
  return [
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        let { isLoaderView, id } = record;

        const clientProfileUrl = `${ROUTE_PATHS.CLIENT_AVAILABILITY}${id}`;

        return !isLoaderView ? (
          <div>
            <Link
              className="provider_link"
              to={clientProfileUrl}
              target={TARGET_BLANK}
            >
              {text}
            </Link>
          </div>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      render: (text, record) => {
        let { isLoaderView } = record;

        return !isLoaderView ? <div>{text}</div> : <div>{text}</div>;
      }
    },
    // CLIENT_ROSTER_STATUS_SELECT
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        let {
          isLoaderView,
          languages,
          restrictedProviders,
          petAllergies,
          clientId
        } = record;
        const statusType = text ? 'Active' : 'Inactive';
        let handleStatusUpdate = handleChange(
          {
            [CLIENT_EDIT_TYPE.languages]: languages,
            [CLIENT_EDIT_TYPE.restrictedProviders]: restrictedProviders,
            [CLIENT_EDIT_TYPE.allergies]: petAllergies
          },
          clientId,
          CLIENT_EDIT_TYPE.status
        );

        return providerStatusForSelect(
          record.status,
          CLIENT_ROSTER_STATUS_SELECT
        ) ? (
          <div className={`Status_${statusType}`}>
            <span className={`Status_Inner_Cont`}>
              <Select
                value={record.status}
                // open={true}
                onChange={e => {
                  handleStatusUpdate(e, clientId, CLIENT_EDIT_TYPE.status);
                }}
                getPopupContainer={trigger =>
                  trigger.closest('.ant-table-container')
                }
                options={CLIENT_ROSTER_STATUS_SELECT}
              ></Select>
            </span>
          </div>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Contact Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => {
        let { isLoaderView } = record;

        return !isLoaderView ? <div>{text}</div> : <div>{text}</div>;
      }
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      render: (text, record) => {
        let { isLoaderView } = record;

        return !isLoaderView ? <div>{text}</div> : <div>{text}</div>;
      }
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      render: (text, record) => {
        let { isLoaderView } = record;

        return !isLoaderView ? <div>{text}</div> : <div>{text}</div>;
      }
    },
    {
      title: 'Allergies',
      dataIndex: 'petAllergies',
      key: 'allergies',
      render: (text, record) => {
        let {
          isLoaderView,
          status,
          restrictedProviders,
          languages,
          clientId
        } = record;
        let handleAllergiesUpdate = handleChange(
          {
            [CLIENT_EDIT_TYPE.status]: status,
            [CLIENT_EDIT_TYPE.restrictedProviders]: restrictedProviders,
            [CLIENT_EDIT_TYPE.languages]: languages
          },
          clientId,
          CLIENT_EDIT_TYPE.allergies
        );

        return !isLoaderView ? (
          <Select
            placeholder=""
            mode="multiple"
            onChange={e => {
              let allergyTotalIndex = e?.reduce((acc, val) => acc + val, 0);
              handleAllergiesUpdate(
                allergyTotalIndex,
                clientId,
                CLIENT_EDIT_TYPE.allergies
              );
            }}
            className={'provider-select'}
            value={MapProviderPetAllergies(text)}
            getPopupContainer={trigger =>
              trigger.closest('.ant-table-container')
            }
            options={clientAllergies}
          ></Select>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Languages',
      dataIndex: 'languages',
      key: 'languages',
      render: (text, record) => {
        let {
          isLoaderView,
          languages,
          id,
          status,
          restrictedProviders,
          allergies,
          petAllergies
        } = record;
        let handleLanguageUpdate = handleChange(
          {
            [CLIENT_EDIT_TYPE.status]: status,
            [CLIENT_EDIT_TYPE.restrictedProviders]: restrictedProviders,
            [CLIENT_EDIT_TYPE.allergies]: petAllergies
          },
          id,
          CLIENT_EDIT_TYPE.languages,
          'name'
        );

        return !isLoaderView && calendarFilterData ? (
          <div>
            <SubRestrictedProvider
              placeholder={
                <div>
                  <span style={{ marginRight: '14px' }}>Search Languages</span>
                  <img
                    src={searchIcon}
                    className="search-icon-waitList"
                    alt=""
                  />
                </div>
              }
              maxTagCount={1}
              dropdownClassName={'custom-dropdown-select'}
              optionLabelProp="label"
              header={
                <div className="Provider-select-header">
                  {CLIENT_LANGUAGES_HEADER}
                </div>
              }
              optionLabelProp={'label'}
              maxTagCount={1}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              className={'provider-select-languages'}
              overAllOptions={calendarFilterData?.languages}
              handleChange={handleLanguageUpdate}
              selectedOptions={languages}
              selectId={id}
              selectType={CLIENT_EDIT_TYPE.languages}
              displayContent={'name'}
              optionMapType={'name'}
            ></SubRestrictedProvider>
          </div>
        ) : (
          <div>{isLoaderView ? text : 'N/A'}</div>
        );
      }
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      key: 'availability',
      render: (text, record) => {
        let { isLoaderView } = record;

        return !isLoaderView ? (
          <WaitListPopOver>
            <PopOverComp
              displayContent={text}
              clientInfo={record}
              popOverType={'clientDayAvailabilityContent'}
              placement="rightBottom"
            ></PopOverComp>
          </WaitListPopOver>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Program',
      dataIndex: 'program',
      key: 'program',
      render: (text, record) => {
        let { isLoaderView } = record;

        return !isLoaderView ? <div>{text}</div> : <div>{text}</div>;
      }
    },
    {
      title: 'Sub Restrictions',
      dataIndex: 'restrictions',
      key: 'restrictions',
      render: (text, record) => {
        let {
          isLoaderView,
          restrictedProviders,
          clientId,
          status,
          languages,
          petAllergies
        } = record;
        let handleProviderUpdate = handleChange(
          {
            [CLIENT_EDIT_TYPE.status]: status,
            [CLIENT_EDIT_TYPE.languages]: languages,
            [CLIENT_EDIT_TYPE.allergies]: petAllergies
          },
          clientId,
          CLIENT_EDIT_TYPE.restrictedProviders,
          'id',
          restrictedProviders
        );

        return !isLoaderView ? (
          <div>
            <SubRestrictedProvider
              selectId={clientId}
              overAllOptions={[]}
              filterOption={false}
              selectedOptions={restrictedProviders}
              handleChange={handleProviderUpdate}
              optionLabelProp={'label'}
              maxTagCount={1}
              dropdownClassName={'custom-dropdown-select'}
              placeholder={
                <div>
                  <span style={{ marginRight: '14px' }}>Search Providers</span>
                  <img
                    src={searchIcon}
                    className="search-icon-waitList"
                    alt=""
                  />
                </div>
              }
              className={'provider-select-languages  provider-select-options'}
              header={
                <div className="Provider-select-header">
                  {CLIENT_PROVIDER_HEADER}
                </div>
              }
              selectType={CLIENT_EDIT_TYPE.restrictedProviders}
              displayContent={'displayName'}
              optionMapType={'id'}
            ></SubRestrictedProvider>
          </div>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      render: (text, record) => {
        const threshold = 99;
        const displayCount =
          record.notesCount === 0
            ? '+'
            : record.notesCount > threshold
            ? `${threshold}+`
            : record.notesCount;
        let { isLoaderView } = record;

        return !isLoaderView ? (
          <Badge
            offset={[-1, 0]}
            style={{ background: '#6F42F5' }}
            size="small"
            count={displayCount}
          >
            <img
              alt="notesicon"
              src={NotesIcon}
              onClick={() => {
                setShowSidebar(true);
                setSidebarData(record);
              }}
              style={{
                paddingLeft: 3,
                paddingRight: 3,
                width: '34px',
                height: '34px',
                filter: 'brightness(110%)'
              }}
            />
          </Badge>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Providers',
      dataIndex: 'providers',
      key: 'providers',
      render: (text, record) => {
        let { isLoaderView, currentProviders, restrictedProviders } = record;

        return !isLoaderView ? (
          <PopOverComp
            displayContent={text}
            providerInfo={{ currentProviders, restrictedProviders }}
            popOverType={'clientProviderInfo'}
          />
        ) : (
          <div>{text}</div>
        );
      }
    }
  ];
};

export const CLIENT_ROSTER_STATUS_SELECT = [
  {
    value: true,
    label: 'Active'
  },
  {
    value: false,
    label: 'Inactive'
  }
];
