import React from 'react';
import { LibSelect, LibOption as Option } from 'lib/ui/select/select.style';
import * as PropTypes from 'prop-types';
import { CaretDownOutlined } from '@ant-design/icons';
import { ErrorMessage } from 'formik';

const Select = props => {
  const { field, options, defaultValue, name, ...restProps } = props;
  const optionItems = options.map(({ value, label }) => (
    <Option key={value} value={value}>
      {label}
    </Option>
  ));
  const onChangeValue = value => {
    // because Formik expects normal Event with normal target, not single value
    const event = {
      target: {
        name: field.name,
        value
      }
    };
    field.onChange(event);
    if (restProps.onOptionSelected) restProps.onOptionSelected(value);
  };
  return (
    <>
      <LibSelect
        suffixIcon={<CaretDownOutlined />}
        defaultValue={defaultValue}
        value={field.value}
        getPopupContainer={trigger => trigger.parentNode}
        onChange={onChangeValue}
        {...restProps}
      >
        {optionItems}
      </LibSelect>
      <ErrorMessage name={field.name}>
        {msg => <div className="error">{msg}</div>}
      </ErrorMessage>
    </>
  );
};

Select.propTypes = {
  field: PropTypes.object,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default Select;
