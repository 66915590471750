import React from 'react';
import Banner from 'components/banner';
import { resetAllCalendarVariables } from 'utils/cache/filters';

interface Props {
  message: string;
}

const EventsErrorBanner: React.FC<Props> = ({ message }: Props) => {
  return (
    <Banner
      message={message}
      onClick={() => {
        resetAllCalendarVariables();
      }}
    />
  );
};
export default React.memo(EventsErrorBanner);
