import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { RED } from 'utils/constants/theme';
import styled from 'styled-components';

const StyledErrorMessage = styled.div`
  color: ${RED};
`;
export const FormError = ({ name, errors }) => {
  if (name)
    return (
      <StyledErrorMessage>
        <ErrorMessage
          name={name}
          render={({ message }) => <div className="error">{message}</div>}
          errors={errors}
        />
      </StyledErrorMessage>
    );

  return null;
};
