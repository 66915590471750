import React from 'react';
import { Icon } from 'semantic-ui-react';
import { HeaderWrapper } from './style';

interface IProps {
  title: string;
  onClose: () => void;
  img?: string;
}

const Header: React.FC<IProps> = ({ title, onClose, img }: IProps) => {
  return (
    <HeaderWrapper>
      <label>
        {img && <img className="cardImage" src={img}></img>}
        {title}{' '}
      </label>

      <Icon link name={'close'} onClick={onClose} />
    </HeaderWrapper>
  );
};
export default React.memo(Header);
