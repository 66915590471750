import React from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { ModalBottom, SaveModalContent, SaveModalUpperContent } from '../style';
import { Button } from 'lib/ui';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import Content from 'views/components/ui/content';
import { yupResolver } from '@hookform/resolvers/yup';
import { SAVE_AVAILABILITY_TEMPLATE } from 'utils/validators/availabilityTemplate/saveAvailabilityTemplate';
import { IAvailabilityTemplateForm } from '../UpperModal';

interface IFullAvailabilityTemplateForm extends IAvailabilityTemplateForm {
  default?: string;
}

interface DropdownOptionsInterface {
  id: number;
  name: string;
}

interface IProps {
  data: IFullAvailabilityTemplateForm | undefined;
  onClose(): void;
  onSubmit(data: any): void;
  disableBtn: boolean;
  dropdownOptions: DropdownOptionsInterface[];
}

const SaveModalForm: React.FC<IProps> = ({
  data,
  onClose,
  onSubmit,
  disableBtn,
  dropdownOptions
}) => {
  const methods = useForm<IFullAvailabilityTemplateForm>({
    resolver: yupResolver(SAVE_AVAILABILITY_TEMPLATE(dropdownOptions))
  });
  const submitForm = React.useCallback(
    data => {
      onSubmit(data);
    },
    [onSubmit]
  );

  return (
    <FormProvider {...methods}>
      <SaveModalUpperContent>
        <div className="upper-formItem-wrapper">
          <div className="upper-formItem">
            <p>Department</p>
            <p>{data?.department}</p>
          </div>
          <div className="upper-formItem saveModal">
            <p>Speciality</p>
            <p>{data?.specialityAbr}</p>
          </div>
        </div>
      </SaveModalUpperContent>
      <SaveModalContent>
        <FormItem label="Template Name">
          <p className="error">{methods.errors.templateType?.message}</p>
          <Controller
            name={'templateName'}
            control={methods.control}
            error={methods.errors}
            defaultValue={''}
            render={fieldProps => (
              <input
                className="input-name"
                type="text"
                placeholder="Input Name"
                {...fieldProps}
              ></input>
            )}
          />
        </FormItem>

        <FormItem label="Save as Speciality Default">
          <Controller
            name={'default'}
            control={methods.control}
            error={methods.errors}
            defaultValue={'NO'}
            render={fieldProps => (
              <Select
                options={[
                  { value: 'NO', label: 'NO' },
                  { value: 'YES', label: 'YES' }
                ]}
                field={fieldProps}
                errors={methods.errors}
              />
            )}
          />
        </FormItem>
      </SaveModalContent>

      <ModalBottom>
        <Button
          className="cancel-btn"
          onClick={onClose}
          type=""
          disabled={disableBtn}
        >
          Cancel
        </Button>
        {disableBtn ? (
          <Content loading={disableBtn} data={true} />
        ) : (
          <Button
            className="confirm-btn"
            onClick={methods.handleSubmit(submitForm)}
            disabled={disableBtn}
            type="submit"
          >
            Confirm
          </Button>
        )}
      </ModalBottom>
    </FormProvider>
  );
};

export default SaveModalForm;
