import { Select } from 'semantic-ui-react';
import { FormError } from '../error';

const SelectList = ({ field, errors, ...restProps }: any) => {
  return (
    <>
      {field?.name && <Select {...field} {...restProps} />}
      {field?.name && <FormError name={field.name} errors={errors} />}
    </>
  );
};
export default SelectList;
