import gql from 'graphql-tag';

export const GET_ALL_PROVIDER_TEMPLATES = gql`
  query($providerId: Int!) {
    getProviderTemplatesByProviderId(providerId: $providerId) {
      id
      name
      isDefault
      isActive
      activeTo
      activeFrom
      frequency
      description
    }
  }
`;

export const CHECK_PROVIDER_TEMPLATE_DATES_FOR_COLLISIONS = gql`
  query(
    $startDate: String!
    $endDate: String!
    $frequency: String!
    $providerId: Int!
  ) {
    checkProviderTemplateDatesForCollisions(
      startDate: $startDate
      endDate: $endDate
      frequency: $frequency
      providerId: $providerId
    ) {
      startDate
      providerTemplate {
        id
        name
      }
    }
  }
`;
