import React from 'react';
import { dayDateAppointment, formatEventPopupTime } from 'utils/format';
import { TimeRangeWrapper } from './style';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
interface IProps {
  start: string;
  end: string;
  oneLineFormat?: boolean;
}

const TimeRange: React.FC<IProps> = ({
  start,
  end,
  oneLineFormat = false
}: IProps) => (
  <TimeRangeWrapper>
    {oneLineFormat ? (
      <div className="event-details oneLineFormat">
        <ClockCircleOutlined className="event-details-icon" />
        <label>{`${dayDateAppointment(start)},${formatEventPopupTime(
          start
        )} - ${formatEventPopupTime(end)} `}</label>
      </div>
    ) : (
      <>
        <div className="event-details">
          <CalendarOutlined className="event-details-icon" />
          <label>{dayDateAppointment(start)}</label>
        </div>
        <div className="event-details">
          <ClockCircleOutlined className="event-details-icon" />
          <label>
            {`${formatEventPopupTime(start)} - ${formatEventPopupTime(end)}`}
          </label>
        </div>
      </>
    )}
  </TimeRangeWrapper>
);
export default React.memo(TimeRange);
