import { Controller, useWatch } from 'react-hook-form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import React, { useCallback, useState } from 'react';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { useFormContext } from 'react-hook-form';
import {
  availabilityTemplatesDropdown,
  IModalDropdown
} from 'model/availabilityTemplate';
import { useMemo } from 'react';
import { IProviderSpecialty } from 'model/v2';

export const TEMPLATE_DEFAULT_OPTION = { value: 0, label: 'None' };

export interface IAvailabilityTemplateForm {
  department: string;
  speciality: number;
  specialityAbr: string;
  templateType: number;
  templateName: string;
}

interface IProps {
  data: IAvailabilityTemplateForm | undefined;
  disabled: boolean;
  dropdownOptions: IModalDropdown;
  onTemplateTypeChange: (value: number) => void;
}

const UpperAvailabilityTemplateForm: React.FC<IProps> = ({
  disabled,
  data,
  dropdownOptions,
  onTemplateTypeChange
}) => {
  const { control, errors } = useFormContext();
  const { templateType } = useWatch({
    name: ['templateType'],
    control: control
  });

  const templatesByDeptMap = useMemo(() => {
    const map = new Map();
    dropdownOptions.listDropdownAvailabilities?.forEach(
      availabilityTemplate => {
        if (!map.has(availabilityTemplate.department)) {
          map.set(availabilityTemplate.department, []);
        }
        map.get(availabilityTemplate.department).push(availabilityTemplate);
      }
    );
    return map;
  }, [dropdownOptions]);

  const getSpecialtyOptions = useCallback(specialtyList => {
    return specialtyList?.map((speciality: IProviderSpecialty) => ({
      value: speciality.id,
      label: speciality.abbreviation
    }));
  }, []);

  const getTemplateOptions = useCallback(
    (availabilityList: any) => {
      if (data) availabilityList = templatesByDeptMap.get(data.department);
      const mappedArray = availabilityList?.map(
        (availabilityTemplate: availabilityTemplatesDropdown) => ({
          value: availabilityTemplate.id,
          label: availabilityTemplate.name
        })
      );
      if (data?.templateType && data?.templateName)
        mappedArray.push({
          value: data?.templateType,
          label: data?.templateName
        });
      if (mappedArray) return [TEMPLATE_DEFAULT_OPTION].concat(mappedArray);
      else return [TEMPLATE_DEFAULT_OPTION];
    },
    [data, templatesByDeptMap]
  );

  const [specialtiesOptions, setSpecialtiesOptions] = useState(
    getSpecialtyOptions(dropdownOptions.specialties || [])
  );

  const [templateOptions, setTemplateOptions] = useState(
    getTemplateOptions(dropdownOptions.listDropdownAvailabilities)
  );

  const specialtiesByDeptMap = useMemo(() => {
    const map = new Map();
    dropdownOptions.specialties?.forEach(it => {
      if (!map.has(it.department)) {
        map.set(it.department, []);
      }
      map.get(it.department).push(it);
    });
    return map;
  }, [dropdownOptions]);

  const specialtiesByIdMap = useMemo(() => {
    const map = new Map();
    dropdownOptions.specialties?.forEach(it => {
      map.set(it.id, it);
    });
    return map;
  }, [dropdownOptions]);

  const templatesByIdMap = useMemo(() => {
    const map = new Map();
    dropdownOptions.listDropdownAvailabilities?.forEach(it => {
      map.set(it.id, it);
    });
    return map;
  }, [dropdownOptions]);

  const departments = useMemo(() => {
    return dropdownOptions.specialtiesDepartment.map((department: string) => {
      return {
        value: department,
        label: department
      };
    });
  }, [dropdownOptions]);

  const handleDepartmentChange = useCallback(
    (value: string) => {
      setSpecialtiesOptions(
        getSpecialtyOptions(specialtiesByDeptMap.get(value) || [])
      );
      setTemplateOptions(getTemplateOptions(templatesByDeptMap.get(value)));
      control.setValue('speciality', undefined);
      control.setValue('specialityAbr', '');
      if (
        control.getValues('templateType') !== TEMPLATE_DEFAULT_OPTION.value &&
        value !==
          templatesByIdMap.get(control.getValues('templateType'))?.department
      )
        control.setValue('templateType', TEMPLATE_DEFAULT_OPTION.value);
    },
    [
      getSpecialtyOptions,
      specialtiesByDeptMap,
      getTemplateOptions,
      templatesByDeptMap,
      control,
      templatesByIdMap
    ]
  );

  const handleSpecialtyChange = useCallback(
    (value: number) => {
      const speciality = specialtiesByIdMap.get(value);
      control.setValue('department', speciality?.department);
      control.setValue('specialityAbr', speciality?.abbreviation);
      // to handle initial change to the list
      if (specialtiesOptions.length === dropdownOptions.specialties.length) {
        setSpecialtiesOptions(
          getSpecialtyOptions(
            specialtiesByDeptMap.get(speciality?.department) || []
          )
        );
      }
    },
    [
      specialtiesByIdMap,
      control,
      specialtiesOptions.length,
      dropdownOptions.specialties.length,
      getSpecialtyOptions,
      specialtiesByDeptMap
    ]
  );

  return (
    <>
      <div className="upper-formItem-wrapper">
        <div className="upper-formItem">
          <FormItem label="Department *">
            <Controller
              control={control}
              name={'department'}
              rules={{ required: true }}
              defaultValue={data?.department}
              render={fieldProps => (
                <Select
                  options={departments}
                  placeholder={'Select Department'}
                  onSelect={handleDepartmentChange}
                  field={fieldProps}
                  disabled={disabled}
                  errors={errors}
                />
              )}
            />
          </FormItem>
        </div>

        <div className="upper-formItem">
          <FormItem label="Speciality *">
            <Controller
              control={control}
              name={'speciality'}
              rules={{ required: true }}
              defaultValue={data?.speciality}
              render={fieldProps => (
                <Select
                  options={specialtiesOptions}
                  placeholder={'Select Speciality'}
                  onSelect={handleSpecialtyChange}
                  field={fieldProps}
                  disabled={disabled}
                  errors={errors}
                  listHeight={160}
                />
              )}
            />
            <Controller
              control={control}
              name={'specialityAbr'}
              defaultValue={data?.specialityAbr}
              render={() => <></>}
            />
          </FormItem>
        </div>
      </div>

      <div className="speciality-formItem">
        <FormItem label="Template Type">
          <Controller
            control={control}
            name={'templateType'}
            render={fieldProps => (
              <Select
                options={templateOptions}
                placeholder={'Select Type'}
                onSelect={onTemplateTypeChange}
                value={templateType}
                field={fieldProps}
                errors={errors}
                listHeight={160}
              />
            )}
          />
        </FormItem>
      </div>
    </>
  );
};

export default UpperAvailabilityTemplateForm;
