import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import './index.css';
// eslint-disable-next-line
import App from './App';
import GlobalLoadingIndicator from './GlobalLoadingIndicator';
import AzureAuthenticationApollo from 'authentication-apollo/components/MsalWrapper';
import msalInstance from 'authentication-apollo/msalInit';

ReactDOM.render(
  <AzureAuthenticationApollo pca={msalInstance}>
    {(activeAccount, logout) => {
      return (
        <React.Fragment>
          <GlobalLoadingIndicator />
          <App account={activeAccount} logout={logout} />
        </React.Fragment>
      );
    }}
  </AzureAuthenticationApollo>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
