import EditTimeForm from 'components/AvailabilityTemplateModalForm/EditForm/EditTimeForm';
import { ModalWrapper } from 'components/AvailabilityTemplateModalForm/style';
import { ClientAvailabilityEvent } from 'model/v2/availabilityTemplate';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { mapToLocationsDownOption } from 'utils/mappers/form';
import ModalBottomWithDelete from 'components/AvailabilityTemplateModalForm/ReusableComponents/ModalBottomWithDelete';
import { ILocation } from 'model/v2/location';
import { FrequencyFormWrapper } from 'pages/MainCalendarPage/AppointmentForm/style';
import { OvalCheckboxGroup } from 'lib/ui';
import { WEEK_DAYS } from 'utils/constants/lists';
import { yupResolver } from '@hookform/resolvers/yup';
import { CLIENT_EDIT_AVAILABILITY_BLOCK } from 'utils/validators/clients';
import { Checkbox } from 'semantic-ui-react';

interface IProps {
  onClose(): void;
  onSubmit(data: ITemplateForm, oldEvent: ClientAvailabilityEvent): void;
  onDelete(selectedEvent: ClientAvailabilityEvent): void;
  data: any;
  locations: ILocation[];
  setLocationChecked: Dispatch<SetStateAction<number>>;
  locationChecked: number;
}
interface ITemplateForm {
  start: Date;
  end: Date;
  location: number;
  recurringDays: number[];
}

const EditAvailabilityBlockForm: React.FC<IProps> = ({
  onClose,
  onSubmit,
  onDelete,
  data,
  locations,
  setLocationChecked,
  locationChecked
}) => {
  const dayIndex = data.start.getDay();
  const locationsOptions = useMemo(() => {
    return mapToLocationsDownOption(locations || []);
  }, [locations]);
  const methods = useForm<ITemplateForm>({
    defaultValues: {
      start: data.start,
      end: data.end,
      location: data.location || (locations && locations[0].id),
      recurringDays: [dayIndex]
    },
    resolver: yupResolver(CLIENT_EDIT_AVAILABILITY_BLOCK)
  });

  const recurringDaysHandler = useCallback(
    (val: number[]) => {
      methods.setValue('recurringDays', val);
    },
    [methods]
  );
  const onHandleCheckChange = (id: number, isChecked?: boolean) => {
    if (isChecked) {
      setLocationChecked(locationChecked + id);
    } else {
      setLocationChecked(locationChecked - id);
    }
  };
  const submitForm = useCallback(
    (submittedData: ITemplateForm) => {
      if (locationChecked === 0) locationChecked = locations[0].id!;
      submittedData.location = locationChecked;
      onSubmit(submittedData, data);
    },
    [onSubmit, data, locationChecked]
  );
  return (
    <FormProvider {...methods}>
      <ModalWrapper>
        <div className="upper-formItem-wrapper">
          <div className="upper-formItem">
            <div className="time-formItem">
              <EditTimeForm
                time={data.start}
                controlName={'start'}
                displayName={'Start Time'}
                dayIndex={dayIndex}
              ></EditTimeForm>

              <EditTimeForm
                time={data.end}
                controlName={'end'}
                displayName={'End Time'}
                dayIndex={dayIndex}
              ></EditTimeForm>
            </div>
          </div>
          <div className="upper-formItem">
            <FormItem label="Location">
              <Controller
                control={methods.control}
                name={'location'}
                render={() => (
                  <>
                    {locationsOptions.map((location: any) => {
                      return (
                        <div className="saved_filters" key={location.id}>
                          <Checkbox
                            className="saved-filters_checkbox"
                            label={location.label}
                            name={location.label}
                            value={location.value}
                            checked={(location.value & locationChecked) > 0}
                            onChange={(_e, checkData) =>
                              onHandleCheckChange(
                                location.value!,
                                checkData.checked
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              />
            </FormItem>
          </div>
        </div>
      </ModalWrapper>

      <FrequencyFormWrapper>
        <h3>Apply To</h3>
        <FormItem optional={false} label="Days" className="daysWrapper">
          <Controller
            name="recurringDays"
            control={methods.control}
            render={fieldProps => (
              <OvalCheckboxGroup
                options={WEEK_DAYS}
                field={fieldProps}
                defaultValue={[dayIndex]}
                errors={methods.errors}
                children={null}
                onChange={recurringDaysHandler}
              />
            )}
          />
        </FormItem>
      </FrequencyFormWrapper>

      <ModalBottomWithDelete
        onClose={onClose}
        submitForm={submitForm}
        onDelete={onDelete}
        selectedEvent={data}
      />
    </FormProvider>
  );
};

export default EditAvailabilityBlockForm;
