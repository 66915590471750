import React, { useCallback, useMemo } from 'react';
import { BigCalendar } from 'lib/ui';
import moment from 'moment';
import { localizer } from 'utils/builders/calendar';
import { Views } from 'react-big-calendar';
import { useFormContext } from 'react-hook-form';
import { clientCalendarVar } from '../SideBar/ClientCalendar';
import { MIN_CALENDAR_TIME } from 'utils/constants/calendar';

export const dayHasOpenings = (openings, date) =>
  openings.find(o => {
    let thisDate = moment(date).format('DD MM YYYY');
    let startDate = moment(o.start).format('DD MM YYYY');
    let endDate = moment(o.end).format('DD MM YYYY');
    return thisDate === startDate && thisDate === endDate;
  }) !== undefined;

const OpeningsCalendar = ({
  openings,
  setFieldValue,
  disabledFormFields,
  setOpeningsView,
  availableDays,
  selectedDate
}) => {
  const { setValue } = useFormContext();

  const range = useMemo(
    () => ({
      start: moment(selectedDate)
        .startOf('month')
        .startOf('day')
        .toDate(),
      end: moment(selectedDate)
        .endOf('month')
        .toDate()
    }),
    [selectedDate]
  );

  const customToolbar = useCallback(data => {
    return <></>;
  }, []);

  const customMonthDateHeader = useCallback(
    ({ date, label, onDrillDown }) => {
      const selectDayHandler = (e, date) => {
        if (!disabledFormFields.selectedSlot) {
          onDrillDown(e);
          setValue('selectedDate', new Date(date).toISOString());
          clientCalendarVar(date);
        }
      };

      const hasOpenings = availableDays.current?.has(
        moment(date).format('DD-MM-YYYY')
      );

      const beforeEndDate = range.end
        ? moment(date).isSameOrBefore(range.end)
        : true;

      return (
        <span
          className={hasOpenings && beforeEndDate ? 'has-event' : null}
          onClick={e =>
            hasOpenings && beforeEndDate ? selectDayHandler(e, date) : null
          }
        >
          {label}
        </span>
      );
    },
    [range, setValue, disabledFormFields.selectedSlot, availableDays]
  );

  const handleNavigation = date => {
    if (
      (moment(date).isSameOrAfter(moment(), 'month') ||
        moment(date).isSameOrAfter(moment(selectedDate), 'month')) &&
      !disabledFormFields.selectedSlot
    ) {
      setValue(
        'selectedDate',
        moment(date)
          .startOf('month')
          .startOf('day')
          .toDate()
      );
      setValue('selectedSlot', null);
    }
  };

  const components = useMemo(() => {
    return {
      toolbar: customToolbar,
      month: {
        dateHeader: customMonthDateHeader
      }
    };
  }, [customMonthDateHeader, customToolbar]);

  const { currentDate, availableViews, events } = useMemo(
    () => ({
      currentDate: new Date(selectedDate),
      availableViews: {
        month: true
      },
      events: []
    }),
    [selectedDate]
  );

  return (
    <>
      <BigCalendar
        events={events}
        views={availableViews}
        min={MIN_CALENDAR_TIME}
        defaultView={Views.MONTH}
        components={components}
        date={currentDate}
        onNavigate={handleNavigation}
        localizer={localizer}
        slotEventOverlap={false}
        className="openings-calendar"
      />
    </>
  );
};

export default React.memo(OpeningsCalendar);
export { OpeningsCalendar };
