import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

export const getEventSubsetConflicts = (start, end, values) => {
  if (!values?.selectedSlot || !values?.selectedSlot?.conflicts) return values;

  const { selectedSlot } = values;

  const range = moment.range(start, end);

  const conflicts = selectedSlot.conflicts.filter(({ startTime }) =>
    range.contains(moment(startTime))
  );

  const openingsAvailableNum =
    selectedSlot.totalRecurringAppointments - conflicts.length;

  return {
    ...values,
    selectedSlot: {
      ...selectedSlot,
      conflicts,
      openingsAvailableNum,
      start: moment(start).toDate()
    },
    recurringUntil: moment(end).endOf('day')
  };
};
