import React from 'react';
import { useMutation } from 'react-apollo';
import { updateCalendarPref } from 'utils/cache/calendar';
import { IOption } from 'model';
import moment, { Moment } from 'moment';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';
import Content from 'views/components/ui/content';
import { IEvent, IFormEvent, IMappedEvent } from 'model/v2';
import {
  ADD_EVENTS,
  ADD_EVENT,
  UPDATE_EVENT,
  EDIT_EVENT
} from 'api/graphql/v2/mutations/Event';
import { unset } from 'lodash';
import { useFormContext } from 'react-hook-form';

function calculateCustomRecurringDate(
  startDate: Moment | Date | undefined,
  day: number
) {
  const startDateISO = startDate?.toISOString();
  const current = moment(startDateISO);
  const dayDate = current.day() <= day ? current : current.add(1, 'weeks');
  const newcurrent = moment(dayDate).day(day);
  return newcurrent.utc().toDate();
}

function constructCustomRecurringEvent(
  event: IMappedEvent,
  customDayDate: Date
) {
  let newEvent: IMappedEvent;
  newEvent = event;
  newEvent.startDate = customDayDate.toString();
  newEvent.endDate = moment(customDayDate)
    .add(newEvent.duration, 'minutes')
    .toString();
  unset(newEvent.recurrencePattern, 'recurringDaysArray');
  unset(newEvent.recurrencePattern, 'recurringDays');

  return newEvent;
}

interface Props {
  setVisible: (val: boolean) => void;
  setSaving: (val: boolean) => void;
  recurringOptions: IOption[];
  action: string;
  formData: IFormEvent | null;
  mappedEvent: IMappedEvent[];
  saving: boolean;
  abaObservers?: IEvent[];
  isSeriesAbaEvent?: boolean;
  seriesEventObservations?: IEvent[];
}
const SubmitFooter: React.FC<Props> = ({
  setVisible,
  setSaving,
  mappedEvent,
  formData,
  action,
  recurringOptions,
  saving,
  abaObservers,
  isSeriesAbaEvent,
  seriesEventObservations
}) => {
  const ref = React.useRef(0);
  const [addEvent] = useMutation(ADD_EVENT);
  const [addEvents] = useMutation(ADD_EVENTS);
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [editEvent] = useMutation(EDIT_EVENT);
  const methods = useFormContext();
  const selectedDayBeforeSplit = formData?.selectedDay?.toString();
  const dateSelected = selectedDayBeforeSplit!
    .split(' ')
    .slice(0, 4)
    .join(' ');
  const startDateBeforeReplace = formData?.startDate?.toString();
  const startDateAfterSplit = startDateBeforeReplace!
    .split(' ')
    .slice(0, 4)
    .join(' ');
  const dateReplaced = startDateBeforeReplace?.replace(startDateAfterSplit, '');
  const setHours = parseInt(`${dateReplaced?.slice(1, 3)}`);
  const setMin = parseInt(`${dateReplaced?.slice(4, 6)}`);
  const setsec = parseInt(`${dateReplaced?.slice(7, 9)}`);
  let newSelectedDate = new Date(dateSelected + dateReplaced);
  newSelectedDate.setHours(setHours, setMin, setsec);
  formData!.startDate = newSelectedDate;
  const singleMappedEvent = Array.isArray(mappedEvent)
    ? mappedEvent[0]
    : mappedEvent;

  const successCallbackBehavior = React.useCallback(() => {
    setVisible(false);
    updateCalendarPref(moment(singleMappedEvent.startDate));
    setSaving(false);
  }, [setVisible, mappedEvent, setSaving]);

  const errorCallbackBehavior = React.useCallback(() => {
    setSaving(false);
    setVisible(false);
  }, [setSaving, setVisible]);

  const sendRequest = React.useCallback(async () => {
    let request;

    if (action === SIDEBAR_ACTIONS.NEW) {
      const recurringDays = methods.getValues()?.recurrencePattern
        ?.recurringDaysArray;
      if (recurringDays?.length > 0) {
        recurringDays.forEach((day: number) => {
          if (Array.isArray(mappedEvent)) {
            const mappedCustomEvents: IMappedEvent[] = [];
            mappedEvent.forEach(event => {
              let mappedCustomEvent: IMappedEvent = constructCustomRecurringEvent(
                event,
                calculateCustomRecurringDate(formData?.startDate, day)
              );
              mappedCustomEvents.push(mappedCustomEvent);
            });
            request = addEvents({
              variables: { events: mappedCustomEvents }
            });
          } else {
            const mappedCustomEvent: IMappedEvent = constructCustomRecurringEvent(
              mappedEvent,
              calculateCustomRecurringDate(formData?.startDate, day)
            );
            request = addEvent({
              variables: { event: mappedCustomEvent }
            });
          }
        });
      } else {
        request = addEvents({
          variables: { events: mappedEvent }
        });
      }
    } else if (action === SIDEBAR_ACTIONS.EDIT && formData?.dirtyEdit) {
      let updatedObserversData: IEvent[] = [];
      if (abaObservers && !isSeriesAbaEvent) {
        updatedObserversData = abaObservers;
      } else if (seriesEventObservations && isSeriesAbaEvent) {
        updatedObserversData = [];
        seriesEventObservations.map(observationEvent => {
          observationEvent.observations?.map(observer => {
            updatedObserversData.push(observer);
          });
        });
      }
      request = updateEvent({
        variables: {
          data: {
            recurrent: formData?.recurrent === recurringOptions[1].value,
            event: Array.isArray(mappedEvent) ? singleMappedEvent : mappedEvent,
            cancelNote: formData?.cancelNote,
            cancelReasonId: formData?.cancelReason,
            updatedObserversData: updatedObserversData
          }
        }
      });
    } else {
      let updatedObserversData: IEvent[] = [];
      if (abaObservers && !isSeriesAbaEvent) {
        updatedObserversData = abaObservers;
      } else if (seriesEventObservations && isSeriesAbaEvent) {
        updatedObserversData = [];
        seriesEventObservations.map(observationEvent => {
          observationEvent.observations?.map(observer => {
            updatedObserversData.push(observer);
          });
        });
      }
      request = editEvent({
        variables: {
          data: {
            recurrent: formData?.recurrent === recurringOptions[1].value,
            event: Array.isArray(mappedEvent) ? singleMappedEvent : mappedEvent,
            updatedObserversData: updatedObserversData
          }
        }
      });
    }
    if (request) {
      await request
        .then(() => {
          successCallbackBehavior();
        })
        .catch(() => {
          errorCallbackBehavior();
        });
    }
  }, [
    action,
    formData,
    mappedEvent,
    methods,
    addEvents,
    updateEvent,
    recurringOptions,
    editEvent,
    successCallbackBehavior,
    errorCallbackBehavior
  ]);

  React.useEffect(() => {
    if (ref.current === 0) {
      ref.current += 1;
      sendRequest();
    }
  }, [sendRequest]);
  return <Content loading={saving} data={true} />;
};
export default React.memo(SubmitFooter);
