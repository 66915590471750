import { IStaff } from 'model/user';
import {
  IAppointmentSubType,
  IAppointmentType,
  OpeningCardType
} from 'model/appointment';

import { IAppointment, ITherapist } from 'model/calendar/events';
import { ICancellationReason, IClinic, IRoom } from 'model';
import { ILocation } from 'model/location';
import { Moment } from 'moment';
import { IProvider, IClient, IDriveTimeMileage } from './v2';
import { EVENT_RESCHEDULE_OPTIONS } from 'utils/constants/lists';

export interface IFullEvent {
  event: IEvent;
  isLastEvent: boolean;
  client: IClient;
  provider: IProvider;
  appointmentType: IAppointmentType;
  appointmentSubType: IAppointmentSubType;
  room: IRoom;
  //TODO_PLANNER_MAPPING check this
  // eventType: string;
}

//TODO : check that this is not used anywhere
export interface IEvent {
  observations: any;
  id: string;
  subject: string;
  startTime: Date;
  endTime: Date;
  status: string;
  locationType: string;
  masterID?: string;
  locationCategory?: string;
  appointmentType: IAppointment;
  clinic: IClinic;
  therapist: ITherapist;
  room: IRoom;
  type: string;
  client: IClient;
  recurringEvery: number;
  recurringUntil: string;
  duration: number;
  authorizedTherapyID: string;
  audit?: IAudit[];
  telehealthLink?: string;
  notes?: string;
  locationStreet?: string;
  locationDetails?: ILocation;
  originalStartDate?: Date;
  originalEndDate?: Date;
  crStatus?: number;
  athenaStatus?: number;
  isConverted?: boolean;
  clinicPreference?: string;
  paymentMethod?: string;
  isPendingConfirmation?: boolean;
}

export interface IEditAppointmentPopup {
  clinic?: IClinic;
  room?: IRoom;
  oldRoomName?: string;
  reason?: string;
  notes?: string;
  provider?: IStaff;
  client?: IClient;
  clinicId?: string;
  appointmentName?: string;
  isConflictingProvider?: boolean;
  providerClinicsRoom?: IRoom[];
  cancellationReasons?: ICancellationReason[];
  newStartDate?: string;
  newEndDate?: string;
  oldStartDate?: string;
  oldEndDate?: string;
  isAdminEvent?: boolean;
  type?: number;
  telehealthLink?: string;
  locationCategory?: string;
  address?: ILocation;
  appType?: number;
  driveTimeSourceAddr?: IDriveTimeMileage;
  driveTimeDesteAddr?: IDriveTimeMileage;
  driveTimeMileage?: number;
  isFirstOrLastDriveToNonClinic?: boolean;
  reimbursableMileage?: number;
}

export interface IAudit {
  action: string;
  actionAt: string;
  actionBy: string;
  note?: string;
  reason?: string;
}

export enum EditAppointmentPopup {
  // eslint-disable-next-line
  clinic = 'clinic',
  // eslint-disable-next-line
  room = 'room',
  // eslint-disable-next-line
  reason = 'reason',
  // eslint-disable-next-line
  notes = 'notes',
  // eslint-disable-next-line
  type = 'type',
  // eslint-disable-next-line
  telehealthLink = 'telehealthLink',
  // eslint-disable-next-line
  locationDetails = 'locationDetails'
}

export enum EventRecurringType {
  // eslint-disable-next-line
  single = 'This Event',
  // eslint-disable-next-line
  recurring = 'This and Following Events'
}

export const RescheduleModalType = {
  singleMappedValue: 1,
  recurringMappedValue: 2,
  single: EVENT_RESCHEDULE_OPTIONS(true)[0].label,
  recurring: (count?: number) => EVENT_RESCHEDULE_OPTIONS(true, count)[1].label
};

export enum LocationTypes {
  // eslint-disable-next-line
  inClinic = 'In Clinic',
  // eslint-disable-next-line
  offSite = 'Off-Site',
  // eslint-disable-next-line
  teleHealth = 'Telehealth',
  // eslint-disable-next-line no-unused-vars
  AnyAvailability = 'Any Availability'
}

export enum PlannerExtendDirection {
  // eslint-disable-next-line
  left = 'left',
  // eslint-disable-next-line
  right = 'right'
}

export enum EventStatus {
  // eslint-disable-next-line
  scheduled = 'scheduled',
  // eslint-disable-next-line
  needsReschedule = 'needsReschedule',
  // eslint-disable-next-line
  canceled = 'canceled',
  // eslint-disable-next-line
  rescheduled = 'rescheduled',
  // eslint-disable-next-line
  pending = 'pending'
}

export interface IFormEvent {
  id?: string;
  masterID?: string;
  eventType?: string;
  smart?: boolean;
  appType?: string;
  appSubType?: string;
  isClinical?: boolean;
  provider?: string;
  client?: string;
  clientName?: string;
  clinicId?: string;
  clinicHub?: string;
  clinicPreference?: string;
  room?: string;
  paymentMethod?: string;
  startDate?: Moment;
  recurringUntil?: Moment;
  startTime?: Moment;
  endTime?: Moment;
  duration?: number;
  locationType?: string;
  locationDetails?: ILocation;
  locationCategory?: string;
  telehealthLink?: string;
  scheduleType?: string;
  recurringEvery?: string;
  weekdays?: string[];
  invisible?: boolean;
  notes?: string;
  authorizedTherapyID?: string | null;
  dirtyEdit?: boolean;
  recurrent?: string;
  cancelReason?: string | null;
  cancelNote?: string;
  selectedOpening?: OpeningCardType | null;
  observationsCount?: number;
}

export interface IAvailabilityEvent {
  allDay?: boolean;
  title?: string;
  start?: Date;
  end?: Date;
  resource?: string;
  location?: number;
  htmlTitle?: any;
}

export interface IAvailabilityAudit {
  actionBy: string;
  actionAt: Date;
  actionTime: Date;
}
