import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

interface Props {
  isLoading: boolean;
  active: boolean;
}
export const MainCalendarToolbarWrapper = styled.div<Props>`
  .calendar-nav {
    padding: 18px 16px 17px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: ${props => (props.isLoading ? 0.6 : '')};

    .calendar-nav__left-side {
      display: flex;
      align-items: center;

      &.hidden {
        display: none;
      }
    }
    .ui.selection.dropdown {
      border: 1px solid #c9cfd3;
      transition: unset !important;
      &:hover,
      &.active {
        color: #6f4bf1;
        border: 1px solid #6f4bf1;
      }
      &:hover {
        .menu {
          border: 1px solid #6f4bf1;
          &:hover {
            border: 1px solid #6f4bf1;
          }
        }
      }
      .menu {
        border: 1px solid #6f4bf1;
        top: 31px;
        &:hover {
          border: 1px solid #6f4bf1;
        }
        .item {
          &:hover {
            background-color: #f2f4f5;
          }
        }
      }
    }
    .calendar-nav__today {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 87px;
      height: 32px;
      border-radius: 7px;
      background-color: ${props =>
        props.active
          ? `${colors.PURPLE} !important`
          : `${colors.WHITE} !important`};
      color: ${props =>
        props.active
          ? `${colors.WHITE} !important`
          : `${colors.DARK_GREY} !important`};
      border: 1px solid;
      border-color: ${props => (props.active ? 'transparent' : '#c9cfd3')};
      cursor: ${props => (props.isLoading ? 'not-allowed' : 'pointer')};
    }
    .ui.button {
      transition: all 0.2s ease-in-out;
      &:disabled {
        color: #919699 !important;
        background-color: #e7eaed !important;
        border: none;
        box-shadow: 0 0 0 2px #e7eaed inset !important;
        border-color: #e7eaed !important;
      }
      &:hover {
        background-color: #fff !important;
        color: #6f4bf1 !important;
        border: 1px solid #6f4bf1;
      }
    }

    .calendar-nav__left,
    .calendar-nav__right {
      width: 14px;
      height: 21px;
      color: ${colors.PURPLE};
      cursor: ${props => (props.isLoading ? 'not-allowed ' : 'pointer')};
      &:hover {
        opacity: 0.6;
      }
    }
    .calendar-nav__left {
      margin: 0 15px 0 16px;
    }
    .calendar-nav__title {
      margin: 0 12px 0 20px;
      font-size: 17px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: #415766;
      white-space: nowrap;
    }
    .date-picker-wrapper {
      position: relative;
      .date-picker {
        position: absolute;
        left: 0;
        top: 28px;
        z-index: 1000;
      }
      .calendar-nav__calendarIcon {
        cursor: ${props => (props.isLoading ? 'not-allowed ' : 'pointer')};
        filter: brightness(0) saturate(100%) invert(37%) sepia(64%)
          saturate(6301%) hue-rotate(243deg) brightness(55%) contrast(100%);
        &:hover {
          opacity: 0.7;
        }
      }
      .editingMode {
        filter: brightness(0) saturate(100%) invert(63%) sepia(0%)
          saturate(2030%) hue-rotate(218deg) brightness(97%) contrast(82%);
        pointer-events: none;
      }
    }
    .refresh-icon {
      margin: 2px 0 0 16px;
      color: ${colors.DARK_GREY};
      cursor: ${props => (props.isLoading ? 'not-allowed ' : 'pointer')};
      &:hover {
        opacity: 0.7;
      }
    }

    .customView {
      &.calendar-nav__left-side {
        gap: 5px;
      }
      .calendar-nav__left {
        margin: 0;
      }
      .calendar-nav__title {
        margin: 0 5px;
      }
    }

    .disabled {
      pointer-events: none;
      opacity: 50%;
    }
  }
  .tz-alert {
    color: red;
    &.top {
      margin: 0em;
    }
    &.footer {
      padding: 1em 0;
    }
  }
`;
