import { IABAAppointmentSubType } from 'model/abaAppointmentSubtype';
import { IDTAppointmentSubType } from 'model/DTAppointmentSubType';
import { IMEDAppointmentSubType } from 'model/MEDAppointmentSubtype';

export const formatABASubTypeToInput = (
  data: IABAAppointmentSubType,
  initialValues: IABAAppointmentSubType
) => {
  return {
    id: Number(initialValues?.id),
    title: data.title,
    crAuthCode: Number(data.crAuthCode?.id),
    placeOfServiceId: Number(data.placeOfService?.id),
    crType: data.crType,
    appointmentType: {
      id: Number(data.superType?.id)
    }
  };
};

export const formatDTSubTypeToInput = (
  data: IDTAppointmentSubType,
  initialValues: IDTAppointmentSubType
) => {
  return {
    id: Number(initialValues?.DTSettingId),
    athenaId: Number(data.athenaId),
    crName: data.crName,
    crType: data.crType,
    duration: data.duration,
    appointmentType: {
      id: Number(initialValues?.id),
      title: data.title,
      parent: { id: Number(data.superType.id) },
      eventType: { id: 2 },
      salesForceTherapyMappings: {
        id: data.salesForceMapping?.id
      }
    }
  };
};

export const formatMEDSubTypeToInput = (
  data: IMEDAppointmentSubType,
  initialValues: IMEDAppointmentSubType
) => {
  return {
    id: Number(initialValues?.MEDSettingId),
    athenaId: Number(data.athenaId),
    crName: data.crName,
    crType: data.crType,
    duration: data.duration,
    appointmentType: {
      id: Number(initialValues?.id),
      title: data.title,
      parent: { id: Number(data.superType.id) },
      eventType: { id: 4 }
    }
  };
};
