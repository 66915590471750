import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { IClinic } from 'model/v2';

interface Props {
  clinics: IClinic[];
  checkedItems: Set<Number>;
  onHandleChange: (id: number, isChecked?: boolean) => void;
}

const ClinicItems = ({ clinics, checkedItems, onHandleChange }: Props) => {
  return (
    <>
      {clinics?.length > 0 && (
        <>
          {clinics.map(clinic => {
            return (
              <div className="saved_filters" key={clinic.id}>
                <Checkbox
                  className="saved-filters_checkbox"
                  label={`${clinic.name}, ${clinic.abbreviation}`}
                  name={clinic.name}
                  value={clinic.id}
                  checked={checkedItems.has(clinic.id || 0)}
                  onChange={(_e, data) =>
                    onHandleChange(clinic.id!, data.checked)
                  }
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export default React.memo(ClinicItems);
