import React from 'react';
import moment from 'moment-timezone';
import { Button } from 'lib/ui';
import { ConflictsForSingleSeries, STEPS } from '../utils';
import { IMappedEvent } from 'model/v2';

interface Props {
  conflictsPerSeries: ConflictsForSingleSeries[];
  setStep: (val: number) => void;
  backToForm: () => void;
  mappedSmartEvents: IMappedEvent[];
}
const SmartConflictsFooter: React.FC<Props> = ({
  conflictsPerSeries,
  backToForm,
  setStep
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        borderTop: '1px solid #d8d8d8',
        paddingTop: '8px'
      }}
    >
      <h2>Warning</h2>
      {conflictsPerSeries.map((seriesConflicts, seriesIndex) => {
        const singleConflicts = seriesConflicts.filter(
          conflict => conflict.count === 1
        );
        const singleConflictSummary = singleConflicts.length
          ? `${singleConflicts.length} single conflict(s)`
          : ``;

        const recurringConflicts = seriesConflicts.filter(
          conflict => conflict.count > 1
        );
        const recurrPrefixPendingSingleConflicts = singleConflictSummary.length
          ? ' and '
          : '';
        const recurringConflictSummary = recurringConflicts.length
          ? `${recurrPrefixPendingSingleConflicts}${recurringConflicts.length} recurring conflict(s)`
          : ``;

        return (
          <div>
            <hr />
            <span>{`Selected series #${seriesIndex +
              1} (counted from top) contains ${singleConflictSummary}${recurringConflictSummary}.`}</span>
            {singleConflicts.map(singleConflict => {
              const {
                conflictAppointmentTitle: title,
                locationType,
                providerName,
                startDate
              } = singleConflict.earliestConflict;
              const startDateHack = moment
                .utc(startDate)
                .tz('America/Los_Angeles')
                .format('MM/DD/YYYY');
              return (
                <p>{`Single Conflict: ${title}${
                  locationType ? ` at ${locationType}` : ''
                } with ${providerName} on ${startDateHack}`}</p>
              );
            })}
            {recurringConflicts.map(recurringConflict => {
              const {
                conflictAppointmentTitle: title,
                locationType,
                providerName,
                startDate
              } = recurringConflict.earliestConflict;
              const startDateHack = moment
                .utc(startDate)
                .tz('America/Los_Angeles')
                .format('MM/DD/YYYY');
              return (
                <p>{`Recurring Conflict: ${title} ${
                  locationType ? ` at ${locationType}` : ''
                } with ${providerName} from ${startDateHack} for ${
                  recurringConflict.count
                } week(s)`}</p>
              );
            })}
          </div>
        );
      })}
      {/* <SliderItems events={conflicts} isSidebar={true} /> */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '15px',
          marginBottom: '10px'
        }}
      >
        <Button className="secondary" type="" onClick={backToForm}>
          Back To Form
        </Button>
        <Button
          className="secondary"
          type=""
          onClick={() => {
            setStep(STEPS.SUBMIT);
          }}
        >
          Keep on Schedule
        </Button>
      </div>
    </div>
  );
};
export default React.memo(SmartConflictsFooter);
