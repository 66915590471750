import { useReactiveVar } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import PopupActions from 'components/popupActions';
import {
  EditAppointmentPopup,
  RescheduleModalType,
  IEditAppointmentPopup
} from 'model';
import React, { useMemo, useContext, useState, useEffect } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { ICalendarEvent } from 'model/calendar/events';
import { ICalendarResource } from 'model/calendar/filters';
import { mainCalendarPrefDayVar, PlannerView } from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  providerResourcesPrevVar
} from 'utils/cache/filters';
import { ADMIN_DEFAULT_CANCEL_REASON } from 'utils/constants/lists';
import EventTypeRadioGroup from './EventTypeRadioGroup';
import { ContentWrapper } from './style';
import TimeRange from './TimeRange';
import { mapAdminEventToBeRescheduled } from 'utils/mappers/calendar';
import { UPDATE_EVENT } from 'api/graphql/v2/mutations/Event';
import { IFullEvent } from 'model/v2';
import { prepareEventsAfterReschedule } from 'utils/mappers/response/eventsV2';
import { plannerContext } from 'pages/MainCalendarPage';
import moment from 'moment';
import DriveTimeMileage from 'pages/MainCalendarPage/AppointmentForm/DriveTime/DriveTimeMileage';
import { DRIVE_TIME_APPT_IDS } from 'utils/constants/appointmentsTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { initDriveTimeValues } from 'pages/MainCalendarPage/AppointmentSidebar';
import { NEW_DRIVE_TIME_EDIT_SCHEMA } from 'utils/validators/calendar';
import { useLazyQuery } from 'react-apollo';
import {
  FETCH_DRIVE_TIME_ADDRESS,
  GET_SEARCH_ADDRESS
} from 'api/graphql/v2/queries/Events';
import RescheduleMileageWarning from 'pages/MainCalendarPage/AppointmentForm/Footer/RescheduleMileageWarning';
interface IProps {
  fullEvent: IFullEvent;
  newStartDate?: string;
  newEndDate?: string;
  oldStartDate?: string;
  oldEndDate?: string;
  view: PlannerView;
  onClose: () => void;
  onCancel: () => void;
  recurringEventsCount: number;
  isSeries: boolean;
}
const AdminForm: React.FC<IProps> = ({
  fullEvent,
  newStartDate,
  newEndDate,
  oldStartDate,
  oldEndDate,
  view,
  onClose,
  onCancel,
  recurringEventsCount,
  isSeries
}: IProps) => {
  const [selectedRadioButton, setSelectedRadioButton] = useState(1);
  const radioButtonStatus = (index: number) => {
    setSelectedRadioButton(index);
    console.log(selectedRadioButton);
  };
  const events = useReactiveVar<ICalendarEvent[]>(calendarEventsPrefVar);
  const providers = useReactiveVar<ICalendarResource[]>(
    providerResourcesPrevVar
  );
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const [isAddressFetched, setIsAddressFetched] = useState<boolean>(false);
  const [startingAddrList, setStartingAddrList] = useState<any>();
  const [destAddrList, setDestAddrList] = useState<any>();

  const isConverted = fullEvent.isConverted;
  const { handleDateSelect } = useContext(plannerContext);
  const { event, provider } = useMemo(() => {
    return {
      event: fullEvent.event,
      provider: fullEvent.event.provider
    };
  }, [fullEvent]);
  const methods = useForm({
    defaultValues: React.useMemo(() => {
      return initDriveTimeValues(event);
    }, [event]),
    resolver: yupResolver(NEW_DRIVE_TIME_EDIT_SCHEMA),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });
  const [doStartingAddrRequest, { data: startAddressList }] = useLazyQuery(
    GET_SEARCH_ADDRESS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setStartingAddrList(startAddressList?.searchAddress);
        methods.setValue('drivetime-source-address-search-in-progress', false);
      }
    }
  );

  const [doDestAddrRequest, { data: destAddressList }] = useLazyQuery(
    GET_SEARCH_ADDRESS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setDestAddrList(destAddressList?.searchAddress);
        methods.setValue(
          'drivetime-destination-address-search-in-progress',
          false
        );
      }
    }
  );
  const mileage: number | undefined = useWatch({
    name: 'driveTimeMileage',
    control: methods.control
  });

  const awaitingRHFSetSourceAddress = useWatch({
    name: 'drivetime-source-address-search-in-progress',
    control: methods.control
  });
  const awaitingRHFSetDestinationAddress = useWatch({
    name: 'drivetime-destination-address-search-in-progress',
    control: methods.control
  });
  const awaitingMileageCalculation = useWatch({
    name: 'mileage-request-in-progress',
    control: methods.control
  });
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const [updateEvent] = useMutation(UPDATE_EVENT);

  const onConfirm = handleSubmit(async (data: IEditAppointmentPopup) => {
    const eventData = mapAdminEventToBeRescheduled(
      event,
      provider,
      newStartDate,
      newEndDate,
      data,
      startingAddrList,
      destAddrList,
      driveTimeAddress
    );

    const submittedData = {
      event: eventData,
      recurrent: data.type === RescheduleModalType.recurringMappedValue,
      cancelNote: data.notes,
      cancelReasonId: ADMIN_DEFAULT_CANCEL_REASON
    };

    await updateEvent({
      variables: {
        data: submittedData
      }
    })
      .then((data: any) => {
        const updatedEvent = data.data.updateEvent;
        if (updatedEvent) {
          const currentEvents = prepareEventsAfterReschedule(
            updatedEvent,
            events,
            providers,
            undefined,
            view,
            calendarDate
          );
          calendarEventsPrefVar(currentEvents);
          // console.log('CalendarEventsPrefVar in AdminForm.tsx', currentEvents);
          // console.log(
          //   'CalendarEventsPrefVar in AdminForm.tsx',
          //   calendarEventsPrefVar
          // );
          handleDateSelect(moment(calendarDate).toDate());
          onClose();
        }
      })
      .catch(error => {
        console.log('Error while updating event', JSON.stringify(error));
        onCancel();
      });
  });

  const [
    getDriveTimeAddress,
    { loading: adminAddressisFetch, data: driveTimeAddress }
  ] = useLazyQuery(FETCH_DRIVE_TIME_ADDRESS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data?.getDriveEventAddress?.startingAddress === null) {
        methods.setValue('drivetime-source-address-search-in-progress', false);
      }
      if (data?.getDriveEventAddress?.destinationAddress === null) {
        methods.setValue(
          'drivetime-destination-address-search-in-progress',
          false
        );
      }
    }
  });

  useEffect(() => {
    const ifDriveTimeAppt = DRIVE_TIME_APPT_IDS[
      process.env.REACT_APP_STAGE!
    ].includes(event?.appointmentType?.id!);
    if (ifDriveTimeAppt) {
      getDriveTimeAddress({
        variables: {
          startDate: moment(newStartDate),
          endDate: moment(newEndDate),
          providerId: event?.provider?.id
        }
      });
      methods.setValue('drivetime-source-address-search-in-progress', true);
      methods.setValue(
        'drivetime-destination-address-search-in-progress',
        true
      );
    }
  }, [newStartDate, newEndDate, event, getDriveTimeAddress]);

  useEffect(() => {
    if (driveTimeAddress) {
      doStartingAddrRequest({
        variables: {
          querySearch: driveTimeAddress?.getDriveEventAddress.startingAddress?.trim()
        }
      });

      doDestAddrRequest({
        variables: {
          querySearch: driveTimeAddress?.getDriveEventAddress.destinationAddress?.trim()
        }
      });
      setIsAddressFetched(!isAddressFetched);
    }
  }, [driveTimeAddress]);
  return (
    <ContentWrapper>
      <FormProvider {...methods}>
        <Grid columns={2}>
          <Grid.Row>
            <label className="title">
              {event?.appointmentType?.parent
                ? event?.appointmentType?.parent.title
                : event?.appointmentType?.title}
            </label>
          </Grid.Row>

          <Grid.Row>
            <div className="name-wrapper">
              <Link
                className="name"
                target="_blank"
                to={`/providers/${provider?.id}`}
              >
                {`${provider?.displayName}, ${provider?.speciality?.abbreviation} (${provider?.clinic?.abbreviation})`}
              </Link>
            </div>
          </Grid.Row>

          <Grid.Row className="date">
            <TimeRange start={newStartDate!} end={newEndDate!} />
          </Grid.Row>

          {isSeries && (
            <Grid.Row className="radio-btns">
              <Controller
                name={EditAppointmentPopup.type}
                render={() => (
                  <EventTypeRadioGroup
                    methods={methods}
                    recurringEventsCount={recurringEventsCount}
                    radioButtonStatus={radioButtonStatus}
                    observersCount={0}
                  />
                )}
              />
            </Grid.Row>
          )}

          <Grid.Row>
            <label className="title">Previously</label>
          </Grid.Row>

          <Grid.Row>
            <TimeRange start={oldStartDate!} end={oldEndDate!} />
          </Grid.Row>

          {DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!].includes(
            event?.appointmentType?.id!
          ) && (
            <>
              <Grid.Row>
                <DriveTimeMileage
                  pageName="adminForm"
                  driveTimeAddress={driveTimeAddress}
                  adminAddressisFetch={adminAddressisFetch}
                  isAddressFetched={isAddressFetched}
                />
              </Grid.Row>
              <Grid.Row>
                {mileage !== undefined && mileage > 50 && (
                  <RescheduleMileageWarning />
                )}
              </Grid.Row>
            </>
          )}
          {isConverted && (
            <Grid.Row className="name-wrapper">
              <p style={{ color: '#e82424', marginTop: '20px' }}>
                This event is converted in CentralReach and it cannot be
                rescheduled.
              </p>
            </Grid.Row>
          )}
          <Grid.Row className="modal-actions">
            <PopupActions
              disabled={
                isSubmitting ||
                isConverted! ||
                !!awaitingRHFSetDestinationAddress ||
                !!awaitingRHFSetSourceAddress ||
                !!awaitingMileageCalculation
              }
              loading={isSubmitting}
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          </Grid.Row>
        </Grid>
      </FormProvider>
    </ContentWrapper>
  );
};

export default React.memo(AdminForm);
