import * as colors from 'utils/constants/theme';
import styled from 'styled-components';

export const BulkCancelModalWrapper = styled.div`
  border-top: 10px solid #db2828;
  border-radius: 5px;
  .cancel-modal-title {
    font-family: Lato;
    padding: 22px 32px 0 32px;
    margin: 0 0 10px 0;
    opacity: 0.9;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    color: #3e5766;
  }
  .cancel-modal-text {
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-left: 32px;
    color: #3e5766;
  }

  .selected-events-num {
    color: red;
  }
  /* RADIO GROUP */
  .radio-group-wrapper {
    padding: 0 32px;
    margin-bottom: 8px;
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      .ant-radio-wrapper {
        margin: 0 0 16px 0;
        font-size: 15px;
        color: #21303a;
        .ant-radio-checked {
          .ant-radio-inner {
            border: 1px solid red;
            &:after {
              border: 1px solid #db2828;
              background: #db2828;
            }
          }
        }
      }
    }
  }

  /* SELECT REASON DROPDOWN*/
  .select-reason {
    padding: 0 32px;
    margin: 0 0 16px 0;
    .ant-select {
      width: 360px;
      .ant-select-selector {
        border-radius: 5px;
      }
    }
    .error {
      margin-top: 5px;
    }
  }

  /* ADD NOTE TEXT AREA */
  .add-note {
    margin-bottom: 20px;
    padding: 0 32px;
    & > div {
      width: 360px;
      .ant-input {
        border: 1px solid #c9cfd3;
        border-radius: 5px;
        min-height: 56px;
        max-height: 150px;
        padding: 9px;
        resize: vertical;
        &:focus {
          box-shadow: none;
          border: 1px solid #6f4bf1;
        }
      }
    }
  }

  /* MODAL ACTIONS */
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding: 0 32px;
    button {
      margin-left: 12px;
    }
  }

  .two-column {
    margin-left: 32px;
    margin-bottom: 21px;
    display: flex;
    flex: column;
    background: #c9cfd3;
    border-radius: 6px;
    width: 360px;
    height: 32px;
    padding: 4px 24px 4px 12px;
  }
  .reason {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    color: #ffffff;
  }
  .remove-btn {
    width: 12px;
    height: 12px;

    margin: 0px -16px 0px 0px;
  }
`;

export const BulkCancelPopupWrapper = styled.div`
  .cancel-popup-segment {
    zindex: 1000 !important;
    border-top: 10px solid ${colors.PRIMARY_PURPLE} !important;
    display: flex;
    align-items: center;
  }
  .ui.segment {
    position: absolute;
    bottom: 34px;
    right: 34px;
    max-width: 500px;
  }

  button {
    max-width: 94px;
    padding: 9px 14px 9px 13px !important;
    white-space: nowrap;
  }
  p {
    span {
      color: red;
    }
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: #3e5766;
    margin-right: 15px;
  }
`;
