// Custom tag rendering function
const tagRender = ({ label, closable, onClose }) => {
  return (
    <span
      style={{
        display: 'inline-block',
        margin: '2px',
        padding: '2px 6px',
        border: '1px solid #d9d9d9',
        borderRadius: '2px',
        backgroundColor: '#e6f7ff'
      }}
    >
      {label}
    </span>
  );
};

export default tagRender;
