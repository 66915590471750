import React from 'react';
import { Row } from 'antd';
import { formatBusinessHours } from 'utils/format/clinic';

const BusinessHours = ({ businessHours }) => (
  <div className="clinic-hours noedit">
    <Row
      className="day-row clinic-hours__topbar"
      type="flex"
      justify="start"
      gutter={16}
    >
      <span className="day-row__name"></span>
      <span className="day-row__open-hours">Open</span>
      <span className="day-row__closed-hours">Close</span>
    </Row>
    {formatBusinessHours(businessHours).map(workingDay => (
      <DayRow
        workingDay={workingDay}
        key={workingDay.day}
        index={workingDay.day}
      />
    ))}
  </div>
);

const DayRow = ({ workingDay }) => (
  <Row className="day-row" type="flex" justify="start" gutter={16}>
    <div className="day-row__name">{workingDay.day}</div>
    {workingDay.startTime ? (
      <>
        <div className="day-row__open-hours">
          <p>{workingDay.startTime}</p>
        </div>
        <div className="day-row__closed-hours">
          <p>{workingDay.endTime}</p>
        </div>
      </>
    ) : (
      <div className="day-row__closed-allDay">Closed all day</div>
    )}
  </Row>
);

export default BusinessHours;
export { BusinessHours };
