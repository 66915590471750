import { ITableColumn } from 'views/components/table/type';
import { TableType } from 'model/tableType';
import {
  AvailabilityTemplate,
  IAvailabilityTemplate
} from 'model/availabilityTemplate';
import { ISpecialty } from 'model/specialty';

export const AVAILABILITY_TEMPLATE_COLUMNS: ITableColumn[] = [
  {
    label: 'Template Department',
    key: AvailabilityTemplate.department,
    notWrap: true,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, AvailabilityTemplate.department),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, AvailabilityTemplate.department)
    }
  },
  {
    label: 'Template Speciality',
    key: AvailabilityTemplate.speciality,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, AvailabilityTemplate.speciality),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, AvailabilityTemplate.speciality)
    }
  },
  {
    label: 'Template Name',
    key: AvailabilityTemplate.name,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, AvailabilityTemplate.name),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, AvailabilityTemplate.name)
    }
  },
  {
    label: 'Create Date',
    key: AvailabilityTemplate.createdAt,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, AvailabilityTemplate.createdAt),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, AvailabilityTemplate.createdAt)
    }
  },
  {
    label: 'Created By',
    key: AvailabilityTemplate.createdBy,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, AvailabilityTemplate.createdBy),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, AvailabilityTemplate.createdBy)
    }
  },
  {
    label: 'Action',
    key: AvailabilityTemplate.isActive
  }
];

const sort = (a: TableType, b: TableType, sortField: AvailabilityTemplate) => {
  let valueA = (a as IAvailabilityTemplate)[sortField];
  let valueB = (b as IAvailabilityTemplate)[sortField];
  if (sortField === AvailabilityTemplate.speciality) {
    valueA = (valueA as ISpecialty).abbreviation;
    valueB = (valueB as ISpecialty).abbreviation;
  }
  return valueA!.toString().toLowerCase() > valueB!.toString().toLowerCase()
    ? -1
    : 1;
};
