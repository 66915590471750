import { Button } from 'lib/ui';
import { CalenderEvent } from 'model/v2/availabilityTemplate';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ModalBottomWithDeleteWrapper } from '../../style';

interface IProps {
  onClose(): void;
  submitForm(data: any): void;
  onDelete?(selectedEvent: CalenderEvent): void;
  selectedEvent: CalenderEvent;
  deleteAvail?: boolean | undefined;
}

const ModalBottomWithDelete: React.FC<IProps> = ({
  onClose,
  submitForm,
  onDelete,
  selectedEvent,
  deleteAvail
}) => {
  const methods = useFormContext();
  return (
    <ModalBottomWithDeleteWrapper>
      {onDelete ? (
        <Button
          className="delete-btn"
          onClick={() => onDelete(selectedEvent)}
          type=""
        >
          Delete
        </Button>
      ) : (
        ''
      )}
      <div className={deleteAvail ? `Modal-Final-Sub` : ''}>
        <Button className="cancel-btn" onClick={onClose} type="">
          Cancel
        </Button>
        <Button
          className="confirm-btn"
          onClick={
            deleteAvail
              ? () => submitForm(selectedEvent)
              : methods.handleSubmit(submitForm)
          }
          type="submit"
        >
          Confirm
        </Button>
      </div>
    </ModalBottomWithDeleteWrapper>
  );
};

export default ModalBottomWithDelete;
