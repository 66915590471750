import { IProvider, IProviderSingle } from './providers';
import { Moment } from 'moment';
import { IFullAppointmentType } from '.';
import { IAddress } from './address';
import { IClient } from './clients';
import { IClinic, IRoom } from './clinic';
import { EventType, Status } from 'model/calendar/events';
import { LocationType } from 'views/components/icons/eventIcons/locationIcon';

export interface IRecurrencePattern {
  recurringType?: string;
  recurringEvery?: number;
  recurringDays?: number;
  recurringUntil?: Date | Moment;
  recurringDaysArray?: number[];
}

export interface INote {
  id?: number;
  note?: string;
}

export interface IEventAudit {
  id?: number;
  action?: string;
  actionBy?: string;
  actionAt?: Date;
  reason?: string;
  note?: string;
}
export interface IEvent {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  athenaId?: number;
  athenaStatus?: number;
  crId?: number;
  crExecutionId?: string;
  crStatus?: number;
  clinicPreference?: string;
  instanceIndex?: number;
  isPendingConfirmation?: boolean;
  startDate?: Date | Moment;
  endDate?: Date | Moment;
  originalStartDate?: Date | Moment;
  originalEndDate?: Date | Moment;
  duration?: number;
  locationType?: LocationType;
  locationCategory?: string;
  paymentMethod?: string;
  status?: Status;
  subject?: string;
  telehealthLink?: string;
  note?: INote;
  address?: IAddress;
  eventAudit?: IEventAudit[];
  recurrencePattern?: IRecurrencePattern;
  appointmentType?: IFullAppointmentType;
  client?: IClient;
  provider?: IProvider;
  clinic?: IClinic;
  room?: IRoom;
  master?: IEvent;
  type?: EventType;
  isOriginal?: boolean;
  leadEvent?: IEvent;
  isObservation?: boolean;
  syncAction?: IEventSyncAction;
  observationsCount?: number;
  observations?: IEvent[];
  isDelete?: boolean;
  driveTimeSourceAddr?: IDriveTimeMileage;
  driveTimeDesteAddr?: IDriveTimeMileage;
  driveTimeMileage?: number;
  isFirstOrLastDriveToNonClinic?: boolean;
  reimbursableMileage?: number;
}

export enum IEventSyncStatus {
  NO_SYNC = 0,
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3
}
export interface IDriveTimeMileage {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  freeformAddress?: string;
  postalCode?: string;
  lat?: string;
  lon?: string;
}
export interface IEventSyncAction {
  retryCount: number;
  failureReason?: string;
}

export interface IMappedEvent {
  id?: number;
  clinicPreference: string;
  isPendingConfirmation: boolean;
  startDate: any;
  endDate: any;
  duration: number;
  locationType?: LocationType;
  locationCategory?: string;
  paymentMethod: string;
  telehealthLink?: string;
  note?: INote;
  address?: IAddress;
  recurrencePattern: any;
  appointmentType: IFullAppointmentType;
  client: IClient;
  provider: IProvider;
  clinic: IClinic;
  room?: IRoom;
  master?: IEvent;
  driveTimeSourceAddr?: IDriveTimeMileage;
  driveTimeDesteAddr?: IDriveTimeMileage;
  driveTimeMileage?: number;
  isFirstOrLastDriveToNonClinic?: boolean;
  reimbursableMileage?: number;
}

export interface IFormEvent extends IEvent {
  recurringDaysFlag?: boolean;
  recurrent?: string;
  selectedDay?: Moment;
  smart?: boolean;
  dirtyEdit?: boolean;
  cancelReason?: string | null;
  cancelNote?: string;
  selectedOpening?: IOpeningCardType | null;
  scheduleType?: string;
  invisible?: boolean;
  appType?: number;
  appSubType?: number;
  eventTypeName?: string;
  apptCategory?: string;
  apptCategoryType?: string;
  appTypeLabel?: string | null;
  appointmentTypeId?: number;
  driveTimeSourceAddr?: IDriveTimeMileage;
  driveTimeDesteAddr?: IDriveTimeMileage;
  driveTimeMileage?: number;
  isFirstOrLastDriveToNonClinic?: boolean;
  reimbursableMileage?: number;
  isDTAppt?: Boolean | undefined;
  isABAAppt?: Boolean | undefined;
  no_of_session?: any;
  subType_Id?: number | undefined;
  parent_Type_ID?: number | undefined;
}

export interface IFullEvent {
  event: IEvent;
  isConverted?: boolean;
  isLastEventInSeries?: boolean;
}
export interface IFullObserverEvent {
  observerEvent: IEvent;
  isConverted?: boolean;
}
export interface IOpeningCardType {
  id: string;
  start: Date;
  end: Date;
  provider?: IProvider;
  clinic?: IClinic;
  appointmentType?: IFullAppointmentType;
  recurrencePattern?: IRecurrencePattern;
  eventsCount?: number;
  freeCount?: number;
  conflicts?: IConflict[];
}

export interface IOpening {
  id: string;
  startDate: Date;
  endDate: Date;
}

export interface IProviderOpenings {
  providerId: string;
  openings: IOpening[];
}

export interface IEventConflicts {
  eventsCount?: number;
  conflicts?: IConflict[];
  warnings?: IWarning[];
}

export interface IWarning {
  startDate: string;
  authorizedTherapyID: string;
}

export interface IConflict {
  startDate?: string;
  endDate?: string;
  conflictAppointmentTitle?: string;
  locationType?: string;
  locationCategory?: string;
  masterId?: number;
  providerName?: string;
}

export interface IConflictMapped {
  title?: string;
  startDate?: string;
  endDate?: string;
  locationType?: string;
  appType?: string;
  category?: string;
}

// this is temporary
export interface SidebarState {
  event?: IFormEvent;
  action: string;
  isRedirected?: boolean;
}

export interface ISmartForm extends IFormEvent {
  aba?: ISmartABA;
  dt?: ISmartDT;
  apptCategory?: string;
  apptCategoryType?: string;
  preferredDays?: number[];
  preferredLocation?: number[];
  preferredTime?: { from: string; to: string }[];
  startTimeCustom?: Moment;
  endTimeCustom?: Moment;
  isIgnoreClientProfileAvailability?: boolean;
  providerFilter?: IProviderFilter;
  clientAvailabilityDayFilter?: IClientAvailabilityFilter[];
  clinicFilter?: {
    clinicIds: number[];
    applyClinicFilterToTelehealth?: boolean;
  };
  locationFilter?: {
    isInClinic: boolean;
    isOffsite: boolean;
    isTelehealth: boolean;
  };
}

export interface IClientAvailabilityFilter {
  dayIndex?: number;
  preferredTimes?: { from: string; to: string; location?: number }[];
}

export interface IProviderFilter {
  clinicType?: number;
  preferredProviders?: number[];
  preferredSpecialties?: number[];
}
export interface ISmartABA {
  sessionDuration: {
    min: number;
    max: number;
  };
  offsiteWeekly?: string | undefined;
  inClinicWeekly?: string | undefined;
  clientFrequencyPreference: string;
  maximumProviders: number;
  clientDailyHours: number;
  paymentMethod?: string;
  singleSessionDuration?: string | undefined;
}

export interface ISmartDT {
  sessionDuration?: number | undefined;
  weeklySessions: number;
  clientFrequencyPreference: string;
  maximumProviders: number;
  clientDailyHours: number;
}

export interface ISmartResultListForm {
  results: ISmartResultItemForm[];
}

export interface ISmartResultItemForm extends ISmartResultCard {
  apptTypeId?: number | undefined;
  index: number;
  isChecked: boolean;
  isInVisible: boolean;
  room?: number | null;
  telehealthLink?: string;
  locationCategory?: string | null;
  address?: IAddress | null;
  client: IClient;
  paymentMethod: string;
  startTimeForm?: number;
  endTimeForm?: number;
  appSubType?: number;
  driveTimeCheck?: boolean;
  driveTime?: number;
}

export interface iSmartSingleResultForm {
  result: SingleSmartResultInterface;
}

export interface SingleSmartResultInterface {
  isInVisible: boolean;
  room?: number | null;
  telehealthLink?: string;
  locationCategory?: string | null;
  address?: number | null;
  client: IClient;
  timeSlot: TimeSlot;
  clinic: IClinic;
  isInClinic?: boolean;
  isOffsite?: boolean;
  isTelehealth?: boolean;
  startTime: string;
  endTime: string;
  provider: IProviderSingle;
}

export interface TimeSlot {
  startTime: string;
  endTime: string;
  adjacentType: number;
}
export interface HydratedTimeSlot {
  timeSlot: TimeSlot;
  clientFullyAvailable: boolean;
  effectiveStartDate: string;
  previousAdjacentEndDate: Date;
  trailingAdjacentEndDate: Date;
}
export interface ISmartScheduleOpenings {
  openingCards: ISmartResultCard[];
  clientTimezone?: string;
  bookedDays: number;
  bookedProviders: number;
  totalCareplan: number;
}
export interface TimeSlots {
  timeSlot: {
    startTime: string;
    endTime: string;
    adjacentType: number;
  };
  clientFullyAvailable: boolean;
  effectiveStartDate: string;
  previousAdjacentEndDate: Date;
  trailingAdjacentEndDate: Date;
}

export interface TimeRange {
  effectiveStartDate: string;
  fullProviderRanges: TimeSlot[];
  clientAvailableRanges: TimeSlot[];
}

export interface ISmartResultCard {
  timeSlots?: TimeSlots[];
  timeRanges?: TimeRange[];
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  isInClinic?: boolean;
  isOffsite?: boolean;
  isTelehealth?: boolean;
  allowedLocations: string[];
  clinic?: IClinic;
  provider: IProvider;
  appointmentType: IFullAppointmentType;
  careplan?: number;
  dayOfWeek: number;
  maxBookableMinutes?: number;
}

export interface ISmartSingleOpenings {
  openingCards: ISmartSingleResult[];
  bookedDays: number;
  bookedProviders: number;
  totalCareplan: number;
}

export interface ISmartSingleResult {
  date: string;
  times: times[];
  provider: IProvider;
  inPersonClinics: IClinic[];
  locations: locations;
  careplan?: number;
  dayOfWeek?: number;
  apptTypeId?: number;
  allowedLocations?: string[];
  isInClinic: boolean;
  isOffsite: boolean;
  isTelehealth: boolean;
  telehealthClinic?: IClinic;
}

export interface locations {
  isInClinic: boolean;
  isOffsite: boolean;
  isTelehealth: boolean;
}

export interface times {
  startTime: string;
  endTime: string;
}
export interface IStatistics {
  clientBooked: number;
  careplan: number;
  providerBooked: number;
}
export interface ISmartScheduleConsolidatedResults {
  openingCards: ISmartResultCard[];
  totalAvailableDuration?: string;
  availableDays?: number;
}
export interface ICarePlanFieldsForSmartScheduling {
  recommended__c: number;
  requested__c: number;
  axonReceivingHours: number;
  DTReceivingUnits: number;
  status__c: string;
  SmartScheduling_status: 0 | 1 | 2 | null;
  createdDate: Date;
  waitlisted_reason__c: string;
}
