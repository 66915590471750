import React, { MouseEvent, ReactNode } from 'react';
import { HeaderNav } from '../../views/containers/header/header.style';
import MainButton from '../../views/components/button/index';
import { Icon } from 'semantic-ui-react';

interface IProps {
  title: string;
  buttonText: string;
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
  loading: boolean;
  children?: ReactNode;
}
const Header: React.FC<IProps> = ({
  title,
  buttonText,
  onSubmit,
  onCancel,
  disabled,
  loading,
  children
}: IProps) => (
  <HeaderNav>
    <div className={'navbar'}>
      <div className="inner-wrap">
        <div className="inner-title">
          <h2>{title}</h2>
        </div>
        <div className="inner-button">
          {children}
          <MainButton
            title={buttonText}
            onClick={onSubmit}
            loading={loading}
            disabled={disabled}
          />
          <Icon className={'closeBtn'} link name={'close'} onClick={onCancel} />
        </div>
      </div>
    </div>
  </HeaderNav>
);

export default React.memo(Header);
