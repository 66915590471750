import SelectList from 'api/sharedComponents/reactHookFormComponents/semantic/SelectList';
import { IClinic } from 'model/v2';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DropdownProps } from 'semantic-ui-react';
import { CustomLocationWrapper } from './Style';
import {
  getPreferredLocation,
  onSmartScheduleCardLocationDropdownChange
} from 'helpers/smartScheduleCardHelper';
import { LOCATION_CATEGORY_LIST } from 'utils/constants/lists';
import { Grid } from 'semantic-ui-react';
import { EventSelectOptions } from 'model/calendar/filters';

interface IProps {
  index: number;
  isInClinic?: boolean;
  isOffsite?: boolean;
  isTelehealth?: boolean;
  allowedLocations: string[];
  clinic?: IClinic;
  isCheckedCard: boolean;
  clinics: IClinic[];
  selectedIndex: number | undefined;
  eventCategoriesOptions?: EventSelectOptions[];
}
const ResultLocationCustom: React.FC<IProps> = ({
  isInClinic,
  isOffsite,
  isTelehealth,
  allowedLocations,
  index,
  clinics,
  selectedIndex,
  eventCategoriesOptions
}: IProps) => {
  const { control, errors, setValue } = useFormContext();

  const categoriesOptions = useMemo(
    () =>
      LOCATION_CATEGORY_LIST.map(it => ({
        key: it.id,
        value: it.id,
        text: it.value
      })),
    []
  );

  const LocationList = useMemo(() => {
    return allowedLocations?.map(
      (location: string, index: number): DropdownProps => {
        return {
          key: index,
          value: location,
          text: location?.charAt(0).toUpperCase() + location.slice(1)
        };
      }
    );
  }, [allowedLocations]);

  const ClinicsList = useMemo(() => {
    return clinics?.map(
      (clinic: IClinic, index: number): DropdownProps => {
        return {
          key: index,
          value: clinic.id,
          text: clinic.abbreviation
        };
      }
    );
  }, [allowedLocations]);

  const preferredLocationIndex = useMemo(() => {
    const preferredLocation = getPreferredLocation(
      isInClinic,
      isOffsite,
      isTelehealth
    );
    return LocationList.findIndex((location: DropdownProps) => {
      return location.value === preferredLocation;
    });
  }, [LocationList, isInClinic, isOffsite, isTelehealth]);
  return (
    <>
      <CustomLocationWrapper>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Grid.Row className="row-style">
                <Controller
                  name={`location-dropdown`}
                  control={control}
                  render={props => (
                    <SelectList
                      field={props}
                      errors={errors}
                      options={LocationList}
                      value={LocationList[preferredLocationIndex]?.value}
                      disabled={selectedIndex !== index}
                      onChange={(_view: any, data: DropdownProps) => {
                        setValue(`location-dropdown`, data.value);
                        onSmartScheduleCardLocationDropdownChange(
                          data.value as string,
                          index,
                          setValue,
                          true
                        );
                      }}
                    />
                  )}
                />
              </Grid.Row>
              <Grid.Row>
                {isInClinic && ClinicsList && (
                  <>
                    <Controller
                      name={`clinic-dropdown`}
                      control={control}
                      defaultValue={ClinicsList[0]?.value}
                      render={props => (
                        <SelectList
                          field={props}
                          errors={errors}
                          options={ClinicsList}
                          placeholder="Select Clinic"
                          disabled={selectedIndex !== index}
                          onChange={(_view: any, data: DropdownProps) => {
                            setValue(`clinic-dropdown`, data.value);
                            setValue(`result.clinic.id`, data.value);
                          }}
                        />
                      )}
                    />
                  </>
                )}
              </Grid.Row>
              <Grid.Row>
                {isOffsite && (
                  <>
                    {eventCategoriesOptions === undefined ? (
                      <>
                        <Controller
                          name={`location-category`}
                          control={control}
                          render={props => (
                            <SelectList
                              field={props}
                              errors={errors}
                              options={categoriesOptions}
                              placeholder="Select Category"
                              disabled={selectedIndex !== index}
                              onChange={(_view: any, data: DropdownProps) => {
                                setValue(`location-category`, data.value);
                                setValue(`result.locationCategory`, data.value);
                              }}
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <Grid.Row className="row-style">
                          <Controller
                            name={`location-category`}
                            //control={control}
                            render={props => (
                              <SelectList
                                field={props}
                                defaultValue={eventCategoriesOptions[0].value}
                                //errors={errors}
                                options={eventCategoriesOptions}
                                placeholder="Select Category"
                                disabled={selectedIndex !== index}
                              />
                            )}
                          />
                        </Grid.Row>
                      </>
                    )}
                  </>
                )}
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </CustomLocationWrapper>
    </>
  );
};
export default React.memo(ResultLocationCustom);
