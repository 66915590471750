import { IProvider } from 'model/v2';
import React, { useMemo } from 'react';
import { Grid } from 'semantic-ui-react';
import { DEFAULT_TELEHEALTH } from 'utils/constants/default';
import FirstAidIcon from 'assets/img/FirstAid-icon.svg';
import CalendarIcon from 'assets/img/calendar-icon.svg';
import HourglassMediumIcon from 'assets/img/HourglassMedium-icon.svg';
import ClockIcon from 'assets/img/clock.svg';
import LicensedForIcon from 'assets/img/icon-inClinic.png';
import { convertTZtoTZString } from 'pages/ConfigurationsPage/addAvailabilityTemplate/utils';

interface IProps {
  provider?: IProvider;
}

const ProviderInformation = ({ provider }: IProps) => {
  const {
    telehealth,
    bookedHoursValues,
    bookedSessionsValues
  } = useMemo(() => {
    const clinic = provider?.clinic;
    const bookedHours = provider?.providerCapacity?.bookedHours;
    const bookedSessions = provider?.providerCapacity?.bookedSessions;
    const capacity = provider?.providerCapacity?.capacity;
    const availability = provider?.providerCapacity?.availability;
    const bookedHoursValues =
      bookedHours && capacity
        ? `${bookedHours} / ${capacity} | ${Math.floor(
            (bookedHours / capacity) * 100
          )}% `
        : 0;
    const bookedSessionsValues =
      bookedSessions && availability
        ? `${bookedSessions} / ${availability} | ${Math.floor(
            (bookedSessions / availability) * 100
          )}%`
        : 0;
    const telehealth = provider?.telehealth
      ? provider.telehealth.join(', ')
      : [
          DEFAULT_TELEHEALTH.PREFIX +
            clinic?.address?.state +
            DEFAULT_TELEHEALTH.SUFFIX
        ];
    return {
      capacity,
      bookedHoursValues,
      bookedSessionsValues,
      telehealth
    };
  }, [provider]);
  const providerTimezone = convertTZtoTZString(
    provider?.clinic?.timezone,
    false
  );

  return (
    <Grid.Column className="provider-information">
      <Grid className="body" stackable columns={2}>
        <Grid.Row style={{ paddingTop: 25 }}>
          <div className="title">Provider Information</div>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            style={{ padding: 0, width: '44%', marginBottom: '16px' }}
          >
            <Grid.Row>
              <label>
                <img
                  alt="firstaid"
                  src={FirstAidIcon}
                  style={{ paddingLeft: 3, paddingRight: 3 }}
                />
                Clinic: <span>{provider?.clinic?.name}</span>
              </label>
            </Grid.Row>
            <Grid.Row>
              <label>
                <img
                  alt="clock"
                  src={ClockIcon}
                  style={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    width: '19px',
                    filter: 'brightness(65%)'
                  }}
                />
                Provider Time Zone:
                <span>{providerTimezone}</span>
              </label>
            </Grid.Row>
            <Grid.Row>
              <label>
                <img
                  alt="licensedFor"
                  src={LicensedForIcon}
                  style={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    width: '19px',
                    filter:
                      'invert(48%) sepia(13%) saturate(3207%) hue-rotate(215deg) brightness(75%) contrast(80%)'
                  }}
                />
                Licensed For:
                <span>{telehealth}</span>
              </label>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column style={{ padding: 0, width: '56%' }}>
            <Grid.Row>
              <label>
                {' '}
                <img
                  alt="email"
                  src={CalendarIcon}
                  style={{ paddingLeft: 3, paddingRight: 3 }}
                />
                Booked Sessions: <span>{bookedSessionsValues}</span>
              </label>
            </Grid.Row>
            <Grid.Row>
              <label>
                <img
                  alt="email"
                  src={HourglassMediumIcon}
                  style={{ paddingLeft: 3, paddingRight: 3 }}
                />
                Booked Hours: <span>{bookedHoursValues}</span>
              </label>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid.Column>
  );
};
export default React.memo(ProviderInformation);
