import { Configuration, LogLevel, PopupRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID!,
    authority: process.env.REACT_APP_AAD_APP_AUTHORITY_URL,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: true
  },
  system: {
    tokenRenewalOffsetSeconds: 5 * 60,
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error({
              error: new Error(`Authentication Error :: ${message || ''}`)
            });
            return;
          case LogLevel.Info:
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            console.log({
              name: 'Authentication Warning',
              properties: [`level: ${level}`, `message: ${message}`]
            });
            return;
        }
      },
      piiLoggingEnabled: false
    }
  }
};

export const loginRequest: PopupRequest = {
  scopes: [`${process.env.REACT_APP_AAD_APP_CLIENT_ID}/.default`],
  authority: process.env.REACT_APP_AAD_APP_AUTHORITY_URL
};
