import moment from 'moment';
import {
  convertDateFromISO,
  convertDateToMoment,
  formatUsernameOriginal
} from 'utils/format';
import { YES_NO_LIST, PHONE_PREFERENCE_LIST } from 'utils/constants/lists';
import { getAge } from 'utils/eval';
import { eventInRange } from './events';
import { PHONE_REGEX } from 'utils/constants/regex';
import { isEmpty } from 'lodash';
import { CLIENT_EDIT_TYPE } from 'model/v2';
import { constructProgramDataForDisplay } from 'utils/common';

export const getClientsData = clients =>
  clients &&
  clients.map(client => {
    const dob = convertDateFromISO(client.profile?.dob);
    const medicalLabels = getMedicalLabels(client);
    return {
      ...client,
      dob,
      name: client.name,
      ...medicalLabels
    };
  });

export const getClientData = client => {
  const dob = convertDateFromISO(client?.profile?.dob);
  const age = getAge(client?.profile?.dob);
  const medicalLabels = getMedicalLabels(client);
  const preferredPhone = client?.profile
    ? getPreferredPhone(client?.profile)
    : '';
  const profile = {
    ...client?.profile,
    dob
  };
  return {
    ...client,
    profile,
    age,
    preferredPhone,
    ...medicalLabels
  };
};

export const mapClientTableInfo = (
  clientData,
  clientAllergies,
  clientProviderJson
) => {
  let allProviders = JSON.parse(clientProviderJson).clientCurrentProviders;
  let mappedClientList;
  if (clientData)
    return (mappedClientList = clientData.map(clientData => {
      let { profile, dayAvailabilities } = clientData;
      let client_dayAvailabilities = dayAvailabilities;
      let currentProviders = allProviders[clientData.id];
      return {
        id: clientData.id,
        name:
          clientData?.firstName +
          ' ' +
          clientData?.lastName +
          ' ' +
          `(${clientData?.clinic?.abbreviation || ''})`,

        status:
          typeof clientData?.isActive === 'boolean'
            ? clientData?.isActive
            : 'N/A',
        dob: profile?.dob ? moment(profile?.dob).format('MM/DD/YYYY') : 'N/A',
        email: profile?.personalEmail || 'N/A',
        phone: profile?.cellPhone || 'N/A',
        gender: profile?.gender || 'N/A',
        petAllergies: clientData?.petAllergies,
        clientId: clientData?.id,
        notesCount: clientData?.clientRosterNotes?.length,
        notes: clientData.clientRosterNotes,
        client_dayAvailabilities: client_dayAvailabilities,
        languages: clientData?.languages,
        availability: clientData?.availabilityLastModificationDate
          ? moment(clientData?.availabilityLastModificationDate).format(
              'MM/DD/YYYY'
            )
          : 'N/A',

        program: constructProgramDataForDisplay(clientData.corticaPrograms),
        restrictedProviders: clientData?.restrictedProviders,
        providers:
          clientData?.restrictedProviders.length +
          (currentProviders ? currentProviders.length : 0),
        currentProviders: currentProviders
      };
    }));
};

export const updateFinalResults = function*({
  clientId,
  filterLanguages,
  additionalUpdateFields,
  calendarFilterData,
  value,
  selectedValues,
  editType,
  optionType,
  selectedOptions
}) {
  let mappedUpdateData;
  let updatedItems;
  switch (editType) {
    case CLIENT_EDIT_TYPE.languages:
      let calendarData = calendarFilterData;
      let editValue = calendarData[editType];
      updatedItems = selectedValues.includes(value[optionType])
        ? selectedValues?.filter(item => item !== value[optionType]) // Uncheck if already selected
        : [...selectedValues, value[optionType]];
      const selectedData = editValue?.filter(data =>
        updatedItems.includes(data[optionType])
      );
      mappedUpdateData = [...selectedData];
      break;
    case CLIENT_EDIT_TYPE.restrictedProviders:
      updatedItems = selectedValues.includes(value[optionType])
        ? selectedOptions?.filter(
            item => item[optionType] !== value[optionType]
          )
        : [...selectedOptions, value];
      mappedUpdateData = [...updatedItems];
      break;
    case CLIENT_EDIT_TYPE.allergies:
      mappedUpdateData = value;
      break;
    default:
      mappedUpdateData = value;
  }

  yield mappedUpdateData;

  additionalUpdateFields[editType] = mappedUpdateData;
  additionalUpdateFields[CLIENT_EDIT_TYPE.id] = clientId;
  additionalUpdateFields[CLIENT_EDIT_TYPE.languages] = additionalUpdateFields[
    CLIENT_EDIT_TYPE.languages
  ]?.map(data => {
    return { id: filterLanguages[data.name], name: data.name };
  });
  additionalUpdateFields[
    CLIENT_EDIT_TYPE.restrictedProviders
  ] = additionalUpdateFields[CLIENT_EDIT_TYPE.restrictedProviders]?.map(
    data => {
      return { id: data.id };
    }
  );
  additionalUpdateFields['isActive'] =
    additionalUpdateFields[CLIENT_EDIT_TYPE.status];
  delete additionalUpdateFields[CLIENT_EDIT_TYPE.status];
  // additionalUpdateFields
  return additionalUpdateFields;
};

export const cleanClient = (client, optionalFields) => {
  optionalFields.forEach(field => (client[field] = client[field] || ''));
  return client;
};

export const getEditableClientData = client => {
  const registrationDate = convertDateToMoment(client.registrationDate);
  const dob = convertDateToMoment(client.profile?.dob);
  const subscriberDOB = convertDateToMoment(client.profile?.subscriberDOB);
  const noticesOnFile = client.profile?.noticesOnFile === YES_NO_LIST[0].value;

  const profile = {
    ...client.profile,
    dob,
    subscriberDOB,
    noticesOnFile,
    homePhone: adjustPhone(client.profile?.homePhone),
    cellPhone: adjustPhone(client.profile?.cellPhone),
    fatherPhone: adjustPhone(client.profile?.fatherPhone),
    motherPhone: adjustPhone(client.profile?.motherPhone)
  };

  client.profile = profile;
  client.registrationDate = registrationDate;
  if (!client.usualProvider) {
    client.usualProvider = { id: undefined };
  }
  return client;
};

const adjustPhone = phone => {
  if (!phone || isEmpty(phone?.trim()) || phone?.length < 10) return undefined;
  if (PHONE_REGEX.test(phone)) return phone;
  return format(phone, '###-###-####');
};
const format = (value, pattern) => {
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, _ => v[i++]);
};

export const handleClientAddresses = client => {
  if (!client.addresses || client.addresses?.length === 0) {
    client.addresses = [
      {
        addressLine1: undefined,
        addressLine2: undefined,
        state: undefined,
        zipCode: undefined,
        city: undefined,
        name: undefined
      }
    ];
  } else {
    const primaryAddressIndex = client.addresses?.findIndex(
      a => a.isPrimary === true
    );

    let primaryAddress = undefined;
    if (primaryAddressIndex > 0) {
      primaryAddress = client.addresses[primaryAddressIndex];
      client.addresses.splice(primaryAddressIndex, 1);
      client.addresses = [primaryAddress].concat(client.addresses);
    }
  }
};

export const getClientEvents = (events, range, doCheckEventTime) => {
  const filtered = [];
  events.forEach(event => {
    if (eventInRange(event, range, doCheckEventTime)) {
      const appointmentType = event.appointmentType?.parent
        ? event.appointmentType?.parent
        : event.appointmentType;
      filtered.push({
        ...event,
        dayOfWeek: new Date(event.startDate).getDay(),
        start: new Date(event.startDate),
        end: new Date(event.endDate),
        title: appointmentType?.title,
        desc: {
          main:
            event?.provider?.name ||
            formatUsernameOriginal(
              event?.provider?.firstName,
              event?.provider?.lastName
            )
        },
        color: appointmentType?.headerColor,
        type: event?.clinic?.id ? 'inClinic' : 'offSite'
      });
    }
  });
  return filtered;
};

export const getClientBlockedDates = blockedDates => {
  let newBlockedDates = [];
  blockedDates.forEach(blockedDate => {
    const blockedStartDate = moment(blockedDate.startDate);
    const blockedEndDate = moment(blockedDate.endDate);

    if (blockedStartDate.isSame(blockedEndDate, 'day')) {
      newBlockedDates.push({
        start: new Date(blockedDate.startDate),
        end: new Date(blockedDate.endDate),
        type: 'na'
      });
    } else {
      newBlockedDates.push({
        start: blockedStartDate.toDate(),
        end: blockedStartDate.endOf('day').toDate(),
        type: 'na'
      });

      blockedStartDate.add(1, 'day');
      while (blockedStartDate.isSameOrBefore(blockedEndDate)) {
        newBlockedDates.push({
          start: blockedStartDate.startOf('day').toDate(),
          end: blockedStartDate.isSame(blockedEndDate, 'day')
            ? blockedEndDate.toDate()
            : blockedStartDate.endOf('day').toDate(),
          type: 'na'
        });
        blockedStartDate.add(1, 'day');
      }
    }
  });
  return newBlockedDates;
};

/* exporting for any later usage outside file scope */
export const getMedicalLabels = client => {
  const phenoType = client?.profile?.phenoType || '00';
  const behavior = client?.profile?.behavior || '00';
  const cognition = client?.profile?.cognition || '00';
  const communication = client?.profile?.communication || '00';
  const sensorimotor = client?.profile?.sensorimotor || '00';
  return { phenoType, behavior, cognition, communication, sensorimotor };
};

const getPreferredPhone = ({ phonePreference, cellPhone, homePhone }) =>
  phonePreference === PHONE_PREFERENCE_LIST[0].id
    ? cellPhone
    : phonePreference === PHONE_PREFERENCE_LIST[1].id
    ? homePhone
    : undefined;

export const clientEventsAdjacency = ({ clientsEvent }) => {
  clientsEvent.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  const totalCombinedEvents = {};

  clientsEvent.forEach(event => {
    const dayOfWeek = event.dayOfWeek;

    if (!totalCombinedEvents[dayOfWeek]) {
      totalCombinedEvents[dayOfWeek] = [];
    }
    if (!event.isAvailabilitySlot) {
      totalCombinedEvents[dayOfWeek].push([
        {
          id: event.id,
          startAt: new Date(event.startDate),
          endAt: new Date(event.endDate)
        }
      ]);
    }
  });
  let groupedWeekEvents = {};
  for (let key in totalCombinedEvents) {
    let finalCombinedEvents = [];
    finalCombinedEvents.push(totalCombinedEvents[key][0]);
    for (let i = 0; i <= totalCombinedEvents[key].length; i++) {
      let currentIndex = i;
      if (i !== totalCombinedEvents[key].length - 1) {
        while (currentIndex < totalCombinedEvents[key].length - 1) {
          var isMerged = false;
          let indices = currentIndex;
          let [currentEvent] = totalCombinedEvents[key][i];
          let [nextEvent] = totalCombinedEvents[key][indices + 1];

          const EventIndex = inspectIfEventCollide(
            finalCombinedEvents,
            currentEvent
          );
          if (EventIndex >= 0) {
            if (ifAppointmentTimeSlotMerge(currentEvent, nextEvent)) {
              isMerged = true;
              if (!finalCombinedEvents[EventIndex].includes(nextEvent))
                finalCombinedEvents[EventIndex].push(nextEvent);
            }
          } else {
            if (!isMerged) {
              if (ifAppointmentTimeSlotMerge(currentEvent, nextEvent))
                finalCombinedEvents.push([currentEvent, nextEvent]);
              else finalCombinedEvents.push([currentEvent]);
            }
          }
          currentIndex++;
        }
      } else {
        const EventIndex = finalCombinedEvents.findIndex(innerArray =>
          innerArray.some(
            item =>
              item.id === [totalCombinedEvents[key][i]].id &&
              item.startAt === [totalCombinedEvents[key][i]].startAt &&
              item.endAt === [totalCombinedEvents[key][i]].endAt
          )
        );
        if (EventIndex < 0) {
          finalCombinedEvents.push(totalCombinedEvents[key][i]);
        }
      }
    }
    groupedWeekEvents[key] = finalCombinedEvents;
  }
  return groupedWeekEvents;
};
function inspectIfEventCollide(finalCombinedEvents, currentEvent) {
  let index = -1;
  for (let i = 0; i < finalCombinedEvents.length; i++) {
    const innerArray = finalCombinedEvents[i];

    for (let j = 0; j < innerArray.length; j++) {
      const item = innerArray[j];
      if (item.id === currentEvent.id) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      break;
    }
  }
  return index;
}
function ifAppointmentTimeSlotMerge(currentEvent, nextEvent) {
  return (
    (currentEvent.startAt >= nextEvent.startAt &&
      currentEvent.startAt < nextEvent.endAt) ||
    (currentEvent.endAt > nextEvent.startAt &&
      currentEvent.endAt <= nextEvent.endAt) ||
    (currentEvent.startAt <= nextEvent.startAt &&
      currentEvent.endAt > nextEvent.startAt)
  );
}

export function styleClientCalendarEvents(eventId, eventIndex) {
  if (this) {
    const clinetsDayWeekEvents = this;
    let style;
    switch (clinetsDayWeekEvents.length) {
      case 1:
        return 'IndividualEvent';
      case 2:
        return `doubleEvent-${eventIndex}`;

      case 3:
        return `tripleEvent-${eventIndex}`;
      case 4:
        return `QuadrupleEvent-${eventIndex}`;
      case 5:
        return `QuintupleEvent-${eventIndex}`;
      case 6:
        return `HexaupleEvent-${eventIndex}`;
      case 7:
        return `OctupleEvent-${eventIndex}`;
      case 8:
        return `NonupleEvent-${eventIndex}`;

      default:
        return `LastEvent-${eventIndex}`;
    }
  } else return '';
}
