export const getDisabeldFields = isDisabled => {
  return {
    // provider/registration
    usualProvider: isDisabled,
    clinic: isDisabled,
    registrationDate: isDisabled,
    clientType: isDisabled,
    clientTypeNotes: false,
    // identification
    firstName: isDisabled,
    lastName: isDisabled,
    firstNameUsed: isDisabled,
    dob: isDisabled,
    legalSex: isDisabled,
    gender: isDisabled,
    // contact
    addresses: {
      addressLine1: false,
      addressLine2: false,
      city: false,
      state: false,
      zipCode: false,
      name: false
    },
    cellPhone: isDisabled,
    homePhone: isDisabled,
    phonePreference: isDisabled,
    consentToText: isDisabled,
    personalEmail: isDisabled,
    // isDisabledl
    motherName: isDisabled,
    motherPhone: isDisabled,
    motherEmail: isDisabled,
    motherNotes: isDisabled,
    fatherName: isDisabled,
    fatherPhone: isDisabled,
    fatherEmail: isDisabled,
    fatherNotes: isDisabled,
    otherAuthorizedGuardians: isDisabled,
    consentToEmailPHI: isDisabled,
    aboutCortica: isDisabled,
    // privacy
    noticesOnFile: isDisabled,
    consentToCall: isDisabled,
    medicalHistoryAuthority: isDisabled,
    // insurance
    insuranceProvider: isDisabled,
    memberNumber: isDisabled,
    groupNumber: isDisabled,
    subscriberFirstName: isDisabled,
    subscriberLastName: isDisabled,
    subscriberDOB: isDisabled,
    subscriberSSN: isDisabled,
    patientRelationshipToSubscriber: isDisabled,
    physicianPhoneNumber: isDisabled,
    // legal guardian
    legalGuardianFirstName: isDisabled,
    legalGuardianLastName: isDisabled,
    // medical profile
    phenoType: isDisabled,
    behavior: isDisabled,
    cognition: isDisabled,
    communication: isDisabled,
    sensorimotor: isDisabled,
    notes: isDisabled
  };
};
