import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  ModalWrapper,
  FormProviderWrapper,
  RecurringTitle,
  ModalBottom
} from './style';
import UpperAvailabilityTemplateForm, {
  TEMPLATE_DEFAULT_OPTION
} from './UpperModal';
import RecurringEvent from './RecurringForm/index';
import { Button } from 'lib/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { ADD_AVAILABILITY_TEMPLATE } from 'utils/validators/availabilityTemplate/newAvailabilityTemplate';
import { IModalDropdown } from 'model/availabilityTemplate';
import {
  generateModalDefaultTemplate,
  mapDayAvailabilityToCalendarEvent,
  MapEventsToDayAvailability,
  getDefaultTemplateData,
  getDefaultProviderDailyMaxValue
} from 'utils/mappers/availabilityTemplate';
import { useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { GET_DAY_AVAILABILITIES_BY_ID } from 'api/graphql/v2/queries/AvailabilityTemplate';
import { useCallback } from 'react';
import Loader from 'views/components/ui/content/Loader';
import { useEffect } from 'react';
import {
  CalenderEvent,
  DayAvailability,
  ModalData
} from 'model/v2/availabilityTemplate';
import { PROVIDER_EMPLOYMENT_LIST } from 'model/v2';

const Days: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

interface IProps {
  onClose(): void;
  onSubmit(data: any, events: CalenderEvent[]): void;
  data: ModalData | undefined;
  isEditMode: boolean;
  currentEvents: CalenderEvent[];
  dropdownOptions: IModalDropdown;
}

const ModalForm: React.FC<IProps> = ({
  onClose,
  onSubmit,
  data,
  currentEvents,
  isEditMode,
  dropdownOptions
}) => {
  const [events, setModalEvents] = useState<CalenderEvent[]>(currentEvents);
  const [daysArr, setDaysArr] = useState<DayAvailability[] | undefined>(
    data?.Day
  );
  const [loading, setLoading] = useState<boolean>(false);
  const disableUpperForm =
    data?.department !== undefined && data?.speciality !== undefined;

  const isToShowDailyMax =
    disableUpperForm &&
    (data?.employment === PROVIDER_EMPLOYMENT_LIST.FULL_TIME_HOURLY ||
      data?.employment === PROVIDER_EMPLOYMENT_LIST.PART_TIME_HOURLY);

  const methods = useForm<ModalData>({
    defaultValues: React.useMemo(() => getDefaultTemplateData(data), [data]),
    resolver: yupResolver(ADD_AVAILABILITY_TEMPLATE(isToShowDailyMax)),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  const updateDailyMax = useCallback(
    (days: DayAvailability[]) => {
      for (let i = 0; i < 7; i++) {
        methods.setValue(
          `dailyMaxHours[${i}].value`,
          getDefaultProviderDailyMaxValue(
            days[i],
            methods.getValues(`dailyMaxHours[${i}].value`)
          ),
          { shouldDirty: true }
        );
      }
    },
    [methods]
  );

  const updateStartAndLength = useCallback(
    (newDays: DayAvailability[]) => {
      for (let i = 0; i < 7; i++) {
        if (newDays[i]?.startTime !== undefined || newDays[i]?.length !== 0) {
          methods.setValue(
            `Day[${i}].startTime`,
            newDays[i]?.startTime?.isValid()
              ? newDays[i]?.startTime.day(i)
              : undefined,
            {
              shouldDirty: true
            }
          );
          methods.setValue(`Day[${i}].length`, newDays[i]?.length || 0);
        }
        setDaysArr(newDays);
      }
    },
    [methods, setDaysArr]
  );

  const updateRecurring = useCallback(
    (events: CalenderEvent[]) => {
      let newDays = MapEventsToDayAvailability(events);
      updateStartAndLength(newDays);
      updateDailyMax(newDays);
    },
    [updateStartAndLength, updateDailyMax]
  );
  const [getNewTemplateData] = useLazyQuery(GET_DAY_AVAILABILITIES_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: dataDayAvailability => {
      let events = mapDayAvailabilityToCalendarEvent(
        dataDayAvailability?.getAvailabilityWithDetails?.dayAvailabilities ||
          [],
        data?.providerDefaultClinicsIds
      );
      setModalEvents(events);
      updateRecurring(events);
      setLoading(false);
    }
  });

  useEffect(() => {
    async function setDefaultDataForEmptyTemplate() {
      if (!isEditMode && daysArr?.length === undefined) {
        let newDays = await generateModalDefaultTemplate();
        updateStartAndLength(newDays);
      }
    }
    setDefaultDataForEmptyTemplate();
  }, [daysArr, updateStartAndLength, isEditMode]);

  const onTemplateTypeChange = useCallback(
    async (value: number) => {
      setLoading(true);
      if (value === TEMPLATE_DEFAULT_OPTION.value) {
        setModalEvents([]);
        let newDays = await generateModalDefaultTemplate();
        updateStartAndLength(newDays);
        updateDailyMax(newDays);
        setLoading(false);
      } else {
        getNewTemplateData({ variables: { id: Number(value) } });
      }
    },
    [getNewTemplateData, updateDailyMax, updateStartAndLength]
  );

  const submitForm = useCallback(
    (values: ModalData) => {
      onSubmit({ ...values, employment: data?.employment }, events);
    },
    [onSubmit, data, events]
  );

  return (
    <FormProvider {...methods}>
      <ModalWrapper>
        <UpperAvailabilityTemplateForm
          disabled={disableUpperForm}
          data={data}
          dropdownOptions={dropdownOptions}
          onTemplateTypeChange={onTemplateTypeChange}
        />
      </ModalWrapper>

      <RecurringTitle>Recurring Start Times and Day Lengths</RecurringTitle>
      <FormProviderWrapper>
        {loading ? (
          <div className="loadingContent">
            <Loader />
          </div>
        ) : (
          Days.map((day, index) => {
            return (
              <RecurringEvent
                key={index}
                dayName={day}
                index={index}
                data={daysArr ? daysArr[index] : undefined}
                isToShowDailyMax={isToShowDailyMax}
              />
            );
          })
        )}
      </FormProviderWrapper>

      <ModalBottom>
        <Button className="cancel-btn" onClick={onClose} type="">
          Cancel
        </Button>
        {!loading && (
          <Button
            className="confirm-btn"
            onClick={methods.handleSubmit(submitForm)}
            type="submit"
          >
            Confirm
          </Button>
        )}
      </ModalBottom>
    </FormProvider>
  );
};

export default ModalForm;
