import React from 'react';
import DescriptionExpandableRow from 'views/components/clients/profile/header/components/DescriptionExpandableRow';
import { getApptTypeAndSubType } from 'utils/common';
import { formatPatternString } from 'utils/mappers/response/authorizations';
import { humanizeFloat } from 'utils/format';
import moment from 'moment';
import { getWaitingValue } from 'utils/mappers/response/waitlist';
import { ExpandedRowForWaitlistNewAppointment } from 'views/components/forms/appointment/components/SideBar/Waitlist/ExpandedRowForWaitlistNewAppointment';

export function formatWaitlistSectionData(
  data,
  appointmentTypes = [],
  patternFlag = false
) {
  let items = [];
  data?.forEach(element => {
    if (isNaN(element.toFulfill) || element.toFulfill > 0) {
      const { apptType, apptSubType } = getApptTypeAndSubType(
        {
          appointmentType: {
            id: element.appointmentTypeID
          },
          appointmentSubType: element.appointmentSubType
        },
        appointmentTypes
      );
      const waitlistPattern = element.quantityPattern
        ? element.quantityPattern.toLowerCase()
        : element.isClinical
        ? 'visits'
        : 'hours';

      const item = {
        key: element.id,
        idealPlan: patternFlag
          ? formatPatternString(
              element.ideal,
              waitlistPattern,
              element.isRecommendedTherapy
            )
          : element.ideal,
        receiving: patternFlag
          ? formatPatternString(
              element.receiving,
              waitlistPattern,
              element.isRecommendedTherapy
            )
          : element.receiving,
        toFulfill: patternFlag
          ? formatPatternString(
              element.toFulfill,
              waitlistPattern,
              element.isRecommendedTherapy
            )
          : element.toFulfill,
        apptType: apptType?.title || '',
        apptSubType: apptSubType?.title || 'None',
        appointmentType: {
          id: apptType?.id,
          isClinical: apptType?.isClinical,
          subType: apptSubType?.id
        },
        quantityPattern: waitlistPattern,
        waiting: element.waiting,
        isRecommendedTherapy: element.isRecommendedTherapy,
        isClinical: element.isClinical,
        waitlistWeekCount: element.waitlistWeekCount,
        waitlistEndDate: element.waitlistEndDate,
        canSchedule: element.canSchedule
      };
      /// only expanded if recommended therapy
      if (element.isRecommendedTherapy)
        item.description = (
          <DescriptionExpandableRow
            recommended={element.maxRecommendedQuantity}
            authorized={element.authorized}
            requested={element.requestedQuantity}
            quantityPattern={waitlistPattern}
            isRecommendedTherapy={element.isRecommendedTherapy}
          />
        );
      items.push(item);
    }
  });
  return items;
}

export const WaitListPopOverAvailHelper = (days, clientInfo) => {
  const clientAvailInfo = clientInfo?.client_dayAvailabilities?.reduce(
    (acc, currentData) => {
      const formattedTime = moment(currentData.endTime, 'HH:mm').format(
        'hh:mm A'
      );
      const formattedStartTime = moment(currentData.startTime, 'HH:mm').format(
        'hh:mm A'
      );
      const dayOfWeek = currentData?.dayOfWeek;

      if (acc[days[dayOfWeek]]) {
        acc[days[dayOfWeek]].push(`${formattedStartTime}-${formattedTime}`);
      } else {
        acc[days[dayOfWeek]] = [`${formattedStartTime}-${formattedTime}`];
      }

      return acc;
    },
    {}
  );
  return clientAvailInfo;
};
export const appointmentTypeCheck = (
  carePlan_AppointmentType,
  carePlanNotMapped,
  AXON_Type
) => {
  let eval_type = carePlan_AppointmentType?.eval_type
    ? carePlan_AppointmentType?.eval_type
    : carePlan_AppointmentType?.parentTypeTitle
    ? carePlan_AppointmentType?.parentTypeTitle
    : carePlanNotMapped?.eval_type__c;

  let eventType = AXON_Type?.parentTypeEventId
    ? AXON_Type?.parentTypeEventId
    : carePlan_AppointmentType?.parentTypeEventId;

  let WaitListDTAppointment = [
    'OT',
    'PT',
    'MT',
    'SLP',
    'Counseling',
    'Family Counseling'
  ];
  return WaitListDTAppointment.includes(eval_type)
    ? true
    : eventType === '2'
    ? true
    : false;
};

export const checkIsAbaAppointment = (
  carePlan_AppointmentType,
  carePlanNotMapped,
  AXON_Type
) => {
  let appointmentType = carePlan_AppointmentType?.type;
  let WaitListABAAppointment = [
    'ABA Direct Service',
    'ABA Parent Training',
    'ABA Supervision'
  ];
  return WaitListABAAppointment.includes(appointmentType) ? true : false;
};

export const getCarePlanApptType = (
  isDT,
  clientInfo,
  ABAMainApptType,
  Receving_Hours,
  Recommended_Hour,
  Requested_Hour
) => {
  let subTypeMappingForDt = { PT: '45', MT: '45', OT: '45', SLP: '30' };
  let No_Of_Session;
  let Patient_Receving_Hrs = parseInt(Receving_Hours);
  let Patient_Recommended_Hrs = parseInt(Recommended_Hour);
  let Patient_Requested_Hrs = parseInt(Requested_Hour);
  if (Number.isInteger(Patient_Receving_Hrs)) {
    if (Number.isInteger(Patient_Requested_Hrs) && Patient_Requested_Hrs !== 0)
      No_Of_Session = Patient_Requested_Hrs - Patient_Receving_Hrs;
    else if (Number.isInteger(Patient_Recommended_Hrs)) {
      No_Of_Session = Patient_Recommended_Hrs - Patient_Receving_Hrs;
    }
  }
  if (isDT) {
    let DtApptType = clientInfo?.AxonType?.filter(data => {
      return data.parentTypeTitle === clientInfo.eval_type;
    });

    let Individual_Type_CarePlan;

    if (DtApptType.length > 0) {
      if (subTypeMappingForDt[clientInfo.eval_type]) {
        Individual_Type_CarePlan = DtApptType.find(
          data =>
            data.subTypeTitle === subTypeMappingForDt[clientInfo.eval_type]
        );
      }
    }

    let [MappedType] = DtApptType || [];
    return {
      carePlanMainType: MappedType?.parentId,
      carePlanSubType: Individual_Type_CarePlan
        ? Individual_Type_CarePlan.subTypeId
        : MappedType?.subTypeId,
      Sessions: No_Of_Session
    };
  } else
    return {
      carePlanMainType: ABAMainApptType?.parentId,
      carePlanSubType: ABAMainApptType?.subTypeId,
      Sessions: No_Of_Session
    };
};

export const getNoOfSession = () => {};
export const perWeekPendingVisits = (
  isClinical,
  noOfCreatedEvents,
  waitlistNoOfWeeks,
  eventDuration
) => {
  return humanizeFloat(
    (noOfCreatedEvents / waitlistNoOfWeeks) *
      (isClinical ? 1 : eventDuration / 60)
  );
};

export const isSameAppt = (waitlist, appType, appSubType) =>
  waitlist.apptType === appType &&
  (waitlist.apptSubType === appSubType ||
    (!appSubType && waitlist.apptSubType === 'None'));

// adjust waitlist calculations for pending
const weeksUntil = (startDate, endDate) => {
  return endDate
    ? moment(endDate)
        .endOf('day')
        .diff(moment(startDate), 'weeks')
    : undefined;
};

const noOfEvents = (repeat, startDate, endDate) => {
  return repeat && weeksUntil(startDate, endDate)
    ? Math.floor(weeksUntil(startDate, endDate) / repeat) + 1
    : 1;
};

export const adjustWaitlistPending = (
  waitlist,
  apptTypes,
  start,
  repeat,
  recurringUntil,
  duration,
  appType,
  appSubType
) =>
  formatWaitlistSectionData(waitlist, apptTypes, []).map(w => {
    const consideredEndDate = moment.min([
      moment(recurringUntil).endOf('day'),
      moment(w.waitlistEndDate)
    ]);

    // count total number of events created and number of events to adjust
    const totalEventsCreated = noOfEvents(repeat, start, recurringUntil);
    const noOfEventsInWaitlist = noOfEvents(repeat, start, consideredEndDate);

    // pending is number of events in the waitlist period divided by the number of weeks in waitlist
    const pendingVisits = w.isRecommendedTherapy
      ? perWeekPendingVisits(
          w.isClinical,
          noOfEventsInWaitlist,
          w.waitlistWeekCount,
          duration
        )
      : totalEventsCreated; // in case no recommended evaluation which doesn't normalize to number of weeks

    const toFulfill = humanizeFloat(w.toFulfill - pendingVisits);

    return {
      key: w.key,
      appt: w.apptType,
      toFulFill: formatPatternString(
        isSameAppt(w, appType, appSubType) ? toFulfill : w.toFulfill,
        w.quantityPattern,
        w.isRecommendedTherapy
      ),
      subType: w.apptSubType,
      waiting: getWaitingValue(w.waiting),
      description: (
        <ExpandedRowForWaitlistNewAppointment
          ideal={w.idealPlan}
          receiving={w.receiving}
          pending={isSameAppt(w, appType, appSubType) ? pendingVisits : 0}
          quantity={w.quantityPattern}
          toFulfill={
            isSameAppt(w, appType, appSubType) ? toFulfill : w.toFulfill
          }
          isRecommendedTherapy={w.isRecommendedTherapy}
        />
      )
    };
  });
