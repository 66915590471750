import React, { useState, useMemo, useCallback } from 'react';
import ListViewComponentItem from './ListViewComponentItem';
import { openingId } from 'utils/common';
import { useLocation } from 'react-router';

const List = ({ openings, disabledFormFields, selectedDate }) => {
  const location = useLocation();
  const { event: rescheduledEvent, thisAndFollowingEvents } = location.state;

  const [showMore, setShowMore] = useState(10);

  const fetchMore = useCallback(() => {
    setShowMore(prev => prev + prev);
  }, [setShowMore]);

  const sortedOpenings = useMemo(() => {
    return openings.sort((a, b) => new Date(a.start) - new Date(b.start));
  }, [openings]);

  return (
    <div className="list-view">
      {sortedOpenings.length !== 0 ? (
        <>
          {sortedOpenings.slice(0, showMore).map(event => (
            <ListViewComponentItem
              key={openingId(event)}
              opening={event}
              reschedule={disabledFormFields.reschedule}
              thisAndFollowingEvents={thisAndFollowingEvents}
              masterID={rescheduledEvent?.masterID}
              disabledFormFields={disabledFormFields}
            />
          ))}
          {sortedOpenings.length > showMore && (
            <span onClick={fetchMore} className="show-more">
              Load More
            </span>
          )}
        </>
      ) : (
        <span className="list-view__empty" style={{ textAlign: 'center' }}>
          No openings found
        </span>
      )}
    </div>
  );
};

export default React.memo(List);
