export const EVENT_STATUS = {
  canceled: 'canceled',
  needsReschedule: 'needsReschedule',
  scheduled: 'scheduled',
  rescheduled: 'rescheduled',
  deleted: 'deleted'
};

export const DOWN_STREAM_STATUS_VALUES = {
  MIN_VALUE_CREATION: 100,
  MIN_VALUE_FAILURE: 400
};

export const DEFAULT_STATUS = 'scheduled';

export const WAITLIST_SMART_SCHEDULING_STATUS = {
  0: 'No Match',
  1: 'Match',
  2: 'Unavailable'
};
