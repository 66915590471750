import React, { useEffect, ChangeEvent, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { APPOINTMENT_TYPE_SCHEMA } from 'utils/validators/configurations/appointmentTypes';
import MainButton from 'views/components/button';
import {
  AppointmentType,
  IAppointmentType,
  SalesForceTherapyMappingsType
} from 'model/appointment';
import { PUT_APPOINTMENT_TYPE } from 'api/graphql/v2/mutations/AppointmentTypes';
import { GET_APPT_TYPES_CONFIG } from 'api/graphql/v2/queries/AppointmentTypes';
import { GET_SALESFORCE_THERAPY_MAPPINGS } from 'api/graphql/v2/queries/SalesForceTherapyMappings';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ColorPickerInput from 'views/containers/form/colorPickerInput';
import { ConfigurationFormWrapper } from '../AppointmetTypesForm.style';
import { Input } from 'api/sharedComponents/reactHookFormComponents';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { Checkbox } from 'semantic-ui-react';
import Select from 'api/sharedComponents/reactHookFormComponents/select';

import {
  InputLabel,
  MenuItem,
  Select as MUISelect,
  makeStyles
} from '@material-ui/core';
import { Appointment_Types } from 'components/AvailabilityTemplateModalForm/EditForm/dropdownOptions';
import { FormError } from 'api/sharedComponents/reactHookFormComponents/error';

interface Props {
  initialValues: IAppointmentType;
  onFormClose: () => void;
  title: string;
  eventType: string;
  apptTypeTitles: string[];
}

const useStyles = makeStyles({
  muiLabel: {
    marginTop: '15px',
    color: 'black'
  },
  muiSelect: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      wordWrap: 'break-word',
      whiteSpace: 'normal'
    }
  }
});

export const mapDataToOptions = (data: SalesForceTherapyMappingsType[]) => {
  const mappedTitles = data.map((record: SalesForceTherapyMappingsType) => {
    const { id, salesforceTherapyTitle } = record;
    return { value: id, label: salesforceTherapyTitle };
  });
  return mappedTitles;
};

const AppointmentTypesForm: React.FC<Props> = ({
  initialValues,
  title,
  onFormClose,
  eventType,
  apptTypeTitles
}: Props) => {
  const {
    handleSubmit,
    setValue,
    errors,
    reset,
    control,
    trigger,
    getValues,
    formState: { isSubmitting, isDirty }
  } = useForm<IAppointmentType>({
    defaultValues: initialValues,
    resolver: yupResolver(
      APPOINTMENT_TYPE_SCHEMA(apptTypeTitles, initialValues?.title!)
    )
  });
  const { data: SFTherapyData } = useQuery(GET_SALESFORCE_THERAPY_MAPPINGS);
  const [submitAppointmentType] = useMutation(PUT_APPOINTMENT_TYPE);

  const handleIsEvaluationChange = (_event: any, data: any) => {
    setValue('isEvaluation', data.checked, { shouldDirty: true });
  };

  const handleAppointmentPaycodeChange = (AppointmentPaycode: String) => {
    setValue('appointmentPaycode', AppointmentPaycode, { shouldDirty: true });
  };

  const handleChange = (ActiveStatus: boolean) => {
    setValue('isActive', ActiveStatus, { shouldDirty: true });
  };

  const handleDayAvailTypesChange = (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    setValue(
      `${AppointmentType.dayAvailabilityTypes}`,
      event.target.value as string[],
      { shouldDirty: true }
    );
  };

  const salesForceTherapyListOptions = useMemo(
    () =>
      SFTherapyData ? mapDataToOptions(SFTherapyData.getSFTherapyMappings) : [],
    [SFTherapyData]
  );

  const dayAvailTypesClasses = useStyles();

  const onSubmit = handleSubmit(async (data: IAppointmentType) => {
    // clearing a "simple-column" via typeorm requires passing null or empty string, not empty arr
    if (data.dayAvailabilityTypes && data.dayAvailabilityTypes.length === 0)
      data.dayAvailabilityTypes = null;
    await submitAppointmentType({
      variables: {
        appointmentType: {
          ...data,
          id: Number(initialValues?.id),
          eventTypeName: eventType
        }
      },
      refetchQueries: [
        {
          query: GET_APPT_TYPES_CONFIG,
          variables: {
            eventType
          }
        }
      ]
    })
      .then(onFormClose)
      .catch(error => {
        console.log(
          'Error while saving appointment type',
          JSON.stringify(error)
        );
      });
  });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  return (
    <ConfigurationFormWrapper>
      <div className="header">
        <h3>{title}</h3>
        <button className="ui icon button close-button" onClick={onFormClose}>
          <i className="close icon"></i>
        </button>
      </div>

      <form onSubmit={onSubmit}>
        <FormItem label="Title">
          <Controller
            name={AppointmentType.title}
            control={control}
            render={props => (
              <Input
                errors={errors}
                placeholder="Input title"
                field={props}
              ></Input>
            )}
          />
        </FormItem>

        <Controller
          name={AppointmentType.ActiveStatus}
          control={control}
          render={Props => (
            <FormItem optional={false} label="Status">
              <Select
                className="appt-category-type"
                placeholder="Select Clinic"
                options={[
                  { label: 'Active', value: true },
                  { label: 'Inactive', value: false }
                ]}
                field={Props}
                onSelect={handleChange}
                errors={errors}
              />
            </FormItem>
          )}
        />

        <FormItem label="">
          <Controller
            name={AppointmentType.isEvaluation}
            control={control}
            render={() => (
              <Checkbox
                label={'Is Evaluation?'}
                onChange={handleIsEvaluationChange}
                style={{ width: '150px' }}
                checked={getValues('isEvaluation')}
              />
            )}
          />
        </FormItem>

        {initialValues?.id !== 0 && (
          <FormItem label="ID">
            <Controller
              name={AppointmentType.id}
              control={control}
              render={props => (
                <Input
                  errors={errors}
                  placeholder="Input ID"
                  field={props}
                  disabled
                ></Input>
              )}
            />
          </FormItem>
        )}

        <Controller
          name={AppointmentType.headerColor}
          control={control}
          render={() => (
            <ColorPickerInput
              title="Header Color Number"
              control={control}
              name={AppointmentType.headerColor}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
            ></ColorPickerInput>
          )}
        />

        <Controller
          name={AppointmentType.backgroundColor}
          control={control}
          render={() => (
            <ColorPickerInput
              title="Background Color Number"
              control={control}
              name={AppointmentType.backgroundColor}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
            ></ColorPickerInput>
          )}
        />

        <Controller
          name={`${AppointmentType.salesForceMapping}.id`}
          control={control}
          defaultValue={null}
          render={Props => (
            <FormItem
              optional={true}
              className="sf-therapy-mapping-field"
              label="Salesforce Therapy Title"
            >
              <Select
                className="appt-category-type"
                placeholder="Select Salesforce Title"
                options={salesForceTherapyListOptions}
                field={Props}
                errors={errors}
              />
            </FormItem>
          )}
        />

        <Controller
          name={AppointmentType.paycodes}
          control={control}
          defaultValue={''}
          render={Props => (
            <FormItem
              optional={false}
              className="Paycodes-field"
              label="Appointment Paycode"
            >
              <Select
                className="appt-category-type"
                placeholder="Select Paycode"
                options={[
                  { label: 'Direct', value: 'Direct' },
                  { label: 'Indirect', value: 'Indirect' },
                  { label: 'Excluded', value: 'Excluded' }
                ]}
                field={Props}
                onSelect={(paycode: String) =>
                  handleAppointmentPaycodeChange(paycode)
                }
                errors={errors}
              />
            </FormItem>
          )}
        />

        <Controller
          name={AppointmentType.dayAvailabilityTypes}
          control={control}
          render={() => (
            <>
              <InputLabel className={dayAvailTypesClasses.muiLabel}>
                Slot Types
              </InputLabel>
              <MUISelect
                className={dayAvailTypesClasses.muiSelect}
                multiple
                value={
                  getValues(`${AppointmentType.dayAvailabilityTypes}`) ?? []
                }
                error={Boolean(errors?.dayAvailabilityTypes)}
                onChange={handleDayAvailTypesChange}
                renderValue={(selected: unknown) => {
                  if (Array.isArray(selected)) {
                    const accountForCareSession = selected.map(
                      (item: string) => {
                        if (item === 'working') return 'Care Sessions';
                        return item;
                      }
                    );
                    return (accountForCareSession as string[]).join(', ');
                  }
                  return 'Rendering error, please report to engineering';
                }}
              >
                {Appointment_Types.map(dayAvailTypeOption => (
                  <MenuItem
                    key={dayAvailTypeOption.value}
                    value={dayAvailTypeOption.value}
                  >
                    {`${dayAvailTypeOption.label}`}
                  </MenuItem>
                ))}
              </MUISelect>
              {errors?.dayAvailabilityTypes && (
                <FormError
                  name={AppointmentType.dayAvailabilityTypes}
                  errors={errors}
                />
              )}
            </>
          )}
        />

        <MainButton
          className="submit-button"
          title="Save"
          onClick={() => {
            onSubmit();
          }}
          disabled={isSubmitting || !isDirty}
          loading={isSubmitting}
        />
      </form>
    </ConfigurationFormWrapper>
  );
};

export default React.memo(AppointmentTypesForm);
