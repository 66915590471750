import React from 'react';
import { LibSelect } from 'lib/ui/select/select.style';
import { Checkbox } from 'lib/ui/checkbox';
import { CheckBoxSelectionWrapper } from './style';
import { CaretDownOutlined } from '@ant-design/icons';

export interface IOptions {
  text: string;
  value: number;
}
interface IProps {
  options: IOptions[];
  placeHolderTitle: string;
  field: any;
  onChange: (isChecked: boolean, value: number) => void;
}

const FilterSelection: React.FC<IProps> = ({
  options,
  placeHolderTitle,
  field,
  onChange
}: IProps) => {
  return (
    <LibSelect
      suffixIcon={<CaretDownOutlined />}
      getPopupContainer={trigger => trigger.parentNode}
      placeholder={placeHolderTitle}
      dropdownRender={() => (
        <CheckBoxSelectionWrapper>
          {options.map(({ value, text }) => (
            <div key={value}>
              <Checkbox
                onChange={(event: any) => {
                  onChange(event.target.checked, value);
                }}
              >
                {text}
              </Checkbox>
            </div>
          ))}
        </CheckBoxSelectionWrapper>
      )}
      {...field}
    />
  );
};
export default React.memo(FilterSelection);
