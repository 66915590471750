import React, { Dispatch } from 'react';
import AuditView from './AuditView';
import TimeView from './TimeView';
import LocationView from './LocationView';
import StatusView from './StatusView';
import AttendeesView from './AttendeesView';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import { AppointmentViewWrapper, CheckboxWrapper } from './style';
import PendingAppointment from './PendingAppointment';
import { IEvent, IFullAppointmentType, IProvider } from 'model/v2';
import { IModalDownStreamsConfigurations } from 'utils/downstreams/DownStreamModalConfigurations';
import { EVENT_STATUS } from 'utils/constants/status';
import { EventTypes } from 'model/eventType';
import { ICancellationReason } from 'model';
import { ICalendarResource } from 'model/calendar/filters';

interface Props {
  handleEditTime: () => void;
  event: IEvent;
  reloadEvent: () => void;
  configurations: IModalDownStreamsConfigurations;
  cancellationReasons: ICancellationReason[];
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  providersMap: Map<number, IProvider>;
  apptTypes: IFullAppointmentType[];
  setVisible: (val: boolean) => void;
  isAddObservationMode: boolean;
  setIsAddObservationMode: Dispatch<React.SetStateAction<boolean>>;
  observersData: any;
  isLoading: boolean;
  initialLoadEvent: any;
}
const ClientView: React.FC<Props> = ({
  handleEditTime,
  event,
  reloadEvent,
  configurations,
  cancellationReasons,
  paginationArray,
  setPaginationArray,
  providersMap,
  apptTypes,
  setVisible,
  isAddObservationMode,
  setIsAddObservationMode,
  observersData,
  isLoading,
  initialLoadEvent
}: Props) => {
  const { canEditTime, status } = React.useMemo(() => {
    const canEditTime =
      moment().isAfter(event?.startDate) &&
      event?.appointmentType?.eventType?.name === EventTypes.ABA &&
      !configurations.rescheduleDisabled;
    return {
      canEditTime,
      status: event?.status || ''
    };
  }, [event, configurations]);

  return (
    <AppointmentViewWrapper>
      <>
        <div className="scrollable">
          <StatusView
            event={event}
            reloadEvent={reloadEvent}
            configurations={configurations}
            isLoading={isLoading && !initialLoadEvent}
          />
          <span className="appointment-status">
            {event?.isObservation && event?.status === EVENT_STATUS.canceled
              ? 'Cancelled Observation'
              : status}
          </span>
          <TimeView event={event} isLoading={isLoading && !initialLoadEvent} />
          {event?.status !== EVENT_STATUS.canceled &&
            event?.type !== 'adminEvent' && (
              <Button
                className="edit-time-btn"
                onClick={handleEditTime}
                disabled={!canEditTime || isLoading}
              >
                Edit Actual Time
              </Button>
            )}
          {event && (
            <AttendeesView
              event={event}
              paginationArray={paginationArray}
              setPaginationArray={setPaginationArray}
              providersMap={providersMap}
              apptTypes={apptTypes}
              setVisible={setVisible}
              setIsAddObservationMode={setIsAddObservationMode}
              isAddObservationMode={isAddObservationMode}
              observersData={observersData}
              isLoading={isLoading && !initialLoadEvent}
            />
          )}
          {event && event.isObservation !== undefined && (
            <LocationView
              event={event}
              isObservation={event.isObservation}
              isLoading={isLoading}
            />
          )}
          <AuditView
            event={event}
            cancellationReasons={cancellationReasons}
            isLoading={isLoading}
          />
        </div>
        {event?.isPendingConfirmation && (
          <CheckboxWrapper>
            <h2>Make Visible</h2>
            <PendingAppointment event={event} />
          </CheckboxWrapper>
        )}
      </>
    </AppointmentViewWrapper>
  );
};
export default React.memo(ClientView);
