import React, { useState, useCallback, useMemo, useRef } from 'react';
import { IAppointmentType, AppointmentType } from 'model/appointment';
import { ITableComp } from 'views/components/table/type';
import { useQuery } from 'react-apollo';
import { GET_APPT_TYPES_CONFIG } from 'api/graphql/v2/queries/AppointmentTypes';
import Content from 'views/components/ui/content';
import PushableSideBar from 'views/components/ui/sidebar';
import AppointmentTypesForm from 'views/components/forms/configurations/AppointmentTypesForm';
import { NEW_APPOINTMENT_CONFIG } from 'utils/constants/init';
import ConfigurationHeader from 'views/components/configurationHeader';
import TableComponent from 'views/components/table/Table';
import { useHistory } from 'react-router';
import { APPOINTMENT_COLUMNS } from 'views/components/table/tableColumns';
import { TableType } from 'model/tableType';
import { scrollToTop } from 'utils/math';
import {
  ISortValue,
  MAPPER_FUNCTION,
  mapToTableState
} from 'utils/mappers/configuration';
import { IAppointmentTypeConfig } from 'model/tableConfigurations';

interface IAppointmentTypeConfigProps {
  eventType: string;
  title: string;
}

const AppointmentTypeConfig: React.FC<IAppointmentTypeConfigProps> = ({
  eventType,
  title
}: IAppointmentTypeConfigProps) => {
  const history = useHistory();
  const [currentForm, setCurrentForm] = useState(-1);
  const [sortValue, setSortValue] = useState<ISortValue>();
  const [searchValue, setSearchValue] = useState('');
  const tableHeaders = APPOINTMENT_COLUMNS;
  const formValues = useRef(NEW_APPOINTMENT_CONFIG);

  const { loading, error, data } = useQuery(GET_APPT_TYPES_CONFIG, {
    variables: {
      eventType
    },
    fetchPolicy: 'cache-and-network'
  });

  const addNew = useCallback(
    (data?: TableType) => {
      if (data) formValues.current = data as IAppointmentType;
      else formValues.current = NEW_APPOINTMENT_CONFIG;
      scrollToTop();
      setCurrentForm(formValues.current.id!);
    },
    [setCurrentForm]
  );

  const tableData = useMemo(() => {
    return data
      ? (mapToTableState(
          data.appointmentTypesConfig,
          addNew,
          MAPPER_FUNCTION.appointmentTypes,
          searchValue,
          sortValue
        ) as IAppointmentTypeConfig[])
      : [];
  }, [data, addNew, searchValue, sortValue]);

  const onSearch = useCallback(
    value => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const onSort = useCallback(
    (comp: ITableComp, reverse: boolean) => {
      setSortValue({ comp, reverse });
    },
    [setSortValue]
  );

  const onFormClose = useCallback(() => {
    formValues.current = NEW_APPOINTMENT_CONFIG;
    setCurrentForm(-1);
  }, [setCurrentForm]);

  const onGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <PushableSideBar
        content={
          // update isClinial here
          <AppointmentTypesForm
            title={formValues.current.id ? `Edit ${title}` : `New ${title}`}
            initialValues={formValues.current}
            onFormClose={onFormClose}
            eventType={eventType}
            apptTypeTitles={(data?.appointmentTypesConfig as IAppointmentType[])?.map(
              type => type.title?.toLowerCase() || ''
            )}
          />
        }
        visible={currentForm !== -1}
        pageComponent={
          <Content
            loading={loading}
            error={error}
            data={data}
            fullHeight={true}
          >
            {() => (
              <>
                <ConfigurationHeader
                  addNew={addNew}
                  onSearch={onSearch}
                  title={title}
                  onClick={onGoBack}
                  visible={currentForm !== -1}
                />
                <TableComponent
                  headers={tableHeaders}
                  data={tableData}
                  sort={onSort}
                  primarySortedKey={AppointmentType.title}
                />
              </>
            )}
          </Content>
        }
      />
    </>
  );
};

export default AppointmentTypeConfig;
