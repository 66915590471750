import { getApptType } from 'utils/common';
import { pick } from 'lodash';

const getAuthLimit = (limit, pattern) => {
  /// Convert to backend in minutes
  return pattern === 'Hours' ? parseInt(limit * 60) : parseInt(limit);
};

export const mapToAuthGroupBE = (values, client, apptTypes, clinicsMap) => {
  /// add client data
  const authGroup = {
    ...values,
    clientID: client.id,
    clientName: client.name,
    clinicAbr: clinicsMap.get(client.clinicId)?.abbreviation,
    clinicID: client.clinicId
  };
  /// mapping to appointmentTypeUserInput: title -> object
  /// result.authTherapies should not be empty
  authGroup.authTherapies = authGroup.authTherapies.map(authTherapy => {
    const apptTypeIDs = authTherapy.apptTypes.map(apptTypeTitle =>
      pick(getApptType({ title: apptTypeTitle }, apptTypes), [
        'id',
        'isClinical'
      ])
    );

    /// creating new object is better for reference issues
    const mappedAuthTherapy = {
      ...pick(authTherapy, ['id', 'authPeriod', 'authPattern']),
      authLimit: getAuthLimit(authTherapy.authLimit, authTherapy.authPattern),
      apptTypeIDs
    };

    /// return new auth therapy reference with new fields
    return mappedAuthTherapy;
  });

  return authGroup;
};
