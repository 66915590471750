import { ALL_CANCELLATION_REASONS } from 'api/graphql/v2/queries/CancellationReasons';
import { ICancellationReason, IOption } from 'model';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { mapToOption } from 'utils/mappers/form';
const RESCHEDULE_REASONS_TYPE = 1;
export const useCancellationReasons = () => {
  const [cancelReasons, setCancelReasons] = useState<IOption[]>([]);
  const { data, loading: isCancelReasonsLoading } = useQuery(
    ALL_CANCELLATION_REASONS,
    {
      fetchPolicy: 'network-only'
    }
  );
  useEffect(() => {
    if (data) {
      const cancellationReasons: ICancellationReason[] = data.allCancellationReasons.filter(
        (cancelReason: ICancellationReason) =>
          cancelReason.type !== RESCHEDULE_REASONS_TYPE
      );
      setCancelReasons(
        mapToOption(
          cancellationReasons?.map(reason => {
            return {
              id: reason.reasonId,
              value: reason.name
            };
          })
        )
      );
    }
  }, [data]);

  return { cancelReasons, isCancelReasonsLoading };
};
