import styled from 'styled-components';

export const ModalWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
  margin-right: 30px;
  .button-wrapper {
    display: flex;
  }
  .center-text {
    display: flex;
    justify-content: center;
  }
`;

export const ModalBottom = styled.div`
  border-top: 1px solid #bbb;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 32px;
  button {
    border: none;
    font-size: 15px;
    border-radius: 0;
    padding: 0;
    &:first-of-type {
      color: #919699;
      height: 18px;
    }
    &:last-of-type {
      color: #0d77b5;
      border-bottom: 1px solid #0d77b5;
      margin-left: 24px;
      height: 18px;
    }
  }
`;

export const ModalMessage = styled.p`
  margin-bottom: 60px;
`;
