import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import RangeTimePicker from './RangeTimePicker';
import RangeTimePickerModal from './RangeTimePickerModal';
import { Range } from 'react-input-range';

const DEFAULT_HOURS = {
  MIN: 2,
  MAX: 4
};

export interface Duration {
  hour: number;
  minute: number;
}

export const getHoursFromTime = (duration: number) => Math.floor(duration / 60);
const getMinutesFromTime = (duration: number) => duration % 60;
const convertTimeToMinutes = (time: Duration) => time.hour * 60 + time.minute;

export const calculateRange = (min: Duration, max: Duration) => {
  return {
    min: min.hour * 4 + min.minute / 15,
    max: max.hour * 4 + max.minute / 15
  };
};

const ABA_SESSION_DURATION_FIELD_NAME = 'aba.sessionDuration';

const ABASessionDuration: React.FC = () => {
  const methods = useFormContext();
  const durations = methods.getValues(ABA_SESSION_DURATION_FIELD_NAME);

  const [min, setMin] = useState<Duration>({
    hour: durations ? getHoursFromTime(durations.min) : DEFAULT_HOURS.MIN,
    minute: durations ? getMinutesFromTime(durations.min) : 0
  });

  const [max, setMax] = useState<Duration>({
    hour: durations ? getHoursFromTime(durations.max) : DEFAULT_HOURS.MAX,
    minute: durations ? getMinutesFromTime(durations.max) : 0
  });

  const [range, setRange] = useState<Range>(calculateRange(min, max));

  useEffect(() => {
    methods.setValue(ABA_SESSION_DURATION_FIELD_NAME, {
      min: convertTimeToMinutes(min),
      max: convertTimeToMinutes(max)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);

  return (
    <Popover
      content={
        <RangeTimePickerModal
          range={range}
          setMin={setMin}
          setMax={setMax}
          setRange={setRange}
        />
      }
      trigger="focus"
      overlayStyle={{
        width: '430px',
        border: '1px solid #d9d9d9',
        borderRadius: '2px',
        paddingBottom: '0px'
      }}
    >
      <FormItem optional={false} label="Session Duration">
        <Controller
          name={ABA_SESSION_DURATION_FIELD_NAME}
          control={methods.control}
          render={() => (
            <RangeTimePicker
              min={min}
              max={max}
              setMin={setMin}
              setMax={setMax}
              setRange={setRange}
            ></RangeTimePicker>
          )}
        />
      </FormItem>
    </Popover>
  );
};

export default React.memo(ABASessionDuration);
