import styled from 'styled-components';

import { PURPLE } from 'utils/constants/theme';

export const AppointmentViewWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  .scrollable {
    overflow: scroll;
    padding: 0 24px;
    flex: 1;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
        inset 1px 1px 0px rgba(0, 0, 0, 0);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .appointment-status {
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
    text-transform: capitalize;
  }
  .statusMessage {
    color: white;
    justify-content: center;
    height: 32px;
    padding: 4%;
    margin: 2%;
    font-size: 12px;
    border-radius: 7px;
  }
  .fail {
    background: #e82424;
  }
  .success {
    background: #3d9912;
  }
  .nosync {
    background: #c3ccd9;
  }
  .pending,
  .cancelling {
    background: #daf0fc;
    color: black !important;
    font-size: 11px !important;
  }
  .resync-button {
    background: transparent;
    border-radius: 7px;
    border: 1px solid;
    font-size: 14px;
    height: 32px;
    margin-top: 20%;
    float: right;
    cursor: pointer;
  }
  .force-fail-button {
    background: transparent;
    border-radius: 7px;
    border: 1px solid;
    font-size: 14px;
    height: 32px;
    margin-top: 20%;
    float: right;
    cursor: pointer;
  }
  .onLoading {
    opacity: 0.2;
    cursor: not-allowed;
  }
  .status-icon {
    float: right;
    font-size: 1em !important;
  }
  .edit-time-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 152px;
    height: 32px;
    padding: 0 !important;
    border-radius: 8px !important;
    border: solid 2px ${PURPLE} !important;
    background-color: #fff !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: ${PURPLE} !important;
    margin: 0 0 20px 0 !important;
  }
  .notes-title {
    margin: 0 0 14px 0;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0px;
    color: #21303a;
  }
  .notes-content {
    margin: 0 0 20px 0;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.15px;
    color: #21303a;
  }
`;

interface Props {
  specialityColor?: string;
  isObservation?: boolean;
}

export const HeaderViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 95%;
  padding: 24px 24px 0 24px;
  background: #ffff;
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #21303a;
    margin: 0;
  }
  .icons-wrapper {
    display: flex;
    justify-content: flex-end;
    .anticon-close,
    .fa-ban,
    .fa-pen {
      cursor: pointer;
      margin-left: 16px;
      color: #415766;
      font-size: 18px;
    }
    .fa-eye {
      cursor: pointer;
      font-size: 21px;
      color: #415766;
    }
  }
`;

export const StatusViewWrapper = styled.div`
  margin-bottom: 20px;
  p {
    font-size: 13px;
    line-height: 1.38;
    color: #21303a;
    margin: 0;
  }
`;

export const TimeViewWrapper = styled.div`
  .appointment-type {
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
    margin-bottom: 12px;
  }
  .event-date,
  .event-time,
  .event-original-time,
  .recurring-pattern {
    font-size: 15px;
    color: #21303a;
    margin-bottom: 6px;
    &.event-original-time {
      text-decoration: line-through;
    }
  }
  p:last-of-type {
    margin-bottom: 16px;
  }
`;

export const AttendeesViewWrapper = styled.div<Props>`
  margin-bottom: 20px;
  .attendees-title {
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
    height: 24px;
    margin-bottom: 15px;
  }
  .add-observer-btn {
    background: transparent;
    border-radius: 5px;
    border: 1px solid #6f42f5;
    color: #6f42f5;
    padding: 2%;
    float: right;
    position: relative;
  }
  .add-observer-btn: hover {
    background: #6f42f5;
    border: none;
    color: white;
    curser: pointer;
  }
  .provider-btn {
    background: #6f42f5;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 4%;
    font-size: 12px;
  }
  .patient-btn {
    background: #17a8c2;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 4%;
    font-size: 12px;
  }
  .observerBtn {
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10%;
    font-size: 12px;
  }
  .observer1-btn {
    background: #45bfff;
  }
  .observer2-btn {
    background: #4d7bbd;
  }
  .observer3-btn {
    background: #45bfff;
  }
  .observer4-btn {
    background: #4d7bbd;
  }
  .observer5-btn {
    background: #45bfff;
  }
  .manage-btn {
    border: 1px solid #6f42f5;
    border-radius: 6px;
    color: #6f42f5;
    background: transparent;
    padding: 10%;
  }
  .adminEvent {
    margin-top: 20px;
  }
  .cancel-btn {
    background: transparent;
    border-radius: 5px;
    border: 1px solid #e82424;
    color: #e82424;
    padding: 4%;
    margin-right: 5%;
    width: 45%;
    top: -40px;
    position: relative;
  }
  .save-btn {
    background: transparent;
    border-radius: 5px;
    border: 1px solid #3d9912;
    color: #3d9912;
    padding: 4%;
    width: 45%;
    top: -40px;
    position: relative;
  }
  .save-btn: disabled {
    opacity: 0.1;
  }
  .observers-note {
    background: #6f42f5;
    color: white;
    border-radius: 5px;
    padding: 3%;
    font-size: 11px;
    display: flex;
    width: 100%;
  }
  .client-pic {
    width: 16%;
    margin: 0 8px 0 0;
    border-radius: 50%;
  }
  .attende-name-row {
    width: 64% !important;
  }
  .attende-name-label {
    width: 28% !important;
  }
  .attendees {
    display: flex;
    align-items: center;
    .avatar {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
      border-radius: 50%;
      border: solid 1px #979797;
      background-color: #d8d8d8;
    }
    .attende-name {
      font-size: 15px;
      color: #6f42f5;
      display: flex;
      align-items: center;
    }
    .client-bullet {
      width: 24px;
      height: 24px;
      border: solid 1px #979797;
      background-color: #d8d8d8;
      border-radius: 50%;
      margin-right: 5px;
    }
    .Lead-Select {
      padding-bottom: 16px;
    }
    .staff-bullet {
      width: 11px;
      height: 11px;
      background-color: ${props =>
        props.specialityColor ? props.specialityColor : '#45bfff'};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
`;

export const AppointmentAuthWrapper = styled.div`
  display: flex;
  margin: 0 0 32px 0;
  .auth-appointment-wrapper {
    width: 100%;
    padding: 0;
    display: unset;
    .no-authorizations {
      display: flex;
      align-items: center;
      margin: 0 0 9px 0;
      h4 {
        margin: 0 0 0 10px;
      }
    }
    h4 {
      font-family: Roboto;
      margin: 0 0 9px 0;
      font-size: 14px;
      font-weight: bold;
      color: #415766;
    }
    .wrapper_data {
      display: flex;
      margin: 0 0 14px 0;
      span {
        margin: 0 0 3px 0;
        opacity: 0.8;
        font-size: 15px;
        font-weight: bold;
        color: #21303a;
        text-align: unset;
      }
      .start_end {
        display: unset;
        padding: 0;
        display: flex;
        justify-content: unset;
        line-height: normal;
        span {
          font-size: 10px;
          color: #21303a;
          margin: 0;
          &.date-separator {
            display: inline-block;
            margin: 0 2px;
          }
        }
      }
    }
    .auth_therapies_data {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      & > div {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h5 {
          margin: 0 0 5px 0;
          font-family: Roboto;
          font-size: 10px;
          font-weight: bold;
          color: #000;
        }
        span {
          font-size: 15px;
          color: #21303a;
          width: unset;
        }
      }
    }
  }
`;

export const LocationViewWrapper = styled.div<Props>`
  margin-bottom: 20px;
  .location-title,
  .notes-title,
  .telehealth-title {
    margin-bottom: 2px;
    font-size: ${props => (props.isObservation ? '21px' : '16px')};
    font-weight: bold;
    color: #21303a;
    &.telehealth-title {
      font-weight: normal;
      margin: 0 0 14px 0;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 15px;
      color: #21303a;
      line-height: 1.53;
      .telehealth-link {
        color: #6f42f5;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      &.notes-li {
        margin-top: 20px;
        line-height: normal;
        .notes-content {
          max-height: 118px;
          line-height: normal;
          overflow-y: auto;
          font-size: 15px;
          color: #21303a;
          &::-webkit-scrollbar {
            height: 15px;
            width: 15px;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 50px;
            background-color: #c9cfd3;
            box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
              inset 1px 1px 0px rgba(0, 0, 0, 0);
          }
          &::-moz-scrollbar {
            height: 15px;
            width: 15px;
          }
          &::-moz-scrollbar-track {
            background-color: transparent;
          }
          &::-moz-scrollbar-thumb {
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 50px;
            background-color: #c9cfd3;
            box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
              inset 1px 1px 0px rgba(0, 0, 0, 0.05);
          }
          &::-ms-scrollbar {
            height: 15px;
            width: 15px;
          }
          &::-ms-scrollbar-track {
            background-color: transparent;
          }
          &::-ms-scrollbar-thumb {
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 50px;
            background-color: #c9cfd3;
            box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
              inset 1px 1px 0px rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
`;

export const AuditViewWrapper = styled.div<Props>`
  .history-title {
    font-size: ${props => (props.isObservation ? '21px' : '16px')};
    font-weight: bold;
    color: #21303a;
    margin-bottom: 2px;
  }
  .history-content {
    margin-bottom: 24px;
    p {
      margin: 0;
      font-size: 15px;
      line-height: 1.53;
      color: #21303a;
    }
    &:last-of-type {
      margin: 0;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  height: 80px;
  padding-left: 24px;
  h2 {
    font-size: 15px;
    color: #000;
    margin: 6px 15.5px 0 0 !important;
  }
  .ui.fitted.toggle.checkbox {
    label {
      &::after {
        width: 28px;
        height: 28px;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
          0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
        border: 0.5px solid rgba(0, 0, 0, 0.1);
        background-color: #fff;
      }
      &::before {
        width: 51px;
        height: 29px;
        border-radius: 15.5px;
        border: 1.5px solid rgba(0, 0, 0, 0.1);
        background-color: #fff;
      }
    }
    &.checked {
      label {
        &::after {
          left: 22px;
        }
        &::before {
          background-color: #4cd964 !important;
        }
      }
    }
  }
`;
export const AddObserversViewWrapper = styled.div`
  background: #fcfcfe;
  padding-top: 5px;
  .ui.disabled.dropdown {
    background: #c9cfd3 !important;
    width: 90%;
  }
  .lead-paragraph {
    margin-bottom: 0.3em;
  }
  .add-new-observer {
    border: none;
    background: transparent;
    float: right;
    font-size: 12px;
    text-decoration: underline;
  }
  .observer-input {
    margin-top: 1%;
    margin-bottom: 0%;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #6f42f5;
    width: 97%;
  }
  .observer-label {
    margin-bottom: 0px;
    margin-top: 2%;
  }
  .minus-column {
    padding-top: 9px !important;
    width: 20px !important;
    color: #e82424;
    display: flex !important;
    align-items: center;
    margin-top: 3% !important;
  }
  .search-icon {
    color: #6f42f5;
  }
  .delete-icon {
    position: absolute;
    top: 70%;
    font-size: 18px;
    color: #e82424;
  }
  .antselect {
    margin-bottom: 10px;
  }
  .ant-select {
    border-radius: 10px;
  }
  .Observer_Select {
    margin-bottom: 0px;
    border-radius: 10px;
  }
  .Observer_Input {
    width: 100%;
    height: 32px;
  }
  .ui.grid > .row {
    padding-bottom: 0px;
  }
  .anticon-minus-circle {
    margin-top: 7px;
  }
  .observer_alert {
    position: absolute;
    left: 0%;
    right: 71.74%;
    top: 78.71%;
    bottom: 0%;
    height: 150px;
    width: 100%;
    border-radius: 0px;
    background: #ffffff;
  }
  .observer_alert .content {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 10px;
    padding: 0px 10px 20px 10px;
    background: #ffffff;
  }
  .observer_alert .closeButton {
    float: right;
    font-size: 20px;
    padding: 10px;
  }
  .observer_alert .attention {
    font-family: Lato;
    font-size: 20px;
    padding-top: 40px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
`;

export const AddObserversFormWrapper = styled.div`
  margin-top: 20px;
  .aba_observer_div {
    width: 90%;
    float: left;
  }
  .aba_observer_trash {
    width: 8%;
    padding-left: 2%;
    padding-top: 25px;
    font-size: 20px;
    float: right;
    cursor: pointer;
  }
  .Observer_alert {
    border: 1px solid #e82424;
    border-radius: 5px;
  }
  .Observer_required {
    color: #e82424;
  }
`;
