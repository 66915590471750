import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

const Loader = ({ isSidebar, scroll, fullHeight }) => {
  const loaderStyleForScroll = scroll && {
    position: 'relative',
    width: '100%',
    height: '30px',
    background: '#fff',
    zIndex: 10,
    alignItems: 'center'
  };
  const loaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: isSidebar ? '40%' : fullHeight ? '50vh' : '50%',
    width: isSidebar ? '261px' : '95%',
    ...loaderStyleForScroll
  };
  return <PulseLoader css={loaderStyle} size={7} color={'#6f4bf1'} />;
};

export default Loader;
