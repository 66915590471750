export const Appointment_Types = [
  {
    value: 'ABA Assessment: Parent Interview',
    label: 'ABA Assessment: Parent Interview',
    color: 'rgb(255, 190, 56, 0.6)',
    borderColor: '#6887CA',
    colorRank: 2
  },
  {
    value: 'ABA Assessment: Observation',
    label: 'ABA Assessment: Observation',
    color: 'rgb(250, 212, 172, 0.6)',
    borderColor: '#fad4ac',
    colorRank: 2
  },
  {
    value: 'ADIR',
    label: 'ADIR',
    color: 'rgb(76, 0, 51, 0.5)',
    borderColor: '#00228D',
    colorRank: 2
  },
  {
    value: 'ADIR Follow Up',
    label: 'ADIR Follow Up',
    color: 'rgb(150, 77, 79, 0.5)',
    borderColor: '#407271',
    colorRank: 2
  },
  {
    value: 'ADOS',
    label: 'ADOS',
    color: 'rgb(10, 0, 30, 0.5)',
    borderColor: '#00228D',
    colorRank: 2
  },
  {
    value: 'Admin Work',
    label: 'Admin Work',
    color: 'rgb(185, 203, 221, 0.4)',
    borderColor: '#B9CBDD',
    colorRank: 2
  },
  {
    value: 'Care Plan Meeting',
    label: 'Care Plan Meeting',
    color: 'rgb(247, 235, 224, 0.7)',
    borderColor: '#D69962',
    colorRank: 2
  },
  {
    value: 'working',
    label: 'Care Sessions',
    color: 'rgb(137, 241, 232, 0.4)',
    borderColor: 'rgb(137, 241, 232)',
    colorRank: 0
  },
  {
    value: 'CIM',
    label: 'CIM',
    color: 'rgb(22, 24, 23, 0.3)',
    borderColor: '#407271',
    colorRank: 2
  },
  {
    value: 'DT Eval Slot',
    label: 'DT Eval Slot',
    color: 'rgb(164, 219, 179, 0.4)',
    borderColor: 'rgb(164, 219, 179)',
    colorRank: 2
  },
  {
    value: 'EEG Read',
    label: 'EEG Read',
    color: 'rgb(22, 245, 137, 0.2)',
    borderColor: '#16f589',
    colorRank: 2
  },
  {
    value: 'Lunch',
    label: 'Lunch',
    color: 'rgba(214, 194, 98, 0.2)',
    borderColor: '#d6c262',
    colorRank: 1
  },
  {
    value: 'MD Care Sessions',
    label: 'MD Care Sessions',
    color: 'rgb(137, 241, 232, 0.4)',
    borderColor: 'rgb(137, 241, 232)',
    colorRank: 2
  },
  {
    value: 'Meeting',
    label: 'Meeting',
    color: 'rgba(208, 98, 214, 0.2)',
    borderColor: '#d062d6',
    colorRank: 1
  },
  {
    value: 'MP Neuro Eval',
    label: 'MP Neuro Eval',
    color: 'rgb(42, 91, 93, 0.5)',
    borderColor: '#151513',
    colorRank: 2
  },
  {
    value: 'Notes',
    label: 'Notes',
    color: 'rgba(98, 141, 214, 0.2)',
    borderColor: '#628dd6',
    colorRank: 1
  },
  {
    value: 'Parent Training',
    label: 'Parent Training',
    color: 'rgba(190, 109, 183, 0.4)',
    colorRank: 2
  },
  {
    value: 'Welcome Visit',
    label: 'Welcome Visit',
    color: 'rgb(18, 47, 78, 0.2)',
    borderColor: '#0F7AF4',
    colorRank: 2
  }
];

export const SubBlock_Length = [
  { value: 0, label: 'None' },
  { value: 10, label: '10 min' },
  { value: 15, label: '15 min' },
  { value: 20, label: '20 min' },
  { value: 30, label: '30 min' },
  { value: 40, label: '40 min' },
  { value: 45, label: '45 min' },
  { value: 50, label: '50 min' },
  { value: 60, label: '1 hour' },
  { value: 120, label: '2 hours' }
];

export const Notes_Length = [
  { value: 10, label: '10 min' },
  { value: 15, label: '15 min' },
  { value: 20, label: '20 min' },
  { value: 30, label: '30 min' },
  { value: 40, label: '40 min' },
  { value: 45, label: '45 min' },
  { value: 50, label: '50 min' },
  { value: 60, label: '1 hour' },
  { value: 120, label: '2 hours' }
];

export const NotesAmount = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' }
];
