import {
  defaultDataIdFromObject,
  InMemoryCache,
  ApolloReducerConfig
} from 'apollo-cache-inmemory';

/* Custom ID generator for Apollo Cache */
const constructObjectId: ApolloReducerConfig['dataIdFromObject'] = (
  object: any
) => {
  switch (object.__typename) {
    case 'AppointmentTypeEvent': // event clinical and ABA appointment types may have same IDs
      return `${defaultDataIdFromObject(object)}-${object.isClinical}-${
        object.subType // events with same appointment types may have different subtypes
      }`;
    case 'OptionType':
      return `${defaultDataIdFromObject(object)}-${object.value}`;
    default:
      return defaultDataIdFromObject(object);
  }
};

export const updateCacheAfterFetchMore = ({
  resource,
  prevData,
  fetchMoreResult
}: {
  resource: string;
  prevData: any;
  fetchMoreResult: any;
}) => {
  if (!fetchMoreResult || !prevData) return prevData;
  return Object.assign({}, prevData, {
    data: [...prevData[resource].data, ...fetchMoreResult[resource].data]
  });
};

const cache = new InMemoryCache({
  dataIdFromObject: constructObjectId
});

export default cache;
