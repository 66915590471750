import React from 'react';
import { IProvider } from 'model/v2';
import UserIcon from 'assets/img/user-icon.svg';
import RemoveIcon from 'assets/img/remove-btn.svg';

interface Props {
  selectedProvidersList: number[];
  allProvidersMap: Map<number, IProvider>;
  onClearSelectedList: () => void;
  onRemoveProvider: (id: number) => void;
}

const SelectedProviders = ({
  selectedProvidersList,
  allProvidersMap,
  onClearSelectedList,
  onRemoveProvider
}: Props) => {
  return (
    <>
      <div>
        <span className="clear-list" onClick={onClearSelectedList}>
          Clear List
        </span>
      </div>
      {selectedProvidersList.map(id => {
        return (
          <div className="row-position" key={id}>
            <img
              alt="user"
              src={UserIcon}
              width={25}
              style={{ padding: '4px', marginBottom: '5px' }}
            />
            <label>
              {allProvidersMap.get(id)?.name}, (
              {allProvidersMap.get(id)?.clinic?.abbreviation || ''})
            </label>
            <img
              alt="remove"
              src={RemoveIcon}
              width={19}
              style={{ padding: '5px', marginBottom: '2px', cursor: 'pointer' }}
              onClick={() => onRemoveProvider(id)}
            />
          </div>
        );
      })}
    </>
  );
};
export default React.memo(SelectedProviders);
