import React, { Dispatch, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { formatAttendeeName } from '../../../utils/format';
import { AttendeesViewWrapper } from './style';
import { Grid, Icon } from 'semantic-ui-react';
import { EyeOutlined } from '@ant-design/icons';
import { IEvent, IProvider, SidebarState } from 'model/v2';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';

interface Props {
  leadEvent: IEvent;
  provider?: IProvider;
  openSidebar: (sidebarState: SidebarState) => void;
  setIsAddObservationMode: Dispatch<React.SetStateAction<boolean>>;
  LeadProvider: any;
}
const AttendeesView: React.FC<Props> = ({
  leadEvent,
  provider,
  openSidebar,
  LeadProvider
}: Props) => {
  const { provider: leadProvider, client, observations } = leadEvent;
  const leadProviderName = formatAttendeeName(
    leadProvider?.firstName,
    leadProvider?.lastName,
    leadProvider?.clinic?.abbreviation,
    leadProvider?.speciality?.abbreviation
  );
  const clientName = formatAttendeeName(
    client?.firstName,
    client?.lastName,
    client?.clinic?.abbreviation
  );
  let observersCount: number | undefined;
  observersCount = observations?.length;
  let attendees: number | undefined;
  if (observersCount) {
    attendees = 2 + observersCount;
  }

  const onManageClick = useCallback(() => {
    openSidebar({
      event: { id: leadEvent?.id, status: leadEvent?.status },
      action: SIDEBAR_ACTIONS.VIEW,
      isRedirected: true
    });
  }, [leadEvent.id, leadEvent.status, openSidebar]);
  return (
    <AttendeesViewWrapper specialityColor={provider?.speciality?.color}>
      <Grid columns={2} style={{ marginTop: '0%' }}>
        <Grid.Row>
          <Grid.Column width={8}>
            <h2 className="attendees-title">Attendees ({attendees})</h2>
          </Grid.Column>
          <Grid.Column floated="right" width={4}>
            <button
              disabled={!LeadProvider.isObservable}
              className="manage-btn"
              onClick={onManageClick}
            >
              Manage
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={2}>
        {' '}
        {observations &&
          observations.map((observer: any) => {
            const observerName = formatAttendeeName(
              observer?.provider?.firstName,
              observer?.provider?.lastName,
              observer?.provider?.clinic?.abbreviation
            );
            return (
              <Grid.Row>
                <Grid.Column width={9}>
                  <div className="attendees">
                    <div className="staff-bullet" />
                    <Link
                      className="attende-name"
                      target="_blank"
                      to={`/providers/${observer.provider?.id}`}
                    >
                      {observerName}
                    </Link>
                  </div>
                </Grid.Column>
                <Grid.Column width={7}>
                  <button
                    className={`observer-btn observer${observations.indexOf(
                      observer
                    ) + 1}-btn `}
                  >
                    <EyeOutlined /> Observer{' '}
                    {observations.indexOf(observer) + 1}
                  </button>
                </Grid.Column>
              </Grid.Row>
            );
          })}
        {leadProvider && (
          <Grid.Row>
            <Grid.Column width={9}>
              <div className="attendees">
                <div className="staff-bullet" />
                <Link
                  className="attende-name"
                  target="_blank"
                  to={`/providers/${leadProvider.id}`}
                >
                  {leadProviderName}
                </Link>
              </div>
            </Grid.Column>
            <Grid.Column width={7}>
              <button className="provider-btn">
                <EyeOutlined /> Lead Provider
              </button>
            </Grid.Column>
          </Grid.Row>
        )}
        {client && (
          <Grid.Row>
            <Grid.Column width={9}>
              <div className="attendees">
                <div className="client-bullet" />
                <Link
                  className="attende-name"
                  target="_blank"
                  to={`/clients/${client?.id}`}
                >
                  {clientName}
                </Link>
              </div>
            </Grid.Column>
            <Grid.Column width={7}>
              <button className="patient-btn">
                <Icon.Group size="large">
                  <Icon size="small" name="square outline" />
                  <Icon
                    size="tiny"
                    name="user outline"
                    style={{ top: '40%', left: '40%' }}
                  />
                </Icon.Group>
                Patient
              </button>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </AttendeesViewWrapper>
  );
};
export default React.memo(AttendeesView);
