import { LocationTypes } from 'model/event';
import { ClientDayAvailabilityOutput, IClinic } from 'model/v2';
import React from 'react';
import { formatTime } from 'utils/format';
import { AvailabilityBlockWrapper } from './style';

interface IProps {
  availabilityBlock: ClientDayAvailabilityOutput;
  clientClinicNetwork: IClinic[];
}

// Location is encoded in binary representation
// 2^0 --> telehealth
// 2^1 --> inClinic
// 2^2 --> offsite

const getLocationList = (encodedLocation: number) => {
  const locationList: string[] = [];
  switch (encodedLocation) {
    case 1:
      locationList.push(LocationTypes.teleHealth);
      break;
    case 2:
      locationList.push(LocationTypes.inClinic);
      break;
    case 3:
      locationList.push(LocationTypes.inClinic);
      locationList.push(LocationTypes.teleHealth);
      break;
    case 4:
      locationList.push(LocationTypes.offSite);
      break;
    case 5:
      locationList.push(LocationTypes.offSite);
      locationList.push(LocationTypes.teleHealth);
      break;
    case 6:
      locationList.push(LocationTypes.inClinic);
      locationList.push(LocationTypes.offSite);
      break;
    case 7:
      locationList.push(LocationTypes.AnyAvailability);
      break;
  }
  return locationList;
};
const ClientAvailabilityBlock: React.FC<IProps> = ({
  availabilityBlock,
  clientClinicNetwork
}) => {
  const getLocation = () => {
    const locationList = getLocationList(availabilityBlock.location);
    return <span> {locationList.join(', ')}</span>;
  };

  const getClinicNetwork = () => {
    return clientClinicNetwork && clientClinicNetwork.length > 0 ? (
      <span>
        ({clientClinicNetwork.map(clinic => clinic.abbreviation).join(',')})
      </span>
    ) : (
      ''
    );
  };
  return (
    <AvailabilityBlockWrapper>
      <span>Available Between </span>
      <span>{formatTime(availabilityBlock.startTime)} - </span>
      <span>{formatTime(availabilityBlock.endTime)} </span>
      <span> {getLocation()} </span>
      <span>{getClinicNetwork()}</span>
    </AvailabilityBlockWrapper>
  );
};

export default ClientAvailabilityBlock;
