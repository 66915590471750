export enum CombinedAppointmentType {
  id = 'id',
  isClinical = 'isClinical'
}
export interface ICombinedAppointmentType {
  id: number;
  isClinical: Boolean;
}

export interface ICombinedAppointmentTypeOptionsList {
  id?: number;
  title?: string;
  isClinical?: string;
}
