import { ClockCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { EDIT_EVENT_TIME } from 'api/graphql/v2/mutations/Event';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import ModalSemantic from 'components/modalSemantic';
import { Button } from 'lib/ui';
import { IEvent } from 'model/v2';
import moment from 'moment';
import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';
import { updateCalendarPref } from 'utils/cache/calendar';
import { plannerContext } from 'pages/MainCalendarPage';
import { DEFAULT_EDIT_TIME_NOTE } from 'utils/constants/default';
import { APPOINTMENT_BY_MINUTE_VALIDATOR } from 'utils/validators/appointmentByMinute';
import {
  getEndOffset,
  getStartOffset,
  setOptions,
  TIME_PADDING
} from 'views/components/calendar/mainCalendar/ModalEditTime';
import { FormItem, TextArea } from 'views/containers/form';
import { EditTimeModalWrapper } from './style';

interface Props {
  onClose: () => void;
  event: IEvent;
  doFullEventRequest: (val: any) => void;
  setVisible: any;
}
const EditTimeModal: React.FC<Props> = ({
  onClose,
  event,
  doFullEventRequest,
  setVisible
}: Props) => {
  return (
    <ModalSemantic
      open={true}
      onClose={onClose}
      trigger={<div></div>}
      content={
        <EditTimeForm
          event={event}
          onClose={onClose}
          doFullEventRequest={doFullEventRequest}
          setVisible={setVisible}
        />
      }
    />
  );
};
export default React.memo(EditTimeModal);

const EditTimeForm: React.FC<Props> = ({
  event,
  onClose,
  doFullEventRequest,
  setVisible
}: Props) => {
  const { SetEditActualTimeState } = useContext(plannerContext);
  const methods = useForm({
    defaultValues: {
      startTimeOffset: getStartOffset(event.originalStartDate, event.startDate),
      endTimeOffset: getEndOffset(
        event.originalStartDate,
        event.startDate,
        event.endDate
      ),
      id: event.id,
      notes: DEFAULT_EDIT_TIME_NOTE
    },
    resolver: yupResolver(APPOINTMENT_BY_MINUTE_VALIDATOR)
  });

  const notes = useWatch({ name: 'notes', control: methods.control });

  const [startTimeOptions, setStartTimeOptions] = React.useState([]);
  const [endTimeOptions, setEndTimeOptions] = React.useState([]);
  const validOptions = React.useRef([]);
  React.useEffect(() => {
    setOptions(
      event.startDate,
      event.endDate,
      event.originalStartDate,
      event.originalEndDate,
      validOptions,
      setStartTimeOptions,
      setEndTimeOptions,
      TIME_PADDING /// 1 hour padding before and after
    );
  }, [event]);
  const [saving, setSaving] = React.useState(false);
  const [editEventTime] = useMutation(EDIT_EVENT_TIME);
  const submitForm = React.useCallback(
    (data: any) => {
      setSaving(true);
      const eventStart = moment(event.originalStartDate || event.startDate);
      const editedStart = eventStart
        .clone()
        .add(data.startTimeOffset, 'minutes');
      editEventTime({
        variables: {
          data: {
            event: {
              startDate: editedStart
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              endDate: eventStart
                .clone()
                .add(data.endTimeOffset, 'minutes')
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              id: event.id
            },
            notes: data.notes
          }
        }
      })
        .then(() => {
          setSaving(false);
          updateCalendarPref(editedStart);
          onClose();
          setVisible(false);
          SetEditActualTimeState(false);
          doFullEventRequest({
            variables: { id: event.id }
          });
        })
        .catch(() => {
          setSaving(false);
        });
    },
    [
      event.originalStartDate,
      event.startDate,
      event.id,
      editEventTime,
      onClose,
      setVisible,
      doFullEventRequest
    ]
  );
  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);
  return (
    <EditTimeModalWrapper>
      <FormProvider {...methods}>
        <Controller name="id" control={methods.control} render={() => <></>} />

        <h2 className="edit-appointment-title">Edit Appointment Time</h2>

        <div>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column className="start-time">
                <FormItem optional={false} label="Start Time">
                  <Controller
                    name="startTimeOffset"
                    control={methods.control}
                    render={fieldProps => (
                      <Select
                        suffixIcon={<ClockCircleOutlined />}
                        options={startTimeOptions}
                        field={fieldProps}
                        errors={methods.errors}
                      />
                    )}
                  />
                </FormItem>
              </Grid.Column>
              <Grid.Column className="end-time">
                <FormItem optional={false} label="End Time">
                  <Controller
                    name="endTimeOffset"
                    control={methods.control}
                    render={fieldProps => (
                      <Select
                        suffixIcon={<ClockCircleOutlined />}
                        options={endTimeOptions}
                        field={fieldProps}
                        errors={methods.errors}
                      />
                    )}
                  />
                </FormItem>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div className="notes-wrapper">
          <FormItem optional={true} label="Notes">
            <Controller
              name="notes"
              control={methods.control}
              render={() => (
                <TextArea
                  name="notes"
                  placeholder="N/A"
                  value={notes}
                  onChange={(e: any) =>
                    methods.setValue('notes', e.target.value)
                  }
                  errors={methods.errors}
                />
              )}
            />
          </FormItem>
        </div>

        <div className="modal-actions">
          <Button
            className="cancel-btn"
            onClick={onClose}
            type=""
            loading={saving}
            disabled={saving}
          >
            Cancel
          </Button>

          <Button
            className="ok-btn"
            onClick={methods.handleSubmit(submitForm, submitError)}
            type="submit"
            loading={saving}
            disabled={saving}
          >
            Ok
          </Button>
        </div>
      </FormProvider>
    </EditTimeModalWrapper>
  );
};
