import { useApptTypesWithAdmin } from 'api/graphql/v2/hooks/appointmentTypes';
import { useClinicDataMap } from 'api/graphql/v2/hooks/clinics';
import { useEventSyncStatus } from 'api/graphql/v2/hooks/eventSyncStatus';
import {
  useBulkRecurringEventsCount,
  useDeleteWhenCancelAppointmentsIds
} from 'api/graphql/v2/hooks/recurringEvents';
import {
  GET_FULL_EVENT,
  GET_OBSERVATION_OF_LEAD_EVENT
} from 'api/graphql/v2/queries/Events';
import { ICalendarResource } from 'model/calendar/filters';
import { IEvent, IFullEvent, SidebarState } from 'model/v2';
import React, { Dispatch } from 'react';
import { useLazyQuery } from 'react-apollo';
import { EVENT_CATEGORY } from 'utils/constants/lists';
import { EVENT_STATUS } from 'utils/constants/status';
import { getDownStreamStatusConfigurations } from 'utils/downstreams/DownStreamModalConfigurations';
import Content from 'views/components/ui/content';
import CancelModal from '../AppointmentModals/CancelModal/index';
import EditTimeModal from '../AppointmentModals/EditTimeModal';
import AdminView from './AdminView';
import ClientView from './ClientView';
import HeaderView from './HeaderView';
import { AppointmentViewWrapper } from './style';
import { GET_OBSERVERS } from 'api/graphql/v2/queries/Providers';
import { useQuery } from '@apollo/react-hooks';
import { ALL_CANCELLATION_REASONS } from 'api/graphql/v2/queries/CancellationReasons';
import { FAKE_LOADING_DATA } from './SkeletonData';
import { ICalendarEvent } from 'model/calendar/events';
import { useReactiveVar } from '@apollo/client';
import { calendarEventsPrefVar } from 'utils/cache/filters';

interface Props {
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  isAddObservationMode: boolean;
  setIsAddObservationMode: Dispatch<React.SetStateAction<boolean>>;
}
const AppointmentView: React.FC<Props> = ({
  setVisible,
  sidebarState,
  paginationArray,
  setPaginationArray,
  isAddObservationMode,
  setIsAddObservationMode
}: Props) => {
  const [edit, setEdit] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState<{
    athenaStatus?: number;
    crStatus?: number;
  }>({});

  const [
    doFullEventRequest,
    { data, loading: loadingData, error, refetch }
  ] = useLazyQuery(GET_FULL_EVENT, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const { crStatus, athenaStatus } = (data?.event || {}) as IEvent;
      setCurrentStatus({ crStatus, athenaStatus });
    }
  });

  const [
    doCancellationsReasonsRequest,
    { data: cancellationReason, loading: loadingCancellationReason }
  ] = useLazyQuery(ALL_CANCELLATION_REASONS, {
    fetchPolicy: 'cache-and-network'
  });

  const [
    getObservationOfLeadEvent,
    { loading: observationOfLeadEventLoading, data: observationOfLeadEvent }
  ] = useLazyQuery(GET_OBSERVATION_OF_LEAD_EVENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ObserverData => {
      console.log('Data for observer', ObserverData);
    }
  });

  React.useEffect(() => {
    if (sidebarState.event?.id) {
      doFullEventRequest({
        variables: {
          id: sidebarState.event?.id,
          canceled: sidebarState.event?.status === EVENT_STATUS.canceled
        }
      });
      doCancellationsReasonsRequest();
      getObservationOfLeadEvent({
        variables: {
          id: sidebarState.event?.id
        }
      });
    }
  }, [
    sidebarState,
    doFullEventRequest,
    doCancellationsReasonsRequest,
    getObservationOfLeadEvent
  ]);

  const isConverted = data?.isConverted;
  const { type } = (data?.event || {}) as IEvent;
  const { crStatus, athenaStatus } = useEventSyncStatus({
    eventId: sidebarState.event?.id
  });

  React.useEffect(() => {
    if (crStatus) {
      setCurrentStatus({ athenaStatus: currentStatus.athenaStatus, crStatus });
      if (data?.event) {
        data.event.crStatus = crStatus;
        data.event.athenaStatus = currentStatus.athenaStatus;
      }
    }
    if (athenaStatus) {
      setCurrentStatus({ crStatus: currentStatus.crStatus, athenaStatus });
      if (data?.event) {
        data.event.crStatus = currentStatus.crStatus;
        data.event.athenaStatus = athenaStatus;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crStatus, athenaStatus]);
  const isAdminEvent = type && type === EVENT_CATEGORY[1].value;

  const handleEditTime = React.useCallback(() => {
    setEdit(!edit);
  }, [edit, setEdit]);

  const handleCancel = React.useCallback(() => {
    setCancel(!cancel);
  }, [cancel, setCancel]);

  const configurations = getDownStreamStatusConfigurations(
    currentStatus?.crStatus,
    currentStatus?.athenaStatus,
    isConverted
  );

  const {
    recurringBulkEventsCount,
    recurringBulkEventsCountLoading
  } = useBulkRecurringEventsCount(
    [data?.event?.id],
    data?.event?.id === undefined
  );

  const {
    deleteWhenCancelAppointmentsIds,
    deleteWhenCancelAppointmentsIdsLoading
  } = useDeleteWhenCancelAppointmentsIds(data?.event?.id === undefined);

  const {
    providersMap,
    loadingClinics: isLoadingProvidersMap
  } = useClinicDataMap(sidebarState.action);

  const { apptTypes, loadingApptTypes } = useApptTypesWithAdmin();

  const { loading: isLoadingObservers, data: observersData } = useQuery(
    GET_OBSERVERS,
    {
      variables: {
        status:
          data?.event?.observations && data?.event?.observations.length > 0
            ? 'Edit'
            : 'Add'
      },
      fetchPolicy: 'network-only',
      onCompleted: observerData => {
        console.log(observerData, 'ObserverProvider Data Check ');
      }
    }
  );

  const events: ICalendarEvent[] = useReactiveVar(calendarEventsPrefVar);
  let initialLoadEvent: any = null;
  events.forEach(event => {
    if (event.id === sidebarState.event?.id) {
      initialLoadEvent = event;
    }
  });
  const isLoadingSidebar =
    loadingData ||
    loadingCancellationReason ||
    recurringBulkEventsCountLoading ||
    deleteWhenCancelAppointmentsIdsLoading ||
    isLoadingProvidersMap ||
    loadingApptTypes ||
    isLoadingObservers ||
    observationOfLeadEventLoading;

  const transformed_FAKE_LOADING_DATA = {
    event: FAKE_LOADING_DATA,
    isLastEventInSeries: false,
    isConverted: false
  };
  const transformed_initialLoadEvent = {
    event: initialLoadEvent,
    isLastEventInSeries: false,
    isConverted: false
  };

  console.log(isLoadingSidebar);

  return (
    <AppointmentViewWrapper>
      <HeaderView
        setVisible={setVisible}
        event={data?.event}
        handleCancel={handleCancel}
        configurations={configurations}
        setIsAddObservationMode={setIsAddObservationMode}
        isLoading={loadingData}
      />
      <Content
        loading={false}
        error={
          error || data?.event === null
            ? new Error("Event Couldn't be found")
            : undefined
        }
        data={
          data
            ? data
            : transformed_initialLoadEvent
            ? transformed_initialLoadEvent
            : transformed_FAKE_LOADING_DATA
        }
      >
        {(data: IFullEvent) => {
          return (
            <>
              {edit && (
                <EditTimeModal
                  event={data?.event}
                  setVisible={setVisible}
                  onClose={handleEditTime}
                  doFullEventRequest={doFullEventRequest}
                />
              )}
              {cancel && (
                <CancelModal
                  event={data?.event}
                  isLastEventInSeries={!!data?.isLastEventInSeries}
                  onClose={handleCancel}
                  setVisible={setVisible}
                  cancellationReasons={
                    cancellationReason?.allCancellationReasons
                  }
                  recurringEventsCount={
                    recurringBulkEventsCount?.getBulkRecurringEventsCount
                  }
                  deleteWhenCancelAppointmentsIds={
                    deleteWhenCancelAppointmentsIds?.deleteWhenCancelAppointmentsIds
                  }
                  observationOfLeadEvent={
                    observationOfLeadEvent?.observationOfSeriesLeadEvent
                  }
                />
              )}
              {isAdminEvent && (
                <AdminView
                  event={data?.event}
                  reloadEvent={refetch}
                  configurations={configurations}
                />
              )}
              {!isAdminEvent && (
                <ClientView
                  handleEditTime={handleEditTime}
                  event={data?.event}
                  reloadEvent={refetch}
                  configurations={configurations}
                  cancellationReasons={
                    cancellationReason?.allCancellationReasons
                  }
                  setPaginationArray={setPaginationArray}
                  paginationArray={paginationArray}
                  providersMap={providersMap}
                  apptTypes={apptTypes}
                  setVisible={setVisible}
                  setIsAddObservationMode={setIsAddObservationMode}
                  isAddObservationMode={isAddObservationMode}
                  observersData={observersData}
                  isLoading={loadingData}
                  initialLoadEvent={initialLoadEvent}
                />
              )}
            </>
          );
        }}
      </Content>
    </AppointmentViewWrapper>
  );
};
export default React.memo(AppointmentView);
