import styled from 'styled-components';

export const TitleWrapper = styled.div`
  min-height: 96px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8ecef;
  position: relative;
  h1 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.38px;
  }
  .inner-wrap {
    box-sizing: border-box;
    padding: 16px 16px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      display: none;
    }

    &.staff-filters {
      > div {
        width: 100%;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 15px;
    }
  }
  .dropdown-wrapper {
    display: flex;
  }
  .dropdown-wrapper > div {
    margin: 0;
    padding-right: 15px;
  }
  .dropdown-wrapper > div:last-child {
    padding-right: 0px;
  }
  .provider-title {
    padding: 0px;
    margin: 0px 0px 0px 34px;
    padding-top: 28px;
    border-bottom: 0px;
  }
  .provider-title-outer-wrap {
    display: flex;
    justify-content: space-between;
  }
  .Add-Staff-btn {
    margin: 12px 22px 0px 34px;
  }
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  .inner-wrap {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 160px);
    margin: 0 auto;
    max-width: 1280px;
    padding: 0;
    .ant-table-content {
      &:after {
        content: none;
      }
    }
    .ant-table-thead {
      th {
        border: none;
        padding: 10px 27px;
        color: #262626;
      }
    }
  }
`;
export const FormWrap = styled.div`
  margin-top: 17px;
  .inner-wrap {
    padding: 40px 40px 10px;
    border-radius: 4px;
    border: solid 1px #c9cfd3;
    background-color: #ffffff;
  }
`;
