import { TableType } from 'model/tableType';
import UnderlineTitle from 'views/components/underlineTitle';
import {
  IMEDAppointmentSubType,
  MEDAppointmentSubType
} from 'model/MEDAppointmentSubtype';
import { convertMinsToTimeFormat } from 'utils/format';
import { IMEDAppointmentSubTypeConfig } from 'model/tableConfigurations';

export const mapMEDAppoitmentSubTypes = (
  data: TableType[],
  addNew: (data?: TableType) => void
): IMEDAppointmentSubTypeConfig[] =>
  (data as IMEDAppointmentSubType[]).map(item => ({
    title: (
      <UnderlineTitle
        title={item[MEDAppointmentSubType.title]}
        onClick={() => {
          addNew(item);
        }}
      />
    ),
    id: item[MEDAppointmentSubType.id],
    athenaId: item.athenaId,
    crName: item.crName,
    crType: item.crType,
    duration: convertMinsToTimeFormat(item.duration),
    superType: item.superType.value
  }));
