import moment from 'moment';
import { isEmpty, cloneDeep } from 'lodash';
import { getApptType } from 'utils/common';
import pluralize from 'pluralize';

export function formatAuthFlatData(data) {
  const formattedAuths = [];
  const auths = data?.authorizedTherapiesFlats?.auths || [];
  auths.forEach(auth => {
    formattedAuths.push({
      id: auth.id,
      clientName: auth.clientName,
      clientID: auth.clientID,
      clinic: auth.clinicAbr,
      authId: auth.authGroupID,
      startDate: auth.startDate,
      endDate: auth.endDate,
      dueDate: auth.dueDate,
      apptType: formatApptTypesIDs(
        auth.apptTypeIDs,
        data.appointmentTypes,
        data.appointmentABATypes
      ),
      authsRemaining: formatAuthsRemaining(
        auth.remaining,
        auth.authPattern,
        auth.completed + auth.scheduled + auth.remaining
      ),
      completed: formatString(auth.completed, auth.authPattern),
      scheduled: formatString(auth.scheduled, auth.authPattern),
      timeRemaining: formatTimeRemaining(Math.ceil(auth.timeRemaining), 'Week'),
      toFulfill: formatToFulfill(auth.toFulfill)
    });
  });
  return formattedAuths;
}

export function mapAuthGroupToFormData(authGroup, apptTypes) {
  return {
    authGroupID: authGroup.authGroupID,
    authGroupType: authGroup.authGroupType,
    clientID: authGroup.clientID,
    clientName: authGroup.clientName,
    clinicAbr: authGroup.clinicAbr,
    clinicID: authGroup.clinicID,
    startDate: moment(authGroup.startDate),
    endDate: moment(authGroup.endDate),
    dueDate: moment(authGroup.dueDate),
    authTherapies: authGroup.authTherapies.map(authTherapy => ({
      id: authTherapy.id,
      apptTypes: authTherapy.apptTypeIDs.map(
        apptType => getApptType(apptType, apptTypes).title
      ),
      authLimit:
        authTherapy.authPattern === 'Hours'
          ? authTherapy.authLimit / 60
          : authTherapy.authLimit,
      authPattern: authTherapy.authPattern,
      authPeriod: authTherapy.authPeriod
    }))
  };
}
export function formatAuthData(data) {
  const clone = cloneDeep(data.clientGroupedAuthsWithEvents);
  clone.forEach(groupedAuth => {
    let lessThanTwentyPercent = false;
    let authPattern = '';
    let totalScheduled = 0;
    let totalCompleted = 0;

    groupedAuth.authAppointmentTypes.forEach(authAppointmentType => {
      authAppointmentType.key = authAppointmentType.authTherapyID;

      authPattern = !isEmpty(authPattern)
        ? authPattern
        : authAppointmentType.authPattern;

      const limit =
        authAppointmentType.scheduled +
        authAppointmentType.completed +
        authAppointmentType.remaining;

      authAppointmentType.remaining = formatAuthsRemaining(
        authAppointmentType.remaining,
        authAppointmentType.authPattern,
        limit
      );
      totalScheduled += authAppointmentType.scheduled;
      totalCompleted += authAppointmentType.completed;

      authAppointmentType.completed = formatString(
        authAppointmentType.completed,
        authAppointmentType.authPattern
      );

      authAppointmentType.scheduled = formatString(
        authAppointmentType.scheduled,
        authAppointmentType.authPattern
      );

      authAppointmentType.toFulfill = formatToFulfill(
        authAppointmentType.toFulfill
      );

      lessThanTwentyPercent =
        lessThanTwentyPercent ||
        authAppointmentType.remaining.lessThanTwentyPercent;

      if (authAppointmentType.appointmentTypeID) {
        authAppointmentType.appointmentType = getApptTypeTitle(
          authAppointmentType.appointmentTypeID,
          authAppointmentType.isClinical,
          data.appointmentTypes,
          data.appointmentABATypes
        );
      }

      authAppointmentType.children &&
        authAppointmentType.children.forEach(apptType => {
          authPattern = !isEmpty(authPattern)
            ? authPattern
            : apptType.authPattern;
          apptType.appointmentType = getApptTypeTitle(
            apptType.appointmentTypeID,
            apptType.isClinical,
            data.appointmentTypes,
            data.appointmentABATypes
          );
          apptType.key = `${authAppointmentType.authTherapyID}-${apptType.appointmentTypeID}`;
          apptType.completed = formatString(
            apptType.completed,
            apptType.authPattern
          );
          apptType.scheduled = formatString(
            apptType.scheduled,
            apptType.authPattern
          );

          delete apptType.remaining;
          delete apptType.toFulfill;
        });
    });

    groupedAuth.timeRemaining =
      groupedAuth.timeRemaining > 0
        ? formatString(
            Math.ceil(groupedAuth.timeRemaining),
            pluralize('Week', Math.ceil(groupedAuth.timeRemaining))
          )
        : 'Expired';

    const totalLimit =
      totalScheduled + totalCompleted + groupedAuth.authsRemaining;

    groupedAuth.authsRemaining = formatAuthsRemaining(
      groupedAuth.authsRemaining,
      authPattern,
      totalLimit
    );
  });
  return clone;
}

export function getApptTypeTitle(
  appointmentTypeID,
  isClinical,
  appointmentTypes,
  appointmentABATypes
) {
  const apptType = isClinical
    ? appointmentTypes.find(x => x.id === appointmentTypeID)
    : appointmentABATypes.find(x => x.id === appointmentTypeID);
  return apptType?.title || '';
}

export function formatString(value, type) {
  if (value === undefined || value === null) return 'N/A';
  return `${value} ${type || ''}`;
}

export function formatPatternString(value, type, isWeekly = false) {
  return formatString(
    value,
    `${type ? type.toLowerCase() : ''}${type && isWeekly ? '/week' : ''} `
  );
}

export function formatAuthsRemaining(remaining, type, limit) {
  return {
    count: formatString(remaining, type),
    lessThanTwentyPercent: (remaining * 100) / limit < 20
  };
}

export function formatTimeRemaining(value, type) {
  return {
    string: formatString(value, pluralize(type, value)),
    negative: value < 0
  };
}

export function formatToFulfill(toFulfill) {
  return toFulfill <= 0 ? 'N/A' : formatString(toFulfill, '/ Week');
}

export function formatAppointmentAuthTherapy(
  clientGroupedAuthsWithEvents,
  appointmentType,
  authorizedTherapyID
) {
  const authTherapy = clientGroupedAuthsWithEvents.authAppointmentTypes.find(
    el => el.authTherapyID === authorizedTherapyID
  );

  const authAppointmentType = Array.isArray(authTherapy.children)
    ? authTherapy.children.find(
        el =>
          el.appointmentTypeID === appointmentType.id &&
          el.isClinical === appointmentType.isClinical
      )
    : authTherapy;

  const remainingFormat = formatAuthsRemaining(
    authAppointmentType.remaining,
    authAppointmentType.authPattern,
    authAppointmentType.remaining +
      authAppointmentType.completed +
      authAppointmentType.scheduled
  );

  return {
    isValidAuth: !remainingFormat.lessThanTwentyPercent,
    remaining: remainingFormat.count,
    completed: formatString(
      authAppointmentType.completed,
      authAppointmentType.authPattern
    ),
    scheduled: formatString(
      authAppointmentType.scheduled,
      authAppointmentType.authPattern
    ),
    toFulfill: formatToFulfill(authAppointmentType.toFulfill),
    authGroupID: authTherapy.authGroupID
  };
}

function formatApptTypesIDs(
  apptTypeIDs,
  appointmentTypes,
  appointmentABATypes
) {
  const formatedApptTypes = [];
  apptTypeIDs.forEach(appt => {
    formatedApptTypes.push({
      apptType: getApptTypeTitle(
        appt.id,
        appt.isClinical,
        appointmentTypes,
        appointmentABATypes
      ),
      completed: appt.completed,
      scheduled: appt.scheduled
    });
  });
  return formatedApptTypes;
}
