import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { mapToValuedOption } from 'utils/mappers/form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { AppointmentTypeFormWrapper } from '../style';
import { IOption } from 'model';
import { IFullAppointmentType, SidebarState } from 'model/v2';
import FormItem from 'views/containers/form/formItem/formItem';
import { ADMIN_APPT_ID_FOR_DURATION } from 'utils/constants/appointmentsTypes';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';

interface Props {
  adminTypes: IFullAppointmentType[];
  sidebarState: SidebarState;
  setAdminApptType: Dispatch<SetStateAction<number | undefined>>;
}

const AdminApptTypeFormV2: React.FC<Props> = ({
  adminTypes,
  sidebarState,
  setAdminApptType
}: Props) => {
  const methods = useFormContext();
  const [isSelected, setIsSelected] = useState(false);
  const apptOptionsList: IOption[] = React.useMemo(
    () => mapToValuedOption(adminTypes),
    [adminTypes]
  );
  const appointmentTypeSelect = useCallback(
    (val: number) => {
      setIsSelected(true);
      methods.setValue('dirtyEdit', true);
      setAdminApptType(val);

      const { startDate } = methods.getValues(['startDate']);
      const { appType } = methods.watch(['appType']);
      let apptTypeDuration = 15;
      if (
        ADMIN_APPT_ID_FOR_DURATION[process.env.REACT_APP_STAGE!].includes(
          appType
        )
      ) {
        apptTypeDuration = 30;
      }
      if (sidebarState?.action !== SIDEBAR_ACTIONS.EDIT) {
        methods.setValue('duration', apptTypeDuration);
        !!startDate &&
          methods.setValue(
            'endDate',
            startDate.clone().add(apptTypeDuration, 'minutes')
          );
      }
    },
    [methods, setAdminApptType, sidebarState.action]
  );
  return (
    <AppointmentTypeFormWrapper>
      <FormItem optional={false} label="Appointment Type">
        <Controller
          name="appType"
          control={methods.control}
          render={fieldProps => (
            <>
              <Select
                showSearch
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                className={
                  isSelected
                    ? 'appt-category-type-selected'
                    : 'appt-category-type'
                }
                placeholder="Select Appointment Type"
                options={apptOptionsList}
                field={fieldProps}
                errors={methods.errors}
                onSelect={appointmentTypeSelect}
              />
            </>
          )}
        />
      </FormItem>
    </AppointmentTypeFormWrapper>
  );
};
export default React.memo(AdminApptTypeFormV2);
