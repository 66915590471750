import React from 'react';
import { Button } from 'lib/ui';
import { CancelModalWrapper } from './style';

import WarningIcon from 'views/components/icons/warning';

interface IProps {
  onClose(): void;
  onSubmit(e: Event, templateId: number): void;
  form: any;
}

const CancelModalForm: React.FC<IProps> = ({
  onClose,
  onSubmit,
  form
}: IProps) => {
  return (
    <CancelModalWrapper className="CancelModalContainer">
      <div className="content">
        <WarningIcon className="delete icon" />
        <p className="message">{form.message}</p>
      </div>
      <div className="cta">
        <Button className="ui button cancel" onClick={onClose} type="">
          {form.left_button_text}
        </Button>
        <Button
          className="ui button submit"
          onClick={(e: Event) => {
            onSubmit(e, form.templateId);
          }}
          type="submit"
        >
          {form.right_button_text}
        </Button>
      </div>
    </CancelModalWrapper>
  );
};

export default CancelModalForm;
