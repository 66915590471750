import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Radio } from 'semantic-ui-react';

import DTResultTime from './DTResultTime';

import ResultLocationCustom from './ResultLocationCustom';

import SingleResultLocationList from './SingleResultLocationList';

import TimeSlotSelector from './TimeSlotSelector';
import { DescriptionWrapperDT } from './Style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import ProvidersTime from './ProvidersTime';
import { EventSelectOptions } from 'model/calendar/filters';
import { times } from 'model/v2';
interface IProps {
  result: any;
  isCheckedCard: boolean;
  minHours: number;
  index: number;
  clientId?: number;
  isAba: boolean;
  isDT: boolean;
  hasSingleDT: any;
  selectedIndex: number | undefined;
  selectedAppointment?: string;
  eventCategoriesOptions?: EventSelectOptions[];
  eventAddressesOptions?: EventSelectOptions[];
  setProviderTime: (val: times) => void;
  providerTime: times;
}
const SingleResultDescription: React.FC<IProps> = ({
  result,
  isCheckedCard,
  index,
  clientId,
  hasSingleDT,
  selectedIndex,
  selectedAppointment,
  eventCategoriesOptions,
  eventAddressesOptions,
  setProviderTime,
  providerTime
}: IProps) => {
  const { watch, control, setValue } = useFormContext();
  const isInClinic = hasSingleDT
    ? watch(`result.isInClinic`)
    : watch(`results[${index}].isInClinic`);
  const isOffsite = hasSingleDT
    ? watch(`result.isOffsite`)
    : watch(`results[${index}].isOffsite`);
  const isTelehealth = hasSingleDT
    ? watch(`result.isTelehealth`)
    : watch(`results[${index}].isTelehealth`);

  const [clientTimeZone, setClientTimeZone] = useState<any>();

  return (
    <>
      <DescriptionWrapperDT>
        <Grid>
          <Grid.Row className="outer-shell">
            <Grid.Column className="w-50-inline pad-8-px">
              <div className="flx-1-1-grow">
                <div className="flx-fixed-w flx-v-c">
                  <FontAwesomeIcon className="icon-small" icon={faCalendar} />
                </div>
                <div className="flx-grow-1 flx-v-c color-custom f-w-500">
                  <DTResultTime
                    startTime={result.times[0].startTime}
                    endTime={result.times[0].endTime}
                    format={true}
                  />
                </div>
              </div>
              <div className="flx-1-1-grow m-top-5">
                <div className="flx-fixed-w flx-v-c">
                  <FontAwesomeIcon className="icon-small" icon={faClock} />
                </div>
                <div className="flx-grow-1 v-align-c color-custom f-w-500">
                  <span className="time-zone-text">
                    Client's Appointment: {clientTimeZone}
                  </span>
                </div>
              </div>
              <div className="p-left-15 m-top-5">
                {result.times && (
                  <TimeSlotSelector
                    times={result.times}
                    timeZone={result.clientTimezone}
                    setProviderTime={setProviderTime}
                    setClientTimeZone={setClientTimeZone}
                    selectedIndex={selectedIndex}
                    index={index}
                  />
                )}
              </div>
              <div className="flx-1-1-grow p-left-15 d-flx flx-dr-c m-top-15">
                <ProvidersTime result={result} providerTime={providerTime} />
              </div>
            </Grid.Column>
            <Grid.Column className="w-50-inline p-left-10">
              <div className="flx-1-1-grow appointment-info">
                <div className="flx-grow-1 flx-v-c color-custom f-size-12 f-w-500 overf-ell">
                  <span>Appointment Type: {selectedAppointment}</span>
                </div>
              </div>
              <ResultLocationCustom
                index={index}
                isCheckedCard={isCheckedCard}
                isInClinic={isInClinic}
                isTelehealth={isTelehealth}
                isOffsite={isOffsite}
                allowedLocations={result.allowedLocations}
                clinic={result.clinic}
                clinics={result.inPersonClinics}
                selectedIndex={selectedIndex}
                eventCategoriesOptions={eventCategoriesOptions}
              />
              <SingleResultLocationList
                isInClinic={isInClinic}
                isTelehealth={isTelehealth}
                isOffsite={isOffsite}
                clinic={result.clinic}
                index={index}
                clientId={clientId}
                hasSingleDT={hasSingleDT}
                selectedIndex={selectedIndex}
                telehealthLinks={result.provider.telehealthLinks}
                eventAddressesOptions={eventAddressesOptions}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="invisible-toggle">
          <div className="toggle-wrap">
            <div className="toggle-flex-item">
              <label className="visibleText">Save as Invisible</label>
            </div>
            <div className="toggle-flex-item">
              <Controller
                name={'result.isInVisible'}
                control={control}
                render={props => (
                  <Radio
                    toggle
                    disabled={selectedIndex !== index}
                    onChange={(_, { checked }) => {
                      setValue(`result.isInVisible`, checked);
                    }}
                    checked={props.value}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </DescriptionWrapperDT>
    </>
  );
};
export default React.memo(SingleResultDescription);
