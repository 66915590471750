import { CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
interface Props {
  alertContent: string;
  setIsAlertShown: (val: boolean) => void;
}
const AbaObserverAlert: React.FC<Props> = ({
  alertContent,
  setIsAlertShown
}) => {
  return (
    <div className="observer_alert">
      <div className="closeButton">
        <CloseOutlined onClick={() => setIsAlertShown(false)} />
      </div>
      <div>
        <p className="attention">
          <span>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ color: `#E82424` }}
            />
          </span>{' '}
          Attention
        </p>
        <p className="content">{alertContent}</p>
      </div>
    </div>
  );
};

export default AbaObserverAlert;
