import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import {
  ICalendarFilterOption,
  ISavedFilterOptions
} from 'model/calendar/filters';
import SidebarFilterForm from 'components/calendar/calendarToolbar/FilterTagList/form/SidebarFilterForm';
interface Props {
  filters: ICalendarFilterOption[];
  savedFilter: string;
  defaultFilter?: ISavedFilterOptions;
  handleSearchedValues: (
    list: ICalendarFilterOption[],
    checkedList: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  setHasData?: Dispatch<SetStateAction<boolean>>;
  pageName: string;
  setEditedFilter: Dispatch<SetStateAction<string>>;
  editedFilter: string;
  isConfirmed: boolean;
  filtername: string;
  setFiltername: Dispatch<SetStateAction<string>>;
  setDeletedFilter: Dispatch<SetStateAction<string>>;
  deletedFilter: string;
  setCheckedFilter: Dispatch<SetStateAction<string>>;
  checkedFilter: string;
  setRemovedFilterTags: Dispatch<SetStateAction<boolean>>;
  errorMsg: string;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  setSelectedFilter: Dispatch<SetStateAction<string>>;
  selectedFilter: string;
  allFilters: any[];
}

const SidebarSavedFilters = ({
  filters,
  defaultFilter,
  handleSearchedValues,
  setHasData,
  pageName,
  setEditedFilter,
  editedFilter,
  isConfirmed,
  filtername,
  setFiltername,
  setDeletedFilter,
  deletedFilter,
  setCheckedFilter,
  checkedFilter,
  setRemovedFilterTags,
  errorMsg,
  setErrorMsg,
  setSelectedFilter,
  selectedFilter,
  allFilters
}: Props) => {
  const savedFilters = useMemo(() => {
    const searchedValues = handleSearchedValues(filters, [
      { label: checkedFilter, value: checkedFilter }
    ]);

    return searchedValues;
  }, [handleSearchedValues, filters, checkedFilter]);
  const filterData = useMemo(() => {
    return allFilters?.find(f => f.name === editedFilter);
  }, [allFilters, editedFilter]);
  const handleChange = (
    _e: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    setEditedFilter('');
    setDeletedFilter('');
    setErrorMsg('');
    setRemovedFilterTags(false);
    setSelectedFilter(data.value as string);
    if (checkedFilter !== '') setCheckedFilter(data.value as string);
  };
  useEffect(() => {
    setHasData &&
      (savedFilters?.length === 0 ? setHasData(false) : setHasData(true));
  }, [savedFilters, setHasData]);
  return (
    <>
      {savedFilters?.length > 0 ? (
        <>
          {savedFilters.map(filter => {
            let filterLabel = filter.label;
            if (defaultFilter?.name === filter.label) {
              filterLabel = `${filter.label} (Default)`;
            }
            return (
              <>
                <div
                  className="saved_filters saved_filters_div"
                  key={filter.label}
                >
                  <Checkbox
                    className="saved-filters_checkbox"
                    radio={true}
                    label={filterLabel}
                    name={filter.label}
                    value={filter.value}
                    checked={
                      checkedFilter === filter.label ||
                      selectedFilter === filter.label
                    }
                    onChange={handleChange}
                    disabled={
                      deletedFilter !== '' && deletedFilter !== filter.label
                    }
                  />
                  {editedFilter !== '' && editedFilter === filter.label ? (
                    <>
                      {deletedFilter === '' && (
                        <button
                          className="delete_btn"
                          onClick={() => {
                            setDeletedFilter(filter.label);
                            setErrorMsg('');
                          }}
                        >
                          Delete
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={`${
                        deletedFilter !== '' && deletedFilter !== filter.label
                          ? `disabled_edit_btn`
                          : `edit_btn`
                      }`}
                      onClick={() => {
                        setEditedFilter(filter.label);
                        setDeletedFilter('');
                        setErrorMsg('');
                      }}
                      disabled={
                        deletedFilter !== '' && deletedFilter !== filter.label
                      }
                    >
                      Edit
                    </button>
                  )}
                </div>
                {deletedFilter === filter.label ? (
                  <>
                    <div className="saved_filters">
                      <p className="delete_filter">
                        Are you sure you want to delete this filter?
                      </p>
                    </div>
                  </>
                ) : (
                  editedFilter === filter.label && (
                    <SidebarFilterForm
                      filterName={filter.label}
                      pageName={pageName}
                      isConfirmed={isConfirmed}
                      filtername={filtername}
                      setFiltername={setFiltername}
                      filterData={filterData!}
                      setRemovedFilterTags={setRemovedFilterTags}
                      errorMsg={errorMsg}
                    />
                  )
                )}
              </>
            );
          })}
        </>
      ) : (
        <>
          <p className="error"> No Records Found</p>
        </>
      )}
    </>
  );
};
export default React.memo(SidebarSavedFilters);
