import React from 'react';
import { Row } from 'antd';

const FormSection = ({ title, children, optional }) => {
  return (
    <>
      <h2 className="appointment-body__title">
        {title} {optional && <span>(optional)</span>}
      </h2>
      <div className="section">
        <Row type="flex" justify="start" align="top" gutter={16}>
          {children}
        </Row>
      </div>
    </>
  );
};

export default FormSection;
