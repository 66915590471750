import { ITableColumn } from 'views/components/table/type';
import {
  DTAppointmentSubType,
  IDTAppointmentSubType
} from 'model/DTAppointmentSubType';
import { TableType } from 'model/tableType';
import { ILookup } from 'model/lookup';
import { sortNumerical } from 'utils/sort';
export const DT_APPOINTMENT_SUBTYPE_COLUMNS: ITableColumn[] = [
  {
    label: 'Title',
    key: DTAppointmentSubType.title,
    notWrap: true,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, DTAppointmentSubType.title),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, DTAppointmentSubType.title)
    }
  },
  {
    label: 'Super type',
    key: DTAppointmentSubType.superType,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, DTAppointmentSubType.superType),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, DTAppointmentSubType.superType)
    }
  },
  {
    label: 'ID',
    key: DTAppointmentSubType.id,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sortNumerical(a[DTAppointmentSubType.id]!, b[DTAppointmentSubType.id]!),
      reverse: (a: TableType, b: TableType) =>
        sortNumerical(b[DTAppointmentSubType.id]!, a[DTAppointmentSubType.id]!)
    }
  },
  { label: 'Athena Id', key: DTAppointmentSubType.athenaId },
  {
    label: 'CR Name',
    key: DTAppointmentSubType.crName,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, DTAppointmentSubType.crName),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, DTAppointmentSubType.crName)
    }
  },
  { label: 'CR Type', key: DTAppointmentSubType.crType },
  { label: 'Duration', key: DTAppointmentSubType.duration },
  { label: 'Salesforce Title', key: DTAppointmentSubType.salesForceMapping }
];
const sort = (a: TableType, b: TableType, sortField: DTAppointmentSubType) => {
  let valueA = (a as IDTAppointmentSubType)[sortField];
  let valueB = (b as IDTAppointmentSubType)[sortField];
  if (sortField === DTAppointmentSubType.superType) {
    valueA = (valueA as ILookup).value;
    valueB = (valueB as ILookup).value;
  }
  return valueA!.toString().toLowerCase() > valueB!.toString().toLowerCase()
    ? -1
    : 1;
};
