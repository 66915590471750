import { RefObject, useCallback, useEffect, useRef } from 'react';
import { Menu, Popup } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import useClickOutsideHook from 'hooks/clickOutsideHook';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ICalendarEvent } from 'model/calendar/events';
import {
  closeItemContextMenu,
  openItemContextMenuVar,
  selectedItemToSubstituteVar,
  selectedItemsToCancelAddAdminVar,
  selectedItemsToCancelVar
} from 'utils/cache/calendar';
import { isItemSelectedForCancellation } from 'utils/validators/BulkCancel';
import { ItemContextMenuWrapper } from '../style';
import CancelIcon from 'assets/img/cancel-icon.svg';
import CancelIconActive from 'assets/img/cancel-icon-active.svg';
import DeleteIcon from 'assets/img/delete-icon.svg';
import DeleteIconActive from 'assets/img/delete-icon-active.svg';
import CustomMenuItem from './CustomMenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { SidebarState } from 'model/v2';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';
import SubstituteIcon from 'assets/img/find-substitute.svg';
import SubstituteIconActive from 'assets/img/find-substitute-active.svg';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';

interface Props {
  item: ICalendarEvent;
  itemRef: RefObject<HTMLElement>;
  isConverted: any;
  isLoading: any;
  openSidebar: (sidebarState: SidebarState) => void;
  setIsCancelAddAdminModalOpen: (open: boolean) => void;
}

const ItemContextMenu = ({
  item,
  itemRef,
  isConverted,
  isLoading,
  openSidebar,
  setIsCancelAddAdminModalOpen
}: Props) => {
  const openItemContextMenuId = useReactiveVar(openItemContextMenuVar);
  const itemContextMenuRef = useRef<HTMLDivElement>(null);
  const { windowHeight, windowWidth } = useWindowDimensions();
  useClickOutsideHook(itemContextMenuRef, () => {
    closeItemContextMenu();
  });
  useEffect(() => {
    closeItemContextMenu();
  }, [windowHeight, windowWidth]);
  const addToSelectedItemsToCancel = useCallback(() => {
    const selectedItemsToCancel = selectedItemsToCancelVar();
    const newSelectedItems = new Map<number, ICalendarEvent>(
      selectedItemsToCancel.set(item.id!, item)
    );
    selectedItemsToCancelVar(newSelectedItems);
  }, [item]);
  const removeFromSelectedItemsToCancel = useCallback(() => {
    const selectedItemsToCancel = selectedItemsToCancelVar();
    selectedItemsToCancel.delete(item.id!);
    const newSelectedItems = new Map<number, ICalendarEvent>(
      selectedItemsToCancel
    );
    selectedItemsToCancelVar(newSelectedItems);
  }, [item.id]);
  const onClickCancelAppointment = useCallback(
    (mouseEvent: React.MouseEvent<HTMLElement>) => {
      mouseEvent.stopPropagation();
      addToSelectedItemsToCancel();
      closeItemContextMenu();
    },
    [addToSelectedItemsToCancel]
  );
  const onClickCancelAddAppointment = useCallback(
    (mouseEvent: React.MouseEvent<HTMLElement>) => {
      mouseEvent.stopPropagation();
      const selectedItemsToCancelAddAdmin = new Map<number, ICalendarEvent>();
      const newSelectedItems = new Map<number, ICalendarEvent>(
        selectedItemsToCancelAddAdmin.set(item.id!, item)
      );
      selectedItemsToCancelAddAdminVar(newSelectedItems);
      closeItemContextMenu();
      setIsCancelAddAdminModalOpen(true);
    },
    [setIsCancelAddAdminModalOpen, item]
  );
  const onClickFindSubstitute = useCallback(
    (mouseEvent: React.MouseEvent<HTMLElement>) => {
      mouseEvent.stopPropagation();
      selectedItemToSubstituteVar(item.id);
      openSidebar({
        event: item,
        action: SIDEBAR_ACTIONS.SUBSTITUTE
      });
      closeItemContextMenu();
    },
    []
  );
  const onClickClientCallOut = useCallback(
    (mouseEvent: React.MouseEvent<HTMLElement>) => {
      mouseEvent.stopPropagation();
      selectedItemToSubstituteVar(item.id);
      openSidebar({
        event: item,
        action: SIDEBAR_ACTIONS.FIND_MAKEUP
      });
      closeItemContextMenu();
    },
    []
  );
  const onClickDeselectAppointment = useCallback(
    (mouseEvent: React.MouseEvent<HTMLElement>) => {
      mouseEvent.stopPropagation();
      removeFromSelectedItemsToCancel();
      closeItemContextMenu();
    },
    [removeFromSelectedItemsToCancel]
  );
  const onItemContextClose = useCallback(() => {
    closeItemContextMenu();
  }, []);
  const SubsititueApptTypes = [
    APPOINTMENT_CATEGORY[0].value,
    APPOINTMENT_CATEGORY[1].value,
    APPOINTMENT_CATEGORY[4].value
  ];
  return (
    <ItemContextMenuWrapper>
      <Popup
        basic
        context={itemRef}
        open={openItemContextMenuId === item.calendarID}
        position="right center"
        onClose={onItemContextClose}
      >
        <div ref={itemContextMenuRef}>
          <Menu vertical style={{ maxWidth: '190px' }}>
            <Menu.Header
              style={{ padding: '5px', display: 'inline-block', width: '100%' }}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    minHeight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content'
                  }}
                >
                  <span style={{ display: 'inline-block', fontSize: '12px' }}>
                    Actions
                  </span>
                </div>
                <div
                  style={{
                    display: 'inline-flex',
                    marginLeft: '5px',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: '7px'
                  }}
                >
                  {!isLoading && isConverted?.isConverted && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ fontSize: '18px' }}
                    />
                  )}
                  {isLoading && (
                    <div
                      className="loader"
                      style={{
                        border: '3px solid #BEBEBE',
                        borderRadius: '50%',
                        borderTop: '3px solid #2997ff',
                        width: '18px',
                        height: '18px',
                        animation: 'spin 0.7s linear infinite',
                        display: 'inline-block'
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </Menu.Header>
            {!isLoading && (
              <>
                {getFeatureAvailability(FEATURES.FIND_SUBSTITUTE_PROVIDER) &&
                  SubsititueApptTypes.includes(
                    item?.appointmentType?.eventType?.name!
                  ) &&
                  !item?.isObservation &&
                  !(item.status === 'canceled') && (
                    <CustomMenuItem
                      name="Substitute"
                      onClick={onClickFindSubstitute}
                      label={'Find Substitute'}
                      icon={SubstituteIcon}
                      hoverIcon={SubstituteIconActive}
                      isDisabled={isConverted?.isConverted}
                    />
                  )}
                {getFeatureAvailability(FEATURES.FIND_MAKEUP) &&
                  SubsititueApptTypes.includes(
                    item?.appointmentType?.eventType?.name!
                  ) &&
                  !item?.isObservation && (
                    <CustomMenuItem
                      name="Substitute"
                      onClick={onClickClientCallOut}
                      label={'Find Makeup'}
                      icon={SubstituteIcon}
                      hoverIcon={SubstituteIconActive}
                      isDisabled={isConverted?.isConverted}
                    />
                  )}
                {!(item.status === 'canceled') && (
                  <CustomMenuItem
                    name="Cancel"
                    onClick={onClickCancelAppointment}
                    label={'Cancel Appointment'}
                    icon={CancelIcon}
                    hoverIcon={CancelIconActive}
                    isDisabled={isConverted?.isConverted}
                  />
                )}
                {item?.appointmentType?.eventType?.name! ===
                  APPOINTMENT_CATEGORY[0].value &&
                  !item?.isObservation &&
                  item.status !== 'canceled' && (
                    <CustomMenuItem
                      name="Cancel"
                      onClick={onClickCancelAddAppointment}
                      label={'Cancel and Add Admin'}
                      icon={CancelIcon}
                      hoverIcon={CancelIconActive}
                      isDisabled={isConverted?.isConverted}
                    />
                  )}
              </>
            )}

            {isItemSelectedForCancellation(item.id!) && (
              <CustomMenuItem
                name="Deselect"
                onClick={onClickDeselectAppointment}
                label={'Deselect Appointment'}
                icon={DeleteIcon}
                hoverIcon={DeleteIconActive}
                isDisabled={false}
              />
            )}
          </Menu>
        </div>
      </Popup>
    </ItemContextMenuWrapper>
  );
};

export default ItemContextMenu;
