import React from 'react';
import { convertDateFromISO, formatUsername } from 'utils/format';
import ClientSearchProfilePicture from '../SearchClients/ClientSearchProfilePicture';
import AppointmentClientTab from './AppointmentClientTab';
import useGetClinicsHook from 'hooks/GetClinicsHooks';

const ClientItem = ({ client }) => {
  const clinicsMap = useGetClinicsHook();

  return (
    <div className="appointment-sidebar__client-item">
      <div className="appointment-sidebar__client-card">
        <ClientSearchProfilePicture img={client.photo} alt={client.name} />
        <div className="body-wrap">
          <div className="name">
            {formatUsername(client.firstName, client.lastName)}
          </div>
          <div className="dob">{convertDateFromISO(client.dob)}</div>
          <div className="info">
            Clinic:{' '}
            <span>{`${clinicsMap.get(client.clinicId)?.name} (${
              clinicsMap.get(client.clinicId)?.abbreviation
            })`}</span>
          </div>
          <div className="info">
            Client Type <span>{client.clientType}</span>
          </div>
          <div className="info">
            Phenotype <span>{client.phenoType}</span>
          </div>
        </div>
      </div>
      <div className="container-client-tab">
        <AppointmentClientTab client={client} />
      </div>
    </div>
  );
};

export default React.memo(ClientItem);
