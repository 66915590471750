import { FormItem, Input } from 'views/containers/form';

import { Controller, useFormContext } from 'react-hook-form';
import { Row } from 'antd';
import { IDriveTimeMileage } from 'model/v2';
import React from 'react';
interface Props {
  address: IDriveTimeMileage;
}
const DriveTimeLocation: React.FC<Props> = ({ address }: Props) => {
  const { control } = useFormContext();
  return (
    <>
      <Row justify="start" align="top" gutter={16} style={{ width: '100%' }}>
        <FormItem
          optional
          label="Address (Line 1)"
          xs={16}
          sm={12}
          className="address-line-2"
        >
          <Controller
            name="address.addressLine1"
            control={control}
            render={field => (
              <Input
                field={field}
                value={address?.addressLine1}
                disabled="true"
              />
            )}
          />
        </FormItem>
        <FormItem
          optional
          label="Address (Line 2)"
          xs={16}
          sm={12}
          className="address-line-2"
        >
          <Controller
            name="address.addressLine2"
            control={control}
            render={field => (
              <Input
                field={field}
                value={address?.addressLine2}
                disabled="true"
              />
            )}
          />
        </FormItem>
      </Row>
      <Row justify="start" align="top" gutter={16} style={{ width: '100%' }}>
        <FormItem
          optional
          label="City"
          xs={16}
          sm={8}
          className="address-line-2"
        >
          <Controller
            name="address.city"
            control={control}
            render={field => (
              <Input field={field} value={address?.city} disabled="true" />
            )}
          />
        </FormItem>
        <FormItem
          optional
          label="State"
          xs={16}
          sm={8}
          className="address-line-2"
        >
          <Controller
            name="address.state"
            control={control}
            render={field => (
              <Input field={field} value={address?.state} disabled="true" />
            )}
          />
        </FormItem>
        <FormItem
          optional
          label="Zip code"
          xs={16}
          sm={8}
          className="address-line-2"
        >
          <Controller
            name="address.postalCode"
            control={control}
            render={field => (
              <Input
                field={field}
                value={address?.postalCode}
                disabled="true"
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  );
};
export default React.memo(DriveTimeLocation);
