import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Select } from 'api/sharedComponents/reactHookFormComponents';
import {
  EditAppointmentPopup,
  IEditAppointmentPopup,
  IRoom,
  ICancellationReason,
  LocationTypes,
  IOption,
  RescheduleModalType
} from 'model';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Grid, TextArea } from 'semantic-ui-react';
import { formatUsernameOriginal } from 'utils/format';
import {
  APPOINTMENT_RESCHEDULE_OFF_SITE_SCHEMA,
  APPOINTMENT_RESCHEDULE_SCHEMA
} from 'utils/validators/calendar';
import PopupActions from 'components/popupActions';
import EventTypeRadioGroup from './EventTypeRadioGroup';
import ClientRow from './ClientRow';
import { mapRoomsToOptions, mapToOption } from 'utils/mappers/form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useReactiveVar } from '@apollo/client';
import {
  calendarEventsPrefVar,
  clientsResourcesPrevVar,
  providerResourcesPrevVar
} from 'utils/cache/filters';
import { plannerContext } from 'pages/MainCalendarPage';
import { ICalendarEvent } from 'model/calendar/events';
import OffsiteLocation from 'views/components/forms/appointment/components/Location/OffsiteLocation';
import { mainCalendarPrefDayVar, PlannerView } from 'utils/cache/calendar';
import { ICalendarResource } from 'model/calendar/filters';
import { ContentWrapper } from './style';
import TimeRange from './TimeRange';
import { getCurrentClinicsOptionsV2 } from 'views/components/forms/appointment/components/MainFields/appointmentClinicHook';
import { Link } from 'react-router-dom';
import { ATHENA_DEFAULT_DELETE_REASON } from 'utils/constants/lists';
import {
  mapEventDataToBeRescheduled,
  getDefaultPopupFormValues
} from 'utils/mappers/calendar';
import moment from 'moment';
import { IProvider, IClient, IFullEvent, IEvent } from 'model/v2';
import { useClinicDataMap } from 'api/graphql/v2/hooks/clinics';
import Content from 'views/components/ui/content';
import { UPDATE_EVENT } from 'api/graphql/v2/mutations/Event';
import { EventType } from 'model/calendar/events';
import { prepareEventsAfterReschedule } from 'utils/mappers/response/eventsV2';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { WarningOutlined, EyeOutlined } from '@ant-design/icons';
import { DEFAULT_DOCTOR_IMAGE } from 'utils/constants/default';
import ObservationRow from 'pages/MainCalendarPage/AppointmentObserver/ObservationRow';
import ImpactedObservers from 'pages/MainCalendarPage/AppointmentModals/RescheduleModal/ImpactedObservers';
import ObserverForm from './ObserverForm';
import AbaImpactedObservers from 'pages/MainCalendarPage/AppointmentModals/RescheduleModal/AbaImpactedObservers';
import { VALIDATE_LOCATIONS } from 'api/graphql/v2/queries/Location';
import { ImpactedObserversWrapper } from './style';
import LocationValidatorAlert from 'pages/MainCalendarPage/AppointmentView/AttendeesView/LocationValidatorAlert';

interface IProps {
  fullEvent: IFullEvent;
  provider?: IProvider;
  oldProvider?: IProvider;
  client?: IClient;
  isConflictingProvider: boolean;
  isSeries: boolean;
  newStartDate?: string;
  newEndDate?: string;
  oldStartDate?: string;
  oldEndDate?: string;
  view: PlannerView;
  onClose: () => void;
  onCancel: () => void;
  cancellationReasons?: ICancellationReason[];
  roomName: string;
  recurringEventsCount?: number;
  deleteWhenCancelAppointmentsIds?: number[];
  observationOfLeadEvent?: IEvent[];
  observersOfEachEvent?: any;
}

const ClientForm: React.FC<IProps> = ({
  fullEvent,
  isSeries,
  provider,
  oldProvider,
  client,
  isConflictingProvider,
  newStartDate,
  newEndDate,
  oldStartDate,
  oldEndDate,
  view,
  onClose,
  onCancel,
  cancellationReasons,
  roomName,
  recurringEventsCount,
  deleteWhenCancelAppointmentsIds,
  observationOfLeadEvent
}: IProps) => {
  const { clinicsMap, loadingClinics } = useClinicDataMap('Edit');
  const [isSelectedRoom, setIsSelectedRoom] = useState(false);
  const [isSelectedReason, setIsSelectedReason] = useState(false);
  const [alertContent, setAlertContent] = React.useState<any>(null);
  const { handleDateSelect } = useContext(plannerContext);
  const events = useReactiveVar<ICalendarEvent[]>(calendarEventsPrefVar);
  const providers = useReactiveVar<ICalendarResource[]>(
    providerResourcesPrevVar
  );
  const [seriesEventIndex, setSeriesEventIndex] = useState(0);
  const { ShadowFeatureAvailability } = useContext(plannerContext);
  const currentEventInSeries = useMemo(() => {
    if (observationOfLeadEvent) return observationOfLeadEvent[seriesEventIndex];
    return undefined;
  }, [observationOfLeadEvent, seriesEventIndex]);
  const isConverted = fullEvent.isConverted;
  const next = useCallback(() => {
    setSeriesEventIndex(currentEventInSeries =>
      currentEventInSeries < observationOfLeadEvent!.length - 1
        ? currentEventInSeries + 1
        : currentEventInSeries
    );
  }, [observationOfLeadEvent]);
  const previous = useCallback(
    () =>
      setSeriesEventIndex(currentEventInSeries =>
        currentEventInSeries > 0
          ? currentEventInSeries - 1
          : currentEventInSeries
      ),
    []
  );
  console.log(
    observationOfLeadEvent,
    previous,
    next,
    'observationOfLeadEvent in client form'
  );

  const [
    validateAppointment,
    { loading: locationValidationInProgress }
  ] = useLazyQuery(VALIDATE_LOCATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const results = data.checkLocationAvailability;
      setAlertContent(results.alertMessage);
    },
    onError: error => {
      console.error('Query Error:', error.message);
    }
  });

  const clinicId = fullEvent.event.clinic?.id;

  useEffect(() => {
    setAlertContent(null);
    const { startDate, endDate, clinic, locationType } = fullEvent.event;
    const providerId = provider?.id;
    const validateAppointmentVariables = {
      variables: {
        providerId,
        locationType,
        startTime: moment(startDate).toISOString(),
        endTime: moment(endDate).toISOString(),
        selectedClinicId: clinic?.id
      }
    };
    if (providerId && startDate && endDate && clinic?.id && locationType) {
      validateAppointment(validateAppointmentVariables);
    }
  }, [fullEvent.event, clinicId, provider, validateAppointment]);

  const [selectedRadioButton, setSelectedRadioButton] = useState(null);
  let observersArray: any = [];
  let observersCount: any;
  if (selectedRadioButton == 2) {
    observationOfLeadEvent!.map(eachEvent => {
      observersArray.push(eachEvent.observations?.length);
      return null;
    });
    observersCount = observersArray.reduce((a: any, b: any) => a! + b!, 0);
  } else {
    observersCount = fullEvent?.event?.observationsCount!;
  }
  const eventTypes = ['ABA', 'DT'];
  const isABAOrDTEvent = eventTypes.includes(
    fullEvent.event.appointmentType?.eventType?.name!
  );

  const getInitalAbaObserversList = () => {
    return fullEvent.event.observations
      ? fullEvent.event.observations.map(observation => ({
          id: observation.id,
          provider: observation.provider,
          locationType: observation.locationType,
          telehealthLink: observation.telehealthLink,
          startDate: observation.startDate,
          endDate: observation.endDate,
          duration: observation.duration
        }))
      : [];
  };
  let abaObserversListNew = getInitalAbaObserversList();
  const eventObserverduration = moment
    .duration(moment(newStartDate).diff(moment(oldStartDate)))
    .asMinutes();
  abaObserversListNew = abaObserversListNew.map(obj => {
    const momentNewStartDate = moment(obj.startDate).add(
      eventObserverduration,
      'minutes'
    );
    const momentNewEndDate = moment(momentNewStartDate).add(
      obj.duration,
      'minutes'
    );
    return {
      ...obj,
      startDate: momentNewStartDate,
      endDate: momentNewEndDate,
      provider: { id: obj.provider?.id, name: obj.provider?.name }
    };
  });

  let [abaObserversList, setAbaObserversList] = React.useState<IEvent[]>(
    abaObserversListNew
  );
  let seriesObserverEvent = [];
  if (observationOfLeadEvent) {
    seriesObserverEvent = JSON.parse(JSON.stringify(observationOfLeadEvent!));
  }
  const seriesEventObservationsArr = seriesObserverEvent?.map(
    (observerEvent: {
      startDate: moment.MomentInput;
      endDate: moment.MomentInput;
      observations: any[];
    }) => {
      observerEvent.startDate = moment(observerEvent.startDate).add(
        eventObserverduration,
        'minutes'
      );
      observerEvent.endDate = moment(observerEvent.endDate).add(
        eventObserverduration,
        'minutes'
      );
      const observations = observerEvent.observations?.map(obj => {
        const momentNewStartDate = moment(obj.startDate).add(
          eventObserverduration,
          'minutes'
        );
        const momentNewEndDate = moment(momentNewStartDate).add(
          obj.duration,
          'minutes'
        );
        return {
          ...obj,
          startDate: momentNewStartDate,
          endDate: momentNewEndDate,
          provider: { id: obj.provider?.id, name: obj.provider?.name }
        };
      });
      return {
        ...observerEvent,
        observations: observations
      };
    }
  );
  let [seriesEventObservations, setSeriesEventObservations] = React.useState<
    IEvent[]
  >(seriesEventObservationsArr!);
  const radioButtonStatus = (index: any) => {
    setSelectedRadioButton(index);
    // setSeriesEventObservations(seriesEventObservationsArr!);
    // setAbaObserversList(abaObserversListNew);
    if (index == 2) {
      isAlertShownSet = getSeriesAbaObserverValidationStatus(
        seriesEventObservations,
        newStartDate,
        newEndDate
      );
    } else {
      isAlertShownSet = getAbaObserverValidationStatus(
        abaObserversList,
        newStartDate,
        newEndDate
      );
    }
    setIsValidated(isAlertShownSet);
  };
  fullEvent.event.startDate = moment(newStartDate).toDate();
  fullEvent.event.endDate = moment(newEndDate).toDate();

  const [lastAbaObserver, setLastAbaObserver] = React.useState<IEvent>({});
  console.log(lastAbaObserver);

  const clients = useReactiveVar<ICalendarResource[]>(clientsResourcesPrevVar);
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const methods = useForm<IEditAppointmentPopup>({
    defaultValues: getDefaultPopupFormValues(
      fullEvent?.event?.clinic?.id || undefined,
      fullEvent?.event?.room?.id || undefined,
      fullEvent?.event?.address,
      fullEvent?.event?.locationCategory,
      fullEvent?.event?.telehealthLink
    ),
    resolver: yupResolver(
      fullEvent?.event?.locationType === (LocationTypes.offSite as any)
        ? APPOINTMENT_RESCHEDULE_OFF_SITE_SCHEMA(
            deleteWhenCancelAppointmentsIds,
            fullEvent?.event?.appointmentType?.id!
          )
        : APPOINTMENT_RESCHEDULE_SCHEMA(
            deleteWhenCancelAppointmentsIds,
            fullEvent?.event?.appointmentType?.id!
          )
    )
  });

  const {
    handleSubmit,
    errors,
    formState: { isSubmitting },
    setValue
  } = methods;
  const { clinic, room, type } = useWatch({
    name: ['clinic', 'room', 'type'],
    control: methods.control
  });
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const onConfirm = handleSubmit(async (data: IEditAppointmentPopup) => {
    const eventData = mapEventDataToBeRescheduled(
      fullEvent,
      data,
      newStartDate,
      newEndDate,
      provider,
      oldProvider
    );
    let updatedObserversData = abaObserversList;
    if (selectedRadioButton === 2) {
      updatedObserversData = [];
      seriesEventObservations.map(observationEvent => {
        observationEvent.observations?.map(observer => {
          updatedObserversData.push(observer);
        });
      });
    }
    const submittedData = {
      event: eventData,
      recurrent: data.type === RescheduleModalType.recurringMappedValue,
      cancelNote: data.notes,
      cancelReasonId: data.reason || ATHENA_DEFAULT_DELETE_REASON,
      notes: '',
      updatedObserversData: updatedObserversData
    };
    await updateEvent({ variables: { data: submittedData } })
      .then(data => {
        const updatedEvent = data.data.updateEvent;
        if (updatedEvent) {
          const currentEvents = prepareEventsAfterReschedule(
            updatedEvent,
            events,
            providers,
            clients,
            view,
            calendarDate
          );
          calendarEventsPrefVar(currentEvents);
          handleDateSelect(moment(calendarDate).toDate());
          onClose();
        }
      })
      .catch(error => {
        console.log('Error while updating event ::', JSON.stringify(error));
        onCancel();
      });
  });

  const { clinicOptionsList } = useMemo(
    () => getCurrentClinicsOptionsV2(client, clinicsMap),
    [client, clinicsMap]
  );

  const handleClinicChange = useCallback(
    newClinicId => {
      //TODO:PLANNER_MAPPING LocationType type
      setAlertContent(null);
      if (fullEvent?.event.locationType === (LocationTypes.inClinic as any)) {
        const { startDate, endDate, locationType } = fullEvent.event;
        const providerId = provider?.id;
        const validateAppointmentVariables = {
          variables: {
            providerId,
            locationType,
            startTime: moment(startDate).toISOString(),
            endTime: moment(endDate).toISOString(),
            selectedClinicId: newClinicId
          }
        };
        if (providerId && startDate && endDate && newClinicId && locationType) {
          validateAppointment(validateAppointmentVariables);
        }
        const newRooms: IRoom[] = clinicsMap.get(newClinicId)?.roomsList || [];
        if (!newRooms.find(x => x.id === room.id))
          setValue(`${EditAppointmentPopup.room}.id`, undefined);
      }
    },
    [
      fullEvent,
      clinicsMap,
      room,
      setValue,
      validateAppointment,
      provider,
      alertContent
    ]
  );
  const roomsOptionsList = useMemo(() => {
    const newOptions: IOption[] = mapRoomsToOptions(
      clinicsMap.get(clinic?.id)?.rooms || []
    );
    return newOptions;
  }, [clinic, clinicsMap]);

  const reasonsOptionsList = useMemo(
    () =>
      cancellationReasons
        ? mapToOption(
            cancellationReasons?.map(reason => {
              return {
                id: reason.reasonId,
                value: reason.name
              };
            })
          )
        : [],
    [cancellationReasons]
  );

  const isDeleteEvent = useMemo(() => {
    return (
      deleteWhenCancelAppointmentsIds?.includes(
        fullEvent?.event?.appointmentType?.id!
      ) || type === RescheduleModalType.recurringMappedValue
    );
  }, [deleteWhenCancelAppointmentsIds, fullEvent.event.appointmentType, type]);

  const handleChangeRoom = (e: any) => {
    if (e.value !== '') {
      setIsSelectedRoom(true);
    }
  };
  const handleChangeReason = (e: any) => {
    if (e.value !== '') {
      setIsSelectedReason(true);
    }
  };
  let isAlertShownSet = false;
  if (selectedRadioButton == 2) {
    isAlertShownSet = getSeriesAbaObserverValidationStatus(
      seriesEventObservations,
      newStartDate,
      newEndDate
    );
  } else {
    isAlertShownSet = getAbaObserverValidationStatus(
      abaObserversList,
      newStartDate,
      newEndDate
    );
  }
  const [isValidated, setIsValidated] = useState<boolean>(isAlertShownSet);
  return (
    <ContentWrapper>
      <FormProvider {...methods}>
        <Content loading={loadingClinics} data={true} error={undefined}>
          {() => (
            <Grid columns={2}>
              <Grid.Row>
                <label className="title">
                  Confirm Reschedule:{' '}
                  {fullEvent?.event?.appointmentType?.parent
                    ? fullEvent?.event?.appointmentType?.parent.title
                    : fullEvent?.event?.appointmentType?.title}
                </label>
              </Grid.Row>
              {selectedRadioButton == 2 && (
                <>
                  <Grid.Row>
                    <label className="reschedule-message-warning">
                      Are you sure you want to reschedule the series?
                    </label>
                  </Grid.Row>
                  <Grid.Row>
                    {selectedRadioButton == 2 &&
                    observersCount > 0 &&
                    !isABAOrDTEvent ? (
                      <label className="impacted-observers-heading">
                        Impacted Observers:
                      </label>
                    ) : (
                      <></>
                    )}
                  </Grid.Row>
                  <Grid.Row className="Impacted-Observers">
                    {observationOfLeadEvent &&
                      observersCount > 0 &&
                      (observationOfLeadEvent.length > 0 &&
                      selectedRadioButton == 2 &&
                      !isABAOrDTEvent ? (
                        observationOfLeadEvent.map(
                          (observationEvent: IEvent, index: number) => {
                            return (
                              <ImpactedObservers
                                observationEvent={observationEvent.observations}
                                eventIndex={index}
                              />
                            );
                          }
                        )
                      ) : (
                        <></>
                      ))}
                  </Grid.Row>
                </>
              )}
              <Grid.Row>
                <label className="label subHeader">Attendees:</label>
              </Grid.Row>
              <Grid.Row>
                <ClientRow
                  userId={
                    fullEvent?.event?.type === EventType.adminEvent
                      ? provider?.id
                      : client?.id
                  }
                  alt={
                    fullEvent?.event?.type === EventType.adminEvent
                      ? provider?.displayName
                      : client?.name
                  }
                  clientName={client?.name}
                  abbreviation={fullEvent?.event?.clinic?.abbreviation}
                  email={provider?.email}
                />
              </Grid.Row>
              <Grid.Row>
                <div className="name-wrapper">
                  <div>
                    <Link
                      className="name"
                      target="_blank"
                      to={`/providers/${provider?.id}`}
                    >
                      <img
                        src={DEFAULT_DOCTOR_IMAGE}
                        alt={'pic'}
                        className="client-pic"
                      />
                      {provider?.hasOwnProperty('attendeeType')
                        ? formatUsernameOriginal(
                            provider?.firstName,
                            provider?.lastName
                          )
                        : `${formatUsernameOriginal(
                            provider?.firstName,
                            provider?.lastName
                          )}, ${
                            fullEvent?.event?.provider?.speciality?.abbreviation
                          } (${
                            fullEvent?.event?.provider?.clinic?.abbreviation
                          })`}
                    </Link>
                    {isConflictingProvider && (
                      <WarningOutlined className="icon" />
                    )}
                  </div>
                  <div className="attende-type">
                    <EyeOutlined className="type-icon" />
                    <label> Provider </label>
                  </div>
                </div>
              </Grid.Row>
              {ShadowFeatureAvailability &&
                (fullEvent.event.observationsCount !== undefined &&
                fullEvent.event.observationsCount > 0 &&
                !isABAOrDTEvent ? (
                  <ObservationRow
                    observations={currentEventInSeries?.observations}
                  />
                ) : (
                  <></>
                ))}
              {newStartDate &&
                newEndDate &&
                isABAOrDTEvent &&
                selectedRadioButton != 2 &&
                abaObserversList.map((Observations: IEvent, index: number) => (
                  <ObserverForm
                    key={index}
                    observerIndex={index + 1}
                    leadEvent={fullEvent.event}
                    observations={Observations}
                    setAbaObserversList={setAbaObserversList}
                    abaObserversList={abaObserversList}
                    setIsValidated={setIsValidated}
                    newStartDate={newStartDate}
                    newEndDate={newEndDate}
                    setLastAbaObserver={setLastAbaObserver}
                    isValidated={isValidated}
                    initialValidation={true}
                    setSeriesEventObservations={setSeriesEventObservations}
                  ></ObserverForm>
                ))}
              <Grid.Row>
                <Grid.Column>
                  <div style={{ marginTop: '10px' }}></div>
                  <label className="label subHeader">Event Details:</label>
                </Grid.Column>
                {isSeries && (
                  <Grid.Column>
                    <Grid.Row className={'observationBtn-row'}>
                      <Grid.Column width={1}>
                        {seriesEventIndex !== 0 && (
                          <></>
                          // <button
                          //   onClick={previous}
                          //   className="observationButton"
                          // >
                          //   <CaretLeftOutlined />
                          // </button>
                        )}
                      </Grid.Column>
                      {/* {observationOfLeadEvent?.length !== 0 && (
                        <Grid.Column className="observationButton">
                          More Observers
                        </Grid.Column>
                      )} */}
                      {/* <Grid.Column width={1}>
                        <button onClick={next} className="observationButton">
                          {seriesEventIndex <
                            observationOfLeadEvent!.length - 1 && <></>}
                        </button>
                      </Grid.Column> */}
                    </Grid.Row>
                  </Grid.Column>
                )}
              </Grid.Row>
              <Grid.Row className="date">
                <TimeRange start={newStartDate!} end={newEndDate!} />
              </Grid.Row>
              <Grid.Row className="date">
                <Grid.Column>
                  <label className="label subHeader">Location</label>
                </Grid.Column>{' '}
              </Grid.Row>
              {fullEvent?.event?.locationType !== LocationTypes.offSite && (
                <Grid.Row className="row-maxWidth">
                  <FormItem>
                    <Controller
                      name={`${EditAppointmentPopup.clinic}.id`}
                      render={fieldProps => (
                        <Select
                          options={clinicOptionsList}
                          className="rooms-dropdown selected"
                          placeholder="Select Clinic"
                          field={fieldProps}
                          errors={errors}
                          onSelect={handleClinicChange}
                        />
                      )}
                    />
                  </FormItem>
                </Grid.Row>
              )}
              {fullEvent?.event?.locationType === LocationTypes.inClinic && (
                <Grid.Row className="row-maxWidth">
                  <FormItem>
                    <Controller
                      name={`${EditAppointmentPopup.room}.id`}
                      render={fieldProps => (
                        <Select
                          onSelect={handleChangeRoom}
                          options={roomsOptionsList}
                          className={
                            isSelectedRoom
                              ? 'rooms-dropdown selected'
                              : 'rooms-dropdown'
                          }
                          listHeight={162}
                          placeholder="Select Room"
                          field={fieldProps}
                          errors={errors}
                        />
                      )}
                    />
                  </FormItem>
                </Grid.Row>
              )}
              {fullEvent?.event?.locationType === LocationTypes.offSite && (
                <Grid.Row className="row-maxWidth reschedule-location-row">
                  <OffsiteLocation clientId={client?.id} />
                </Grid.Row>
              )}
              {fullEvent?.event?.locationType === LocationTypes.teleHealth && (
                <Grid.Row className="row-maxWidth">
                  <FormItem>
                    <Controller
                      name={EditAppointmentPopup.telehealthLink}
                      render={fieldProps => (
                        <Input
                          field={fieldProps}
                          className="input"
                          placeholder="Add Tele Health Link"
                          errors={errors}
                        />
                      )}
                    />
                  </FormItem>
                </Grid.Row>
              )}
              {isSeries && (
                <>
                  <Grid.Row>
                    <label className="label subHeader">Recurrence:</label>
                  </Grid.Row>
                  <Grid.Row className="radio-btns">
                    <Controller
                      name={EditAppointmentPopup.type}
                      render={() => (
                        <EventTypeRadioGroup
                          methods={methods}
                          recurringEventsCount={recurringEventsCount}
                          radioButtonStatus={radioButtonStatus}
                          observersCount={observersCount}
                        />
                      )}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    {selectedRadioButton == 2 &&
                    isABAOrDTEvent &&
                    seriesEventObservations.length > 0 ? (
                      <label className="impacted-observers-heading">
                        Impacted Observers:
                      </label>
                    ) : (
                      <></>
                    )}
                  </Grid.Row>
                  <Grid.Row className="Impacted-Observers">
                    {observationOfLeadEvent &&
                      observersCount > 0 &&
                      (selectedRadioButton == 2 &&
                      isABAOrDTEvent &&
                      seriesEventObservations.length > 0 ? (
                        seriesEventObservations?.map(
                          (observationEvent: IEvent, index: number) => {
                            return (
                              observationEvent.observations?.length! > 0 && (
                                <AbaImpactedObservers
                                  observationEvent={
                                    observationEvent.observations
                                  }
                                  eventIndex={index}
                                  setSeriesEventObservations={
                                    setSeriesEventObservations
                                  }
                                  seriesEventObservations={
                                    seriesEventObservations
                                  }
                                  leadEvent={observationEvent}
                                  setIsValidated={setIsValidated}
                                  isValidated={isValidated}
                                  newStartDate={newStartDate!}
                                  newEndDate={newEndDate!}
                                  initialValidation={true}
                                  setAbaObserversList={setAbaObserversList}
                                  eventType={
                                    fullEvent.event.appointmentType?.eventType
                                      ?.name!
                                  }
                                />
                              )
                            );
                          }
                        )
                      ) : (
                        <></>
                      ))}
                  </Grid.Row>
                </>
              )}

              {(!isDeleteEvent ||
                !getFeatureAvailability(FEATURES.RESCHEDULE_MODAL_UPDATES)) && (
                <>
                  <Grid.Row className="row-maxWidth">
                    <FormItem>
                      <Controller
                        name={EditAppointmentPopup.reason}
                        render={fieldProps => (
                          <Select
                            showSearch
                            onSelect={handleChangeReason}
                            options={reasonsOptionsList}
                            className={
                              isSelectedReason
                                ? 'rooms-dropdown selected'
                                : 'rooms-dropdown'
                            }
                            field={fieldProps}
                            filterOption={(
                              input: string | undefined,
                              option: { children: string }
                            ) =>
                              option.children
                                ?.toLowerCase()
                                .includes(input?.toLowerCase() ?? '')
                            }
                            listHeight={162}
                            placeholder={
                              !isSeries ||
                              type === RescheduleModalType.singleMappedValue
                                ? 'Select Reason'
                                : 'Select Reason (Optional)'
                            }
                            errors={errors}
                          />
                        )}
                      />
                    </FormItem>
                  </Grid.Row>
                  <Grid.Row className="row-maxWidth">
                    <FormItem>
                      <Controller
                        name={EditAppointmentPopup.notes}
                        as={
                          <TextArea
                            className="text-area"
                            placeholder="Add Note (Optional)"
                            errors={errors}
                          />
                        }
                      />
                    </FormItem>
                  </Grid.Row>
                </>
              )}
              <Grid.Row>
                <label className="label subHeader">Previously</label>
              </Grid.Row>
              {oldProvider && (
                <Grid.Row>
                  <div className="name-wrapper">
                    <Link
                      className="name"
                      target="_blank"
                      to={`/providers/${oldProvider.id}`}
                    >
                      {`${oldProvider?.name}, ${oldProvider?.speciality?.abbreviation} (${oldProvider?.clinic?.abbreviation})`}
                    </Link>
                  </div>
                </Grid.Row>
              )}
              <Grid.Row className="date">
                <TimeRange
                  start={oldStartDate!}
                  end={oldEndDate!}
                  oneLineFormat
                />
              </Grid.Row>
              {fullEvent?.event?.locationType === LocationTypes.inClinic && (
                <Grid.Row>
                  <label className="room-name label">
                    Room name: <label className="room-name">{roomName}</label>
                  </label>
                </Grid.Row>
              )}
              {fullEvent?.event?.locationType === LocationTypes.offSite && (
                <Grid.Row className="name-wrapper">
                  <label>{`${fullEvent?.event?.address?.name}, ${fullEvent?.event?.address?.addressLine1}, ${fullEvent?.event?.address?.city}, ${fullEvent?.event?.address?.state}, ${fullEvent?.event?.address?.zipCode}`}</label>
                </Grid.Row>
              )}
              {fullEvent?.event?.locationType === LocationTypes.teleHealth && (
                <Grid.Row className="name-wrapper">
                  <label>{fullEvent?.event?.telehealthLink}</label>
                </Grid.Row>
              )}
              {isConverted && (
                <Grid.Row className="name-wrapper">
                  <p style={{ color: '#e82424', marginTop: '20px' }}>
                    This event is converted in CentralReach and it cannot be
                    rescheduled.
                  </p>
                </Grid.Row>
              )}

              <Grid.Row className="modal-actions">
                <PopupActions
                  disabled={
                    isSubmitting ||
                    isValidated ||
                    isConverted! ||
                    locationValidationInProgress
                  }
                  loading={isSubmitting}
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                />
              </Grid.Row>
              {alertContent && (
                <ImpactedObserversWrapper>
                  <LocationValidatorAlert
                    alertContent={alertContent}
                    setAlertContent={setAlertContent}
                  />
                </ImpactedObserversWrapper>
              )}
            </Grid>
          )}
        </Content>
      </FormProvider>
    </ContentWrapper>
  );
};

export default React.memo(ClientForm);

export const getSeriesAbaObserverValidationStatus = (
  seriesEventObservations: any[],
  newStartDate: moment.MomentInput | undefined,
  newEndDate: moment.MomentInput | undefined
) => {
  let isAlertShownSet = false;
  seriesEventObservations.map(observationEvent => {
    observationEvent?.observations?.map(
      (eventObj: {
        startDate: moment.MomentInput | undefined;
        endDate: moment.MomentInput | undefined;
        duration: any;
      }) => {
        const observerStartTime = moment(
          moment(eventObj.startDate).format('HH:mm:ss'),
          'HH:mm:ss'
        );
        const observerEndTime = moment(
          moment(eventObj.endDate).format('HH:mm:ss'),
          'HH:mm:ss'
        );
        const eventStartTime = moment(
          moment(newStartDate).format('HH:mm:ss'),
          'HH:mm:ss'
        );
        const eventEndTime = moment(
          moment(newEndDate).format('HH:mm:ss'),
          'HH:mm:ss'
        );
        if (
          observerStartTime.isBefore(eventStartTime) ||
          observerStartTime.isAfter(eventEndTime) ||
          observerEndTime.isBefore(eventStartTime) ||
          observerEndTime.isAfter(eventEndTime)
        ) {
          isAlertShownSet = true;
        } else if (eventObj?.duration! < 15) {
          isAlertShownSet = true;
        }
      }
    );
  });
  return isAlertShownSet;
};

export const getAbaObserverValidationStatus = (
  abaObserversList: any[],
  newStartDate: moment.MomentInput | undefined,
  newEndDate: moment.MomentInput | undefined
) => {
  let isAlertShownSet = false;
  abaObserversList.map(eventObj => {
    const observerStartTime = moment(
      moment(eventObj.startDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    const observerEndTime = moment(
      moment(eventObj.endDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    const eventStartTime = moment(
      moment(newStartDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    const eventEndTime = moment(
      moment(newEndDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    if (
      observerStartTime.isBefore(eventStartTime) ||
      observerStartTime.isAfter(eventEndTime) ||
      observerEndTime.isBefore(eventStartTime) ||
      observerEndTime.isAfter(eventEndTime)
    ) {
      isAlertShownSet = true;
    } else if (eventObj?.duration! < 15) {
      isAlertShownSet = true;
    }
  });
  return isAlertShownSet;
};
