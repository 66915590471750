import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { IBreadcrumbSection } from 'model/ui/breadcrumb';
import { BreadcrumbWrapper } from '../style';

interface Props {
  sections: IBreadcrumbSection[];
}

const BreadcrumbComponent: React.FC<Props> = ({ sections }: Props) => (
  <BreadcrumbWrapper>
    {sections.map((section, index) => (
      <React.Fragment key={section.key}>
        <Breadcrumb size={section.size}>
          <Breadcrumb.Section {...section} />
          {index < sections.length - 1 && (
            <Breadcrumb.Divider icon="right angle" />
          )}
        </Breadcrumb>
      </React.Fragment>
    ))}
  </BreadcrumbWrapper>
);

export default React.memo(BreadcrumbComponent);
