import styled from 'styled-components';
import { Button } from 'antd';
import { PURPLE, WHITE } from 'utils/constants/theme';

export const LibButton = styled(Button)`
  &.large {
    min-width: 140px;
    height: 48px;
    color: #ffffff;
    font-weight: bold;
    background: ${PURPLE};
    border: 2px solid ${PURPLE};
    border-radius: 8px;
    &:hover {
      background: #fff;
      color: ${PURPLE};
    }
    &[disabled] {
      color: #919699;
      background-color: #e7eaed;
      border-color: #e7eaed;
    }
  }
  &.generate-results {
    min-width: 255px;
    height: 48px;
    color: #ffffff;
    font-weight: bold;
    background: ${PURPLE};
    border: 2px solid ${PURPLE};
    border-radius: 8px;
    margin-left: 65px;
    &:hover {
      background: #fff;
      color: ${PURPLE};
    }
    &[disabled] {
      color: #919699;
      background-color: #e7eaed;
      border-color: #e7eaed;
    }
  }
  &.gray {
    min-width: 140px;
    height: 48px;
    border-radius: 8px;
    &:hover,
    &:focus {
      border-color: ${PURPLE};
      color: ${PURPLE};
    }
    &[disabled] {
      color: #919699;
      background-color: #e7eaed;
      border-color: #e7eaed;
    }
  }
  &.secondary {
    height: 32px;
    border-radius: 8px;
    width: fit-content;
    border: solid 2px ${PURPLE};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${PURPLE};
    &:hover,
    &.active {
      background: ${PURPLE};
      color: #fff;
    }
    &.red {
      border-color: #cc3f3f;
      color: #cc3f3f;
      &:hover {
        background-color: #cc3f3f;
        color: #fff;
      }
    }
    &.saved {
      color: ${WHITE};
      background: ${PURPLE};
    }
  }
  &.sign-out {
    font-size: 14px;
    border: none;
    padding: 0;
    height: auto;
    color: inherit;

    .icon {
      margin-right: 8px;
    }

    &:hover {
      color: #40a9ff;
      transition: color 0.3s;
    }
  }
  &.edit-button-2 {
    font-size: 15px;
    letter-spacing: 0.17px;
    color: #0d77b5;
    border: none;
    span {
      text-decoration: underline;
    }
  }
`;
