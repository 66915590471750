import React from 'react';
import { AuthAppointmentWrapper, DataContainer } from './MainCalendar.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { formatAppointmentAuthTherapy } from 'utils/mappers/response/authorizations';

const AuthAppointmentData = ({ title, children, isAuthAppt }) => (
  <DataContainer isAuthAppt={isAuthAppt}>
    <h5>{title}</h5>
    {children}
  </DataContainer>
);

const AppointmentAuth = ({
  authTherapy,
  appointmentType,
  authorizedTherapyID
}) => {
  const formattedAuthTherapy = formatAppointmentAuthTherapy(
    authTherapy,
    appointmentType,
    authorizedTherapyID
  );

  return (
    <AuthAppointmentWrapper
      className="auth-appointment-wrapper"
      isAuthAppt={formattedAuthTherapy.isValidAuth}
    >
      {!formattedAuthTherapy.isValidAuth ? (
        <div className="no-authorizations">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <h4>No Authorizations</h4>
        </div>
      ) : (
        <h4>Authorizations</h4>
      )}
      <div className="wrapper_data">
        <span>ID: {authTherapy.authGroupID}</span>
        <div className="start_end">
          <span>{authTherapy.startDate}</span>
          <span className="date-separator"> - </span>
          <span>{authTherapy.endDate}</span>
        </div>
      </div>
      <div className="auth_therapies_data">
        <AuthAppointmentData
          title="Completed"
          isAuthAppt={formattedAuthTherapy.isValidAuth}
        >
          <span>{formattedAuthTherapy.completed}</span>
        </AuthAppointmentData>
        <AuthAppointmentData
          title="Scheduled"
          isAuthAppt={formattedAuthTherapy.isValidAuth}
        >
          <span>{formattedAuthTherapy.scheduled}</span>
        </AuthAppointmentData>
        <AuthAppointmentData
          title="Remaining"
          isAuthAppt={formattedAuthTherapy.isValidAuth}
        >
          <span className="red">{formattedAuthTherapy.remaining}</span>
        </AuthAppointmentData>
        <AuthAppointmentData
          title="To Fulfill"
          isAuthAppt={formattedAuthTherapy.isValidAuth}
        >
          <span>{formattedAuthTherapy.toFulfill}</span>
        </AuthAppointmentData>
      </div>
    </AuthAppointmentWrapper>
  );
};

export default AppointmentAuth;
