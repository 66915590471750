import React from 'react';
import { Tabs } from 'lib/ui';
import { TabsWrap } from 'views/components/clients/profile/profile.style';
import {
  APPOINTMENT_CLIENT_AUTH_THERAPIES_COLUMNS,
  APPOINTMENT_CLIENT_EXPANDABLE_TABLE_COLUMNS,
  APPOINTMENT_CLIENT_WAITLIST_COLUMNS
} from 'utils/constants/tables/clients';
import { getFeatureAvailability, FEATURES } from 'utils/featureToggle';
import AuthorizedTherapies from 'views/components/clients/profile/tabs/AuthorizedTherapies';
import ClientCalendar from './ClientCalendar';
import Waitlist from './Waitlist/Waitlist';

const AppointmentClientTab = ({ client }) => {
  const availabilityPane = {
    title: 'Availability',
    content: (
      <div className="appointment-sidebar__calendar">
        <ClientCalendar client={client} />
      </div>
    )
  };

  const authorizedTherapiesPane = {
    title: 'Authorized Therapies',
    content: (
      <AuthorizedTherapies
        client={client}
        columns={APPOINTMENT_CLIENT_AUTH_THERAPIES_COLUMNS}
        expandableColumns={APPOINTMENT_CLIENT_EXPANDABLE_TABLE_COLUMNS}
        expandedRowByClick
      />
    )
  };

  const waitlist = {
    title: 'Waitlist',
    content: (
      <Waitlist client={client} columns={APPOINTMENT_CLIENT_WAITLIST_COLUMNS} />
    )
  };

  let panes = [availabilityPane];

  if (getFeatureAvailability(FEATURES.AUTHORIZATION))
    panes.push(authorizedTherapiesPane);

  if (getFeatureAvailability(FEATURES.WAITLIST)) panes.push(waitlist);

  return (
    <TabsWrap>
      <Tabs type="card" panes={panes} />
    </TabsWrap>
  );
};

export default AppointmentClientTab;
