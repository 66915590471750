import React from 'react';
import DayFilterRow from './DayFilterRow';
import { DAYS_FILTER } from 'utils/constants/lists';
import { ClientDayAvailabilityTimeSlot } from 'model/v2';

interface IProps {
  ClientAvailabilityDayFilter: {
    dayIndex: number;
    preferredTimes: ClientDayAvailabilityTimeSlot[];
  }[];
  handleClientAvailabilityDayFilter: Function;
}

const DaysFilter: React.FC<IProps> = ({
  ClientAvailabilityDayFilter,
  handleClientAvailabilityDayFilter
}) => {
  return (
    <>
      {DAYS_FILTER.map((day, index) => {
        const selectedDay = ClientAvailabilityDayFilter.find(
          selected => selected.dayIndex === day.value
        ) || { preferredTimes: [] };

        return (
          <DayFilterRow
            key={index}
            day={day}
            handleClientAvailabilityDayFilter={(newTimeSlot: any) =>
              handleClientAvailabilityDayFilter(newTimeSlot)
            }
            selectedTimes={selectedDay.preferredTimes}
            ClientAvailabilityDayFilter={ClientAvailabilityDayFilter}
          />
        );
      })}{' '}
    </>
  );
};

export default React.memo(DaysFilter);
