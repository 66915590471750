import {
  GET_FULL_EVENT,
  GET_FULL_OBSERVER_EVENT
} from 'api/graphql/v2/queries/Events';
import { IFullObserverEvent, SidebarState } from 'model/v2';
import React, { Dispatch } from 'react';
import { useLazyQuery } from 'react-apollo';
import { EVENT_STATUS } from 'utils/constants/status';
import Content from 'views/components/ui/content';
import HeaderView from 'pages/MainCalendarPage/AppointmentObserver/HeaderView';
import ObserverView from './ObserverView';
import { AppointmentViewWrapper } from './style';
import CancelObservationModal from '../AppointmentModals/CancelModal/CancelObservationModal';
import { useCancellationReasons } from 'hooks/CancellationReasonsHook';

interface Props {
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  openSidebar: (sidebarState: SidebarState) => void;
  setIsAddObservationMode: Dispatch<React.SetStateAction<boolean>>;
}
const AppointmentObserver: React.FC<Props> = ({
  setVisible,
  sidebarState,
  openSidebar,
  setIsAddObservationMode
}: Props) => {
  const [cancel, setCancel] = React.useState(false);
  const { cancelReasons, isCancelReasonsLoading } = useCancellationReasons();

  const [
    doFullEventRequest,
    { data, loading: loadingData, error }
  ] = useLazyQuery(GET_FULL_OBSERVER_EVENT, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      doObserversEventRequest({
        variables: {
          id: data.observerEvent.leadEvent?.id
        }
      });
    }
  });
  const [doObserversEventRequest, { data: ObserversList }] = useLazyQuery(
    GET_FULL_EVENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: ObserversList => {
        console.log('observersLisrt data', ObserversList);
      }
    }
  );
  console.log(isCancelReasonsLoading, 'isCancelReasonsLoading');
  React.useEffect(() => {
    if (sidebarState.event?.id) {
      doFullEventRequest({
        variables: {
          id: sidebarState.event?.id,
          canceled: sidebarState.event?.status === EVENT_STATUS.canceled
        }
      });
    }
  }, [sidebarState, doFullEventRequest]);

  const handleCancel = React.useCallback(() => {
    setCancel(!cancel);
  }, [cancel, setCancel]);
  return (
    <AppointmentViewWrapper>
      <HeaderView
        setVisible={setVisible}
        event={data?.observerEvent}
        handleCancel={handleCancel}
      />
      <Content
        loading={loadingData}
        error={
          error || data?.observerEvent === null
            ? new Error("Event Couldn't be found")
            : undefined
        }
        data={data}
      >
        {(data: IFullObserverEvent) => (
          <>
            {cancel && (
              <CancelObservationModal
                observationEventId={data?.observerEvent.id!}
                leadEventId={data.observerEvent.leadEvent?.id!}
                setCancel={setCancel}
                setVisible={setVisible}
                cancelReasons={cancelReasons}
              />
            )}
            <ObserverView
              event={data?.observerEvent}
              observersEvent={ObserversList}
              openSidebar={openSidebar}
              setIsAddObservationMode={setIsAddObservationMode}
            />
          </>
        )}
      </Content>
    </AppointmentViewWrapper>
  );
};
export default React.memo(AppointmentObserver);
