import React, { useCallback, useMemo } from 'react';

import { BigCalendar } from 'lib/ui';
import 'react-big-calendar/lib/sass/styles.scss';
import {
  customEventPropGetter,
  customizeRange,
  localizer
} from 'utils/builders/calendar';
import {
  CALENDAR_SLOT_STEP,
  CALENDAR_TIMESLOTS,
  CALENDAR_FORMATS,
  MIN_CALENDAR_TIME,
  MAX_CALENDAR_PREVIEW_TIME
} from 'utils/constants/calendar';

import Event from '../../../views/components/calendar/mainCalendar/event';
import {
  CalendarProps,
  EventProps,
  ToolbarProps,
  View
} from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';

import MainCalendarToolbar from '../calendarToolbar';
import { CalendarView, views } from 'utils/cache/calendar';

import {
  CalendarWrapper,
  GutterHeaderWrapper,
  ResourceAccessorTitle
} from './style';
import { ICalendarEvent } from 'model/calendar/events';
import { dayNumber, getDay } from 'utils/format';
import { IStaffResource } from 'model/calendar/filters';
import {
  calendarEventsPrefVar,
  clientsResourcesPrevVar,
  providerResourcesPrevVar
} from 'utils/cache/filters';
import { useReactiveVar } from '@apollo/client';
import { SidebarState } from 'model/v2';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';

interface Props {
  view: View;
  selectedDate: Date;
  handleViewChange: (view: CalendarView) => void;
  handleNavigation: (date: Date) => void;
  openSidebar: (sidebarState: SidebarState) => void;
}

const Calendar: React.FC<Props> = ({
  view,
  selectedDate,
  handleViewChange,
  handleNavigation,
  openSidebar
}: Props) => {
  const events = useReactiveVar(calendarEventsPrefVar);
  const providers: any = useReactiveVar(providerResourcesPrevVar);
  const clients: any = useReactiveVar(clientsResourcesPrevVar);
  const groups = useMemo(() => clients.concat(providers), [clients, providers]);

  const customResourceTitleAccessor = useCallback(
    (selectedFilter: object): JSX.Element => {
      const { displayName } = selectedFilter as IStaffResource;

      return (
        <ResourceAccessorTitle>
          <div className="resource-name">{displayName}</div>
        </ResourceAccessorTitle>
      );
    },
    []
  );

  const customToolbar = useCallback(
    (data: ToolbarProps<ICalendarEvent, object>): JSX.Element => (
      <MainCalendarToolbar
        data={data}
        range={customizeRange(selectedDate, view)}
        updateView={handleViewChange}
        handleNavigation={handleNavigation}
      />
    ),

    [selectedDate, view, handleNavigation, handleViewChange]
  );

  const customEvent = useCallback(
    ({ event }: EventProps<ICalendarEvent>): JSX.Element => (
      <Event event={event} view={view} />
    ),
    [view]
  );

  const customTimeGutterHeader = useCallback(() => {
    return view === views.DAY ? (
      <GutterHeaderWrapper>
        <p className="name">{getDay(selectedDate)}</p>
        <div className="calendar-day">
          <span>{dayNumber(selectedDate)}</span>
        </div>
      </GutterHeaderWrapper>
    ) : null;
  }, [selectedDate, view]);

  const calendarOptions: CalendarProps<ICalendarEvent, any> = {
    localizer,
    events: events,
    defaultView: view,
    views: [views.DAY, views.WEEK] as View[],
    onView: handleViewChange,
    min: MIN_CALENDAR_TIME,
    max: MAX_CALENDAR_PREVIEW_TIME,
    step: CALENDAR_SLOT_STEP,
    timeslots: CALENDAR_TIMESLOTS,
    date: selectedDate,
    formats: CALENDAR_FORMATS,
    components: {
      toolbar: customToolbar,
      event: customEvent,
      timeGutterHeader: customTimeGutterHeader
    },
    eventPropGetter: customEventPropGetter,
    onNavigate: handleNavigation,
    onShowMore: (_events, date) => {
      handleNavigation(date);
    },
    onSelectEvent: event => {
      openSidebar({
        event: { id: event.id },
        action: SIDEBAR_ACTIONS.VIEW
      });
    },
    //TODO: Reaplace resources with value from selectedFilters
    //show searched/filtered clients or staff
    resourceTitleAccessor: customResourceTitleAccessor,
    ...(view === views.DAY && {
      resources: groups, //array of all filters
      resourceIdAccessor: 'id'
    })
  };

  return (
    <CalendarWrapper className={view}>
      <BigCalendar {...calendarOptions} />
    </CalendarWrapper>
  );
};

export default React.memo(Calendar);
