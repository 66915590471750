import React from 'react';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { useFormContext, Controller } from 'react-hook-form';
import { TimerTextField } from 'api/sharedComponents/reactHookFormComponents';
import moment from 'antd/node_modules/moment';

interface IProps {
  name: string;
  disabled?: boolean;
}

const HoursInfoTimer: React.FC<IProps> = ({ name, disabled }: IProps) => {
  const { control, errors, setValue, trigger } = useFormContext();

  const timeHandler = React.useCallback(
    event => {
      const time = event.target.value;
      const momentValue = moment(time, 'HH:mm');
      setValue(name, momentValue.format('HH:mm'), { shouldDirty: true });
      trigger(name);
    },
    [name, setValue, trigger]
  );

  return (
    <FormItem>
      <Controller
        control={control}
        name={name}
        render={fieldProps => (
          <TimerTextField
            disabled={disabled}
            field={fieldProps}
            defaultValue={fieldProps.value}
            errors={errors}
            onChange={timeHandler}
            style={{ width: '150px' }}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: 900
            }}
          />
        )}
      />
    </FormItem>
  );
};
export default React.memo(HoursInfoTimer);
