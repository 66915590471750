import gql from 'graphql-tag';

export const smartResultCard = gql`
  fragment SmartResultCard on SmartScheduleOutput {
    clientTimezone
    openingCards {
      timeSlots {
        timeSlot {
          startTime
          endTime
          adjacentType
        }
        clientFullyAvailable
        effectiveStartDate
        previousAdjacentEndDate
        trailingAdjacentEndDate
      }
      provider {
        id
        name
        telehealthLinks
        clinic {
          id
          timezone
          abbreviation
        }
        speciality {
          id
          abbreviation
        }
      }
      appointmentType {
        id
        title
      }
      clinic {
        id
        name
        abbreviation
      }
      endDate
      dayOfWeek
      isTelehealth
      isInClinic
      isOffsite
      allowedLocations
      maxBookableMinutes
    }
  }
`;
