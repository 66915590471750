import React from 'react';

import { LibTable } from './table.style';

const Table = ({ ...restProps }) => {
  return <LibTable {...restProps} />;
};

export default Table;
export { Table };
