import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { CarePlanWrapper } from '../style';
import QuestionIcon from 'assets/img/icon-question.svg';
import { FilterSelectionWrapper, NewClientFilter } from './style';
import { Popover } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import InputClientAvailabilityView from './InputClientAvailabilityView';

interface Props {
  setAutoGeneratedResults: Dispatch<SetStateAction<boolean>>;
}
const InputClientAvailabilityFilter: React.FC<Props> = ({
  setAutoGeneratedResults
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [clientAvailabilityErrMsg, setClientAvailabilityErrMsg] = useState<
    string
  >();
  const methods = useFormContext();
  let { client } = useWatch({
    name: ['client'],
    control: methods.control
  })!;
  if (client === undefined) client = methods.getValues('client');
  const handleClick = () => {
    if (client && client.id) setOpen(open => !open);
  };

  useEffect(() => {
    if (!client?.id) {
      setOpen(false);
      return;
    }
  }, [client]);

  const getAccordionContent = (
    setAutoGeneratedResults: Dispatch<SetStateAction<boolean>>
  ) => {
    return (
      <NewClientFilter>
        <FilterSelectionWrapper>
          <Grid>
            {
              <Grid.Row style={{ paddingTop: '0px' }}>
                {getFeatureAvailability(FEATURES.VIEW_CLIENT_AVAILABILITY) &&
                  client &&
                  client.id && (
                    <InputClientAvailabilityView
                      clientId={client.id}
                      setClientAvailabilityErrMsg={setClientAvailabilityErrMsg}
                      setAutoGeneratedResults={setAutoGeneratedResults}
                    />
                  )}
              </Grid.Row>
            }
          </Grid>
        </FilterSelectionWrapper>
      </NewClientFilter>
    );
  };
  const popoverContent = useMemo(() => {
    return (
      <ul>
        <li>
          We recommend using the Soonest Available date in order to find the
          best match
        </li>
        <li>
          Toggling on the Find Cortica’s first available option will show you
          options for any day of the week, any time, and any location, depending
          on Cortica’s provider availability. Use this option if you do not see
          enough result options
        </li>
      </ul>
    );
  }, []);
  return (
    <CarePlanWrapper>
      <Accordion>
        <Accordion.Title active={true} onClick={handleClick}>
          <Grid>
            <div className="rectangle"></div>
            <Grid.Row className="care-plan" floated="left" width={60}>
              Input Client Availability
              <Popover
                placement="bottomLeft"
                content={popoverContent}
                title="Client Availability"
                trigger="hover"
              >
                <img
                  alt="ques"
                  src={QuestionIcon}
                  width={22}
                  style={{ paddingLeft: 4 }}
                />
              </Popover>
              <Grid.Column floated="right" width="1" className="arrow">
                {open ? <Icon name="angle up" /> : <Icon name="angle down" />}
              </Grid.Column>
            </Grid.Row>
            <div className="rectangle"></div>
          </Grid>
        </Accordion.Title>
        <div className="errorMsg">{clientAvailabilityErrMsg}</div>
        <Accordion.Content
          active={open}
          content={getAccordionContent(setAutoGeneratedResults)}
        />
      </Accordion>
    </CarePlanWrapper>
  );
};
export default React.memo(InputClientAvailabilityFilter);
