import styled from 'styled-components';

export const TabsWrap = styled.div`
  .ant-table-body {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
  }
  .ant-table-content {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      background-color: #ffffff;
      height: 55px;
      width: 100vw;
      border-bottom: 1px solid #e8e8e8;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background-color: #ffffff;
      height: 55px;
      width: 100vw;
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .schedule-calendar {
    padding-top: 40px;
    max-width: 1280px;
    margin: 0 auto;
    .calendar-nav {
      position: relative;
      left: -100px;
      margin: 0 auto;
    }
  }
`;
export const SearchFormWrap = styled.div`
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ffffff;
    height: 55px;
    width: 100vw;
    border-bottom: 1px solid #e8e8e8;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ffffff;
    height: 55px;
    width: 100vw;
    border-bottom: 1px solid #e8e8e8;
  }
  > div {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    margin-left: auto;
  }
`;
