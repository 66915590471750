/* eslint-disable no-constant-condition */
import React, { Dispatch, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'semantic-ui-react';
import { isCheckedIndex } from 'utils/validators/smartSchedule';
import { CardWrapper } from './Style';
import RecurringResultHeader from './RecurringResultHeader';
import RecurringResultDescription from './RecurringResultDescription';
import RecurringSSRangeResultDescription from './SmartScheduleRangeResults/RecurringSSRangeResultDescription';
import { IAddress, IProvider, ISmartResultCard } from 'model/v2';
import { useLazyQuery } from 'react-apollo';
import { FETCH_ESTIMATED_DRIVE_TIME } from 'api/graphql/v2/queries/Events';

interface IProps {
  clientTimezone: string;
  appointmentTypeTitle: string;
  result: ISmartResultCard;
  index: number;
  updateSelectedCards: (idx: number, isChecked: boolean) => void;
  setOpeningCards: Dispatch<React.SetStateAction<ISmartResultCard[]>>;
  clientAddresses: IAddress[];
  providersMap: Map<any, IProvider>;
}
const RecurringResultItemCard: React.FC<IProps> = ({
  clientTimezone,
  appointmentTypeTitle,
  result,
  index,
  updateSelectedCards,
  setOpeningCards,
  clientAddresses,
  providersMap
}: IProps) => {
  const methods = useFormContext();
  const { watch } = methods;
  const isCheckedCard = watch(isCheckedIndex(index));
  const border = useMemo(
    () => (isCheckedCard ? '2px solid #6f42f5' : '2px solid #f4efef'),
    [isCheckedCard]
  );
  const rangeBasedResult = !!result.timeRanges;
  const [estimatedDriveTime, setEstimatedDriveTime] = useState<string>('');
  const [showEstimatedDriveTime, setShowEstimatedDriveTime] = useState<boolean>(
    true
  );
  const addressObj = providersMap.get(result.provider.id!)?.clinic?.address;
  const clinicAddress = `${addressObj?.addressLine1 ||
    ''},${addressObj?.addressLine2 || ''},${addressObj?.city ||
    ''},${addressObj?.state || ''},${addressObj?.zipCode || ''}`;
  const [
    getEstimatedDriveTime,
    { loading: isAdminDriveTimeLoading, data: driveTimeAddress }
  ] = useLazyQuery(FETCH_ESTIMATED_DRIVE_TIME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (driveTimeAddress !== undefined) {
        const minTravelTimeInMins =
          driveTimeAddress.getEstimatedDriveTime.minTravelTimeInMins;
        const maxTravelTimeInMins =
          driveTimeAddress.getEstimatedDriveTime.maxTravelTimeInMins;
        let driveTime = `${minTravelTimeInMins} minutes`;
        if (minTravelTimeInMins < maxTravelTimeInMins)
          driveTime = `${minTravelTimeInMins} - ${maxTravelTimeInMins} minutes`;
        setEstimatedDriveTime(driveTime);
        return;
      }
    }
  });
  const calculateEstimatedDriveTime = useCallback(addressObj => {
    const address = `${addressObj.addressLine1 ||
      ''},${addressObj.addressLine2 || ''},${addressObj.city ||
      ''},${addressObj.state || ''},${addressObj.zipCode || ''}`;
    setShowEstimatedDriveTime(true);
    getEstimatedDriveTime({
      variables: {
        startDate: result.startTime,
        endDate: result.endTime,
        providerId: result.provider.id,
        address: address,
        clinicAddress: clinicAddress,
        isOffSite: true
      }
    });
  }, []);
  useMemo(() => {
    getEstimatedDriveTime({
      variables: {
        startDate: result.startTime,
        endDate: result.endTime,
        providerId: result.provider.id,
        address: clinicAddress,
        clinicAddress: clinicAddress,
        isOffSite: false
      }
    });
  }, []);
  return (
    <CardWrapper>
      <div className={isCheckedCard ? 'checked' : ''}>
        <Card fluid style={{ border: border }}>
          <Card.Content
            style={{ border: 'none', borderBottom: '1px solid #f4efef' }}
            header={
              <RecurringResultHeader
                appointmentTitle={appointmentTypeTitle}
                result={result}
                index={index}
                isCheckedCard={isCheckedCard}
                updateSelectedCards={updateSelectedCards}
              />
            }
          />
          <Card.Content
            style={{ border: 'none' }}
            description={
              rangeBasedResult ? (
                <RecurringSSRangeResultDescription
                  clientTimezone={clientTimezone}
                  result={result}
                  isCheckedCard={isCheckedCard}
                  index={index}
                  updateSelectedCards={updateSelectedCards}
                  clientAddresses={clientAddresses}
                  isAdminDriveTimeLoading={isAdminDriveTimeLoading}
                  calculateEstimatedDriveTime={calculateEstimatedDriveTime}
                  estimatedDriveTime={estimatedDriveTime}
                  showEstimatedDriveTime={showEstimatedDriveTime}
                />
              ) : (
                <RecurringResultDescription
                  clientTimezone={clientTimezone}
                  result={result}
                  isCheckedCard={isCheckedCard}
                  index={index}
                  setOpeningCards={setOpeningCards}
                  updateSelectedCards={updateSelectedCards}
                  clientAddresses={clientAddresses}
                  isAdminDriveTimeLoading={isAdminDriveTimeLoading}
                  calculateEstimatedDriveTime={calculateEstimatedDriveTime}
                  estimatedDriveTime={estimatedDriveTime}
                  showEstimatedDriveTime={showEstimatedDriveTime}
                />
              )
            }
          />
        </Card>
      </div>
    </CardWrapper>
  );
};
export default React.memo(RecurringResultItemCard);
