import React, { useCallback } from 'react';
import { CalendarToolbar } from '../../NewAppointment.style';
import { Radio } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { OPENINGS_VIEW } from './OpeningsForm';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import clsx from 'clsx';

const MESSAGES = {
  calendar: 'Cal',
  list: 'List'
};

const OPERATIONS = {
  NEXT: 'NEXT',
  PREV: 'PREV'
};

const SwitchOpeningsView = ({
  setOpeningsView,
  selectedDate,
  openingsView,
  disabledFormFields
}) => {
  const { setValue } = useFormContext();

  const onNavigation = useCallback(
    operation => {
      let newValue = moment(selectedDate);
      if (operation === OPERATIONS.NEXT) newValue.add(1, 'month');
      else newValue.subtract(1, 'month');

      if (
        (newValue.isSameOrAfter(moment(), 'month') ||
          newValue.isSameOrAfter(moment(selectedDate), 'month')) &&
        !disabledFormFields.selectedSlot
      ) {
        setValue(
          'selectedDate',
          newValue
            .startOf('month')
            .startOf('day')
            .toISOString()
        );
        setValue('selectedSlot', null);
      }
    },
    [selectedDate, setValue, disabledFormFields.selectedSlot]
  );

  const handleViewChange = useCallback(view => {
    if (view === 'list') setOpeningsView(OPENINGS_VIEW.list);
    else setOpeningsView(OPENINGS_VIEW.calendar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CalendarToolbar>
      <h2 className="appointment-body__title">
        Openings <span>(select one)</span>
      </h2>

      <div className="calendar-nav">
        <Radio.Group>
          <Radio.Button
            value="prev"
            onClick={() => onNavigation(OPERATIONS.PREV)}
          >
            <CaretLeftOutlined />
          </Radio.Button>
          <span className="calendar-nav__title">
            {moment(selectedDate).format('MMM YYYY')}
          </span>
          <Radio.Button
            value="next"
            onClick={() => onNavigation(OPERATIONS.NEXT)}
          >
            <CaretRightOutlined />
          </Radio.Button>
        </Radio.Group>
      </div>

      <div className="calendar-switch">
        {Object.keys(OPENINGS_VIEW).map(name => (
          <Radio.Button
            key={name}
            className={clsx({ active: openingsView === OPENINGS_VIEW[name] })}
            onClick={() => handleViewChange(name)}
          >
            {MESSAGES[name]}
          </Radio.Button>
        ))}
      </div>
    </CalendarToolbar>
  );
};

export default React.memo(SwitchOpeningsView);
