import React, { useCallback, useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import SignIn from './SignIn';
import ApolloWrapper, { Props as ApolloWrapperProps } from './ApolloWrapper';
import LoggedInUserInfo from 'authentication-apollo/types/loggedInUserInfo';
import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
  IPublicClientApplication
} from '@azure/msal-browser';
import { loginRequest } from 'authentication-apollo/authConfig';

interface Props {
  children: ApolloWrapperProps['children'];
}

function getActiveAccount(instance: IPublicClientApplication) {
  return instance.getActiveAccount();
}

export default function AuthenticationHelper({ children }: Props) {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance, accounts } = useMsal();

  const [activeAccount, setActiveAccount] = useState<AccountInfo | null>(
    getActiveAccount(instance)
  );

  useEffect(() => {
    setActiveAccount(getActiveAccount(instance));
  }, [instance, accounts]);

  const acquireTokenSilent = useCallback(async () => {
    try {
      console.log('Acquire Token Silent');
      return await instance.acquireTokenSilent({
        ...loginRequest
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [instance]);

  const acquireTokenRedirect = useCallback(async () => {
    try {
      console.log('Acquire Token Redirect');
      await instance.acquireTokenRedirect({
        ...loginRequest
      });
    } catch (error) {
      console.error(error);
    }
  }, [instance]);

  const acquireTokenCallback = useCallback(async () => {
    const activeAccount = getActiveAccount(instance);

    if (
      activeAccount &&
      [InteractionStatus.Startup, InteractionStatus.None].includes(inProgress)
    ) {
      try {
        const result = await acquireTokenSilent();
        return result?.accessToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          instance.setActiveAccount(null);
          await acquireTokenRedirect();
        } else {
          console.error(err);
        }
      }
    }

    console.log('Active account: ', activeAccount);
    console.log('All acconts: ', accounts);
    console.log('Interaction status: ', inProgress);

    console.log('Acquire Token Silent Returned undefined');

    return undefined;
  }, [
    accounts,
    acquireTokenRedirect,
    acquireTokenSilent,
    inProgress,
    instance
  ]);

  const loginCallback = useCallback(async () => {
    try {
      console.log('Login');
      await instance.loginPopup();
    } catch (error) {
      console.error(error);
    }
  }, [instance]);

  const logoutCallback = useCallback(async () => {
    const activeAccount = getActiveAccount(instance);

    if (activeAccount) {
      try {
        console.log('Logout');
        instance.setActiveAccount(null);
        await instance.logoutRedirect({
          account: activeAccount
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [instance]);

  if (!isAuthenticated) {
    return <SignIn handleLogin={loginCallback} />;
  }

  if (!activeAccount) {
    if (inProgress === InteractionStatus.None) acquireTokenRedirect();

    return <div>Loading...</div>;
  }

  const loggedInUser = {
    homeAccountId: activeAccount.homeAccountId,
    localAccountId: activeAccount.localAccountId,
    username: activeAccount.username,
    name: activeAccount.name
  } as LoggedInUserInfo;

  return (
    <React.Fragment>
      <ApolloWrapper
        children={children}
        handleLogout={logoutCallback}
        acquireToken={acquireTokenCallback}
        activeAccount={loggedInUser}
      />
    </React.Fragment>
  );
}
