import styled from 'styled-components';

export const FormItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  label {
    font-size: 13px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-bottom: 5px;
  }
  h2 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    color: #21303a;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  .top-wrap {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    color: #415766;
  }
  .app-labelv2 {
    width: fit-content;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #415766;
    line-height: 16px;
  }
  .optional {
    font-size: 12px;
    letter-spacing: -0.4px;
    text-align: right;
    color: #888a8c;
    float: right;
  }
`;
