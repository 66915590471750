import React, { useCallback } from 'react';
import { FormItem } from 'views/containers/form';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { NO_PREFERENCE_OPTION } from 'utils/constants/default';

export default function AppointmentProvider({
  client,
  disabledFormFields,
  staffList,
  filteredStaffList,
  isProviderDisabled
}) {
  const { control, setValue } = useFormContext();

  // only chang clinic if provider
  const onProviderChange = useCallback(
    providerId => {
      // redundant condition but better safe than sorr
      if (Array.isArray(staffList)) {
        if (providerId !== NO_PREFERENCE_OPTION.id) {
          const provider = staffList.find(p => p.id === providerId);
          setValue('clinicId', provider.clinicId);
        } else {
          // setting to client 's clinic
          setValue('clinicId', client?.clinicId);
        }
      }
      setValue('selectedSlot', undefined);
      setValue('dirtyEdit', true);
    },
    [staffList, setValue, client]
  );

  return (
    <FormItem label="Provider" xs={16} sm={12}>
      <Controller
        name="provider"
        control={control}
        render={fieldProps => (
          <Select
            placeholder="Select Provider"
            options={filteredStaffList}
            disabled={isProviderDisabled}
            onSelect={onProviderChange}
            field={fieldProps}
          />
        )}
      />
    </FormItem>
  );
}
