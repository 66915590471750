import { IProvider } from 'model/v2';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Grid } from 'semantic-ui-react';
import IconWithTitle from 'components/icons/iconWithTitle';
import PhoneIcon from 'assets/img/phone-icon.svg';
import EmailIcon from 'assets/img/email-icon.svg';
import TelehealthIcon from 'assets/img/icon-telehealth.png';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
interface IProps {
  provider?: IProvider;
}

const PersonalInformation = ({ provider }: IProps) => {
  const history = useHistory();

  const editStaff = useCallback(() => {
    history.push({
      pathname: `/providers/${provider?.id}/edit`,
      state: {
        title: 'Edit Staff Info',
        provider: provider
      }
    });
  }, [history, provider]);

  return (
    <Grid.Column className="personal-information" style={{ width: '20%' }}>
      <Grid.Row className="name">
        <IconWithTitle
          title={`${provider?.firstName} ${provider?.lastName}`}
          icon={'pencil alternate'}
          onClick={editStaff}
        />
      </Grid.Row>
      <Grid.Row>
        <img
          alt="phone"
          src={PhoneIcon}
          style={{ paddingLeft: 3, paddingRight: 3 }}
        />
        <label className="phone">{provider?.mobilePhone}</label>
        {provider?.mobilePhone ? provider?.mobilePhone : 'N/A'}
      </Grid.Row>
      <Grid.Row className="url">
        <img
          alt="email"
          src={EmailIcon}
          style={{ paddingLeft: 3, paddingRight: 3 }}
        />
        <label className="url">{provider?.email} </label>
      </Grid.Row>
      {provider?.telehealthLinks?.length > 0 &&
        provider?.telehealthLinks.map((link: string) => {
          {
            console.log('737 max links', provider.telehealthLinks);
          }
          return (
            <Grid.Row className="url">
              <img
                className="tele-link-icon"
                alt="link"
                src={TelehealthIcon}
                style={{ paddingLeft: 3, paddingRight: 3 }}
              />
              <label className="url">{link} </label>
            </Grid.Row>
          );
        })}
      <Grid.Row className="appointment-types">
        <div className="section-body">
          <span className="prov-speciality appt-types">
            {provider?.speciality?.title}
          </span>
          <span className="prov-employment appt-types">
            {provider?.employment}
          </span>
          <span className="prov-status appt-types">{provider?.status}</span>
          {getFeatureAvailability(FEATURES.SHADOW_SCHEDULING) &&
            provider?.isObservable && (
              <span className="prov-observable appt-types">Observable</span>
            )}
        </div>
      </Grid.Row>
    </Grid.Column>
  );
};
export default React.memo(PersonalInformation);
