import React, { useCallback, useState } from 'react';
import { ContentWrapper } from './style';
import ModalSemantic from 'components/modalSemantic';
import PopupHeader from '../popupHeader';
import Reschedule from '../reschedule';
import { PlannerView } from 'utils/cache/calendar';
import { Toast } from 'react-toastify';
import { ISelectedEvent } from 'utils/validators/dragEventValidator';
export interface IReschedulePopupProps {
  selectedEventProps: ISelectedEvent;
  view: PlannerView;
  onClose: () => void;
  onCancel: () => void;
  toast: Toast;
}

const ShowPopup: React.FC<IReschedulePopupProps> = (
  props: IReschedulePopupProps
) => {
  const { onCancel } = props;
  const [conflicting, setConflicting] = useState(false);
  const [header, setHeader] = useState(true);

  const setConflictingState = useCallback(
    isConflict => {
      setConflicting(isConflict);
    },
    [setConflicting]
  );

  return (
    <>
      <ContentWrapper>
        <ModalSemantic
          open={true}
          onClose={onCancel}
          trigger={<div></div>}
          content={
            <Reschedule
              rescheduleProps={props}
              setConflicting={setConflictingState}
              setHeader={setHeader}
            />
          }
          header={
            header ? <PopupHeader isConflicting={conflicting} /> : undefined
          }
        />
      </ContentWrapper>
    </>
  );
};
export default React.memo(ShowPopup);
