import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { TitleWrapper, AuthsWrapper } from './AuthTherapiesPage.style';
import {
  AUTH_THERAPIES_COLUMNS,
  COMPLETED_COLUNMS
} from 'utils/constants/tables/authTherapies';
import { Table, Tooltip, Popover } from 'antd';
import { Button } from 'lib/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import Content from 'views/components/ui/content';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'views/components/ui/content/Loading';
import useAuths from './useAuths';

const truncatedCells = (nameClient, count) => {
  const arrayName = nameClient?.split(', ') || [];
  const transformTruncatedName = arrayName.map(name => name.slice(0, count));
  const truncatedName = transformTruncatedName.join(', ');

  return truncatedName;
};

const renderTooltip = (clientName, clientID, onClick) => {
  return (
    <Tooltip
      overlayClassName="tooltipStyle"
      placement="bottom"
      title={clientName}
    >
      <Button className="go-to-profile" onClick={() => onClick(clientID)}>
        {truncatedCells(clientName, 5)}
      </Button>
    </Tooltip>
  );
};

const renderPopover = (completedColumns, dataSourceCompleted) => {
  const content = () => {
    return (
      <Table
        columns={completedColumns}
        dataSource={dataSourceCompleted}
        rowKey={record => record.apptType}
        className="contentWrapper"
        pagination={false}
        bordered={false}
      />
    );
  };

  if (dataSourceCompleted.length === 1) {
    return <p>{`${dataSourceCompleted[0].apptType}`}</p>;
  } else {
    return (
      <>
        <p>{'Grouped Limit'}</p>
        <Popover overlayClassName="viewCompleted" content={content()}>
          <FontAwesomeIcon icon={faExpand} />
        </Popover>
      </>
    );
  }
};

const AuthsTherapiesPage = () => {
  const history = useHistory();
  const [sort, setSort] = useState([{ timeRemaining: 'asc' }]);
  const filter = [
    {
      timeRemaining: {
        range: { gt: 0.0 }
      }
    }
  ];
  const { auths, count, loading, loadMore, refetch } = useAuths({
    sort,
    filter
  });

  const handleTableChange = (
    pagination,
    filters,
    sorter,
    currentDataSource
  ) => {
    const orderMap = { descend: 'desc', ascend: 'asc' };
    const order = orderMap[sorter.order];
    const columnKey =
      sorter.columnKey === 'authsRemaining' ? 'remaining' : sorter.columnKey;
    !columnKey || !order
      ? setSort([{ timeRemaining: 'asc' }])
      : setSort([{ [columnKey]: order }]);
    refetch();
  };

  // each time we define a function withour callback it triggers a rerender
  // only define function if it needs to change on each rerender
  const goToClientProfile = useCallback(
    id => {
      history.push({ pathname: `/clients/${id}` });
    },
    [history]
  );

  const customizeAuthsView = () =>
    auths?.map(item => ({
      ...item,
      clinicAbr: item.clinic,
      name: renderTooltip(item.clientName, item.clientID, goToClientProfile),
      clientName: renderTooltip(
        item.clientName,
        item.clientID,
        goToClientProfile
      ),
      apptType: renderPopover(COMPLETED_COLUNMS, item.apptType)
    }));
  return (
    <AuthsWrapper>
      <TitleWrapper>
        <div className="inner-wrap">
          <h1>Authorized Therapies</h1>
        </div>
      </TitleWrapper>

      <Content loading={loading && !auths?.length} data={auths}>
        {() => (
          <InfiniteScroll
            dataLength={auths?.length}
            next={loadMore}
            hasMore={!loading && auths?.length < count}
            endMessage={loading && <Loader scroll />}
          >
            <Table
              onChange={handleTableChange}
              columns={AUTH_THERAPIES_COLUMNS}
              dataSource={customizeAuthsView()}
              rowKey={record => record.id}
              pagination={false}
              bordered
            />
          </InfiniteScroll>
        )}
      </Content>
    </AuthsWrapper>
  );
};

export default AuthsTherapiesPage;
