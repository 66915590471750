import styled from 'styled-components';

export const CalendarWrap = styled.div`
  .rbc-events-container {
    .rbc-event {
      width: 100% !important;
      left: 0 !important;
      min-height: 5px !important;
      &.interseption {
        .rbc-event-label {
          display: none;
        }
      }
      .rbc-event-content {
        background-color: transparent;
      }
      .rbc-event-label {
        background-color: transparent;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
        max-height: 16px;
        margin-top: 2px;
      }
    }
  }
  .rbc-current-time-indicator {
    display: none;
  }
  .error {
    pointer-events: none;
    z-index: 1;
  }
  .inClinic {
    ${({ availabilityType }) =>
      !availabilityType ? 'pointer-events: auto' : 'pointer-events: none'}
  }
  .inClinic,
  .inClinic.rbc-selected {
    background-color: #8be07d;
  }
  .interseption,
  .rbc-selected {
    &.error {
      background-image: linear-gradient(
        120deg,
        #8be07d 25%,
        #007aff 25%,
        #007aff 50%,
        #8be07d 50%,
        #8be07d 75%,
        #007aff 75%,
        #007aff 100%
      );
      background-size: 46.19px 80px;
    }
  }
  .offSite {
    ${({ availabilityType }) =>
      !availabilityType ? 'pointer-events: none' : 'pointer-events: auto'}
  }
  .offSite,
  .offSite.rbc-selected {
    background-color: #007aff;
  }
  .event-close {
    position: absolute;
    top: 6px;
    right: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.5s;
    z-index: 2;
    &:hover {
      /* transform: rotate(180deg); */
    }
  }
`;
export const BlockedWrap = styled.div`
  padding-top: 60px;
  .calendar-addEvent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  .calendar-extraEvent {
    display: flex;
    align-items: flex-start;
    &__close {
      padding-top: 30px;
      padding-right: 20px;
      font-weight: bold;
      color: #cc3f3f;
      cursor: pointer;
    }
    &__item {
      width: 100%;
      max-width: 300px;
    }
    > span {
      padding: 25px 50px 0;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .rbc-event {
    min-height: 5px !important;
  }
`;

export const EditAvailabilityWrap = styled.div`
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  .button-wrap {
    margin-right: 40px !important;
  }

  .rbc-event-label {
    margin-top: 12px !important;
    font-family: 'Lato' !important;
    font-size: 10.6px !important;
    font-weight: 700;
    line-height: 12px;
    color: rgba(65, 87, 102, 1);
    margin-bottom: 8px;
  }
  .Audit-content {
    height: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .location-container {
    font-family: 'Lato' !important;
    font-size: 10.6px !important;
    font-weight: 700;
    line-height: 12px;
    color: rgba(65, 87, 102, 1);
    margin-left: 3px;
    margin-bottom: 10px;
  }

  .calendar-type {
    button {
      margin: 0 15px;
    }
    span {
      font-size: 15px;
    }
    &__name {
      position: relative;
      top: 3px;
      font-size: 18px;
      font-weight: bold;
      color: #21303a;
    }
  }
  .rbc-event {
    min-height: 5px !important;
  }
  .sideBarTitle {
    margin-bottom: 8px !important;
  }
  .audit-inner-label > div > p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 14.4px;
    margin-bottom: 4px !important;
    color: rgba(65, 87, 102, 1);
  }
  .audit-inner-label > p:first-child {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 14.4px;
    color: rgba(65, 87, 102, 1);
    margin-bottom: 18px !important;
  }
  .audit-show-label {
    margin-top: 10px;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 14.4px;
    color: #6f42f5;
    display: flex;
  }

  .expand-icon {
    width: 10px;
    height: 6px;
    cursor: pointer;
    margin-left: 8px;
    margin-top: 8px;
  }
  .calendar-actions {
    width: 200px;
  }
  .Client-edit-avail .rbc-time-view {
    width: 1000px;
  }
  .popup-wrap {
    text-align: center;
  }
  .inner-wrap {
    display: inline-flex;
  }
  .right-sidebar {
    display: inline-flex;
    heigth: fit-content;
    position: relative;
    right: 80px;
  }
`;
