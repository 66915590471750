import React, { Dispatch, SetStateAction } from 'react';
import { FormItem } from 'views/containers/form';
import {
  PlainTimepicker,
  TimerTextField
} from 'api/sharedComponents/reactHookFormComponents';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  calculateEndDate,
  durationToMinutesDuration,
  minutesDurationToDuration
} from 'utils/format/time';
import moment from 'moment';
import { ObserverTimeWrapper } from './style';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { IEvent } from 'model/v2';
import { ABA_ALERT_MESSAGES } from 'utils/constants/default';

interface Props {
  setSeriesEventObservations: Dispatch<SetStateAction<IEvent[]>>;
  seriesEventObservations: IEvent[];
  observerIndex: number;
  eventIndex: number;
  leadEvent: IEvent;
  observations: IEvent;
  setIsAlertShown: (val: boolean) => void;
  setIsValidated: (val: boolean) => void;
  setAlertContent: (val: string) => void;
  newStartDate: string;
  newEndDate: string;
  setAbaObserversList: Dispatch<SetStateAction<IEvent[]>>;
}

const ImpactedObserverTimeDuration: React.FC<Props> = ({
  setSeriesEventObservations,
  observerIndex,
  eventIndex,
  leadEvent,
  setIsAlertShown,
  setIsValidated,
  setAlertContent,
  observations,
  newStartDate,
  newEndDate,
  seriesEventObservations,
  setAbaObserversList
}) => {
  const methods = useFormContext();

  let { duration, startDate, endDate } = useWatch({
    control: methods.control,
    name: ['duration', 'startDate', 'endDate', 'smart', 'eventTypeName']
  });
  if (observerIndex > 0) {
    duration = observations.duration;
    startDate = moment(observations.startDate);
    endDate = moment(observations.endDate);
  }
  const startDateHandler = React.useCallback(
    event => {
      const time = event.target.value;

      const momentValue = moment(time, 'HH:mm');

      const endateVal = momentValue.clone().add(duration, 'minutes');

      methods.setValue('dirtyEdit', true);
      methods.setValue('startDate', momentValue);
      methods.setValue('endDate', endateVal);
      const eventStartTime = moment(
        moment(newStartDate).format('HH:mm'),
        'h:mma'
      );
      const eventEndTime = moment(moment(newEndDate).format('HH:mm'), 'h:mma');

      const observerStartTime = moment(time, 'h:mma');

      const observerEndTime = moment(endateVal, 'h:mma');

      setIsAlertShown(false);
      setIsValidated(false);
      setAlertContent('');
      if (
        observerStartTime.isBefore(eventStartTime) ||
        observerStartTime.isAfter(eventEndTime) ||
        observerEndTime.isBefore(eventStartTime) ||
        observerEndTime.isAfter(eventEndTime)
      ) {
        setIsAlertShown(true);
        setIsValidated(true);
        setAlertContent(ABA_ALERT_MESSAGES.timeAlert);
      } else {
        const isValidatedStatus = checkObserverTimeValidation(
          seriesEventObservations,
          eventStartTime,
          eventEndTime,
          eventIndex,
          observerIndex
        );
        setIsValidated(isValidatedStatus);
      }
      const momentStartDate = moment(
        moment(leadEvent.startDate).format('YYYY-MM-DD') +
          ' ' +
          moment(methods.getValues('startDate')).format('HH:mm')
      );
      const momentEndDate = moment(
        moment(leadEvent.endDate).format('YYYY-MM-DD') +
          ' ' +
          moment(methods.getValues('endDate')).format('HH:mm')
      );
      if (observerIndex > 0) {
        let observerEventId: number;
        setSeriesEventObservations(current =>
          current.map((obj, parentIndex) => {
            if (parentIndex === eventIndex) {
              obj.observations = obj.observations?.map(
                (observer, childIndex) => {
                  if (childIndex === observerIndex - 1) {
                    observerEventId = observer.id!;
                    setAbaObserversList(current =>
                      current.map(obj => {
                        if (obj.id === observerEventId) {
                          return {
                            ...obj,
                            endDate: momentEndDate,
                            startDate: momentStartDate
                          };
                        }
                        return obj;
                      })
                    );
                    return {
                      ...observer,
                      endDate: momentEndDate,
                      startDate: momentStartDate
                    };
                  }
                  return observer;
                }
              );
            }
            return obj;
          })
        );
      }
      methods.trigger(['startDate', 'endDate']);
    },
    [methods, duration]
  );

  const endDateHandler = React.useCallback(
    event => {
      const time = moment(event.target.value, 'HH:mm');
      const endDate = startDate
        .clone()
        .set('hours', time.hours())
        .set('minutes', time.minutes());
      const duration = moment.duration(endDate.diff(startDate)).asMinutes();
      const eventStartTime = moment(
        moment(newStartDate).format('HH:mm'),
        'h:mma'
      );
      const eventEndTime = moment(moment(newEndDate).format('HH:mm'), 'h:mma');
      const observerEndTime = moment(time, 'h:mma');
      setIsAlertShown(false);
      setIsValidated(false);
      setAlertContent('');
      if (
        observerEndTime.isBefore(eventStartTime) ||
        observerEndTime.isAfter(eventEndTime) ||
        duration < 0
      ) {
        setIsAlertShown(true);
        setIsValidated(true);
        setAlertContent(ABA_ALERT_MESSAGES.timeAlert);
      } else if (duration < 15) {
        setIsAlertShown(true);
        setIsValidated(true);
        setAlertContent(ABA_ALERT_MESSAGES.durationAlert);
      } else {
        const isValidatedStatus = checkObserverTimeValidation(
          seriesEventObservations,
          eventStartTime,
          eventEndTime,
          eventIndex,
          observerIndex
        );
        setIsValidated(isValidatedStatus);
      }
      methods.setValue('dirtyEdit', true);
      methods.setValue('endDate', endDate);
      methods.setValue('duration', duration);
      const momentEndDate = moment(
        moment(leadEvent.endDate).format('YYYY-MM-DD') +
          ' ' +
          moment(methods.getValues('endDate')).format('HH:mm')
      );
      if (observerIndex > 0) {
        let observerEventId: number;
        setSeriesEventObservations(current =>
          current.map((obj, parentIndex) => {
            if (parentIndex === eventIndex) {
              obj.observations = obj.observations?.map(
                (observer, childIndex) => {
                  if (childIndex === observerIndex - 1) {
                    observerEventId = observer.id!;
                    setAbaObserversList(current =>
                      current.map(obj => {
                        if (obj.id === observerEventId) {
                          return {
                            ...obj,
                            duration: duration,
                            endDate: momentEndDate
                          };
                        }
                        return obj;
                      })
                    );
                    return {
                      ...observer,
                      duration: duration,
                      endDate: momentEndDate
                    };
                  }
                  return observer;
                }
              );
            }
            return obj;
          })
        );
      }
      methods.trigger(['endDate', 'duration']);
    },
    [methods, startDate]
  );

  const durationHandler = React.useCallback(
    event => {
      methods.setValue('dirtyEdit', true);
      methods.setValue('selectedOpening', null);
      const minutes = durationToMinutesDuration(event);
      methods.setValue('duration', minutes);
      setIsAlertShown(false);
      setIsValidated(false);
      setAlertContent('');
      const eventStartTime = moment(
        moment(newStartDate).format('HH:mm'),
        'h:mma'
      );
      const eventEndTime = moment(moment(newEndDate).format('HH:mm'), 'h:mma');
      const observerEndTime = moment(
        calculateEndDate(startDate, minutes).format('HH:mm'),
        'h:mma'
      );
      if (minutes < 15) {
        setIsAlertShown(true);
        setIsValidated(true);
        setAlertContent(ABA_ALERT_MESSAGES.durationAlert);
      } else if (
        observerEndTime.isBefore(eventStartTime) ||
        observerEndTime.isAfter(eventEndTime) ||
        minutes < 0
      ) {
        setIsAlertShown(true);
        setIsValidated(true);
        setAlertContent(ABA_ALERT_MESSAGES.timeAlert);
      } else {
        const isValidatedStatus = checkObserverTimeValidation(
          seriesEventObservations,
          eventStartTime,
          eventEndTime,
          eventIndex,
          observerIndex
        );
        setIsValidated(isValidatedStatus);
      }
      methods.trigger('duration');
      if (!minutes || !startDate) return;
      methods.setValue('endDate', calculateEndDate(startDate, minutes));
      const momentEndDate = moment(
        moment(leadEvent.endDate).format('YYYY-MM-DD') +
          ' ' +
          moment(methods.getValues('endDate')).format('HH:mm')
      );
      if (observerIndex > 0) {
        let observerEventId: number;
        setSeriesEventObservations(current =>
          current.map((obj, parentIndex) => {
            if (parentIndex === eventIndex) {
              obj.observations = obj.observations?.map(
                (observer, childIndex) => {
                  if (childIndex === observerIndex - 1) {
                    observerEventId = observer.id!;
                    setAbaObserversList(current =>
                      current.map(obj => {
                        if (obj.id === observerEventId) {
                          return {
                            ...obj,
                            duration: minutes,
                            endDate: momentEndDate
                          };
                        }
                        return obj;
                      })
                    );
                    return {
                      ...observer,
                      duration: minutes,
                      endDate: momentEndDate
                    };
                  }
                  return observer;
                }
              );
            }
            return obj;
          })
        );
      }
      methods.trigger('endDate');
    },
    [methods, startDate]
  );

  return (
    <ObserverTimeWrapper>
      <div className="date-first-row">
        <FormItem optional={false} label="Start Time">
          <Controller
            name="startDate"
            control={methods.control}
            render={fieldProps => (
              <TimerTextField
                className="time-style"
                field={fieldProps}
                errors={methods.errors}
                value={startDate?.format('HH:mm')}
                onChange={startDateHandler}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 900
                }}
              />
            )}
          />
        </FormItem>
        <FormItem optional={false} label="End Time">
          <Controller
            name="endDate"
            control={methods.control}
            render={fieldProps => (
              <TimerTextField
                className="time-style"
                field={fieldProps}
                errors={methods.errors}
                value={endDate?.format('HH:mm')}
                disabled={false}
                onChange={endDateHandler}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 900
                }}
              />
            )}
          />
        </FormItem>
        <FormItem optional={false} label="Duration">
          <Controller
            name="duration"
            control={methods.control}
            render={fieldProps => (
              <PlainTimepicker
                className="date-style"
                name="duration"
                format="HH:mm"
                inputReadOnly={
                  !getFeatureAvailability(FEATURES.FIVE_MINUTE_SCHEDULING)
                }
                pattern="[0-9]{2}:[0-9]{2}"
                minuteStep={15}
                disabled={false}
                value={minutesDurationToDuration(duration)}
                onChange={durationHandler}
                errors={methods.errors}
                field={fieldProps}
              />
            )}
          />
        </FormItem>
      </div>
    </ObserverTimeWrapper>
  );
};
export default React.memo(ImpactedObserverTimeDuration);

export const checkObserverTimeValidation = (
  seriesEventObservations: any[],
  eventStartTime: moment.MomentInput,
  eventEndTime: moment.MomentInput,
  eventIndex: number,
  observerIndex: number
) => {
  let timeStatus = false;
  seriesEventObservations.map((observerEvent, parentIndex) => {
    observerEvent.observations?.map(
      (
        observer: {
          startDate: moment.MomentInput | undefined;
          endDate: moment.MomentInput | undefined;
        },
        childIndex: number
      ) => {
        const observerstartTime = moment(
          moment(observer.startDate).format('HH:mm'),
          'h:mma'
        );
        const observerendTime = moment(
          moment(observer.endDate).format('HH:mm'),
          'h:mma'
        );
        if (
          (observerstartTime.isBefore(eventStartTime) ||
            observerstartTime.isAfter(eventEndTime) ||
            observerendTime.isBefore(eventStartTime) ||
            observerendTime.isAfter(eventEndTime)) &&
          ((eventIndex === parentIndex && observerIndex - 1 !== childIndex) ||
            (eventIndex !== parentIndex &&
              (observerIndex - 1 !== childIndex ||
                observerIndex - 1 === childIndex)))
        ) {
          timeStatus = true;
        }
      }
    );
  });
  return timeStatus;
};
