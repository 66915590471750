export const CLINIC_STAFF_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Display Name',
    dataIndex: 'displayName'
  },
  {
    title: 'Specialty',
    dataIndex: 'speciality'
  }
];
export const TRANSPORTATION_SELECT = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];

export const PROVIDER_ROSTER_STATUS_SELECT = [
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'Inactive',
    label: 'Inactive'
  },
  {
    value: 'Accommodation',
    label: 'Accommodation'
  }
];
