import styled from 'styled-components';
import { RED } from 'utils/constants/theme';

export const EditCarePlanWrapper = styled.div`
  .inner-wrap {
    max-width: 100%;
    padding-left: 50px;
    padding-right: 45px;
  }
  .button-wrap {
    button {
      width: 308px;
    }
  }
`;

export const CarePlanContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  h2 {
    margin-bottom: 23px;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
  }
  .left-column {
    width: 45%;
    height: 100%;
    border-right: 1px solid #c9cfd3;
    padding: 16px 30px;
  }
  .right-column {
    width: 45%;
    padding: 16px 30px;
  }
`;

export const AddTherapyForm = styled.div`
  position: relative;
  width: 100%;
  height: 187px;
  border-radius: 4px;
  border: solid 1px #c9cfd3;
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 24px;
  .ant-col {
    display: inline-block;
    width: 50%;
  }
  .per-week {
    margin-left: 11px;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #21303a;
    margin-bottom: 6px;
  }
  .vertical-line {
    position: absolute;
    bottom: 24px;
    right: 119px;
    width: 1px;
    height: 139px;
    background: #979797;
  }
  .delete {
    position: absolute;
    top: 45%;
    right: 4%;
    margin-bottom: 10px;
    border: none;
    text-transform: uppercase;
    color: ${RED};
    font-family: Lato;
    font-size: 15px;
    font-weight: normal;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }
  .error {
    position: absolute;
    bottom: 0;
    width: 160px;
    height: 30px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: bold;
    color: #cc3f3f;
  }
  .error-input {
    border-color: #cc3f3f;
  }
`;
