import styled from 'styled-components';
import { Carousel } from 'antd';

export const LibCarousel = styled(Carousel)`
  width: ${props => (props.isSidebar ? '350px' : '100%')};
  margin-left: ${props => (props.isSidebar ? '30px' : '0px')};
  margin-bottom: 40px;
  .slick-arrow {
    border-radius: 50%;
    background: #eae8ff;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    &:hover {
      background: #eae8ff;
    }
    svg {
      width: 15px;
      height: 15px;
      fill: #6f4bf1;
    }
  }
  .slick-next {
  }
  .slick-dots {
    bottom: ${props => (props.isSidebar ? '-10px' : '-20px')};
    height: 8px;
    li {
      width: 8px;
      height: 8px;
      &.slick-active {
        button {
          width: 8px;
          background-color: #eae8ff;
        }
      }
      button {
        width: 8px;
        height: 8px;
        background-color: #d8d8d8;
        border-radius: 50%;
      }
    }
  }
`;

export const CarouselWrap = styled.div``;
