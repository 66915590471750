import React from 'react';
import { getTelehealthLink } from 'utils/common';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { isEmpty } from 'lodash';
import { formatAddress } from 'utils/format';
import { LocationViewWrapper } from './style';
import { mapRoomIdToFullData } from 'utils/mappers/form';
import { IEvent } from 'model/v2';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  event: IEvent;
  isObservation?: boolean;
  isLoading?: boolean;
}

const LocationView: React.FC<Props> = ({
  event,
  isObservation,
  isLoading
}: Props) => {
  let location =
    event?.address?.addressLine1 && !isEmpty(event?.address?.addressLine1)
      ? formatAddress(event?.address)
      : '';
  if (event.leadEvent) {
    location =
      event.leadEvent?.address?.addressLine1 &&
      !isEmpty(event.leadEvent?.address?.addressLine1)
        ? formatAddress(event.leadEvent?.address)
        : '';
  }
  const offSiteLocation = location
    ? `${event?.locationCategory} - ${location}`
    : `${event?.locationCategory}`;
  const clinicName = event?.clinic?.name;
  const clinicNameLeadEvent = event?.leadEvent?.clinic?.name;

  // Conditionally render location only if event.type !== 'adminEvent'
  const locationSection =
    event.type !== 'adminEvent' ? (
      <>
        {event?.locationType === LOCATION_TYPE_LIST[0].id && (
          <>
            {event.leadEvent ? (
              <>
                {' '}
                {!isLoading ? (
                  <li key="clinicKey">{`${clinicNameLeadEvent ||
                    ''} Clinic`}</li>
                ) : (
                  <Skeleton
                    children={
                      <li key="clinicKey">{`${clinicNameLeadEvent ||
                        ''} Clinic`}</li>
                    }
                  />
                )}
              </>
            ) : (
              <>
                {' '}
                {!isLoading ? (
                  <li key="clinicKey">{`${clinicName || ''} Clinic`}</li>
                ) : (
                  <Skeleton
                    variant="text"
                    children={
                      <li key="clinicKey">{`${clinicName ||
                        ''} ClinicnonLead`}</li>
                    }
                  />
                )}
              </>
            )}
            {event?.room?.id && (
              <>
                {!isLoading ? (
                  <li key="roomKey">{mapRoomIdToFullData(event?.room)}</li>
                ) : (
                  <Skeleton variant="text">
                    <li key="roomKey">{mapRoomIdToFullData(event?.room)}</li>
                  </Skeleton>
                )}
              </>
            )}
          </>
        )}
        {event?.locationType !== LOCATION_TYPE_LIST[0].id &&
          event?.locationType !== LOCATION_TYPE_LIST[2].id &&
          (!isLoading ? (
            <li key="roomKey">{offSiteLocation}</li>
          ) : (
            <Skeleton variant="text">
              <li key="roomKey">{offSiteLocation}</li>
            </Skeleton>
          ))}
        {event?.locationType === LOCATION_TYPE_LIST[2].id && (
          <>
            {!isLoading ? (
              <li key="roomKey" className="telehealth-title">
                Telehealth:{' '}
                {!event?.telehealthLink || event?.telehealthLink === 'N/A' ? (
                  // TODO: remove when 'N/A' is not used in the back-end
                  <span>
                    {event?.telehealthLink ? event?.telehealthLink : 'N/A'}
                  </span>
                ) : (
                  <a
                    className="telehealth-link"
                    href={getTelehealthLink(event?.telehealthLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${event?.telehealthLink}`}
                  </a>
                )}
              </li>
            ) : (
              <Skeleton variant="text">
                <li key="roomKey" className="telehealth-title">
                  Telehealth: <span>Loading...</span>
                </li>
              </Skeleton>
            )}
          </>
        )}
      </>
    ) : (
      <span></span>
    );

  // Conditionally render location title only if event.type !== 'adminEvent'
  const locationTitle =
    event.type !== 'adminEvent' ? (
      <h2 className="location-title">Location</h2>
    ) : (
      <span></span>
    );

  return (
    <LocationViewWrapper isObservation={isObservation}>
      {locationTitle}
      <ul>
        {locationSection}
        {!isObservation && (
          <>
            <li className="notes-li">
              <h2 className="notes-title">Notes</h2>
              {!isLoading ? (
                <p className="notes-content">{event?.note?.note}</p>
              ) : (
                <Skeleton variant="text">
                  <p className="notes-content">{'event?.note?.note'}</p>
                </Skeleton>
              )}
            </li>
          </>
        )}
      </ul>
    </LocationViewWrapper>
  );
};

export default React.memo(LocationView);
