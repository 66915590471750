import { INote } from 'model/v2';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormItem, TextArea } from 'views/containers/form';
import { NotesFormWrapper } from './style';

const NotesForm: React.FC = () => {
  const methods = useFormContext();
  const Note: INote | undefined = useWatch({
    name: 'note',
    control: methods.control
  });

  return (
    <NotesFormWrapper>
      <FormItem optional={false} label="Notes">
        <Controller
          name="note.id"
          control={methods.control}
          render={() => <></>}
        />
        <Controller
          name="note.note"
          control={methods.control}
          render={() => (
            <TextArea
              className="notes-style"
              name="note.note"
              placeholder="Add notes"
              value={Note?.note}
              onChange={(e: any) =>
                methods.setValue('note.note', e.target.value)
              }
              errors={methods.errors}
            />
          )}
        />
      </FormItem>
    </NotesFormWrapper>
  );
};
export default React.memo(NotesForm);
