import React, { useCallback } from 'react';
import { Timepicker as LibDatepicker } from 'lib/ui/timepicker';
import { FormError } from './error';
import {
  durationToMinutesDuration,
  minutesDurationToDuration
} from 'utils/format/time';

const Timepicker = ({ field, name, errors, ...restProps }) => {
  const minutesOnChange = useCallback(
    val => {
      field.onChange(durationToMinutesDuration(val));
    },
    [field]
  );

  if (!field?.name) return null;
  return (
    <>
      <LibDatepicker
        getPopupContainer={trigger => trigger.parentNode}
        {...restProps}
        {...field}
        value={minutesDurationToDuration(field.value)}
        onChange={minutesOnChange}
        ref={undefined}
      />
      {field?.name && <FormError name={field?.name} errors={errors} />}
    </>
  );
};

export default Timepicker;
export { Timepicker };
