import React from 'react';
import { Field } from 'formik';
import { Row, Empty } from 'antd';
import { FormItem, Select, Input } from 'views/containers/form';
import { AddTherapyForm } from '../EditCarePlan.style';
import { getApptType } from 'utils/common';
import {
  mapToAppointmentOption,
  mapStringArrayToOptions
} from 'utils/mappers/form';

const RecommendedTherapy = ({
  keyForm,
  recommendation,
  apptTypes,
  quantityPatterns,
  onDelete,
  setFieldValue,
  handleChange,
  errors,
  setFieldTouched,
  touched,
  unfilteredApptTypes
}) => {
  const hasErrors =
    !!errors?.recommendedTherapies?.[keyForm]?.recommendedQuantity &&
    touched?.recommendedTherapies?.[keyForm]?.recommendedQuantity;

  const apptType = getApptType(
    { title: recommendation.apptType },
    unfilteredApptTypes
  );

  const onFieldChange = (e, nameFiled) => {
    handleChange(e);
    setFieldTouched(nameFiled);
  };

  const onApptTypeSelect = apptType => {
    clearFields(['apptSubType', 'recommendedQuantity', 'requestedQuantity']);
    setFieldValue(
      `recommendedTherapies.${keyForm}.quantityPattern`,
      getApptType({ title: apptType }, apptTypes).isClinical
        ? quantityPatterns.VISITS
        : quantityPatterns.HOURS
    );
  };

  const clearFields = fields =>
    fields.forEach(field =>
      setFieldValue(`recommendedTherapies.${keyForm}.${field}`, undefined)
    );

  return (
    <AddTherapyForm>
      <Row type="flex" justify="start" align="bottom" gutter={16}>
        <FormItem label="Appointment Type" xs={16} sm={10}>
          <Field
            name={`recommendedTherapies.${keyForm}.apptType`}
            render={fieldProps => (
              <Select
                value={recommendation.apptType}
                placeholder="Appointment Type"
                options={mapToAppointmentOption(apptTypes)}
                onSelect={onApptTypeSelect}
                notFoundContent={
                  apptTypes ? (
                    <div>Loading...</div>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                {...fieldProps}
              />
            )}
          />
        </FormItem>
        {apptType?.isClinical && (
          <FormItem label="Appointment Sub-Type" xs={16} sm={10}>
            <Field
              name={`recommendedTherapies.${keyForm}.apptSubType`}
              render={fieldProps => (
                <Select
                  value={recommendation.apptSubType}
                  placeholder="Appointment Sub-Type"
                  options={mapToAppointmentOption(
                    getApptType({ title: recommendation.apptType }, apptTypes)
                      ?.subTypes || []
                  )}
                  {...fieldProps}
                />
              )}
            />
          </FormItem>
        )}
        <FormItem sx={16} sm={20}>
          <Row type="flex" justify="start" align="bottom" gutter={16}>
            <FormItem label="Quantity" xs={16} sm={6}>
              <Input
                name={`recommendedTherapies.${keyForm}.recommendedQuantity`}
                value={recommendation.recommendedQuantity}
                placeholder="Ex: 1, 1-2"
                className={hasErrors ? 'error-input' : ''}
                onChange={e =>
                  onFieldChange(
                    e,
                    `recommendedTherapies.${keyForm}.recommendedQuantity`
                  )
                }
              />
            </FormItem>
            <FormItem xs={16} sm={6}>
              <Field
                name={`recommendedTherapies.${keyForm}.quantityPattern`}
                render={fieldProps => (
                  <>
                    <Select
                      value={recommendation.quantityPattern}
                      placeholder="Visits"
                      options={mapStringArrayToOptions(
                        Object.values(quantityPatterns)
                      )}
                      disabled={true}
                      {...fieldProps}
                    />
                  </>
                )}
              />
            </FormItem>
            <FormItem xs={16} sm={6}>
              <div className="per-week">per week</div>
            </FormItem>
            <FormItem label="Client Request" xs={16} sm={6}>
              <Input
                name={`recommendedTherapies.${keyForm}.requestedQuantity`}
                value={recommendation.requestedQuantity}
                placeholder="Optional"
                onChange={handleChange}
              />
            </FormItem>
          </Row>
        </FormItem>
        <Row type="flex" justify="center" align="middle" gutter={16}>
          <div className="vertical-line" />
          <span className="delete" onClick={onDelete}>
            Delete
          </span>
        </Row>
      </Row>
    </AddTherapyForm>
  );
};

export default RecommendedTherapy;
