import { IProvider } from 'model/v2';
import React from 'react';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import AddObserversWarning from './AddObserversWarning';

interface Props {
  leadProvider: IProvider;
}
const AddObserversHeader: React.FC<Props> = ({ leadProvider }) => {
  const providersOptions = [
    {
      id: leadProvider?.id,
      value: leadProvider?.id,
      label: `${leadProvider?.firstName} ${leadProvider?.lastName} (${leadProvider?.clinic?.abbreviation})`
    }
  ];
  return (
    <>
      <AddObserversWarning />
      <h4>Add Appointment Observer(s)</h4>
      <p className="lead-paragraph">Lead Provider</p>
      <Select
        className="Lead-Select"
        options={providersOptions}
        listHeight={162}
        errors={[]}
        field={[]}
        value={providersOptions[0].value}
        disabled
      />
    </>
  );
};

export default AddObserversHeader;
