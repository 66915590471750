import gql from 'graphql-tag';

export const providerAvailability = gql`
  fragment ProviderAvailability on ProviderAvailability {
    id
    startTime
    endTime
  }
`;
export const workingHours = gql`
  fragment WorkingHours on WorkingHoursOutput {
    workingHours {
      sun {
        ...ProviderAvailability
      }
      mon {
        ...ProviderAvailability
      }
      tues {
        ...ProviderAvailability
      }
      wed {
        ...ProviderAvailability
      }
      thurs {
        ...ProviderAvailability
      }
      fri {
        ...ProviderAvailability
      }
      sat {
        ...ProviderAvailability
      }
    }
  }
  ${providerAvailability}
`;
