import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { ICalendarFilterOption } from 'model/calendar/filters';
import CalendarFilter from '../../../ui/calendarFilter';
import { mapToValuedOption } from 'utils/mappers/form';
import { providerStatusListType } from 'utils/constants/lists';

interface Props {
  statusList: providerStatusListType;
  handleSearchedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  checkedStatus: ICalendarFilterOption[];
  setCheckedStatus: Dispatch<SetStateAction<any>>;
  setHasData: Dispatch<SetStateAction<boolean>>;
  title?: string;
  searchParams?: URLSearchParams;
  updateHistory?: () => void;
}

const ActiveStatusFilter = ({
  statusList,
  handleSearchedValues,
  checkedStatus,
  setCheckedStatus,
  setHasData,
  title,
  searchParams,
  updateHistory
}: Props) => {
  const statusArray = Object.entries(statusList).map(([_, value]) => ({
    id: value,
    title: value
  }));

  const statuses = useMemo(
    () => (statusArray ? mapToValuedOption(statusArray) : []),

    [statusArray]
  );

  const searchedStatuses = useMemo(() => {
    const searchedValues = handleSearchedValues(statuses);

    return searchedValues;
  }, [handleSearchedValues, statuses]);

  useEffect(() => {
    searchedStatuses?.length === 0 ? setHasData(false) : setHasData(true);
  }, [searchedStatuses, setHasData]);

  return (
    <>
      <CalendarFilter
        setCheckedList={setCheckedStatus}
        checkedList={checkedStatus}
        listOptions={searchedStatuses}
        length={Object.keys(statusList).length}
        title={title ? title : 'Status'}
        searchParams={searchParams}
        updateHistory={updateHistory}
      />
    </>
  );
};

export default React.memo(ActiveStatusFilter);
