import gql from 'graphql-tag';

export const UPSERT_DAY_AVAILABILITY = gql`
  mutation(
    $dayAvailabilityUpdate: DayAvailabilityUpdateInput!
    $providerTemplate: ProviderTemplateInput!
    $deletedEvents: [Int!]
  ) {
    upsertDayAvailabilities(
      dayAvailabilityUpdate: $dayAvailabilityUpdate
      providerTemplate: $providerTemplate
      deletedEvents: $deletedEvents
    ) {
      id
    }
  }
`;
