import { useReactiveVar } from '@apollo/client';
import {
  convertFilteredToSearched,
  isAttendeeInFiltered
} from 'helpers/calendarHelper';
import useEventsFetcherHook from 'hooks/eventsFetcherHook';
import { IPhantomEvent } from 'model/calendar/events';
import { ICalendarResource } from 'model/calendar/filters';
import { calendarGroup } from 'model/calendar/groups';
import { IEvent, IFullAppointmentType } from 'model/v2';
import React, {
  Dispatch,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar
} from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  calendarFiltersPrefVar
} from 'utils/cache/filters';
import { formatProviderDisplayName } from 'utils/format';
import { mapObservationToPhantomEvent } from 'utils/mappers/response/phantomEvents';
import { EVENTS_FETCHING_ACTIONS, plannerContext } from '..';
import {
  useAddPhantomEvents,
  useAddProviderToCalendar,
  useApptTypesMap
} from 'pages/MainCalendarPage/FormPhantomEvents/CustomPhantomHooks';

interface IProps {
  event: IEvent;
  observersList: number[];
  providersMap: Map<any, any>;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  apptTypes: IFullAppointmentType[];
  isAddObservationMode: boolean;
  abaObserversList: IEvent[];
  lastAbaObserver: IEvent;
  observerId: number | undefined;
  abaObserversListObj?: IEvent;
}

const ShadowPhantomEvents: React.FC<IProps> = ({
  event,
  observersList,
  providersMap,
  paginationArray,
  setPaginationArray,
  apptTypes,
  isAddObservationMode,
  abaObserversList,
  observerId,
  abaObserversListObj
}) => {
  const selectedGroupRef = useRef<ICalendarResource[]>();
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const { setIsLoading, setIsCalendarLoading, isCalendarLoading } = useContext(
    plannerContext
  );
  const appTypesMap = useApptTypesMap(apptTypes);
  const { getStaffEvents } = useEventsFetcherHook(
    selectedGroupRef && selectedGroupRef.current
      ? selectedGroupRef.current
      : undefined,
    setIsLoading,
    setIsCalendarLoading,
    isCalendarLoading,
    EVENTS_FETCHING_ACTIONS.APPLY_SEARCH,
    setPaginationArray,
    paginationArray,
    [],
    selectedGroupRef.current as ICalendarResource[]
  );

  const { addProviderToCalendar, searchedProviders } = useAddProviderToCalendar(
    getStaffEvents,
    filters,
    calendarView,
    calendarDate
  );

  const addPhantomEvents = useAddPhantomEvents();

  useEffect(() => {
    setIsInitialRender(true);
  }, [isAddObservationMode, setIsInitialRender]);

  const events = useReactiveVar(calendarEventsPrefVar);
  useEffect(() => {
    if (!isAddObservationMode) return;
    if (observersList && observersList.length > 0) {
      const newProviders: ICalendarResource[] = [];
      observersList.forEach(observer => {
        let selectedProvider: ICalendarResource = providersMap?.get(observer);
        if (selectedProvider?.status !== 'Inactive') {
          selectedProvider = {
            ...selectedProvider,
            displayName: formatProviderDisplayName(selectedProvider),
            attendeeType: calendarGroup.provider
          };
          if (isAttendeeInFiltered(observer) && selectedProvider) {
            const shouldFetchEvents = convertFilteredToSearched(
              selectedProvider
            );
            if (!shouldFetchEvents) return;
          } else if (searchedProviders?.has(observer)) {
            return;
          }
          if (selectedProvider) {
            newProviders.push(selectedProvider);
          }
        }
      });
      if (newProviders) {
        //in filter convert to search
        selectedGroupRef.current = newProviders;
        addProviderToCalendar(newProviders);
      }
    }
  }, [
    observersList,
    providersMap,
    searchedProviders,
    addProviderToCalendar,
    isAddObservationMode
  ]);

  //ABA Observer selected
  useEffect(() => {
    if (!isAddObservationMode) return;
    if (observerId !== undefined) {
      const newProviders: ICalendarResource[] = [];
      let selectedProvider: ICalendarResource = providersMap?.get(observerId);
      if (selectedProvider.status !== 'Inactive') {
        selectedProvider = {
          ...selectedProvider,
          displayName: formatProviderDisplayName(selectedProvider),
          attendeeType: calendarGroup.provider
        };
        if (isAttendeeInFiltered(observerId) && selectedProvider) {
          const shouldFetchEvents = convertFilteredToSearched(selectedProvider);
          if (!shouldFetchEvents) return;
        } else if (
          observerId !== undefined &&
          searchedProviders?.has(observerId)
        ) {
          return;
        }
        if (selectedProvider) {
          newProviders.push(selectedProvider);
        }
        if (newProviders) {
          //in filter convert to search
          selectedGroupRef.current = newProviders;
          addProviderToCalendar(newProviders);
        }
      }
    }
  }, [
    observerId,
    providersMap,
    searchedProviders,
    addProviderToCalendar,
    isAddObservationMode
  ]);

  // add phantom events
  useEffect(() => {
    const leadEvents = events.filter(
      calendarEvent => calendarEvent.id === event.id
    );
    const currentEventsWithoutLead = events.filter(
      calendarEvent => event.id !== calendarEvent.id
    );

    const adjustedLead = leadEvents.map(leadEvent => {
      leadEvent.observationsCount = observersList.length;
      return { ...leadEvent, observationsCount: observersList.length };
    });

    if (
      (!isAddObservationMode || !isInitialRender) &&
      observersList.length === 0
    ) {
      addPhantomEvents(currentEventsWithoutLead.concat(leadEvents), []);
    }

    if (observersList && observersList.length > 0 && isAddObservationMode) {
      setIsInitialRender(false);
      const phantomEvents: IPhantomEvent[] = observersList.map(observer => {
        const mappedPhantomEvent = mapObservationToPhantomEvent(
          event,
          observer,
          calendarView,
          calendarDate,
          appTypesMap,
          providersMap,
          observersList.length,
          {},
          false
        );
        return mappedPhantomEvent;
      });
      addPhantomEvents(currentEventsWithoutLead, [
        ...phantomEvents,
        ...(adjustedLead as any)
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarDate,
    calendarView,
    event.id,
    observersList,
    appTypesMap,
    isAddObservationMode
  ]);

  // add ABA observer events
  useEffect(() => {
    const leadEvents = events.filter(
      calendarEvent => calendarEvent.id === event.id
    );
    const currentEventsWithoutLead = events.filter(
      calendarEvent => event.id !== calendarEvent.id
    );

    if (
      (!isAddObservationMode || !isInitialRender) &&
      abaObserversList.length === 0
    ) {
      addPhantomEvents(currentEventsWithoutLead.concat(leadEvents), []);
    }
    let phantomEvents: IPhantomEvent[] = [];
    let observerCount = 0;
    if (
      abaObserversList &&
      abaObserversList.length > 0 &&
      isAddObservationMode
    ) {
      observerCount = abaObserversList.length;
      abaObserversList.map(observer => {
        const mappedPhantomEvent = mapObservationToPhantomEvent(
          event,
          observer?.provider?.id,
          calendarView,
          calendarDate,
          appTypesMap,
          providersMap,
          abaObserversList.length,
          observer,
          false
        );
        phantomEvents.push(mappedPhantomEvent);
        return mappedPhantomEvent;
      });
    }
    if (
      abaObserversListObj?.provider?.id !== undefined &&
      abaObserversListObj?.provider?.status !== 'Inactive'
    ) {
      observerCount = observerCount + 1;
      phantomEvents.push(
        mapObservationToPhantomEvent(
          event,
          abaObserversListObj?.provider?.id,
          calendarView,
          calendarDate,
          appTypesMap,
          providersMap,
          abaObserversList.length,
          abaObserversListObj,
          false
        )
      );
    }
    if (isAddObservationMode) {
      const adjustedLead = leadEvents.map(leadEvent => {
        leadEvent.observationsCount = observerCount;
        return { ...leadEvent, observationsCount: observerCount };
      });
      setIsInitialRender(false);
      addPhantomEvents(currentEventsWithoutLead, [
        ...phantomEvents,
        ...(adjustedLead as any)
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarDate,
    calendarView,
    event.id,
    abaObserversList,
    abaObserversListObj,
    appTypesMap,
    isAddObservationMode
  ]);

  return <></>;
};

export default ShadowPhantomEvents;
