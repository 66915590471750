import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { ITableColumn, TableSortDirection, ITableComp } from './type';
import TheadLabel from './TheadLabel';

interface ITheadProps {
  headers: ITableColumn[];
  direction: TableSortDirection;
  clickedCol: string;
  changeSort: (comp: ITableComp, clickedCol: string) => void;
}

const Thead: React.FC<ITheadProps> = (props: ITheadProps) => {
  const { headers, direction, clickedCol, changeSort } = props;
  return (
    <Table.Header>
      <Table.Row>
        {headers.map((col, index) => {
          if (col.comp) {
            return (
              <Table.HeaderCell
                key={index}
                onClick={() => changeSort(col.comp!, col.key)}
              >
                <TheadLabel
                  sorted={col.key === clickedCol}
                  label={col.label}
                  direction={direction}
                />
              </Table.HeaderCell>
            );
          }
          return <Table.HeaderCell key={index}>{col.label}</Table.HeaderCell>;
        })}
      </Table.Row>
    </Table.Header>
  );
};

export default Thead;
