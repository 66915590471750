import React from 'react';
import { ContentWrapper } from './style';
import { WarningOutlined } from '@ant-design/icons';
interface IProps {
  isConflicting?: boolean;
}

const PopupHeader: React.FC<IProps> = ({ isConflicting }: IProps) => {
  return (
    <ContentWrapper>
      {isConflicting && (
        <div className="conflict">
          <label>Conflict</label>
          <WarningOutlined className="warning" />
        </div>
      )}
    </ContentWrapper>
  );
};
export default React.memo(PopupHeader);
