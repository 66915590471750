import { IFullAppointmentType } from 'model/v2';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { GET_APPT_TYPES_WITH_SUB_TYPES } from '../queries/AppointmentTypes';

export const useApptTypesWithAdmin = (sidebarstate?: boolean) => {
  const [apptTypes, setApptTypes] = useState<IFullAppointmentType[]>([]);
  const [adminTypes, setAdminTypes] = useState<IFullAppointmentType[]>([]);
  const { loading } = useQuery(GET_APPT_TYPES_WITH_SUB_TYPES, {
    variables: {
      includeAdminTypes: true,
      sidebarState: sidebarstate
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      let tempApptTypes: IFullAppointmentType[] = [];
      let tempAdminTypes: IFullAppointmentType[] = [];
      data?.allAppointmentTypes?.forEach((apptType: IFullAppointmentType) => {
        apptType.eventType?.name === EVENT_TYPES.Admin
          ? tempAdminTypes.push(apptType)
          : tempApptTypes.push(apptType);
      });
      setApptTypes(tempApptTypes);
      setAdminTypes(tempAdminTypes);
    }
  });
  return { apptTypes, adminTypes, loadingApptTypes: loading };
};
