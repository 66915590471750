import React from 'react';
import { Datepicker as LibDatepicker } from 'lib/ui/datepicker';
import { FormError } from './error';

const Datepicker = ({ field, onChange, ...restProps }) => {
  return (
    <>
      <LibDatepicker value={field.value} onChange={onChange} {...restProps} />
      {field?.name && <FormError name={field.name} />}
    </>
  );
};

export default React.memo(Datepicker);
