import React, { useMemo } from 'react';

import { ClinicBarWrapper } from '../style';
interface IProps {
  clinics: string[][];
}
const ClinicBarCalendar: React.FC<IProps> = ({ clinics }: IProps) => {
  const renderRows = useMemo(() => {
    let columnsArr: any[] = [];
    let finalArr: any[] = [];
    clinics.forEach(clinicDayArr => {
      let columns: any[] = [];
      clinicDayArr.forEach((clinic, i) => {
        columns.push(<label className="label">{`${clinic}`}</label>);
        columns.push(<label> | </label>);
        if ((i + 1) % 3 === 0) {
          if (!clinicDayArr[i + 1]) columns.pop();
          columnsArr.push(<div className="row">{columns}</div>);
          columns = [];
        }
      });
      columns.pop();
      columnsArr.push(<div className="row">{columns}</div>);
      finalArr.push(<div className="column">{columnsArr}</div>);
      columnsArr = [];
    });
    return finalArr;
  }, [clinics]);
  return (
    <ClinicBarWrapper>
      <div className="header">
        <div className="row-header">{renderRows}</div>
      </div>
    </ClinicBarWrapper>
  );
};
export default ClinicBarCalendar;
