import gql from 'graphql-tag';

export const GET_DAY_AVAILABILITIES_BY_PROVIDER_TEMPALTE_ID = gql`
  query($providerTemplateId: Int!) {
    getDayAvailabilitiesByProviderTemplateId(
      providerTemplateId: $providerTemplateId
    ) {
      id
      dayOfWeek
      startTime
      endTime
      type
      isAdmin
      isTelehealth
      isInClinic
      isOffsite
      location
      clinics {
        id
      }
      providerTemplate {
        id
      }
    }
  }
`;
