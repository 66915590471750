import { formatClinicName } from 'utils/format';
import { DefaultTagFilterOption } from 'utils/constants/calendarFilters';

export const mapToFilter = array =>
  array.map(item => {
    return {
      text: item.value,
      value: item.id
    };
  });

export const mapToFilterClinic = array =>
  array.map(item => {
    return {
      text: formatClinicName(item.value),
      value: item.id
    };
  });

export const mapToDefaultTagFiltersValue = filter => ({
  name: filter?.name,
  isDefault: filter?.isDefault
    ? DefaultTagFilterOption.yes
    : DefaultTagFilterOption.no
});
