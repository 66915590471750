import React from 'react';
import { AuthIdWrapper } from '../AuthGroupForm.style';
import { Row } from 'antd';
import { FormItem, Input, RadioGroup, Datepicker } from 'views/containers/form';
import { Field } from 'formik';

const AuthIdContainer = ({ values, authTypes, handleChange, errorMessage }) => {
  return (
    <AuthIdWrapper>
      <h3>Auth ID</h3>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem label="Authorization ID Number" xs={16} sm={4}>
          <Input
            className={errorMessage ? 'error-input' : undefined}
            name="authGroupID"
            value={values.authGroupID}
            placeholder="Authorization ID Number"
            onChange={handleChange}
          />
          {errorMessage && <div className="error"> {errorMessage} </div>}
        </FormItem>
        <FormItem label="Authorization Type" xs={16} sm={6}>
          <Field
            name="authGroupType"
            render={fieldProps => (
              <RadioGroup
                options={authTypes}
                name="authGroupType" /// should be added directly not in the field
                value={values.authGroupType}
                {...fieldProps}
              />
            )}
          />
        </FormItem>
      </Row>

      <h3>Auth Period</h3>
      <Row type="flex" justify="start" align="bottom" gutter={16}>
        <FormItem label="Authorization Start Date" xs={16} sm={3}>
          <Field
            name="startDate"
            render={fieldProps => (
              <Datepicker
                value={values.startDate}
                placeholder="mm/dd/yyyy"
                format={'MM/DD/YYYY'}
                {...fieldProps}
              />
            )}
          />
        </FormItem>
        <FormItem label="Authorization End Date" xs={16} sm={3}>
          <Field
            name="endDate"
            render={fieldProps => (
              <Datepicker
                value={values.endDate}
                placeholder="mm/dd/yyyy"
                format={'MM/DD/YYYY'}
                {...fieldProps}
              />
            )}
          />
        </FormItem>
        <FormItem label="Report Due Date" xs={16} sm={3}>
          <Field
            name="dueDate"
            render={fieldProps => (
              <Datepicker
                value={values.dueDate}
                placeholder="mm/dd/yyyy"
                format={'MM/DD/YYYY'}
                {...fieldProps}
              />
            )}
          />
        </FormItem>
      </Row>
    </AuthIdWrapper>
  );
};

export default AuthIdContainer;
