import styled from 'styled-components';
import { Select } from 'antd';
import { PURPLE } from 'utils/constants/theme';

export const LibSelect = styled(Select)`
  width: 100%;
  &.simple {
    .ant-select-selection {
      border-color: transparent;
    }
    svg {
      position: static;
    }
  }
  .ant-select-selection {
    border-color: ${({ disabled }) => (disabled ? 'inherit' : PURPLE)};
    &:focus {
      position: absolute;
      top: 100%;
      box-shadow: 0 0 0 2px rgba(111, 75, 241, 0.22);
    }
  }
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }
  .ant-select-selection-selected-value {
    font-size: 14px;
    color: #21303a;
  }
  .ant-select-dropdown-menu-item {
    font-size: 14px;
    color: #000000;
  }
  .ant-select-arrow {
    color: ${PURPLE};
  }
  .select-option-wrapper {
    display: flex;
    align-items: center;
  }
  .color-box {
    justify-content: center;
    margin-right: 5px;
  }
`;

export const rectangleStyle = (color, borderColor) => ({
  width: '23px',
  height: '15px',
  backgroundColor: color,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: borderColor
});

export const LibOption = styled(Select.Option)``;
