import { Avatar } from '@material-ui/core';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { DEFAULT_DOCTOR_IMAGE } from 'utils/constants/default';

import { CancelAttendeeRowWrapper } from './style';
import { Link } from 'react-router-dom';

interface Props {
  displayName: string;
  displayId: any;
  icon: any;
  iconTitle: string;
  color: string;
  eventType: string;
  classNameVar: string;
}

const CancelAttendeeRow: React.FC<Props> = ({
  displayName,
  displayId,
  icon,
  iconTitle,
  color,
  eventType,
  classNameVar
}) => {
  return (
    <CancelAttendeeRowWrapper color={color}>
      <Grid>
        <Grid.Row className={`row-style ${eventType} `}>
          <Grid.Column className="avatar">
            <Avatar
              src={iconTitle === 'Patient' ? ' ' : DEFAULT_DOCTOR_IMAGE}
            />
          </Grid.Column>

          <Grid.Column className="attende-name">
            <Link
              className="attende-name"
              target="_blank"
              to={
                iconTitle === 'Patient'
                  ? `/clients/${displayId}`
                  : `/providers/${displayId}`
              }
            >
              {displayName}
            </Link>
          </Grid.Column>

          <Grid.Column className="button-column">
            <button className={classNameVar}>
              {icon} {iconTitle}
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CancelAttendeeRowWrapper>
  );
};

export default CancelAttendeeRow;
