import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .conflict {
    background: #e82424;
    border-radius: 3px;
    color: #ffffff;
    padding: 6px 10px;
    max-width: 40%;
  }
  .warning {
    color: white;
    padding-left: 8px;
  }
`;
