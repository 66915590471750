import React, { useState, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'lib/ui';
import { IClinic, iSmartSingleResultForm } from 'model/v2';
import { ADD_EVENT } from 'api/graphql/v2/mutations/Event';
import { SmartCardFooterWrapper } from './Style';
import { getOrganisedSmartEvent } from 'utils/mappers/request/V2/event';
import { useMutation } from 'react-apollo';
import { updateCalendarPref } from 'utils/cache/calendar';
import moment from 'moment';
import Content from 'views/components/ui/content';

interface IProps {
  appointmentTypeId: number;
  appointmentSubTypeId?: number;
  setVisible: (val: boolean) => void;
  selectedIndex: number | undefined;
  index: number;
  telehealthClinic: IClinic;
  setSelectedIndex: (val: number | undefined) => void;
}

const SimpleSmartFooter: React.FC<IProps> = ({
  appointmentTypeId,
  appointmentSubTypeId,
  setVisible,
  selectedIndex,
  index,
  telehealthClinic,
  setSelectedIndex
}: IProps) => {
  const { handleSubmit, watch } = useFormContext();
  const [saving, setSaving] = useState(false);
  const [payload, setPayload] = useState<any>();
  const cardIsSelected = selectedIndex === index;
  let addEvents: any;
  [addEvents] = useMutation(ADD_EVENT);

  const successCallbackBehavior = useCallback(() => {
    updateCalendarPref(moment(payload?.startDate));
    setVisible(false);
    setSaving(false);
  }, [setSaving, setVisible, payload]);

  const errorCallbackBehavior = useCallback(() => {
    setSaving(false);
    setVisible(false);
  }, [setSaving, setVisible]);

  useEffect(() => {
    if (payload !== undefined) sendRequest();
  }, [payload]);

  const sendRequest = useCallback(async () => {
    const request = addEvents({
      variables: { event: payload }
    });
    console.log(request);
    await request
      .then(() => {
        successCallbackBehavior();
      })
      .catch(() => {
        errorCallbackBehavior();
      });
  }, [payload, errorCallbackBehavior, successCallbackBehavior, addEvents]);

  const formData = watch();
  const formIsInvalid =
    formData.result.isOffsite &&
    (formData['location-category'] === undefined ||
      formData.address === undefined);

  const submitForm = handleSubmit(async (data: iSmartSingleResultForm) => {
    const addEventPayload = getOrganisedSmartEvent(
      data.result,
      appointmentTypeId,
      appointmentSubTypeId
    );
    if (
      addEventPayload.locationType === 'Telehealth' ||
      addEventPayload.locationType === 'Off-Site'
    )
      addEventPayload.clinic = { id: telehealthClinic.id };
    setSelectedIndex(undefined);
    setPayload(addEventPayload);
    setSaving(true);
  });

  return (
    <SmartCardFooterWrapper>
      <Button
        className="large"
        onClick={submitForm}
        type="submit"
        loading={saving}
        disabled={saving || !cardIsSelected || formIsInvalid}
      >
        Schedule
      </Button>
      <Content loading={saving} data={true} />
    </SmartCardFooterWrapper>
  );
};

export default React.memo(SimpleSmartFooter);
