import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin: 2em auto;
  display: flex;
  width: 100%

  .availability-templates {
      margin: 2em 0;
      border-collapse: collapse;
      width: 100%;
      height: 100%;

      td,
      th {
        padding: 1em;
        font-size: 15px;
      }
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      tr:hover {
        background-color: #ddd;
      }
    }
    tr {
      border: solid 1px #c9cfd3;
    }
    th {
      background-color: #E7E9EB;
      color: black;
      padding: 2em 1em;
    }
    .edit,.delete {
      color: #6f4bf1;
      margin: 0 10px;
      cursor: pointer;
      font-size: 22px;
      display: inline-flex;
      flex-direction: row;
      &:hover {
        opacity: 55%;
      }
    }
    .text-action {
      padding: 2px;
      display: inline-block;
      color: #6f4bf1;
      text-decoration: underline;
      text-underline-offset: 5px;
      &:hover {
        cursor: pointer;
        opacity: 55%;
      }
    }
    .cta {
      text-align: right;
    }
    .disabled {
      opacity: 50%;
      pointer-events: none;
    }
    .availability-actions {
      width: 200px;
    }
    .availability-description {
      width: 34%;
    }
  }
`;
