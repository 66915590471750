import styled from 'styled-components';

export const CancelModalWrapper = styled.div`
  .observer-warning {
    color: red;
    padding: 0px 32px;
    text-align: center;
  }
  border-top: 10px solid #db2828;
  border-radius: 5px;
  .cancel-modal-title {
    font-family: Lato;
    padding: 22px 32px 0 32px;
    margin: 0 0 10px 0;
    opacity: 0.9;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    color: #3e5766;
  }
  .observers-list {
    padding: 0px !important;
  }

  .observers-list .avatar-cls {
    width: 60px !important;
    border-radius: 19px;
    padding-left: 24px !important;
  }
  .observers-list .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  .observers-list .attende-name-cls {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3e5766;
    width: 53% !important;
    padding-top: 6px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .observers-list .button-column-cls {
    width: 28% !important;
    padding-right: 0px !important;
    margin-top: 2px !important;
  }
  .observers-list .observer-btn {
    background: #6f4bf1;
    border-radius: 6px !important;
    padding: 5% !important;
    font-size: 12px;
    font-weight: 700;
  }
  .observers-list .collapsible {
    color: #6f42f5;
  }
  .observers-list .time-icon {
    color: #3e5766;
  }
  .observers-list .ui.grid > .row > .column {
    padding-right: 0px !important;
  }
  .cancel-modal-text {
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-left: 32px;
    margin-bottom: 8px;
    color: #3e5766;
  }

  .selected-events-num {
    color: red;
  }
  /* RADIO GROUP */
  .radio-group-wrapper {
    padding: 0 32px;
    margin-bottom: 8px;
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      .ant-radio-wrapper {
        align-items: flex-start;
        margin: 8px 0 16px 0;
        font-size: 15px;
        color: #21303a;
        .ant-radio-checked {
          .ant-radio-inner {
            border: 1px solid red;
            &:after {
              border: 1px solid #db2828;
              background: #db2828;
            }
          }
        }
      }
    }
  }

  /* SELECT REASON DROPDOWN*/
  .select-reason {
    padding: 0 32px;
    margin: 0 0 16px 0;
    .ant-select {
      width: 360px;
      .ant-select-selector {
        border-radius: 5px;
      }
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
      border: 1px solid #e82424;
    }
    .error {
      margin-top: 5px;
    }
    .ant-select-arrow {
      color: #e82424;
    }
  }
  .select-reason-selected {
    padding: 0 32px;
    margin: 0 0 16px 0;
    .ant-select {
      width: 360px;
      .ant-select-selector {
        border-radius: 5px;
        border: 1px solid #e82424;
      }
    }
    .ant-select-arrow {
      color: #e82424;
    }
    .error {
      margin-top: 5px;
    }
  }
  /* ADD NOTE TEXT AREA */
  .add-note {
    margin-bottom: 20px;
    padding: 0 32px;
    & > div {
      width: 360px;
      .ant-input {
        border: 1px solid #c9cfd3;
        border-radius: 5px;
        min-height: 56px;
        max-height: 150px;
        padding: 9px;
        resize: vertical;
        &:focus {
          box-shadow: none;
          border: 1px solid #e82424;
        }
      }
    }
  }
  .add-note-selected {
    margin-bottom: 20px;
    padding: 0 32px;
    & > div {
      width: 360px;
      .ant-input {
        border: 1px solid #e82424;
        border-radius: 5px;
        min-height: 56px;
        max-height: 150px;
        padding: 9px;
        resize: vertical;
        &:focus {
          box-shadow: none;
          border: 1px solid #e82424;
        }
      }
    }
  }

  /* MODAL ACTIONS */
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding: 0 32px;
    button {
      margin-left: 12px;
    }
  }

  .two-column {
    margin-left: 32px;
    margin-bottom: 21px;
    display: flex;
    flex: column;
    background: #c9cfd3;
    border-radius: 6px;
    width: 360px;
    height: 32px;
    padding: 4px 24px 4px 12px;
  }
  .reason {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    color: #ffffff;
  }
  .remove-btn {
    width: 12px;
    height: 12px;

    margin: 0px -16px 0px 0px;
  }
  .cancel-modal-cotent {
    font-family: Lato;
    font-size: 11px;
    font-style: normal;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-left: 32px;
    padding-right: 20px;
    margin-bottom: 8px;
    color: #3e5766;
    height: 26px;
  }
  .cancel-modal-attendees {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
    color: #6f42f5;
  }
  .cancel-modal-attendees-div {
    padding-left: 32px;
    padding-bottom: 20px;
  }
  .time-icon {
    color: #6f42f5;
  }
  .ant-radio-wrapper {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }
`;

export const CancelObserversWrapper = styled.div`
.observers-list {
  padding: 0 32px;
}
.collapsible {
  background: transparent;
  border: none;
}
.event-date {
  font-size: 13px;
}
.attendees {
  display: flex;
  align-items: center;
  margin: 5px;
  .avatar {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
    border-radius: 50%;
    border: solid 1px #979797;
    background-color: #d8d8d8;
  }
  .attende-name {
    font-size: 15px;
    color: #3e5766;
    width: 360px;
  }
  .observer-btn {
    color: white;
    float: right;
    padding: 2%;
    border: none;
    border-radius: 6px;
  }
  .observer1-btn {
    background-color: #45bfff;
  }
  .observer2-btn {
    background-color: #4d7bbd;
  }
  .observer3-btn {
    background-color: #45bfff;
  }
  .observer4-btn {
    background-color: #4d7bbd;
  }
  .observer5-btn {
    background-color: #45bfff;
  }
  `;

export const EditTimeModalWrapper = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .edit-appointment-title {
    opacity: 0.8;
    font-size: 15px;
    font-weight: bold;
    color: #21303a;
    margin-bottom: 40px;
  }

  .start-time {
    padding-left: 0 !important;
    padding-right: 14px !important;
  }

  .end-time {
    padding-right: 0 !important;
    padding-left: 14px !important;
  }

  .notes-wrapper {
    width: 227px;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 40px 0 30px 0;
    padding: 0 65px;
    .cancel-btn,
    .ok-btn {
      background-color: transparent;
      border: none;
      font-size: 15px;
      padding: 0;
      &.cancel-btn {
        color: #919699;
      }
      &.ok-btn {
        opacity: 0.5;
        letter-spacing: 0.17px;
        color: #0d77b5;
        margin-left: 25px;
        span {
          text-decoration: underline !important;
        }
      }
      &::after {
        box-shadow: none !important;
      }
    }
  }
`;
export const ImpactedObserversWrapper = styled.div`
.observers-list {
  padding: 0 32px;
}

.observer-event-timing {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon {
  color: #6F42F5 !important;
}
.impacted-observers-list {
  padding: 0 0 14px;
}
.impacted-observers-expanded {
  display: flex !important;
  align-items: center !important;
}
.collapsible {
  background: transparent;
  border: none;
}
.event-date {
  font-size: 12px;
  color: #3E5766;
}
.attendees {
  display: flex;
  align-items: center;
  margin-left: 9%;
  margin-top: 10px;
  .avatar {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
    border-radius: 50%;
    border: solid 1px #979797;
    background-color: #d8d8d8;
  }
  .attende-name {
    font-size: 15px;
    color: #3e5766;
    width: 180px;
    display: grid;
  }
  .observer-btn {
    color: white;
    float: right;
    padding: 2%;
    border: none;
    border-radius: 6px;
  }
  .observer1-btn {
    background-color: #45bfff;
  }
  .observer2-btn {
    background-color: #4d7bbd;
  }
  `;
export const SubstituteModalWrapper = styled.div`
  border-top: 10px solid #6f42f5;
  border-radius: 5px;
  .substitute-modal-title {
    font-family: Lato;
    padding: 22px 15px 0 15px;
    margin: 0 0 10px 0;
    opacity: 0.9;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    color: #3e5766;
  }
  .substitute-modal-text {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-left: 15px;
    padding-right: 10px;
    margin-bottom: 8px;
    color: #415766;
  }
  .substitute-modal-p {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 8px;
    color: #415766;
  }
  .p-title {
    min-height: 45px;
  }
  .modal-underline {
    text-decoration: underline;
  }
  .f-14 {
    font-size: 14px;
  }
  .f-12 {
    font-size: 12px;
  }
  .pad-8-px {
    padding-right: 8px;
  }
  .outer-shell {
    width: 100% !important;
  }
  .w-42-inline {
    height: auto;
    width: 42% !important;
  }
  .w-16-inline {
    height: auto;
    width: 16% !important;
  }
  .grid .row {
    padding: 0 !important;
  }
  .flx-1-1-grow {
    display: flex;
    height: auto;
    max-height: 75px;
  }
  .flx-fixed-w {
    width: 30px;
    height: 25px;
  }
  .selected-events-num {
    color: red;
  }
  .arrow-cls {
    font-size: 45px;
    padding-top: 100%;
  }
  .red {
    color: #e82424;
  }
  .pad-15-px {
    padding-left: 15px;
  }
  /* SELECT REASON DROPDOWN*/
  .select-reason {
    padding: 0 15px;
    margin: 5px 0 16px 0;
    .ant-select {
      width: 360px;
      .ant-select-selector {
        border-radius: 5px;
      }
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
      border: 1px solid #e82424;
    }
    .error {
      margin-top: 5px;
    }
    .ant-select-arrow {
      color: #e82424;
    }
  }
  .select-reason-selected {
    padding: 0 15px;
    margin: 5px 0 16px 0;
    .ant-select {
      width: 360px;
      .ant-select-selector {
        border-radius: 5px;
        border: 1px solid #e82424;
      }
    }
    .ant-select-arrow {
      color: #e82424;
    }
    .error {
      margin-top: 5px;
    }
  }
  /* ADD NOTE TEXT AREA */
  .add-note {
    margin-bottom: 10px;
    padding: 0 15px;
    & > div {
      width: 360px;
      .ant-input {
        border: 1px solid #c9cfd3;
        border-radius: 5px;
        min-height: 56px;
        max-height: 150px;
        padding: 9px;
        resize: vertical;
        &:focus {
          box-shadow: none;
          border: 1px solid #e82424;
        }
      }
    }
  }
  .add-note-selected {
    margin-bottom: 10px;
    padding: 0 15px;
    & > div {
      width: 360px;
      .ant-input {
        border: 1px solid #e82424;
        border-radius: 5px;
        min-height: 56px;
        max-height: 150px;
        padding: 9px;
        resize: vertical;
        &:focus {
          box-shadow: none;
          border: 1px solid #e82424;
        }
      }
    }
  }

  /* MODAL ACTIONS */
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding-left: 55%;
    padding-bottom: 20px;
    button {
      margin-left: 12px;
    }
  }

  .two-column {
    margin-left: 32px;
    margin-bottom: 21px;
    display: flex;
    flex: column;
    background: #c9cfd3;
    border-radius: 6px;
    width: 360px;
    height: 32px;
    padding: 4px 24px 4px 12px;
  }
  .reason {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    color: #ffffff;
  }
  .remove-btn {
    width: 12px;
    height: 12px;

    margin: 0px -16px 0px 0px;
  }
  .cancel-modal-cotent {
    font-family: Lato;
    font-size: 11px;
    font-style: normal;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-left: 32px;
    padding-right: 20px;
    margin-bottom: 8px;
    color: #3e5766;
    height: 26px;
  }
  .cancel-modal-attendees {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
    color: #6f42f5;
  }
  .cancel-modal-attendees-div {
    padding-left: 32px;
    padding-bottom: 20px;
  }
  .time-icon {
    color: #6f42f5;
  }
  .ant-radio-wrapper {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }
`;
