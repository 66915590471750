export const PURPLE = '#6f4bf1';
export const GREEN = '#02bf00';
export const RED = '#cc3f3f';
export const INACTIVE_URL = '#415766';
export const ACTIVE_URL = '#0d77b5';
export const AVATAR_INITIAL = '#21303a';
export const PALE_ORANGE = '#f19f73';
export const LIGHT_SKY_BLUE = '#d2e8f4';
export const WHITE = '#ffffff';
export const EDIT_FORM_BG = '#f6f6f6';
export const DARK_GREY = '#415766';
export const LIGHT_GREY = '#d8d8d8';
export const LIGHT_PINK = '#dadada';
export const LIGHT_PURPLE = '#f8f7ff';
export const PRIMARY_PURPLE = '#6F42F5';
export const OPACITY_GREY = '#fafafa';
export const BOLD_GREY = '#1f2b33';
export const FAILED_SYNC = '#e82424';
// // Appointment Type Colors
export const AT_1 = '#81cc89'; // ABA Therapy
export const AT_2 = '#81d6af'; // Occupational Therapy
export const AT_3 = '#88ddea'; // Medical
export const AT_4 = '#a0c5ff'; // Speech Therapy
export const AT_5 = '#a0aeff'; // MSE
export const AT_6 = '#c7acfd'; // Music Therapy
export const AT_7 = '#e490f6'; // Other
export const AT_8 = '#fdacd8'; // Neuromodulation Therapists
export const AT_9 = '#fdb9a3'; // Therapy
export const AT_10 = '#f38281'; // Medical Providers
export const AT_11 = '#fdc784'; // Physical Therapy
export const AT_12 = '#f9dc70'; // Family Wellness Counseling
export const AT_13 = '#c0f88f'; // CIM
export const AT_14 = '#cbcdc6'; // Internal

export const APPOINTMENT_BACKGROUND_COLORS = {
  CANCELED: 'rgba(255, 94, 94, 0.4)',
  DELETED: 'rgba(216, 216, 216, 0.4)',
  ADMIN: 'rgba(181, 208, 226)',
  CLIENT: '#caf3d1'
};

export const APPOINTMENT_HEADER_COLORS = {
  CANCELED: 'rgba(255, 94, 94, 1)',
  DELETED: 'rgba(1, 1, 1, 0.4)',
  ADMIN: 'rgba(61, 137, 182, 1)',
  CLIENT: '#81cc89'
};

// general
export const DEFAULT_COLOR_PICKER = '#FFFFFF';
export const SMART_SCHEDULE_RESULTS = {
  DIMMED_BACKGROUND: '#C9CFD3',
  DARK_CIRCLE_BACKGROUND: '#3E5766',
  LIGHT_CHECKED_BACKGROUND: 'rgba(111, 66, 245, 0.1)'
};
