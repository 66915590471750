import styled from 'styled-components';

export const ToastWrapper = styled.div`
  border: 1px
  border-radius: 3px;
  .container {
    width: 402px;
    height: 40px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .text {
    display: flex;
    align-items: center;
  }
  .name {
    display: block;
    max-width: 46px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .logo {
    display: inline-block;
    float: right;
  }
  .container-bottom {
    width: 310px;
    height: 2px;
    opacity: 0.5;
    box-shadow: 3px 20px 32px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
 
`;
