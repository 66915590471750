import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client';
import { mapValuesToArray } from 'helpers/calendarHelper';
import { ICalendarEvent } from 'model/calendar/events';
import { ICalendarFilter, ICalendarResource } from 'model/calendar/filters';
import {
  plannerCalendarPrefViewVar,
  PlannerView
} from 'utils/cache/calendar/index';

const calendarFilters: ICalendarFilter = {
  specialities: [],
  clinics: [],
  programs: [],
  departments: [],
  locations: [],
  savedFilterName: '',
  internals: []
};

//displayed on view (maintains order and removal)
const providerResources: ICalendarResource[] = [];
const clientsResources: ICalendarResource[] = [];
const programsResources: ICalendarResource[] = [];

//added through search
const searchedClients = new Map<number, ICalendarResource>();
const searchedProviders = new Map<number, ICalendarResource>();

//added from filter
const filteredProviders: ICalendarResource[] = [];

const calendarEvents: ICalendarEvent[] = [];

const useDefaultFilter: Boolean = true;

export const calendarFiltersPrefVar: ReactiveVar<ICalendarFilter> = makeVar(
  calendarFilters
);

//displayed providers on planner
export const providerResourcesPrevVar: ReactiveVar<ICalendarResource[]> = makeVar(
  providerResources
);
//displayed clients on planner
export const clientsResourcesPrevVar: ReactiveVar<ICalendarResource[]> = makeVar(
  clientsResources
);

//displayed programs on planner
export const programsResourcesPrevVar: ReactiveVar<ICalendarResource[]> = makeVar(
  programsResources
);

// all filtered providers (not paginated)
export const filteredProvidersResourcesPrevVar: ReactiveVar<ICalendarResource[]> = makeVar(
  filteredProviders
);

export const calendarEventsPrefVar: ReactiveVar<ICalendarEvent[]> = makeVar(
  calendarEvents
);

export const defaultFilterPrefVar: ReactiveVar<Boolean> = makeVar(
  useDefaultFilter
);

export const searchedClientsPrevVar: ReactiveVar<Map<
  number,
  ICalendarResource
>> = makeVar(searchedClients);
export const searchedProvidersPrefVar: ReactiveVar<Map<
  number,
  ICalendarResource
>> = makeVar(searchedProviders);

//get all resources
export const useGetAllResources = () => {
  const clients = useReactiveVar(searchedClientsPrevVar);
  const clientsValues = mapValuesToArray(clients);
  const providers = useReactiveVar(searchedProvidersPrefVar);
  const providerValues = mapValuesToArray(providers);
  const filteredProviders = useReactiveVar(filteredProvidersResourcesPrevVar);
  return clientsValues.concat(providerValues).concat(filteredProviders);
};

//Reset calendar when on tab navigation
export const resetAllCalendarVariables = () => {
  plannerCalendarPrefViewVar(PlannerView.plannerDay);
  clientsResourcesPrevVar([]);
  providerResourcesPrevVar([]);
  searchedClientsPrevVar(new Map<number, ICalendarResource>());
  searchedProvidersPrefVar(new Map<number, ICalendarResource>());
  filteredProvidersResourcesPrevVar([]);
  defaultFilterPrefVar(true);
  calendarEventsPrefVar([]);
  calendarFiltersPrefVar({
    specialities: [],
    clinics: [],
    programs: [],
    departments: [],
    locations: [],
    savedFilterName: '',
    internals: []
  });
};
