import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { IDropdownOptions } from 'model/ui/dropdown';
import SelectWrapper from './style';

interface Props {
  options: IDropdownOptions[];
  onChange: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => void;
  defaultValue?: string;
  placeholder?: string;
  value?: string;
  className?: string;
  onSearchChange?: (e: React.SyntheticEvent<HTMLElement>, data: any) => void;
  disabled?: boolean;
}

const SelectDropDown: React.FC<Props> = ({
  options,
  onChange,
  defaultValue,
  placeholder,
  value,
  className,
  onSearchChange,
  disabled
}: Props) => {
  return (
    <SelectWrapper>
      <Dropdown
        className={className}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        fluid
        selection
        options={options}
        onChange={onChange}
        onSearchChange={onSearchChange}
        disabled={!!disabled}
      />
    </SelectWrapper>
  );
};

export default React.memo(SelectDropDown);
