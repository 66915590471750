import { IProviderSpecialty, Specialty } from 'model/v2';
import { TableType } from 'model/tableType';
import UnderlineTitle from 'views/components/underlineTitle';
import ColorCell from 'views/components/colorCell';
import { ISpecialitiesConfig } from 'model/tableConfigurations';

export const mapSpecialities = (
  data: TableType[],
  addNew: (data?: TableType) => void
): ISpecialitiesConfig[] =>
  (data as IProviderSpecialty[]).map(item => ({
    title: (
      <UnderlineTitle
        title={item[Specialty.title]}
        onClick={() => {
          addNew(item);
        }}
      />
    ),
    id: item[Specialty.id],
    color: <ColorCell color={item[Specialty.color]} />,
    abbreviation: item[Specialty.abbreviation],
    department: item[Specialty.department]
  }));
