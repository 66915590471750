import { GET_OPENING_CONFLICTS } from 'api/graphql/v2/queries/Openings';
import { IOption } from 'model';
import {
  IConflictMapped,
  IEvent,
  IEventConflicts,
  IFormEvent,
  IFullAppointmentType,
  IMappedEvent,
  IProvider,
  IWarning
} from 'model/v2';
import React from 'react';
import { useLazyQuery } from 'react-apollo';
import { useFormContext } from 'react-hook-form';
import { EVENT_RESCHEDULE_OPTIONS } from 'utils/constants/lists';
import Content from 'views/components/ui/content';
import ConflictsFooter from './ConflictsFooter';
import ObserverWarningFooter from './ObserverWarningFooter';
import SubmitFooter from './SubmitFooter';
import { mapToConflict, STEPS } from './utils';
import ValidationFooter from './ValidationFooter';
import WarningFooter from './WarningFooter';
import MileageWarningFooter from './MileageWarningFooter';

interface Props {
  setVisible: (val: boolean) => void;
  action: string;
  providersMap: Map<any, IProvider>;
  apptTypes: IFullAppointmentType[];
  abaObservers?: IEvent[];
  setIsValidated?: boolean;
  isSeriesAbaEvent?: boolean;
  seriesEventObservations?: IEvent[];
  isValidated?: boolean;
  isValidatedEvent?: boolean;
  additionalProvidersList?: IProvider[];
  timeValidate?: boolean;
  adminAddressisFetch?: boolean;
}

const FormFooter: React.FC<Props> = ({
  setVisible,
  action,
  providersMap,
  apptTypes,
  abaObservers,
  isSeriesAbaEvent,
  seriesEventObservations,
  isValidated,
  isValidatedEvent,
  additionalProvidersList,
  timeValidate,
  adminAddressisFetch
}: Props) => {
  const methods = useFormContext();
  const recurringOptions: IOption[] = EVENT_RESCHEDULE_OPTIONS(true);
  const [saving, setSaving] = React.useState(false);
  const [formData, setFormData] = React.useState<IFormEvent | null>(null);
  const [mappedEvent, setMappedEvent] = React.useState<IMappedEvent[]>([]);
  const [step, setStep] = React.useState(STEPS.VALIDATE_FORM);
  const [conflicts, setConflicts] = React.useState<IConflictMapped[]>([]);
  const [warnings] = React.useState<IWarning[]>([]);

  const [doConflictsRequest, { loading }] = useLazyQuery(
    GET_OPENING_CONFLICTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        const eventConflicts: IEventConflicts = data?.getEventConflicts;
        if (eventConflicts.conflicts && eventConflicts.conflicts.length > 0) {
          setConflicts(
            mapToConflict(
              formData,
              eventConflicts.conflicts,
              providersMap,
              apptTypes
            )
          );
        }
        if ((eventConflicts.conflicts || []).length > 0) {
          setStep(STEPS.CONFLICTS);
        } else {
          setStep(STEPS.SUBMIT);
        }
      }
    }
  );

  const backToForm = React.useCallback(() => {
    setStep(STEPS.VALIDATE_FORM);
    setSaving(false);
  }, [setStep]);
  return (
    <Content loading={loading} data={true}>
      {() =>
        step === STEPS.VALIDATE_FORM ? (
          <ValidationFooter
            providersMap={providersMap}
            setMappedEvent={setMappedEvent}
            setFormData={setFormData}
            doConflictsRequest={doConflictsRequest}
            recurringOptions={recurringOptions}
            saving={saving}
            action={action}
            methods={methods}
            setStep={setStep}
            setSaving={setSaving}
            isValidated={isValidated}
            isValidatedEvent={isValidatedEvent}
            abaObservers={abaObservers}
            isSeriesAbaEvent={isSeriesAbaEvent}
            seriesEventObservations={seriesEventObservations}
            additionalProvidersList={additionalProvidersList}
            timeValidate={timeValidate}
            adminAddressisFetch={adminAddressisFetch}
          />
        ) : step === STEPS.MILEAGE_WARNING ? (
          <MileageWarningFooter
            setStep={setStep}
            conflicts={conflicts}
            backToForm={backToForm}
          />
        ) : step === STEPS.WARNINGS ? (
          <WarningFooter
            warnings={warnings}
            setStep={setStep}
            conflicts={conflicts}
            backToForm={backToForm}
            formData={formData}
            mappedEvent={mappedEvent}
          />
        ) : step === STEPS.OBSERVER_WARNING ? (
          <ObserverWarningFooter
            formData={formData}
            recurringOptions={recurringOptions}
            backToForm={backToForm}
            providersMap={providersMap}
            doConflictsRequest={doConflictsRequest}
          />
        ) : step === STEPS.CONFLICTS ? (
          <ConflictsFooter
            conflicts={conflicts}
            setStep={setStep}
            backToForm={backToForm}
          />
        ) : step === STEPS.SUBMIT ? (
          <SubmitFooter
            setSaving={setSaving}
            setVisible={setVisible}
            mappedEvent={mappedEvent}
            formData={formData}
            action={action}
            recurringOptions={recurringOptions}
            saving={saving}
            abaObservers={abaObservers}
            isSeriesAbaEvent={isSeriesAbaEvent}
            seriesEventObservations={seriesEventObservations}
          />
        ) : (
          <></>
        )
      }
    </Content>
  );
};
export default React.memo(FormFooter);
