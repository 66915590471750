import React from 'react';
import { Field, FastField } from 'formik';
import {
  FormItem,
  Input,
  AutoFormattedInput,
  RadioGroup,
  TextArea
} from 'views/containers/form';
import { Row } from 'antd';
import { mapToOption } from 'utils/mappers/form';
import { YES_NO_LIST } from 'utils/constants/lists';

const yesNoOptions = mapToOption(YES_NO_LIST);

export const ClientAdditionalInfoForm = React.memo(({ disabledFormFields }) => {
  return (
    <>
      <div className="form-title">
        Additional Information <span className="optionalSpan">(optional)</span>
      </div>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Mother's Name" xs={16} sm={6}>
          <Field
            name="profile.motherName"
            render={({ field }) => (
              <Input
                name="profile.motherName"
                value={field.value}
                placeholder="Mother's Name"
                onChange={field.onChange}
                disabled={disabledFormFields.motherName}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Mother's Phone" xs={16} sm={6}>
          <FastField
            name="profile.motherPhone"
            render={({ field }) => (
              <AutoFormattedInput
                type="phone"
                name="profile.motherPhone"
                value={field.value || ''}
                placeholder="000-000-0000"
                onChange={field.onChange}
                disabled={disabledFormFields.motherPhone}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Mother's Email" xs={16} sm={6}>
          <Field
            name="profile.motherEmail"
            render={({ field }) => (
              <Input
                name="profile.motherEmail"
                value={field.value}
                placeholder="Mother's Email"
                onChange={field.onChange}
                disabled={disabledFormFields.motherEmail}
              />
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Notes" xs={16} sm={12}>
          <FastField
            name="profile.motherNote.note"
            render={({ field }) => (
              <TextArea
                name="profile.motherNote.note"
                value={field.value}
                placeholder="Notes"
                onChange={field.onChange}
                disabled={disabledFormFields.motherNotes}
              />
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Father's Name" xs={16} sm={6}>
          <Field
            name="profile.fatherName"
            render={({ field }) => (
              <Input
                name="profile.fatherName"
                value={field.value}
                placeholder="Father's Name"
                onChange={field.onChange}
                disabled={disabledFormFields.fatherName}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Father's Phone" xs={16} sm={6}>
          <FastField
            name="profile.fatherPhone"
            render={({ field }) => (
              <AutoFormattedInput
                type="phone"
                name="profile.fatherPhone"
                value={field.value || ''}
                placeholder="000-000-0000"
                onChange={field.onChange}
                disabled={disabledFormFields.fatherPhone}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Father's Email" xs={16} sm={6}>
          <Field
            name="profile.fatherEmail"
            render={({ field }) => (
              <Input
                name="profile.fatherEmail"
                value={field.value || ''}
                placeholder="Father's Email"
                onChange={field.onChange}
                disabled={disabledFormFields.fatherEmail}
              />
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Notes" xs={16} sm={12}>
          <FastField
            name="profile.fatherNote.note"
            render={({ field }) => (
              <TextArea
                name="profile.fatherNote.note"
                value={field.value}
                placeholder="Notes"
                onChange={field.onChange}
                disabled={disabledFormFields.fatherNotes}
              />
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Other Authorized Guardians" xs={16} sm={12}>
          <Field
            name="profile.otherAuthorizedGuardians"
            render={({ field }) => (
              <Input
                name="profile.otherAuthorizedGuardians"
                value={field.value}
                placeholder="i.e. grandma authroized to bring child for medical visits"
                onChange={field.onChange}
                disabled={disabledFormFields.otherAuthorizedGuardians}
              />
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Consent to Email PHI" xs={16} sm={4}>
          <Field
            name="profile.consentToEmailPHI"
            render={fieldProps => (
              <RadioGroup
                defaultValue={fieldProps.field.value}
                options={yesNoOptions}
                name="profile.consentToEmailPHI"
                {...fieldProps}
                disabled={disabledFormFields.consentToEmailPHI}
              />
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="How did you hear about us?" xs={16} sm={12}>
          <Field
            name="profile.aboutCortica"
            render={({ field }) => (
              <Input
                name="profile.aboutCortica"
                value={field.value}
                placeholder="i.e. Website, referral, etc."
                onChange={field.onChange}
                disabled={disabledFormFields.aboutCortica}
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  );
});
