/* eslint-disable no-constant-condition */
import moment from 'moment';
import {
  IClient,
  iSmartSingleResultForm,
  SingleSmartResultInterface,
  times
} from 'model/v2';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Card } from 'semantic-ui-react';
import SingleResultDescription from './SingleResultDescription';
import { CardWrapperDT } from './Style';
import { getDefaultSingleResultList } from 'utils/mappers/smartSchedule';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SMART_SCHEDULE_EVAL_SELECT_RESULT } from 'utils/validators/smartSchedule';
import SimpleSmartFooter from './SimpleSmartFooter';
import { updateCalendarPref } from 'utils/cache/calendar';
import SingleResultHeader from './SingleResultHeader';
interface IProps {
  appointmentTypeId: number;
  appointmentTypeTitle: string;
  result: any;
  minHours: number;
  offsiteCategory?: string;
  index: number;
  clientId?: number;
  setSelectedResult: Dispatch<
    SetStateAction<SingleSmartResultInterface | undefined>
  >;
  isAba: boolean;
  isDT: boolean;
  hasSingleDT: any;
  client: IClient;
  setVisible: (val: boolean) => void;
  appointmentSubTypeId?: number;
  selectedIndex: number | undefined;
  setSelectedIndex: (val: number | undefined) => void;
  setCurrClient?: Dispatch<SetStateAction<IClient | undefined>>;
  selectedAppointment: string;
  removeUnselectedProviders: () => void;
  setCopyText?: Dispatch<SetStateAction<string | undefined>>;
  copyText?: string;
  setCount?: Dispatch<SetStateAction<number>>;
  count?: number;
}
const SingleResultCard: React.FC<IProps> = ({
  result,
  minHours,
  index,
  clientId,
  isAba,
  isDT,
  hasSingleDT,
  client,
  appointmentSubTypeId,
  setVisible,
  selectedIndex,
  setSelectedIndex,
  // setCurrClient,
  selectedAppointment,
  setSelectedResult,
  removeUnselectedProviders,
  appointmentTypeId,
  setCount,
  count
}: IProps) => {
  const defaultEvalResults: iSmartSingleResultForm = useMemo(() => {
    return getDefaultSingleResultList(result, client?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id, result]);
  const methods = useForm<iSmartSingleResultForm>({
    defaultValues: defaultEvalResults,
    resolver: yupResolver(SMART_SCHEDULE_EVAL_SELECT_RESULT()),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  const { watch } = methods;

  const resultValue = watch('result');

  useEffect(() => {
    if (selectedIndex === index) {
      setSelectedResult(resultValue);
    }
  }, [resultValue.isInClinic, resultValue.isOffsite, resultValue.clinic.id]);
  useEffect(() => {
    if (selectedIndex === index) {
      removeUnselectedProviders();
      setSelectedResult(resultValue);
    }
  }, [resultValue.startTime]);
  const getIntialProviderTime = () => {
    if (hasSingleDT) {
      return result.times[0];
    }
  };
  const [providerTime, setProviderTime] = React.useState<times>(
    getIntialProviderTime()
  );
  const setCardIndex = () => {
    if (selectedIndex !== index) {
      setSelectedResult(resultValue);
      removeUnselectedProviders();
      updateCalendarPref(moment(resultValue.startTime));
      setSelectedIndex(index); // index = 8
    }
  };

  return (
    <>
      <CardWrapperDT onClick={setCardIndex}>
        <FormProvider {...methods}>
          <div>
            <Card
              fluid
              style={{
                border:
                  selectedIndex === index
                    ? '1px solid #6f42f5'
                    : '1px solid #c9cfd3'
              }}
            >
              <Card.Content
                header={
                  <SingleResultHeader
                    result={result}
                    setCount={setCount}
                    count={count}
                    providerTime={providerTime}
                    selectedIndex={selectedIndex!}
                    index={index}
                    isMakeupSession={true}
                  />
                }
              />
              <Card.Content
                description={
                  <SingleResultDescription
                    result={result}
                    isCheckedCard={false}
                    index={index}
                    minHours={minHours}
                    clientId={clientId}
                    isAba={isAba}
                    isDT={isDT}
                    hasSingleDT={hasSingleDT}
                    selectedIndex={selectedIndex}
                    selectedAppointment={selectedAppointment}
                    setProviderTime={setProviderTime}
                    providerTime={providerTime}
                  />
                }
              />
              <SimpleSmartFooter
                appointmentSubTypeId={appointmentSubTypeId}
                setVisible={setVisible}
                selectedIndex={selectedIndex}
                index={index}
                telehealthClinic={result.telehealthClinic}
                setSelectedIndex={setSelectedIndex}
                appointmentTypeId={appointmentTypeId}
              ></SimpleSmartFooter>
            </Card>
          </div>
        </FormProvider>
      </CardWrapperDT>
    </>
  );
};
export default React.memo(SingleResultCard);
