import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Table, Button } from 'lib/ui';
import ExpandableTable from './ExpandableTable';
import { AuthorizedTherapiesStyles } from '../profile.style';
import { useQuery } from '@apollo/react-hooks';
import { CLIENT_AUTHORIZED_THERAPIES } from 'api/graphql/queries/authorizations';
import Content from 'views/components/ui/content';
import { formatAuthData } from 'utils/mappers/response/authorizations';

const AuthorizedTherapies = ({
  client,
  columns,
  expandableColumns,
  editable,
  expandedRowByClick
}) => {
  const [isExpandedRowKeys, setExpandedRowKeys] = useState([]);
  const [authorizedTherapies, setAuthorizedTherapies] = useState([]);

  const history = useHistory();

  //TODO: USE v2 query and replace with id
  const { loading, data } = useQuery(CLIENT_AUTHORIZED_THERAPIES, {
    variables: {
      clientID: client.id
    },
    skip: isEmpty(client?.id),
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setAuthorizedTherapies(formatAuthData(data));
    }
  });

  //TODO: replace with id
  const onAddClicked = e => {
    e.preventDefault();
    history.push({
      pathname: `/clients/${client.id || client.id}/auth-groups/new`,
      state: {
        client
      }
    });
  };

  //TODO: replace with id
  const onEditClicked = (e, record) => {
    e.preventDefault();
    history.push({
      pathname: `/clients/${client.id}/auth-groups/${record.authGroupID}/edit`,
      state: {
        client,
        authGroupID: record.authGroupID
      }
    });
  };

  const customizeAuthorizedTherapiesView = () =>
    authorizedTherapies.map(groupedAuth => ({
      ...groupedAuth,
      description: (
        <ExpandableTable
          columns={expandableColumns}
          values={groupedAuth.authAppointmentTypes}
        />
      )
    }));

  const customExpandIcon = props => {
    if (props.expanded) {
      return <DownOutlined onClick={e => props.onExpand(props.record, e)} />;
    } else {
      return (
        <RightOutlined
          onClick={e =>
            props.record.description && props.onExpand(props.record, e)
          }
        />
      );
    }
  };

  const handleExpanded = (expanded, { authGroupID, description }) => {
    if (expanded) {
      return description
        ? setExpandedRowKeys([...isExpandedRowKeys, authGroupID])
        : null;
    } else {
      return setExpandedRowKeys(prev =>
        prev.filter(item => item !== authGroupID)
      );
    }
  };

  return (
    <AuthorizedTherapiesStyles
      isExpandedRowByClick={expandedRowByClick}
      className="profile_table"
    >
      {editable && (
        <div className="profile_table__title">
          <h1>Authorized Therapies</h1>
          <Button
            className="button-outline medium edit-button"
            onClick={onAddClicked}
          >
            Add Auth Group
          </Button>
        </div>
      )}
      <div className="profile_table__container">
        <Content loading={loading} data={data}>
          {() =>
            authorizedTherapies.length > 0 ? (
              expandedRowByClick ? (
                <Table
                  columns={columns}
                  dataSource={customizeAuthorizedTherapiesView()}
                  expandable={{
                    expandedRowRender: record => record.description,
                    expandIcon: props => customExpandIcon(props)
                  }}
                  expandedRowKeys={isExpandedRowKeys}
                  rowKey={record => record.authGroupID}
                  pagination={false}
                  bordered
                  expandRowByClick={expandedRowByClick}
                  onExpand={handleExpanded}
                />
              ) : (
                <Table
                  columns={columns(onEditClicked)}
                  dataSource={customizeAuthorizedTherapiesView()}
                  expandable={{
                    expandedRowRender: record => record.description,
                    expandIcon: props => customExpandIcon(props)
                  }}
                  rowKey={record => record.authGroupID}
                  pagination={false}
                  bordered
                />
              )
            ) : (
              <p>Select “Add Auth Group” to Add Authorized Therapies</p>
            )
          }
        </Content>
      </div>
    </AuthorizedTherapiesStyles>
  );
};

export default AuthorizedTherapies;
