import { GET_CLIENT_ADDRESSES } from 'api/graphql/v2/queries/Clients';
import SelectList from 'api/sharedComponents/reactHookFormComponents/semantic/SelectList';
import { IAddress, IClinic } from 'model/v2';
import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { Controller, useFormContext } from 'react-hook-form';
import { DropdownProps } from 'semantic-ui-react';
import { CustomLocationListWrapper } from './Style';
import { EventSelectOptions } from 'model/calendar/filters';
// import SmartSchedulingTelehealthInput from './SmartSchedulingTelehealthInput';
import TelehealthInput from '../Client/TelehealthInput';

interface IProps {
  isInClinic?: boolean;
  isOffsite?: boolean;
  isTelehealth?: boolean;
  clinic?: IClinic;
  index: number;
  clientId?: number;
  hasSingleDT?: boolean;
  selectedIndex: number | undefined;
  telehealthLinks?: string[];
  eventAddressesOptions?: EventSelectOptions[];
}
const SingleResultLocationList: React.FC<IProps> = ({
  isInClinic,
  isOffsite,
  isTelehealth,
  clientId,
  selectedIndex,
  index,
  telehealthLinks,
  eventAddressesOptions
}: IProps) => {
  const { data: addressesData } = useQuery(GET_CLIENT_ADDRESSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      clientId
    },
    skip: isTelehealth || isInClinic || !clientId
  });

  const clientAddressesOptions = useMemo(() => {
    return (addressesData?.clientAddresses as IAddress[])?.map(it => ({
      text: `${it.addressLine1 || ''},${it.addressLine2 || ''},${it.city ||
        ''},${it.state || ''},${it.zipCode || ''}`,
      value: {
        id: it.id,
        addressLine1: it.addressLine1,
        addressLine2: it.addressLine2,
        city: it.city,
        state: it.state,
        zipCode: it.zipCode,
        name: it.name,
        country: it.country
      },
      key: it.id
    }));
  }, [addressesData]);

  const { control, errors, setValue } = useFormContext();

  return (
    <CustomLocationListWrapper>
      {isOffsite && (
        <>
          {eventAddressesOptions === undefined ? (
            <>
              <Controller
                name={'address'}
                control={control}
                render={props => (
                  <SelectList
                    field={props}
                    errors={errors}
                    options={clientAddressesOptions}
                    disabled={selectedIndex !== index}
                    placeholder="Select Address"
                    loading={addressesData === undefined}
                    onChange={(_view: any, data: DropdownProps) => {
                      setValue(`address`, data.value);
                      setValue(`result.address`, data.value);
                    }}
                  />
                )}
              />
            </>
          ) : (
            <>
              <Controller
                name={'address'}
                render={props => (
                  <SelectList
                    field={props}
                    defaultValue={eventAddressesOptions[0].value}
                    options={eventAddressesOptions}
                    placeholder="Select Address"
                    disabled={selectedIndex !== index}
                  />
                )}
              />
            </>
          )}
        </>
      )}
      {isTelehealth && (
        <TelehealthInput
          type="smart"
          index={'result.telehealthLink'}
          telehealthLinks={telehealthLinks}
        />
      )}
    </CustomLocationListWrapper>
  );
};
export default React.memo(SingleResultLocationList);
