import { convertToDate } from 'utils/format';
import { YES_NO_LIST } from 'utils/constants/lists';
import { cloneWithoutTypename } from 'utils/common';
export const getClientFormData = client => {
  let editedClient = {
    ...client,
    clinic: {
      id: client.clinic.id
    },
    usualProvider: {
      id: client.usualProvider.id
    },
    registrationDate:
      client.registrationDate && client.registrationDate.isValid()
        ? convertToDate(client.registrationDate)
        : null,
    profile: {
      ...client.profile,
      dob: convertToDate(client?.profile?.dob),
      subscriberDOB:
        client.profile?.subscriberDOB && client.profile?.subscriberDOB.isValid()
          ? convertToDate(client.profile.subscriberDOB)
          : null,
      noticesOnFile: YES_NO_LIST[1 - +client.profile?.noticesOnFile].value
    }
  };
  editedClient = cloneWithoutTypename(editedClient);

  return editedClient;
};
