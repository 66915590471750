import React, { useCallback, useMemo } from 'react';
import { IRoom, roomsTypeList } from 'model/v2';
import {
  useForm,
  FormProvider,
  Controller,
  ControllerRenderProps
} from 'react-hook-form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { Input, Select } from 'api/sharedComponents/reactHookFormComponents';
import MainButton from 'views/components/button';
import { Checkbox } from 'semantic-ui-react';
import { mapToOptions } from 'utils/mappers/form';
import { ContentWrapper } from './style';
import { ROOMS_FORM_SCHEMA } from 'utils/validators/configurations/roomForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-apollo';
import { ADD_NEW_ROOM } from 'api/graphql/v2/mutations/Clinics';

interface IProps {
  room: IRoom;
  onCloseForm: () => void;
  onAddRoom: (room: IRoom) => void;
}

const EditRoomForm: React.FC<IProps> = ({
  room,
  onCloseForm,
  onAddRoom
}: IProps) => {
  const methods = useForm<IRoom>({
    defaultValues: room,
    resolver: yupResolver(ROOMS_FORM_SCHEMA)
  });
  const {
    handleSubmit,
    errors,
    setValue,
    formState: { isSubmitting, isDirty }
  } = methods;

  const [addRoom] = useMutation(ADD_NEW_ROOM);
  const onSubmit = handleSubmit(async (data: IRoom) => {
    if (!data.clinic?.id) {
      onAddRoom(data);
      onCloseForm();
    } else {
      await addRoom({
        variables: {
          room: {
            ...data,
            id: Number(data.id),
            clinic: { id: Number(data.clinic?.id) }
          }
        }
      })
        .then(result => {
          onAddRoom(result?.data?.addRoom);
          onCloseForm();
        })
        .catch((error: any) => {
          console.log('Error while saving room', JSON.stringify(error));
        });
    }
  });
  const setCheckBoxFormValue = useCallback(
    (name, value: boolean) => {
      setValue(name, value, { shouldDirty: true });
    },
    [setValue]
  );
  const roomTypeOptions = useMemo(() => {
    return mapToOptions(roomsTypeList);
  }, []);

  return (
    <FormProvider {...methods}>
      <ContentWrapper>
        <FormItem label={'Room Name'}>
          <Controller
            name={'name'}
            render={(fieldProps: ControllerRenderProps<IRoom>) => (
              <Input field={fieldProps} errors={errors} />
            )}
          />
        </FormItem>

        {room?.id && (
          <FormItem label={'ID'}>
            <Controller
              name={'id'}
              render={(fieldProps: ControllerRenderProps<IRoom>) => (
                <Input field={fieldProps} errors={errors} disabled />
              )}
            />
          </FormItem>
        )}

        {room?.clinic?.id && (
          <FormItem label={'Clinic ID'}>
            <Controller
              name={'clinic.id'}
              render={(fieldProps: ControllerRenderProps<IRoom>) => (
                <Input field={fieldProps} errors={errors} disabled />
              )}
            />
          </FormItem>
        )}
        <FormItem label="Room Type">
          <Controller
            name={'type'}
            render={(fieldProps: ControllerRenderProps<IRoom>) => (
              <Select
                options={roomTypeOptions}
                field={fieldProps}
                errors={errors}
              />
            )}
          />
        </FormItem>
        <label className={'label'}> {'Amenities'} </label>
        <FormItem label={'Capacity / Seats'}>
          <Controller
            name={'seats'}
            render={(fieldProps: ControllerRenderProps<IRoom>) => (
              <Input field={fieldProps} errors={errors} />
            )}
          />
        </FormItem>

        <div className={'check-box-grooup'}>
          <Controller
            render={() => (
              <Checkbox
                label={'Has TV'}
                defaultChecked={room.hasTV}
                onChange={(_e, data) => {
                  setCheckBoxFormValue('hasTV', data.checked || false);
                }}
              />
            )}
            name={'hasTV'}
          />
          <Controller
            render={() => (
              <Checkbox
                label={'Has Instruments'}
                defaultChecked={room.hasInstruments}
                onChange={(_e, data) => {
                  setCheckBoxFormValue('hasInstruments', data.checked || false);
                }}
              />
            )}
            name={'hasInstruments'}
          />
        </div>
        <div className="save-btn">
          <MainButton
            title={'OK'}
            onClick={onSubmit}
            disabled={isSubmitting || !isDirty}
            loading={isSubmitting}
          />
        </div>
      </ContentWrapper>
    </FormProvider>
  );
};
export default React.memo(EditRoomForm);
