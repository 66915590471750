import React, { useMemo, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Row } from 'antd';
import { FormItem } from 'views/containers/form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { getSubOptions } from 'utils/mappers/response/events';
import { getApptType } from 'utils/common';
import { mapToAppointmentOption } from 'utils/mappers/form';
import { useAppointmentTypes } from '../../newAppointmentHooks';
import { DEFAULT_DURATION } from 'utils/constants/default';

const AppointmentTypeForm = ({ disabledFormFields }) => {
  const { control, setValue } = useFormContext();
  const { apptTypes } = useAppointmentTypes();
  const formApptType = useWatch({ name: 'appType', control });

  const apptOptionsList = useMemo(() => mapToAppointmentOption(apptTypes), [
    apptTypes
  ]);

  const [apt, appointmentSubOptions, subTypesOptionList] = useMemo(() => {
    const apt = getApptType({ title: formApptType }, apptTypes);
    const subOptions = getSubOptions(formApptType, apptTypes);
    return [apt, subOptions, mapToAppointmentOption(subOptions)];
  }, [formApptType, apptTypes]);

  const appointmentTypeHandler = useCallback(
    apptType => {
      const apt = getApptType({ title: apptType }, apptTypes);
      setValue('appSubType', null);
      setValue('provider', null);
      setValue('selectedSlot', null);
      !apt.isClinical && setValue('duration', DEFAULT_DURATION.ABA); // duration defaults to 2 hrs in case of ABA appointments
      setValue('isClinical', apt.isClinical);
    },
    [setValue, apptTypes]
  );

  const appointmentSubTypeHandler = useCallback(
    appSubType => {
      const apt = getApptType({ title: appSubType }, appointmentSubOptions);
      setValue('duration', parseInt(apt.duration));
    },
    [setValue, appointmentSubOptions]
  );

  return (
    <>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem label="Appointment Type" xs={16} sm={12}>
          <Controller
            name="appType"
            control={control}
            render={fieldProps => (
              <>
                <Select
                  placeholder="Select Appointment Type"
                  disabled={disabledFormFields?.appType}
                  options={apptOptionsList}
                  field={fieldProps}
                  onSelect={appointmentTypeHandler}
                />
              </>
            )}
          />
        </FormItem>
        <FormItem label="Appointment Sub-Type" xs={16} sm={12}>
          <Controller
            name="appSubType"
            control={control}
            render={fieldProps => (
              <Select
                disabled={!apt?.isClinical || disabledFormFields.appSubType}
                placeholder="Select Appointment Sub-Type"
                options={subTypesOptionList}
                field={fieldProps}
                onSelect={appointmentSubTypeHandler}
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  );
};

export default React.memo(AppointmentTypeForm);
