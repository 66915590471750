import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import {
  ICalendarFilterOption,
  ISavedFilterOptions
} from 'model/calendar/filters';
import { Divider } from 'antd';
import { FilterPageNameEnum } from 'components/calendar/calendarToolbar/FilterTagList/form/utils';

interface Props {
  filters: ICalendarFilterOption[];
  selectSavedFilter: (name: string) => void;
  savedFilter: string;
  defaultFilter?: ISavedFilterOptions;
  handleSearchedValues: (
    list: ICalendarFilterOption[],
    checkedList: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  setHasData: Dispatch<SetStateAction<boolean>>;
  setClickedFilter: (label: string) => void;
  pageName: string;
}

const SavedFilters = ({
  filters,
  selectSavedFilter,
  savedFilter,
  defaultFilter,
  handleSearchedValues,
  setHasData,
  setClickedFilter,
  pageName
}: Props) => {
  const [checkedFilter, setCheckedFilter] = useState<string>(savedFilter);

  const savedFilters = useMemo(() => {
    const searchedValues = handleSearchedValues(filters, [
      { label: checkedFilter, value: checkedFilter }
    ]);

    return searchedValues;
  }, [handleSearchedValues, filters, checkedFilter]);

  const handleChange = (
    _e: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    selectSavedFilter(data.label as string);
    setCheckedFilter(data.label as string);
  };

  useEffect(() => {
    savedFilters?.length === 0 ? setHasData(false) : setHasData(true);
  }, [savedFilters, setHasData]);

  useEffect(() => {
    setCheckedFilter(savedFilter);
  }, [savedFilter]);

  return (
    <>
      {savedFilters?.length > 0 && (
        <>
          <div className="title">Saved</div>
          {savedFilters.map(filter => {
            return (
              <div className="saved_filters" key={filter.label}>
                <Checkbox
                  className="saved-filters_checkbox"
                  label={filter.label}
                  name={filter.label}
                  value={filter.value}
                  checked={checkedFilter === filter.label}
                  onChange={handleChange}
                />
                <button
                  className="edit_btn"
                  onClick={() => {
                    setClickedFilter(filter.label);
                  }}
                >
                  {defaultFilter?.name === filter.label &&
                  pageName === FilterPageNameEnum.planner
                    ? 'Edit Default'
                    : 'Edit'}
                </button>
              </div>
            );
          })}
          <Divider />
        </>
      )}
    </>
  );
};
export default React.memo(SavedFilters);
