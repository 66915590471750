import React from 'react';
import * as PropTypes from 'prop-types';
import { CaretDownOutlined } from '@ant-design/icons';
import { LibSelect, LibOption } from './select.style';

const Select = ({ options, ...restProps }) => {
  return (
    <LibSelect suffixIcon={<CaretDownOutlined />} {...restProps}>
      {options &&
        options.map(({ label, value }) => (
          <LibOption key={value} value={value}>
            {label}
          </LibOption>
        ))}
    </LibSelect>
  );
};

Select.propTypes = {
  options: PropTypes.array
};

Select.defaultProps = {
  options: []
};

export default Select;
export { Select };
