import gql from 'graphql-tag';

export const GET_WAITLIST = gql`
  query($from: Int, $size: Int, $search: [JSONObject], $sort: [JSONObject]) {
    waitlist(from: $from, size: $size, search: $search, sort: $sort) {
      data {
        id
        appointmentTypeID
        appointmentSubType
        isClinical
        clientAvailability
        clientAvailabilityFrequency
        clientID
        clientName
        clinicID
        date
        isRecommendedTherapy
        quantityPattern
        minRecommendedQuantity
        maxRecommendedQuantity
        requestedQuantity
        toFulfill
        ideal
        authorized
        receiving
        waiting
        status
        canSchedule
      }
      count
    }
    appointmentTypes {
      id
      title
      isClinical
      subTypes {
        id
        title
        duration
      }
    }
    appointmentABATypes {
      id
      title
      isClinical
      subTypes {
        id
        title
        duration
      }
    }
    clientAvailabilityStatusOptions {
      id
      value
    }
  }
`;

export const GET_CLIENT_WAITLIST = gql`
  query($clientID: String) {
    clientWaitlist(clientID: $clientID) {
      id
      appointmentTypeID
      appointmentSubType
      isClinical
      clientAvailability
      clientID
      clientName
      clinicID
      date
      isRecommendedTherapy
      quantityPattern
      minRecommendedQuantity
      maxRecommendedQuantity
      requestedQuantity
      toFulfill
      ideal
      authorized
      receiving
      waiting
      status
      waitlistWeekCount
      waitlistEndDate
    }
    appointmentTypes {
      id
      title
      isClinical
      subTypes {
        id
        title
      }
    }
    appointmentABATypes {
      id
      title
      isClinical
      subTypes {
        id
        title
      }
    }
  }
`;
