import React from 'react';
import { Button } from 'lib/ui';
import { ModalWarningWrapper } from '../appointment/NewAppointment.style';

const ModalWarning = ({
  visible,
  setVisible,
  leavePage,
  destroyOnClose,
  width
}) => {
  return (
    <ModalWarningWrapper
      visible={visible}
      destroyOnClose={destroyOnClose}
      onCancel={() => setVisible(false)}
      closeIcon={<Button className="edit-button-2">Cancel</Button>}
      width={width}
      title=""
      footer=""
    >
      <h2>Confirm Navigation</h2>
      <p>
        Changes you made will not be saved. Are you sure you want to leave this
        page?
      </p>
      <div className="button-wrapper">
        <Button onClick={leavePage} className="button-fill large">
          Leave this Page
        </Button>
      </div>
    </ModalWarningWrapper>
  );
};

export default ModalWarning;
