import { IAppointmentType, IClient, IEvent, IStaff } from 'model';
import { Status } from 'model/calendar/events';
import { EVENT_STATUS } from 'utils/constants/status';

export const isRecurrent = (recurringEvery?: number) => {
  return !!recurringEvery ? 'Scheduled - Recurring' : 'Scheduled - One Time';
};
export const getStatus = (
  status: Status | undefined,
  recurringEvery?: number,
  isPendingConfirmation?: boolean
) => {
  if (status === EVENT_STATUS.canceled || status === EVENT_STATUS.deleted) {
    return status;
  }

  if (isPendingConfirmation) return 'invisible' as Status;

  return status === EVENT_STATUS.scheduled
    ? isRecurrent(recurringEvery)
    : status;
};

export const getAppointmentId = (appointment?: IAppointmentType) =>
  `${appointment?.id}`;

export const getEventNewSubject = (
  event: IEvent,
  newProvider: IStaff,
  client: IClient,
  apptTypesMap: Map<string, any>
) =>
  `${apptTypesMap.get(getAppointmentId(event.appointmentType))?.title} - ${
    client?.name
  } & ${newProvider?.name}`;

export const getAdminEventNewSubject = (
  event: IEvent,
  newProvider: IStaff,
  apptTypesMap: Map<string, any>
) =>
  `${apptTypesMap.get(getAppointmentId(event.appointmentType))?.title} - ${
    newProvider.name
  }`;
