import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import {
  ICalendarFilterData,
  ICalendarFilterOption
} from 'model/calendar/filters';
import { SPECIALITY_FILTER } from 'utils/constants/calendarFilters';
import { mapToCheckListOptions, mapToOptions } from 'utils/mappers/form';
import CalendarFilter from '../../../ui/calendarFilter';

interface Props {
  data?: ICalendarFilterData;
  handleSearchedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  checkedSpecialities: ICalendarFilterOption[];
  checkedDepartments: ICalendarFilterOption[];
  setCheckedDepartments: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  setCheckedSpecialities: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  setHasData: Dispatch<SetStateAction<boolean>>;
  searchParams?: URLSearchParams;
  updateHistory?: () => void;
}

const DepartmentFilter: React.FC<Props> = ({
  data,
  handleSearchedValues,
  checkedSpecialities,
  checkedDepartments,
  setCheckedDepartments,
  setCheckedSpecialities,
  setHasData,
  searchParams,
  updateHistory
}: Props) => {
  const departments: ICalendarFilterOption[] = useMemo(
    () =>
      data?.specialtiesDepartment
        ? mapToOptions(data.specialtiesDepartment)
        : [],
    [data]
  );
  const searchedDepartments = useMemo(() => {
    return handleSearchedValues(departments);
  }, [handleSearchedValues, departments]);

  const [specialities, departmentSpecialities] = useMemo(() => {
    const departmentSpecialities: Map<
      string,
      ICalendarFilterOption[]
    > = new Map();
    let specialities: ICalendarFilterOption[] = [];

    data?.specialties!.forEach(speciality => {
      const mappedSpeciality = mapToCheckListOptions(
        [speciality],
        SPECIALITY_FILTER.id,
        SPECIALITY_FILTER.value
      )[0];

      if (departmentSpecialities.has(speciality?.department)) {
        departmentSpecialities
          .get(speciality.department)
          ?.push(mappedSpeciality);
      } else {
        departmentSpecialities.set(speciality.department, [mappedSpeciality]);
      }
      specialities.push(mappedSpeciality);
    });
    let ordered_Specalities: ICalendarFilterOption[] = specialities.sort(
      (a: ICalendarFilterOption, b: ICalendarFilterOption) => {
        let sortedA = a.label.toLowerCase();
        let sortedB = b.label.toLowerCase();
        return sortedA.localeCompare(sortedB);
      }
    );
    specialities = ordered_Specalities;

    return [specialities, departmentSpecialities];
  }, [data]);

  const searchedSpecialities = useMemo(() => {
    return handleSearchedValues(specialities);
  }, [handleSearchedValues, specialities]);

  const notChecked = useCallback(
    (list: ICalendarFilterOption[], option: string): boolean => {
      return (
        list?.filter(listOption => listOption.value === option).length === 0
      );
    },
    []
  );

  const onDepartmentChange = useCallback(
    (
      checkedValues: CheckboxValueType[],
      allDepartmentsChecked: boolean = false
    ) => {
      //All departments are selected set checked specialities with all options
      if (allDepartmentsChecked) {
        let specialitiesToCheck: ICalendarFilterOption[] = [];
        for (const specialities of departmentSpecialities.values()) {
          specialitiesToCheck = specialitiesToCheck.concat(
            specialities as ICalendarFilterOption[]
          );
        }
        setCheckedSpecialities(specialitiesToCheck);
      } else {
        //Check the new checked departments and add their specialities
        checkedValues.forEach(value => {
          const specialitiesInCheckedDepartment = departmentSpecialities
            .get(value as string)
            ?.map(s => s.value);

          const wasNotChecked = notChecked(checkedDepartments, value as string);

          if (checkedValues.includes(value) && wasNotChecked) {
            const filteredCheckedSpecialities = checkedSpecialities.filter(
              s => !specialitiesInCheckedDepartment?.includes(s.value)
            );

            setCheckedSpecialities(
              filteredCheckedSpecialities.concat(
                departmentSpecialities.get(value as string) || []
              )
            );
          }
        });
      }
    },
    [
      checkedDepartments,
      departmentSpecialities,
      checkedSpecialities,
      setCheckedSpecialities,
      notChecked
    ]
  );

  const onSpecialityChange = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      const departmentsToCheck = [];
      for (const department of departmentSpecialities.keys()) {
        const specialties = departmentSpecialities.get(department);
        const allChecked = specialties?.reduce(function(
          result: boolean,
          item: ICalendarFilterOption
        ) {
          return result && checkedValues.includes(item.value);
        },
        true);
        if (
          allChecked &&
          notChecked(checkedDepartments, department as string)
        ) {
          departmentsToCheck.push({ value: department, label: department });
        }
      }
      setCheckedDepartments(checkedDepartments.concat(departmentsToCheck));
    },
    [
      checkedDepartments,
      departmentSpecialities,
      setCheckedDepartments,
      notChecked
    ]
  );
  useEffect(() => {
    searchedDepartments.length || searchedSpecialities.length
      ? setHasData(true)
      : setHasData(false);
  }, [searchedDepartments, searchedSpecialities, setHasData]);

  return (
    <>
      <CalendarFilter
        setCheckedList={setCheckedDepartments}
        checkedList={checkedDepartments}
        listOptions={searchedDepartments}
        length={departments.length}
        title="Department"
        handleChange={onDepartmentChange}
        searchParams={searchParams}
        updateHistory={updateHistory}
      />

      <CalendarFilter
        setCheckedList={setCheckedSpecialities}
        checkedList={checkedSpecialities}
        listOptions={searchedSpecialities}
        length={specialities.length}
        title="Speciality"
        handleChange={onSpecialityChange}
        searchParams={searchParams}
        updateHistory={updateHistory}
      />
    </>
  );
};

export default React.memo(DepartmentFilter);
