import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onClick?:
    | ((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void)
    | undefined;
  className?: string;
  size?: FontAwesomeIconProps['size'];
  disabled?: boolean;
}
const CaretRightIcon: React.FC<Props> = ({
  onClick,
  className,
  size,
  disabled
}: Props) => (
  <FontAwesomeIcon
    size={size || '1x'}
    className={disabled ? className + ' disabled' : className}
    icon={faCaretRight}
    onClick={onClick}
  />
);

export default React.memo(CaretRightIcon);
