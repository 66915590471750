import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';
import { appointmentTypesFragments } from '../appointment-types';

const clientFragment = gql`
  fragment Client on Client {
    id
    name
    email
  }
`;

const therapistFragment = gql`
  fragment Therapist on User {
    id
    name
    email
  }
`;

export const eventsFragments = {
  eventData: gql`
    fragment EventData on Event {
      id
      masterID
      subject
      startTime
      endTime
      duration
      status
      notes
      telehealthLink
      locationType
      locationCategory
      locationStreet
      clinic {
        id
      }
      appointmentType {
        id
        isClinical
        subType
      }
      therapist {
        ...Therapist
      }
      room {
        id
        email
        roomName
      }
      authorizedTherapyID
      recurringEvery
      recurringUntil
      type
      clinicPreference
      paymentMethod
      originalStartDate
      originalEndDate
      locationDetails {
        id
        addressLine1
        addressLine2
        city
        state
        name
        zipCode
        country
        __typename @skip(if: true)
      }
      audit {
        action
        actionBy
        actionAt
        reason
        note
      }
    }
    ${therapistFragment}
  `,
  calendarEventData: gql`
    fragment CalendarEventData on CalendarEvent {
      id
      subject
      startTime
      endTime
      status
      locationType
      masterID
      locationCategory
      recurringEvery
      crStatus
      athenaStatus
      isPendingConfirmation
      appointmentType {
        id
        isClinical
        subType
        __typename @skip(if: true)
      }
      clinic {
        id
        __typename @skip(if: true)
      }
      therapist {
        id
        name
        __typename @skip(if: true)
      }
      room {
        id
        roomName
        __typename @skip(if: true)
      }
      type
      __typename @skip(if: true)
    }
  `,
  conflictData: gql`
    fragment ConflictData on Event {
      id
      startTime
      endTime
      client {
        ...Client
      }
      therapist {
        ...Therapist
      }
    }
    ${clientFragment}
    ${therapistFragment}
  `,
  instanceData: gql`
    fragment InstanceData on Event {
      id
      startTime
      endTime
      client {
        ...Client
      }
      therapist {
        ...Therapist
      }
      conflicts {
        id
        startTime
        endTime
        client {
          ...Client
        }
        therapist {
          ...Therapist
        }
      }
    }
    ${clientFragment}
    ${therapistFragment}
  `,
  workingHours: gql`
    fragment UserWorkingHours on UserWorkingHours {
      userId
      scheduleId
      workingHours {
        sun {
          startTime
          endTime
        }
        mon {
          startTime
          endTime
        }
        tues {
          startTime
          endTime
        }
        wed {
          startTime
          endTime
        }
        thurs {
          startTime
          endTime
        }
        fri {
          startTime
          endTime
        }
        sat {
          startTime
          endTime
        }
      }
    }
  `
};

export const GET_FULL_EVENT = gql`
  query($id: ID!) {
    fullEvent(id: $id) {
      event {
        ...EventData
        client {
          ...Client
        }
        crStatus
        athenaStatus
        isConverted
        isPendingConfirmation
      }
      client {
        id
        dob
        clinicId
        name
        lastName
        firstName
        firstNameUsed
        dob
        legalSex
        gender
        phenoType
      }
      provider {
        id
        name
        firstName
        lastName
        displayName
        speciality
        mobilePhone
        email
        clinicId
      }
      appointmentType {
        ...AppointmentTypeTableData
      }
      appointmentSubType {
        id
        title
        duration
      }
      room {
        id
        email
        roomName
        seats
        hasTV
        hasInstruments
        hasPhone
        roomType
        clinicID
      }
      isLastEvent
    }
  }
  ${appointmentTypesFragments.appointmentTypesTableData}
  ${eventsFragments.eventData}
  ${clientFragment}
`;

export const GET_PROVIDER_CALENDAR_EVENTS = gql`
  query($providerID: ID!, $startTime: DateTime!, $endTime: DateTime!) {
    providerEvents(
      providerID: $providerID
      startTime: $startTime
      endTime: $endTime
    ) {
      ...CalendarEventData
      client {
        id
        name
        __typename @skip(if: true)
      }
    }
  }
  ${eventsFragments.calendarEventData}
`;

export const GET_CALENDAR_EVENTS = gql`
  query(
    $startTime: String!
    $view: String!
  ) {
    cardEvents(
      startTime: $startTime
      view: $view
      timeZone: "${TIMEZONE}"
    ) {
      ...CalendarEventData
      client { 
        id
        name
        __typename @skip(if: true)
      }
    }
    appointmentTypes {
      ...AppointmentTypeData
    }
    appointmentABATypes {
      ...AppointmentTypeData
    }
    adminAppointmentTypes {
      id
      value
    }
  }
  ${eventsFragments.calendarEventData}
  ${appointmentTypesFragments.appointmentTypesData}
  `;

export const GET_WORKING_HOURS = gql`
  query($users: [UserObj]) {
    usersWorkingHours(users: $users) {
      ...UserWorkingHours
    }
  }
  ${eventsFragments.workingHours}
`;

//old appt form
export const ADD_EVENT = gql`
  mutation($event: EventInput!) {
    createEvent(event: $event, timeZone: "${TIMEZONE}") {
      ...EventData
      client {
        ...Client
      }
      conflicts {
        ...ConflictData
      }
      instances {
        ...InstanceData
      }
    }
  }
  ${eventsFragments.eventData}
  ${clientFragment}
  ${eventsFragments.conflictData}
  ${eventsFragments.instanceData}
`;

//old appt form
export const UPDATE_EVENT = gql`
  mutation(
    $id: ID!
    $recurrent: Boolean!
    $event: EventInput!
    $cancelReasonId: ID!
    $cancelNote: String
  ) {
    updateEvent(
      id: $id
      recurrent: $recurrent
      event: $event
      cancelReasonId: $cancelReasonId
      cancelNote: $cancelNote
      timeZone: "${TIMEZONE}"
    ) {
      ...CalendarEventData
      client { 
        id
        name
        __typename @skip(if: true)
      }
    }
  }
  ${eventsFragments.calendarEventData}
`;

//old appt form

export const EDIT_EVENT = gql`
  mutation(
    $id: ID!
    $recurrent: Boolean!
    $event: EventInput!
  ) {
    editEvent(
      id: $id
      recurrent: $recurrent
      event: $event
      timeZone: "${TIMEZONE}"
    ) {
      ...EventData
      client {
        ...Client
      }
      conflicts {
        ...ConflictData
      }
      instances {
        ...InstanceData
      }
    }
  }
  ${eventsFragments.eventData}
  ${clientFragment}
  ${eventsFragments.conflictData}
  ${eventsFragments.instanceData}
`;

//modalwrapper use this
export const IS_LAST_EVENT = gql`
  query($id: String!, $masterID: String!) {
    isLastEventInSeries(id: $id, masterID: $masterID)
  }
`;

//old appt form
export const GET_APPOINTMENT_CONFLICTS_AND_AUTHORIZATIONS = gql`
  query(
    $userId: String
    $reschedule: Boolean
    $timeZone: String!
    $clientID: String!
    $recurring: Boolean!
    $event: EventAuthWarningInput!
  ) {
    getEventConflictsAndWarnings(
      userId: $userId
      reschedule: $reschedule
      timeZone: $timeZone
      clientID: $clientID
      recurring: $recurring
      event: $event
    ) {
      eventsCount
      conflicts {
        startTime
        endTime
      }
      warnings {
        startDate
        authorizedTherapyID
      }
    }
  }
`;
