import React from 'react';
import * as PropTypes from 'prop-types';
import { CaretDownOutlined } from '@ant-design/icons';
import { LibSelect, LibOption } from './selectWithDelete.style';

const SelectWithDelete = ({ options, ...restProps }) => {
  return (
    <>
      <LibSelect suffixIcon={<CaretDownOutlined />} {...restProps}>
        {options &&
          options.map(({ label, value, displayName }) => (
            <LibOption key={value} value={value} displayName={displayName}>
              {label}
            </LibOption>
          ))}
      </LibSelect>
    </>
  );
};

SelectWithDelete.propTypes = {
  options: PropTypes.array
};

SelectWithDelete.defaultProps = {
  options: []
};

export default SelectWithDelete;
export { SelectWithDelete };
