import * as yup from 'yup';
import { MEDAppointmentSubType } from 'model/MEDAppointmentSubtype';
import { Lookup } from 'model/lookup';

export const MED_APPOINTMENT_SUBTYPE_SCHEMA = () =>
  yup.object().shape({
    [MEDAppointmentSubType.title]: yup.string().required('Title is required'),
    [MEDAppointmentSubType.athenaId]: yup
      .string()
      .required('Athena ID is required'),
    [MEDAppointmentSubType.crName]: yup
      .string()
      .required('CR Name is required'),
    [MEDAppointmentSubType.crType]: yup
      .string()
      .required('CR Type is required'),
    [MEDAppointmentSubType.superType]: yup.object().shape({
      [Lookup.id]: yup.string().required('Parent Type is required')
    }),
    [MEDAppointmentSubType.duration]: yup
      .number()
      .typeError('duration must be greater than or equal to 15')
      .required('duration is required')
      .min(15)
  });
