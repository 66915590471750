import styled from 'styled-components';

export const TitleWrapper = styled.div`
  min-height: 73px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8ecef;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 16px 50px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  h1 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.38px;
  }
  .main_btn_wrapper .ui.inverted.purple.button {
    width: 238px;
    height: 48px;
  }
  .tz-alert {
    color: red;
  }
  .providerName {
    font-size: 22px;
    line-height: 14px;
    margin: 10px 0px 14px 0px;
  }
  .providerTimezoneHeader {
    display: flex;
    flex-direction: column;
  }
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  padding-left: 105px;
  white-space: nowrap;
  height: 32px;
  margin-bottom: 20px;
  h3 {
    padding: 10px;
    font-size: 18px;
  }
  .subtitle-btn {
    margin-left: 25px;
    width: 107px;
    height: 32px;
  }
`;

export const SaveAndClose = styled.div`
  display: flex;
  align-items: center;

  i.link.icon,
  i.link.icons {
    margin-left: 16px;
  }
`;
export const ClinicBarWrapper = styled.div`
  .row {
    display: flex;
    flex-direction: row;
    min-height: 10px;
    align-items: center;
    justify-content: center;
  }
  .row-header {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    min-height: 60px;
    background-color: #c9cfd3;
    align-items: center;
    margin-left: 85px;
    margin-right: 10px;
    a {
      text-decoration: underline;
      margin: auto;
    }
  }
  .label {
    border: none;
    background-color: #c9cfd3;
    flexGrow: 1,
    flexBasis: 0
    a {
      color: #415766;
      font-family: 'Lato';
    }
    padding: 0px 4px;
  }
  .column {
    height: fit-content;
    width: 164px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3 px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    color: #415766;
    font-family: 'Lato';
  }
  .header {
    flex: 1 1;
    display: flex;
    min-width: 0;
    flex-direction: column;
  }
`;

export const CalendarWrapper = styled.div`
  .rbc-header + .rbc-header {
    border: none;
    background-color: #c9cfd3;
  }
  .clinic-abbr {
    font-size: 14px;
    font-weight: 700;
    color: rgba(65, 87, 102, 1);
    margin-top: 2px;
  }
  .weekendDay {
    margin-bottom: 20px;
  }
  .rbc-header {
    border: none;
    background-color: #c9cfd3;
    a {
      color: #415766;
      font-family: 'Lato';
      pointer-events: none;
      text-decoration: none !important;
      font-size: 15px;
      fonr-weight: 700;
    }
  }
  .rbc-row {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    height: 60px;
    background-color: #c9cfd3;
    align-items: center;
    a {
      text-decoration: underline;
      margin: auto;
    }
  }
  .rbc-time-column {
    :last-of-type {
      border-right: 1px solid #ddd;
    }
  }
  .rbc-toolbar {
    text-align: center;
    display: inline-block;
  }

  .rbc-events-container {
    .rbc-event {
      width: 100% !important;
      left: 0 !important;
      border-radius: 0px;
      margin-top: 0px;
      color: black;
      font-size: 12px;
      font-family: 'Lato';
      font-weight: bold;
      &.interseption {
        .rbc-event-label {
          display: none;
        }
      }
      .rbc-event-content {
        background-color: transparent;
        margin-top: 3px;
      }
      .rbc-event-label {
        background-color: transparent;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: bold;
        max-height: 16px;
        margin-top: 0.5px;
        padding: 1px 0px 2px 10px;
      }
    }
  }
  .rbc-current-time-indicator {
    display: none;
  }
  .error {
    pointer-events: none;
    z-index: 1;
  }
  .lunch,
  .lunch.rbc-selected {
    background-color: rgba(214, 194, 98, 0.23);
    border: 1px solid #6f42f5 !important;
  }

  .clinicandtelehealth,
  .clinicandtelehealth.rbc-selected {
    background-color: rgba(98, 214, 200, 0.23);
    border: 1px solid #62d6c8 !important;
  }
  .notes,
  .notes.rbc-selected {
    background-color: rgba(98, 141, 214, 0.23);
    border: 1px solid #628dd6 !important;
  }

  .meeting,
  .meeting.rbc-selected {
    background-color: rgba(208, 98, 214, 0.2);
    border: 1px solid #d062d6 !important;
  }

  .working,
  .working.rbc-selected {
    background-color: rgba(137, 241, 232, 0.4);
    border: 1px solid #89f1e8 !important;
  }

  .telehealth,
  .telehealth.rbc-selected {
    background-color: rgba(214, 153, 98, 0.2);
    border: 1px solid #d69962 !important;
  }

  .clinic,
  .clinic.rbc-selected {
    background-color: rgba(155, 248, 84, 0.2);
    border: 1px solid #9bf854 !important;
  }

  .offsite,
  .offsite.rbc-selected {
    background-color: rgba(243, 197, 222, 0.2);
    border: 1px solid #f3c5de !important;
  }

  .anyavailability,
  .anyavailability.rbc-selected {
    background-color: rgba(165, 138, 246, 0.2);
    border: 1px solid #a58af6 !important;
  }

  .rbc-timeslot-group {
    min-height: 70px;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: none;
  }
  .rbc-calendar {
    text-align: center;
    .calendar-nav {
      justify-content: center;
    }
    .rbc-time-header {
      padding-top: 3px;
      margin-bottom: 18px;
    }
    .availability-header {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .template-name {
      text-align: center;
      max-width: 50vh;
      padding: 0 2em;
      margin: 0 0 0 10vh;
      border: 1px solid #6f4bf1;
      display: flex;
      min-width: 50vh;
      justify-content: center;
    }
    .edit-icon {
      display: inline-flex;
      cursor: pointer;
      padding: 0 2%;
      stroke: #6f4bf1;
      &:hover {
        opacity: 70%;
      }
    }
    .disabled {
      stroke: #979797;
      pointer-events: none;
    }
  }
`;

export const CalendarContainer = styled.div`
  .alternate-calendar {
    display: flex;
    flex-direction: column;
    .calendar-container {
      display: flex;
      flex-direction: row;
    }
  }
`;
