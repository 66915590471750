import styled from 'styled-components';
import { EventType } from 'model/calendar/events';
import * as colors from 'utils/constants/theme';
import { EventStatus } from 'model';
interface Props {
  backgroundColor?: string;
  headerColor?: string;
  type?: string;
  itemContext: any;
  isPendingConfirmation?: boolean;
  isSelectedForCancellation?: boolean;
  isDelete?: boolean;
  status?: string;
  isSelectedForSubstitute?: boolean;
}
export const ItemWrapper = styled.div<Props>`
  .rct-item {
    padding-left: 2px;
    cursor: ${props =>
      props.itemContext.dragging ? 'move !important' : 'default !important'};
    ${props =>
      props.isSelectedForCancellation
        ? ` border: 3.5px ${'red'} solid !important; border-radius: 5px;`
        : props.itemContext.selected
        ? ` border: 2px ${props.headerColor || '#c9cfd3'} solid !important;`
        : 'border: none !important;'};
  }
  .rct-item-content {
    display: ${props =>
      props.type === EventType.clientEvent || EventType.phantomClientEvent
        ? 'list-item !important'
        : 'flex !important'};
    align-items: ${props =>
      props.type === EventType.adminEvent ? 'center' : ''};
    justify-content: ${props =>
      props.type === EventType.adminEvent ? 'start' : ''};
    line-height: 15px !important;
    overflow: hidden;
    max-height: 42px !important;
    border-radius: 0px !important;
    background-color: ${props =>
      props.status === EventStatus.canceled && !props.isDelete
        ? colors.APPOINTMENT_BACKGROUND_COLORS.CANCELED
        : props.isDelete
        ? colors.APPOINTMENT_BACKGROUND_COLORS.DELETED
        : props.backgroundColor} !important;
    ${props =>
      props.isSelectedForCancellation || props.isSelectedForSubstitute
        ? `border: 3.5px ${'red'} solid !important; 
           border-radius: 5px;`
        : props.itemContext.selected
        ? `border: 2px ${props.headerColor || '#c9cfd3'} solid !important;`
        : props.status === EventStatus.canceled && !props.isDelete
        ? `border: 0.6px ${colors.APPOINTMENT_HEADER_COLORS.CANCELED} solid !important;`
        : props.isDelete
        ? `border: 0.6px ${colors.APPOINTMENT_HEADER_COLORS.DELETED} solid !important;`
        : `border: 0.6px ${props.headerColor} solid !important;`}
  }
  .rct-item-handler-resize-right {
    cursor: ${props =>
      props.itemContext.selected ? 'col-resize !important' : 'default'};
    width: 2px;
  }
  .rct-item-handler-resize-left {
    cursor: ${props =>
      props.itemContext.selected ? 'col-resize !important' : 'default'};
    width: 2px;
  }
  .rct-item-handler {
    width: 0px !important;
  }

  .event {
    border: none !important;
    cursor: ${props =>
      props.itemContext.dragging ? 'move !important' : 'default !important'};
    opacity: ${({ isPendingConfirmation, type }) =>
      isPendingConfirmation ||
      type === EventType.phantomClientEvent ||
      type === EventType.phantomObserverEvent
        ? '0.7'
        : '1'};
    background-color: transparent !important;
    min-width: ${props => `${props.itemContext.dimensions.width}px`};
    margin: 13px 0px 0px 0px !important;
    overflow: hidden;
    .header {
      background-color: ${props =>
        props.status === EventStatus.canceled && !props.isDelete
          ? colors.APPOINTMENT_HEADER_COLORS.CANCELED
          : props.isDelete
          ? colors.APPOINTMENT_HEADER_COLORS.DELETED
          : props.headerColor} !important;
      height: 2px !important;
    }
    .upper {
      margin-top: 5px;
      max-height: 18px;
      align-items: center;
      display: flex;
    }

    .upper > div {
      margin-left: 0px;
      display: inline;
      vertical-align: middle;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      align-item: center;
    }
    .lower {
      align-items: center;
      display: flex;
    }
    .lower > div {
      margin-left: 0px;
      display: inline;
      vertical-align: middle;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      align-item: center;
    }
    .location-icon {
      width: 13px;
      height: 13px;
    }

    .failed-icon {
      color: red;
      padding: 70% 0;
    }

    .admin-failed-icon {
      color: red;
    }

    .observer-btn-fail {
      color: white;
      background: ${colors.RED};
      border: none;
      border-radius: 3px;
      font-size: 8px;
      font-weight: 400;
      height: 18px;
      margin-top: 25%;
    }

    .observer-btn {
      color: white;
      background: ${props => props.headerColor};
      border: none;
      border-radius: 3px;
      font-size: 8px;
      font-weight: 400;
      height: 18px;
      margin-top: 10%;
    }
    .observer-icon {
      color: white;
      font-size: 10px;
    }

    .observer-text {
      color: white;
      font-size: 8px;
      font-weight: 400;
    }

    .remove-padding-margin {
      padding: 0 !important;
      margin: 0 !important;
    }

    .failed-icon-column {
      text-align: center;
    }

    .phantom-observer-btn {
      color: white;
      background: ${props => props.headerColor} !important;
      border: none;
      border-radius: 6px;
      margin-top: 5px;
    }

    .phantom-observer-icon {
      color: white;
    }

    .phantom-observer-btn-cancelled {
      color: white;
      background: ${props => props.headerColor} !important;
      border: none;
      border-radius: 4px;
      margin: 0px 0px 0px 2px;
      max-width: 20px;
      padding: 0 2px;
      display: flex;
    }

    .phantom-observer-icon-cancelled {
      color: black;
      font-size: 14px;
    }

    .name {
      padding-left: 3px;
      color: #21303a;
      font-size: 14px;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
    .superType {
      font-weight: 400;
      padding-left: 3px;
      color: #21303a;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
    .deleted {
      text-decoration: line-through;
    }
    .item-event {
      display: flex !important;
      align-items: center !important;
    }
  }
  .na {
    background: rgb(217, 217, 217) !important;
    max-height: 38px !important;
  }
`;

export const ItemTooltipWrapper = styled.div`
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  p {
    padding: 0 !important;
    margin: 0 !important;
    white-space: nowrap;
  }
  .clinic-abbr {
    color: #0066ff;
  }
  .error {
    color: #ec4f4f;
  }
`;

export const ItemContextMenuWrapper = styled.div`
  .loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 0.7s linear infinite; /* Safari */
    animation: spin 0.7s linear infinite;
  }
  .menu-header { 
    padding: '5px', 
    display: 'inline-block', 
    width: '100%' 
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default ItemWrapper;
