import gql from 'graphql-tag';

export const ALL_CANCELLATION_REASONS = gql`
  query {
    allCancellationReasons {
      id
      reasonId
      name
      type
    }
  }
`;
