import gql from 'graphql-tag';

export const AvailabilityTemplateFragments = {
  AvailabilityTemplateList: gql`
    fragment AvailabilityTemplateList on AvailabilityTemplate {
      id
      createdAt
      department
      speciality {
        id
        abbreviation
      }
      title: name
      name
      createdBy
      default
      isActive
    }
  `,
  AvailabilityTemplateWithDayAvailability: gql`
    fragment AvailabilityTemplateWithDayAvailability on AvailabilityTemplate {
      id
      createdAt
      department
      speciality {
        id
        abbreviation
      }
      name
      createdBy
      default
      isActive
      dayAvailabilities {
        id
        dayOfWeek
        startTime
        endTime
        type
        location
        isAdmin
      }
    }
  `,
  ProviderDayAvailability: gql`
    fragment ProviderDayAvailability on ProviderTemplateOutput {
      id
      name
      isActive
      dayAvailabilities {
        id
        dayOfWeek
        startTime
        endTime
        updatedAt
        type
        location
        isAdmin
        isInClinic
        isOffsite
        isTelehealth
        clinics {
          id
        }
      }
    }
  `,
  ProviderDayAvailabilityPlanner: gql`
    fragment ProviderDayAvailability on ProviderTemplateOutput {
      id
      name
      isActive
      dayAvailabilities {
        id
        dayOfWeek
        startTime
        endTime
        type
        location
        isAdmin
        isInClinic
        isOffsite
        isTelehealth
        clinics {
          id
          abbreviation
        }
      }
    }
  `
};
