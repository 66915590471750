import React, { useMemo, useState } from 'react';

import GroupWrapper from './style';
import Group from '../group/group';
import { createPortal } from 'react-dom';
import { useDisableBodyScroll } from 'hooks/disableScrollHook';
import DraggableDayGroup from './draggableDayGroup';
import { IPlannerGroup } from 'model/calendar/groups';

interface Props {
  group: IPlannerGroup;
  setRemovedGroups: React.Dispatch<React.SetStateAction<number[]>>;
  removedGroups: number[];
  handleAutoFetching: () => void;
}

const PlannerDayGroup: React.FC<Props> = ({
  group,
  setRemovedGroups,
  removedGroups,
  handleAutoFetching
}: Props) => {
  const [isGroupDragged, setIsGroupDragged] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(false);

  useDisableBodyScroll(isGroupDragged);

  const draggableDayGroup = useMemo(() => {
    return (
      <DraggableDayGroup
        setIsGroupDragged={setIsGroupDragged}
        isShown={isShown}
        isGroupDragged={isGroupDragged}
        group={group}
        setIsShown={setIsShown}
        setRemovedGroups={setRemovedGroups}
        removedGroups={removedGroups}
        handleAutoFetching={handleAutoFetching}
      />
    );
  }, [
    isShown,
    isGroupDragged,
    group,
    setRemovedGroups,
    removedGroups,
    handleAutoFetching
  ]);

  return (
    <>
      {/* render dragged div outside  the DOM hierarchy of the parent component */}
      {group.id !== 'DEFAULT' && isGroupDragged
        ? createPortal(<>{draggableDayGroup}</>, document.body)
        : group.id !== 'DEFAULT' && <>{draggableDayGroup}</>}

      {/* render cloned placeholder group */}
      {isGroupDragged && (
        <GroupWrapper
          type={group.attendeeType}
          // TODO: check that get in specialtiesMap take the id as number
          specialityColor={group?.speciality?.color}
          isGroupDragged={isGroupDragged}
        >
          <Group
            cloneGroup={true}
            isShown={false}
            group={group}
            setRemovedGroups={setRemovedGroups}
            removedGroups={removedGroups}
            showFullGroup={true}
          />
        </GroupWrapper>
      )}
    </>
  );
};

export default React.memo(PlannerDayGroup);
