import { EditAppointmentPopup, IEditAppointmentPopup } from 'model';
import React, { useCallback, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Form, Radio } from 'semantic-ui-react';
import { RescheduleModalType } from 'model/event';
import dompurify from 'dompurify';

interface IRadioEventProps {
  methods: UseFormMethods<IEditAppointmentPopup>;
  recurringEventsCount?: number;
  radioButtonStatus: any;
  observersCount: number;
}

const EventTypeRadioGroup = React.memo(
  ({
    methods,
    recurringEventsCount,
    radioButtonStatus,
    observersCount
  }: IRadioEventProps) => {
    const [value, setValue] = useState(1);
    const handleChange = useCallback(
      (_event, { value }) => {
        radioButtonStatus(value);
        methods.setValue(EditAppointmentPopup.type, value, {
          shouldDirty: true
        });
        setValue(value);
      },
      [methods]
    );
    const singleColoredLabel = RescheduleModalType.single.replace(
      /\d+/,
      match => `<span style="color: #6F42F5">${match}</span>`
    );

    const recurringColoredLabel = RescheduleModalType.recurring(
      recurringEventsCount
    ).replace(
      /\d+/,
      match => `<span style="color: #E82424
    ">${match}</span>`
    );

    const getLabel = (selectedValue: number, label: any) => {
      return value === selectedValue ? (
        <label
          style={{ fontWeight: 'bold' }}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(label)
          }}
        ></label>
      ) : (
        <label
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(label)
          }}
        ></label>
      );
    };

    const singleLabel = getLabel(
      RescheduleModalType.singleMappedValue,
      singleColoredLabel
    );

    const recurringLabel = getLabel(
      RescheduleModalType.recurringMappedValue,
      recurringColoredLabel
    );
    const getObserversSingleCountLabel = () => {
      if (value == 1 && observersCount > 0) {
        return (
          <div>
            <p>
              Rescheduling this event, will also affect(
              <p style={{ color: '#E82424', display: 'inline-block' }}>
                {observersCount}
              </p>
              ) observers.
            </p>
          </div>
        );
      } else {
        return <></>;
      }
    };
    const getObserversUpdateAllCountLabel = () => {
      if (value == 2 && observersCount > 0) {
        return (
          <div>
            <p>
              Rescheduling this series events, will also affect (
              <p style={{ color: '#E82424', display: 'inline-block' }}>
                {observersCount}
              </p>
              )observers.
            </p>
          </div>
        );
      } else {
        return <></>;
      }
    };
    const getEffectedObserversMessage = () => {
      if (value == 1) {
        return getObserversSingleCountLabel();
      } else if (value == 2) {
        return getObserversUpdateAllCountLabel();
      } else {
        return <></>;
      }
    };

    return (
      <Form>
        <Form.Field>
          <Radio
            label={singleLabel}
            value={RescheduleModalType.singleMappedValue}
            checked={value === RescheduleModalType.singleMappedValue}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <Radio
            label={recurringLabel}
            value={RescheduleModalType.recurringMappedValue}
            checked={value === RescheduleModalType.recurringMappedValue}
            onChange={handleChange}
          />
          <div
            style={{
              marginTop: '3%',
              fontFamily: 'Lato',
              fontSize: '13px',
              lineHeight: '22px',
              fontWeight: 'bold',
              padding: '5px 0 5px 0'
            }}
          >
            {getEffectedObserversMessage()}
          </div>
        </Form.Field>
      </Form>
    );
  }
);

export default React.memo(EventTypeRadioGroup);
