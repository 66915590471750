export const templateActions = {
  REACTIVATE: 'Reactivate',
  DEACTIVATE: 'Deactivate'
};

export const WeekEnd = {
  Sunday: 0,
  Saturday: 6
};

export const DayValue = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const PROVIDER_MAX_DAILY_HOURS_TEMPLATE = 8;

export const FIFTEEN_MINUTES_IN_MS = 15 * 60000;

export const TEN_MINUTES_IN_MS = 10 * 60000;

export const FIVE_MINUTES_IN_MS = 5 * 60000;
