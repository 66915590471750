import { useEffect } from 'react';

export const useDisableBodyScroll = (shouldDisable: boolean) => {
  useEffect(() => {
    if (shouldDisable) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [shouldDisable]);
};
