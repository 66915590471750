import reactCSS from 'reactcss';

export const rectangleStyle = (color: string) => ({
  width: '23px',
  height: '15px',
  backgroundColor: color
});

export const styles = reactCSS({
  default: {
    color: {
      fontFamily: 'Lato',
      fontSize: '12px',
      letterSpacing: '0.15px'
    }
  }
});
