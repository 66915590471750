export interface IModalDownStreamsConfigurations {
  cancelDisabled: boolean;
  editDisabled: boolean;
  rescheduleDisabled: boolean;
  crStatusMessage: string;
  crStatusMessageType: string;
  athenaStatusMessage: string;
  athenaStatusMessageType: string;
}
enum DownStreams {
  ATHENA = 'ATHENA',
  CR = 'CR'
}
interface MessagesMap {
  unknown: string;
  pending: string;
  cancelling: string;
  success: string;
  fail: string;
  nosync: string;
}
interface DownMessageMap {
  [DownStreams.CR]: MessagesMap;
  [DownStreams.ATHENA]: MessagesMap;
}
const UNKNOWN_ATHENA_MESSAGE = '';
const UNKNOWN_CR_MESSAGE = '';
const PENDING_ATHENA = 'Sync with Athena in-progress...';
const PENDING_CANCEL_ATHENA = 'Pending Cancellation to Athena';
const PENDING_CR = 'Sync with Central Reach in-progress...';
const PENDING_CANCEL_CR = 'Pending Cancellation to CR';
const SUCCESS_CR = 'Sync with Central Reach successful...';
const SUCCESS_ATHENA = 'Sync with Athena successful...';
const FAIL_CR = 'Sync with Central Reach failed...';
const FAIL_ATHENA = 'Sync with Athena failed...';
const NO_SYNC_CR = 'Does Not Sync to CR';
const NO_SYNC_ATHENA = 'Does not sync with Athena';

export const CONVERTED_APPOINTMENT_MESSAGE = 'Appointment Converted';

export const MESSAGES_TYPES: MessagesMap = {
  unknown: 'unknown',
  pending: 'pending',
  cancelling: 'cancelling',
  success: 'success',
  fail: 'fail',
  nosync: 'no sync'
};

const MESSAGES: DownMessageMap = {
  CR: {
    unknown: UNKNOWN_CR_MESSAGE,
    pending: PENDING_CR,
    cancelling: PENDING_CANCEL_CR,
    success: SUCCESS_CR,
    fail: FAIL_CR,
    nosync: NO_SYNC_CR
  },
  ATHENA: {
    unknown: UNKNOWN_ATHENA_MESSAGE,
    pending: PENDING_ATHENA,
    cancelling: PENDING_CANCEL_ATHENA,
    success: SUCCESS_ATHENA,
    fail: FAIL_ATHENA,
    nosync: NO_SYNC_ATHENA
  }
};
const downMessage = (downStream: DownStreams, status?: number) => {
  const messagesMap: MessagesMap = MESSAGES[downStream];
  let message = messagesMap.unknown;
  let messageStatusObject: any = [];
  if (status === undefined || status === null || isNaN(status))
    message = UNKNOWN_CR_MESSAGE;
  else if (status === 0) message = messagesMap.nosync;
  else if (status === 3) message = messagesMap.cancelling;
  else if (status < 100) {
    message = messagesMap.pending;
  } else if (status < 200) {
    message = messagesMap.success;
  } else {
    message = messagesMap.fail;
  }

  messageStatusObject = Object.entries(messagesMap).find(([key, value]) => {
    if (value === message) return key;
    return '';
  });
  return messageStatusObject;
};
export function getDownStreamStatusConfigurations(
  crStatus?: number,
  athenaStatus?: number,
  isConverted?: boolean
): IModalDownStreamsConfigurations {
  const crMessageStatusObject = downMessage(DownStreams.CR, crStatus);
  const athenaMessageStatusObject = downMessage(
    DownStreams.ATHENA,
    athenaStatus
  );
  const crMessage = crMessageStatusObject[1];
  const athenaMessage = athenaMessageStatusObject[1];

  let pending =
    [
      MESSAGES[DownStreams.ATHENA].pending,
      MESSAGES[DownStreams.ATHENA].cancelling
    ].includes(athenaMessage) ||
    [
      MESSAGES[DownStreams.CR].pending,
      MESSAGES[DownStreams.CR].cancelling
    ].includes(crMessage);

  let success =
    athenaMessage !== MESSAGES[DownStreams.ATHENA].fail &&
    crMessage !== MESSAGES[DownStreams.CR].fail;

  if (isConverted) {
    pending = true;
  }

  return {
    athenaStatusMessage: athenaMessage,
    athenaStatusMessageType: athenaMessageStatusObject[0],
    crStatusMessage: crMessage,
    crStatusMessageType: crMessageStatusObject[0],
    cancelDisabled: pending ? true : false,
    editDisabled: pending ? true : !success,
    rescheduleDisabled: pending ? true : !success
  } as IModalDownStreamsConfigurations;
}
