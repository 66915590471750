import styled from 'styled-components';
import { WHITE, PRIMARY_PURPLE } from 'utils/constants/theme';
export const DailyClientAvailabilityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 3px;
  padding: 10px 7px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: ${WHITE};
`;

export const AvailabilityBlockWrapper = styled.div`
  margin-bottom: 10px;
  background: ${PRIMARY_PURPLE};
  border-radius: 3px;
  padding: 10px 7px;
  width: 342px;
`;
