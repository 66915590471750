import PropTypes from 'prop-types';
import moment from 'moment';
import { EVENT_STATUS } from 'utils/constants/status';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { getFeatureAvailability, FEATURES } from 'utils/featureToggle';
import { icons } from 'utils/common';
import LocationIcon from 'views/components/icons/eventIcons/locationIcon';
import { ICalendarEvent } from 'model/calendar/events';
import { CalendarView } from 'utils/cache/calendar';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';

interface IProps {
  event: ICalendarEvent;
  view: CalendarView;
  provider?: boolean;
  style?: any;
}
const Event = ({ event, view, provider }: IProps) => {
  const {
    type,
    title,
    desc,
    status,
    clinic,
    room,
    start,
    color,
    locationType,
    locationCategory,
    appointmentType
  } = event;

  const startDate = moment(start).format('hh:mm A');
  const roomSelected = room?.id ? `, ${room?.name || ''}` : '';

  const inClinic =
    locationType === LOCATION_TYPE_LIST[0].id || // OR-ing for cases where events were created via Outlook ('locationType' not set)
    (!locationType && clinic?.id);

  const isTelehealth = getFeatureAvailability(FEATURES.TELEHEALTH)
    ? locationType === LOCATION_TYPE_LIST[2].id
    : false;
  const isRecurringEvent = () => {
    return event?.recurrencePattern?.recurringUntil;
  };

  return (
    <>
      {type !== 'na' && (
        <div>
          <i
            style={{
              backgroundColor: color
            }}
          ></i>
          <p
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            <strong>
              {view === 'month' && startDate}{' '}
              {view === 'month' &&
              appointmentType?.eventType?.name === EVENT_TYPES.Admin
                ? appointmentType.title
                : title}
            </strong>
          </p>
          {view !== 'month' && (
            <>
              <p
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {desc?.main}
              </p>
              {view === 'week' &&
                desc?.extras !== undefined &&
                desc?.extras?.map((extra, index) => (
                  <p
                    key={index}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {extra}
                  </p>
                ))}
              <p
                className="event-room"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {inClinic
                  ? `${clinic?.abbreviation}${
                      roomSelected ? `${roomSelected}` : ''
                    }`
                  : isTelehealth
                  ? ''
                  : `${locationCategory}`}
              </p>
              {appointmentType?.eventType?.name !== EVENT_TYPES.Admin &&
                provider && (
                  <p
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {isRecurringEvent()
                      ? moment(event?.recurrencePattern?.recurringUntil).format(
                          'MM/DD/YYYY'
                        )
                      : moment(event.endDate).format('MM/DD/YYYY')}
                  </p>
                )}

              <span className="event-status">
                {(icons as Record<string, any>)[status!]}
                <LocationIcon
                  locationType={locationType}
                  className="location-icon"
                />
                {(status === EVENT_STATUS.needsReschedule && 'pending') ||
                  status}
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
};

Event.propTypes = {
  event: PropTypes.object,
  view: PropTypes.string
};

export default Event;
