import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useQuery } from 'react-apollo';
import Content from 'views/components/ui/content';
import PushableSideBar from 'views/components/ui/sidebar';
import ConfigurationHeader from 'views/components/configurationHeader';
import TableComponent from 'views/components/table/Table';
import { useHistory } from 'react-router';
import { TableType } from 'model/tableType';
import { IProviderSpecialty, Specialty } from 'model/v2';
import { SPECIALITIES_COLUMNS } from 'views/components/table/tableColumns/specialities';
import { SPECIALITIES_TITLE } from 'utils/constants/configurations';
import {
  GET_SPECIALTIES,
  GET_DEPARTMENTS
} from 'api/graphql/v2/queries/ProviderSpecialty';
import SpecialitiesForm from 'views/components/forms/configurations/specialities';
import { NEW_SPECIALITIES_CONFIG } from 'utils/constants/init';
import {
  ISortValue,
  mapToTableState,
  MAPPER_FUNCTION
} from 'utils/mappers/configuration';
import { ITableComp } from 'views/components/table/type';
import { scrollToTop } from 'utils/math';
import { ISpecialitiesConfig } from 'model/tableConfigurations';

const SpecialitiesConfig: React.FC = () => {
  const history = useHistory();
  const [currentForm, setCurrentForm] = useState(-1);
  const [sortValue, setSortValue] = useState<ISortValue>();
  const [searchValue, setSearchValue] = useState('');
  const formValues = useRef(NEW_SPECIALITIES_CONFIG);

  const tableHeaders = SPECIALITIES_COLUMNS;

  const { loading, error, data } = useQuery(GET_SPECIALTIES, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: departments } = useQuery(GET_DEPARTMENTS, {
    fetchPolicy: 'cache-and-network'
  });

  const addNew = useCallback(
    (data?: TableType) => {
      if (data) formValues.current = data as IProviderSpecialty;
      else formValues.current = NEW_SPECIALITIES_CONFIG;
      scrollToTop();
      setCurrentForm(formValues.current.id!);
    },
    [setCurrentForm]
  );

  const tableData = useMemo(() => {
    return data
      ? (mapToTableState(
          data.specialties,
          addNew,
          MAPPER_FUNCTION.speciality,
          searchValue,
          sortValue
        ) as ISpecialitiesConfig[])
      : [];
  }, [data, addNew, searchValue, sortValue]);

  const onSearch = useCallback(
    value => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const onSort = useCallback(
    (comp: ITableComp, reverse: boolean) => {
      setSortValue({ comp, reverse });
    },
    [setSortValue]
  );

  const onFormClose = useCallback(() => {
    formValues.current = NEW_SPECIALITIES_CONFIG;
    setCurrentForm(-1);
  }, [setCurrentForm]);

  const onGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <PushableSideBar
        content={
          <SpecialitiesForm
            title={
              formValues.current.id
                ? `Edit ${SPECIALITIES_TITLE}`
                : `New ${SPECIALITIES_TITLE}`
            }
            initialValues={formValues.current}
            onFormClose={onFormClose}
            departments={departments?.specialtiesDepartment}
          />
        }
        visible={currentForm !== -1}
        pageComponent={
          <Content
            loading={loading}
            error={error}
            data={data}
            fullHeight={true}
          >
            {() => (
              <>
                <ConfigurationHeader
                  addNew={addNew}
                  onSearch={onSearch}
                  title={SPECIALITIES_TITLE}
                  onClick={onGoBack}
                  visible={currentForm !== -1}
                />
                <TableComponent
                  headers={tableHeaders}
                  data={tableData}
                  sort={onSort}
                  primarySortedKey={Specialty.title}
                />
              </>
            )}
          </Content>
        }
      />
    </>
  );
};

export default SpecialitiesConfig;
