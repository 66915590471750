import styled from 'styled-components';
import { Tabs } from 'antd';
import { PURPLE } from 'utils/constants/theme';
const { TabPane } = Tabs;

export const LibTabs = styled(Tabs)`
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

  .ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
    font-size: 15px;
    line-height: 32px;
    border: none !important;
    margin: 0 !important;
    border-radius: 0 !important;
    box-shadow: inset 0 4px 0 0 #415766;
  }

  .ant-tabs-tab-active {
    box-shadow: inset 0 4px 0 0 #6f4bf1;
    font-weight: bold;
    .ant-tabs-tab-btn {
      color: ${PURPLE};
    }
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

export const LibTabPane = styled(TabPane)`
  display: flex;
  justify-content: center;
  .inner-wrap {
    max-width: 100vw;
    padding: 0;
  }
  .ant-table {
    .ant-table-content::after {
      display: none;
    }
    .ant-table-thead > tr > th {
      color: #919699;
    }
  }
`;
