import React from 'react';
import {
  FieldErrors,
  Control,
  Controller,
  ControllerRenderProps
} from 'react-hook-form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { Input } from 'api/sharedComponents/reactHookFormComponents';
import { IClinicEditForm } from 'model/clinic';

interface IProps {
  control: Control<IClinicEditForm>;
  errors?: FieldErrors<IClinicEditForm>;
  label: string;
  name: string;
  disabled: boolean;
  placeholder: string;
  width?: string;
  marginRight?: string;
}
const ClinicInput: React.FC<IProps> = ({
  control,
  errors,
  label,
  name,
  disabled,
  placeholder,
  width,
  marginRight
}: IProps) => {
  return (
    <div className="form-input">
      <FormItem label={label}>
        <Controller
          control={control}
          name={name}
          render={(fieldProps: ControllerRenderProps<IClinicEditForm>) => (
            <Input
              placeholder={placeholder}
              field={fieldProps}
              errors={errors}
              disabled={disabled}
              style={{ width, marginRight }}
            />
          )}
        />
      </FormItem>
    </div>
  );
};
export default React.memo(ClinicInput);
