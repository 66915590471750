import styled from 'styled-components';
import { CalendarView } from 'utils/cache/calendar';
import * as colors from 'utils/constants/theme';

interface Props {
  isSearchListOpen?: boolean;
  view?: CalendarView;
  isStaffPage?: boolean;
}
export const CalendarFiltersAndSearchWrapper = styled.div`
  display: flex;
  height: 100%;
  .empty-slot {
    width: 90px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: ${colors.LIGHT_GREY};
  }
`;
export const ProviderCalendarFiltersAndSearchWrapper = styled.div<Props>`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top:${Props => (Props.isStaffPage ? '16px' : '0px')}
  .empty-slot {
    width: 90px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: ${colors.LIGHT_GREY};
  }
`;

export const SearchWrapper = styled.div<Props>`
  height: 100%;
  width: 100%;
  padding: ${props => (props.isStaffPage ? '' : '0 16px')};
  position: relative;
  .client_search_wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .staff_wrapper {
    width: 100%;
  }
  .search-by-client-selected {
    height: 32px;
    width: 363px;
    position: relative;
    .ant-select-selector {
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
      flex: 1;
      background-color: #fff;
      border: 1px solid #6f42f5;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    .ant-select-dropdown {
      border-radius: 4px;
      background: #fff;
      z-index: 999;
      max-height: 216px !important;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      padding: 2px 0;
      border-color: #6f42f5;
      border-style: solid;
      border-width: 0 1px 1px 1px;
      overflow-y: overlay !important;
    }
  }
  .search-by-client {
    height: 32px;
    width: 363px;
    position: relative;
    .ant-select-selector {
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
      flex: 1;
      background-color: #fff;
      border: 1px solid #c9cfd3;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    .ant-select-dropdown {
      border-radius: 4px;
      background: #fff;
      z-index: 999;
      max-height: 216px !important;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      padding: 2px 0;
      border-color: #6f42f5;
      border-style: solid;
      border-width: 0 1px 1px 1px;
      overflow-y: overlay !important;
    }
  }
  .client-Provider_wrapper {
    height: 32px;
    position: relative;
    .rbt-input {
      flex: 1;
      background-color: #fff;
      border: 1px solid #c9cfd3;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
      &.focus {
        border-radius: ${props =>
          props.isSearchListOpen ? '4px 4px 0 0' : '4px'};
        border-color: #6f42f5;
        outline: none;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: 600;
        color: #415766;
      }
    }
    .rbt-input-hint {
      display: none !important;
    }
  }
  .rbt-menu {
    border-radius: 4px;
    background: #fff;
    z-index: 999;
    max-height: 216px !important;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    padding: 2px 0;
    border-color: #6f42f5;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    overflow-y: overlay !important;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .client-Provider_option {
      transition: all 0.1s ease-in-out;
      font-size: 14px;
      color: #000;
      padding: 7px 16px 7px 16px;
      &:hover {
        background-color: #f2f4f5;
      }
    }

    .client-Provider_results,
    .client-Provider_search {
      font-size: 14px;
      color: #000;
      padding: 7px 16px 7px 16px;
      cursor: default;
    }
    .client-Provider_loader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 16px 7px 16px;
    }
  }
  .search-icon {
    position: absolute;
    z-index: 80;
    top: 14px;
    left: 28px;
  }
  .search-icon-provider {
    position: absolute;
    z-index: 80;
    top: 50px;
    left: 28px;
  }
  .search-icon-waitList {
    position: absolute;
    z-index: 80;
    top: 8px;
    margin-right: 10px;
    // left: 220px;
  }

  .Provider_Wrapper {
    margin-top: 10px;
  }
  .waitlist_Wrapper {
    .rbt-input {
      padding: 0 30px 0 12px !important;
    }
  }
  .staff_wrapper {
    .rbt-input {
      width: 100%;
      border-radius: 8px;
    }
  }
`;
