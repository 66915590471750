import * as yup from 'yup';
import { DTAppointmentSubType } from 'model/DTAppointmentSubType';
import { Lookup } from 'model/lookup';

export const DT_APPOINTMENT_SUBTYPE_SCHEMA = () =>
  yup.object().shape({
    [DTAppointmentSubType.title]: yup.string().required('Title is required'),
    [DTAppointmentSubType.athenaId]: yup
      .string()
      .required('Athena ID is required'),
    [DTAppointmentSubType.crName]: yup.string().required('CR Name is required'),
    [DTAppointmentSubType.crType]: yup.string().required('CR Type is required'),
    [DTAppointmentSubType.superType]: yup.object().shape({
      [Lookup.id]: yup.string().required('Parent Type is required')
    }),
    [DTAppointmentSubType.duration]: yup
      .number()
      .typeError('duration must be greater than or equal to 15')
      .required('duration is required')
      .min(15),
    [DTAppointmentSubType.salesForceMapping]: yup.object().shape({
      id: yup.number().nullable(),
      salesforceTherapyTitle: yup.string().nullable()
    })
  });
