import React, { useState, useCallback, useMemo } from 'react';
import Content from 'views/components/ui/content';
import ConfigurationHeader from 'views/components/configurationHeader';
import TableComponent from 'views/components/table/Table';
import { useHistory } from 'react-router';
import { AVAILABILITY_TEMPLATE_COLUMNS } from 'views/components/table/tableColumns/availabilityTemplate';
import { AVAILABILITY_TEMPLATE } from 'utils/constants/configurations';

import {
  AvailabilityTemplate,
  IAvailabilityTemplate,
  IAvailabilityTemplateData
} from 'model/availabilityTemplate';
import {
  ISortValue,
  mapToTableState,
  MAPPER_FUNCTION
} from 'utils/mappers/configuration';
import { ITableComp } from 'views/components/table/type';
import DeactivateTemplateModal from './DeactivateTemplateModal';
import { IAvailabilityTemplateConfig } from 'model/tableConfigurations';
import { useQuery, useMutation } from 'react-apollo';
import {
  GET_ALL_TEMPLATES,
  GET_SPECIALITIES_AND_DEPARTMENTS_AND_TEMPLATES
} from 'api/graphql/v2/queries/AvailabilityTemplate';
import { TOGGLE_ISACTIVE_TEMPLATE } from 'api/graphql/v2/mutations/AvailabilityTemplate';

const AvailabilityTemplateConfig: React.FC = () => {
  const history = useHistory();
  const [sortValue, setSortValue] = useState<ISortValue>();
  const [searchValue, setSearchValue] = useState('');
  const [modalState, setModalState] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [template, setTemplate] = useState<IAvailabilityTemplate>();
  const [id, setId] = useState(0);
  const [disabled, setBtnDisabled] = useState(false);
  const tableHeaders = AVAILABILITY_TEMPLATE_COLUMNS;

  const { data, loading, error } = useQuery<IAvailabilityTemplateData>(
    GET_ALL_TEMPLATES
  );

  const refetch = () => [
    {
      query: GET_SPECIALITIES_AND_DEPARTMENTS_AND_TEMPLATES
    }
  ];

  const [toggleIsActiveTemplate] = useMutation<IAvailabilityTemplate>(
    TOGGLE_ISACTIVE_TEMPLATE,
    {
      refetchQueries: () => refetch()
    }
  );

  const addNew = useCallback(() => {
    history.replace('/config/addTemplate');
  }, [history]);

  const onSearch = useCallback(
    value => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const onSort = useCallback(
    (comp: ITableComp, reverse: boolean) => {
      setSortValue({ comp, reverse });
    },
    [setSortValue]
  );

  const onGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const openModal = useCallback(() => {
    setModalState(true);
  }, []);

  const tableData = useMemo(() => {
    const buttonHandler = {
      viewHandler: (id: string) => {
        history.push('availabilityTemplates/' + id);
      },
      deactivateHandler: (isActive: boolean, id: string) => {
        const target = data?.listAllAvailabilityTemplatesOrderByDepartment.find(
          item => item.id === id
        );
        setBtnDisabled(false);
        setTemplate(target);
        setIsActive(isActive);
        setId(Number(id));
        openModal();
      }
    };
    return data
      ? (mapToTableState(
          data.listAllAvailabilityTemplatesOrderByDepartment,
          addNew,
          MAPPER_FUNCTION.availabilityTemplate,
          searchValue,
          sortValue,
          buttonHandler
        ) as IAvailabilityTemplateConfig[])
      : [];
  }, [data, addNew, searchValue, sortValue, history, openModal]);

  const onModalClose = useCallback((): void => {
    setModalState(false);
  }, []);

  const onModalSubmit = useCallback(async () => {
    setBtnDisabled(true);
    await toggleIsActiveTemplate({
      variables: {
        id: id
      }
    }).then(() => {
      setModalState(false);
    });
  }, [id, toggleIsActiveTemplate]);

  const getModalMessage = useCallback(() => {
    if (isActive) {
      return (
        <>
          Are you sure you want to deactivate the{' '}
          <strong> {template?.name || ''} </strong> template? once deactivated
          it will no longer show up as an option to apply as a new template
        </>
      );
    } else {
      return (
        <>
          Are you sure you want to reactivate the{' '}
          <strong> {template?.name || ''} </strong>
          template? once reactivated it will show up as an option to apply as a
          new template
        </>
      );
    }
  }, [isActive, template]);

  return (
    <>
      <Content
        loading={loading}
        error={error}
        data={data?.listAllAvailabilityTemplatesOrderByDepartment}
        fullHeight={true}
      >
        {() => (
          <>
            <ConfigurationHeader
              addNew={addNew}
              onSearch={onSearch}
              title={AVAILABILITY_TEMPLATE}
              onClick={onGoBack}
              visible={false}
              searchPlaceholder={'Search by template name'}
            />
            <TableComponent
              headers={tableHeaders}
              data={tableData}
              sort={onSort}
              primarySortedKey={AvailabilityTemplate.department}
            />
            <DeactivateTemplateModal
              modalState={modalState}
              onModalClose={onModalClose}
              isActive={isActive}
              onModalSubmit={onModalSubmit}
              getModalMessage={getModalMessage}
              disabled={disabled}
            />
          </>
        )}
      </Content>
    </>
  );
};

export default AvailabilityTemplateConfig;
