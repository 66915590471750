import { GET_CLINICS_OPTIONS } from 'api/graphql/v2/queries/Clinics';
import { IClinic } from 'model/v2';
import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { STATES } from 'utils/constants/commonLists';
import { alphabeticalSort } from 'utils/sort';

export const useGetStateClinicsHook = () => {
  const { data } = useQuery(GET_CLINICS_OPTIONS, {
    fetchPolicy: 'no-cache'
  });
  const stateToClinicsMap = useMemo(() => {
    const map = new Map<string, any>();
    const clinicsData: IClinic[] = data?.clinicOptionsList || [];

    STATES.forEach((state: string) => {
      const stateClinics = clinicsData
        .filter(
          clinic =>
            clinic.address?.state?.toUpperCase() === state?.toUpperCase()
        )
        .map(clinic => clinic.name)
        .sort((a, b) => alphabeticalSort(a, b));
      if (stateClinics?.length > 0) {
        map.set(state, stateClinics);
      }
    });
    return map;
  }, [data]);

  return stateToClinicsMap;
};
