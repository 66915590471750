import React, { Dispatch, SetStateAction } from 'react';
import { AppointmentFormHead } from './style';
import { CloseOutlined } from '@ant-design/icons';
import { SIDEBAR_ACTIONS } from '../AppointmentSidebar';
import { selectedItemToSubstituteVar } from 'utils/cache/calendar';
import arrowBackFilled from '../../../assets/img/ArrowBackFilled.png';

interface Props {
  setVisible: (val: boolean) => void;
  action: string;
  clientName?: string;
  setSmartResults: Dispatch<SetStateAction<any>>;
  setAction: Dispatch<SetStateAction<string>>;
}

const FormHeader: React.FC<Props> = ({
  setVisible,
  action,
  clientName,
  setSmartResults,
  setAction
}: Props) => {
  const handleclose = async () => {
    await setVisible(false);
    selectedItemToSubstituteVar(0);
  };
  if (
    action !== SIDEBAR_ACTIONS.SUBSTITUTE &&
    action !== SIDEBAR_ACTIONS.FIND_MAKEUP
  )
    selectedItemToSubstituteVar(0);
  return (
    <AppointmentFormHead>
      {action === SIDEBAR_ACTIONS.SUBSTITUTE ? (
        <>
          <label>Find Substitute</label>
        </>
      ) : action === SIDEBAR_ACTIONS.FIND_MAKEUP ? (
        <>
          <label>Find Makeup</label>
        </>
      ) : action === SIDEBAR_ACTIONS.POTENIAL_RESULTS ? (
        <>
          <label>
            <img
              src={arrowBackFilled}
              style={{ paddingRight: '5px', cursor: 'pointer' }}
              onClick={() => {
                setSmartResults(undefined);
                setAction('New');
              }}
            />
            {clientName}
          </label>
        </>
      ) : (
        <>
          <label>{action} Appointment</label>
        </>
      )}
      <CloseOutlined onClick={handleclose} />
    </AppointmentFormHead>
  );
};
export default React.memo(FormHeader);
