import React, { useState, useCallback, useMemo, useRef } from 'react';
import { ITableComp } from 'views/components/table/type';
import PushableSideBar from 'views/components/ui/sidebar';
import ConfigurationHeader from 'views/components/configurationHeader';
import TableComponent from 'views/components/table/Table';
import { useHistory } from 'react-router';
import { DT_APPOINTMENT_SUBTYPE_COLUMNS } from 'views/components/table/tableColumns';
import {
  IDTAppointmentSubType,
  DTAppointmentSubType
} from 'model/DTAppointmentSubType';
import { DT_APPT_SUBTYPE_TITLE } from 'utils/constants/configurations';
import { GET_DT_APPOINTMENT_SUBTYPES } from 'api/graphql/v2/queries/AppointmentTypes';
import { useQuery } from '@apollo/react-hooks';
import Content from 'views/components/ui/content';
import { TableType } from 'model/tableType';
import DTAppointmentSubTypesForm from 'views/components/forms/configurations/DTAppointmentSubType';
import { NEW_DT_APPOINTMENT_SUBTYPE_CONFIG } from 'utils/constants/init';
import { GET_APPT_TYPES_CONFIG } from 'api/graphql/v2/queries/AppointmentTypes';
import { scrollToTop } from 'utils/math';
import {
  ISortValue,
  MAPPER_FUNCTION,
  mapToTableState
} from 'utils/mappers/configuration';
import { IDTAppointmentSubTypeConfig } from 'model/tableConfigurations';
import { EventTypes } from 'model/eventType';
import { GET_SALESFORCE_THERAPY_MAPPINGS } from 'api/graphql/v2/queries/SalesForceTherapyMappings';

const DTAppointmentSubTypeConfig: React.FC = () => {
  const history = useHistory();
  const [currentForm, setCurrentForm] = useState(-1);
  const [sortValue, setSortValue] = useState<ISortValue>();
  const [searchValue, setSearchValue] = useState('');
  const formValues = useRef(NEW_DT_APPOINTMENT_SUBTYPE_CONFIG);

  const tableHeaders = DT_APPOINTMENT_SUBTYPE_COLUMNS;

  const { loading, error, data } = useQuery(GET_DT_APPOINTMENT_SUBTYPES, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: superTypedata } = useQuery(GET_APPT_TYPES_CONFIG, {
    variables: {
      eventType: EventTypes.DT
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: salesForceData } = useQuery(GET_SALESFORCE_THERAPY_MAPPINGS, {
    fetchPolicy: 'cache-and-network'
  });

  const addNew = useCallback(
    (data?: TableType) => {
      if (data) formValues.current = data as IDTAppointmentSubType;
      else formValues.current = NEW_DT_APPOINTMENT_SUBTYPE_CONFIG;
      scrollToTop();
      setCurrentForm(formValues.current.id!);
    },
    [setCurrentForm]
  );

  const tableData = useMemo(() => {
    return data
      ? (mapToTableState(
          data.appointmentSubTypesConfig,
          addNew,
          MAPPER_FUNCTION.dtAppointmentSubTypes,
          searchValue,
          sortValue
        ) as IDTAppointmentSubTypeConfig[])
      : [];
  }, [data, addNew, searchValue, sortValue]);

  const onSearch = useCallback(
    value => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const onSort = useCallback(
    (comp: ITableComp, reverse: boolean) => {
      setSortValue({ comp, reverse });
    },
    [setSortValue]
  );

  const onFormClose = useCallback(() => {
    formValues.current = NEW_DT_APPOINTMENT_SUBTYPE_CONFIG;
    setCurrentForm(-1);
  }, [setCurrentForm]);

  const onGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <PushableSideBar
        content={
          <DTAppointmentSubTypesForm
            title={
              formValues.current.id
                ? `Edit ${DT_APPT_SUBTYPE_TITLE}`
                : `New ${DT_APPT_SUBTYPE_TITLE}`
            }
            superTypeList={superTypedata?.appointmentTypesConfig}
            salesForceData={salesForceData?.getSFTherapyMappings}
            initialValues={formValues.current}
            onFormClose={onFormClose}
          />
        }
        visible={currentForm !== -1}
        pageComponent={
          <Content
            loading={loading}
            error={error}
            data={data}
            fullHeight={true}
          >
            {() => (
              <>
                <ConfigurationHeader
                  addNew={addNew}
                  onSearch={onSearch}
                  title={DT_APPT_SUBTYPE_TITLE}
                  onClick={onGoBack}
                  visible={currentForm !== -1}
                />
                <TableComponent
                  headers={tableHeaders}
                  data={tableData}
                  sort={onSort}
                  primarySortedKey={DTAppointmentSubType.title}
                />
              </>
            )}
          </Content>
        }
      />
    </>
  );
};

export default DTAppointmentSubTypeConfig;
