import { formatAttendeeName } from 'utils/format';
import { CancelObserversWrapper } from '../style';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { IEvent } from 'model/v2';
import CancelObserversRow from './CancelObserversRow';

interface Props {
  observationEvent: IEvent[] | undefined;
  eventIndex: number;
  event: IEvent;
}
const CancelObservers = ({ observationEvent, eventIndex, event }: Props) => {
  const [openCollapse, setOpenCollapse] = useState(
    observationEvent !== undefined && observationEvent?.length > 0
      ? [true]
      : [false]
  );
  const handleOpenCollapse = (index: number) => {
    const newOpenCollapseArray = [...openCollapse];
    newOpenCollapseArray[index] = !newOpenCollapseArray[index];
    setOpenCollapse(newOpenCollapseArray);
  };
  const eventTimes = formatDates(event.startDate, event.endDate);
  return (
    <CancelObserversWrapper>
      <div className="observers-list">
        <Grid columns={2} style={{ width: '95%' }}>
          <Grid.Row>
            <Grid.Column width={14}>
              {/* <p className="event-date">
                Event on {eventTimes?.date} at {eventTimes?.time} (
                {observationEvent?.length} Providers)
              </p> */}
              <p
                className="event-date"
                style={{ fontSize: '12px', paddingTop: '3px' }}
              >
                <span>
                  <Icon name="calendar outline" className="time-icon" />
                  {eventTimes?.date}
                </span>
                <span style={{ paddingLeft: '15px', float: 'right' }}>
                  <Icon name="clock outline" className="time-icon" />
                  {eventTimes?.time}
                </span>
              </p>
            </Grid.Column>

            <Grid.Column width={1}>
              {' '}
              {observationEvent!.length >= 1 && (
                <button
                  className="collapsible"
                  onClick={() => handleOpenCollapse(eventIndex)}
                >
                  <Icon
                    name={
                      openCollapse[eventIndex]
                        ? 'triangle down'
                        : 'triangle right'
                    }
                    size="large"
                  ></Icon>
                </button>
              )}
            </Grid.Column>

            {observationEvent?.map((observation, index) => {
              const observerName = formatAttendeeName(
                observation.provider?.firstName,
                observation.provider?.lastName,
                observation.provider?.clinic?.abbreviation,
                observation.provider?.speciality?.abbreviation
              );
              return (
                <>
                  <div>
                    {openCollapse[eventIndex] ? (
                      <CancelObserversRow
                        observerCount={index + 1}
                        observerName={observerName}
                      />
                    ) : null}
                  </div>
                </>
              );
            })}
          </Grid.Row>
        </Grid>
      </div>
    </CancelObserversWrapper>
  );
};

export default CancelObservers;

export const formatDates = (
  start: Date | Moment | undefined,
  end: Date | Moment | undefined
) => {
  if (!start && !end) return null;
  return {
    date: moment(start).format('dddd MMMM DD, YYYY'),
    time: `${moment(start).format('hh:mm a')} - ${moment(end).format(
      'hh:mm a'
    )}`
  };
};
