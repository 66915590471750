export const getAutoFormatOptions = type => {
  let options;
  switch (type) {
    case 'phone': {
      options = {
        numericOnly: true,
        blocks: [3, 3, 4],
        delimiter: '-'
      };
      break;
    }
    case 'ssn': {
      options = {
        numericOnly: true,
        blocks: [3, 2, 4],
        delimiter: '-'
      };
      break;
    }
    case 'zip': {
      options = {
        numericOnly: true,
        blocks: [5]
      };
      break;
    }
    case 'state': {
      options = {
        uppercase: true,
        blocks: [2]
      };
      break;
    }
    default: {
      break;
    }
  }

  return options;
};
