import moment from 'antd/node_modules/moment';
import {
  CalenderEvent,
  AvailabilityEvent
} from 'model/v2/availabilityTemplate';
import { ILocation } from 'model/v2/location';
import {
  FIFTEEN_MINUTES_IN_MS,
  FIVE_MINUTES_IN_MS
} from 'utils/constants/availabilityTemplate';
import { mapArrayOfIdsToObject } from 'utils/mappers/request/Provider';

export const removeOverlappingEvents = (
  updatedEvent: AvailabilityEvent,
  events: AvailabilityEvent[]
) => {
  const OverlappedEvents = getCompleteOverlappedEvents(updatedEvent, events);
  if (OverlappedEvents.length > 0) {
    OverlappedEvents.forEach(event => {
      const index: number = events.indexOf(event);
      events.splice(index, 1);
    });
  }
};

const getCompleteOverlappedEvents = (
  event: AvailabilityEvent,
  events: AvailabilityEvent[]
) => {
  const eventIndex = events.indexOf(event);
  return events.filter((targetEvent: CalenderEvent, index: number) => {
    if (
      event.start.getDate() === targetEvent.start.getDate() &&
      event.start.getTime() === targetEvent.start.getTime() &&
      event.end.getTime() === targetEvent.end.getTime() &&
      index === eventIndex
    ) {
      return null;
    } else {
      return (
        event.start.getDate() === targetEvent.start.getDate() &&
        event.start.getTime() <= targetEvent.start.getTime() &&
        event.end.getTime() >= targetEvent.end.getTime() &&
        index !== eventIndex
      );
    }
  });
};

export const handleUpperTouchingEvents = (
  updatedEvent: AvailabilityEvent,
  events: AvailabilityEvent[]
) => {
  const index = events.indexOf(updatedEvent);
  const indexOfUpperEvent = events.findIndex(
    (targetEvent: AvailabilityEvent, idx: number) => {
      return (
        updatedEvent.start.getDate() === targetEvent.start.getDate() &&
        updatedEvent.start.getTime() < targetEvent.end.getTime() &&
        updatedEvent.end.getTime() > targetEvent.end.getTime() &&
        idx !== index
      );
    }
  );

  if (indexOfUpperEvent > -1) {
    events[indexOfUpperEvent].end = updatedEvent.start;
  }
};

export const handleLowerTouchingEvents = (
  updatedEvent: AvailabilityEvent,
  events: AvailabilityEvent[]
) => {
  const index = events.indexOf(updatedEvent);
  const indexOfLowerEvent = events.findIndex(
    (targetEvent: AvailabilityEvent, idx: number) => {
      return (
        updatedEvent.start.getDate() === targetEvent.start.getDate() &&
        updatedEvent.start.getTime() < targetEvent.start.getTime() &&
        updatedEvent.end.getTime() > targetEvent.start.getTime() &&
        idx !== index
      );
    }
  );

  if (indexOfLowerEvent > -1) {
    events[indexOfLowerEvent].start = updatedEvent.end;
  }
};

export const handleDroppedEvent = (
  UpdatedEvent: AvailabilityEvent,
  events: AvailabilityEvent[]
) => {
  const index = events.indexOf(UpdatedEvent);
  const indexOfOverlappingEvent = events.findIndex(
    (targetEvent: AvailabilityEvent, idx: number) => {
      return (
        UpdatedEvent.start.getDate() === targetEvent.start.getDate() &&
        targetEvent.start.getTime() <= UpdatedEvent.start.getTime() &&
        targetEvent.end.getTime() >= UpdatedEvent.end.getTime() &&
        idx !== index
      );
    }
  );

  if (indexOfOverlappingEvent > -1) {
    if (
      events[indexOfOverlappingEvent].end.getTime() > UpdatedEvent.end.getTime()
    ) {
      const newEvent = {
        ...events[indexOfOverlappingEvent],
        start: UpdatedEvent.end
      };

      events.push(newEvent);
    }
    events[indexOfOverlappingEvent].end = UpdatedEvent.start;
    if (
      events[indexOfOverlappingEvent].start.getTime() ===
      events[indexOfOverlappingEvent].end.getTime()
    ) {
      events.splice(indexOfOverlappingEvent, 1);
    }
  }
};

export const createProviderSubBlocks = (
  events: CalenderEvent[],
  updatedEvent: CalenderEvent,
  data: any,
  defaultLocation: ILocation
) => {
  const idx = events.indexOf(updatedEvent);

  const dayIndex = updatedEvent.start.getDay();
  const startMoment = moment(updatedEvent.start).day(dayIndex);
  const endMoment = moment(updatedEvent.end).day(dayIndex);

  const subBlocksArr: CalenderEvent[] = [];

  let amountCount = 0;
  let nextStart = startMoment.clone();
  let nextEnd = startMoment.add(data.sub_block_length, 'minute');
  const returnedClinics = mapArrayOfIdsToObject(data.clinics);
  while (nextEnd.isSameOrBefore(endMoment)) {
    if (amountCount === data.notes_amount && data.notes_amount > 0) {
      subBlocksArr.push({
        title: 'Notes',
        location: defaultLocation.id!,
        type: 'Notes',
        isAdmin: true,
        start: nextStart.toDate(),
        end: nextEnd.toDate(),
        clinics: returnedClinics?.length === 0 ? undefined : returnedClinics
      });
      amountCount = 0;
      nextStart = nextStart.add(data.notes_duration, 'minute');
      nextEnd = nextEnd.add(data.sub_block_length, 'minute');
    } else {
      subBlocksArr.push({
        title: updatedEvent.title,
        location: updatedEvent.location,
        type: updatedEvent.type,
        isAdmin: updatedEvent.isAdmin,
        start: nextStart.toDate(),
        end: nextEnd.toDate(),
        clinics: returnedClinics?.length === 0 ? undefined : returnedClinics
      });
      amountCount++;
      nextStart = nextStart.add(data.sub_block_length, 'minute');
      nextEnd = getNextEnd(nextEnd, amountCount, data);
    }
  }

  if (
    updatedEvent.end.getTime() >
    subBlocksArr[subBlocksArr.length - 1].end.getTime()
  ) {
    subBlocksArr.push({
      title: amountCount === data.notes_amount ? 'Notes' : updatedEvent.title,
      location:
        amountCount === data.notes_amount
          ? defaultLocation?.id!
          : updatedEvent.location,
      type: updatedEvent.type,
      isAdmin: amountCount === data.notes_amount ? true : updatedEvent.isAdmin,
      start: nextStart.toDate(),
      end: updatedEvent.end
    });
  }
  events.splice(idx, 1, ...subBlocksArr);
  return events;
};

const getNextEnd = (currentEnd: any, index: number, data: any) => {
  if (index % data.notes_amount === 0)
    return currentEnd.add(data.notes_duration, 'minute');
  return currentEnd.add(data.sub_block_length, 'minute');
};

export const removeAllOverlappingEventsWithEditedOneAndAddNewEvent = (
  newEvents: CalenderEvent[],
  data: any,
  dayIndex: number,
  label?: string,
  value?: string
) => {
  const returnedClinics = mapArrayOfIdsToObject(data.clinics);

  const updatedEvent: CalenderEvent = {
    title: label ?? data.appt_type,
    type: value ?? data.appt_type,
    location: data.location,
    isAdmin: data.appt_type !== 'working',
    start: moment(data.start)
      .day(dayIndex)
      .toDate(),
    end: moment(data.end)
      .day(dayIndex)
      .toDate(),
    clinics: returnedClinics?.length === 0 ? undefined : returnedClinics
  };

  newEvents.push(updatedEvent);

  removeOverlappingEvents(updatedEvent, newEvents);
  handleDroppedEvent(updatedEvent, newEvents);
  handleUpperTouchingEvents(updatedEvent, newEvents);
  handleLowerTouchingEvents(updatedEvent, newEvents);
  return updatedEvent;
};

export const durationInMinutes = (end: any, start: any) => {
  return Math.round((((end - start) % 86400000) / 3600000) * 60);
};

export const addMinutes = (date: any, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000);
};

export const checkIfLessThanFifteenMinsAndUpdate = (event: CalenderEvent) => {
  if (event.end.getTime() - event.start.getTime() < FIFTEEN_MINUTES_IN_MS) {
    event.end = moment(event.start)
      .add(FIFTEEN_MINUTES_IN_MS, 'milliseconds')
      .toDate();
  }
};

export const checkIfLessThanFiveMinsAndUpdate = (event: CalenderEvent) => {
  if (event.end.getTime() - event.start.getTime() < FIVE_MINUTES_IN_MS) {
    event.end = moment(event.start)
      .add(FIVE_MINUTES_IN_MS, 'milliseconds')
      .toDate();
  }
};
