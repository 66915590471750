import React from 'react';
import { Button } from 'lib/ui';
import { ContentWrapper } from 'views/components/ui/card/card.style';
import ModalSemantic from 'components/modalSemantic';
import { ModalWrapper, ModalBottom, ModalMessage } from './style';
import { templateActions } from 'utils/constants/availabilityTemplate';

interface IProps {
  onModalClose(): void;
  onModalSubmit(): void;
  modalState: boolean;
  isActive: boolean;
  disabled: boolean;
  getModalMessage(): JSX.Element;
}

const DeactivateTemplateModal: React.FC<IProps> = ({
  modalState,
  onModalClose,
  isActive,
  getModalMessage,
  onModalSubmit,
  disabled
}) => {
  return (
    <ContentWrapper>
      <ModalSemantic
        open={modalState}
        onClose={onModalClose}
        modalWidth={500}
        title={isActive ? 'Deactivate template' : 'Reactivate template'}
        trigger={<div></div>}
        content={
          <ModalWrapper>
            <ModalMessage>{getModalMessage()}</ModalMessage>
            <ModalBottom>
              <Button type="" onClick={onModalClose}>
                Cancel
              </Button>
              <Button type="submit" onClick={onModalSubmit} disabled={disabled}>
                {isActive
                  ? templateActions.DEACTIVATE
                  : templateActions.REACTIVATE}
              </Button>
            </ModalBottom>
          </ModalWrapper>
        }
      />
    </ContentWrapper>
  );
};

export default DeactivateTemplateModal;
