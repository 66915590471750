import React, { useState } from 'react';
import { RadioGroup, Datepicker, Button } from 'lib/ui';
import { Radio } from 'antd';
import {
  CaretRightOutlined,
  CaretLeftOutlined,
  CalendarFilled
} from '@ant-design/icons';
import { CalendarToolbar } from '../../NewAppointment.style';
import { todayMoment, dayMoment } from 'utils/format';
import PropTypes from 'prop-types';

const setPickerDay = (day, setPickerOpen, setDate, pickerOpen) => {
  setPickerOpen(!pickerOpen);
  setDate(day._d);
};

const Toolbar = ({ data, setDate }) => {
  const {
    localizer: { messages },
    label,
    onNavigate,
    view,
    date
  } = data;

  const [pickerOpen, setPickerOpen] = useState(false);

  const day = dayMoment(date);

  const todayClassname = 'secondary ' + (todayMoment === day && 'active');

  return (
    <CalendarToolbar>
      <div className="calendar-datepicker">
        <CalendarFilled onClick={() => setPickerOpen(!pickerOpen)} />
        <Datepicker
          open={pickerOpen}
          onChange={e => setPickerDay(e, setPickerOpen, setDate, pickerOpen)}
        />
      </div>
      <div className="calendar-nav">
        <div className="calendar-nav__today">
          <Button
            className={todayClassname}
            onClick={() => onNavigate('TODAY')}
          >
            {messages.today}
          </Button>
        </div>
        <RadioGroup>
          <Radio.Button value="prev" onClick={() => onNavigate('PREV')}>
            <CaretLeftOutlined />
          </Radio.Button>
          <span className="calendar-nav__title">
            {view === 'day' ? day : label}
          </span>
          <Radio.Button value="next" onClick={() => onNavigate('NEXT')}>
            <CaretRightOutlined />
          </Radio.Button>
        </RadioGroup>
      </div>
    </CalendarToolbar>
  );
};

Toolbar.propTypes = {
  data: PropTypes.object,
  setDate: PropTypes.func
};

export default Toolbar;
export { Toolbar };
