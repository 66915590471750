import { TableType } from 'model/tableType';
import UnderlineTitle from 'views/components/underlineTitle';
import {
  IDTAppointmentSubType,
  DTAppointmentSubType
} from 'model/DTAppointmentSubType';
import { convertMinsToTimeFormat } from 'utils/format';
import { IDTAppointmentSubTypeConfig } from 'model/tableConfigurations';

export const mapDTAppoitmentSubTypes = (
  data: TableType[],
  addNew: (data?: TableType) => void
): IDTAppointmentSubTypeConfig[] =>
  (data as IDTAppointmentSubType[]).map(item => ({
    title: (
      <UnderlineTitle
        title={item[DTAppointmentSubType.title]}
        onClick={() => {
          addNew(item);
        }}
      />
    ),
    id: item[DTAppointmentSubType.id],
    athenaId: item.athenaId,
    crName: item.crName,
    crType: item.crType,
    duration: convertMinsToTimeFormat(item.duration),
    superType: item.superType.value,
    salesForceMapping: item[DTAppointmentSubType.salesForceMapping]
      ? item[DTAppointmentSubType.salesForceMapping]?.salesforceTherapyTitle
      : null
  }));
