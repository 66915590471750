import { useQuery } from 'react-apollo';
import { GET_DELETE_WHEN_CANCEL_APPOINTMENTS_IDS } from '../queries/AppointmentTypes';
import { GET_BULK_RECURRING_EVENTS_COUNT } from '../queries/Events';

export const useBulkRecurringEventsCount = (
  eventsId: (number | undefined)[],
  skip?: boolean
) => {
  const {
    loading: recurringBulkEventsCountLoading,
    data: recurringBulkEventsCount
  } = useQuery(GET_BULK_RECURRING_EVENTS_COUNT, {
    variables: { ids: eventsId },
    fetchPolicy: 'cache-and-network',
    skip: skip || !eventsId
  });

  return { recurringBulkEventsCountLoading, recurringBulkEventsCount };
};

export const useDeleteWhenCancelAppointmentsIds = (skip?: boolean) => {
  const {
    loading: deleteWhenCancelAppointmentsIdsLoading,
    data: deleteWhenCancelAppointmentsIds
  } = useQuery(GET_DELETE_WHEN_CANCEL_APPOINTMENTS_IDS, {
    fetchPolicy: 'cache-and-network',
    skip: skip
  });

  return {
    deleteWhenCancelAppointmentsIdsLoading,
    deleteWhenCancelAppointmentsIds
  };
};
