import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { GET_CLINICS_OPTIONS } from 'api/graphql/v2/queries/Clinics';
import { IClinicOptionsList } from 'model/clinic';

export const useGetClinicsHook = () => {
  const { data } = useQuery(GET_CLINICS_OPTIONS);
  const clinicsMap = useMemo(() => {
    const map = new Map();
    (data?.clinicOptionsList as IClinicOptionsList[])?.forEach(item => {
      map.set(item.id, {
        id: item.id,
        name: item.name,
        abbreviation: item.abbreviation,
        state: item.state
      });
    });
    return map;
  }, [data]);

  return clinicsMap;
};
export default useGetClinicsHook;
