import { EyeOutlined } from '@ant-design/icons';
import { Avatar } from '@material-ui/core';
import { Grid } from 'semantic-ui-react';
import { DEFAULT_DOCTOR_IMAGE } from 'utils/constants/default';
interface Props {
  observerCount: number;
  observerName: String;
}

const CancelObserversRow = ({ observerCount, observerName }: Props) => {
  return (
    <div className="attendees">
      <div className="staff-bullet" />
      <p className="attende-name">
        <Grid>
          <Grid.Row>
            <Grid.Column className="avatar-cls">
              <Avatar src={DEFAULT_DOCTOR_IMAGE} />
            </Grid.Column>
            <Grid.Column className="attende-name-cls">
              {observerName}
            </Grid.Column>
            <Grid.Column className="button-column-cls">
              <button className={`observer-btn observer${observerCount}-btn`}>
                <EyeOutlined /> Observer {observerCount}
              </button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </p>
    </div>
  );
};

export default CancelObserversRow;
