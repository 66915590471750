import { UPDATE_CARE_PLAN } from 'api/graphql/mutations/careplan';
import { useEffect, useMemo } from 'react';
import { useMutation } from 'react-apollo';

const useUpdateWaitList = () => {
  const [updateCarePlan] = useMutation(UPDATE_CARE_PLAN);
  const handleWaitlistReasonChange = async (
    value: string,
    carePlanId: string,
    requested: number,
    updatedBookingDetails: string,
    setTableData: any,
    setSidebarData: any,
    reasonsArr: string[]
  ) => {
    let shouldUpdateCount = false;
    setTableData((prevTableData: any) => {
      const updatedTableData = prevTableData.map((item: any) => {
        if (item.carePlanId === carePlanId) {
          setSidebarData({
            ...item,
            waitListReason: value,
            bookingDetails: updatedBookingDetails,
            waitListRequested: requested || null,
            requested: requested || null
          });
          return {
            ...item,
            waitListReason: value,
            bookingDetails: updatedBookingDetails,
            waitListRequested: requested || null,
            requested: requested || null
          };
        }

        return item;
      });
      if (reasonsArr.length > 0) {
        return updatedTableData.filter((item: any) => {
          if (reasonsArr.includes(item.waitListReason)) {
            return true;
          } else {
            shouldUpdateCount = true;
            return false;
          }
        });
      }
      return updatedTableData;
    });
    try {
      await updateCarePlan({
        variables: {
          data: {
            carePlanId: carePlanId,
            waitListReason: value,
            requested: requested ?? null
          }
        }
      });
      return shouldUpdateCount;
    } catch (error) {
      console.error('Error updating waitlist reason:', error);
      return false;
    }
  };

  const handleCPStatusChange = async (
    value: string,
    carePlanId: string,
    setTableData: any,
    checkedStatusArr: string[]
  ) => {
    let shouldUpdateCount = false;
    setTableData((prevTableData: any) => {
      const updatedTableData = prevTableData.map((item: any) => {
        if (item.carePlanId === carePlanId) {
          return {
            ...item,
            cpStatus: value
          };
        }

        return item;
      });
      if (checkedStatusArr.length > 0) {
        return updatedTableData.filter((item: any) => {
          if (checkedStatusArr.includes(item.cpStatus)) {
            return true;
          } else {
            shouldUpdateCount = true;
            return false;
          }
        });
      }
      return updatedTableData;
    });
    try {
      await updateCarePlan({
        variables: {
          data: {
            carePlanId: carePlanId,
            cpStatus: value
          }
        }
      });
      return shouldUpdateCount;
    } catch (error) {
      console.error('Error updating Care Plan Status:', error);
      return false;
    }
  };

  return { handleWaitlistReasonChange, handleCPStatusChange };
};
const useSessionOptions = (max: number) => {
  return useMemo(() => {
    let options = [];
    for (let i = 0; i <= max; ++i) {
      options.push({
        label: `${i} ${i === 1 ? 'session' : 'sessions'}`,
        value: i
      });
    }
    return options;
  }, [max]);
};

const useCloseSelectDropDown = (className: string) => {
  return useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.querySelector(className);
      if (scrollContainer && scrollContainer.scrollLeft !== 0) {
        // Close all Select dropdowns when the container is scrolled horizontally
        const selects: any = scrollContainer.querySelectorAll(
          '.ant-select-open .ant-select-selection-search-input'
        );
        selects?.forEach((select: any) => {
          select.blur();
        });
      }
    };

    // Attach scroll event listener to the scrollable container
    const scrollContainer = document.querySelector(className);
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [className]);
};

export { useUpdateWaitList, useSessionOptions, useCloseSelectDropDown };
