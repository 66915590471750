import gql from 'graphql-tag';

export const PUT_APPOINTMENT_TYPE = gql`
  mutation($appointmentType: AppointmentTypeInput!) {
    putAppointmentType(appointmentType: $appointmentType) {
      id
    }
  }
`;

export const PUT_ABA_APPOINTMENT_SUBTYPE = gql`
  mutation($settingsWithAppointment: ABASettingInput!) {
    putABAAppointmentSubType(
      settingsWithAppointment: $settingsWithAppointment
    ) {
      id
    }
  }
`;

export const PUT_DT_APPOINTMENT_SUBTYPE = gql`
  mutation($settingsWithAppointment: DTSettingInput!) {
    putDTAppointmentSubType(settingsWithAppointment: $settingsWithAppointment) {
      id
    }
  }
`;

export const PUT_MED_APPOINTMENT_SUBTYPE = gql`
  mutation($settingsWithAppointment: MEDSettingInput!) {
    putMEDAppointmentSubType(
      settingsWithAppointment: $settingsWithAppointment
    ) {
      id
    }
  }
`;
