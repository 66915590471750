import React from 'react';
import { FormItem, Input } from 'views/containers/form';
import { Row } from 'antd';
import { FastField } from 'formik';

export const ClientLegalGuardianForm = React.memo(({ disabledFormFields }) => {
  return (
    <>
      <div className="form-title">Legal Guardian</div>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem label="Last Name" xs={16} sm={6}>
          <FastField
            name="profile.legalGuardianLastName"
            render={({ field }) => (
              <Input
                name={field.name}
                value={field.value}
                placeholder="Last Name"
                onChange={field.onChange}
                disabled={disabledFormFields.legalGuardianLastName}
              />
            )}
          />
        </FormItem>
        <FormItem label="First Name" xs={16} sm={6}>
          <FastField
            name="profile.legalGuardianFirstName"
            render={({ field }) => (
              <Input
                name={field.name}
                value={field.value}
                placeholder="First Name"
                onChange={field.onChange}
                disabled={disabledFormFields.legalGuardianFirstName}
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  );
});
