import { TableType } from 'model/tableType';

export interface ITableColumn {
  label: string;
  key: string;
  notWrap?: boolean;
  comp?: ITableComp;
}
export interface ITableRow {
  [key: string]: any;
}

export interface ITableComp {
  sort: (a: TableType, b: TableType) => number;
  reverse: (a: TableType, b: TableType) => number;
}
export enum TableSortDirection {
  descending = 'descending',
  ascending = 'ascending'
}
