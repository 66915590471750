import React from 'react';
import { Grid, Sidebar, Segment } from 'semantic-ui-react';
import { BottomSideBarWrapper } from './sidebar.style';
interface Props {
  content?: JSX.Element;
  pageComponent: JSX.Element;
  visible: boolean;
  displayScroll?: boolean;
}
const BottomSideBar: React.FC<Props> = ({
  content,
  pageComponent,
  visible,
  displayScroll
}: Props) => {
  return (
    <BottomSideBarWrapper displayScroll={displayScroll}>
      <Grid columns={1}>
        <Grid.Column>
          <Sidebar.Pushable as={Segment} style={{ overflow: 'inherit' }}>
            <Sidebar
              as={Segment}
              animation="overlay"
              icon="labeled"
              direction="bottom"
              inverted
              visible={visible}
              width="thin"
            >
              {content}
            </Sidebar>
            <Sidebar.Pusher dimmed={visible}>
              <Segment basic style={{ height: '100%' }}>
                <div style={{ overflowX: 'hidden', height: '100%' }}>
                  {pageComponent}
                </div>
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Grid.Column>
      </Grid>
    </BottomSideBarWrapper>
  );
};

export default React.memo(BottomSideBar);
