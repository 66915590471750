import React from 'react';
import { Grid } from 'semantic-ui-react';
import { ClientDayAvailabilityTimeSlot } from 'model/v2';

interface IProps {
  timeSlot: ClientDayAvailabilityTimeSlot;
  toggleTimeSlot: Function;
  selectedTimeSlots?: ClientDayAvailabilityTimeSlot[];
}

const TimeSlot: React.FC<IProps> = ({
  timeSlot,
  toggleTimeSlot,
  selectedTimeSlots
}: IProps) => {
  const handleTimeSlot = () => {
    toggleTimeSlot(timeSlot);
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column
          className={
            selectedTimeSlots?.find(selected => selected.id === timeSlot.id)
              ? 'time-slot selected-time-slot'
              : 'time-slot'
          }
          onClick={handleTimeSlot}
        >
          {timeSlot.fromLabel} - {timeSlot.toLabel}
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
export default React.memo(TimeSlot);
