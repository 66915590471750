import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { ITableColumn, ITableRow } from './type';

interface ITcellProps {
  headers: ITableColumn[];
  row: ITableRow;
}
const Tcell: React.FC<ITcellProps> = ({ headers, row }: ITcellProps) => (
  <React.Fragment>
    {headers.map((col, index) => (
      <React.Fragment key={index}>
        {col.notWrap && (
          <Table.Cell key={index} collapsing>
            {row[col.key]}
          </Table.Cell>
        )}
        {!col.notWrap && <Table.Cell key={index}>{row[col.key]}</Table.Cell>}
      </React.Fragment>
    ))}
  </React.Fragment>
);

export default Tcell;
