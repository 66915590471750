import { TEMPLATE_DEFAULT_OPTION } from 'components/AvailabilityTemplateModalForm/UpperModal';
import * as yup from 'yup';

interface DropdownOptionsInterface {
  id: number;
  name: string;
}

export const SAVE_AVAILABILITY_TEMPLATE = (
  dropdownOptions: DropdownOptionsInterface[]
) =>
  yup.object().shape({
    templateName: yup
      .string()
      .required('This field is required')
      .test(
        'test unique',
        'There is a template with same name, please use another name',
        function(value) {
          if (value === TEMPLATE_DEFAULT_OPTION.label) return false;
          return !dropdownOptions.some(
            (dropdown: DropdownOptionsInterface) => value === dropdown.name
          );
        }
      ),
    default: yup.string().required('This field is required')
  });
