import { IAddress, IProvider } from '.';
export interface IClinic {
  id?: number;
  name?: string;
  abbreviation?: string;
  centralReachId?: string;
  centralReachPk?: string;
  centralReachLocationId?: string;
  departmentId?: number;
  clinicType?: string;
  medicalGroupId?: string;
  fax?: string;
  phone?: string;
  location?: string;
  timezone?: string;
  unavailableClientId?: string;
  isActive?: boolean;
  clinicHub?: IClinic;
  address?: IAddress;
  clinicBusinessHours?: [IClinicBusinessHours];
  rooms?: IRoom[];
  providers?: IProvider[];
  roomsCount?: number;
  clientsCount?: number;
  providersCount?: number;
}

export enum Days {
  mon = 'mon',
  tues = 'tues',
  weds = 'weds',
  thurs = 'thurs',
  fri = 'fri',
  sat = 'sat',
  sun = 'sun'
}
export enum RoomType {
  medical = 'Medical',
  allPurpose = 'All Purpose',
  gym = 'Gym'
}
export const roomsTypeList = [
  RoomType.medical,
  RoomType.allPurpose,
  RoomType.gym
];
export interface IHoursEditForm {
  id?: number;
  open: string;
  close: string;
  checked: boolean;
  clinic: IClinic;
}

export type IHoursEditFormList = {
  [day in Days]: IHoursEditForm;
};

export interface IClinicForm extends IClinic {
  businessHours?: IHoursEditFormList;
}

export interface IClinicBusinessHours {
  id?: number;
  dayOfWeek?: number;
  startTime?: string;
  endTime?: string;
  clinic?: IClinic;
}
export interface IRoom {
  id?: number;
  email?: string;
  hasInstruments?: boolean;
  hasTV?: boolean;
  hasPhone?: boolean;
  name?: string;
  type?: string;
  seats?: number;
  clinic?: IClinic;
}
