import React from 'react';
import { Checkbox } from 'semantic-ui-react';

interface Props {
  locations: {
    name: string;
    id: number;
  }[];
  checkedItems: Set<Number>;
  onHandleChange: (id: number, isChecked?: boolean) => void;
}

const LocationItems = ({ locations, checkedItems, onHandleChange }: Props) => {
  return (
    <>
      {locations?.length > 0 && (
        <>
          {locations.map(location => {
            return (
              <div className="saved_filters" key={location.id}>
                <Checkbox
                  className="saved-filters_checkbox"
                  label={location.name}
                  name={location.name}
                  value={location.id}
                  checked={checkedItems.has(location.id || 0)}
                  onChange={(_e, data) =>
                    onHandleChange(location.id!, data.checked)
                  }
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export default React.memo(LocationItems);
