import React, { useCallback, useMemo, useState } from 'react';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { CarePlanWrapper } from '../style';
import QuestionIcon from 'assets/img/icon-question.svg';
import FilterSelection from './FilterSelection';
import { Controller, useFormContext } from 'react-hook-form';
import { FilterSelectionWrapper } from './style';
import {
  CLIENT_FILTER_DAYS,
  CLIENT_FILTER_TIME,
  LOCATION_LIST
} from 'utils/constants/lists';
import { STYLE_CLASS } from 'utils/constants/appointmentsTypes';
import { FormItem } from 'views/containers/form';
import { TimePicker } from 'antd';
import { Popover } from 'antd';
import DateRange from './DateRange';
const ClientAvailabilityFilter: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const [preferredDays, setPreferredDays] = useState(new Set());
  const [preferredLocation, setPreferredLocation] = useState(new Set());
  const [preferredTime, setPreferredTime] = useState(new Set());
  const [customRange, setCustomRange] = useState(false);
  const { control, setValue } = useFormContext();

  const filtersData = useMemo(
    () => ({
      daysOptions: CLIENT_FILTER_DAYS,
      locationOptions: [
        {
          text: LOCATION_LIST.inClinic.text,
          value: LOCATION_LIST.inClinic.value
        },
        {
          text: LOCATION_LIST.teleHealth.text,
          value: LOCATION_LIST.teleHealth.value
        },
        { text: LOCATION_LIST.offSite.text, value: LOCATION_LIST.offSite.value }
      ],
      preferredTime: CLIENT_FILTER_TIME
    }),
    []
  );

  const setPreferredDaysFromSelection = useCallback(
    (isChecked: boolean, value: number) => {
      const copy = new Set(preferredDays);
      if (isChecked) {
        copy.add(value);
      } else {
        copy.delete(value);
      }

      const selected = [...copy].map(item =>
        filtersData.daysOptions
          .find(it => it.value === item)
          ?.text?.substring(0, 3)
      );
      setPreferredDays(copy);
      const text =
        selected.length > 0
          ? `Preferred Days:${selected.join(',')}`
          : undefined;
      var toSplitArray = [''];

      if (text !== undefined) {
        toSplitArray = text.split(':');
      }
      setValue('preferredDays', [...copy]);
      setValue(
        'days',
        selected.length > 0 ? (
          <div>
            <span>{toSplitArray[0]}</span>
            <span>: </span>
            <span className="selected-style">{toSplitArray[1]}</span>
          </div>
        ) : (
          <span>Preferred Days</span>
        )
      );
    },
    [preferredDays, setPreferredDays, filtersData.daysOptions, setValue]
  );

  const setPreferredLocationFromSelection = useCallback(
    (isChecked: boolean, value: number) => {
      const copy = new Set(preferredLocation);
      if (isChecked) {
        copy.add(value);
      } else {
        copy.delete(value);
      }
      const selected = [...copy].map(
        item => filtersData.locationOptions.find(it => it.value === item)?.text
      );
      const text =
        selected.length > 0
          ? `Preferred Location:${selected.join(',')}`
          : undefined;
      var toSplitArray = [''];

      if (text !== undefined) {
        toSplitArray = text.split(':');
      }
      setPreferredLocation(copy);
      setValue('preferredLocation', [...copy]);
      setValue(
        'location',
        selected.length > 0 ? (
          <div>
            <span>{toSplitArray[0]}</span>
            <span>: </span>
            <span className="selected-style">{toSplitArray[1]}</span>
          </div>
        ) : (
          <span>Select a Location</span>
        )
      );
    },
    [
      preferredLocation,
      setPreferredLocation,
      filtersData.locationOptions,
      setValue
    ]
  );

  const renderPreferredTime = useCallback(
    (toSplitArray: any, isCustom: boolean) => {
      return (
        <div className="to-truncate">
          <span>{toSplitArray[0]}</span>
          <span>: </span>
          <span className="selected-style">{toSplitArray[1]}</span>
          {setCustomRange(isCustom)}
        </div>
      );
    },
    [setCustomRange]
  );

  const setPreferredTimeFromSelection = useCallback(
    (isChecked: boolean, value: number) => {
      const copy = new Set(preferredTime);
      if (isChecked) {
        copy.add(value);
      } else {
        copy.delete(value);
      }

      const selected = [...copy].map(
        item => filtersData.preferredTime.find(it => it.value === item)?.text
      );

      const selectedRange = [...copy].map(
        item => filtersData.preferredTime.find(it => it.value === item)?.range
      );

      const text =
        selected.length > 0
          ? `Preferred Time:${selected.join(',')}`
          : undefined;
      var toSplitArray = [''];

      if (text !== undefined) {
        toSplitArray = text.split(':');
      }

      setPreferredTime(copy);
      setValue('preferredTime', [
        ...selectedRange.filter(it => it !== undefined)
      ]);
      setValue(
        'time',
        selected.length > 0
          ? toSplitArray[1].includes('Custom Range')
            ? renderPreferredTime(toSplitArray, true)
            : renderPreferredTime(toSplitArray, false)
          : setCustomRange(false)
      );
    },
    [
      preferredTime,
      setPreferredTime,
      filtersData.preferredTime,
      renderPreferredTime,
      setCustomRange,
      setValue
    ]
  );

  const getContent = () => {
    return (
      <FilterSelectionWrapper>
        <Grid>
          <Grid.Row className="header" floated="left" width={60}>
            Filter by Date, Time & Location
          </Grid.Row>
          <Grid.Row>
            <DateRange isSingleAppt={''} />
          </Grid.Row>
          <Grid.Row className="row-style">
            <Grid.Column className="column-style">
              <Controller
                name="days"
                control={control}
                className={STYLE_CLASS.UNSELECTED_STYLE}
                render={props => (
                  <FilterSelection
                    options={filtersData.daysOptions}
                    placeHolderTitle="Preferred Days"
                    field={props}
                    onChange={setPreferredDaysFromSelection}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="row-style">
            <Grid.Column className="column-style">
              <Controller
                name="location"
                control={control}
                render={props => (
                  <FilterSelection
                    options={filtersData.locationOptions}
                    placeHolderTitle="Select a Location"
                    field={props}
                    onChange={setPreferredLocationFromSelection}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="row-style">
            <Grid.Column className="column-style">
              <Controller
                name="time"
                control={control}
                render={props => (
                  <FilterSelection
                    options={filtersData.preferredTime}
                    placeHolderTitle="Select Time of Day"
                    field={props}
                    onChange={setPreferredTimeFromSelection}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
          {customRange ? (
            <Grid.Row>
              <Grid.Column className="start-column">
                <FormItem optional={false} label="Starting">
                  <Controller
                    name="startTimeCustom"
                    control={control}
                    render={() => {
                      return (
                        <TimePicker
                          use12Hours
                          format="hh:mm A"
                          minuteStep={15}
                          showNow={false}
                          onChange={m => setValue('startTimeCustom', m)}
                        />
                      );
                    }}
                  />
                </FormItem>
              </Grid.Column>
              <Grid.Column className="end-column">
                <FormItem optional={false} label="Ending">
                  <Controller
                    name="endTimeCustom"
                    control={control}
                    render={() => {
                      return (
                        <TimePicker
                          use12Hours
                          format="hh:mm A"
                          minuteStep={15}
                          showNow={false}
                          onChange={m => setValue('endTimeCustom', m)}
                        />
                      );
                    }}
                  />
                </FormItem>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <></>
          )}
        </Grid>
        <Controller
          name="preferredDays"
          control={control}
          render={() => <></>}
        />
        <Controller
          name="preferredLocation"
          control={control}
          render={() => <></>}
        />
        <Controller
          name="preferredTime"
          control={control}
          render={() => <></>}
        />
      </FilterSelectionWrapper>
    );
  };
  const content = useMemo(() => {
    return (
      <ul>
        <li>
          We recommend using the Soonest Available date in order to find the
          best match
        </li>
        <li>
          Toggling on the Find Cortica’s first available option will show you
          options for any day of the week, any time, and any location, depending
          on Cortica’s provider availability. Use this option if you do not see
          enough result options
        </li>
      </ul>
    );
  }, []);
  return (
    <CarePlanWrapper>
      <Accordion>
        <Accordion.Title active={true} onClick={handleClick}>
          <Grid>
            <div className="rectangle"></div>
            <Grid.Row className="care-plan" floated="left" width={60}>
              Filter by Client Availability
              <Popover
                placement="bottomLeft"
                content={content}
                title="Client Availability"
                trigger="hover"
              >
                <img
                  alt="ques"
                  src={QuestionIcon}
                  width={22}
                  style={{ paddingLeft: 4 }}
                />
              </Popover>
              <Grid.Column floated="right" width="1" className="arrow">
                {open ? <Icon name="angle up" /> : <Icon name="angle down" />}
              </Grid.Column>
            </Grid.Row>
            <div className="rectangle"></div>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={open} content={getContent()} />
      </Accordion>
    </CarePlanWrapper>
  );
};
export default React.memo(ClientAvailabilityFilter);
