import React, { useMemo, useCallback } from 'react';
import { Button } from 'lib/ui';
import { toWords } from 'number-to-words';
import { WarningModal } from '../../NewAppointment.style';
import SliderItems from './SliderItems';
import { mapToConflict } from '../../../../../../utils/mappers/response/events';
import { SUBMISSION_STATES } from '../../../appointment/submissionReducer';

const ConflictPopup = ({ state, dispatch }) => {
  const conflicts = useMemo(
    () =>
      state?.conflicts ? mapToConflict(state.formData, state.conflicts) : [],
    [state]
  );

  const handleCancel = useCallback(() => {
    dispatch({
      type: SUBMISSION_STATES.START,
      payload: {
        loading: false,
        formData: undefined,
        warnings: undefined,
        conflicts: undefined
      }
    });
  }, [dispatch]);

  const keepSchedule = useCallback(() => {
    dispatch({
      type: SUBMISSION_STATES.SAVE,
      payload: {}
    });
  }, [dispatch]);

  return (
    <WarningModal
      className="conflict-modal"
      destroyOnClose={true}
      visible={true}
      onCancel={handleCancel}
      closeIcon={<>Cancel</>}
      title=""
      footer=""
    >
      <div className="modal-title">Warning</div>
      {
        <>
          <div className="modal-info">
            The series you’ve created has {toWords(conflicts.length)} (
            {conflicts.length}) conflict(s):
          </div>
          <SliderItems events={conflicts} />
        </>
      }
      <div className="button-wrap">
        <Button
          className="secondary"
          onClick={keepSchedule}
          loading={state.loading}
        >
          Keep on Schedule
        </Button>
      </div>
    </WarningModal>
  );
};

export default ConflictPopup;
export { ConflictPopup };
