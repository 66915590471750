import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faUsers,
  faUserMd,
  faHospital,
  faSignOutAlt,
  faClipboard
} from '@fortawesome/free-solid-svg-icons';
import logo from 'assets/img/logo.svg';
import { Button } from 'lib/ui';
import WaitlistIcon from 'views/components/icons/waitlist';
import ConfigurationsIcon from 'views/components/icons/configuration';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';

class UserNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: '',
      name: '',
      email: ''
    };
  }

  toggleDropDown() {
    var dropdown = this.state.dropdown === '' ? 'active' : '';
    this.setState({ dropdown: dropdown });
  }
  handleLogout = async () => {
    await this.state.logout();
  };

  componentDidMount() {
    const { account, logout } = this.props;
    this.setState({
      name: account.name,
      email: account.username,
      logout: logout
    });
  }
  render() {
    return (
      <header className="user-navigation-wrap">
        <div className="inner-wrap">
          <ul className="user-nav-right v-centered">
            <li className="avatar">
              <div
                className="wrap v-centered"
                onClick={this.toggleDropDown.bind(this)}
              >
                {this.state.name && this.state.name.charAt(0)}
              </div>
              <div className={`user-dropdown ${this.state.dropdown}`}>
                <ul>
                  <li>
                    <span className="hi">Welcome back</span>, <br />
                    {this.state.name}{' '}
                    <span className="email">{this.state.email}</span>
                  </li>
                  <li>
                    <Button className="sign-out" onClick={this.handleLogout}>
                      <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
                      Sign Out
                    </Button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul className="user-nav-left v-centered">
            <li className="logo-wrap">
              <NavLink to="/planner">
                <img src={logo} className="logo" alt="logo" />
              </NavLink>
            </li>
            {getFeatureAvailability(FEATURES.CALENDAR_PREVIEW) && (
              <li>
                <NavLink
                  to="/planner"
                  className="eff02"
                  activeClassName="active"
                  title="Calendar"
                >
                  <FontAwesomeIcon icon={faBriefcase} className="icon" />
                  <br />
                  Planner
                </NavLink>
                <span className="underline eff02" />
              </li>
            )}
            <li>
              <NavLink
                to="/clients"
                className="eff02"
                activeClassName="active"
                title="Clients"
              >
                <FontAwesomeIcon icon={faUsers} className="icon" />
                <br />
                Clients
              </NavLink>
              <span className="underline eff02" />
            </li>
            {getFeatureAvailability(FEATURES.AUTHORIZATION) && (
              <li>
                <NavLink
                  to="/auth-therapies"
                  className="eff02"
                  activeClassName="active"
                  title="Auths"
                >
                  <FontAwesomeIcon icon={faClipboard} className="icon" />
                  <br />
                  Auths
                </NavLink>
                <span className="underline eff02" />
              </li>
            )}
            <li>
              <NavLink
                to="/providers"
                className="eff02"
                activeClassName="active"
                title="Providers"
              >
                <FontAwesomeIcon icon={faUserMd} className="icon" />
                <br />
                Providers
              </NavLink>
              <span className="underline eff02" />
            </li>
            <li>
              <NavLink
                to="/clinics"
                className="eff02"
                activeClassName="active"
                title="Clinics"
              >
                <FontAwesomeIcon icon={faHospital} className="icon" />
                <br />
                Clinics
              </NavLink>
              <span className="underline eff02" />
            </li>

            {getFeatureAvailability(FEATURES.CONFIGURATIONS) && (
              <li>
                <NavLink
                  to="/config"
                  className="eff02"
                  activeClassName="active"
                  title="Configuration"
                >
                  <ConfigurationsIcon className="icon" />
                  <br />
                  Configuration
                </NavLink>
                <span className="underline eff02" />
              </li>
            )}
            {getFeatureAvailability(FEATURES.CALENDAR_REDESIGN) && (
              <>
                <li>
                  <NavLink
                    to="/calendar-redesign"
                    className="eff02"
                    activeClassName="active"
                    title="Calendar"
                  >
                    <FontAwesomeIcon icon={faBriefcase} className="icon" />
                    <br />
                    Calendar
                  </NavLink>
                  <span className="underline eff02" />
                </li>
              </>
            )}
            {getFeatureAvailability(FEATURES.WAITLIST) && (
              <li>
                <NavLink
                  to="/waitlist"
                  className="eff02"
                  activeClassName="active"
                  title="Waitlist"
                >
                  <WaitlistIcon className="icon" />
                  <br />
                  Waitlist
                </NavLink>
                <span className="underline eff02" />
              </li>
            )}
          </ul>
        </div>
      </header>
    );
  }
}
UserNavigation.propTypes = {
  account: PropTypes.object.isRequired
};

export default UserNavigation;
