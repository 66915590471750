import * as yup from 'yup';
import moment from 'antd/node_modules/moment';

export const ALTERNATE_AVAILABILITY_TEMPLATE_VALIDATION = yup.object().shape({
  startTemplate: yup.string().required('This field is required'),
  startDate: yup
    .date()
    .required('Start date is required')
    .test('isValidStartDate', 'Start date must begin on a Sunday.', function(
      startDate: Date | undefined
    ) {
      const validateStartDate = moment(startDate).startOf('day');
      return validateStartDate.weekday() === 0;
    }),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), "End date can't be before start date")
    .required('End date is required')
    .test('isValidEndDate', 'End date must end on a Saturday', function(
      endDate: Date | undefined
    ) {
      const validateEndDate = moment(endDate).endOf('day');
      return validateEndDate.weekday() === 6;
    }),
  frequency: yup.string().required('Frequency is required'),
  templateTitle: yup.string().required('Template title is required'),
  description: yup.string()
});
