import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { Formik, FieldArray } from 'formik';
import { Button } from 'lib/ui';
import {
  AuthGroupFormWrapper,
  AuthGroupFormContainer
} from './AuthGroupForm.style';
import Header from 'views/containers/header';
import Content from 'views/components/ui/content';
import AuthIdContainer from './components/AuthIdContainer';
import AuthTherapy from './components/AuthTherapy';
import { CLIENT_PROFILE_TABS } from 'utils/constants/lists';
import { AUTHORIZATION_GROUP_FORM } from 'utils/validators/authorizations';
import {
  mapStringArrayToOptions,
  mapToOption,
  getApptTypesForm
} from 'utils/mappers/form';
import { NEW_AUTH_GROUP, NEW_AUTH_THERAPY } from 'utils/constants/init';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  GET_AUTH_GROUP,
  GET_AUTH_GROUP_INTEGRATION_DATA,
  CLIENT_AUTHORIZED_THERAPIES,
  AUTHORIZED_THERAPIES_FLATS
} from 'api/graphql/queries/authorizations';
import {
  ADD_AUTH_GROUP,
  EDIT_AUTH_GROUP
} from 'api/graphql/mutations/authorizations';
import { mapAuthGroupToFormData } from 'utils/mappers/response/authorizations';
import { mapToAuthGroupBE } from 'utils/mappers/request/authorizations';
import { DEFAULT_AUTH_TAB_PAGE_SIZE } from 'utils/constants/default';
import useGetClinicsHook from 'hooks/GetClinicsHooks';

const AuthGroupForm = ({ match }) => {
  const [dataSource, setDataSource] = useState(NEW_AUTH_GROUP);
  const [changes, setChanges] = useState(false);
  const [apptTypes, setApptTypes] = useState([]);
  const [authTypes, setAuthTypes] = useState([]);
  const [apptTypesOptions, setApptTypesOptions] = useState([]);
  const [quantityPatternsOptions, setQuantityPatternsOptions] = useState([]);
  const [authPeriodOptions, setAuthPeriodOptions] = useState([]);
  const [idErrorMessage, setIdErrorMessage] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const { client } = location.state;
  const { authGroupID } = match.params;
  const duplicateIdErrorMessage = 'Error. This Authorization ID already exists';

  const {
    data: integrationData,
    error: integrationDataError,
    loading: integrationDataLoading
  } = useQuery(GET_AUTH_GROUP_INTEGRATION_DATA, {
    onCompleted: data => {
      setQuantityPatternsOptions(
        mapStringArrayToOptions(data.authTherapyVisitsPatterns)
      );

      setAuthTypes(mapToOption(data.authorizationTypes));

      setAuthPeriodOptions(mapToOption(data.authTherapyPeriodPatterns));

      const { apptTypes, apptTypesOptions } = getApptTypesForm(
        data.appointmentTypes,
        data.appointmentABATypes
      );

      setApptTypes(apptTypes);
      setApptTypesOptions(apptTypesOptions);
    }
  });

  const {
    data: existingAuthGroupData,
    error: existingAuthGroupError,
    loading: existingAuthGroupLoading
  } = useQuery(GET_AUTH_GROUP, {
    variables: { authGroupID: authGroupID },
    skip: !authGroupID
  });

  const refetch = () => [
    {
      query: CLIENT_AUTHORIZED_THERAPIES,
      variables: { clientID: client.id }
    },
    {
      query: AUTHORIZED_THERAPIES_FLATS,
      variables: {
        nextLink: null,
        pageSize: DEFAULT_AUTH_TAB_PAGE_SIZE
      }
    },
    ...(authGroupID
      ? [
          /// to add this on a condition as skip wasn't working on refetch
          {
            query: GET_AUTH_GROUP,
            variables: { authGroupID }
          }
        ]
      : [])
  ];

  const [addAuthGroup] = useMutation(ADD_AUTH_GROUP, {
    refetchQueries: () => refetch()
  });

  const [editAuthGroup] = useMutation(EDIT_AUTH_GROUP, {
    refetchQueries: () => refetch()
  });

  useEffect(() => {
    if (existingAuthGroupData && !isEmpty(apptTypes)) {
      setDataSource(
        mapAuthGroupToFormData(existingAuthGroupData.authGroup, apptTypes)
      );
    }
  }, [existingAuthGroupData, apptTypes]);

  const handleAddAuthTherapies = arrayHelpers => {
    arrayHelpers.push(NEW_AUTH_THERAPY);
    setChanges(true);
  };

  const submit = (values, actions) => {
    actions.setSubmitting(true);
    setIdErrorMessage(null);

    const { mutate, variables } = getRequest(values);

    mutate({ variables }).then(
      res => onSubmitSuccess(res, actions, values),
      error => onSubmitFailure(error, actions)
    );
  };

  const clinicsMap = useGetClinicsHook();

  const getRequest = values => {
    const request = {};
    const commonVariables = {
      authGroup: mapToAuthGroupBE(values, client, apptTypes, clinicsMap)
    };

    if (!authGroupID) {
      request.mutate = addAuthGroup;
      request.variables = commonVariables;
    } else {
      request.mutate = editAuthGroup;
      request.variables = { authGroupID, ...commonVariables };
    }

    return request;
  };

  const onSubmitSuccess = (res, actions, values) => {
    setChanges(false);
    actions.resetForm(values);
    actions.setSubmitting(false);
    history.push({
      pathname: `/clients/${client.id}/`,
      state: {
        client,
        activeKey: CLIENT_PROFILE_TABS().AUTHORIZATION
      }
    });
  };

  const onSubmitFailure = (error, actions) => {
    checkDuplicateIdError(error);
    setChanges(true);
    actions.setSubmitting(false);
  };

  const checkDuplicateIdError = error => {
    error.graphQLErrors.forEach(graphQlError => {
      if (graphQlError.extensions.code === 'DUPLICATE_AUTH_ID') {
        setIdErrorMessage(duplicateIdErrorMessage);
      }
    });
  };
  const loading =
    integrationDataLoading || (authGroupID && existingAuthGroupLoading);
  const error = integrationDataError || (authGroupID && existingAuthGroupError);
  const data = integrationData && (!authGroupID || existingAuthGroupData);

  return (
    <AuthGroupFormWrapper>
      <div className="popup-wrap">
        <Formik
          initialValues={dataSource}
          enableReinitialize
          isInitialValid={AUTHORIZATION_GROUP_FORM(apptTypes).isValidSync(
            dataSource
          )}
          validationSchema={AUTHORIZATION_GROUP_FORM(apptTypes)}
          onSubmit={(values, actions) => submit(values, actions)}
          render={({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValid,
            dirty,
            errors,
            setFieldTouched,
            touched
          }) => (
            <>
              <Header
                title="New Auth Group"
                source={client}
                buttonText="Save Authorization"
                onCancel={() => history.goBack()}
                disabled={(!changes && !dirty) || !isValid}
                handleSubmit={handleSubmit}
                loading={isSubmitting}
              />
              <Content loading={loading} error={error} data={data}>
                {() => (
                  <>
                    <AuthIdContainer
                      values={values}
                      handleChange={handleChange}
                      authTypes={authTypes}
                      isValid={isValid}
                      errorMessage={idErrorMessage}
                    />
                    <AuthGroupFormContainer>
                      <h2>Authorized Therapies</h2>
                      <FieldArray
                        name="authTherapies"
                        render={arrayHelpers => (
                          <>
                            {values.authTherapies.map((_therapy, index) => (
                              <AuthTherapy
                                key={index}
                                values={values}
                                handleChange={handleChange}
                                apptTypesOptions={apptTypesOptions}
                                apptTypes={apptTypes}
                                quantityPatternsOptions={
                                  quantityPatternsOptions
                                }
                                keyForm={index}
                                setChanges={setChanges}
                                authPeriodOptions={authPeriodOptions}
                                authTherapiesArrayHelpers={arrayHelpers}
                                setFieldValue={setFieldValue}
                                isValid={isValid}
                                errors={errors}
                                setFieldTouched={setFieldTouched}
                                touched={touched}
                              />
                            ))}

                            <Button
                              className="button-outline medium edit-button"
                              onClick={() =>
                                handleAddAuthTherapies(arrayHelpers)
                              }
                            >
                              + Add Therapy Limit
                            </Button>
                          </>
                        )}
                      />
                    </AuthGroupFormContainer>
                  </>
                )}
              </Content>
            </>
          )}
        />
      </div>
    </AuthGroupFormWrapper>
  );
};

export default AuthGroupForm;
