import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';
import { FormItem } from 'views/containers/form';

import { Datepicker } from 'api/sharedComponents/reactHookFormComponents';
import { Moment } from 'moment';
import moment from 'antd/node_modules/moment';

interface Props {
  isSingleAppt: any;
}
export const mapToValueOption = (map: any) => {
  const arr: any = [];
  if (map) {
    for (const [key, value] of map) {
      arr.push({ label: value.name, value: key });
    }
  }
  return arr;
};

const DateRange: React.FC<Props> = ({ isSingleAppt }) => {
  const methods = useFormContext();
  const startDateHandler = React.useCallback(
    (val: Moment) => {
      methods.setValue('startDate', val);
      methods.setValue('dirtyEdit', true);
    },
    [methods]
  );
  const endDateHandler = React.useCallback(
    (val: Moment) => {
      methods.setValue('endDate', val);
      methods.setValue('dirtyEdit', true);
    },
    [methods]
  );
  const disabledDate = (current: Date) => {
    return (
      current &&
      moment(current).isBefore(
        moment(methods.getValues('startDate')).add(4, 'weeks')
      )
    );
  };

  return (
    <>
      <Grid.Column className="start-column">
        <FormItem optional={false} label="Start Date">
          <Controller
            name="startDate"
            control={methods.control}
            render={fieldProps => {
              return (
                <Datepicker
                  className="datapicker-style"
                  name="selectedStartDate"
                  placeholder="Soonest Available"
                  format="MM/DD/YYYY"
                  field={fieldProps}
                  onChange={startDateHandler}
                  errors={methods.errors}
                  allowClear={false}
                />
              );
            }}
          />
        </FormItem>
      </Grid.Column>
      <Grid.Column width={4} className="end-column">
        {!isSingleAppt && (
          <FormItem optional={false} label="End Date">
            <Controller
              name="endDate"
              control={methods.control}
              render={fieldProps => {
                return (
                  <Datepicker
                    className="datapicker-style"
                    name="selectedEndDate"
                    placeholder="mm/dd/yyyy"
                    format="MM/DD/YYYY"
                    field={fieldProps}
                    onChange={endDateHandler}
                    errors={methods.errors}
                    allowClear={false}
                    disabledDate={disabledDate}
                  />
                );
              }}
            />
          </FormItem>
        )}
      </Grid.Column>
    </>
  );
};
export default React.memo(DateRange);
