import * as yup from 'yup';

export const ROOMS_FORM_SCHEMA = yup.object().shape({
  name: yup.string().required('Room Name is required'),
  type: yup
    .string()
    .required('Room Type is required')
    .nullable(),
  seats: yup
    .number()
    .typeError('seats must be greater than or equal to 1')
    .required('Seats is required')
    .min(1)
});
