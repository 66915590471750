import { useQuery } from '@apollo/react-hooks';
import { GET_CLIENT_AVAILABILITYV2 } from 'api/graphql/v2/queries/Clients';
import {
  ClientAvailabilityOutput,
  ClientDayAvailabilityOutput
} from 'model/v2';
import { useState } from 'react';

const groupAvailabilityByDay = (
  availabilities: ClientDayAvailabilityOutput[]
) => {
  const availabilityPerDay = new Map<number, ClientDayAvailabilityOutput[]>();
  availabilities.forEach(availability => {
    availabilityPerDay.set(
      availability.dayOfWeek,
      (availabilityPerDay.get(availability.dayOfWeek) || []).concat(
        availability
      )
    );
  });
  return availabilityPerDay;
};

export const useClientAvailability = (clientId: number) => {
  const [clientAvailabilityPerDay, setClientAvailabilityPerDay] = useState<
    Map<number, ClientDayAvailabilityOutput[]>
  >(new Map<number, ClientDayAvailabilityOutput[]>());

  const { loading: isLoadingClientAvailability } = useQuery<
    ClientAvailabilityOutput
  >(GET_CLIENT_AVAILABILITYV2, {
    fetchPolicy: 'no-cache',
    variables: { id: clientId },
    onCompleted: data => {
      setClientAvailabilityPerDay(
        groupAvailabilityByDay(data.clientDayAvailabilities!)
      );
    }
  });

  return {
    isLoadingClientAvailability,
    clientAvailabilityPerDay
  };
};
