import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';
import { eventsFragments } from '../fragments/Events';

export const ADD_EVENT = gql`
  mutation($event: EventInput!) {
    addEvent(event: $event, timezone: "${TIMEZONE}") {
      ...FullEventData
    }
  }
  ${eventsFragments.fullEventData}
`;

export const ADD_MAKEUP_EVENT = gql`
  mutation($event: EventInput!, $cancelledEventId: Float!) {
    addMakeupEvent(event: $event, timezone: "${TIMEZONE}", cancelledEventId: $cancelledEventId) {
      ...FullEventData
    }
  }
  ${eventsFragments.fullEventData}
`;

export const ADD_EVENTS = gql`
  mutation($events: [EventInput!]!) {
    addEvents(events: $events, timezone: "${TIMEZONE}") {
      ...FullEventData
    }
  }
  ${eventsFragments.fullEventData}
`;

export const EDIT_EVENT = gql`
  mutation($data: EventUpdateInput!) {
    editEvent(data: $data, timezone: "${TIMEZONE}") {
      ...FullEventData
    }
  }
  ${eventsFragments.fullEventData}
`;

export const UPDATE_EVENT = gql`
  mutation($data: EventUpdateInput!) {
    updateEvent(data: $data, timezone: "${TIMEZONE}") {
        ...FullEventData
    }
  }
  ${eventsFragments.fullEventData}
`;

export const EDIT_EVENT_TIME = gql`
  mutation($data: EventUpdateInput!) {
    editEventTime(data: $data, timezone: "${TIMEZONE}") {
      ...FullEventData
    }
  }
  ${eventsFragments.fullEventData}
`;

export const CANCEL_EVENT = gql`
  mutation($data: EventUpdateInput!) {
    cancelEvent(data: $data) {
      statusCode
    }
  }
`;

export const CANCEL_OBSERVATION = gql`
  mutation(
    $observationEventId: Int!
    $leadEventId: Int!
    $data: EventObserverCancelInput!
  ) {
    cancelObservation(
      observationEventId: $observationEventId
      leadEventId: $leadEventId
      data: $data
    ) {
      statusCode
    }
  }
`;

export const CANCEL_BULK_EVENTS = gql`
  mutation($data:BulkCancelInput!){
    cancelBulkEvents(data: $data , timezone: "${TIMEZONE}") {
      statusCode
    }
  }
`;
export const CONFIRM_PENDING_EVENT = gql`
  mutation($id: Int!, $masterId: Int!, $isRecurring: Boolean!) {
    confirmAppointment(
      id: $id
      masterId: $masterId
      isRecurring: $isRecurring
      timezone: "${TIMEZONE}"
    ) {
      statusCode
    }
  }
`;

export const RETRY_LAST_SYNC_ACTION = gql`
  mutation($eventId: Int!) {
    retryLastSyncAction(
      eventId: $eventId
      timezone: "${TIMEZONE}"
    ) {
      retryCount
      statusCode
    }
  }
`;

export const FORCE_FAIL_ACTION = gql`
  mutation($eventId: Int!) {
    forceFailAction(
      eventId: $eventId
      timezone: "${TIMEZONE}"
    ) {
      statusCode
    }
  }
`;

export const PUT_OBSERVATION = gql`
  mutation($leadEventId: Int!, $currentObservers: [EventInput!]!) {
    putObservations(
      leadEventId: $leadEventId
      currentObservers: $currentObservers
    ) {
      id
    }
  }
`;
export const PUT_ABA_OBSERVATION = gql`
  mutation($leadEventId: Int!, $currentObservers: [EventInput!]!) {
    putAbaObservations(
      leadEventId: $leadEventId
      currentObservers: $currentObservers
    ) {
      id
    }
  }
`;
