import moment from 'moment-timezone';
import { v4 } from 'uuid';
import { WAITLIST_SMART_SCHEDULING_STATUS } from 'utils/constants/status';

export function mapStatusOptionsEnum(options) {
  return options.map(e => {
    return {
      value: e.id,
      label: e.value
    };
  });
}

export function generateEventsFromAvailability(
  data,
  calendarStartDay = undefined
) {
  const days = Object.keys(data);
  const eventsOfWeek = [];

  days.forEach(day => {
    if (day !== '__typename') {
      const dayOfEvents = data[day];

      if (!!dayOfEvents && Array.isArray(dayOfEvents)) {
        dayOfEvents.forEach(event =>
          mapAvailabilityObject(event, day, calendarStartDay)
        );
        eventsOfWeek.push(...dayOfEvents);
      }
    }
  });

  return eventsOfWeek;
}

export function generateEventsFromBlocked(data) {
  const blocked = data;
  blocked.forEach(event => mapBlockedDateObject(event));
  return blocked;
}

function mapBlockedDateObject(blockedDate) {
  const blockedObject = blockedDate;

  blockedObject.startDate = moment(blockedDate.startDate);
  blockedObject.endDate = moment(blockedDate.endDate);

  return blockedObject;
}

export function mapWaitListClientAvailabilityFilters(
  basicAvailabilityFilter,
  advanceAvailabilityFilter,
  locations
) {
  let [inClinicId, OffsiteId, teleHealthId] = locations?.clientLocations || [];
  let basicFilterDayOfWeek = [0, 1, 2, 3, 4, 5, 6];
  let clientAvailabilityLocationType = [
    { id: 2, type: 'inClinic' },
    { id: 4, type: 'offSite' },
    { id: 1, type: 'teleHealth' }
  ];
  let overAllAvailFilter = [];
  if (advanceAvailabilityFilter.isFilterApplied) {
    let startTime = advanceAvailabilityFilter?.startTime;

    overAllAvailFilter.push({
      isAddtnlAvail: true,
      time: `${moment(advanceAvailabilityFilter.startTime).format(
        'HH:mm A'
      )} - ${moment(advanceAvailabilityFilter.endTime).format('HH:mm A')}`,
      locations: {
        inClinic: advanceAvailabilityFilter?.locations.includes(inClinicId?.id)
          ? 1
          : 0,
        offSite: advanceAvailabilityFilter?.locations.includes(OffsiteId?.id)
          ? 1
          : 0,
        teleHealth: advanceAvailabilityFilter?.locations.includes(
          teleHealthId?.id
        )
          ? 1
          : 0
      },
      days:
        advanceAvailabilityFilter?.days.length > 0
          ? advanceAvailabilityFilter?.days
          : basicFilterDayOfWeek
    });

    if (basicAvailabilityFilter?.length > 0) {
      basicAvailabilityFilter.forEach(selectTimeduration => {
        overAllAvailFilter.push({
          isAddtnlAvail: true,
          time: selectTimeduration.label,
          days: advanceAvailabilityFilter?.days,
          locations: {
            inClinic: advanceAvailabilityFilter?.locations.includes(
              inClinicId?.id
            )
              ? 1
              : 0,
            offSite: advanceAvailabilityFilter?.locations.includes(
              OffsiteId?.id
            )
              ? 1
              : 0,
            teleHealth: advanceAvailabilityFilter?.locations.includes(
              teleHealthId?.id
            )
              ? 1
              : 0
          }
        });
      });
    }
  } else if (basicAvailabilityFilter?.length > 0) {
    basicFilterDayOfWeek = basicFilterDayOfWeek.filter(
      dayVal => !advanceAvailabilityFilter?.day?.includes(dayVal)
    );
    basicAvailabilityFilter.forEach(selectTimeduration => {
      overAllAvailFilter.push({
        isAddtnlAvail: false,
        time: selectTimeduration.label,
        days: basicFilterDayOfWeek
      });
    });
  }

  if (overAllAvailFilter.length > 1) {
    overAllAvailFilter = overAllAvailFilter.filter(item => {
      let times = item.time.split(' - ');
      return times[0] !== times[1];
    });
  }
  return overAllAvailFilter;
}
export function mapWaitListSSStatus(smartSchedulingStatus) {
  let smartSchedulingIds = Object.entries(WAITLIST_SMART_SCHEDULING_STATUS)
    .filter(([key, value]) => {
      return smartSchedulingStatus.includes(value);
    })
    .map(([key, value]) => Number(key));
  return smartSchedulingIds;
}

/*
Example of the object to be returned to the calendar:
----------------------------------------------------
  {
    id: 1,
    title: '',
    start: new Date(...todayDay, 8, 45, 0),
    end: new Date(...todayDay, 11, 30, 0),
    type: 'inClinic',
    isError: false
  }
*/
function mapAvailabilityObject(object, day, calendarStartDay = undefined) {
  const eventObject = object;
  eventObject.isError = false;
  eventObject.title = '';
  const daysToNum = {
    sat: 6,
    sun: 0,
    mon: 1,
    tues: 2,
    wed: 3,
    thurs: 4,
    fri: 5
  };
  const startDay = calendarStartDay
    ? moment(calendarStartDay)
    : moment()
        .startOf('week')
        .format('YYYY-MM-DD') + 'T00:00';

  eventObject.start = new Date(
    moment
      .utc(startDay)
      .startOf('week')
      .add(daysToNum[day], 'days')
      .add(object.startTime.split(':')[0], 'hours')
      .add(object.startTime.split(':')[1], 'minutes')
      .format('YYYY-MM-DDTHH:mm')
  );
  eventObject.end = new Date(
    moment
      .utc(startDay)
      .startOf('week')
      .add(daysToNum[day], 'days')
      .add(object.endTime.split(':')[0], 'hours')
      .add(object.endTime.split(':')[1], 'minutes')
      .format('YYYY-MM-DDTHH:mm')
  );
  eventObject.id = object.id;
  return eventObject;
}

export function generateEventsByDay(savedEvents, id, isActive) {
  const AvailabilityEvents = [];
  savedEvents.forEach(event => {
    AvailabilityEvents.push({
      id: event?.id,
      startTime: moment(event.start).format('HH:mm'),
      endTime: moment(event.end).format('HH:mm'),
      type: event.type,
      dayOfWeek: moment(event.start).day(),
      isActive: isActive
    });
  });
  return AvailabilityEvents;
}

export function generateProviderWorkingHoursEventsByDay(savedEvents) {
  const days = {
    sat: [],
    sun: [],
    mon: [],
    tues: [],
    wed: [],
    thurs: [],
    fri: []
  };
  const events = savedEvents;
  events.forEach(event => {
    let day = moment(event.start)
      .format('ddd')
      .toLowerCase();
    day = day === 'tue' ? 'tues' : day === 'thu' ? 'thurs' : day;

    days[day].push({
      startTime: moment(event.start).format('HH:mm'),
      endTime: moment(event.end).format('HH:mm'),
      type: event.type
    });
  });
  return days;
}
export function generateBlockedDates(savedEvents, id, isActive) {
  return savedEvents.map(e => {
    return handleBlockedDateObject(e, id, isActive);
  });
}

function handleBlockedDateObject(e, id, isActive) {
  const event = {};
  event.id = e?.id;
  event.startDate = moment(e.startDate)
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm');
  event.endDate = moment(e.endDate)
    .endOf('day')
    .format('YYYY-MM-DDTHH:mm');
  event.client = { id: parseInt(id) };
  event.isActive = isActive;
  return event;
}
