import React, { useState, useCallback } from 'react';
import { Row } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { US_STATES_LIST } from 'utils/constants/commonLists';
import { LOCATION_CATEGORY_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { LocationWrapper } from '../../NewAppointment.style';
import { useClientAddressFetcher, useSaveAddress } from './locationHooks';
import { FormItem } from 'views/containers/form';
import { Button } from 'lib/ui';
import {
  Select,
  Input,
  RadioGroup
} from 'api/sharedComponents/reactHookFormComponents';
import { uniqueNameValidator } from './offsiteLocationValidator';
import { formatClientAddress } from 'utils/format';
import Content from 'views/components/ui/content';
import OffsiteLocationAddressSelector from './OffsiteLocationAddressSelector';

const stateOptions = mapToOption(US_STATES_LIST);
const locationCategoryOptions = mapToOption(LOCATION_CATEGORY_LIST);

const OFFSITE_LOCATION_FIELDS = [
  'address.id',
  'address.addressLine1',
  'address.addressLine2',
  'address.state',
  'address.city',
  'address.zipCode',
  'address.name'
];

function OffsiteLocation({ clientId }) {
  const [searchMode, setSearchMode] = useState(false);
  const [canSaveAddress, setCanSaveAddress] = useState(false);
  const { control, setValue, getValues, trigger } = useFormContext();

  const [
    saveAddress,
    { loading: loadingSaveAddress, called: saved }
  ] = useSaveAddress(clientId);

  let {
    clientAddressesOptions,
    clientAddresses,
    clientAddressesLoading,
    clientAddressesError
  } = useClientAddressFetcher(clientId);

  const saveClientAddress = useCallback(async () => {
    const validationResult = await trigger(OFFSITE_LOCATION_FIELDS);

    if (validationResult) {
      const values = getValues();
      uniqueNameValidator(clientAddresses)
        .validate(values.address)
        .then(_ => {
          formatClientAddress([values.address]);
          saveAddress({
            variables: {
              clientAddress: {
                ...values.address,
                client: Number(clientId)
              }
            }
          })
            .then(_ => {
              console.log('location post saved', _, clientAddresses);
              setValue('address.id', _.data.saveClientAddress.id);
              setSearchMode(false);
              setCanSaveAddress(false);
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.log('error in saving ', error);
        });
    }
  }, [trigger, clientId, getValues, clientAddresses, saveAddress]);

  return (
    <LocationWrapper>
      <Row
        type="flex"
        justify="start"
        align="top"
        gutter={16}
        style={{ width: '100%' }}
      >
        <FormItem
          label="Address (Line 1)"
          xs={16}
          sm={12}
          className="address-line-1"
        >
          {/* TODO: refactor this component */}
          <Content
            loading={clientAddressesLoading}
            error={clientAddressesError}
            data={clientAddressesOptions}
          >
            {() => (
              <OffsiteLocationAddressSelector
                clientAddresses={clientAddresses}
                clientAddressesOptions={clientAddressesOptions}
                searchMode={searchMode}
                setSearchMode={setSearchMode}
                control={control}
                setValue={setValue}
                setCanSaveAddress={setCanSaveAddress}
              />
            )}
          </Content>
        </FormItem>
        <FormItem
          optional
          label="Address (Line 2)"
          xs={16}
          sm={12}
          className="address-line-2"
        >
          <Controller
            name="address.addressLine2"
            control={control}
            render={field => <Input field={field} />}
          />
        </FormItem>
      </Row>

      <Row
        type="flex"
        justify="start"
        align="top"
        gutter={16}
        style={{ width: '100%' }}
      >
        <FormItem label="City" xs={16} sm={6}>
          <Controller
            control={control}
            name="address.city"
            render={fieldProps => <Input field={fieldProps} />}
          />
        </FormItem>

        <FormItem label="State" xs={16} sm={6} required>
          <Controller
            name="address.state"
            control={control}
            render={field => (
              <Select
                options={stateOptions}
                placeholder="Select City"
                field={field}
              />
            )}
          />
        </FormItem>

        <FormItem required label="ZIP Code" xs={16} sm={6}>
          <Controller
            name={'address.zipCode'}
            control={control}
            render={fieldProps => (
              <Input field={fieldProps} placeholder={'ZIP Code'} />
            )}
          />
        </FormItem>
      </Row>

      <Row
        type="flex"
        justify="start"
        align="top"
        gutter={16}
        style={{ width: '100%' }}
        className="name_save-btn"
        id="save-appt-address"
      >
        <FormItem label="Name" xs={16} sm={12} required>
          <Controller
            name="address.name"
            control={control}
            render={field => <Input field={field} />}
          />
        </FormItem>
        <FormItem xs={16} sm={6} style={{ marginTop: '22px' }}>
          {(canSaveAddress || saved) && (
            <Button
              className={`secondary ${saved ? 'saved' : null}`}
              disabled={saved || !clientId}
              onClick={saveClientAddress}
              loading={loadingSaveAddress}
            >
              {saved ? 'Saved' : 'Save Address'}
            </Button>
          )}
        </FormItem>
      </Row>

      <FormItem label="Category" xs={24} sm={24} className="category-wrapper">
        <Controller
          name="locationCategory"
          control={control}
          render={fieldProps => (
            <RadioGroup
              options={locationCategoryOptions}
              field={fieldProps}
              setValue={setValue}
            />
          )}
        />
      </FormItem>
      <Controller name="address.id" control={control} render={() => <></>} />
    </LocationWrapper>
  );
}

export default React.memo(OffsiteLocation);
