import React, { useEffect, useMemo, useState } from 'react';

import PLannerWeekGroupWrapper from './style';
import { getPlannerWeekDayLabel } from 'helpers/calendarHelper';
import { mainCalendarPrefDayVar } from 'utils/cache/calendar';
import { FIRST_WEEK_DAY, LAST_WEEK_DAY } from 'utils/constants/planner';

import Group from '../group/group';
import DraggableWeekGroup from './draggableWeekGroup';
import { createPortal } from 'react-dom';
import { useDisableBodyScroll } from 'hooks/disableScrollHook';
import { IPlannerGroup } from 'model/calendar/groups';
import { useReactiveVar } from '@apollo/client';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PROVIDER_WITH_AVAILABILITY } from 'api/graphql/v2/queries/AvailabilityTemplate';
import {
  getClinicsAbbreviation,
  mapDayAvailabilityToCalendarEvent
} from 'utils/mappers/availabilityTemplate';

interface Props {
  group: IPlannerGroup;
  setRemovedGroups: React.Dispatch<React.SetStateAction<number[]>>;
  removedGroups: number[];
  handleAutoFetching: () => void;
  events: any;
}

const PlannerWeekGroup: React.FC<Props> = ({
  group,
  setRemovedGroups,
  removedGroups,
  handleAutoFetching
}: Props) => {
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const [isGroupDragged, setIsGroupDragged] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(false);
  const [availabilityEvents, setAvailabilityEvents] = useState();
  const [clinics, setClinics] = useState();
  const [getProviderWithAvailability, { data }] = useLazyQuery(
    GET_PROVIDER_WITH_AVAILABILITY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setAvailabilityEvents(
          data?.providerWithAvailability?.availability?.dayAvailabilities!
        );
        setClinics(data?.clinicWithItsHub);
      }
    }
  );
  useDisableBodyScroll(isGroupDragged);
  const label = useMemo(() => {
    return group.id === 'DEFAULT'
      ? ''
      : getPlannerWeekDayLabel(
          (group.id?.split(':')[1] as unknown) as number,
          calendarDate
        );
  }, [calendarDate, group.id]);
  const indexOfDay = group.id?.split(':')[1];
  const showFullGroup = useMemo(() => {
    return group.id?.split(':')[1] === FIRST_WEEK_DAY || false;
  }, [group]);
  const providerId = group.id?.split(':')[0];
  const attendeeTypeGroup = group.attendeeType;
  useEffect(() => {
    getProviderWithAvailability({
      variables: {
        id: Number(providerId),
        date: calendarDate
      }
    });
  }, [providerId, calendarDate, getProviderWithAvailability]);
  const dayOfAvailabilites = mapDayAvailabilityToCalendarEvent(
    availabilityEvents!,
    clinics
  );
  const clinicAbbreviation = getClinicsAbbreviation(
    dayOfAvailabilites,
    clinics
  );
  let clinicAbbreviationName: string[];

  const getClinicsAbbreviationName = (attendeeTypeGroup: string) => {
    if (
      indexOfDay &&
      indexOfDay !== undefined &&
      attendeeTypeGroup === 'provider'
    ) {
      clinicAbbreviationName = clinicAbbreviation[parseInt(indexOfDay)];
    }
    if (
      clinicAbbreviation !== undefined &&
      clinicAbbreviationName?.length == 1
    ) {
      return (
        <>
          <p className="day-label-with-clinics">{label}</p>
          <p className="clinic-label">{clinicAbbreviationName}</p>
        </>
      );
    } else if (
      clinicAbbreviation !== undefined &&
      clinicAbbreviationName?.length == 2
    ) {
      return (
        <>
          <p className="day-label-with-clinics">{label}</p>
          <p className="clinic-label">
            {clinicAbbreviationName[0] + '|' + clinicAbbreviationName[1]}
          </p>
        </>
      );
    } else if (
      clinicAbbreviation !== undefined &&
      clinicAbbreviationName?.length == 3
    ) {
      return (
        <>
          <p className="day-label-with-clinics">{label}</p>
          <p className="clinic-label">
            {clinicAbbreviationName[0] +
              '|' +
              clinicAbbreviationName[1] +
              '|' +
              clinicAbbreviationName[2]}
          </p>
        </>
      );
    } else if (
      clinicAbbreviation !== undefined &&
      clinicAbbreviationName?.length == 4
    ) {
      return (
        <>
          <p className="day-label-with-clinics">{label}</p>
          <p className="clinic-label-font-adjusted">
            {clinicAbbreviationName[0] +
              '|' +
              clinicAbbreviationName[1] +
              '|' +
              clinicAbbreviationName[2] +
              '|' +
              clinicAbbreviationName[3]}
          </p>
        </>
      );
    } else if (
      clinicAbbreviation !== undefined &&
      clinicAbbreviationName?.length == 5
    ) {
      return (
        <>
          <p className="day-label-with-clinics">{label}</p>
          <p className="clinic-label-font-adjusted">
            {clinicAbbreviationName[0] +
              '|' +
              clinicAbbreviationName[1] +
              '|' +
              clinicAbbreviationName[2] +
              '|' +
              clinicAbbreviationName[3] +
              '|' +
              clinicAbbreviationName[4]}
          </p>
        </>
      );
    } else if (
      clinicAbbreviation !== undefined &&
      clinicAbbreviationName?.length == 0
    ) {
      return <p className="day-label-with-clinics">{label}</p>;
    } else {
      return <p className="day-label-with-clinics">{label}</p>;
    }
  };
  const ClientProviderDiffHandler = (attendeeTypeGroup: string) => {
    if (attendeeTypeGroup === 'provider') return true;
    else return false;
  };
  const WeekGroupLabel = useMemo(
    () =>
      ClientProviderDiffHandler(attendeeTypeGroup) ? (
        <div className="day-label-wrapper day-provider">
          <p>{getClinicsAbbreviationName(attendeeTypeGroup)}</p>
        </div>
      ) : (
        <div className="day-label-wrapper day-client">
          <p>{getClinicsAbbreviationName(attendeeTypeGroup)}</p>
        </div>
      ),
    [label, clinicAbbreviation]
  );

  const draggableWeekGroup = useMemo(() => {
    return (
      <DraggableWeekGroup
        setIsGroupDragged={setIsGroupDragged}
        isShown={isShown}
        isGroupDragged={isGroupDragged}
        group={group}
        setIsShown={setIsShown}
        setRemovedGroups={setRemovedGroups}
        removedGroups={removedGroups}
        showFullGroup={showFullGroup}
        handleAutoFetching={handleAutoFetching}
      />
    );
  }, [
    group,
    handleAutoFetching,
    isGroupDragged,
    isShown,
    removedGroups,
    setRemovedGroups,
    showFullGroup
  ]);

  return (
    <PLannerWeekGroupWrapper
      type={group?.attendeeType}
      // TODO: check that get in specialtiesMap take the id as number
      specialityColor={group?.speciality?.color}
      isLastDay={group?.id?.split(':')[1] === LAST_WEEK_DAY}
      isGroupDragged={isGroupDragged}
    >
      {/* render dragged group outside dom */}
      {showFullGroup && isGroupDragged ? (
        createPortal(
          <>
            {draggableWeekGroup}
            {WeekGroupLabel}
          </>,
          document.body
        )
      ) : showFullGroup ? (
        <>
          {draggableWeekGroup}
          {WeekGroupLabel}
        </>
      ) : (
        <>
          {/* render day label only */}

          <Group
            cloneGroup={false}
            isShown={isShown}
            group={group}
            setRemovedGroups={setRemovedGroups}
            removedGroups={removedGroups}
            showFullGroup={showFullGroup}
          />
          {WeekGroupLabel}
        </>
      )}
      {/* render placeholder */}
      {isGroupDragged && (
        <>
          <Group
            cloneGroup={true}
            isShown={isShown}
            group={group}
            setRemovedGroups={setRemovedGroups}
            removedGroups={removedGroups}
            showFullGroup={true}
          />
          {WeekGroupLabel}
        </>
      )}
    </PLannerWeekGroupWrapper>
  );
};

export default React.memo(PlannerWeekGroup);
