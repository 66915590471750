import React from 'react';
import { Icon } from 'semantic-ui-react';
import { MESSAGES_TYPES } from 'utils/downstreams/DownStreamModalConfigurations';

interface Props {
  statusType: String;
  statusMessage: String;
}
const StatusAlert: React.FC<Props> = ({ statusType, statusMessage }: Props) => {
  return (
    <p className={`${statusType} statusMessage`}>
      {statusMessage}
      <Icon.Group size="small" className="status-icon">
        {statusType === MESSAGES_TYPES.pending ||
        statusType === MESSAGES_TYPES.cancelling ? (
          <Icon inverted loading color="black" name={'refresh'} />
        ) : (
          <Icon
            inverted
            name={
              statusType === MESSAGES_TYPES.fail
                ? 'warning sign'
                : statusType === MESSAGES_TYPES.success
                ? 'check circle outline'
                : 'eye slash outline'
            }
          />
        )}
      </Icon.Group>
    </p>
  );
};
export default React.memo(StatusAlert);
