import React, { useEffect } from 'react';
import {
  useAppointmentTypes,
  useNewAppointmentMetaData
} from './newAppointmentHooks';
import { GET_CLIENT } from 'api/graphql/queries/clients';
import NewAppointment from './NewAppointment';
import { useLocation } from 'react-router';
import { useParams } from 'react-router';
import { getNewAppointmentDisabledFields } from 'utils/mappers/response/events';
import { useLazyQuery } from 'react-apollo';
import SearchClients from './components/SearchClients/SearchClients';
import ContentLoading from 'views/components/ui/content/Loading';
import { EDIT_EVENT_BEHAVIOR_OPERATION } from 'utils/constants/default';
import NewAppointmentTemplate from './NewAppointmentTemplate';
import { v4 } from 'uuid';
// This is the Main HOC for appointment form creation
// This component responsible for all data fetching and gathering to generate the form
// ONLY HOC FOR PARSING EVENT
export default function NewAppointmentClient() {
  const location = useLocation();
  const { id, operation } = useParams();
  const {
    event,
    thisAndFollowingEvents,
    reason,
    cancelReasonId,
    cancelNote,
    modalContentFor
  } = location.state || {};
  const { apptTypes, loadingApptTypes } = useAppointmentTypes();

  const [
    doRequest,
    { loading: loadingClient, error: errorClient, data: clientResponse }
  ] = useLazyQuery(GET_CLIENT);

  // adjust all condition disabled form fields
  const disabledFormFields = React.useMemo(() => {
    const isEditBehaviorOnly =
      !!id && operation === EDIT_EVENT_BEHAVIOR_OPERATION;
    const reschedule = !!id;
    return getNewAppointmentDisabledFields(reschedule, isEditBehaviorOnly);
  }, [id, operation]);

  const { formEvent } = useNewAppointmentMetaData(
    event,
    apptTypes,
    modalContentFor,
    clientResponse?.client
  );

  useEffect(() => {
    if (event?.client?.id) {
      doRequest({
        variables: {
          id: event?.client.id
        }
      });
    }
  }, [event, doRequest]);

  // loading client error
  if (errorClient) return errorClient;
  // case loading client & appt Types
  if (loadingClient || loadingApptTypes || !Array.isArray(apptTypes))
    return (
      <NewAppointmentTemplate>
        <ContentLoading />
      </NewAppointmentTemplate>
    );
  // client not loaded
  if (!clientResponse?.client)
    return (
      <NewAppointmentTemplate>
        <SearchClients onSelectClient={doRequest} />
      </NewAppointmentTemplate>
    );

  // return if client and appointment types are loaded
  return (
    <div className="popup-wrap">
      <NewAppointment
        key={v4()}
        client={clientResponse.client}
        formEvent={formEvent}
        disabledFormFields={disabledFormFields}
        eventMeta={{
          thisAndFollowingEvents,
          reason,
          cancelReasonId,
          cancelNote,
          authorizedTherapyID: event?.authorizedTherapyID
        }}
        apptTypes={apptTypes}
      />
    </div>
  );
}
