import styled from 'styled-components';
import {
  WHITE,
  PRIMARY_PURPLE,
  OPACITY_GREY,
  BOLD_GREY
} from 'utils/constants/theme';
interface DayCheckboxItemStyleProps {
  isActive: boolean;
  isBordered: boolean;
}

export const DayCheckboxItemWrapper = styled.div<DayCheckboxItemStyleProps>`
  background-color: ${props =>
    props.isActive ? PRIMARY_PURPLE : OPACITY_GREY};
  color: ${props => (props.isActive ? WHITE : BOLD_GREY)};
  padding: 4px 0px;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 100px;
  margin: 5px 3px;
  min-width: 42px;
  text-align: center;
  border: 2px solid ${OPACITY_GREY};
  border-color: ${props => (props.isBordered ? PRIMARY_PURPLE : OPACITY_GREY)};
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
`;

export const DayCheckboxGroupWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${OPACITY_GREY};
  border-radius: 100px;
  width: 345px;
  height: 40;
  margin-left: 6px;
`;
