import 'react-calendar-timeline/lib/Timeline.css';

import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { calendarGroup } from 'model/calendar/groups';
import { useReactiveVar } from '@apollo/client';
import {
  clientsResourcesPrevVar,
  providerResourcesPrevVar
} from 'utils/cache/filters';

import {
  DragDropContext,
  DragUpdate,
  Droppable,
  DroppableProvided,
  DropResult
} from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import { DroppableItem } from './style';

interface Props {
  children: ReactNode;
}
const DroppableTimeLine: React.FC<Props> = ({ children }: Props) => {
  const providers = useReactiveVar(providerResourcesPrevVar);
  const clients = useReactiveVar(clientsResourcesPrevVar);
  const groups = useMemo(() => clients.concat(providers), [clients, providers]);
  const [isDragDisabled, setIsDragDisabled] = useState<boolean>(false);

  const handleGroupDrop = useCallback(
    result => {
      const source = result?.source?.index;
      const destination = result?.destination?.index;

      if (source !== undefined && destination !== undefined) {
        if (
          groups[source]?.attendeeType === groups[destination]?.attendeeType
        ) {
          if (groups[source].attendeeType === calendarGroup.client) {
            const draggedClient = clients.splice(source, 1);
            clients.splice(destination, 0, draggedClient[0]);
            clientsResourcesPrevVar(cloneDeep(clients));
          } else {
            const lastClientIndex = clients.length;
            const providerSourceIndex = source - lastClientIndex;
            const providerDestinationIndex = destination - lastClientIndex;
            const draggedProvider = providers.splice(providerSourceIndex, 1);
            providers.splice(providerDestinationIndex, 0, draggedProvider[0]);

            providerResourcesPrevVar(cloneDeep(providers));
          }
        }
        setIsDragDisabled(false);
      }
    },
    [clients, groups, providers]
  );

  const handleGroupUpdate = useCallback(
    result => {
      const source = result?.source?.index;
      const destination = result?.destination?.index;

      if (source !== undefined && destination !== undefined) {
        if (groups[source]?.attendeeType === groups[destination]?.attendeeType)
          setIsDragDisabled(false);
        else setIsDragDisabled(true);
      }
    },
    [groups]
  );

  return (
    <DragDropContext
      onDragUpdate={(result: DragUpdate) => handleGroupUpdate(result)}
      onDragEnd={(result: DropResult) => handleGroupDrop(result)}
    >
      <Droppable droppableId="groups">
        {(provided: DroppableProvided) => (
          <DroppableItem
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              cursor: isDragDisabled ? 'not-allowed' : ''
            }}
            className="droppable-groups"
          >
            {children}
            {provided.placeholder}
          </DroppableItem>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default React.memo(DroppableTimeLine);
