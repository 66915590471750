/* eslint-disable no-constant-condition */
import moment from 'moment';
import {
  IClient,
  IFullAppointmentType,
  iSmartSingleResultForm,
  SingleSmartResultInterface,
  times
} from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Card } from 'semantic-ui-react';
import SingleResultDescription from './SingleResultDescription';
import { SmartCardFooterWrapper } from './Style';
import { CardWrapperDT } from './Style';
import { getDefaultSingleResultList } from 'utils/mappers/smartSchedule';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SMART_SCHEDULE_EVAL_SELECT_RESULT } from 'utils/validators/smartSchedule';
import { removePhantomEvents, updateCalendarPref } from 'utils/cache/calendar';
// import { removePhantomEvents } from 'utils/cache/calendar';
import { calendarEventsPrefVar } from 'utils/cache/filters';
import { Button } from 'lib/ui';
import SingleResultHeader from './SingleResultHeader';
import { getSubstituteMakeUpPayload } from 'utils/mappers/request/V2/event';
import { useMutation } from 'react-apollo';
import { ADD_MAKEUP_EVENT } from 'api/graphql/v2/mutations/Event';
interface IProps {
  appointmentTypeTitle: string;
  result: any;
  minHours: number;
  offsiteCategory?: string;
  index: number;
  clientId?: number;
  setSelectedResult: Dispatch<
    SetStateAction<SingleSmartResultInterface | undefined>
  >;
  isAba: boolean;
  isDT: boolean;
  hasSingleDT: any;
  client: IClient;
  selectedIndex: number | undefined;
  setSelectedIndex: (val: number | undefined) => void;
  setCurrClient?: Dispatch<SetStateAction<IClient | undefined>>;
  selectedAppointment: string;
  removeUnselectedProviders: () => void;
  setConfirmModel: Dispatch<SetStateAction<boolean>>;
  confirmModel: boolean;
  setCount?: Dispatch<SetStateAction<number>>;
  count?: number;
  event?: any;
  appTypesMap?: Map<number, IFullAppointmentType>;
  setVisible: (val: boolean) => void;
}
const SubstituteMakeupSessionCard: React.FC<IProps> = ({
  result,
  minHours,
  index,
  clientId,
  isAba,
  isDT,
  hasSingleDT,
  client,
  selectedIndex,
  setSelectedIndex,
  // setCurrClient,
  selectedAppointment,
  setSelectedResult,
  removeUnselectedProviders,
  setConfirmModel,
  confirmModel,
  setCount,
  count,
  event,
  appTypesMap,
  setVisible
}: IProps) => {
  console.log('[SubstituteMakeupSessionCard] FETCHED result', result);
  console.log('[SubstituteMakeupSessionCard] PREVIOUS event ', event);
  const defaultEvalResults: iSmartSingleResultForm = useMemo(() => {
    return getDefaultSingleResultList(result, client?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id, result]);

  let addEvents: any;
  [addEvents] = useMutation(ADD_MAKEUP_EVENT);
  console.log(addEvents);

  const methods = useForm<iSmartSingleResultForm>({
    defaultValues: defaultEvalResults,
    resolver: yupResolver(SMART_SCHEDULE_EVAL_SELECT_RESULT()),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  const { watch } = methods;

  const [saving, setSaving] = useState(false);

  const resultValue = watch('result');

  const successCallbackBehavior = useCallback(() => {
    setVisible(false);
    setSaving(false);
    updateCalendarPref(moment(resultValue?.startTime));
  }, [setSaving]);

  const errorCallbackBehavior = useCallback(() => {
    setVisible(false);
    setSaving(false);
    updateCalendarPref(moment(resultValue?.startTime));
  }, [setSaving]);

  console.log(successCallbackBehavior, errorCallbackBehavior);

  useEffect(() => {
    if (selectedIndex === index) {
      setSelectedResult(resultValue);
    }
  }, [
    resultValue.isInClinic,
    resultValue.isOffsite,
    resultValue.clinic.id,
    resultValue.isInVisible,
    resultValue.telehealthLink
  ]);
  useEffect(() => {
    if (selectedIndex === index) {
      removeUnselectedProviders();
      setSelectedResult(resultValue);
    }
  }, [resultValue.startTime]);
  const setCardIndex = () => {
    if (selectedIndex !== index) {
      updateCalendarPref(moment(resultValue.startTime));
      removePhantomEvents(calendarEventsPrefVar());
      setSelectedIndex(index); // index = 8
      setSelectedResult(resultValue);
      removeUnselectedProviders();
    }
  };
  const makeAddEventCall = React.useCallback(async () => {
    setSaving(true);
    const parsedEvent = JSON.parse(JSON.stringify(resultValue));
    const parsedPreviousEvent = JSON.parse(JSON.stringify(event));
    const coursePayload = getSubstituteMakeUpPayload(
      parsedEvent,
      parsedPreviousEvent,
      appTypesMap!
    );
    console.log(
      '[makeAddEventCall] [SubstituteMakeupSessionCard] requestEvent coursepayload',
      coursePayload
    );
    // setSaving(false);
    const request = addEvents({
      variables: {
        event: coursePayload,
        cancelledEventId: parsedPreviousEvent.id
      }
    });
    console.log(request);
    await request
      .then(() => {
        console.log('[makeAddEventCall] success behaviour');
        successCallbackBehavior();
      })
      .catch(() => {
        console.log('[makeAddEventCall] failure behaviour');
        errorCallbackBehavior();
      });
  }, [resultValue]);

  const selectProvider = React.useCallback(() => {
    setConfirmModel(!confirmModel);
  }, [confirmModel, setConfirmModel]);

  const address = `${
    event?.status === 'canceled'
      ? event?.address?.addressLine1
      : result?.address?.addressLine1
      ? result?.address?.addressLine1 + ', '
      : ''
  } ${
    event?.status === 'canceled'
      ? event?.address?.addressLine2
      : result?.address?.addressLine2
      ? result?.address?.addressLine2 + ', '
      : ''
  } ${
    event?.status === 'canceled'
      ? event?.address?.city
      : result?.address?.city
      ? result?.address?.city + ', '
      : ''
  } ${
    event?.status === 'canceled'
      ? event?.address?.state
      : result?.address?.state
      ? result?.address?.state + ', '
      : ''
  } ${
    event?.status === 'canceled'
      ? event?.address?.zipCode
      : result?.address?.zipCode || ''
  }`;

  const eventCategoriesOptions = [
    {
      key: 0,
      value: result.locationCategory,
      text: result.locationCategory
    }
  ];
  const eventAddressesOptions = [
    {
      key: 0,
      value: address,
      text: address
    }
  ];
  const getIntialProviderTime = () => {
    if (hasSingleDT) {
      return result.times[0];
    }
  };
  const [providerTime, setProviderTime] = React.useState<times>(
    getIntialProviderTime()
  );
  return (
    <>
      <CardWrapperDT onClick={setCardIndex}>
        <FormProvider {...methods}>
          <div>
            <Card
              fluid
              style={{
                border:
                  selectedIndex === index
                    ? '1px solid #6f42f5'
                    : '1px solid #c9cfd3'
              }}
            >
              <Card.Content
                header={
                  <SingleResultHeader
                    result={result}
                    setCount={setCount}
                    count={count}
                    providerTime={providerTime}
                    selectedIndex={selectedIndex!}
                    index={index}
                    isMakeupSession={true}
                  />
                }
              />
              <Card.Content
                description={
                  <SingleResultDescription
                    result={result}
                    isCheckedCard={false}
                    index={index}
                    minHours={minHours}
                    clientId={clientId}
                    isAba={isAba}
                    isDT={isDT}
                    hasSingleDT={hasSingleDT}
                    selectedIndex={selectedIndex}
                    selectedAppointment={selectedAppointment}
                    eventCategoriesOptions={eventCategoriesOptions}
                    eventAddressesOptions={eventAddressesOptions}
                    setProviderTime={setProviderTime}
                    providerTime={providerTime}
                  />
                }
              />
              <SmartCardFooterWrapper>
                {event.status === 'canceled' ? (
                  <Button
                    className="large"
                    type="submit"
                    style={{ height: '32px' }}
                    onClick={makeAddEventCall}
                    disabled={selectedIndex !== index || saving}
                    loading={saving}
                  >
                    Schedule
                  </Button>
                ) : (
                  <Button
                    className="large"
                    //onClick={submitForm}
                    onClick={selectProvider}
                    type="submit"
                    //loading={saving}
                    //disabled={saving}
                    style={{ height: '32px' }}
                    disabled={selectedIndex !== index}
                  >
                    Select Provider
                  </Button>
                )}
                {/* <Content loading={saving} data={true} /> */}
              </SmartCardFooterWrapper>
            </Card>
          </div>
        </FormProvider>
      </CardWrapperDT>
    </>
  );
};
export default React.memo(SubstituteMakeupSessionCard);
