import React, { useCallback, useMemo } from 'react';
import { Views } from 'react-big-calendar';
import { BigCalendar } from 'lib/ui';
import Toolbar from './ClientCalendarToolbar';
import { getRange, localizer } from 'utils/builders/calendar';
import { customEventPropGetter } from 'utils/builders/calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Content from 'views/components/ui/content';
import { useAppointmentFormCalendarEvents } from '../../newAppointmentHooks';
import { useFormContext, useWatch } from 'react-hook-form';
import { makeVar, useReactiveVar } from '@apollo/client';
import {
  CALENDAR_SLOT_STEP,
  CALENDAR_TIMESLOTS,
  MAX_CALENDAR_TIME,
  MIN_CALENDAR_TIME
} from 'utils/constants/calendar';

const formats = {
  timeGutterFormat: 'hh:mm',
  dayFormat: 'ddd'
};

export const clientCalendarVar = makeVar(new Date());

const ClientCalendar = ({ client }) => {
  // why calendar date needed
  const { control, setValue } = useFormContext();
  const selectedSlot = useWatch({
    control,
    name: 'selectedSlot'
  });

  const calendarDate = useReactiveVar(clientCalendarVar);

  const {
    loadingClientCalendar,
    comparedEvents: events,
    errorClientCalendar
  } = useAppointmentFormCalendarEvents(client.id, calendarDate);

  const deleteAppointmentHandler = useCallback(() => {
    setValue('selectedSlot', null);
  }, [setValue]);

  const customToolbar = useCallback(data => {
    return <Toolbar data={data} setDate={date => getRange(date, Views.WEEK)} />;
  }, []);

  const CustomEvent = ({ event: { type, start, end } }) => {
    const thirtyMin = 1800000;
    const lessThanThirtyMin = end - start < thirtyMin;
    return type === 'appointment' ? (
      <FontAwesomeIcon
        className="event-close"
        icon={faTimes}
        onClick={deleteAppointmentHandler}
        style={{
          zIndex: 12,
          top: lessThanThirtyMin ? '1px' : '4px',
          height: lessThanThirtyMin ? '9px' : ''
        }}
      />
    ) : (
      <></>
    );
  };

  const comparedEvents = useMemo(() => {
    if (selectedSlot && selectedSlot.start) {
      return [
        ...events,
        {
          type: 'appointment',
          ...selectedSlot,
          id: 'selectedSlotEvent',
          start: new Date(selectedSlot.start),
          end: new Date(selectedSlot.end)
        }
      ];
    }
    return events;
  }, [selectedSlot, events]);

  return (
    <Content
      loading={loadingClientCalendar}
      data={events}
      error={errorClientCalendar}
    >
      {() => (
        <>
          <div className="appointment-sidebar__legend">
            <p>Availability</p>
            <div className="item inclinic">
              <span>In Clinic</span>
            </div>
            <div className="item offsite">
              <span>Off site</span>
            </div>
            <div className="item unavailable">
              <span>Unavailable</span>
            </div>
          </div>
          <BigCalendar
            events={comparedEvents}
            defaultView={Views.WEEK}
            min={MIN_CALENDAR_TIME}
            max={MAX_CALENDAR_TIME}
            step={CALENDAR_SLOT_STEP}
            timeslots={CALENDAR_TIMESLOTS}
            date={new Date(calendarDate)}
            onNavigate={date => {
              clientCalendarVar(date);
            }}
            components={{
              toolbar: customToolbar,
              event: CustomEvent
            }}
            style={{ height: '700px' }}
            formats={formats}
            localizer={localizer}
            eventPropGetter={customEventPropGetter}
            slotEventOverlap={false}
            className="client-calendar"
          />
        </>
      )}
    </Content>
  );
};

export default React.memo(ClientCalendar);
