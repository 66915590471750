import { EventSyncStatus } from 'model/calendar/events';
import { useSubscription } from 'react-apollo';
import { EVENT_STATUS } from 'utils/constants/status';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { SYNC_STATUS_SUBSCRIPTION } from '../subscriptions/Events';

export const compareStatuses = (
  originalStatus?: number,
  subscriptionStatus?: number
) => {
  if (subscriptionStatus === undefined || subscriptionStatus === null)
    return originalStatus;

  return subscriptionStatus;
};
export const useEventSyncStatus = (event: EventSyncStatus) => {
  const { data } = useSubscription(SYNC_STATUS_SUBSCRIPTION, {
    variables: { eventId: event.eventId },
    skip:
      !Number.isInteger(event.eventId) ||
      event?.status === EVENT_STATUS.canceled ||
      !getFeatureAvailability(FEATURES.EVENT_SYNC_STATUS_SUBSCRIPTIONS)
  });
  return {
    eventId: event.eventId,
    crStatus: compareStatuses(event.crStatus, data?.eventSyncStatus?.crStatus),
    athenaStatus: compareStatuses(
      event.athenaStatus,
      data?.eventSyncStatus?.athenaStatus
    )
  };
};
