import { calendarGroup, IPlannerGroup } from 'model/calendar/groups';
import * as colors from 'utils/constants/theme';

export const getAttendeeBulletClassName = (
  group: IPlannerGroup | undefined
) => {
  const className =
    group?.attendeeType === calendarGroup.provider
      ? 'staff-bullet'
      : group?.attendeeType === calendarGroup.client
      ? 'client-bullet'
      : '';
  return className;
};

export const getGroupBackgroundColor = (type: string) => {
  return type === calendarGroup.client
    ? `${colors.LIGHT_PURPLE} !important`
    : `${colors.WHITE} !important`;
};

export const getDraggedGroupBackGroundColor = (
  isGroupDragged: boolean,
  type: string
) => {
  return isGroupDragged
    ? `#fafbfb`
    : type === calendarGroup.client
    ? `${colors.LIGHT_PURPLE} !important`
    : `${colors.WHITE} !important`;
};
