import React, { Dispatch, useContext } from 'react';
import { IEvent } from 'model/v2';
import { SIDEBAR_ACTIONS } from '../AppointmentSidebar';
import { HeaderViewWrapper } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import { IModalDownStreamsConfigurations } from 'utils/downstreams/DownStreamModalConfigurations';
import { CloseOutlined } from '@ant-design/icons';
import { formRedirectHandler } from 'helpers/calendarHelper';
import { EVENT_STATUS } from 'utils/constants/status';
import { plannerContext } from '..';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { isPendingToSyncEvent } from 'utils/validators/BulkCancel';
import { selectedItemToSubstituteVar } from 'utils/cache/calendar';

interface Props {
  setVisible: (val: boolean, shouldRemovePhantom?: boolean) => void;
  event: IEvent;
  handleCancel: () => void;
  configurations: IModalDownStreamsConfigurations;
  isObservation?: boolean;
  setIsAddObservationMode: Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}
const HeaderView: React.FC<Props> = ({
  setVisible,
  event,
  handleCancel,
  configurations,
  isObservation,
  setIsAddObservationMode,
  isLoading
}: Props) => {
  const { isBulkCancelMode } = useContext(plannerContext);
  selectedItemToSubstituteVar(0);
  const isEditDisabled = React.useCallback(() => {
    return (
      configurations.editDisabled ||
      configurations.rescheduleDisabled ||
      isBulkCancelMode
    );
  }, [configurations, isBulkCancelMode]);

  const isCancelDisabled = React.useCallback(() => {
    return configurations.cancelDisabled || isBulkCancelMode;
  }, [configurations, isBulkCancelMode]);

  const editHandler = React.useCallback(() => {
    if (isEditDisabled()) return;
    formRedirectHandler({
      event,
      action: SIDEBAR_ACTIONS.EDIT
    });
  }, [isEditDisabled, event]);

  const cancelClickHandler = React.useCallback(() => {
    if (isCancelDisabled()) return;
    handleCancel();
  }, [handleCancel, isCancelDisabled]);
  const isFailedEvent = (event: IEvent) => {
    if (event?.appointmentType?.eventType?.name === EVENT_TYPES.DT)
      return event?.crStatus! > 200 || event?.athenaStatus! > 200;
    else return event?.crStatus! > 200;
  };

  let isAddObservesFeatureEnabled =
    ((event?.appointmentType?.eventType?.name === EVENT_TYPES.ABA &&
      getFeatureAvailability(FEATURES.ABA_SHADOW_SCHEDULING)) ||
      (event?.appointmentType?.eventType?.name === EVENT_TYPES.DT &&
        getFeatureAvailability(FEATURES.SHADOW_SCHEDULING))) &&
    !isFailedEvent(event) &&
    event?.status !== EVENT_STATUS.canceled &&
    event?.provider?.isObservable &&
    !event?.isPendingConfirmation &&
    !isPendingToSyncEvent(event?.crStatus);
  return (
    <HeaderViewWrapper>
      <h2>Appointment</h2>
      <div className="icons-wrapper">
        {!isLoading && !!event && event?.status !== EVENT_STATUS.canceled && (
          <>
            {isAddObservesFeatureEnabled ? (
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => {
                  setIsAddObservationMode(true);
                }}
              />
            ) : (
              <></>
            )}
            {!isObservation && (
              <FontAwesomeIcon
                style={{
                  cursor: isEditDisabled() ? 'not-allowed' : 'pointer'
                }}
                icon={faPen}
                onClick={editHandler}
              />
            )}
            <FontAwesomeIcon
              style={{
                cursor: isCancelDisabled() ? 'not-allowed' : 'pointer'
              }}
              icon={faBan}
              onClick={cancelClickHandler}
            />
          </>
        )}
        <CloseOutlined
          onClick={() => {
            setVisible(false, true);
          }}
        />
      </div>
    </HeaderViewWrapper>
  );
};
export default React.memo(HeaderView);
