import moment from 'moment';

export const alphabeticalSort = (a, b) => {
  if (a && b && a.toLowerCase() < b.toLowerCase()) return -1;
  if (a && b && a.toLowerCase() > b.toLowerCase()) return 1;
  return 0;
};

export const sortByStartTime = (a, b) => {
  moment(a.startTime).diff(b.startTime) || moment(a.endTime).diff(b.endTime);
};

export const sortNumerical = (a, b) => b - a;

export const sortAttendees = (a, b) => {
  if (a?.clinic?.abbreviation === b?.clinic.abbreviation) {
    return a?.displayName?.localeCompare(b?.displayName);
  }
  return a?.clinic?.abbreviation?.localeCompare(b?.clinic?.abbreviation);
};
