import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalSemantic from 'components/modalSemantic';
import { ICancellationReason, IOption } from 'model';
import React, { useEffect, useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Button, Icon } from 'semantic-ui-react';
import { mapToOption } from 'utils/mappers/form';
import {
  ADMIN_DEFAULT_CANCEL_REASON,
  EVENT_CATEGORY,
  EVENT_CANCEL_OPTIONS,
  ATHENA_DEFAULT_DELETE_REASON,
  APPOINTMENT_CATEGORY,
  GROUPED_CANCEL_OPTIONS
} from 'utils/constants/lists';
import { APPOINTMENT_CANCEL_SCHEMA } from 'utils/validators/calendar';
import { updateCalendarPref } from 'utils/cache/calendar';
import { IEvent } from 'model/v2';
import { CANCEL_EVENT } from 'api/graphql/v2/mutations/Event';
import RecurrentCancelRadioGroup from './RecurrentCancelRadioGroup';
import CancelReasonDropdown from './CancelReasonDropdown';
import CancelNoteTextArea from './CancelNoteTextArea';
import { CancelModalWrapper } from '../style';
import CancelAttendeeRow from 'pages/MainCalendarPage/AppointmentView/AttendeesView/CancelAttendeeRow';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { PURPLE } from 'utils/constants/theme';
import { teal } from '@material-ui/core/colors';
import { formatAttendeeName } from 'utils/format';
import { formatDates } from 'pages/MainCalendarPage/AppointmentView/TimeView';
import CancelObservers from './CancelObservers';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';

interface Props {
  onClose: () => void;
  setVisible: (val: boolean) => void;
  event: IEvent;
  isLastEventInSeries: boolean;
  cancellationReasons: ICancellationReason[];
  recurringEventsCount: number;
  deleteWhenCancelAppointmentsIds: number[];
  observationOfLeadEvent: IEvent[];
}
const CancelModal: React.FC<Props> = ({
  onClose,
  event,
  isLastEventInSeries,
  setVisible,
  cancellationReasons,
  recurringEventsCount,
  deleteWhenCancelAppointmentsIds,
  observationOfLeadEvent
}: Props) => {
  return (
    <ModalSemantic
      open={true}
      onClose={onClose}
      modalWidth={430}
      trigger={<div></div>}
      content={
        <CancelForm
          event={event}
          isLastEventInSeries={isLastEventInSeries}
          onClose={onClose}
          setVisible={setVisible}
          cancellationReasons={cancellationReasons}
          recurringEventsCount={recurringEventsCount}
          deleteWhenCancelAppointmentsIds={deleteWhenCancelAppointmentsIds}
          observationOfLeadEvent={observationOfLeadEvent}
        />
      }
    />
  );
};
export default React.memo(CancelModal);

const CancelForm: React.FC<Props> = ({
  event,
  isLastEventInSeries,
  onClose,
  setVisible,
  cancellationReasons,
  recurringEventsCount,
  deleteWhenCancelAppointmentsIds,
  observationOfLeadEvent
}: Props) => {
  const isAdmin = useMemo(() => event.type === EVENT_CATEGORY[1].value, [
    event.type
  ]);

  const isABA = useMemo(
    () =>
      event.appointmentType?.eventType?.name === APPOINTMENT_CATEGORY[0].value,
    [event.appointmentType?.eventType?.name]
  );
  const eventActionString =
    event.appointmentType?.eventType?.name === APPOINTMENT_CATEGORY[3].value
      ? 'delete'
      : 'cancel';
  let schedulingFeature: any = FEATURES.SHADOW_SCHEDULING;
  if (isABA) {
    schedulingFeature = FEATURES.ABA_SHADOW_SCHEDULING;
  }
  const ShadowFeatureAvailability = getFeatureAvailability(schedulingFeature);
  const isDeleteEvent = useMemo(() => {
    return deleteWhenCancelAppointmentsIds?.includes(
      event.appointmentType?.id!
    );
  }, [deleteWhenCancelAppointmentsIds, event.appointmentType]);

  const methods = useForm({
    defaultValues: {
      recurrent: GROUPED_CANCEL_OPTIONS[0].value,
      cancelReason: isAdmin
        ? ADMIN_DEFAULT_CANCEL_REASON
        : isDeleteEvent
        ? ATHENA_DEFAULT_DELETE_REASON
        : null,
      cancelNote: ''
    },
    resolver: yupResolver(APPOINTMENT_CANCEL_SCHEMA(!isDeleteEvent, false))
  });

  const cancelOptions =
    !event.master?.id ||
    isLastEventInSeries ||
    event.recurrencePattern?.recurringEvery === 0
      ? EVENT_CANCEL_OPTIONS(false, isABA)
      : EVENT_CANCEL_OPTIONS(true, isABA, isAdmin, recurringEventsCount);
  const [cancelReasons, setCancelReasons] = React.useState<IOption[]>([]);

  useEffect(() => {
    const reasons: ICancellationReason[] = cancellationReasons.filter(
      (x: ICancellationReason) => x.type !== 1
    );
    setCancelReasons(
      mapToOption(
        reasons?.map(reason => {
          return {
            id: reason.reasonId,
            value: reason.name
          };
        })
      )
    );
  }, [cancellationReasons]);

  const { recurrent } = useWatch({
    name: ['recurrent'],
    control: methods.control
  });
  const isDeleteOrRecurringEvent = useMemo(() => {
    return isDeleteEvent;
  }, [isDeleteEvent, recurrent]);
  const [saving, setSaving] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(
    GROUPED_CANCEL_OPTIONS[0].value
  );
  const [cancelEvent] = useMutation(CANCEL_EVENT);
  const submitForm = React.useCallback(
    (data: any) => {
      setSaving(true);
      cancelEvent({
        variables: {
          data: {
            event: {
              id: event.id,
              provider: { id: event.provider?.id }
            },
            recurrent: data.recurrent === GROUPED_CANCEL_OPTIONS[2].value,
            cancelReasonId:
              data.cancelReason !== null
                ? data.cancelReason
                : isAdmin
                ? ADMIN_DEFAULT_CANCEL_REASON
                : ATHENA_DEFAULT_DELETE_REASON,
            cancelNote: data.cancelNote || '',
            isHardDeleteCr:
              methods.getValues('recurrent') === 'deleteSingle' ? true : false
          }
        }
      })
        .then(() => {
          setVisible(false);
          updateCalendarPref(moment(event.startDate));
          setSaving(false);
          onClose();
        })
        .catch(() => {
          setSaving(false);
        });
    },
    [setSaving, event, onClose, cancelEvent, setVisible, isAdmin]
  );
  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);
  const { provider, client, observations } = event;
  const providerName = formatAttendeeName(
    provider?.firstName,
    provider?.lastName,
    provider?.clinic?.abbreviation,
    provider?.speciality?.abbreviation
  );

  const clientName = formatAttendeeName(
    client?.firstName,
    client?.lastName,
    client?.clinic?.abbreviation
  );
  const eventTimes = formatDates(
    event.startDate,
    event?.endDate,
    event.isObservation
  );
  return (
    <CancelModalWrapper>
      <h2 className="cancel-modal-title">Confirm Cancellation</h2>
      <p className="cancel-modal-text">
        {' '}
        {`Are you sure you want to ${eventActionString} the selected event?`}
      </p>
      <FormProvider {...methods}>
        {observations &&
        ShadowFeatureAvailability &&
        observations.length > 0 ? (
          <>
            <p className="cancel-modal-cotent">
              Canceling this event will remove the appointment for the provider,
              patient and observers
            </p>
          </>
        ) : (
          ''
        )}
        <div style={{ paddingTop: '10px' }}></div>
        <RecurrentCancelRadioGroup
          cancelOptions={cancelOptions}
          isColorNumbers
          setSelectedOption={setSelectedOption}
        />
        <div className="cancel-modal-attendees-div">
          {observationOfLeadEvent?.length > 0 &&
          selectedOption === EVENT_CANCEL_OPTIONS(true)[1].value ? (
            <>
              <p className="cancel-modal-attendees">Impacted Observers</p>
            </>
          ) : (
            ''
          )}

          {observationOfLeadEvent &&
            ShadowFeatureAvailability &&
            (observationOfLeadEvent.length > 0 &&
            selectedOption === EVENT_CANCEL_OPTIONS(true)[1].value
              ? observationOfLeadEvent.map(
                  (observationEvent: IEvent, index: number) => {
                    return (
                      <CancelObservers
                        observationEvent={observationEvent.observations}
                        eventIndex={index}
                        event={observationEvent}
                      />
                    );
                  }
                )
              : event.observationsCount !== undefined &&
                event.observationsCount > 0 && (
                  <>
                    {/* {observationOfLeadEvent[0] != undefined && (
                      <CancelObservers
                        observationEvent={
                          observationOfLeadEvent[0].observations
                        }
                        eventIndex={0}
                        event={event}
                      />
                    )} */}
                  </>
                ))}
          {observationOfLeadEvent.length > 0 &&
          selectedOption === EVENT_CANCEL_OPTIONS(true)[1].value ? (
            <>
              <div style={{ paddingBottom: '15px' }}></div>
            </>
          ) : (
            ''
          )}
          <p
            className="cancel-modal-attendees"
            style={{ paddingBottom: '15px' }}
          >
            Attendees
          </p>
          {provider && (
            <CancelAttendeeRow
              eventType={event.type!}
              displayName={providerName}
              displayId={provider.id}
              icon={<EyeOutlined />}
              iconTitle={'Provider'}
              color={PURPLE}
              classNameVar={'provider-btn'}
            />
          )}
          {client && (
            <CancelAttendeeRow
              eventType={event.type!}
              displayName={clientName}
              displayId={client.id}
              icon={<UserOutlined />}
              iconTitle={'Patient'}
              color={teal[100]}
              classNameVar={'provider-btn'}
            />
          )}
          {ShadowFeatureAvailability
            ? observations?.map((observer, index) => {
                const observerName = formatAttendeeName(
                  observer.provider?.firstName,
                  observer.provider?.lastName,
                  observer.provider?.clinic?.abbreviation,
                  observer.provider?.speciality?.abbreviation
                );
                return (
                  <CancelAttendeeRow
                    eventType={event.type!}
                    displayName={observerName}
                    displayId={observer.provider?.id}
                    icon={<EyeOutlined />}
                    iconTitle={`Observer ${index + 1}`}
                    color={PURPLE}
                    classNameVar={`provider-btn observer${index + 1}-btn`}
                  />
                );
              })
            : ''}
          <p className="cancel-modal-attendees">Event Details</p>
          <p
            className="event-date"
            style={{ fontSize: '12px', marginBottom: '5px' }}
          >
            <Icon name="calendar outline" className="time-icon" />
            {eventTimes?.date}
          </p>
          <p className="event-time" style={{ fontSize: '12px' }}>
            <Icon name="clock outline" className="time-icon" />
            {eventTimes?.time}
          </p>
        </div>
        <CancelReasonDropdown
          cancelReasons={cancelReasons}
          isDeleteOrAdminEvent={isDeleteOrRecurringEvent}
        />
        <CancelNoteTextArea isDeleteOrAdminEvent={isDeleteOrRecurringEvent} />

        <div className="modal-actions">
          <Button onClick={onClose} basic loading={saving} disabled={saving}>
            No, GO Back
          </Button>
          <Button
            negative
            onClick={methods.handleSubmit(submitForm, submitError)}
            type="submit"
            loading={saving}
            disabled={saving}
          >
            Yes, Cancel
          </Button>
        </div>
      </FormProvider>
    </CancelModalWrapper>
  );
};
