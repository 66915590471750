import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import {
  Datepicker,
  Select
} from 'api/sharedComponents/reactHookFormComponents';
import {
  SCHEDULE_TYPE_LIST,
  EVENT_OCCURENCE,
  PAYMENT_METHODS,
  WEEK_DAYS
} from 'utils/constants/lists';
import moment, { Moment } from 'moment';
import { getAppointmentsEndDate } from 'utils/constants/default';
import { mapToOption } from 'utils/mappers/form';
import { FrequencyFormWrapper } from '../style';
import { SIDEBAR_ACTIONS } from '../../AppointmentSidebar';
import { isRecurringEvent } from 'utils/common';
import { OvalCheckboxGroup } from 'lib/ui';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';

const paymentMethods = mapToOption(PAYMENT_METHODS);
const eventOcurrences = EVENT_OCCURENCE;
interface Props {
  action: string;
  setIsCustom: Dispatch<SetStateAction<boolean>>;
}
const FrequencyForm: React.FC<Props> = ({ action, setIsCustom }: Props) => {
  const methods = useFormContext();
  const {
    smart,
    scheduleType,
    selectedDay,
    recurrencePattern,
    recurringDaysFlag,
    eventTypeName
  } = useWatch({
    name: [
      'smart',
      'scheduleType',
      'selectedDay',
      'recurrencePattern',
      'recurringDaysFlag',
      'eventTypeName'
    ],
    control: methods.control
  });

  eventOcurrences[4].disabled = smart;

  const disabledDate = React.useCallback(
    (current: Moment) => {
      return (
        current.isSameOrBefore((selectedDay || moment()).endOf('day')) ||
        current.isAfter(getAppointmentsEndDate(selectedDay))
      );
    },
    [selectedDay]
  );

  const recurringEveryHandler = React.useCallback(
    (val: string) => {
      if (val === EVENT_OCCURENCE[4].value) {
        methods.setValue('smart', false);
        setIsCustom(true);
      } else {
        methods.setValue('recurrencePattern.recurringDaysArray', null);
        methods.setValue('recurringDaysFlag', !recurringDaysFlag);
        setIsCustom(false);
      }
    },
    [methods, recurringDaysFlag, setIsCustom]
  );

  const recurringUntilHandler = React.useCallback(
    (val: Moment) => {
      methods.setValue('recurrencePattern.recurringUntil', val);
      methods.setValue('recurrencePattern.id', recurrencePattern.id);
    },
    [methods, recurrencePattern]
  );

  const recurringDaysHandler = React.useCallback(
    (val: number[]) => {
      methods.setValue('recurrencePattern.recurringDaysArray', val);
      methods.setValue('recurringDaysFlag', !recurringDaysFlag);
    },
    [methods, recurringDaysFlag]
  );

  return (
    <FrequencyFormWrapper
      className={`${
        eventTypeName === EVENT_TYPES.DT || EVENT_TYPES.MED
          ? 'details-client-clinical'
          : eventTypeName === EVENT_TYPES.ABA
          ? 'details-client-nonClinical'
          : 'details-admin'
      }`}
    >
      <h2 className="section-title">Details</h2>
      <div
        className={`payment-method-Wrapper ${!isRecurringEvent(scheduleType) &&
          'nopayment-method-wrapper'}`}
      >
        {eventTypeName === EVENT_TYPES.ABA && (
          <FormItem
            className="schedule-type"
            optional={false}
            label="Payment Method"
          >
            <Controller
              name="paymentMethod"
              control={methods.control}
              render={fieldProps => (
                <Select
                  className="freq-style"
                  name="paymentMethod"
                  options={paymentMethods}
                  field={fieldProps}
                  errors={methods.errors}
                />
              )}
            />
          </FormItem>
        )}
        <FormItem optional={false} label="Frequency">
          <Controller
            name="scheduleType"
            control={methods.control}
            render={fieldProps => (
              <Select
                className="freq-style"
                placeholder="Select Frequency"
                options={mapToOption(SCHEDULE_TYPE_LIST)}
                field={fieldProps}
                errors={methods.errors}
                disabled={action === SIDEBAR_ACTIONS.EDIT}
              />
            )}
          />
        </FormItem>
      </div>
      {isRecurringEvent(scheduleType) && (
        <>
          <div className="recurrence-pattern-wrapper">
            <Controller
              name="recurrencePattern.id"
              control={methods.control}
              render={() => <></>}
            />
            <FormItem
              optional={false}
              label="Occurrence"
              className="occurence-wrapper"
            >
              <Controller
                name="recurrencePattern.recurringEvery"
                control={methods.control}
                render={fieldProps => (
                  <Select
                    className="freq-style"
                    placeholder="Select Occurrence"
                    options={eventOcurrences}
                    field={fieldProps}
                    errors={methods.errors}
                    disabled={action === SIDEBAR_ACTIONS.EDIT}
                    onSelect={recurringEveryHandler}
                  />
                )}
              />
            </FormItem>

            <FormItem
              optional={false}
              label="End date"
              className={`endDate-wrapper ${recurrencePattern?.recurringEvery !==
                EVENT_OCCURENCE[4].value && 'noDays-endDate-wrapper'}`}
            >
              <Controller
                name="recurrencePattern.recurringUntil"
                control={methods.control}
                render={fieldProps => (
                  <Datepicker
                    className="freq-style "
                    placeholder="mm/dd/yyyy"
                    format="MM/DD/YYYY"
                    field={fieldProps}
                    disabledDate={disabledDate}
                    errors={methods.errors}
                    onChange={recurringUntilHandler}
                    disabled={action === SIDEBAR_ACTIONS.EDIT}
                    allowClear={false}
                  />
                )}
              />
            </FormItem>
          </div>
          {recurrencePattern?.recurringEvery === EVENT_OCCURENCE[4].value && (
            <FormItem optional={false} label="Days" className="daysWrapper">
              <Controller
                name="recurrencePattern.recurringDaysArray"
                control={methods.control}
                render={fieldProps => (
                  <OvalCheckboxGroup
                    options={WEEK_DAYS}
                    field={fieldProps}
                    defaultValue={recurrencePattern?.recurringDaysArray}
                    errors={methods.errors}
                    children={null}
                    onChange={recurringDaysHandler}
                    disabled={action === SIDEBAR_ACTIONS.EDIT}
                  />
                )}
              />
            </FormItem>
          )}
        </>
      )}
    </FrequencyFormWrapper>
  );
};
export default React.memo(FrequencyForm);
