import PropTypes from 'prop-types';
import ProfilePicture from 'views/components/ui/profile/ProfilePicture';
import Actions from './Actions';
import { IProvider } from 'model/v2';
import React from 'react';
import AppointmentTypes from './AppointmentTypes';
import { Grid } from 'semantic-ui-react';
import { ProfileProfileHeaderWrapper as ProviderProfileHeaderWrapper } from './style';
import ProviderInformation from './ProviderInformation';
import PersonalInformation from './PersonalInformation';
interface IProps {
  provider?: IProvider;
}

const Header = ({ provider }: IProps) => (
  <ProviderProfileHeaderWrapper>
    <Grid className="profile-info-wrap">
      <Grid.Row>
        <Grid.Column width={2} className="profile-pic-container">
          <ProfilePicture email={provider?.email} />
        </Grid.Column>
        <PersonalInformation provider={provider} />
        <ProviderInformation provider={provider} />
        <AppointmentTypes provider={provider} />
        <Grid.Column floated="right">
          <Actions provider={provider} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </ProviderProfileHeaderWrapper>
);

Header.propTypes = {
  staffMember: PropTypes.object.isRequired
};

export default React.memo(Header);
