import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'lib/ui';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ROUTE_PARAMS,
  ROUTE_PATHS,
  TARGET_BLANK
} from 'utils/constants/routes';

const ClientProfileActions = ({ client }) => {
  const history = useHistory();

  const editClient = e => {
    e.preventDefault();
    history.push(`/client/edit/${client.id}`);
  };

  const editAvailability = e => {
    e.preventDefault();
    history.push({
      pathname: `/clients/${client.id}/availability`,
      state: { client }
    });
  };

  return (
    <>
      <div className="overall-btn-cont">
        <div>
          <Link
            className="button-fill small"
            to={ROUTE_PATHS.NEW_APPOINTMENT + ROUTE_PARAMS.CLIENT + client?.id}
            target={TARGET_BLANK}
          >
            New Appointment
          </Link>
        </div>
        {/* </li>
      </ul> */}

        <div>
          <Button className="edit-Avail medium" onClick={editAvailability}>
            Edit Availability
          </Button>
        </div>
        <div>
          <Button className="edit-info-btn" onClick={editClient}>
            <p className="edit-info-inner">Edit Client Info</p>
          </Button>
        </div>
      </div>
    </>
  );
};

export default ClientProfileActions;
