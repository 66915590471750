import React, { useCallback, useMemo } from 'react';
import { Button } from 'lib/ui';
import { ModalWarningWrapper } from '../NewAppointment.style';
import { getWarningsSupportanceData } from 'utils/authorizations/event';
import { getEventSubsetConflicts } from 'utils/authorizations/conflicts';
import { isEmpty } from 'lodash';
import { SUBMISSION_STATES } from '../submissionReducer';

const ModalWarning = ({ state, dispatch, reschedule }) => {
  const [
    computedValues,
    createButtonDisabled,
    eventsLen,
    unauthedEventsLen
  ] = useMemo(() => {
    // getting event warnings
    const eventsLen = state.warnings.length;

    // unauthorized length
    const unauthedEventsLen = state.warnings.filter(event =>
      isEmpty(event.authorizedTherapyID)
    ).length;

    if (unauthedEventsLen === 0) {
      if (state.conflicts?.length > 0) {
        dispatch({ type: SUBMISSION_STATES.CONFLICTS });
      } else {
        dispatch({ type: SUBMISSION_STATES.SAVE });
      }
    }

    const { supported, blockStart, blockEnd } = getWarningsSupportanceData(
      state.warnings
    );

    const createButtonDisabled =
      reschedule || !supported || state.formData?.repeat === 0;

    let newValues = { ...state.formData };

    if (!createButtonDisabled) {
      newValues = getEventSubsetConflicts(blockStart, blockEnd, state.formData);
    }

    return [newValues, createButtonDisabled, eventsLen, unauthedEventsLen];
  }, [reschedule, state, dispatch]);

  const handleNext = useCallback(() => {
    if (state.conflicts?.length > 0) {
      dispatch({ type: SUBMISSION_STATES.CONFLICTS });
    } else {
      dispatch({ type: SUBMISSION_STATES.SAVE });
    }
  }, [dispatch, state]);

  const createAuthorizedEvents = useCallback(() => {
    const newFormData = {
      ...state.formData,
      selectedSlot: computedValues.selectedSlot,
      recurringUntil: computedValues.recurringUntil
    };
    if (state.conflicts?.length > 0) {
      dispatch({
        type: SUBMISSION_STATES.CONFLICTS,
        payload: { formData: newFormData }
      });
    } else {
      dispatch({
        type: SUBMISSION_STATES.SAVE,
        payload: { formData: newFormData }
      });
    }
  }, [computedValues, state, dispatch]);

  const handleCancel = useCallback(() => {
    dispatch({
      type: SUBMISSION_STATES.START,
      payload: {
        loading: false,
        formData: undefined,
        warnings: undefined,
        conflicts: undefined
      }
    });
  }, [dispatch]);

  return (
    <ModalWarningWrapper
      visible
      destroyOnClose={true}
      title=""
      footer=""
      onCancel={handleCancel}
      closeIcon={
        <div onClick={handleCancel} className="edit-button-2">
          Cancel
        </div>
      }
      width="777px"
    >
      <h2>Warning</h2>
      <p>
        The appointment(s) you’ve selected will result in {unauthedEventsLen}{' '}
        unauthorized appointment(s).
      </p>
      <div className="button-wrapper">
        <Button
          onClick={handleNext}
          className="button-outline medium edit-button"
        >
          Create All {eventsLen} Appointments
        </Button>
        <Button
          onClick={createAuthorizedEvents}
          className="button-fill large"
          disabled={createButtonDisabled}
        >
          Create Only {eventsLen - unauthedEventsLen} Authorized Appointments
        </Button>
      </div>
    </ModalWarningWrapper>
  );
};

export default ModalWarning;
