import { IS_LAST_EVENT } from 'api/graphql/v2/queries/Events';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  EVENT_RESCHEDULE_OPTIONS,
  SCHEDULE_TYPE_LIST
} from 'utils/constants/lists';
import { RadioGroup } from 'api/sharedComponents/reactHookFormComponents';
import { mapToOption } from 'utils/mappers/form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { TextArea } from 'views/containers/form';
import { ApplyToFormWrapper } from './style';
import { ICancellationReason, IEvent, IOption } from 'model';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { ALL_CANCELLATION_REASONS } from 'api/graphql/v2/queries/CancellationReasons';

interface IProps {
  recurringEventsCount?: number;
  deleteWhenCancelAppointmentsIds?: number[];
  observationCount?: number;
  observationOfLeadEvent?: IEvent[];
  setIsSeriesAbaEvent: Dispatch<SetStateAction<boolean>>;
}

const ApplyToForm: React.FC<IProps> = ({
  recurringEventsCount,
  deleteWhenCancelAppointmentsIds,
  observationCount,
  observationOfLeadEvent,
  setIsSeriesAbaEvent
}: IProps) => {
  const methods = useFormContext();
  const watched = useWatch({
    name: [
      'id',
      'master',
      'recurrencePattern',
      'scheduleType',
      'cancelNote',
      'dirtyEdit',
      'appType',
      'recurrent',
      'eventTypeName'
    ],
    control: methods.control
  });

  const [cancelOptions, setCancelOptions] = React.useState(
    EVENT_RESCHEDULE_OPTIONS(false)
  );
  const [cancelReasons, setCancelReasons] = React.useState<IOption[]>([]);

  const [isSeriesEvent, setIsSeriesEvent] = React.useState<boolean>(
    watched.recurrencePattern?.recurringEvery > 0
  );
  let observersArray: any = [];
  let observersCount: number = 0;
  if (observationOfLeadEvent !== undefined) {
    observationOfLeadEvent!.map(eachEvent => {
      observersArray.push(eachEvent.observations?.length);

      return null;
    });

    observersCount = observersArray.reduce((a: any, b: any) => a! + b!, 0);
  }

  useQuery(IS_LAST_EVENT, {
    variables: {
      id: watched.id,
      masterId: watched.master?.id
    },
    fetchPolicy: 'no-cache',
    skip:
      !watched.master?.id ||
      !isSeriesEvent ||
      watched.scheduleType === SCHEDULE_TYPE_LIST[0].id,
    onCompleted: data => {
      const options =
        !data || data?.isLastEventInSeries
          ? EVENT_RESCHEDULE_OPTIONS(false)
          : EVENT_RESCHEDULE_OPTIONS(true, recurringEventsCount);
      const isSeries =
        data !== undefined &&
        !data?.isLastEventInSeries &&
        watched.recurrencePattern?.recurringEvery !== 0;

      setCancelOptions(options);
      setIsSeriesEvent(isSeries);
    }
  });
  useQuery(ALL_CANCELLATION_REASONS, {
    onCompleted: data => {
      const cancellationReasons: ICancellationReason[] = data.allCancellationReasons.filter(
        (x: any) => x.type !== 0
      );
      setCancelReasons(
        mapToOption(
          cancellationReasons?.map(reason => {
            return {
              id: reason.reasonId,
              value: reason.name
            };
          })
        )
      );
    }
  });

  const isCancelEvent = useMemo(() => {
    return (
      !deleteWhenCancelAppointmentsIds?.includes(watched.appType!) &&
      watched.recurrent === EVENT_RESCHEDULE_OPTIONS(true)[0].value
    );
  }, [deleteWhenCancelAppointmentsIds, watched.appType, watched.recurrent]);

  return !watched.dirtyEdit ? (
    <>
      {isSeriesEvent && cancelOptions.length > 0 && (
        <ApplyToFormWrapper>
          <div className="apply-to">
            <h3>Apply To</h3>
            <label className="label subHeader">Recurrence:</label>
            <Controller
              name="recurrent"
              control={methods.control}
              render={fieldProps => (
                <RadioGroup
                  options={cancelOptions}
                  field={fieldProps}
                  isColorNumbers
                  styleClassName={'customRadioGroup'}
                  customOnChangeHandler={(e: any) => {
                    methods.setValue('recurrent', e.target.value);
                    setIsSeriesAbaEvent(e.target.value === 'all');
                  }}
                />
              )}
            />
          </div>
        </ApplyToFormWrapper>
      )}
    </>
  ) : (
    <>
      {(isSeriesEvent || isCancelEvent) && (
        <ApplyToFormWrapper>
          <div className="reschedule">
            <h3>Reschedule</h3>
            {isSeriesEvent && cancelOptions.length > 0 && (
              <label className="label subHeader">Recurrence:</label>
            )}
            <Controller
              name="recurrent"
              control={methods.control}
              render={fieldProps => (
                <RadioGroup
                  options={cancelOptions}
                  field={fieldProps}
                  isColorNumbers
                  styleClassName={'customRadioGroup'}
                  customOnChangeHandler={(e: any) => {
                    methods.setValue('recurrent', e.target.value);
                    setIsSeriesAbaEvent(e.target.value === 'all');
                    if (
                      EVENT_RESCHEDULE_OPTIONS(true)[1].value === e.target.value
                    ) {
                      methods.trigger('cancelReason');
                    }
                  }}
                />
              )}
            />

            <div
              style={{
                fontFamily: 'Lato',
                fontSize: '13px',
                lineHeight: '22px',
                fontWeight: 'bold',
                padding: '5px 0 5px 0'
              }}
            >
              {watched.recurrent == 'single' ? (
                <>
                  {observationCount !== undefined && observationCount > 0 && (
                    <p style={{ marginBottom: 16 }}>
                      Rescheduling this event, will also affect (
                      <p style={{ color: '#E82424', display: 'inline-block' }}>
                        {observationCount}
                      </p>
                      ) observers.
                    </p>
                  )}
                </>
              ) : (
                <>
                  {observersCount > 0 && (
                    <p style={{ marginBottom: 16 }}>
                      Rescheduling this series events, will also affect (
                      <p style={{ color: '#E82424', display: 'inline-block' }}>
                        {observersCount}
                      </p>
                      ) observers.
                    </p>
                  )}
                </>
              )}
            </div>

            {isCancelEvent &&
              getFeatureAvailability(FEATURES.RESCHEDULE_MODAL_UPDATES) && (
                <>
                  <div className="reason-section">
                    <label>
                      {EVENT_RESCHEDULE_OPTIONS(true)[0].value ===
                      watched.recurrent
                        ? 'Reason'
                        : 'Reason (Optional)'}
                    </label>
                    <Controller
                      name="cancelReason"
                      control={methods.control}
                      render={fieldProps => (
                        <Select
                          showSearch
                          placeholder="Select Reason"
                          defaultValue={cancelReasons[0]?.value}
                          options={cancelReasons}
                          field={fieldProps}
                          filterOption={(
                            input: string | undefined,
                            option: { children: string }
                          ) =>
                            option.children
                              ?.toLowerCase()
                              .includes(input?.toLowerCase() ?? '')
                          }
                          errors={methods.errors}
                        />
                      )}
                    />
                  </div>

                  <div className="reschedule-Notes">
                    <label>Reschedule Notes (Optional)</label>
                    <Controller
                      name="cancelNote"
                      control={methods.control}
                      render={() => (
                        <TextArea
                          name="cancelNote"
                          placeholder="Add Note"
                          value={watched.cancelNote}
                          onChange={(e: any) =>
                            methods.setValue('cancelNote', e.target.value)
                          }
                          errors={methods.errors}
                        />
                      )}
                    />
                  </div>
                </>
              )}
          </div>
        </ApplyToFormWrapper>
      )}
    </>
  );
};
export default React.memo(ApplyToForm);
