import { yupResolver } from '@hookform/resolvers/yup';
import { ICalendarResource, IRecurringReqData } from 'model/calendar/filters';
import {
  IFullAppointmentType,
  IProvider,
  ISmartScheduleOpenings,
  ISmartResultListForm,
  IClient,
  ISmartResultCard,
  ISmartScheduleConsolidatedResults,
  ICarePlanFieldsForSmartScheduling
} from 'model/v2';
import { useRemoveProviderFromCalendar } from 'pages/MainCalendarPage/FormPhantomEvents/CustomPhantomHooks';
import SmartPhantomEvents from 'pages/MainCalendarPage/FormPhantomEvents/SmartPhantomEvents';
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getDay } from 'utils/format';
import { getDefaultResultsList } from 'utils/mappers/smartSchedule';
import { SMART_SCHEDULE_SELECT_RESULT } from 'utils/validators/smartSchedule';
import SmartFormFooter from '../Footer/Smart/SmartFormFooter';
import { ResultListWrapper } from './Style';
import RecurringSSFilterInput from './RecurringSSFilterInput';
import RecurringSSPostSearchRangeFilters from './SmartScheduleRangeResults/RecurringSSPostSearchRangeFilters';
import RecurringResultItemCard from './RecurringResultItemCard';
import { isCheckedIndex } from 'utils/validators/smartSchedule';
import Content from 'views/components/ui/content';
import { GET_CLIENT_ADDRESSES } from 'api/graphql/v2/queries/Clients';
import { useQuery } from 'react-apollo';
import CarePlanInformation from './CarePlanInformation';
import moment from 'antd/node_modules/moment';
import { Grid } from 'semantic-ui-react';
import ExpandMoreFilled from 'assets/img/ExpandMoreFilled.png';
import ExpandLessFilled from 'assets/img/ExpandLessFilled.png';
interface IProps {
  appointmentTypeId: number;
  smartResults: ISmartScheduleOpenings;
  setSmartResults: Dispatch<React.SetStateAction<ISmartScheduleOpenings>>;
  action: string;
  providersMap: Map<any, IProvider>;
  apptTypes: IFullAppointmentType[];
  client: IClient;
  paymentMethod: string;
  setVisible: (val: boolean) => void;
  visibleResults: boolean;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  isAba: boolean;
  appointmentSubTypeId?: number;
  hasSingleDT: any;
  adminTypes?: IFullAppointmentType[];
  reqData: IRecurringReqData;
  setReqData: Dispatch<React.SetStateAction<IRecurringReqData>>;
  setOpeningCardsStatus: Dispatch<React.SetStateAction<boolean>>;
  openingCardsStatus: boolean;
  smartLoading: boolean;
  sessionDuration: number;
  carePlanInfo: ICarePlanFieldsForSmartScheduling;
  isWaitlistDataLoading: boolean;
}
const RecurringSSResultList: React.FC<IProps> = ({
  appointmentTypeId,
  smartResults,
  action,
  providersMap,
  apptTypes,
  client,
  paymentMethod,
  setVisible,
  visibleResults,
  paginationArray,
  setPaginationArray,
  isAba,
  appointmentSubTypeId,
  adminTypes,
  reqData,
  setReqData,
  hasSingleDT,
  setOpeningCardsStatus,
  openingCardsStatus,
  smartLoading,
  sessionDuration,
  carePlanInfo,
  isWaitlistDataLoading
}: IProps) => {
  const [selectedCards, setSelectedCards] = useState(() => new Set<number>());
  const [openingCards, setOpeningCards] = useState<ISmartResultCard[]>([]);
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [statistics, setStatistics] = useState({
    careplan: 0,
    clientBooked: 0,
    providerBooked: 0
  });
  const [providersExpanded, setProvidersExpanded] = useState<string[]>([]);
  const defaultResults: ISmartResultListForm = useMemo(() => {
    const defaultResults = getDefaultResultsList(
      openingCards,
      client?.id,
      paymentMethod
    );
    return defaultResults;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id, openingCards, paymentMethod, visibleResults]);
  const methods = useForm<ISmartResultListForm>({
    defaultValues: defaultResults,
    resolver: yupResolver(SMART_SCHEDULE_SELECT_RESULT()),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });
  const providerSSResults: Map<
    string,
    ISmartScheduleConsolidatedResults
  > = useMemo(() => {
    const providerMap = new Map<string, ISmartResultCard[]>();
    openingCards.map(card => {
      const providerName = card.provider?.name!;
      if (!providerMap.has(providerName)) {
        providerMap.set(providerName, [card]);
      } else {
        providerMap.get(providerName)!.push(card);
      }
    });
    const consolidatedResults = new Map<
      string,
      ISmartScheduleConsolidatedResults
    >();
    for (const [providerName, smartResultCards] of providerMap) {
      const availableDays: number[] = [];
      let totalAvailableDuration = '0 sessions';
      if (isAba) {
        const totalHours =
          smartResultCards[0].maxBookableMinutes! > 0
            ? parseFloat(
                (smartResultCards[0].maxBookableMinutes! / 60).toFixed(2)
              )
            : 0;
        totalAvailableDuration = `${totalHours | 0} hours`;
      } else {
        const totalSessions =
          smartResultCards[0].maxBookableMinutes! > 0
            ? Math.floor(
                smartResultCards[0].maxBookableMinutes! / sessionDuration
              )
            : 0;
        totalAvailableDuration = `${totalSessions | 0} sessions`;
      }
      smartResultCards.forEach(card => {
        if (!availableDays.includes(card.dayOfWeek))
          availableDays.push(card.dayOfWeek);
      });
      consolidatedResults.set(providerName, {
        openingCards: smartResultCards,
        totalAvailableDuration: totalAvailableDuration,
        availableDays: availableDays.length
      });
    }
    return consolidatedResults;
  }, [openingCards]);
  const { reset } = methods;

  const calculateStatistics = useCallback(
    (currentSelectedCards: Set<number>) => {
      const selectedResults: any[] = [];
      const formCards = methods.getValues()?.results;
      currentSelectedCards.forEach(it => {
        selectedResults.push(formCards[it]);
      });

      let careplan;
      if (isAba) {
        careplan = selectedResults.reduce(
          (sum, it) => sum + ((it?.endTimeForm - it?.startTimeForm) / 60 || 0),
          0
        );
      } else {
        careplan = selectedResults.length;
      }
      let copy = { ...statistics };

      const clientBooked = new Set(
        selectedResults.map(it => getDay(it.startDate))
      ).size;
      const providerBooked = new Set(selectedResults.map(it => it.provider.id))
        .size;
      copy = {
        ...copy,
        careplan: Number(careplan.toFixed(2)),
        clientBooked,
        providerBooked
      };

      setStatistics(copy);
    },
    [isAba, methods, statistics]
  );
  React.useEffect(() => {
    if (openingCardsStatus && Array.from(selectedCards).length > 0) return;
    reset(defaultResults);
    const set = new Set<number>();
    openingCards.forEach((_, index) => {
      if (index < reqData.weeklySessions) {
        methods.setValue(isCheckedIndex(index), true);
        set.add(index);
      } else {
        methods.setValue(isCheckedIndex(index), false);
      }
    });
    setSelectedCards(set);
    calculateStatistics(set);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultResults, openingCards, reqData]);

  const {
    removeProviderFromCalendar,
    removeAllProvidersFromCalendar
  } = useRemoveProviderFromCalendar();

  const updateSelectedCards = useCallback(
    (idx: number, isChecked: boolean) => {
      let currentSelectedCards = new Set(selectedCards);
      removeAllProvidersFromCalendar();
      if (isChecked) {
        currentSelectedCards.add(idx);
      } else {
        currentSelectedCards.delete(idx);
      }
      setSelectedCards(currentSelectedCards);
      calculateStatistics(currentSelectedCards);
    },
    [
      calculateStatistics,
      methods,
      removeProviderFromCalendar,
      removeAllProvidersFromCalendar,
      selectedCards
    ]
  );
  const formVals = methods.watch();
  useEffect(() => {
    if (isAba) {
      let totalMins = 0;
      Array.from(selectedCards).forEach(index => {
        const startTime = moment(formVals.results[index].startTime);
        const endTime = moment(formVals.results[index].endTime);
        const diffInMins = endTime.diff(startTime, 'minutes');
        totalMins = totalMins + diffInMins;
      });
      const totalHours =
        totalMins > 0 ? parseFloat((totalMins / 60).toFixed(2)) : 0;
      setSelectedValue(totalHours);
    } else {
      setSelectedValue(Array.from(selectedCards).length);
    }
  }, [setSelectedValue, selectedCards, isAba, formVals]);
  const clientId = client.id;
  const { data: addressesData } = useQuery(GET_CLIENT_ADDRESSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      clientId
    },
    skip: !clientId
  });
  const rangeBasedResults = !!smartResults.openingCards[0]?.timeRanges;
  useEffect(() => {
    const firstKey = providerSSResults?.keys().next().value;
    setProvidersExpanded([firstKey]);
  }, [providerSSResults, setProvidersExpanded]);
  const toggleProvider = useCallback(
    (newProvider: string) => {
      if (providersExpanded.includes(newProvider)) {
        setProvidersExpanded(prev => prev.filter(p => p !== newProvider));
      } else {
        setProvidersExpanded(prev => [...prev, newProvider]);
      }
    },
    [setProvidersExpanded, providersExpanded]
  );
  let index = 0;
  return (
    <ResultListWrapper>
      <FormProvider {...methods}>
        <div className="filter-cls">
          <div className="flex-grow">
            {rangeBasedResults ? (
              <RecurringSSPostSearchRangeFilters
                smartResults={smartResults}
                setOpeningCards={setOpeningCards}
                isAba={isAba}
                reqData={reqData}
                setReqData={setReqData}
                setOpeningCardsStatus={setOpeningCardsStatus}
                setSelectedCards={setSelectedCards}
                smartLoading={smartLoading}
                clientTimezone={smartResults.clientTimezone!}
                openingCards={openingCards}
              />
            ) : (
              <RecurringSSFilterInput
                smartResults={smartResults}
                setOpeningCards={setOpeningCards}
                isAba={isAba}
                reqData={reqData}
                setReqData={setReqData}
                setOpeningCardsStatus={setOpeningCardsStatus}
                setSelectedCards={setSelectedCards}
                smartLoading={smartLoading}
                openingCards={openingCards}
              />
            )}
            <CarePlanInformation
              carePlanInfo={carePlanInfo!}
              isAba={isAba}
              isWaitlistDataLoading={isWaitlistDataLoading}
              selectedValue={selectedValue}
            ></CarePlanInformation>
            <Content loading={smartLoading} data={smartResults}>
              {() => (
                <>
                  <SmartPhantomEvents
                    providersMap={providersMap}
                    apptTypes={apptTypes}
                    client={client}
                    setPaginationArray={setPaginationArray}
                    paginationArray={paginationArray}
                    visibleResults={visibleResults}
                    hasSingleDT={hasSingleDT}
                    adminTypes={adminTypes!}
                    openingCardsStatus={openingCardsStatus}
                    openingCards={openingCards}
                    selectedCards={selectedCards}
                  />
                  {Array.from(providerSSResults!).map(
                    ([providerName, providerResults]) => (
                      <>
                        <div className="provider-div">
                          <Grid>
                            <Grid.Row className="provider-row">
                              <Grid.Column width="8">
                                <label className="provider-name">
                                  {providerName}
                                  {', '}
                                  {
                                    providerResults.openingCards[0]!.provider
                                      .speciality?.abbreviation
                                  }{' '}
                                  (
                                  {
                                    providerResults.openingCards[0]!.provider
                                      .clinic?.abbreviation
                                  }
                                  ){' '}
                                </label>
                              </Grid.Column>
                              <Grid.Column width="7">
                                <label className="available-days">
                                  Available: {providerResults.availableDays}{' '}
                                  Days; {providerResults.totalAvailableDuration}
                                </label>
                              </Grid.Column>
                              <Grid.Column width="1">
                                <img
                                  src={
                                    providersExpanded.includes(providerName)
                                      ? ExpandLessFilled
                                      : ExpandMoreFilled
                                  }
                                  alt="Expand Icon"
                                  onClick={() => {
                                    toggleProvider(providerName);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </div>
                        <div
                          style={{
                            display: providersExpanded.includes(providerName)
                              ? 'block'
                              : 'none'
                          }}
                        >
                          {providerResults.openingCards.map(
                            (result: ISmartResultCard) => (
                              <RecurringResultItemCard
                                clientTimezone={smartResults.clientTimezone!}
                                appointmentTypeTitle={
                                  apptTypes.find(
                                    it => it.id === appointmentTypeId
                                  )?.title || ''
                                }
                                result={result}
                                index={index++}
                                updateSelectedCards={updateSelectedCards}
                                setOpeningCards={setOpeningCards}
                                clientAddresses={
                                  addressesData?.clientAddresses!
                                }
                                key={`${result.provider.id}-${result.clinic?.id}-${result.dayOfWeek}-${result.allowedLocations}`}
                                providersMap={providersMap}
                              />
                            )
                          )}
                        </div>
                      </>
                    )
                  )}
                  {openingCards.length === 0 && (
                    <p className="error-msg">
                      No results were found for this criteria. Please adjust the
                      filters and try again.
                    </p>
                  )}
                </>
              )}
            </Content>
          </div>
          <div className="footer-ss-cls">
            <SmartFormFooter
              setVisible={setVisible}
              action={action}
              noSelectedSlots={
                methods.getValues('results').filter(it => it.isChecked)
                  .length === 0
              }
              appointmentSubTypeId={appointmentSubTypeId}
              hasSingleDT={hasSingleDT}
            />
          </div>
        </div>
      </FormProvider>
    </ResultListWrapper>
  );
};
export default React.memo(RecurringSSResultList);
