import React, { Dispatch, SetStateAction } from 'react';
import { TelehealthLinksWrapper } from './style';
import Trash from '../../../assets/img/Trash.png';
import plus from '../../../assets/img/Plus.png';

interface IProps {
  providerName: string | undefined;
  telehealthLinks: string[] | undefined;
  setTelehealthLinks: Dispatch<SetStateAction<string[]>>;
  setFormPrestine: Dispatch<SetStateAction<boolean>>;
  setfilteredLinks: Dispatch<SetStateAction<string[]>>;
}

const TelehealthLinksInput: React.FC<IProps> = ({
  providerName,
  telehealthLinks,
  setTelehealthLinks,
  setFormPrestine,
  setfilteredLinks
}: IProps) => {
  const cleanTelelehealthClinksData = (links: string[] | null) => {
    let filteredLinks: string[] = [];
    if (telehealthLinks && telehealthLinks.length) {
      links?.map(link => {
        if (!link.includes('Paste') || !link.includes('here')) {
          filteredLinks.push(link);
        }
      });
    }
    return filteredLinks;
  };

  const addTelehealthCell = () => {
    let previousLinks: string[];
    if (telehealthLinks === undefined || !telehealthLinks) {
      previousLinks = [];
    } else {
      previousLinks = [...telehealthLinks];
    }
    previousLinks?.push('Paste meeting link here');
    setTelehealthLinks(previousLinks);
    setfilteredLinks(cleanTelelehealthClinksData(previousLinks));
    setFormPrestine(true);
  };

  const removeLinkCell = (index: number) => {
    let previousLinks = [...telehealthLinks!];
    previousLinks.splice(index, 1);
    setTelehealthLinks(previousLinks);
    setfilteredLinks(cleanTelelehealthClinksData([...previousLinks]));
    setFormPrestine(true);
  };

  const onInputAlter = (value: string, index: number) => {
    let previousLinks = [...telehealthLinks!];
    previousLinks[index] = value;
    setTelehealthLinks([...previousLinks]);
    setfilteredLinks(cleanTelelehealthClinksData([...previousLinks]));
    setFormPrestine(true);
  };

  const isFinalLink = (index: number) => {
    return index === telehealthLinks?.length! - 1;
  };

  if (telehealthLinks && telehealthLinks.length) {
    return (
      <>
        <TelehealthLinksWrapper>
          {telehealthLinks?.map((link, index) => {
            return (
              <div className="custom-input">
                <div className="text-input">
                  <input
                    style={{ border: '1px solid #d9d9d9' }}
                    type="text"
                    placeholder={link}
                    onChange={e => onInputAlter(e.target.value, index)}
                  />
                </div>
                <div
                  className="icon-container"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '11px',
                    marginLeft: '6px'
                  }}
                >
                  {isFinalLink(index) && (
                    <div>
                      <img
                        style={{ height: '20px' }}
                        src={plus}
                        className={'trash-icon-assistants color-purple '}
                        onClick={() => {
                          addTelehealthCell();
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  className="icon-container"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '8px',
                    marginLeft: '4px'
                  }}
                >
                  <img
                    src={Trash}
                    className={'trash-icon-assistants color-purple'}
                    onClick={() => {
                      removeLinkCell(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </TelehealthLinksWrapper>
      </>
    );
  } else {
    return (
      <>
        <TelehealthLinksWrapper>
          <div className="info-wrapper">
            {providerName ? (
              <small className="info-text-small">
                {providerName} does not have any Telehealth links
              </small>
            ) : (
              <small className="info-text-small">
                Add Telehealth links for the provider
              </small>
            )}
          </div>
          <div className="info-wrapper">
            <button
              onClick={() => {
                addTelehealthCell();
              }}
              className="add-link-button"
            >
              Add Links
            </button>
          </div>
        </TelehealthLinksWrapper>
      </>
    );
  }
};
export default React.memo(TelehealthLinksInput);
