import { pick } from 'lodash';
import moment from 'moment';
import { getApptTypeAndSubType } from 'utils/common';
import { NEW_CARE_PLAN } from 'utils/constants/init';
import { formatPatternString } from './authorizations';

export const getCarePlanData = (carePlan, apptTypes, waitlistStatusOption) => {
  if (!carePlan) return NEW_CARE_PLAN;

  const recommendedEvaluations = carePlan.recommendedEvaluations.map(
    recommendation => {
      const { apptType, apptSubType } = getApptTypeAndSubType(
        recommendation,
        apptTypes
      );
      return {
        key: recommendation.id,
        apptType: apptType.title,
        apptSubType: apptSubType?.title || 'None',
        status:
          waitlistStatusOption.find(el => el.id === recommendation.status)
            ?.value || 'N/A',
        createdAt: moment(recommendation.createdAt).format('MM/DD/YYYY')
      };
    }
  );

  const recommendedTherapies = carePlan.recommendedTherapies.map(
    recommendation => {
      const { apptType, apptSubType } = getApptTypeAndSubType(
        recommendation,
        apptTypes
      );
      return {
        key: recommendation.id,
        apptType: apptType.title,
        apptSubType: apptSubType?.title || 'None',
        recommendedQuantity: `${formatRecommendedQuantity(
          recommendation
        )} ${recommendation.quantityPattern.toLowerCase()}/week`,
        requestedQuantity: `${formatPatternString(
          recommendation.requestedQuantity,
          recommendation.quantityPattern.toLowerCase(),
          true
        )}`,
        status:
          waitlistStatusOption.find(el => el.id === recommendation.status)
            ?.value || 'N/A',
        updatedAt: moment(recommendation.updatedAt).format('MM/DD/YYYY')
      };
    }
  );

  return { recommendedEvaluations, recommendedTherapies };
};

export const mapCarePlanToFormData = (carePlan, apptTypes) => {
  const recommendedEvaluations = carePlan.recommendedEvaluations.map(
    recommendation => {
      const { apptType, apptSubType } = getApptTypeAndSubType(
        recommendation,
        apptTypes
      );

      return {
        ...pick(recommendation, ['id', 'clientResponse', 'createdAt']),
        apptType: apptType.title,
        apptSubType: apptSubType?.title
      };
    }
  );

  const recommendedTherapies = carePlan.recommendedTherapies.map(
    recommendation => {
      const { apptType, apptSubType } = getApptTypeAndSubType(
        recommendation,
        apptTypes
      );

      return {
        ...pick(recommendation, [
          'id',
          'requestedQuantity',
          'quantityPattern',
          'updatedAt'
        ]),
        apptType: apptType.title,
        apptSubType: apptSubType?.title,
        recommendedQuantity: formatRecommendedQuantity(recommendation)
      };
    }
  );

  return {
    recommendedEvaluations,
    recommendedTherapies
  };
};

const formatRecommendedQuantity = ({
  minRecommendedQuantity,
  maxRecommendedQuantity
}) => {
  const minQuantity = minRecommendedQuantity.toString();
  const maxQuantity = maxRecommendedQuantity.toString();
  return minQuantity === maxQuantity
    ? minQuantity.toString()
    : `${minQuantity}-${maxQuantity}`;
};
