import * as Yup from 'yup';

export const SMART_SCHEDULE_SELECT_RESULT = () =>
  Yup.object().shape({
    results: Yup.array().of(
      Yup.object().shape({
        isChecked: Yup.boolean(),
        isInVisible: Yup.boolean(),
        locationCategory: Yup.string()
          .nullable()
          .test(
            'valid Location Category',
            'Location Category is required',
            function(value?: string | null) {
              if (this.parent.isChecked && this.parent.isOffsite && !value) {
                return false;
              } else {
                return true;
              }
            }
          ),

        address: Yup.object()
          .nullable()
          .test('valid Address', 'Address is required', function(
            value?: any | null
          ) {
            if (this.parent.isChecked && this.parent.isOffsite && !value) {
              return false;
            } else {
              return true;
            }
          })
      })
    )
  });
export const SMART_SCHEDULE_EVAL_SELECT_RESULT = () =>
  Yup.object().shape({
    results: Yup.array().of(
      Yup.object().shape({
        isChecked: Yup.boolean(),
        isInVisible: Yup.boolean(),
        locationCategory: Yup.string().nullable(),

        address: Yup.number()
          .nullable()
          .test('valid Address', 'Address is required', function(
            value?: number | null
          ) {
            if (this.parent.isChecked && this.parent.isOffsite && !value) {
              return false;
            } else {
              return true;
            }
          })
      })
    )
  });

export const isCheckedIndex = (idx: number) => `results[${idx}].isChecked`;
export const isInVisibleIndex = (idx: number) => `results[${idx}].isInVisible`;
export const roomIndex = (idx: number) => `results[${idx}].room`;
export const startTimeIndex = (idx: number) => `results[${idx}].startTimeForm`;
export const endTimeIndex = (idx: number) => `results[${idx}].endTimeForm`;
export const startTimeNormalIndex = (idx: number) =>
  `results[${idx}].startTime`;
export const endTimeNormalIndex = (idx: number) => `results[${idx}].endTime`;
export const addressIndex = (idx: number) => `results[${idx}].address`;
export const teleHealthIndex = (idx: number) =>
  `results[${idx}].telehealthLink`;
export const CategoryIndex = (idx: number) =>
  `results[${idx}].locationCategory`;
export const driveTimeCheckIndex = (idx: number) =>
  `results[${idx}].driveTimeCheck`;
export const driveTimeIndex = (idx: number) => `results[${idx}].driveTime`;
export const driveStartTimeIndex = (idx: number) =>
  `results[${idx}].driveStartTime`;
export const driveEndTimeIndex = (idx: number) =>
  `results[${idx}].driveEndTime`;
export const rangeBasedMultiFieldErrorMessage = (idx: number) =>
  `results[${idx}].multiFieldErrorMessage`;
