import React from 'react';
import { Timepicker as LibDatepicker } from 'lib/ui/timepicker';
import { FormError } from './error';

const PlainTimepicker = ({ field, name, errors, ...restProps }) => {
  if (!field?.name) return null;
  return (
    <>
      <LibDatepicker
        getPopupContainer={trigger => trigger.parentNode}
        {...field}
        {...restProps}
        ref={undefined}
      />
      {field?.name && <FormError name={field?.name} errors={errors} />}
    </>
  );
};

export default PlainTimepicker;
export { PlainTimepicker };
