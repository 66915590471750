import React from 'react';
import moment from 'moment';
import { WarningFilled } from '@ant-design/icons';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { Carousel } from 'lib/ui';

const SliderItems = ({ events, isSidebar = false }) => {
  return (
    <Carousel arrows="true" isSidebar={isSidebar}>
      {events.map(
        (
          { title, startDate, endDate, locationType, appType, category },
          index
        ) => {
          const start = moment(startDate);
          const end = moment(endDate);
          return (
            <div className="slide-item" key={index}>
              <p>
                {appType}
                {locationType &&
                  (locationType === LOCATION_TYPE_LIST[0].id
                    ? ' - In Clinic'
                    : locationType === LOCATION_TYPE_LIST[1].id
                    ? ` - Off Site - ${category}`
                    : ' - Telehealth')}
              </p>
              <p style={{ color: '#cc3f3f' }}>
                <WarningFilled />
                {title}
              </p>
              <p>{start.format('MM/DD/YYYY')}</p>
              <p>
                {start.format('h:mm A')} - {end.format('h:mm A')}
              </p>
            </div>
          );
        }
      )}
    </Carousel>
  );
};

export default SliderItems;
