import { IOption } from 'model';

export const styleNumbers = (options: IOption[], color: string) => {
  return options.map(option => {
    const { label } = option;
    const arraySplatted = label.split('(');
    let renderedLabel = [<span>{arraySplatted[0]}</span>];
    for (let i = 1; i < arraySplatted.length; ++i) {
      const end = arraySplatted[i].indexOf(')');
      renderedLabel.push(<span>{'('}</span>);
      renderedLabel.push(
        <span style={{ color: color }}>{arraySplatted[i].slice(0, end)}</span>
      );
      renderedLabel.push(<span>{arraySplatted[i].slice(end)}</span>);
    }
    return {
      ...option,
      label: renderedLabel
    };
  });
};
