import { RadioGroup } from 'api/sharedComponents/reactHookFormComponents';
import { IOption } from 'model';
import { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { styleNumbers } from 'utils/common/radioGroup';

interface Props {
  cancelOptions: IOption[];
  isColorNumbers?: boolean;
  setSelectedOption?: Dispatch<SetStateAction<string>>;
}

const RecurrentCancelRadioGroup = ({
  cancelOptions,
  isColorNumbers = false,
  setSelectedOption
}: Props) => {
  const methods = useFormContext();
  const handleChange = (value: string) => {
    methods.setValue('recurrent', value);
    if (setSelectedOption) setSelectedOption(value);
  };
  const renderedOptions = isColorNumbers
    ? styleNumbers(cancelOptions, 'red')
    : cancelOptions;

  return (
    <div className="radio-group-wrapper">
      <Controller
        name="recurrent"
        control={methods.control}
        render={fieldProps => (
          <RadioGroup
            options={renderedOptions}
            field={fieldProps}
            customOnChangeHandler={(e: any) => handleChange(e.target.value)}
          />
        )}
      />
    </div>
  );
};

export default RecurrentCancelRadioGroup;
