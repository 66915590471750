import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext
} from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';

import GroupWrapper from '../style';
import Group from '../../group/group';
import { calendarGroup, IPlannerGroup } from 'model/calendar/groups';

import {
  calendarEventsPrefVar,
  clientsResourcesPrevVar,
  filteredProvidersResourcesPrevVar,
  providerResourcesPrevVar,
  searchedClientsPrevVar,
  searchedProvidersPrefVar
} from 'utils/cache/filters';
import { useReactiveVar } from '@apollo/client';
import { getDraggingStyle } from 'helpers/calendarGroupsHelper';
import { plannerContext } from 'pages/MainCalendarPage';

interface Props {
  setIsGroupDragged: Dispatch<SetStateAction<boolean>>;
  isShown: boolean;
  isGroupDragged: boolean;
  group: IPlannerGroup;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  setRemovedGroups: Dispatch<SetStateAction<number[]>>;
  removedGroups: number[];
  handleAutoFetching: () => void;
}

const DraggableDayGroup: React.FC<Props> = ({
  setIsGroupDragged,
  isShown,
  isGroupDragged,
  group,
  setIsShown,
  setRemovedGroups,
  removedGroups,
  handleAutoFetching
}: Props) => {
  const providers = useReactiveVar(providerResourcesPrevVar);
  const clients = useReactiveVar(clientsResourcesPrevVar);
  const searchedClients = useReactiveVar(searchedClientsPrevVar);
  const searchedProviders = useReactiveVar(searchedProvidersPrefVar);
  const events = useReactiveVar(calendarEventsPrefVar);

  const filteredProviders = useReactiveVar(filteredProvidersResourcesPrevVar);
  const { isBulkCancelMode } = useContext(plannerContext);
  const handleGroupRemoval = useCallback(() => {
    if (isBulkCancelMode) return;
    if (group.attendeeType === calendarGroup.client) {
      const groups = clients.filter(
        g => g.id?.toString() !== group.id?.toString()
      );

      clientsResourcesPrevVar(groups);
      searchedClients.delete(parseInt(group.id));
      setRemovedGroups(removedGroups.concat(parseInt(group.id)));
    } else if (group.attendeeType === calendarGroup.provider) {
      const groups = providers.filter(
        g => g.id?.toString() !== group.id.toString()
      );

      providerResourcesPrevVar(groups);
      searchedProviders.delete(parseInt(group?.id));
      setRemovedGroups(removedGroups.concat(parseInt(group.id)));

      const currentFilteredProviders = filteredProviders.filter(
        g => g.id?.toString() !== group.id?.toString()
      );

      filteredProvidersResourcesPrevVar(currentFilteredProviders);
      handleAutoFetching();
    }

    const filteredEvents = events.filter(
      e => e.resourceId?.toString() !== group.id?.toString()
    );
    console.log(
      'calendarEventsPrefVar in draggableDayGroup index.tsx',
      filteredEvents
    );
    calendarEventsPrefVar(filteredEvents);
  }, [
    group,
    events,
    clients,
    searchedClients,
    setRemovedGroups,
    removedGroups,
    providers,
    searchedProviders,
    filteredProviders,
    handleAutoFetching,
    isBulkCancelMode
  ]);

  return (
    <GroupWrapper
      type={group.attendeeType}
      // TODO: check that get in specialtiesMap take the id as number
      specialityColor={group?.speciality?.color}
      isGroupDragged={isGroupDragged}
    >
      <Draggable
        draggableId={group.id?.toString()}
        index={clients
          .concat(providers)
          .findIndex(g => g.id?.toString() === group.id?.toString())}
      >
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          setIsGroupDragged(snapshot?.isDragging);

          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false || snapshot?.isDragging)}
              style={getDraggingStyle(provided.draggableProps.style, snapshot)}
            >
              <Group
                cloneGroup={false}
                isShown={isShown}
                group={group}
                setRemovedGroups={setRemovedGroups}
                removedGroups={removedGroups}
                showFullGroup={true}
                handleGroupRemoval={handleGroupRemoval}
              />
            </div>
          );
        }}
      </Draggable>
    </GroupWrapper>
  );
};

export default React.memo(DraggableDayGroup);
