import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Grid, Icon, Radio } from 'semantic-ui-react';
import { CarePlanWrapper } from '../style';
import QuestionIcon from 'assets/img/icon-question.svg';
import {
  FilterSelectionWrapper,
  NewClientFilter,
  ClientAvailabilityWrapper
} from './style';
import { DAYS_FILTER, TimeSlots } from 'utils/constants/lists';
import { Popover } from 'antd';
import DateRange from './DateRange';
import { useFormContext, useWatch } from 'react-hook-form';
import ClientAvailabilityView from './ClientAvailabilityView';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import DaysFilter from './DaysFilter';
import { IClientAvailabilityFilter } from 'model/v2';

interface Props {
  isSingleAppt: boolean | undefined;
}
const NewClientAvailabilityFilter: React.FC<Props> = ({ isSingleAppt }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [clientFilterToggle, setClientFilterToggle] = useState<boolean>(false);
  const [ignore, setIgnore] = useState<boolean>(false);
  const [
    ClientAvailabilityDayFilter,
    setClientAvailabilityDayFilter
  ] = useState<any[]>([]);
  const methods = useFormContext();
  const { setValue } = useFormContext();
  const { client } = useWatch({
    name: ['client'],
    control: methods.control
  })!;

  const handleClientAvailabilityDayFilter = (newTimeSlot: any) => {
    const newSelection = [...ClientAvailabilityDayFilter];
    //find if day already exists
    const selectedIndex = newSelection.findIndex(
      selected => selected.dayIndex === newTimeSlot.dayIndex
    );
    let oldSelectedTimeSlot;
    if (selectedIndex !== -1) {
      // find if newTimeSlot is already added
      const selectedTime = newSelection[selectedIndex].preferredTimes.find(
        (selected: any) => selected.id === newTimeSlot.preferredTimes.id
      );
      // if newTimeSlot was not found, add it
      if (selectedTime === undefined) {
        oldSelectedTimeSlot = [
          ...newSelection[selectedIndex].preferredTimes,
          newTimeSlot.preferredTimes
        ];
      } else {
        oldSelectedTimeSlot = newSelection[selectedIndex].preferredTimes.filter(
          (selected: any) => selected.id !== newTimeSlot.preferredTimes.id
        );
      }

      // update preferredTimes
      newSelection[selectedIndex].preferredTimes = oldSelectedTimeSlot;
      // if the day doesn't have any time slots left
      if (newSelection[selectedIndex].preferredTimes.length === 0) {
        newSelection.splice(selectedIndex, 1);
      }
    } else {
      newTimeSlot.preferredTimes = [newTimeSlot.preferredTimes];
      newSelection.push(newTimeSlot);
    }

    setClientAvailabilityDayFilter(newSelection);
    methods.setValue('clientAvailabilityDayFilter', newSelection);
  };

  const selectAllTimeSlots = () => {
    setClientAvailabilityDayFilter([]);
    const allDays: any[] = [];
    DAYS_FILTER.forEach(day => {
      const newDay: IClientAvailabilityFilter = {
        dayIndex: day.value,
        preferredTimes: []
      };
      TimeSlots.forEach(timeSlot => newDay?.preferredTimes?.push(timeSlot));
      allDays.push(newDay);
    });
    setClientAvailabilityDayFilter(allDays);
    methods.setValue('clientAvailabilityDayFilter', allDays);
  };

  const handleClick = () => {
    if (client && client.id) setOpen(open => !open);
  };

  useEffect(() => {
    if (client && client.id) return;
    setOpen(false);
  }, [client]);

  const onToggle = useCallback(() => {
    setValue('isIgnoreClientProfileAvailability', !ignore);
    setIgnore(prev => !prev);
    setClientFilterToggle(prev => !prev);
    setClientAvailabilityDayFilter([]);
    methods.setValue('clientAvailabilityDayFilter', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFilterToggle]);

  const getAccordionContent = () => {
    return (
      <NewClientFilter>
        <FilterSelectionWrapper>
          <Grid>
            <Grid.Row className="header" floated="left" width={60}>
              Filter by Date, Time & Location
            </Grid.Row>
            <Grid.Row>
              <DateRange isSingleAppt={isSingleAppt} />
            </Grid.Row>
            {
              <Grid.Row>
                {getFeatureAvailability(FEATURES.VIEW_CLIENT_AVAILABILITY) &&
                  client &&
                  client.id && <ClientAvailabilityView clientId={client.id} />}
              </Grid.Row>
            }
            {
              <div className="toggle-rectangle">
                <div>
                  <div className="label-title">Filter Client Availability</div>
                  <div className="label-msg">
                    This will not consider current client availability
                  </div>
                </div>
                <Radio toggle onChange={onToggle} />
              </div>
            }
          </Grid>
        </FilterSelectionWrapper>
        {clientFilterToggle && (
          <ClientAvailabilityWrapper>
            <>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <div className="preferred-time-labels">
                      Preferred Time(s) of Day
                    </div>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <div
                      className="select-all-btn"
                      onClick={selectAllTimeSlots}
                    >
                      Select All
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns={5}>
                <DaysFilter
                  ClientAvailabilityDayFilter={ClientAvailabilityDayFilter}
                  handleClientAvailabilityDayFilter={
                    handleClientAvailabilityDayFilter
                  }
                />
              </Grid>
              {methods?.errors?.clientAvailabilityDayFilter && (
                <Grid>
                  <Grid.Row>
                    <p className="error-msg">
                      {methods?.errors?.clientAvailabilityDayFilter?.message}
                    </p>
                  </Grid.Row>
                </Grid>
              )}
            </>
          </ClientAvailabilityWrapper>
        )}
      </NewClientFilter>
    );
  };
  const popoverContent = useMemo(() => {
    return (
      <ul>
        <li>
          We recommend using the Soonest Available date in order to find the
          best match
        </li>
        <li>
          Toggling on the Find Cortica’s first available option will show you
          options for any day of the week, any time, and any location, depending
          on Cortica’s provider availability. Use this option if you do not see
          enough result options
        </li>
      </ul>
    );
  }, []);
  return (
    <CarePlanWrapper>
      <Accordion>
        <Accordion.Title active={true} onClick={handleClick}>
          <Grid>
            <div className="rectangle"></div>
            <Grid.Row className="care-plan" floated="left" width={60}>
              Filter by Client Availability
              <Popover
                placement="bottomLeft"
                content={popoverContent}
                title="Client Availability"
                trigger="hover"
              >
                <img
                  alt="ques"
                  src={QuestionIcon}
                  width={22}
                  style={{ paddingLeft: 4 }}
                />
              </Popover>
              <Grid.Column floated="right" width="1" className="arrow">
                {open ? <Icon name="angle up" /> : <Icon name="angle down" />}
              </Grid.Column>
            </Grid.Row>
            <div className="rectangle"></div>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={open} content={getAccordionContent()} />
      </Accordion>
    </CarePlanWrapper>
  );
};
export default React.memo(NewClientAvailabilityFilter);
