import { Modal } from 'antd';
import * as colors from 'utils/constants/theme';
import styled from 'styled-components';

interface Props {
  isAuthAppt?: boolean;
}

export const CalendarWrapper = styled.div<Props>`
  width: 100%;
  overflow: hidden;
    .rbc-month-header,
    .rbc-time-header-cell {
      .rbc-header {
        white-space: pre-wrap;
        border-color: transparent;
        border-bottom: none;
        span {
          max-width: 30px;
          display: block;
          margin: 0 auto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: center;
          color: #21303a;
        }
      }
    }
    .rbc-month-view {
      .rbc-month-header {
        border-bottom: 1px solid #ddd;
        align-items: center;
        justify-content: center;
        display: flex;
        min-height: 50px;
        span {
          font-weight: bold;
        }
      }
    }
  }

  &.day {
    .rbc-event {
    }
    .rbc-day-slot,
    .rbc-time-header-content {
      min-width: calc(100% / 3 - 40px);
    }
    .rbc-time-column:nth-child(1) {
      .rbc-timeslot-group:nth-child(1) {
        padding-top: 3px;
      }
    }
  }
  .rbc-time-column:nth-child(2) {
    .rbc-current-time-indicator {
      &:before {
        content: '';
        position: absolute;
        top: -4px;
        left: -1px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #fff;
        border:1px solid #e94545;
      }
    }
  }
  .rbc-current-time-indicator {
    z-index: 1;
    border-color: #e94545;
  }
  }
  &:not(.month) {
    .rbc-event {
      display: block !important;
      background-color: ${colors.AT_14};
      &:not(.na) {
        min-height: 35px;
        &:hover {
          min-height: 4.5rem;
          max-height: auto;
          z-index: 2 !important;
        }
      }
      &.rbc-event-continues-earlier {
        display: none !important;
      }
      &:not(.na) {
        z-index: 1;
      }
      &.na {
        left: 0 !important;
        width: 100% !important;
        min-height: auto;
        background-image: linear-gradient(
          45deg,
          #dbdbdb 6.52%,
          #f8f8f8 6.52%,
          #f8f8f8 50%,
          #dbdbdb 50%,
          #dbdbdb 56.52%,
          #f8f8f8 56.52%,
          #f8f8f8 100%
        );
        /* background-size: 32.53px 32.53px; */
        background-size: 18px 18px;
        pointer-events: none;
        > * {
          opacity: 0;
        }
      }
      &.at-1 {
        background-color: ${colors.AT_1};
      }
      &.at-2 {
        background-color: ${colors.AT_2};
      }
      &.at-3 {
        background-color: ${colors.AT_3};
      }
      &.at-4 {
        background-color: ${colors.AT_4};
      }
      &.at-5 {
        background-color: ${colors.AT_5};
      }
      &.at-6 {
        background-color: ${colors.AT_6};
      }
      &.at-7 {
        background-color: ${colors.AT_7};
      }
      &.at-8 {
        background-color: ${colors.AT_8};
      }
      &.at-9 {
        background-color: ${colors.AT_9};
      }
      &.at-10 {
        background-color: ${colors.AT_10};
      }
      &.at-11 {
        background-color: ${colors.AT_11};
      }
      &.at-12 {
        background-color: ${colors.AT_12};
      }
      &.at-13 {
        background-color: ${colors.AT_13};
      }
      &.at-14 {
        background-color: ${colors.AT_14};
      }
    }
  }
  &.week {
    .rbc-event {
      &:hover {
        width: 100% !important;
        z-index: 2 !important;
        p {
          line-height: inherit !important;
        }
      }
    }
    .rbc-time-column:nth-child(1) {
      .rbc-timeslot-group:nth-child(1) {
        padding-top: 3px;
      }
    }

    .rbc-events-container .rbc-event .rbc-event-content {
      p {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }

  .ant-radio-button-wrapper:hover:not(:first-child)::before {
    background-color: #6f4bf1;
  }

  .rbc-event-content {
    > div:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
  .calendar-list {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background-color: #eae8ff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.left {
      top: 20px;
      left: 10px;
    }
    &.right {
      right: 0;
      top: 20px;
    }
    svg {
      fill: #6f4bf1;
      transition: 0.2s transform linear;
    }
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }

  .rbc-header {
    border: none;
  }
  .rbc-time-content {
    border-top: 1px solid #ddd;
    overflow-y: overlay;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
        inset 1px 1px 0px rgba(0, 0, 0, 0);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .rbc-time-header {
    padding: 0px;
    display: flex;
    height: 50px;
    align-items: center;
  }
  .rbc-time-view {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #c9cfd3;
  }
  .rbc-time-slot {
    text-align: center;
    padding:0 10px;
    .rbc-label{
      padding:0;
    }
  }

  .rbc-time-view-resources {
    .rbc-time-gutter{
      z-index: 2;
      background: #fff;
      position: sticky !important;
      .rbc-timeslot-group {
        border-bottom: none;
      }
    }
    .rbc-time-header-gutter {
      z-index: 2;
      border: none;
      background: #fff;
      position: sticky !important;
      padding:0;
      margin:0;
      width: auto !important;
      min-width:unset !important;
      max-width:unset !important;
      p {
          padding: 0 0 0 5px;
          margin: 0;
        }
    }
  }
  .event-status {
    position: absolute;
    top: 2px;
    right: 8px;
    min-height: 17px;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.AVATAR_INITIAL};
    text-transform: capitalize;
    display: flex;
    align-items: center;
    svg {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      fill: ${colors.AVATAR_INITIAL};
    }
  }
  .event-room {
    color: ${colors.AVATAR_INITIAL};
    text-align: left;
  }
`;

//TODO: Move every wrapper to its componenent
export const ResourceAccessorTitle = styled.div`
  user-select: none;
  .resource-name {
    font-size: 14px;
    color: ${colors.DARK_GREY};
  }
`;

export const GutterHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  .name {
    margin-top: 10px;
    margin-left: 16px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #415766;
    padding-left: 20px;
  }
  .calendar-day {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6f4bf1;
    border-radius: 50%;
    color: #fff;
    height: 28px;
    width: 28px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    padding: 0;
    span {
      margin-bottom: 1px;
      margin-right: 1px;
    }
  }
`;

export const EventModal = styled(Modal)`
  ${({ isModalContent }: any) =>
    isModalContent &&
    `
  .ant-modal-body {
    width: ${(props: any) => (props.isAuthAvailable ? '584px' : '520px')};
    min-height: 369px;
    padding: 15px 15px;
    box-shadow: 0 0 0 #000;
    .verticalLine {
      width: 1px;
      height: 177px;
      border: solid 1px #c9cfd3;
    }
  }
  .action-modal {
  }
  .event-modal {
    &__title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 2px solid #e4e7e9;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    &__person {
      display: flex;
      align-items: flex-start;
      .person-image {
        text-align: center;
        img {
          width: 72px;
          height: 72px;
          border: solid 2px #6f4bf1;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .person-info {
        padding-left: 15px;
      }
      h2 {
        font-family: Lato;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
      }
      .ant-tag {
        margin: 10px auto 0;
      }
    }
    &__type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .status {
        position: relative;
        min-height: 39px;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: ${colors.AVATAR_INITIAL};
        text-transform: capitalize;
        padding: 0 30px 15px 20px;
        &:before {
          content: '';
          position: absolute;
          top: 9px;
          left: 0;
          border-radius: 50%;
          width: 8px;
          height: 8px;
        }
        &.canceled:before {
          background-color: ${colors.RED};
        }
        &.checked-in:before {
          background-color: ${colors.GREEN};
        }
      }
      button {
        /* width: 121px; */
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    &__body {
      display: flex;
      &__telehealth {
        white-space: pre-line;
        border-top: 2px solid #e4e7e9;
        padding-top: 19px;
        .wraper-text {
          width: 100%;
          height: 100%;
          padding: 0px 10px 0px 27px;
        }
        a {
          margin-left: 22px;
        }
      }
    }
    &__info-list {
      width: 45%;
      padding-left: 0;
      svg {
        color: #919699;
        margin-right: 10px;
      }
      li {
        list-style: none;
        font-size: 15px;
        color: ${colors.AVATAR_INITIAL};
        &:not(:last-child) {
          padding-bottom: 15px;
        }
        span {
          margin-left: 15px;
        }
      }
      .anticon {
        color: #919699;
        margin-right: 15px;
      }
      a {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.17px;
        text-decoration: underline;
        color: ${colors.ACTIVE_URL};
      }
    }
    &__info-list-sub {
      width: 100%;
      padding-left: 0;
      svg {
        color: #919699;
        margin-right: 10px;
      }
      li {
        list-style: none;
        font-size: 15px;
        color: ${colors.AVATAR_INITIAL};
        &:not(:last-child) {
          padding-bottom: 15px;
        }
        span {
          margin-left: 15px;
        }
      }
      .anticon {
        color: #919699;
        margin-right: 15px;
      }
      a {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.17px;
        text-decoration: underline;
        color: ${colors.ACTIVE_URL};
      }
    }
  }
  `}
`;

export const CancelConfirm = styled.div`
  p {
    font-size: 15px;
    font-weight: bold;
    opacity: 0.8;
    color: #21303a;
    padding: 30px 0;
  }
  .textArea {
    margin-top: 16px;
    height: 60px;
    font-size: 14px;
    min-height: 60px !important;
  }
`;

export const AuthAppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  padding-top: ${({ isAuthAppt }: Props) => (isAuthAppt ? '20px' : '0')};
  .fa-exclamation-triangle {
    font-size: 20px;
    text-align: center;
    color: #ec4f4f;
  }
  h4 {
    font-size: 14px;
    font-weight: bold;
    color:  color: ${(props: Props) =>
      props.isAuthAppt ? colors.INACTIVE_URL : colors.RED};
    text-transform: ${({ isAuthAppt }: any) =>
      isAuthAppt ? 'none' : 'uppercase'};
  }
  .wrapper_data {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    margin-bottom: 14px;
    span {
      opacity: 0.8;
      font-family: Lato;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      color: ${colors.AVATAR_INITIAL};
    }
    .start_end {
      display: flex;
      justify-content: space-between;
      padding-right: 8px;
      > span {
        font-family: Lato;
        font-size: 10px;
        font-weight: normal;
        color: ${colors.AVATAR_INITIAL};
      }
    }
  }
  .auth_therapies_data {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    color: #000000;
  }
  span {
    width: 45px;
    text-align: center;
    font-family: Lato;
    font-size: 15px;
    color: ${colors.AVATAR_INITIAL};
    &.red {
      color: ${(props: Props) =>
        props.isAuthAppt ? colors.AVATAR_INITIAL : colors.RED};
    }
  }
`;

export const EditTimeModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
  flex-wrap: nowrap;
  .title {
    color: #21303a;
    font-family: Lato;
    opacity: 0.8;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 41px;
  }
  .label{
    font-family: Lato;
    font-size: 14px;
    color: #21303a;
    margin-bottom: 14px;
  }
  .form {
    display: flex;
    flex-direction: row;
    width: 80%;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin-top: 30px;
  }
  .item{
    width: 150px;
  }
  .buttons{
    margin-right: 179px;
    margin-top: 58px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: row
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-content: flex-end;
  }
  .button{
    border: none;
    font-family: Lato;
    font-size: 15px;
    text-decoration: underline;
    box-shadow: none;
    background-color: #fff;
  }
  .primary {
    color: #0d77b5;
    opacity: 1;
  }
  .secondary{
    color: #919699;
    margin-right: 10px;
  }
  .underlineSpan{
    text-decoration: underline;
  }
  .disabled{
    opacity: 0.5
  }
`;
