import React from 'react';
import { Row } from 'antd';
import { Field, FastField } from 'formik';
import { MEDICAL_STAGE_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { FormItem, Select, TextArea } from 'views/containers/form';
import { ClientactiveStatus } from 'utils/constants/lists';

const medicalStageOptions = mapToOption(MEDICAL_STAGE_LIST);

export const ClientProfileForm = React.memo(({ disabledFormFields }) => {
  return (
    <>
      <div className="form-title">
        Profile
        <span className="optionalSpan">(optional)</span>
      </div>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Status" xs={16} sm={5}>
          <Field
            name="isActive"
            render={fieldProps => (
              <Select
                options={ClientactiveStatus}
                placeholder="Select Status"
                {...fieldProps}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Phenotype (Overall)" xs={16} sm={5}>
          <Field
            name="profile.phenoType"
            render={fieldProps => (
              <Select
                options={medicalStageOptions}
                placeholder="Select"
                {...fieldProps}
                disabled={disabledFormFields.phenoType}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Phenotype (Overall)" xs={16} sm={5}>
          <Field
            name="profile.phenoType"
            render={fieldProps => (
              <Select
                options={medicalStageOptions}
                placeholder="Select"
                {...fieldProps}
                disabled={disabledFormFields.phenoType}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Behavior" xs={16} sm={5}>
          <Field
            name="profile.behavior"
            render={fieldProps => (
              <Select
                options={medicalStageOptions}
                placeholder="Select"
                {...fieldProps}
                disabled={disabledFormFields.behavior}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Cognition" xs={16} sm={5}>
          <Field
            name="profile.cognition"
            render={fieldProps => (
              <Select
                options={medicalStageOptions}
                placeholder="Select"
                {...fieldProps}
                disabled={disabledFormFields.cognition}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Communication" xs={16} sm={5}>
          <Field
            name="profile.communication"
            render={fieldProps => (
              <Select
                options={medicalStageOptions}
                placeholder="Select"
                {...fieldProps}
                disabled={disabledFormFields.communication}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="Sensorimotor" xs={16} sm={5}>
          <Field
            name="profile.sensorimotor"
            render={fieldProps => (
              <Select
                options={medicalStageOptions}
                placeholder="Select"
                {...fieldProps}
                disabled={disabledFormFields.sensorimotor}
              />
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Notes" xs={16} sm={12}>
          <FastField
            name="profile.note.note"
            render={({ field }) => (
              <TextArea
                name="profile.note.note"
                value={field.value}
                placeholder="Notes"
                onChange={field.onChange}
                disabled={disabledFormFields.notes}
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  );
});
