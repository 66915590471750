import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const ContentWrapper = styled.div`
  .ui {
    margin: 0 !important;
    &.grid > .row {
      padding: 0 32px 0 32px !important;
      &.row-maxWidth {
        > div {
          max-width: 376px;
        }
        .rooms-dropdown {
          .ant-select-selection {
            background-color: green !important;
            .ant-select-selector {
              border: 1px solid green;
              border-radius: 5px;
            }
          }
          &.selected {
            .ant-select-selector {
              border: 1px solid #6f42f5;
              border-radius: 5px;
            }
          }
          .ant-select-selector {
            border-radius: 5px;
          }
          .ant-select-selection {
            border-color: #c9cfd3;
            .ant-select-selection-selected-value {
              font-size: 14px;
              font-weight: 600;
              color: #415766;
            }
          }
          &.ant-select-open {
            .ant-select-selection {
              border-color: ${colors.PURPLE};
              outline: none;
              box-shadow: none;
            }
          }
        }
        .text-area {
          width: 376px;
          height: 56px;
          padding: 9px;
          border-radius: 4px;
          border-color: #c9cfd3;
          resize: vertical !important;
          &:focus {
            border-color: ${colors.PURPLE};
            outline: none;
          }
          &::placeholder {
            font-size: 14px;
            letter-spacing: 0.15px;
            color: #919699;
          }
        }
        .input {
          width: 360px;
          height: 32px;
          padding: 9px;
          border-radius: 4px;
          border-color: #c9cfd3;
          resize: vertical !important;
          &:focus {
            border-color: ${colors.PURPLE};
            outline: none;
          }
          &::placeholder {
            font-size: 14px;
            letter-spacing: 0.15px;
            color: #919699;
          }
        }
      }

      &.radio-btns {
        margin: 11px 0 24px 0;
        .form {
          width: 100%;
          .field {
            .radio.checkbox {
              font-size: 15px;
              color: #21303a;
              label {
                &::before {
                  width: 16px;
                  height: 16px;
                  border: 1px solid ${colors.PURPLE};
                  background-color: #fff;
                }
                &::after {
                  width: 12px;
                  height: 12px;
                  left: 1.5px;
                  top: 3px;
                  background-color: ${colors.PURPLE};
                }
              }
            }
          }
        }
      }
      &.modal-actions {
        position: sticky;
        z-index: 999;
        bottom: 0;
        left: 0;
        right: 0;
        margin-top: 30px;
        background-color: #fff;
        padding: 0 !important;
        .action-btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          border-top: 1px solid #c9cfd3;
          padding: 16px 28px 16px 0;
          button {
            padding: 0;
            box-shadow: none !important;
            border: none;
            font-size: 15px;
            &:hover {
              box-shadow: none !important;
            }
            &:nth-child(1) {
              color: #919699 !important;
              margin-right: 24px !important;
            }
            &:nth-child(2) {
              letter-spacing: 0.17px;
              color: #0d77b5 !important;
              text-decoration: underline;
              &:disabled {
                opacity: 0.6 !important;
              }
            }
          }
        }
      }
      &.reschedule-location-row {
        .ant-row-flex {
          margin: 0 !important;
          .ant-col {
            margin: 0 !important;
            padding: 0 !important;
          }
          &:nth-child(1) {
            .ant-col {
              &:nth-child(1) {
                padding: 0 8px 0 0 !important;
              }
              &:nth-child(2) {
                padding: 0 0 0 8px !important;
              }
            }
          }
          &:nth-child(2) {
            .ant-col {
              flex: 1;
              &:nth-child(1) {
                padding: 0 8px 0 0 !important;
              }
              &:nth-child(2) {
                padding: 0 8px 0 8px !important;
              }
              &:nth-child(3) {
                padding: 0 0 0 8px !important;
              }
            }
          }
          &:nth-child(3) {
            .ant-col {
              &:nth-child(1) {
                flex: 1;
                padding: 0 16px 0 0 !important;
              }
              &:nth-child(2) {
                min-width: 109.33px;
                margin-top: 22px !important;
                .ant-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .ui.grid .Impacted-Observers {
    padding: 0px !important;
    align-items: center;
  }
  .reschedule-message-warning {
    font-size: 14px;
    font-family: Lato;
    line-height: 17px;
    font-weight: 700;
    padding: 0 0 16px 0;
  }
  .impacted-observers-heading {
    font-size: 14px;
    font-family: Lato;
    line-height: 17px;
    font-weight: 700;
    padding: 0 0 16px 0;
    color: #6f42f5;
  }
  .title {
    margin: 0 0 15px 0;
    color: #3e5766;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  .label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01em;
    margin-bottom: 22px;
    &.subtitle {
      color: #3e5766;
      line-height: 22px;
      font-size: 12px;
    }
    &.subHeader {
      color: #6f42f5;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 10px;
    }
  }
  .icon {
    color: #e82424;
  }
  .type-icon {
    color: #fffff;
    padding-right: 4px;
  }
  .date {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .client-pic {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    border: solid 1px #979797;
    border-radius: 50%;
  }
  .attende-name-row {
    width: 56% !important;
  }
  .attende-name-label {
    width: 40% !important;
  }
  .attende-type {
    background: #6f42f5;
    border-radius: 5px;
    padding: 6px 12px;
    width: 100px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    &.observer1 {
      background: #45bfff;
    }
    &.observer2 {
      background: #4d7bbd;
    }
  }
  .name-wrapper {
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 374px;
    .name {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
      cursor: pointer;
      margin-right: 8px;
    }
    .conflicting-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      padding: 2px 0 0 0;
    }
  }
  .schedule-date {
    margin: 0 0 12px 0;
    font-size: 15px;
    color: #21303a;
  }
  .event-location {
    margin: 0 0 12px 0;
    font-size: 15px;
    color: #21303a;
  }
  .event-Previous-date,
  .room-name {
    margin: 0;
    line-height: 1.33;
    color: #3e5766;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    &.label {
      color: #6f42f5;
    }
    &.room-name {
      margin: 0 0 0 0;
    }
  }

  .text-area {
    width: 100%;
    height: 60px;
    padding: 10px;
  }
  .observationBtn-row {
    margin-left: 18%;
    display: flex;
  }
  .observationButton {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #17a8c2;
    background: white;
    border: none;
  }
  .attende-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3e5766;
  }
  .ui.grid > .column:not(.row),
  .ui.grid > .row > .column {
    padding: 0 !important;
  }
  .observerRow {
    margin-bottom: 12px;
  }
  .observerBtn {
    color: white;
    border: none;
    border-radius: 6px;
    padding: 4%;
    font-size: 12px;
    margin-left: 20%;
  }
  .observer1-btn {
    background: #45bfff;
  }
  .observer2-btn {
    background: #4d7bbd;
  }
`;

export const ClientNameWrapper = styled.div`
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 374px;
  .client-pic {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    border: solid 1px #979797;
    border-radius: 50%;
  }
  .client-name {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #3E5766
    cursor: pointer;
  }
  .attende-type-patient {
    background: #17A8C2;
    border-radius: 5px;
    padding: 6px 12px;
    width: 100px; 
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
  }
  .icon {
    padding-right: 4px;
  }
`;
export const TimeRangeWrapper = styled.div`
  .event-details-icon {
    color: #6f42f5;
    margin-right: 8px;
  }
  .event-details {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #21303a;
    mix-blend-mode: normal;
    opacity: 0.8;
    &.oneLineFormat {
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: #3e5766;
      mix-blend-mode: normal;
      opacity: 0.8;
    }
  }
`;

export const ObserverFormWrapper = styled.div`
width: 100%;
padding: 8px 12px;
margin: 5px 15px;
background-color: rgba(201, 207, 211, 0.35);
border-radius: 7px;
.top-detail {
  width: 100%;
  display: flex;
}
.float-left {
  display: flex;
  align-items: center;
  flex-grow:1;
}
.float-right {
  width: 160px
  align-items: center;
  display: flex;
}
.profile-picture {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-position: center;
}
.observer-name {
  flex-grow: 1;
  color : #3E5766;
  font-size : 15px;
  display: flex;
  text-align: center;
  align-items:center;
  padding:0;
  margin:0px 0px 0px 8px;
}
.observer-count-div {
  display: flex;
  flex-grow: 1;
  height: 30px;
  width: 70px;
  background-color: #45BFFF;
  border-radius: 5px;
  color: #ffffff;
}
.icon-div {
  width: 30px;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.delete-icon {
  width: 50px
  display: flex;
  justify-content: flex-end;
}
.delete-icon-i {
  color: red;
}
.label-div {
  display: flex;
  align-items:center
  padding: 0;
  margin: 0
}
.tele-link-div {
  width: 100% !important; 
}
.Observer_Select {
  opacity: 1 !important;
  width: 100% !important;
  padding: 7px !imporant;
  height: 45px !important;
  margin-bottom: 12px !important;
  .ant-select-selector {
    height: 40px !important;
    padding-top: 4px;
    border-radius: 6px;
  }
  .ant-select-selection-search {
    padding-top: 4px;
  }
}
.Observer_Input {
  width: 100% !important;
  padding: 7px !imporant;
  min-height: 1.2em !important;
  border-radius: 6px
  margin-bottom: 12px !important;
}
.warning-message-div {
  margin-bottom: 12px;
  width: 100%;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  small {
    color: red !important;
  }
}
`;

export const ObserverTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .date-style {
    margin-top: 8px;
    width: 105px;
  }
  .time-style {
    margin-top: 8px;
  }
  .ant-picker {
    border-radius: 5px;
  }
  .ant-calendar-picker,
  .ant-time-picker {
    input {
      border-color: #c9cfd3;
    }
  }
  .date-first-row {
    max-height: 80px;
    margin-top: 12px;
    display: flex;
    justify-content: space-around !important;
    .ant-col:nth-child(1) {
      width: 100px;
      margin-right: 15px;
    }
    .ant-col:nth-child(2) {
      margin-right: 15px;
      width: 100px;
    }
    .ant-col:nth-child(3) {
      width: 100px;
    }
    .ant-col {
      .ant-time-picker-panel-inner {
        min-width: 168px;
      }
    }
  }
  .date-second-row {
    display: flex;
    .ant-col:nth-child(1) {
      width: 80px;
    }
  }
  .time-style input[type='time']::-webkit-calendar-picker-indicator {
    background: none !important;
    display: none;
  }
  .time-style input {
    border: 1px solid #6f42f5 !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    overflow: hidden;
    padding-left: 5px;
  }
  .date-style {
    border: 1px solid #6f42f5 !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    overflow: hidden;
    padding-left: 15px;
    width: 100px;
  }
  .date-style input::-webkit-calendar-picker-indicator {
    background: none !important;
    display: none !important;
  }
  .date-style span {
    display: none !important;
  }
  .MuiInput-input {
    background-color: #ffffff !important;
  }
`;

export const ImpactedObserversWrapper = styled.div`
  .impacted-observers-heading {
    font-size: 14px;
    font-family: Lato;
    line-height: 17px;
    font-weight: 700;
    margin-left: 15px;
    color: #6f42f5;
  }
  .observer-event-timing {
    margin-left: 15px;
  }
  .observer_alert {
    position: absolute;
    z-index: 1;
    left: 0%;
    right: 71.74%;
    top: 71.3%;
    bottom: 0%;
    height: 150px;
    width: 100%;
    border-radius: 0px;
    background: #ffffff;
  }
  .observer_alert .content {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 10px;
    padding: 0px 10px 20px 10px;
    background: #ffffff;
  }
  .observer_alert .closeButton {
    float: right;
    font-size: 20px;
    padding: 10px;
  }
  .observer_alert .attention {
    font-family: Lato;
    font-size: 20px;
    padding-top: 40px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
`;
