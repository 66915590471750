import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import { Icon } from 'semantic-ui-react';
import SaveModalForm from 'components/AvailabilityTemplateModalForm/SaveModal';

import TemplateCalender from './availabilityTemplateCalender';
import { ContentWrapper } from 'views/components/ui/card/card.style';
import Content from 'views/components/ui/content';
import MainButton from 'views/components/button';
import ModalSemantic from 'components/modalSemantic';
import ModalForm from 'components/AvailabilityTemplateModalForm';
import { TitleWrapper, SaveAndClose } from './style';

import {
  extendLastEvent,
  getStartTimeAndLengthForEachDay,
  getSubmittedDailyWorkingHours,
  prepareEventsForSubmission,
  prepareForApiCall,
  shiftEvents,
  truncateEvents,
  convertTZtoTZString
} from './utils';
import {
  CalendarView,
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar
} from 'utils/cache/calendar';
import { UPSERT_DAY_AVAILABILITY } from 'api/graphql/v2/mutations/DayAvailability';
import {
  SAVE_PROVIDER_TEMPLATE,
  ADD_TEMPLATE
} from 'api/graphql/v2/mutations/AvailabilityTemplate';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { DEACTIVATE_ALTERNATE_TEMPLATE } from 'api/graphql/v2/mutations/ProviderAlternateTemplateMapping';
import { useCallback } from 'react';
import { accountContext } from 'App';
import {
  GET_ALL_TEMPLATES,
  GET_SPECIALITIES_AND_DEPARTMENTS_AND_TEMPLATES
} from 'api/graphql/v2/queries/AvailabilityTemplate';
import { CHECK_PROVIDER_TEMPLATE_DATES_FOR_COLLISIONS } from 'api/graphql/v2/queries/AlternateAvailabilityTemplate';
import { mapEditedDayAvailabilityToEvent } from 'utils/mappers/availabilityTemplate';
import { GET_PROVIDER_LOCATIONS } from 'api/graphql/v2/queries/Enums';
import {
  AvailabilityTemplateEventsInput,
  CalenderEvent,
  ModalData
} from 'model/v2/availabilityTemplate';
import { Appointment_Types } from 'components/AvailabilityTemplateModalForm/EditForm/dropdownOptions';
import { IClinic } from 'model/v2/clinic';
import AddAlternateAvailabilityModalForm from 'components/AvailabilityTemplateModalForm/AddModal';
import CancelModalForm from 'components/AvailabilityTemplateModalForm/CancelModal';
import AlternateAvailabilityTable from 'components/table';
import { CalendarContainer } from './style';
import { GET_ALL_PROVIDER_TEMPLATES } from 'api/graphql/v2/queries/AlternateAvailabilityTemplate';
import { GET_PROVIDER_WITH_AVAILABILITY } from 'api/graphql/v2/queries/AvailabilityTemplate';
import { AltTemplateSubmittedFormData } from 'model/v2/providerTemplate';
import { GET_DAY_AVAILABILITIES_BY_PROVIDER_TEMPALTE_ID } from 'api/graphql/v2/queries/dayAvailability';
import { mapDayAvailabilityToCalendarEvent } from 'utils/mappers/availabilityTemplate';
import { useLazyQuery } from 'react-apollo';
interface IProps {
  calenderEvents?: CalenderEvent[];
  Data: ModalData;
  providerId: number;
  providerTemplateId?: number;
  openModalForm?: boolean;
  selectedDate: Date;
  templateName?: string;
  providerName?: string;
  providerTz?: string;
  isProviderAvailabilityMode?: boolean;
}

const AddAvailabilityTemplate: React.FC<IProps> = ({
  calenderEvents,
  Data,
  providerId,
  openModalForm,
  selectedDate,
  templateName,
  providerName,
  providerTz,
  providerTemplateId,
  isProviderAvailabilityMode
}) => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const openDefaultModalOnInitialMount =
    openModalForm === undefined || openModalForm;
  const [modalState, setModalState] = useState<boolean>(
    openDefaultModalOnInitialMount
  );
  const [saveModalState, setSaveModalState] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [addModalState, setAddModalState] = useState(false);
  const [cancelModalState, setCancelModalState] = useState(false);
  const [
    deleteProviderTemplateModalState,
    setDeleteProviderTemplateModalState
  ] = useState(false);
  const [cancelModal, setCancelModal] = useState({});
  const [events, setEvents] = useState<CalenderEvent[]>(calenderEvents || []);
  const [submittedData, setSubmittedData] = useState<ModalData>(Data);
  const [
    providerTemplateMetaFormData,
    setProviderTemplateMetaFormData
  ] = useState<AltTemplateSubmittedFormData>();
  const [savingProviderTemplate, setSavingProviderTemplate] = useState(false);
  const isEditMode = submittedData !== undefined;
  const account = useContext(accountContext);
  const altTemplateMetadataSubmitted =
    providerTemplateMetaFormData !== undefined;
  const [altTemplateSubmittedData, setAltTemplateSubmittedData] = useState();
  const [disableTemplateSelect, setDisableTemplateSelect] = useState(false);
  const [editCalendarEventsState, setEditCalendarEventsState] = useState(false);
  const [tableEditTemplateId, setTableEditTemplateId] = useState<
    number | null
  >();

  // The following states and useEffects are implemented since the relevant template data
  // can change when the parent changes dates or when a user explicitly loads a tempalte from the alt avail table
  const [originalCalendarEvents, setOriginalCalendarEvents] = useState<
    CalenderEvent[]
  >(calenderEvents || []);
  const [localTemplateId, setLocalTemplateId] = useState(providerTemplateId);
  const [localTemplateName, setLocalTemplateName] = useState(templateName);
  useEffect(() => {
    setEvents(calenderEvents ?? []);
    setOriginalCalendarEvents(
      calenderEvents !== undefined ? [...calenderEvents] : []
    );
  }, [setEvents, setOriginalCalendarEvents, calenderEvents]);
  useEffect(() => {
    setLocalTemplateId(providerTemplateId);
  }, [providerTemplateId]);
  useEffect(() => {
    setLocalTemplateName(templateName);
  }, [templateName]);

  const bannerText =
    openDefaultModalOnInitialMount && isProviderAvailabilityMode
      ? `You are creating 'Default Template'`
      : openDefaultModalOnInitialMount && !isProviderAvailabilityMode
      ? `You are creating a global template`
      : altTemplateMetadataSubmitted
      ? `You are ${Boolean(tableEditTemplateId) ? 'editing' : 'creating'} '${
          providerTemplateMetaFormData!.templateTitle
        }'`
      : editCalendarEventsState
      ? `You are editing '${localTemplateName}'`
      : `You are viewing '${localTemplateName}'`;

  const [collisionDetectionInFlight, setCollisionDetectionInFlight] = useState(
    false
  );

  const handleToolbarEdit = () => {
    setEditCalendarEventsState(prev => !prev);
  };

  const { data: dropdownOptions, loading, error } = useQuery(
    GET_SPECIALITIES_AND_DEPARTMENTS_AND_TEMPLATES
  );

  const { data: altAvailabilityTemplatesList, refetch } = useQuery(
    GET_ALL_PROVIDER_TEMPLATES,
    {
      variables: {
        providerId
      },
      fetchPolicy: 'network-only'
    }
  );

  const { data: locations, loading: loadingLocations } = useQuery(
    GET_PROVIDER_LOCATIONS
  );
  const [getStartingDayAvailabilities] = useLazyQuery(
    GET_DAY_AVAILABILITIES_BY_PROVIDER_TEMPALTE_ID,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        let events = mapDayAvailabilityToCalendarEvent(
          data.getDayAvailabilitiesByProviderTemplateId,
          data.clinics,
          Appointment_Types,
          selectedDate
        );
        setEvents(events);
        setOriginalCalendarEvents([...events]);
        setEditCalendarEventsState(true);
      }
    }
  );

  const [addTemplate] = useMutation(ADD_TEMPLATE, {
    refetchQueries: () => [
      {
        query: GET_ALL_TEMPLATES
      },
      {
        query: GET_SPECIALITIES_AND_DEPARTMENTS_AND_TEMPLATES
      }
    ]
  });

  const [upsertDayAvailabilities] = useMutation(UPSERT_DAY_AVAILABILITY);
  const [saveNewProviderTemplate] = useMutation(SAVE_PROVIDER_TEMPLATE);
  const [deactivateAlternateProviderTemplate] = useMutation(
    DEACTIVATE_ALTERNATE_TEMPLATE,
    {
      refetchQueries: [
        {
          query: GET_ALL_PROVIDER_TEMPLATES,
          variables: { providerId }
        },
        {
          query: GET_PROVIDER_WITH_AVAILABILITY,
          variables: { id: providerId, date: selectedDate }
        }
      ],
      awaitRefetchQueries: true
    }
  );

  const handleDateSelect = useCallback<(...args: Date[]) => void>(
    (date: Date) => {
      mainCalendarPrefDayVar(date);
    },
    []
  );

  const reroute = useCallback(() => {
    if (isProviderAvailabilityMode) {
      history.replace(`/providers/${providerId}`);
      handleDateSelect(new Date());
      refetch();
    } else {
      history.replace('/config/availabilityTemplates');
    }
  }, [
    handleDateSelect,
    history,
    providerId,
    refetch,
    isProviderAvailabilityMode
  ]);

  const onModalCancel = useCallback(() => {
    reroute();
  }, [reroute]);

  const onIconClose = () => {
    if (editCalendarEventsState) {
      setCancelModalState(true);
      setCancelModal({
        message: `Do you want to cancel changes before exiting? Any changes will not be saved.`,
        left_button_text: 'Go Back',
        right_button_text: 'Exit without Saving'
      });
    } else {
      reroute();
    }
  };

  const discardChangesOnClose = () => {
    setAltTemplateSubmittedData(undefined);
    setEditCalendarEventsState(false);
    reroute();
  };

  const onModalClose = () => {
    setModalState(false);
    setEditCalendarEventsState(true);
  };
  const onAddModalClose = () => {
    setTableEditTemplateId(undefined);
    setAddModalState(false);
    setDisableTemplateSelect(false);
    setProviderTemplateMetaFormData(undefined);
  };

  const calculateEventsToDelete = (
    originalEvents: AvailabilityTemplateEventsInput[],
    savedEvents: AvailabilityTemplateEventsInput[]
  ) => {
    const temp: Record<string, boolean> = {};
    const deletedEvents: number[] = [];
    originalEvents!.forEach(currentEvent => {
      if (currentEvent.id) {
        temp[currentEvent.id] = true;
      }
    });

    savedEvents.forEach(event => {
      if (event.id) {
        temp[event.id] = false;
      }
    });

    Object.keys(temp).forEach(key => {
      if (temp[key] === true) deletedEvents.push(Number(key));
    });

    return deletedEvents;
  };

  const calculateEventsToUpsert = (
    savedEvents: AvailabilityTemplateEventsInput[],
    originalEvents: AvailabilityTemplateEventsInput[]
  ) => {
    return savedEvents.filter(finalEvent => {
      if (finalEvent.id === undefined) return true;
      const originalEvent = originalEvents?.find(originalEvent => {
        return originalEvent.id === finalEvent.id;
      });
      //checking for deltas between both events
      for (let key in finalEvent) {
        // @ts-expect-error: string can't be used to index type AvailabilityTemplateEventsInput
        let finalValue = finalEvent[key];
        // @ts-expect-error: string can't be used to index type AvailabilityTemplateEventsInput
        let originalValue = originalEvent![key];
        if (Array.isArray(finalValue) && Array.isArray(originalValue)) {
          if (originalValue.length !== finalValue.length) return true;
          // @ts-expect-error: not all code paths return a value
          let clinicDeltas = finalValue.filter(finalClinic => {
            let existsInOriginal = Boolean(
              originalValue.find((originalClinic: IClinic) => {
                return originalClinic.id === finalClinic.id;
              })
            );
            if (!existsInOriginal) return true;
          });
          if (clinicDeltas.length) return true;
        } else {
          if (finalValue !== originalValue) {
            return true;
          }
        }
      }
      return false;
    });
  };

  const checkDuplicatesForSplitEvents = (
    upsertEvents: AvailabilityTemplateEventsInput[],
    deleteEvents: number[]
  ) => {
    const duplicateUpsert: Record<string, number> = {};
    upsertEvents.forEach(upsertEvent => {
      const currentId = upsertEvent.id;
      if (currentId !== undefined) {
        if (duplicateUpsert[currentId] === undefined) {
          duplicateUpsert[currentId] = 1;
        } else {
          duplicateUpsert[currentId]++;
        }
      }
    });
    for (let key in duplicateUpsert) {
      if (duplicateUpsert[key] > 1) {
        deleteEvents.push(Number(key));
        upsertEvents.forEach(upsertEvent => {
          if (upsertEvent.id === Number(key)) {
            upsertEvent.id = undefined;
          }
        });
      }
    }
  };

  const openSaveModal = () => {
    if (isProviderAvailabilityMode) {
      setSavingProviderTemplate(true);

      const makingChangesToProviderTemplateTable =
        !providerTemplateId || altTemplateMetadataSubmitted;

      let networkOperation;

      if (makingChangesToProviderTemplateTable) {
        const shouldInstantiateDefaultTemplate = !providerTemplateId;

        if (shouldInstantiateDefaultTemplate === altTemplateMetadataSubmitted)
          throw new Error(
            'Error managing default and alternate template creation state'
          );

        const eventsToBeSaved = prepareEventsForSubmission(events).map(
          event => ({
            ...event,
            id: undefined // this ensures we are creating new dayAvails when making new PTs, not updating the mapping of existing dayAvails
          })
        );

        // convert to "flat" dates
        const flatSunday = providerTemplateMetaFormData?.startDate
          ? moment(providerTemplateMetaFormData.startDate).format('YYYY-MM-DD')
          : undefined;
        const flatSaturday = providerTemplateMetaFormData?.endDate
          ? moment(providerTemplateMetaFormData.endDate).format('YYYY-MM-DD')
          : undefined;

        networkOperation = saveNewProviderTemplate({
          variables: {
            providerTemplate: {
              providerId: providerId,
              isDefault: shouldInstantiateDefaultTemplate,
              name: altTemplateMetadataSubmitted
                ? providerTemplateMetaFormData?.templateTitle
                : 'Default Template',
              dayAvailabilities: eventsToBeSaved,
              dailyMaxHours: getSubmittedDailyWorkingHours(
                submittedData.dailyMaxHours || []
              ),
              description: providerTemplateMetaFormData?.description,
              startDate: flatSunday,
              endDate: flatSaturday,
              frequency: providerTemplateMetaFormData?.frequency,
              providerTemplateId: tableEditTemplateId
            }
          }
        });
      } else {
        // we're just editing the day avails, don't need to touch PT table
        if (originalCalendarEvents) {
          const eventsToBeSaved = prepareEventsForSubmission(events);
          const preparedOriginalEvents = prepareEventsForSubmission(
            originalCalendarEvents
          );

          const eventsToBeDeleted: number[] = calculateEventsToDelete(
            preparedOriginalEvents,
            eventsToBeSaved
          );
          const eventsToBeUpserted: AvailabilityTemplateEventsInput[] = calculateEventsToUpsert(
            eventsToBeSaved,
            preparedOriginalEvents
          );
          checkDuplicatesForSplitEvents(eventsToBeUpserted, eventsToBeDeleted);
          const providerTemplateToBeSubmitted = {
            providerId: providerId,
            providerTemplateId: localTemplateId,
            name: submittedData.specialityAbr + ' Template',
            dayAvailabilities: eventsToBeSaved,
            dailyMaxHours: getSubmittedDailyWorkingHours(
              submittedData.dailyMaxHours || []
            )
          };
          networkOperation = upsertDayAvailabilities({
            variables: {
              dayAvailabilityUpdate: {
                upsertEvents: eventsToBeUpserted
              },
              providerTemplate: providerTemplateToBeSubmitted,
              deletedEvents: eventsToBeDeleted
            }
          });
        }
      }

      networkOperation
        ?.then(() => {
          setSavingProviderTemplate(false);
          reroute();
        })
        .catch((e: any) => {
          setSavingProviderTemplate(false);
          console.log('there was an error', e);
        });
    } else {
      setSaveModalState(true);
    }
  };

  const closeSaveModal = () => {
    setSaveModalState(false);
  };

  const submitSaveModal = async (data: any) => {
    const preparedData = prepareForApiCall(
      submittedData,
      events,
      data,
      account.name
    );
    setDisableSaveButton(true);
    await addTemplate({
      variables: {
        template: preparedData
      }
    })
      .then(() => {
        setDisableSaveButton(false);
        reroute();
      })
      .catch((e: any) => {
        console.log(e);
        setDisableSaveButton(false);
      });
  };

  const truncateOrExtendEvents = useCallback(
    (events: CalenderEvent[], submittedDayLength: number, index: number) => {
      let eventsOfDayIndex = events.filter(event => {
        return event.start.getDay() === index;
      });

      const { startTime, length } = getStartTimeAndLengthForEachDay(
        eventsOfDayIndex
      );

      if (length === submittedDayLength) return;

      const offset = submittedDayLength - length;
      if (submittedDayLength > length) {
        extendLastEvent(offset, eventsOfDayIndex);
      } else {
        truncateEvents(startTime, eventsOfDayIndex, submittedDayLength);
        updateEvents(eventsOfDayIndex, index);
      }
    },
    []
  );

  const onModalSubmitEditMode = useCallback(
    (data: ModalData, currentEvents: CalenderEvent[]) => {
      if (isProviderAvailabilityMode) {
        data.clinics = submittedData.clinics;
        data.providerDefaultClinicsIds =
          submittedData.providerDefaultClinicsIds;
      }
      let CalenderEvents: CalenderEvent[] = mapEditedDayAvailabilityToEvent(
        data,
        currentEvents,
        locations?.providerLocations[0],
        Appointment_Types
      );
      setEvents([...CalenderEvents]);
      data.Day.forEach((day, index) => {
        const isNotEmptySlot = day?.startTime;

        if (isNotEmptySlot !== undefined && String(day.length) !== '0') {
          shiftEvents(CalenderEvents, day.startTime, index);
          truncateOrExtendEvents(CalenderEvents, day.length, index);
        } else {
          removeEventsOfGivenDay(index);
        }
      });

      setModalState(false);
      setSubmittedData(data);
      setEditCalendarEventsState(true);
    },
    [
      locations,
      submittedData,
      truncateOrExtendEvents,
      isProviderAvailabilityMode
    ]
  );

  const updateEvents = (eventsOfDayIndex: CalenderEvent[], index: number) => {
    setEvents(prev => {
      let newEventsArr = prev.filter(event => {
        return event.start.getDay() !== index;
      });
      return [...newEventsArr, ...eventsOfDayIndex];
    });
  };

  const removeEventsOfGivenDay = (index: number) => {
    setEvents(prev => {
      let newEventsArr = prev.filter(event => {
        return event.start.getDay() !== index;
      });
      return [...newEventsArr];
    });
  };

  const handleViewChange = useCallback<(...args: CalendarView[]) => void>(
    view => {
      plannerCalendarPrefViewVar(view);
    },
    []
  );

  const onAddAlternateTemplate = (template?: any) => {
    setAltTemplateSubmittedData(template);
    setDisableTemplateSelect(template.id ? true : false);
    setAddModalState(!addModalState);
    setTableEditTemplateId(template?.id);
  };
  const onDefaultEdit = (template?: any) => {
    getStartingDayAvailabilities({
      variables: { providerTemplateId: template.id }
    });
    setLocalTemplateId(template.id);
    setLocalTemplateName(template.name);
  };
  const onDeleteAlternateTemplate = (template?: any) => {
    setDeleteProviderTemplateModalState(true);
    setCancelModal({
      message: `Do you want to delete the availability template "${template?.name}?"`,
      left_button_text: 'Cancel',
      right_button_text: 'Confirm and Save',
      templateId: template.id
    });
  };
  const onDuplicateAlternateTemplate = (template?: any) => {
    setAltTemplateSubmittedData(template);
    setDisableTemplateSelect(template.id ? true : false);
    setAddModalState(!addModalState);
  };

  const onDeleteAlternateTemplateClose = () => {
    setDeleteProviderTemplateModalState(false);
  };

  const onDeleteAlternateTemplateConfirm = (e: Event, templateId: number) => {
    e.preventDefault();
    deactivateAlternateProviderTemplate({
      variables: { providerTemplateId: templateId }
    });
    setDeleteProviderTemplateModalState(false);
  };

  const onCancelModalClose = () => {
    setCancelModalState(!cancelModalState);
  };

  const saveAddAvailbilityModal = async (
    data: AltTemplateSubmittedFormData,
    calendarEvents: CalenderEvent[]
  ) => {
    // convert to "flat" dates
    const flatSunday = moment(data.startDate).format('YYYY-MM-DD');
    const flatSaturday = moment(data.endDate).format('YYYY-MM-DD');
    setCollisionDetectionInFlight(true);

    const {
      data: collisionData,
      loading: collisionLoading
    } = await apolloClient.query({
      query: CHECK_PROVIDER_TEMPLATE_DATES_FOR_COLLISIONS,
      fetchPolicy: 'network-only',
      variables: {
        startDate: flatSunday,
        endDate: flatSaturday,
        frequency: data.frequency,
        providerId
      }
    });

    if (!collisionLoading) setCollisionDetectionInFlight(false);
    if (collisionData?.checkProviderTemplateDatesForCollisions?.length === 0) {
      setEvents(calendarEvents);
      setProviderTemplateMetaFormData(data);
      setAddModalState(false);
      setEditCalendarEventsState(true);
    } else {
      const uniqueTemplateNames = new Set();
      collisionData.checkProviderTemplateDatesForCollisions.forEach(
        (patmCollision: any) => {
          if (tableEditTemplateId !== patmCollision.providerTemplate.id) {
            uniqueTemplateNames.add(patmCollision.providerTemplate.name);
          }
        }
      );
      if (uniqueTemplateNames.size) {
        alert(
          `Unable to proceed. The proposed template frequency and dates will collide with these templates: ${[
            ...uniqueTemplateNames
          ]}`
        );
        return;
      }
    }
    setEvents(calendarEvents);
    setProviderTemplateMetaFormData(data);
    setAddModalState(false);
    setEditCalendarEventsState(true);
  };

  const providerTimezone = convertTZtoTZString(providerTz, true);

  const alertText = `Availability for this provider should be created in ${providerTimezone}`;
  return (
    <>
      <Content
        loading={loading || loadingLocations}
        error={error}
        data={dropdownOptions}
        fullHeight={true}
      >
        {() => (
          <>
            <div className="popup-wrap">
              <TitleWrapper>
                <h1>{isEditMode ? 'Edit' : 'Create'} Availability Template </h1>
                <div className="providerTimezoneHeader">
                  <h2 className="providerName">{providerName}</h2>
                  <p className="tz-alert">
                    This provider is in {providerTimezone}
                  </p>
                </div>
                <SaveAndClose>
                  <MainButton
                    className="save-button"
                    title={'Save'}
                    onClick={openSaveModal}
                    loading={savingProviderTemplate}
                    disabled={
                      savingProviderTemplate || !editCalendarEventsState
                    }
                  />
                  <Icon link name={'close'} onClick={onIconClose} />
                </SaveAndClose>
              </TitleWrapper>

              <CalendarContainer>
                <div className="alternate-calendar inner-wrap">
                  <div className="calendar-container">
                    <TemplateCalender
                      bannerText={bannerText}
                      selectedDate={selectedDate}
                      events={events}
                      updateEvents={setEvents}
                      isProviderAvailabilityMode={isProviderAvailabilityMode}
                      department={submittedData?.department}
                      alertText={alertText}
                      providerId={providerId}
                      clinics={submittedData?.clinics}
                      providerDefaultClinicsIds={
                        submittedData?.providerDefaultClinicsIds
                      }
                      locations={locations?.providerLocations}
                      handleNavigation={handleDateSelect}
                      handleViewChange={handleViewChange}
                      disableNavigation={
                        !Boolean(providerTemplateId) ||
                        altTemplateMetadataSubmitted
                      }
                      editModeState={editCalendarEventsState}
                      handleEditClick={handleToolbarEdit}
                      handleAddAlternateModal={onAddAlternateTemplate}
                    />
                    {isEditMode &&
                      submittedData?.department !== 'DT' &&
                      isProviderAvailabilityMode && (
                        <div className="right-sidebar">
                          <MainButton
                            className="add-alt"
                            title={'Add Alternative'}
                            onClick={onAddAlternateTemplate}
                            disabled={
                              editCalendarEventsState || !providerTemplateId
                            }
                          />
                        </div>
                      )}
                  </div>
                  {submittedData?.department !== 'DT' &&
                    isProviderAvailabilityMode && (
                      <AlternateAvailabilityTable
                        disableCTA={editCalendarEventsState}
                        data={
                          altAvailabilityTemplatesList?.getProviderTemplatesByProviderId
                        }
                        onEdit={onAddAlternateTemplate}
                        onDelete={onDeleteAlternateTemplate}
                        onDuplicate={onDuplicateAlternateTemplate}
                        onDefaultEdit={onDefaultEdit}
                      />
                    )}
                </div>
              </CalendarContainer>
              {modalState && (
                <ModalSemantic
                  open={true}
                  onClose={onModalClose}
                  modalWidth={1000}
                  title={'Select Template'}
                  trigger={<div></div>}
                  content={
                    <ModalForm
                      onClose={onModalCancel}
                      onSubmit={onModalSubmitEditMode}
                      currentEvents={events}
                      data={submittedData}
                      dropdownOptions={dropdownOptions}
                      isEditMode={isEditMode}
                    />
                  }
                />
              )}
            </div>
            {saveModalState && (
              <ContentWrapper>
                <ModalSemantic
                  open={true}
                  onClose={closeSaveModal}
                  modalWidth={580}
                  title={'Save New Template'}
                  trigger={<div></div>}
                  content={
                    <SaveModalForm
                      data={submittedData}
                      disableBtn={disableSaveButton}
                      dropdownOptions={
                        dropdownOptions.listDropdownAvailabilities
                      }
                      onClose={closeSaveModal}
                      onSubmit={submitSaveModal}
                    />
                  }
                />
              </ContentWrapper>
            )}
            {addModalState && (
              <ModalSemantic
                open={true}
                onClose={onAddModalClose}
                modalWidth={600}
                trigger={<div></div>}
                content={
                  <AddAlternateAvailabilityModalForm
                    submittedData={altTemplateSubmittedData}
                    disableTemplateSelect={disableTemplateSelect}
                    dropdownOptions={
                      altAvailabilityTemplatesList.getProviderTemplatesByProviderId
                    }
                    onSubmit={saveAddAvailbilityModal}
                    submitCallbackInFlight={collisionDetectionInFlight}
                    onClose={onAddModalClose}
                    disableBtn={false}
                    selectedDate={selectedDate}
                    providerDefaultClinicsIds={Data?.providerDefaultClinicsIds}
                  />
                }
              />
            )}
            {cancelModalState && (
              <ModalSemantic
                open={true}
                onClose={onAddModalClose}
                modalWidth={600}
                trigger={<div></div>}
                content={
                  <CancelModalForm
                    form={cancelModal}
                    onSubmit={discardChangesOnClose}
                    onClose={onCancelModalClose}
                  />
                }
              />
            )}
            {deleteProviderTemplateModalState && (
              <ModalSemantic
                open={true}
                onClose={onDeleteAlternateTemplateClose}
                modalWidth={600}
                trigger={<div></div>}
                content={
                  <CancelModalForm
                    form={cancelModal}
                    onSubmit={onDeleteAlternateTemplateConfirm}
                    onClose={onDeleteAlternateTemplateClose}
                  />
                }
              />
            )}
          </>
        )}
      </Content>
    </>
  );
};

export default AddAvailabilityTemplate;
