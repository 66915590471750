import {
  AvailabilityTemplateData,
  AvailabilityTemplateEventsInput,
  CalenderEvent,
  DayAvailability,
  ModalData,
  ProviderDailyMaxHours
} from 'model/v2/availabilityTemplate';
import { ILocation } from 'model/v2/location';
import moment, { Moment } from 'moment';
import { getCheckEndTimeMomentCalendar } from 'utils/mappers/availabilityTemplate';
import momentTz from 'moment-timezone';
export const prepareSubmittedData = (
  submittedData: ModalData | undefined,
  events: CalenderEvent[]
) => {
  submittedData?.Day.forEach((day, index) => {
    let eventsOfDayIndex = getEventsOfSpecificDay(events, index);
    if (eventsOfDayIndex.length > 0) {
      let { startTime, length } = getStartTimeAndLengthForEachDay(
        eventsOfDayIndex
      );
      day.startTime = startTime;
      day.length = length;
    } else {
      day.startTime = moment.invalid();
      day.length = 0;
    }
  });
};

export const shiftEvents = (
  events: CalenderEvent[],
  startTime: Moment,
  index: number
) => {
  const startTimeMoment = moment(startTime).day(index);
  let eventsOfDayIndex = getEventsOfSpecificDay(events, index);

  if (eventsOfDayIndex.length === 0 || startTime === undefined) return;

  eventsOfDayIndex.sort((a: CalenderEvent, b: CalenderEvent) => {
    return a.start.getTime() - b.start.getTime();
  });
  const offset = startTimeMoment.diff(moment(eventsOfDayIndex[0].start));
  if (offset === 0) return;

  eventsOfDayIndex.forEach(event => {
    event.start = moment(event.start)
      .add(offset, 'millisecond')
      .toDate();
    event.end = moment(event.end)
      .add(offset, 'millisecond')
      .toDate();
  });
};

export const extendLastEvent = (offset: number, events: CalenderEvent[]) => {
  events.sort((a: CalenderEvent, b: CalenderEvent) => {
    return a.start.getTime() - b.start.getTime();
  });
  const endTimeMoment = moment(events[events.length - 1].end).add(
    offset,
    'hour'
  );
  const startDate = moment(events[0].start).toDate();
  events[events.length - 1].end = getCheckEndTimeMomentCalendar(
    endTimeMoment,
    startDate
  );
};

export const truncateEvents = (
  startTime: Moment,
  eventsOfDayIndex: CalenderEvent[],
  length: number
) => {
  const expectedEndTime = startTime.add(length, 'hour');
  const expectedEndTimeDateObj = expectedEndTime.toDate();
  eventsOfDayIndex.sort((a: CalenderEvent, b: CalenderEvent) => {
    return a.start.getTime() - b.start.getTime();
  });

  const targetIndex = eventsOfDayIndex.findIndex(event => {
    return (
      event.start.getTime() < expectedEndTimeDateObj.getTime() &&
      event.end.getTime() >= expectedEndTimeDateObj.getTime()
    );
  });

  removeTrailingEvents(eventsOfDayIndex, expectedEndTimeDateObj);

  if (targetIndex === -1) return;
  eventsOfDayIndex[targetIndex].end = expectedEndTimeDateObj;
};

const removeTrailingEvents = (
  eventsOfDayIndex: CalenderEvent[],
  expectedEndTimeDateObj: Date
) => {
  const eventsToBeDeleted = eventsOfDayIndex.filter(event => {
    return (
      event.start.getTime() >= expectedEndTimeDateObj.getTime() &&
      event.end.getTime() > expectedEndTimeDateObj.getTime()
    );
  });
  eventsToBeDeleted.forEach(targetEvent => {
    const idx = eventsOfDayIndex.findIndex(
      event => event.start.getTime() === targetEvent.start.getTime()
    );
    eventsOfDayIndex.splice(idx, 1);
  });
};

export const getStartTimeAndLengthForEachDay = (
  events: CalenderEvent[]
): DayAvailability => {
  events.sort((a: CalenderEvent, b: CalenderEvent) => {
    return a.start.getTime() - b.start.getTime();
  });
  const startTime = moment(events[0].start);
  const endTime = moment(events[events.length - 1].end);
  const length = endTime.hours() - startTime.hours();
  return { startTime: startTime, length: length };
};

export const getEventsOfSpecificDay = (
  events: CalenderEvent[],
  index: number
) => {
  let eventsOfDayIndex = events.filter(event => {
    return event.start.getDay() === index;
  });
  return eventsOfDayIndex;
};

export const getSlotsStyleColor = (appointment: any) => {
  return {
    backgroundColor: appointment?.color,
    border: `1px solid ${appointment?.borderColor}`
  };
};
export const getLocationColor = (
  locations: ILocation[],
  locationIdx: number
) => {
  const location = locations?.find((loc: ILocation) => loc.id === locationIdx);
  return {
    backgroundColor: location?.color,
    border: `1px solid ${location?.borderColor}`
  };
};

export const prepareForApiCall = (
  modalData: ModalData,
  events: CalenderEvent[],
  formData: any,
  userName: string
) => {
  const data: AvailabilityTemplateData = {
    department: modalData.department,
    speciality: { id: Number(modalData.speciality) },
    name: formData.templateName,
    createdBy: userName,
    isActive: true,
    default: formData.default === 'YES',
    dayAvailabilities: prepareEventsForSubmission(events)
  };
  return data;
};

export const prepareEventsForSubmission = (events: CalenderEvent[]) => {
  return events.map(
    (event: CalenderEvent): AvailabilityTemplateEventsInput => {
      return {
        id: event.id,
        dayOfWeek: event.start.getDay(),
        type: event.type,
        locationInput: event.location,
        isAdmin: event.isAdmin!,
        startTime: moment(event.start).format('HH:mm'),
        endTime: moment(event.end).format('HH:mm'),
        clinics: event.clinics
      };
    }
  );
};

export const getSubmittedDailyWorkingHours = (
  dailyMaxHours: ProviderDailyMaxHours[]
) =>
  dailyMaxHours.map((it, idx) => ({
    dayOfWeek: idx,
    value: it.value
  }));

export const convertTZtoTZString = (
  tz: string | undefined,
  includeTzSuffix: boolean
) => {
  const tzAbbr = momentTz.tz(`${tz}`).format('zz');
  return includeTzSuffix ? `${tzMap[tzAbbr]} Time Zone` : tzMap[tzAbbr];
};

interface timezone {
  [key: string]: string;
}

export const tzMap: timezone = {
  EDT: 'Eastern Daylight',
  EST: 'Eastern Standard',
  CST: 'Central Standard',
  CDT: 'Central Daylight',
  MST: 'Mountain Standard',
  MDT: 'Central Standard',
  PST: 'Pacific Standard',
  PDT: 'Pacific Daylight'
};
