import React, { useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MainButton from 'views/components/button';
import ColorPickerInput from 'views/containers/form/colorPickerInput';
import { ConfigurationFormWrapper } from '../AppointmetTypesForm.style';
import { Input, Select } from 'api/sharedComponents/reactHookFormComponents';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { useMutation } from 'react-apollo';
import { GET_SPECIALTIES } from 'api/graphql/v2/queries/ProviderSpecialty';
import { PUT_SPECIALTY } from 'api/graphql/v2/mutations/ProviderSpecialty';
import { SPECIALTIES_SCHEMA } from 'utils/validators/configurations/specialties';
import { mapToOptions } from 'utils/mappers/form';
import { IProviderSpecialty, Specialty } from 'model/v2';
interface Props {
  initialValues: IProviderSpecialty;
  onFormClose: () => void;
  title: string;
  departments?: string[];
}
const SpecialitiesForm: React.FC<Props> = ({
  initialValues,
  title,
  onFormClose,
  departments
}: Props) => {
  const {
    handleSubmit,
    setValue,
    errors,
    reset,
    control,
    trigger,
    formState: { isSubmitting, isDirty }
  } = useForm<IProviderSpecialty>({
    defaultValues: initialValues,
    resolver: yupResolver(SPECIALTIES_SCHEMA)
  });

  const departmentsOptions = useMemo(
    () => (departments ? mapToOptions(departments) : []),
    [departments]
  );

  const [putSpecialty] = useMutation(PUT_SPECIALTY);

  const onSubmit = handleSubmit(async (data: IProviderSpecialty) => {
    await putSpecialty({
      variables: {
        specialty: {
          ...data,
          id: initialValues?.id
        }
      },
      refetchQueries: [
        {
          query: GET_SPECIALTIES
        }
      ]
    })
      .then(onFormClose)
      .catch(error => {
        console.log('Error while saving specialities', JSON.stringify(error));
      });
  });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  return (
    <ConfigurationFormWrapper>
      <div className="header">
        <h3>{title}</h3>
        <button className="ui icon button close-button" onClick={onFormClose}>
          <i className="close icon"></i>
        </button>
      </div>
      <form onSubmit={onSubmit}>
        <FormItem label="Title">
          <Controller
            name={Specialty.title}
            control={control}
            render={props => (
              <Input errors={errors} placeholder="Title" field={props} />
            )}
          />
        </FormItem>
        {initialValues?.id !== 0 && (
          <FormItem label="ID">
            <Controller
              name={Specialty.id}
              control={control}
              render={props => (
                <Input
                  errors={errors}
                  placeholder="ID"
                  field={props}
                  disabled
                />
              )}
            />
          </FormItem>
        )}

        <FormItem label="Abbreviation">
          <Controller
            name={Specialty.abbreviation}
            control={control}
            render={props => (
              <Input errors={errors} placeholder="Abbreviation" field={props} />
            )}
          />
        </FormItem>

        <Controller
          name={Specialty.color}
          control={control}
          render={() => (
            <ColorPickerInput
              title="Color"
              control={control}
              name={Specialty.color}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
            />
          )}
        />
        <FormItem label="Department Link">
          <Controller
            name={Specialty.department}
            control={control}
            render={fieldProps => (
              <Select
                options={departmentsOptions}
                placeholder="Select Department"
                field={fieldProps}
                errors={errors}
              />
            )}
          />
        </FormItem>
        <MainButton
          className="submit-button"
          title="Save"
          onClick={() => {
            onSubmit();
          }}
          disabled={isSubmitting || !isDirty}
          loading={isSubmitting}
        />
      </form>
    </ConfigurationFormWrapper>
  );
};
export default React.memo(SpecialitiesForm);
