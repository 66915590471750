import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const FilterSelectionWrapper = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 16px;
  .div-days {
    margin-left: 29px;
    background: #c9cfd3;
    opacity: 0.4;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    display: flex;
    flex: row;
    width: 342px;
  }
  .days {
    margin: 14px 11px;
    padding: 5px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: black;
    width: 45px;
    &:hover {
      color: #ffffff;
      background: #6f42f5;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }
  }
  .selected-style {
    color: #6f42f5;
  }
  .to-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px;
    display: inline-block;
  }
  .appt-category-type {
    padding-top: 8px;
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
      padding-top: 10px;
    }
    .ant-select-arrow {
      position: absolute;
      height: 20px;
      left: 337px;
      top: 24px;
    }
    .ant-select-selector {
      border-radius: 5px !important;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }
  .header {
    position: absolute;
    height: 16px;
    left: 20px;
    margin-bottom: 9px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #415766;
  }
  .start-column {
    min-width: 160px;
    max-width: 160px;
    position: absolute;
    left: 28px;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .ant-picker-input > input::placeholder {
    color: ${colors.PURPLE};
  }
  .end-column {
    min-width: 130px;
    max-width: 130px;
    position: absolute;
    left: 73px;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .datapicker-style {
    margin-top: 9px;
  }
  .row-style {
    position: absolute;
    left: 28px;
    padding: 0px !important;
    margin-bottom: 13px;
  }
  .column-style {
    min-width: 342px;
    max-width: 342px;
  }
  .label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    &.part2 {
      color: #415766;
      font-weight: bold;
    }
  }
  .ant-select-multiple .ant-select-selection-item-remove svg {
    display: none !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9 !important;
    border-radius: 6px !important;
  }
  .ant-select-multiple .ant-select-selection-item {
    background: transparent;
    border: none;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${colors.PURPLE};
    border-right-width: 1px !important;
  }
  .ant-picker {
    border: 1px solid ${colors.PURPLE};
  }

  .ant-picker-suffix {
    color: ${colors.PURPLE};
  }
`;

interface Props {
  startTimeSelected?: boolean;
  endTimeSelected?: boolean;
}

export const CustomTimeSlotWrapper = styled.div<Props>`
  display: inherit;
  .MuiFormControl-root {
    margin-top: 4%;
  }
  .MuiInputBase-root {
    font-size: 10px;
  }
  .MuiInput-underline:before {
    content: '';
    position: inherit;
    border: 1px solid blue;
  }
  .MuiInput-underline:after {
    border: 1px solid blue;
  }
  .MuiInputBase-input {
    width: 30px;
    box-sizing: inherit;
    color: ${colors.PURPLE};
  }
  .selected-start-time .MuiInputBase-input {
    color: ${props => (props.startTimeSelected ? 'white' : colors.PURPLE)};
  }
  .selected-end-time .MuiInputBase-input {
    color: ${props => (props.startTimeSelected ? 'white' : colors.PURPLE)};
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  .time-error {
    color: red;
    font-size: 8px;
  }
  .MuiFormControl-root > input[type='time']::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  .MuiInput-input > input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  .MuiTextField-root > input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
`;

export const ClientAvailabilityWrapper = styled.div`
  margin-bottom: 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  .preferred-time-labels {
    margin-left: 25px;
  }
  .select-all-btn {
    text-decoration: underline;
    color: #50bed1;
  }
  .day-row {
    align-items: center;
    max-width: 340px;
    min-width: 340px;
    margin-left: 28px;
    margin-bottom: 10px;
    padding: 8px !important;
    border-radius: 8px;
    border: 1px solid #6f4bf1;
    color: ${colors.PURPLE};
  }

  .custom-time-slot {
    padding: 3px;
    border-radius: 12px;
    border: 1px solid #6f4bf1;
    font-size: 10px;
    display: inline-flex;
    margin-right: 15px;
  }
  .custom-time-slot > input[type='time']::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  .time-slot {
    font-size: 10px;
    border-radius: 12px;
    padding: 2px !important;
    border: 1px solid #6f4bf1;
    margin-right: 2%;
    text-align: center;
  }
  .edit-icon {
    color: ${colors.PURPLE} !important;
    font-size: 12px;
  }
  .selected-time-slot {
    background: ${colors.PURPLE};
    color: white !important;
  }
  .error-msg {
    color: #cc3f3f;
    font-size: 12px;
    margin-left: 28px;
  }
`;

export const CheckBoxSelectionWrapper = styled.div`
  overflow: auto;
  max-height: 220px;
  .ant-checkbox-wrapper {
    display: block;
    padding-left: 8px;
  }
  .ant-checkbox-group {
    display: block;
    padding-left: 20px;
  }
  .ant-checkbox-inner {
    display: inline-block;
  }
`;

export const NewClientFilter = styled.div`
  .label-title {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19.2px;
    color: #415766;
    margin-bottom: 1px;
  }
  .label-msg {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #415766;
  }
  .ignore {
    font-weight: 900;
  }
  .toggle-rectangle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 340px;
    min-width: 340px;
    max-height: 55px;
    margin-left: 28px;
    padding: 13px;
    border-radius: 8px;
    border: 1px solid ${colors.PURPLE};
  }
  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background-color: #e82424;
  }
  .ui.toggle.checkbox .box:hover:before,
  .ui.toggle.checkbox label:hover:before {
    background-color: #e82424;
  }

  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #3caf05 !important;
  }
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #3caf05 !important;
  }

  .ui.checkbox input:focus ~ .box:before,
  .ui.checkbox input:focus ~ label:before {
    background-color: #e82424 !important;
  }
  .filter-input_wrapper {
    width: 100% !important;
  }
`;
