import React from 'react';
import { Icon } from 'semantic-ui-react';
import { ContentWrapper } from './style';

interface IProps {
  message: string;
  onClick: () => void;
}

const Banner: React.FC<IProps> = ({ message, onClick }: IProps) => {
  return (
    <ContentWrapper>
      <label className="banner">{message}</label>
      <Icon link className="closeIcon" name="close" onClick={onClick} />
    </ContentWrapper>
  );
};
export default React.memo(Banner);
