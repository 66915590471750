import React, { useCallback, useEffect, useState } from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import apolloCache from '../apolloClientCache';
import LoggedInUserInfo from 'authentication-apollo/types/loggedInUserInfo';
import UserCredentials from 'authentication-apollo/types/userCredentials';
import getApolloLink from '../apolloClientLink';

export interface Props {
  children: (
    acount: Props['activeAccount'],
    logout: Props['handleLogout']
  ) => React.ReactNode;
  handleLogout: () => Promise<void>;
  acquireToken: () => Promise<string | undefined>;
  activeAccount: LoggedInUserInfo;
}

export default function ApolloWrapper({
  children,
  handleLogout,
  acquireToken,
  activeAccount
}: Props) {
  const env = process.env.REACT_APP_STAGE;

  const loadLoggedInUserCredentials = useCallback(async () => {
    const token = await acquireToken();

    return {
      accessToken: token,
      ...activeAccount
    } as UserCredentials;
  }, [acquireToken, activeAccount]);

  const [apolloClient, setApolloClient] = useState<ApolloClient<any> | null>(
    null
  );

  useEffect(() => {
    // Load ApolloLink and initialize Apollo Client
    (async () => {
      const apolloLink = await getApolloLink(loadLoggedInUserCredentials);
      const client = new ApolloClient({
        cache: apolloCache,
        link: apolloLink,
        connectToDevTools: env === 'DEV'
      });
      setApolloClient(client);
    })();
  }, [loadLoggedInUserCredentials, env]);

  if (!apolloClient) {
    return null; // Render a loading state or nothing until the Apollo Client is ready
  }

  return (
    <ApolloProvider client={apolloClient}>
      {children(activeAccount, handleLogout)}
    </ApolloProvider>
  );
}
