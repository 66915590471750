import styled from 'styled-components';

export const FormProviderWrapper = styled.div`
  padding: 0 24px 32px 24px;
  display: flex;
  .recurring-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 8px;
    &:first-of-type {
      padding: 0 8x 0 0;
    }
    &:last-of-type {
      padding: 0 0 0 8px;
    }
    .dimmed {
      opacity: 0.7;
    }
  }
  .input-num {
    width: 100%;
    padding: 0 7px;
    border-radius: 4px;
    height: 32px;
    border: 1px solid #bbb;
    outline: none;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:focus {
      border-color: #6f4bf1 !important;
    }
  }
  .ant-time-picker-input {
    border-color: #bbb;
  }
  .ant-time-picker-panel-combobox {
    min-width: 168px;
  }
  .error-msg {
    color: #cc3f3f;
    font-size: 11px;
  }
  .loadingContent {
    margin: 0 auto;
  }
`;

export const ModalWrapper = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: column;
  padding: 0 100px 0 24px;
  .upper-formItem-wrapper {
    display: flex;
    &.formItem-with-title {
      align-items: center;
    }
    .single-item {
      width: 49%;
      margin-right: 8px;
      margin-left: 0px;
    }
    .upper-formItem {
      flex: 1;
      &.upper-formItem-title {
        display: flex;
        align-items: center;
        h3 {
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          color: #415766;
          white-space: nowrap;
          margin: 8px 12.5px 0 0;
        }
      }
      &:first-of-type {
        margin-right: 8px;
      }
      &:last-of-type {
        margin-left: 8px;
      }
      .time-formItem {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
      }
      .error {
        margin-top: 4px;
        font-size: 16px;
      }
    }
  }
  .speciality-formItem {
    > div {
      width: 50%;
      padding-right: 10px;
    }
  }
  .ant-select-selection {
    border-color: #bbb;
  }
  .saved_filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 0 10px;
    .saved-filters_checkbox {
      width: 100%;
      input {
        transition: all 0.5s ease-in-out;
      }
      label {
        font-size: 15px;
        padding-bottom: 0px;
        line-height: 1.27;
        color: #415766;
        transition: all 0.5s ease-in-out;
        padding-left: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 152px;
        &::before,
        &::after {
          width: 16px;
          height: 16px;
          border-color: #6f4bf1;
          transition: all 0.5s ease-in-out;
          border-radius: 2px;
          margin-top: 2px;
          margin-right: 10px;
        }
        &::after {
          padding-top: 2px;
          color: #fff !important;
          font-size: 11px;
          background-color: #6f4bf1;
        }
      }
    }
`;

export const SelectWithColorWrapper = styled.div`
  .rc-virtual-list-holder {
    > div {
      max-height: 110px !important;
      overflow: auto !important;
    }
  }
`;

export const ModalBottom = styled.div`
  border-top: 1px solid #bbb;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 32px;
  button {
    border: none;
    font-size: 15px;
    border-radius: 0;
    padding: 0;
    &:first-of-type {
      color: #919699;
      height: 18px;
    }
    &:last-of-type {
      color: #0d77b5;
      border-bottom: 1px solid #0d77b5;
      margin-left: 24px;
      height: 18px;
    }
  }
`;

export const ModalBottomWithDeleteWrapper = styled.div`
  border-top: 1px solid #bbb;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 32px;
  button {
    border: none;
    font-size: 15px;
    border-radius: 0;
    padding: 0;
    &.delete-btn {
      color: red;
      height: 18px;
    }
    &.cancel-btn {
      color: #919699;
      height: 18px;
    }
    &.confirm-btn {
      color: #0d77b5;
      border-bottom: 1px solid #0d77b5;
      margin-left: 24px;
      height: 18px;
    }
  }
`;

export const RecurringTitle = styled.h3`
  padding-left: 24px;
`;

export const SaveModalUpperContent = styled.div`
  font-size: 14px;
  padding: 0 100px 0 32px;
  font-family: Helvetica;
  font-weight: normal;
  .upper-formItem-wrapper {
    display: flex;
    margin-bottom: 20px;
    .upper-formItem {
      &:first-of-type {
        margin-right: 95px;
      }
    }
  }
`;

export const SaveModalContent = styled.div`
  font-family: Helvetica;
  font-weight: normal;
  padding: 0 24px 32px 32px;
  display: flex;
  flex-direction: column;
  .input-name {
    width: 359px;
    padding: 0 7px;
    border-radius: 4px;
    height: 32px;
    border: 1px solid #bbb;
    outline: none;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:focus {
      border-color: #6f4bf1 !important;
    }
  }
  .ant-select {
    width: 359px;
  }
  .ant-time-picker-input {
    border-color: #bbb;
  }
  .ant-time-picker-panel-combobox {
    min-width: 168px;
  }
  .error {
    color: red;
  }
`;
