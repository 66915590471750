import React from 'react';
import Card from 'views/components/ui/card';
import { IClinic } from 'model/v2';
import { Grid } from 'semantic-ui-react';
import { formatBusinessHours } from 'utils/format/clinic';

interface Props {
  clinic: IClinic;
  selectClinic: (record: string) => void;
}

const ClinicCard: React.FC<Props> = ({ clinic, selectClinic }: Props) => (
  <Card
    id={clinic.id?.toString() || ''}
    title={clinic.name || ''}
    subTitle={clinic.abbreviation}
    selectItem={selectClinic}
  >
    <p className="card-item__subtitle">Business Hours</p>
    <ul className="card-item__hours">
      {clinic.clinicBusinessHours &&
        formatBusinessHours(clinic.clinicBusinessHours, clinic?.id).map(
          workingDay => (
            <li key={workingDay.day}>
              <span>{workingDay.day}</span>
              {workingDay.startTime
                ? `${workingDay.startTime} - ${workingDay.endTime}`
                : 'Closed'}
            </li>
          )
        )}
    </ul>
    <Grid columns={3}>
      <Grid.Row className="card-item__stats">
        <Grid.Column>
          <span>{clinic.roomsCount}</span>
          Rooms
        </Grid.Column>
        <Grid.Column>
          <span>{clinic.providersCount}</span>
          Staff
        </Grid.Column>
        <Grid.Column>
          <span>{clinic.clientsCount}</span>
          Patients
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Card>
);
export default ClinicCard;
