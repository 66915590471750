import styled from 'styled-components';
import { Modal } from 'antd';
import * as colors from 'utils/constants/theme';

export const CalendarWrapper = styled.div`
    .rbc-month-row {
      overflow: hidden;
    }
    .rbc-time-header.rbc-overflowing{
      border: none !important;
      margin-right:0px !important;
    }
    // .event-appointment{
    //   max-width: 80% !important;
    // }
    .week-IndividualEvent{
      left: 0% !important;
      margin-left: 4px;
      width: 94% !important;
    }
    .day-IndividualEvent{
      left: 0% !important;
      margin-left: 2px;
      width: 99.6% !important;
    }
    .week-doubleEvent-0{
      left: 0% !important;
      margin-left: 4px;
      width: 46% !important;
    }
    .week-doubleEvent-1{
      left: 48% !important;
      margin-left: 4px;
      width: 46% !important;
    }
    .week-tripleEvent-0{
      left: 0% !important;
      margin-left: 3px;
      width: 30% !important;
    }
    .week-tripleEvent-1{
      left: 35% !important;
      width: 30% !important;
    }
    .week-tripleEvent-2{
      width: 30% !important;
      left:65% !important;
      margin-left: 3px;

    }
    .week-QuadrupleEvent-0{
      width: 22% !important;
      margin-left: 3px;
      left: 1% !important;
    }
    .week-QuadrupleEvent-1{
      width: 22% !important;
      left: 27% !important;
    }
    .week-QuadrupleEvent-2{
      width: 22% !important;
      left: 51% !important;
    }
    .week-QuadrupleEvent-3{
      width: 22% !important;
      left: 75% !important;
    }
    .week-QuintupleEvent-0{
      width: 19% !important;
      left: 2% !important;
    }
    .week-QuintupleEvent-1{
      width: 17% !important;
     left: 22.3333% !important;
    }
    .week-QuintupleEvent-2{
      width: 17% !important;
     left: 41% !important;
    }
    .week-QuintupleEvent-3{
      width: 17% !important;
     left: 60% !important;
    }
    .week-QuintupleEvent-4{
      width: 17% !important;
     left: 80% !important;
    }
    .week-HexaupleEvent-0{
      width: 14.5% !important;
      left: 2% !important;
    }
    .week-HexaupleEvent-1{
      width: 14% !important;
      left: 17.8% !important;
    }
    .week-HexaupleEvent-2{
      width: 14.5% !important;
      left: 34% !important;
    }
    .week-HexaupleEvent-3{
      width: 14% !important;
      left: 51% !important;
    }
    .week-HexaupleEvent-4{
      width: 14% !important;
      left: 67% !important;
    }
    .week-HexaupleEvent-5{
      width: 14% !important;
      left: 83% !important;
    }
    .week-OctupleEvent-0{
      width: 12% !important;
      left:2% !important;
    }
    .week-OctupleEvent-1{
      width: 12% !important;
      left:17% !important;
    }
    .week-OctupleEvent-2{
      width: 12% !important;
      left:31% !important;
    }
    .week-OctupleEvent-3{
      width: 12% !important;
      left:45% !important;
    }
    .week-OctupleEvent-4{
      width: 12% !important;
      left:58.5% !important;
    }
    .week-OctupleEvent-5{
      width: 12% !important;
      left:73% !important;
    }
    .week-OctupleEvent-6{
      width: 11% !important;
      left:86% !important;
    }
    .week-NonupleEvent-0{
      width: 11% !important;
      left: 2% !important;
    }
    .week-NonupleEvent-1{
      width: 11% !important;
      left: 14.5% !important;
    }
    .week-NonupleEvent-2{
      width: 11% !important;
      left: 27.5% !important;
    }
    .week-NonupleEvent-3{
      width: 11% !important;
      left: 40% !important;
    }
    .week-NonupleEvent-4{
      width: 11% !important;
      left: 52.5% !important;
    }
    .week-NonupleEvent-5{
      width: 11% !important;
      left: 65% !important;
    }
    .week-NonupleEvent-6{
      width: 10% !important;
      left: 77% !important;
    }
    .week-NonupleEvent-7{
      width: 9% !important;
      left: 89% !important;
    }
    .week-NonupleEvent-8{
      width: 9% !important;
      left: 89% !important;
    }
    .day-LastEvent-0{
      left: 1% !important;
      width: 18% !important;
    }
    .week-LastEvent-0{
      left:3px !important;
    }
    .day-doubleEvent-0{
      left: 3px !important;
      width: 50% !important

    }
    .day-doubleEvent-1{
      left: 51% !important;
      width: 48.6% !important

    }
    .day-tripleEvent-0{
      left: 2px !important;
      width: 34% !important;
    }
    .day-tripleEvent-1{
      left: 34.6% !important;
      width: 34% !important;
    }
    .day-tripleEvent-2{
      left: 69% !important;
      width: 26% !important;
    }
    .day-QuadrupleEvent-0{
      left: 2px !important;
      width: 25% !important;
    }
    .day-QuadrupleEvent-1{
      left: 25.5% !important;
      width: 25% !important;
    }
    .day-QuadrupleEvent-2{
      left: 50.8% !important;
      width: 24.4% !important;
    }
    .day-QuadrupleEvent-3{
      left: 75.5% !important;
      width: 24% !important;
    }
    .day-QuintupleEvent-0{
      left: 2px !important;
      width: 18% !important;
    }
    .day-QuintupleEvent-1{
      left: 19% !important;
      width: 18% !important;
    }
    .day-QuintupleEvent-2{
      left: 37.8% !important;
      width: 19% !important;
    }
    .day-QuintupleEvent-3{
      left: 57.6% !important;
      width: 20% !important;
    }
    .day-QuintupleEvent-4{
      left: 78.4% !important;
      width: 21% !important;
    }
    .day-HexaupleEvent-0{
      width: 16% !important;
      left: 2px !important;
    }
    .day-HexaupleEvent-1{
      width: 16% !important;
      left: 16.6% !important;
    }
    .day-HexaupleEvent-2{
      width: 16% !important;
      left: 32.8% !important;
    }
    .day-HexaupleEvent-3{
      width: 16% !important;
      left: 49% !important;
    }
    .day-HexaupleEvent-4{
      width: 17% !important;
      left: 65.4% !important;
      // display:none !important;
    }
    .day-HexaupleEvent-5{
      width: 16.6% !important;
      left: 83% !important;
    }
    .day-OctupleEvent-0{
      left: 2px !important;
      width: 14% !important;
    }
    .day-OctupleEvent-1{
      left: 14.6% !important;
      width: 14% !important;
    }
    .day-OctupleEvent-2{
      left: 28.8% !important;
      width: 14% !important;
    }
    .day-OctupleEvent-3{
      left: 43% !important;
      width: 14% !important;
    }
    .day-OctupleEvent-4{
      left: 57.2% !important;
      width: 14% !important;
    }
    .day-OctupleEvent-5{
      left: 71.4% !important;
      width: 14% !important;
    }
    .day-OctupleEvent-6{
      left: 85.6% !important;
      width: 14% !important;
    }
    .day-NonupleEvent-0{
      left: 2px !important;
      width: 12% !important;
    }
    .day-NonupleEvent-1{
      left: 12.6% !important;
      width: 12% !important;
    }
    .day-NonupleEvent-2{
      left: 25% !important;
      width: 12% !important;
    }
    .day-NonupleEvent-3{
      left: 37.4% !important;
      width: 12% !important;
    }
    .day-NonupleEvent-4{
      left: 49.8% !important;
      width: 12% !important;
    }
    .day-NonupleEvent-5{
      left: 62% !important;
      width: 12% !important;
    }
    .day-NonupleEvent-6{
      left: 74.6% !important;
      width: 12% !important;
    }
    .day-NonupleEvent-7{
      left: 87% !important;
      width: 12.6% !important;
    }
    .rbc-time-header-content{
      background:rgba(201, 207, 211, 0.35);
    }
    .Client-Profile-ToolBar{
      width: 1075px;
    }
    .rbc-timeslot-group{
      min-height: 68px;
    }
    
    .availability-slot-week{
      z-index: 0 !important;
      left: 0 !important;
    }
    .availability-slot-day{
      left: 0 !important;
      z-index: 0 !important;
    }
    .Client-Profile-ToolBar .calendar-nav{
      margin-left:48px;
    }
    .Client-Profile-ToolBar .calendar-nav__today .ant-radio-button-wrapper{
      width:136px;
      text-align: center;
      margin-right: 31px;
    }
    .rbc-events-container{
      border-right: 1px solid #DDD;
    }
    .rbc-time-header{
      margin-bottom: 18px;
    }
    .ProviderEvent:hover {
      width: 100% !important;
      z-index: 2 !important; 
      min-height: 5rem !important;      
    
  }
    .rbc-month-header,
    .rbc-time-header-cell {
      .rbc-header {
        white-space: pre-wrap;
        border-color: transparent;
        margin-top:10px;
        span {
          max-width: 30px;
          display: block;
          margin: 0 auto;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          text-align: center;
          color: #21303a;
        }
      }
    }
    .rbc-month-view {
      border: none;
      .rbc-month-header {
        span {
          font-weight: bold;
          padding-bottom: 20px;
        }
      }
    }
  }
  &.day {
    .rbc-event {
    }
    .rbc-day-slot,
    .rbc-time-header-content {
      min-width: calc(100% / 3 - 40px);
    }
    .rbc-time-column:nth-child(2) {
      .rbc-current-time-indicator {
        &:before {
          content: '';
          position: absolute;
          top: -9px;
          left: 0;
          width: 16px;
          height: 16px;
          border-radius: 12px;
          background-color: #ed6347;
        }
      }
    }
  }
  &:not(.month) {
    .rbc-event {
      display: block !importantt;

      &.availability {
        width: 100% !important;
        z-index: 0 !important;
        left: 0% !important;
        opacity: 0.8 !important;
        min-height: unset !important;
        border-radius: 0;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        &:hover {
          width: 100%;
          min-height: unset !important;
          z-index: 0 !important;
        }
        .rbc-event-content {
          background-color: inherit;
          p {
            color: transparent !important;
          }
        }
        .rbc-event-label {
          max-width: 100% !important;
          color: transparent !important;
        }
      }

      &:not(.na) {
        min-height: 35px;
        &:hover {
          // min-height: 5rem;
          max-height: auto;
          z-index: 2;
        }
      }
      &.rbc-event-continues-earlier {
        /* display: none !important; */
      }
      &:not(.na) {
        z-index: 1;
      }
      &.na {
        left: 0 !important;
        width: 100% !important;
        min-height: auto;
        background-image: linear-gradient(
          45deg,
          #dbdbdb 6.52%,
          #f8f8f8 6.52%,
          #f8f8f8 50%,
          #dbdbdb 50%,
          #dbdbdb 56.52%,
          #f8f8f8 56.52%,
          #f8f8f8 100%
        );
        /* background-size: 32.53px 32.53px; */
        background-size: 18px 18px;
        pointer-events: none;
        > * {
          opacity: 0;
        }
      }
      &.at-1 {
        background-color: ${colors.AT_1};
      }
      &.at-2 {
        background-color: ${colors.AT_2};
      }
      &.at-3 {
        background-color: ${colors.AT_3};
      }
      &.at-4 {
        background-color: ${colors.AT_4};
      }
      &.at-5 {
        background-color: ${colors.AT_5};
      }
      &.at-6 {
        background-color: ${colors.AT_6};
      }
      &.at-7 {
        background-color: ${colors.AT_7};
      }
      &.at-8 {
        background-color: ${colors.AT_8};
      }
      &.at-9 {
        background-color: ${colors.AT_9};
      }
      &.at-10 {
        background-color: ${colors.AT_10};
      }
      &.at-11 {
        background-color: ${colors.AT_11};
      }
      &.at-12 {
        background-color: ${colors.AT_12};
      }
      &.at-13 {
        background-color: ${colors.AT_13};
      }
      &.at-14 {
        background-color: ${colors.AT_14};
      }
    }
  }
  // &.week {
  //   .rbc-event {
  //     &:hover {
  //       width: 100% !important;
  //       z-index: 2 !important;
  //       p {
  //         line-height: inherit !important;
  //       }
  //     }
  //   }
   
   
    .rbc-events-container .rbc-event .rbc-event-content {
      p {
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 2px;
      }
    }
  }
  &.month {
    .rbc-event {
      position: relative;
      padding-left: 30px;
      background-color: transparent !important;
      font-size: 10px;
      letter-spacing: -0.33px;
      color: #21303a;
      i {
        position: absolute;
        top: 2px;
        left: 5px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      &.at-1:before {
        background-color: ${colors.AT_1};
      }
      &.at-2:before {
        background-color: ${colors.AT_2};
      }
      &.at-3:before {
        background-color: ${colors.AT_3};
      }
      &.at-4:before {
        background-color: ${colors.AT_4};
      }
      &.at-5:before {
        background-color: ${colors.AT_5};
      }
      &.at-6:before {
        background-color: ${colors.AT_6};
      }
      &.at-7:before {
        background-color: ${colors.AT_7};
      }
      &.at-8:before {
        background-color: ${colors.AT_8};
      }
      &.at-9:before {
        background-color: ${colors.AT_9};
      }
      &.at-10:before {
        background-color: ${colors.AT_10};
      }
      &.at-11:before {
        background-color: ${colors.AT_11};
      }
      &.at-12:before {
        background-color: ${colors.AT_12};
      }
      &.at-13:before {
        background-color: ${colors.AT_13};
      }
      &.at-14:before {
        background-color: ${colors.AT_14};
      }
    }
  }

  .ant-radio-button-wrapper:hover:not(:first-child)::before {
    background-color: #6f4bf1;
  }

  .rbc-event-content {
    > div:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
  .calendar-list {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background-color: #eae8ff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.left {
      top: 20px;
      left: 10px;
    }
    &.right {
      right: 0;
      top: 20px;
    }
    svg {
      fill: #6f4bf1;
      transition: 0.2s transform linear;
    }
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
  .rbc-time-view-resources {
    .rbc-time-gutter,
    .rbc-time-header-gutter {
      border: none;
      background: #ffffff;
      .rbc-timeslot-group {
        border-bottom: none;
      }
    }
  }
  .profile-schedule-calendar  .rbc-time-view{
    width: 1042px;
    margin-right: 188px;
    margin-bottom:28px;
  }
  .event-status {
    position: absolute;
    top: 0px;
    left: 118px;
    min-height: 17px;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.AVATAR_INITIAL};
    text-transform: capitalize;
    display: flex;
    align-items: center;
    svg {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      fill: ${colors.AVATAR_INITIAL};
    }
  }
  .event-room {
    color: ${colors.AVATAR_INITIAL};
    text-align: left;
  }
  .availability-slot-week,.availability-slot-day {
    width:100% !important;
    pointer-events: none !important
    border-radius: 0px;
  }
  .availability-slot-week ,.availability-slot-day{
    width:100% !important;
    pointer-events: none !important
  }
  .availability-slot-month{
    pointer-events: none;
  }
 
  .availability-slot-day .rbc-event-content {
    background-color: inherit !important;

  }
  .availability-slot-week .rbc-event-label{
    display: none !important;

  }
  .availability-slot-day .rbc-event-label{
    display: none !important;

  }
  .availability-slot-month .location-container{
    display:none;

  }
  .availability-slot-month {
    padding-left: 0px !important;
    border: none !important;
    font-size: 10px !important;
  }
  .avail-label{
    color:rgba(65, 87, 102, 1);
    font-size: 11px;
    font-family: Lato;
    line-height:12px;
    font-weight:700;
    margin-left: 20px
    margin-top: 6px;
  }
  .availability-slot-week .rbc-event-content {
    background-color: inherit !important;
    padding: 2px 0px 0px 0px !important;

  }
  .location-container {
    color:rgba(65, 87, 102, 1);
    font-size: 11px;
    font-family: Lato;
    line-height:12px;
    margin-top: 8px;
    margin-left: 20px;
    font-weight: 700;
  }
  .availability-slot-month  .avail-label{
    font-size: 10px !important;
    margin-left: 0px;
    
  }
  .availability-slot-month .avail-content-container{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }
  .availability-slot-week .availability-icon{
    display:none;
  }
  .availability-slot-day .availability-icon{
    display:none;
  }

`;
export const CalendarToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${colors.PURPLE} !important;
  }
  .calendar-switch {
    label {
      color: ${colors.PURPLE};
      border-color: ${colors.PURPLE};
      box-shadow: inherit;
      &:first-child {
        border-color: ${colors.PURPLE};
      }
      &.active,
      &:hover {
        border-color: ${colors.PURPLE};
        background-color: ${colors.PURPLE};
        color: #fff;
        box-shadow: inherit;
      }
    }
  }
  .calendar-nav {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: 140px;
    > * {
      display: flex;
      align-items: center;
    }
    label {
      color: ${colors.PURPLE};
      border: 1px solid ${colors.PURPLE};
      box-shadow: inherit;
      &:first-child {
        border-color: ${colors.PURPLE};
      }
      &.ant-radio-button-wrapper-checked,
      &.active,
      &:hover {
        border-color: ${colors.PURPLE};
        background-color: ${colors.PURPLE};
        color: #fff;
        box-shadow: inherit;
      }
    }
    .calendar-nav__title {
      font-size: 14px;
      line-height: 30px;
      border-left-width: 0;
      padding: 0 18px !important;
      &:hover {
        background: #fff;
        color: ${colors.PURPLE};
      }
    }
    .calendar-nav__today {
      margin-right: 24px;
      label {
        border-radius: 8px;
        border-width: 2px;
        font-weight: bold;
      }
    }
    .ant-radio-group {
      label {
        padding: 0 9px;
      }
    }
  }
  .calendar-availability-update {
    font-size: 15px;
    display: flex;
  }
`;

export const StaffTitle = styled.div`
  padding-bottom: 5px;
  user-select: none;
  .staff-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
  }
  .staff-image {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .staff-name {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-left: 15px;
  }
  .staff-sessions {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
    color: ${colors.INACTIVE_URL};
    text-align: center;
  }
`;

export const EventModal = styled(Modal)`
  ${({ isModalContent }) =>
    isModalContent &&
    `
  .ant-modal-body {
    width: ${props => (props.isAuthAvailable ? '584px' : '520px')};
    min-height: 369px;
    max-height: 670px;
    overflow-y: scroll;
    padding: 15px 15px;
    box-shadow: 0 0 0 #000;
    .verticalLine {
      width: 1px;
      height: 177px;
      border: solid 1px #c9cfd3;
    }
  }
  .action-modal {
  }
  .person-left {
    display: flex;
    flex-direction: column;
    min-height: 155px;
    justify-content: space-between;
  }
  .other-messages {
    margin-top: 20px;
  }
  .event-modal {
    &__title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 2px solid #e4e7e9;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    &__person {
      display: flex;
      align-items: flex-start;
      .person-image {
        text-align: center;
        img {
          width: 72px;
          height: 72px;
          border: solid 2px #6f4bf1;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .person-info {
        padding-left: 15px;
      }
      h2 {
        font-family: Lato;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
      }
      .ant-tag {
        margin: 10px auto 0;
      }
    }
    &__type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .status {
        position: relative;
        min-height: 39px;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: ${colors.AVATAR_INITIAL};
        text-transform: capitalize;
        padding: 0 30px 15px 20px;
        &:before {
          content: '';
          position: absolute;
          top: 9px;
          left: 0;
          border-radius: 50%;
          width: 8px;
          height: 8px;
        }
        &.canceled:before {
          background-color: ${colors.RED};
        }
        &.checked-in:before {
          background-color: ${colors.GREEN};
        }
      }
      button {
        /* width: 121px; */
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    &__body {
      display: flex;
      &__telehealth {
        white-space: pre-line;
        border-top: 2px solid #e4e7e9;
        padding-top: 19px;
        .wraper-text {
          width: 100%;
          height: 100%;
          padding: 0px 10px 0px 27px;
        }
        a {
          margin-left: 22px;
        }
      }
    }
    &__info-list {
      width: 45%;
      padding-left: 0;
      svg {
        color: #919699;
        margin-right: 10px;
      }
      li {
        list-style: none;
        font-size: 15px;
        color: ${colors.AVATAR_INITIAL};
        &:not(:last-child) {
          padding-bottom: 15px;
        }
        span {
          margin-left: 15px;
        }
      }
      .anticon {
        color: #919699;
        margin-right: 15px;
      }
      a {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.17px;
        text-decoration: underline;
        color: ${colors.ACTIVE_URL};
      }
    }
    &__info-list-sub {
      width: 100%;
      padding-left: 0;
      svg {
        color: #919699;
        margin-right: 10px;
      }
      li {
        list-style: none;
        font-size: 15px;
        color: ${colors.AVATAR_INITIAL};
        &:not(:last-child) {
          padding-bottom: 15px;
        }
        span {
          margin-left: 15px;
        }
      }
      .anticon {
        color: #919699;
        margin-right: 15px;
      }
      a {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.17px;
        text-decoration: underline;
        color: ${colors.ACTIVE_URL};
      }
    }
  }
  `}
`;

export const CancelConfirm = styled.div`
  p {
    font-size: 15px;
    font-weight: bold;
    opacity: 0.8;
    color: #21303a;
    padding: 30px 0;
  }
  .textArea {
    margin-top: 16px;
    height: 60px;
    font-size: 14px;
    min-height: 60px !important;
  }
`;

export const AuthAppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  padding-top: ${({ isAuthAppt }) => (isAuthAppt ? '20px' : '0')};
  .fa-exclamation-triangle {
    font-size: 20px;
    text-align: center;
    color: #ec4f4f;
  }
  h4 {
    font-size: 14px;
    font-weight: bold;
    color: ${({ isAuthAppt }) =>
      isAuthAppt ? colors.INACTIVE_URL : colors.RED};
    text-transform: ${({ isAuthAppt }) => (isAuthAppt ? 'none' : 'uppercase')};
  }
  .wrapper_data {
    display: flex;
    flex-direction: column;
    margin-top: 9px;
    margin-bottom: 14px;
    span {
      opacity: 0.8;
      font-family: Lato;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      color: ${colors.AVATAR_INITIAL};
    }
    .start_end {
      display: flex;
      justify-content: space-between;
      padding-right: 8px;
      > span {
        font-family: Lato;
        font-size: 10px;
        font-weight: normal;
        color: ${colors.AVATAR_INITIAL};
        &.date-separator {
          display: none;
        }
      }
    }
  }
  .auth_therapies_data {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    color: #000000;
  }
  span {
    width: 45px;
    text-align: center;
    font-family: Lato;
    font-size: 15px;
    color: ${colors.AVATAR_INITIAL};
    &.red {
      color: ${({ isAuthAppt }) =>
        isAuthAppt ? colors.AVATAR_INITIAL : colors.RED};
    }
  }
`;

export const EditTimeModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
  flex-wrap: nowrap;
  .title {
    color: #21303a;
    font-family: Lato;
    opacity: 0.8;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 41px;
  }
  .notes-wrapper {
    margin-top: 15px;
    width: 70%;
  }
  .label{
    font-family: Lato;
    font-size: 14px;
    color: #21303a;
    margin-bottom: 14px;
  }
  .form {
    display: flex;
    flex-direction: row;
    width: 80%;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin-top: 30px;
  }
  .item{
    width: 150px;
  }
  .buttons{
    margin-right: 179px;
    margin-top: 58px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: row
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-content: flex-end;
  }
  .button{
    border: none;
    font-family: Lato;
    font-size: 15px;
    text-decoration: underline;
    box-shadow: none;
    background-color: #fff;
  }
  .primary {
    color: #0d77b5;
    opacity: 1;
  }
  .secondary{
    color: #919699;
    margin-right: 10px;
  }
  .underlineSpan{
    text-decoration: underline;
  }
  .disabled{
    opacity: 0.5
  }
`;
