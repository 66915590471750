import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { MAIN_CALENDAR_VIEWS_OPTIONS } from 'utils/constants/lists';
import { mapToDropDownOption } from 'utils/mappers/form';
import { MainCalendarToolbarWrapper } from './style';
import { isTodayInRange } from 'utils/builders/calendar';
import Select from 'views/components/ui/select';
import { DateRange, ToolbarProps, View } from 'react-big-calendar';
import CaretLeftIcon from 'views/components/icons/caret/caretLeftIcon';
import CaretRightIcon from 'views/components/icons/caret/caretRightIcon';
import CalendarIcon from 'views/components/icons/calendar';
import EditIcon from 'views/components/icons/edit';
import { CalendarView } from 'utils/cache/calendar';
import { Button } from 'semantic-ui-react';
import { IDropdownOptions } from 'model/ui/dropdown';
import DatePicker from 'components/ui/datePicker';
import { calendarIntervals } from 'utils/constants/calendar';
import { IPlannerToolbarData } from 'model/calendar';
import useClickOutsideHook from 'hooks/clickOutsideHook';
import FilterTagList from './FilterTagList/index';
import { useReactiveVar } from '@apollo/client';
import { calendarFiltersPrefVar } from 'utils/cache/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { plannerContext } from 'pages/MainCalendarPage';
import { FilterPageNameEnum } from './FilterTagList/form/utils';
const moment = require('moment-timezone');

enum CALENDAR_TOOLBAR_NAVIGATION_EVENTS {
  today = 'TODAY',
  prev = 'PREV',
  next = 'NEXT'
}

interface Props {
  data: ToolbarProps<Event> | IPlannerToolbarData;
  range: DateRange;
  bannerText?: string;
  alertText?: string;
  updateView?: (view: CalendarView) => void;
  handleNavigation: (date: Date) => void;
  setShouldRefresh?: any;
  customViewEnabled?: Boolean;
  disabled?: boolean;
  inEditMode?: boolean;
  handleEditClick?: () => void;
}

const MainCalendarToolbar: React.FC<Props> = ({
  data,
  range,
  bannerText,
  alertText,
  updateView,
  handleNavigation,
  setShouldRefresh,
  customViewEnabled = false,
  disabled = false,
  inEditMode = false,
  handleEditClick
}: Props) => {
  const { label, onNavigate, view, date, onView } = data;
  const [calendarVisible, setCalendarVisible] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  const { isLoading } = useContext(plannerContext);

  const onOusideClick = useCallback(() => {
    setCalendarVisible(false);
  }, []);

  useClickOutsideHook(calendarRef, onOusideClick);
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const customViewSelector = useMemo<JSX.Element | undefined>(() => {
    const setViewOnCalendar = (
      onViewChange: (view: View) => void,
      view: View
    ) => {
      onViewChange(view);
      if (updateView != null) {
        updateView(view);
      }
    };
    return (
      <Select
        onChange={(
          _event: React.SyntheticEvent<HTMLElement, Event>,
          data: any
        ) => {
          if (onView) setViewOnCalendar(onView, data.value);
        }}
        value={view}
        options={
          mapToDropDownOption(MAIN_CALENDAR_VIEWS_OPTIONS) as IDropdownOptions[]
        }
        disabled={isLoading || disabled}
      />
    );
  }, [view, isLoading, disabled, updateView, onView]);

  const handleDateChange = useCallback(
    (e: Date) => {
      setCalendarVisible(false);
      handleNavigation(e);
    },
    [handleNavigation]
  );

  const handleRefresh = useCallback(
    (date: Date) => {
      handleNavigation(moment(date).toDate());
      setShouldRefresh(true);
    },

    [handleNavigation, setShouldRefresh]
  );

  const handleCalendarIconClick = useCallback<() => void>(() => {
    setCalendarVisible(prevState => !prevState);
  }, []);

  // const onEdit = useCallback<() => void>(() => {
  //   console.log('on click', handleAddAlternateModal, onEdit);
  // }, [handleAddAlternateModal]);

  return (
    <MainCalendarToolbarWrapper
      isLoading={isLoading}
      active={isTodayInRange(range)}
    >
      <div className="calendar-nav">
        <div
          className={`calendar-nav__left-side
          ${customViewEnabled ? 'customView' : ''} 
          ${disabled ? 'hidden' : ''}`}
        >
          {!customViewEnabled ? customViewSelector : ''}
          <Button
            onClick={() =>
              isLoading
                ? undefined
                : onNavigate(CALENDAR_TOOLBAR_NAVIGATION_EVENTS.today)
            }
            className="calendar-nav__today"
            disabled={inEditMode || disabled}
          >
            Today
          </Button>
          <CaretLeftIcon
            className="calendar-nav__left"
            onClick={() =>
              isLoading
                ? undefined
                : onNavigate(CALENDAR_TOOLBAR_NAVIGATION_EVENTS.prev)
            }
            disabled={inEditMode}
          />
          {!customViewEnabled && (
            <CaretRightIcon
              className="calendar-nav__right"
              onClick={() =>
                isLoading
                  ? undefined
                  : onNavigate(CALENDAR_TOOLBAR_NAVIGATION_EVENTS.next)
              }
              disabled={inEditMode}
            />
          )}
          <span className="calendar-nav__title">{label}</span>

          {customViewEnabled && (
            <CaretRightIcon
              className="calendar-nav__right"
              onClick={() =>
                isLoading
                  ? undefined
                  : onNavigate(CALENDAR_TOOLBAR_NAVIGATION_EVENTS.next)
              }
              disabled={inEditMode}
            />
          )}
          <div className="date-picker-wrapper">
            <CalendarIcon
              className={
                inEditMode
                  ? 'calendar-nav__calendarIcon editingMode'
                  : 'calendar-nav__calendarIcon'
              }
              onClick={isLoading ? undefined : handleCalendarIconClick}
            >
              <div ref={calendarRef} className="date-picker">
                {calendarVisible && (
                  <DatePicker
                    onChangeHandler={handleDateChange}
                    date={new Date(date)}
                    view={calendarIntervals.MONTH}
                  />
                )}
              </div>
            </CalendarIcon>
          </div>
          {!customViewEnabled && (
            <div className="refresh-icon">
              <FontAwesomeIcon
                icon={faRedo}
                onClick={() => (isLoading ? undefined : handleRefresh(date))}
              />
            </div>
          )}
        </div>
      </div>
      {customViewEnabled && (
        <div className="availability-header">
          {bannerText && <div className="template-name">{bannerText}</div>}
          <EditIcon
            className={inEditMode ? 'edit-icon disabled' : 'edit-icon'}
            onClick={handleEditClick}
          />
        </div>
      )}
      {!bannerText && (
        <FilterTagList
          filters={filters}
          showSavedIcon={true}
          pageName={FilterPageNameEnum.planner}
        />
      )}
      {customViewEnabled && (
        <>
          <p className="tz-alert footer">{alertText}</p>
        </>
      )}
    </MainCalendarToolbarWrapper>
  );
};

export default React.memo(MainCalendarToolbar);
