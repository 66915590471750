import { isEmpty } from 'lodash';
import { sortByStartTime } from 'utils/sort';
import { FEATURES, getFeatureAvailability } from '../featureToggle';

export const isUnauthorizedEvent = event => isEmpty(event.authorizedTherapyID);

export const hasWarnings = eventWarnings => {
  if (getFeatureAvailability(FEATURES.AUTHORIZATION))
    return eventWarnings.some(isUnauthorizedEvent);
  return false;
};

export const getWarningsSupportanceData = (warnings, reschedule) => {
  let supported = false;
  if (reschedule) return { supported };

  warnings.sort(sortByStartTime);

  let blockStart, blockEnd;
  let foundBlock = false;
  for (const { startDate, authorizedTherapyID } of warnings) {
    if (authorizedTherapyID) {
      if (foundBlock) return { supported };

      if (!blockStart) {
        blockStart = startDate;
      }
      blockEnd = startDate;
    } else {
      if (blockStart && blockEnd) foundBlock = true;
    }
  }

  return !blockEnd ? { supported } : { supported: true, blockStart, blockEnd };
};
