import { eventInRange, eventInRangeV2 } from './events';
import moment from 'moment';
import { getApptType } from 'utils/common';
import { formatUsername, parseUsername } from 'utils/format';
import { getTimeSlots } from 'time-slots-generator';
import { MAX_CALENDAR_TIME, MIN_CALENDAR_TIME } from 'utils/constants/calendar';
import { APPOINTMENT_HEADER_COLORS } from 'utils/constants/theme';
import { availabilityColorsMap } from 'utils/mappers/response/availabilityEventsColorsMap';
import { MapProviderPetAllergies } from 'utils/common';
import { TRANSPORTATION_SELECT } from 'utils/constants/tables/staff';
import ThreeDot from 'assets/img/DotsThreeVertical.png';
import { constructProgramDataForDisplay } from 'utils/common';

/** @todo this function has V2 */

export const PROVIDER_LOCATIONS = {
  '3': 'In Clinic and Telehealth',
  '1': 'Telehealth Only',
  '2': 'In Clinic Only',
  '5': 'Offsite and Telehealth',
  '7': 'Any Availability'
};
export const getStaffMemberEvents = (events, apptTypes, range) => {
  const filtered = [];
  events.forEach(event => {
    if (eventInRange(event, range)) {
      const appointmentType = getApptType(event.appointmentType, apptTypes);

      filtered.push({
        ...event,
        start: new Date(event.startTime),
        end: new Date(event.endTime),
        title: parseUsername(event.client?.name),
        desc: { main: appointmentType?.title },
        color: appointmentType?.headerColor || APPOINTMENT_HEADER_COLORS.ADMIN
      });
    }
  });
  return filtered;
};

export const getStaffMemberEventsV2 = (events, range) => {
  const filtered = [];
  events.forEach(event => {
    if (eventInRangeV2(event, range)) {
      const appointmentType = event.appointmentType?.parent
        ? event.appointmentType?.parent
        : event.appointmentType;
      filtered.push({
        ...event,
        isProviderEvent: true,
        start: new Date(event.startDate),
        end: new Date(event.endDate),
        title: event.client
          ? parseUsername(
              event.client?.name ||
                formatUsername(event.client?.firstName, event.client?.lastName)
            )
          : '',
        desc: { main: appointmentType?.title },
        color: appointmentType?.headerColor || APPOINTMENT_HEADER_COLORS.ADMIN
      });
    }
  });
  return filtered;
};

export const mapAvailabilityBlocksToEvents = (
  availabilityBlocks,
  locations,
  startOfWeekDate
) => {
  const events = availabilityBlocks?.map(block => {
    return {
      id: block.id,
      start: generateDateFromBlockTime(
        block.startTime,
        block.dayOfWeek,
        startOfWeekDate
      ),
      end: generateDateFromBlockTime(
        block.endTime,
        block.dayOfWeek,
        startOfWeekDate
      ),
      title: getAvailabilityBlockTitle(block),
      clinic: block.clinics,
      color: getAvailabilityBlockColor(block, locations),
      locationCategory: '',
      type: 'availability'
    };
  });
  return events;
};

const getAvailabilityBlockTitle = event => {
  let title = 'Available for ';

  if (event.type === 'working') {
    title = title + 'Care Sessions ';
  } else {
    title += event.type + ' ';
  }
  title += PROVIDER_LOCATIONS[event.location];
  return title;
};

const getAvailabilityBlockColor = (event, locations) => {
  const availabilityColors = availabilityColorsMap();
  return availabilityColors.get(event.type?.toLowerCase());
};

const generateDateFromBlockTime = (timeStr, dayOfWeek, weekStart) => {
  const [hour, mins] = timeStr.split(':');
  const result = moment(weekStart);
  result.hour(hour);
  result.minute(mins);
  result.day(dayOfWeek);

  return result.toDate();
};

export const getStaffMemberNonWorkingHours = (
  workingHours,
  currentRange,
  userId
) => {
  if (!workingHours) return [];
  const momentDayToChar = {
    1: 'mon',
    2: 'tues',
    3: 'wed',
    4: 'thurs',
    5: 'fri',
    6: 'sat',
    0: 'sun'
  };
  let result = [];

  const cur = moment(currentRange.start);
  while (cur.isBefore(currentRange.end)) {
    const day = momentDayToChar[cur.day()];
    if (workingHours[day]) {
      const hours = workingHours[day] || [];
      const workingTimes = [
        [0, 410],
        [1260, 1440]
      ];
      hours.forEach(hourObject => {
        const [startHour, startMin] = hourObject.startTime.split(':');
        const [endHour, endMin] = hourObject.endTime.split(':');
        const start = parseInt(startHour || 0) * 60 + parseInt(startMin || 0);
        const end = parseInt(endHour || 0) * 60 + parseInt(endMin || 0);
        workingTimes.push([start, end]);
      });

      const occupiedSlots = getTimeSlots(
        workingTimes,
        true,
        'quarter',
        false,
        true
      );
      for (let key in occupiedSlots) {
        const [startHour, startMin] = occupiedSlots[key].split(':');
        const start = moment(cur)
          .hour(startHour)
          .minute(startMin)
          .toDate();
        const end = moment(start)
          .add(15, 'minutes')
          .toDate();
        result.push({
          start: start,
          end: end,
          type: 'na',
          userId,
          resourceId: userId
        });
      }
    } else {
      result.push({
        start: cur.hour(MIN_CALENDAR_TIME.getHours()).toDate(),
        end: cur.hour(MAX_CALENDAR_TIME.getHours()).toDate(),
        type: 'na',
        userId,
        resourceId: userId
      });
    }
    cur.add(1, 'day');
  }
  return result;
};

export const mapProviderTableInfo = (
  providerData,
  Select,
  providerAllergies,
  handleStaffUpdate
) => {
  let mappedProviderList = [];
  if (providerData)
    return (mappedProviderList = providerData.map((providerInfo, index) => {
      let {
        clientInfo,
        providerList,
        providerRoasterNotes,
        providerAvailabilities
      } = providerInfo;
      let { providerCapacity } = providerList;
      let { address, speciality, clinic, corticaPrograms } = providerList;
      let futureClientIds = clientInfo.futureClient.map(data => data.clientId);
      let pastClient = clientInfo.pastClient.filter(
        data => !futureClientIds.includes(data.clientId)
      );
      let totalClientCount =
        clientInfo.futureClient?.length + pastClient?.length;
      let providerAddress = formProviderAddress(address, clinic);
      let programType = constructProgramDataForDisplay(corticaPrograms);
      let client_dayAvailabilities = providerDayAvailabilities(
        providerAvailabilities
      );
      let [latestUpdatedAvail] = client_dayAvailabilities;
      let updateAvailabilityDate = latestUpdatedAvail?.updatedAt
        ? moment(latestUpdatedAvail.updatedAt).format('MM/DD/YYYY')
        : 'N/A';
      return {
        providerId: providerList.id,
        name:
          providerList?.firstName +
          ' ' +
          providerList?.lastName +
          ',' +
          ' ' +
          speciality?.abbreviation +
          ' ' +
          `(${providerList?.clinic?.abbreviation || ''})`,
        status: providerList?.status || 'N/A',
        email: providerList?.email,
        phoneNumber: providerList?.mobilePhone || 'N/A',
        zipCode: address?.zipCode || 'N/A',
        gender: providerList.gender || 'N/A',
        programType: programType,
        transportation: providerList?.transportation,
        providerData: providerList,
        petAllergies: providerList?.petAllergies,
        actions: (
          <div>
            <img src={ThreeDot} />
          </div>
        ),
        availUpdated: updateAvailabilityDate,
        clients: totalClientCount,
        notes: providerRoasterNotes,
        notesCount: providerRoasterNotes?.length,
        providerCapacity: providerCapacity,
        providerAddress: providerAddress,
        speciality: speciality,
        employment: providerList?.employment,
        isObservable: providerList?.isObservable,
        client_dayAvailabilities: client_dayAvailabilities,
        currentClients: clientInfo?.futureClient,
        pastClient: pastClient
      };
    }));
};
const providerDayAvailabilities = ProviderTemplates => {
  let DayAvailabilities = [];
  ProviderTemplates.forEach(IndTemplate => {
    DayAvailabilities.push(...IndTemplate.dayAvailabilities);
  });
  return DayAvailabilities;
};
const formProviderAddress = (address, clinic) => {
  let provider_address = '';
  if (address) {
    provider_address += address?.addressLine1
      ? `${address.addressLine1}, `
      : '';
    provider_address += address?.addressLine2
      ? `${address.addressLine2}, `
      : '';
    provider_address += address?.city ? `${address?.city}, ` : '';
    provider_address += address?.state ? `${address?.state} ` : '';
    provider_address +=
      clinic?.abbreviation && address?.state ? `${clinic?.abbreviation} ` : '';
    provider_address += address?.zipCode || '';
  }

  // Trim any trailing commas and spaces
  provider_address = provider_address.trim().replace(/,\s*$/, '');
  return provider_address;
};
