import React from 'react';
import { DAY_INDEX } from 'utils/constants/default';
import { DayCheckboxItemWrapper } from './style';

interface IProps {
  dayIndex: number;
  isActive: boolean;
  isBordered: boolean;
  setActiveDayIndex: (dayIndex: number) => void;
}

const DayCheckboxItem: React.FC<IProps> = ({
  dayIndex,
  isActive,
  isBordered,
  setActiveDayIndex
}) => {
  return (
    <DayCheckboxItemWrapper isActive={isActive} isBordered={isBordered}>
      <div onClick={() => setActiveDayIndex(dayIndex)}>
        {DAY_INDEX.get(`${dayIndex}`)}{' '}
      </div>
    </DayCheckboxItemWrapper>
  );
};

export default DayCheckboxItem;
