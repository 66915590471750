import Error from './Error';
import Loading from './Loading';

interface Props {
  loading: boolean;
  error?: Error;
  children?: (data: any) => JSX.Element;
  data?: any;
  isSidebar?: boolean;
  scroll?: boolean;
  fullHeight?: boolean;
}

const Content = ({
  loading,
  error,
  data,
  children,
  isSidebar,
  scroll,
  fullHeight
}: Props): JSX.Element | null => {
  if (error) return <Error error={error} />;
  if (loading || !data)
    return (
      <Loading isSidebar={isSidebar} scroll={scroll} fullHeight={fullHeight} />
    );
  if (children) {
    return children(data);
  } else return null;
};
export default Content;
