import { useReactiveVar } from '@apollo/client';
import {
  convertFilteredToSearched,
  isAttendeeInFiltered
} from 'helpers/calendarHelper';
import useEventsFetcherHook from 'hooks/eventsFetcherHook';

import React, { Dispatch, SetStateAction, useContext, useRef } from 'react';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ICalendarResource } from 'model/calendar/filters';
import { calendarGroup } from 'model/calendar/groups';

import {
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar
} from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  calendarFiltersPrefVar
} from 'utils/cache/filters';
import { formatAttendeeName, formatProviderDisplayName } from 'utils/format';

import {
  fullPhantomEventMapper,
  mapObservationToPhantomEvent
} from 'utils/mappers/response/phantomEvents';
import { EVENTS_FETCHING_ACTIONS, plannerContext } from '..';
import { IClient, IEvent, IFullAppointmentType } from 'model/v2';
import {
  useAddClientToCalendar,
  useAddPhantomEvents,
  useAddProviderToCalendar,
  useApptTypesMap
} from './CustomPhantomHooks';
import moment from 'moment';
import { IPhantomEvent } from 'model/calendar/events';
import { IProvider } from '../../../model/v2/providers';

interface Props {
  appTypes: IFullAppointmentType[];
  providersMap: Map<number, any>;
  formAction: string;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<SetStateAction<ICalendarResource[]>>;
  abaObserversList?: IEvent[];
  additionalProvidersList?: IProvider[];
  isReorderFiltrSearchProvider: boolean;
}
const FormEvents: React.FC<Props> = ({
  appTypes,
  providersMap,
  formAction,
  paginationArray,
  setPaginationArray,
  abaObserversList,
  additionalProvidersList,
  isReorderFiltrSearchProvider
}) => {
  const methods = useFormContext();
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const selectedGroupRef = useRef<ICalendarResource | null>();
  const appTypesMap = useApptTypesMap(appTypes);
  const { setIsLoading, setIsCalendarLoading, isCalendarLoading } = useContext(
    plannerContext
  );
  const { getClientEvents, getStaffEvents } = useEventsFetcherHook(
    selectedGroupRef && selectedGroupRef.current
      ? [selectedGroupRef.current as ICalendarResource]
      : undefined,
    setIsLoading,
    setIsCalendarLoading,
    isCalendarLoading,
    EVENTS_FETCHING_ACTIONS.APPLY_SEARCH,
    setPaginationArray,
    paginationArray,
    [selectedGroupRef.current as ICalendarResource],
    [selectedGroupRef.current as ICalendarResource]
  );
  const addPhantomEvents = useAddPhantomEvents();
  const addClientToCalendar = useAddClientToCalendar(
    getClientEvents,
    filters,
    calendarView,
    calendarDate
  );
  const { addProviderToCalendar, searchedProviders } = useAddProviderToCalendar(
    getStaffEvents,
    filters,
    calendarView,
    calendarDate
  );

  const {
    selectedDay,
    startDate,
    endDate,
    duration,
    provider,
    client,
    locationType,
    scheduleType,
    appType,
    recurringDaysFlag,
    recurrencePattern
  } = useWatch({
    control: methods.control,
    name: [
      'selectedDay',
      'startDate',
      'endDate',
      'duration',
      'provider',
      'client',
      'locationType',
      'recurrencePattern',
      'scheduleType',
      'appType',
      'recurringDaysFlag',
      'selectedOpening'
    ]
  });

  useMemo(() => {
    let values = methods?.getValues();
    const selectedProvider = providersMap?.get(provider?.id);
    const currentEvents = calendarEventsPrefVar();
    let mappedEvents: IPhantomEvent[] = [];
    if (selectedProvider && selectedProvider.status !== 'Inactive') {
      mappedEvents = fullPhantomEventMapper(
        values,
        calendarDate,
        calendarView,
        appTypesMap,
        selectedProvider,
        formAction
      );
      if (abaObserversList && abaObserversList.length > 0) {
        abaObserversList.map(observer => {
          if (selectedDay) {
            const startDate =
              selectedDay.format('YYYY-MM-DD') +
              ' ' +
              moment(observer?.startDate).format('HH:mm:ss');
            const endDate = moment(startDate)
              .clone()
              .add(observer.duration, 'minutes');
            observer.startDate = moment(startDate).toDate();
            observer.endDate = endDate.toDate();
          }
          const mappedPhantomEvent = mapObservationToPhantomEvent(
            values,
            observer?.provider?.id,
            calendarView,
            calendarDate,
            appTypesMap,
            providersMap,
            abaObserversList.length,
            observer,
            true
          );
          mappedEvents.push(mappedPhantomEvent);
        });
      }
    }
    if (additionalProvidersList && additionalProvidersList.length) {
      additionalProvidersList.forEach(provider => {
        const selectedProvider = providersMap?.get(provider.id!);
        if (values.provider?.id) {
          values.provider.id = selectedProvider.id;
        }
        mappedEvents = mappedEvents.concat(
          fullPhantomEventMapper(
            values,
            calendarDate,
            calendarView,
            appTypesMap,
            selectedProvider,
            formAction
          )
        );
      });
    }
    if (mappedEvents?.length > 0) addPhantomEvents(currentEvents, mappedEvents);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDay,
    startDate,
    endDate,
    duration,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    client?.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    provider?.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    recurrencePattern?.recurringEvery,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    recurrencePattern?.recurringUntil,
    scheduleType,
    appType,
    locationType,
    recurringDaysFlag,
    providersMap,
    appTypesMap,
    calendarDate,
    calendarView,
    abaObserversList,
    additionalProvidersList
  ]);

  useMemo(() => {
    if (client?.id) {
      const values = methods?.getValues();
      const selectedClient: ICalendarResource = {
        id: values?.client.id,
        displayName: formatAttendeeName(
          (client as IClient)?.firstName,
          (client as IClient)?.lastName,
          client?.clinic?.abbreviation
        ),
        name: (client as IClient).name,
        attendeeType: calendarGroup.client,
        clinic: values.clinic,
        profile: (client as IClient)?.profile
      };
      selectedGroupRef.current = selectedClient;
      addClientToCalendar(selectedClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id]);

  useMemo(() => {
    if (additionalProvidersList?.length) {
      additionalProvidersList.forEach(provider => {
        let mappedProvider = providersMap?.get(provider.id!);
        mappedProvider = {
          ...provider,
          displayName: formatProviderDisplayName(provider),
          attendeeType: calendarGroup.provider
        };
        selectedGroupRef.current = mappedProvider;
        addProviderToCalendar([mappedProvider]);
      });
    }
  }, [addProviderToCalendar, additionalProvidersList, providersMap]);

  useMemo(() => {
    if (provider?.id) {
      let selectedProvider: ICalendarResource = providersMap?.get(provider?.id);
      if (selectedProvider && selectedProvider.status !== 'Inactive') {
        selectedProvider = {
          ...selectedProvider,
          displayName: formatProviderDisplayName(selectedProvider),
          attendeeType: calendarGroup.provider
        };
        //in filter convert to search
        if (isAttendeeInFiltered(provider?.id) && selectedProvider) {
          const shouldFetchEvents = convertFilteredToSearched(
            selectedProvider,
            isReorderFiltrSearchProvider
          );
          if (!shouldFetchEvents) return;
        } else if (searchedProviders?.has(provider?.id)) {
          return;
        }
        selectedGroupRef.current = selectedProvider;
        addProviderToCalendar([selectedProvider]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providersMap, provider?.id]);

  return <></>;
};

export default React.memo(FormEvents);
