import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { AddObserversFormWrapper } from '../style';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { Icon, Input } from 'semantic-ui-react';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { IEvent, IProvider } from 'model/v2';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { FormProvider, useForm } from 'react-hook-form';
import TimeDurationForm from 'pages/MainCalendarPage/AppointmentForm/Date/TimeDurationForm';
import { initValues } from 'pages/MainCalendarPage/AppointmentSidebar';
import { LocationType } from 'views/components/icons/eventIcons/locationIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
interface Props {
  key: any;
  Observations: IEvent;
  observerIndex: any;
  abaObserversList: IEvent[];
  setAbaObserversList: Dispatch<SetStateAction<IEvent[]>>;
  setLastAbaObserver: Dispatch<SetStateAction<IEvent>>;
  setObserverId: Dispatch<SetStateAction<number | undefined>>;
  setIsObserversDropdownShown: (val: boolean) => void;
  event: IEvent;
  observersData: any;
  isSaving: boolean;
  setIsAlertShown: (val: boolean) => void;
  setIsValidated: (val: boolean) => void;
  isValidated: boolean;
  setAlertContent: (val: string) => void;
}

const AbaObserversDropdown: React.FC<Props> = ({
  observerIndex,
  Observations,
  abaObserversList,
  setAbaObserversList,
  setLastAbaObserver,
  event,
  observersData,
  isSaving,
  setIsAlertShown,
  setIsValidated,
  isValidated,
  setAlertContent,
  setObserverId
}) => {
  const locationTypes = mapToOption(LOCATION_TYPE_LIST);
  let filteredLocations = locationTypes.filter(
    (location: { value: any }) =>
      location.value === event.locationType || location.value === 'Telehealth'
  );
  const { provider: leadProvider } = event;
  const [observingProviders, setObservingProviders] = React.useState<
    IProvider[]
  >([]);
  const onObserverSelect = (observersId: number) => {
    setObserverId(observersId);
    setAbaObserversList(current =>
      current.map((obj, index) => {
        if (index === observerIndex - 1) {
          return { ...obj, id: observersId, provider: { id: observersId } };
        }
        return obj;
      })
    );
  };
  const onLocationSelect = (location: LocationType) => {
    setAbaObserversList(current =>
      current.map((obj, index) => {
        if (index === observerIndex - 1) {
          return { ...obj, locationType: location };
        }
        return obj;
      })
    );
  };
  const onLinkChange = (e: any) => {
    setAbaObserversList(current =>
      current.map((obj, index) => {
        if (index === observerIndex - 1) {
          return { ...obj, telehealthLink: e.target.value };
        }
        return obj;
      })
    );
  };
  useEffect(() => {
    if (!observersData) return;
    const fetchedObservers: IProvider[] = observersData.getObservers;
    const filteredObservers = fetchedObservers;
    setObservingProviders(filteredObservers);
  }, [observersData, leadProvider, abaObserversList]);
  const observingProvidersFiltered = observingProviders.filter(
    provider => provider.id !== event.provider?.id
  );
  const dropdownOptions = observingProvidersFiltered.map(provider => ({
    value: provider.id,
    label: provider.name
  }));
  let eventObj = Observations;
  const methods = useForm({
    defaultValues: React.useMemo(() => {
      return initValues(eventObj);
    }, [event, observersData]),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });
  const onObserverRemove = (removedObserverId: number) => {
    setAbaObserversList((oldObserversList: IEvent[]) =>
      oldObserversList.filter(
        observer => observer.provider?.id !== removedObserverId
      )
    );
  };
  return (
    <AddObserversFormWrapper>
      <div className="aba_observer_div">
        <FormItem label={`Observer ${observerIndex}`}>
          <Select
            className="Observer_Select"
            options={dropdownOptions}
            filterOption={(input: string, option: any) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
            showSearch
            onSelect={onObserverSelect}
            listHeight={162}
            value={Observations.provider!.id!}
            placeholder={
              <p style={{ paddingTop: '5px' }}>
                <Icon name="search"></Icon> Search by provider name
              </p>
            }
            field={undefined}
            errors={undefined}
            disabled={isSaving}
          />
        </FormItem>
        {event.appointmentType?.eventType?.name === EVENT_TYPES.ABA && (
          <>
            <FormItem>
              <Select
                className="Observer_Select"
                options={filteredLocations}
                filterOption={(input: string, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
                showSearch
                onSelect={onLocationSelect}
                value={Observations.locationType}
                listHeight={162}
                placeholder={
                  <p style={{ paddingTop: '5px' }}>
                    <Icon name="search"></Icon> Please select meeting location
                  </p>
                }
                field={undefined}
                errors={undefined}
                disabled={isSaving}
              />
            </FormItem>
            {Observations.locationType === LOCATION_TYPE_LIST[2].id && (
              <FormItem>
                <Input
                  className="Observer_Input"
                  value={Observations.telehealthLink}
                  placeholder="Example: https://addlink.com"
                  onChange={onLinkChange}
                />
              </FormItem>
            )}
            <FormProvider {...methods}>
              <TimeDurationForm
                setLastAbaObserver={setLastAbaObserver}
                setAbaObserversList={setAbaObserversList}
                abaObserversList={abaObserversList}
                observerIndex={observerIndex}
                leadEvent={event}
                observations={eventObj}
                setIsAlertShown={setIsAlertShown}
                setIsValidated={setIsValidated}
                setAlertContent={setAlertContent}
              />
            </FormProvider>
          </>
        )}
      </div>
      <div className="aba_observer_trash">
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{ color: `#E82424` }}
          onClick={() => {
            if (!isSaving && !isValidated)
              onObserverRemove(Observations.provider!.id!);
          }}
        />
      </div>
    </AddObserversFormWrapper>
  );
};

export default AbaObserversDropdown;
