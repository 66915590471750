export const easeInOutQuad = (Math.easeInOutQuad = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
});

export const scrollLeft = (element, change, duration) => {
  var start = element.scrollLeft,
    currentTime = 0,
    increment = 20;

  var animateScroll = function() {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);

    element.scrollLeft = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const uniqByKeepFirst = (a, key) => {
  let seen = new Set();
  return a.filter(item => {
    let k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};

export const between = (x, min, max) => {
  return x >= min && x <= max;
};

export const roundToTwoDecimalPlaces = value => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto'
  });
};

export const scrollElementToTop = elementID => {
  const root = document.getElementById(elementID);
  root?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

export const roundToNearestIncrement = (value, increment) => {
  return Math.round(value / increment) * increment;
};
