import gql from 'graphql-tag';
import { appointmentTypesFragments } from 'api/graphql/v2/fragments/AppointmentTypes';

export const GET_APPT_TYPES_CONFIG = gql`
  query($eventType: String!) {
    appointmentTypesConfig(eventType: $eventType) {
      ...AppointmentTypesConfig
    }
  }
  ${appointmentTypesFragments.appointmentTypesConfig}
`;

export const GET_ALL_APPT_TYPES = gql`
  query($eventTypes: [String!]!) {
    allAppointmentTypesConfig(eventTypes: $eventTypes) {
      ...AllAppointmentTypesConfig
    }
  }
  ${appointmentTypesFragments.allAppointmentTypesConfig}
`;

export const GET_ABA_APPOINTMENT_SUBTYPES = gql`
  query {
    appointmentSubTypesConfig(eventType: "ABA") {
      ...ABAAppointmentSubTypeConfig
    }
  }
  ${appointmentTypesFragments.ABAAppointmentSubTypeConfig}
`;

export const GET_DT_APPOINTMENT_SUBTYPES = gql`
  query {
    appointmentSubTypesConfig(eventType: "DT") {
      ...DTAppointmentSubTypeConfig
    }
  }
  ${appointmentTypesFragments.DTAppointmentSubTypeConfig}
`;

export const GET_MED_APPOINTMENT_SUBTYPES = gql`
  query {
    appointmentSubTypesConfig(eventType: "MED") {
      ...MEDAppointmentSubTypeConfig
    }
  }
  ${appointmentTypesFragments.MEDAppointmentSubTypeConfig}
`;

export const GET_APPT_TYPES_WITH_SUB_TYPES = gql`
  query($includeAdminTypes: Boolean!, $sidebarState: Boolean) {
    allAppointmentTypes(
      includeAdminTypes: $includeAdminTypes
      sidebarState: $sidebarState
    ) {
      ...AppointmentTypesTableData
      appointmentSubTypes {
        id
        title
        DTSetting {
          id
          duration
        }
        MEDSetting {
          id
          duration
        }
      }
    }
  }
  ${appointmentTypesFragments.appointmentTypesTableData}
`;

export const GET_APPT_TYPES = gql`
  query {
    allAppointmentTypes(includeAdminTypes: false) {
      ...AppointmentTypeData
    }
  }
  ${appointmentTypesFragments.appointmentTypesData}
`;

export const GET_DELETE_WHEN_CANCEL_APPOINTMENTS_IDS = gql`
  query {
    deleteWhenCancelAppointmentsIds
  }
`;
