import React from 'react';
import { formatUsername, formatClinicName } from 'utils/format';
import { USER_TAB } from 'utils/constants/breadcrumbs';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ userType, user, clinic }) => (
  <div className="bread-crumbs">
    <div className="inner-wrap">
      <Link to={'/' + USER_TAB[userType].toLowerCase()}>
        {USER_TAB[userType]}
      </Link>
      <div className="angle">&gt;</div>
      {user && (
        <Link to="#">{formatUsername(user.firstName, user.lastName)}</Link>
      )}
      {clinic && <Link to="#">{formatClinicName(clinic)}</Link>}
    </div>
  </div>
);
export default Breadcrumb;
