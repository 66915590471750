import * as Yup from 'yup';

export const APPOINTMENT_BY_MINUTE_VALIDATOR = Yup.object().shape({
  startTimeOffset: Yup.number()
    .required()
    .max(Yup.ref('endTimeOffset')),
  endTimeOffset: Yup.number()
    .required()
    .min(Yup.ref('startTimeOffset')),
  id: Yup.string().required(),
  notes: Yup.string()
});
