import React from 'react';

interface Props {
  onClick:
    | ((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void)
    | undefined;
  className?: string;
}
const TrashIcon: React.FC<Props> = ({ onClick, className }: Props) => (
  <div className={className} onClick={onClick}>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2515 5.25L3.75146 5.25001"
        stroke="#6f4bf1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.75183 9.75V15.75"
        stroke="#6f4bf1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2518 9.75V15.75"
        stroke="#6f4bf1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.7515 5.25V19.5C18.7515 19.6989 18.6724 19.8897 18.5318 20.0303C18.3911 20.171 18.2004 20.25 18.0015 20.25H6.00146C5.80255 20.25 5.61179 20.171 5.47113 20.0303C5.33048 19.8897 5.25146 19.6989 5.25146 19.5V5.25"
        stroke="#6f4bf1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.7518 5.25V3.75C15.7518 3.35218 15.5938 2.97064 15.3125 2.68934C15.0312 2.40804 14.6497 2.25 14.2518 2.25H9.75183C9.35401 2.25 8.97248 2.40804 8.69117 2.68934C8.40987 2.97064 8.25183 3.35218 8.25183 3.75V5.25"
        stroke="#6f4bf1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);

export default React.memo(TrashIcon);
