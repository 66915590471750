import React from 'react';

import {
  Input as LibInput,
  AutoFormattedInput as LibAutoFormattedInput,
  TextArea as LibTextArea
} from 'lib/ui';
import { ErrorMessage } from 'formik';

const Input = props => {
  const { name, className } = props;
  return (
    <div className={className}>
      <LibInput {...props} />
      <ErrorMessage name={name}>
        {msg => <div className="error">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

const AutoFormattedInput = props => {
  const { name, className } = props;
  return (
    <div className={className}>
      <LibAutoFormattedInput {...props} />
      <ErrorMessage name={name}>
        {msg => <div className="error">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

const TextArea = props => {
  const { name, className } = props;
  return (
    <div className={className}>
      <LibTextArea {...props} />
      <ErrorMessage name={name}>
        {msg => <div className="error">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default Input;
export { Input, AutoFormattedInput, TextArea };
