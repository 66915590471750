import React, { useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import Header from 'views/containers/header';
import AvailabilityCalendar from './AvailabilityCalendar';
import { Formik, FieldArray, Field } from 'formik';
import { FormItem, Datepicker, Select } from 'views/containers/form';
import { Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Switch, Button } from 'lib/ui';
import { CLIENT_EDIT_AVAILABILITY_SCHEMA } from 'utils/validators/clients';
import { GET_CLIENT_AVAILABILITY } from 'api/graphql/v2/queries/Clients';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CalendarWrap,
  BlockedWrap,
  EditAvailabilityWrap
} from './EditAvailability.style';
import {
  generateEventsFromAvailability,
  generateEventsByDay,
  generateEventsFromBlocked,
  generateBlockedDates,
  mapStatusOptionsEnum
} from 'helpers/clientAvailabilityHelper';
import { toast } from 'react-toastify';
import Content from 'views/components/ui/content';
import { EDIT_AVAILABILITY } from 'api/graphql/v2/mutations/ClientAvailability';

const EditAvailability = () => {
  const history = useHistory();
  const { id } = useParams();

  const [availabilityType, setAvailabilityType] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [changes, setChanges] = useState(false);

  const { data, loading, error: errorClientAvailability } = useQuery(
    GET_CLIENT_AVAILABILITY,
    {
      variables: {
        startDate: '',
        endDate: '',
        clientIds: [parseInt(id)]
      },
      skip: !id
    }
  );

  const [editAvailability] = useMutation(EDIT_AVAILABILITY, {
    onCompleted: () => {
      setProcessing(false);
      toast.success('Availability saved!');
      history.goBack();
    },
    refetchQueries: [
      {
        query: GET_CLIENT_AVAILABILITY,
        variables: {
          startDate: '',
          endDate: '',
          clientIds: [parseInt(id)]
        }
      }
    ]
  });

  const submit = useCallback(
    (values, actions) => {
      setProcessing(true);
      const isActive = true;
      const editedAvailabilityObject = {
        availabilities: generateEventsByDay(values.events, id, isActive) || [],
        blockedDates:
          generateBlockedDates(values.blockedEvents, id, isActive) || [],
        id: parseInt(id),
        availabilityStatus: values.statusOption
      };
      editAvailability({
        variables: {
          availabilityInput: editedAvailabilityObject
        }
      });
      actions.setSubmitting(false);
    },
    [editAvailability, id]
  );

  const onChange = useCallback(checked => setAvailabilityType(checked), []);

  const deletingEvent = useCallback((arrayHelpers, index) => {
    arrayHelpers.remove(index);
    setChanges(true);
  }, []);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Content data={data} loading={loading} error={errorClientAvailability}>
      {() => (
        <div className="popup-wrap">
          <EditAvailabilityWrap>
            <Formik
              initialValues={{
                events: generateEventsFromAvailability(
                  data ? data.clientAvailability[0].availabilities : []
                ),
                blockedEvents: generateEventsFromBlocked(
                  data ? data.clientAvailability[0].blockedDates : []
                ),
                statusOption: data
                  ? data.clientAvailability[0].availabilityStatus
                  : ''
              }}
              validationSchema={CLIENT_EDIT_AVAILABILITY_SCHEMA}
              onSubmit={(values, actions) => submit(values, actions)}
              render={({
                values,
                handleSubmit,
                setFieldValue,
                dirty,
                isValid
              }) => (
                <>
                  <Header
                    title="Edit Availability"
                    source={undefined}
                    buttonText="Save Changes"
                    handleSubmit={handleSubmit}
                    onCancel={goBack}
                    loading={processing}
                    disabled={(!dirty && !changes) || !isValid}
                  />

                  <div className="inner-wrap">
                    <div className="calendar-actions">
                      <h2>RECURRING AVAILABILITY </h2>
                      <Row type="flex" align="middle">
                        <Col span={20}>
                          <div className="calendar-type">
                            <span>Click and drag to add</span>
                            <Switch onChange={onChange} />
                            <span className="calendar-type__name">
                              {availabilityType ? 'Off-Site' : 'In Clinic'}
                            </span>
                          </div>
                        </Col>
                        {
                          <Col span={4}>
                            <div className="availability-options">
                              <FormItem label="Status" xs={16} sm={24}>
                                <Field
                                  name="statusOption"
                                  value={values.statusOption}
                                  render={fieldProps => (
                                    <Select
                                      name="statusOption"
                                      options={mapStatusOptionsEnum(
                                        data
                                          ? data.clientAvailabilityStatusOptions
                                          : []
                                      )}
                                      value={values.statusOption}
                                      notFoundContent="Loading..."
                                      placeholder="Select"
                                      {...fieldProps}
                                    />
                                  )}
                                />
                              </FormItem>
                            </div>
                          </Col>
                        }
                      </Row>
                    </div>
                    <CalendarWrap availabilityType={availabilityType}>
                      <AvailabilityCalendar
                        events={values.events}
                        setFieldValue={setFieldValue}
                        availabilityType={availabilityType}
                        setChanges={setChanges}
                      />
                    </CalendarWrap>
                    <BlockedWrap>
                      <FieldArray
                        name="blockedEvents"
                        render={arrayHelpers => (
                          <>
                            <div className="calendar-addEvent">
                              <h2>BLOCKED DATES</h2>
                              <Button
                                className="large"
                                onClick={() => {
                                  arrayHelpers.push({
                                    startDate: '',
                                    endDate: ''
                                  });
                                  setChanges(true);
                                }}
                              >
                                + Add Blocked Dates
                              </Button>
                            </div>
                            {values.blockedEvents.map((item, index) => (
                              <div className="calendar-extraEvent" key={index}>
                                <CloseOutlined
                                  className="calendar-extraEvent__close"
                                  onClick={() =>
                                    deletingEvent(arrayHelpers, index)
                                  }
                                />
                                <FormItem
                                  label="Start Date"
                                  className="calendar-extraEvent__item"
                                >
                                  <Field
                                    name={`blockedEvents[${index}].startDate`}
                                    render={fieldProps => (
                                      <Datepicker
                                        placeholder="mm/dd/yyyy"
                                        format={'MM/DD/YYYY'}
                                        value={
                                          item.startDate === ''
                                            ? null
                                            : item.startDate
                                        }
                                        {...fieldProps}
                                      />
                                    )}
                                  />
                                </FormItem>
                                <span>to</span>
                                <FormItem
                                  label="End Date"
                                  className="calendar-extraEvent__item"
                                >
                                  <Field
                                    name={`blockedEvents[${index}].endDate`}
                                    render={fieldProps => (
                                      <Datepicker
                                        placeholder="mm/dd/yyyy"
                                        format={'MM/DD/YYYY'}
                                        value={
                                          item.endDate === ''
                                            ? null
                                            : item.endDate
                                        }
                                        {...fieldProps}
                                      />
                                    )}
                                  />
                                </FormItem>
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </BlockedWrap>
                  </div>
                </>
              )}
            />
          </EditAvailabilityWrap>
        </div>
      )}
    </Content>
  );
};

export default EditAvailability;
export { EditAvailability };
