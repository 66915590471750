import NotesIcon from 'assets/img/icon-notes-chat.svg';
import { Avatar, Badge, Space } from 'antd';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import {
  TRANSPORTATION_SELECT,
  PROVIDER_ROSTER_STATUS_SELECT
} from 'utils/constants/tables/staff';
import { MapProviderPetAllergies } from 'utils/common';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS, TARGET_BLANK } from '../routes';
import { providerStatusForSelect } from 'utils/common';

export const PROVIDER_COLUMNS = (
  providerDataLoading,
  PopOverComp,
  WaitListPopOver,
  setShowSidebar,
  setSidebarData,
  navigateToProviderProfile,
  handleStaffUpdate,
  providerAllergies
) => [
  {
    title: 'Provider Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      let { isLoaderView } = record;

      const providerProfileUrl = `${ROUTE_PATHS.PROVIDER}${record.providerId}`;

      const textData = (
        <Link
          className="provider_link"
          to={providerProfileUrl}
          target={TARGET_BLANK}
        >
          {text}
        </Link>
      );

      return !isLoaderView ? (
        <WaitListPopOver>
          <PopOverComp
            displayContent={textData}
            clientInfo={record}
            providerInfo={record}
            popOverType={'ProviderInfoPopOver'}
            placement="rightBottom"
          ></PopOverComp>
        </WaitListPopOver>
      ) : (
        <div>{text}</div>
      );
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (text, record) => {
      return (
        <div className={`Status_${text}`}>
          <span className={`Status_Inner_Cont`}>
            {providerStatusForSelect(
              record.status,
              PROVIDER_ROSTER_STATUS_SELECT
            ) ? (
              <Select
                value={record.status}
                // open={true}
                onChange={e => {
                  handleStaffUpdate(e, record.providerData, `status`);
                }}
                getPopupContainer={trigger =>
                  trigger.closest('.ant-table-container')
                }
                options={PROVIDER_ROSTER_STATUS_SELECT}
              ></Select>
            ) : (
              text
            )}
          </span>
        </div>
      );
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (text, record) => {
      return (
        <div className={`Status_${text}`}>
          <span className={`Status_Inner_Cont`}>{text}</span>
        </div>
      );
    }
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber'
  },
  {
    title: 'Zip Code',
    dataIndex: 'zipCode'
  },
  {
    title: 'Gender',
    dataIndex: 'gender'
  },
  {
    title: 'Transportation',
    dataIndex: 'transportation',
    render: (text, record) => {
      let { isLoaderView } = record;
      return !isLoaderView ? (
        <Select
          value={record.transportation}
          className={'provider-select provider-transportation'}
          onChange={e => {
            handleStaffUpdate(e, record.providerData, `transportation`);
          }}
          getPopupContainer={trigger => trigger.closest('.ant-table-container')}
          options={TRANSPORTATION_SELECT}
        ></Select>
      ) : (
        <div>{text}</div>
      );
    }
  },
  {
    title: 'Allergies',
    dataIndex: 'petAllergies',
    render: (text, record) => {
      let { isLoaderView } = record;
      return !isLoaderView ? (
        <Select
          placeholder="N/A"
          mode="multiple"
          onChange={e => {
            let allergyTotalIndex = e?.reduce((acc, val) => acc + val, 0);
            handleStaffUpdate(
              allergyTotalIndex,
              record.providerData,
              `petAllergies`
            );
          }}
          className={'provider-select'}
          value={MapProviderPetAllergies(text)}
          getPopupContainer={trigger => trigger.closest('.ant-table-container')}
          options={providerAllergies}
        ></Select>
      ) : (
        <div>{text}</div>
      );
    }
  },
  {
    title: 'Availability',
    dataIndex: 'availUpdated',
    render: (text, record) => {
      let { isLoaderView } = record;
      return !isLoaderView ? (
        <WaitListPopOver>
          <PopOverComp
            displayContent={text}
            clientInfo={record}
            popOverType={'clientDayAvailabilityContent'}
            placement="rightBottom"
          ></PopOverComp>
        </WaitListPopOver>
      ) : (
        <div>{text}</div>
      );
    }
  },
  {
    title: 'Program Type',
    dataIndex: 'programType'
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    render: (text, record) => {
      const threshold = 99;
      const displayCount =
        record.notesCount === 0
          ? '+'
          : record.notesCount > threshold
          ? `${threshold}+`
          : record.notesCount;
      let { isLoaderView } = record;

      return !isLoaderView ? (
        <Badge
          offset={[-1, 0]}
          style={{ background: '#6F42F5' }}
          size="small"
          count={displayCount}
        >
          <img
            alt="notesicon"
            src={NotesIcon}
            onClick={() => {
              setShowSidebar(true);
              setSidebarData(record);
            }}
            style={{
              paddingLeft: 3,
              paddingRight: 3,
              width: '34px',
              height: '34px',
              filter: 'brightness(110%)'
            }}
          />
        </Badge>
      ) : (
        <div>{text}</div>
      );
    }
  },
  {
    title: 'Clients',
    width: 50,
    dataIndex: 'clients',
    render: (text, record) => {
      let { isLoaderView } = record;
      return !isLoaderView ? (
        <WaitListPopOver>
          <PopOverComp
            displayContent={text}
            clientInfo={record}
            providerInfo={record}
            popOverType={'ProviderClientsInfo'}
            placement="rightBottom"
            style={{ marginLeft: '10px' }}
          ></PopOverComp>
        </WaitListPopOver>
      ) : (
        <div>{text}</div>
      );
    }
  },
  {
    title: 'Actions',
    width: 50,
    dataIndex: 'actions',
    render: (text, record) => {
      let { isLoaderView } = record;
      return !isLoaderView ? (
        <WaitListPopOver>
          <PopOverComp
            displayContent={text}
            clientInfo={record}
            providerInfo={record}
            popOverType={'ProviderActions'}
            placement="rightBottom"
            trigger="click"
          ></PopOverComp>
        </WaitListPopOver>
      ) : (
        <div>{text}</div>
      );
    }
  }
];
