import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import { Checkbox } from 'semantic-ui-react';
import { Button } from 'lib/ui';
import { FooterFormWrapper, ImpactedObserversWrapper } from '../style';
import { SIDEBAR_ACTIONS } from '../../AppointmentSidebar';
import { IOption } from 'model';
import {
  ADMIN_DEFAULT_CANCEL_REASON,
  APPOINTMENT_CATEGORY
} from 'utils/constants/lists';
import { clean } from 'utils/common';
import {
  getDateFromFormDate,
  prepareAdminForSubmissionV2,
  prepareEventForSubmissionV2
} from 'utils/mappers/request/events';
import { IEvent, IProvider } from 'model/v2';
import { getConflictsVariables, STEPS } from './utils';
import { DRIVE_TIME_APPT_IDS } from 'utils/constants/appointmentsTypes';
import { useLazyQuery } from 'react-apollo';
import { VALIDATE_LOCATIONS } from 'api/graphql/v2/queries/Location';
import LocationValidatorAlert from 'pages/MainCalendarPage/AppointmentView/AttendeesView/LocationValidatorAlert';
interface Props {
  providersMap: Map<any, IProvider>;
  setMappedEvent: (val: any) => void;
  setFormData: (val: any) => void;
  doConflictsRequest: (val: any) => void;
  setStep: (val: number) => void;
  recurringOptions: IOption[];
  saving: boolean;
  action: string;
  methods: UseFormMethods;
  setSaving: (val: boolean) => void;
  isValidated?: boolean;
  isValidatedEvent?: boolean;
  abaObservers?: IEvent[];
  isSeriesAbaEvent?: boolean;
  seriesEventObservations?: IEvent[];
  timeValidate?: boolean;
  additionalProvidersList?: IProvider[];
  adminAddressisFetch?: boolean;
}

const ValidationFooter: React.FC<Props> = ({
  providersMap,
  setMappedEvent,
  setFormData,
  doConflictsRequest,
  setStep,
  recurringOptions,
  saving,
  action,
  methods,
  setSaving,
  isValidated,
  isValidatedEvent,
  abaObservers,
  isSeriesAbaEvent,
  seriesEventObservations,
  additionalProvidersList,
  timeValidate,
  adminAddressisFetch
}: Props) => {
  // const [providerId, setProviderId] = useState(0);
  //Sushma removed as getProviderAvailabilitywithEvents is not required August 22nd
  // const [
  //   getProviderAvailabilitywithEvents,
  //   { data: providerWithAvailabilityData }
  // ] = useLazyQuery(GET_PROVIDER_WITH_AVAILABILITY, {
  //   fetchPolicy: 'cache-and-network',
  //   onCompleted: () => {
  //     console.log('Inside the provider availability query');
  //     console.log(
  //       providerWithAvailabilityData,
  //       'Availability data from footer'
  //     );
  //   }
  // });

  const [alertContent, setAlertContent] = React.useState<string | null>(null);

  const providerId = methods.watch('provider.id');
  const selectedClinicName = methods.watch('client.clinic.name');
  const selectedClinicId = methods.watch('clinic.id');
  const { startDate, endDate, locationType, selectedDay } = methods.watch([
    'startDate',
    'endDate',
    'locationType',
    'selectedDay'
  ]);

  // getLocations is called when these five of the watched fields are filled out by user
  const [
    validateAppointment,
    { loading: locationValidationInProgress }
  ] = useLazyQuery(VALIDATE_LOCATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      // If true, pop up alert to the scheduler. If false, do nothing.
      const results = data.checkLocationAvailability;
      setAlertContent(results.alertMessage);
    },
    onError: error => {
      console.error('Query Error:', error.message);
    }
  });

  useEffect(() => {
    // Clears alert when any of the watched fields are changed by user
    setAlertContent(null);
    if (
      providerId !== undefined &&
      startDate !== undefined &&
      endDate !== undefined &&
      locationType !== undefined &&
      selectedClinicName !== undefined
    ) {
      validateAppointment({
        variables: {
          providerId: providerId,
          locationType: locationType,
          startTime: getDateFromFormDate(selectedDay, startDate),
          endTime: getDateFromFormDate(selectedDay, endDate),
          selectedClinicId: selectedClinicId
        }
      });
    }
  }, [
    providerId,
    startDate,
    endDate,
    locationType,
    selectedClinicName,
    selectedDay,
    selectedClinicId,
    validateAppointment
  ]);

  const submitForm = React.useCallback(
    data => {
      if (!data.cancelReason) {
        data.cancelReason = ADMIN_DEFAULT_CANCEL_REASON;
      }
      setSaving(true);
      const isAdmin =
        data.apptCategory.split(': ')[1] === APPOINTMENT_CATEGORY[3].value;
      const isABA =
        data.apptCategory.split(': ')[1] === APPOINTMENT_CATEGORY[0].value;
      const isDriveTime = DRIVE_TIME_APPT_IDS[
        process.env.REACT_APP_STAGE!
      ].includes(data.appType);

      const mapped = clean(
        isAdmin
          ? prepareAdminForSubmissionV2(
              data,
              providersMap,
              additionalProvidersList,
              isDriveTime
            )
          : prepareEventForSubmissionV2(data)
      );
      setMappedEvent(mapped);

      setFormData(data);
      const isOverDriveTime = data?.driveTimeMileage > 50;
      //Sushma removed as getProviderAvailabilitywithEvents is not required August 22nd
      // getProviderAvailabilitywithEvents({
      //   variables: { id: Number(providerId) }
      // });
      const variables = isAdmin
        ? {}
        : getConflictsVariables(data, [mapped], recurringOptions)[0];
      if (isDriveTime && isOverDriveTime) {
        setStep(STEPS.MILEAGE_WARNING);
      } else if (
        isAdmin ||
        (action === SIDEBAR_ACTIONS.EDIT && !data?.dirtyEdit)
      ) {
        setStep(STEPS.SUBMIT);
      } else if (
        isABA &&
        action === SIDEBAR_ACTIONS.EDIT &&
        data?.dirtyEdit &&
        ((!isSeriesAbaEvent && abaObservers && abaObservers.length > 0) ||
          (isSeriesAbaEvent &&
            seriesEventObservations &&
            seriesEventObservations.length > 0))
      ) {
        setStep(STEPS.OBSERVER_WARNING);
      } else {
        doConflictsRequest({ variables });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setSaving,
      action,
      providersMap,
      recurringOptions,
      doConflictsRequest,
      setStep,
      setMappedEvent,
      setFormData
    ]
  );

  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);

  const {
    apptCategory,
    'drivetime-source-address-search-in-progress': awaitingRHFSetSourceAddress,
    'drivetime-destination-address-search-in-progress': awaitingRHFSetDestinationAddress,
    'mileage-request-in-progress': awaitingRHFMileage
  } = methods.watch([
    'apptCategory',
    'drivetime-source-address-search-in-progress',
    'drivetime-destination-address-search-in-progress',
    'mileage-request-in-progress'
  ]);

  return (
    <FooterFormWrapper>
      {action !== SIDEBAR_ACTIONS.EDIT &&
      apptCategory?.split(': ')[1] !== APPOINTMENT_CATEGORY[3].value ? (
        <FormItem
          optional={false}
          label="Save as Invisible"
          className="save-as-invisible-form-item"
        >
          <Controller
            name="isPendingConfirmation"
            control={methods.control}
            render={() => (
              <Checkbox
                style={{ marginLeft: 10 }}
                toggle
                onChange={(_, { checked }) => {
                  methods.setValue('isPendingConfirmation', checked);
                }}
              />
            )}
          />
        </FormItem>
      ) : (
        <FormItem
          optional={false}
          label=""
          className="save-as-invisible-form-item"
        >
          <></>
        </FormItem>
      )}
      <FormItem optional={false} label="">
        <Button
          className="large"
          onClick={methods.handleSubmit(submitForm, submitError)}
          type="submit"
          loading={saving}
          disabled={
            saving ||
            isValidated ||
            isValidatedEvent ||
            timeValidate ||
            locationValidationInProgress ||
            adminAddressisFetch ||
            providersMap.size === 0 ||
            awaitingRHFSetSourceAddress ||
            awaitingRHFSetDestinationAddress ||
            awaitingRHFMileage
          }
        >
          Save
        </Button>
      </FormItem>
      {alertContent && (
        <ImpactedObserversWrapper>
          <LocationValidatorAlert
            alertContent={alertContent}
            setAlertContent={setAlertContent}
          />
        </ImpactedObserversWrapper>
      )}
    </FooterFormWrapper>
  );
};
export default React.memo(ValidationFooter);
