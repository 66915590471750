import gql from 'graphql-tag';
import { clinicFragments } from '../fragments/Clinics';

export const ADD_NEW_CLINIC = gql`
  mutation($clinic: ClinicInput!) {
    addClinic(clinic: $clinic) {
      ...ClinicData
      rooms {
        ...RoomsData
      }
    }
  }
  ${clinicFragments.clinicData}
  ${clinicFragments.roomsData}
`;

export const ADD_NEW_ROOM = gql`
  mutation($room: RoomInput!) {
    addRoom(room: $room) {
      ...RoomsData
    }
  }
  ${clinicFragments.roomsData}
`;
