import gql from 'graphql-tag';

export const UNPAUSE_DB = gql`
  query {
    unpauseDB
  }
`;
export const CHECK_DB_STATUS = gql`
  query {
    checkDownDBStatus
  }
`;
export const CHECK_DOWN_STREAMS_STATUS = gql`
  query {
    checkDownStreamsApiStatus {
      athena
      cr
    }
  }
`;
