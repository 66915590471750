import React from 'react';
import TimeView from './TimeView';
import AttendeesView from './AttendeesView';
import { AppointmentViewWrapper } from './style';
import StatusView from './StatusView';
import { IModalDownStreamsConfigurations } from 'utils/downstreams/DownStreamModalConfigurations';
import AuditView from './AuditView';
import { IEvent } from 'model/v2';
import DriveTimeMileageView from './DriveTimeMileageView';
import { DRIVE_TIME_APPT_IDS } from 'utils/constants/appointmentsTypes';

interface Props {
  event: IEvent;
  reloadEvent: () => void;
  configurations: IModalDownStreamsConfigurations;
}
const AdminView: React.FC<Props> = ({
  event,
  reloadEvent,
  configurations
}: Props) => {
  return (
    <AppointmentViewWrapper>
      <div className="scrollable">
        <StatusView
          event={event}
          reloadEvent={reloadEvent}
          configurations={configurations}
        />
        <TimeView event={event} />
        {DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!].includes(
          event?.appointmentType?.id!
        ) && <DriveTimeMileageView event={event} />}
        <AttendeesView event={event} />
        <h2 className="notes-title">Notes</h2>
        <p className="notes-content">{event?.note?.note}</p>

        <AuditView event={event} />
      </div>
    </AppointmentViewWrapper>
  );
};
export default React.memo(AdminView);
