import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';

export const EDIT_AVAILABILITY = gql`
  mutation editAvailability($availabilityInput: ClientEditAvailabilityInput!) {
    updateClientAvailability(availabilityInput: $availabilityInput) {
      availabilityLastModificationDate
    }
  }
`;

export const SAVE_CLIENT_AVAILABILITY = gql`
  mutation saveAvailability($clientAvailability: ClientDayAvailabilityInput!) {
    saveClientDayAvailabilities(data: $clientAvailability, timezone: "${TIMEZONE}") {
      id
    }
  }
`;

export const GET_AVAILABILITY_AUDIT = gql`
  query GetAvailabilityAudit($clientId: Int!) {
    getAvailabilityAudit(clientId: $clientId) {
      actionBy
      actionAt
    }
  }
`;
