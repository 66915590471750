import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import {
  IEvent,
  IFormEvent,
  IFullAppointmentType,
  IProvider,
  iSmartSingleResultForm
} from 'model/v2';
import {
  CardWrapperDT,
  DescriptionWrapperDT,
  LocationListWrapper,
  LocationWrapper,
  SmartCardFooterWrapper
} from './Style';
import { Card, Grid, Radio } from 'semantic-ui-react';
import { Button } from 'lib/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import DTResultTime from './DTResultTime';
import SubstitueResultTimes from './SubstitueResultTimes';
import SubstitueAppointmentResults from './SubstitueAppointmentResults';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import SelectList from 'api/sharedComponents/reactHookFormComponents/semantic/SelectList';
import SingleResultHeader from './SingleResultHeader';
import moment from 'moment';
import TelehealthInput from '../Client/TelehealthInput';
import { getDefaultSingleResultList } from 'utils/mappers/smartSchedule';
import { yupResolver } from '@hookform/resolvers/yup';
import { SMART_SCHEDULE_EVAL_SELECT_RESULT } from 'utils/validators/smartSchedule';
interface IProps {
  event: IFormEvent;
  appTypesMap: Map<number, IFullAppointmentType>;
  apptId: number;
  result: any;
  index: number;
  setSubstituteProviderId: Dispatch<SetStateAction<number | undefined>>;
  setSubstituteProvider: Dispatch<SetStateAction<IProvider | undefined>>;
  setAdjacentEvent: Dispatch<SetStateAction<IEvent | undefined>>;
  setIsPendingConfirmation: Dispatch<SetStateAction<boolean>>;
  setConfirmModel: Dispatch<SetStateAction<boolean>>;
  confirmModel: boolean;
  setSelectedIndex: Dispatch<SetStateAction<number | undefined>>;
  selectedIndex: number;
  setSubstituteType: Dispatch<SetStateAction<string | undefined>>;
  substituteType: string;
  setCount?: Dispatch<SetStateAction<number>>;
  count?: number;
  setProviderTelehealthLink: Dispatch<SetStateAction<string | undefined>>;
}
const SubstitueResultsCard: React.FC<IProps> = ({
  event,
  appTypesMap,
  apptId,
  result,
  index,
  setSubstituteProviderId,
  setSubstituteProvider,
  setAdjacentEvent,
  setIsPendingConfirmation,
  setConfirmModel,
  confirmModel,
  setSelectedIndex,
  selectedIndex,
  setSubstituteType,
  substituteType,
  setCount,
  count,
  setProviderTelehealthLink
}) => {
  const defaultEvalResults: iSmartSingleResultForm = useMemo(() => {
    return getDefaultSingleResultList(result, result.client?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.client?.id, result]);
  const methods = useForm<iSmartSingleResultForm>({
    defaultValues: defaultEvalResults,
    resolver: yupResolver(SMART_SCHEDULE_EVAL_SELECT_RESULT()),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  const { watch, setValue } = methods;
  const resultValue = watch('result');
  useEffect(() => {
    if (selectedIndex === index) {
      setProviderTelehealthLink(resultValue.telehealthLink!);
    }
  }, [resultValue.telehealthLink, selectedIndex]);

  const address = `${
    event?.address?.addressLine1 ? event?.address?.addressLine1 + ', ' : ''
  } ${
    event?.address?.addressLine2 ? event?.address?.addressLine2 + ', ' : ''
  } ${event?.address?.city ? event?.address?.city + ', ' : ''} ${
    event?.address?.state ? event?.address?.state + ', ' : ''
  } ${event?.address?.zipCode || ''}`;
  let newLocationType = event?.locationType;
  let newClinic = event?.clinic;
  let newTeleHealthLink = event?.telehealthLink;
  let newlocationCategory = event?.locationCategory;
  let newAddress = address;
  const adjacentEvent = result.adjacentEvent;
  if (adjacentEvent) {
    newLocationType = adjacentEvent?.locationType;
    newClinic = adjacentEvent?.clinic;
    newTeleHealthLink = adjacentEvent?.telehealthLink;
    newlocationCategory = adjacentEvent?.locationCategory;
    newAddress = `${
      adjacentEvent?.address?.addressLine1
        ? adjacentEvent?.address?.addressLine1 + ', '
        : ''
    } ${
      adjacentEvent?.address?.addressLine2
        ? adjacentEvent?.address?.addressLine2 + ', '
        : ''
    } ${
      adjacentEvent?.address?.city ? adjacentEvent?.address?.city + ', ' : ''
    } ${
      adjacentEvent?.address?.state ? adjacentEvent?.address?.state + ', ' : ''
    } ${adjacentEvent?.address?.zipCode || ''}`;
  }
  const LocationList = [
    {
      key: 0,
      value: newLocationType,
      text: newLocationType
    }
  ];
  const ClinicList = [
    {
      key: 0,
      value: newClinic?.abbreviation,
      text: newClinic?.abbreviation
    }
  ];
  const categoriesOptions = [
    {
      key: 0,
      value: newlocationCategory,
      text: newlocationCategory
    }
  ];
  const clientAddressesOptions = [
    {
      key: 0,
      value: newAddress,
      text: newAddress
    }
  ];
  const locationInfo =
    newLocationType === 'In Clinic'
      ? `${event.clinic?.name}, In Clinic`
      : newLocationType === 'Telehealth'
      ? `${newTeleHealthLink ? newTeleHealthLink : `N/A`}, Telehealth`
      : `${newAddress ? newAddress : `N/A`}, ${
          newlocationCategory ? newlocationCategory : `N/A`
        }, Off-Site`;
  const selectResultCard = useCallback((selectedEvent, index) => {
    setSelectedIndex(index);
    setSubstituteProviderId(selectedEvent.provider.id);
    setSubstituteProvider(selectedEvent.provider);
    setAdjacentEvent(selectedEvent.adjacentEvent!);
    let isInVisible = false;
    if (selectedEvent.isInVisible) isInVisible = selectedEvent.isInVisible;
    setIsPendingConfirmation(isInVisible);
    setSubstituteType(selectedEvent.substituteType);
  }, []);
  const selectProvider = React.useCallback(() => {
    setConfirmModel(!confirmModel);
  }, [confirmModel, setConfirmModel]);
  let startDate = event?.startDate;
  let endDate = event?.endDate;
  if (adjacentEvent) {
    if (startDate === adjacentEvent.endDate)
      startDate = adjacentEvent.startDate;
    if (endDate === adjacentEvent.startDate) endDate = adjacentEvent.endDate;
  }
  const providerTime = {
    startTime: moment(startDate).format('hh:mm A'),
    endTime: moment(endDate).format('hh:mm A')
  };
  return (
    <>
      <CardWrapperDT onClick={() => selectResultCard(result, index)}>
        <FormProvider {...methods}>
          <div>
            <Card
              fluid
              style={{
                border:
                  selectedIndex !== index ||
                  substituteType !== result.substituteType
                    ? 'none'
                    : '1px solid #6f42f5'
              }}
            >
              <Card.Content
                header={
                  <SingleResultHeader
                    result={result}
                    setCount={setCount}
                    count={count}
                    providerTime={providerTime}
                    selectedIndex={selectedIndex!}
                    index={index}
                    isMakeupSession={false}
                    locationInfo={locationInfo}
                  />
                }
              />
              <Card.Content
                description={
                  <DescriptionWrapperDT>
                    <Grid>
                      <Grid.Row className="outer-shell">
                        <Grid.Column className="w-50-inline pad-8-px">
                          <div className="flx-1-1-grow">
                            <div className="flx-fixed-w flx-v-c">
                              <FontAwesomeIcon
                                className="icon-small"
                                icon={faCalendar}
                              />
                            </div>
                            <div className="flx-grow-1 flx-v-c color-custom f-w-500">
                              <DTResultTime
                                startTime={result.times[0].startTime}
                                endTime={result.times[0].endTime}
                                format={true}
                              />
                            </div>
                          </div>
                          <div className="flx-1-1-grow">
                            <div className="flx-fixed-w flx-v-c">
                              <FontAwesomeIcon
                                className="icon-small"
                                icon={faClock}
                              />
                            </div>
                            <div className="flx-grow-1 flx-v-c color-custom f-w-500">
                              <SubstitueResultTimes
                                event={event!}
                                adjacentEvent={result.adjacentEvent!}
                              />
                            </div>
                          </div>
                          <div
                            className="flx-1-1-grow"
                            style={{ paddingTop: '5px' }}
                          >
                            <div className="flx-grow-1 flx-v-c color-custom f-size-12 f-w-500">
                              <SubstitueAppointmentResults
                                event={event!}
                                appTypesMap={appTypesMap}
                                adjacentEvent={result.adjacentEvent!}
                                apptId={apptId!}
                              />
                            </div>
                          </div>
                        </Grid.Column>
                        <Grid.Column className="w-50-inline">
                          <LocationWrapper>
                            <Grid>
                              <Grid.Row>
                                <Grid.Column>
                                  <Grid.Row className="row-style">
                                    <Controller
                                      name={`location-dropdown`}
                                      //control={control}
                                      render={props => (
                                        <SelectList
                                          field={props}
                                          defaultValue={newLocationType}
                                          //errors={errors}
                                          options={LocationList}
                                          disabled={
                                            selectedIndex !== index ||
                                            substituteType !==
                                              result.substituteType
                                          }
                                          onChange={(_view: any) => {}}
                                        />
                                      )}
                                    />
                                  </Grid.Row>
                                  {newLocationType === 'In Clinic' && (
                                    <>
                                      <Grid.Row className="row-style">
                                        <Controller
                                          name={`clinic-dropdown`}
                                          //control={control}
                                          render={props => (
                                            <SelectList
                                              field={props}
                                              //errors={errors}
                                              defaultValue={
                                                newClinic?.abbreviation
                                              }
                                              options={ClinicList}
                                              placeholder="Select Clinic"
                                              disabled={
                                                selectedIndex !== index ||
                                                substituteType !==
                                                  result.substituteType
                                              }
                                            />
                                          )}
                                        />
                                      </Grid.Row>
                                    </>
                                  )}
                                  {newLocationType === 'Off-Site' && (
                                    <>
                                      <Grid.Row className="row-style">
                                        <Controller
                                          name={`location-category`}
                                          //control={control}
                                          render={props => (
                                            <SelectList
                                              field={props}
                                              defaultValue={newlocationCategory}
                                              //errors={errors}
                                              options={categoriesOptions}
                                              placeholder="Select Category"
                                              disabled={
                                                selectedIndex !== index ||
                                                substituteType !==
                                                  result.substituteType
                                              }
                                            />
                                          )}
                                        />
                                      </Grid.Row>
                                    </>
                                  )}
                                  <LocationListWrapper>
                                    {newLocationType === 'Off-Site' && (
                                      <Controller
                                        name={'address'}
                                        //control={control}
                                        render={props => (
                                          <SelectList
                                            field={props}
                                            defaultValue={newAddress}
                                            options={clientAddressesOptions}
                                            placeholder="Select Address"
                                            disabled={
                                              selectedIndex !== index ||
                                              substituteType !==
                                                result.substituteType
                                            }
                                          />
                                        )}
                                      />
                                    )}
                                    {newLocationType === 'Telehealth' ? (
                                      <TelehealthInput
                                        type="smart"
                                        index={'result.telehealthLink'}
                                        telehealthLinks={
                                          result.provider.telehealthLinks
                                        }
                                      />
                                    ) : null}
                                  </LocationListWrapper>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </LocationWrapper>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns="2">
                        <Grid.Column>
                          <div className="visibleContainer">
                            <label className="visibleText">
                              Save as Invisible
                            </label>
                            <Controller
                              name={'result.isInVisible'}
                              //control={control}
                              render={props => (
                                <Radio
                                  toggle
                                  onChange={(_, { checked }) => {
                                    setValue(`result.isInVisible`, checked);
                                    result.isInVisible = checked;
                                    setIsPendingConfirmation(checked!);
                                  }}
                                  checked={props.value}
                                  disabled={
                                    selectedIndex !== index ||
                                    substituteType !== result.substituteType
                                  }
                                />
                              )}
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </DescriptionWrapperDT>
                }
              />
              <SmartCardFooterWrapper>
                <Button
                  className="large"
                  //onClick={submitForm}
                  onClick={selectProvider}
                  type="submit"
                  //loading={saving}
                  //disabled={saving}
                  style={{ height: '32px' }}
                  disabled={
                    selectedIndex !== index ||
                    substituteType !== result.substituteType
                  }
                >
                  Select Provider
                </Button>
                {/* <Content loading={saving} data={true} /> */}
              </SmartCardFooterWrapper>
            </Card>
          </div>
        </FormProvider>
      </CardWrapperDT>
    </>
  );
};
export default React.memo(SubstitueResultsCard);
