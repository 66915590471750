import styled from 'styled-components';
import reactCSS from 'reactcss';

export const LineWrapper = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid #c9cfd3;
  position: relative;
  .ui.segment:last-child {
    padding: 0px;
  }
`;
export const BreadcrumbWrapper = styled.div`
  font-weight: bold;
  letter-spacing: 0.3px;
  padding-left: 8px;
  .ui.breadcrumb a {
    color: #000000;
    &:hover {
      color: #1e70bf;
    }
  }
`;
export const SearchWrapper = styled.div`
  .actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 18px;
    }
  }
`;
export const styles = reactCSS({
  default: {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }
});
