import React from 'react';
import { IEvent, IFormEvent } from 'model/v2';
import moment from 'moment';
interface IProps {
  event: IFormEvent;
  adjacentEvent?: IEvent;
}
const SubstitueResultTimes: React.FC<IProps> = ({ event, adjacentEvent }) => {
  let startDate = event?.startDate;
  let endDate = event?.endDate;
  if (adjacentEvent) {
    if (startDate === adjacentEvent.endDate)
      startDate = adjacentEvent.startDate;
    if (endDate === adjacentEvent.startDate) endDate = adjacentEvent.endDate;
  }
  return (
    <>
      <label
        style={{
          paddingLeft: '2px',
          fontSize: '11px',
          fontWeight: 'bold'
        }}
      >
        {moment(startDate).format('hh:mm a')} -{' '}
        {moment(endDate).format('hh:mm a')}
      </label>
    </>
  );
};
export default React.memo(SubstitueResultTimes);
