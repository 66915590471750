import { ApolloError } from 'apollo-client';
import React from 'react';
interface Props {
  error: Error;
}

const ContentError: React.FC<Props> = ({ error }: Props): JSX.Element => {
  console.error(error);

  let text = `Error! ${error.message}`;
  if (error instanceof ApolloError && error.networkError) {
    text = 'Please check your network connection';
  }

  return <p>{text}</p>;
};

export default ContentError;
