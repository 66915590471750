import styled from 'styled-components';
import { Table } from 'antd';
import { PURPLE } from 'utils/constants/theme';

export const LibTable = styled(Table)`
  max-width: 1280px;
  margin: 0 auto;
  table {
    border-spacing: 0 10px;
  }
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: ${PURPLE};
    color: ${PURPLE};
  }
  .ant-pagination-item-active {
    border-color: ${PURPLE};
    a {
      color: ${PURPLE};
    }
  }
  .ant-pagination-item {
    &:hover {
      border-color: ${PURPLE};
      a {
        color: ${PURPLE};
      }
    }
  }
  &.clear-head {
    .ant-table-content {
      border: 1px solid #c9cfd3;
      &:before,
      &:after {
        display: none;
      }
    }
  }
  .ant-table-content {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      background-color: #ffffff;
      height: 55px;
      width: 100vw;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 1px;
      background-color: #ffffff;
      height: 55px;
      width: 100vw;
    }
  }
  .ant-table-body {
    position: relative;
    z-index: 2;
  }
  .ant-table-thead {
    position: relative;
    overflow: hidden;
    background-color: #ffffff;
    font-size: 15px;
    span {
      font-family: Lato;
      font-size: 15px;
      color: #919699;
    }
    th {
      padding: 8px 27px 13px;
      border-bottom: none;
    }
    .ant-table-column-has-sorters {
      i.on {
        color: ${PURPLE} !important;
      }
      &:hover {
        span {
          color: ${PURPLE};
        }
      }
    }
    .ant-table-column-has-filters {
      .anticon-filter {
        &:hover {
          color: ${PURPLE} !important;
        }
      }
      .anticon-filter:active {
        color: ${PURPLE}!important;
      }
    }
    .ant-table-cell::before {
      display: none;
    }
  }
  .ant-table-thead > tr > th {
    background-color: #ffffff;
    .ant-table-header-column {
      vertical-align: middle;
    }
    .ant-table-column-sorter {
      display: none;
    }
  }
  .ant-table-tbody > tr > td {
    height: 72px;
    padding: 20px 27px;
    opacity: 0.8;
    font-family: Lato;
    font-size: 15px;
    color: #21303a;
    border-bottom: none;
  }
  .ant-table-row {
    min-height: 72px;
    background-color: #ffffff;
    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    td {
      border-top-style: solid;
      border-bottom-style: solid !important;
      border-width: 1px !important;
      border-color: #c9cfd3 !important;
    }

    td:first-child {
      border-left-style: solid;
    }

    td:last-child {
      border-right-style: solid;
    }
  }
  .table-dot {
    position: relative;
    padding-left: 20px;
    i {
      position: absolute;
      top: 7px;
      left: 0;
      width: 8px;
      height: 8px;
      background-color: #59c2ff;
      border-radius: 50%;
      display: inline-block;
    }
  }
`;
