import moment from 'moment';
import { getApptType } from 'utils/common';
import {
  EVENT_OCCURENCE,
  LOCATION_TYPE_LIST,
  SCHEDULE_TYPE_LIST
} from 'utils/constants/lists';
import { getView } from 'helpers/calendarHelper';
import { customizeRange } from 'utils/builders/calendar';
import { CLINIC_PROVIDERS } from 'utils/constants/lists';
import { isString } from 'lodash';

export function prepareEventForSubmission(
  values,
  client,
  apptTypes,
  clinic,
  eventTypes
) {
  const locationDetails = values.locationDetails
    ? prepareLocationDetails(values.locationDetails)
    : undefined;

  const apptType = getApptType({ title: values.appType }, apptTypes);
  const apptSubType = getApptType(
    { title: values.appSubType },
    apptType.subTypes
  );

  const provider = values.selectedSlot.provider;

  return {
    id: undefined,
    masterID: undefined,
    subject: `${apptType.title} - ${client.name} & ${provider.name}`,
    startTime: values.selectedSlot.start,
    endTime: moment(values.selectedSlot.start)
      .add(values.duration, 'minutes')
      .toDate()
      .toISOString(),
    therapist: provider && {
      id: provider.id,
      name: provider.name,
      email: provider.email
    },
    client: { name: client.name, email: client.email, id: client.id },
    room:
      values.locationType === LOCATION_TYPE_LIST[0].id &&
      values.selectedRoom?.id
        ? {
            id: values.selectedRoom.id,
            email: values.selectedRoom.email,
            roomName: values.selectedRoom.roomName
          }
        : undefined,
    clinic: {
      id: clinic.id,
      timeZone: clinic?.timezone
    },
    appointmentType: {
      id: apptType.id,
      isClinical: apptType.isClinical,
      subType: apptSubType?.id
    },
    locationType: values.locationType,
    locationCategory: values.locationCategory,
    recurringEvery: values.repeat || 0,
    duration: values.duration,
    notes: values.notes,
    telehealthLink:
      values.locationType === LOCATION_TYPE_LIST[2].id
        ? values.telehealthLink
        : undefined,
    recurringUntil: values.recurringUntil
      ? moment(values.recurringUntil)
          .endOf('day')
          .toISOString()
      : undefined,
    eventType: eventTypes[0],
    clinicPreference: values.clinicPreference,
    paymentMethod: values.isClinical ? null : values.paymentMethod,
    locationDetails:
      values.locationType === LOCATION_TYPE_LIST[1].id
        ? locationDetails
        : undefined
  };
}

export function prepareAdminEventForSubmission(
  values,
  provider,
  timeZone,
  eventTypes,
  adminAppointmentRepeats,
  apptTypes
) {
  const { startTime, endTime, date, endDate, repeats } = values;
  const duration = Math.ceil(endTime?.diff(startTime, 'seconds') / 60);

  const eventStartTime =
    date
      ?.clone()
      .hours(startTime?.hours())
      .minutes(startTime?.minutes())
      .seconds(0)
      .milliseconds(0) || moment();

  const appType = apptTypes.find(apt => apt.id === values.appType);
  let recurringEvery = adminAppointmentRepeats.findIndex(e => e.id === repeats);

  return {
    subject: `${appType?.value} - ${provider.displayName}`,
    startTime: eventStartTime?.toISOString(),
    endTime: eventStartTime?.add(duration || 0, 'minutes').toISOString(),
    therapist: {
      id: provider.id,
      name: provider.displayName,
      email: provider.email
    },
    clinic: {
      id: provider.clinicId,
      timeZone
    },
    appointmentType: { id: appType?.id },
    recurringEvery,
    duration,
    notes: values.notes,
    recurringUntil:
      recurringEvery === 0 || endDate === null
        ? null
        : endDate?.endOf('day').toISOString(),
    eventType: eventTypes[1]
  };
}

export const styleAdminEvent = event => {
  return {
    id: 'userEventID',
    subject: event.subject,
    startTime: new Date(event.startTime),
    start: new Date(event.startTime),
    endTime: new Date(event.endTime),
    end: new Date(event.endTime),
    status: event.status,
    therapist: event.therapist,
    client: event.client,
    room: event.room,
    appointmentType: event.appointmentType,
    street: event.street || '',
    zipCode: event.zipCode || '',
    recurringEvery: event.recurringEvery || 0,
    duration:
      event.duration ||
      moment(event.endTime).diff(moment(event.startTime), 'minutes'),
    recurringUntil: event.recurringUntil,
    color: '#6f4bf1'
  };
};

export const mapEventToEventInput = event => {
  delete event.therapist.__typename;
  delete event.client.__typename;
  delete event.room.__typename;
  delete event.appointmentType.__typename;

  return {
    subject: event.subject,
    startTime: event.startTime,
    endTime: event.endTime,
    status: event.status,
    therapist: event.therapist,
    client: event.client,
    room: event.room,
    appointmentType: event.appointmentType,
    street: event.street || '',
    zipCode: event.zipCode || '',
    recurringEvery: event.recurringEvery || 0,
    duration:
      event.duration ||
      moment(event.endTime).diff(moment(event.startTime), 'minutes'),
    recurringUntil: event.recurringUntil
  };
};

const prepareLocationDetails = location => {
  for (const key of Object.keys(location)) {
    if (!location[key]) location[key] = '';
    if (!isString(location[key])) continue;
    location[key] = location[key].trim();
  }
  location.id = undefined;
  location.isOldLocation = undefined;
  return location;
};

export const getDateFromFormDate = (selectedDay, formDate) =>
  moment(selectedDay)
    .set('hours', formDate.hours())
    .set('minutes', formDate.minutes())
    .set('seconds', 0)
    .set('milliseconds', 0)
    .toISOString();

const mapRecurrencePattern = (recurrencePattern, isRecurring) => {
  const recurringEvery = recurrencePattern.recurringEvery;
  const recurringUntil = recurrencePattern.recurringUntil;
  delete recurrencePattern.recurringDaysArray;
  delete recurrencePattern.recurringDays;

  return {
    ...recurrencePattern,
    recurringEvery: parseInt(
      isRecurring
        ? recurringEvery === EVENT_OCCURENCE[4].value
          ? 1
          : recurringEvery
        : 0
    ),
    recurringUntil: isRecurring
      ? moment(recurringUntil)
          .endOf('day')
          .toISOString()
      : null
  };
};

export const prepareAdminForSubmissionV2 = (
  data,
  providersMap,
  additionalProvidersList,
  isDriveTime
) => {
  const provider = providersMap.get(data.provider.id);
  const formStartDate = data.selectedOpening
    ? moment(data.selectedOpening.start)
    : moment(data.startDate);
  const formEndDate = data.selectedOpening
    ? moment(data.selectedOpening.end)
    : moment(data.endDate);

  const startDate = getDateFromFormDate(data.selectedDay, formStartDate);
  const endDate = getDateFromFormDate(data.selectedDay, formEndDate);

  const isRecurring = data.scheduleType === SCHEDULE_TYPE_LIST[1].id;
  const recurrencePattern = mapRecurrencePattern(
    data.recurrencePattern,
    isRecurring
  );

  let additionalProvidersResults = additionalProvidersList.map(provider => {
    return {
      startDate,
      endDate,
      provider: { id: provider.id },
      clinic: { id: provider.clinic.id },
      appointmentType: { id: data.appType },
      duration: data.duration,
      note: data.note?.note
        ? {
            id: data.note?.id,
            note: data.note?.note || ''
          }
        : null,
      recurrencePattern,
      driveTimeSourceAddr: isDriveTime ? data.driveTimeSourceAddr : null,
      driveTimeDesteAddr: isDriveTime ? data.driveTimeDesteAddr : null,
      driveTimeMileage: isDriveTime ? parseFloat(data.driveTimeMileage) : null,
      isFirstOrLastDriveToNonClinic:
        isDriveTime && data.isFirstOrLastDriveToNonClinic === undefined
          ? 0
          : data.isFirstOrLastDriveToNonClinic,
      reimbursableMileage: isDriveTime
        ? parseFloat(data.reimbursableMileage)
        : null
    };
  });

  const leadResult = {
    id: data.id,
    startDate,
    endDate,
    provider: { id: data.provider.id },
    clinic: { id: provider.clinic.id },
    appointmentType: { id: data.appType },
    duration: data.duration,
    note: data.note?.note
      ? {
          id: data.note?.id,
          note: data.note?.note || ''
        }
      : null,
    recurrencePattern,
    driveTimeSourceAddr: isDriveTime ? data.driveTimeSourceAddr : null,
    driveTimeDesteAddr: isDriveTime ? data.driveTimeDesteAddr : null,
    driveTimeMileage: isDriveTime ? parseFloat(data.driveTimeMileage) : null,
    isFirstOrLastDriveToNonClinic:
      isDriveTime && data.isFirstOrLastDriveToNonClinic === undefined
        ? false
        : data.isFirstOrLastDriveToNonClinic,
    reimbursableMileage: isDriveTime
      ? parseFloat(data.reimbursableMileage)
      : null
  };
  return [leadResult, ...additionalProvidersResults];
};

export const prepareEventForSubmissionV2 = data => {
  const formStartDate = data.selectedOpening
    ? moment(data.selectedOpening.start)
    : moment(data.startDate);
  const formEndDate = data.selectedOpening
    ? moment(data.selectedOpening.end)
    : moment(data.endDate);

  const startDate = getDateFromFormDate(data.selectedDay, formStartDate);
  const endDate = getDateFromFormDate(data.selectedDay, formEndDate);

  const address = data.address || undefined;
  const isRecurring = data.scheduleType === SCHEDULE_TYPE_LIST[1].id;
  const recurrencePattern = mapRecurrencePattern(
    data.recurrencePattern,
    isRecurring
  );
  const MappedEventData = {
    id: data.id,
    client: data.client
      ? {
          id: data.client.id
        }
      : null,
    provider: {
      id: data.provider.id
    },
    clinic: {
      id: data.clinic?.id || data.client?.clinic?.id
    },
    appointmentType: {
      id: data.appSubType ? data.appSubType : data.appType
    },
    room:
      data.locationType === LOCATION_TYPE_LIST[0].id && data.room
        ? {
            id: data.room.id
          }
        : null,
    startDate,
    endDate,
    duration: data.duration,
    locationType: data.locationType,
    locationCategory: data.locationCategory,
    clinicPreference: data.clinicPreference,
    paymentMethod: data.isClinical ? null : data.paymentMethod,
    address: data.locationType === LOCATION_TYPE_LIST[1].id ? address : null,
    recurrencePattern: recurrencePattern,
    note: data.note?.note
      ? {
          id: data.note?.id,
          note: data.note?.note || ''
        }
      : null,
    telehealthLink:
      data.locationType === LOCATION_TYPE_LIST[2].id
        ? data.telehealthLink
        : undefined,
    isPendingConfirmation: data.isPendingConfirmation,
    abaObserverList: data.abaObserversList
  };
  if (data.clinic.abbreviation) {
    MappedEventData.clinic.abbreviation = data.clinic.abbreviation;
  }
  if (data.client.firstName && data.client.lastName) {
    MappedEventData.client.firstName = data.client.firstName;
    MappedEventData.client.lastName = data.client.lastName;
  }
  return MappedEventData;
};

//TODO: Check time conversion to isostring ?
export const getStaffEventsVariables = (
  providers,
  calendarDate,
  calendarView,
  canceled,
  isDelete
) => {
  return {
    variables: {
      providersIDs: providers?.map(p => p.id),
      startDate: customizeRange(calendarDate, getView(calendarView)).start,
      endDate: customizeRange(calendarDate, getView(calendarView)).end,
      canceled: canceled,
      isDelete: isDelete
    }
  };
};

export const getStaffCanceledEventsVariables = (
  providers,
  calendarDate,
  calendarView,
  canceled,
  isDelete
) => {
  return {
    variables: {
      providersIDs: providers,
      startDate: customizeRange(calendarDate, getView(calendarView)).start,
      endDate: customizeRange(calendarDate, getView(calendarView)).end,
      canceled,
      isDelete
    }
  };
};

export const getClientEventsVariables = (
  clients,
  calendarDate,
  calendarView,
  canceled,
  isDelete
) => {
  return {
    variables: {
      clientsIDs: clients.map(c => c.id),
      startDate: customizeRange(calendarDate, getView(calendarView)).start,
      endDate: customizeRange(calendarDate, getView(calendarView)).end,
      canceled,
      isDelete
    }
  };
};

export const getClientCanceledEventsVariables = (
  clients,
  calendarDate,
  calendarView,
  canceled,
  isDelete
) => {
  return {
    variables: {
      clientsIDs: clients,
      startDate: customizeRange(calendarDate, getView(calendarView)).start,
      endDate: customizeRange(calendarDate, getView(calendarView)).end,
      canceled,
      isDelete
    }
  };
};

export const getClientDeletedEventsVariables = (
  clients,
  calendarDate,
  calendarView
) => {
  return {
    variables: {
      clientsIDs: clients,
      startDate: customizeRange(calendarDate, getView(calendarView)).start,
      endDate: customizeRange(calendarDate, getView(calendarView)).end
    }
  };
};

export const getSearchedStaffEventsVariables = (
  providers,
  calendarDate,
  calendarView,
  canceled,
  isDelete
) => {
  return {
    variables: {
      providersIDs: providers?.map(p => p.id),
      startDate: customizeRange(calendarDate, getView(calendarView)).start,
      endDate: customizeRange(calendarDate, getView(calendarView)).end,
      canceled,
      isDelete
    }
  };
};

export const mapBaseEvent = (fullEvent, provider, oldProvider) => {
  const { event } = fullEvent;
  return {
    id: event.id,
    client: { id: event?.client?.id },
    provider: { id: provider?.id },
    clinic: { id: event?.clinic?.id },
    appointmentType: { id: event.appointmentType?.id },
    duration: event.duration,
    locationType: event.locationType,
    recurrencePattern: event.recurrencePattern,
    isPendingConfirmation: event.isPendingConfirmation,
    paymentMethod: event.paymentMethod,
    note: event.note,
    address: event.address,
    clinicPreference:
      provider?.clinicId === oldProvider?.clinicId
        ? event.clinicPreference
        : CLINIC_PROVIDERS[2].id
  };
};

export const mapBaseAdminEvent = (event, provider) => {
  return {
    id: event.id,
    provider: { id: provider?.id },
    clinic: { id: event.clinic?.id },
    appointmentType: { id: event.appointmentType?.id },
    duration: event.duration,
    locationType: event.locationType,
    recurrencePattern: event.recurrencePattern
  };
};
