import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
  color: string;
}
export const AttendeeRowWrapper = styled(Grid.Row)<Props>`
  .attendees {
    display: flex;
    align-items: center;
    margin: 5px;
    .avatar {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
      border-radius: 50%;
      border: solid 1px #979797;
      background-color: #d8d8d8;
    }
    .attende-name {
      font-size: 15px;
      color: #6f42f5;
    }
    .staff-bullet {
      width: 11px;
      height: 11px;
      background-color: ${props =>
        props.specialityColor ? props.specialityColor : '#45bfff'};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .clientEvent .provider-btn {
    background: ${props => props.color};
    color: white;
    border: none;
    border-radius: 6px;
    padding: 8%;
    font-size: 12px;
    font-weight: 700;
  }
  .avatar {
    width: 16% !important;
    border-radius: 19px;
    height: 60% !important;
  }
  .attende-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6f42f5;
    width: 48% !important;
    padding-top: 6px !important;
  }
  .button-column {
    width: 36% !important;
  }
  .row-style {
    width: 100% !important;
  }
  .ui.grid {
    width: 100% !important;
  }
  .ui.grid > .row {
    width: 100% !important;
    padding-top: 0;
  }
`;

export const CancelAttendeeRowWrapper = styled(Grid.Row)<Props>`
  .attendees {
    display: flex;
    align-items: center;
    margin: 5px;
    margin-bottom: 20px;
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: solid 1px #979797;
      background-color: #d8d8d8;
    }
    .attende-name {
      font-size: 15px;
      color: #3e5766;
    }
    .staff-bullet {
      width: 11px;
      height: 11px;
      background-color: ${props =>
        props.specialityColor ? props.specialityColor : '#45bfff'};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .provider-btn {
    background: ${props => props.color};
    color: white;
    border: none;
    border-radius: 6px;
    padding: 5%;
    font-size: 12px;
    font-weight: 700;
  }
  .avatar {
    width: 50px !important;
    border-radius: 19px;
  }
  .attende-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3e5766;
    width: 56% !important;
    padding-top: 6px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .button-column {
    width: 28% !important;
    padding-right: 0px !important;
    margin-top: 2px !important;
  }
  .row-style {
    width: 100% !important;
  }
  .ui.grid {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .ui.grid > .row {
    width: 100% !important;
    padding-top: 0;
  }
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  .observer1-btn {
    background: #45bfff;
  }
  .observer2-btn {
    background: #4d7bbd;
  }
  .observer3-btn {
    background: #45bfff;
  }
  .observer4-btn {
    background: #4d7bbd;
  }
  .observer5-btn {
    background: #45bfff;
  }
`;
