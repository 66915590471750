import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import {
  CustomMarker,
  TimelineMarkers,
  TodayMarker
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { PlannerView } from 'utils/cache/calendar';
import { PLANNER_TIMESTEP } from 'utils/constants/planner';
import * as colors from 'utils/constants/theme';

interface Props {
  startTime: number;
  endTime: number;
  view: PlannerView;
}
const PlannerMarkers: React.FC<Props> = ({
  startTime,
  endTime,
  view
}: Props) => {
  const intervals = useMemo(() => {
    const intervals = [];
    let time: number | Moment = moment(startTime)
      .add(PLANNER_TIMESTEP, 'minutes')
      .valueOf();
    while (time <= endTime) {
      intervals.push({
        startTime: moment(time)
      });
      time = moment(time).add(PLANNER_TIMESTEP, 'minutes');
      if (time.minutes() === 0) {
        time = moment(time).add(PLANNER_TIMESTEP, 'minutes');
      }
      time = time.valueOf();
    }
    return intervals;
  }, [endTime, startTime]);
  return (
    <>
      {view === PlannerView.plannerDay && (
        <TodayMarker date={moment().valueOf()} interval={60000}>
          {({ styles }) => (
            <div
              style={{
                ...styles,
                backgroundColor: `${colors.RED}`,
                zIndex: 200
              }}
            />
          )}
        </TodayMarker>
      )}

      <TimelineMarkers>
        {intervals?.map(marker => (
          <CustomMarker
            date={marker.startTime.toDate().valueOf()}
            key={marker.startTime.toString()}
          >
            {({ styles }: any) => (
              <div
                style={{ ...styles, backgroundColor: '#e7eaed', width: '1px' }}
              />
            )}
          </CustomMarker>
        ))}
      </TimelineMarkers>
    </>
  );
};
export default PlannerMarkers;
