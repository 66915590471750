// make sure to be same as db data
export enum Specialty {
  id = 'id',
  title = 'title',
  color = 'color',
  abbreviation = 'abbreviation',
  department = 'department'
}
export interface IProviderSpecialty {
  [Specialty.id]?: number;
  [Specialty.title]: string;
  [Specialty.color]: string;
  [Specialty.abbreviation]: string;
  [Specialty.department]: string;
}

export interface ISpecialitiesOptionsList {
  id?: string;
  title?: string;
}
