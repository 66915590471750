import React from 'react';
import * as PropTypes from 'prop-types';

import {
  LibCheckbox,
  LibCheckboxGroup,
  LibOvalCheckboxGroup
} from './checkbox.style';
import { FormError } from 'api/sharedComponents/reactHookFormComponents/error';

const Checkbox = ({ children, ...restProps }) => {
  return <LibCheckbox {...restProps}>{children}</LibCheckbox>;
};

const CheckboxGroup = ({ ...restProps }) => {
  return <LibCheckboxGroup {...restProps}></LibCheckboxGroup>;
};

const OvalCheckboxGroup = ({ field, errors, children, ...restProps }) => {
  return (
    <>
      <LibOvalCheckboxGroup {...restProps}>{children}</LibOvalCheckboxGroup>
      {field?.name && <FormError name={field?.name} errors={errors} />}
    </>
  );
};

Checkbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Checkbox.defaultProps = {
  children: null
};

export default Checkbox;
export { Checkbox, CheckboxGroup, OvalCheckboxGroup };
