import React, { useContext } from 'react';
import { IEvent } from 'model/v2';
import { HeaderViewWrapper } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { CloseOutlined } from '@ant-design/icons';
import { EVENT_STATUS } from 'utils/constants/status';
import { plannerContext } from '..';

interface Props {
  setVisible: (val: boolean, shouldRemovePhantom?: boolean) => void;
  event: IEvent;
  handleCancel: () => void;
}
const HeaderView: React.FC<Props> = ({
  setVisible,
  event,
  handleCancel
}: Props) => {
  const { isBulkCancelMode } = useContext(plannerContext);

  const isCancelDisabled = React.useCallback(() => {
    return isBulkCancelMode;
  }, [isBulkCancelMode]);

  const cancelClickHandler = React.useCallback(() => {
    if (isCancelDisabled()) return;
    handleCancel();
  }, [handleCancel, isCancelDisabled]);

  return (
    <HeaderViewWrapper>
      <h2>Appointment</h2>
      <div className="icons-wrapper">
        {!!event && event?.status !== EVENT_STATUS.canceled && (
          <>
            <FontAwesomeIcon
              style={{
                cursor: isCancelDisabled() ? 'not-allowed' : 'pointer'
              }}
              icon={faBan}
              onClick={cancelClickHandler}
            />
          </>
        )}
        <CloseOutlined onClick={() => setVisible(false, true)} />
      </div>
    </HeaderViewWrapper>
  );
};
export default React.memo(HeaderView);
