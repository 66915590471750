import * as colors from 'utils/constants/theme';
import styled from 'styled-components';
import { PlannerView } from 'utils/cache/calendar';

interface Props {
  view: PlannerView;
}

export const CalandarWrapper = styled.div`
  height: 100%;
`;

export const CalendarUpperSidebar = styled.div`
  display: flex;

  .calendar-sidebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 291px;
    max-width: 291px;
    padding: 24px 16px 20px;
    border-right: 1px solid #d8d8d8;
    .sidebar-title {
      opacity: 0.8;
      font-family: Lato;
      font-size: 18px;
      font-weight: bold;
      color: #21303a;
      margin: 0;
    }
    .reset-filters {
      cursor: pointer;
    }
  }
  .calendar-toolbar_tags {
    width: calc(100% - 291px);
  }
`;

export const PlannerWrapper = styled.div<Props>`
  overflow-y: scroll;
  height: calc(100% - 101px);

  .react-calendar-timeline {
    height: auto;
    .rct-header-root {
      background-color: ${colors.WHITE};
      border: none !important;
      position: sticky;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
    }

    .rct-dateHeader {
      background-color: ${colors.WHITE};
      border-width: 1px !important;
      border-color: ${colors.LIGHT_GREY};
      border-bottom: none !important;
    }
    .rct-outer {
      border-bottom: 1px solid #d8d8d8;
      min-height: 60px !important;
      &::-webkit-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        min-height: 70px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
          inset 1px 1px 0px rgba(0, 0, 0, 0);
      }
      &::-moz-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-moz-scrollbar-track {
        background-color: transparent;
      }
      &::-moz-scrollbar-thumb {
        min-height: 70px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
      &::-ms-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-ms-scrollbar-track {
        background-color: transparent;
      }
      &::-ms-scrollbar-thumb {
        min-height: 70px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
      .rct-sidebar {
        min-height: 60px !important;
        background-color: #fff;
        min-height: 100% !important;
        border-right: 1px solid ${colors.LIGHT_GREY} !important;
      }
    }
  }

  .rct-outer .client-row:nth-child(even) {
    background-color: #bcb3ff1f !important;
  }
  .rct-outer .client-row:nth-child(odd) {
    background-color: #8d87ff29 !important;
  }
  .rct-outer .staff-row {
    background-color: #f6f6f600 !important;
  }
  .droppable-groups {
    min-height: 100%;
    height: auto;
  }

  .staff-row {
    background-color: ${colors.WHITE} !important;
  }
  .client-row {
    background-color: #f6f5ff !important;
  }

  .rct-sidebar {
    border: none !important;
  }

  .rct-sidebar-row {
    border-width: 1px !important;
    border-color: #c9cfd3;
    overflow: unset !important;
    min-height: 60px !important;
    border-bottom: ${props =>
      props.view === PlannerView.plannerWeek ? 'none !important' : ''};
  }
  .rct-sidebar-row {
    padding: 0 !important;
    // border-bottom: 1px solid #e7eaed !important;
  }
  .day-label-wrapper:nth-child(2) {
    border-top: 1px solid #21303a;
  }
  .rct-sidebar-row-even .day-label-wrapper.day-client {
    background-color: rgb(232, 229, 255, 0.7) !important;
  }

  .rct-sidebar-row-even .day-label-wrapper.day-provider {
    background-color: rgb(231, 234, 237, 0.4) !important;
  }
  .rct-calendar-header {
    border: 0px solid ${colors.LIGHT_GREY} !important;
    margin-left: 1px;
  }
  .react-calendar-timeline .rct-scroll {
    overflow-x: hidden;
  }
  .rct-vl.rct-day-0 {
    background: transparent !important;
  }
  .rct-vl.rct-day-6 {
    background: transparent !important;
  }

  .react-calendar-timeline .rct-hl {
    border: none !important;
    border-top: ${props =>
      props.view === PlannerView.plannerWeek
        ? '1px solid #21303a !important;'
        : ''};
    z-index: 81;
  }
  z-index: 2 !important;
    border-bottom: 1px solid #e7eaed !important;
  }
  .react-calendar-timeline .rct-hl .rct-hl-even {
    border-bottom: ${props =>
      props.view === PlannerView.plannerWeek
        ? '1px solid #21303a !important;'
        : '1px solid #e7eaed !important;'};
        z-index: 1;
        background-color: rgb(232, 229, 255, 0.7) !important;
  }
  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid rgb(155, 155, 155, 0.4) !important;
  }
  .rct-vertical-lines {
    position: relative !important;
    z-index: 9;
  }
  .react-calendar-timeline .rct-hl .rct-hl-odd {
    border-bottom: ${props =>
      props.view === PlannerView.plannerWeek
        ? '1px solid #21303a !important;'
        : '1px solid #e7eaed !important;'};
      z-index: 1;
    background-color: rgb(231, 234, 237, 0.4) !important;
  }
  .rct-outer {
    border-top: 1px solid ${colors.LIGHT_GREY} !important ;
    position: relative !important;
    z-index: 9 !important;
  }
  span {
    font-family: Lato;
    font-size: 16px;
    text-align: center;
    color: ${colors.DARK_GREY};
  }
  .minutes {
    height: 74px !important;

    span {
      font-family: Lato;
      font-size: 11px;
      font-weight: 500;
      color: ${colors.DARK_GREY};
      display: flex;
      justify-content: center;
    }
  }
  .hours {
    border-top: 1px solid ${colors.LIGHT_GREY};
    border-bottom: 1px solid ${colors.LIGHT_GREY};
    height: 50px !important;
  }

  .infinite-scroll-component {
    overflow: unset !important; // for dateheader to stay sticky
  }
  .rct-hl-even.client-row .rct-hl-even {
    background-color: #8d87ff36 !important;
  }
  .rct-hl-even.staff-row {
    background-color: rgb(231, 234, 237, 0.5) !important;
    min-height: 60px !important;
  }
`;

export const SearchWrapper = styled.div`
  justify-content: center;
  align-items: center;
  margin-right: 350px !important;
  display: flex;
`;

export const PlannerSidebarWrapper = styled.div<Props>`
  display: block;
  width: ${props =>
    props.view === PlannerView.plannerDay ? '290px' : '379px'};

  .ui.fluid.selection.dropdown {
    width: 260px;
  }
`;

export const AvailableSlotWrapper = styled.div`
  .slot-wrapper {
    width: 100%;
    height: 40px !important;
    display: flex !important;
    padding: 2px;
    text-overflow: ellipses !important;
    flex-direction: column;
  }
  .title-text {
    display: flex;
    color: rgba(143, 143, 143, 1) !important;
    font-size: 14px !important;
  }
  .slot-name {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .h-40 {
    height: 16px;
  }
  .h-60 {
    height: 24px;
  }
  .location-icons {
    padding: 0px 3px;
    height: 50%;
    display: flex;
    justify-content: flex-start;
    background-color: #ffffff;
  }
  .icon-wrapper {
    width: 20px;
    // img{
    //   height: 14px !important;
    //   width: 14px !important;
    //   color: rgba(143, 143, 143, 1) !important;
    // }
    span {
      font-size: 12px !important;
      color: rgba(143, 143, 143, 1) !important;
    }
  }
  .fa-home .fa-link .fa-briefcase {
    font-size: 12px !important;
    color: red !important;
    background-color: #ffffff !important;
  }
`;
