import React, { useCallback, useState } from 'react';
import { BigCalendar } from 'lib/ui';
import { useQuery } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { useSubscription } from 'react-apollo';
import Toolbar from 'views/components/calendar/mainCalendar/toolbar';
import CalendarEvent from 'views/components/calendar/mainCalendar/event';
import { CalendarWrapper } from 'views/components/calendar/mainCalendar/MainCalendar.style';
import {
  getStaffMemberEventsV2,
  getStaffMemberNonWorkingHours,
  mapAvailabilityBlocksToEvents
} from 'utils/mappers/response/staff';
import {
  customEventPropGetter,
  customizeRange,
  localizer
} from 'utils/builders/calendar';
import {
  staffCalendarPrefDayVar,
  staffCalendarPrefViewVar,
  views
} from 'utils/cache/calendar/index';
import {
  MAX_CALENDAR_TIME,
  MIN_CALENDAR_TIME,
  STAFF_CALENDAR_MIN_HEIGHT,
  STAFF_CALENDAR_MAX_HEIGHT
} from 'utils/constants/calendar';
import { TIMEZONE } from 'utils/constants/default';
import { GET_PROVIDER_PROFILE_CALENDAR } from 'api/graphql/v2/queries/Providers';
import { CALENDAR_EVENTS_SUBSCRIPTION } from 'api/graphql/v2/subscriptions/Events';
import { handleSubscriptionDataUpdate } from 'helpers/subscriptionsHelper';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { IProvider } from 'model/v2';
import { ICalendarEvent } from 'model/calendar/events';
import { View } from 'react-big-calendar';

const formats = {
  dayFormat: 'ddd DD'
};

interface CustomEventProps {
  event: ICalendarEvent;
}

interface ScheduleCalendarProps {
  provider: IProvider;
}

const ScheduleCalendar = ({ provider }: ScheduleCalendarProps) => {
  const calendarDate = useReactiveVar(staffCalendarPrefDayVar);
  const calendarView = useReactiveVar(staffCalendarPrefViewVar) as View;
  const [totalEvents, setTotalEvents] = useState<ICalendarEvent[]>([]);
  const [providerAvailabilityEvents, setProviderAvailabilityEvents] = useState<
    ICalendarEvent[]
  >([]);

  const handleRangeChange = useCallback(date => {
    staffCalendarPrefDayVar(date);
  }, []);

  const handleViewChange = useCallback(inputView => {
    staffCalendarPrefViewVar(inputView);
  }, []);

  useQuery(GET_PROVIDER_PROFILE_CALENDAR, {
    fetchPolicy: 'no-cache',
    variables: {
      providerId: Number(provider.id),
      startDate: customizeRange(calendarDate, calendarView).start,
      endDate: customizeRange(calendarDate, calendarView).end,
      timeZone: TIMEZONE
    },
    skip: !provider?.id,
    onCompleted: data => {
      const availabilities = mapAvailabilityBlocksToEvents(
        data?.providerCalendarAvailabilities,
        data?.providerLocations,
        customizeRange(calendarDate, calendarView).start
      );
      const events = data?.eventsByProvider || [];
      const userWorkingHours = data?.providerAvailabilityBlocks;

      const range = {
        start: customizeRange(calendarDate, calendarView).start,
        end: customizeRange(calendarDate, calendarView).end
      };

      setProviderAvailabilityEvents(availabilities);
      setTotalEvents(
        getStaffMemberEventsV2(events, range).concat(
          getStaffMemberNonWorkingHours(
            userWorkingHours?.workingHours,
            range,
            provider?.id
          ).concat(availabilities)
        )
      );
    }
  });

  useSubscription(CALENDAR_EVENTS_SUBSCRIPTION, {
    variables: {
      providerId: Number(provider.id),
      startDate: customizeRange(calendarDate, calendarView).start,
      endDate: customizeRange(calendarDate, calendarView).end
    },
    skip: !getFeatureAvailability(FEATURES.CALENDARS_SUBSCRIPTIONS),
    onSubscriptionData: result => {
      const events = handleSubscriptionDataUpdate(
        result?.subscriptionData?.data?.calendarEvents,
        totalEvents
      );
      setTotalEvents(
        getStaffMemberEventsV2(
          events,
          customizeRange(calendarDate, calendarView)
        ).concat(providerAvailabilityEvents)
      );
    }
  });

  const customToolbar = React.memo(data => {
    return (
      <Toolbar
        data={data}
        updateView={handleViewChange}
        range={customizeRange(calendarDate, calendarView)}
      />
    );
  });

  const customEvent = React.memo(({ event }: CustomEventProps) => {
    return (
      <>
        <CalendarEvent event={event} view={calendarView} provider />
      </>
    );
  });

  const customToolTipOfAvailability = useCallback(event => {
    if (event.type === 'availability') return event.title;
  }, []);

  const eventsOnly = calendarView === views.MONTH;
  return (
    <CalendarWrapper className={calendarView}>
      <BigCalendar
        view={calendarView}
        onView={handleViewChange}
        events={
          eventsOnly ? totalEvents.filter(e => e.type !== 'na') : totalEvents
        }
        views={[views.DAY, views.WEEK, views.MONTH]}
        defaultView={views.WEEK}
        min={MIN_CALENDAR_TIME}
        max={MAX_CALENDAR_TIME}
        components={{
          toolbar: customToolbar,
          event: customEvent
        }}
        date={calendarDate}
        onNavigate={handleRangeChange}
        step={15}
        timeslots={4}
        localizer={localizer}
        slotEventOverlap={false} // TODO: check usage of this prop
        formats={formats}
        eventPropGetter={customEventPropGetter}
        style={{
          height:
            calendarView !== views.MONTH
              ? STAFF_CALENDAR_MAX_HEIGHT
              : STAFF_CALENDAR_MIN_HEIGHT
        }}
        tooltipAccessor={customToolTipOfAvailability}
        className="schedule-calendar"
      />
    </CalendarWrapper>
  );
};

ScheduleCalendar.propTypes = {
  uiState: PropTypes.object
};

export default React.memo(ScheduleCalendar);
