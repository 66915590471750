import { pick, min, max, isEqual } from 'lodash';
import moment from 'moment';
import { getApptTypeAndSubType, clean, getApptType } from 'utils/common';

export const mapToCarePlanBE = (
  values,
  dataSource,
  client,
  apptTypes,
  clinicsMap
) => {
  const carePlan = {
    clientID: client.id,
    clientName: client.name,
    clinicID: client.clinicId,
    clinicName: clinicsMap?.get(client.clinicId)?.name,
    clinicAbbreviation: clinicsMap?.get(client.clinicId)?.abbreviation
  };

  carePlan.recommendedEvaluations = values.recommendedEvaluations.map(
    recommendation => {
      const { apptType, apptSubType } = getApptTypeAndSubType(
        {
          appointmentType: { title: recommendation.apptType },
          appointmentSubType: recommendation.apptSubType
        },
        apptTypes
      );

      return clean({
        ...pick(recommendation, ['id', 'clientResponse']),
        appointmentType: { id: apptType.id, isClinical: apptType.isClinical },
        appointmentSubType: apptSubType?.id,
        createdAt: recommendation.createdAt || moment().toISOString()
      });
    }
  );

  carePlan.recommendedTherapies = values.recommendedTherapies.map(
    recommendation => {
      const { apptType, apptSubType } = getApptTypeAndSubType(
        {
          appointmentType: { title: recommendation.apptType },
          appointmentSubType: recommendation.apptSubType
        },
        apptTypes
      );
      const recommendedQuantity = recommendation.recommendedQuantity
        .split('-')
        .map(quantity => quantity.trim());
      const isUpdated = isEqual(
        recommendation,
        dataSource.recommendedTherapies.find(
          recommendedTherapy => recommendedTherapy.id === recommendation.id
        )
      );

      return clean({
        ...pick(recommendation, ['id', 'quantityPattern']),
        appointmentType: { id: apptType.id, isClinical: apptType.isClinical },
        appointmentSubType: apptSubType?.id,
        minRecommendedQuantity: parseFloat(min(recommendedQuantity)),
        maxRecommendedQuantity: parseFloat(max(recommendedQuantity)),
        requestedQuantity: parseInt(recommendation.requestedQuantity),
        updatedAt: isUpdated ? recommendation.updatedAt : moment().toISOString()
      });
    }
  );

  return carePlan;
};

// receives title of apptType & title of apptSubtype and value of appTypes
export const filterAppointmentTypes = (recommendedTherapies, apptTypes) => {
  if (
    !apptTypes ||
    apptTypes.length === 0 ||
    !Array.isArray(recommendedTherapies)
  )
    return [];
  /// filtered the recommended therapies to appointment types
  const selectedApptTypes = recommendedTherapies
    .map(({ apptSubType, apptType }) =>
      !apptSubType && !apptType
        ? undefined
        : {
            apptSubType,
            apptType,
            isClinical: getApptType({ title: apptType }, apptTypes)?.isClinical
          }
    )
    .filter(el => el !== undefined); // remove undefined values

  // filter apptTypes
  let filteredApptTypes = [...apptTypes];

  filteredApptTypes = filteredApptTypes
    .map(apptType => {
      /// all selected who has same appointment type
      const selectedWithApptTypes = selectedApptTypes.filter(
        selected => selected.apptType === apptType.title
      );
      // copy for reference issues
      let newApptType = { ...apptType };
      // 0 will return false
      if (selectedWithApptTypes.length) {
        selectedWithApptTypes.forEach(selected => {
          if (selected.isClinical && apptType) {
            // SMC appointment // filter sub type
            newApptType.subTypes = newApptType.subTypes.filter(
              el => el.title !== selected.apptSubType
            );
            if (newApptType?.subTypes.length === 0) newApptType = undefined;
          }
          // ABA appointment remove it totally as it has no sub types
          else newApptType = undefined;
        });
        return newApptType; // return the updated appointment type
      }
      // return initial apptType if it doesn't collide with selected
      return newApptType;
    })
    .filter(el => el !== undefined); // remove undefiend
  return filteredApptTypes;
};
