import * as Yup from 'yup';
import {
  LOCATION_TYPE_LIST,
  SCHEDULE_TYPE_LIST,
  EVENT_RESCHEDULE_OPTIONS,
  APPOINTMENT_CATEGORY,
  EVENT_OCCURENCE,
  GROUPED_CANCEL_OPTIONS
} from 'utils/constants/lists';
import { ABA_DURATION_ERROR } from 'utils/constants/errors';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { EventType } from 'model/calendar/events';
import { RescheduleModalType } from 'model';

const DATE_AND_TIME_VALIDATORS = {
  selectedDay: Yup.date()
    .nullable()
    .required('Date is required'),
  startDate: Yup.date()
    .nullable()
    .required('Start time is required')
    .typeError('Start time is required')
    .test('startDate', 'Start Time must be before End time', function(
      value: any
    ) {
      const endDate = this.parent.endDate; // Access the value of endDate
      const formatted_StartDate: Date = new Date(value);
      const formatted_EndDate: Date = new Date(endDate);

      const startHours: number = formatted_StartDate.getHours();
      const startMinutes: number = formatted_StartDate.getMinutes();
      const startSeconds: number = formatted_StartDate.getSeconds();

      const endHours: number = formatted_EndDate.getHours();
      const endMinutes: number = formatted_EndDate.getMinutes();
      const endSeconds: number = formatted_EndDate.getSeconds();

      const startTimeInSeconds: number =
        startHours * 3600 + startMinutes * 60 + startSeconds;
      const endTimeInSeconds: number =
        endHours * 3600 + endMinutes * 60 + endSeconds;

      if (startTimeInSeconds > endTimeInSeconds) {
        return false;
      } else {
        return true;
      }
    }),
  endDate: Yup.date()
    .nullable()
    .required('End time is required')
    .typeError('End time is required'),
  duration: Yup.number()
    .required('Duration is required')
    .test('duration', 'Invalid duration', function(value) {
      const apptCategoryType = this.parent.apptCategoryType;
      const isFeatureAvailable = getFeatureAvailability(
        FEATURES.FIVE_MINUTE_SCHEDULING
      );

      if (isFeatureAvailable && apptCategoryType === EVENT_TYPES.ABA) {
        // Apply specific validation for ABA event type with custom error message
        if (value !== undefined && value < 5 && value >= 0) {
          return this.createError({ message: ABA_DURATION_ERROR });
        }
      } else {
        // Apply default validation with the standard error message
        if (value !== undefined && value === 0) {
          return this.createError({ message: 'Duration is required' });
        }
      }

      // Validation passed
      return true;
    })
    .typeError('Duration is required')
};

const RESCHEDULE_REASON_VALIDATORS = (
  deleteWhenCancelAppointmentsIds: number[] = [],
  appointmentTypeId?: number
) => {
  return {
    reason: Yup.string()
      .when(['type'], {
        is: (type: number) =>
          (type === undefined ||
            type === RescheduleModalType.singleMappedValue) &&
          !deleteWhenCancelAppointmentsIds.includes(appointmentTypeId!),
        then: Yup.string()
          .required('Reason required')
          .nullable(),
        otherwise: Yup.string().nullable()
      })
      .nullable(),
    cancelReason: Yup.string()
      .when(['id', 'dirtyEdit', 'recurrent', 'eventTypeName', 'appType'], {
        is: (
          id: number,
          dirtyEdit: boolean,
          recurrent: any,
          eventTypeName: string,
          appType: number
        ) =>
          id &&
          dirtyEdit &&
          recurrent === EVENT_RESCHEDULE_OPTIONS(true)[0].value &&
          eventTypeName !== EventType.adminEvent &&
          !deleteWhenCancelAppointmentsIds.includes(appType),
        then: Yup.string()
          .required('Reason required')
          .nullable(),
        otherwise: Yup.string().nullable()
      })
      .nullable()
  };
};

const APPOINTMENT_COMMON_VALIDATORS = {
  id: Yup.number().nullable(),
  master: Yup.object()
    .shape({
      id: Yup.number().nullable()
    })
    .nullable(),
  dirtyEdit: Yup.boolean(),
  appType: Yup.number()
    .required('Appointment Type is required')
    .nullable(),
  provider: Yup.object({
    id: Yup.number()
      .nullable()
      .required('Provider is required')
  }),
  clinic: Yup.object({
    id: Yup.number()
      .nullable()
      .required('Clinic network is required')
  }),
  type: Yup.string().required('Appointment Category is required'),
  note: Yup.object({
    id: Yup.number().nullable(),
    note: Yup.string()
      .max(1000)
      .nullable()
  }).nullable(),
  scheduleType: Yup.string().required('Schedule Type is required'),
  recurrencePattern: Yup.object().when('scheduleType', {
    is: SCHEDULE_TYPE_LIST[1].id,
    then: Yup.object({
      recurringEvery: Yup.string()
        .required('Occurrence is required')
        .nullable(),
      recurringDaysArray: Yup.array()
        .when(
          ['recurringEvery'],
          (recurringEvery: string, schema: Yup.AnySchema) => {
            if (recurringEvery === EVENT_OCCURENCE[4].value)
              return schema.required('Recurring days are required');
            return schema.nullable();
          }
        )
        .nullable()
    }),
    otherwise: Yup.object({
      recurringEvery: Yup.string().nullable(),
      recurringDaysArray: Yup.array().nullable()
    })
  })
};

const ADDRESS_VALIDATOR = Yup.object()
  .shape({
    id: Yup.number().nullable(),
    name: Yup.string()
      .trim()
      .nullable()
      .required('Name is required'),
    addressLine1: Yup.string()
      .trim()
      .nullable()
      .required('Address Line 1 is required'),
    addressLine2: Yup.string().nullable(),
    state: Yup.string()
      .nullable()
      .required('State is required'),
    city: Yup.string()
      .trim()
      .nullable()
      .required('City is required'),
    zipCode: Yup.string()
      .nullable()
      .required('ZipCode is required')
  })
  .nullable()
  .required('Location Details are required');

const LOCATION_VALIDATORS = {
  locationType: Yup.string()
    .required('Location Type is required')
    .nullable(),
  locationCategory: Yup.string()
    .when('locationType', {
      is: LOCATION_TYPE_LIST[1].id,
      then: Yup.string()
        .required('Location category is required')
        .nullable()
    })
    .nullable(),
  address: Yup.object()
    .when('locationType', {
      is: LOCATION_TYPE_LIST[1].id,
      then: ADDRESS_VALIDATOR
    })
    .nullable(),
  telehealthLink: Yup.string().when('locationType', {
    is: LOCATION_TYPE_LIST[2].id,
    then: Yup.string().required('Telehealth link is required'),
    otherwise: Yup.string().nullable()
  })
};

const CLIENT_APPOINTMENT_VALIDATORS = {
  paymentMethod: Yup.string()
    .when(['eventTypeName'], (eventTypeName: string, schema: Yup.AnySchema) => {
      if (eventTypeName === EVENT_TYPES.ABA)
        return schema.required('Payment Method is required');
      return schema.nullable();
    })
    .nullable(),
  appSubType: Yup.number()
    .when(['eventTypeName'], (eventTypeName: string, schema: Yup.AnySchema) => {
      if (eventTypeName === EVENT_TYPES.DT)
        return schema.required('Appointment Sub type is required');
      else if (eventTypeName === EVENT_TYPES.MED)
        return schema.required('Appointment Sub type is required');
      return schema.nullable();
    })
    .nullable(),
  clinic: Yup.object({
    id: Yup.number()
      .nullable()
      .required('Clinic is required')
  }),
  clinicPreference: Yup.string().required('Clinic Providers is required')
};

export const APPOINTMENT_ADD_SCHEMA = Yup.object().shape({
  id: APPOINTMENT_COMMON_VALIDATORS.id,
  master: APPOINTMENT_COMMON_VALIDATORS.master,
  appSubType: CLIENT_APPOINTMENT_VALIDATORS.appSubType,
  clinic: CLIENT_APPOINTMENT_VALIDATORS.clinic,
  appType: APPOINTMENT_COMMON_VALIDATORS.appType,
  provider: APPOINTMENT_COMMON_VALIDATORS.provider,
  duration: DATE_AND_TIME_VALIDATORS.duration,
  repeat: Yup.number()
    .when('scheduleType', {
      is: SCHEDULE_TYPE_LIST[1].id,
      then: Yup.number()
        .required('Repeats every weeks number is required')
        .min(1)
    })
    .nullable(),
  clinicPreference: CLIENT_APPOINTMENT_VALIDATORS.clinicPreference,
  address: LOCATION_VALIDATORS.address,
  paymentMethod: CLIENT_APPOINTMENT_VALIDATORS.paymentMethod,
  locationType: LOCATION_VALIDATORS.locationType,
  locationCategory: LOCATION_VALIDATORS.locationCategory,
  selectedSlot: Yup.object()
    .shape({
      start: Yup.date().required('Start is required'),
      end: Yup.date().required('End is required in slot'),
      providerId: Yup.string().required('Provider is required'),
      provider: Yup.object()
        .shape({
          id: Yup.string().required('Provider id is required'),
          name: Yup.string().required('Provider name is required'),
          email: Yup.string().required('Provider email is required')
        })
        .required('Slot is invalid')
    })
    .nullable()
    .required('Selected slot is required'),
  selectedRoom: Yup.object()
    .shape({
      id: Yup.string().required('Room id is required'),
      roomName: Yup.string().required('Room name is required'),
      email: Yup.string().nullable()
    })
    .nullable()
    .notRequired(), //TODO: add validations on rooms
  telehealthLink: Yup.string()
    .nullable()
    .min(0), //TODO: check validation on telehealth link
  note: APPOINTMENT_COMMON_VALIDATORS.note
});

export const APPOINTMENT_NEW_ADMIN_SCHEMA = Yup.object().shape({
  appType: Yup.string().required('Appointment Type is required'),
  date: Yup.date().required('Date is required'),
  repeats: Yup.string().required('Repeats is required'),
  selectedDay: DATE_AND_TIME_VALIDATORS.selectedDay,
  startDate: DATE_AND_TIME_VALIDATORS.startDate,
  endDate: DATE_AND_TIME_VALIDATORS.endDate,
  duration: DATE_AND_TIME_VALIDATORS.duration,
  note: APPOINTMENT_COMMON_VALIDATORS.note
});

export const APPOINTMENT_RESCHEDULE_OFF_SITE_SCHEMA = (
  deleteWhenCancelAppointmentsIds: number[] = [],
  appointmentTypeId?: number
) => {
  return Yup.object().shape({
    reason: RESCHEDULE_REASON_VALIDATORS(
      deleteWhenCancelAppointmentsIds,
      appointmentTypeId
    ).reason,
    address: ADDRESS_VALIDATOR
  });
};

export const APPOINTMENT_RESCHEDULE_SCHEMA = (
  deleteWhenCancelAppointmentsIds: number[] = [],
  appointmentTypeId?: number
) => {
  return Yup.object().shape({
    reason: RESCHEDULE_REASON_VALIDATORS(
      deleteWhenCancelAppointmentsIds,
      appointmentTypeId
    ).reason
  });
};

export const APPOINTMENT_OBSERVER_CANCEL_SCHEMA = (deleteObserver: boolean) => {
  if (deleteObserver) {
    return Yup.object().shape({
      cancelReason: Yup.string()
        .nullable()
        .required('Reason is Required')
    });
  } else {
    return Yup.object().shape({
      cancelReason: Yup.string().notRequired()
    });
  }
};

export const APPOINTMENT_CANCEL_SCHEMA = (
  includeEventsToCancel: boolean,
  includeSingleCancelEvents: boolean
) => {
  return Yup.object().shape({
    cancelReason: Yup.string()
      .when('recurrent', (recurrent: string) => {
        if (
          ((recurrent === GROUPED_CANCEL_OPTIONS[0].value &&
            includeEventsToCancel) ||
            includeSingleCancelEvents) &&
          !(recurrent === GROUPED_CANCEL_OPTIONS[1].value)
        ) {
          return Yup.string()
            .nullable()
            .required('Reason required');
        } else if (recurrent === GROUPED_CANCEL_OPTIONS[1].value) {
          return Yup.string()
            .nullable()
            .notRequired();
        } else {
          return Yup.string()
            .nullable()
            .notRequired();
        }
      })
      .nullable()
  });
};

export const NEW_ADMIN_APPOINTMENT_ADD_SCHEMA = Yup.object().shape({
  id: APPOINTMENT_COMMON_VALIDATORS.id,
  master: APPOINTMENT_COMMON_VALIDATORS.master,
  dirtyEdit: APPOINTMENT_COMMON_VALIDATORS.dirtyEdit,
  appType: APPOINTMENT_COMMON_VALIDATORS.appType,
  provider: APPOINTMENT_COMMON_VALIDATORS.provider,
  type: APPOINTMENT_COMMON_VALIDATORS.type,
  selectedDay: DATE_AND_TIME_VALIDATORS.selectedDay,
  startDate: DATE_AND_TIME_VALIDATORS.startDate,
  endDate: DATE_AND_TIME_VALIDATORS.endDate,
  duration: DATE_AND_TIME_VALIDATORS.duration,
  note: APPOINTMENT_COMMON_VALIDATORS.note,
  scheduleType: APPOINTMENT_COMMON_VALIDATORS.scheduleType,
  recurrencePattern: APPOINTMENT_COMMON_VALIDATORS.recurrencePattern
  // commented for future refernece
  // driveTimeSourceAddr: Yup.object()
  //   .when('appType', {
  //     is: (appType: number) => {
  //       return appType === DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!];
  //     },
  //     then: Yup.object({
  //       freeformAddress: Yup.string().required('Starting Address is required')
  //     }),
  //     otherwise: Yup.object().nullable()
  //   })
  //   .transform(originalValue =>
  //     originalValue === undefined ? {} : originalValue
  //   )
  //   .nullable(),
  // driveTimeDesteAddr: Yup.object()
  //   .when('appType', {
  //     is: (appType: number) => {
  //       return appType === DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!];
  //     },
  //     then: Yup.object({
  //       freeformAddress: Yup.string().required(
  //         'Destination Address is required'
  //       )
  //     }),
  //     otherwise: Yup.object().nullable()
  //   })
  //   .transform(originalValue =>
  //     originalValue === undefined ? {} : originalValue
  //   )
  //   .nullable(),
  // driveTimeMileage: Yup.string()
  //   .when('appType', {
  //     is: (appType: number) =>
  //       appType === DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!],
  //     then: Yup.string().required('Drive Time Mileage is required'),
  //     otherwise: Yup.string().nullable()
  //   })
  //   .transform((originalValue, originalObject) => {
  //     // Convert empty strings to null
  //     if (originalObject.driveTimeMileage === '') {
  //       return null;
  //     }
  //     // Convert undefined values to null
  //     return originalValue === undefined ? null : originalValue;
  //   })
  //   .nullable()
});

export const NEW_APPOINTMENT_ADD_SCHEMA = (
  deleteWhenCancelAppointmentsIds: number[] = []
) => {
  return Yup.object().shape({
    id: APPOINTMENT_COMMON_VALIDATORS.id,
    master: APPOINTMENT_COMMON_VALIDATORS.master,
    dirtyEdit: APPOINTMENT_COMMON_VALIDATORS.dirtyEdit,
    cancelReason: RESCHEDULE_REASON_VALIDATORS(deleteWhenCancelAppointmentsIds)
      .cancelReason,
    appSubType: CLIENT_APPOINTMENT_VALIDATORS.appSubType,
    clinic: CLIENT_APPOINTMENT_VALIDATORS.clinic,
    appType: APPOINTMENT_COMMON_VALIDATORS.appType,
    scheduleType: APPOINTMENT_COMMON_VALIDATORS.scheduleType,
    recurrencePattern: APPOINTMENT_COMMON_VALIDATORS.recurrencePattern,
    clinicPreference: CLIENT_APPOINTMENT_VALIDATORS.clinicPreference,
    paymentMethod: CLIENT_APPOINTMENT_VALIDATORS.paymentMethod,
    address: LOCATION_VALIDATORS.address,
    locationType: LOCATION_VALIDATORS.locationType,
    locationCategory: LOCATION_VALIDATORS.locationCategory,
    telehealthLink: LOCATION_VALIDATORS.telehealthLink,
    selectedDay: DATE_AND_TIME_VALIDATORS.selectedDay,
    startDate: DATE_AND_TIME_VALIDATORS.startDate,
    endDate: DATE_AND_TIME_VALIDATORS.endDate,
    duration: DATE_AND_TIME_VALIDATORS.duration,
    provider: APPOINTMENT_COMMON_VALIDATORS.provider,
    type: APPOINTMENT_COMMON_VALIDATORS.type,
    // THERE IS ACTUALLY NO REASON TO CHECK ON TYPE BECAUSE IF WE ARE ADMIN ... WE DONT HAVE CLIENT
    // AND THESE VALIDATIONS ARE NOT EVEN IN USE
    client: Yup.object({
      id: Yup.lazy(() =>
        Yup.number()
          .nullable()
          .required('Client is required')
      )
    }),
    note: APPOINTMENT_COMMON_VALIDATORS.note,
    selectedOpening: Yup.object()
      .when('smart', {
        is: true,
        then: Yup.object()
          .nullable()
          .required('Opening is required')
      })
      .nullable()
  });
};

export const NEW_SMART_APPOINTMENT_SCHEMA = (
  type: string,
  isSingleAppt: boolean
) => {
  const isDT = type === APPOINTMENT_CATEGORY[1].value;
  const isABA = type === APPOINTMENT_CATEGORY[0].value;
  return Yup.object().shape({
    appType: APPOINTMENT_COMMON_VALIDATORS.appType,
    appSubType: Yup.number().when('apptCategoryType', {
      is: APPOINTMENT_CATEGORY[1].value,
      then: schema => schema.required('Appointment Sub type is required'),
      otherwise: schema => schema.nullable()
    }),

    clientAvailabilityDayFilter: Yup.array()
      .when('isIgnoreClientProfileAvailability', {
        is: true,
        then: Yup.array().required('Client Availabilty days are required')
      })
      .nullable(),
    apptCategory: APPOINTMENT_COMMON_VALIDATORS.type,
    appTypeLabel: Yup.string()
      .required('Appointment Type is required')
      .nullable(),
    startDate: Yup.date()
      .nullable()
      .typeError('Start time is required'),
    endDate: Yup.date()
      .nullable()
      .required('End time is required')
      .typeError('End time is required'),
    client: Yup.object({
      id: Yup.lazy(() =>
        Yup.number()
          .nullable()
          .required('Client is required')
      )
    }),
    aba:
      isABA && isSingleAppt
        ? Yup.object({
            singleSessionDuration: Yup.string().required(
              'Session Duration is required'
            )
          })
        : Yup.object().nullable(),
    dt: isDT
      ? Yup.object({
          sessionDuration: Yup.number().required(
            'Session Duration is required'
          ),
          weeklySessions: Yup.number().required('Weekly Sessions is required'),
          clientFrequencyPreference: Yup.string(),
          maximumProviders: Yup.number()
            .min(1)
            .max(3),
          clientDailyHours: Yup.number()
        })
      : Yup.object().nullable()
  });
};

export const CANCEL_ADD_ADMIN_SCHEMA = () => {
  return Yup.object().shape({
    cancelReason: Yup.string()
      .nullable()
      .required('Reason required')
  });
};

export const NEW_DRIVE_TIME_EDIT_SCHEMA = Yup.object().shape({
  // commented for future reference
  // driveTimeSourceAddr: Yup.object()
  //   .when('appType', {
  //     is: (appType: number) => {
  //       return appType === DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!];
  //     },
  //     then: Yup.object({
  //       freeformAddress: Yup.string().required('Starting Address is required')
  //     }),
  //     otherwise: Yup.object().nullable()
  //   })
  //   .transform(originalValue =>
  //     originalValue === undefined ? {} : originalValue
  //   )
  //   .nullable(),
  // driveTimeDesteAddr: Yup.object()
  //   .when('appType', {
  //     is: (appType: number) => {
  //       return appType === DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!];
  //     },
  //     then: Yup.object({
  //       freeformAddress: Yup.string().required(
  //         'Destination Address is required'
  //       )
  //     }),
  //     otherwise: Yup.object().nullable()
  //   })
  //   .transform(originalValue =>
  //     originalValue === undefined ? {} : originalValue
  //   )
  //   .nullable(),
  // driveTimeMileage: Yup.string()
  //   .when('appType', {
  //     is: (appType: number) =>
  //       appType === DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!],
  //     then: Yup.string().required('Drive Time Mileage is required'),
  //     otherwise: Yup.string().nullable()
  //   })
  //   .transform((originalValue, originalObject) => {
  //     // Convert empty strings to null
  //     if (originalObject.driveTimeMileage === '') {
  //       return null;
  //     }
  //     // Convert undefined values to null
  //     return originalValue === undefined ? null : originalValue;
  //   })
  //   .nullable()
});
