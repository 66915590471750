import styled from 'styled-components';

export const CancelModalWrapper = styled.div`
  border-radius: 5px;
  padding: 1em 2em 2.5em;
  text-align: center;
  border: 1px solid #db2828;
  border-top: 10px solid #db2828;
  .content {
    padding: 1em 0;
    .message {
      font-size: 28px;
      padding: 1em 0;
    }
  }
  .cta {
    display: block;
    display: inline-flex;
    gap: 25px;

    .button {
      min-width: 140px;
      background-color: #6f42f5;
      color: white;
      min-height: 40px;
    }
  }
  .error {
    position: absolute;
    padding: 5px 0;
  }
`;
