import styled from 'styled-components';

export const MainCalendarSidebarWrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px 20px 16px;
    .header-title {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: normal;
    }
    .close-button {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
`;
