import { MsalProvider } from '@azure/msal-react';
import AuthenticationHelper from './AuthenticationHelper';
import { Props as ApolloWrapperProps } from './ApolloWrapper';
import { PublicClientApplication } from '@azure/msal-browser';

interface Props {
  children: ApolloWrapperProps['children'];
  pca: PublicClientApplication;
}
export default function MsalWrapper({ children, pca }: Props) {
  return (
    <MsalProvider instance={pca}>
      <AuthenticationHelper children={children} />
    </MsalProvider>
  );
}
