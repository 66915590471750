import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

interface Props {
  visible?: boolean;
  width?: number;
  height?: string;
}
export const CalenderWrapper = styled.div`
  .filter-input_wrapper {
    width: 258px;
  }
  .list_wrapper {
    position: absolute;
    width: 258px;
  }
`;
export const CheckListWrapper = styled.div<Props>`
  z-index: 100px;
  display: block;
  margin-top: 16px;
  .specialty-dropdown {
    background-color: ${colors.PURPLE} !important;
  }
  .filter-input_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: ${(props: any) => props.width};
    height: 32px;
    border-radius: ${(props: any) => (props.visible ? '4px 4px 0 0' : '4px')};
    border: solid 1px ${(props: any) => (props.visible ? '#6f4bf1' : '#c9cfd3')};
    background-color: #fff;
    overflow: hidden;
    cursor: auto;
    .filter_input {
      padding-left: 22px;
      background-color: transparent !important;
      box-shadow: none !important;
      border: none;
      width: 90%;
      height: 100%;
      input {
        border: none;
        text-overflow: ellipsis;
        width: 100%;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
        color: #415766;
        &::placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #415766;
        }
      }
    }

    .filter_input_waitlist {
      padding-left: 2px;
      background-color: transparent !important;
      box-shadow: none !important;
      border: none;
      width: 90%;
      height: 100%;
      input {
        border: none;
        text-overflow: ellipsis;
        width: 100%;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
        color: #415766;
        &::placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #415766;
        }
      }
    }

    div.specialties-selected {
      background-color: ${colors.PURPLE} !important;
      opacity: 1 !important;
    }
    .specialty-icon {
      color: white !important;
    }
    .icon {
      color: #6f4bf1;
      font-size: 20px;
      cursor: pointer;
      margin-right: 14px;
    }
  }
  .list_wrapper {
    background: #fff;
    z-index: 90;
    width: ${(props: any) => props.width};
    border-top: none;
    overflow: hidden;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: ${(props: any) => (props.visible ? '#6f4bf1' : '#c9cfd3')};
    border-radius: 0 0 4px 4px;
    .scrollable_list {
      max-height: ${(props: any) => props.height};
      width: ${(props: any) => props.width};
      overflow-x: auto;
      overflow-y: overlay !important;
      border-radius: 0;
      &::-webkit-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
      &::-moz-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-moz-scrollbar-track {
        background-color: transparent;
      }
      &::-moz-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
      &::-ms-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-ms-scrollbar-track {
        background-color: transparent;
      }
      &::-ms-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
      .saved_filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px 0 10px;
        .saved-filters_checkbox {
          width: 100%;
          input {
            transition: all 0.5s ease-in-out;
          }
          label {
            font-size: 15px;
            line-height: 1.27;
            color: #415766;
            transition: all 0.5s ease-in-out;
            padding-left: 25px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 152px;
            &::before,
            &::after {
              width: 16px;
              height: 16px;
              border-color: #6f4bf1;
              transition: all 0.5s ease-in-out;
              border-radius: 2px;
              margin-top: 2px;
              margin-right: 10px;
            }
            &::after {
              padding-top: 2px;
              color: #fff;
              font-size: 11px;
              background-color: #6f4bf1;
            }
          }
        }
        .edit_btn {
          all: unset;
          font-size: 15px;
          color: #0d77b5;
          cursor: pointer;
          text-decoration: underline;
          white-space: nowrap;
        }
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #415766;
        padding: 10px 0 0 10px;
      }
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
      }
      .ant-checkbox-group-item {
        .ant-checkbox,
        .ant-checkbox-checked {
          &::after {
            transition: unset;
            animation: unset;
            border: none;
          }
          .ant-checkbox-inner {
            &::after {
              transition: unset;
              border-width: 3px;
            }
          }
        }
        &:hover {
          .ant-checkbox,
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              border-color: #6f4bf1;
            }
          }
        }
      }
      .ant-checkbox-wrapper {
        padding: 10px 10px 0 10px;
        font-size: 14px;
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border-color: #6f4bf1;
          &:hover {
            border-color: #6f4bf1;
          }
        }
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #6f4bf1;
            border-color: #6f4bf1;
          }
        }
      }
    }
    .calender-filters_actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      border-top: 1px solid ${colors.LIGHT_GREY};
      padding: 8px 10px 8px 10px;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      margin-top: 5px;
      span {
        text-decoration: underline;
        font-weight: 500;
        font-size: 14px;
        color: #21303a;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
      button {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 86px;
        height: 32px;
        border-radius: 8px;
        border: 2px solid #6f4bf1;
        font-size: 14px;
        font-weight: bold;
        color: #6f4bf1;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
        &:disabled {
          border: 2px solid #e7eaed;
          color: #919699;
          cursor: not-allowed;
        }
        &: hover {
          transform: scale(104%);
        }
      }
      .highlightButton {
        background: #8b67f6;
        color: white;
      }
    }
    .ant-divider {
      &.ant-divider-horizontal {
        margin: 10px 0 0 0;
      }
    }
    .no-results_filter {
      text-align: center;
      font-weight: bold;
      margin: 10px;
    }
    .css-0 {
      position: absolute;
      display: flex;
      justify-content: center;
      top: 10px;
      left: 0;
      right: 0;
    }
  }
  .filter_list {
    position: absolute;
    top: 100%;
    left: 0;
    zindex: 100;
    width: 100%;
  }

  .waitList_input_wrapper {
    border-radius: 8px;
    .filter_input {
      input {
        padding: 0px;
      }
    }
  }
`;
