import React from 'react';
import { LibUpload } from './upload.style';
import * as PropTypes from 'prop-types';

const Upload = ({ children, ...restProps }) => {
  return <LibUpload {...restProps}>{children}</LibUpload>;
};

Upload.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Upload.defaultProps = {
  children: null
};

export default Upload;
export { Upload };
