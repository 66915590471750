import hexRgb from 'hex-rgb';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { RGBColor } from 'react-color';
import { Control, useWatch } from 'react-hook-form';
import { TableType } from 'model/tableType';
import { DEFAULT_COLOR } from 'utils/constants/default';

const mapToHex = (hexObj: hexRgb.RgbaObject): RGBColor => {
  return {
    r: hexObj?.red,
    g: hexObj?.green,
    b: hexObj?.blue,
    a: hexObj?.alpha
  };
};

export const useColorHook = (
  name: string,
  control: Control<TableType>
): RGBColor => {
  const colorWatch: any = useWatch({ control, name });
  const [color, setColor] = useState<RGBColor>(mapToHex(hexRgb(DEFAULT_COLOR)));

  useEffect(() => {
    try {
      const colorHexValue: hexRgb.RgbaObject = !isEmpty(colorWatch)
        ? hexRgb(colorWatch)
        : hexRgb(DEFAULT_COLOR);

      setColor(mapToHex(colorHexValue));
    } catch (e) {
      console.log('Error happened: ', e);
    }
  }, [colorWatch, name]);
  return color;
};
