import { EyeOutlined } from '@ant-design/icons';
interface Props {
  observerCount: number;
  observerName: String;
}

const ImpactedObserversRow = ({ observerCount, observerName }: Props) => {
  return (
    <div className="attendees">
      <p className="attende-name">
        <div>
          <img className="avatar"></img>
          {observerName}
        </div>
      </p>
      <p className="attende-name">
        <div>
          <button className={`observer-btn observer${observerCount}-btn`}>
            <EyeOutlined /> Observer {observerCount}
          </button>
        </div>
      </p>
    </div>
  );
};

export default ImpactedObserversRow;
