import { ITableColumn } from 'views/components/table/type';
import { AppointmentType, IAppointmentType } from 'model/appointment';
import { TableType } from 'model/tableType';
import { sortNumerical } from 'utils/sort';
export const APPOINTMENT_COLUMNS: ITableColumn[] = [
  {
    label: 'Title',
    key: AppointmentType.title,
    notWrap: true,
    comp: {
      sort: (a: TableType, b: TableType) => sort(a, b, AppointmentType.title),
      reverse: (a: TableType, b: TableType) => sort(b, a, AppointmentType.title)
    }
  },
  { label: 'Header Color', key: AppointmentType.headerColor },
  { label: 'Background Color', key: AppointmentType.backgroundColor },
  {
    label: 'ID',
    key: AppointmentType.id,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sortNumerical(a[AppointmentType.id]!, b[AppointmentType.id]!),
      reverse: (a: TableType, b: TableType) =>
        sortNumerical(b[AppointmentType.id]!, a[AppointmentType.id]!)
    }
  },
  { label: 'Is Evaluation?', key: AppointmentType.isEvaluation },
  { label: 'Status', key: AppointmentType.ActiveStatus },
  { label: 'Slot Types', key: AppointmentType.dayAvailabilityTypes },
  {
    label: 'Salesforce Title',
    key: AppointmentType.salesForceMapping
  },
  { label: 'Paycode', key: AppointmentType.paycodes }
];
const sort = (a: TableType, b: TableType, sortField: AppointmentType) =>
  (b as IAppointmentType)[sortField]!.toString().toLowerCase() >
  (a as IAppointmentType)[sortField]!.toString().toLowerCase()!
    ? 1
    : -1;
