import { Control, Controller, useWatch } from 'react-hook-form';
import { memo } from 'react';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { CLINIC_TYPES } from 'utils/constants/lists';
import { IClinic } from 'model/v2';

interface IProps {
  control: Control;
  clinicId?: number;
  clinics: IClinic[];
  editMode: boolean;
  clinicTypeSelected: string | undefined;
}

function ClinicType({
  control,
  clinicId,
  clinics,
  editMode,
  clinicTypeSelected
}: IProps) {
  const clinicType = useWatch({
    control,
    name: 'clinicType'
  });

  return (
    <div className="row">
      <div className="form-select">
        <FormItem label="Clinic Type">
          <Controller
            control={control}
            name={'clinicType'}
            defaultValue={editMode ? clinicTypeSelected : CLINIC_TYPES[2].id}
            render={fieldProps => (
              <Select
                options={CLINIC_TYPES.map(x => ({
                  value: x.id,
                  label: x.label
                }))}
                defaultValue={
                  editMode ? clinicTypeSelected : CLINIC_TYPES[2].id
                }
                placeholder=""
                field={fieldProps}
                errors={undefined}
              />
            )}
          />
        </FormItem>
      </div>
      {clinicType === CLINIC_TYPES[1]?.id && (
        <div className="form-select">
          <FormItem label="Hub">
            <Controller
              control={control}
              name={'clinicHub.id'}
              render={fieldProps => (
                <Select
                  options={clinics
                    .filter(
                      cl =>
                        cl.clinicType === CLINIC_TYPES[0].id &&
                        cl.id !== clinicId
                    )
                    .map(cl => ({
                      value: cl.id,
                      label: `${cl.name} (${cl.abbreviation})`
                    }))}
                  placeholder=""
                  field={fieldProps}
                  errors={undefined}
                />
              )}
            />
          </FormItem>
        </div>
      )}
    </div>
  );
}

export default memo(ClinicType);
