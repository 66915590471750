import React, { useCallback, useRef, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { updateCalendarPref } from 'utils/cache/calendar';
import moment from 'moment';
import Content from 'views/components/ui/content';
import { ADD_EVENT, ADD_EVENTS } from 'api/graphql/v2/mutations/Event';
interface Props {
  setVisible: (val: boolean) => void;
  setSaving: (val: boolean) => void;
  mappedEvents: any[];
  saving: boolean;
  hasSingleDT: any;
}

const SubmitFooter: React.FC<Props> = ({
  setVisible,
  setSaving,
  mappedEvents,
  saving,
  hasSingleDT
}) => {
  const ref = useRef(0);
  let addEvents: any;
  if (!hasSingleDT) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [addEvents] = useMutation(ADD_EVENTS);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [addEvents] = useMutation(ADD_EVENT);
  }
  const successCallbackBehavior = useCallback(() => {
    setVisible(false);
    updateCalendarPref(moment(mappedEvents[0].startDate));
    setSaving(false);
  }, [setSaving, setVisible, mappedEvents]);

  const errorCallbackBehavior = useCallback(() => {
    setVisible(false);
    setSaving(false);
  }, [setSaving, setVisible]);

  const sendRequest = useCallback(async () => {
    let request;
    if (!hasSingleDT) {
      request = addEvents({
        variables: { events: mappedEvents }
      });
      await request
        .then(() => {
          successCallbackBehavior();
        })
        .catch(() => {
          errorCallbackBehavior();
        });
    } else {
      const request = addEvents({
        variables: { event: mappedEvents[0] }
      });
      await request
        .then(() => {
          successCallbackBehavior();
        })
        .catch(() => {
          errorCallbackBehavior();
        });
    }
  }, [mappedEvents, errorCallbackBehavior, successCallbackBehavior, addEvents]);

  useEffect(() => {
    if (ref.current === 0) {
      ref.current += 1;
      sendRequest();
    }
  }, [sendRequest]);
  return (
    <>
      <Content loading={saving} data={true} />
    </>
  );
};
export default React.memo(SubmitFooter);
