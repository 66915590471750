import { Select } from 'api/sharedComponents/reactHookFormComponents';
import React, { useMemo } from 'react';
import { ABAWrapper } from 'pages/MainCalendarPage/AppointmentForm/style';

interface props {
  max: number;
  name: string;
  field: any;
  title?: string;
  errors: any;
  onSelect?: any;
  className?: string;
}
const IndexedLabelSelectorNum = ({
  max,
  name,
  field,
  errors,
  onSelect,
  className,
  title
}: props) => {
  const generateOptions = useMemo(() => {
    let options = [];
    for (let i = 1; i <= max; ++i) {
      options.push({
        label: title !== undefined ? `${i} ${title}` : `${i}`,
        value: i
      });
    }
    return options;
  }, [max]);
  return (
    <ABAWrapper>
      <Select
        showSearch
        name={name}
        options={generateOptions}
        field={field}
        errors={errors}
        onSelect={onSelect}
        className={className}
      />
    </ABAWrapper>
  );
};

export default React.memo(IndexedLabelSelectorNum);
