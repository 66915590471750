import {
  getEventDayIndex,
  getPlannerWeekTime,
  getView
} from 'helpers/calendarHelper';
import moment, { Moment } from 'moment';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';
import { EventType, IPhantomEvent } from 'model/calendar/events';
import { calendarGroup } from 'model/calendar/groups';
import { customizeRange } from 'utils/builders/calendar';
import { CalendarView, views } from 'utils/cache/calendar';
import {
  EVENT_CATEGORY,
  EVENT_OCCURENCE,
  SCHEDULE_TYPE_LIST
} from 'utils/constants/lists';
import { EVENT_STATUS } from 'utils/constants/status';
import {
  IProvider,
  IFormEvent,
  IFullAppointmentType,
  IClient,
  ISmartResultItemForm,
  IEvent,
  SingleSmartResultInterface
} from 'model/v2';
import {
  APPOINTMENT_BACKGROUND_COLORS,
  APPOINTMENT_HEADER_COLORS
} from 'utils/constants/theme';
import { NO_PREFERENCE_OPTION } from 'utils/constants/default';
import {
  prepareSmartEvalEventForSubmission,
  prepareSmartEventForSubmission,
  getOrganisedSmartEvent,
  prepareSmartDriveTimeEventForSubmission
} from '../request/V2/event';
import {
  ABA_EXTENSION_SUBSTITUTE_APPT_IDS,
  SUBSTITUTE_APPT_IDS
} from 'utils/constants/appointmentsTypes';

const getInitPhantomEvents = (isAdminEvent: boolean) => {
  let phantomEvents = [];
  const phantomEvent = {
    type: isAdminEvent
      ? EventType.phantomAdminEvent
      : EventType.phantomClientEvent,
    status: EVENT_STATUS.scheduled
  };

  const clientPhantomEvent = {
    ...phantomEvent,
    groupType: calendarGroup.client
  };

  const providerPhantomEvent = {
    ...phantomEvent,
    groupType: calendarGroup.provider
  };
  if (!isAdminEvent) {
    phantomEvents = [clientPhantomEvent, providerPhantomEvent];
  } else {
    phantomEvents = [providerPhantomEvent];
  }
  return phantomEvents;
};

export const getEventPlannerWeekTime = (
  calendarDate: Date,
  eventStartDate: Moment,
  eventEndDate: Moment
) => {
  const calendarDateWeek = moment(customizeRange(calendarDate, 'week').start);
  const eventDateWeek = moment(
    customizeRange(eventStartDate.toDate(), 'week').start
  );
  const isNotSameWeek = eventDateWeek.isAfter(calendarDateWeek);
  const plannerStartDate = isNotSameWeek
    ? eventStartDate.toDate()
    : calendarDate;
  const plannerEndDate = isNotSameWeek ? eventEndDate.toDate() : calendarDate;

  const plannerWeekStartTime = getPlannerWeekTime(
    eventStartDate.toDate(),
    plannerStartDate
  );

  const plannerWeekEndTime = getPlannerWeekTime(
    eventEndDate.toDate(),
    plannerEndDate
  );

  return { plannerWeekStartTime, plannerWeekEndTime };
};

export const mapObservationToPhantomEvent = (
  event: IEvent,
  observer: number | undefined,
  view: CalendarView,
  calendarDate: Date,
  appTypesMap: Map<number, IFullAppointmentType>,
  providerMap: Map<number, IProvider>,
  observersLength: number,
  observerEvent: IEvent,
  observerEdit: boolean
) => {
  let eventStartDate = moment(event.startDate);
  let eventEndDate = moment(event.endDate);
  if (
    observerEvent.startDate !== undefined &&
    observerEvent.endDate !== undefined
  ) {
    if (observerEdit) {
      eventStartDate = moment(
        moment(observerEvent.startDate).format('YYYY-MM-DD') +
          ' ' +
          moment(observerEvent.startDate).format('HH:mm:ss')
      );
      eventEndDate = moment(
        moment(observerEvent.endDate).format('YYYY-MM-DD') +
          ' ' +
          moment(observerEvent.endDate).format('HH:mm:ss')
      );
    } else {
      eventStartDate = moment(
        moment(event.startDate).format('YYYY-MM-DD') +
          ' ' +
          moment(observerEvent.startDate).format('HH:mm:ss')
      );
      eventEndDate = moment(
        moment(event.endDate).format('YYYY-MM-DD') +
          ' ' +
          moment(observerEvent.endDate).format('HH:mm:ss')
      );
    }
  }
  const { plannerWeekStartTime, plannerWeekEndTime } = getEventPlannerWeekTime(
    calendarDate,
    eventStartDate,
    eventEndDate
  );
  const eventAppointmentType = event.appointmentType?.parent
    ? event.appointmentType.parent
    : event.appointmentType;
  const appointmentType = appTypesMap.get(eventAppointmentType?.id!)!;

  const providerData = providerMap.get(observer!)!;
  const provider =
    providerData?.status !== 'Inactive' ? providerData : undefined;
  const leadProvider = providerMap.get(event.provider?.id!)!;
  const { providerResourceID } = getEventResourceIDs(
    event.client,
    provider,
    eventStartDate,
    view
  );
  const observationPhantomEvent: IPhantomEvent = {
    type: EventType.phantomObserverEvent,
    status: EVENT_STATUS.scheduled,
    groupType: calendarGroup.provider,
    appointmentType: {
      headerColor:
        appointmentType?.headerColor || APPOINTMENT_HEADER_COLORS.CLIENT,
      backgroundColor:
        appointmentType?.backgroundColor ||
        APPOINTMENT_BACKGROUND_COLORS.CLIENT,
      title: appointmentType?.title
    },
    startTime: eventStartDate,
    endTime: eventEndDate,
    plannerWeekStartTime,
    plannerWeekEndTime,
    start: eventStartDate?.toDate(),
    end: eventEndDate?.toDate(),
    locationType: event.locationType,
    clinic: event.clinic,
    resourceId: providerResourceID,
    baseId: provider?.id?.toString(),
    title: leadProvider?.name,
    recurrencePattern: {
      recurringEvery: 0
    },
    observationsCount: observersLength
  };
  return observationPhantomEvent;
};
export const mapSmartEventToPhantomEvent = (
  data: ISmartResultItemForm,
  view: CalendarView,
  calendarDate: Date,
  client: IClient,
  appTypesMap: Map<number, IFullAppointmentType>,
  providerMap: Map<number, IProvider>,
  hasSingleDT: any,
  adminTypesMap?: Map<number, IFullAppointmentType>
) => {
  let mappedEvent: any;
  if (hasSingleDT) {
    mappedEvent = prepareSmartEvalEventForSubmission(data);
  } else {
    mappedEvent = prepareSmartEventForSubmission(data);
  }
  const events = getInitPhantomEvents(false);
  const eventStartDate = moment(mappedEvent.startDate);
  const eventEndDate = moment(mappedEvent.endDate);
  const { plannerWeekStartTime, plannerWeekEndTime } = getEventPlannerWeekTime(
    calendarDate,
    eventStartDate,
    eventEndDate
  );
  const appointmentType = appTypesMap.get(mappedEvent.appointmentType.id!)!;
  const provider = providerMap.get(mappedEvent.provider.id!)!;
  const phantomEvents: IPhantomEvent[] = [];
  const clinic = data.clinic;

  events.forEach(event => {
    const { clientResourceID, providerResourceID } = getEventResourceIDs(
      client,
      provider,
      eventStartDate,
      view
    );

    const phantomEvent: IPhantomEvent = {
      ...event,
      appointmentType: {
        headerColor:
          appointmentType?.headerColor || APPOINTMENT_HEADER_COLORS.CLIENT,
        backgroundColor:
          appointmentType?.backgroundColor ||
          APPOINTMENT_BACKGROUND_COLORS.CLIENT,
        title: appointmentType?.title
      },
      startTime: eventStartDate,
      endTime: eventEndDate,
      plannerWeekStartTime,
      plannerWeekEndTime,
      start: eventStartDate?.toDate(),
      end: eventEndDate?.toDate(),
      recurrencePattern: mappedEvent.recurrencePattern,
      status: EVENT_STATUS.scheduled,
      locationType: mappedEvent.locationType,
      clinic
    };

    if (event.groupType === calendarGroup.provider) {
      phantomEvents.push({
        ...phantomEvent,
        resourceId: providerResourceID,
        baseId: provider.id?.toString(),
        title: client.name
      });
      if (data.driveTimeCheck && data?.driveTime! > 0) {
        const mappedAdminEvent = prepareSmartDriveTimeEventForSubmission(data);
        const driveStartTime = moment(mappedAdminEvent.startDate);
        const driveEndTime = moment(mappedAdminEvent.endDate);
        const appointmentTypeNew = adminTypesMap?.get(
          mappedAdminEvent.appointmentType.id!
        )!;
        const {
          plannerWeekStartTime,
          plannerWeekEndTime
        } = getEventPlannerWeekTime(calendarDate, driveStartTime, driveEndTime);
        const phantomEvent: IPhantomEvent = {
          ...event,
          type: EventType.phantomAdminEvent,
          appointmentType: {
            headerColor:
              appointmentTypeNew?.headerColor ||
              APPOINTMENT_HEADER_COLORS.ADMIN,
            backgroundColor:
              appointmentTypeNew?.backgroundColor ||
              APPOINTMENT_BACKGROUND_COLORS.ADMIN,
            title: appointmentTypeNew?.title
          },
          startTime: driveStartTime,
          endTime: driveEndTime,
          plannerWeekStartTime,
          plannerWeekEndTime,
          start: driveStartTime?.toDate(),
          end: driveEndTime?.toDate(),
          recurrencePattern: mappedAdminEvent.recurrencePattern,
          status: EVENT_STATUS.scheduled,
          clinic
        };
        phantomEvents.push({
          ...phantomEvent,
          resourceId: providerResourceID,
          baseId: provider.id?.toString(),
          title: ''
        });
      }
    } else {
      phantomEvents.push({
        ...phantomEvent,
        title: provider.name,
        resourceId: clientResourceID,
        baseId: client.id?.toString()
      });
    }
  });

  const recurringPhantomEvents = generateRecurringSeries(
    phantomEvents,
    mappedEvent.recurrencePattern.recurringEvery.toString(),
    mappedEvent.recurrencePattern.recurringUntil
  );
  return recurringPhantomEvents;
};

export const mapSingleSmartPhantomEvent = (
  data: SingleSmartResultInterface,
  view: CalendarView,
  calendarDate: Date,
  client: IClient,
  appTypesMap: Map<number, IFullAppointmentType>,
  providerMap: Map<number, IProvider>,
  appointmentTypeId: number,
  appointmentSubTypeId?: number
) => {
  let mappedEvent = getOrganisedSmartEvent(data, appointmentTypeId);
  const events = getInitPhantomEvents(false);
  const eventStartDate = moment(mappedEvent.startDate);
  const eventEndDate = moment(mappedEvent.endDate);

  const { plannerWeekStartTime, plannerWeekEndTime } = getEventPlannerWeekTime(
    calendarDate,
    eventStartDate,
    eventEndDate
  );

  const appointmentType = appTypesMap.get(mappedEvent.appointmentType.id!)!;
  let filteredApptSubTypeList: IFullAppointmentType[] = [];
  appointmentType.appointmentSubTypes?.map(subtype => {
    if (subtype.id === appointmentSubTypeId) {
      filteredApptSubTypeList.push(subtype);
    }
    return;
  });

  const appointmentSubType = filteredApptSubTypeList?.length
    ? filteredApptSubTypeList[0]
    : undefined;
  const provider = providerMap.get(mappedEvent.provider.id!)!;
  const phantomEvents: IPhantomEvent[] = [];
  const clinic = data.clinic;

  events.forEach(event => {
    const { clientResourceID, providerResourceID } = getEventResourceIDs(
      client,
      provider,
      eventStartDate,
      view
    );
    let apptTitle = appointmentType?.title;
    if (appointmentSubType?.title)
      apptTitle =
        appointmentType?.title + '(' + appointmentSubType?.title + ')';
    const phantomEvent: IPhantomEvent = {
      ...event,
      appointmentType: {
        headerColor:
          appointmentType?.headerColor || APPOINTMENT_HEADER_COLORS.CLIENT,
        backgroundColor:
          appointmentType?.backgroundColor ||
          APPOINTMENT_BACKGROUND_COLORS.CLIENT,
        title: apptTitle
      },
      startTime: eventStartDate,
      endTime: eventEndDate,
      plannerWeekStartTime,
      plannerWeekEndTime,
      start: eventStartDate?.toDate(),
      end: eventEndDate?.toDate(),
      recurrencePattern: mappedEvent.recurrencePattern,
      status: EVENT_STATUS.scheduled,
      locationType: mappedEvent.locationType,
      clinic
    };

    if (event.groupType === calendarGroup.provider) {
      phantomEvents.push({
        ...phantomEvent,
        resourceId: providerResourceID,
        baseId: provider.id?.toString(),
        title: client.name
      });
    } else {
      phantomEvents.push({
        ...phantomEvent,
        title: provider.name,
        resourceId: clientResourceID,
        baseId: client.id?.toString()
      });
    }
  });

  const recurringPhantomEvents = generateRecurringSeries(
    phantomEvents,
    mappedEvent.recurrencePattern.recurringEvery.toString(),
    mappedEvent.recurrencePattern.recurringUntil
  );
  return recurringPhantomEvents;
};

export const fullPhantomEventMapper = (
  values: IFormEvent,
  calendarDate: Date,
  view: CalendarView,
  apptTypes: Map<number, IFullAppointmentType>,
  provider: IProvider | undefined,
  formAction: string
) => {
  let mappedEvents: IPhantomEvent[] = [];
  if (!values.selectedDay || !values.startDate || !values.endDate) return [];
  const isAdminEvent = values.type === EVENT_CATEGORY[1].value;
  const apptType = values?.appType ? apptTypes.get(values?.appType) : undefined;

  const events = getInitPhantomEvents(isAdminEvent);

  const { eventStartDate, eventEndDate } = getEventDates(values);

  const { plannerWeekStartTime, plannerWeekEndTime } = getEventPlannerWeekTime(
    calendarDate,
    eventStartDate,
    eventEndDate
  );

  if (values.provider?.id === NO_PREFERENCE_OPTION.id) {
    provider = values?.selectedOpening?.provider;
  }

  events.forEach(event => {
    const { clientResourceID, providerResourceID } = getEventResourceIDs(
      values?.client,
      provider,
      eventStartDate,
      view
    );

    const phantomEvent: IPhantomEvent = {
      ...event,
      appointmentType: apptType
        ? {
            headerColor: isAdminEvent
              ? APPOINTMENT_HEADER_COLORS.ADMIN
              : apptType?.headerColor || APPOINTMENT_HEADER_COLORS.CLIENT,
            backgroundColor: isAdminEvent
              ? APPOINTMENT_BACKGROUND_COLORS.ADMIN
              : apptType?.backgroundColor ||
                APPOINTMENT_BACKGROUND_COLORS.CLIENT,
            title: apptType?.title
          }
        : {
            headerColor: isAdminEvent
              ? APPOINTMENT_HEADER_COLORS.ADMIN
              : APPOINTMENT_HEADER_COLORS.CLIENT,
            backgroundColor: isAdminEvent
              ? APPOINTMENT_BACKGROUND_COLORS.ADMIN
              : APPOINTMENT_BACKGROUND_COLORS.CLIENT
          },

      startTime: eventStartDate,
      endTime: eventEndDate,

      plannerWeekStartTime,
      plannerWeekEndTime,

      start: eventStartDate?.toDate(),
      end: eventEndDate?.toDate(),

      recurrencePattern: {
        recurringEvery:
          values.scheduleType === SCHEDULE_TYPE_LIST[0].id
            ? 0
            : values.recurrencePattern?.recurringEvery || 1,
        recurringUntil: values.recurrencePattern?.recurringUntil
      },

      status: EVENT_STATUS.scheduled,
      locationType: values.locationType,
      clinic: values.clinic
    };

    if (event.groupType === calendarGroup.provider) {
      mappedEvents.push({
        ...phantomEvent,
        resourceId: providerResourceID,
        baseId: provider?.id?.toString(),
        title: values.client?.name
      });
    } else {
      mappedEvents.push({
        ...phantomEvent,
        title: provider?.name,
        resourceId: clientResourceID,
        baseId: values.client?.id?.toString()
      });
    }
  });
  mappedEvents = getRecurringEvents(
    mappedEvents,
    values,
    calendarDate,
    view,
    formAction
  );

  return mappedEvents;
};

const getEventResourceIDs = (
  client: IClient | undefined,
  provider: IProvider | undefined,
  startTime: Moment,
  view: CalendarView
) => {
  let providerResourceID = provider?.id?.toString();
  let clientResourceID = client?.id?.toString();
  if (view === views.PLANNER_WEEK) {
    const eventDay = startTime
      ? getEventDayIndex(startTime?.toDate())
      : undefined;
    providerResourceID = `${providerResourceID}:${eventDay}`;
    clientResourceID = `${clientResourceID}:${eventDay}`;
  }
  return { clientResourceID, providerResourceID };
};

//should be IFormEvent V2
const getEventDates = (values: IFormEvent) => {
  const clonedSelectedDay = values?.selectedDay?.clone();
  const eventStartDate = values?.startDate
    ? moment(clonedSelectedDay)
        .set('hours', moment(values.startDate).hours())
        .set('minutes', moment(values.startDate).minutes())
    : moment(clonedSelectedDay);

  const eventEndDate = values?.endDate
    ? moment(clonedSelectedDay)
        .set('hours', moment(values?.endDate)?.hours())
        .set('minutes', moment(values?.endDate)?.minutes())
    : moment(clonedSelectedDay);

  return { eventStartDate, eventEndDate };
};

const getRecurringEvents = (
  mappedEvents: IPhantomEvent[],
  values: IFormEvent,
  date: Date,
  view: CalendarView,
  formAction: string
) => {
  const isRecurring = values.scheduleType === SCHEDULE_TYPE_LIST[1].id;
  const isCustom =
    values.recurrencePattern?.recurringEvery === EVENT_OCCURENCE[4].value;
  const recurringEveryValue = parseInt(
    isRecurring && values.recurrencePattern?.recurringEvery
      ? values.recurrencePattern?.recurringEvery === EVENT_OCCURENCE[4].value
        ? '1'
        : values.recurrencePattern?.recurringEvery?.toString()
      : '0'
  );

  const shouldGenerateCustom =
    isCustom && values.recurrencePattern?.recurringDaysArray && isRecurring;
  const shouldGenerateRecurring =
    !isCustom && recurringEveryValue > 0 && isRecurring;

  if (
    shouldGenerateRecurring ||
    (shouldGenerateCustom && formAction === SIDEBAR_ACTIONS.EDIT)
  ) {
    mappedEvents = generateRecurringSeries(
      mappedEvents,
      isCustom ? '1' : values.recurrencePattern?.recurringEvery?.toString(),
      values.recurrencePattern?.recurringUntil
    );
  } else if (shouldGenerateCustom) {
    mappedEvents = generateCustomEvents(
      mappedEvents,
      values.recurrencePattern?.recurringDaysArray || [],
      values.recurrencePattern?.recurringUntil,
      date,
      view,
      values
    );
  } else if (isCustom && !values.recurrencePattern?.recurringDaysArray) {
    return [];
  }
  return mappedEvents;
};
const generateRecurringSeries = (
  events: IPhantomEvent[],
  recurringEvery: string | undefined,
  recurringUntil: Moment | Date | undefined
) => {
  const mappedEvents: IPhantomEvent[] = [];

  events.forEach(event => {
    let startDate = moment(event.startTime).add(recurringEvery, 'weeks');
    let endDate = moment(event.endTime).add(recurringEvery, 'weeks');

    mappedEvents.push(event);
    while (startDate < moment(recurringUntil)) {
      mappedEvents.push({
        ...event,
        startTime: startDate,
        endTime: endDate,
        start: startDate?.toDate(),
        end: endDate?.toDate(),
        plannerWeekStartTime: getPlannerWeekTime(
          startDate.toDate(),
          customizeRange(startDate.toDate(), 'week').start
        ),
        plannerWeekEndTime: getPlannerWeekTime(
          endDate.toDate(),
          customizeRange(endDate.toDate(), 'week').start
        )
      });
      startDate = moment(startDate).add(recurringEvery, 'weeks');
      endDate = moment(endDate).add(recurringEvery, 'weeks');
    }
  });
  return mappedEvents;
};

const generateCustomEvents = (
  events: IPhantomEvent[],
  recurringDays: number[],
  recurringUntil: Moment | Date | undefined,
  calendarDate: Date,
  view: CalendarView,
  values: any //should be IFormEvent V2
) => {
  let mappedEvents: IPhantomEvent[] = [];
  if (recurringDays) {
    events.forEach(event => {
      recurringDays.forEach(day => {
        const { eventStartDate, eventEndDate } = getEventDates(values);
        let nextStart = calculateCustomMasterDate(moment(eventStartDate), day);
        let nextEnd = calculateCustomMasterDate(moment(eventEndDate), day);

        const { clientResourceID, providerResourceID } = getEventResourceIDs(
          values?.client,
          values?.provider,
          nextStart,
          view
        );
        let masterEvent = {
          ...event,
          startTime: nextStart,
          endTime: nextEnd,
          start: nextStart?.toDate(),
          end: nextEnd?.toDate(),
          resourceId:
            event?.groupType === calendarGroup.client
              ? clientResourceID
              : providerResourceID
        };

        //Hide event if master custom date not in this week
        if (!isEventInRange(view, calendarDate, nextStart)) {
          delete masterEvent.plannerWeekEndTime;
          delete masterEvent.plannerWeekStartTime;
        }

        mappedEvents.push(masterEvent);
      });
    });
    mappedEvents = generateRecurringSeries(mappedEvents, '1', recurringUntil);
    return mappedEvents;
  } else {
    return [];
  }
};

const isEventInRange = (view: CalendarView, date: Date, startTime: Moment) => {
  const range = customizeRange(date, getView(view));
  return (
    startTime?.isSameOrAfter(range.start) && startTime.isSameOrBefore(range.end)
  );
};

const calculateCustomMasterDate = (currentDate: Moment, day: number) => {
  const clonedCurrentDate = currentDate.clone();
  const dayDate =
    clonedCurrentDate.day() <= day
      ? clonedCurrentDate
      : clonedCurrentDate.add(1, 'weeks');
  return moment(dayDate.day(day));
};

//adjust mapping on view switch for phantom events in range
export const adjustPhantomEventsMapping = (
  events: IPhantomEvent[],
  view: CalendarView,
  date: Date
) => {
  const mappedEvents: IPhantomEvent[] = [];

  events.forEach(event => {
    let resourceID = event.baseId;
    let plannerWeekStartTime = getPlannerWeekTime(
      event.startTime?.toDate() || new Date(),
      date
    );
    let plannerWeekEndTime = getPlannerWeekTime(
      moment(event.endTime).toDate(),
      date
    );
    if (view === views.PLANNER_WEEK) {
      const eventDay = getEventDayIndex(
        event.startTime?.toDate() || new Date()
      );
      resourceID = `${resourceID}:${eventDay}`;
    }

    let mappedEvent = {
      ...event,
      resourceId: resourceID
    };
    const range = customizeRange(date, getView(view));

    //check if event is in this week to compute the planner week time
    if (
      event?.startTime?.isSameOrAfter(range.start) &&
      event?.endTime?.isSameOrBefore(range.end)
    ) {
      mappedEvent = {
        ...mappedEvent,
        plannerWeekEndTime,
        plannerWeekStartTime
      };
    }
    mappedEvents.push(mappedEvent);
  });
  return mappedEvents;
};

export const mapSubstituteEventToPhantomEvent = (
  mainEvent: any,
  provider: IProvider,
  appTypesMap: Map<number, IFullAppointmentType>,
  view: CalendarView,
  calendarDate: Date,
  adjacentEvent?: IEvent,
  isDirectSubstitute?: boolean
) => {
  let mappedEvent = mainEvent;
  let startDate = mappedEvent.startDate;
  let endDate = mappedEvent.endDate;
  if (adjacentEvent) {
    if (startDate === adjacentEvent.endDate)
      startDate = adjacentEvent.startDate;
    if (endDate === adjacentEvent.startDate) endDate = adjacentEvent.endDate;
  }
  let client = mainEvent.client;
  const events = getInitPhantomEvents(false);
  const eventStartDate = moment(startDate);
  const eventEndDate = moment(endDate);

  const { plannerWeekStartTime, plannerWeekEndTime } = getEventPlannerWeekTime(
    calendarDate,
    eventStartDate,
    eventEndDate
  );
  const appointmentTypeId =
    mappedEvent?.appointmentType?.parent?.id ||
    mappedEvent?.appointmentType?.id;
  let apptId = SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!][
    appointmentTypeId!
  ]
    ? SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!][appointmentTypeId!]
    : appointmentTypeId!;
  if (adjacentEvent) {
    apptId = adjacentEvent.appointmentType?.id;
  } else if (!isDirectSubstitute) {
    apptId = ABA_EXTENSION_SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!];
  }
  const appointmentType = appTypesMap.get(apptId)!;
  const appointmentSubTypeTitle = mappedEvent?.appointmentType?.parent?.id
    ? mappedEvent?.appointmentType?.title
    : undefined;
  let filteredApptSubTypeList: IFullAppointmentType[] = [];
  appointmentType.appointmentSubTypes?.map(subtype => {
    if (subtype.title === appointmentSubTypeTitle) {
      filteredApptSubTypeList.push(subtype);
    }
    return;
  });

  // const appointmentSubType = filteredApptSubTypeList?.length
  //   ? filteredApptSubTypeList[0]
  //   : undefined;
  // const appointmentTypeTitle = appointmentSubType?.title
  //   ? appointmentType?.title + '(' + appointmentSubType?.title + ')'
  //   : appointmentType?.title;
  const appointmentTypeTitle = appointmentType?.title;
  const phantomEvents: IPhantomEvent[] = [];
  const clinic = mainEvent.clinic;
  events.forEach(event => {
    const { clientResourceID, providerResourceID } = getEventResourceIDs(
      client,
      provider,
      eventStartDate,
      view
    );

    const phantomEvent: IPhantomEvent = {
      ...event,
      appointmentType: {
        headerColor:
          appointmentType?.headerColor || APPOINTMENT_HEADER_COLORS.CLIENT,
        backgroundColor:
          appointmentType?.backgroundColor ||
          APPOINTMENT_BACKGROUND_COLORS.CLIENT,
        title: appointmentTypeTitle
      },
      startTime: eventStartDate,
      endTime: eventEndDate,
      plannerWeekStartTime,
      plannerWeekEndTime,
      start: eventStartDate?.toDate(),
      end: eventEndDate?.toDate(),
      recurrencePattern: {
        recurringEvery: 0
      },
      status: EVENT_STATUS.scheduled,
      locationType: adjacentEvent?.locationType
        ? adjacentEvent?.locationType
        : mappedEvent.locationType,
      clinic
    };

    if (event.groupType === calendarGroup.provider) {
      phantomEvents.push({
        ...phantomEvent,
        resourceId: providerResourceID,
        baseId: provider.id?.toString(),
        title: client.name
      });
    } else {
      phantomEvents.push({
        ...phantomEvent,
        title: provider.name,
        resourceId: clientResourceID,
        baseId: client.id?.toString()
      });
    }
  });
  return phantomEvents;
};
