import { useReactiveVar } from '@apollo/client';
import { notPhantomEvent } from 'helpers/calendarHelper';
import { getCancelFilterStatus } from 'helpers/filterHekper';
import { ICalendarEvent, IPhantomEvent } from 'model/calendar/events';
import { ICalendarFilter, ICalendarResource } from 'model/calendar/filters';
import { IFullAppointmentType } from 'model/v2';
import { useCallback, useMemo } from 'react';
import { CalendarView } from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  filteredProvidersResourcesPrevVar,
  providerResourcesPrevVar,
  searchedClientsPrevVar,
  searchedProvidersPrefVar
} from 'utils/cache/filters';
import { internalFilters } from 'utils/constants/calendarFilters';
import {
  getClientEventsVariables,
  getSearchedStaffEventsVariables
} from 'utils/mappers/request/events';
import { IProvider } from '../../../model/v2';
import { MomentInput } from 'moment';

export const useAddPhantomEvents = () => {
  const addPhantomEvents = (
    currentEvents: ICalendarEvent[],
    phantomEvents: IPhantomEvent[]
  ) => {
    const calendarEvents = currentEvents.filter(e =>
      notPhantomEvent(e as IPhantomEvent)
    );
    calendarEventsPrefVar([
      ...calendarEvents.concat((phantomEvents as unknown) as ICalendarEvent)
    ]);
  };

  return addPhantomEvents;
};

export const useApptTypesMap = (apptTypes: IFullAppointmentType[]) => {
  const appTypesMap = useMemo(() => {
    const appMap = new Map();
    apptTypes != undefined &&
      apptTypes.forEach(it => {
        appMap.set(it.id, it);
      });
    return appMap;
  }, [apptTypes]);
  console.log(
    '[getSubstituteMakeUpPayload] apptTypes here useApptTypesMap function',
    appTypesMap
  );
  return appTypesMap;
};

export const useAddClientToCalendar = (
  getClientEvents: any,
  filters: ICalendarFilter,
  calendarView: CalendarView,
  calendarDate: Date
) => {
  const searchedClients = useReactiveVar(searchedClientsPrevVar);
  const addClientToCalendar = useCallback(
    (client: ICalendarResource | undefined) => {
      if (client && !searchedClients.has(client?.id)) {
        searchedClientsPrevVar(searchedClients.set(client.id, client));
        getClientEvents(
          getClientEventsVariables(
            [client],
            calendarDate,
            calendarView,
            getCancelFilterStatus(filters.internals as internalFilters[])
          )
        );
      }
    },
    [
      calendarDate,
      calendarView,
      filters.internals,
      getClientEvents,
      searchedClients
    ]
  );
  return addClientToCalendar;
};

export const useAddProviderToCalendar = (
  getStaffEvents: any,
  filters: ICalendarFilter,
  calendarView: CalendarView,
  calendarDate: Date
) => {
  const searchedProviders = useReactiveVar(searchedProvidersPrefVar);

  const addProviderToCalendar = useCallback(
    (
      providers: ICalendarResource[],
      calendarSelDate?: MomentInput | undefined
    ) => {
      const newProviders = providers.filter(
        provider => !searchedProviders.get(provider.id)
      );
      if (newProviders && newProviders.length > 0) {
        newProviders.forEach(provider => {
          searchedProvidersPrefVar(
            provider.status !== 'Inactive'
              ? searchedProviders.set(provider.id, provider)
              : undefined
          );
        });
        let dateVal: MomentInput = calendarDate;
        if (calendarSelDate) dateVal = calendarSelDate;
        getStaffEvents(
          getSearchedStaffEventsVariables(
            newProviders,
            dateVal,
            calendarView,
            getCancelFilterStatus(filters.internals as internalFilters[])
          )
        );
      }
    },
    [
      calendarDate,
      calendarView,
      filters.internals,
      getStaffEvents,
      searchedProviders
    ]
  );

  return { addProviderToCalendar, searchedProviders };
};

export const useRemoveProviderFromCalendar = () => {
  const providers = useReactiveVar(providerResourcesPrevVar);
  const searchedProviders = useReactiveVar(searchedProvidersPrefVar);
  const events = useReactiveVar(calendarEventsPrefVar);
  const removeProviderFromCalendar = useCallback(
    (deletedProvider: ICalendarResource | IProvider) => {
      const groups = providers.filter(g => g?.id !== deletedProvider?.id);
      providerResourcesPrevVar(groups);
      if (deletedProvider.id) {
        searchedProviders.delete(deletedProvider.id);
      }
      searchedProvidersPrefVar(searchedProviders);
      const filteredEvents = events.filter(
        e => parseInt(e?.resourceId!) !== deletedProvider?.id
      );

      calendarEventsPrefVar(filteredEvents);
    },
    [events, providers, searchedProviders]
  );

  const removeAllProvidersFromCalendar = useCallback(() => {
    const remainingEvents = events.filter(
      e => !providers.some(provider => provider.id === parseInt(e.resourceId!))
    );
    calendarEventsPrefVar(remainingEvents);
    providerResourcesPrevVar([]);
    filteredProvidersResourcesPrevVar([]);
    searchedProvidersPrefVar(new Map());
  }, [events, providers]);
  return { removeProviderFromCalendar, removeAllProvidersFromCalendar };
};
