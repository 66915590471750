import React from 'react';
import RemoveIcon from 'assets/img/remove-btn.svg';

interface Props {
  selectedLocationsList: number[];
  allLocationsMap: Map<
    number,
    {
      name: string;
      id: number;
    }
  >;
  onClearSelectedList: () => void;
  onRemoveLocation: (id: number) => void;
}

const SelectedClinics = ({
  selectedLocationsList,
  allLocationsMap,
  onClearSelectedList,
  onRemoveLocation
}: Props) => {
  return (
    <>
      <div>
        <span className="clear-list" onClick={onClearSelectedList}>
          Clear List
        </span>
      </div>
      {selectedLocationsList.map(id => {
        return (
          <div className="row-position" key={id}>
            <label>{allLocationsMap.get(id)?.name}</label>
            <img
              alt="remove"
              src={RemoveIcon}
              width={19}
              style={{ padding: '5px', marginBottom: '2px', cursor: 'pointer' }}
              onClick={() => onRemoveLocation(id)}
            />
          </div>
        );
      })}
    </>
  );
};
export default React.memo(SelectedClinics);
