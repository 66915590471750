import { formatAttendeeName } from 'utils/format';
import { ImpactedObserversWrapper } from '../style';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { IEvent } from 'model/v2';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import ImpactedObserversRow from './ImpactedObserversRow';

interface Props {
  observationEvent: IEvent[] | undefined;
  eventIndex: number;
}
const ImpactedObservers = ({ observationEvent, eventIndex }: Props) => {
  const [openCollapse, setOpenCollapse] = useState(
    observationEvent !== undefined && observationEvent?.length > 0
      ? [true]
      : [false]
  );
  const handleOpenCollapse = (index: number) => {
    const newOpenCollapseArray = [...openCollapse];
    newOpenCollapseArray[index] = !newOpenCollapseArray[index];
    setOpenCollapse(newOpenCollapseArray);
  };
  const eventTimes = formatDates(
    observationEvent![0].startDate,
    observationEvent![0].endDate
  );
  return (
    <ImpactedObserversWrapper>
      <div className="impacted-observers-list">
        <Grid style={{ width: '100%' }}>
          <Grid.Row className="impacted-observers-expanded">
            <Grid.Column width={15}>
              <div className="observer-event-timing event-date">
                <CalendarOutlined />
                <div className="event-date">{eventTimes?.startDate}</div>
                <div className="event-date">
                  <ClockCircleOutlined className="event-details-icon" />
                </div>
                <div className="event-date">
                  {eventTimes?.startTime} {'-'} {eventTimes?.endTIme}
                </div>
              </div>
            </Grid.Column>

            <Grid.Column width={1}>
              {' '}
              {observationEvent!.length >= 1 && (
                <button
                  className="collapsible"
                  onClick={() => handleOpenCollapse(eventIndex)}
                >
                  <Icon
                    name={
                      openCollapse[eventIndex]
                        ? 'triangle down'
                        : 'triangle right'
                    }
                    size="large"
                  ></Icon>
                </button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div>
          {observationEvent?.map((observation, index) => {
            const observerName = formatAttendeeName(
              observation.provider?.firstName,
              observation.provider?.lastName,
              observation.provider?.clinic?.abbreviation,
              observation.provider?.speciality?.abbreviation
            );
            return (
              <>
                {openCollapse[eventIndex] ? (
                  <ImpactedObserversRow
                    observerCount={index + 1}
                    observerName={observerName}
                  />
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    </ImpactedObserversWrapper>
  );
};

export default ImpactedObservers;

export const formatDates = (
  start: Date | Moment | undefined,
  end: Date | Moment | undefined
) => {
  if (!start) return null;
  return {
    startDate: moment(start).format('MMMM DD, YYYY'),
    endTIme: moment(end).format('hh:mm a'),
    startTime: `${moment(start).format('hh:mm a')}`
  };
};
