import gql from 'graphql-tag';

export const DELETE_FILTER = gql`
  mutation deleteFilter($id: String!) {
    deleteFilter(id: $id)
  }
`;

export const UPDATE_FILTER = gql`
  mutation updateFilter(
    $id: String!
    $pageName: String!
    $updatedFilter: FilterInput!
  ) {
    updateFilter(id: $id, pageName: $pageName, updatedFilter: $updatedFilter)
  }
`;

export const ADD_FILTER = gql`
  mutation addFilter($newFilter: FilterInput!) {
    addFilter(newFilter: $newFilter)
  }
`;

export const UPDATE_SIDEBAR_FILTER = gql`
  mutation updateSidebarFilter(
    $id: String!
    $pageName: String!
    $updatedFilter: FilterInput!
  ) {
    updateSidebarFilter(
      id: $id
      pageName: $pageName
      updatedFilter: $updatedFilter
    )
  }
`;
