import { FEATURE_STAGES } from './features';

export function getFeatureAvailability(featureEnvironment) {
  const env = process.env.REACT_APP_STAGE;
  switch (featureEnvironment) {
    case FEATURE_STAGES.DEV:
      return env === FEATURE_STAGES.DEV;
    case FEATURE_STAGES.STAGING:
      return env === FEATURE_STAGES.DEV || env === FEATURE_STAGES.STAGING;
    case FEATURE_STAGES.UAT:
      return (
        env === FEATURE_STAGES.DEV ||
        env === FEATURE_STAGES.STAGING ||
        env === FEATURE_STAGES.UAT
      );
    case FEATURE_STAGES.PROD:
      return true;
    default:
      return false;
  }
}

export { FEATURES } from './features';
