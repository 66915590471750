import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';

export const GET_OPENINGS = gql`
  query openings(
    $providerId: String
    $clinicId: String
    $startTime: String
    $endTime: String
    $scope: Int
    $clientId: String
    $locationType: String
    $reschedule: Boolean
    $thisAndFollowing: Boolean
    $rescheduledEvent: RescheduledEvent
    $timezone: String
    $appointmentTypeId: String
    $isClinical: Boolean
  ) {
    openings(
      providerId: $providerId
      clinicId: $clinicId
      startTime: $startTime
      endTime: $endTime
      scope: $scope
      timezone: $timezone
      locationType: $locationType
      clientId: $clientId
      reschedule: $reschedule
      thisAndFollowing: $thisAndFollowing
      rescheduledEvent: $rescheduledEvent
      appointmentTypeId: $appointmentTypeId
      isClinical: $isClinical
    ) {
      userId
      openings {
        startTime
        endTime
      }
    }
  }
`;

export const GET_OPENING_CONFLICTS = gql`
  query getEventConflicts(
    $userId: String
    $startTime: String
    $endTime: String
    $recurringUntil: String
    $recurringEvery: Int
    $masterID: String
    $reschedule: Boolean
    $recurring: Boolean!
  ) {
    getEventConflicts(
      userId: $userId
      startTime: $startTime
      endTime: $endTime
      recurringUntil: $recurringUntil
      timeZone: "${TIMEZONE}"
      recurringEvery: $recurringEvery
      masterID: $masterID
      reschedule: $reschedule
      recurring: $recurring
    ) {
      eventsCount
      conflicts {
        startTime
        endTime
      }
    }
  }
`;
