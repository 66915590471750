import React from 'react';

interface IProps {
  title: string;
  value?: string;
  className?: string;
}
const Label: React.FC<IProps> = ({ title, value, className }: IProps) => (
  <>
    <p className="title">{title}</p>
    <label className={`label ${className}`}>
      <div className="editable-label">{value}</div>
    </label>
  </>
);

export default React.memo(Label);
