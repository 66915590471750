import React from 'react';
import {
  LibSelect,
  rectangleStyle,
  LibOption as Option
} from 'lib/ui/select/select.style';
import { Empty } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { FormError } from './error';

const SelectWithColor = ({ field, errors, options, ...restProps }) => {
  return (
    <>
      <LibSelect
        suffixIcon={<CaretDownOutlined />}
        getPopupContainer={trigger => trigger.parentNode}
        notFoundContent={<SelectOptionsNotFound list={options} />}
        {...restProps}
        {...field}
      >
        {options &&
          options.map(({ value, label, color, borderColor, disabled }) => (
            <Option key={value} value={value} disabled={disabled} color={color}>
              <div className="select-option-wrapper">
                <div
                  className="color-box"
                  style={rectangleStyle(color, borderColor)}
                ></div>
                {label}
              </div>
            </Option>
          ))}
      </LibSelect>
      {field?.name && <FormError name={field?.name} errors={errors} />}
    </>
  );
};

const SelectOptionsNotFound = React.memo(({ list }) => {
  return Array.isArray(list) && list.length === 0 ? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  ) : (
    <div>Loading...</div>
  );
});

export default React.memo(SelectWithColor);
