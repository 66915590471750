import React, { useMemo } from 'react';
import { IEventAudit, IEvent } from 'model/v2';
import moment from 'moment';
import { AuditViewWrapper } from './style';
import { ICancellationReason } from 'model';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  event: IEvent;
  cancellationReasons?: ICancellationReason[];
  isObservation?: boolean;
  isLoading?: boolean;
}

const AuditView: React.FC<Props> = ({
  event,
  cancellationReasons,
  isObservation,
  isLoading
}: Props) => {
  const cancellationReasonsMap = useMemo(() => {
    const map = new Map();
    cancellationReasons?.forEach(c => {
      map.set(c.reasonId, c);
    });
    return map;
  }, [cancellationReasons]);

  return (
    <AuditViewWrapper isObservation={isObservation}>
      <h2 className="history-title">History</h2>

      {!isLoading ? (
        event?.eventAudit ? (
          event?.eventAudit
            .sort((a, b) => moment(b.actionAt).diff(a.actionAt))
            .map((audit: IEventAudit) => (
              <div key={audit.id} className="history-content">
                {
                  <div>
                    <p>
                      {audit.action === 'Delete' ? 'Cancel' : audit.action} by{' '}
                      {audit.actionBy}
                    </p>
                    <p>{moment(audit.actionAt).format('MM/DD/YY, LT')} </p>
                    {audit.reason && (
                      <p>{cancellationReasonsMap.get(audit.reason)?.name}</p>
                    )}
                    {audit.note && <p>{audit.note}</p>}
                  </div>
                }
              </div>
            ))
        ) : (
          <span>N/A</span>
        )
      ) : (
        <Skeleton
          variant="text"
          children={
            <div>
              <p>{'Deleted by user'} </p>
              <p>{'MM/DD/YY, LT'} </p>
            </div>
          }
        />
      )}
    </AuditViewWrapper>
  );
};
export default React.memo(AuditView);
