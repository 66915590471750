/* eslint-disable no-constant-condition */
import React from 'react';
import { HeaderWrapper } from './Style';
import CheckCircle from 'assets/img/CheckCircle.png';
import CalendarBlank from 'assets/img/CalendarBlankBlack.png';
import { isCheckedIndex } from 'utils/validators/smartSchedule';
import { useFormContext } from 'react-hook-form';
import { CLIENT_AVAILABLE_DAYS } from 'utils/constants/lists';

interface IProps {
  appointmentTitle: string;
  result: any;
  index: number;
  isCheckedCard: boolean;
  updateSelectedCards: (idx: number, isChecked: boolean) => void;
}
const RecurringResultHeader: React.FC<IProps> = ({
  result,
  index,
  isCheckedCard,
  updateSelectedCards
}: IProps) => {
  const { setValue } = useFormContext();

  return (
    <HeaderWrapper>
      <header className="parent">
        <nav
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setValue(isCheckedIndex(index), !isCheckedCard);
            updateSelectedCards(index, !isCheckedCard);
          }}
        >
          <ul>
            <li>
              <img
                alt="user"
                src={CalendarBlank}
                width={25}
                style={{ paddingLeft: '2px' }}
              />
            </li>
            <li style={{ width: '32%', paddingTop: '2px' }}>
              <label className="provider provider-cls">
                {CLIENT_AVAILABLE_DAYS[result?.dayOfWeek!]}s
              </label>
            </li>
            <li className="rectangle adjacent-cls">
              {result.timeSlots[0]?.clientFullyAvailable! && (
                <p>
                  Client Availability{' '}
                  <img src={CheckCircle} alt="checked circle" />
                </p>
              )}
            </li>
            <li className="rectangle adjacent-cls">
              {result.timeSlots[0]?.timeSlot?.adjacentType! && (
                <p className="adjacent-p">
                  Adjacent <img src={CheckCircle} alt="checked circle" />
                </p>
              )}
            </li>
          </ul>
        </nav>
      </header>
    </HeaderWrapper>
  );
};
export default React.memo(RecurringResultHeader);
