import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_DOCTOR_IMAGE } from 'utils/constants/default';
import ProfilePicture from 'views/components/ui/profile/ProfilePicture';
import { ClientNameWrapper } from './style';
import { EyeOutlined } from '@ant-design/icons';
interface IProps {
  userId?: number;
  alt?: string;
  clinicId?: number;
  clientName?: string;
  email?: string;
  abbreviation: string | undefined;
}

const ClientRow: React.FC<IProps> = ({
  userId,
  alt,
  clientName,
  email,
  abbreviation
}: IProps) => {
  return (
    <ClientNameWrapper>
      <div>
        <ProfilePicture
          email={email}
          className="client-pic"
          img={DEFAULT_DOCTOR_IMAGE}
          alt={alt}
        />
        <Link className="client-name" target="_blank" to={`/clients/${userId}`}>
          {`${clientName} (${abbreviation})`}
        </Link>
      </div>
      <div className="attende-type-patient">
        <EyeOutlined className="type-icon" />
        <label>Patient</label>
      </div>
    </ClientNameWrapper>
  );
};
export default React.memo(ClientRow);
