import React from 'react';
import { LibTabs, LibTabPane } from './tabs.style';

const Tabs = props => {
  const { type, panes, activeKey } = props;
  return (
    <LibTabs type={type} defaultActiveKey={activeKey}>
      {panes.map(({ title, content }, index) => (
        <LibTabPane key={index} tab={title}>
          <div className="inner-wrap">{content}</div>
        </LibTabPane>
      ))}
    </LibTabs>
  );
};

export default Tabs;
export { Tabs };
