import { IRoom } from 'model';
import { IStaff } from 'model/user';

export interface IClinicCardItem {
  id: string;
  clinicName: string;
  clinicAbbreviation: string;
  departmentId: string;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phone: string;
  fax?: string | null;
  timezone: string;
  rooms: number;
  staff: number;
  patients: number;
  businessHours: IClinicBussinessHours[];
  roomsList: IRoom[];
}

export interface IClinicHours {
  opens?: string;
  closes?: string;
}

export interface IClinicBussinessHours extends IClinicHours {
  dayOfWeek: string;
}

export interface IClinicWorkingDays extends IClinicHours {
  day?: string | any;
}

export enum Clinic {
  id = 'id',
  clinicName = 'clinicName',
  clinicAbbreviation = 'clinicAbbreviation',
  departmentId = 'departmentId',
  street = 'street',
  city = 'city',
  state = 'state',
  zip = 'zip',
  phone = 'phone',
  fax = 'fax',
  timezone = 'timezone',
  rooms = 'rooms',
  staff = 'staff',
  patients = 'patients',
  businessHours = 'businessHours',
  services = 'services',
  roomsList = 'roomsList',
  staffList = 'staffList',
  clientsList = 'clientsList',
  centralReachId = 'centralReachId',
  centralReachLocationId = 'centralReachLocationId',
  centralReachPk = 'centralReachPk',
  address = 'address',
  clinicType = 'clinicType',
  clinicHub = 'clinicHub',
  dummyAdminId = 'dummyAdminId'
}
export interface IClinic {
  id?: string;
  clinicName?: string;
  clinicAbbreviation?: string;
  departmentId?: number | string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  fax?: string;
  timezone?: string;
  rooms?: number;
  staff?: number;
  patients?: number;
  services?: [IService];
  roomsList?: IRoom[];
  staffList?: [IStaff]; // TODO  [User]
  clientsList?: [any]; // TODO [Client]
  centralReachId?: string;
  centralReachLocationId?: string;
  centralReachPk?: string;
  address?: string;
  clinicType?: string;
  clinicHub?: string;
  abbreviation?: string;
  dummyAdminId?: string;
}

export interface IClinicOutput extends IClinic {
  businessHours?: [IBusinessHours];
}

export interface IBusinessHours {
  dayOfWeek: string;
  opens: string;
  closes: string;
}

export interface IService {
  service: string;
  defaultLength: number;
}

export interface IHoursEditForm {
  open: string;
  close: string;
  checked: boolean;
}

export type IHoursEditFormList = {
  [day in Days]: IHoursEditForm;
};

export interface IClinicEditForm extends IClinic {
  address?: string;
  businessHours?: IHoursEditFormList;
}

export interface IClinicOptionsList {
  id: string;
  name: string;
  abbreviation?: string;
  state?: string;
}

export enum Days {
  mon = 'mon',
  tues = 'tues',
  weds = 'weds',
  thurs = 'thurs',
  fri = 'fri',
  sat = 'sat',
  sun = 'sun'
}

export interface IClinicTypeOption {
  id: string;
  label: string;
}

export interface ISimpleClinics {
  id: string;
  clinicName: string;
  clinicType?: string;
  clinicAbbreviation: string;
  clinicHub?: string;
}
export interface IClinicFormData {
  clinicTypes: Array<IClinicTypeOption>;
  clinics: Array<ISimpleClinics>;
}
