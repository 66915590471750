import { makeVar, ReactiveVar } from '@apollo/client';
import { notPhantomEvent } from 'helpers/calendarHelper';
import { Moment } from 'moment';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';
import { View } from 'react-big-calendar';

import { calendarEventsPrefVar } from '../filters';
import { SidebarState } from 'model/v2';
import {
  ICalendarEvent,
  IPhantomEvent,
  IProviderAppointmentHours
} from 'model/calendar/events';

//TODO: Find a way to decouple this values from the predefined values in 'react-big-calendar' package

export enum PlannerView {
  plannerDay = 'planner day',
  plannerWeek = 'planner week'
}
export type CalendarView = View | PlannerView;
export const views: Record<string, CalendarView> = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
  PLANNER_DAY: PlannerView.plannerDay,
  PLANNER_WEEK: PlannerView.plannerWeek
};

/**
 * Calendar user preference reactive variable
 * Unknown type is 'ReactiveVar' from 'apollo/client' package
 */

const staffCalendarPrefDay: Date = new Date();
const staffCalendarPrefView: CalendarView = views.WEEK;

const clientCalendarPrefDay: Date = new Date();
const clientCalendarPrefView: CalendarView = views.WEEK;

//TODO: Change main calendar default view to planner day (will break current calendar)
const mainCalendarPrefDay: Date = new Date();
const mainCalendarPrefView: CalendarView = views.DAY;

const plannerCalendarPrefView: CalendarView = views.PLANNER_DAY;

const selectedItemsToCancelInit = new Map<number, ICalendarEvent>();

export const calendarSidebarInit: SidebarState = {
  event: undefined,
  action: SIDEBAR_ACTIONS.NEW
};
export const staffCalendarPrefDayVar: ReactiveVar<Date> = makeVar(
  staffCalendarPrefDay
);
export const staffCalendarPrefViewVar: ReactiveVar<CalendarView> = makeVar(
  staffCalendarPrefView
);

export const clientCalendarPrefDayVar: ReactiveVar<Date> = makeVar(
  clientCalendarPrefDay
);
export const clientCalendarPrefViewVar: ReactiveVar<CalendarView> = makeVar(
  clientCalendarPrefView
);

export const mainCalendarPrefDayVar: ReactiveVar<Date> = makeVar(
  mainCalendarPrefDay
);

export const plannerCalendarPrefViewVar: ReactiveVar<CalendarView> = makeVar(
  plannerCalendarPrefView
);

export const mainCalendarPrefViewVar: ReactiveVar<CalendarView> = makeVar(
  mainCalendarPrefView
);

export const calendarSidebarVar: ReactiveVar<SidebarState> = makeVar(
  calendarSidebarInit
);

export const selectedItemsToCancelVar: ReactiveVar<Map<
  number,
  ICalendarEvent
>> = makeVar(selectedItemsToCancelInit);

export const selectedItemsToCancelAddAdminVar: ReactiveVar<Map<
  number,
  ICalendarEvent
>> = makeVar(selectedItemsToCancelInit);

export const waitListPageCount: ReactiveVar<number | undefined> = makeVar<
  number | undefined
>(undefined);

export const openItemContextMenuVar: ReactiveVar<string> = makeVar('');

export const selectedItemToViewVar: ReactiveVar<string> = makeVar('');

export const selectedItemToSubstituteVar: ReactiveVar<number> = makeVar(0);

export const providerAppointmentHours: ReactiveVar<IProviderAppointmentHours> = makeVar(
  {}
);

/**
 * Sets calendars reactiveVariables with Default views and date of created appointment
 */

export const updateCalendarPref = (date: Moment) => {
  staffCalendarPrefDayVar(date.toDate());
  clientCalendarPrefDayVar(date.toDate());
  mainCalendarPrefDayVar(date.toDate());
  staffCalendarPrefViewVar(views.WEEK);
  clientCalendarPrefViewVar(views.WEEK);
  mainCalendarPrefViewVar(views.DAY);
};

export const removePhantomEvents = (events: ICalendarEvent[]) => {
  const currentEvents = events.filter(e => notPhantomEvent(e as IPhantomEvent));
  calendarEventsPrefVar(currentEvents);
};

export const removeSelectedItemsToCancel = () => {
  selectedItemsToCancelVar(new Map<number, ICalendarEvent>());
};

export const closeItemContextMenu = () => {
  openItemContextMenuVar('');
};

export const removeSelectedItemToView = () => {
  selectedItemToViewVar('');
};
