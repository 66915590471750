import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Content from 'views/components/ui/content';
import Table from 'views/containers/table';
import { CLINIC_STAFF_COLUMNS } from 'utils/constants/tables/staff';
import { useQuery } from '@apollo/react-hooks';
import { updateCacheAfterFetchMore } from 'authentication-apollo/apolloClientCache';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from 'utils/constants/default';
import { GET_CLINIC_PROVIDERS } from 'api/graphql/v2/queries/Providers';

const columns = CLINIC_STAFF_COLUMNS;

const mapStaffData = staff =>
  staff.map(member => ({
    ...member,
    key: member.id,
    speciality: member.speciality?.title
  }));

const Staff = ({ clinicId }) => {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [nextRecord, setNextRecord] = useState(0);

  const onPageChange = page => {
    setPage(page);
    setNextRecord(page * DEFAULT_PAGE_SIZE - DEFAULT_PAGE_SIZE);
    fetchMore({
      variables: { from: nextRecord },
      updateQuery: (prev, { fetchMoreResult }) =>
        updateCacheAfterFetchMore({
          resource: 'clinicProviders',
          prevData: prev,
          fetchMoreResult
        })
    });
  };

  const { loading, error, data, fetchMore } = useQuery(GET_CLINIC_PROVIDERS, {
    variables: {
      clinicId: `${clinicId}`,
      status: 'Add',
      from: nextRecord,
      size: DEFAULT_PAGE_SIZE,
      sort: DEFAULT_SORT
    },
    fetchPolicy: 'network-only'
  });

  return (
    <div>
      <div className="inner-wrap">
        <Content loading={loading} error={error} data={data}>
          {({ clinicProviders }) => (
            <Table
              columns={columns}
              data={mapStaffData(clinicProviders?.providers)}
              total={clinicProviders?.count}
              page={page}
              onPageChangeCallback={onPageChange}
              onRowSelect={row => history.push('/providers/' + row.id)}
            />
          )}
        </Content>
      </div>
    </div>
  );
};
export default Staff;
