import { yupResolver } from '@hookform/resolvers/yup';
import { useClinicDataMap } from 'api/graphql/v2/hooks/clinics';
import { ICalendarResource } from 'model/calendar/filters';
import {
  IFormEvent,
  SidebarState,
  IClient,
  IEvent,
  IFullAppointmentType
} from 'model/v2';
import React, { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NEW_APPOINTMENT_ADD_SCHEMA } from 'utils/validators/calendar';
import Content from 'views/components/ui/content';
import { initValues, SIDEBAR_ACTIONS } from '../AppointmentSidebar';
import ApplyToForm from './ApplyToForm';
import ClientSelect from './Client/ClientSelect';
import ClinicForm from './Client/ClinicForm';
import LocationForm from './Client/LocationForm';
import DateForm from './Date/DateForm';
import FrequencyForm from './Date/FrequencyForm';
import FormFooter from './Footer/FormFooter';
import NotesForm from './NotesForm';
import FormEvents from 'pages/MainCalendarPage/FormPhantomEvents/FormPhantomEvents';
import MetaFieldsForm from './MetaFieldsForm';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import CategoryForm from './CategoryFormV2';
import AppointmentTypeFormV2 from './AppointmentType/AppointmentTypeFormV2';
import { useBulkRecurringEventsCount } from 'api/graphql/v2/hooks/recurringEvents';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { useLazyQuery } from 'react-apollo';
import { GET_OBSERVATION_OF_LEAD_EVENT } from 'api/graphql/v2/queries/Events';
import ObserverForm from 'components/appointment/popupForm/ObserverForm';
import { Grid } from 'semantic-ui-react';
import AbaImpactedObservers from '../AppointmentModals/RescheduleModal/AbaImpactedObservers';
import { ImpactedObserversWrapper } from './style';
import moment from 'moment';

interface Props {
  setIsCustom: Dispatch<SetStateAction<boolean>>;
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<SetStateAction<ICalendarResource[]>>;
  appointmentCategory: string;
  setSmart: Dispatch<SetStateAction<boolean>>;
  setAppointmentCategory: Dispatch<SetStateAction<string>>;
  changeFormHandler: (val: IFormEvent) => void;
  isCustom: boolean;
  smart: boolean;
  currClient: IClient | undefined;
  setCurrClient: Dispatch<SetStateAction<IClient>>;
  deleteWhenCancelAppointmentsIds: number[];
  apptTypes: IFullAppointmentType[];
  loadingApptTypes: boolean;
}
const ClientAppointmentForm: React.FC<Props> = ({
  setVisible,
  sidebarState,
  paginationArray,
  setPaginationArray,
  setIsCustom,
  appointmentCategory,
  setAppointmentCategory,
  changeFormHandler,
  isCustom,
  smart,
  setSmart,
  currClient,
  setCurrClient,
  deleteWhenCancelAppointmentsIds,
  apptTypes,
  loadingApptTypes
}: Props) => {
  let [seriesEventObservations, setSeriesEventObservations] = React.useState<
    IEvent[]
  >([]);
  const [timeValidate, setTimeValidate] = React.useState<boolean>(false);
  const isReorderFiltrSearchProvider = false;
  const [
    getObservationOfLeadEvent,
    { data: observationOfLeadEvent }
  ] = useLazyQuery(GET_OBSERVATION_OF_LEAD_EVENT, {
    variables: {
      id: sidebarState.event?.id
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: observationOfLeadEventNew => {
      let seriesObservations = JSON.parse(
        JSON.stringify(observationOfLeadEventNew.observationOfSeriesLeadEvent)
      );
      seriesObservations = seriesObservations.map((observerEvent: any) => {
        const observations = observerEvent.observations.map((obj: any) => {
          return {
            ...obj,
            provider: { id: obj.provider?.id, name: obj.provider?.name }
          };
        });
        return {
          ...observerEvent,
          observations: observations
        };
      });
      setSeriesEventObservations(seriesObservations);
    }
  });

  let eventObj = sidebarState.event
    ? JSON.parse(JSON.stringify(sidebarState.event))
    : {};
  const [event, setEvent] = React.useState<any>(eventObj);

  const [lastAbaObserver, setLastAbaObserver] = React.useState<IEvent>({});
  console.log('use states logged', lastAbaObserver);
  const methods = useForm({
    defaultValues: React.useMemo(() => {
      return initValues(
        sidebarState.event,
        appointmentCategory,
        sidebarState.event?.appointmentType?.eventType?.name ===
          APPOINTMENT_CATEGORY[0].value ||
          appointmentCategory === APPOINTMENT_CATEGORY[0].value,
        currClient
      );
    }, [sidebarState, appointmentCategory, currClient]),
    resolver: yupResolver(
      NEW_APPOINTMENT_ADD_SCHEMA(deleteWhenCancelAppointmentsIds)
    ),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  //aba observer code
  const getInitalAbaObserversList = () => {
    return sidebarState.event?.observations
      ? sidebarState.event.observations.map(observation => ({
          id: observation.id,
          locationType: observation.locationType,
          telehealthLink: observation.telehealthLink,
          clinic: observation.clinic,
          startDate: observation.startDate,
          endDate: observation.endDate,
          duration: observation.duration,
          provider: {
            id: observation.provider?.id,
            name: observation.provider?.name
          }
        }))
      : [];
  };

  let [abaObserversList, setAbaObserversList] = React.useState<IEvent[]>(
    getInitalAbaObserversList()
  );

  const eventTypes = ['ABA', 'DT'];
  const isABAOrDTEvent = eventTypes.includes(
    sidebarState.event?.appointmentType?.eventType?.name!
  );
  //aba observer code
  const { providersMap, clinicsMap, loadingClinics } = useClinicDataMap(
    sidebarState.action
  );
  const {
    recurringBulkEventsCountLoading,
    recurringBulkEventsCount
  } = useBulkRecurringEventsCount(
    [sidebarState.event?.id],
    sidebarState.event?.id === undefined ||
      !getFeatureAvailability(FEATURES.RESCHEDULE_MODAL_UPDATES)
  );

  // Invalidate form default values
  const { reset } = methods;
  React.useEffect(() => {
    const isPendingConfirmation = methods.getValues('isPendingConfirmation');
    reset({
      ...initValues(
        sidebarState.event,
        appointmentCategory,
        sidebarState.event?.appointmentType?.eventType?.name ===
          APPOINTMENT_CATEGORY[0].value ||
          appointmentCategory === APPOINTMENT_CATEGORY[0].value,
        currClient
      ),
      isPendingConfirmation
    });
    if (sidebarState.action != 'New')
      sidebarState.event &&
        getObservationOfLeadEvent({
          variables: {
            id: sidebarState.event?.id
          }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reset,
    sidebarState,
    appointmentCategory,
    getObservationOfLeadEvent,
    loadingClinics
  ]);
  const appointmentTypes = React.useMemo(() => {
    //TODO:: Remove this after adding FWC appointment types
    return appointmentCategory === APPOINTMENT_CATEGORY[2].value
      ? apptTypes
      : apptTypes.filter(appt => appt.eventType?.name === appointmentCategory);
  }, [appointmentCategory, apptTypes]);
  const [isSeriesAbaEvent, setIsSeriesAbaEvent] = React.useState<boolean>(
    false
  );
  let isValidatedEvent = false;
  if (isABAOrDTEvent) {
    const eventStartTime = moment(
      moment(event?.startDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    const eventEndTime = moment(
      moment(event?.endDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    if (isSeriesAbaEvent) {
      seriesEventObservations.map(observerEvent => {
        observerEvent.observations?.map(obj => {
          const observerStartTime = moment(
            moment(obj.startDate).format('HH:mm:ss'),
            'HH:mm:ss'
          );
          const observerEndTime = moment(
            moment(obj.endDate).format('HH:mm:ss'),
            'HH:mm:ss'
          );
          if (
            observerStartTime.isBefore(eventStartTime) ||
            observerStartTime.isAfter(eventEndTime) ||
            observerEndTime.isBefore(eventStartTime) ||
            observerEndTime.isAfter(eventEndTime) ||
            obj.duration! < 15
          ) {
            isValidatedEvent = true;
          }
        });
      });
    } else {
      abaObserversList.map(obj => {
        const observerStartTime = moment(
          moment(obj.startDate).format('HH:mm:ss'),
          'HH:mm:ss'
        );
        const observerEndTime = moment(
          moment(obj.endDate).format('HH:mm:ss'),
          'HH:mm:ss'
        );
        if (
          observerStartTime.isBefore(eventStartTime) ||
          observerStartTime.isAfter(eventEndTime) ||
          observerEndTime.isBefore(eventStartTime) ||
          observerEndTime.isAfter(eventEndTime) ||
          obj.duration! < 15
        ) {
          isValidatedEvent = true;
        }
      });
    }
  }
  const [isValidated, setIsValidated] = React.useState<boolean>(false);
  return (
    <FormProvider {...methods}>
      <MetaFieldsForm />
      <Content
        loading={loadingApptTypes || recurringBulkEventsCountLoading}
        data={true}
        error={undefined}
      >
        {() => (
          <>
            <FormEvents
              appTypes={appointmentTypes}
              providersMap={providersMap}
              formAction={sidebarState.action}
              paginationArray={paginationArray}
              setPaginationArray={setPaginationArray}
              abaObserversList={abaObserversList}
              isReorderFiltrSearchProvider={isReorderFiltrSearchProvider}
            />
            <div className="scrollable">
              <CategoryForm
                isCustom={isCustom}
                appointmentCategory={appointmentCategory}
                setSmart={setSmart}
                smart={smart}
                setAppointmentCategory={setAppointmentCategory}
                sidebarState={sidebarState}
                changeFormHandler={changeFormHandler}
              />
              <AppointmentTypeFormV2
                apptTypes={appointmentTypes}
                sidebarState={sidebarState}
              />
              <ClientSelect
                action={sidebarState.action}
                setCurrClient={setCurrClient}
              />
              <LocationForm providersMap={providersMap} />
              <ClinicForm
                clinicsMap={clinicsMap}
                apptTypes={appointmentTypes}
                providersMap={providersMap}
                loadingClinics={loadingClinics}
              />
              <DateForm
                abaObserversList={abaObserversList}
                setAbaObserversList={setAbaObserversList}
                setSeriesEventObservations={setSeriesEventObservations}
                setEvent={setEvent}
                leadEvent={event}
                sidebarState={sidebarState}
                setTimeValidate={setTimeValidate}
                timeValidate={timeValidate}
              />
              <FrequencyForm
                action={sidebarState.action}
                setIsCustom={setIsCustom}
              />
              <h2 className="section-title" style={{ marginLeft: '20px' }}>
                Optional
              </h2>
              <NotesForm />
              <ImpactedObserversWrapper>
                <Grid.Row>
                  {isABAOrDTEvent &&
                  !isSeriesAbaEvent &&
                  abaObserversList.length > 0 &&
                  event ? (
                    <label className="impacted-observers-heading">
                      Observers:
                    </label>
                  ) : (
                    <></>
                  )}
                </Grid.Row>
              </ImpactedObserversWrapper>
              {isABAOrDTEvent &&
                !isSeriesAbaEvent &&
                abaObserversList.length > 0 &&
                event &&
                abaObserversList.map((Observations: IEvent, index: number) => (
                  <div style={{ width: '100%', maxWidth: '375px' }}>
                    <ObserverForm
                      key={index}
                      observerIndex={index + 1}
                      leadEvent={event}
                      observations={Observations}
                      setAbaObserversList={setAbaObserversList}
                      abaObserversList={abaObserversList}
                      setIsValidated={setIsValidated}
                      setLastAbaObserver={setLastAbaObserver}
                      isValidated={isValidated}
                      initialValidation={true}
                      setSeriesEventObservations={setSeriesEventObservations}
                    ></ObserverForm>
                  </div>
                ))}
              {sidebarState.action === SIDEBAR_ACTIONS.EDIT && (
                <ApplyToForm
                  recurringEventsCount={
                    recurringBulkEventsCount?.getBulkRecurringEventsCount
                  }
                  deleteWhenCancelAppointmentsIds={
                    deleteWhenCancelAppointmentsIds
                  }
                  observationCount={sidebarState.event?.observationsCount}
                  observationOfLeadEvent={
                    observationOfLeadEvent?.observationOfSeriesLeadEvent
                  }
                  setIsSeriesAbaEvent={setIsSeriesAbaEvent}
                />
              )}
              {isSeriesAbaEvent && isABAOrDTEvent && (
                <ImpactedObserversWrapper>
                  <Grid.Row>
                    {seriesEventObservations?.length > 0 ? (
                      <label className="impacted-observers-heading">
                        Impacted Observers:
                      </label>
                    ) : (
                      <></>
                    )}
                  </Grid.Row>
                  <Grid.Row className="Impacted-Observers">
                    {observationOfLeadEvent &&
                      (seriesEventObservations?.length > 0 ? (
                        seriesEventObservations?.map(
                          (observationEvent: IEvent, index: number) => {
                            return (
                              observationEvent.observations?.length! > 0 && (
                                <AbaImpactedObservers
                                  observationEvent={
                                    observationEvent.observations
                                  }
                                  eventIndex={index}
                                  setSeriesEventObservations={
                                    setSeriesEventObservations
                                  }
                                  seriesEventObservations={
                                    seriesEventObservations!
                                  }
                                  leadEvent={observationEvent}
                                  setIsValidated={setIsValidated}
                                  isValidated={isValidated}
                                  newStartDate={moment(event?.startDate).format(
                                    'YYYYY-MM-DD HH:mm:ss'
                                  )}
                                  newEndDate={moment(event?.endDate).format(
                                    'YYYYY-MM-DD HH:mm:ss'
                                  )}
                                  initialValidation={true}
                                  setAbaObserversList={setAbaObserversList}
                                  eventType={
                                    event.appointmentType.eventType.name
                                  }
                                />
                              )
                            );
                          }
                        )
                      ) : (
                        <></>
                      ))}
                  </Grid.Row>
                </ImpactedObserversWrapper>
              )}
            </div>
            <FormFooter
              setVisible={setVisible}
              action={sidebarState.action}
              providersMap={providersMap}
              apptTypes={appointmentTypes}
              abaObservers={abaObserversList}
              isSeriesAbaEvent={isSeriesAbaEvent}
              seriesEventObservations={seriesEventObservations}
              isValidated={isValidated}
              isValidatedEvent={isValidatedEvent}
              timeValidate={timeValidate}
            />
          </>
        )}
      </Content>
    </FormProvider>
  );
};
export default React.memo(ClientAppointmentForm);
