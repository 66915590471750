import React, { Dispatch, SetStateAction, useState } from 'react';
import { ObserverFormWrapper } from './style';
import { DEFAULT_DOCTOR_IMAGE } from 'utils/constants/default';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EyeOutlined } from '@ant-design/icons';
import { IEvent } from 'model/v2';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { mapToOption } from 'utils/mappers/form';
import { LocationType } from 'views/components/icons/eventIcons/locationIcon';
import { Icon, Input } from 'semantic-ui-react';
import ObserverTimeDuration from './ObserverTimeDuration';
import { FormProvider, useForm } from 'react-hook-form';
import { initValues } from 'pages/MainCalendarPage/AppointmentSidebar';
import moment from 'moment';
import { ABA_ALERT_MESSAGES } from 'utils/constants/default';

interface IProps {
  key: number;
  observations: IEvent;
  leadEvent: IEvent;
  observerIndex: number;
  setAbaObserversList: Dispatch<SetStateAction<IEvent[]>>;
  abaObserversList: IEvent[];
  setIsValidated: (val: boolean) => void;
  newStartDate?: string;
  newEndDate?: string;
  setLastAbaObserver: Dispatch<SetStateAction<IEvent>>;
  isValidated: boolean;
  initialValidation?: boolean;
  setSeriesEventObservations: Dispatch<SetStateAction<IEvent[]>>;
}

const ObserverForm: React.FC<IProps> = ({
  observations,
  observerIndex,
  leadEvent,
  setAbaObserversList,
  abaObserversList,
  setIsValidated,
  setLastAbaObserver,
  isValidated,
  initialValidation,
  setSeriesEventObservations
}) => {
  const locationTypes = mapToOption(LOCATION_TYPE_LIST);
  let filteredLocations = locationTypes.filter(
    (location: { value: any }) =>
      location.value === leadEvent.locationType ||
      location.value === 'Telehealth'
  );
  const [locationType, setLocationType] = React.useState<LocationType>();
  const [teleHealthLink, setTeleHealthLink] = React.useState<string>();
  console.log(locationType);
  const onLocationSelect = (location: LocationType) => {
    let observerEventId: number;
    setLocationType(location);
    setAbaObserversList(current =>
      current.map((obj, index) => {
        if (index === observerIndex - 1) {
          observerEventId = obj.id!;
          setSeriesEventObservations(current =>
            current.map(obj => {
              obj.observations = obj.observations?.map(observer => {
                if (observer.id === observerEventId) {
                  return { ...observer, locationType: location };
                }
                return observer;
              });
              return obj;
            })
          );
          return { ...obj, locationType: location };
        }
        return obj;
      })
    );
  };
  const onLinkChange = (e: any) => {
    let observerEventId: number;
    setTeleHealthLink(e.target.value);
    console.log(teleHealthLink);
    setAbaObserversList(current =>
      current.map((obj, index) => {
        if (index === observerIndex - 1) {
          observerEventId = obj.id!;
          setSeriesEventObservations(current =>
            current.map(obj => {
              obj.observations = obj.observations?.map(observer => {
                if (observer.id === observerEventId) {
                  return { ...observer, telehealthLink: e.target.value };
                }
                return observer;
              });
              return obj;
            })
          );
          return { ...obj, telehealthLink: e.target.value };
        }
        return obj;
      })
    );
  };

  let eventObj = JSON.parse(JSON.stringify(observations));

  const methods = useForm({
    defaultValues: React.useMemo(() => {
      return initValues(eventObj);
    }, [leadEvent]),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  const onObserverRemove = (removedObserverId: number) => {
    if (!isValidated) {
      let observerEventId: number;
      setAbaObserversList(current =>
        current.map((obj, index) => {
          if (index === observerIndex - 1) {
            observerEventId = obj.id!;
            setSeriesEventObservations((oldObserversList: IEvent[]) =>
              oldObserversList.map(observerEvent => {
                observerEvent.observations = observerEvent.observations?.filter(
                  observer => observer?.id !== observerEventId
                );
                return observerEvent;
              })
            );
            setSeriesEventObservations((oldObserversList: IEvent[]) =>
              oldObserversList.filter(
                observerEvent => observerEvent.observations?.length! > 0
              )
            );
            return { ...obj };
          }
          return obj;
        })
      );
      setAbaObserversList((oldObserversList: IEvent[]) =>
        oldObserversList.filter(
          observer => observer.provider?.id !== removedObserverId
        )
      );
    }
  };
  const [alertContent, setAlertContent] = useState<string>('');
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
  let alertContentset: string = '';
  let isAlertShownSet: boolean = false;

  if (initialValidation) {
    const observerStartTime = moment(
      moment(eventObj.startDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    const observerEndTime = moment(
      moment(eventObj.endDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    const eventStartTime = moment(
      moment(leadEvent.startDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    const eventEndTime = moment(
      moment(leadEvent.endDate).format('HH:mm:ss'),
      'HH:mm:ss'
    );
    if (
      observerStartTime.isBefore(eventStartTime) ||
      observerStartTime.isAfter(eventEndTime) ||
      observerEndTime.isBefore(eventStartTime) ||
      observerEndTime.isAfter(eventEndTime)
    ) {
      isAlertShownSet = true;
      //setIsValidated(true);
      alertContentset = ABA_ALERT_MESSAGES.timeAlert;
    } else if (eventObj.duration < 15) {
      isAlertShownSet = true;
      //setIsValidated(true);
      alertContentset = ABA_ALERT_MESSAGES.durationAlert;
    }
  } else {
    isAlertShownSet = isAlertShown;
    alertContentset = alertContent;
  }
  return (
    <ObserverFormWrapper>
      <div className="top-detail">
        <div className="float-left">
          <img className="profile-picture" src={DEFAULT_DOCTOR_IMAGE} />
          <h3 className="observer-name">{observations.provider?.name}</h3>
        </div>
        <div className="float-right">
          <div className="observer-count-div">
            <div className="icon-div">
              <EyeOutlined className="type-icon" />
            </div>
            <div className="label-div">
              <span>Observer {observerIndex}</span>
            </div>
          </div>
          <div className="delete-icon">
            <FontAwesomeIcon
              className="delete-icon-i"
              icon={faTrash}
              style={{ cursor: `pointer` }}
              onClick={() => {
                onObserverRemove(observations.provider!.id!);
              }}
            />
          </div>
        </div>
      </div>
      {leadEvent?.appointmentType?.eventType?.name === 'ABA' && (
        <>
          <div className="time-duration-row">
            <FormProvider {...methods}>
              <ObserverTimeDuration
                setLastAbaObserver={setLastAbaObserver}
                setAbaObserversList={setAbaObserversList}
                abaObserversList={abaObserversList}
                observerIndex={observerIndex}
                leadEvent={leadEvent}
                observations={eventObj}
                setIsAlertShown={setIsAlertShown}
                setIsValidated={setIsValidated}
                setAlertContent={setAlertContent}
                setSeriesEventObservations={setSeriesEventObservations}
              />
            </FormProvider>
          </div>
          <div className="warning-message-div">
            {isAlertShownSet && <small>{alertContentset}</small>}
          </div>
          <div className="location-div">
            <Select
              className="Observer_Select"
              options={filteredLocations}
              filterOption={(input: string, option: any) =>
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }
              showSearch
              onSelect={onLocationSelect}
              value={observations.locationType}
              listHeight={162}
              placeholder={
                <p style={{ paddingTop: '5px' }}>
                  <Icon name="search"></Icon> Please select meeting location
                </p>
              }
              field={undefined}
              errors={undefined}
            />
          </div>
          <div className="tele-link-div">
            {observations.locationType === LOCATION_TYPE_LIST[2].id && (
              <Input
                className="Observer_Input"
                value={observations.telehealthLink}
                placeholder="Example: https://addlink.com"
                onChange={onLinkChange}
              />
            )}
          </div>
        </>
      )}
    </ObserverFormWrapper>
  );
};

export default ObserverForm;
