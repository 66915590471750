export enum EventTypes {
  ABA = 'ABA',
  DT = 'DT',
  Admin = 'Admin',
  MED = 'MED'
}

export interface IEventType {
  id?: number;
  name?: string;
}

export enum EventSessionType {
  ABA = 'hours',
  DT = 'sessions'
}
