import { yupResolver } from '@hookform/resolvers/yup';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Content from 'views/components/ui/content';
import { initValues, SIDEBAR_ACTIONS } from '../AppointmentSidebar';
import ApplyToForm from './ApplyToForm';
import DateForm from './Date/DateForm';
import FrequencyForm from './Date/FrequencyForm';
import FormFooter from './Footer/FormFooter';
import NotesForm from './NotesForm';
import ProviderSelect from './ProviderSelect';
import { NEW_ADMIN_APPOINTMENT_ADD_SCHEMA } from 'utils/validators/calendar';
import FormEvents from '../FormPhantomEvents/FormPhantomEvents';
import {
  IFormEvent,
  IFullAppointmentType,
  SidebarState,
  DriveTimeAddress
} from 'model/v2';
import { ICalendarResource } from 'model/calendar/filters';
import { useClinicDataMap } from 'api/graphql/v2/hooks/clinics';
//import { useApptTypesWithAdmin } from 'api/graphql/v2/hooks/appointmentTypes';
import MetaFieldsForm from './MetaFieldsForm';
import CategoryForm from './CategoryFormV2';
import AdminApptTypeFormV2 from './AppointmentType/AdminApptTypeFormV2';
import { useBulkRecurringEventsCount } from 'api/graphql/v2/hooks/recurringEvents';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { IProvider } from '../../../model/v2/providers';
import DriveTimeMileage from './DriveTime/DriveTimeMileage';
import { DRIVE_TIME_APPT_IDS } from 'utils/constants/appointmentsTypes';
import { FETCH_DRIVE_TIME_ADDRESS } from 'api/graphql/v2/queries/Events';
import { useLazyQuery } from 'react-apollo';
import moment from 'moment';

interface Props {
  setIsCustom: Dispatch<SetStateAction<boolean>>;
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  appointmentCategory: string;
  setSmart: Dispatch<SetStateAction<boolean>>;
  setAppointmentCategory: Dispatch<SetStateAction<string>>;
  changeFormHandler: (val: IFormEvent) => void;
  isCustom: boolean;
  smart: boolean;
  deleteWhenCancelAppointmentsIds: number[];
  loadingApptTypes: boolean;
  adminTypes: IFullAppointmentType[];
}
const AdminAppointmentForm: React.FC<Props> = ({
  setVisible,
  sidebarState,
  paginationArray,
  setPaginationArray,
  appointmentCategory,
  setAppointmentCategory,
  changeFormHandler,
  isCustom,
  smart,
  setSmart,
  setIsCustom,
  deleteWhenCancelAppointmentsIds,
  loadingApptTypes,
  adminTypes
}) => {
  const { providersMap } = useClinicDataMap(sidebarState.action); // should use a hook for providers only
  //const { adminTypes, loadingApptTypes } = useApptTypesWithAdmin();

  const [isAddressFetched, setIsAddressFetched] = useState<boolean>(false);
  const isReorderFiltrSearchProvider = false;
  const methods = useForm({
    defaultValues: React.useMemo(() => {
      return initValues(sidebarState.event, appointmentCategory);
    }, [appointmentCategory, sidebarState.event]),
    resolver: yupResolver(NEW_ADMIN_APPOINTMENT_ADD_SCHEMA),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });
  var formFieldNames = [
    'startDate',
    'appType',
    'selectedDay',
    'endDate',
    'provider',
    'Admin_ProviderId'
  ];
  let [
    formstartDate,
    apptType,
    formselectedDay,
    formendDate,
    providerId,
    Admin_ProviderId
  ]: any = formFieldNames.map(name => methods.getValues(name));
  const { reset } = methods;
  React.useEffect(() => {
    const isPendingConfirmation = methods.getValues('isPendingConfirmation');
    reset({
      ...initValues(sidebarState.event, appointmentCategory),
      isPendingConfirmation
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentCategory, reset, sidebarState]);

  const {
    recurringBulkEventsCountLoading,
    recurringBulkEventsCount
  } = useBulkRecurringEventsCount(
    [sidebarState.event?.id],
    sidebarState.event?.id === undefined ||
      !getFeatureAvailability(FEATURES.RESCHEDULE_MODAL_UPDATES)
  );
  const [isSeriesAbaEvent, setIsSeriesAbaEvent] = React.useState<boolean>(
    false
  );
  console.log(isSeriesAbaEvent);

  const [additionalProvidersList, setAdditionalProvidersList] = React.useState<
    IProvider[]
  >([]);

  const currentProviderId: number = methods.getValues('provider.id');

  useEffect(() => {
    let formValue: DriveTimeAddress = {
      startDate: formstartDate?.format('YYYY-MM-DD HH:mm:ss'),
      endDate: formendDate?.format('YYYY-MM-DD HH:mm:ss'),
      selectedDay: formselectedDay?.format('YYYY-MM-DD'),
      apptType: apptType,
      providerId: providerId?.id
    };
    payloadForDriveTime(formValue);
  }, [formstartDate, apptType, formselectedDay, formendDate, Admin_ProviderId]);

  const [adminApptType, setAdminApptType] = React.useState<number | undefined>(
    sidebarState?.event?.appointmentType?.id
  );

  const payloadForDriveTime = (value: DriveTimeAddress) => {
    const shouldMakeAddressCall = Object.values(value).includes(undefined);
    const ifDriveTimeAppt = DRIVE_TIME_APPT_IDS[
      process.env.REACT_APP_STAGE!
    ].includes(value.apptType!);
    if (!shouldMakeAddressCall && ifDriveTimeAppt) {
      let startDate_time = value?.startDate?.split(' ')[1];
      let endDate_time = value?.endDate?.split(' ')[1];
      let updateStartDate = moment(value?.selectedDay + ' ' + startDate_time);
      let updateEndDate = moment(value?.selectedDay + ' ' + endDate_time);

      // setting address into the RHF state for submission happens in separate lifecycles from the lazyQuery loading state
      // so this establishes some metadata within RHF state that can be updated within the same lifecycles to disable save
      methods.setValue('drivetime-source-address-search-in-progress', true);
      methods.setValue(
        'drivetime-destination-address-search-in-progress',
        true
      );

      getDriveTimeAddress({
        variables: {
          startDate: updateStartDate,
          endDate: updateEndDate,
          providerId: value.providerId
        }
      });
    }
  };

  const [
    getDriveTimeAddress,
    { loading: adminAddressisFetch, data: driveTimeAddress }
  ] = useLazyQuery(FETCH_DRIVE_TIME_ADDRESS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      // if the network request doesn't return an address, manually update the state instead of waiting for the form onChange handlers
      if (data?.getDriveEventAddress?.startingAddress === null) {
        methods.setValue('drivetime-source-address-search-in-progress', false);
      }
      if (data?.getDriveEventAddress?.destinationAddress === null) {
        methods.setValue(
          'drivetime-destination-address-search-in-progress',
          false
        );
      }
      return;
    }
  });

  useEffect(() => {
    if (driveTimeAddress) {
      setIsAddressFetched(!isAddressFetched);
    }
  }, [driveTimeAddress]);
  return (
    <FormProvider {...methods}>
      <MetaFieldsForm />
      <Content
        loading={loadingApptTypes || recurringBulkEventsCountLoading}
        data={true}
        error={undefined}
      >
        {() => (
          <>
            <FormEvents
              appTypes={adminTypes}
              providersMap={providersMap}
              formAction={sidebarState.action}
              paginationArray={paginationArray}
              setPaginationArray={setPaginationArray}
              additionalProvidersList={additionalProvidersList}
              isReorderFiltrSearchProvider={isReorderFiltrSearchProvider}
            />
            <div className="scrollable">
              <CategoryForm
                isCustom={isCustom}
                appointmentCategory={appointmentCategory}
                setSmart={setSmart}
                smart={smart}
                setAppointmentCategory={setAppointmentCategory}
                sidebarState={sidebarState}
                changeFormHandler={changeFormHandler}
              />
              <AdminApptTypeFormV2
                adminTypes={adminTypes}
                sidebarState={sidebarState}
                setAdminApptType={setAdminApptType}
              />
              <ProviderSelect
                currentProviderId={currentProviderId}
                providersMap={providersMap}
                action={sidebarState.action}
                additionalProvidersList={additionalProvidersList}
                setAdditionalProvidersList={setAdditionalProvidersList}
              />
              <DateForm sidebarState={sidebarState} />
              <FrequencyForm
                action={sidebarState.action}
                setIsCustom={setIsCustom}
              />
              <h2 className="section-title" style={{ marginLeft: '20px' }}>
                Optional
              </h2>
              {DRIVE_TIME_APPT_IDS[process.env.REACT_APP_STAGE!].includes(
                adminApptType!
              ) && (
                <DriveTimeMileage
                  isSideBarEdit={true}
                  pageName="appointmentForm"
                  driveTimeAddress={driveTimeAddress}
                  adminAddressisFetch={adminAddressisFetch}
                  isAddressFetched={isAddressFetched}
                />
              )}

              <NotesForm />
              {sidebarState.action === SIDEBAR_ACTIONS.EDIT && (
                <ApplyToForm
                  recurringEventsCount={
                    recurringBulkEventsCount?.getBulkRecurringEventsCount
                  }
                  deleteWhenCancelAppointmentsIds={
                    deleteWhenCancelAppointmentsIds
                  }
                  observationCount={sidebarState.event?.observationsCount}
                  observationOfLeadEvent={[]}
                  setIsSeriesAbaEvent={setIsSeriesAbaEvent}
                />
              )}
            </div>
            <FormFooter
              setVisible={setVisible}
              action={sidebarState.action}
              providersMap={providersMap}
              apptTypes={adminTypes}
              additionalProvidersList={additionalProvidersList}
              adminAddressisFetch={adminAddressisFetch}
            />
          </>
        )}
      </Content>
    </FormProvider>
  );
};
export default React.memo(AdminAppointmentForm);
