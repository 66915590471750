import { ISmartResultListForm, iSmartSingleResultForm } from 'model/v2';
// import { DRIVE_TIME } from 'utils/constants/default';
import { LOCATION_CATEGORY_LIST } from 'utils/constants/lists';
import { getTotalMin } from 'utils/format';

export const getDefaultResultsList = (
  results: any,
  clientId: number,
  paymentMethod: string
): ISmartResultListForm => {
  return {
    results: results.map(
      (res: { startTime: any; endTime: any; isOffsite: any }, index: any) => ({
        ...res,
        index,
        client: {
          id: clientId
        },
        isChecked: true,
        isInVisible: false,
        isOffsite: res.isOffsite ? true : false,
        // driveTimeCheck: res.isOffsite ? true : false, // not fully savvy on what current flows expect this to work
        // driveTime: res.isOffsite ? DRIVE_TIME : 0, // not fully savvy on what current flows expect this to work
        driveTimeCheck: false,
        driveTime: 0,
        room: null,
        startTimeForm: getTotalMin(res.startTime),
        endTimeForm: getTotalMin(res.endTime),
        locationCategory: null,
        address: null,
        paymentMethod
      })
    )
  };
};
export const getDefaultEvalResultsList = (
  results: any,
  clientId: number,
  paymentMethod: string
): ISmartResultListForm => {
  return {
    results: results?.map(
      (res: { startTime: any; endTime: any; isOffsite: any }, index: any) => ({
        ...res,
        index,
        client: {
          id: clientId
        },
        isChecked: false,
        isInVisible: false,
        room: null,
        startTimeForm: getTotalMin(res.startTime),
        endTimeForm: getTotalMin(res.endTime),
        locationCategory: res.isOffsite ? LOCATION_CATEGORY_LIST[0].id : null,
        address: null,
        paymentMethod
      })
    )
  };
};

/**
 * Used to determine default form data based on a backend result payload
 * @param data The data returned from backend for one result
 * @param clientId client ID
 * @returns the default values to pre-fill RHF form
 */
export const getDefaultSingleResultList = (
  data: any,
  clientId: number
): iSmartSingleResultForm => {
  const resultToReturn = {
    isInVisible: false,
    room: null,
    timeSlot: data.times[0],
    client: {
      id: clientId
    },
    provider: {
      id: data.provider.id
    },
    clinic: data.inPersonClinics[0] || data.telehealthClinic,
    locationCategory: data.locations.isOffsite
      ? LOCATION_CATEGORY_LIST[0].id
      : null,
    isInClinic: data.isInClinic,
    isOffsite: data.isOffsite,
    isTelehealth: data.isTelehealth,
    startTime: data.times[0].startTime,
    endTime: data.times[0].endTime
  };

  return { result: resultToReturn };
};
const SINGLEAPPOINTMENTIDS: Record<string, number[]> = {
  dev: [
    193,
    30,
    163,
    164,
    165,
    166,
    167,
    168,
    169,
    186,
    187,
    188,
    189,
    190,
    214,
    215,
    216,
    217,
    254,
    118,
    260,
    261,
    262,
    263,
    264
  ],
  test: [
    272,
    273,
    200,
    201,
    202,
    203,
    204,
    205,
    206,
    223,
    228,
    230,
    233,
    236,
    239,
    241,
    243,
    247,
    251,
    252,
    257,
    258,
    259,
    260,
    290,
    292,
    293,
    294,
    327,
    198,
    336,
    337,
    338,
    339,
    340
  ],
  uat: [
    22,
    29,
    28,
    24,
    183,
    184,
    187,
    188,
    189,
    190,
    191,
    192,
    193,
    194,
    195,
    196,
    197,
    198,
    199,
    200,
    201,
    236,
    237,
    238,
    239,
    250,
    269,
    270,
    271,
    272,
    336,
    165,
    345,
    346,
    347,
    348,
    349
  ],
  prod: [
    38,
    31,
    24,
    25,
    205,
    206,
    207,
    208,
    224,
    229,
    230,
    237,
    244,
    249,
    250,
    251,
    252,
    253,
    254,
    299,
    300,
    301,
    302,
    398,
    167,
    413,
    414,
    415,
    416,
    417
  ]
};

export const getSingleAppointmentTypeIds = (env: string | undefined) => {
  if (env === 'DEV') {
    return SINGLEAPPOINTMENTIDS.dev;
  } else if (env === 'STAGING') {
    return SINGLEAPPOINTMENTIDS.test;
  } else if (env === 'UAT') {
    return SINGLEAPPOINTMENTIDS.uat;
  } else if (env === 'PROD') {
    return SINGLEAPPOINTMENTIDS.prod;
  } else {
    throw new Error(
      `Error fetching single appointment IDs with the given env string: ${env}`
    );
  }
};
