import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext
} from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';

import {
  calendarEventsPrefVar,
  clientsResourcesPrevVar,
  filteredProvidersResourcesPrevVar,
  providerResourcesPrevVar,
  searchedClientsPrevVar,
  searchedProvidersPrefVar
} from 'utils/cache/filters';

import { PLannerWeekGroupWrapper } from '../style';
import Group from '../../group/group';

import { useReactiveVar } from '@apollo/client';
import { LAST_WEEK_DAY } from 'utils/constants/planner';
import { calendarGroup, IPlannerGroup } from 'model/calendar/groups';
import { getDraggingStyle } from 'helpers/calendarGroupsHelper';
import { plannerContext } from 'pages/MainCalendarPage';

interface Props {
  setIsGroupDragged: Dispatch<SetStateAction<boolean>>;
  isShown: boolean;
  isGroupDragged: boolean;
  group: IPlannerGroup | undefined;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  setRemovedGroups: Dispatch<SetStateAction<number[]>>;
  removedGroups: number[];
  showFullGroup: boolean;
  handleAutoFetching: () => void;
}

const DraggableWeekGroup: React.FC<Props> = ({
  setIsGroupDragged,
  isShown,
  isGroupDragged,
  group,
  setIsShown,
  setRemovedGroups,
  removedGroups,
  showFullGroup,
  handleAutoFetching
}: Props) => {
  const providers = useReactiveVar(providerResourcesPrevVar);
  const clients = useReactiveVar(clientsResourcesPrevVar);
  const searchedClients = useReactiveVar(searchedClientsPrevVar);
  const searchedProviders = useReactiveVar(searchedProvidersPrefVar);
  const events = useReactiveVar(calendarEventsPrefVar);
  const filteredProviders = useReactiveVar(filteredProvidersResourcesPrevVar);
  const { isBulkCancelMode } = useContext(plannerContext);
  const handleGroupRemoval = useCallback(() => {
    if (isBulkCancelMode) return;
    if (group?.attendeeType === calendarGroup.client) {
      const groups = clients.filter(
        g => g?.id?.toString().split(':')[0] !== group.baseId?.toString()
      );
      clientsResourcesPrevVar(groups);
      searchedClients.delete(parseInt(group?.baseId!));
      setRemovedGroups(removedGroups.concat(parseInt(group.id)));
    } else if (group?.attendeeType === calendarGroup.provider) {
      const groups = providers.filter(
        g => g.id?.toString().split(':')[0] !== group?.baseId
      );
      providerResourcesPrevVar(groups);
      searchedProviders.delete(parseInt(group?.baseId!));
      setRemovedGroups(removedGroups.concat(parseInt(group.baseId!)));
      const currentFilteredProviders = filteredProviders.filter(
        g => g.id?.toString() !== group.baseId?.toString()
      );
      filteredProvidersResourcesPrevVar(currentFilteredProviders);
    }
    handleAutoFetching();
    const filteredEvents = events.filter(
      e => e.baseId?.toString() !== group?.baseId?.toString()
    );
    calendarEventsPrefVar(filteredEvents);
  }, [
    group,
    handleAutoFetching,
    events,
    clients,
    searchedClients,
    setRemovedGroups,
    removedGroups,
    providers,
    searchedProviders,
    filteredProviders,
    isBulkCancelMode
  ]);

  return (
    <PLannerWeekGroupWrapper
      type={group?.attendeeType}
      // TODO: check that get in specialtiesMap take the id as number
      specialityColor={group?.speciality?.color}
      isLastDay={group?.id?.split(':')[1] === LAST_WEEK_DAY}
      isGroupDragged={isGroupDragged}
    >
      <Draggable
        draggableId={group?.id.replaceAll('-', '') || ''}
        index={clients
          .concat(providers)
          .findIndex(g => g.id?.toString() === group?.baseId)}
      >
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          setIsGroupDragged(snapshot?.isDragging);
          return (
            <div
              className="draggable-group"
              ref={provided.innerRef}
              {...provided.draggableProps}
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false || snapshot?.isDragging)}
              style={getDraggingStyle(provided.draggableProps.style, snapshot)}
            >
              <Group
                cloneGroup={false}
                isShown={isShown}
                group={group}
                setRemovedGroups={setRemovedGroups}
                removedGroups={removedGroups}
                showFullGroup={showFullGroup}
                handleGroupRemoval={handleGroupRemoval}
                dragHandleProps={provided.dragHandleProps}
              />
            </div>
          );
        }}
      </Draggable>
    </PLannerWeekGroupWrapper>
  );
};

export default React.memo(DraggableWeekGroup);
