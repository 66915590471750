import React from 'react';
import { formatPatternString } from 'utils/mappers/response/authorizations';

const DescriptionExpandableRow = ({
  recommended,
  authorized,
  requested,
  quantityPattern,
  isRecommendedTherapy
}) => {
  return (
    <div className="description-wrapper">
      <p>
        <span>Recommended: </span>
        {formatPatternString(
          recommended,
          quantityPattern,
          isRecommendedTherapy
        )}
      </p>
      <p>
        <span>Authorized: </span>
        {formatPatternString(authorized, quantityPattern, isRecommendedTherapy)}
      </p>
      <p>
        <span>Requested: </span>
        {formatPatternString(requested, quantityPattern, isRecommendedTherapy)}
      </p>
    </div>
  );
};

export default DescriptionExpandableRow;
