import * as Yup from 'yup';
import { getApptType } from 'utils/common';

export const AUTHORIZATION_GROUP_FORM = apptTypes =>
  Yup.object().shape({
    authGroupID: Yup.string().required('Authorization Id is required'),
    authGroupType: Yup.string().required('Authorization type is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .when(
        'startDate',
        (startDate, schema) => startDate && schema.min(startDate + 1)
      ),
    dueDate: Yup.date().required('Report Date is required'),
    authTherapies: Yup.array()
      .of(
        Yup.object().shape({
          apptTypes: Yup.array()
            .of(Yup.string().required('Appointment type is required'))
            .min(1)
            .test(
              'mixed-appointments',
              'Error. ABA and DT Appointment Types cannot exist in the same Therapy Limit.',
              value => {
                let checkValid = true;
                if (Array.isArray(value)) {
                  const currentType = getApptType(
                    { title: value[0] },
                    apptTypes
                  )?.isClinical;
                  if (currentType === undefined || currentType === null)
                    return checkValid;
                  value.forEach(apptType => {
                    if (!apptType) return;
                    const otherType = getApptType(
                      { title: apptType },
                      apptTypes
                    )?.isClinical;
                    if (currentType !== otherType) checkValid = false;
                  });
                }
                return checkValid;
              }
            ),
          authLimit: Yup.string().when(
            'authPattern',
            (authPattern = 'Visits', schema) => {
              switch (authPattern) {
                case 'Visits':
                  return schema
                    .test('only-integers', 'Error. Only integers', value => {
                      if (value === undefined) {
                        return '';
                      }
                      return (
                        Number(Number.parseFloat(value).toFixed(0)) ===
                        Number(value)
                      );
                    })
                    .test('only-integers', 'Error. Only integers', value => {
                      if (value === undefined) {
                        return '';
                      }
                      return value.split('.')[1] !== '0';
                    })
                    .test('only-integers', 'Error. Only integers', value => {
                      if (value === undefined) {
                        return '';
                      }
                      return value.split('.').length <= 2;
                    })
                    .test('only-integers', 'Error. Only integers', value => {
                      if (value === undefined) {
                        return '';
                      }
                      return value.slice(-1) !== '.';
                    })
                    .required('Authorization Therapy Limit is required');
                case 'Hours':
                  return schema
                    .test(
                      'only-one-decimal-point',
                      'Error. Only one decimal point',
                      value => {
                        if (value === undefined) {
                          return '';
                        }
                        return value.split('.').length <= 2;
                      }
                    )
                    .test(
                      'only-two-digits-after-decimal',
                      'Error. Only two digits after decimal',
                      value => {
                        if (value === undefined) {
                          return '';
                        }
                        if (value.split('.')[1]) {
                          return value.split('.')[1]?.length <= 2;
                        }
                        return true;
                      }
                    )
                    .test('not-point', 'Error.', value => {
                      if (value === undefined) {
                        return '';
                      }
                      return value.split('.')[1] !== '0';
                    })
                    .test('not-zero-after-decimal', 'Error.', value => {
                      if (value === undefined) {
                        return '';
                      }
                      return value.slice(-1) !== '.';
                    })
                    .required('Authorization Therapy Limit is required');
                default:
                  return undefined;
              }
            }
          ),
          authPattern: Yup.string().required(
            'Authorization Therapy Pattern is required'
          ),
          authPeriod: Yup.string().required(
            'Authorization Therapy Period is required'
          )
        })
      )
      .min(1)
  });
