import {
  PlainTimepicker,
  Select
} from 'api/sharedComponents/reactHookFormComponents';
import {
  ICarePlanFieldsForSmartScheduling,
  IFullAppointmentType,
  IOption,
  SidebarState
} from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
  useEffect
} from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';
import { minutesDurationToDuration } from 'utils/format/time';
import { FormItem } from 'views/containers/form';
import { DTWrapper } from '../style';
import QuestionIcon from 'assets/img/icon-question.svg';
import FormItemNolabel from 'views/containers/form/formItem/formItemNolabel';
import { mapToValuedOption } from 'utils/mappers/form';
import { STYLE_CLASS } from 'utils/constants/appointmentsTypes';
import IndexedLabelSelectorNum from './IndexedLabelSelectorNum';
import { Popover } from 'antd';
import { getSingleAppointmentTypeIds } from 'utils/mappers/smartSchedule';
import DateRange from '../ClientAvailabilityFilter/DateRange';
import CarePlanWaitlistSSInfo from './CarePlanWaitlistSSInfo';

interface Props {
  apptTypes: IFullAppointmentType[];
  setIsSingleAppt: Dispatch<SetStateAction<boolean | undefined>>;
  setSelectedAppointment: Dispatch<SetStateAction<string>>;
  parent_Type_ID: number | undefined;
  subType_Id: number | undefined;
  sidebarState?: SidebarState;
  isWaitlistDataLoading: boolean;
  showCarePlanWaitlistInfo: boolean;
  carePlanWaitlistData: ICarePlanFieldsForSmartScheduling;
  setSelectedAppointmentType: Dispatch<
    SetStateAction<IFullAppointmentType | undefined>
  >;
  selectedAppointmentType: IFullAppointmentType;
}
const DTCarePlan: React.FC<Props> = ({
  apptTypes,
  setIsSingleAppt,
  setSelectedAppointment,
  parent_Type_ID,
  subType_Id,
  sidebarState,
  isWaitlistDataLoading,
  showCarePlanWaitlistInfo,
  carePlanWaitlistData,
  setSelectedAppointmentType,
  selectedAppointmentType
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [isSelectedSubType, setIsSelectedSubType] = useState(false);
  const methods = useFormContext();
  const apptOptionsList: IOption[] = useMemo(
    () => mapToValuedOption(apptTypes),
    [apptTypes]
  );

  const duration = useWatch({
    control: methods.control,
    name: 'dt.sessionDuration'
  });
  const [selected, setSelected] = useState<number>(0);
  const [isEvaluation, setIsEvaluation] = useState<boolean | undefined>(false);
  const subTypesOptionList: IOption[] = useMemo(
    () => mapToValuedOption(selectedAppointmentType?.appointmentSubTypes || []),
    [selectedAppointmentType]
  );
  const appointmentTypeSelect = useCallback(
    val => {
      if (val) {
        let isEval;
        const selectedApptType = apptTypes.find(apptType => {
          return apptType.id === val;
        });
        const singleApptIds = getSingleAppointmentTypeIds(
          process.env.REACT_APP_STAGE
        );
        const isSingleAppt = selectedApptType?.id
          ? singleApptIds.includes(selectedApptType?.id)
          : undefined;
        isEval = selectedApptType?.isEvaluation;

        if (selectedApptType?.title) {
          setSelectedAppointment(selectedApptType.title);
        }
        methods.setValue('appType', val);
        setSelectedAppointmentType(selectedApptType);
        methods.setValue(
          'appTypeLabel',
          `Appointment Type: ${selectedApptType?.title}`
        );
        methods.setValue('appSubType', undefined);
        setIsEvaluation(isEval);
        setIsSingleAppt(isSingleAppt);
        setIsSelected(true);
        setIsSelectedSubType(false);
        setSelected(prev => prev + 1);
        if (selectedApptType?.title === 'SLP') {
          methods.setValue('dt.sessionDuration', 30);
        } else {
          methods.setValue('dt.sessionDuration', 45);
        }
      }
    },
    [apptTypes, methods, isEvaluation]
  );
  const appointmentSubTypeHandler = useCallback(
    value => {
      const appointmentSubType = selectedAppointmentType?.appointmentSubTypes?.find(
        x => x.id === value
      );
      setIsSelectedSubType(true);
      const duration = appointmentSubType?.DTSetting?.duration;
      methods.setValue('dt.sessionDuration', duration);
    },
    [selectedAppointmentType, methods]
  );

  React.useEffect(() => {
    if (sidebarState?.event?.isDTAppt) {
      appointmentTypeSelect(parent_Type_ID);
    }
  }, [parent_Type_ID, subType_Id, apptTypes]);

  useEffect(() => {
    if (subType_Id) appointmentSubTypeHandler(subType_Id);
  }, [subType_Id, selectedAppointmentType, appointmentSubTypeHandler]);

  const content = useMemo(() => {
    return (
      <div className="popup">
        <label>i.Client Frequency Preference:</label> Selecting Fewest Days will
        aim to fill slots for the client on the days they are already booked, or
        minimize booked days. Finding Most Days will spread appointments across
        as many days possible.
        <br></br>
        <label>ii.Maximum Providers:</label> This will look for a total amount
        of providers, including booked appointments for this client.
        <br></br>
        <label>iii.Max Daily Hours:</label> Will limit the amount of hours a
        Client is booked on any day to this amount OR their Available Hours.
        Whichever is less “
      </div>
    );
  }, []);

  return (
    <DTWrapper>
      <Grid.Row className="type-row">
        <Grid.Column>
          <FormItemNolabel optional={false}>
            <></>

            <Controller
              name="appTypeLabel"
              control={methods.control}
              render={fieldProps => (
                <Select
                  className={
                    isSelected
                      ? STYLE_CLASS.SELECTED_STYLE
                      : STYLE_CLASS.UNSELECTED_STYLE
                  }
                  key={selected}
                  onChange="this.className=this.options[this.selectedIndex].className"
                  placeholder={'Appointment Type'}
                  options={apptOptionsList}
                  field={fieldProps}
                  errors={methods.errors}
                  onSelect={(val: any) => appointmentTypeSelect(val)}
                />
              )}
            />
          </FormItemNolabel>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="type-row">
        <Grid.Column>
          <FormItemNolabel optional={false}>
            <Controller
              name="appSubType"
              control={methods.control}
              render={fieldProps => (
                <Select
                  placeholder="Appointment Sub-Type"
                  options={subTypesOptionList}
                  className={
                    isSelectedSubType
                      ? STYLE_CLASS.SELECTED_STYLE
                      : STYLE_CLASS.UNSELECTED_STYLE
                  }
                  field={fieldProps}
                  onSelect={appointmentSubTypeHandler}
                  errors={methods.errors}
                />
              )}
            />
          </FormItemNolabel>
        </Grid.Column>
      </Grid.Row>
      {showCarePlanWaitlistInfo && (
        <CarePlanWaitlistSSInfo
          isWaitlistDataLoading={isWaitlistDataLoading}
          carePlanWaitlistData={carePlanWaitlistData}
          isAba={false}
        ></CarePlanWaitlistSSInfo>
      )}
      {isEvaluation ? null : (
        <Grid>
          <Grid.Row className="session" floated="left" width={60}>
            Session Information
            <Popover
              placement="bottomLeft"
              content={content}
              title="Session Information"
              trigger="hover"
            >
              <img
                alt="ques"
                src={QuestionIcon}
                width={22}
                style={{ paddingLeft: 4 }}
              />
            </Popover>
          </Grid.Row>
          <Grid.Row columns={2} className="lab">
            <Grid.Column className="duration">
              <FormItem optional={false} label="Session Duration">
                <Controller
                  name="dt.sessionDuration"
                  control={methods.control}
                  render={fieldProps => (
                    <PlainTimepicker
                      className="timepicker-style"
                      name="sessionDuration"
                      format="HH:mm"
                      pattern="[0-9]{2}:[0-9]{2}"
                      minuteStep={15}
                      disabled={true}
                      errors={methods.errors}
                      field={fieldProps}
                      value={minutesDurationToDuration(duration)}
                    />
                  )}
                />
              </FormItem>
            </Grid.Column>
            <Grid.Column className="duration">
              <FormItem optional={false} label="Weekly Sessions">
                <Controller
                  name="dt.weeklySessions"
                  control={methods.control}
                  render={fieldProps => (
                    <IndexedLabelSelectorNum
                      max={10}
                      title={'Sessions'}
                      name="weeklySessions"
                      field={fieldProps}
                      errors={methods.errors}
                      className="weekly"
                    />
                  )}
                />
              </FormItem>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: '0px' }}>
            <DateRange isSingleAppt={false} />
          </Grid.Row>
        </Grid>
      )}
    </DTWrapper>
  );
};
export default React.memo(DTCarePlan);
