import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const TitleWrapper = styled.div`
  min-height: 96px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8ecef;
  position: relative;
  h1 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.38px;
  }
  .inner-wrap {
    box-sizing: border-box;
    padding: 27px 16px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      display: none;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 15px;
    }
  }
`;

export const AuthsWrapper = styled.div`
  .ant-table-wrapper {
    overflow-y: auto;
    height: 100%;
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    .ant-table-content {
      table {
        .ant-table-thead {
          tr {
            th {
              width: 134px;
              height: 47px;
              padding: 11px 0 14px 9px;
              background: #ffffff;
              opacity: 0.8;
              font-family: Lato;
              font-size: 18px;
              font-weight: bold;
              color: ${colors.AVATAR_INITIAL};
              box-shadow: inset -1px 0 0 0 rgba(145, 150, 153, 0.43);
            }
          }
        }
        .ant-table-tbody {
          .ant-table-row-level-0 {
            td {
              background: #ffffff;
              box-shadow: inset -1px 0 0 0 rgba(145, 150, 153, 0.43);
            }
            &:nth-of-type(odd) {
              td {
                background: #ececec;
              }
            }
            td {
              position: relative;
              font-family: Lato;
              font-size: 14px;
              letter-spacing: 0.15px;
              color: ${colors.AVATAR_INITIAL};
              &.ant-tooltip-open {
                top: 0;
              }
              .go-to-profile {
                border: none;
                background: inherit;
                padding: 0;
                font-family: Lato;
                font-size: 14px;
                letter-spacing: 0.15px;
                color: ${colors.ACTIVE_URL};
                cursor: pointer;
                span {
                  text-decoration: underline;
                }
              }
              .fa-expand {
                font-size: 16px;
                position: absolute;
                right: 8px;
                top: 37%;
                cursor: pointer;
              }
              p {
                cursor: default;
              }
            }
          }
        }
      }
    }
    .ant-table-pagination {
      display: flex;
      justify-content: center;
      align-items: baseline;
      position: relative;
      right: 184px;
      .ant-pagination-item,
      .ant-pagination-prev,
      .ant-pagination-next {
        a {
          opacity: 0.8;
          font-family: Lato;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: ${colors.PURPLE};
        }
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        .ant-pagination-item-link {
          font-size: 16px;
        }
      }
      .ant-pagination-item-active {
        border: none;
        a {
          font-weight: 900;
          text-align: center;
          color: ${colors.PURPLE};
        }
      }
      .ant-pagination-disabled {
        a {
          color: #919699;
        }
      }
    }
  }

  .red {
    font-weight: bold;
    color: ${colors.RED};
  }
  .bold {
    font-weight: bold;
  }
  .endMessage {
    text-align: center;
  }
`;

export const JumpToWrapper = styled.div`
  display: flex;
  align-items: baseline;
  position: absolute;
  right: 31px;
  width: 125px;
  z-index: 10;
  &.top {
    top: 10px;
  }
  &.bottom {
    bottom: 10px;
  }
  p {
    width: 57px;
    margin-right: 8px;
  }
  .ant-select {
    width: 60px;
    height: 36px;
  }
`;
