import * as colors from 'utils/constants/theme';
import reactCSS from 'reactcss';

export const styles = reactCSS({
  default: {
    underLineTitle: {
      marginRight: '100px',
      border: 'none',
      background: 'inherit',
      padding: 0,
      fontFamily: 'Lato',
      fontSize: '14px',
      letterSpacing: '0.15px',
      color: colors.ACTIVE_URL,
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  }
});
