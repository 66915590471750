import reactCSS from 'reactcss';
export const styles = reactCSS({
  default: {
    color: {
      width: '22px',
      height: '22px'
    },
    swatch: {
      padding: '5px',
      background: '#c9cfd3',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      marginTop: '27px',
      marginLeft: '15px'
    },
    popover: {
      position: 'absolute' as 'absolute',
      zIndex: 4,
      right: '-1px'
    },
    cover: {
      position: 'fixed' as 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }
  }
});
