import gql from 'graphql-tag';

export const appointmentTypesFragments = {
  appointmentTypesData: gql`
    fragment AppointmentTypeData on AppointmentType {
      id
      title
      headerColor
      backgroundColor
      isClinical
      subTypes {
        id
        title
        athenaType
        crType
        superType
        duration
      }
    }
  `,
  appointmentTypesTableData: gql`
    fragment AppointmentTypeTableData on AppointmentType {
      id
      title
      headerColor
      backgroundColor
      isClinical
    }
  `
};

export const GET_APPT_TYPES_SUMMARY = gql`
  query {
    appointmentTypes {
      id
      title
      isClinical
    }
    appointmentABATypes {
      id
      title
      isClinical
    }
  }
`;

export const GET_APPT_TYPES_LEGEND = gql`
  query {
    appointmentTypes {
      id
      title
      headerColor
      isClinical
      backgroundColor
    }
    appointmentABATypes {
      id
      title
      headerColor
      isClinical
      backgroundColor
    }
    adminAppointmentTypes {
      id
      value
    }
  }
`;

export const GET_APPT_TYPES = gql`
  query {
    appointmentTypes {
      ...AppointmentTypeData
    }
    appointmentABATypes {
      ...AppointmentTypeData
    }
  }
  ${appointmentTypesFragments.appointmentTypesData}
`;

export const GET_APPT_TYPES_WITH_EVENTS_TYPES = gql`
  query {
    appointmentTypes {
      ...AppointmentTypeData
    }
    appointmentABATypes {
      ...AppointmentTypeData
    }
    adminAppointmentTypes {
      id
      value
    }
    eventTypes
  }
  ${appointmentTypesFragments.appointmentTypesData}
`;
