import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: #cc3f3f;
  .closeIcon {
    color: #ffffff;
    margin-left: 20px;
  }
  .banner {
    color: #ffffff;
    font-family: Lato;
    font-size: 14px;
  }
`;
