import { ITableComp } from 'views/components/table/type';
import { mapSpecialities } from './specialities';
import { TableType } from 'model/tableType';
import { mapAppointmentTypes } from './appointmentType';
import { mapABAAppoitmentSubTypes } from './ABAAppointmentSubTypes';
import { mapDTAppoitmentSubTypes } from './DTAppointmentSubTypes';
import { mapAvailabilityTemplate } from './availabilityTemplate';
import { TableConfigurationType } from 'model/tableConfigurations';
import { mapMEDAppoitmentSubTypes } from './MEDAppointmentSubTypes';

export interface ISortValue {
  comp: ITableComp;
  reverse: boolean;
}

export const mapToTableState = (
  data: TableType[],
  addNew: (data?: TableType) => void,
  mapFunction: (
    data: TableType[],
    addNew: (data?: TableType) => void,
    buttonHandlers?: object
  ) => TableConfigurationType[],
  searchValue?: string,
  sortValue?: ISortValue,
  handler?: object
): TableConfigurationType[] => {
  const filtered: TableType[] = searchValue
    ? data.filter((item: TableType) =>
        item.title?.toLowerCase().startsWith(searchValue.toLowerCase())
      )
    : data;

  const sortedData = sortValue
    ? sortValue.reverse
      ? filtered.sort(sortValue.comp.reverse)
      : filtered.sort(sortValue.comp.sort)
    : filtered;
  return mapFunction(sortedData, addNew, handler);
};
export const MAPPER_FUNCTION = {
  speciality: mapSpecialities,
  appointmentTypes: mapAppointmentTypes,
  abaAppointmentSubTypes: mapABAAppoitmentSubTypes,
  dtAppointmentSubTypes: mapDTAppoitmentSubTypes,
  medAppointmentSubTypes: mapMEDAppoitmentSubTypes,
  availabilityTemplate: mapAvailabilityTemplate
};
