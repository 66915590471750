import gql from 'graphql-tag';

export const ADD_TEMPLATE = gql`
  mutation($template: AvailabilityTemplateInput!) {
    insertAvailabilityTemplate(template: $template)
  }
`;

export const TOGGLE_ISACTIVE_TEMPLATE = gql`
  mutation($id: Int!) {
    toggleActiveAvailabilityTemplate(id: $id) {
      id
      name
      isActive
    }
  }
`;

export const SAVE_PROVIDER_TEMPLATE = gql`
  mutation($providerTemplate: ProviderTemplateInput!) {
    saveProviderTemplate(providerTemplate: $providerTemplate) {
      id
    }
  }
`;
