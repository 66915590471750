import React from 'react';
import { ToastWrapper } from './style';
import cancelIcon from 'assets/img/circle-cancel-icon.svg';
interface Props {
  message: String;
  onClose: (e: any) => void;
}
const ToastMessage: React.FC<Props> = ({ message, onClose }) => {
  return (
    <ToastWrapper>
      <div className="container">
        <div className="text">{message}</div>
        <div onClick={onClose} className="logo">
          <img src={cancelIcon} alt="cancelIcon" />
        </div>
      </div>
      <div className="container-bottom" />
    </ToastWrapper>
  );
};
export default ToastMessage;
