import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const ProviderCheckListWrapper = styled.div`
  .filter-input_wrapper {
    width: 368px;
  }
  .list_wrapper {
    position: absolute;
    width: 368px;
  }
  .specialties-selected input {
    background-color: ${colors.PURPLE} !important;
  }
  .specialties-selected ::placeholder {
    color: white !important;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  margin-bottom: 21px;
`;
export const ProviderFilterWrapper = styled.div`
  .row-position {
    background: #ffffff;
    height: 40px;
    margin: 0px 24px 0px 16px;
    width: 368px;
  }
  .clear-list {
    margin-left: 330px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    text-decoration-line: underline;
    color: #e82424;
    mix-blend-mode: normal;
    opacity: 0.8;
    cursor: pointer;
  }
  .provider-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 16px 7px 16px;
  }
`;
export const ProviderCheckListSSWrapper = styled.div`
  .list_wrapper {
    position: absolute;
    width: 94%;
  }
  .specialties-selected input {
    background-color: ${colors.PURPLE} !important;
  }
  .specialties-selected ::placeholder {
    color: white !important;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .list_wrapper .calender-filters_actions span {
    font-size: 12px;
    font-weight: bold;
  }
  .list_wrapper .calender-filters_actions button {
    font-size: 12px;
    font-weight: bold;
    width: 62px;
    height: 25px;
  }
  margin-bottom: 10px;
  .filter_list .calender-filters_actions span {
    font-size: 12px;
  }
`;
