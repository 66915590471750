import './sidebar.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CheckCircleOutlined from 'assets/img/CheckCircleOutlined.svg';
import CloseFilled from 'assets/img/CloseFilled.svg';
import { useMutation } from '@apollo/react-hooks';
import {
  ADD_AXON_CARE_PLAN_NOTES
  // UPDATE_CARE_PLAN
} from 'api/graphql/mutations/careplan';
import Notes from './Notes';
import Edit from './Edit';
import { ADD_AXON_PROVIDER_NOTES } from 'api/graphql/v2/queries/Providers';
import moment from 'moment';
import { accountContext } from 'App';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FilterPageNameEnum } from 'components/calendar/calendarToolbar/FilterTagList/form/utils';
import { ADD_AXON_CLIENT_NOTES } from 'api/graphql/v2/queries/Clients';
interface SidebarProps {
  show: boolean;
  close: () => void;
  sidebarData: any;
  setSidebarData: any;
  onNoteAdded: (clientId: string, addedNote: any) => void;
  waitListReasons?: Array<{
    title: string;
    __typename: 'WaitListCarePlanFilter';
  }>;
  pageName?: string;
  activeTab?: string;
  setActiveTab?: (tab: string) => void;
  onFieldsEdited?: (
    cpName: string,
    carePlanId: string,
    selectedWaitListReason: string,
    requestedValue: number | null,
    updatedBookingDetails: string
  ) => void;
}
const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ]
};
const Sidebar: React.FC<SidebarProps> = ({
  show,
  close,
  sidebarData,
  setSidebarData,
  onNoteAdded,
  waitListReasons,
  pageName,
  activeTab,
  setActiveTab,
  onFieldsEdited
}) => {
  const [requestedValue, setRequestedValue] = useState('');
  const [recommendedValue, setRecommendedValue] = useState(''); // Add state for recommended value
  const [newNote, setNewNote] = useState('');
  const [isCreateNotesInProgress, setIsCreateNotesInProgress] = useState(false);
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [noteSavedMessage, setNoteSavedMessage] = useState(false); // State to track if note was saved
  const [editSavedMessage, setEditSavedMessage] = useState(false);
  const sidebarRef = useRef<HTMLUListElement>(null);
  const account = useContext(accountContext);
  const [selectedWaitListReason, setSelectedWaitListReason] = useState(
    sidebarData?.waitListReason
  );
  const [addAxonCarePlanNotes] = useMutation(
    pageName === FilterPageNameEnum.provider
      ? ADD_AXON_PROVIDER_NOTES
      : pageName === FilterPageNameEnum.client
      ? ADD_AXON_CLIENT_NOTES
      : ADD_AXON_CARE_PLAN_NOTES
  );

  useEffect(() => {
    if (pageName === FilterPageNameEnum.waitList) {
      const bookingDetailsParts = sidebarData?.bookingDetails.split(' - ');

      const initialRecommendedValue =
        bookingDetailsParts?.length > 1 ? bookingDetailsParts[1] : '0'; // Set to '0' if null
      setRequestedValue(`${sidebarData.waitListRequested}`);
      setRecommendedValue(initialRecommendedValue);
    }
  }, [sidebarData]);

  const saveNote = async (newNote: string) => {
    try {
      setIsSavingInProgress(true);
      const response = await addAxonCarePlanNotes({
        variables: {
          note: {
            clientId:
              FilterPageNameEnum.provider === pageName
                ? sidebarData.providerId
                : sidebarData.clientId,
            noteContent: newNote
          }
        }
      });

      if (
        response.data &&
        (response.data.addAxonCarePlanNotes ||
          response.data.addProviderNotes ||
          response.data.addClientNotes)
      ) {
        const addedNote =
          response.data.addAxonCarePlanNotes ||
          response.data.addProviderNotes ||
          response.data.addClientNotes;
        setSidebarData({
          ...sidebarData,
          notes: [addedNote, ...sidebarData.notes],
          notesCount: sidebarData.notesCount + 1
        });
        if (FilterPageNameEnum.provider === pageName)
          onNoteAdded(sidebarData.providerId, addedNote);
        else {
          onNoteAdded(sidebarData.clientId, addedNote);
        }
        setNewNote('');
        setIsCreateNotesInProgress(false);
        setNoteSavedMessage(true);
        setTimeout(() => {
          setNoteSavedMessage(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error adding note:', error);
      setSidebarData({
        ...sidebarData,
        notes: [
          {
            actionBy: account.name,
            actionAt: new Date().toISOString(),
            note: newNote,
            error: true
          },
          ...sidebarData.notes
        ],
        notesCount: sidebarData.notesCount + 1
      });
      setNewNote('');
      setIsCreateNotesInProgress(false);
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const handleSaveNote = () => {
    if (newNote.trim() !== '') {
      saveNote(newNote);
    }
  };

  const handleSaveEdit = async () => {
    try {
      setIsSavingInProgress(true);
      let updatedBookingDetails;
      if (sidebarData && sidebarData.bookingDetails) {
        const detailsParts = sidebarData.bookingDetails.split(' - ');
        detailsParts[detailsParts.length - 1] = requestedValue;
        updatedBookingDetails = detailsParts.join(' - ');
      }
      setSidebarData({
        ...sidebarData,
        waitListReason: selectedWaitListReason,
        bookingDetails: updatedBookingDetails
      });
      if (onFieldsEdited) {
        const numericRequestedValue = requestedValue
          ? Number(requestedValue)
          : null;
        await onFieldsEdited(
          sidebarData.cpName,
          sidebarData.carePlanId,
          selectedWaitListReason,
          numericRequestedValue,
          updatedBookingDetails
        );
        setIsSavingInProgress(false);
      }
      setEditSavedMessage(true);
      setTimeout(() => {
        setEditSavedMessage(false); // Reset the message state after 3 seconds
      }, 3000);
    } catch (error) {
      setIsSavingInProgress(false);
      throw new Error('Failed to save data');
    }
  };

  const handleCancelEdit = () => {
    setNewNote('');
    setIsCreateNotesInProgress(false);
    setIsSavingInProgress(false);
    setNoteSavedMessage(false);
    close();
  };
  const formatDate = (dateString: any) => {
    const newDate = moment(dateString).format('MM/DD/YYYY');
    return newDate;
  };
  const removeHtmlTags = (text: string): string => {
    return text.replace(/<\/?[^>]+(>|$)/g, '').trim();
  };
  const handleChange = (content: string) => {
    const note = removeHtmlTags(content);
    if (note !== '') setNewNote(content);
    else setNewNote(note);
  };
  return (
    <>
      {show ? (
        <div className="wl-sidebar open">
          <div className="sidebar-header">
            <span
              className="sidebar-cancel"
              onClick={() => {
                setIsCreateNotesInProgress(false);
                setActiveTab?.('');
                close();
              }}
            >
              <img
                style={{ cursor: 'pointer' }}
                alt="closeIcon"
                src={CloseFilled}
              />
            </span>
            <div className="sidebar-body-header">
              <div className="sidebar-header-list">
                <span
                  className={`sidebar-header-selected first ${
                    activeTab === 'notes' ? 'active' : ''
                  }`}
                  onClick={() => setActiveTab?.('notes')}
                >
                  Notes
                </span>
                {FilterPageNameEnum.waitList === pageName && (
                  <span
                    className={`sidebar-header-selected ${
                      activeTab === 'edit' ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab?.('edit')}
                  >
                    Edit
                  </span>
                )}
              </div>
              <div className="sidebar-note-details">
                <div className="sidebar-notes-patient-details">
                  <span className="sidebar-notes-patient-name">
                    {sidebarData.name}
                  </span>
                  <span className="sidebar-notes-patient-dob">
                    DOB:{formatDate(sidebarData.client_Dob)}
                  </span>
                </div>
                {activeTab === 'notes' && (
                  <>
                    {noteSavedMessage && (
                      <div className="notes-saved-div">
                        <img src={CheckCircleOutlined} alt="" />
                        <span className="note-saved-message">
                          Note successfully saved
                        </span>
                      </div>
                    )}
                    {isCreateNotesInProgress ? (
                      <div className="create-notes-form">
                        <ReactQuill
                          value={newNote}
                          onChange={handleChange}
                          modules={modules}
                        />
                        <div className="create-note-buttons">
                          <button
                            className="cancel-note-button"
                            onClick={() => {
                              setNewNote('');
                              setIsCreateNotesInProgress(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className={`save-note-button ${isSavingInProgress ||
                              (newNote.trim() === '' &&
                                'save-note-button-disabled')}`}
                            onClick={handleSaveNote}
                            disabled={
                              isSavingInProgress || newNote.trim() === ''
                            }
                          >
                            {isSavingInProgress ? 'Saving...' : 'Save Note'}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="createnote-button-div">
                        <button
                          className="createnote-button"
                          onClick={() => {
                            setIsCreateNotesInProgress(true);
                            setNewNote('');
                          }}
                        >
                          Create Note
                        </button>
                      </div>
                    )}

                    <div className="notes-options">
                      <span className="notes-options-span">
                        Total Notes: {sidebarData.notesCount}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="sidebar-body">
            <div
              className={`sidebar-body-content ${
                activeTab === 'notes' ? 'scroll-y' : ''
              }`}
            >
              {activeTab === 'notes' ? (
                <Notes sidebarData={sidebarData} sidebarRef={sidebarRef} />
              ) : (
                <Edit
                  sidebarData={sidebarData}
                  requestedValue={requestedValue || '0'}
                  setRequestedValue={setRequestedValue}
                  recommendedValue={recommendedValue}
                  selectedWaitListReason={selectedWaitListReason}
                  setSelectedWaitListReason={setSelectedWaitListReason}
                  waitListReasons={waitListReasons}
                  handleSaveEdit={handleSaveEdit}
                  handleCancelEdit={handleCancelEdit}
                  isSavingInProgress={isSavingInProgress}
                  editSavedMessage={editSavedMessage}
                  setIsSavingInProgress={setIsSavingInProgress}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Sidebar;
