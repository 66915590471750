import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'lib/ui';
import { IProvider } from 'model/v2';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  ROUTE_PARAMS,
  ROUTE_PATHS,
  TARGET_BLANK
} from 'utils/constants/routes';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';

interface IProps {
  provider?: IProvider;
}

const Actions = ({ provider }: IProps) => {
  const history = useHistory();
  const editAvailability = (e: any) => {
    e.preventDefault();
    history.push({
      pathname: `/providers/${provider?.id}/availability`,
      state: { id: provider?.id }
    });
  };

  return (
    <>
      <ul className="actions">
        {getFeatureAvailability(FEATURES.ADMIN_APPOINTMENTS) && (
          <li>
            <Link
              className="new-appt-button"
              to={
                ROUTE_PATHS.NEW_APPOINTMENT +
                ROUTE_PARAMS.PROVIDER +
                provider?.id
              }
              target={TARGET_BLANK}
            >
              <PlusCircleFilled /> New Appointment
            </Link>
          </li>
        )}
        <li>
          <Button
            className="edit-availability-button"
            //style={{ top: '70px' }}
            onClick={editAvailability}
            type={null}
          >
            Manage Availability
          </Button>
        </li>
      </ul>
    </>
  );
};

export default React.memo(Actions);
