import {
  getDraggedGroupBackGroundColor,
  getGroupBackgroundColor
} from 'helpers/calendarUIHelper';
import styled from 'styled-components';
import { calendarGroup } from 'model/calendar/groups';
import * as colors from 'utils/constants/theme';

interface Props {
  type?: string;
  specialityColor?: string;
  isLastDay?: boolean;
  isGroupDragged: boolean;
}
export const PLannerWeekGroupWrapper = styled.div<Props>`
  display: flex !important;
  justify-content: flex-end !important;
  background-color: ${props => getGroupBackgroundColor(props.type || '')};

  height: 100%;
  display: flex;
  justify-content: start;
  .draggable-group {
    display: flex;
    &:focus {
      outline: none;
    }
  }
  .clone {
    color: ${colors.LIGHT_GREY} !important;
    background-color: ${props => getGroupBackgroundColor(props.type || '')};
  }

  .custom-group {
    width: 290px;
    background-color: ${props =>
      getDraggedGroupBackGroundColor(props.isGroupDragged, props.type || '')};

    justify-content: space-between;
    display: flex;
    align-items: start;
    padding: 3px;

    .client-case-render{
      width: 100% !important;
      padding-left: 5px !important;
      height: 18px;
    }
    .basic-data-shell{
      display: flex;
      flex-direction: column;
    }
    .basic-info {
      display: flex;
      width: 100%;
      height: auto;
    }
    .basic-info-block {
      width: 50%;
      height: auto;
      display: flex;
      span {
        font-size: 10px;
        display: inline-block;
        line-height: 15px !important;
      }
    }
    .basic-label {
      font-size: 10px;
      color: #8c9aa3;
    }
    .label-value {
      font-size: 10px;
      color: #8c9aa3;
    }
    .dob.row {
      width: 100%
      font-size: 10px;
      color: #8c9aa3;
      line0
    }
    .advance-info-shell {
      display: flex;
      flex-direction: column !important;
      position: absolute;
      .advance-info-shell-child {
        width: 100%;
        height: 20px;
        display: flex;
        cursor: text;
        margin-bottom: 10px;
      }
      small {
        line-height: 10px;
      }
    }
    .spaced-text {
      margin-bottom: 2px;
    }

    border-bottom: ${props =>
      props.isLastDay ? `1px solid ${colors.LIGHT_GREY}` : 'none'};

    .close-button {
      font-size: smaller;
      background-color: ${props =>
        getDraggedGroupBackGroundColor(props.isGroupDragged, props.type || '')};

      &:hover {
        background-color: ${props => getGroupBackgroundColor(props.type || '')};
      }

      .anticon-close {
        color: #415766;
        font-size: 18px;
      }
    }
    .clone-close-button {
      font-size: smaller;
      background-color: ${props => getGroupBackgroundColor(props.type || '')};

      .anticon-close {
        color: #415766;
        font-size: 18px;
      }
    }

    .group-identifier {
      justify-content: center;
      display: flex;
      align-items: start;
      /* margin-top: 6px; */
      overflow: hidden;
      .client-bullet {
        width: 24px;
        height: 24px;
        border: solid 1px #979797;
        background-color: ${colors.LIGHT_GREY};
        border-radius: 50%;
        margin-top: 2px;
      }
      .staff-bullet {
        width: 11px;
        height: 11px;
        background-color: ${props =>
          props.specialityColor ? props.specialityColor : '#45bfff'};
        border-radius: 50%;
        margin: auto;
      }
      .title {
        margin-left: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
        width: 200px;
      }

      .drag-icon {
        color: ${colors.LIGHT_PINK};
        margin-right: ${props =>
          props.type === calendarGroup.provider ? '8px' : '3px'};
        margin-top: 3px;
        margin-left: 3px;
      }
    }
  }
  .day-label-wrapper {
    width: 90px;
    border-bottom: ${props =>
      props.isLastDay ? `1px solid ${colors.LIGHT_GREY}` : '1px solid #e7eaed'};
    border-left: 1px solid ${colors.LIGHT_GREY};

    .day-label {
      color: ${colors.DARK_GREY};
      font-size: 15px;
      margin-top: 10px;
      padding-left: 9px;
    }
    .day-label-with-clinics {
      color: ${colors.DARK_GREY};
      font-size: 13px;
      margin-top: 6px;
      padding-left: 9px;
      line-height: 8px;
    }
    .clinic-label {
      color: ${colors.DARK_GREY};
      font-size: 10px;
      margin-top: 6px;
      padding-left: 9px;
      line-height: 8px;
    }
    .clinic-label-font-adjusted {
      color: ${colors.DARK_GREY};
      font-size: 9px;
      margin-top: 6px;
      padding-left: 9px;
      line-height: 8px;
    }
  }
`;

export default PLannerWeekGroupWrapper;
