import { SmartScheduleLocation } from 'model/v2/location';
import { DRIVE_TIME } from 'utils/constants/default';
import { GENERIC_LOCATION_TYPES } from 'utils/constants/lists';
import {
  driveEndTimeIndex,
  driveStartTimeIndex,
  driveTimeIndex
} from 'utils/validators/smartSchedule';
export const getPreferredLocation = (
  isInClinic?: boolean,
  isOffsite?: boolean,
  isTelehealth?: boolean
) => {
  if (isInClinic) return SmartScheduleLocation.InClinic;
  if (isOffsite) return SmartScheduleLocation.Offsite;
  if (isTelehealth) return SmartScheduleLocation.Telehealth;
  return undefined;
};

export const onSmartScheduleCardLocationDropdownChange = (
  location: string,
  index: number,
  setValue: (name: string, value: any, config?: any) => void,
  singleDT?: boolean
) => {
  if (location === SmartScheduleLocation.InClinic) {
    if (!singleDT) {
      setValue(`results[${index}].isInClinic`, true);
      setValue(`results[${index}].isOffsite`, false);
      setValue(`results[${index}].isTelehealth`, false);
    } else {
      setValue(`result.isInClinic`, true);
      setValue(`result.isOffsite`, false);
      setValue(`result.isTelehealth`, false);
    }
  }

  if (location === SmartScheduleLocation.Offsite) {
    if (!singleDT) {
      setValue(`results[${index}].isInClinic`, false);
      setValue(`results[${index}].isOffsite`, true);
      setValue(`results[${index}].isTelehealth`, false);
    } else {
      setValue(`result.isInClinic`, false);
      setValue(`result.isOffsite`, true);
      setValue(`result.isTelehealth`, false);
    }
  }

  if (location === SmartScheduleLocation.Telehealth) {
    if (!singleDT) {
      setValue(`results[${index}].isInClinic`, false);
      setValue(`results[${index}].isOffsite`, false);
      setValue(`results[${index}].isTelehealth`, true);
    } else {
      setValue(`result.isInClinic`, false);
      setValue(`result.isOffsite`, false);
      setValue(`result.isTelehealth`, true);
    }
  }
};

export const SetLocationCategory = (
  id: number,
  index: number,
  setValue: (name: string, value: any, config?: any) => void,
  singleDT?: boolean
) => {
  if (!singleDT) {
    setValue(`results[${index}].locationCategory`, id);
  } else {
    setValue(`locationCategory`, id);
  }
};

export const getInitialLocationValue = (allowedLocations: string[]) => {
  if (allowedLocations.includes(GENERIC_LOCATION_TYPES[0])) {
    return GENERIC_LOCATION_TYPES[0];
  } else if (allowedLocations.includes(GENERIC_LOCATION_TYPES[1])) {
    return GENERIC_LOCATION_TYPES[1];
  } else if (allowedLocations.includes(GENERIC_LOCATION_TYPES[2])) {
    return GENERIC_LOCATION_TYPES[2];
  } else {
    return GENERIC_LOCATION_TYPES[0];
  }
};

export const getAllowedLocationSingleDT = (
  isInClinic: boolean,
  isTelehealth: boolean,
  isOffsite: boolean
) => {
  let allowedLocationsList: string[] = [];
  if (isInClinic) {
    allowedLocationsList.push('in-clinic');
  }
  if (isOffsite) {
    allowedLocationsList.push('off-site');
  }
  if (isTelehealth) {
    allowedLocationsList.push('telehealth');
  }
  return allowedLocationsList;
};

export const onSmartScheduleCardDriveTimeSelected = (
  checked: boolean,
  index: number,
  setValue: (name: string, value: any, config?: any) => void
) => {
  if (checked) {
    setValue(driveTimeIndex(index), DRIVE_TIME);
  } else {
    setValue(driveTimeIndex(index), 0);
    setValue(driveStartTimeIndex(index), 0);
    setValue(driveEndTimeIndex(index), 0);
  }
};
