import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import moment from 'antd/node_modules/moment';
import { MINUTES_INTERVAL_ERROR } from 'utils/constants/errors';
import { checkMinutesInterval } from 'utils/format/time';
import { TimerTextField } from 'api/sharedComponents/reactHookFormComponents';

interface IProps {
  time: Date;
  controlName: string;
  displayName: string;
  dayIndex: number;
}

const EditTimeForm: React.FC<IProps> = ({
  time,
  controlName,
  displayName,
  dayIndex
}) => {
  const { control, errors, setValue, setError, trigger } = useFormContext();

  const timeHandler = React.useCallback(
    event => {
      const submittedTime = event.target.value;
      const [hours, minutes] = submittedTime.split(':');
      const dateTimeToSave = moment(time)
        .day(dayIndex)
        .hour(hours)
        .minute(minutes);
      setValue(controlName, dateTimeToSave, {
        shouldDirty: true
      });
      if (!checkMinutesInterval(submittedTime)) {
        setError(controlName, {
          message: MINUTES_INTERVAL_ERROR,
          shouldFocus: true
        });
      }
      trigger(controlName);
    },
    [setValue, setError, trigger, controlName, dayIndex]
  );
  return (
    <>
      <FormItem label={displayName}>
        <Controller
          name={controlName}
          control={control}
          error={errors}
          defaultValue={time}
          render={fieldProps => (
            <TimerTextField
              field={fieldProps}
              errors={errors}
              defaultValue={moment(time)
                .day(dayIndex)
                .format('HH:mm')}
              onChange={timeHandler}
              inputProps={{
                step: 900
              }}
            />
          )}
        />
      </FormItem>
    </>
  );
};

export default EditTimeForm;
