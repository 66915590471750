import { useReducer, useEffect, useCallback } from 'react';
import { useMutation } from 'react-apollo';
import {
  EDIT_EVENT_BEHAVIOR_OPERATION,
  UPDATE_EVENT_OPERATION
} from 'utils/constants/default';
import { updateCalendarPref } from 'utils/cache/calendar';
import moment from 'moment';
import {
  ADD_EVENT,
  UPDATE_EVENT,
  EDIT_EVENT
} from 'api/graphql/queries/events';
import { getRefetchQueries, getSubmissionEvent } from './newAppointmentHelpers';
import { useHistory, useParams } from 'react-router';

export const SUBMISSION_STATES = {
  START: 'START_FORM',
  SUBMIT: 'SUBMIT_FORM', // click submit form with valid date
  FETCHING_WARNINGS: 'FETCHING_WARNINGS',
  WARNINGS_FETCHED: 'WARNINGS_FETCHED',
  AUTHORIZATIONS: 'AUTH_WARNINGS',
  CONFLICTS: 'CONFLICTS_WARNINGS',
  SAVE: 'SAVE_APPOINTMENT',
  SAVED: 'APPOINTMENT_SAVED',
  SAVE_FAILED: 'APPOINTMENT_SAVE_FAILED'
};

const DEFAULT_SUBMISSION_STATE = {
  loading: false,
  currentState: SUBMISSION_STATES.START, // current local state of the form
  formData: undefined, // the submitted data from appointment creation
  warnings: undefined, // NO warnings
  conflicts: undefined, // NO conflicts
  warningsVariables: undefined // DATA FOR QUERY TO FETCH WARNINGS
};

export function newAppointmentSubmissionReducer(state, action) {
  switch (action.type) {
    case SUBMISSION_STATES.START:
      return {
        ...DEFAULT_SUBMISSION_STATE
      };
    case SUBMISSION_STATES.SUBMIT:
      // should load warnings and conflicts
      return {
        ...state,
        currentState: SUBMISSION_STATES.SUBMIT,
        loading: true,
        ...action.payload
      }; // payload is the data to submit
    case SUBMISSION_STATES.AUTHORIZATIONS:
      return {
        ...state,
        currentState: SUBMISSION_STATES.AUTHORIZATIONS,
        loading: false,
        ...action.payload
      };
    case SUBMISSION_STATES.CONFLICTS:
      return {
        ...state,
        currentState: SUBMISSION_STATES.CONFLICTS,
        loading: false,
        ...action.payload
      };
    case SUBMISSION_STATES.SAVE:
      return {
        ...state,
        loading: true,
        currentState: SUBMISSION_STATES.SAVE,
        ...action.payload
      };
    case SUBMISSION_STATES.SAVED:
      return {
        ...state,
        currentState: SUBMISSION_STATES.SAVED,
        loading: false
      };
    default:
      console.log('unknown state triggered', action);
      return state;
  }
}

export const useSubmissionReducer = (
  apptTypes,
  eventTypes,
  client,
  clinics,
  oldId,
  eventMeta
) => {
  const { operation } = useParams();
  const history = useHistory();
  const [state, dispatch] = useReducer(
    newAppointmentSubmissionReducer,
    DEFAULT_SUBMISSION_STATE
  );

  const [addEvent] = useMutation(ADD_EVENT);
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [editEvent] = useMutation(EDIT_EVENT);

  const saveAppointment = useCallback(
    data => {
      if (!oldId)
        addEvent({
          variables: {
            event: getSubmissionEvent(
              data,
              client,
              apptTypes,
              data.clinicId,
              eventTypes,
              clinics
            )
          },
          refetchQueries: getRefetchQueries(data.selectedSlot)
        })
          .then(() => dispatch({ type: SUBMISSION_STATES.SAVED }))
          .catch(error => dispatch({ type: SUBMISSION_STATES.START }));
      else if (operation === UPDATE_EVENT_OPERATION) {
        updateEvent({
          variables: {
            id: oldId,
            recurrent: eventMeta.thisAndFollowingEvents,
            event: getSubmissionEvent(
              data,
              client,
              apptTypes,
              data.clinicId,
              eventTypes,
              clinics
            ),
            cancelNote: eventMeta.cancelNote,
            cancelReasonId: eventMeta.cancelReasonId
          },
          refetchQueries: getRefetchQueries(data.selectedSlot)
        })
          .then(() => dispatch({ type: SUBMISSION_STATES.SAVED }))
          .catch(error => dispatch({ type: SUBMISSION_STATES.START }));
      } else if (operation === EDIT_EVENT_BEHAVIOR_OPERATION)
        editEvent({
          variables: {
            id: oldId,
            recurrent: eventMeta.thisAndFollowingEvents,
            event: getSubmissionEvent(
              data,
              client,
              apptTypes,
              data.clinicId,
              eventTypes,
              clinics
            )
          },
          refetchQueries: getRefetchQueries(data.selectedSlot)
        })
          .then(() => dispatch({ type: SUBMISSION_STATES.SAVED }))
          .catch(error => dispatch({ type: SUBMISSION_STATES.START }));
    },
    [
      oldId,
      addEvent,
      updateEvent,
      editEvent,
      apptTypes,
      eventTypes,
      client,
      eventMeta,
      clinics,
      operation,
      dispatch
    ]
  );

  useEffect(() => {
    if (state.currentState === SUBMISSION_STATES.SAVE) {
      saveAppointment(state.formData);
    } else if (state.currentState === SUBMISSION_STATES.SAVED) {
      updateCalendarPref(moment(state.formData.selectedDate));
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return [state, dispatch];
};
