import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { Grid, Radio } from 'semantic-ui-react';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import { CategoryFormWrapper } from './style';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { IClient, IFormEvent, SidebarState } from 'model/v2';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormItemNolabel from 'views/containers/form/formItem/formItemNolabel';
import QuestionIconUncolored from 'assets/img/question-icon-uncolored.svg';
import { SearchWrapper } from 'components/calendar/calendarFiltersAndSearch/style';
import searchIcon from 'assets/img/bars-search-search-bar.svg';
import { GET_CLIENT_LIST } from 'api/graphql/v2/queries/Clients';
import { useLazyQuery } from 'react-apollo';
import Loader from 'views/components/ui/content/Loading';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from 'utils/constants/default';
import debounce from 'lodash/debounce';
import { removePhantomEvents } from 'utils/cache/calendar';
import { calendarEventsPrefVar } from 'utils/cache/filters';
import { SIDEBAR_ACTIONS } from '../AppointmentSidebar';
import { SMART_APPOINTMENT_CATEGORY } from 'utils/constants/smartSchedule';

interface Props {
  sidebarState: SidebarState;
  isCustom: boolean;
  appointmentCategory: string;
  setSmart: Dispatch<SetStateAction<boolean>>;
  setAppointmentCategory: Dispatch<SetStateAction<string>>;
  changeFormHandler: (val: IFormEvent) => void;
  smart: boolean;
  setCurrClient?: Dispatch<SetStateAction<IClient>>;
}
const CategoryForm: React.FC<Props> = ({
  isCustom,
  appointmentCategory,
  setSmart,
  setAppointmentCategory,
  smart,
  setCurrClient,
  sidebarState
}: Props) => {
  const methods = useFormContext();
  const { client } = useWatch({
    name: ['client'],
    control: methods.control
  });
  const [isSelected, setIsSelected] = useState(false);
  const [clients, setClients] = React.useState<IClient[]>([]);
  const [search, setSearch] = React.useState('');
  const [isManualDisabled, setIsManualDisabled] = React.useState(false);
  const fetchRef = React.useRef(0);

  const handleSmartChange = React.useCallback(
    (_, { checked }) => {
      removePhantomEvents(calendarEventsPrefVar());
      setSmart(checked);
    },
    [setSmart]
  );

  const appointmentCategorySelect = useCallback(
    (val: any) => {
      setAppointmentCategory(val);
      methods.setValue('apptCategory', `Appointment category: ${val}`);
      methods.setValue('apptCategoryType', val);
      methods.setValue('appType', undefined);
      methods.setValue('appSubType', undefined);
    },
    [methods, setAppointmentCategory]
  );

  const [doRequest, { loading }] = useLazyQuery(GET_CLIENT_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setClients(
        data?.clientsList?.clients.filter((value: any) => value.isActive) || []
      );
    }
  });

  React.useMemo(() => {
    const found = clients?.find(arrClient => arrClient.id === client?.id);
    if (client?.id && !found && search === '') {
      fetchRef.current += 1;
      setClients([
        {
          id: client?.id,
          name: client?.name,
          clinic: client?.clinic
        }
      ]);
    }
  }, [client, fetchRef, clients, search]);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      setSearch(value);
      fetchRef.current += 1;
      doRequest({
        variables: {
          search: value ? value : null,
          from: 0,
          size: DEFAULT_PAGE_SIZE,
          sort: DEFAULT_SORT
        }
      });
    };

    return debounce(loadOptions, 800);
  }, [doRequest]);
  const setSearchClient = React.useCallback(
    (id: number) => {
      setSearch('');
      setIsSelected(true);
      const selectedClient = clients?.find(
        (client: IClient) => client.id === id
      );

      methods.setValue('client', { ...selectedClient });
      if (setCurrClient) setCurrClient({ ...selectedClient } as IClient);
    },
    [clients, methods, setCurrClient]
  );
  const handleClear = () => {
    setIsSelected(false);
  };
  const smartAppointmnetCategory = useMemo(
    () => SMART_APPOINTMENT_CATEGORY.includes(appointmentCategory),
    [appointmentCategory]
  );

  React.useEffect(() => {
    if (
      !smartAppointmnetCategory ||
      (appointmentCategory === APPOINTMENT_CATEGORY[1].value &&
        !getFeatureAvailability(FEATURES.DT_SMART_SCHEDULE))
    ) {
      setIsManualDisabled(!smart);
      if (smart) {
        setSmart(false);
        setAppointmentCategory(appointmentCategory);
      }
    } else {
      setIsManualDisabled(false);
    }
    if (sidebarState.action === SIDEBAR_ACTIONS.EDIT) setIsManualDisabled(true);
  }, [
    appointmentCategory,
    setAppointmentCategory,
    setSmart,
    sidebarState.action,
    smart,
    smartAppointmnetCategory
  ]);
  return (
    <CategoryFormWrapper>
      <Grid className="grid">
        <div className="rectangle"></div>
        <Grid.Row className={smart ? `smart-schedule` : `manual-schedule`}>
          {getFeatureAvailability(FEATURES.SMART_SCHEDULE) && (
            <>
              <Grid.Column width={5} className="smart-schedule-text">
                {' '}
                Smart Scheduling{' '}
              </Grid.Column>
              <Grid.Column width={5}>
                <img
                  alt="ques"
                  src={QuestionIconUncolored}
                  style={{
                    margin: 'auto',
                    marginLeft: -9,
                    marginRight: 30,
                    display: 'block',
                    width: '13'
                  }}
                />
              </Grid.Column>
              <Grid.Column className="label-style" width={3}>
                <label className={smart ? 'enabled' : 'disabled'}>
                  {smart ? `Enabled` : `Disabled`}
                </label>
              </Grid.Column>
              <Grid.Column width={2} className="column-style">
                <Radio
                  toggle
                  onChange={handleSmartChange}
                  disabled={isCustom || isManualDisabled}
                  checked={smart}
                />
              </Grid.Column>
            </>
          )}
        </Grid.Row>

        <Grid.Row className="row-position">
          <Grid.Column>
            {smart && (
              <SearchWrapper>
                <img src={searchIcon} className="search-icon" alt="" />
                <Controller
                  name="client.id"
                  control={methods.control}
                  render={fieldProps => (
                    <Select
                      allowClear
                      showSearch
                      className={
                        isSelected
                          ? 'search-by-client-selected'
                          : 'search-by-client'
                      }
                      placeholder={'Search by client'}
                      options={clients?.map((client: IClient) => {
                        return {
                          label: `${client.name} (${client?.clinic
                            ?.abbreviation || ''})`,
                          value: client.id
                        };
                      })}
                      field={fieldProps}
                      onSelect={setSearchClient}
                      onClear={handleClear}
                      errors={methods.errors}
                      onSearch={debounceFetcher}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      notFoundContent={
                        loading ? (
                          <Loader
                            isSidebar={false}
                            fullHeight={false}
                            scroll={false}
                          />
                        ) : null
                      }
                    />
                  )}
                />
              </SearchWrapper>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="type-row">
          <Grid.Column>
            <FormItemNolabel optional={false}>
              <Controller
                name="apptCategory"
                control={methods.control}
                render={fieldProps => (
                  <Select
                    className={
                      methods.errors.client
                        ? 'appt-category extra-margin'
                        : 'appt-category'
                    }
                    placeholder={'Select Appointment Category'}
                    options={APPOINTMENT_CATEGORY}
                    field={fieldProps}
                    onSelect={appointmentCategorySelect}
                    errors={methods.errors}
                    disabled={sidebarState.action === SIDEBAR_ACTIONS.EDIT}
                  />
                )}
              />
            </FormItemNolabel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CategoryFormWrapper>
  );
};
export default React.memo(CategoryForm);
