import gql from 'graphql-tag';

export const specialtiesFragments = {
  specialty: gql`
    fragment Specialty on ProviderSpecialty {
      id
      title
      color
      abbreviation
      department
    }
  `
};
