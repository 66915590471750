import styled from 'styled-components';

export const LoaderWrap = styled.div`
  text-align: center;
  padding: 100px 0;
  span {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin: 0 7px 10px;
    background: #919699;
    border-radius: 50%;
    transform: translateY(0);
    animation: wave 2s infinite ease-in-out;
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.3s;
    }
    &:nth-child(5) {
      animation-delay: 0.4s;
    }
  }
  p {
    opacity: 0.8;
    font-size: 15px;
    color: #21303a;
  }
  @keyframes wave {
    0%,
    60%,
    100% {
      transform: translateY(0);
    }

    20% {
      transform: translateY(-33px);
    }

    40% {
      transform: translateY(0);
    }
  }
`;
