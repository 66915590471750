import gql from 'graphql-tag';
import { AvailabilityTemplateFragments } from '../fragments/AvailabilityTemplate';

export const GET_ALL_TEMPLATES = gql`
  {
    listAllAvailabilityTemplatesOrderByDepartment {
      ...AvailabilityTemplateList
    }
  }
  ${AvailabilityTemplateFragments.AvailabilityTemplateList}
`;

export const GET_SPECIALITIES_AND_DEPARTMENTS_AND_TEMPLATES = gql`
  query {
    listDropdownAvailabilities {
      id
      name
      department
    }
    specialties {
      id
      department
      abbreviation
    }
    specialtiesDepartment
  }
`;
export const GET_PROVIDER_WITH_AVAILABILITY = gql`
  query($id: Int!, $date: DateTime) {
    providerWithAvailability(id: $id, date: $date) {
      id
      name
      employment
      isDefaultTemplateInherited
      speciality {
        id
        department
        abbreviation
      }
      dailyMaxHours {
        id
        dayOfWeek
        value
      }
      availability {
        ...ProviderDayAvailability
      }
    }
    clinicWithItsHub {
      id
      name
      abbreviation
      clinicType
      clinicHub {
        id
        name
        abbreviation
        clinicType
      }
    }
    providerClinic(id: $id) {
      clinic {
        id
        name
        abbreviation
        clinicType
        timezone
      }
    }
  }
  ${AvailabilityTemplateFragments.ProviderDayAvailability}
`;

export const GET_DAY_AVAILABILITIES_BY_ID = gql`
  query($id: Int!) {
    getAvailabilityWithDetails(id: $id) {
      name
      dayAvailabilities {
        dayOfWeek
        startTime
        endTime
        type
        location
        isAdmin
        clinics {
          id
        }
      }
    }
  }
`;

export const GET_AVAILIABILITY_TEMPLATE_BY_ID = gql`
  query($id: Int!) {
    getAvailabilityTemplateById(id: $id) {
      ...AvailabilityTemplateWithDayAvailability
    }
  }
  ${AvailabilityTemplateFragments.AvailabilityTemplateWithDayAvailability}
`;
