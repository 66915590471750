import { EVENT_STATUS } from 'utils/constants/status';

export const handleSubscriptionDataUpdate = (
  events,
  totalEvents = [],
  attributeKey = 'id',
  shouldShowCanceled = true,
  shouldShowDeleted = true
) => {
  console.log('Calendar subscription: events ', events);
  console.log('Calendar subscription: total events ', totalEvents);
  console.log('Calendar subscription: attribute key ', attributeKey);
  console.log('Calendar subscription: showing Canceled ', shouldShowCanceled);
  console.log('Calendar subscription: showing Delete ', shouldShowDeleted);
  if (!events || events?.length === 0) return totalEvents;
  console.log('Calendar subscription: continued after length check ', events);

  events?.forEach(event => {
    event.dayOfWeek = new Date(event?.startDate).getDay();
    const index = totalEvents?.findIndex(
      x => x[attributeKey] === event[attributeKey]
    );

    if (event.status === EVENT_STATUS.deleted) {
      console.log('Calendar subscription: Entered delete');
      totalEvents = totalEvents.filter(
        x => x[attributeKey] !== event[attributeKey]
      );
    } else if (event.status === EVENT_STATUS.canceled) {
      console.log(
        'Calendar subscription: Entered cancel , event to remove',
        event
      );
      totalEvents = totalEvents.filter(
        x => x[attributeKey] !== event[attributeKey]
      );

      if (shouldShowCanceled && index < 0) {
        console.log(
          'Calendar subscription: cancel filter is enabled and event is new'
        );
        totalEvents = [event, ...totalEvents];
      }
    } else {
      console.log('Calendar subscription: Adding Event ', event);
      totalEvents = totalEvents.filter(
        x => x[attributeKey] !== event[attributeKey]
      );
      totalEvents = [event, ...totalEvents];
    }
  });

  totalEvents.forEach(eachEvent => {
    console.log(eachEvent, 'Each Event of totalEvents');
  });

  return totalEvents;
};
