import { LibSelect } from 'lib/ui/select/select.style';
import { TelehealthWrapper } from 'pages/provider/form/style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, CheckboxGroup } from 'lib/ui/checkbox';
import { FormError } from 'api/sharedComponents/reactHookFormComponents/error';
import { IClinic } from 'model/v2/clinic';
import {
  createClinicToSpokeMap,
  createClinicIdToClinicMap,
  createClinicNameToIdMap,
  createClinicToSpokeMapNames
} from 'utils/mappers/availabilityTemplate';
import { clinicNetworkTypes } from 'utils/constants/clinicTypes';

interface Props {
  providerId?: number;
  defaultData?: number[];
  clinics: IClinic[];
}

const ClinicSelect: React.FC<Props> = ({ clinics, defaultData }: Props) => {
  const spokeMap = useMemo(() => createClinicToSpokeMap(clinics), [clinics]);
  const spokeMapName = useMemo(() => createClinicToSpokeMapNames(clinics), [
    clinics
  ]);
  const hubClinics = useMemo(
    () =>
      clinics?.filter(clinic => clinic.clinicType !== clinicNetworkTypes.SPOKE),
    [clinics]
  );
  const idToClinicMap = useMemo(() => createClinicIdToClinicMap(clinics), [
    clinics
  ]);
  const nameToIdMap = useMemo(() => createClinicNameToIdMap(clinics), [
    clinics
  ]);

  const { control, errors } = useFormContext();

  const getSelectedClinicsNames = useCallback(() => {
    const result: string[] = [];
    // eslint-disable-next-line array-callback-return
    defaultData?.map((clinicId: number) => {
      const clinic = idToClinicMap.get(clinicId);
      if (clinic?.name) result.push(clinic.name);
    });
    return result;
  }, [defaultData, idToClinicMap]);

  const [selectedClinicsNames, setSelectedClinicsNames] = useState<string[]>(
    getSelectedClinicsNames()
  );

  const getSelectedClinicsIds = useCallback(() => {
    const result: number[] = [];
    if (defaultData) result.push(...defaultData);
    return result;
  }, [defaultData]);

  const [selectedClinicsIds, setSelectedClinicsIds] = useState<number[]>(
    getSelectedClinicsIds()
  );

  const getSelectedClinicsName = useCallback(() => {
    const result: string[] = [];
    selectedClinicsIds?.forEach((clinicId: number) => {
      const clinic = idToClinicMap.get(clinicId);
      if (clinic?.name) result.push(clinic.name);
    });
    return result;
  }, [idToClinicMap, selectedClinicsIds]);

  useEffect(() => {
    setSelectedClinicsNames(getSelectedClinicsName());
    control.setValue('clinics', selectedClinicsIds, { shouldDirty: true });
  }, [control, getSelectedClinicsName, selectedClinicsIds]);

  const onDeselect = useCallback(
    (item: any) => {
      const itemId = nameToIdMap.get(item);
      if (itemId) {
        if (!selectedClinicsIds.includes(itemId)) {
          setSelectedClinicsNames(
            selectedClinicsNames.filter(clinic => !item?.includes(clinic))
          );
        } else {
          setSelectedClinicsIds(
            selectedClinicsIds.filter(clinic => clinic !== itemId)
          );
        }
      }
    },
    [nameToIdMap, selectedClinicsIds, selectedClinicsNames]
  );

  const isClinicChecked = useCallback(
    (clinicId?: number) => {
      if (clinicId) return selectedClinicsIds?.includes(clinicId);
      else return false;
    },
    [selectedClinicsIds]
  );

  const checkHubClinics = useCallback(
    (event: any, clinic: IClinic) => {
      const filteredSelectedClinics = selectedClinicsIds.filter(
        clinicItem => clinicItem !== clinic.id
      );
      if (event.target.checked) {
        if (filteredSelectedClinics.length !== 0 && clinic?.id)
          setSelectedClinicsIds([...filteredSelectedClinics, clinic.id]);
        else if (clinic?.id) setSelectedClinicsIds([clinic.id]);
      } else {
        setSelectedClinicsIds(filteredSelectedClinics);
      }
    },
    [selectedClinicsIds, setSelectedClinicsIds]
  );

  const checkSpoke = useCallback(
    (event: number[], clinicHub: IClinic) => {
      const spokeClinics = spokeMap.get(clinicHub.id);
      const filteredSelectecClinics = selectedClinicsIds.filter(
        clinic => !spokeClinics?.includes(clinic)
      );
      setSelectedClinicsIds([...filteredSelectecClinics, ...event]);
    },
    [selectedClinicsIds, spokeMap]
  );

  return (
    <>
      <LibSelect
        mode="multiple"
        placeholder="Clinics"
        value={selectedClinicsNames} //it shows in the result box
        onDeselect={onDeselect}
        dropdownRender={() => (
          <TelehealthWrapper>
            {hubClinics?.map(clinicHub => (
              <div key={clinicHub?.id}>
                <Checkbox
                  checked={isClinicChecked(clinicHub?.id)}
                  onChange={(event: any) => checkHubClinics(event, clinicHub)}
                >
                  {`${clinicHub.name} (${clinicHub.abbreviation})`}
                </Checkbox>
                <CheckboxGroup
                  options={spokeMapName.get(clinicHub.id)}
                  name="Spokeclinics"
                  value={selectedClinicsIds}
                  onChange={(event: number[]) => checkSpoke(event, clinicHub)}
                ></CheckboxGroup>
              </div>
            ))}
          </TelehealthWrapper>
        )}
      ></LibSelect>
      <FormError name="clinics" errors={errors} />
    </>
  );
};

export default React.memo(ClinicSelect);
