import { Input } from 'api/sharedComponents/reactHookFormComponents';
import { IProvider } from 'model/v2';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import { TelehealthWrapper } from '../style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

interface Props {
  type: string;
  providersMap?: Map<any, IProvider>;
  index?: string;
  telehealthLinks?: string[] | undefined;
  isCheckedCard?: boolean;
}

const TelehealthInput: React.FC<Props> = ({
  type,
  providersMap,
  index,
  telehealthLinks,
  isCheckedCard
}: Props) => {
  const methods = useFormContext();
  const [telehealthLinkVal, setTelehealthLinkVal] = useState<string>();

  const { provider } = methods.watch(['provider']);

  const [showDropdown, setDropdown] = useState<boolean>(false);

  const isAppointmentForm = type === 'appointmentForm';
  const dynamicClass = isAppointmentForm ? 'hybrid-input' : 'smart-input';

  const handleFocus = () => {
    setDropdown(true);
  };

  const handleBlur = (event: {
    target: { value: React.SetStateAction<string | undefined> };
  }) => {
    setTelehealthLinkVal(event.target.value);
    setTimeout(() => setDropdown(false), 300);
  };

  const initializeLinksList = () => {
    if (providersMap && provider && isAppointmentForm) {
      const extractedProvider: IProvider[] | undefined = [
        ...providersMap.values()
      ].filter(providerSelected => providerSelected.id === provider.id);
      if (extractedProvider[0]?.telehealthLinks) {
        const linkMap = extractedProvider[0]?.telehealthLinks;
        const linksArray = [...linkMap];
        let sortedLinks: any = [];
        linksArray.map(link => {
          const linkObj: any = {
            label: link,
            value: link
          };
          sortedLinks.push(linkObj);
        });
        return sortedLinks;
      }
    } else if (telehealthLinks?.length) {
      const linkMap = telehealthLinks;
      const linksArray = [...linkMap];
      let sortedLinks: any = [];
      linksArray.map(link => {
        const linkObj: any = {
          label: link,
          value: link
        };
        sortedLinks.push(linkObj);
      });
      return sortedLinks;
    } else {
      return undefined;
    }
  };

  const setInitialList = () => {
    if ((provider && providersMap) || (telehealthLinks && index)) {
      const linksData = initializeLinksList();
      return linksData;
    }
  };

  const [linksList, setLinksList] = useState<any | undefined>(setInitialList());

  React.useEffect(() => {
    const fetchedLinks = initializeLinksList();
    setLinksList(fetchedLinks);
  }, [provider?.id]);
  React.useEffect(() => {
    if (isAppointmentForm && telehealthLinkVal !== undefined) {
      methods.setValue('telehealthLink', telehealthLinkVal, {
        shouldValidate: true
      });
    } else if (index && telehealthLinkVal !== undefined) {
      methods.setValue(index!, telehealthLinkVal, { shouldValidate: true });
    }
  }, [telehealthLinkVal]);

  React.useEffect(() => {
    const fetchedLinks = initializeLinksList();
    setLinksList(fetchedLinks);
    if (fetchedLinks && fetchedLinks.length === 1) {
      setTelehealthLinkVal(fetchedLinks[0].value);
    }
  }, [telehealthLinks]);

  const onLinkClick = (link: string) => {
    setTelehealthLinkVal(link);
  };

  return (
    <TelehealthWrapper>
      <div className={dynamicClass}>
        <div>
          <FormItem optional={false} label="Telehealth Link">
            <Controller
              name={index ? index : 'telehealthLink'}
              control={methods.control}
              defaultValue={
                telehealthLinks?.length! === 1 ? telehealthLinkVal : ''
              }
              render={fieldProps => (
                <Input
                  placeholder="Paste link here"
                  field={fieldProps}
                  errors={methods.errors}
                  onFocus={handleFocus}
                  value={telehealthLinkVal}
                  disabled={
                    isCheckedCard !== undefined ? !isCheckedCard! : false
                  }
                  onBlurCapture={handleBlur}
                />
              )}
            />
          </FormItem>
          <FontAwesomeIcon className="drop-icon" icon={faCaretDown} />
        </div>
        {showDropdown && linksList?.length > 0 && (
          <div className="dropdown-wrapper">
            {linksList.map((link: any, index: number) => {
              return (
                <div
                  onClick={() => onLinkClick(link.value)}
                  key={index}
                  className="link-wrapper"
                >
                  <span>{link.label}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </TelehealthWrapper>
  );
}; // component ends here

export default React.memo(TelehealthInput);
