import React, { useCallback, useMemo, useEffect, useContext } from 'react';
import ModalSemantic from 'components/modalSemantic';
import { useReactiveVar } from '@apollo/client';
import { selectedItemsToCancelAddAdminVar } from 'utils/cache/calendar';
import {
  useBulkRecurringEventsCount,
  useDeleteWhenCancelAppointmentsIds
} from 'api/graphql/v2/hooks/recurringEvents';
import { plannerContext } from 'pages/MainCalendarPage';
import { ICalendarEvent } from 'model/calendar/events';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GET_OBSERVATION_OF_LEAD_EVENT,
  GET_SINGLE_EVENT_OBSERVER_COUNT,
  GET_RECCURING_OBSERVER_COUNT
} from 'api/graphql/v2/queries/Events';
import CancelAddAdminForm from './CancelAddAdminForm';

interface Props {
  isCancelAddAdminModalOpen: boolean;
  setIsCancelAddAdminModalOpen: (open: boolean) => void;
}
const CancelAddAdminModal: React.FC<Props> = ({
  isCancelAddAdminModalOpen,
  setIsCancelAddAdminModalOpen
}: Props) => {
  const [saving, setSaving] = React.useState(false);
  const selectedItemsToCancel = useReactiveVar(
    selectedItemsToCancelAddAdminVar
  );
  console.log('selectedItemsToCancel', selectedItemsToCancel);
  const [SelectedItemIdToCancel, setSelectedItemIdToCancel] = React.useState(0);
  const [CountForBulkCancel, SeCountForBulkCancel] = React.useState(0);
  const { ShadowFeatureAvailability } = useContext(plannerContext);
  console.log(CountForBulkCancel, 'CountForBulkCancel axon');

  const onModalClose = useCallback(() => {
    setIsCancelAddAdminModalOpen(false);
  }, [setIsCancelAddAdminModalOpen]);
  const [
    getObservationOfLeadEvent,
    { data: observationOfLeadEvent }
  ] = useLazyQuery(GET_OBSERVATION_OF_LEAD_EVENT, {
    variables: {
      id: SelectedItemIdToCancel
    },
    fetchPolicy: 'cache-and-network'
  });

  const SelecteItemFunction = () => {
    const SelecetedArray = [...selectedItemsToCancel.values()];
    return SelecetedArray.map(item => item.id);
  };

  const [
    SingleeventObserverHandle,
    { data: getLeadEventIdWithMasterId }
  ] = useLazyQuery(GET_SINGLE_EVENT_OBSERVER_COUNT, {
    variables: {
      ids: SelecteItemFunction()
    },
    fetchPolicy: 'cache-and-network'
  });

  const [
    RecurringeventObserverHandle,
    { data: ObservationCountOfSeriesFromLeadEventId }
  ] = useLazyQuery(GET_RECCURING_OBSERVER_COUNT, {
    variables: {
      ids: SelecteItemFunction()
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (ShadowFeatureAvailability) {
      getObservationOfLeadEvent();
      SingleeventObserverHandle();
      RecurringeventObserverHandle();
      // if(recurringBulkEventsCount){
      SeCountForBulkCancel(SelectedItemIdToCancel);
      // }
    }
  }, [getObservationOfLeadEvent, SelectedItemIdToCancel]);

  const disableCloseWhileSaving = useCallback(() => {
    if (saving) return;
    onModalClose();
  }, [onModalClose, saving]);

  const selectedEventHaveRecurringEventsIds = useMemo(() => {
    const selectedEvents = [...selectedItemsToCancel.values()];

    if (selectedEvents.length === 1) {
      setSelectedItemIdToCancel(selectedEvents[0].id!);
    }
    selectedEvents.filter(
      (event: ICalendarEvent) => event.recurrencePattern?.recurringEvery! > 0
    );
    return selectedEvents.map(event => event.id);
  }, [selectedItemsToCancel]);

  const {
    recurringBulkEventsCount,
    recurringBulkEventsCountLoading
  } = useBulkRecurringEventsCount(
    selectedEventHaveRecurringEventsIds,
    selectedEventHaveRecurringEventsIds.length === 0
  );
  useEffect(() => {
    // if(recurringBulkEventsCount?.getBulkRecurringEventsCount === 0)
  }, [recurringBulkEventsCount]);

  const {
    deleteWhenCancelAppointmentsIds,
    deleteWhenCancelAppointmentsIdsLoading
  } = useDeleteWhenCancelAppointmentsIds(
    [...selectedItemsToCancel.values()].length === 0
  );

  return (
    <ModalSemantic
      open={
        isCancelAddAdminModalOpen! &&
        !recurringBulkEventsCountLoading &&
        !deleteWhenCancelAppointmentsIdsLoading
      }
      onClose={disableCloseWhileSaving}
      modalWidth={430}
      trigger={<div></div>}
      content={
        <CancelAddAdminForm
          onClose={onModalClose}
          getLeadEventIdWithMasterId={getLeadEventIdWithMasterId!}
          ObservationCountOfSeriesFromLeadEventId={
            ObservationCountOfSeriesFromLeadEventId!
          }
          saving={saving}
          setSaving={setSaving}
          observationOfLeadEvent={observationOfLeadEvent}
          recurringBulkEventsCount={
            recurringBulkEventsCount?.getBulkRecurringEventsCount
          }
          deleteWhenCancelAppointmentsIds={
            deleteWhenCancelAppointmentsIds?.deleteWhenCancelAppointmentsIds
          }
        />
      }
    />
  );
};
export default React.memo(CancelAddAdminModal);
