import { clientsFragment } from 'api/graphql/v2/fragments/Client';
import gql from 'graphql-tag';

export const UPDATE_CLIENT = gql`
  mutation($client: ClientInput!) {
    updateClient(data: $client) {
      ...ClientData
    }
  }
  ${clientsFragment.ClientData}
`;

export const UPDATE_CLIENT_ROSTER = gql`
  mutation($client: ClientRosterInput!) {
    updateClientRoster(data: $client)
  }
`;

export const ADD_CLIENT = gql`
  mutation($client: ClientInput!) {
    addClient(data: $client) {
      ...ClientData
    }
  }
  ${clientsFragment.ClientData}
`;

export const SAVE_CLIENT_ADDRESS = gql`
  mutation($clientAddress: AddressInput!) {
    saveClientAddress(data: $clientAddress) {
      id
    }
  }
`;
