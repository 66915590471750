import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { CarePlanWrapper } from '../style';
import { ProviderFilterWrapper } from './style';
import QuestionIcon from 'assets/img/icon-question.svg';
import { useFormContext } from 'react-hook-form';
import ProviderSelector from './ProviderSelector';

import ClinicSelector from './ClinicSelector';
import { useClinicDataMap } from 'api/graphql/v2/hooks/clinics';
import { IClinic } from 'model/v2';

import LocationSelector from './LocationSelector';

import SpecialtySelector from './SpecialtySelector';
import { useApolloClient } from '@apollo/react-hooks';
import {
  GET_ABA_PRECALCULATION_PROVIDERS,
  GET_DT_PROVIDERS_BY_APPT_AND_CLINIC,
  GET_MED_PRECALCULATION_PROVIDERS
} from 'api/graphql/v2/queries/SmartSchedule';
import Loader from 'views/components/ui/content/Loader';
import { ISpecialitiesOptionsList } from 'model/v2/providerSpecialty';
import { IProvider } from 'model/v2';
import SelectedProviders from './SelectedProviders';
import SelectedClinics from './SelectedClinics';
import SelectedLocations from './SelectedLocations';
import { SearchWrapper } from 'components/calendar/calendarFiltersAndSearch/style';
import searchIcon from 'assets/img/bars-search-search-bar.svg';
import { APPOINTMENT_CATEGORY_SMART } from 'utils/constants/lists';
import { Popover } from 'antd';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
interface Props {
  isSingleAppt: boolean | undefined;
  disabled: boolean;
}
const ProviderFilter: React.FC<Props> = ({ isSingleAppt, disabled }) => {
  const apolloClient = useApolloClient();
  const { setValue, watch } = useFormContext();
  const { client, appType, apptCategoryType } = watch([
    'client',
    'appType',
    'apptCategoryType',
    'clinicFilter'
  ]);
  const [open, setOpen] = useState<boolean>(false);
  const [isLoadingProviders, setIsLoadingProviders] = useState<boolean>(false);

  const handleClick = () => {
    if (client && client.id && appType) setOpen(open => !open);
  };

  useEffect(() => {
    if (client && client.id && appType) return;
    setOpen(false);
  }, [client, appType]);

  const [selectedProvidersList, setSelectedProvidersList] = useState(
    new Set<number>()
  );
  const [selectedSpecialtiesList, setSelectedSpecialtiesList] = useState(
    new Set<number>()
  );
  const [filteredProviders, setFilteredProviders] = useState<IProvider[]>([]);
  const [unfilteredProviders, setUnfilteredProviders] = useState<IProvider[]>(
    []
  );
  const [filteredSpecialties, setFilteredSpecialties] = useState<
    ISpecialitiesOptionsList[]
  >([]);

  const { clinicsMap } = useClinicDataMap('New');
  const allClinics: IClinic[] = Array.from(clinicsMap.values());
  const [selectedClinicsList, setSelectedClinicsList] = useState(
    new Set<number>()
  );

  const allLocations = [
    { id: 1, name: 'In Clinic' },
    { id: 2, name: 'Offsite' },
    { id: 3, name: 'Telehealth' }
  ];
  const [selectedLocationsList, setSelectedLocationsList] = useState(
    new Set<number>()
  );

  const getProviderQuery = useCallback(() => {
    if (apptCategoryType === APPOINTMENT_CATEGORY_SMART.ABA) {
      return GET_ABA_PRECALCULATION_PROVIDERS;
    } else if (apptCategoryType === APPOINTMENT_CATEGORY_SMART.DT) {
      return GET_DT_PROVIDERS_BY_APPT_AND_CLINIC;
    } else {
      return GET_MED_PRECALCULATION_PROVIDERS;
    }
  }, [apptCategoryType]);

  const onCompleteProvidersLoading = useCallback(
    providers => {
      const newProvidersSet = new Set<number>();
      providers?.forEach((it: IProvider) => {
        if (selectedProvidersList.has(it.id!)) {
          newProvidersSet.add(it.id!);
        }
      });
      setSelectedProvidersList(newProvidersSet);
      setSelectedSpecialtiesList(newProvidersSet);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProvidersList, selectedSpecialtiesList]
  );

  useEffect(() => {
    setFilteredSpecialties([]);
    const newSpecialties: ISpecialitiesOptionsList[] = [];
    unfilteredProviders.forEach((provider: any) => {
      const specialty = newSpecialties.find(
        specialty => specialty.id === provider.speciality.id
      );
      if (specialty) return;
      const addedSpecialty = {
        id: provider.speciality!.id,
        title: provider.speciality!.title
      };
      newSpecialties.push(addedSpecialty);
    });
    setFilteredSpecialties(newSpecialties);
  }, [unfilteredProviders]);

  useEffect(() => {
    if (selectedSpecialtiesList.size > 0) {
      setFilteredProviders([]);
      const newFilteredProviders: any[] = [];
      selectedSpecialtiesList.forEach((specialityId: number) => {
        unfilteredProviders.forEach((provider: IProvider) => {
          if (provider!.speciality!.id === specialityId)
            newFilteredProviders.push(provider);
        });
      });
      setFilteredProviders(newFilteredProviders);
    } else {
      setFilteredProviders(unfilteredProviders);
    }
  }, [selectedSpecialtiesList]);

  useEffect(() => {
    const fetchProviders = async () => {
      if (client && client.id && appType) {
        setIsLoadingProviders(true);
        const variables = {
          isClientNetwork: true,
          clientId: client?.id,
          clinicId: client?.clinic?.id,
          appointmentTypeId: appType
        };
        const { data } = await apolloClient.query({
          query: getProviderQuery(),
          variables,
          fetchPolicy: 'no-cache'
        });
        const providers =
          apptCategoryType === APPOINTMENT_CATEGORY_SMART.ABA
            ? data.getSmartABAProviders
            : apptCategoryType === APPOINTMENT_CATEGORY_SMART.DT
            ? data.getSmartDTProviders
            : apptCategoryType === APPOINTMENT_CATEGORY_SMART.MED
            ? data.getSmartMEDProviders
            : null;
        setFilteredProviders(providers);
        setUnfilteredProviders(providers);
        onCompleteProvidersLoading(providers);
        onClearSelectedProvidersList();
        setIsLoadingProviders(false);
      }
    };
    fetchProviders();
  }, [client?.id, appType]);

  const allProvidersMap = useMemo(() => {
    const providers: IProvider[] = unfilteredProviders || [];
    const map = new Map<number, IProvider>();

    providers.forEach(it => {
      map.set(it.id!, it);
    });
    return map;
  }, [unfilteredProviders]);
  const onClearSelectedProvidersList = useCallback(() => {
    setSelectedProvidersList(new Set());
  }, [setSelectedProvidersList]);
  const onRemoveProvider = useCallback(
    (id: number) => {
      selectedProvidersList.delete(id);
      setSelectedProvidersList(new Set(selectedProvidersList));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProvidersList]
  );

  const onClearSelectedClinicsList = useCallback(() => {
    setSelectedClinicsList(new Set());
  }, [setSelectedClinicsList]);
  const onRemoveClinic = useCallback(
    (id: number) => {
      selectedClinicsList.delete(id);
      setSelectedClinicsList(new Set(selectedClinicsList));
    },
    [selectedClinicsList, setSelectedClinicsList]
  );

  const allLocationsMap = useMemo(() => {
    const map = new Map<
      number,
      {
        name: string;
        id: number;
      }
    >();

    allLocations.forEach(it => {
      map.set(it.id!, it);
    });
    return map;
  }, [allLocations]);
  const onClearSelectedLocationsList = useCallback(() => {
    setSelectedLocationsList(new Set());
  }, [setSelectedLocationsList]);
  const onRemoveLocation = useCallback(
    (id: number) => {
      selectedLocationsList.delete(id);
      setSelectedLocationsList(new Set(selectedLocationsList));
    },
    [setSelectedLocationsList, selectedLocationsList]
  );

  useEffect(() => {
    setValue('providerFilter.preferredProviders', [...selectedProvidersList]);
    setValue('providerFilter.preferredSpecialties', [
      ...selectedSpecialtiesList
    ]);
    setValue('clinicFilter.clinicIds', [...selectedClinicsList]);
    const isInClinic = selectedLocationsList.has(allLocations[0].id);
    const isOffsite = selectedLocationsList.has(allLocations[1].id);
    const isTelehealth = selectedLocationsList.has(allLocations[2].id);
    setValue('locationFilter', {
      isInClinic,
      isOffsite,
      isTelehealth
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProvidersList,
    selectedSpecialtiesList,
    selectedClinicsList,
    selectedLocationsList
  ]);

  /**
   * @todo clinic drop down options should be PROVIDER_FILTER_CLINICS_TYPE
   * @see PROVIDER_FILTER_CLINICS_TYPE src/utils/constants/lists.ts
   * but it's changed to only one option =>> client clinic network
   * so we may return to use all options list again and change ui to dropdown list
   */
  const popoverContentRecurring = useMemo(() => {
    return (
      <p>
        Filtering by provider will find a more specific set of providers with
        matching availability. If a provider is unavailable, they will not
        appear.”
      </p>
    );
  }, []);
  const popoverContentSingle = useMemo(() => {
    return (
      <p>
        Filtering by location and clinic will find day availabilities based in
        that clinic with the matching locations from providers based in that
        clinic
      </p>
    );
  }, []);

  const getContentRecurringSearch = () => {
    return (
      <ProviderFilterWrapper>
        <Grid.Row className="row-position">
          <Grid.Column>Client's Clinic Network</Grid.Column>
        </Grid.Row>
        {isLoadingProviders && (
          <p className="provider-loader">
            <Loader />
          </p>
        )}
        {!isLoadingProviders && (
          <>
            {getFeatureAvailability(FEATURES.SPECIALTY_FILTER) && (
              <Grid.Row>
                <SearchWrapper>
                  <SpecialtySelector
                    allSpecialties={filteredSpecialties || []}
                    selectedSpecialtiesList={selectedSpecialtiesList}
                    setSelectedSpecialtiesList={setSelectedSpecialtiesList}
                  />
                </SearchWrapper>
              </Grid.Row>
            )}
            <Grid.Row>
              <SearchWrapper>
                <img src={searchIcon} className="search-icon" alt="" />
                <ProviderSelector
                  allProviders={filteredProviders || []}
                  selectedProvidersList={selectedProvidersList}
                  setSelectedProvidersList={setSelectedProvidersList}
                />
              </SearchWrapper>
            </Grid.Row>

            {selectedProvidersList.size > 0 && (
              <SelectedProviders
                selectedProvidersList={[...selectedProvidersList]}
                allProvidersMap={allProvidersMap}
                onClearSelectedList={onClearSelectedProvidersList}
                onRemoveProvider={onRemoveProvider}
              />
            )}
          </>
        )}
      </ProviderFilterWrapper>
    );
  };

  const getContentSingleSearch = () => {
    return (
      <ProviderFilterWrapper>
        <Grid.Row>
          <SearchWrapper>
            <img src={searchIcon} className="search-icon" alt="" />
            <ClinicSelector
              allClinics={allClinics}
              selectedClinicsList={selectedClinicsList}
              setSelectedClinicsList={setSelectedClinicsList}
            />
          </SearchWrapper>
        </Grid.Row>
        <Grid.Row>
          {selectedClinicsList.size > 0 && (
            <SelectedClinics
              selectedClinicsList={[...selectedClinicsList]}
              allClinicsMap={clinicsMap}
              onClearSelectedList={onClearSelectedClinicsList}
              onRemoveClinic={onRemoveClinic}
            />
          )}
        </Grid.Row>
        <Grid.Row>
          <SearchWrapper>
            <img src={searchIcon} className="search-icon" alt="" />
            <LocationSelector
              allLocations={allLocations}
              selectedLocationsList={selectedLocationsList}
              setSelectedLocationsList={setSelectedLocationsList}
            />
          </SearchWrapper>
        </Grid.Row>
        <Grid.Row>
          {selectedLocationsList.size > 0 && (
            <SelectedLocations
              selectedLocationsList={[...selectedLocationsList]}
              allLocationsMap={allLocationsMap}
              onClearSelectedList={onClearSelectedLocationsList}
              onRemoveLocation={onRemoveLocation}
            />
          )}
        </Grid.Row>
        <Grid.Row>
          <SearchWrapper>
            <img src={searchIcon} className="search-icon" alt="" />
            <ProviderSelector
              allProviders={filteredProviders || []}
              selectedProvidersList={selectedProvidersList}
              setSelectedProvidersList={setSelectedProvidersList}
            />
          </SearchWrapper>
        </Grid.Row>

        {selectedProvidersList.size > 0 && (
          <SelectedProviders
            selectedProvidersList={[...selectedProvidersList]}
            allProvidersMap={allProvidersMap}
            onClearSelectedList={onClearSelectedProvidersList}
            onRemoveProvider={onRemoveProvider}
          />
        )}
      </ProviderFilterWrapper>
    );
  };

  return (
    <div
      style={disabled ? { pointerEvents: 'none', opacity: '50%' } : undefined}
    >
      <CarePlanWrapper>
        <Accordion>
          <Accordion.Title active={true} onClick={handleClick}>
            <Grid>
              <div className="rectangle"></div>
              <Grid.Row className="care-plan" floated="left" width={60}>
                Filter by Provider and Location
                <Popover
                  placement="bottomLeft"
                  content={
                    isSingleAppt
                      ? popoverContentSingle
                      : popoverContentRecurring
                  }
                  title="Filtering by provider"
                  trigger="hover"
                >
                  <img
                    alt="ques"
                    src={QuestionIcon}
                    width={22}
                    style={{ paddingLeft: 4 }}
                  />
                </Popover>
                <Grid.Column floated="right" width="1" className="arrow">
                  <Icon name="angle down" />
                </Grid.Column>
              </Grid.Row>
              <div className="rectangle"></div>
            </Grid>
          </Accordion.Title>
          <Accordion.Content
            active={open}
            content={
              isSingleAppt
                ? getContentSingleSearch()
                : getContentRecurringSearch()
            }
          />
        </Accordion>
      </CarePlanWrapper>
    </div>
  );
};
export default React.memo(ProviderFilter);
