import { IProvider } from 'model/v2';
import React from 'react';
import { Grid } from 'semantic-ui-react';

interface IProps {
  provider?: IProvider;
}

const AppointmentTypes = ({ provider }: IProps) => {
  const appointmentTypes = provider?.appointmentTypes;

  return (
    <Grid.Column className="appointment-types" width={4}>
      <div className="title" style={{ paddingTop: 10 }}>
        Show Appointment Types
      </div>
      <div className="section-body">
        {appointmentTypes &&
          appointmentTypes.map((appointmentType, index) => (
            <span
              className="appt-types"
              style={{
                color: appointmentType.headerColor,
                borderColor: appointmentType.headerColor
              }}
              key={index}
            >
              {appointmentType.title}
            </span>
          ))}
      </div>
    </Grid.Column>
  );
};

export default React.memo(AppointmentTypes);
