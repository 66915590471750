import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { IClinic, IFullAppointmentType, IProvider } from 'model/v2';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CLINIC_PROVIDERS, LOCATION_TYPE_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { getCurrentClinicsOptionsV2 } from 'views/components/forms/appointment/components/MainFields/appointmentClinicHook';
import { FormItem } from 'views/containers/form';
import { ClinicFormWrapper } from '../style';
import AppointmentProvider from './AppointmentProvider';
import RoomsForm from './RoomsForm';
import TelehealthInput from './TelehealthInput';

const clinicPreferences = mapToOption(CLINIC_PROVIDERS);

interface Props {
  clinicsMap: Map<any, IClinic>;
  apptTypes: IFullAppointmentType[];
  providersMap: Map<any, IProvider>;
  loadingClinics: boolean;
}
const ClinicForm: React.FC<Props> = ({
  clinicsMap,
  apptTypes,
  providersMap,
  loadingClinics
}: Props) => {
  const methods = useFormContext();
  const {
    client,
    clinicPreference,
    appType,
    locationType,
    smart,
    clinic
  } = methods.watch([
    'client',
    'clinicPreference',
    'appType',
    'locationType',
    'smart',
    'clinic'
  ]);
  const onClinicPreferenceChange = React.useCallback(() => {
    methods.setValue('selectedOpening', null);
  }, [methods]);

  const { clinicOptionsList, filteredStaffList } = React.useMemo(() => {
    const appointmentType = apptTypes.find(x => x.id === appType);
    const { clinicOptionsList, filteredStaffList } = getCurrentClinicsOptionsV2(
      client,
      clinicsMap,
      appointmentType,
      clinicPreference,
      smart
    );
    return {
      clinicOptionsList,
      filteredStaffList
    };
  }, [clinicsMap, apptTypes, appType, client, clinicPreference, smart]);
  React.useEffect(() => {
    if (locationType === LOCATION_TYPE_LIST[2].id) {
      methods.setValue('clinic', { ...client?.clinic });
    }
  }, [locationType]);
  const onClinicSelect = () => {
    methods.setValue('room.id', null);
  };

  return (
    <ClinicFormWrapper>
      {locationType !== LOCATION_TYPE_LIST[1].id && (
        <Controller
          name={'clinic.id'}
          control={methods.control}
          render={fieldProps => (
            <FormItem optional={false} label="Clinic" xs={100} sm={100}>
              <Select
                className="appt-category-type"
                placeholder="Select Clinic"
                options={clinicOptionsList}
                field={fieldProps}
                onSelect={onClinicSelect}
                errors={methods.errors}
                disabled={loadingClinics}
              />
            </FormItem>
          )}
        />
      )}
      {locationType === LOCATION_TYPE_LIST[0].id && (
        <RoomsForm
          clinicId={clinic?.id}
          smart={smart}
          clinicsMap={clinicsMap}
        />
      )}
      <h2 className="section-title">Provider Preference</h2>
      <FormItem optional={false} label="Clinic Providers" xs={100} sm={100}>
        <Controller
          name={'clinicPreference'}
          control={methods.control}
          render={fieldProps => (
            <Select
              className="appt-category-type"
              defaultValue={clinicPreferences[0].value}
              options={clinicPreferences}
              onSelect={onClinicPreferenceChange}
              field={fieldProps}
              errors={methods.errors}
            />
          )}
        />
      </FormItem>
      <AppointmentProvider
        filteredStaffList={filteredStaffList}
        isProviderDisabled={false}
        providersMap={providersMap}
      />
      {locationType === LOCATION_TYPE_LIST[2].id && (
        <TelehealthInput type="appointmentForm" providersMap={providersMap} />
      )}
    </ClinicFormWrapper>
  );
};
export default React.memo(ClinicForm);
