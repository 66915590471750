import * as yup from 'yup';
import {
  FIFTEEN_MINUTES_IN_MS,
  TEN_MINUTES_IN_MS,
  FIVE_MINUTES_IN_MS
} from 'utils/constants/availabilityTemplate';

export const TEST_FIFTEEN_MIN_INTERVAL = (
  start: Date | undefined,
  end: Date | undefined
) => {
  return (
    (end?.getTime() || 0) - (start?.getTime() || 0) >= FIFTEEN_MINUTES_IN_MS
  );
};
export const TEST_IF_SAME = (
  start: Date | undefined,
  end: Date | undefined
) => {
  return (end?.getTime() || 0) - (start?.getTime() || 0) !== 0;
};
export const TEST_TEN_MIN_INTERVAL = (
  start: Date | undefined,
  end: Date | undefined
) => {
  return (end?.getTime() || 0) - (start?.getTime() || 0) >= TEN_MINUTES_IN_MS;
};

export const TEST_FIVE_MIN_INTERVAL = (
  start: Date | undefined,
  end: Date | undefined
) => {
  return (end?.getTime() || 0) - (start?.getTime() || 0) >= FIVE_MINUTES_IN_MS;
};

export const EDIT_AVAILABILITY_BLOCK = yup.object().shape({
  appt_type: yup.string().required('You should Provide appointment Type'),
  start: yup.date().required('Start time is required'),
  end: yup
    .date()
    .min(yup.ref('start'), "End time can't be before start time")
    .test(
      'isValidEndDate',
      'difference between start and end time should be equal or greater than 5 mins',
      function(end: Date | undefined) {
        const { start } = this.parent;
        return TEST_FIVE_MIN_INTERVAL(start, end);
      }
    )
    .required('End time is required'),
  sub_block_length: yup.number(),
  location: yup.number().required('You should provide location'),
  notes_amount: yup.number(),
  notes_duration: yup.number(),
  recurringDays: yup
    .array()
    .of(yup.number())
    .required('You should select a day')
});

export const EDIT_AVAILABILITY_BLOCK_PROVIDER = yup.object().shape({
  appt_type: yup.string().required('You should Provide appointment Type'),
  start: yup.date().required('Start time is required'),
  end: yup
    .date()
    .min(yup.ref('start'), "End time can't be before start time")
    .required('End time is required')
    .test(
      'isValidEndDate',
      'difference between start and end time should be equal or greater than 5 mins',
      function(end: Date | undefined) {
        const { start } = this.parent;
        return TEST_FIVE_MIN_INTERVAL(start, end);
      }
    ),
  sub_block_length: yup.number(),
  clinics: yup
    .array()
    .of(yup.number())
    .min(1, 'You should select a clinic')
    .required('You should select a clinic'),
  location: yup.number().required('You should provide location'),
  recurringDays: yup
    .array()
    .of(yup.number())
    .required('You should select a day')
});
