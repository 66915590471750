import React from 'react';
import { LibDatepicker } from './datepicker.style';

const dateFormat = 'MM/DD/YYYY';

const Datepicker = ({ ...restProps }) => {
  return <LibDatepicker format={dateFormat} {...restProps} />;
};

export default Datepicker;
export { Datepicker };
