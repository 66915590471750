import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Content from 'views/components/ui/content';
import Table from 'views/containers/table';
import { CLINIC_CLIENTS_COLUMNS } from 'utils/constants/tables/clients';
import { useQuery } from '@apollo/react-hooks';
import { GET_CLINIC_CLIENTS } from 'api/graphql/v2/queries/Clients';
import { getClientsData } from 'utils/mappers/response/clients';
import { updateCacheAfterFetchMore } from 'authentication-apollo/apolloClientCache';
import { formatInitial } from 'utils/format';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from 'utils/constants/default';
import { IClient } from 'model/v2/clients';

const columns = CLINIC_CLIENTS_COLUMNS;

const mapClientsData = (clients: IClient[]) =>
  getClientsData(clients).map((client: IClient) => ({
    ...client,
    key: client.id,
    gender: formatInitial(client.profile?.gender)
  }));

interface IProps {
  clinicId: number;
}

const Clients: React.FC<IProps> = ({ clinicId }: IProps) => {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [nextRecord, setNextRecord] = useState(0);

  const onPageChange = (page: number) => {
    setPage(page);
    setNextRecord(page * DEFAULT_PAGE_SIZE - DEFAULT_PAGE_SIZE);
    fetchMore({
      variables: { from: nextRecord },
      updateQuery: (prev, { fetchMoreResult }) =>
        updateCacheAfterFetchMore({
          resource: 'clinicClients',
          prevData: prev,
          fetchMoreResult
        })
    });
  };

  const { loading, error, data, fetchMore } = useQuery(GET_CLINIC_CLIENTS, {
    variables: {
      clinicId,
      from: nextRecord,
      size: DEFAULT_PAGE_SIZE,
      sort: DEFAULT_SORT
    }
  });

  return (
    <div>
      <div className="inner-wrap">
        <Content loading={loading} error={error} data={data}>
          {({ clinicClients }) => (
            <>
              <Table
                columns={columns}
                data={mapClientsData(clinicClients.clients)}
                total={clinicClients.count}
                page={page}
                onPageChangeCallback={onPageChange}
                onRowSelect={(row: IClient) =>
                  history.push('/clients/' + row.id)
                }
              />
            </>
          )}
        </Content>
      </div>
    </div>
  );
};
export default Clients;
