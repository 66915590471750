import { ITableColumn } from 'views/components/table/type';
import {
  MEDAppointmentSubType,
  IMEDAppointmentSubType
} from 'model/MEDAppointmentSubtype';
import { TableType } from 'model/tableType';
import { ILookup } from 'model/lookup';
import { sortNumerical } from 'utils/sort';
export const MED_APPOINTMENT_SUBTYPE_COLUMNS: ITableColumn[] = [
  {
    label: 'Title',
    key: MEDAppointmentSubType.title,
    notWrap: true,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, MEDAppointmentSubType.title),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, MEDAppointmentSubType.title)
    }
  },
  {
    label: 'Super type',
    key: MEDAppointmentSubType.superType,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, MEDAppointmentSubType.superType),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, MEDAppointmentSubType.superType)
    }
  },
  {
    label: 'ID',
    key: MEDAppointmentSubType.id,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sortNumerical(
          a[MEDAppointmentSubType.id]!,
          b[MEDAppointmentSubType.id]!
        ),
      reverse: (a: TableType, b: TableType) =>
        sortNumerical(
          b[MEDAppointmentSubType.id]!,
          a[MEDAppointmentSubType.id]!
        )
    }
  },
  { label: 'Athena Id', key: MEDAppointmentSubType.athenaId },
  {
    label: 'CR Name',
    key: MEDAppointmentSubType.crName,
    comp: {
      sort: (a: TableType, b: TableType) =>
        sort(a, b, MEDAppointmentSubType.crName),
      reverse: (a: TableType, b: TableType) =>
        sort(b, a, MEDAppointmentSubType.crName)
    }
  },
  { label: 'CR Type', key: MEDAppointmentSubType.crType },
  { label: 'Duration', key: MEDAppointmentSubType.duration }
];
const sort = (a: TableType, b: TableType, sortField: MEDAppointmentSubType) => {
  let valueA = (a as IMEDAppointmentSubType)[sortField];
  let valueB = (b as IMEDAppointmentSubType)[sortField];
  if (sortField === MEDAppointmentSubType.superType) {
    valueA = (valueA as ILookup).value;
    valueB = (valueB as ILookup).value;
  }
  return valueA!.toString().toLowerCase() > valueB!.toString().toLowerCase()
    ? -1
    : 1;
};
