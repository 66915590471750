import { TableType } from 'model/tableType';
import UnderlineTitle from 'views/components/underlineTitle';
import ColorCell from 'views/components/colorCell';
import { IAppointmentType, AppointmentType } from 'model/appointment';
import { IAppointmentTypeConfig } from 'model/tableConfigurations';

export const mapAppointmentTypes = (
  data: TableType[],
  addNew: (data?: TableType) => void
): IAppointmentTypeConfig[] =>
  (data as IAppointmentType[]).map(item => ({
    title: (
      <UnderlineTitle
        title={item[AppointmentType.title] || ''}
        onClick={() => {
          addNew(item);
        }}
      />
    ),
    id: item[AppointmentType.id],
    headerColor: <ColorCell color={item[AppointmentType.headerColor]!} />,
    backgroundColor: (
      <ColorCell color={item[AppointmentType.backgroundColor]!} />
    ),
    isEvaluation: item[AppointmentType.isEvaluation] ? 'Yes' : 'No',
    isActive: item[AppointmentType.ActiveStatus] ? 'Active' : 'Inactive',
    dayAvailabilityTypes: item[AppointmentType.dayAvailabilityTypes]
      ? `${item[AppointmentType.dayAvailabilityTypes]
          ?.map(elem => (elem === 'working' ? 'Care Sessions' : elem))
          .join(', ')}`
      : ``,
    salesForceTherapyMappings: item[AppointmentType.salesForceMapping]
      ? item[AppointmentType.salesForceMapping]?.salesforceTherapyTitle
      : null,
    paycodes: item[AppointmentType.paycodes]
  }));
