import { CheckListWrapper } from 'components/calendar/filters/style';
import { Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import React, { ChangeEvent, LegacyRef } from 'react';
import {
  ICalendarFilterData,
  ICalendarFilterOption
} from 'model/calendar/filters';
import DepartmentFilter from 'components/calendar/filters/departmentFilter';

export const searchValueContext = React.createContext('');
interface Props {
  filterDropdownExpand: Record<string, boolean>;
  specialtyFiltersRef: LegacyRef<HTMLDivElement> | undefined;
  handleGenericBlur: (
    e: React.FocusEvent<HTMLDivElement>,
    filterType: string
  ) => void;
  toggleSpecialtyList: (type: string) => void;
  handleGenericSearch: (
    _e: ChangeEvent<HTMLInputElement>,
    data: { value: string | number },
    typeOfSearch: string
  ) => void;
  hasSpecialties: boolean;
  searchValue: Record<string, string>;
  IsLoading: boolean;
  handleSpecialtySearchedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  checkedSpecialities: ICalendarFilterOption[];
  setCheckedSpecialities: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  setHasSpecialties: React.Dispatch<React.SetStateAction<boolean>>;
  clearSpecialty: (
    clearFilter: React.Dispatch<React.SetStateAction<ICalendarFilterOption[]>>,
    departMentFilter: React.Dispatch<
      React.SetStateAction<ICalendarFilterOption[]>
    >
  ) => void;
  Loader: React.FC;
  calendarFilterData: ICalendarFilterData;
  checkedDepartments: ICalendarFilterOption[];
  setCheckedDepartments: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
}
const ProviderDepartmentFilter = ({
  filterDropdownExpand,
  specialtyFiltersRef,
  handleGenericBlur,
  toggleSpecialtyList,
  handleGenericSearch,
  searchValue,
  hasSpecialties,
  IsLoading,
  Loader,
  calendarFilterData,
  handleSpecialtySearchedValues,
  checkedSpecialities,
  checkedDepartments,
  setCheckedSpecialities,
  setCheckedDepartments,
  setHasSpecialties,
  clearSpecialty
}: Props) => {
  return (
    <>
      <CheckListWrapper
        visible={filterDropdownExpand.specialty}
        height="300px"
        style={{ position: 'relative' }}
      >
        <div
          ref={specialtyFiltersRef}
          onBlur={e => handleGenericBlur(e, 'specialty')}
          tabIndex={-1}
        >
          <div
            className="filter-input_wrapper waitList_input_wrapper staff-input_wrapper"
            onClick={() => toggleSpecialtyList('specialty')}
          >
            <Input
              className="filter_input"
              placeholder={
                filterDropdownExpand.specialty ? 'Search' : 'Specialty'
              }
              onChange={event =>
                handleGenericSearch(
                  event,
                  { value: event.currentTarget.value },
                  'specialty'
                )
              }
            />
            {!filterDropdownExpand.specialty && (
              <FontAwesomeIcon className="icon" icon={faCaretDown} />
            )}
            {filterDropdownExpand.specialty && (
              <FontAwesomeIcon
                className="icon"
                icon={faCaretDown}
                style={{ opacity: 0 }}
              />
            )}
          </div>

          {filterDropdownExpand.specialty && (
            <>
              <searchValueContext.Provider value={searchValue.specialty}>
                <div
                  className="list_wrapper"
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    zIndex: 1,
                    width: 'calc(100% - 15px)'
                  }}
                >
                  <div className="scrollable_list">
                    {!hasSpecialties && (
                      <p className="no-results_filter">No results found</p>
                    )}
                    {IsLoading && <Loader />}
                    <DepartmentFilter
                      data={calendarFilterData || undefined}
                      handleSearchedValues={handleSpecialtySearchedValues}
                      checkedSpecialities={checkedSpecialities}
                      checkedDepartments={checkedDepartments}
                      setCheckedSpecialities={setCheckedSpecialities}
                      setCheckedDepartments={setCheckedDepartments}
                      setHasData={setHasSpecialties}
                    ></DepartmentFilter>
                  </div>
                  <div className="calender-filters_actions">
                    <span
                      onClick={() =>
                        clearSpecialty(
                          setCheckedDepartments,
                          setCheckedSpecialities
                        )
                      }
                    >
                      Clear all
                    </span>
                  </div>
                </div>
              </searchValueContext.Provider>
            </>
          )}
        </div>
      </CheckListWrapper>
    </>
  );
};
export default ProviderDepartmentFilter;
