import React from 'react';
import { WEEK } from 'utils/constants/default';
import DayCheckboxItem from './DayCheckboxItem';
import { DayCheckboxGroupWrapper } from './style';

interface IProps {
  activeDayIndex: number;
  setActiveDayIndex: (dayIndex: number) => void;
  daysWithAvailability: number[];
}

const DaysCheckboxGroup: React.FC<IProps> = ({
  activeDayIndex,
  setActiveDayIndex,
  daysWithAvailability
}) => {
  return (
    <DayCheckboxGroupWrapper>
      {WEEK.map((_, index) => (
        <DayCheckboxItem
          isActive={activeDayIndex === index}
          setActiveDayIndex={setActiveDayIndex}
          isBordered={daysWithAvailability.includes(index)}
          dayIndex={index}
        />
      ))}
    </DayCheckboxGroupWrapper>
  );
};

export default DaysCheckboxGroup;
