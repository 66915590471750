import React from 'react';
import { FormItem, RadioGroup, Checkbox } from 'views/containers/form';
import { Row } from 'antd';
import { Field, FastField } from 'formik';
import { YES_NO_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';

const yesNoOptions = mapToOption(YES_NO_LIST);

export const ClientPrivacyForm = React.memo(({ disabledFormFields }) => {
  return (
    <>
      <div className="form-title">Privacy</div>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Notices on File">
          <Field
            name="profile.noticesOnFile"
            render={fieldProps => (
              <Checkbox
                name="profile.noticesOnFile"
                checked={fieldProps.field.value}
                {...fieldProps}
                disabled={disabledFormFields.noticesOnFile}
              >
                Privacy Notice, Release of Billing Information and Assignment of
                Benefits
              </Checkbox>
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem label="Consent to Call" xs={16} sm={4}>
          <Field
            name="profile.consentToCall"
            render={fieldProps => (
              <RadioGroup
                value={fieldProps.field.value}
                options={yesNoOptions}
                name="profile.consentToCall"
                disabled={disabledFormFields.consentToCall}
                {...fieldProps}
              />
            )}
          />
        </FormItem>
      </Row>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem optional label="Medical History Authority" xs={16} sm={5}>
          <FastField
            name="profile.medicalHistoryAuthority"
            render={fieldProps => (
              <RadioGroup
                value={fieldProps.field.value}
                options={yesNoOptions}
                name="profile.medicalHistoryAuthority"
                disabled={disabledFormFields.medicalHistoryAuthority}
                {...fieldProps}
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  );
});
