import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button, Portal, Segment } from 'semantic-ui-react';
import {
  removeSelectedItemsToCancel,
  selectedItemsToCancelVar
} from 'utils/cache/calendar';
import { BulkCancelPopupWrapper } from './style';

interface Props {
  setIsBulkCancelModalOpen: (open: boolean) => void;
}

const BulkCancelPopup = ({ setIsBulkCancelModalOpen }: Props) => {
  const selectedItemsToCancel = useReactiveVar(selectedItemsToCancelVar);

  const onClickNevermind = useCallback(() => {
    removeSelectedItemsToCancel();
  }, []);

  const onClickCancel = useCallback(() => {
    setIsBulkCancelModalOpen(true);
  }, [setIsBulkCancelModalOpen]);

  return (
    <Portal open={selectedItemsToCancel.size > 0}>
      <BulkCancelPopupWrapper>
        <Segment className="cancel-popup-segment">
          <p>
            {' '}
            Would you like to cancel ( <span>
              {selectedItemsToCancel.size}
            </span>{' '}
            ) appointment(s)
          </p>
          <Button
            content="Nevermind"
            basic
            color="grey"
            onClick={onClickNevermind}
          />
          <Button
            //className="button"
            content="Yes, Cancel"
            negative
            onClick={onClickCancel}
          />
        </Segment>
      </BulkCancelPopupWrapper>
    </Portal>
  );
};

export default BulkCancelPopup;
