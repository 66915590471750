import React, { useEffect, useState } from 'react';
import { useClientAvailability } from 'hooks/useClientAvailability';
import DailyClientAvailability from './DailyClientAvailability';
import DaysCheckboxGroup from './DaysCheckboxGroup';
import { useClientClinicNetwork } from 'hooks/useClientClinicNetwork';
import Content from 'views/components/ui/content';
import { ClientAvailabilityViewWrapper } from './style';

interface IProps {
  clientId: number;
}
const ClientAvailabilityView: React.FC<IProps> = ({ clientId }) => {
  const [activeDayIndex, setActiveDayIndex] = useState<number>(-1);
  const [daysWithAvailability, setDaysWithAvailability] = useState<number[]>(
    []
  );

  const {
    isLoadingClientAvailability,
    clientAvailabilityPerDay
  } = useClientAvailability(clientId);

  const {
    clientClinicNetwork,
    isLoadingClinicNetwork
  } = useClientClinicNetwork(clientId);

  useEffect(() => {
    if (clientAvailabilityPerDay && clientAvailabilityPerDay.size > 0) {
      const availableDays = [...clientAvailabilityPerDay.keys()].sort();
      setDaysWithAvailability(availableDays);
      setActiveDayIndex(availableDays[0]);
    }
  }, [clientAvailabilityPerDay]);

  return (
    <Content
      loading={isLoadingClientAvailability || isLoadingClinicNetwork}
      data={clientAvailabilityPerDay && clientClinicNetwork}
    >
      {() => (
        <ClientAvailabilityViewWrapper>
          <DaysCheckboxGroup
            activeDayIndex={activeDayIndex}
            setActiveDayIndex={setActiveDayIndex}
            daysWithAvailability={daysWithAvailability}
          />
          <DailyClientAvailability
            dayIndex={activeDayIndex}
            clientAvailabilityPerDay={clientAvailabilityPerDay}
            clientClinicNetwork={clientClinicNetwork}
          />
        </ClientAvailabilityViewWrapper>
      )}
    </Content>
  );
};

export default ClientAvailabilityView;
