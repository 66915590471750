import React from 'react';
import { useHistory } from 'react-router';
import Header from 'views/containers/header';

export default function NewAppointmentTemplate({ children }) {
  const history = useHistory();

  return (
    <div className="popup-wrap">
      <Header
        title="New Appointment"
        buttonText="Save Appointment"
        loading={false}
        handleSubmit={() => {}}
        onCancel={() => history.goBack()}
        newAppointment={{
          appointmentTitle: 'New Appointment',
          clientSelected: false
        }}
        disabled={true}
      />
      {children}
    </div>
  );
}
