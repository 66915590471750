import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import CalendarEventItem from './CalendarEventItem';
import { openingId } from 'utils/common';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import AutoSizer from 'react-virtualized-auto-sizer';
// import ReactVisibilitySensor from 'react-visibility-sensor';
// import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';

const rescheduleNote =
  'Note: You cannot reschedule an appointment on or after the next appointment in the series, or past the end date.';
const editNote =
  'Note: You have to reschedule the appointment to change the date, time or provider.';

const helperText = 'Choose a slot for the appointment or series beginning on';

const OpeningsCalendarView = ({
  openings,
  disabledFormFields,
  selectedDate
}) => {
  const { control, setValue } = useFormContext();
  const location = useLocation();
  const { thisAndFollowingEvents } = location.state || {};

  const note = useMemo(() => {
    if (disabledFormFields.isEditBehaviorOnly) {
      return editNote;
    } else if (disabledFormFields.reschedule) return rescheduleNote;
    return null;
  }, [disabledFormFields]);

  const { selectedDateOpenings, autoSizerHeightStyle } = useMemo(() => {
    const date = moment(selectedDate).format('YYYY-MM-DD');
    const selectedDateOpenings = selectedDate
      ? openings
          .filter(o => date === moment(o.start).format('YYYY-MM-DD'))
          .sort(
            (a, b) =>
              moment(a.start).diff(b.start) ||
              a.provider?.displayName?.localeCompare(b.provider?.displayName)
          )
      : [];

    const autoSizerHeightStyle = {
      height: `${Math.min(
        500,
        Math.ceil(selectedDateOpenings.length / 2.0) * 90
      )}px`
    };
    return { selectedDateOpenings, autoSizerHeightStyle };
  }, [selectedDate, openings]);

  const Row = useCallback(
    ({ index, style }) => {
      const leftOpening = selectedDateOpenings[index * 2];
      const rightOpening = selectedDateOpenings[index * 2 + 1];

      return (
        <div style={style} key={openingId(leftOpening)} className="opening_row">
          {leftOpening && (
            <CalendarEventItem
              disabledFormFields={disabledFormFields}
              thisAndFollowingEvents={thisAndFollowingEvents}
              key={openingId(leftOpening)}
              opening={leftOpening}
              control={control}
              setValue={setValue}
            />
          )}

          {rightOpening && (
            <CalendarEventItem
              disabledFormFields={disabledFormFields}
              thisAndFollowingEvents={thisAndFollowingEvents}
              key={openingId(rightOpening)}
              opening={rightOpening}
              control={control}
              setValue={setValue}
            />
          )}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDateOpenings]
  );

  return (
    <div className="openings-slot">
      <span className="openings-slot__help">
        {selectedDate &&
          `${helperText} ${moment(selectedDate).format('ddd, MMM DD, YYYY')}`}
      </span>
      {note && (
        <div className="openings-slot__container">
          <span className="openings-slot__help">{note}</span>
        </div>
      )}
      <div className="openings-slot__wrap" style={autoSizerHeightStyle}>
        <AutoSizer>
          {({ height, width }) => (
            <>
              <List
                itemCount={Math.ceil(selectedDateOpenings.length / 2.0)}
                width={width}
                height={height}
                itemSize={90}
                style={{ outline: 'none' }}
              >
                {Row}
              </List>
            </>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default React.memo(OpeningsCalendarView);
