import * as React from 'react';
import { Button } from 'semantic-ui-react';
import './style.scss';

interface IButtonProps {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}
const MainButton: React.FC<IButtonProps> = (props: IButtonProps) => {
  const { title, onClick, className, disabled, loading } = props;
  return (
    <div className={`main_btn_wrapper ${className}`}>
      <Button
        className={className}
        inverted
        color="purple"
        onClick={onClick}
        disabled={disabled}
        loading={loading}
      >
        {title}
      </Button>
    </div>
  );
};
export default MainButton;
