import React from 'react';
import { Grid } from 'semantic-ui-react';
interface Props {
  id: string;
  title: string;
  subTitle?: string;
  description?: String;
  selectItem: (record: any) => void;
  children?: React.ReactNode;
}

const Card: React.FC<Props> = ({
  id,
  title,
  subTitle,
  description,
  selectItem,
  children
}: Props) => {
  return (
    <Grid.Column key={id} onClick={() => selectItem(id)}>
      <div className="card-item">
        <div className="card-item__head">
          <h2>{title}</h2>
          <span>{subTitle}</span>
        </div>
        <p>{description}</p>
        <div>{children}</div>
      </div>
    </Grid.Column>
  );
};
export default React.memo(Card);
