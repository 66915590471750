import { Select } from 'api/sharedComponents/reactHookFormComponents';
import debounce from 'lodash/debounce';
import React, { Dispatch, SetStateAction } from 'react';
import { useLazyQuery } from 'react-apollo';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { formatSearchedAddress } from 'views/components/forms/appointment/components/Location/OffsiteLocationAddressSelector';
import Loader from 'views/components/ui/content/Loading';
import { FormItem } from 'views/containers/form';
import { SIDEBAR_ACTIONS } from '../../AppointmentSidebar';
import { ClientSelectWrapper } from '../style';
import { IClient } from 'model/v2';
import { GET_CLIENT_LIST } from 'api/graphql/v2/queries/Clients';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT,
  FORMAT_CLIENT_DOB
} from 'utils/constants/default';

interface Props {
  action: string;
  setCurrClient?: Dispatch<SetStateAction<IClient>>;
}

const ClientSelect: React.FC<Props> = ({ action, setCurrClient }: Props) => {
  const methods = useFormContext();
  const { client, clinic } = useWatch({
    name: ['client', 'clinic'],
    control: methods.control
  });
  const [clients, setClients] = React.useState<IClient[]>([]);
  const [search, setSearch] = React.useState('');
  const fetchRef = React.useRef(0);

  const [doRequest, { loading }] = useLazyQuery(GET_CLIENT_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setClients(
        data?.clientsList?.clients.filter((value: any) => value.isActive) || []
      );
    }
  });

  React.useMemo(() => {
    const found = clients?.find(x => x.id === client?.id);
    if (client?.id && !found && search === '') {
      fetchRef.current += 1;
      setClients([
        {
          id: client?.id,
          name: client?.name,
          clinic: client?.clinic,
          profile: client?.profile
        }
      ]);
    }
  }, [client, fetchRef, clients, search]);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      setSearch(value);
      fetchRef.current += 1;
      doRequest({
        variables: {
          search: value ? value : null,
          from: 0,
          size: DEFAULT_PAGE_SIZE,
          sort: DEFAULT_SORT
        }
      });
    };

    return debounce(loadOptions, 800);
  }, [doRequest]);

  const setCurrentClient = React.useCallback(
    (id: number) => {
      setSearch('');
      const selectedClient = clients?.find((x: IClient) => x.id === id);
      if (selectedClient?.clinic?.id !== clinic?.id) {
        methods.setValue('room.id', null);
      }
      if (setCurrClient) setCurrClient(selectedClient!);
      methods.setValue('clinic', { ...selectedClient?.clinic });
      methods.setValue('client', { ...selectedClient });
      methods.setValue('selectedOpening', null);
      methods.setValue('locationDetails', formatSearchedAddress(null));
    },
    [clients, clinic, methods, setCurrClient]
  );

  return (
    <ClientSelectWrapper>
      <FormItem optional={false} label="Client">
        <Controller
          name="client.id"
          control={methods.control}
          render={fieldProps => (
            <Select
              className="client"
              showSearch
              loading={loading}
              placeholder="Select Client"
              options={clients?.map((client: IClient) => {
                return {
                  label: `${client.name} (${client?.clinic?.abbreviation ||
                    ''} , ${FORMAT_CLIENT_DOB(client?.profile?.dob || '')})`,
                  value: client.id
                };
              })}
              showArrow={true}
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={debounceFetcher}
              notFoundContent={
                loading ? (
                  <Loader isSidebar={false} fullHeight={false} scroll={false} />
                ) : null
              }
              field={fieldProps}
              errors={methods.errors}
              onSelect={setCurrentClient}
              disabled={action === SIDEBAR_ACTIONS.EDIT}
            />
          )}
        />
      </FormItem>
    </ClientSelectWrapper>
  );
};
export default React.memo(ClientSelect);
