import { ILookup } from './lookup';

export enum ABAAppointmentSubType {
  id = 'id',
  title = 'title',
  superType = 'superType',
  crType = 'crType',
  crAuthCode = 'crAuthCode',
  placeOfService = 'placeOfService',
  ABASettingId = 'ABASettingId'
}

export interface IABAAppointmentSubType {
  [ABAAppointmentSubType.id]?: number;
  [ABAAppointmentSubType.title]: string;
  [ABAAppointmentSubType.superType]: ILookup;
  [ABAAppointmentSubType.crType]: string;
  [ABAAppointmentSubType.crAuthCode]: ILookup;
  [ABAAppointmentSubType.placeOfService]?: ILookup;
  [ABAAppointmentSubType.ABASettingId]?: number;
}
