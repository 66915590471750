import React, { useCallback } from 'react';
import ClinicCard from './ClinicCard';
import { useHistory } from 'react-router';
import { IClinic } from 'model/v2';
import { Grid } from 'semantic-ui-react';

interface Props {
  clinics: IClinic[];
}

const ClinicList: React.FC<Props> = ({ clinics }: Props) => {
  const history = useHistory();
  type CallbackType = (...args: string[]) => void;

  const selectClinic = useCallback<CallbackType>(
    record => {
      history.push('/clinics/' + record);
    },
    [history]
  );
  return (
    <Grid stretched columns={3}>
      <Grid.Row>
        {clinics &&
          clinics.map((clinic, index) => (
            <ClinicCard
              key={index}
              selectClinic={selectClinic}
              clinic={clinic}
            />
          ))}
      </Grid.Row>
    </Grid>
  );
};

export default ClinicList;
