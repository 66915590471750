import React, { useState } from 'react';
import { Table, Button } from 'lib/ui';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import Content from 'views/components/ui/content';
import {
  MAIN_CLIENT_RECOMMENDED_EVALUATIONS_COLUMNS,
  MAIN_CLIENT_RECOMMENDED_THERAPIES_COLUMNS
} from 'utils/constants/tables/clients';
import { GET_CLIENT_CARE_PLAN } from 'api/graphql/queries/careplan';
import { getCarePlanData } from 'utils/mappers/response/careplan';

const CarePlan = ({ client }) => {
  const [carePlan, setCarePlan] = useState([]);

  const history = useHistory();

  //to be replaced with v2 and use client.id
  const { loading, error } = useQuery(GET_CLIENT_CARE_PLAN, {
    variables: { clientID: client.id },
    onCompleted: data => {
      const apptTypes = data.appointmentTypes.concat(data.appointmentABATypes);
      setCarePlan(
        getCarePlanData(data.carePlan, apptTypes, data.waitlistStatusOptions)
      );
    }
  });

  const redirectToCarePlanForm = e => {
    e.preventDefault();
    history.push({
      pathname: `/clients/${client.id}/edit-care-plan`,
      state: {
        client
      }
    });
  };

  return (
    <div className="profile_table">
      <div className="profile_table__title">
        <h1>Care Plan</h1>
        <Button
          className="button-outline medium edit-button"
          onClick={redirectToCarePlanForm}
        >
          Edit Care Plan
        </Button>
      </div>

      <Content loading={loading} error={error} data={carePlan}>
        {() => (
          <div className="profile_table__container">
            <div className="table-title">Evals & One-Time Recommendations</div>
            <Table
              columns={MAIN_CLIENT_RECOMMENDED_EVALUATIONS_COLUMNS}
              dataSource={carePlan.recommendedEvaluations}
              rowKey="key"
              pagination={false}
              bordered
            />
            <div className="table-title">Frequency Recommendations</div>
            <Table
              columns={MAIN_CLIENT_RECOMMENDED_THERAPIES_COLUMNS}
              dataSource={carePlan.recommendedTherapies}
              rowKey="key"
              pagination={false}
              bordered
            />
          </div>
        )}
      </Content>
    </div>
  );
};

export default CarePlan;
