import React from 'react';
import cancelledIcon from 'assets/img/icon-canceled.svg';
import deletedIcon from 'assets/img/icon-deleted.svg';
import recurringIcon from 'assets/img/icon-recurring.svg';
import oneTimeIcon from 'assets/img/icon-oneTime.png';
import pendingIcon from 'assets/img/icon-pending.svg';
import rescheduleIcon from 'assets/img/icon-reschedule.png';
import { getStatus } from 'utils/validators/planner';
import { Status } from 'model/calendar/events';

export const statusIcons: any = {
  'Scheduled - One Time': oneTimeIcon,
  'Scheduled - Recurring': recurringIcon,
  canceled: cancelledIcon,
  deleted: deletedIcon,
  rescheduled: rescheduleIcon,
  pending: pendingIcon,
  invisible: pendingIcon,
  scheduled: oneTimeIcon
};
interface Props {
  status: Status;
  className?: string;
  recurringEvery?: number;
  isPendingConfirmation?: boolean;
  isDelete?: boolean;
}
const StatusIcon: React.FC<Props> = ({
  status,
  recurringEvery,
  className,
  isPendingConfirmation,
  isDelete
}: Props) => {
  const eventStatus = getStatus(status, recurringEvery, isPendingConfirmation);

  const iconSource: string = isDelete
    ? statusIcons['deleted']
    : eventStatus
    ? statusIcons[eventStatus]
    : undefined;

  return iconSource ? (
    <img src={iconSource} className={className} alt="" />
  ) : (
    <div style={{ width: '13px', height: '13px', display: 'inline-block' }} />
  );
};

export default StatusIcon;
