import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, { useEffect } from 'react';
import Content from 'views/components/ui/content';
import { EVENT_STATUS } from 'utils/constants/status';
import { COULD_NOT_FIND_EVENT } from 'utils/constants/planner';
import { IReschedulePopupProps } from '../showPopup';
import { EventType } from 'model/calendar/events';
import AdminForm from '../popupForm/AdminForm';
import ClientForm from '../popupForm/ClientForm';
import { mapRoomIdToFullData } from 'utils/mappers/form';
import {
  GET_EVENT_WITH_OBSERVERS,
  GET_FULL_EVENT,
  GET_OBSERVATION_OF_LEAD_EVENT
} from 'api/graphql/v2/queries/Events';
import { GET_OPENING_CONFLICTS } from 'api/graphql/v2/queries/Openings';
import {
  useBulkRecurringEventsCount,
  useDeleteWhenCancelAppointmentsIds
} from 'api/graphql/v2/hooks/recurringEvents';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { ALL_CANCELLATION_REASONS } from 'api/graphql/v2/queries/CancellationReasons';

interface IProps {
  rescheduleProps: IReschedulePopupProps;
  setHeader: (loading: boolean) => void;
  setConflicting: (isConflict: boolean) => void;
}

//TODO_PLANNER_MAPPING
const Reschedule: React.FC<IProps> = ({
  rescheduleProps,
  setConflicting,
  setHeader
}: IProps) => {
  const {
    selectedEventProps,
    view,
    toast,
    onClose,
    onCancel
  } = rescheduleProps;

  const {
    eventId,
    newStartDate,
    newEndDate,
    oldStartDate,
    oldEndDate,
    provider,
    oldProvider,
    type
  } = selectedEventProps;

  //TODO: Queru v2
  const { loading: fullEventLoading, error, data: fullEventData } = useQuery(
    GET_FULL_EVENT,
    {
      variables: {
        id: eventId
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        setHeader(!!data);
        if (!data || data?.event?.status === EVENT_STATUS.canceled) {
          onCancel();
          toast.error(COULD_NOT_FIND_EVENT);
        }
      }
    }
  );

  const [
    getConflicts,
    { loading: conflictsLoading, data: conflictsData }
  ] = useLazyQuery(GET_OPENING_CONFLICTS, {
    variables: {
      providerId: provider?.id || fullEventData?.event?.provider?.id,
      startDate: newStartDate,
      endDate: newEndDate,
      recurringUntil: fullEventData?.event?.recurrencePattern?.recurringUntil,
      recurringEvery: fullEventData?.event?.recurrencePattern?.recurringEvery,
      masterId: fullEventData?.event.master?.id,
      reschedule: true,
      recurring:
        fullEventData?.event?.recurrencePattern?.recurringEvery > 0 &&
        !fullEventData?.isLastEventInSeries
    },
    fetchPolicy: 'cache-and-network'
  });

  const {
    loading: cancellationReasonsLoading,
    data: cancellationReasons
  } = useQuery(ALL_CANCELLATION_REASONS, {
    fetchPolicy: 'cache-and-network',
    skip: type === EventType.adminEvent
  });

  const {
    recurringBulkEventsCountLoading,
    recurringBulkEventsCount
  } = useBulkRecurringEventsCount(
    [eventId],
    eventId === undefined ||
      !getFeatureAvailability(FEATURES.RESCHEDULE_MODAL_UPDATES)
  );

  const [
    getObservationOfLeadEvent,
    { loading: observationOfLeadEventLoading, data: observationOfLeadEvent }
  ] = useLazyQuery(GET_OBSERVATION_OF_LEAD_EVENT, {
    variables: {
      id: eventId
    },
    fetchPolicy: 'cache-and-network'
  });
  const [
    getObserversOfEachEvent,
    { data: observersOfEachEvent }
  ] = useLazyQuery(GET_EVENT_WITH_OBSERVERS, {
    variables: {
      id: eventId
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (fullEventData && type === EventType.clientEvent) {
      getConflicts();
      getObservationOfLeadEvent();
      getObserversOfEachEvent();
    }
  }, [
    fullEventData,
    type,
    getConflicts,
    getObservationOfLeadEvent,
    getObserversOfEachEvent
  ]);

  useEffect(() => {
    setConflicting(conflictsData?.getEventConflicts?.conflicts?.length > 0);
  }, [setConflicting, conflictsData]);

  const {
    deleteWhenCancelAppointmentsIds,
    deleteWhenCancelAppointmentsIdsLoading
  } = useDeleteWhenCancelAppointmentsIds(eventId === undefined);

  const isSeries =
    !fullEventData?.isLastEventInSeries &&
    fullEventData?.event.recurrencePattern?.recurringEvery &&
    fullEventData?.event.recurrencePattern?.recurringEvery !== 0;

  return (
    <Content
      loading={
        fullEventLoading ||
        conflictsLoading ||
        cancellationReasonsLoading ||
        recurringBulkEventsCountLoading ||
        deleteWhenCancelAppointmentsIdsLoading ||
        observationOfLeadEventLoading
      }
      error={error}
      data={fullEventData}
    >
      {() => (
        <>
          {fullEventData && (
            <>
              {type === EventType.adminEvent && (
                <AdminForm
                  fullEvent={fullEventData}
                  newStartDate={newStartDate}
                  newEndDate={newEndDate}
                  oldStartDate={oldStartDate}
                  oldEndDate={oldEndDate}
                  view={view}
                  onClose={onClose}
                  onCancel={onCancel}
                  recurringEventsCount={
                    recurringBulkEventsCount?.getBulkRecurringEventsCount
                  }
                  isSeries={isSeries}
                />
              )}
              {type === EventType.clientEvent && (
                <ClientForm
                  fullEvent={fullEventData}
                  isSeries={isSeries}
                  provider={provider || fullEventData?.event?.provider}
                  oldProvider={
                    oldProvider ? fullEventData?.event?.provider : null
                  }
                  client={fullEventData?.event?.client}
                  isConflictingProvider={
                    conflictsData?.getEventConflicts?.conflicts?.length > 0
                  }
                  newStartDate={newStartDate}
                  newEndDate={newEndDate}
                  oldStartDate={oldStartDate}
                  oldEndDate={oldEndDate}
                  view={view}
                  onClose={onClose}
                  onCancel={onCancel}
                  cancellationReasons={
                    cancellationReasons?.allCancellationReasons
                  }
                  roomName={mapRoomIdToFullData(fullEventData?.event?.room)}
                  recurringEventsCount={
                    recurringBulkEventsCount?.getBulkRecurringEventsCount
                  }
                  deleteWhenCancelAppointmentsIds={
                    deleteWhenCancelAppointmentsIds?.deleteWhenCancelAppointmentsIds
                  }
                  observationOfLeadEvent={
                    observationOfLeadEvent?.observationOfSeriesLeadEvent
                  }
                  observersOfEachEvent={observersOfEachEvent}
                />
              )}
            </>
          )}
        </>
      )}
    </Content>
  );
};
export default React.memo(Reschedule);
