import gql from 'graphql-tag';

export const clinicFragments = {
  clinicData: gql`
    fragment ClinicData on Clinic {
      id
      createdAt
      updatedAt
      name
      abbreviation
      centralReachId
      centralReachPk
      centralReachLocationId
      departmentId
      clinicType
      medicalGroupId
      fax
      phone
      timezone
      unavailableClientId
      isActive
      clinicHub {
        id
        name
        abbreviation
      }
      address {
        id
        addressLine1
        addressLine2
        name
        city
        country
        state
        zipCode
        addressType
        crId
        isPrimary
      }
      clinicBusinessHours {
        id
        dayOfWeek
        startTime
        endTime
      }
      providersCount
      roomsCount
      clientsCount
    }
  `,
  roomsData: gql`
    fragment RoomsData on Room {
      id
      email
      hasInstruments
      hasTV
      hasPhone
      name
      type
      seats
    }
  `,
  provider: gql`
    fragment Provider on Provider {
      id
      telehealth
      firstName
      lastName
      displayName
      name
      status
      speciality {
        id
        title
        abbreviation
        color
        department
      }
      appointmentTypes {
        id
      }
      clinic {
        id
      }
      telehealthLinks
      corticaPrograms {
        id
        programName
      }
    }
  `
};
