import React from 'react';
import Description from './Description';
import Actions from './Actions';
import ProfilePicture from 'views/components/ui/profile/ProfilePicture';
import { Table } from 'lib/ui';
import { MAIN_CLIENT_SCHEDULING_STATUS_COLUMNS } from 'utils/constants/tables/clients';
import { useHistory } from 'react-router-dom';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { getFeatureAvailability, FEATURES } from 'utils/featureToggle';
import { getApptDuration } from 'utils/common';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';
import { calendarGroup } from 'model/calendar/groups';
import { formRedirectHandler } from 'helpers/calendarHelper';
import { formatAttendeeName } from 'utils/format';
import { DEFAULT_DURATION } from 'utils/constants/default';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';

const ClientProfile = ({ client, clientWaitlist, appointmentTypes }) => {
  const history = useHistory();

  //TODO: Revisit this after waitlist is added

  const handleSchedule = item => {
    let duration = undefined;
    if (item.apptSubType && item.apptSubType !== 'None')
      duration = getApptDuration(
        item.apptType,
        item.apptSubType,
        appointmentTypes
      );
    const appointmentTypeName = appointmentTypes.apptType.name;
    const clientName = formatAttendeeName(
      client.firstName,
      client.lastName,
      client?.clinic?.abbreviation
    );

    formRedirectHandler(
      {
        event: {
          client: client,
          appType: item.apptType,
          appSubType: item.apptSubType,
          // TODO replace is clinical with eventType
          eventTypeName:
            appointmentTypeName === 'DT'
              ? EVENT_TYPES.DT
              : appointmentTypeName === 'MED'
              ? EVENT_TYPES.MED
              : EVENT_TYPES.ABA,
          clinic: client?.clinic,
          duration: duration ? duration : DEFAULT_DURATION.ABA
        },
        action: SIDEBAR_ACTIONS.NEW
      },
      history,
      {
        id: client.id,
        displayName: clientName,
        name: client.name,
        attendeeType: calendarGroup.client,
        baseId: client.id,
        clinic: client?.clinic
      }
    );
  };

  return (
    <div className="profile-info-wrap">
      <div className="inner-wrap clearfix">
        <ProfilePicture img={client?.photo} alt={client.name} />
        <Description client={client} />
        <Actions client={client} />
      </div>
      {/* //TODO Get waitlist from client v2 */}
      {false && (
        <div className="inner-wrapper">
          <div className="table-title">Scheduling Status:</div>
          <Table
            columns={MAIN_CLIENT_SCHEDULING_STATUS_COLUMNS(handleSchedule)}
            dataSource={[]}
            expandable={{
              expandedRowRender: record => record.description,
              expandIcon: props => customExpandIcon(props)
            }}
            rowKey={record => record.key}
            pagination={false}
            bordered
          />
        </div>
      )}
    </div>
  );
};

const customExpandIcon = props => {
  if (!props.record.description) return;
  return props.expanded ? (
    <CaretDownOutlined onClick={e => props.onExpand(props.record, e)} />
  ) : (
    <CaretRightOutlined
      onClick={e => props.record.description && props.onExpand(props.record, e)}
    />
  );
};

export default ClientProfile;
