import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const ContentWrapper = styled.div`
  background-color: ${colors.EDIT_FORM_BG};
  padding-left: 60px;
  padding-right: 60px;
  .block {
    background-color: white;
    border: 1px solid #d8d8d8;
    width: 100%;
    padding-top: 25px !important;
    margin-bottom: 30px !important;
  }
  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: ${colors.WHITE};
    background-color: ${colors.PURPLE};
  }
  .ui.radio.checkbox input:checked ~ label:after {
    opacity: 1;
    color: ${colors.WHITE};
    background-color: ${colors.PURPLE};
  }
  .ui.radio.checkbox label,
  .ui.radio.checkbox + label {
    color: #415766;
    font-size: 13px;
    margin-top: 10px;
  }
  .ui.input.focus > input,
  .ui.input > input:focus {
    border-color: ${colors.PURPLE};
  }
  .ui.checkbox label,
  .ui.checkbox + label {
    font-size: 13px;
    margin-top: 10px;
  }
  .ui.grid {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .room-type {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    color: #415766;
    margin-left: 10px;
  }
  .title {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
  }
  .input {
    height: 30px;
    width: 60px;
    margin-top: 5px;
  }
  .label {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    color: #415766;
    margin-right: 8px;
  }
`;
export const AddIconWrapper = styled.div`
  background-color: ${colors.EDIT_FORM_BG};
  i.icon:before {
    background: 0 0 !important;
    color: #34c9eb;
  }
`;
