import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Formik, FieldArray, Form } from 'formik';
import { Button } from 'lib/ui';
import Header from 'views/containers/header';
import Content from 'views/components/ui/content';
import { CarePlanContainer, EditCarePlanWrapper } from './EditCarePlan.style';
import RecommendedEvaluation from './components/RecommendedEvaluation';
import RecommendedTherapy from './components/RecommendedTherapy';
import ModalWarning from './UnsavedChangesModal';
import { CLIENT_PROFILE_TABS } from 'utils/constants/lists';
import { EMPTY_SCHEMA, CARE_PLAN_SCHEMA } from 'utils/validators/careplan';
import {
  NEW_CARE_PLAN,
  NEW_RECOMMENDED_THERAPY,
  NEW_RECOMMENDED_EVALUATION
} from 'utils/constants/init';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_CLIENT_CARE_PLAN,
  GET_EDIT_CARE_PLAN_DATA
} from 'api/graphql/queries/careplan';
import { EDIT_CARE_PLAN } from 'api/graphql/mutations/careplan';
import { alphabeticalSort } from 'utils/sort';
import { mapCarePlanToFormData } from 'utils/mappers/response/careplan';
import {
  filterAppointmentTypes,
  mapToCarePlanBE
} from 'utils/mappers/request/careplan';
import { DEFAULT_RECOMMENDED_EVALUATION_TYPES } from 'utils/constants/default';
import { GET_CLIENT_PROFILE } from 'api/graphql/queries/clients';
import useClickOutsideHook from 'hooks/clickOutsideHook';

const EditCarePlan = () => {
  const [dataSource, setDataSource] = useState(NEW_CARE_PLAN);
  const [validationSchema, setValidationSchema] = useState(EMPTY_SCHEMA);
  const [apptTypes, setApptTypes] = useState([]);
  const [clientResponses, setClientResponses] = useState();
  const [quantityPatterns, setQuantityPatterns] = useState();
  const [isModalVisible, setModalVisible] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { client } = location.state;

  const { loading, error, data } = useQuery(GET_EDIT_CARE_PLAN_DATA, {
    variables: { clientID: client.id },
    onCompleted: ({
      appointmentTypes,
      appointmentABATypes,
      clientEvaluationResponses,
      quantityPatterns
    }) => {
      const apptTypes = appointmentTypes
        .concat(appointmentABATypes)
        .sort((appt1, appt2) => alphabeticalSort(appt1.title, appt2.title));

      setApptTypes(apptTypes);
      setValidationSchema(CARE_PLAN_SCHEMA(apptTypes, quantityPatterns));
      setClientResponses(clientEvaluationResponses);
      setQuantityPatterns(quantityPatterns);

      if (!data.carePlan)
        setDataSource(prev => ({
          ...prev,
          recommendedEvaluations: DEFAULT_RECOMMENDED_EVALUATION_TYPES.map(
            evalType =>
              NEW_RECOMMENDED_EVALUATION(
                clientEvaluationResponses.ACCEPT,
                evalType
              )
          )
        }));
      else setDataSource(mapCarePlanToFormData(data.carePlan, apptTypes));
    }
  });

  const refetch = () => [
    {
      query: GET_CLIENT_CARE_PLAN,
      variables: { clientID: client.id }
    },
    {
      query: GET_CLIENT_PROFILE,
      variables: { id: client.id }
    }
  ];

  const [editCarePlan] = useMutation(EDIT_CARE_PLAN, {
    refetchQueries: () => refetch()
  });

  const addRecommendation = (arrayHelpers, recommendation) =>
    arrayHelpers.push(recommendation);

  const removeRecommendation = (recommendations, index) =>
    recommendations.remove(index);

  const cancelEdit = dirty =>
    dirty ? setModalVisible(true) : redirectToCarePlanTab();

  const clinicsMap = useClickOutsideHook();
  const submit = (values, actions) => {
    actions.setSubmitting(true);
    editCarePlan({
      variables: {
        clientID: client.id,
        carePlan: mapToCarePlanBE(
          values,
          dataSource,
          client,
          apptTypes,
          clinicsMap
        )
      }
    }).then(
      res => onSubmitSuccess(res, actions, values),
      error => onSubmitFailure(error, actions)
    );
  };

  const onSubmitSuccess = (res, actions, values) => {
    actions.resetForm(values);
    actions.setSubmitting(false);
    redirectToCarePlanTab();
  };

  const onSubmitFailure = (error, actions) => {
    console.log(error);
    actions.setSubmitting(false);
  };

  const redirectToCarePlanTab = () => {
    history.push({
      pathname: `/clients/${client.id}/`,
      state: {
        client,
        activeKey: CLIENT_PROFILE_TABS().CARE_PLAN
      }
    });
  };

  return (
    <Content loading={loading} error={error} data={data}>
      {() => (
        <>
          {isModalVisible && (
            <ModalWarning
              visible={isModalVisible}
              setVisible={setModalVisible}
              leavePage={redirectToCarePlanTab}
              destroyOnClose={true}
              width={'777px'}
            />
          )}
          <Formik
            initialValues={dataSource}
            enableReinitialize
            validationSchema={validationSchema}
            isInitialValid={validationSchema.isValidSync(dataSource)}
            onSubmit={(values, actions) => submit(values, actions)}
            render={({
              values,
              dirty,
              isValid,
              errors,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
              setFieldTouched,
              touched
            }) => (
              <Form>
                <EditCarePlanWrapper>
                  <div className="popup-wrap">
                    <Header
                      title="Edit Care Plan Recommendations"
                      source={client}
                      buttonText="Save Care Plan Recommendations"
                      onCancel={() => cancelEdit(dirty)}
                      disabled={!dirty || !isValid}
                      handleSubmit={handleSubmit}
                      loading={isSubmitting}
                    />
                    <CarePlanContainer>
                      <div className="left-column">
                        <h2>Evals & One-Time Recommendations</h2>
                        <FieldArray
                          name="recommendedEvaluations"
                          render={arrayHelpers => (
                            <>
                              {values.recommendedEvaluations.map(
                                (recommendation, index) => (
                                  <RecommendedEvaluation
                                    key={index}
                                    keyForm={index}
                                    values={values}
                                    recommendation={recommendation}
                                    apptTypes={apptTypes}
                                    clientResponses={clientResponses}
                                    onDelete={() =>
                                      removeRecommendation(arrayHelpers, index)
                                    }
                                    setFieldValue={setFieldValue}
                                  />
                                )
                              )}
                              <Button
                                onClick={() => {
                                  addRecommendation(
                                    arrayHelpers,
                                    NEW_RECOMMENDED_EVALUATION(
                                      clientResponses.ACCEPT
                                    )
                                  );
                                }}
                                className="button-outline medium edit-button"
                              >
                                + Add Recommendation
                              </Button>
                            </>
                          )}
                        />
                      </div>
                      <div className="right-column">
                        <h2>Frequency Recommendations</h2>
                        <FieldArray
                          name="recommendedTherapies"
                          render={arrayHelpers => (
                            <>
                              {values.recommendedTherapies.map(
                                (recommendation, index) => (
                                  <RecommendedTherapy
                                    key={index}
                                    keyForm={index}
                                    recommendation={recommendation}
                                    values={values}
                                    apptTypes={filterAppointmentTypes(
                                      values.recommendedTherapies,
                                      apptTypes
                                    )}
                                    unfilteredApptTypes={apptTypes}
                                    quantityPatterns={quantityPatterns}
                                    onDelete={() =>
                                      removeRecommendation(arrayHelpers, index)
                                    }
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    errors={errors}
                                    setFieldTouched={setFieldTouched}
                                    touched={touched}
                                  />
                                )
                              )}

                              <Button
                                onClick={() => {
                                  addRecommendation(
                                    arrayHelpers,
                                    NEW_RECOMMENDED_THERAPY
                                  );
                                }}
                                className="button-outline medium edit-button"
                              >
                                + Add Recommendation
                              </Button>
                            </>
                          )}
                        />
                      </div>
                    </CarePlanContainer>
                  </div>
                </EditCarePlanWrapper>
              </Form>
            )}
          />
        </>
      )}
    </Content>
  );
};

export default EditCarePlan;
