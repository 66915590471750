import { ISpecialty } from './specialty';
import { IProviderSpecialty } from './v2';

// make sure to be same as db data
export enum AvailabilityTemplate {
  id = 'id',
  shownId = 'shownId',
  title = 'title',
  speciality = 'speciality',
  department = 'department',
  name = 'name',
  createdAt = 'createdAt',
  createdBy = 'createdBy',
  isActive = 'isActive',
  default = 'default'
}
export interface IAvailabilityTemplate {
  [AvailabilityTemplate.id]: string;
  [AvailabilityTemplate.shownId]?: string;
  [AvailabilityTemplate.title]: string;
  [AvailabilityTemplate.speciality]: ISpecialty;
  [AvailabilityTemplate.department]: string;
  [AvailabilityTemplate.name]: string;
  [AvailabilityTemplate.createdAt]: string;
  [AvailabilityTemplate.createdBy]: string;
  [AvailabilityTemplate.isActive]: boolean;
  [AvailabilityTemplate.default]: boolean;
}

export interface IAvailabilityTemplateData {
  listAllAvailabilityTemplatesOrderByDepartment: IAvailabilityTemplate[];
}

export interface IModalDropdown {
  listDropdownAvailabilities: availabilityTemplatesDropdown[];
  specialties: IProviderSpecialty[];
  specialtiesDepartment: string[];
}

export interface availabilityTemplatesDropdown {
  id: number;
  name: string;
  department: string;
}

export interface specialitiesDropdown {
  abbreviation: string;
}
