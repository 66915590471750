import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';

export const GET_AUTH_GROUP = gql`
  query($authGroupID: ID!) {
    authGroup(authGroupID: $authGroupID) {
      authGroupID
      authGroupType
      startDate
      endDate
      dueDate
      clientName
      clientID
      clinicAbr
      clinicID
      authTherapies {
        id
        apptTypeIDs {
          id
          isClinical
          __typename @skip(if: true)
        }
        authLimit
        authPattern
        authPeriod
      }
    }
  }
`;
export const CLIENT_AUTHORIZED_THERAPIES = gql`
  query($clientID: String) {
    clientGroupedAuthsWithEvents(clientID: $clientID, timeZone: "${TIMEZONE}") {
      authGroupID
      clientID
      clientName
      clinicID
      clinicAbr
      startDate
      endDate
      dueDate
      authGroupType
      authsRemaining
      timeRemaining
      authAppointmentTypes {
        authTherapyID
        appointmentTypeID
        isClinical
        appointmentType
        authLimit
        authPeriod
        authPattern
        completed
        scheduled
        remaining
        toFulfill
        children {
          authTherapyID
          appointmentTypeID
          isClinical
          appointmentType
          authLimit
          authPeriod
          authPattern
          completed
          scheduled
          remaining
          toFulfill
        }
      }
    }
    appointmentTypes {
      id
      title
      isClinical
    }
    appointmentABATypes {
      id
      title
      isClinical
    }
  }
`;
export const AUTHORIZED_THERAPIES_FLATS = gql`
  query(
    $from: Int
    $size: Int
    $search: [JSONObject]
    $sort: [JSONObject]
    $filter: [JSONObject]
  ) {
    authorizedTherapiesFlats(
      from: $from
      size: $size
      search: $search
      sort: $sort
      filter: $filter
    ) {
      auths {
        id
        authGroupID
        authGroupType
        clientName
        clientID
        clinicAbr
        clinicID
        startDate
        endDate
        dueDate
        authLimit
        authPattern
        authPeriod
        calculatedAt
        completed
        remaining
        scheduled
        timeRemaining
        toFulfill
        apptTypeIDs {
          id
          isClinical
          completed
          scheduled
          __typename @skip(if: true)
        }
      }
      count
    }
    appointmentTypes {
      id
      title
      isClinical
    }
    appointmentABATypes {
      id
      title
      isClinical
    }
  }
`;

export const GET_AUTH_GROUP_INTEGRATION_DATA = gql`
  query {
    appointmentTypes {
      id
      title
      isClinical
    }
    appointmentABATypes {
      id
      title
      isClinical
    }
    authorizationTypes {
      id
      value
    }
    authTherapyVisitsPatterns
    authTherapyPeriodPatterns {
      id
      value
    }
  }
`;

export const GET_AUTH_THERAPY = gql`
  query($authTherapyID: ID!) {
    authTherapy(authTherapyID: $authTherapyID) {
      authGroupID
      clientID
      clientName
      clinicID
      clinicAbr
      startDate
      endDate
      dueDate
      authGroupType
      authsRemaining
      timeRemaining
      authAppointmentTypes {
        appointmentTypeID
        authTherapyID
        isClinical
        appointmentType
        authLimit
        authPattern
        authPeriod
        completed
        scheduled
        remaining
        toFulfill
        timeRemaining
      }
    }
  }
`;
