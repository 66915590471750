import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SessionWrapper } from './Style';
import Skeleton from '@material-ui/lab/Skeleton';
import { ICarePlanFieldsForSmartScheduling } from 'model/v2';

interface IProps {
  carePlanInfo: ICarePlanFieldsForSmartScheduling;
  isAba: boolean;
  isWaitlistDataLoading: boolean;
  selectedValue: number;
}
const CarePlanInformation: React.FC<IProps> = ({
  carePlanInfo,
  isAba,
  isWaitlistDataLoading,
  selectedValue
}: IProps) => {
  const receivingValue = isAba
    ? carePlanInfo?.axonReceivingHours || 0
    : carePlanInfo?.DTReceivingUnits || 0;
  const valueToUse = carePlanInfo
    ? carePlanInfo?.requested__c ?? carePlanInfo?.recommended__c ?? 0
    : 0;
  const remainingValue = Math.max(valueToUse - receivingValue, 0);
  return (
    <SessionWrapper>
      <Grid>
        <Grid.Row className="first-row">
          <Grid.Column width="7">
            {!isWaitlistDataLoading ? (
              <>
                {carePlanInfo?.requested__c !== null ? (
                  <>
                    <label>Requested: {carePlanInfo?.requested__c || 0}</label>
                  </>
                ) : (
                  <>
                    <label>
                      Recommended: {carePlanInfo?.recommended__c || 0}
                    </label>
                  </>
                )}
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={<label>Recommended: 0</label>}
                />
              </>
            )}
          </Grid.Column>
          <Grid.Column width="7">
            {!isWaitlistDataLoading ? (
              <>
                <label className="receiving-cls">
                  Receiving: {receivingValue}
                </label>
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={<label>Receiving: 0</label>}
                />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="second-row">
          <Grid.Column width="7">
            {!isWaitlistDataLoading ? (
              <>
                <label className="remaining-cls">
                  Remaining: {remainingValue}
                </label>
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={<label>Remaining: 0</label>}
                />
              </>
            )}
          </Grid.Column>
          <Grid.Column width="7">
            {!isWaitlistDataLoading ? (
              <>
                <label className="remaining-cls">
                  Selected: {selectedValue}
                </label>
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={<label>Selected: 0</label>}
                />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </SessionWrapper>
  );
};
export default React.memo(CarePlanInformation);
