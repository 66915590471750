import React from 'react';
import { Button } from 'lib/ui';
import { STEPS } from './utils';
import { isEmpty } from 'lodash';
import { getWarningsSupportanceData } from 'utils/authorizations/event';
import { IConflictMapped, IWarning } from 'model/v2';
import { IFormEvent } from 'model/v2';
import { WHITE } from 'utils/constants/theme';

interface Props {
  warnings: IWarning[];
  conflicts: IConflictMapped[];
  setStep: (val: number) => void;
  backToForm: () => void;
  formData: IFormEvent | null;
  mappedEvent: any;
}

const WarningFooter: React.FC<Props> = ({
  warnings,
  setStep,
  conflicts,
  backToForm,
  formData,
  mappedEvent
}: Props) => {
  const [createButtonDisabled, unauthedEventsLen] = React.useMemo(() => {
    const unauthedEventsLen = warnings.filter(event =>
      isEmpty(event.authorizedTherapyID)
    ).length;

    const { supported } = getWarningsSupportanceData(warnings);

    const createButtonDisabled =
      !!formData?.id ||
      !supported ||
      parseInt(mappedEvent?.recurringEvery || '0') > 0 ||
      warnings.length - unauthedEventsLen === 0;

    return [createButtonDisabled, unauthedEventsLen];
  }, [warnings, formData, mappedEvent]);

  return (
    <div
      style={{
        background: WHITE,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        borderTop: '1px solid #d8d8d8'
      }}
    >
      <h2>Warning</h2>
      <p>
        The appointment(s) you’ve selected will result in
        {` ${unauthedEventsLen}`} unauthorized appointment(s).
      </p>
      <Button
        className="secondary"
        type=""
        onClick={() => {
          if (conflicts.length > 0) {
            setStep(STEPS.CONFLICTS);
          } else {
            setStep(STEPS.SUBMIT);
          }
        }}
      >
        Create All {warnings.length} Appointments
      </Button>
      <Button
        basic="true"
        type=""
        disabled={createButtonDisabled || true}
        onClick={() => {
          if (conflicts.length > 0) {
            setStep(STEPS.CONFLICTS);
          } else {
            setStep(STEPS.SUBMIT);
          }
        }}
      >
        {`Create Only ${warnings.length - unauthedEventsLen}
         Authorized Appointments`}
      </Button>
      <Button className="secondary" type="" onClick={backToForm}>
        Back To Form
      </Button>
    </div>
  );
};
export default React.memo(WarningFooter);
