import React, { useState, useEffect, useMemo } from 'react';
import { NEW_CLIENT } from 'utils/constants/init';
import Content from 'views/components/ui/content';
import { GET_CLIENT_FORM_FIELDS } from 'api/graphql/v2/queries/Clients';
import {
  getEditableClientData,
  handleClientAddresses
} from 'utils/mappers/response/clients';
import { useHistory, useParams } from 'react-router';
import { useQuery } from 'react-apollo';
import { ClientForm } from './ClientForm';
import { getDisabeldFields } from './clientHelpers';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';

const FORM_OPERATION = ['create', 'edit'];

export const ClientFormInit = () => {
  const { id, operation } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(NEW_CLIENT);
  const [client, setClient] = useState(NEW_CLIENT);

  const { error, loading: loadingClient } = useQuery(GET_CLIENT_FORM_FIELDS, {
    fetchPolicy: 'no-cache',
    variables: { id: Number(id) },
    onCompleted: ({ client, clientAddresses }) => {
      if (client) {
        handleClientAddresses(client);
        setClient(getEditableClientData(client));
      } else setClient(NEW_CLIENT);
      setLoading(false);
    },
    skip: !id || id === 'undefined' || operation !== FORM_OPERATION[1]
  });

  useEffect(() => {
    if (operation === FORM_OPERATION[0]) {
      setLoading(false);
    }
    if (operation === FORM_OPERATION[1] && !id) {
      history.push('/client/create');
    }
  }, [id, operation, history]);

  const disabledFormFields = useMemo(() => {
    return getDisabeldFields(
      operation === FORM_OPERATION[1] &&
        !getFeatureAvailability(FEATURES.CLIENT_CREATION) // return if feature is available
    );
  }, [operation]);

  return (
    <Content data={client} loading={loading || loadingClient} error={error}>
      {data => (
        <>
          <ClientForm
            client={data}
            isCreate={operation === FORM_OPERATION[0]}
            disabledFormFields={disabledFormFields}
          />
        </>
      )}
    </Content>
  );
};
