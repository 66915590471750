import { useCallback, useState } from 'react';
import { Grid, Menu } from 'semantic-ui-react';

interface Props {
  onClick: (mouseEvent: React.MouseEvent<HTMLElement>) => void;
  name: string;
  label: string;
  icon: string;
  hoverIcon: string;
  isDisabled: boolean;
}
const CustomMenuItem = ({
  onClick,
  name,
  label,
  icon,
  hoverIcon,
  isDisabled
}: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleMouseOver = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <Menu.Item
      name="Deselect"
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      disabled={isDisabled}
      style={{ maxWidth: '190px' }}
    >
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={3}>
            {isHover && !isDisabled ? (
              <img
                alt={name}
                src={hoverIcon}
                style={{
                  margin: 'auto',
                  marginRight: 4,
                  display: 'inline-block',
                  width: '13',
                  maxWidth: 'none'
                }}
              />
            ) : (
              <img
                alt={name}
                src={icon}
                style={{
                  margin: 'auto',
                  marginRight: 4,
                  display: 'inline-block',
                  width: '13',
                  maxWidth: 'none'
                }}
              />
            )}
          </Grid.Column>
          <Grid.Column width={13}>
            <p>{label}</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Menu.Item>
  );
};

export default CustomMenuItem;
