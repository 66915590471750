import React from 'react';
import ClientItem from './components/SideBar/ClientItem';
import AppointmentBody from './components/AppointmentBody';

const NewAppointmentComponents = ({ client, disabledFormFields }) => {
  return (
    <>
      <div className="inner-wrap" style={{ marginTop: '60px' }}>
        <div
          className="appointment-sidebar"
          style={{ marginTop: '0px !important' }}
        >
          {/* LEFT SIDE BAR */}
          <h3>Not Searching for clients</h3>
          <ClientItem client={client} />
        </div>
        <AppointmentBody
          client={client}
          disabledFormFields={disabledFormFields}
        />
      </div>
    </>
  );
};

// component under a context init should be memoized
export default React.memo(NewAppointmentComponents);
