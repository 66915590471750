import React, { useState } from 'react';
import OpeningsCalendar from './OpeningsCalendar';
import OpeningsCalendarView from './OpeningsCalendarView';
import { useOpenings } from './openingsHooks';
import Content from 'views/components/ui/content';
import Loader from 'views/containers/loader';
import { useFormContext, useWatch } from 'react-hook-form';
import SwitchOpeningsView from './SwitchOpeningsView';
import ListOpeningsView from './ListViewComponent';

export const OPENINGS_VIEW = { list: 'LIST', calendar: 'CALENDAR' };

function OpeningsForm({ clientId, disabledFormFields, apptTypes }) {
  const [openingsView, setOpeningsView] = useState(OPENINGS_VIEW.calendar);
  const { control } = useFormContext();
  const { openings, loadingOpenings, availableDays } = useOpenings(
    clientId,
    apptTypes,
    disabledFormFields.selectedSlot
  );
  const selectedDate = useWatch({
    control,
    name: 'selectedDate'
  });

  if (loadingOpenings) return <Loader />;

  if (Array.isArray(openings))
    return (
      <Content data={true} error={false} loadingOpenings={loadingOpenings}>
        {() => (
          <>
            <SwitchOpeningsView
              setOpeningsView={setOpeningsView}
              selectedDate={selectedDate}
              openingsView={openingsView}
              disabledFormFields={disabledFormFields}
            />

            {openingsView === OPENINGS_VIEW.calendar && (
              <>
                <OpeningsCalendar
                  disabledFormFields={disabledFormFields}
                  setOpeningsView={setOpeningsView}
                  selectedDate={selectedDate}
                  availableDays={availableDays}
                />
                <OpeningsCalendarView
                  openings={openings}
                  disabledFormFields={disabledFormFields}
                  selectedDate={selectedDate}
                />
              </>
            )}
            {openingsView === OPENINGS_VIEW.list && (
              <ListOpeningsView
                openings={openings}
                disabledFormFields={disabledFormFields}
              />
            )}
          </>
        )}
      </Content>
    );

  return null;
}

export default React.memo(OpeningsForm);
