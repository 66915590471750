import gql from 'graphql-tag';

export const VALIDATE_LOCATIONS = gql`
  query checkLocationAvailability(
    $providerId: Float!
    $locationType: String!
    $startTime: String!
    $endTime: String!
    $selectedClinicId: Float!
  ) {
    checkLocationAvailability(
      providerId: $providerId
      locationType: $locationType
      startTime: $startTime
      endTime: $endTime
      selectedClinicId: $selectedClinicId
    ) {
      alertMessage
    }
  }
`;
