import { useQuery } from '@apollo/react-hooks';
import {
  faCalendar,
  faClipboardList,
  faClock,
  faHands,
  faLink,
  faMapMarkerAlt,
  faPen,
  faRedo,
  faStickyNote,
  faUserMd
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_AUTH_THERAPY } from 'api/graphql/queries/authorizations';
import { EventType } from 'model/calendar/events';
import moment from 'moment';
import React, { memo, useCallback, useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { getTelehealthLink } from 'utils/common';
import { modalForList } from 'utils/constants/breadcrumbs';
import { DEFAULT_DOCTOR_IMAGE } from 'utils/constants/default';
import { LOCATION_TYPE_LIST } from 'utils/constants/lists';
import {
  ROUTE_PARAMS,
  ROUTE_PATHS,
  TARGET_BLANK
} from 'utils/constants/routes';
import { EVENT_STATUS } from 'utils/constants/status';
import {
  CONVERTED_APPOINTMENT_MESSAGE,
  getDownStreamStatusConfigurations
} from 'utils/downstreams/DownStreamModalConfigurations';
import { getAge } from 'utils/eval';
import { convertDateFromISO, formatAddress, parseUsername } from 'utils/format';
import Content from 'views/components/ui/content';
import ProfilePicture from 'views/components/ui/profile/ProfilePicture';
import AppointmentAuth from './AppointmentAuth';
import { ModalButtons } from './ModalButtons';
import { MODAL_ACTIONS } from './ModalWrapper';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { useEventSyncStatus } from 'api/graphql/v2/hooks/eventSyncStatus';
// helper functions
const isReschedulable = status =>
  !status ||
  status === EVENT_STATUS.needsReschedule ||
  status === EVENT_STATUS.scheduled ||
  EVENT_STATUS.rescheduled;

const isRecurrent = recurringEvery => !!recurringEvery;

export const ModalContent = ({
  data,
  loading,
  editModalHandler,
  modalContentFor,
  cancellationReasons
}) => {
  const history = useHistory();

  const {
    client: patient,
    provider,
    clinic,
    room,
    locationType,
    locationCategory,
    address,
    status,
    telehealthLink,
    note,
    authorizedTherapyID,
    originalStartDate,
    originalEndDate,
    isConverted,
    recurrencePattern
  } = data;
  const [currentStatus, setCurrentStatus] = React.useState({
    athenaStatus: data?.athenaStatus,
    crStatus: data?.crStatus
  });

  const { athenaStatus, crStatus } = useEventSyncStatus({
    eventId: data?.id,
    status
  });
  React.useEffect(() => {
    if (crStatus)
      setCurrentStatus({ athenaStatus: currentStatus.athenaStatus, crStatus });
    if (athenaStatus)
      setCurrentStatus({ crStatus: currentStatus.crStatus, athenaStatus });
  }, [
    crStatus,
    athenaStatus,
    currentStatus.athenaStatus,
    currentStatus.crStatus
  ]);
  const isAdminEvent = data?.type && data?.type === EventType.adminEvent;
  const roomSelected = room?.id ? `, ${room?.name || ''}` : '';

  const cancellationReasonsMap = useMemo(() => {
    const map = new Map();
    cancellationReasons?.forEach(it => {
      map.set(it.reasonId, it);
    });
    return map;
  }, [cancellationReasons]);

  const configurations = getDownStreamStatusConfigurations(
    currentStatus?.crStatus,
    currentStatus?.athenaStatus,
    isConverted
  );

  // TO_DO AUTH MIGRATION skipping this always until auth work is done
  const {
    loading: loadingAuthTherapy,
    data: authTherapy,
    error: authTherapyError
  } = useQuery(GET_AUTH_THERAPY, {
    skip: true || !authorizedTherapyID || isAdminEvent,
    variables: { authTherapyID: authorizedTherapyID }
  });

  const redirectToEditForm = useCallback(() => {
    const url = ROUTE_PATHS.EDIT_APPOINTMENT + ROUTE_PARAMS.EVENT + data?.id;
    window.open(url, TARGET_BLANK);
  }, [data]);

  const rescheduleAction = useCallback(() => {
    if (modalContentFor === modalForList.clientCalendar) {
      redirectToEditForm();
    } else {
      editModalHandler(
        MODAL_ACTIONS.RESCHEDULE,
        isRecurrent(recurrencePattern?.recurringEvery)
      );
    }
  }, [
    modalContentFor,
    redirectToEditForm,
    editModalHandler,
    recurrencePattern
  ]);

  const editTimeAction = useCallback(() => {
    editModalHandler(
      MODAL_ACTIONS.EDIT_TIME,
      isRecurrent(data?.recurrencePattern?.recurringEvery)
    );
  }, [editModalHandler, data]);

  const cancelAction = useCallback(() => {
    editModalHandler(
      MODAL_ACTIONS.CANCEL,
      isRecurrent(recurrencePattern?.recurringEvery)
    );
  }, [editModalHandler, recurrencePattern]);

  const auditHistory = useCallback(() => {
    editModalHandler(MODAL_ACTIONS.AUDIT_HISTORY);
  }, [editModalHandler]);

  const editBehaviorAction = useCallback(() => {
    if (modalContentFor === modalForList.clientCalendar) {
      redirectToEditForm();
    } else if (data?.recurrencePattern?.recurringEvery) {
      editModalHandler(
        MODAL_ACTIONS.EDIT_BEHAVIOR,
        isRecurrent(data?.recurrencePattern?.recurringEvery)
      );
    } else {
      history.push({
        pathname: `/appointments/${data?.id}/editBehavior`,
        state: {
          event: data,
          thisAndFollowingEvents: false,
          //Leaving all these fields for compatibility
          reason: '',
          cancelReasonId: undefined,
          cancelNote: undefined
        }
      });
    }
  }, [editModalHandler, history, data, redirectToEditForm, modalContentFor]);

  const location = address ? formatAddress(address) : undefined;

  const offSiteLocation = location
    ? `${locationCategory} - ${location}`
    : `${locationCategory}`;

  return (
    <Content
      loading={loadingAuthTherapy}
      error={authTherapyError}
      data={authTherapy || true}
    >
      {({ authTherapy }) => {
        return (
          <>
            <div className="event-modal__title">
              <div className="person-left">
                <div className="event-modal__person">
                  <div className="person-image">
                    <ProfilePicture
                      email={isAdminEvent ? provider.email : undefined}
                      className="undefined"
                      img={DEFAULT_DOCTOR_IMAGE}
                      alt={isAdminEvent ? provider?.name : patient?.name}
                    />
                  </div>
                  {isAdminEvent ? (
                    <div className="person-info">
                      <h2>{data?.provider?.displayName}</h2>
                      <p>{data?.provider?.speciality?.title || ''}</p>
                      <p>{data?.provider?.clinic?.name}</p>
                      <p>{data?.provider?.mobilePhone}</p>
                    </div>
                  ) : (
                    <div className="person-info">
                      <h2>
                        {parseUsername(data?.client?.name || patient.name)}
                      </h2>
                      <p>{`${getAge(data?.client?.profile?.dob).years} 
                          years (${convertDateFromISO(
                            data?.client?.profile?.dob
                          )})`}</p>
                    </div>
                  )}
                </div>
                {isConverted && (
                  <div className="other-messages">
                    <p>{CONVERTED_APPOINTMENT_MESSAGE}</p>
                  </div>
                )}
              </div>
              <div className="event-modal__type">
                <span className={`status ${status}`}>{status}</span>
                <ModalButtons
                  loading={loading}
                  isAdminEvent={isAdminEvent}
                  isPastEvent={moment().isAfter(data?.startDate)}
                  isClinical={
                    data?.appointmentType?.eventType?.name === EVENT_TYPES.DT ||
                    EVENT_TYPES.MED
                  }
                  status={status}
                  isReschedulable={isReschedulable}
                  rescheduleAction={rescheduleAction}
                  editTimeAction={editTimeAction}
                  editBehaviorAction={editBehaviorAction}
                  cancelAction={cancelAction}
                  auditHistory={auditHistory}
                  configurations={configurations}
                />
              </div>
            </div>
            <div className="event-modal__body">
              <ul className="event-modal__info-list">
                {(currentStatus?.crStatus || currentStatus?.athenaStatus) && (
                  <li key="messages">
                    <p>{configurations.athenaStatusMessage}</p>
                    <p>{configurations.crStatusMessage}</p>
                  </li>
                )}
                <li key="appointmentType">
                  <FontAwesomeIcon icon={faHands} />
                  {data?.appointmentType?.parent
                    ? data?.appointmentType?.parent?.title
                    : data?.appointmentType?.title}
                </li>
                <li key="calendarIcon">
                  <FontAwesomeIcon icon={faCalendar} />
                  {moment(data?.startDate).format('LL')}
                  {isRecurrent(data?.recurrencePattern?.recurringEvery) && (
                    <span>
                      <FontAwesomeIcon icon={faRedo} />
                    </span>
                  )}
                </li>
                <TimeList
                  start={new Date(data?.startDate)}
                  end={new Date(data?.endDate)}
                  originalEndDate={originalEndDate}
                  originalStartDate={originalStartDate}
                />
                <li key="therapist">
                  {provider && (
                    <Link to={`/providers/${provider?.id}`}>
                      <FontAwesomeIcon icon={faUserMd} />
                      {provider?.name}
                    </Link>
                  )}
                </li>
                {!isAdminEvent && (
                  <li key="roomKey">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    {locationType === LOCATION_TYPE_LIST[0].id // check in clinic
                      ? `${clinic?.name}${roomSelected}`
                      : locationType === LOCATION_TYPE_LIST[2].id // check if telehealth
                      ? 'Telehealth'
                      : offSiteLocation}
                  </li>
                )}
              </ul>
              {!!authorizedTherapyID && (
                <>
                  <div className="verticalLine"></div>
                  <AppointmentAuth
                    authTherapy={authTherapy}
                    appointmentType={data?.appointmentType}
                    authorizedTherapyID={authorizedTherapyID}
                  />
                </>
              )}
            </div>
            <div className="event-modal__body__telehealth">
              <ul className="event-modal__info-list-sub">
                {locationType === LOCATION_TYPE_LIST[2].id && (
                  <li>
                    <FontAwesomeIcon icon={faLink} />
                    Link
                    {telehealthLink === 'N/A' ? ( // TODO: remove when 'N/A' is not used in the back-end
                      <span>{telehealthLink}</span>
                    ) : (
                      <a
                        href={getTelehealthLink(telehealthLink)}
                        target={TARGET_BLANK}
                        rel="noopener noreferrer"
                      >
                        {telehealthLink}
                      </a>
                    )}
                  </li>
                )}
                <li>
                  <FontAwesomeIcon icon={faStickyNote} />
                  Notes
                  <Scrollbars style={{ height: '80px', marginTop: '20px' }}>
                    <div className="wraper-text">{note?.note}</div>
                  </Scrollbars>
                </li>
              </ul>
            </div>
            <div className="event-modal__body__telehealth">
              <ul className="event-modal__info-list-sub">
                <li>
                  <FontAwesomeIcon icon={faClipboardList} />
                  History
                </li>
                {data?.eventAudit ? (
                  data?.eventAudit?.map((audit, index) => (
                    <li key={index}>
                      <span>
                        {audit?.action} by {audit?.actionBy}
                      </span>
                      <br></br>
                      <span>
                        {moment(audit?.actionAt).format('LT, MM/DD/YY')}
                      </span>
                      <br></br>
                      {audit?.reason && (
                        <span>
                          {cancellationReasonsMap?.get(audit?.reason)?.value}
                        </span>
                      )}
                    </li>
                  ))
                ) : (
                  <li key="notAvailableAudit">
                    <span>N/A</span>
                  </li>
                )}
              </ul>
            </div>
          </>
        );
      }}
    </Content>
  );
};

const TimeList = memo(({ originalStartDate, originalEndDate, start, end }) => {
  if (!originalStartDate && !originalEndDate)
    return (
      <li>
        <FontAwesomeIcon icon={faClock} />
        {`${moment(start).format('hh:mm')} - ${moment(end).format('hh:mm a')}`}
      </li>
    );

  return (
    <>
      <li>
        <FontAwesomeIcon icon={faPen} />
        {`${moment(start).format('hh:mm')} - ${moment(end).format('hh:mm a')}`}
      </li>
      <li>
        <FontAwesomeIcon icon={faClock} />
        <span
          style={{
            textDecoration: 'line-through',
            marginLeft: 0
          }}
        >{`${moment(originalStartDate).format('hh:mm')} - ${moment(
          originalEndDate
        ).format('hh:mm a')}`}</span>
      </li>
    </>
  );
});
