import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';

export const availability = gql`
  fragment Availability on Availability {
    startTime
    endTime
    type
  }
`;
export const clientData = gql`
  fragment BasicClientData on Client {
    id
    name
    firstName
    lastName
    dob
    gender
    phenoType
    behavior
    cognition
    communication
    sensorimotor
    clinicId
    cellPhone
    homePhone
    phonePreference
    addressLine1
    personalEmail
    clientType
  }
`;
export const clientsFragment = {
  clientData: gql`
    fragment ClientData on Client {
      ...BasicClientData
      email
    }
    ${clientData}
  `,
  clientAvailabilities: gql`
    fragment ClientAvailabilities on DayAvailability {
      sun {
        ...Availability
      }
      mon {
        ...Availability
      }
      tues {
        ...Availability
      }
      wed {
        ...Availability
      }
      thurs {
        ...Availability
      }
      fri {
        ...Availability
      }
      sat {
        ...Availability
      }
    }
    ${availability}
  `,
  clientFormData: gql`
    fragment ClientFormData on Client {
      ...BasicClientData
      # provider/registration
      usualProvider
      registrationDate
      clientType
      clientTypeNotes
      # identification
      firstNameUsed
      legalSex
      # contact
      addressName
      addressId
      country
      addressLine2
      city
      state
      zipCode
      consentToText
      # additional
      motherName
      motherPhone
      motherEmail
      motherNotes
      fatherName
      fatherPhone
      fatherEmail
      fatherNotes
      otherAuthorizedGuardians
      consentToEmailPHI
      aboutCortica
      # privacy
      noticesOnFile
      consentToCall
      medicalHistoryAuthority
      # insurance
      insuranceProvider
      memberNumber
      groupNumber
      subscriberFirstName
      subscriberLastName
      subscriberDOB
      subscriberSSN
      patientRelationshipToSubscriber
      physicianPhoneNumber
      # legal guardian
      legalGuardianFirstName
      legalGuardianLastName
      # medical profile
      notes
    }
    ${clientData}
  `,
  addressData: gql`
    fragment ClientAddressData on ClientAddress {
      id
      addressLine1
      addressLine2
      state
      city
      name
      zipCode
      country
      __typename @skip(if: true)
    }
  `
};

export const GET_CLIENT_LIST = gql`
  query getClientList(
    $from: Int
    $size: Int
    $search: [JSONObject]
    $sort: [JSONObject]
  ) {
    clients(from: $from, size: $size, search: $search, sort: $sort) {
      data {
        id
        name
        dob
        gender
        clinicId
        phenoType
      }
      count
    }
  }
`;

export const GET_CLIENT = gql`
  query($id: String!) {
    client(id: $id) {
      ...ClientData
    }
  }
  ${clientsFragment.clientData}
`;

export const GET_CLIENT_FORM_FIELDS = gql`
  query($id: String!) {
    client(id: $id) {
      ...ClientFormData
    }
    clientAddresses(clientID: $id) {
      id
      addressLine1
      addressLine2
      state
      city
      name
      zipCode
      country
      __typename @skip(if: true)
    }
  }
  ${clientsFragment.clientFormData}
`;

export const GET_CLIENT_PROFILE = gql`
  query($id: String!, $startTime: String, $endTime: String ,$clientIDs: [String!]) {
    client(id: $id) {
      ...ClientData
    }
    clientAvailability(
      clientIDs: $clientIDs
      timeZone: "${TIMEZONE}"
      startTime: $startTime
      endTime: $endTime
    ) {
      blockedDates {
        startTime
        endTime
      }
      availabilities {
        ...ClientAvailabilities
      }
      lastAvailabilityModificationDate
      availabilityStatus
    }
    clientAvailabilityStatusOptions {
      id
      value
    }
    clientWaitlist(clientID: $id) {
      id
      appointmentTypeID
      appointmentSubType
      isClinical
      clientAvailability
      clientID
      clientName
      clinicID
      date
      isRecommendedTherapy
      quantityPattern
      minRecommendedQuantity
      maxRecommendedQuantity
      requestedQuantity
      toFulfill
      ideal
      authorized
      receiving
      waiting
      status
      canSchedule
    }
    appointmentTypes {
      id
      title
      isClinical
      subTypes {
        id
        title
        duration
      }
    }
    appointmentABATypes {
      id
      title
      isClinical
      subTypes {
        id
        title
        duration
      }
    }
  }
  ${clientsFragment.clientData}
  ${clientsFragment.clientAvailabilities}
`;
export const GET_CLIENT_ADDRESSES = gql`
  query($clientID: String!) {
    clientAddresses(clientID: $clientID) {
      id
      addressLine1
      addressLine2
      state
      city
      name
      zipCode
      country
      __typename @skip(if: true)
    }
  }
`;

export const GET_CLIENT_CALENDAR = gql`
  query(
    $clientID: String!
    $startTime: String!
    $endTime: String!
  ) {
    events(
      clientID: $clientID
      startTime: $startTime
      endTime: $endTime
      timeZone: "${TIMEZONE}"
    ) {
      id
      startTime
      endTime
      appointmentType {
        id
        isClinical
      }
      clinic {
        id
      }
    }
    clientAvailability(
      clientIDs: [$clientID]
      timeZone: "${TIMEZONE}"
      startTime: $startTime
      endTime: $endTime
    ) {
      blockedDates {
        startTime
        endTime
      }
      availabilities {
      ...ClientAvailabilities
      }
      lastAvailabilityModificationDate
      availabilityStatus
    }
    clientAvailabilityStatusOptions {
      id
      value
    }
    appointmentTypes {
      id
      title
      isClinical
      headerColor
    }
    appointmentABATypes {
      id
      title
      isClinical
      headerColor
    }
  }
  ${clientsFragment.clientAvailabilities}
`;
