import React, { useState, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { LOCATION_DEFAULT_COUNTRY } from 'utils/constants/default';
import axios from 'axios';
import { mapCityDataToOptions } from 'utils/mappers/form';

const baseURL = process.env.REACT_APP_VIRTUALEARTH_BASE_URL;
const key = process.env.REACT_APP_VIRTUALEARTH_KEY;

async function getCityResponse(query, setData) {
  axios
    .get(
      `${baseURL}?query=${query}&countryFilter=US&includeEntityTypes=Address&key=${key}`,
      {
        headers: {
          'Content-type': 'application/json'
        }
      }
    )
    .then(response => {
      setData(response?.data?.resourceSets[0].resources[0].value);
    })
    .catch(error =>
      console.log('error occured in fetch', JSON.stringify(error))
    );
}

export const formatSearchedAddress = address => {
  return {
    id: undefined,
    addressLine1: address?.address?.addressLine || '',
    addressLine2: '',
    zipCode: address?.address?.postalCode || '',
    city: address?.address?.locality || '',
    state: address?.address?.adminDistrict || '',
    name: '',
    country: address?.address?.countryRegion || LOCATION_DEFAULT_COUNTRY
  };
};
export default function OffsiteLocationAddressSelector({
  searchMode,
  setSearchMode,
  control,
  clientAddressesOptions,
  setValue,
  clientAddresses,
  setCanSaveAddress
}) {
  const [cityData, setCityData] = useState([]);

  const cityOptions = useMemo(
    () => (cityData.length > 0 ? mapCityDataToOptions(cityData) : []),
    [cityData]
  );

  const getSearchValues = useCallback(
    query => {
      if (query) {
        setSearchMode(true);
        getCityResponse(query, setCityData);
      } else {
        setSearchMode(false);
      }
    },
    [setSearchMode]
  );

  const addressSelectHandler = val => {
    let addresses = [];
    let address = undefined;
    if (searchMode === false) {
      setCanSaveAddress(false);
      addresses = clientAddresses?.filter(address => address.id === val);
      if (addresses.length > 0) address = { ...addresses[0] };
      address.country = LOCATION_DEFAULT_COUNTRY;
    } else {
      addresses = cityData.filter(
        address => address.address?.formattedAddress === val
      );

      if (addresses.length > 0) {
        address = formatSearchedAddress(addresses[0]);
        setCanSaveAddress(true);
      }
    }
    if (address) {
      setValue('address', address, { shouldDirty: true });
    }
    setValue('selectedRoom', null);
    setValue('room.id', null);
  };

  return (
    <Controller
      name="address.addressLine1"
      control={control}
      render={fieldProps => (
        <Select
          showSearch
          notFoundContent="No Results"
          placeholder="Select Address"
          onSearch={query => getSearchValues(query)}
          options={searchMode ? cityOptions : clientAddressesOptions}
          filterOption={false}
          field={fieldProps}
          onSelect={addressSelectHandler}
        />
      )}
    />
  );
}
