import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { useMutation } from 'react-apollo';
import { Button, Icon } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ADMIN_DEFAULT_CANCEL_REASON,
  APPOINTMENT_CATEGORY,
  ATHENA_DEFAULT_DELETE_REASON,
  EVENT_BULK_CANCEL_OPTIONS,
  EVENT_CANCEL_OPTIONS,
  GROUPED_CANCEL_OPTIONS
} from 'utils/constants/lists';
import { APPOINTMENT_CANCEL_SCHEMA } from 'utils/validators/calendar';
import RecurrentCancelRadioGroup from 'pages/MainCalendarPage/AppointmentModals/CancelModal/RecurrentCancelRadioGroup';
import CancelReasonDropdown from 'pages/MainCalendarPage/AppointmentModals/CancelModal/CancelReasonDropdown';
import CancelNoteTextArea from 'pages/MainCalendarPage/AppointmentModals/CancelModal/CancelNoteTextArea';
import {
  selectedItemsToCancelVar,
  updateCalendarPref
} from 'utils/cache/calendar';
import Content from 'views/components/ui/content';
import { useCancellationReasons } from 'hooks/CancellationReasonsHook';
import { EventType, ICalendarEvent } from 'model/calendar/events';
import { CANCEL_BULK_EVENTS } from 'api/graphql/v2/mutations/Event';
import { IEvent } from 'model/v2';
import { CancelModalWrapper } from 'pages/MainCalendarPage/AppointmentModals/style';
import BulkCancelWarn from './BulkCancelWarn';
import CancelObservers from 'pages/MainCalendarPage/AppointmentModals/CancelModal/CancelObservers';
import CancelAttendeeRow from 'pages/MainCalendarPage/AppointmentView/AttendeesView/CancelAttendeeRow';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { PURPLE } from 'utils/constants/theme';
import { teal } from '@material-ui/core/colors';
import { formatAttendeeName } from 'utils/format';
import { formatDates } from 'pages/MainCalendarPage/AppointmentView/TimeView';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';

interface Props {
  onClose: () => void;
  saving: boolean;
  setSaving: (saving: boolean) => void;
  recurringBulkEventsCount: number;
  deleteWhenCancelAppointmentsIds: number[];
  observationOfLeadEvent: any;
  getLeadEventIdWithMasterId: any;
  ObservationCountOfSeriesFromLeadEventId: any;
}

interface IBulkCancelForm {
  recurrent: string;
  cancelReason: string | null;
  cancelNote?: string;
}

const BulkCancelForm: React.FC<Props> = ({
  onClose,
  saving,
  setSaving,
  recurringBulkEventsCount,
  deleteWhenCancelAppointmentsIds,
  observationOfLeadEvent,
  getLeadEventIdWithMasterId
}: Props) => {
  const recurrentRadio: IEvent[] = [];
  const [cancelBulkEvents] = useMutation(CANCEL_BULK_EVENTS);
  const selectedItemsToCancel = useReactiveVar(selectedItemsToCancelVar);
  const { cancelReasons, isCancelReasonsLoading } = useCancellationReasons();
  const isAllSelectedAdmin = useMemo(() => {
    const selectedEvents = [...selectedItemsToCancel.values()];
    return selectedEvents.every(
      (event: ICalendarEvent) => event.type === EventType.adminEvent
    );
  }, [selectedItemsToCancel]);
  const includeEventsToCancel = useMemo(() => {
    const selectedEvents = [...selectedItemsToCancel.values()];
    selectedItemsToCancel.forEach((event: IEvent) => {
      recurrentRadio.push({ isObservation: event.isObservation });
    });

    return selectedEvents.some(
      (event: ICalendarEvent) =>
        !deleteWhenCancelAppointmentsIds?.includes(event?.appointmentType?.id!)
    );
  }, [selectedItemsToCancel, recurrentRadio, deleteWhenCancelAppointmentsIds]);

  const getSingleEventsNumber = useCallback(() => {
    const selectedEvents = [...selectedItemsToCancel.values()];
    let singleCancelEventsNumber = 0;
    let singleDeleteEventsNumber = 0;
    for (const event of selectedEvents) {
      if (event.recurrencePattern?.recurringEvery! === 0) {
        if (
          !deleteWhenCancelAppointmentsIds?.includes(
            event?.appointmentType?.id!
          )
        ) {
          singleCancelEventsNumber++;
        } else if (
          deleteWhenCancelAppointmentsIds?.includes(event?.appointmentType?.id!)
        ) {
          singleDeleteEventsNumber++;
        }
      }
    }
    const totalSingleEventsNumber =
      singleCancelEventsNumber + singleDeleteEventsNumber;
    return {
      singleCancelEventsNumber,
      singleDeleteEventsNumber,
      totalSingleEventsNumber
    };
  }, [deleteWhenCancelAppointmentsIds, selectedItemsToCancel]);

  const includeSingleCancelEvents = useMemo(() => {
    const { singleCancelEventsNumber } = getSingleEventsNumber();
    return singleCancelEventsNumber > 0;
  }, [getSingleEventsNumber]);

  const methods = useForm<IBulkCancelForm>({
    defaultValues: {
      recurrent: GROUPED_CANCEL_OPTIONS[0].value,
      cancelReason: null,
      cancelNote: undefined
    },
    resolver: yupResolver(
      APPOINTMENT_CANCEL_SCHEMA(
        includeEventsToCancel,
        includeSingleCancelEvents
      )
    )
  });

  let cancelOptions = useMemo(() => {
    const { totalSingleEventsNumber } = getSingleEventsNumber();
    const seriesEventsNumber =
      selectedItemsToCancel.size - totalSingleEventsNumber;
    const isRecurring = seriesEventsNumber > 0;
    let appointmentTypesList = [];
    for (var singleSelected of selectedItemsToCancel.values()) {
      appointmentTypesList.push(singleSelected);
    }
    const cOptions = EVENT_BULK_CANCEL_OPTIONS(
      isRecurring,
      selectedItemsToCancel.size,
      seriesEventsNumber,
      recurringBulkEventsCount,
      totalSingleEventsNumber,
      appointmentTypesList
    );
    return cOptions;
  }, [
    getSingleEventsNumber,
    recurringBulkEventsCount,
    selectedItemsToCancel.size,
    selectedItemsToCancel
  ]);

  const mappedSelectedItemsToCancel = useCallback(() => {
    const mappedSelectedItemsToCancel: IEvent[] = [];
    selectedItemsToCancel.forEach((event: ICalendarEvent) => {
      const mappedEvent = {
        id: event.id,
        duration: event.duration,
        recurrencePattern: event.recurrencePattern,
        appointmentType: { id: event.appointmentType?.id },
        isPendingConfirmation: event.isPendingConfirmation,
        ...(event.client?.id && { client: { id: event.client.id } })
      };
      mappedSelectedItemsToCancel.push(mappedEvent);
    });
    return mappedSelectedItemsToCancel;
  }, [selectedItemsToCancel]);

  const selectedItemsStartDate = useCallback(() => {
    const firstItemKey = mappedSelectedItemsToCancel()[0].id;
    return selectedItemsToCancel.get(firstItemKey!)?.startDate;
  }, [mappedSelectedItemsToCancel, selectedItemsToCancel]);

  const { recurrent } = methods.watch(['recurrent']);

  const isNotesAndReasonAppearing = useMemo(() => {
    if (
      recurrent === 'single' ||
      recurrent === 'all' ||
      recurrent === 'deleteSingle'
    )
      return includeEventsToCancel;
    else return includeSingleCancelEvents;
  }, [includeEventsToCancel, includeSingleCancelEvents, recurrent]);

  const [showWarnPopup, setShowWarnPopup] = useState(false);

  useEffect(() => {
    setShowWarnPopup(includeSingleCancelEvents && recurrent === 'all');
  }, [recurrent, includeSingleCancelEvents, observationOfLeadEvent]);

  const [selectedOption, setSelectedOption] = React.useState(
    GROUPED_CANCEL_OPTIONS[0].value
  );

  const submitForm = useCallback(
    (submittedData: IBulkCancelForm) => {
      setSaving(true);
      cancelBulkEvents({
        variables: {
          data: {
            events: mappedSelectedItemsToCancel(),
            isHardDeleteCr:
              methods.getValues('recurrent') === 'deleteSingle' ? true : false,
            recurrent:
              submittedData.recurrent === GROUPED_CANCEL_OPTIONS[2].value,
            cancelReasonId: submittedData.cancelReason
              ? submittedData.cancelReason
              : isAllSelectedAdmin
              ? ADMIN_DEFAULT_CANCEL_REASON
              : ATHENA_DEFAULT_DELETE_REASON,
            cancelNote: submittedData.cancelNote
          }
        }
      })
        .then(() => {
          setSaving(false);
          updateCalendarPref(moment(selectedItemsStartDate()));
          onClose();
        })
        .catch(() => {
          setSaving(false);
        });
    },
    [
      cancelBulkEvents,
      isAllSelectedAdmin,
      mappedSelectedItemsToCancel,
      onClose,
      selectedItemsStartDate,
      setSaving
    ]
  );

  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);
  const selectedEvents = [...selectedItemsToCancel.values()];
  const selectedEventObj =
    observationOfLeadEvent?.observationOfSeriesLeadEvent[0];
  const singleEventsObj = selectedEvents[0];
  const provider = singleEventsObj?.provider;
  const client = singleEventsObj?.client;
  const observations = selectedEventObj?.observations;
  const providerName = formatAttendeeName(
    provider?.firstName,
    provider?.lastName,
    provider?.clinic?.abbreviation,
    provider?.speciality?.abbreviation
  );

  const clientName = formatAttendeeName(
    client?.firstName,
    client?.lastName,
    client?.clinic?.abbreviation
  );
  const eventTimes = formatDates(
    singleEventsObj?.startDate,
    singleEventsObj?.endDate,
    singleEventsObj?.isObservation
  );
  const isABA = useMemo(
    () =>
      singleEventsObj?.appointmentType?.eventType?.name ===
      APPOINTMENT_CATEGORY[0].value,
    [singleEventsObj?.appointmentType?.eventType?.name]
  );
  const eventActionString =
    singleEventsObj?.appointmentType?.eventType?.name ===
    APPOINTMENT_CATEGORY[3].value
      ? 'delete'
      : 'cancel';
  let schedulingFeature: any = FEATURES.SHADOW_SCHEDULING;
  if (isABA) {
    schedulingFeature = FEATURES.ABA_SHADOW_SCHEDULING;
  }
  const ShadowFeatureAvailability = getFeatureAvailability(schedulingFeature);
  return (
    <CancelModalWrapper>
      <Content loading={isCancelReasonsLoading} data={true} fullHeight={true}>
        {() => (
          <>
            <h2 className="cancel-modal-title">Confirm Cancellation(s)</h2>
            <p className="cancel-modal-text">
              {' '}
              {`Are you sure you want to ${eventActionString} the`} (
              <span className="selected-events-num">
                {selectedItemsToCancel.size}
              </span>
              ) selected events?
            </p>
            {observations &&
            !recurrentRadio[0]?.isObservation &&
            ShadowFeatureAvailability &&
            observations.length > 0 &&
            selectedItemsToCancel.size < 2 ? (
              <>
                <p
                  className="cancel-modal-cotent"
                  style={{ paddingBottom: '10px' }}
                >
                  Canceling this event will remove the appointment for the
                  provider, patient and observers
                </p>
              </>
            ) : (
              ''
            )}
            {ShadowFeatureAvailability &&
            ((getLeadEventIdWithMasterId?.SingleEventObservations > 0 &&
              selectedItemsToCancel.size > 1) ||
              (recurrentRadio[0]?.isObservation &&
                getLeadEventIdWithMasterId?.SingleEventObservations > 0)) ? (
              <>
                <p
                  className="cancel-modal-cotent"
                  style={{ marginBottom: '18px' }}
                >
                  Some selected appointments may include observers. Please
                  review before canceling.
                </p>
              </>
            ) : (
              ''
            )}
            <FormProvider {...methods}>
              {recurrentRadio[0]?.isObservation ? null : (
                <RecurrentCancelRadioGroup
                  cancelOptions={cancelOptions}
                  isColorNumbers
                  setSelectedOption={setSelectedOption}
                />
              )}
              {selectedItemsToCancel.size < 2 &&
              !recurrentRadio[0]?.isObservation ? (
                <>
                  <div className="cancel-modal-attendees-div">
                    {!recurrentRadio[0]?.isObservation &&
                    observationOfLeadEvent?.observationOfSeriesLeadEvent
                      .length > 0 &&
                    selectedItemsToCancel.size < 2 &&
                    selectedOption === 'all' ? (
                      <p className="cancel-modal-attendees">
                        Impacted Observers
                      </p>
                    ) : null}

                    {observationOfLeadEvent &&
                      ShadowFeatureAvailability &&
                      selectedOption === 'all' &&
                      (observationOfLeadEvent.observationOfSeriesLeadEvent
                        .length > 0 && selectedItemsToCancel.size < 2
                        ? selectedOption == 'all'
                          ? observationOfLeadEvent.observationOfSeriesLeadEvent.map(
                              (observationEvent: IEvent, index: number) => {
                                return (
                                  <CancelObservers
                                    observationEvent={
                                      observationEvent.observations
                                    }
                                    eventIndex={index}
                                    event={observationEvent}
                                  />
                                );
                              }
                            )
                          : selectedEvents.length > 0 &&
                            singleEventsObj?.observationsCount !== undefined &&
                            singleEventsObj?.observationsCount > 0 &&
                            [
                              observationOfLeadEvent
                                .observationOfSeriesLeadEvent[0]
                            ].map((observationEvent: IEvent, index: number) => {
                              return (
                                <CancelObservers
                                  observationEvent={
                                    observationEvent.observations
                                  }
                                  eventIndex={index}
                                  event={observationEvent}
                                />
                              );
                            })
                        : null)}
                    {observations?.length > 0 &&
                    selectedOption === EVENT_CANCEL_OPTIONS(true)[1].value ? (
                      <>
                        <div style={{ paddingBottom: '15px' }}></div>
                      </>
                    ) : (
                      ''
                    )}
                    <p
                      className="cancel-modal-attendees"
                      style={{ paddingBottom: '15px' }}
                    >
                      Attendees
                    </p>
                    {provider && (
                      <CancelAttendeeRow
                        eventType={selectedEventObj?.type!}
                        displayName={providerName}
                        displayId={provider.id}
                        icon={<EyeOutlined />}
                        iconTitle={'Provider'}
                        color={PURPLE}
                        classNameVar={'provider-btn'}
                      />
                    )}
                    {client && (
                      <CancelAttendeeRow
                        eventType={selectedEventObj?.type!}
                        displayName={clientName}
                        displayId={client.id}
                        icon={<UserOutlined />}
                        iconTitle={'Patient'}
                        color={teal[100]}
                        classNameVar={'provider-btn'}
                      />
                    )}
                    {ShadowFeatureAvailability &&
                    singleEventsObj?.observationsCount !== undefined &&
                    singleEventsObj?.observationsCount > 0
                      ? observations?.map(
                          (
                            observer: {
                              provider: {
                                id: any;
                                firstName: any;
                                lastName: any;
                                clinic: { abbreviation: any };
                                speciality: { abbreviation: any };
                              };
                            },
                            index: number
                          ) => {
                            const observerName = formatAttendeeName(
                              observer.provider?.firstName,
                              observer.provider?.lastName,
                              observer.provider?.clinic?.abbreviation,
                              observer.provider?.speciality?.abbreviation
                            );
                            return (
                              <CancelAttendeeRow
                                eventType={selectedEventObj?.type!}
                                displayName={observerName}
                                displayId={observer.provider?.id}
                                icon={<EyeOutlined />}
                                iconTitle={`Observer ${index + 1}`}
                                color={PURPLE}
                                classNameVar={`provider-btn observer${index +
                                  1}-btn`}
                              />
                            );
                          }
                        )
                      : ''}
                    {eventTimes ? (
                      <>
                        <p className="cancel-modal-attendees">Event Details</p>
                        <p
                          className="event-date"
                          style={{ fontSize: '12px', marginBottom: '5px' }}
                        >
                          <Icon name="calendar outline" className="time-icon" />
                          {eventTimes?.date}
                        </p>
                        <p className="event-time" style={{ fontSize: '12px' }}>
                          <Icon name="clock outline" className="time-icon" />
                          {eventTimes?.time}
                        </p>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ) : (
                ' '
              )}
              {/* {recurrentRadio[0]?.isObservation &&
              getLeadEventIdWithMasterId?.SingleEventObservations > 0 ? (
                <p className="observer-warning">This contain Observer</p>
              ) : null} */}
              {/* {!recurrentRadio[0]?.isObservation &&
              getLeadEventIdWithMasterId?.SingleEventObservations > 0 &&
              recurrent === 'single' ? (
                <p className="observer-warning">
                  Some selected appointments may include observers. Please
                  review before canceling.
                </p>
              ) : null} */}
              {/* {!recurrentRadio[0]?.isObservation &&
              ObservationCountOfSeriesFromLeadEventId
                ?.getObservationCountOfSeriesFromLeadEventId[0] > 0 &&
              recurrent === 'all' ? (
                <p className="observer-warning">
                  Some selected appointments may include observers. Please
                  review before canceling.
                </p>
              ) : null} */}
              {isNotesAndReasonAppearing && (
                <>
                  <BulkCancelWarn
                    showWarnPopup={showWarnPopup}
                    setShowWarnPopup={setShowWarnPopup}
                  />
                  <CancelReasonDropdown
                    cancelReasons={cancelReasons}
                    isDeleteOrAdminEvent={false}
                  />
                  <CancelNoteTextArea />
                </>
              )}
              <div className="modal-actions">
                <Button onClick={onClose} basic disabled={saving}>
                  No, GO Back
                </Button>
                <Button
                  negative
                  onClick={methods.handleSubmit(submitForm, submitError)}
                  loading={saving}
                  disabled={saving}
                >
                  Yes, Cancel
                </Button>
              </div>
            </FormProvider>
          </>
        )}
      </Content>
    </CancelModalWrapper>
  );
};

export default BulkCancelForm;
