import React from 'react';
import * as PropTypes from 'prop-types';
import {
  LibInput,
  LibAutoFormattedInput,
  LibTextArea,
  LibDecimalInput
} from './input.style';
import { getAutoFormatOptions } from './utils';

const Input = ({ type, name, paragraph, value, ...restProps }) => {
  return paragraph ? (
    <p>{value}</p>
  ) : (
    <LibInput type={type} name={name} value={value} {...restProps} />
  );
};

const AutoFormattedInput = ({ type, name, value, ...restProps }) => {
  return (
    <LibAutoFormattedInput
      name={name}
      value={value}
      options={getAutoFormatOptions(type)}
      {...restProps}
    />
  );
};

const DecimalInput = ({ name, defaultValue, ...restProps }) => {
  return (
    <LibDecimalInput name={name} defaultValue={defaultValue} {...restProps} />
  );
};

const AutoFormattedInputLip = ({ value, name, type, ...restProps }) => {
  return (
    <LibAutoFormattedInput
      name={name}
      value={value || ''}
      options={getAutoFormatOptions(type)}
      {...restProps}
    />
  );
};

const TextArea = ({ name, paragraph, value, ...restProps }) => {
  return <LibTextArea name={name} value={value} {...restProps} />;
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  paragraph: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
TextArea.propTypes = {
  name: PropTypes.string,
  paragraph: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Input.defaultProps = {
  type: 'text',
  name: '',
  paragraph: false,
  value: ''
};
TextArea.defaultProps = {
  name: '',
  value: ''
};

export default Input;
export {
  Input,
  AutoFormattedInput,
  TextArea,
  AutoFormattedInputLip,
  DecimalInput
};
