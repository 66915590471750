import {
  AutoFormattedInput,
  Select
} from 'api/sharedComponents/reactHookFormComponents';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { LibInput } from 'lib/ui/input/input.style';
import { IClinic } from 'model/v2';
import React, { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { STATES, TIMEZONES_LIST } from 'utils/constants/commonLists';
import { mapToOptions } from 'utils/mappers/form';
import ClinicInput from './ClinicInput';
import ClinicType from './ClinicType';

export const mapToStateOptions = (states: string[]) =>
  states.map(state => {
    return {
      label: state,
      value: state
    };
  });

interface IProps {
  editMode: boolean;
  clinic: IClinic | undefined;
  clinics: IClinic[];
}
const ClinicInfo: React.FC<IProps> = ({
  editMode,
  clinic,
  clinics
}: IProps) => {
  const stateOptions = useMemo(() => mapToStateOptions(STATES), []);
  const timeZoneOptions = useMemo(() => mapToOptions(TIMEZONES_LIST), []);
  const { control, errors } = useFormContext();
  const { address } = useWatch({
    control,
    name: ['address']
  });

  const mapFullAddress = () =>
    `${address?.addressLine1 || ''}, ${address?.state ||
      ''}, ${address?.zipCode || ''}`;
  return (
    <>
      <div className="row">
        <ClinicInput
          control={control}
          label="Clinic Name"
          name={'name'}
          disabled={false}
          placeholder="Clinic Name"
          width="300px"
          marginRight="18px"
        />
        <ClinicInput
          control={control}
          label="Clinic Abbreviation"
          name={'abbreviation'}
          disabled={false}
          placeholder="Clinic Abbreviation"
          width="150px"
          marginRight="18px"
        />
      </div>
      <ClinicType
        clinics={clinics}
        clinicTypeSelected={clinic?.clinicType}
        clinicId={clinic?.id}
        editMode={editMode}
        control={control}
      />
      <div className="row">
        <Controller
          control={control}
          name={'address.id'}
          render={() => <></>}
        />
        <ClinicInput
          control={control}
          label="Address line 1"
          name={'address.addressLine1'}
          disabled={false}
          placeholder="Address line 1"
          width="300px"
          marginRight="18px"
        />
        <ClinicInput
          control={control}
          label="City"
          name={'address.city'}
          disabled={false}
          placeholder="City"
          width="145px"
          marginRight="9px"
        />
        <div className="form-input">
          <FormItem label={'State'}>
            <Controller
              control={control}
              name={'address.state'}
              render={fieldProps => (
                <Select
                  options={stateOptions}
                  placeholder="State"
                  field={fieldProps}
                  errors={errors}
                  style={{ width: '65px', marginRight: '9px' }}
                  showSearch={true}
                />
              )}
            />
          </FormItem>
        </div>
        <ClinicInput
          control={control}
          label="Zip"
          name={'address.zipCode'}
          disabled={false}
          placeholder="Zip"
          width="70px"
        />
      </div>
      <div className="row">
        <div className="form-select">
          <FormItem label="Full Address">
            <LibInput
              name={'address.addressLine2'}
              disabled={true}
              type="text"
              placeholder="Full Address"
              defaultValue=""
              value={mapFullAddress()}
            />
          </FormItem>
        </div>
        <FormItem label={'Timezone'}>
          <Controller
            control={control}
            name={'timezone'}
            render={fieldProps => (
              <Select
                options={timeZoneOptions}
                placeholder="Timezone"
                field={fieldProps}
                errors={undefined}
                style={{ width: '300px' }}
                showSearch={true}
              />
            )}
          />
        </FormItem>
      </div>
      <div className="row">
        <div className="form-select">
          <FormItem label={'Phone'}>
            <Controller
              control={control}
              name={'phone'}
              render={fieldProps => (
                <AutoFormattedInput
                  type={'phone'}
                  field={fieldProps}
                  placeholder={'000-000-0000'}
                  errors={errors}
                  style={{ width: '300px' }}
                />
              )}
            />
          </FormItem>
        </div>
        <div className="form-select">
          <FormItem label={'Fax'}>
            <Controller
              control={control}
              name={'fax'}
              render={fieldProps => (
                <AutoFormattedInput
                  type={'phone'}
                  field={fieldProps}
                  placeholder={'000-000-0000'}
                  errors={errors}
                  style={{ width: '300px' }}
                />
              )}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <ClinicInput
          control={control}
          label="Athena Department ID"
          name={'departmentId'}
          disabled={false}
          placeholder="Athena Department ID"
          width="300px"
          marginRight="18px"
        />
        <ClinicInput
          control={control}
          label="Central Reach Location ID"
          name={'centralReachLocationId'}
          disabled={false}
          placeholder="Central Reach Location ID"
          width="300px"
        />
      </div>
      <div className="row">
        <ClinicInput
          control={control}
          label="Central Reach Primary Key"
          name={'centralReachPk'}
          disabled={false}
          placeholder="Central Reach Primary Key"
          width="300px"
          marginRight="18px"
        />
        <ClinicInput
          control={control}
          label="Central Reach ID"
          name={'centralReachId'}
          disabled={false}
          placeholder="Central Reach ID"
          width="300px"
        />
      </div>
      {editMode && (
        <ClinicInput
          control={control}
          label="ID"
          name={'id'}
          disabled={editMode}
          placeholder="ID"
          width="300px"
        />
      )}
      <ClinicInput
        control={control}
        label="Admin Appointment ID"
        name={'unavailableClientId'}
        disabled={false}
        placeholder="Admin Appointment ID"
        width="300px"
      />
    </>
  );
};
export default React.memo(ClinicInfo);
