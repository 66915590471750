import styled from 'styled-components';

export const HeaderWrap = styled.div`
  padding: 25px 0 0;
  background-color: #fff;
  .clinic-info {
    width: calc(100% - 150px);
    input {
      color: #21303a;
      background-color: #fff;
      pointer-events: none;
    }
  }
  .clinic-edit {
    max-width: 150px;
  }
`;
export const ProfileWrap = styled.div`
  .headerRow {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 200px !important;
  }
  .amenities-info {
    display: flex;
    align-items: flex-start;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:not(:first-child) {
        padding-left: 40px;
      }
    }
  }
  .edit {
    cursor: pointer;
  }
  .clinic-hours {
    padding: 25px 0 0 0;
    max-width: 1280px;
    margin: 0 auto;
    &.noedit {
      .day-row {
        align-items: center;
        padding-bottom: 20px;
      }
      .day-row__name {
        padding-top: 0;
        padding-right: 30px;
      }
      .day-row__open-hours {
        > * {
          margin-right: 30px;
          color: #21303a;
        }
      }
      .day-row__closed-hours {
        margin-left: 100px;
        > * {
          margin-right: 30px;
          color: #21303a;
        }
      }
      .clinic-hours__topbar {
        padding-bottom: 20px;
        span {
          color: #000000;
        }
      }
      .day-row__closed-allDay {
        padding-left: 0;
      }
    }
    h2 {
      padding-bottom: 40px;
    }
  }
  .day-row {
    padding-bottom: 9px;
    &.closed {
      div,
      input {
        color: #919699;
      }
    }
    &__input {
      padding: 0;
      > span {
        padding-right: 4px;
      }
      .ant-input-suffix {
        opacity: 0.3;
      }
    }
    input {
      max-width: 104px;
      color: #000;
    }
    label span {
      color: #000;
    }
    i {
      /* display: none; */
    }
    &__name {
      width: 60px;
      text-align: right;
      padding-right: 17px;
      padding-top: 5px;
      font-size: 14px;
      color: #000000;
      text-transform: capitalize;
    }
    &__open-hours {
      display: flex;
      align-items: flex-start;
      min-width: 90px;
      > * {
        margin-right: 9px;
      }
    }
    &__closed-hours {
      margin-left: auto;
      display: flex;
      align-items: flex-start;
      > * {
        margin-right: 9px;
      }
    }
    &__closed-allDay {
      padding-top: 4px;
      padding-left: 15px;
    }
    &__select {
      width: 64px;
    }
    &__input {
      display: flex;
      flex-direction: column;
    }
  }
`;
