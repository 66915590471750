export const FEATURE_STAGES = Object.freeze({
  DISABLED: 'DISABLED',
  DEV: 'DEV',
  STAGING: 'STAGING',
  UAT: 'UAT',
  PROD: 'PROD'
});

export const FEATURES = Object.freeze({
  TELEHEALTH: FEATURE_STAGES.PROD,
  CARE_PLAN: FEATURE_STAGES.DISABLED,
  AUTHORIZATION: FEATURE_STAGES.DISABLED,
  WAITLIST: FEATURE_STAGES.PROD,
  ADMIN_APPOINTMENTS: FEATURE_STAGES.PROD,
  NOTES: FEATURE_STAGES.PROD,
  APPOINTMENT_BY_MINUTE: FEATURE_STAGES.PROD,
  CLIENT_ADDRESSES: FEATURE_STAGES.PROD,
  CONFIGURATIONS: FEATURE_STAGES.PROD,
  CALENDAR_PREVIEW: FEATURE_STAGES.PROD,
  AUDIT_HISTORY: FEATURE_STAGES.PROD,
  CLINIC_CONFIGURATION: FEATURE_STAGES.PROD,
  CLIENT_CREATION: FEATURE_STAGES.PROD, // will not show up if feature flag is not here
  NEW_APPOINTMENT_FORM: FEATURE_STAGES.PROD,
  CALENDAR_PREVIEW_DAY_WEEK: FEATURE_STAGES.DISABLED,
  AVAILABILITY_TEMPLATE: FEATURE_STAGES.PROD,
  SMART_SCHEDULE: FEATURE_STAGES.PROD,
  CLIENT_AVAILABILITY_FILTER: FEATURE_STAGES.DISABLED,
  CALENDARS_SUBSCRIPTIONS: FEATURE_STAGES.PROD,
  FAILURE_NOTICE_SUBSCRIPTIONS: FEATURE_STAGES.PROD,
  SUCCESS_NOTICE_SUBSCRIPTIONS: FEATURE_STAGES.PROD,
  EVENT_SYNC_STATUS_SUBSCRIPTIONS: FEATURE_STAGES.PROD,
  PROVIDER_FILTER: FEATURE_STAGES.PROD,
  FIVE_MINUTE_SCHEDULING: FEATURE_STAGES.PROD,
  NEW_CLIENT_AVAILABILITY_FILTER: FEATURE_STAGES.PROD,
  BULK_CANCEL: FEATURE_STAGES.PROD,
  VIEW_CLIENT_AVAILABILITY: FEATURE_STAGES.PROD,
  DT_SMART_SCHEDULE: FEATURE_STAGES.PROD,
  RESCHEDULE_MODAL_UPDATES: FEATURE_STAGES.PROD,
  SHADOW_SCHEDULING: FEATURE_STAGES.PROD,
  RETRY_SYNC: FEATURE_STAGES.PROD,
  FORCE_FAIL: FEATURE_STAGES.PROD,
  FAILED_SYNC: FEATURE_STAGES.DEV,
  CANCEL_MODAL_UPDATES: FEATURE_STAGES.PROD,
  SPECIALTY_FILTER: FEATURE_STAGES.PROD,
  EVALSLOTS_TO_PLANNER: FEATURE_STAGES.PROD,
  ABA_SHADOW_SCHEDULING: FEATURE_STAGES.PROD,
  FIND_SUBSTITUTE_PROVIDER: FEATURE_STAGES.PROD,
  FIND_MAKEUP: FEATURE_STAGES.PROD
});
