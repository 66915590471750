import React, { useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import MainButton from 'views/components/button';
import { ConfigurationFormWrapper } from '../AppointmetTypesForm.style';
import {
  IMEDAppointmentSubType,
  MEDAppointmentSubType
} from 'model/MEDAppointmentSubtype';
import {
  Input,
  Select,
  Timepicker
} from 'api/sharedComponents/reactHookFormComponents';
import { Lookup } from 'model/lookup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MED_APPOINTMENT_SUBTYPE_SCHEMA } from 'utils/validators/configurations/MEDAppointmentSubType';
import { IAppointmentType } from 'model/appointment';
import { mapAppointmentTitlesToOptions } from 'utils/mappers/form';
import { useMutation } from '@apollo/react-hooks';
import { GET_MED_APPOINTMENT_SUBTYPES } from 'api/graphql/v2/queries/AppointmentTypes';
import { PUT_MED_APPOINTMENT_SUBTYPE } from 'api/graphql/v2/mutations/AppointmentTypes';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { formatMEDSubTypeToInput } from 'utils/format/appointmentType';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';

interface Props {
  superTypeList?: IAppointmentType[];
  initialValues: IMEDAppointmentSubType;
  onFormClose: () => void;
  title: string;
}

const MEDAppointmentSubTypesForm: React.FC<Props> = ({
  superTypeList,
  initialValues,
  title,
  onFormClose
}: Props) => {
  const {
    handleSubmit,
    reset,
    control,
    errors,
    formState: { isSubmitting, isDirty }
  } = useForm<IMEDAppointmentSubType>({
    defaultValues: initialValues,
    resolver: yupResolver(MED_APPOINTMENT_SUBTYPE_SCHEMA())
  });

  const superTypeListOptions = useMemo(
    () => (superTypeList ? mapAppointmentTitlesToOptions(superTypeList) : []),
    [superTypeList]
  );

  const [submitMEDAppointmentSubType] = useMutation(
    PUT_MED_APPOINTMENT_SUBTYPE
  );

  const onSubmit = handleSubmit(async (data: IMEDAppointmentSubType) => {
    await submitMEDAppointmentSubType({
      variables: {
        settingsWithAppointment: formatMEDSubTypeToInput(data, initialValues),
        eventType: EVENT_TYPES.MED
      },
      refetchQueries: [
        {
          query: GET_MED_APPOINTMENT_SUBTYPES
        }
      ]
    }).then(onFormClose);
  });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  return (
    <ConfigurationFormWrapper>
      <div className="header">
        <h3>{title}</h3>
        <button className="ui icon button close-button" onClick={onFormClose}>
          <i className="close icon"></i>
        </button>
      </div>

      <form onSubmit={onSubmit}>
        <FormItem label="Title">
          <Controller
            name={MEDAppointmentSubType.title}
            control={control}
            render={fieldProps => (
              <Input
                field={fieldProps}
                placeholder="Input title"
                errors={errors}
              />
            )}
          />
        </FormItem>

        {initialValues?.id !== 0 && (
          <FormItem label="ID">
            <Controller
              name={MEDAppointmentSubType.id}
              control={control}
              render={fieldProps => (
                <Input
                  field={fieldProps}
                  placeholder="Input ID"
                  errors={errors}
                  disabled
                />
              )}
            />
          </FormItem>
        )}
        <FormItem label="Athena Appointment Type ID">
          <Controller
            name={MEDAppointmentSubType.athenaId}
            control={control}
            render={fieldProps => (
              <Input
                placeholder="Input Athena Appointment Type ID"
                field={fieldProps}
                errors={errors}
              />
            )}
          />
        </FormItem>
        <FormItem label="Parent Type">
          <Controller
            name={`${MEDAppointmentSubType.superType}.${Lookup.id}`}
            control={control}
            render={fieldProps => (
              <Select
                options={superTypeListOptions}
                placeholder="Select Parent Type"
                field={fieldProps}
                errors={errors}
              />
            )}
          />
        </FormItem>
        <FormItem label="Duration">
          <Controller
            name={MEDAppointmentSubType.duration}
            control={control}
            render={fieldProps => (
              <Timepicker
                name={MEDAppointmentSubType.duration}
                minuteStep={15}
                format="HH:mm"
                disabled={false}
                field={fieldProps}
                errors={errors}
              />
            )}
          />
        </FormItem>
        <FormItem label="CR Appointment Name">
          <Controller
            name={MEDAppointmentSubType.crName}
            control={control}
            render={fieldProps => (
              <Input
                field={fieldProps}
                placeholder="Input CR Appointment Name"
                errors={errors}
              />
            )}
          />
        </FormItem>
        <FormItem label="CR Appointment Type">
          <Controller
            name={MEDAppointmentSubType.crType}
            control={control}
            render={fieldProps => (
              <Input
                field={fieldProps}
                placeholder="Input CR Appointment Type"
                errors={errors}
              />
            )}
          />
        </FormItem>
        <MainButton
          className="submit-button"
          title="Save"
          onClick={() => {
            onSubmit();
          }}
          disabled={isSubmitting || !isDirty}
          loading={isSubmitting}
        />
      </form>
    </ConfigurationFormWrapper>
  );
};
export default React.memo(MEDAppointmentSubTypesForm);
