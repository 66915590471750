import { useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { PAGE_SIZE } from 'utils/constants/clientList';
import { DEFAULT_SORT_ORDER } from 'utils/constants/default';
import {
  GET_CLIENT_CALENDAR,
  GET_CLIENT_LIST
} from 'api/graphql/queries/clients';
import {
  GET_CLINICS,
  GET_CLINIC_LIST_WITH_STAFF
} from 'api/graphql/queries/clinics';
import { alphabeticalSort } from 'utils/sort';
import {
  getClientBlockedDates,
  getClientEvents
} from 'utils/mappers/response/clients';
import {
  buildAppointmentDictionary,
  getEventInitialValues
} from 'utils/mappers/response/events';
import moment from 'moment';
import { generateEventsFromAvailability } from 'helpers/clientAvailabilityHelper';
import { GET_APPT_TYPES } from 'api/graphql/queries/appointment-types';

import { CLINIC_PROVIDERS } from 'utils/constants/lists';
import { toProviderOption } from 'utils/mappers/form';
import { getApptType } from 'utils/common';
import { compareEvents } from 'utils/mappers/calendar';
import gql from 'graphql-tag';
import { clientCalendarVar } from './components/SideBar/ClientCalendar';

//TODO: separate custom hooks
export const useClientSearchList = searchName => {
  const { loading: loadingClients, error: errorClients, data } = useQuery(
    GET_CLIENT_LIST,
    {
      variables: {
        search: searchName ? [{ name: searchName }] : null,
        sort: DEFAULT_SORT_ORDER,
        filter: '',
        from: 0,
        size: PAGE_SIZE
      }
    }
  );

  return {
    loadingClients,
    clients: data?.clients?.data || [],
    errorClients
  };
};

export const useNewAppointmentMetaData = (
  event,
  apptTypes,
  modalContentFor,
  client
) => {
  const formEvent = useMemo(() => {
    const formData = getEventInitialValues(
      event,
      apptTypes,
      modalContentFor,
      client
    );
    clientCalendarVar(moment(formData.selectedDate).toDate());
    return formData;
  }, [event, apptTypes, modalContentFor, client]);

  return {
    errorMetaData: false,
    loadingMetaData: !apptTypes.length,
    apptTypes,
    formEvent
  };
};

//TODO: custom hook not working
export const useAppointmentFormCalendarEvents = (clientID, selectedDate) => {
  const range = useMemo(
    () => ({
      start: moment(selectedDate || undefined)
        .startOf('week')
        .startOf('day')
        .toISOString(),
      end: moment(selectedDate || undefined)
        .endOf('week')
        .startOf('day')
        .add(1, 'day')
        .toISOString()
    }),
    [selectedDate]
  );

  const [availabilities, setAvailabilities] = useState([]);
  const [events, setEvents] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);
  const [comparedEvents, setComparedEvents] = useState([]);

  const { loading, error } = useQuery(GET_CLIENT_CALENDAR, {
    variables: {
      clientID,
      startTime: range.start,
      endTime: range.end
    },
    onCompleted: data => {
      // make availabilities into event
      //TODO: use color map for appointments

      const apptTypes = data?.appointmentTypes
        ? data.appointmentTypes.concat(
            data.appointmentABATypes.map(a => ({ ...a, isABA: true }))
          )
        : [];

      const adjustedAvailabilities = adjustAvailabilities(
        data?.clientAvailability?.length > 0
          ? data.clientAvailability[0].availabilities
          : {},
        range.start
      );

      const clientEvents = getClientEvents(
        data?.events || [],
        apptTypes,
        range
      );

      const clientBlockedDates = getClientBlockedDates(
        data.clientAvailability?.length > 0
          ? data.clientAvailability[0].blockedDates
          : []
      );

      setAvailabilities(adjustedAvailabilities);

      setBlockedDates(clientBlockedDates);
      setEvents(clientEvents);
      setComparedEvents(
        compareEvents([
          ...clientEvents,
          ...clientBlockedDates,
          ...adjustedAvailabilities
        ])
      );
    },
    skip: !clientID,
    fetchPolicy: 'cache-and-network'
  });

  return {
    loadingClientCalendar: loading,
    errorClientCalendar: error,
    events,
    availabilities,
    blockedDates,
    comparedEvents
  };
};

function adjustAvailabilities(availabilities, rangeStart) {
  return generateEventsFromAvailability(availabilities, rangeStart).map(a => ({
    start: moment(a.start).toDate(),
    end: moment(a.end).toDate(),
    type: a.type
  }));
}

// should return array of strings for event types
export const useEventTypes = () => {
  const { data, loading } = useQuery(
    gql`
      query {
        eventTypes
      }
    `,
    {
      fetchPolicy: 'cache-first'
    }
  );
  return {
    eventTypes: data?.eventTypes || [],
    loadingEventTypes: loading
  };
};
export const useAppointmentTypes = () => {
  const [apptTypes, setApptTypes] = useState([]);
  const { loading } = useQuery(GET_APPT_TYPES, {
    onCompleted: data => {
      setApptTypes(
        data?.appointmentTypes
          ? data.appointmentTypes
              .concat(
                data.appointmentABATypes.map(a => ({ ...a, isABA: true }))
              )
              .sort((appt1, appt2) =>
                alphabeticalSort(appt1.title, appt2.title)
              )
          : []
      );
    },
    fetchPolicy: 'cache-first'
  });

  const apptTypesMap = useMemo(() => {
    return buildAppointmentDictionary(apptTypes);
  }, [apptTypes]);

  return { apptTypes, loadingApptTypes: loading, apptTypesMap };
};

export const useStaffMap = () => {
  const [staffMap, setStaffMap] = useState(new Map());

  //TODO: make clinics without rooms and clients
  useQuery(GET_CLINIC_LIST_WITH_STAFF, {
    onCompleted: data => {
      const staffList = data?.clinicsWithoutRoomsWithoutClients
        ? data.clinicsWithoutRoomsWithoutClients.reduce(
            (staffList, currentClinic) => {
              return [...staffList, ...currentClinic.staffList];
            },
            []
          )
        : [];

      const newStaffMap = new Map();
      staffList.forEach(provider => newStaffMap.set(provider.id, provider));
      setStaffMap(newStaffMap);
    },
    fetchPolicy: 'cache-first'
  });

  return staffMap;
};

export const useClinicsAndStaffList = (
  apptTypeTitle,
  isClinical,
  clinicPreference,
  clinicId
) => {
  const { loading, data, error } = useQuery(GET_CLINIC_LIST_WITH_STAFF);
  const { apptTypes } = useAppointmentTypes();

  const apt = useMemo(() => {
    return getApptType({ title: apptTypeTitle }, apptTypes || []);
  }, [apptTypeTitle, apptTypes]);

  const [staffList, filteredStaffList] = useMemo(() => {
    const staffList = data?.clinicsWithoutRoomsWithoutClients
      ? data.clinicsWithoutRoomsWithoutClients.reduce(
          (staffList, currentClinic) => {
            return [...staffList, ...currentClinic.staffList];
          },
          []
        )
      : [];

    if (Array.isArray(staffList) && staffList.length > 0) {
      const filteredStaffList = staffList
        .filter(provider => {
          // filter by clinic preference
          if (
            clinicPreference === CLINIC_PROVIDERS[0].id &&
            provider.clinicId !== clinicId
          ) {
            // need here current clinic id
            return false;
          }

          // filter by appointment type
          if (apt && apt.id) {
            // check if the provider has this appointment type
            return provider.appointmentTypes.some(
              a => a.id === apt.id && a.isClinical === apt.isClinical
            );
          }
          return true;
        })
        .sort((a, b) => alphabeticalSort(a.displayName, b.displayName));

      return [staffList, filteredStaffList];
    } else return [[], []];
  }, [data, apt, clinicPreference, clinicId]);

  const clinicsMap = useMemo(() => {
    const clinicsMap = new Map();

    data?.clinicsWithoutRoomsWithoutClients.forEach(cl => {
      clinicsMap.set(cl.id, cl);
    });

    return clinicsMap;
  }, [data]);

  return {
    loading,
    error,
    clinics: data?.clinicsWithoutRoomsWithoutClients || [],
    staffList,
    filteredStaffList: toProviderOption(
      filteredStaffList,
      apt?.title || '',
      clinicsMap,
      clinicPreference
    )
  };
};
