import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormItem } from 'views/containers/form';
import { Button } from 'lib/ui';
import { FooterFormWrapper } from '../../style';
import { ISmartResultItemForm, ISmartResultListForm } from 'model/v2';
import {
  prepareSmartDriveTimeEventForSubmission,
  prepareSmartEvalEventForSubmission,
  prepareSmartEventForSubmission
} from 'utils/mappers/request/V2/event';

const getSmartConflictsVariables = (mappedEvents: any[]) => {
  return mappedEvents.map(mapped => ({
    reschedule: false,
    masterId: null,
    recurring: parseInt(mapped?.recurrencePattern?.recurringEvery || '0') > 0,
    providerId: mapped.provider?.id,
    clientId: mapped.client?.id,
    startDate: mapped.startDate,
    endDate: mapped.endDate,
    recurringUntil: mapped.recurrencePattern?.recurringUntil,
    recurringEvery: mapped.recurrencePattern?.recurringEvery
  }));
};

interface Props {
  setMappedSmartEvent: (val: any) => void;
  doSmartConflictRequest: (val: any) => void;
  setStep: (val: number) => void;
  saving: boolean;
  setSaving: (val: boolean) => void;
  noSelectedSlots: boolean;
  appointmentSubTypeId?: number;
  hasSingleDT: any;
}

const SmartValidationFooter: React.FC<Props> = ({
  setMappedSmartEvent,
  setSaving,
  doSmartConflictRequest,
  saving,
  noSelectedSlots,
  appointmentSubTypeId,
  hasSingleDT
}: Props) => {
  const { handleSubmit, errors } = useFormContext();

  const submitForm = handleSubmit(async (data: ISmartResultListForm) => {
    for (let i = 0; i < data.results.length; i++) {
      const card = data.results[i];
      if (card.isChecked && errors?.['results']?.[i]) return;
    }

    setSaving(true);
    const checkedCards: ISmartResultItemForm[] = data.results.filter(
      item => item.isChecked
    );
    let mappedSmartEvents;
    if (hasSingleDT) {
      mappedSmartEvents = checkedCards.map(item =>
        prepareSmartEvalEventForSubmission(item, appointmentSubTypeId)
      );
    } else {
      mappedSmartEvents = checkedCards.map(item =>
        prepareSmartEventForSubmission(item, appointmentSubTypeId)
      );
      const driveTimeResults = checkedCards.filter(item => item.driveTimeCheck);
      const recurringCards = driveTimeResults.map(item =>
        prepareSmartDriveTimeEventForSubmission(item)
      );
      mappedSmartEvents =
        recurringCards.length > 0
          ? mappedSmartEvents.concat(recurringCards)
          : mappedSmartEvents;
    }
    setMappedSmartEvent(mappedSmartEvents);
    const conflictInputs = getSmartConflictsVariables(mappedSmartEvents);
    doSmartConflictRequest({
      variables: {
        conflictInputs
      }
    });
  });

  return (
    <FooterFormWrapper>
      <FormItem optional={false} label="">
        <Button
          className="large"
          onClick={submitForm}
          type="submit"
          loading={saving}
          disabled={saving || noSelectedSlots}
        >
          Schedule
        </Button>
      </FormItem>
    </FooterFormWrapper>
  );
};
export default React.memo(SmartValidationFooter);
