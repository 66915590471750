import React from 'react';
import { MainCalendarSidebarWrapper } from './style';
import FixedSideBar from 'components/ui/fixedSidebar';
import ResetFiltersIcon from '../../../assets/img/noun-remove.svg';

const CalendarSidebar: React.FC = () => {
  return (
    <MainCalendarSidebarWrapper>
      <FixedSideBar>
        <div className="header">
          <div className="header-title">Calendars</div>
          <img
            className="close-button"
            src={ResetFiltersIcon}
            onClick={() => {}}
            alt="Reset Filters"
          />
        </div>
      </FixedSideBar>

      {/* TODO: list providers and clients  */}
    </MainCalendarSidebarWrapper>
  );
};

export default React.memo(CalendarSidebar);
