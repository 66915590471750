import { useQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router';
import { useCallback, useMemo } from 'react';
import Content from 'views/components/ui/content';
import { GET_AVAILIABILITY_TEMPLATE_BY_ID } from 'api/graphql/v2/queries/AvailabilityTemplate';
import { mapDayAvailabilityToCalendarEvent } from 'utils/mappers/availabilityTemplate';
import {
  CalendarWrapper,
  SubTitleWrapper
} from 'pages/ConfigurationsPage/addAvailabilityTemplate/style';
import { BigCalendar } from 'lib/ui';
import moment from 'moment';
import { localizer } from 'utils/builders/calendar';
import { STAFF_CALENDAR_MAX_HEIGHT } from 'utils/constants/calendar';
import { Event } from 'react-big-calendar';
import {
  TitleWrapper,
  SaveAndClose
} from 'pages/ConfigurationsPage/addAvailabilityTemplate/style';
import { Icon } from 'semantic-ui-react';
import { Appointment_Types } from 'components/AvailabilityTemplateModalForm/EditForm/dropdownOptions';
import { GET_PROVIDER_LOCATIONS } from 'api/graphql/v2/queries/Enums';
import { getLocationColor } from 'pages/ConfigurationsPage/addAvailabilityTemplate/utils';
import { AvailabilityTemplateData } from 'model/v2/availabilityTemplate';

interface AvailabilityTemplateWithDayAvailability {
  getAvailabilityTemplateById: AvailabilityTemplateData;
}

interface Iparams {
  id: string;
}

const ViewTemplateModal = () => {
  const history = useHistory();
  const view = 'week';
  const formats = {
    dayFormat: 'ddd'
  };

  const { data: locations, loading: locationLoading } = useQuery(
    GET_PROVIDER_LOCATIONS
  );

  const availabilityTemplateId: Iparams = useParams();
  const { data, loading, error } = useQuery<
    AvailabilityTemplateWithDayAvailability
  >(GET_AVAILIABILITY_TEMPLATE_BY_ID, {
    variables: {
      id: Number(availabilityTemplateId.id)
    }
  });

  const customEventPropGetter = useCallback(
    (event: any) => {
      const appt_Type = Appointment_Types.find((appt_type: any) => {
        return appt_type.value === event.type;
      });
      if (appt_Type && appt_Type.colorRank > 0) {
        return { className: `${event.type?.toLowerCase()}` };
      } else {
        const style = getLocationColor(
          locations.providerLocations,
          event.location
        );

        if (
          event.type === 'evalslot' &&
          event.location === locations?.providerLocations[0]?.id
        ) {
          return { className: 'evalslot' };
        } else {
          return { style };
        }
      }
    },
    [locations]
  );

  const titleAccessor = useCallback((event: Event) => {
    return event.title !== undefined &&
      event.title?.toLocaleLowerCase() !== 'working'
      ? event.title.toUpperCase()
      : '';
  }, []);

  const events = useMemo(
    () =>
      mapDayAvailabilityToCalendarEvent(
        data?.getAvailabilityTemplateById.dayAvailabilities || []
      ),
    [data]
  );

  const reroute = useCallback(() => {
    history.goBack();
  }, [history]);

  const onIconClose = () => {
    reroute();
  };

  return (
    <Content data={data} error={error} loading={loading || locationLoading}>
      {() => (
        <>
          <div className="popup-wrap">
            <TitleWrapper>
              <h1>
                {data?.getAvailabilityTemplateById.name}{' '}
                {data?.getAvailabilityTemplateById.default ? '(Default)' : ''}
              </h1>
              <SaveAndClose>
                <Icon link name={'close'} onClick={onIconClose} />
              </SaveAndClose>
            </TitleWrapper>
            <SubTitleWrapper>
              <h3>Recurring Template - View Only</h3>
            </SubTitleWrapper>
            <div className="inner-wrap">
              <CalendarWrapper>
                <BigCalendar
                  selectable
                  views={[view]}
                  defaultView={view}
                  defaultDate={new Date()}
                  scrollToTime={moment()
                    .set({ h: 7, m: 0 })
                    .toDate()}
                  toolbar={false}
                  events={events}
                  step={15}
                  timeslots={4}
                  localizer={localizer}
                  formats={formats}
                  style={{
                    height: STAFF_CALENDAR_MAX_HEIGHT
                  }}
                  titleAccessor={titleAccessor}
                  eventPropGetter={customEventPropGetter}
                />
              </CalendarWrapper>
            </div>
          </div>
        </>
      )}
    </Content>
  );
};

export default ViewTemplateModal;
