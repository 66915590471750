import React, { useState, useMemo, useCallback } from 'react';
import {
  CalendarWrapper,
  StaffTitle
} from 'views/components/calendar/mainCalendar/MainCalendar.style';
import { BigCalendar } from 'lib/ui';
import { Views } from 'react-big-calendar';
import moment from 'moment';
import { dayDate } from 'utils/format';
import { isSelectedEvent } from 'utils/format/time';
import { MEDICAL_STAGE_LIST } from 'utils/constants/lists';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_USER_WORKING_HOURS } from 'api/graphql/queries/staff';
import { getStaffMemberNonWorkingHours } from 'utils/mappers/response/staff';
import { getApptType } from 'utils/common';
import { customizeRange, localizer } from 'utils/builders/calendar';
import { useAppointmentTypes } from '../../newAppointmentHooks';
import { MAX_CALENDAR_TIME, MIN_CALENDAR_TIME } from 'utils/constants/calendar';
import ClientSearchProfilePicture from '../SearchClients/ClientSearchProfilePicture';
import { useFormContext, useWatch } from 'react-hook-form';

const formats = {
  timeGutterFormat: 'h:mm',
  dayFormat: 'ddd DD'
};

function ProviderHoverCalendar({ opening }) {
  const { apptTypes } = useAppointmentTypes();
  const [totalEvents, setTotalEvents] = useState([]);

  const { control } = useFormContext();
  const selectedSlot = useWatch({ control, name: 'selectedSlot' });

  const range = useMemo(() => customizeRange(opening.start, 'day'), [opening]);

  const customToolbar = data => (
    <div className="current-date">{dayDate(data.date)}</div>
  );

  const customEvent = useCallback(
    ({ event }) => {
      const { start, end, appointmentType, client } = event;

      const timeRange = `${moment(start).format('h:mm')} - ${moment(end).format(
        'h:mm A'
      )}`;
      const apptType = getApptType(appointmentType || {}, apptTypes || []);
      const phenotype = MEDICAL_STAGE_LIST[client?.phenoType];

      return (
        <div className="provider-item">
          <span className="date">{timeRange}</span>
          <span className="appointment">{apptType?.title}</span>
          <span className="phenotype">{phenotype}</span>
        </div>
      );
    },
    [apptTypes]
  );

  const customEventPropGetter = useCallback(
    event => ({
      className: `${event?.type || ''} ${isSelectedEvent(selectedSlot, event) &&
        'rbc-selected'}`
    }),
    [selectedSlot]
  );

  const customResourceTitleAccessor = useCallback(
    staff => {
      const { displayName, id } = staff || {};
      return (
        <StaffTitle>
          <div className="staff-wrapper">
            <div className="staff-image">
              <ClientSearchProfilePicture userId={id} alt={displayName} />
            </div>
            <div className="staff-name">{displayName}</div>
          </div>
          <span className="staff-sessions">{totalEvents.length} sessions</span>
        </StaffTitle>
      );
    },
    [totalEvents]
  );

  useQuery(QUERY_USER_WORKING_HOURS, {
    variables: {
      users: [{ email: opening.provider?.email, userId: opening.provider?.id }],
      providersIDs: [opening.provider.id],
      startTime: range.start.toISOString(),
      endTime: range.end.toISOString()
    },
    onCompleted: ({ usersWorkingHours, events }) => {
      // make this use effect because if selected slot changes needs to update this
      const nonWorkingHours = getStaffMemberNonWorkingHours(
        usersWorkingHours[0]?.workingHours,
        range,
        usersWorkingHours[0]?.userId
      );

      const mappedEvents = events
        .map(ev => ({
          ...ev,
          start: new Date(ev.startTime),
          end: new Date(ev.endTime),
          resourceId: opening.provider.id,
          type: 'appointment'
        }))
        .concat(nonWorkingHours);

      setTotalEvents(mappedEvents);
    },
    skip: !opening.provider?.email || !opening.provider.id
  });

  const totalEventsWithSelectedSlot = useMemo(() => {
    if (selectedSlot && selectedSlot.start)
      return [
        ...totalEvents,
        {
          ...selectedSlot,
          start: new Date(selectedSlot.start),
          end: new Date(selectedSlot.end),
          resourceId: selectedSlot.providerId,
          type: 'appointment'
        }
      ];
    return totalEvents;
  }, [selectedSlot, totalEvents]);

  return (
    <CalendarWrapper>
      <BigCalendar
        events={totalEventsWithSelectedSlot}
        views={[Views.DAY]}
        defaultView={Views.DAY}
        min={MIN_CALENDAR_TIME}
        max={MAX_CALENDAR_TIME}
        components={{
          toolbar: customToolbar,
          event: customEvent
        }}
        step={15}
        timeslots={4}
        localizer={localizer}
        formats={formats}
        defaultDate={new Date(opening.start)}
        resources={[opening.provider]}
        resourceTitleAccessor={customResourceTitleAccessor}
        eventPropGetter={customEventPropGetter}
        style={{ height: '620px' }}
        className="providers-calendar"
      />
    </CalendarWrapper>
  );
}

export default React.memo(ProviderHoverCalendar);
