import React from 'react';
import { FormItem, Input, Select, Datepicker } from 'views/containers/form';
import { Row } from 'antd';
import { GENDER_LIST, SEX_LIST, TRUE_FALSE_LIST } from 'utils/constants/lists';
import { Field, FastField } from 'formik';
import { mapToOption } from 'utils/mappers/form';

const sexOptions = mapToOption(SEX_LIST);
const genderOptions = mapToOption(GENDER_LIST);
const yesOrNoOptions = TRUE_FALSE_LIST?.map(item => {
  return {
    value: item.value,
    label: item.id
  };
});

export const ClientIdentificationForm = React.memo(({ disabledFormFields }) => {
  return (
    <>
      <div className="form-title">Identification</div>
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem label="Last Name" xs={16} sm={6}>
          <Field
            name="lastName"
            render={({ field }) => (
              <Input
                name="lastName"
                value={field.value}
                placeholder="Last Name"
                onChange={field.onChange}
                disabled={disabledFormFields.lastName}
              />
            )}
          />
        </FormItem>
        <FormItem label="First Name" xs={16} sm={6}>
          <Field
            name="firstName"
            render={({ field }) => (
              <Input
                name="firstName"
                value={field.value}
                placeholder="First Name"
                onChange={field.onChange}
                disabled={disabledFormFields.firstName}
              />
            )}
          />
        </FormItem>
        <FormItem optional label="First Name Used" xs={16} sm={6}>
          <Field
            name="firstNameUsed"
            render={({ field }) => (
              <Input
                name="firstNameUsed"
                value={field.value}
                placeholder="First Name Used"
                onChange={field.onChange}
                disabled={disabledFormFields.firstNameUsed}
              />
            )}
          />
        </FormItem>
        <FormItem label="Date of Birth" xs={16} sm={6}>
          <FastField
            name="profile.dob"
            render={fieldProps => (
              <Datepicker
                value={
                  fieldProps.field.value ? fieldProps.field.value : undefined
                }
                placeholder="mm/dd/yyyy"
                format={'MM/DD/YYYY'}
                disabled={disabledFormFields.dob}
                {...fieldProps}
              />
            )}
          />
        </FormItem>
      </Row>
      {/* //TODO: check working */}
      <Row type="flex" justify="start" align="top" gutter={16}>
        <FormItem label="Legal Sex" xs={16} sm={6}>
          <Field
            name="profile.legalSex"
            render={fieldProps => (
              <Select
                options={sexOptions}
                placeholder="Select"
                disabled={disabledFormFields.legalSex}
                {...fieldProps}
              />
            )}
          />
        </FormItem>
        <FormItem label="Gender" xs={16} sm={6}>
          <Field
            name="profile.gender"
            render={fieldProps => (
              <Select
                options={genderOptions}
                placeholder="Select"
                {...fieldProps}
                disabled={disabledFormFields.gender}
              />
            )}
          />
        </FormItem>
        <FormItem label="Test Client" xs={16} sm={6}>
          <Field
            name="isTestClient"
            render={fieldProps => (
              <Select
                defaultValue={
                  fieldProps.field.value ? fieldProps.field.value : false
                }
                options={yesOrNoOptions}
                placeholder="Select"
                {...fieldProps}
                disabled={disabledFormFields.isTestClient}
              />
            )}
          />
        </FormItem>
      </Row>
    </>
  );
});
