import React from 'react';
import * as _ from 'lodash';
import { SCHEDULE_TYPE_LIST } from 'utils/constants/lists';
import {
  StopOutlined,
  CheckOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { ABSOLUTE_URL_PROTOCOL_REGEX } from 'utils/constants/regex';
import { UsaStates } from 'usa-states';
import { DEFAULT_DURATION } from 'utils/constants/default';
import { isPlainObject, isArray } from 'lodash';
import { EventTypes } from 'model/eventType';
import { PROVIDER_ROSTER_STATUS_SELECT } from 'utils/constants/tables/staff';
export const addBodyClass = className => document.body.classList.add(className);

export const removeBodyClass = className =>
  document.body.classList.remove(className);

export const isSameAppointmentType = (apptType1, apptType2) => {
  const sameTitle = apptType1.title === apptType2.title;
  const sameID = apptType1.id === apptType2.id;
  return sameTitle || sameID;
};

export const programTypeFilter = (FilterData, data) => {
  let filterProgram = FilterData?.corticaProgram?.filter(
    Filterdata => Filterdata.programName === data.label
  );
  let [{ id }] = filterProgram;
  return {
    label: data.label,
    value: id
  };
};

export const getApptType = (type, apptTypes) =>
  apptTypes.find(apptType => isSameAppointmentType(apptType, type));

export const getApptTypeAndSubType = (
  { appointmentType, appointmentSubType },
  apptTypes
) => {
  const apptType = getApptType(appointmentType, apptTypes);
  const apptSubType =
    !apptType.eventType.name === EventTypes.ABA
      ? getApptType(
          {
            id: appointmentSubType,
            title: appointmentSubType
          }, // whichever is passed
          apptType.subTypes
        )
      : undefined;
  return { apptType, apptSubType };
};

export const constructProgramDataForDisplay = programType => {
  let corticaProgramType = programType.map(data => data.programName).join(', ');
  if (programType.length === 0) corticaProgramType = 'N/A';
  return corticaProgramType;
};

export const providerStatusForSelect = (providerStatus, selectOptions) => {
  let isInProviderStatusList = selectOptions.find(
    status => status.value === providerStatus
  );
  return isInProviderStatusList ? true : false;
};

export const getApptDuration = (_apptType, _apptSubType, apptTypes) => {
  const { apptSubType } = getApptTypeAndSubType(
    {
      appointmentType: {
        title: _apptType
      },
      appointmentSubType: _apptSubType
    },
    apptTypes
  );
  return apptSubType?.DTSetting.duration
    ? apptSubType.DTSetting.duration
    : apptSubType?.MEDSetting.duration
    ? apptSubType?.MEDSetting.duration
    : DEFAULT_DURATION.ABA;
};

export const clean = json => {
  if (_.isArray(json)) {
    return json.map(clean);
  }

  let sanitizedJSON = {};

  Object.keys(json).forEach(key => {
    let val = json[key];

    if (!_.isNull(val) && !_.isUndefined(val)) {
      if (_.isObject(val)) {
        sanitizedJSON[key] = clean(val);
      }

      sanitizedJSON[key] = val;
    }
  });

  return sanitizedJSON;
};

export const isRecurringEvent = type => type === SCHEDULE_TYPE_LIST[1].id;

export const openingId = opening => `${opening.providerId}-${opening.start}`;

export function getProvider(providers, id) {
  return providers.find(el => el.id === id);
}
export const icons = {
  needsReschedule: <WarningOutlined />,
  checked: <CheckOutlined />,
  canceled: <StopOutlined />
};

export const getTelehealthLink = telehealthLink => {
  return ABSOLUTE_URL_PROTOCOL_REGEX.test(telehealthLink)
    ? telehealthLink
    : `http://${telehealthLink}`;
};

export const getStateFullName = state =>
  new UsaStates().states.find(s => s.abbreviation === state)?.name;

export const cloneWithoutTypename = obj => {
  if (!isPlainObject(obj)) {
    return obj;
  }
  let result = {};
  for (let key in obj) {
    if (key === '__typename') {
      continue;
    }
    let value = obj[key];
    if (isPlainObject(value)) {
      result[key] = cloneWithoutTypename(value);
    } else if (isArray(value)) {
      result[key] = value.map(cloneWithoutTypename);
    } else {
      result[key] = value;
    }
  }
  return clean(result);
};

export const mapProviderAddress = (data, provider, addressId) => {
  if (!provider.gender) provider['gender'] = null;
  if (!provider.petAllergies) provider['petAllergies'] = null;
  if (addressId) {
    if (
      provider.addressLine1 &&
      provider.state &&
      provider.zipCode &&
      provider.city
    ) {
      provider['address'] = {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode
      };
    } else {
      provider['address'] = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: ''
      };
    }
    provider['address'].id = addressId;
  } else {
    if (
      provider.addressLine1 &&
      provider.state &&
      provider.zipCode &&
      provider.city
    ) {
      provider['address'] = {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode
      };
    }
  }

  return provider;
};

export const MapProviderPetAllergies = allergieOrder => {
  const powers = [];
  let currentPower = 1;
  let order = allergieOrder;
  while (order > 0) {
    if (order % 2 === 1) {
      powers.push(currentPower);
    }
    currentPower *= 2;
    order = Math.floor(order / 2);
  }
  return powers;
};

export const isEmptyObject = obj => {
  let result = true;
  for (let key in obj) {
    let value = obj[key];
    if (isPlainObject(value)) {
      result = result && !value;
    } else if (isArray(value)) {
      result = result && value.length === 0;
    } else if (typeof value !== 'string') {
      result = result && isEmptyObject(value);
    }
  }
  return result;
};

/* @return A timezone offset in minutes */
export const getTzOffsetInMinutes = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export const getWebClientTzString = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
