import React, { useState, useCallback, useEffect } from 'react';
import { Views } from 'react-big-calendar';
import { BigCalendar } from 'lib/ui';
import Toolbar from 'views/components/calendar/mainCalendar/toolbar';
import Event from 'views/components/calendar/mainCalendar/event';
import { CalendarWrapper } from 'views/components/calendar/mainCalendar/MainCalendar.style';
import * as PropTypes from 'prop-types';
import {
  customEventPropGetter,
  customizeRange,
  localizer
} from 'utils/builders/calendar';
import { useQuery } from '@apollo/react-hooks';
import { useSubscription } from 'react-apollo';
import { GET_CLIENT_EVENTS } from 'api/graphql/v2/queries/Clients';
import {
  getClientEvents,
  getClientBlockedDates,
  clientEventsAdjacency
} from 'utils/mappers/response/clients';
import { ModalWrapper } from 'views/components/calendar/mainCalendar/ModalWrapper';
import { modalForList } from 'utils/constants/breadcrumbs';
import {
  MAX_CALENDAR_TIME,
  MIN_CALENDAR_TIME,
  MIN_CLIENT_CALENDAR_TIME,
  CLIENT_CALENDAR_MAX_HEIGHT,
  CLIENT_CALENDAR_MIN_HEIGHT
} from 'utils/constants/calendar';
import {
  clientCalendarPrefDayVar,
  clientCalendarPrefViewVar
} from 'utils/cache/calendar/index';
import { useReactiveVar } from '@apollo/client';
import { CALENDAR_EVENTS_SUBSCRIPTION } from 'api/graphql/v2/subscriptions/Events';
import { handleSubscriptionDataUpdate } from 'helpers/subscriptionsHelper';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { GET_CLIENT_AVAILABILITYV2 } from 'api/graphql/v2/queries/Clients';
import { mapClientAvailabilityOutputToEvents } from 'helpers/clientAvailabilityHelperV2';
import { ClientAvailabilityOutput } from 'model/v2';
import {
  mapClientAvailabilityOutputToClientProfilePage,
  formAvaibilityOnCalendarView
} from 'helpers/clientAvailabilityHelperV2';
import { generateAvailabilitySchedule } from 'helpers/clientAvailabilityHelperV2';
import { GET_CLIENT_LOCATIONS } from 'api/graphql/v2/queries/Enums';
import { GET_AVAILABILITY_AUDIT } from 'api/graphql/v2/mutations/ClientAvailability';
import { AvailabilitySlot } from 'views/components/calendar/mainCalendar/availabilitySlot';
import { AvailabilityHeader } from 'views/components/calendar/mainCalendar/availabilitySlot';
import { SCROLL_TIME_CALENDAR } from 'utils/constants/calendar';
import { styleClientCalendarEvents } from 'utils/mappers/response/clients';
import moment from 'moment';

const formats = {
  dayFormat: 'ddd DD'
};

const ScheduleCalendar = ({
  client,
  calendarOptions: { blockedDates, availabilityLastUpdated }
}) => {
  const calendarDate = useReactiveVar(clientCalendarPrefDayVar);
  const calendarView = useReactiveVar(clientCalendarPrefViewVar);
  const [clickedEventId, setClickedEventId] = useState(null);
  const [availabilitySlots, setAvailabilitySlots] = useState();
  const [isAvailabiltyFetched, setIsAvailabilityFetched] = useState(false);
  const [auditHistory, setAuditHistory] = useState('');
  const [distributedEventList, setDistributedEventsList] = useState({});
  const handleRangeChange = useCallback(date => {
    clientCalendarPrefDayVar(date);
  }, []);

  const handleViewChange = useCallback(inputView => {
    clientCalendarPrefViewVar(inputView);
  }, []);

  const [totalEvents, setTotalEvents] = useState([]);
  const { data: locations, loading: loadingLocations, error } = useQuery(
    GET_CLIENT_LOCATIONS
  );
  useQuery(GET_AVAILABILITY_AUDIT, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id },
    onCompleted: data => {
      let lastAuditedBy = data.getAvailabilityAudit?.length
        ? data.getAvailabilityAudit?.length - 1
        : 0;
      setAuditHistory({
        auditActionBy: data.getAvailabilityAudit[lastAuditedBy]?.actionBy,
        auditActionAt: data.getAvailabilityAudit[lastAuditedBy]?.actionAt
          ? moment(data.getAvailabilityAudit[lastAuditedBy]?.actionAt).format(
              'MMMM DD, YYYY'
            )
          : null,
        auditActionTime: moment(
          data.getAvailabilityAudit[lastAuditedBy]?.actionAt
        ).format('hh:mm a')
      });
    }
  });
  useQuery(GET_CLIENT_EVENTS, {
    variables: {
      clientId: Number(client.id),
      startDate: customizeRange(calendarDate, calendarView).start,
      endDate: customizeRange(calendarDate, calendarView).end
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const events = data?.clientEvents || [];
      const clientsEvent = getClientEvents(
        events,
        customizeRange(calendarDate, calendarView),
        true
      ).concat(getClientBlockedDates(blockedDates));
      const checkConflictingEvents = clientEventsAdjacency({
        clientsEvent: clientsEvent
      });
      setDistributedEventsList(checkConflictingEvents);
      setTotalEvents(clientsEvent);
      setIsAvailabilityFetched(true);
    }
  });

  useSubscription(CALENDAR_EVENTS_SUBSCRIPTION, {
    variables: {
      clientId: Number(client.id),
      startDate: customizeRange(calendarDate, calendarView).start,
      endDate: customizeRange(calendarDate, calendarView).end
    },
    skip: !getFeatureAvailability(FEATURES.CALENDARS_SUBSCRIPTIONS),
    onSubscriptionData: result => {
      let CalendarAppointment = totalEvents.filter(
        val => !val.isAvailabilitySlot
      );
      const events = handleSubscriptionDataUpdate(
        result?.subscriptionData?.data?.calendarEvents,
        CalendarAppointment
      );
      const clientsEvent = getClientEvents(
        events,
        customizeRange(calendarDate, calendarView),
        true
      );
      const checkConflictingEvents = clientEventsAdjacency({
        clientsEvent: clientsEvent
      });
      setDistributedEventsList(checkConflictingEvents);
      const AvailabilitySchedule = generateAvailabilitySchedule(
        customizeRange(calendarDate, calendarView).start,
        customizeRange(calendarDate, calendarView).end
      );
      const getTotalEventSlots = formAvaibilityOnCalendarView(
        AvailabilitySchedule
      )(availabilitySlots)(locations)(calendarView);
      setTotalEvents([...clientsEvent, ...getTotalEventSlots]);
    }
  });
  const { loading: loadingEvents } = useQuery(GET_CLIENT_AVAILABILITYV2, {
    fetchPolicy: 'no-cache',
    variables: { id: client.id },
    onCompleted: data => {
      const availabilityData = new mapClientAvailabilityOutputToClientProfilePage(
        data?.clientDayAvailabilities
      );
      setAvailabilitySlots(availabilityData.Availabilty);
    }
  });
  const CustomHeader = label => {
    return <AvailabilityHeader label={label} client={client} />;
  };
  const customToolbar = data => {
    return (
      <div className="Client-Profile-ToolBar">
        <Toolbar
          data={data}
          updateView={handleViewChange}
          range={customizeRange(calendarDate, calendarView)}
          options={{
            clientAvailabilityLastUpdated:
              availabilityLastUpdated || Date.now(),
            isClientProfile: true,
            auditHistory: auditHistory
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (availabilitySlots !== undefined && isAvailabiltyFetched) {
      const AvailabilitySchedule = generateAvailabilitySchedule(
        customizeRange(calendarDate, calendarView).start,
        customizeRange(calendarDate, calendarView).end
      );
      setIsAvailabilityFetched(false);
      const getTotalEventSlots = formAvaibilityOnCalendarView(
        AvailabilitySchedule
      )(availabilitySlots)(locations)(calendarView);
      setTotalEvents([...totalEvents, ...getTotalEventSlots]);
    }
  }, [
    availabilitySlots,
    calendarDate,
    calendarView,
    isAvailabiltyFetched,
    totalEvents,
    locations
  ]);
  useEffect(() => {
    setTotalEvents([]);
  }, [calendarView]);

  const customEvent = ({ event }) => {
    let className;
    let style;
    if (!event.isAvailabilitySlot) {
      if (Object.keys(distributedEventList).length > 0) {
        const weekTotalEvents = distributedEventList[event.dayOfWeek]?.find(
          innerArray => {
            const foundObject = innerArray?.find(obj => obj.id === event.id);
            return foundObject !== undefined;
          }
        );
        const sortedWeekTotal = weekTotalEvents?.sort(
          (a, b) => new Date(a.startAt) - new Date(b.startAt)
        );
        let Indexing = sortedWeekTotal?.findIndex(
          value => value.id === event.id
        );
        style = styleClientCalendarEvents.call(weekTotalEvents, Indexing);
      }

      return <Event event={event} view={calendarView} style={style} />;
    } else {
      return <AvailabilitySlot event={event} locations={locations} />;
    }
  };
  const getEventProp = event => {
    let className;
    let Adjacencystyle;

    if (!event.isAvailabilitySlot) {
      if (Object.keys(distributedEventList).length > 0) {
        const weekTotalEvents = distributedEventList[event.dayOfWeek]?.find(
          innerArray => {
            const foundObject = innerArray?.find(obj => obj.id === event.id);
            return foundObject !== undefined;
          }
        );
        weekTotalEvents?.sort(
          (a, b) => new Date(a.startAt) - new Date(b.startAt)
        );
        const eventArrvingIndex = weekTotalEvents?.findIndex(
          value => value.id === event.id
        );
        Adjacencystyle = styleClientCalendarEvents.call(
          weekTotalEvents,
          event.id,
          eventArrvingIndex
        );
      }
    }
    let overallStyle = customEventPropGetter(event);
    overallStyle.className = `${overallStyle.className} clientEventCalendar ${calendarView}-${Adjacencystyle}`;
    return overallStyle;
  };

  return (
    <CalendarWrapper className={calendarView}>
      <BigCalendar
        events={totalEvents}
        views={[Views.DAY, Views.WEEK, Views.MONTH]}
        defaultView={calendarView}
        onView={handleViewChange}
        // min={MIN_CLIENT_CALENDAR_TIME}
        // max={MAX_CALENDAR_TIME}
        components={{
          toolbar: customToolbar,
          event: customEvent,
          header: CustomHeader
        }}
        date={calendarDate}
        onNavigate={handleRangeChange}
        step={15}
        timeslots={4}
        localizer={localizer}
        slotEventOverlap={false}
        formats={formats}
        eventPropGetter={getEventProp}
        scrollToTime={SCROLL_TIME_CALENDAR}
        style={{
          height:
            calendarView.calendarView !== Views.MONTH
              ? CLIENT_CALENDAR_MAX_HEIGHT
              : CLIENT_CALENDAR_MIN_HEIGHT
        }}
        className="profile-schedule-calendar"
        onSelectEvent={event => {
          setClickedEventId(event.id.toString());
        }}
      />
      {clickedEventId && (
        <ModalWrapper
          eventId={clickedEventId}
          setClickedEvent={setClickedEventId}
          range={customizeRange(calendarDate, calendarView)}
          modalContentFor={modalForList.clientCalendar}
        />
      )}
    </CalendarWrapper>
  );
};

ScheduleCalendar.propTypes = {
  uiState: PropTypes.object
};

export default ScheduleCalendar;
export { ScheduleCalendar };
