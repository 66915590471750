import styled from 'styled-components';

export const ClientAvailabilityViewWrapper = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  .faIcon {
    position: relative;
    margin-left: 10px;
    color: #6f42f5;
    font-size: 16px;
    cursor: pointer;
    width: 20px;
    top: 10px;
  }
  .faEditCls {
    top: 18px !important;
  }

  .day-title {
    font-size: 14px;
    color: #6f42f5;
    font-weight: bold;
    padding-top: 10px;
    position: relative;
    left: -10px;
  }
  .select-all-btn {
    text-decoration: underline;
    color: #6f42f5;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const InputDayFilterRowWrapper = styled.div<{
  isTimeDisabled: boolean;
  isDragTimeDisabled: boolean;
}>`
  .input-range__track--active {
    background: #6f42f5 !important;
  }
  .input-range__slider {
    border: ${({ isTimeDisabled, isDragTimeDisabled }) =>
      isTimeDisabled || isDragTimeDisabled
        ? '1px solid #eeeeee'
        : '1px solid #6f42f5'};
    background: ${({ isTimeDisabled, isDragTimeDisabled }) =>
      isTimeDisabled || isDragTimeDisabled ? '#eeeeee' : '#6f42f5'};
  }
  .input-range__label--min,
  .input-range__label--max {
    display: none;
  }
  .input-range__label--value {
    top: 8px;
    color: #415766; /* Add the missing semicolon here */
    font-size: 10px;
    display: none;
  }
  .error {
    font-size: 10px;
  }
  .input-range__label-container {
    padding: 25px; /* Adjust the padding as needed */
  }
  .time-cls {
    height: 20px;
  }
  .time-cls p {
    font-size: 12px;
    margin-bottom: 0px;
  }
`;
