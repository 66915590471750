import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const ContentWrapper = styled.div`
  padding: 55px;
  background-color: ${colors.EDIT_FORM_BG};
  .row {
    display: flex;
  }
  .form-input {
    margin-bottom: 12px;
  }
  .hour-row {
    display: flex;
    margin-bottom: 12px;
  }
  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: ${colors.WHITE};
    background-color: ${colors.PURPLE};
  }
  .label {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    color: #415766;
  }
  .warning {
    color: ${colors.RED};
  }
  .form-select {
    width: 300px;
    min-width: 300px;
    margin-right: 18px;
  }
`;
