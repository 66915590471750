import { times } from 'model/v2';
import React from 'react';
import { ProviderTimeWraper } from './Style';
const momentTz = require('moment-timezone');
interface IProps {
  result: any;
  providerTime: times;
}

const ProvidersTime: React.FC<IProps> = ({ result, providerTime }: IProps) => {
  const startTime = momentTz
    .tz(providerTime.startTime, result.provider.clinic.timezone)
    .format('hh:mm A');
  const endTime = momentTz
    .tz(providerTime.endTime, result.provider.clinic.timezone)
    .format('hh:mm A');

  const zone = new Date()
    .toLocaleTimeString('en-us', {
      timeZoneName: 'short',
      timeZone: result.provider.clinic.timezone
    })
    .split(' ')[2];
  return (
    <>
      <ProviderTimeWraper>
        <span className="time-zone-text span-m-3px">
          Provider's Appointment: {zone}
        </span>
        <span className="time-zone-text span-m-3px time-text m-top-5">
          {startTime} - {endTime}
        </span>
      </ProviderTimeWraper>
    </>
  );
};

export default React.memo(ProvidersTime);
